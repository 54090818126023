import React from "react";

import { ctMsgType } from "../../../lib/ConstCommand";
import { ctRcvKind } from "../../../lib/classTalk/CodeEnum";

// import PentalkIcon from "../../../images/hiclasstvIcon.png"
// import WatsonAvatar from "../../image/watson/watson_avatar.png";
import WatsonMessage from "../../lecture/watson/WatsonMessage";
import FileShareMessage from "./FileShareMessage";
import AudioMemoMessage from "./AudioMemoMessage";
import InkMemoMessage from "./InkMemoMessage";
import ThumbnailMessage from "./ThumbnailMessage";
import CardTemplate from "../../lecture/CardTemplate";
import TextMessage from "./TextMessage";
import ClassTalkProfileImage from "../../user/profile/ClassTalkProfileImage";

const RcvMessage = ({ indexKey, msgInfo, handleSelectUrl, handleSelectedFunc }) => {
    switch (msgInfo.kind) {
        case ctMsgType.WatsonText:
        case ctMsgType.WatsonImage:
            return <WatsonMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;

        /* case ctMsgType.FileShare:
            return <FileShareMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;

        case ctMsgType.AudioMemo:
            return <AudioMemoMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;

        case ctMsgType.InkMemo:
            return <ThumbnailMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />; */

        case ctMsgType.PageJpeg:
            return <ThumbnailMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;

        case ctMsgType.CardTemplate:
            return <CardTemplate cardInfo={msgInfo.cardInfo} handleSelectedFunc={handleSelectedFunc} />;

        case ctMsgType.Quiz:
            return <CardTemplate cardInfo={msgInfo.cardInfo} handleSelectedFunc={handleSelectedFunc} />;

        case ctMsgType.AudioMemo:
        case ctMsgType.FileShare:
        case ctMsgType.InkMemo:
            return (
                <>
                    {
                        msgInfo.text !== undefined && msgInfo.text !== null && msgInfo.text !== "" &&
                        <TextMessage kind={msgInfo.kind} indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />
                    }
                    <RcvClassTalkMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />
                </>
            );

        default:
            return <TextMessage kind={msgInfo.kind} indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;
    }
}

const RcvClassTalkMessage = ({ indexKey, msgInfo, handleSelectUrl }) => {
    /* return (
        <>
        {
            msgInfo.text !== undefined && msgInfo.text !== null && msgInfo.text !== "" &&
                <TextMessage kind={msgInfo.kind} indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />
        }
        </>
    ); */

    switch (msgInfo.kind) {
        case ctMsgType.AudioMemo:
            return <AudioMemoMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;

        case ctMsgType.FileShare:
            return <FileShareMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;

        case ctMsgType.InkMemo:
            return <InkMemoMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;

        default:
            return <></>;
    }
}

const RcvProfileImg = ({ msgInfo }) => {
    switch (msgInfo.rcvKind) {
        case ctRcvKind.RcvWatson:
        case ctRcvKind.rcvPentalk:
        case ctRcvKind.Card:
            return <img className="ct-profile-img watson" src="/image/watson/watson_avatar.png" alt="" />;

        case ctRcvKind.infoPentalk:
            return <img className="ct-profile-img" src="/images/hiclasstvIcon.png" alt="" />;

        default:
            return <ClassTalkProfileImage url={msgInfo.iconSeq} alt={msgInfo.rcvName} />;
    }
}

const ReceiveMessage = ({ indexKey, msgInfo, handleSelectUrl, handleSelectedClassTalkFunc }) => {
    return (
        <div className="d-flex w-100" style={{ padding: '2px' }}>
            <RcvProfileImg msgInfo={msgInfo} />
            <div className="d-flex flex-column w-100" style={{ padding: '2px' }}>
                <span className="ct-profile-nickname">{msgInfo.rcvName}</span>
                <RcvMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} handleSelectedFunc={handleSelectedClassTalkFunc} />
                {
                    msgInfo.emoticSeq > 0 && msgInfo.emoticSeq < 64 &&
                    // <img className="hiclasstv-classtalk-emo" src={require(`../images/et${msgInfo.emoticSeq.toString().padStart(2, "0")}.png`)} alt="" />
                    <img className="hiclasstv-classtalk-emo" src={`/images/classtalk/emoticon/et${msgInfo.emoticSeq.toString().padStart(2, "0")}.png`} alt="" />
                }
            </div>
        </div>
    );
}

export default ReceiveMessage;