import React, { Component } from "react";
import { connect } from "react-redux";

import ConstData from "../../lib/ConstData";

import FindIdPasswordWindow from "../../components/user/modal/FindIdPasswordWindow";
import FindOption from "../../components/user/find/FindOption";
// import ChoiceFindInfo from "../../components/user/find/ChoiceFindInfo";
// import FindId from "../../components/user/find/FindId";
// import ShowIdList from "../../components/user/find/ShowIdList";
// import FindPW from "../../components/user/find/FindPW";
// import FindPWCertifyPhone from "../../components/user/find/FindPWCertifyPhone";
// import ResetPassword from "../../components/user/find/ResetPassword";
// import ShowResetPassword from "../../components/user/find/ShowResetPassword";

import {
    toggle_findIdPasswordModal,
    changeSelectFindIdPasswordOption,
    changeSelectFindIdPasswordStep,
    phoneCheckSend,
    phoneCheckConfirm,
    falseToPhoneCheckOk,
    falseToPhoneCheckSend,
    emailCheckSend,
    emailCheckConfirm,
    falseToEmailCheckOk,
    falseToEmailCheckSend,
    getIdList,
    compareInfo,
    checkExistUserId,
    resetPassword,
    userInfoToggle,
    setMessageString
} from "../../modules/user";

class FindMemberIdPasswordContainer extends Component {

    handleSelectedFunc = ({ selectedFunc, message, type, phoneNumber, userEmail, userId, password, code, step }) => {
        switch (selectedFunc) {
            case "LOGIN":
                this.props.toggle_findIdPasswordModal();
                break;

            case "REGIST_TOGGLE":
                this.props.userInfoToggle({ kind: "regist" });
                break;

            case "FIND":
                console.log("type : ", type);

                if (type === "ID") {
                    this.props.changeSelectFindIdPasswordOption({
                        option: ConstData.FIND_OPTION.ID
                    });
                } else if (type === "PW") {
                    this.props.changeSelectFindIdPasswordOption({
                        option: ConstData.FIND_OPTION.PW
                    });
                }
                break;

            case "CHECK_ID":
                this.props.checkExistUserId({
                    userID: userId,
                    userKind: this.props.userKind
                });
                break;

            case "FALSE_TO_PHONE_CHECK_SEND":
                this.props.falseToPhoneCheckSend({ message });
                break;

            case "FLASE_TO_PHONE_CHECK_OK":
                this.props.falseToPhoneCheckOk({ message });
                break;

            case "GET_CERTI":
                this.props.phoneCheckSend({
                    isFindIdPassword: true,
                    phoneNumber
                });
                break;

            case "CONFIRM_CERTI":
                console.log("certi code : ", code);
                this.props.phoneCheckConfirm({
                    isFindIdPassword: true,
                    certiCodeSeq: this.props.certiCodeSeq,
                    confirmNumber: code
                });
                break;

            case "EMAIL_CHECK":
                this.props.emailCheckSend({
                    isFindIdPassword: true,
                    userEmail
                });
                break;

            case "EMAIL_CHECK_CONFIRM":
                this.props.emailCheckConfirm({
                    isFindIdPassword: true,
                    certiCodeSeq: this.props.certiCodeSeq,
                    confirmNumber: code
                });
                break;

            case "FLASE_TO_EMAIL_CHECK_OK":
                this.props.falseToEmailCheckOk({ message });
                break;

            case "FALSE_TO_EMAIL_CHECK_SEND":
                this.props.falseToEmailCheckSend({ message });
                break;

            case "RESET_PW":
                this.props.compareInfo({
                    userID: this.props.findIdPasswordID,
                    userPN: phoneNumber,
                    userKind: this.props.userKind
                });
                break;

            case "RESET":
                this.props.resetPassword({
                    userID: this.props.findIdPasswordID,
                    userPW: password
                });
                break;

            case "GET_ID_LIST":
                this.props.getIdList({
                    userPN: phoneNumber,
                    userKind: this.props.userKind
                });
                break;

            case "PRE":
                this.props.changeSelectFindIdPasswordStep({ step });
                break;

            case "CLOSE":
                this.props.toggle_findIdPasswordModal();
                break;

            case "MESSAGE":
                this.props.setMessageString(message);
                break;

            default:
                break;
        }
    }

    render() {
        /* return (
            <FindIdPasswordWindow
                isFindIdPasswordOpened={this.props.isFindIdPasswordOpened}
                findIdPasswordOption={this.props.findIdPasswordOption}
                findIdPasswordStep={this.props.findIdPasswordStep}
                handleSelectedFunc={this.handleSelectedFunc}
            >
                {
                    this.props.findIdPasswordOption === ConstData.FIND_OPTION.START &&
                    <ChoiceFindInfo handleSelectedFunc={this.handleSelectedFunc} />
                }
                {
                    this.props.findIdPasswordOption === ConstData.FIND_OPTION.ID &&
                    <>
                        {
                            this.props.findIdPasswordStep === ConstData.FIND_STEP.CERTIFY_PHONE ?
                                <FindId
                                    isPhoneTestStart={this.props.isPhoneTestStart}
                                    isPhoneTestOK={this.props.isPhoneTestOK}
                                    alertKind={this.props.alertKind}
                                    alertMessage={this.props.alertMessage}
                                    handleSelectedFunc={this.handleSelectedFunc}
                                /> :
                            this.props.findIdPasswordStep === ConstData.FIND_STEP.SHOW_ID ?
                                <ShowIdList
                                    isExistUserInfo={this.props.isExistUserInfo}
                                    list_findUserId={this.props.list_findUserId}
                                    handleSelectedFunc={this.handleSelectedFunc}
                                /> :
                                <></>
                        }
                    </>
                }
                {
                    this.props.findIdPasswordOption === ConstData.FIND_OPTION.PW &&
                    <>
                        {
                            this.props.findIdPasswordStep === ConstData.FIND_STEP.CHECK_ID ?
                                <FindPW
                                    handleSelectedFunc={this.handleSelectedFunc}
                                /> :
                            this.props.findIdPasswordStep === ConstData.FIND_STEP.CERTIFY_PHONE ?
                                <FindPWCertifyPhone
                                    isExistUserInfo={this.props.isExistUserInfo}
                                    isPhoneTestStart={this.props.isPhoneTestStart}
                                    isPhoneTestOK={this.props.isPhoneTestOK}
                                    alertKind={this.props.alertKind}
                                    alertMessage={this.props.alertMessage}
                                    handleSelectedFunc={this.handleSelectedFunc}
                                /> :
                            this.props.findIdPasswordStep === ConstData.FIND_STEP.RESET_PW ?
                                <ResetPassword
                                    isExistUserInfo={this.props.isExistUserInfo}
                                    handleSelectedFunc={this.handleSelectedFunc}
                                /> :
                            this.props.findIdPasswordStep === ConstData.FIND_STEP.END ?
                                <ShowResetPassword
                                    isExistUserInfo={this.props.isExistUserInfo}
                                    handleSelectedFunc={this.handleSelectedFunc}
                                /> :
                                <></>
                        }
                    </>
                }
            </FindIdPasswordWindow>
        ); */

        return (
            <FindIdPasswordWindow
                isFindIdPasswordOpened={this.props.isFindIdPasswordOpened}
                findIdPasswordOption={this.props.findIdPasswordOption}
                findIdPasswordStep={this.props.findIdPasswordStep}
                handleSelectedFunc={this.handleSelectedFunc}
            >
                <FindOption
                    findIdPasswordOption={this.props.findIdPasswordOption}
                    findIdPasswordStep={this.props.findIdPasswordStep}
                    isExistUserInfo={this.props.isExistUserInfo}
                    isPhoneTestStart={this.props.isPhoneTestStart}
                    isPhoneTestOK={this.props.isPhoneTestOK}
                    list_findUserId={this.props.list_findUserId}
                    alertKind={this.props.alertKind}
                    alertMessage={this.props.alertMessage}
                    handleSelectedFunc={this.handleSelectedFunc}
                />
            </FindIdPasswordWindow>
        );
    }
}

export default connect(
    ({ user }) => ({
        isExistUserInfo         : user.isExistUserInfo,

        findIdPasswordOption    : user.findIdPasswordOption,
        findIdPasswordStep      : user.findIdPasswordStep,
        findIdPasswordID        : user.findIdPasswordID,

        certiCodeSeq            : user.certiCodeSeq,
        isPhoneTestStart        : user.isPhoneTestStart,
        isPhoneTestOK           : user.isPhoneTestOK,

        alertKind               : user.userInfoAlertKind,
        alertMessage            : user.userInfoMessage,

        list_findUserId         : user.list_findUserId,
        userKind                : user.userKind,
        emailCertiCodeSeq       : user.emailCertiCodeSeq,

        isEmailTestStart        : user.isEmailTestStart,
        isEmailTeskOK           : user.isEmailTeskOK
    }),
    {
        getIdList,
        compareInfo,
        resetPassword,
        checkExistUserId,
        phoneCheckSend,
        phoneCheckConfirm,
        emailCheckSend,
        emailCheckConfirm,
        falseToPhoneCheckOk,
        falseToPhoneCheckSend,
        falseToEmailCheckOk,
        falseToEmailCheckSend,
        userInfoToggle,
        toggle_findIdPasswordModal,
        changeSelectFindIdPasswordOption,
        changeSelectFindIdPasswordStep,
        setMessageString
    }
) (FindMemberIdPasswordContainer);