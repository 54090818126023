import React from 'react';
import { FaRegGrinBeamSweat } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const NotFound = () => {

    return(
        <div className="d-flex flex-column align-items-center w-100 py-5">
            <FaRegGrinBeamSweat className="font-xxxlg hiclasstv-blue-text" />
            <span className="font-xxlg oops hiclasstv-blue-text">Oops!</span>
            <div className="d-flex flex-column align-items-center justify-content-center hiclasstv-blue white-text w-100 p-4">
                <span className="font-xxlg">Sorry, Page Not Found</span>
                <span className="font-xlg mb-3">This page you requested could not be found</span>
                <button className="btn rounded white">
                    <Link to="/main">
                        HOME
                    </Link>
                </button>
            </div>
        </div>
    );
}

export default NotFound;