import React from 'react';

import WebUserStatus from '../../etc/list/WebUserStatus';

const WebCreateSubDomainInfo = ({ isStandardType, subDomain_info, goToPassListPage }) => {
    return (
        <tbody>
            <tr className="d-flex">
                <td className="d-flex align-items-center justify-content-center area-35">
                    <div className="text-area" style={{ textAlign: 'center' }}>
                        <span className="info-title hiclass-text" title={subDomain_info.subDomainName}>{subDomain_info.subDomainName}</span>
                    </div>
                </td>
                <td className="landscape-to-portrait align-items-center justify-content-center small area-25">
                    <WebUserStatus kind={'userStatus'} memberCnt={subDomain_info.memberCnt} />
                </td>
                <td className="landscape-to-portrait small area-25">
                    <div className="list-row area-50">
                        <span className="only-mobile">T :&nbsp;</span>{subDomain_info.chatRoomCnt}
                    </div>
                    <div className="list-row area-50">
                        <span className="only-mobile">L :&nbsp;</span>
                        {
                            subDomain_info.liveOnCnt > 0 ?
                                <span className="green-text font-weight-bold">{subDomain_info.liveOnCnt}</span> :
                                <span>{subDomain_info.liveOnCnt}</span>
                        }
                        <span>&nbsp;{' / ' + subDomain_info.liveCnt}</span>
                    </div>
                </td>
                <td className="d-flex align-items-center justify-content-center area-15">
                    {
                        isStandardType &&
                        <button type="button" className="btn btn-sm btn-outline-primary px-2 py-1 m-0" onClick={() => goToPassListPage({ subDomainSeq: subDomain_info.subDomainSeq })}>
                            조회
                        </button>
                    }
                </td>
            </tr>
        </tbody>
    );
}

export default WebCreateSubDomainInfo;