import React, { forwardRef } from 'react';
import { FaRegCalendarAlt } from 'react-icons/fa';

import { format } from 'date-fns';

const CustomDatePicker = forwardRef(({ dateInfo, onClick, pickerID }, ref) => {

    return (
        <div className="datepicker" inline="true">
            <input type="text" className="datepicker-input" readOnly="readonly" id={pickerID} ref={ref} autoComplete='off' placeholder={format(dateInfo, 'yyyy. MM. dd')} onClick={onClick} />
            <label htmlFor="pickerDT"></label>
            <span className="datepicker-icon" tabIndex="0">
                <FaRegCalendarAlt className="font-md blue-text" />
            </span>
        </div>
    );
});
export default CustomDatePicker;