import React, { useState } from 'react';
import ConstData from '../../../lib/ConstData';
import Alerts from '../../etc/list/Alerts';

const ResetPassword = ({ handleSelectedFunc, isExistUserInfo }) => {

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [alertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [alertMessage, setAlertMessage] = useState("");

    const onChangePassword = (e) => {
        let isChecked = checkPasswordValue(e.target.value);
        
        if (!isChecked) {
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("비밀번호는 영문자, 숫자, 특수문자 조합을 입력해야 합니다.");
        } else {
            setAlertKind(ConstData.ALERT_KIND.SUCCESS);
            setAlertMessage("사용 가능한 비밀번호입니다.");
        }
        setPassword(e.target.value);
    }

    const onChangePasswordForConfirm = (e) => {
        let isChecked = checkPasswordValue(e.target.value);

        if (password !== e.target.value) {
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("비밀번호가 일치하지 않습니다.");
        } else {
            if (!isChecked) {
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage("비밀번호는 영문자, 숫자, 특수문자 조합을 입력해야 합니다.");
            } else {
                setAlertKind(ConstData.ALERT_KIND.NONE);
                setAlertMessage("");
            }
        }
        setConfirmPassword(e.target.value);
    }

    const checkPasswordValue = (str) => {
        let number_pattern = /[0-9]/;				        // 숫자
        let alpha_pattern = /[a-zA-Z]/;			            // 문자
        let special_pattern = /[~!@#$%^&*()_+|<>?:{}]/;     // 특수문자

        if (!number_pattern.test(str) || !alpha_pattern.test(str) || !special_pattern.test(str)) {
            return false;
        } else {
            return true;
        }
    }

    const handleCheckPassword = (selectedFunc) => {
        if(password !== confirmPassword) {
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("비밀번호를 확인해주세요");
        } else {
            handleSelectedFunc({selectedFunc, password});
        }
    }

    return (
        <div className="d-flex flex-column align-items-center my-2 w-100">
            <div className="d-flex flex-column font-md py-4 px-2 w-100">
                {
                    isExistUserInfo ? 
                        <>
                            <div className="input-area">
                                <div className="d-flex justify-content-center area-60">
                                    <span>비밀번호</span>
                                </div>
                                <div className="input-data">
                                    <input type="password" id="resetPw" className="d-flex flex-fill web-search-input" placeholder="비밀번호를 입력하세요" onChange={onChangePassword} value={password} />
                                </div>
                            </div>
                            <div className="input-area">
                                <div className="d-flex justify-content-center area-60">
                                    <span>비밀번호 확인</span>
                                </div>
                                <div className="input-data">
                                    <input type="password" id="resetPwCnfn" className="d-flex flex-fill web-search-input" placeholder="비밀번호를 한번 더 입력하세요" onChange={onChangePasswordForConfirm} value={confirmPassword} />
                                </div>
                            </div>
                            <Alerts kind={alertKind} message={alertMessage} />
                            {/* 위에 거에 스타일 적용해야할지 확인해보기 m-0 mt-2 */}
                        </> : 
                            <span className="small">
                                고객님의 정보와 일치하는 회원정보가 없습니다.
                            </span>
                }
            </div>
            <div className="d-flex w-100 justify-content-center">
                {
                    isExistUserInfo && 
                        <button className="btn btn-primary btn-sm" onClick={() => handleCheckPassword("RESET")} disabled={password !== confirmPassword || (password === "" || confirmPassword === "") ? true : false}>재설정</button>
                }
                <button className="btn btn-sm hiclasstv-grey" onClick={() => handleSelectedFunc({selectedFunc:"CLOSE"})}>취소</button>
            </div>
        </div>
    );
}

export default ResetPassword;