/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';

const QnA008 = ({ isOpened, onChangeOpenedFlag }) => {
    useEffect(() => {
        let content = document.getElementById("qna08");

        if (isOpened) {
            content.style.maxHeight = content.scrollHeight + "px";
        } else {
            content.style.maxHeight = null;
        }
    }, [isOpened]);

    return (
        <div className="accordion">
            <div className="accordion-title" onClick={() => onChangeOpenedFlag(7)}>
                <span className="hiclasstv-deepDarkgrey-text button">
                    <b>8. 학습 과정을 녹화하여 활용할 수 있나요?</b>
                </span>
            </div>
            <div className="accordion-content hiclasstv-darkgrey-text" id="qna08">
                <span className="d-flex" style={{ minWidth: 'max-content' }}>A.&nbsp;</span>
                <p>
                    Pencam을 통해서 현재 진행하고 있는 학습 내용을 실시간으로 녹화할 수 있습니다.<br />
                    학습 콘텐츠 및 판서, 음성 등이 녹화되어 재사용할 수 있습니다.<br />
                </p>
            </div>
        </div>
    );
}
export default QnA008;