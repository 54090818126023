import React, { useState, useEffect } from 'react';
import { MDBInput } from 'mdbreact';

import ConstData from '../../lib/ConstData';
import Alerts from '../etc/list/Alerts';

const UserLoginAtCloud = ({ handleSelectedFunc, loginMessage, alertKind, savedLocalServerSeq, getCookiesInfoHandle }) => {

    const [userID, setUserID]       = useState("");
    const [password, setPassword]   = useState("");
    const [autoLogin, setAutoLogin] = useState(false);
    const [errorMsg, setErrorMsg]   = useState(loginMessage);
    const [errorKind, setErrorKind] = useState(alertKind);

    useEffect(() => {
        setErrorMsg(loginMessage);
    }, [loginMessage]);

    useEffect(() => {
        setErrorKind(alertKind);
    }, [alertKind]);

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            bt_handleLogin();
        }
    }

    const onChangeUserId = (e) => {
        //let convertStr = e.target.value.replace(/[^a-z0-9_]/gi, '');
        setUserID(e.target.value);
        handleSelectedFunc({selectedFunc:"L_MESSAGE", message:"", kind:"none"});
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value);
        handleSelectedFunc({selectedFunc:"L_MESSAGE", message:"", kind:"none"});
    }
    
    const onChangeAutoLogin = (e) => {
        setAutoLogin(e.target.checked);
    }

    const bt_handleLogin = () => {
        if (userID === "") {
            handleSelectedFunc({selectedFunc:"L_MESSAGE", message:"사용자 ID를 입력하세요", kind:"danger"});
        } else {
            if (password === "") {
                handleSelectedFunc({selectedFunc:"L_MESSAGE", message:"비밀번호를 입력하세요!", kind:"danger"});
            } else {
                handleSelectedFunc({selectedFunc:"L_MESSAGE", message:"", kind:"none"});
                handleSelectedFunc({ selectedFunc:"LOGIN", 
                                     loginInfo:({ userID, password, isAutoLogin:autoLogin }),
                                     mode:"hiclass"
                });
            }
        }
    }

    return (
        <div className="d-flex align-items-center">
            <div className="card-body">
                <div className="card-text">
                    <div className='d-flex justify-content-end'>
                        <span onClick={() => handleSelectedFunc({ selectedFunc: "SETTING_LOCAL_INFO_TOGGLE"})}><u>로컬 정보 설정</u></span>
                    </div>
                    {/*
                    <div className="custom-control custom-checkbox text-right">
                        <div>
                            <input type="checkbox" className="custom-control-input" id="defaultCheck" checked={autoLogin} onChange={onChangeAutoLogin} />
                            <label className="custom-control-label" htmlFor="defaultCheck">Auto Login</label>
                        </div>
                    </div>
                    */}
                    <MDBInput label="your ID" id="inputID" placeholder="Your ID" onChange={onChangeUserId} value={userID} />
                    <MDBInput label="your Password" type="password" id="inputPw"  placeholder="Your password" onChange={onChangePassword} value={password} onKeyPress={handleKeyPress} />
                    {/* {
                        errorMsg && errorKind === ConstData.ALERT_KIND.DANGER &&
                        <div className="alert-area alert-danger w-100 mb-3">{errorMsg}</div>
                    } {
                        errorMsg && errorKind === ConstData.ALERT_KIND.SUCCESS &&
                        <div className="alert-area alert-success w-100 mb-3">{errorMsg}</div>
                    } */}
                    <Alerts kind={errorKind} message={errorMsg} style={{ width: '100%', marginBottom: '1rem' }} />
                    <button className="btn btn-primary btn-md btn-block px-4 mb-3" onClick={() => bt_handleLogin()}>
                        Login
                    </button>
                </div>
                <div className="d-flex flex-column align-items-end w-100">
                    {
                        //hiclass에서 가입을 받지않기때문에 주석 처리함 by hjkim 200824
                        //<p>
                        //    Not a member?
                        //    <a onClick={() => handleSelectedFunc({ selectedFunc: "REGIST_TOGGLE" })}> <u>Sign Up</u></a>
                        //</p>
                    }
                    <div>
                        <span onClick={() => handleSelectedFunc({ selectedFunc: "GO_TO_LOCAL"})} disabled={savedLocalServerSeq === -1 ? true : false}>local로 이동</span>
                    </div>
                    <div>
                        Forgot
                        <span className="font-weight-bold" onClick={() => handleSelectedFunc({ selectedFunc: "FIND_TOGGLE", mode: "web" })}> <u>ID/Password?</u></span>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UserLoginAtCloud;