import React from "react";

import { format } from "date-fns";

import WebUserStatusButton from "../button/WebUserStatusButton";
import WebUserResetPWButton from "../button/WebUserResetPWButton";
import WebCreateAdminGrantButton from "../button/WebCreateAdminGrantButton";
import WebConfigAdminGrantButton from "../button/WebConfigAdminGrantButton";

const WebConfigMemberInfo = ({ userKind, subDomain_info, member_info, toggleModalOpened, togglePWModalOpened, toggleCreateSubDomainAdminModalOpened, toggleSubDomainAdminModalOpened }) => {
    return (
        <tr className="d-flex">
            <td className="d-flex align-items-center justify-content-center area-40">
                <div className="landscape-to-portrait mr-1 area-20 fit-content">
                    {
                        userKind === "hiclasstv" && 
                        <WebCreateAdminGrantButton 
                            subDomainSeq={subDomain_info.subDomainSeq}
                            subDomainName={subDomain_info.subDomainName}
                            member_info={member_info}
                            toggleModalOpened={toggleCreateSubDomainAdminModalOpened}
                        />
                    }
                    <WebConfigAdminGrantButton 
                        subDomainSeq={subDomain_info.subDomainSeq}
                        subDomainName={subDomain_info.subDomainName}
                        member_info={member_info}
                        toggleModalOpened={toggleSubDomainAdminModalOpened}
                    />
                </div>
                <div className="d-flex full">
                    <div className="text-area admin">
                        <span title={member_info.userNickname} className="hiclass-text">{member_info.userNickname}</span>
                    </div>
                </div>
            </td>
            <td className="landscape-to-portrait align-items-center justify-content-center area-25 user-status">
                <WebUserStatusButton 
                    subDomainSeq={subDomain_info.subDomainSeq}
                    member_info={member_info}
                    toggleModalOpened={toggleModalOpened}
                />
                <WebUserResetPWButton
                    subDomainSeq={subDomain_info.subDomainSeq}
                    member_info={member_info}
                    toggleModalOpened={togglePWModalOpened}
                />
            </td>
            <td className="landscape-to-portrait align-items-center justify-content-center user-status small-status px-0 area-35">
                <div className="list-row justify-content-center align-items-center px-1 area-50">
                    <span>{member_info.userLoginDT}</span>
                </div>
                <div className="list-row justify-content-center align-items-center px-1 area-50">
                    <span>{member_info.userSignUpDT}</span>
                </div>
            </td>
        </tr>
    );
}

export default WebConfigMemberInfo;