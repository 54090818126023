import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import WebMyLectureList from '../../components/lecture/WebMyLectureList';

import { toggle_classTalk } from '../../modules/classTalkLive';
import { selectLecture, selectLectureWithAutoLogin, toggleIsNeedGoToHome } from '../../modules/lecture';
import WebMyReviewLectureList from '../../components/lecture/WebMyReviewLectureList';

class WebLectureContainer extends Component {

    shouldComponentUpdate(nextProps) { // 기존과 props가 달라졌을 때만 update되도록! by hjkim 20210402
        return (JSON.stringify(nextProps) !== JSON.stringify(this.props));
    }

    componentDidUpdate(prevProps) {
        //console.log(`WebLectureContainer-componentDidUpdate`);
        if (prevProps.isNeedGoToHome !== this.props.isNeedGoToHome) {
            if (this.props.isNeedGoToHome) {
                const { routeProps } = this.props;

                if (routeProps.history !== undefined) {
                    routeProps.history.push('/main');
                    this.props.toggleIsNeedGoToHome(false);
                }
            }
        }

        if (this.props.routeProps) {
            const { routeProps } = this.props;

            if (routeProps.location !== undefined) {
                if (routeProps.location.search && routeProps.location.search !== '') {
                    const query = queryString.parse(routeProps.location.search);

                    if (query && query.lectureCode && query.lectureCode !== '') {
                        const { list_lecture, selectLectureWithAutoLogin, toggle_classTalk } = this.props;
                        const lecture_info = list_lecture.find(info => info.lectureCode === query.lectureCode);

                        if (lecture_info !== undefined && lecture_info !== null) {
                            routeProps.history.push('/live');
                            toggle_classTalk({ visibility: 'visible' });
                            selectLectureWithAutoLogin({ lectureCode: query.lectureCode, lectureKind: lecture_info.lectureKind });
                        } else {    // 들을 수 없는 강의 코드인 경우... modal 창 by hjkim 20210405
                            console.log('could not find lecture in list_lecture');
                            this.props.toggleIsNeedGoToHome(true);
                        }
                    }
                }
            }
        }
    }

    handleSelectedFunc = ({ selectedFunc, lectureCode, lectureKind }) => {
        const { selectLecture } = this.props;

        switch(selectedFunc) {
            case "SELECT_LECTURE" :
                selectLecture({ lectureCode, lectureKind });
                break;

            default:
                break;
        }
    }

    render() {
        const { list_lecture, list_reviewLecture, isUsablePass, isLectureLive } = this.props;
        const { handleSelectedFunc } = this;

        return (
            <>
                <WebMyLectureList 
                    isLectureLive={isLectureLive}
                    list_lecture={list_lecture}
                    isUsablePass={isUsablePass}
                    handleSelectedFunc={handleSelectedFunc}
                />
                <WebMyReviewLectureList 
                    isUsablePass={isUsablePass}
                    list_lecture={list_reviewLecture}
                    handleSelectedFunc={handleSelectedFunc}
                />
            </>
        );
    }
}

export default connect(
    ({ user, lecture }) => ({
        isLoginYn           : user.isLoginYn,
        isUsablePass        : user.isUsablePass,

        isLectureLive       : lecture.isLectureLive,
        isNeedGoToHome      : lecture.isNeedGoToHome,

        list_lecture        : lecture.list_lecture,
        list_reviewLecture  : lecture.list_reviewLecture
    }),
    {
        selectLecture,
        selectLectureWithAutoLogin,
        toggleIsNeedGoToHome,

        toggle_classTalk
    }
)(WebLectureContainer);