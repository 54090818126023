import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { FaTicketAlt, FaInfo, FaTimes } from 'react-icons/fa';

import { prdtKind, prdtSacle, prdtTarget } from '../../../lib/ConstCommand';
import ConstData from '../../../lib/ConstData';

const getLookupResults = (list_prdtPass, kindCode, scaleCode, targetCode) => {
    console.log('getLookupResults() -> ', list_prdtPass, kindCode, scaleCode, targetCode);

    return list_prdtPass.filter(info => info.prdtKindCode === kindCode && info.prdtScaleCode === scaleCode && info.prdtTargetCode === targetCode);
}

const WebRegistPass = ({ isModalOpened, toggleModalOpened, subDomainInfo, userInfo, list_subDomainInfo, list_prdtPass, list_prdtKindCode, list_prdtScaleCode, list_prdtTargetCode, list_prdtTermCode, handleRegistData }) => {
    /**
     * subDomainInfo
     * - selectedIdx
     * - subDomainSeq
     * - subDomainName
     * 
     * registInfo       : { kindCode, scaleCode, targetCode, termCode }
     * 
     * list_prdtPass    : { prdtSeq, prdtName, prdtKindCode, prdtTargetCode, prdtScaleCode, prdtTermCode }
     */

    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [lAlertMessage, setAlertMessage] = useState("");
    const [lKindCode, setKindCode] = useState(prdtKind.Purchase);
    const [lScaleCode, setScaleCode] = useState(prdtSacle.L50.code);
    const [lTargetCode, setTargetCode] = useState(prdtTarget.B2C);
    const [selIdx, setSelIdx] = useState(0);
    const [selPrdtSeq, setSelPrdtSeq] = useState(-1);
    const [lSubDomainInfo, setSubDomainInfo] = useState(null);
    const [lUserInfo, setUserInfo] = useState(null);
    const [list_lookupPass, setListLookupPass] = useState([]);

    const [isPolicyModalOpened, setIsPolicyModalOpened] = useState(false);

    useEffect(() => {
        if (subDomainInfo !== undefined && subDomainInfo !== null) {
            if (subDomainInfo.subDomainName === 'hiclasstv') {
                setTargetCode(prdtTarget.B2C);
                setScaleCode(prdtSacle.B2C.code);
            } else {
                setTargetCode(prdtTarget.B2B);
                setScaleCode(prdtSacle.L50.code);
            }
        } else {
            setTargetCode(prdtTarget.B2C);
            setScaleCode(prdtSacle.B2C.code); 
        }
    }, [subDomainInfo]);

    useEffect(() => {
        if (userInfo !== undefined && userInfo !== null) {
            setUserInfo(userInfo);
        } else {
            setUserInfo(null);
        }
    }, [userInfo]);

    useEffect(() => {
        if (list_prdtPass !== undefined && list_prdtPass !== null) {
            const list_tmp = getLookupResults(list_prdtPass, lKindCode, lScaleCode, lTargetCode);
            console.log("list_tmp - ", list_tmp);
            setListLookupPass(list_tmp);
        } else {
            setListLookupPass([]);
        }
    }, [list_prdtPass, lTargetCode, lKindCode, lScaleCode]);

    /* useEffect(() => {
        if (list_prdtPass !== undefined && list_prdtPass !== null) {
            const list_tmp = getLookupResults(list_prdtPass, lKindCode, lScaleCode, lTargetCode);
            setListLookupPass(list_tmp);
        } else {
            setListLookupPass([]);
        }
    }, [list_prdtPass]); */

    useEffect(() => {
        setSelIdx(0);
        setAlertKind(ConstData.ALERT_KIND.NONE);
        setAlertMessage("");
    }, [list_lookupPass]);

    const onChangePrdtKind = (e) => {
        setKindCode(e.target.value);
    }

    const onChangePrdtScale = (e) => {
        setScaleCode(e.target.value);
    }

    const onChangePrdtTerm = (e) => {
        let idx = e.target.selectedIndex;
        setSelIdx(idx);
    }

    const onChangeSelectSubDomainName = (e) => {
        console.log('selectSubDomainName() - value => ', e.target.value);
        console.log('selectSubDomainName() - index => ', e.target.selectedIndex);
    }

    const onChangeSearchValue = () => {

    }

    const checkSearchValue = () => {

    }

    const handleKeyPress = () => {

    }

    

    const onClickRegistPass = (e) => {
        if (lTargetCode === prdtTarget.B2C) {
            console.log('onClickRegistPass(B2C) subDomainInfo - ', subDomainInfo);
            if (list_lookupPass.length > selIdx) {
                const passInfo = list_lookupPass.find((info, index) => index === selIdx);
                if (passInfo !== undefined && passInfo !== null) {
                    let seq = passInfo.prdtSeq;

                    if (lUserInfo !== undefined && lUserInfo !== null) {
                        setIsPolicyModalOpened(false);
                        handleRegistData({ kind:'B2C', prdtSeq:seq, userInfo:lUserInfo, prdtInfo:passInfo });
                    } else {
                        console.log('이런 경우가 있나...?  lUserInfo is ', lUserInfo);
                    }
                } else {
                    console.log('존재하지 않는 passInfo....');
                    setAlertKind(ConstData.ALERT_KIND.DANGER);
                    setAlertMessage('이용권을 선택해 주세요.');
                }
            } else {
                console.log('존재하지 않는 selIdx....');
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage('이용권을 선택해 주세요.');
            }
        } else {
            console.log('onClickRegistPass(B2B) subDomainInfo - ', subDomainInfo);
            if (list_lookupPass.length > selIdx) {
                const passInfo = list_lookupPass.find((info, index) => index === selIdx);
                if (passInfo !== undefined && passInfo !== null) {
                    let seq = passInfo.prdtSeq;

                    if (subDomainInfo !== undefined && subDomainInfo !== null) {
                        setIsPolicyModalOpened(false);
                        handleRegistData({ kind:'B2B', prdtSeq:seq, subDomainInfo:subDomainInfo, prdtInfo:passInfo });
                    }
                }
            } else {
                console.log('존재하지 않는 selIdx....');
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage('이용권을 선택해 주세요.');
            }
        }
    }

    const togglePolicyModalOpened = (isOpened) => {
        setIsPolicyModalOpened(isOpened);
    }

    return (
        <Modal isOpen={isModalOpened} centered size='lg' backdrop={true} toggle={() => toggleModalOpened(false)}>
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <span className="d-flex white-text mx-1" style={{height:'max-content'}}>
                        <FaTicketAlt className="font-lg mr-2" aria-hidden="true" />이용권 등록
                    </span>
                    <div>
                        <button type="button" className="d-flex btn btn-sm round red m-2 p-1" title="환불 정책 안내" onClick={() => togglePolicyModalOpened(!isPolicyModalOpened)}>
                            <FaInfo className="font-md white-text" aria-hidden="true" />
                        </button>
                    </div>
                </div>
                <div className="px-2">
                    <div className="d-flex flex-column w-100">
                        <div className="d-flex justify-content-between p-2 my-2 w-100">
                            <div className="d-flex align-items-center px-1" style={{ width: '20%', minWidth: 'max-content', maxWidth: '20%' }}>
                                <span className="d-flex my-1"><b>서브 도메인</b></span>
                            </div>
                            <div className="d-flex px-1" style={{ width: '80%', maxWidth: '80%' }}>
                                <select className="custom-select small my-1" style={{ width: '50%', fontSize: '0.9rem' }} value={subDomainInfo !== null ? subDomainInfo.subDomainSeq : -1} disabled onChange={onChangeSelectSubDomainName}>
                                    {
                                        subDomainInfo !== null && list_subDomainInfo !== undefined && list_subDomainInfo !== null && list_subDomainInfo.length > 0 ?
                                            list_subDomainInfo.map(
                                                (info, index) => (
                                                    index === subDomainInfo.selectedIdx ? 
                                                        <option value={info.subDomainSeq} key={"sv-modal-" + index} defaultValue>{info.subDomainName}</option> : 
                                                        <option value={info.subDomainSeq} key={"sv-modal-" + index}>{info.subDomainName}</option>
                                                )
                                            ) :
                                            <option value={-1}>hiclasstv</option>
                                    }
                                </select>
                            </div>
                        </div>
                        {
                            lTargetCode === prdtTarget.B2C ?
                                <div className="d-flex justify-content-between p-2 my-2 w-100">
                                    <div className="d-flex px-1" style={{ width: '20%', minWidth: 'max-content', maxWidth: '20%' }}>
                                        <span className="d-flex my-1"><b>사용자 ID</b></span>
                                    </div>
                                    <div className="d-flex px-1 input-group" style={{ width: '80%', maxWidth: '80%' }}>
                                        <input type="text" className="d-flex flex-fill web-search-input px-2 py-1 white" placeholder={lUserInfo !== null ? lUserInfo.userNickname : "사용자ID를 입력하세요"} onChange={onChangeSearchValue} onKeyPress={handleKeyPress} />
                                    </div>
                                </div> :
                                <div className="d-flex justify-content-between p-2 my-2 w-100">
                                    <div className="d-flex px-1" style={{ width: '20%', minWidth: 'max-content', maxWidth: '20%' }}>
                                        <span className="d-flex my-1"><b>사용자 규모</b></span>
                                    </div>
                                    <div className="d-flex px-1" style={{ width: '80%', maxWidth: '80%' }}>
                                        {
                                            list_prdtScaleCode !== undefined && list_prdtScaleCode !== null && list_prdtScaleCode.length > 0 ?
                                                list_prdtScaleCode.map(
                                                    (info, index) => (
                                                        <div className="d-flex align-items-center w-100" key={"scale-" + index}>
                                                            <input className="d-flex mr-1 p-0" type="radio" name="groupOfPassScale" id={"passScaleRadio_" + index} value={info.scaleCode} defaultChecked={index === 0} onChange={onChangePrdtScale} />
                                                            <span>{info.scaleName}</span>
                                                        </div>
                                                    )
                                                ) :
                                                <div className="d-flex align-items-center w-100">
                                                    <span>선택 가능한 항목이 없습니다.</span>
                                                </div>
                                        }
                                    </div>
                                </div>
                        }
                        <div className="d-flex justify-content-between p-2 my-2 w-100">
                            <div className="d-flex px-1" style={{ width: '20%', minWidth: 'max-content', maxWidth: '20%' }}>
                                <span className="d-flex my-1"><b>이용권 종류</b></span>
                            </div>
                            <div className="d-flex px-1 flex-column" style={{ width: '80%', maxWidth: '90%' }}>
                                <div className="d-flex justify-content-between mb-2">
                                    {
                                        list_prdtKindCode !== undefined && list_prdtKindCode !== null && list_prdtKindCode.length > 0 ?
                                            list_prdtKindCode.map(
                                                (info, index) => (
                                                    <div className="d-flex align-items-center w-100" key={"kind-" + index}>
                                                        <input className="d-flex mr-1 p-0" type="radio" name="groupOfPassKind" id={"passKindRadio_" + index} value={info.kindCode} defaultChecked={index === 0} onChange={onChangePrdtKind} />
                                                        <span>{info.kindName + " 등록"}</span>
                                                    </div>
                                                )
                                            ) :
                                            <div className="d-flex align-items-center w-100">
                                                <span>선택 가능한 항목이 없습니다.</span>
                                            </div>
                                    }
                                </div>
                                <div className="d-flex justify-content-between my-1">
                                    <select className="custom-select small" style={{ width: '50%', minWidth:'max-content', maxWidth: '50%', fontSize: '0.9rem' }} value={list_lookupPass.length > 0 ? list_lookupPass[selIdx].prdtSeq : -1} onChange={onChangePrdtTerm}>
                                        {
                                            list_lookupPass.length > 0 ?
                                                list_lookupPass.map(
                                                    (info, index) => (
                                                        <option value={info.prdtSeq} key={"pass-" + index}>{info.prdtName}</option>
                                                    )
                                                ) :
                                                <option value={-1}>선택 가능한 이용권이 없습니다.</option>
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center p-2 my-2 w-100">
                            {
                                lAlertKind === ConstData.ALERT_KIND.DANGER &&
                                <div className="alert alert-danger" role="alert">{lAlertMessage}</div>
                            } {
                                lAlertKind === ConstData.ALERT_KIND.SUCCESS &&
                                <div className="alert alert-success" role="alert">{lAlertMessage}</div>
                            }
                        </div>
                        <div className="d-flex justify-content-center p-2 my-2 w-100">
                            <button className="btn btn-sm btn-primary" type="button" onClick={onClickRegistPass}>
                                등록
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                isPolicyModalOpened &&
                <Modal isOpen={isModalOpened} size='lg' centered backdrop={true} toggle={() => togglePolicyModalOpened(false)}>
                    <div className="card p-2 align-items-center justify-content-center">
                        <div className="title-area justify-content-end mb-3">
                            <button className="hiclasstv-btn p-0" title="닫기" onClick={() => togglePolicyModalOpened(false)}>
                                <FaTimes className="d-flex m-1 black-text" />
                            </button>
                        </div>
                        <h3>
                            이용권 환불 정책
                        </h3>
                        <div className="d-flex w-100 px-2">
                            <div className="web-policy-box w-100 p-2 my-4 scroll-show">
                                <span>
                                    <p className="last-p">
                                        <b>HiClass.TV 서비스 이용권 환불 정책은 아래 내용을 따릅니다.</b>
                                    </p>
                                    <h3>환불액</h3>
                                    <table className="table table-sm text-center">
                                        <thead className="hiclasstv-grey">
                                            <tr>
                                                <td className="align-middle" rowSpan="2">이용권 사용 이전</td>
                                                <td colSpan="2">이용권 사용 이후</td>
                                            </tr>
                                            <tr>
                                                <td>이용 기간 만료 15일 전</td>
                                                <td>잔여 이용일수 15일 초과하는 경우</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span>전액 환불</span>
                                                </td>
                                                <td>
                                                    <span>환불 불가</span>
                                                </td>
                                                <td>
                                                    <span>결제금액-결제금액X(이용일수/전체이용일수)-수수료(결제금액의 10%)</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </Modal>
    );
}
export default WebRegistPass;