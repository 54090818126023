import React from 'react';
import { FaTimes } from 'react-icons/fa';

const AlertModal = ({ modalType, modalTargetUserNickname, handleSelectedFunc }) => {
    
    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <button className="hiclasstv-btn b-none p-0"  onClick={() => handleSelectedFunc({selectedFunc:modalType})}>
                    <FaTimes className="d-flex m-1 black-text" />
                </button>
            </div>
            <div className="content-area dark-grey-text">
                {
                    modalType === "MEMBER_DELETE_ALERT" ? 
                        <div className="text-area text-center">
                            <span>
                                <span className="font-weight-bold">{modalTargetUserNickname}</span>
                                님은 팀업에 참여 중인 멤버입니다.<br />
                                참여 중인 멤버는 삭제할 수 없습니다.
                            </span>
                        </div> : 
                    modalType === "MEMBER_INVITE_ALERT" ? 
                        <div className="text-area text-center">
                            <span>
                                <span className="font-weight-bold">Multi 타입</span>이 아닌 팀업에서는<br />
                                새로운 멤버를 초대할 수 없습니다.
                            </span>
                        </div> : 
                    modalType === "ALONE_TALK_ALERT" ?
                        <div className="text-area text-center">
                            <span>
                                나와의 채팅에서는<br />
                                보이스 톡 / 라이브 톡 기능을<br />
                                지원하지 않습니다.
                            </span>
                        </div> : 
                        <></>
                }
            </div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small green white-text m-0 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc:modalType})}>확인</button>
            </div>
        </div>
    );
}

export default AlertModal;