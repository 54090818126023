import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { enInfoTap } from "../../lib/ConstCommand";

import WebPromotionList from "../../components/promotion/WebPromotionList";
import WebPromotionListForAdmin from "../../components/promotion/WebPromotionListForAdmin";
import WebPromotionInfo from "../../components/promotion/WebPromotionInfo";
import WebPromotionInfoModal from "../../components/promotion/modal/WebPromotionInfoModal";

import {
    logInToggle
} from "../../modules/user";

import {
    getPromotionList,
    getPromotionRegistInfo,
    getPromotionRequestInfo,
    setPromotionRegistInfoClear,
    registPromotionRequest,
    registPromotionCouponCode,
    publishPromotionCouponCode,
    togglePromotionNotiModalOpened,
    togglePromotionNotiModalClosed
} from "../../modules/promotion";

class WebPromotionContainer extends Component {

    componentDidMount = () => {
        console.log("componentDidMount() - WebPromotionContainer", this.props.userSeq, this.props.userKind);

        if (this.props.userSeq !== undefined && this.props.userSeq !== null) {
            if (this.props.userKind === "hiclasstv" && this.props.userSeq === 0) {
                this.props.getPromotionList({ kind: "initAdmin" });
            } else {
                this.props.getPromotionList({ kind: "init" });
            }
        } else if (this.props.hiclasstvInfo.currentInfoTap === enInfoTap.Event) {
            this.props.getPromotionList({ kind: "init" });
        }
    }

    handleSelectedFunc = ({ selectedFunc, info, kind, promotionSeq, requestSeq }) => {
        switch (selectedFunc) {
            // 모달 창 관련 //
            case "SHOW_LOGIN_WINDOW":
                //console.log("로그인 창 띄우기");
                this.props.handleUserLoginContainer({ selectedFunc: "SHOW_LOGIN_WINDOW" });
                break;

            case "TOGGLE_MODAL_OPENED":
                this.props.togglePromotionNotiModalOpened({ promotionSeq });
                break;

            case "TOGGLE_MODAL_CLOSED":
                this.props.togglePromotionNotiModalClosed();
                break;

            case "REGIST_PROMOTION":
                this.props.registPromotionRequest({ promotionSeq, info });
                break;

            case "REGIST_PROMOTION_COUPON_CODE":
                this.props.registPromotionCouponCode({
                    promotionSeq,
                    userSeq: this.props.userSeq,
                    kind,
                    info
                });
                break;

            case "GOTO_PROMOTION_LIST":
                this.props.setPromotionRegistInfoClear();

                if (this.props.routeProps.history) {
                    this.props.routeProps.history.push("/promotion");
                }
                break;

            case "SHOW_PROMOTION_INFO":
                this.props.getPromotionRegistInfo({ promotionSeq });

                if (this.props.routeProps.history) {
                    this.props.routeProps.history.push(`/promotion/${promotionSeq}`);
                }
                break;

            case "GET_PROMOTION_REQUEST_LIST":
                this.props.getPromotionRequestInfo({
                    promotionSeq,
                    option: {
                        term: info.term,
                        issue: info.issue,
                        item: info.item,
                        keyword: info.keyword
                    }
                });
                break;

            case "PUBLISH_PROMOTION_COUPON_CODE":
                this.props.publishPromotionCouponCode({ promotionSeq, requestSeq });
                break;

            default:
                break;
        }
    }

    render() {
        if (this.props.userSeq < 0) {
            return <Redirect to="/main" />;
        }

        if (this.props.isPromotionInfo) {
            const { params } = this.props.routeProps.match;
            const promotion_info = this.props.list_promotion.find(info => info.promotionSeq === Number(params.promotionSeq));
            console.log("promotion_info => ", promotion_info);

            if (promotion_info === undefined || promotion_info === null
                || this.props.userKind !== "hiclasstv" || this.props.userSeq > 0) {
                return <Redirect to="/promotion" />;
            }

            return (
                this.props.selectedPromotion && this.props.selectedPromotion !== "" &&
                <WebPromotionInfo
                    selectedPromotion={this.props.selectedPromotion}
                    list_request={this.props.list_request}
                    handleSelectedFunc={this.handleSelectedFunc}
                />
            );
        } else {
            return (
                (this.props.userKind === "hiclasstv" && this.props.userSeq === 0) ?
                    <WebPromotionListForAdmin
                        list_promotion={this.props.list_promotion}
                        handleSelectedFunc={this.handleSelectedFunc}
                    /> :
                    <>
                        {
                            this.props.isPromotionNotiModalOpened &&
                            <WebPromotionInfoModal
                                isLoginYn={this.props.isLoginYn}
                                isModalOpened={this.props.isPromotionNotiModalOpened}
                                selectedPromotion={this.props.selectedPromotion}
                                isCheckedRegistCouponCode={this.props.isCheckedRegistCouponCode}
                                isSuccessedRegistCoupon={this.props.isSuccessedRegistCoupon}
                                handleSelectedFunc={this.handleSelectedFunc}
                            />
                        }
                        <WebPromotionList
                            list_promotion={this.props.list_promotion}
                            handleSelectedFunc={this.handleSelectedFunc}
                        />
                    </>
            );
        }
    }
}

export default connect(
    ({ user, hiclasstv, promotion }) => ({
        userSeq                     : user.userSeq,
        userKind                    : user.userKind,
        isLoginYn                   : user.isLoginYn,

        hiclasstvInfo               : { 
            currentInfoTap          : hiclasstv.currentInfoTap
        },

        list_promotion              : promotion.list_promotion,
        selectedPromotion           : promotion.selectedPromotion,
        list_request                : promotion.list_request,

        isPromotionNotiModalOpened  : promotion.isPromotionNotiModalOpened,
        isCheckedRegistCouponCode   : promotion.isCheckedRegistCouponCode,
        isSuccessedRegistCoupon     : promotion.isSuccessedRegistCoupon,
    }),
    {
        togglePromotionNotiModalOpened, 
        togglePromotionNotiModalClosed, 

        setPromotionRegistInfoClear,

        registPromotionRequest, 
        registPromotionCouponCode,

        getPromotionList,
        getPromotionRegistInfo,
        getPromotionRequestInfo,
        publishPromotionCouponCode,

        logInToggle,
    }
)(WebPromotionContainer);