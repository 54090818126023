import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { FaUserAstronaut, FaSistrix, FaTimes } from 'react-icons/fa';

import DefaultProfileImage from '../../user/profile/DefaultProfileImage';
import ConstData from '../../../lib/ConstData';
import Alerts from '../../etc/list/Alerts';

const WebFindUser = ({ isModalOpened, toggleModalOpened, subDomainInfo, searchValue, searchResult, handleSelectUser, handleSelectedFunc }) => {
    const [lSearchValue, setSearchValue] = useState("");
    const [lSearchResult, setSearchResult] = useState([]);
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [lAlertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        if (isModalOpened) {
            if (searchValue !== undefined && searchValue !== null && searchValue !== "") {
                setSearchValue(searchValue);

                if (subDomainInfo !== undefined && subDomainInfo !== null && subDomainInfo.subDomainSeq !== -1) {
                    handleSelectedFunc({ selectedFunc: "FIND_USER", keyword: searchValue, subDomainName: subDomainInfo.subDomainName });
                }
            }
        }
    }, [isModalOpened]);

    useEffect(() => {
        setSearchValue(searchValue);
    }, [searchValue]);

    useEffect(() => {
        setSearchResult(searchResult);
    }, [searchResult]);

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            handleSearchClick();
        }
    }

    const handleSearchClick = () => {
        if (lSearchValue === "" || lSearchValue === undefined || lSearchValue === null) {
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("검색어를 입력하세요");
        } else {
            if (subDomainInfo !== undefined && subDomainInfo !== null) {
                handleSelectedFunc({ selectedFunc: "FIND_USER", keyword: lSearchValue, subDomainName: subDomainInfo.subDomainName });
            }
        }
    }

    const onChangeSearchValue = (e) => {
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage("");
        }
        setSearchValue(e.target.value);
    }

    const onClickSelectUser = ({ userSeq, userID, userNickname }) => {
        setSearchValue("");
        setSearchResult([]);
        handleSelectUser({ userSeq, userID, userNickname });
    }

    return (
        <Modal isOpen={isModalOpened} centered backdrop={true} toggle={() => toggleModalOpened(false)}>
            <div className="d-flex flex-column card border-primary p-2 m-2 justify-content-center">
                <div className="d-flex justify-content-end w-100">
                    <button className="hiclasstv-btn b-none p-0" title="닫기" onClick={() => toggleModalOpened(false)}>
                        <FaTimes className="d-flex m-1 black-text" />
                    </button>
                </div>
                <div className="d-flex justify-content-center">
                    <button className="d-flex flex-column align-items-center p-2 my-2 outline outline-hiclasstv-blue active" style={{ borderRadius: '0.3rem' }} disabled>
                        <FaUserAstronaut className="font-xlg my-1" aria-hidden="true" />
                        <span className="button">
                            <b>키워드로 검색</b>
                        </span>
                    </button>
                </div>
                <div className="d-flex flex-column justify-content-center">
                    <div className="md-form d-flex align-items-center justify-content-center">
                        <input type="text" id="inputSearchValue" className="d-flex form-control form-control-sm white w-75" placeholder={lSearchValue === "" ? "검색어를 입력하세요" : lSearchValue} onChange={onChangeSearchValue} onKeyPress={handleKeyPress} value={lSearchValue} />
                        <button type="button" className="outline bold outline-hiclasstv-blue hiclasstv-blue-text px-2 py-1 m-1" onClick={() => handleSearchClick()} >
                            <FaSistrix className="d-flex font-md" />
                        </button>
                    </div>
                    <Alerts kind={lAlertKind} message={lAlertMessage} />
                    {
                        lSearchResult !== undefined && lSearchResult !== null && lSearchResult.length > 0 ?
                            lSearchResult.map(
                                user => (
                                    <div className="d-flex p-1" key={user.userSeq}>
                                        <div className="d-flex align-items-center justify-content-center area-15 fit-content">
                                            <DefaultProfileImage url={user.profileImgUrl} alt={user.userNickname} />
                                        </div>
                                        <div className="d-flex full px-2">
                                            <div className="d-flex flex-column text-area">
                                                <span className="hiclass-text" title={user.userNickname}><b>{user.userNickname}</b></span>
                                                <span className="hiclass-text small grey-text" title={user.userProfile}>{user.userProfile}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex px-1 align-items-center area-20 fit-content">
                                            <button type="button" className="btn btn-sm green white-text py-1 px-2" onClick={() => onClickSelectUser({ userSeq:user.userSeq, userID:user.userID, userNickname:user.userNickname })}>
                                                선택
                                            </button>
                                        </div>
                                    </div>
                                )
                            ) : 
                        <div className="view m-3 text-center grey-text">
                            검색 결과가 없습니다.
                        </div>
                    }
                </div>
            </div>
        </Modal>
    );
};
export default WebFindUser;