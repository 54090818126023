import React from 'react';

import WebCreateSubDomainInfo from './WebCreateSubDomainInfo';

const WebCreateSubDomainList = ({ isStandardType, list_subDomain, goToPassListPage }) => {
    return (
        list_subDomain !== undefined && list_subDomain !== null && list_subDomain.length > 0 ?
            <table className="d-flex flex-column table-sm flex-list mb-0">
                <thead>
                    <tr className="d-flex">
                        <th className="area-35">
                            <b>서브 도메인 이름</b>
                        </th>
                        <th className="area-25">
                            <b>인원</b>
                        </th>
                        <th className="area-25">
                            <div className="only-web w-100">
                                <span style={{ width: '50%' }}><b>팀업</b></span>
                                <span style={{ width: '50%' }}><b>클래스</b></span>
                            </div>
                            <div className="only-mobile w-100">
                                <b>팀업/클래스</b>
                            </div>
                        </th>
                        <th className="area-15">
                            {
                                isStandardType &&
                                <b>이용권</b>
                            }
                        </th>
                    </tr>
                </thead>
                {
                    list_subDomain.map(subDomain => (
                        <WebCreateSubDomainInfo
                            key={'create-' + subDomain.subDomainSeq}
                            isStandardType={isStandardType}
                            subDomain_info={subDomain}
                            goToPassListPage={goToPassListPage}
                        />
                    ))
                }
            </table> :
            <div className="d-flex view m-3 justify-content-center grey-text">
                관리 가능한 서브 도메인 목록이 없습니다.
            </div>
    );
}

export default WebCreateSubDomainList;