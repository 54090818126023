import React, { useState, useEffect } from "react";
import { MdRefresh } from "react-icons/md";
import { MDBInput } from "mdbreact";

import { enWebPage } from "../../../lib/ConstCommand";
import ConstData from "../../../lib/ConstData";

import Alerts from "../../etc/list/Alerts";
import QRLoginModal from "../../etc/modal/QRLoginModal";

const MixedClassLogInWindow = ({ live_info, list_member, alertKind, loginMessage, isInvalidRoute, currentPageType, handleSelectedFunc }) => {
    const [isModalOpened, setIsModalOpened] = useState(false);

    const [selectedUserIdx, setSelectedUserIdx] = useState(-1);
    const [selectedUserInfo, setSelectedUserInfo] = useState({ userID: "", userSeq: -1, userNickname: "" });
    const [prevSelectedUserInfo, setPrevSelectedUserInfo] = useState({ userID: "", userSeq: -1, userNickname: "" });
    const [liveStudentNickname, setLiveStudentNickname] = useState("");

    const [errorMsg, setErrorMsg] = useState(loginMessage);
    const [errorKind, setErrorKind] = useState(alertKind);

    useEffect(() => {
        if (currentPageType === enWebPage.SmartTVQRLogin || currentPageType === enWebPage.SmartTV) {
            if (live_info && list_member && list_member.length > 0) {
                const teacher_info = list_member.find((info, idx) => idx === 0);
                if (teacher_info) {
                    setSelectedUserInfo({
                        userID: teacher_info.userID,
                        userSeq: teacher_info.userSeq,
                        userNickname: teacher_info.userNickname
                    });
                }
                setSelectedUserIdx(0);
            }
        }
    }, [currentPageType, live_info, list_member]);

    useEffect(() => {
        setErrorKind(alertKind);
        setErrorMsg(loginMessage);
    }, [alertKind, loginMessage]);

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onHandleLogin();
        }
    }

    const onClickSelectedUser = (e) => {
        console.log("onClickOptionSelectedUser - ", e.target.value, typeof e.target.value);
        if (list_member && list_member.length > 0) {
            let selectedUserSeq = e.target.value;
            /* let selectedIndex = e.target.selectedIndex;
            if (currentPageType === enWebPage.LiveQRLogin) {
                const member_info = list_member.find((info, idx) => idx === selectedIndex);
                if (member_info) {
                    setSelectedUserInfo({
                        userID: member_info.userID,
                        userSeq: member_info.userSeq,
                        userNickname: member_info.userNickname
                    });
                    setIsModalOpened(true);
                }
            }
            setSelectedUserIdx(selectedIndex); */
        }
    }

    const onClickCaptureSelectedUser = (e) => {
        //console.log("onClickCaptureSelectedUser - ", e.target.value, e.target.selectedIndex - 1);
        if (errorMsg !== "") {
            setErrorKind(ConstData.ALERT_KIND.NONE);
            setErrorMsg("");
        }

        if (!ConstData.IS_LOCAL_VERSION || currentPageType !== enWebPage.LiveQRLogin) return;

        if (list_member && list_member.length > 0) {
            if (!isModalOpened && selectedUserIdx > -1 && selectedUserInfo.userSeq > -1) {
                if (prevSelectedUserInfo.userSeq === -1) {
                    setPrevSelectedUserInfo({
                        userID: selectedUserInfo.userID,
                        userSeq: selectedUserInfo.userSeq,
                        userNickname: selectedUserInfo.userNickname
                    });
                } else {
                    if (selectedUserInfo.userSeq === prevSelectedUserInfo.userSeq) {
                        setPrevSelectedUserInfo({
                            userID: "",
                            userSeq: -1,
                            userNickname: ""
                        });
                        setIsModalOpened(true);
                    }
                }
            }
        }
    }

    const onChangeSelectedUser = (e) => {
        //console.log("onChangeSelectedUser - ", e.target.selectedIndex - 1);
        if (errorMsg !== "") {
            setErrorKind(ConstData.ALERT_KIND.NONE);
            setErrorMsg("");
        }

        if (list_member && list_member.length > 0) {
            let selectedIndex = e.target.selectedIndex - 1;
            const member_info = list_member.find((info, idx) => idx === selectedIndex);
            if (member_info) {
                setSelectedUserInfo({
                    userID: member_info.userID,
                    userSeq: member_info.userSeq,
                    userNickname: member_info.userNickname
                });

                if (ConstData.IS_LOCAL_VERSION && currentPageType === enWebPage.LiveQRLogin) {
                    if (prevSelectedUserInfo.userSeq > -1 && prevSelectedUserInfo.userSeq !== member_info.userSeq) {
                        setPrevSelectedUserInfo({
                            userID: "",
                            userSeq: -1,
                            userNickname: ""
                        });
                    }
                    setIsModalOpened(true);
                }
            }
            setSelectedUserIdx(selectedIndex);
        }
    }

    const onHandleLogin = () => {
        console.log("onHandleLogin - selectedUserIdx => ", selectedUserIdx);
        console.log("onHandleLogin - selectedUserInfo => ", selectedUserInfo);
        if (selectedUserIdx === -1) {
            setErrorKind(ConstData.ALERT_KIND.DANGER);
            setErrorMsg("로그인할 사용자를 선택해 주세요.");
            return;
        } else {
            setErrorKind(ConstData.ALERT_KIND.NONE);
            setErrorMsg("");
        }

        if (list_member.length > 0) {
            if (liveStudentNickname === "") {
                handleSelectedFunc({
                    selectedFunc: "LOGIN_FOR_MIXED",
                    loginInfo: {
                        userID: list_member[selectedUserIdx].userID,
                        userSeq: list_member[selectedUserIdx].userSeq,
                        userNickname: list_member[selectedUserIdx].userNickname
                    }
                });
            } else {
                if (liveStudentNickname.length > 15) {
                    setErrorKind(ConstData.ALERT_KIND.DANGER);
                    setErrorMsg("닉네임은 15자 이내로 입력해야 합니다.");
                    return;
                } else {
                    handleSelectedFunc({
                        selectedFunc: "LOGIN_FOR_MIXED",
                        loginInfo: {
                            userID: list_member[selectedUserIdx].userID,
                            userSeq: list_member[selectedUserIdx].userSeq,
                            userNickname: liveStudentNickname
                        }
                    });
                }
            }
        }
    }

    const onClickRefresh = () => {
        window.location.reload();
    }

    return (
        <>
            <div className="login-window d-flex flex-column align-items-center w-100 p-5">
                <div className="d-flex justify-content-between align-items-center p-3">
                    <button type="button" className="hiclasstv-button hiclasstv-blue-text p-1" style={{ visibility: 'hidden' }} disabled>
                        <div className="d-flex flex-column">
                            <MdRefresh className="font-xxlg" style={{ visibility: 'hidden' }} aria-hidden="true" />
                            <span className="font-xxsm">
                                새로고침
                            </span>
                        </div>
                    </button>
                    <span className="font-xxlg hiclasstv-blue-text p-1" style={{ fontWeight: 400 }} title="LogIn:">
                        LogIn:
                    </span>
                    <button type="button" className="hiclasstv-button hiclasstv-blue-text p-1" title="새로고침" onClick={onClickRefresh}>
                        <div className="d-flex flex-column">
                            <MdRefresh className="font-xxlg" aria-hidden="true" />
                            <span className="font-xxsm">
                                새로고침
                            </span>
                        </div>
                    </button>
                </div>
                <div className="d-flex flex-column align-items-center p-2">
                    {
                        isInvalidRoute ?
                            <div className="d-flex flex-column align-items-center w-100 p-2">
                                <div className="card web-list-card border-none grey lighten-3">
                                    <div className="input-area transparent">
                                        <div className="input-label medium area-30">
                                            <span className="mr-1">클래스</span>
                                            <span>|</span>
                                        </div>
                                        <div className="input-data medium area-70">
                                            <div className="text-area">
                                                <span className="hiclass-text" title="클래스 정보없음">
                                                    <b>클래스 정보없음</b>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-area transparent">
                                        <div className="input-label medium area-30">
                                            <span className="mr-1">진행자</span>
                                            <span>|</span>
                                        </div>
                                        <div className="input-data medium area-70">
                                            <div className="text-area">
                                                <span className="hiclass-text" title="진행자 정보없음">
                                                    <b>진행자 정보없음</b>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="font-md plus p-2">해당 클래스가 시작되지 않았습니다.</span>
                                <span className="font-md plus pb-2 px-2">클래스 진행자에게 문의하세요.</span>
                            </div> :
                            <div className="d-flex flex-column justify-content-center align-items-center w-100 p-2">
                                <div className="card web-list-card border-none grey lighten-3">
                                    <div className="input-area transparent">
                                        <div className="input-label medium area-30">
                                            <span className="mr-1">클래스</span>
                                            <span>|</span>
                                        </div>
                                        <div className="input-data medium area-70">
                                            <div className="text-area">
                                                <span className="hiclass-text" title={live_info.liveName}>
                                                    <b>{live_info.liveName}</b>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-area transparent">
                                        <div className="input-label medium area-30">
                                            <span className="mr-1">진행자</span>
                                            <span>|</span>
                                        </div>
                                        <div className="input-data medium area-70">
                                            <div className="text-area">
                                                <span className="hiclass-text" title={live_info.teacher_info ? live_info.teacher_info.userNickname : ""}>
                                                    <b>{live_info.teacher_info ? live_info.teacher_info.userNickname : ""}</b>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <span className="font-md plus p-2">로그인할 사용자를 선택해 주세요.</span> */}
                            </div>
                    }
                    <select className="custom-select arrow-1 my-2" value={selectedUserInfo.userSeq} onChange={onChangeSelectedUser} onClickCapture={onClickCaptureSelectedUser}>
                        {
                            list_member && list_member.length > 0 ?
                                <>
                                    <option value="-1" disabled>로그인할 사용자를 선택해 주세요.</option>
                                    {
                                        list_member.map((info, index) => (
                                            <option value={info.userSeq} key={'qrlogin-' + info.userSeq + "-" + index}>
                                                {info.userNickname}
                                            </option>
                                        ))
                                    }
                                </> :
                                <option value="-1">접속 가능한 사용자가 없습니다.</option>
                        }
                    </select>
                    {
                        errorMsg !== "" &&
                        <div className="d-flex justify-content-center align-items-center w-100 mb-3">
                            <Alerts kind={errorKind} message={errorMsg} />
                        </div>
                    }
                    {
                        (!ConstData.IS_LOCAL_VERSION || currentPageType === enWebPage.SmartTV) &&
                        <button className="btn hiclasstv-blue white-text w-100 pt-3" disabled={list_member && list_member.length > 0 ? false : true} onClick={() => onHandleLogin()}>
                            Login
                        </button>
                    }
                </div>
            </div>
            {
                isModalOpened &&
                <QRLoginModal
                    isModalOpened={isModalOpened}
                    selectedUser_info={selectedUserInfo}
                    toggleModalOpened={setIsModalOpened}
                    handleSelectedFunc={handleSelectedFunc}
                />
            }
        </>
    );
}

export default MixedClassLogInWindow;