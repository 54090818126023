import React, { useEffect, useState } from "react";
import { stQnAItemType } from "../../../lib/ConstCommand";
import { BsX } from "react-icons/bs";
import { format } from "date-fns";

const QuizResponseResult = ({ list_member, responseInfo, handleSelectedFunc }) => {

    const [isShortForm, setIsShortForm] = useState(false);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (!responseInfo) return;

        if (responseInfo.list_targetMember) {
            console.log(responseInfo.list_targetMember);

            let memberList = responseInfo.list_targetMember.map(info => ({
                userSeq: info.userSeq,
                userNickname: info.userNickname
            }));

            if (responseInfo.quiz_info && responseInfo.quiz_info.data && responseInfo.quiz_info.data.length > 0) {
                console.log(responseInfo.quiz_info);

                let isShortForm = responseInfo.quiz_info.data[0].type === stQnAItemType.Question && responseInfo.quiz_info.data[0].exYN === "Y" ? false : true;

                if (!isShortForm) {
                    let answer = responseInfo.quiz_info.data[1].type === stQnAItemType.Answer ? responseInfo.quiz_info.data[1].data.no : -1;

                    if (answer !== -1) {
                        let result_info = memberList.map(member => {
                            let value = {
                                ...member,
                                answer: { no: -1 },
                                isCorrect: false
                            };

                            for (let i = 0; i < responseInfo.response.length; i++) {
                                let info = responseInfo.response[i];

                                if (member.userSeq === info.userSeq) {
                                    if (info.answer.no === answer) {
                                        value.isCorrect = true;
                                    }

                                    value.answer = info.answer;
                                }
                            }

                            return value;
                        });

                        console.log("--객관식--");
                        console.log(result_info);
                        setResult(result_info);
                    } else {
                        console.log("------문제 형식이 이상합니다------");
                    }
                } else {
                    console.log("QuizResponseResult --- 1", responseInfo.quiz_info.data);
                    setIsShortForm(true);
                    console.log("QuizResponseResult --- 2", responseInfo.quiz_info.data);

                    let answer = responseInfo.quiz_info.data[1].type === stQnAItemType.Answer ?responseInfo.quiz_info.data[1].data.data : "";

                    console.log("QuizResponseResult --- 3", responseInfo.quiz_info.data);
                    console.log("answer => ", answer);

                    if (answer && answer !== "") {
                        console.log("QuizResponseResult --- 4", responseInfo.quiz_info.data);
                        let result_info = memberList.map(member => {
                            let value = {
                                ...member,
                                answer: { data: "" },
                                isCorrect: false
                            };
                            console.log("(1) value -> ", value);
    
                            console.log("(2) responseInfo -> ", responseInfo);
                            for (let i = 0; i < responseInfo.response.length; i++) {
                                let info = responseInfo.response[i];
                                console.log("(3) info -> ", info);
    
                                console.log("(4) member.userSeq, info.userSeq -> ", member.userSeq, info.userSeq);
                                if (member.userSeq === info.userSeq) {
                                    console.log("(5) info.answer -> ", info.answer);
                                    if (typeof info.answer === "string") {
                                        console.log("info.answer === string");
                                        if (info.answer === answer) {
                                            console.log("(6) value.isCorrect -> ", value.isCorrect);
                                            value.isCorrect = true;
                                        }

                                        console.log("(7) value.answer, info.answer -> ", value.answer, info.answer);
                                        value.answer.data = info.answer;
                                    } else if (typeof info.answer === "object") {
                                        console.log("info.answer === object");
                                        if (info.answer.data === answer) {
                                            console.log("(6) value.isCorrect -> ", value.isCorrect);
                                            value.isCorrect = true;
                                        }

                                        console.log("(7) value.answer, info.answer -> ", value.answer, info.answer);
                                        value.answer = info.answer;
                                    }
                                }
                            }
    
                            return value;
                        });

                        console.log("--주관식--");
                        console.log(result_info);
                        setResult(result_info);
                    } else {
                        console.log("------문제 형식이 이상합니다------2");
                    }
                }
            }
        }
    }, [responseInfo]);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const onHandleSaveResult = () => {
        let saveData = "";

        if (result && responseInfo && responseInfo.quiz_info) {

            console.log(responseInfo.quiz_info);

            let title = responseInfo.quiz_info.quizTitle;
            if (title === "") return;
            saveData = "제목," + title + "\n";
            console.log("제목 추가 데이터 : ", saveData);

            let question = responseInfo.quiz_info.data[0];
            if (question.type !== stQnAItemType.Question) return;

            saveData += "문제";

            for (let i = 0; i < question.data.length; i++) {
                saveData += "," + question.data[i].data + "\n";
                if (i < question.data.length - 1) saveData += ",";
            }

            let answer = responseInfo.quiz_info.data[1];
            if (answer.type !== stQnAItemType.Answer) return;

            saveData += "정답," + (!isShortForm ? answer.data.no : "") + "," + answer.data.data + "\n";

            if (!isShortForm) {
                let example = responseInfo.quiz_info.data[2];
                if (example.type !== stQnAItemType.Example) return;

                saveData += "예제";

                for (let i = 0; i < example.data.length; i++) {
                    saveData += "," + example.data[i].no + "," + example.data[i].data + "\n";
                }
            }

            saveData += "\n[응답]\n";
            saveData += "닉네임,응답내용" + (!isShortForm ? ",정답여부" : "");

            result.forEach(info => {
                if (!isShortForm) {
                    saveData += "\n" + info.userNickname + "," + (info.answer.no !== -1 ? info.answer.no : "-") + "," + (info.isCorrect ? "Y" : "N");
                } else {
                    saveData += "\n" + info.userNickname + "," + info.answer.data;
                }
            });
        }

        if (saveData === "") return;

        console.log(saveData);

        downloadCSV(saveData, "quiz_" + format(new Date(Date.now()), "yyyyMMdd") + "_" + Date.now().toString() + ".csv");

        //handleSelectedFunc({ selectedFunc: "SAVE_QUIZ_RESPONSE_RESULT", data: result });
    }

    const downloadCSV = (data, filename) => {
        let csvFile;
        let fileUrl;
        let downloadLink;

        //한글 처리를 해주기 위해 BOM 추가하기
        const BOM = "\uFEFF";
        data = BOM + data;

        csvFile = new Blob([data], { type: "text/csv" });
        fileUrl = window.URL.createObjectURL(csvFile);
        downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = fileUrl;
        downloadLink.style.display = "none";
        //downloadLink.
        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(fileUrl);
    }

    return (
        <div className="d-flex flex-column" style={{ minHeight: '150px', maxHeight: '400px' }} onClick={handlePreventClick}>
            <div className="title-area justify-content-center">
                <span>응답 결과</span>
                <span className="func-btn" style={{ position: 'absolute', right: 0 }} title="닫기" onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            {
                result ?
                    result.length > 0 ?
                        <div className="d-flex flex-column align-items-center w-100 p-2 overflow-auto">
                            <div style={{ overflow: 'auto', width: '100%' }}>
                                <table className="quiz">
                                    <thead>
                                        <tr className="title">
                                            <th className="area-80">
                                                <span className="hiclass-text"><b>닉네임</b></span>
                                            </th>
                                            <th className="justify-content-center area-20">
                                                <span className="hiclass-text"><b>채점</b></span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            result.map((info, idx) => (
                                                <tr key={"result-" + idx}>
                                                    <td className="area-80">
                                                        <span className="hiclass-text" title={info.userNickname}>{info.userNickname}</span>
                                                    </td>
                                                    {
                                                        !isShortForm ?
                                                            <>
                                                                {
                                                                    info.answer.no === -1 ?
                                                                        <td className="noResponse justify-content-center area-20">-</td> :
                                                                        <td className={(info.isCorrect ? "" : " wrong ") + "justify-content-center area-20"}>
                                                                            <span className="hiclass-text">{info.isCorrect ? "O" : "X"} ({info.answer.no})</span>
                                                                        </td>
                                                                }
                                                            </> :
                                                            <>
                                                                {
                                                                    info.answer.data === "" ?
                                                                        <td className="noResponse justify-content-center area-20">-</td> :
                                                                        <td className={(info.isCorrect ? "" : " wrong ") + "justify-content-center area-20"}>
                                                                            <span className="hiclass-text">{info.answer.data}</span>
                                                                        </td>
                                                                }
                                                            </>
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <button className="response hiclasstv-button hiclasstv-blue font-md white-text p-1 px-2 mt-2" onClick={() => onHandleSaveResult()}>
                                결과 저장
                            </button>
                        </div> :
                        <span className="hiclasstv-darkgrey-text p-2">퀴즈 참여자가 존재하지 않습니다.</span> :
                    <span className="hiclasstv-darkgrey-text p-2">결과 조회에 실패하였습니다.</span>
            }
        </div>
    )
}

export default QuizResponseResult;