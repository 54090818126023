import React, { useState, useEffect } from 'react';

import WebLiteServerInfo from './WebLiteServerInfo';

const WebLiteServerList = ({ list_liteServer, handleSelectedFunc }) => {

    return (
        <div className="d-flex flex-column w-100 my-2">
            {
                list_liteServer && list_liteServer.length > 0 ?
                    <table className="d-flex flex-column table-sm flex-list mb-0">
                        <thead>
                            <tr className="d-flex">
                                <th className="d-flex align-items-center justify-content-center area-10">
                                    <b>No.</b>
                                </th>
                                <th className="d-flex align-items-center justify-content-center area-20">
                                    <b>Local Site</b>
                                </th>
                                <th className="d-flex align-items-center justify-content-center area-15">
                                    <b>Name</b>
                                </th>
                                <th className="d-flex align-items-center justify-content-center area-15">
                                    <b>Memo</b>
                                </th>
                                <th className="d-flex align-items-center justify-content-center area-15">
                                    <b>IP Address</b>
                                </th>
                                <th className="d-flex align-items-center justify-content-center area-15">
                                    <b>Auth Code</b>
                                </th>
                                <th className="d-flex align-items-center justify-content-center area-10">
                                    <b></b>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list_liteServer.map((liteServer, idx) => (
                                    <WebLiteServerInfo 
                                        key={"config-lite-local-" + idx}
                                        idx={idx}
                                        liteServer_info={liteServer}
                                        handleSelectedFunc={handleSelectedFunc}
                                    />
                                ))
                            }
                        </tbody>
                    </table> :
                    <div className="d-flex view m-3 justify-content-center grey-text" style={{ fontSize: '1rem' }}>
                        관리 가능한 로컬 서버 목록이 없습니다.
                    </div>
            }
        </div>
    );
}
export default WebLiteServerList;