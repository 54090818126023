import React, { useState, useEffect, createRef } from 'react';
import DatePicker from "react-datepicker";
import { FaTicketAlt, FaPlusCircle } from 'react-icons/fa';

import { term, termKind, kindType, prdtKind } from '../../lib/ConstCommand';

import "react-datepicker/dist/react-datepicker.css";

import WebRegistPass from './modal/WebRegistPass';
import WebUsePass from './modal/WebUsePass';
import CustomDatePicker from '../etc/button/CustomDatePicker';

import ko from 'date-fns/locale/ko';
import { format } from 'date-fns';

const WebB2BAdminPass = ({ selectedUserInfo, list_pchsPass, list_prdtPass, list_prdtKindCode, handleSelectedFunc }) => {
    
    var msg1 = "이용권 구매일로부터 5년";
    var msg2 = "해당 프로모션 종료시까지";

    var msg3 = " 이용권 유료 구매 고객";
    var msg4 = " 이용권 프로모션 행사 기준을 충족하는 고객";

    const [isRegistPassModalOpened, setIsRegistPassModalOpened] = useState(false);
    const [isUsePassModalOpened, setIsUsePassModalOpened] = useState(false);

    const [lUsePassInfo, setUsePassInfo] = useState(null);

    const [lUserInfo, setUserInfo] = useState(selectedUserInfo);

    const [lTypeValue, setTypeValue] = useState('all');
    const [lTermValue, setTermValue] = useState('all');
    const [fromDT, setFromDT] = useState(new Date('2020-01-01'));
    const [toDT, setToDT] = useState(new Date());
    const fromDtRef = new createRef();
    const toDtRef = new createRef();


    useEffect(() => {
        //console.log('WebB2CPassInfo() init');
        handleLookupDataB2C({ term:lTermValue, kind:lTypeValue, subDomainSeq:1, userSeq:lUserInfo.userSeq});

        return () => {
            console.log('WebB2CPassInfo() component invisible');
        }
    }, []);

    useEffect(() => {
        setUserInfo(selectedUserInfo);
    }, [selectedUserInfo]);

    const onChangeKindType = (e) => {
        setTypeValue(e.target.value);
    }

    const onChangeDatePicker = (kind, date) => {
        if (kind === 'from') {
            setFromDT(date);
            setTermValue(null);
        } else {
            setToDT(date);
            setTermValue(null);
        }
    }

    const onClickUsePass = (info) => {
        //console.log('onClickUsePass => ', info);

        if (isUsePassModalOpened) {
            setUsePassInfo(null);
        } else {
            setUsePassInfo(info);
        }
        setIsUsePassModalOpened(!isUsePassModalOpened);
    }

    const onClickBtnTool = (value) => {
        //console.log('onClickBtnTool() - value => ', value);

        if (value === termKind.ALL) {
            setTermValue('all');
            handleLookupDataB2C({ term:'all', kind:lTypeValue, subDomainSeq:1, userSeq:lUserInfo.userSeq });
        } else {
            let date = new Date();
            date.setMonth(toDT.getMonth() - value);
            
            let fromValue = format(date, 'yyyyMMdd');
            let toValue = format(toDT, 'yyyyMMdd');
            let termValue = fromValue + '-' + toValue;

            setFromDT(date);
            setTermValue(termValue);
            handleLookupDataB2C({ term:termValue, kind:lTypeValue, subDomainSeq:1, userSeq:lUserInfo.userSeq });
        }
    }

    const handleLookupData = ({ term, kind, subDomainSeq, userSeq }) => {
        handleLookupDataB2C({ term, kind, subDomainSeq, userSeq });
    }

    const handleLookupDataB2C = ({ term, kind, subDomainSeq, userSeq }) => {
        if (term === undefined || term === null) {
            let fromValue = format(fromDT, 'yyyyMMdd');
            let toValue = format(toDT, 'yyyyMMdd');
            let termValue = fromValue + '-' + toValue;
            
            handleSelectedFunc({ selectedFunc:"GET_PCHS_LIST", term:termValue, kind:kind.toLowerCase(), selSubDomainSeq:subDomainSeq, selUserSeq:userSeq });
        } else {
            handleSelectedFunc({ selectedFunc:"GET_PCHS_LIST", term, kind:kind.toLowerCase(), selSubDomainSeq:subDomainSeq, selUserSeq:userSeq });
        }
    }

    const toggleUsePassModal = (isModalOpened) => {
        //console.log('toggleUsePassModal() - ', isModalOpened);
        setIsUsePassModalOpened(isModalOpened);
    }

    const handleUsePassDate = ({ pchsSeq, startUseDT, endUseDT }) => {
        //console.log('handleUsePassDate() - ', pchsSeq, startUseDT, endUseDT);
        let stdate = format(startUseDT, 'yyyy.MM.dd');
        let enddate = format(endUseDT, 'yyyy.MM.dd');
        handleSelectedFunc({ selectedFunc:"USE_PASS", pchsSeq, useDateInfo:{ startUseDT:stdate, endUseDT:enddate } });
        toggleUsePassModal(false);
    }

    // ▼▼▼▼▼▼▼▼ 결제 프로세스 추가 후 ▼▼▼▼▼▼▼▼
    const onClickRegistPass = (e) => {
        if (isRegistPassModalOpened) {
            
        } else {
            
        }
        setIsRegistPassModalOpened(!isRegistPassModalOpened);
    }
    
    const toggleRegistPassModal = (isModalOpened) => {
        //console.log('toggleRegistPassModal() - ', isModalOpened);
        setIsRegistPassModalOpened(isModalOpened);
    }

    const handleRegistData = ({ prdtSeq, userInfo, prdtInfo }) => {
        if (prdtSeq > 0) {
            let date = new Date();
            let pchsDate = format(date, 'yyyy-MM-dd');
            
            handleSelectedFunc({ selectedFunc:"REGIST_PASS_B2C", prdtSeq, selUserSeq:userInfo.userSeq, prdtInfo, pchsDate });
            
            toggleRegistPassModal(false);
        } else {
            console.log('handleRegistData() - 존재하지 않는 prdtSeq....');
        }
    }
    

    return (
        <div className="web-main list-area">
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaTicketAlt className="font-lg mr-2" aria-hidden="true" />이용권 구매 내역
                        </span>
                        <button type="button" className="btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" onClick={() => onClickRegistPass()}>
                            <FaPlusCircle className="font-md mr-1" />이용권 등록
                        </button>
                    </div>
                </div>
                <div className="px-2">
                    <div className="web-search-box">
                        <div className="web-search-option-area white">
                            <div className="web-search-option">
                                <div className="web-search-select-label">
                                    <span><b>서브 도메인</b></span>
                                </div>
                                <div className="web-search-select-box">
                                    <select className="custom-select small my-1" style={{ width: '100%', fontSize: '0.9rem' }} disabled >
                                        <option value={-1}>HiClassTV</option>
                                    </select>
                                </div>
                            </div>
                            <div className="web-search-option">
                                <div className="web-search-select-label">
                                    <span><b>사용자 ID</b></span>
                                </div>
                                <div className="web-search-select-box">
                                    <div className="d-flex input-group justify-content-center m-0">
                                        <input type="text" className="d-flex flex-fill web-search-input my-1 px-2 py-1" placeholder={lUserInfo !== null && lUserInfo !== undefined ? lUserInfo.userID : ""} disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="web-search-option-area">
                            <div className="web-toolbar-menu toolbar-menu-btn" role="toolbar">
                                <div className="d-flex w-100 align-items-center justify-content-center">
                                        {
                                            term.map(
                                                (info, index) => (
                                                    index === 0 ?
                                                        <button key={"tl-" + index} className="web-toolbar-item" value={info.value} onClick={() => onClickBtnTool(info.value)}>
                                                            전체
                                                        </button> :
                                                        <button key={"tl-" + index} className="web-toolbar-item" value={info.value} onClick={() => onClickBtnTool(info.value)}>
                                                            {info.name}
                                                        </button>
                                                )
                                            )
                                        }
                                </div>
                            </div>
                            <div className="web-toolbar-menu toolbar-menu-date">
                                <DatePicker
                                    locale={ko}
                                    ref={fromDtRef}
                                    selected={fromDT}
                                    maxDate={new Date()}
                                    onChange={date => onChangeDatePicker('from', date)}
                                    popperModifiers={{ preventOverflow: { enabled: true } }}
                                    popperPlacement="auto"
                                    customInput={<CustomDatePicker ref={fromDtRef} pickerID='fromDT' dateInfo={fromDT} />}
                                />
                                <span className="mx-2">-</span>
                                <DatePicker
                                    locale={ko}
                                    ref={toDtRef}
                                    selected={toDT}
                                    minDate={fromDT}
                                    maxDate={new Date()}
                                    onChange={date => onChangeDatePicker('to', date)}
                                    popperModifiers={{ preventOverflow: { enabled: true } }}
                                    popperPlacement='auto'
                                    customInput={<CustomDatePicker ref={toDtRef} pickerID='toDT' dateInfo={toDT} />}
                                />
                            </div>
                            <div className="web-toolbar-menu">
                                <div className="web-search-option p-0">
                                    <select className="custom-select small" style={{fontSize: '0.9rem' }} value={lTypeValue} onChange={onChangeKindType}>
                                        {
                                            kindType.length > 0 ?
                                                kindType.map(
                                                    (info, index) => (
                                                        <option value={info.value} key={"type-" + index}>{info.name}</option>
                                                    )
                                                ) :
                                                <option value='all'>없음</option>
                                        }
                                    </select>
                                </div>
                                <div className="web-search-option p-0">
                                    <button className="btn btn-sm btn-default" style={{width:'100%', margin:'0 0 0 .375rem', fontSize:'.8rem'}} type="button" onClick={() => handleLookupData({ term: lTermValue, kind: lTypeValue, subDomainSeq: 1, userSeq: lUserInfo.userSeq })}>
                                        조회
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column w-100">
                        {
                            list_pchsPass !== undefined && list_pchsPass !== null && list_pchsPass.length > 0 ? 
                                <div className="d-flex table-wrapper">
                                    <table className="d-flex flex-column table table-sm mb-0 pass-list">
                                        <tbody>
                                            {
                                                list_pchsPass.map(
                                                    info => (
                                                        <tr key={"pkey_" + info.pchsSeq} className="d-flex">
                                                            <td className="d-flex w-100">
                                                                <div className="pass-info-item web-list-title pchsPass">
                                                                    {
                                                                        info.prdtInfo.kindCode === prdtKind.Purchase ?
                                                                            <div className="d-flex flex-column">
                                                                                <span className="info-title">{"[구매] " + info.prdtInfo.name}</span>
                                                                                <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· 대상 : {info.prdtInfo.termName + msg3}</span>
                                                                                <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· 사용기한 : {msg1}</span>
                                                                                <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· 서비스 이용기한 : 사용 시작 지정일로부터 {info.prdtInfo.termName}</span>
                                                                            </div> :
                                                                            <div className="d-flex flex-column">
                                                                                <span className="info-title">{"[프로모션] " + info.prdtInfo.name}</span>
                                                                                <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· 대상 : {info.prdtInfo.termName + msg4}</span>
                                                                                <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· 사용기한 : {msg2}</span>
                                                                                <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· 서비스 이용기한 : 사용 시작 지정일로부터 {info.prdtInfo.termName}</span>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div className="landscape-to-portrait web-list-item pchsPass">
                                                                    <div className="d-flex flex-column align-items-center">
                                                                        <span className="only-mobile">구매일</span>
                                                                        <span className="hiclass-text">{info.pchsDT}</span>
                                                                    </div>
                                                                    <div className="d-flex align-items-center justify-content-center pass-use-date">
                                                                        {
                                                                            info.useDtInfo.strtDT !== null && info.useDtInfo.endDT !== null ?
                                                                                <div className="d-flex flex-column align-items-center w-100">
                                                                                    <span className="hiclass-text">사용완료</span>
                                                                                    <span className="hiclass-text">{info.useDtInfo.strtDT + "~" + info.useDtInfo.endDT}</span>
                                                                                </div> :
                                                                                <button type="button" className="btn btn-sm btn-default" onClick={() => onClickUsePass(info)}>사용</button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div> : 
                                <div className="view m-3 text-center grey-text">
                                    이용권 구매 내역이 없습니다.
                                </div>
                        }
                    </div>
                </div>
            </div>
            <WebRegistPass
                isModalOpened={isRegistPassModalOpened}
                toggleModalOpened={toggleRegistPassModal}
                handleRegistData={handleRegistData}
                userInfo={lUserInfo}
                subDomainInfo={{subDomainName:'hiclasstv', subDomainSeq:1}}
                list_prdtPass={list_prdtPass}
                list_prdtKindCode={list_prdtKindCode}
            />
            <WebUsePass
                isModalOpened={isUsePassModalOpened}
                toggleModalOpened={toggleUsePassModal}
                handleUsePassDate={handleUsePassDate}
                usePchsInfo={lUsePassInfo}
            />
        </div>
    );
};
export default WebB2BAdminPass;