import React, { Component } from 'react';
import { connect } from 'react-redux';

import ChatListTitleTemplate from '../../components/template/title/ChatListTitleTemplate';
import MyChatList from '../../components/chat/MyChatList';
import WebMyChatList from '../../components/chat/WebMyChatList';

import { enBrowserType } from '../../lib/ConstCommand';

class MyChatListContainer extends Component {

    render() {
        const { browserType, selectedFunc } = this.props;
        const { userSeq, list_chatting, handleSelectedFunc } = this.props;

        return (
            browserType === enBrowserType.HiClass ?
                <>
                    <ChatListTitleTemplate
                        handleSelectedFunc={handleSelectedFunc}
                        selectedFunc={selectedFunc}
                    />
                    <MyChatList
                        list_chatting={list_chatting}
                        handleSelectedFunc={handleSelectedFunc}
                    />
                </> :
                <WebMyChatList
                    userSeq={userSeq}
                    list_chatting={list_chatting}
                    handleSelectedFunc={handleSelectedFunc}
                />
        );
    }
}

export default connect(
    ({ user, chat }) => ({
        userSeq         : user.userSeq,
        list_chatting   : chat.list_chatting
    }),
    {
        
    }
)(MyChatListContainer);