import React, { Component } from 'react';
import { connect } from 'react-redux';

import { remoconType } from '../lib/classTalk/CodeEnum';
import RemoconMenu from '../components/etc/RemoconMenu';
import RemoconTitleTemplate from '../components/template/title/RemoconTitleTemplate';
import RightSideModal from '../components/template/modal/RightSideModal';
import SoundDeviceChoice from '../components/etc/SoundDeviceChoice';
import CenteredModal from '../components/template/modal/CenteredModal';
import SoundAlertModal from '../components/etc/modal/SoundAlertModal';

import { remoconTeamupInkStatus, 
         remoconTeamupVoiceStatus, 
         remoconTeamupLiveStatus, 
         remoconTeamupPullScreen, 
         remoconTeamupPushScreen } from '../modules/chat';

import { remoconHandsupSelect,
         remoconHandsupInkStatus,
         remoconHandsupSoundStatus,
         remoconHandsupScreenStatus,
         remoconHandsupPullScreen,
         remoconHandsupPushScreen } from '../modules/live';

import { changeRemoconTap, 
         settingSoundDeviceOpen, 
         selectSoundDevice, 
         setSoundDeviceVolume,
         setMyMicEchoStatus,
         toggleSoundAlertModal } from '../modules/hiclasstv';

class RemoconContainer extends Component {

    handleSelectedFunc = ({ selectedFunc, memberSeq, isSelect, kind, value, idx, activeLiveSeq, memberSeqList }) => {
        const { settingSoundDeviceOpen, remoconTeamupInkStatus, remoconTeamupLiveStatus, remoconTeamupVoiceStatus, remoconTeamupPushScreen, remoconTeamupPullScreen } = this.props;
        const { remoconHandsupSelect, remoconHandsupInkStatus, remoconHandsupPushScreen, remoconHandsupPullScreen, remoconHandsupSoundStatus, remoconHandsupScreenStatus } = this.props;
        const { changeRemoconTap, selectSoundDevice, setSoundDeviceVolume, setMyMicEchoStatus } = this.props;

        switch (selectedFunc) {
            case "NONE" :
                settingSoundDeviceOpen();
                break;

            case "OPEN_SOUND_SETTING" : 
                settingSoundDeviceOpen();
                break;

            case "SET_MY_MIC_ECHO" : 
                setMyMicEchoStatus({ selectedFunc, kind: "setCheckedSelfMicEcho", value });
                break;

            case "SET_MY_SUB_MIC":
            case "SET_PARTNER_SPEAKER":
            case "SET_HANDSUP_PARTNER_SPEAKER" :
                if (kind === "DEVICE") {
                    selectSoundDevice({ selectedFunc, kind: "changeAudioInfo", value, idx });
                } else if (kind === "VOLUME") {
                    setSoundDeviceVolume({ selectedFunc, kind: "updateVolume", value });
                }
                break;

            case "SOUND_CONTROL" : 
                changeRemoconTap(remoconType.SoundControl);
                break;

            case "SCREEN_CONTROL" :
                changeRemoconTap(remoconType.ScreenControl);
                break;

            case "HANDS_UP" : 
                changeRemoconTap(remoconType.HandsUP);
                break;

            case "PULL_SCREEN" : 
                changeRemoconTap(remoconType.PullScreen);
                break;

            case "PUSH_SCREEN" : 
                changeRemoconTap(remoconType.PushScreen);
                break;

            case "INK_STATUS" : 
                changeRemoconTap(remoconType.Ink);
                break;

            // send Remocon select value //
            case "HANDSUP_INK_ON" : 
                remoconHandsupInkStatus({ selectedFunc, memberSeq, isSelect, activeLiveSeq: this.props.activeLive.liveSeq });
                break;

            case "HANDSUP_SOUND_ON" : 
                remoconHandsupSoundStatus({ selectedFunc, memberSeq, isSelect, activeLiveSeq: this.props.activeLive.liveSeq });
            break;

            case "HANDSUP_SCREEN_ON" : 
                remoconHandsupScreenStatus({ selectedFunc, memberSeq, isSelect, activeLiveSeq: this.props.activeLive.liveSeq });
            break;
            
            case "HANDSUP_SELECT" : 
                remoconHandsupSelect({ selectedFunc, memberSeq, isSelect, activeLiveSeq: this.props.activeLive.liveSeq });
            break;
            
            case "HANDSUP_PULL_SCREEN" : 
                remoconHandsupPullScreen({ selectedFunc, memberSeq, isSelect, activeLiveSeq: this.props.activeLive.liveSeq });
            break;
            
            case "HANDSUP_PUSH_SCREEN" : 
                remoconHandsupPushScreen({ selectedFunc, memberSeq, isSelect, activeLiveSeq: this.props.activeLive.liveSeq });
            break;

            case "HANDSUP_LIST" : 
                switch(kind) {
                    case "INK" : 
                        remoconHandsupInkStatus({ selectedFunc, memberSeqList, isSelect, activeLiveSeq: this.props.activeLive.liveSeq });
                        break;

                    case "PULL" : 
                        remoconHandsupPullScreen({ selectedFunc, memberSeqList, isSelect, activeLiveSeq: this.props.activeLive.liveSeq });
                        break;

                    case "PUSH" : 
                        remoconHandsupPushScreen({ selectedFunc, memberSeqList, isSelect, activeLiveSeq: this.props.activeLive.liveSeq });
                        break;

                    case "SELECT" :
                        remoconHandsupSelect({ selectedFunc, memberSeqList, isSelect: true, activeLiveSeq: this.props.activeLive.liveSeq });
                        break;

                    case "SOUND" : 
                        remoconHandsupSoundStatus({ selectedFunc, memberSeqList, isSelect, activeLiveSeq: this.props.activeLive.liveSeq });
                        break;

                    case "SCREEN" :
                        remoconHandsupScreenStatus({ selectedFunc, memberSeqList, isSelect, activeLiveSeq: this.props.activeLive.liveSeq });
                        break;

                    default:
                        break;
                } break;
            
            case "TEAMUP_INK_ON" :
                remoconTeamupInkStatus({ selectedFunc, memberSeq, isSelect, performChatRoomSeq: this.props.performChatRoomInfo.chatRoomSeq });
                break;

            case "TEAMUP_PULL_SCREEN" :
                remoconTeamupPullScreen({ selectedFunc, memberSeq, isSelect, performChatRoomSeq: this.props.performChatRoomInfo.chatRoomSeq });
                break;

            case "TEAMUP_PUSH_SCREEN" :
                remoconTeamupPushScreen({ selectedFunc, memberSeq, isSelect, performChatRoomSeq: this.props.performChatRoomInfo.chatRoomSeq });
                break;
            
            case "TEAMUP_VOICE_ON" :
                remoconTeamupVoiceStatus({ selectedFunc, memberSeq, isSelect, performChatRoomSeq: this.props.performChatRoomInfo.chatRoomSeq });
                break;
            
            case "TEAMUP_LIVE_ON" :
                remoconTeamupLiveStatus({ selectedFunc, memberSeq, isSelect, performChatRoomSeq: this.props.performChatRoomInfo.chatRoomSeq });
                break;

            case "TEAMUP_LIST" :
                switch(kind) {
                    case "INK" :
                        remoconTeamupInkStatus({ selectedFunc, memberSeqList, isSelect, performChatRoomSeq: this.props.performChatRoomInfo.chatRoomSeq });
                        break;

                    case "PULL" :
                        remoconTeamupPullScreen({ selectedFunc, memberSeqList, isSelect, performChatRoomSeq: this.props.performChatRoomInfo.chatRoomSeq });
                        break;

                    case "PUSH" :
                        remoconTeamupPushScreen({ selectedFunc, memberSeqList, isSelect, performChatRoomSeq: this.props.performChatRoomInfo.chatRoomSeq });
                        break;

                    case "VOICE" : 
                        remoconTeamupVoiceStatus({ selectedFunc, memberSeqList, isSelect, performChatRoomSeq: this.props.performChatRoomInfo.chatRoomSeq });
                        break;

                    case "LIVE" : 
                        remoconTeamupLiveStatus({ selectedFunc, memberSeqList, isSelect, performChatRoomSeq: this.props.performChatRoomInfo.chatRoomSeq });
                        break;

                    default:
                        break;
                }
                break;

            case "TOGGLE_SOUND_ALERT" :
                this.props.toggleSoundAlertModal();
                break;

            default:
                break;
        }
    }

    render() {
        const { userInfo, remoconCMD, activeLive, performChatRoomInfo, isSettingDeviceOpened } = this.props;
        const { isMyMicEchoEnable } = this.props;
        const { handleSelectedFunc } = this;
        
        return (
            <>
                {
                    isSettingDeviceOpened &&
                    <RightSideModal modalClose={handleSelectedFunc}>
                        <SoundDeviceChoice
                            isMyMicEchoEnable={isMyMicEchoEnable}
                            myMicInfo={this.props.myMicInfo}
                            teampUpSpeakerInfo={this.props.teampUpSpeakerInfo}
                            handsUpSpeakerInfo={this.props.handsUpSpeakerInfo}
                            handleSelectedFunc={handleSelectedFunc}
                        />
                    </RightSideModal>
                }
                {
                    this.props.isSoundAlertModalOpened && 
                    <CenteredModal modalClose={handleSelectedFunc}>
                        <SoundAlertModal
                            isModalOpened={this.props.isSoundAlertModalOpened}
                            alertKind={this.props.alertKind}
                            alertMessage={this.props.alertMessage}
                            handleSelectedFunc={handleSelectedFunc}
                        />
                    </CenteredModal>
                }
                <>
                    <RemoconTitleTemplate
                        viewCMD={remoconCMD}
                        handleSelectedFunc={handleSelectedFunc}
                    />
                    <RemoconMenu
                        userInfo={userInfo}
                        viewCMD={remoconCMD}
                        activeLive={activeLive}
                        performChatRoomInfo={performChatRoomInfo}
                        handleSelectedFunc={handleSelectedFunc}
                    />
                </>
            </>
        )
    }
}

export default connect(
    ({ chat, live, user, hiclasstv }) => ({
        userInfo            : {
            userSeq         : user.userSeq,
            userNickname    : user.userNickname,
            entryYN         : true
        },

        remoconCMD                          : hiclasstv.remoconCMD,
        isSettingDeviceOpened               : hiclasstv.isSettingDeviceOpened,
        isSoundAlertModalOpened             : hiclasstv.isSoundAlertModalOpened,
        alertKind                           : hiclasstv.alertKind,
        alertMessage                        : hiclasstv.alertMessage,

        isMyMicEchoEnable                   : hiclasstv.isMyMicEchoEnable,
        myMicInfo                           : hiclasstv.myMicInfo,
        teampUpSpeakerInfo                  : hiclasstv.teampUpSpeakerInfo,
        handsUpSpeakerInfo                  : hiclasstv.handsUpSpeakerInfo,

        performChatRoomInfo                 : chat.performChatRoomInfo,

        activeLive  : live.list_live.find(info => info.selectStatus === 'Y') !== undefined && live.list_live.find(info => info.selectStatus === 'Y') !== null ? 
                    live.list_live.find(info => info.selectStatus === 'Y') : 
                    {
                        liveSeq     : -1,
                        liveName    : '',
                        list_member : []
                    }, 
    }),
    {
        changeRemoconTap,
        settingSoundDeviceOpen,
        selectSoundDevice,
        setSoundDeviceVolume,
        setMyMicEchoStatus,

        remoconHandsupSelect,
        remoconHandsupInkStatus,
        remoconHandsupSoundStatus,
        remoconHandsupScreenStatus,
        remoconHandsupPullScreen,
        remoconHandsupPushScreen,

        remoconTeamupInkStatus,
        remoconTeamupVoiceStatus,
        remoconTeamupLiveStatus,
        remoconTeamupPullScreen,
        remoconTeamupPushScreen,

        toggleSoundAlertModal
    }
) (RemoconContainer);