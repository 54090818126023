import { useRef, useEffect, useState, useCallback } from 'react';
import { enableAutoTTS } from './enableAutoTTS';

import { langCode } from '../../../lib/ConstCommand';

const useEventCallback = (fn, dependencies) => {
    const ref = useRef(() => {
        throw new Error('useEventCallback error! can not call event handler while rendering');
    });

    useEffect(() => {
        ref.current = fn;
    }, [fn, ...dependencies]);

    return useCallback((args) => {
        const fn = ref.current;
        return fn(args);
    }, [ref]);
};

const useWatson = ({ isSpeakingMode, isRunningWatson, isLectureLive, onSpeechCallback, onRecogCallback }) => {
    const recognition = useRef(null);
    const utterThis = useRef(null);
    const [voices, setVoices] = useState([]);
    const [speaking, setSpeaking] = useState(false);
    const [listening, setListening] = useState(false);
    const [supportedSpeech, setSupportedSpeech] = useState(false);
    const [supportedRecognition, setSupportedRecognition] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.speechSynthesis) {
                setSupportedSpeech(true);
                utterThis.current = new window.SpeechSynthesisUtterance();
                enableAutoTTS();
            }

            window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            
            if (window.SpeechRecognition) {
                setSupportedRecognition(true);
                recognition.current = new window.SpeechRecognition();
            } else {
                if (isLectureLive) {
                    alert('Browser not supported SpeechRecognition');
                }
            }
        }

        return async () => {
            if (recognition !== null && recognition.current !== null) {
                recognition.current.onstart = () => {};
                recognition.current.onresult = () => {};
                recognition.current.onend = () => {};
                recognition.current.onerror = () => {};
                recognition.current.abort();
                recognition.current.stop();
                recognition.current = null;
            }

            if (typeof window !== 'undefined' && window.speechSynthesis) {
                if (window.speechSynthesis.speaking || window.speechSynthesis.pending) {
                    utterThis.current.onstart = () => {};
                    utterThis.current.onend = () => {};
                    utterThis.current.onerror = () => {};
                    utterThis.current = null;
                    window.speechSynthesis.cancel();
                }
            }
        };
    }, []);

    const initRecognition = useEventCallback(({ lang, noAnswer, watsonLanguageCode }) => {
        //console.log(`lang[${lang}], watsonLanguageCode[${watsonLanguageCode}]`);
        if (!isRunningWatson || listening || !supportedRecognition) return;

        if (recognition.current !== null) {
            setListening(true);
            recognition.current.lang = watsonLanguageCode;
            recognition.current.continuous = true;
            recognition.current.interimResults = true;
    
            recognition.current.noAnswer = noAnswer;
            recognition.current.noAnswerLang = lang;
            
            recognition.current.onstart = onRecogCallback;
            recognition.current.onend = onRecogCallback;
    
            recognition.current.onresult = onRecogCallback;
            recognition.current.onerror = (e) => {
                if (e.error === 'not-allowed') {
                    recognition.current.onend = () => {};
                    setListening(false);
                }
                onRecogCallback(e);
            };
    
            recognition.current.start();
        }
    }, [listening, supportedRecognition, onRecogCallback]);

    const clearRecognition = useEventCallback(() => {
        if (!listening || !supportedRecognition) return;

        if (recognition.current !== null) {
            recognition.current.onstart = () => {};
            recognition.current.onresult = () => {};
            recognition.current.onend = () => {};
            recognition.current.onerror = () => {};
            setListening(false);
            recognition.current.abort();
            recognition.current.stop();
            onRecogCallback();
        }
    }, [listening, supportedRecognition, onRecogCallback]);

    const toggleRecognition = useEventCallback(() => {
        if (!listening || !supportedRecognition) return;

        if (recognition.current !== null) {
            if (isSpeakingMode) {
                recognition.current.start();
            } else {
                recognition.current.abort();
                recognition.current.stop();
            }
        }
    }, [isSpeakingMode, listening, supportedRecognition, onRecogCallback]);

    const stopRecognition = useEventCallback(() => {
        if (!listening || !supportedRecognition) return;

        if (recognition.current !== null) {
            recognition.current.onstart = () => {};
            recognition.current.onresult = () => {};
            recognition.current.onend = () => {};
            recognition.current.onerror = () => {};
            setListening(false);
            recognition.current.stop();
            onRecogCallback();
        }
    }, [listening, supportedRecognition, onRecogCallback]);

    const getVoices = () => {
        if (isRunningWatson && supportedSpeech) {
            let voiceOptions = window.speechSynthesis.getVoices();

            if (voiceOptions.length > 0) {
                setVoices(voiceOptions);
                return;
            }

            if (window.speechSynthesis.onvoiceschanged !== undefined) {
                window.speechSynthesis.onvoiceschanged = voiceListener;
            } else {
                //alert('onvoiceschanged undefined');
                window.speechSynthesis.addEventListener('voiceschanged', voiceListener);
            }
        }
    };

    const speak = ({ enIndex, krIndex, textData, watsonLanguageCode, isLast, isNeedMicOn, noAnswer }) => {
        if (!supportedSpeech) return;

        if (supportedRecognition && listening) {
            clearRecognition();
        }

        if (typeof textData === 'string') {
            //setSpeaking(true);
            utterThis.current = new window.SpeechSynthesisUtterance(textData);
            //utterThis.current.text = textData;
            utterThis.current.onstart = onSpeechCallback;
            utterThis.current.onend = (e) => {
                if (e.target.isLast) {
                    //setSpeaking(false);
                    onSpeechCallback(e);
                }
            }; //onSpeechCallback;
            utterThis.current.onerror = onSpeechCallback;

            utterThis.current.pitch = 1;
            
            if (watsonLanguageCode === langCode.USA.value) {
                utterThis.current.rate = 0.85;
                utterThis.current.voice = voices[enIndex];
            } else if (watsonLanguageCode === langCode.Korea.value || watsonLanguageCode === langCode.Korea.subValue) {
                utterThis.current.rate = 1.1;
                utterThis.current.voice = voices[krIndex];
            }

            utterThis.current.isLast = isLast;
            utterThis.current.isNeedMicOn = isNeedMicOn;
            utterThis.current.noAnswer = noAnswer;
            utterThis.current.lang = watsonLanguageCode === langCode.Korea.subValue ? langCode.Korea.value : watsonLanguageCode;

            //alert('bofore speak - ' + utterThis.current.lang);
            window.speechSynthesis.speak(utterThis.current);
        } else if (typeof textData === 'object') {
            //setSpeaking(true);
            let isRealLast = false;

            for (let i = 0; i < textData.length; i++) {
                let tData = textData[i];
                let lang = watsonLanguageCode;

                if (tData.lang !== undefined && tData.lang !== null) {
                    lang = tData.lang;
                }

                if (i === (textData.length - 1)) {
                    isRealLast = true;
                }

                utterThis.current = new window.SpeechSynthesisUtterance(tData.text);
                //utterThis.current.text = tData.text;
                utterThis.current.onstart = onSpeechCallback;
                utterThis.current.onend = (e) => {
                    if (e.target.isLast) {
                        //setSpeaking(false);
                        onSpeechCallback(e);
                    }
                };
                utterThis.current.onerror = onSpeechCallback;

                utterThis.current.pitch = 1;

                if (lang === langCode.USA.value) {
                    utterThis.current.rate = 0.85;
                    utterThis.current.voice = voices[enIndex];
                    //utterThis.current.lang = langCode.USA.value;
                } else if (lang === langCode.Korea.value || lang === langCode.Korea.subValue) {
                    utterThis.current.rate = 1.1;
                    utterThis.current.voice = voices[krIndex];
                    //utterThis.current.lang = langCode.Korea.value;
                }

                utterThis.current.isLast = (isLast && isRealLast);
                utterThis.current.isNeedMicOn = isNeedMicOn;
                utterThis.current.noAnswer = noAnswer;
                utterThis.current.lang = lang === langCode.Korea.subValue ? langCode.Korea.value : lang;

                window.speechSynthesis.speak(utterThis.current);
            }
        }
    };

    const cancel = () => {
        if (!supportedSpeech) return;
        //setSpeaking(false);
        window.speechSynthesis.cancel();
    };

    const voiceListener = (e) => {
        let voiceOptions = e.target.getVoices();
        setVoices(voiceOptions);
    };

    return {
        voices,
        listening,
        speaking,
        initRecognition,
        toggleRecognition,
        clearRecognition,
        stopRecognition,
        getVoices,
        speak,
        cancel,
    };
};

export default useWatson;