import React, { useState, useEffect } from 'react';
import WebLectureInfo from './WebLectureInfo';

const WebLectureList = ({ list_lecture, handleSelectedFunc }) => {
    return (
        <div className="d-flex flex-column w-100 my-2">
            {
                list_lecture && list_lecture.length > 0 ?
                <table className="d-flex flex-column table-sm flex-list mb-0">
                    <thead>
                        <tr className="d-flex">
                            <th className="d-flex align-items-center justify-content-center area-30">
                                <b>강의명</b>
                            </th>
                            <th className="d-flex align-items-center justify-content-center area-15">
                                <b>강의코드</b>
                            </th>
                            <th className="d-flex align-items-center justify-content-center area-10">
                                <b>강의종류</b>
                            </th>
                            <th className="d-flex align-items-center justify-content-center area-15">
                                <b>어시스턴트정보</b>
                            </th>
                            <th className="d-flex align-items-center justify-content-center area-10">
                                <b>강의내용유형</b>
                            </th>
                            <th className="d-flex align-items-center justify-content-center area-10">
                                <b>스텝순번</b>
                            </th>
                            <th className="d-flex align-items-center justify-content-center area-10" />
                            {/*<th className="d-flex align-items-center justify-content-center area-10" />*/}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list_lecture.map((lecture, idx) => (
                                <WebLectureInfo 
                                    key={"config-lecture-" + idx}
                                    idx={idx}
                                    lecture_info={lecture}
                                    handleSelectedFunc={handleSelectedFunc}
                                />
                            ))
                        }
                    </tbody>
                </table> :
                <div className="d-flex view m-3 justify-content-center grey-text" style={{ fontSize: '1rem' }}>
                    관리 가능한 강의 목록이 없습니다.
                </div>
            }
        </div>
    );
}
export default WebLectureList;