import React, { useState, useEffect, useRef } from "react";
import { Modal } from "reactstrap";
import { FaTimes, FaUserCog, FaUser, FaUserAlt, FaUserCheck, FaUserEdit, FaUserLock, FaLock, FaMobileAlt, FaKey, FaUserTag, FaMailBulk, FaPortrait, FaFeatherAlt, FaUserAstronaut, FaUserCircle, FaImage } from "react-icons/fa";
import { FaEnvelopeOpen, FaEnvelope, FaIdBadge, FaIdCardAlt, FaIdCard, FaRobot, FaTabletAlt, FaTag, FaTags, FaRegAddressCard, FaRegEnvelopeOpen, FaRegEnvelope, FaRegIdBadge, FaRegIdCard } from "react-icons/fa";

import ConstData from "../../lib/ConstData";

import Alerts from "../etc/list/Alerts";

const UserUpdate = ({ isInfoOpened, userInfo, userInfoAlertKind, userInfoMessage, handleSelectedFunc }) => {
    const timer = useRef();

    const [password, setPassword] = useState("");
    const [passwordForConfirm, setPasswordForConfirm] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(userInfo.phoneNumber);
    const [confirmNumber, setConfirmNumber] = useState("인증완료");
    const [userNickname, setUserNickname] = useState(userInfo.userNickname);
    const [userEmail, setUserEmail] = useState(userInfo.userEmail);
    const [emailConfirmNumber, setEmailConfirmNumber] = useState("인증완료");
    const [userExtraCode, setUserExtraCode] = useState(userInfo.userExtraCode);
    const [userProfile, setUserProfile] = useState(userInfo.userProfile);
    const [userProfileImg, setUserProfileImg] = useState(userInfo.profileImgUrl);
    //const [isModifyPNMode, setModifyPNMode] = useState(false);
    //const [isModifyPWMode, setModifyPWMode] = useState(false);
    const [isCanModifyPN, setIsCanModifyPN] = useState(false);
    const [isCanModifyPW, setIsCanModifyPW] = useState(false);
    const [isPhoneTestOK, setIsPhoneTestOK] = useState(false);
    const [isPhoneTestStart, setIsPhoneTestStart] = useState(false);
    const [isEmailTestStart, setIsEmailTestStart] = useState(false);
    const [isEmailTestOK, setIsEmailTestOK] = useState(false);
    const [modifyPhoneTestMode, setModifyPhoneTestMode] = useState(false);
    const [modifyEmailTestMode, setModifyEmailTestMode] = useState(false);

    const [isAliveCertifyTime, setIsAliveCertifyTime] = useState(false);
    const [certifyTime, setCertifyTime] = useState(0);

    const [alertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        if (isPhoneTestStart && !isAliveCertifyTime) {
            handleSelectedFunc({ selectedFunc: "CHANGE_ALERT_MESSAGE", kind: "phoneCertification", message: "인증 가능 시간 2분이 초과되었습니다. 인증번호를 다시 발급받으세요." });
        }

        if (isEmailTestStart && !isAliveCertifyTime) {
            handleSelectedFunc({ selectedFunc: "CHANGE_ALERT_MESSAGE", kind: "emailCertification", message: "인증 가능 시간 2분이 초과되었습니다. 인증번호를 다시 발급받으세요." });
        }
    }, [isPhoneTestStart, isEmailTestStart, isAliveCertifyTime]);

    useEffect(() => {
        if (certifyTime > 0) {
            timer.current = setInterval(() => {
                setCertifyTime(c => c - 1);
            }, 1000);
        } else {
            if (certifyTime === -1) {

            } else {
                setIsAliveCertifyTime(false);
            }
        }
        if (timer.current !== null) {
            return () => clearInterval(timer.current);
        }
    }, [certifyTime]);

    useEffect(() => {
        setAlertKind(userInfoAlertKind);
        setAlertMessage(userInfoMessage);
    }, [userInfoAlertKind, userInfoMessage]);

    /* useEffect(() => {
        if (alertKind !== userInfoAlertKind) {
            setAlertKind(userInfoAlertKind);
        }
        setAlertMessage(userInfoMessage);
    }, [userInfoMessage]); */

    useEffect(() => {
        if (userInfo.isPhoneTestOK) {
            setCertifyTime(-1);
            setConfirmNumber("인증완료");
        }
        setIsPhoneTestOK(userInfo.isPhoneTestOK);
    }, [userInfo.isPhoneTestOK]);

    useEffect(() => {
        if (userInfo.isPhoneTestStart) {
            setCertifyTime(120);
            setIsAliveCertifyTime(true);
            setModifyPhoneTestMode(false);
        }
        setIsPhoneTestStart(userInfo.isPhoneTestStart);
    }, [userInfo.isPhoneTestStart]);

    useEffect(() => {
        if (userInfo.isEmailTestOK) {
            setCertifyTime(-1);
            setEmailConfirmNumber("인증완료");
        }
        setIsEmailTestOK(userInfo.isEmailTestOK);
    }, [userInfo.isEmailTestOK]);

    useEffect(() => {
        if (userInfo.isEmailTestStart) {
            setCertifyTime(120);
            setIsAliveCertifyTime(true);
            setModifyEmailTestMode(false);
        }
        setIsEmailTestStart(userInfo.isEmailTestStart)
    }, [userInfo.isEmailTestStart]);

    const onChangePassword = (e) => {
        let isChecked = checkPasswordPattern(e.target.value);

        if (!isChecked) {
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("비밀번호는 8자리 이상 영문자, 숫자, 특수문자로 구성하여야 합니다.");
        } else {
            setAlertKind(ConstData.ALERT_KIND.SUCCESS);
            setAlertMessage("사용 가능한 비밀번호입니다.");
        }
        setPassword(e.target.value);
    }

    const onChangePasswordForConfirm = (e) => {
        let isChecked = checkPasswordPattern(e.target.value);

        if (password !== e.target.value) {
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("비밀번호가 일치하지 않습니다.");
        } else {
            if (!isChecked) {
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage("비밀번호는 8자리 이상 영문자, 숫자, 특수문자로 구성하여야 합니다.");
            } else {
                setAlertKind(ConstData.ALERT_KIND.NONE);
                setAlertMessage("");
            }
        }
        setPasswordForConfirm(e.target.value);
    }

    const onChangePhoneNumber = (e) => {
        if (userInfo.isPhoneTestOK || userInfo.isPhoneTestStart) {
            handleSelectedFunc({ selectedFunc: "CHANGE_ALERT_MESSAGE", kind: "phoneCertification", message: "휴대폰 번호가 변경되었습니다. 인증을 다시 진행해 주세요." });
        }

        let str = autoHypenPhoneNumber(e.target.value);
        setPhoneNumber(str);
    }

    const onChangeConfirmNumber = (e) => {
        let str = e.target.value;
        //str = str.replace(/[^0-9]/g, '');
        setConfirmNumber(str);
    }

    const onChangeUserNickname = (e) => {
        let str = e.target.value;
        str = str.replace(' ', '');
        if (str.length > 15) {
            str = str.substr(0, 15);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("닉네임은 15자 이내로 입력해야 합니다.");
        } else {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage("");
        }
        setUserNickname(str);
    }

    const onChangeUserEmail = (e) => {
        if (userInfo.isEmailTestOK || userInfo.isEmailTestStart) {
            // email 인증 기능이 추가되면 주석해제하기 by hjkim 20230125
            // handleSelectedFunc({ selectedFunc: "CHANGE_ALERT_MESSAGE", kind: "emailCertification", message: "이메일이 변경되었습니다. 인증을 다시 진행해 주세요." });
        }

        let str = e.target.value;
        str = str.replace(' ', '');
        if (str.length > 50) {
            str = str.substr(0, 50);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("이메일은 50자 이내로 입력해야 합니다.");
        }
        setUserEmail(str);
    }

    const onChangeEmailConfirmNumber = (e) => {
        let str = e.target.value;
        str = str.replace(/[^0-9]/g, '');
        setEmailConfirmNumber(str);
    }

    const onChangeUsetExtraCode = (e) => {
        let str = e.target.value;
        str = str.replace(' ', '');
        if (str.length > 50) {
            str = str.substr(0, 50);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("식별코드는 50자 이내로 입력해야 합니다.");
        }
        setUserExtraCode(str);
    }

    const onChangeUserProfile = (e) => {
        let str = e.target.value;
        if (str.length > 30) {
            str = str.substr(0, 30);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("프로필은 30자 이내로 입력해야 합니다.");
        }
        setUserProfile(str);
    }

    const onChangeProfileImg = (e) => {
        //console.log("onChangeProfileImg : ", e.target.value);
        setUserProfileImg(e.target.value);
    }

    const getDisp = () => {
        const ss = certifyTime % 60;
        const mm = Math.floor(certifyTime / 60);

        const str_s = "0" + parseInt(ss).toString();
        const str_m = "0" + parseInt(mm).toString();

        const str_time = str_m.substr(str_m.length - 2, 2) + ":" + str_s.substr(str_s.length - 2, 2);

        return str_time;
    }

    const checkInputValue = (str) => {
        let convertStr = str.replace(/[^a-z0-9@_.-]/gi, '');
        return convertStr;
    }

    const checkPhoneNumber = (e) => {
        if (isPhoneTestOK === true && phoneNumber !== "") {
            console.log("PHONE_CHANHE_MODE");
            setModifyPhoneTestMode(true);
            setConfirmNumber("");
            handleSelectedFunc({ selectedFunc: "CHANGE_ALERT_MESSAGE", kind: "phoneChange", message: "" });
            return;
        }

        if (phoneNumber === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "휴대폰 번호를 확인해 주세요." });
            return;
        }

        console.log("휴대폰 번호 이상 없음");
        handleSelectedFunc({ selectedFunc: "PHONE_CHECK", phoneNumber });

        /* if (phoneNumber === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "휴대폰 번호를 확인해 주세요." });
        } else {
            handleSelectedFunc({ selectedFunc: "PHONE_CHECK", phoneNumber });
            //handleTestKakao("45678"); ///에러남. 사용하면 안됨.
        } */
    }

    const checkPasswordValue = (str) => {
        let number_pattern = /[0-9]/;				        // 숫자
        let alpha_pattern = /[a-zA-Z]/;			            // 문자
        let special_pattern = /[~!@#$%^&*()_+|<>?:{}]/;     // 특수문자

        if (!number_pattern.test(str) || !alpha_pattern.test(str) || !special_pattern.test(str)) {
            return false;
        } else {
            return true;
        }
    }

    const checkPasswordPattern = (str) => {
        let pattern1 = /[0-9]/;                         // 숫자
        let pattern2 = /[a-zA-Z]/;			            // 문자
        let pattern3 = /[~!@#$%^&*()_+|<>?:{}]/;        // 특수문자

        if (!pattern1.test(str) || !pattern2.test(str) || !pattern3.test(str) || str.length < 8) {
            // alert("비밀번호는 8자리 이상 문자, 숫자, 특수문자로 구성하여야 합니다.");
            return false;
        } else {
            return true;
        }
    }

    const checkSpace = (str) => {
        if (str.search(/\s/) !== -1) {
            return true;
        } else {
            return false;
        }
    }

    const checkConfirmPhoneNumber = () => {
        if (!userInfo.isPhoneTestStart) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 먼저 수신해 주세요." });
            return;
        }

        if (confirmNumber === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 확인해 주세요." });
            return;
        }

        console.log("인증번호 이상 없음 for 휴대폰 번호");
        handleSelectedFunc({ selectedFunc: "PHONE_CHECK_CONFIRM", phoneNumber, confirmNumber });

        /* if (!userInfo.isPhoneTestStart) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 먼저 수신해 주세요." });
        } else {
            if (confirmNumber === "") {
                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 확인해 주세요." });
            } else {
                handleSelectedFunc({ selectedFunc: "PHONE_CHECK_CONFIRM", phoneNumber, confirmNumber });
            }
        } */
    }

    const checkEmailAddress = () => {
        if (isEmailTestOK === true && userEmail !== "") {
            console.log("EMAIL_CHANHE_MODE");
            setModifyEmailTestMode(true);
            setEmailConfirmNumber("");
            handleSelectedFunc({ selectedFunc: "CHANGE_ALERT_MESSAGE", kind: "emailChange", message: "" });
            return;
        }

        if (userEmail === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 주소를 확인해 주세요." });
            return;
        }

        if (userEmail.indexOf("@") === -1) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
            return;
        }

        if (userEmail.indexOf(".") === -1) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
            return;
        }

        if (userEmail.indexOf(".") >= (userEmail.length - 2)) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
            return;
        }

        console.log("이메일 이상 없음");
        handleSelectedFunc({ selectedFunc: "EMAIL_CHECK", userEmail });

        /* if (userEmail === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 주소를 확인해 주세요." });
        } else {
            if (userEmail.indexOf("@") === -1) {
                //console.log("@ index : "+userEmail.indexOf("@"));
                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
            } else {
                if (userEmail.indexOf(".") === -1) {
                    handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
                } else {
                    if (userEmail.indexOf(".") >= (userEmail.length - 2)) {
                        handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
                    } else {
                        console.log("이메일 이상 없음");
                        handleSelectedFunc({ selectedFunc: "EMAIL_CHECK", userEmail });
                    }
                }
            }
        } */
    }

    const checkEmailConfirmNumber = () => {
        if (!userInfo.isEmailTestStart) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 먼저 수신해 주세요." });
            return;
        }

        if (emailConfirmNumber === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 확인해 주세요." });
            return;
        }

        console.log("인증번호 이상 없음 for 이메일");
        handleSelectedFunc({ selectedFunc: "EMAIL_CHECK_CONFIRM", userEmail, confirmNumber: emailConfirmNumber });

        /* if (!userInfo.isEmailTestStart) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 먼저 수신해 주세요." });
        } else {
            if (emailConfirmNumber === "") {
                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 확인해 주세요." });
            } else {
                handleSelectedFunc({ selectedFunc: "EMAIL_CHECK_CONFIRM", userEmail, confirmNumber: emailConfirmNumber });
            }
        } */
    }

    const checkUpdatetUser = () => {
        if (isCanModifyPW) {
            if (password === "") {
                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "비밀번호를 입력해 주세요." });
                return;
            }

            if (!checkPasswordPattern(password)) {
                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "비밀번호는 8자리 이상 영문자, 숫자, 특수문자로 구성하여야 합니다." });
                return;
            }

            if (password !== passwordForConfirm) {
                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "비밀번호가 일치하지 않습니다." });
                return;
            }
        }

        if (isCanModifyPN) {
            if (phoneNumber === "") {
                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "휴대폰 번호를 입력해 주세요." });
                return;
            }

            if (!ConstData.IS_LOCAL_VERSION) {
                if (!userInfo.isPhoneTestOK) {
                    handleSelectedFunc({ selectedFunc: "MESSAGE", message: "휴대폰 번호 인증을 진행해 주세요." });
                    return;
                }
            }
        }

        if (userEmail === undefined || userEmail === null || userEmail === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 주소를 입력해 주세요." });
            return;
        }

        if (userEmail.indexOf("@") === -1) {
            //console.log("@ index : "+userEmail.indexOf("@"));
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
            return;
        }

        if (userEmail.indexOf(".") === -1) {
            //console.log(". index2 : "+userEmail.indexOf("."));
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
            return;
        }

        if (userEmail.indexOf(".") >= (userEmail.length - 2)) {
            //console.log(". index3 : "+userEmail.indexOf(".")+" / length : "+userEmail.length);
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
            return;
        }

        // email 인증 기능이 추가되면 주석해제하기 by hjkim 20230125
        /* if (!ConstData.IS_LOCAL_VERSION) {
            if (!userInfo.isEmailTestOK) {
                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 인증을 진행해 주세요." });
                return;
            }
        } */

        if (userNickname === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "닉네임을 입력해 주세요." });
            return;
        }

        if (userProfile === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "프로필을 입력해 주세요." });
            return;
        }

        let newPhoneNumber = userInfo.phoneNumber;
        let newPassword = null;

        if (isCanModifyPW) {
            handleIsCanModifyPW();
            newPassword = password;
        }

        if (isCanModifyPN) {
            handleIsCanModifyPN();
            newPhoneNumber = phoneNumber;
        }

        // 아무 이상 없는 경우
        console.log("회원수정 데이터 아무이상 없음");
        handleSelectedFunc({
            selectedFunc: "MODIFY",
            modifyInfo: ({ password: newPassword, userNickname, userEmail, phoneNumber: newPhoneNumber, userExtraCode, userProfile, profileImgUrl: userProfileImg })
        }); // payload로 넘겨줌

        /* if (userNickname === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "닉네임을 입력해 주세요." });
        } else {
            if (userEmail) {
                if (userEmail === "") {
                    handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 주소를 입력해 주세요." });
                } else {
                    if (userEmail.indexOf("@") === -1) {
                        //console.log("@ index : "+userEmail.indexOf("@"));
                        handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
                    } else {
                        console.log(". index1 : " + userEmail.indexOf("."));
                        if (userEmail.indexOf(".") === -1) {
                            //console.log(". index2 : "+userEmail.indexOf("."));
                            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
                        } else {
                            if (userEmail.indexOf(".") >= (userEmail.length - 2)) {
                                //console.log(". index3 : "+userEmail.indexOf(".")+" / length : "+userEmail.length);
                                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
                            } else {
                                if (userProfile === "") {
                                    handleSelectedFunc({ selectedFunc: "MESSAGE", message: "프로필을 입력해 주세요." });
                                } else {
                                    if (isCanModifyPW) {
                                        if (password === "") {
                                            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "비밀번호를 입력해 주세요." });
                                        } else {
                                            if (password !== passwordForConfirm) {
                                                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "비밀번호가 일치하지 않습니다." });
                                            } else {
                                                if (isCanModifyPN) {
                                                    if (!userInfo.isPhoneTestOK) {
                                                        handleSelectedFunc({ selectedFunc: "MESSAGE", message: "휴대폰 번호 인증을 진행해 주세요." });
                                                    } else { // 아무이상 없는 경우//
                                                        console.log("아무이상 없음1-1");
                                                        if (isCanModifyPN) {
                                                            handleIsCanModifyPN();
                                                        }
                                                        if (isCanModifyPW) {
                                                            handleIsCanModifyPW();
                                                        }
                                                        handleSelectedFunc({
                                                            selectedFunc: "MODIFY",
                                                            modifyInfo: ({ password, userNickname, userEmail, phoneNumber, userExtraCode, userProfile })
                                                        }); //payload로 넘겨줌
                                                    }
                                                } else {
                                                    console.log("아무이상 없음1-2");
                                                    if (isCanModifyPN) {
                                                        handleIsCanModifyPN();
                                                    }
                                                    if (isCanModifyPW) {
                                                        handleIsCanModifyPW();
                                                    }
                                                    handleSelectedFunc({
                                                        selectedFunc: "MODIFY",
                                                        modifyInfo: ({ password, userNickname, userEmail, phoneNumber, userExtraCode, userProfile })
                                                    }); //payload로 넘겨줌
                                                }
                                            }
                                        }
                                    } else {
                                        if (isCanModifyPN) {
                                            if (!userInfo.isPhoneTestOK) {
                                                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "휴대폰 번호 인증을 진행해 주세요." });
                                            } else { // 아무이상 없는 경우//
                                                console.log("아무이상 없음2-1");
                                                if (isCanModifyPN) {
                                                    handleIsCanModifyPN();
                                                }
                                                if (isCanModifyPW) {
                                                    handleIsCanModifyPW();
                                                }
                                                handleSelectedFunc({
                                                    selectedFunc: "MODIFY",
                                                    modifyInfo: ({ password: null, userNickname, userEmail, phoneNumber, userExtraCode, userProfile, profileImgUrl: userProfileImg })
                                                }); //payload로 넘겨줌
                                            }
                                        } else {
                                            console.log("아무이상 없음2-2");
                                            if (isCanModifyPN) {
                                                handleIsCanModifyPN();
                                            }
                                            if (isCanModifyPW) {
                                                handleIsCanModifyPW();
                                            }
                                            handleSelectedFunc({
                                                selectedFunc: "MODIFY",
                                                modifyInfo: ({ password: null, userNickname, userEmail, phoneNumber, userExtraCode, userProfile, profileImgUrl: userProfileImg })
                                            }); //payload로 넘겨줌
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } */
    }

    const handleIsCanModifyPN = (e) => {
        if (!isCanModifyPN) {
            setConfirmNumber("");
        }
        setIsCanModifyPN(!isCanModifyPN);
    }

    const handleIsCanModifyPW = (e) => {
        setIsCanModifyPW(!isCanModifyPW);
    }

    const handleClickCancel = (e) => {
        if (isCanModifyPN) {
            handleIsCanModifyPN();
        }
        if (isCanModifyPW) {
            handleIsCanModifyPW();
        }
        handleSelectedFunc({ selectedFunc: "MODIFY_TOGGLE" });
    }

    const autoHypenPhoneNumber = (str) => {
        str = str.replace(/[^0-9]/g, '');
        var tmp = '';

        if (str.length < 4) {
            return str;
        } else if (str.length < 7) {
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3);
            return tmp;
        } else if (str.length < 11) {
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 3);
            tmp += '-';
            tmp += str.substr(6);
            return tmp;
        } else {
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 4);
            tmp += '-';
            tmp += str.substr(7);
            return tmp;
        }
    }

    const onCloseModal = () => {
        console.log("onCloseModal");
        setPassword("");
        setPasswordForConfirm("");
        setPhoneNumber(userInfo.phoneNumber);
        setConfirmNumber("인증완료");
        setUserNickname(userInfo.userNickname);
        setUserEmail(userInfo.userEmail);
        setUserExtraCode(userInfo.userExtraCode);
        setUserProfile(userInfo.userProfile);
        setUserProfileImg(userInfo.profileImgUrl);
        setIsPhoneTestOK(false);
        setIsPhoneTestStart(false);
        setIsCanModifyPN(false);
        setIsCanModifyPW(false);
        setCertifyTime(0);
        setIsAliveCertifyTime(false);
        setAlertKind(ConstData.ALERT_KIND.NONE);
        setAlertMessage("");
        if (timer && timer.current !== null) {
            return () => clearInterval(timer.current);
        }
        setCertifyTime(0);
    }

    // placeholder : 입력 창에 보이는 tip 같은 내용 적는 곳
    // value       : 입력 창에 초기값을 지정하는 곳

    return (
        <Modal isOpen={isInfoOpened} backdrop={false} centered onClosed={onCloseModal}>
            <div className="card container-fluid p-0 m-0">
                <div className="card-body py-2 px-3" style={{ fontSize: '0.9rem' }}>
                    <div className="d-flex w-100 justify-content-end align-items-center">
                        <button type="button" className="hiclasstv-button px-2 py-1" data-toggle="tooltip" title="닫기" onClick={handleClickCancel}>
                            <FaTimes className="d-flex m-1" />
                        </button>
                    </div>
                    <div className="d-flex justify-content-center blue-text mb-1">
                        <FaUserCog className="font-xlg mr-2" data-toggle="tooltip" title="회원정보 수정" />
                        {/* <FaUserEdit className="font-xlg mr-2" /> */}
                        <h3 className="m-0">회원정보 수정</h3>
                    </div>
                    <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                        <div className="d-flex flex-column align-items-center justify-content-center m-0 p-0" style={{ width: '20%', maxWidth: '80px' }}>
                            <FaUserCheck className="font-md plus blue-text" data-toggle="tooltip" title="아이디" />
                            {/* <FaUser className="font-md plus blue-text" data-toggle="tooltip" title="아이디" /> */}
                            <span className="input-name">아이디</span>
                        </div>
                        <div className="d-flex m-0 p-0" style={{ width: '80%', maxWidth: '90%' }}>
                            <input type="text" id="editInfoId" className="form-control form-control-sm" value={userInfo.userID} disabled />
                        </div>
                    </div>
                    <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                        <div className="d-flex flex-column align-items-center justify-content-center m-0 p-0" style={{ width: '20%', maxWidth: '80px' }}>
                            <FaUserLock className="font-md plus blue-text" data-toggle="tooltip" title="비밀번호" />
                            {/* <FaLock className="font-md plus blue-text" data-toggle="tooltip" title="비밀번호" /> */}
                            <span className="input-name">비밀번호</span>
                        </div>
                        <div className="d-flex m-0 p-0" style={{ width: '80%', maxWidth: '90%' }}>
                            <input type="password" id="editInfoPw" className="form-control form-control-sm" placeholder={!isCanModifyPW ? "******" : "새 비밀번호를 입력해 주세요."} autoComplete="new-password" disabled={!isCanModifyPW ? true : false} value={password} onChange={onChangePassword} />
                            {
                                !isCanModifyPW &&
                                <div className="input-group-append" style={{ minWidth: 'max-content' }}>
                                    <button className="btn btn-sm btn-primary m-0 px-3" type="button" onClick={handleIsCanModifyPW}>
                                        변경하기
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        isCanModifyPW && // 비밀번호 변경 시작했을 경우에만 비밀번호 확인이 보이게 수정 by hjkim 20230125
                        <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                            <div className="d-flex flex-column align-items-center justify-content-center m-0 p-0" style={{ width: '20%', maxWidth: '80px' }}>
                                <FaUserLock className="font-md plus blue-text" data-toggle="tooltip" title="비밀번호 확인" />
                                {/* <FaLock className="font-md plus blue-text" data-toggle="tooltip" title="비밀번호 확인" /> */}
                                <span className="input-name">비밀번호 확인</span>
                            </div>
                            <div className="d-flex m-0 p-0" style={{ width: '80%', maxWidth: '90%' }}>
                                <input type="password" id="editInfoCfPw" className="form-control form-control-sm" placeholder={!isCanModifyPW ? "******" : "새 비밀번호를 한 번 더 입력해 주세요."} disabled={!isCanModifyPW ? true : false} autoComplete="new-password" value={passwordForConfirm} onChange={onChangePasswordForConfirm} />
                            </div>
                        </div>
                    }
                    {
                        isCanModifyPW && // 비밀번호 변경 시작했을 경우에만 비밀번호 설명이 보이게 by hjkim 20230125
                        <div className="d-flex w-100 justify-content-between">
                            <span style={{ width: '20%', maxWidth: '80px' }}></span>
                            <span className="input-description area-80">8자~20자, 영문, 숫자, 특수문자 사용</span>
                        </div>
                        /* isCanModifyPW && // 비밀번호 변경 시작했을 경우에만 비밀번호 설명이 보이게 by hjkim 20230125
                        <div className="d-flex w-100 justify-content-between">
                            <span style={{ width: '20%', maxWidth: '80px' }}></span>
                            <span className="input-description area-80">6자~20자, 영문, 숫자, 특수문자 사용</span>
                        </div> */
                    }
                    <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                        <div className="d-flex flex-column align-items-center justify-content-center m-0 p-0" style={{ width: '20%', maxWidth: '80px' }}>
                            <FaMobileAlt className="font-md plus blue-text" data-toggle="tooltip" title="휴대폰 번호" />
                            <span className="input-name">휴대폰 번호</span>
                        </div>
                        <div className="d-flex m-0 p-0" style={{ width: '80%', maxWidth: '90%' }}>
                            <input type="text" id="editInfoPn" className="form-control form-control-sm" maxLength={13} placeholder="휴대폰 번호를 입력해 주세요." disabled={(!isCanModifyPN || isPhoneTestOK) ? true : false} onChange={onChangePhoneNumber} value={phoneNumber} />
                            {
                                !ConstData.IS_LOCAL_VERSION ?
                                    <div className="input-group-append align-items-center" style={{ minWidth: 'max-content' }}>
                                        <button type="button" className={"btn btn-sm m-0 px-3" + ((!isPhoneTestStart || isPhoneTestOK) ? " btn-primary" : " btn-blue-grey")} disabled={isPhoneTestStart ? true : false} onClick={!isCanModifyPN ? handleIsCanModifyPN : checkPhoneNumber}>
                                            {
                                                (!isCanModifyPN || (isPhoneTestOK && !modifyPhoneTestMode)) ? 
                                                    "변경하기" :
                                                isPhoneTestOK && modifyPhoneTestMode ? 
                                                    "인증하기" : 
                                                isPhoneTestStart ? "인증대기" : "인증하기"
                                            }
                                            {
                                                /* !isCanModifyPN ? "변경하기" : "인증하기" */
                                            }
                                        </button>
                                        {
                                            /* !isPhoneTestOK && isPhoneTestStart && isAliveCertifyTime &&
                                            <span className="text-center red-text small m-0 ml-1 p-0">
                                                {getDisp()}
                                            </span> */
                                        }
                                    </div> :
                                    !isCanModifyPN ?
                                        <div className="input-group-append align-items-center" style={{ minWidth: 'max-content' }}>
                                            <button type="button" className="btn btn-sm btn-primary m-0 px-3" onClick={handleIsCanModifyPN}>
                                                변경하기
                                            </button>
                                        </div> :
                                        <></>
                            }
                        </div>
                    </div>
                    {
                        !isPhoneTestOK && isPhoneTestStart && isAliveCertifyTime &&
                        <div className="d-flex w-100 justify-content-between">
                            <span style={{ width: '20%', maxWidth: '80px' }}></span>
                            <div className="input-description area-80">
                                <span className="pr-1">
                                    남은 시간 :
                                </span>
                                <span className="red-text">
                                    {getDisp()}
                                </span>
                            </div>
                        </div>
                    }
                    {
                        !ConstData.IS_LOCAL_VERSION && isCanModifyPN && (isPhoneTestStart || isPhoneTestOK) && // local version에서는 휴대폰 번호 인증 과정 생략.. 클라우드에서도 인증 요청 시작했을 경우에만 인증번호가 보이게 수정 by hjkim 20230119
                        <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                            <div className="d-flex flex-column align-items-center justify-content-center m-0 p-0" style={{ width: '20%', maxWidth: '80px' }}>
                                <FaKey className="font-md plus blue-text" data-toggle="tooltip" title="인증번호" />
                                <span className="input-name">인증번호</span>
                            </div>
                            <div className="d-flex m-0 p-0" style={{ width: '80%', maxWidth: '90%' }}>
                                <input type="text" id="editInfoCd" className="form-control form-control-sm" placeholder="인증번호를 입력해 주세요." disabled={(!isCanModifyPN || isPhoneTestOK) ? true : false} onChange={onChangeConfirmNumber} value={confirmNumber} />
                                {
                                    !isPhoneTestOK &&
                                    <div className="input-group-append align-items-center" style={{ minWidth: 'max-content' }}>
                                        <button type="button" className={"btn btn-sm m-0 px-3" + ((!isPhoneTestStart || isPhoneTestOK) ? " btn-blue-grey" : " btn-primary")} disabled={(!isPhoneTestStart || isPhoneTestOK) ? true : false} onClick={checkConfirmPhoneNumber}>
                                            인증확인
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                        <div className="d-flex flex-column align-items-center justify-content-center m-0 p-0" style={{ width: '20%', maxWidth: '80px' }}>
                            <FaEnvelope className="font-md plus blue-text" data-toggle="tooltip" title="이메일" />
                            {/* <FaMailBulk className="font-md plus blue-text" data-toggle="tooltip" title="이메일" /> */}
                            <span className="input-name">이메일</span>
                        </div>
                        <div className="d-flex m-0 p-0" style={{ width: '80%', maxWidth: '90%' }}>
                            <input type="email" id="editInfoEm" className="form-control form-control-sm" placeholder="이메일 주소를 입력해 주세요." onChange={onChangeUserEmail} value={userEmail} />
                        </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                        <span style={{ width: '20%', maxWidth: '80px' }}></span>
                        <span className="input-description area-80">50자 이내로 이메일 형식에 맞춰 입력 가능</span>
                    </div>
                    <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                        <div className="d-flex flex-column align-items-center justify-content-center m-0 p-0" style={{ width: '20%', maxWidth: '80px' }}>
                            <FaUserEdit className="font-md plus blue-text" data-toggle="tooltip" title="닉네임" />
                            {/* <FaUserTag className="font-md plus blue-text" data-toggle="tooltip" title="닉네임" /> */}
                            <span className="input-name">닉네임</span>
                        </div>
                        <div className="d-flex m-0 p-0" style={{ width: '80%', maxWidth: '90%' }}>
                            <input type="text" id="editInfoNn" className="form-control form-control-sm" placeholder="닉네임을 입력해 주세요." onChange={onChangeUserNickname} value={userNickname} />
                        </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                        <span style={{ width: '20%', maxWidth: '80px' }}></span>
                        <span className="input-description area-80">1자~15자, 한글, 영문, 숫자, 특수문자 사용 가능</span>
                    </div>
                    <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                        <div className="d-flex flex-column align-items-center justify-content-center m-0 p-0" style={{ width: '20%', maxWidth: '80px' }}>
                            <FaUserTag className="font-md plus blue-text" data-toggle="tooltip" title="프로필" />
                            {/* <FaPortrait className="font-md plus blue-text" data-toggle="tooltip" title="프로필" /> */}
                            <span className="input-name">프로필</span>
                        </div>
                        <div className="d-flex m-0 p-0" style={{ width: '80%', maxWidth: '90%' }}>
                            <input type="text" id="editInfoPp" className="form-control form-control-sm" placeholder="프로필을 입력해 주세요." onChange={onChangeUserProfile} value={userProfile} />
                        </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                        <span style={{ width: '20%', maxWidth: '80px' }}></span>
                        <span className="input-description area-80">1자~30자, 한글, 영문, 숫자, 특수문자 사용 가능</span>
                    </div>
                    <div className="d-flex w-100 my-2 justify-content-between">
                        <div className="d-flex flex-column align-items-center justify-content-center m-0 p-0" style={{ width: '20%', maxWidth: '80px' }}>
                            <FaUserCircle className="font-md plus blue-text" data-toggle="tooltip" title="프로필 이미지" />
                            {/* <FaImage className="font-md plus blue-text" data-toggle="tooltip" title="프로필 이미지" /> */}
                            <span className="input-name">프로필 이미지</span>
                        </div>
                        <div className="m-0 p-0" style={{ width: '80%', maxWidth: '90%' }}>
                            <div className="d-flex w-100 px-2" style={{ overflow: 'auto' }}>
                                <div className="d-flex mr-2 p-0 align-items-center">
                                    <input className="d-flex mr-1 p-0" type="radio" name="profileImgs" id="profileImg1" value="1" onChange={onChangeProfileImg} defaultChecked={userProfileImg === ConstData.USER_PROFILE_IMG.KIND_1 ? true : false} />
                                    <label className="d-flex m-0" htmlFor="profileImg1">
                                        <img src={ConstData.IMG_DIR + "profile_01.png"} alt="" />
                                    </label>
                                </div>
                                <div className="d-flex mr-2 p-0 align-items-center">
                                    <input className="d-flex mr-1 p-0" type="radio" name="profileImgs" id="profileImg2" value="2" onChange={onChangeProfileImg} defaultChecked={userProfileImg === ConstData.USER_PROFILE_IMG.KIND_2 ? true : false} />
                                    <label className="d-flex m-0" htmlFor="profileImg2">
                                        <img src={ConstData.IMG_DIR + "profile_02.png"} alt="" />
                                    </label>
                                </div>
                                <div className="d-flex mr-2 p-0 align-items-center">
                                    <input className="d-flex mr-1 p-0" type="radio" name="profileImgs" id="profileImg3" value="3" onChange={onChangeProfileImg} defaultChecked={userProfileImg === ConstData.USER_PROFILE_IMG.KIND_3 ? true : false} />
                                    <label className="d-flex m-0" htmlFor="profileImg3">
                                        <img src={ConstData.IMG_DIR + "profile_03.png"} alt="" />
                                    </label>
                                </div>
                                <div className="d-flex mr-2 p-0 align-items-center">
                                    <input className="d-flex mr-1 p-0" type="radio" name="profileImgs" id="profileImg4" value="4" onChange={onChangeProfileImg} defaultChecked={userProfileImg === ConstData.USER_PROFILE_IMG.KIND_4 ? true : false} />
                                    <label className="d-flex m-0" htmlFor="profileImg4">
                                        <img src={ConstData.IMG_DIR + "profile_04.png"} alt="" />
                                    </label>
                                </div>
                                <div className="d-flex mr-2 p-0 align-items-center">
                                    <input className="d-flex mr-1 p-0" type="radio" name="profileImgs" id="profileImg5" value="5" onChange={onChangeProfileImg} defaultChecked={userProfileImg === ConstData.USER_PROFILE_IMG.KIND_5 ? true : false} />
                                    <label className="d-flex m-0" htmlFor="profileImg5">
                                        <img src={ConstData.IMG_DIR + "profile_05.png"} alt="" />
                                    </label>
                                </div>
                                <div className="d-flex mr-2 p-0 align-items-center">
                                    <input className="d-flex mr-1 p-0" type="radio" name="profileImgs" id="profileImg6" value="6" onChange={onChangeProfileImg} defaultChecked={userProfileImg === ConstData.USER_PROFILE_IMG.KIND_6 ? true : false} />
                                    <label className="d-flex m-0" htmlFor="profileImg6">
                                        <img src={ConstData.IMG_DIR + "profile_06.png"} alt="" />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                        <div className="d-flex flex-column align-items-center justify-content-center m-0 p-0" style={{ width: '20%', maxWidth: '80px' }}>
                            <FaFeatherAlt className="font-md plus blue-text" data-toggle="tooltip" title="식별코드" />
                            <span className="input-name">식별코드</span>
                        </div>
                        <div className="d-flex m-0 p-0" style={{ width: '80%', maxWidth: '90%' }}>
                            <input type="text" id="editInfoExCode" className="form-control form-control-sm" placeholder="식별코드를 입력해 주세요." onChange={onChangeUsetExtraCode} value={userExtraCode} />
                        </div>
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                        <span style={{ width: '20%', maxWidth: '80px' }}></span>
                        <span className="input-description area-80">생략 또는 1자~50자, 한글, 영문, 숫자, 특수문자 사용 가능</span>
                    </div>
                    {
                        alertMessage !== '' &&
                        <div className="d-flex justify-content-center">
                            <Alerts kind={alertKind} message={alertMessage} />
                        </div>
                    }
                    <div className="d-flex justify-content-center align-items-center my-2">
                        <button type="button" className="btn btn-sm btn-primary" onClick={checkUpdatetUser}>
                            확인
                        </button>
                        <button type="button" className="btn btn-sm btn-danger" onClick={handleClickCancel}>
                            취소
                        </button>
                    </div>
                </div>
            </div>
            
            {/* <div className="d-flex">
                <div className="card d-flex w-100">
                    <div className="card-body d-flex flex-column p-2" style={{ fontSize: '0.9rem' }}>
                        <div className="d-flex justify-content-end align-items-center m-0 p-0">
                            <button type="button" className="hiclasstv-button px-2 py-1" data-toggle="tooltip" title="닫기" onClick={handleClickCancel}>
                                <FaTimes className="d-flex m-1" />
                            </button>
                        </div>
                        <div className="d-flex justify-content-center blue-text">
                            <FaUserEdit className="font-xlg mr-2" />
                            <h3>회원정보 수정</h3>
                        </div>
                        <div className="d-flex w-100 md-form form-group input-group p-0 mb-0 justify-content-around">
                            <div className="d-flex m-0 p-0 align-items-center justify-content-end small" style={{ width: '25%', maxWidth: '80px' }}>
                                <span className="d-flex">ID</span>
                            </div>
                            <div className="d-flex m-0 p-0" style={{ width: '75%', maxWidth: '90%' }}>
                                <input type="text" id="editInfoId" className="form-control form-control-sm" value={userInfo.userID} disabled />
                            </div>
                        </div>
                        <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-around">
                            <div className="d-flex m-0 p-0 align-items-center justify-content-end small" style={{ width: '25%', maxWidth: '80px' }}>
                                <span className="d-flex">비밀번호</span>
                            </div>
                            <div className="d-flex m-0 p-0" style={{ width: '75%', maxWidth: '90%' }}>
                                <input type="password" id="editInfoPw" className="form-control form-control-sm" placeholder={!isCanModifyPW? "******" : "새 비밀번호를 입력해 주세요."} autoComplete="new-password" disabled={!isCanModifyPW ? true : false} value={password} onChange={onChangePassword} />
                                {
                                    // !isCanModifyPW ?
                                    //     <input type="password" id="editInfoPw" className="form-control form-control-sm" placeholder="******" value={password} disabled /> :
                                    //     <input type="password" id="editInfoPw" className="form-control form-control-sm" placeholder="New Password" onChange={onChangePassword} value={password} />
                                }
                                {
                                    !isCanModifyPW &&
                                    <div className="input-group-append" style={{ minWidth: 'max-content' }}>
                                        <button className="btn btn-sm btn-primary m-0 px-3" type="button" onClick={handleIsCanModifyPW}>
                                            변경하기
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            isCanModifyPW && // 비밀번호 변경 시작했을 경우에만 비밀번호 확인이 보이게 수정 by hjkim 20230119
                            <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-around">
                                <div className="d-flex m-0 p-0 align-items-center justify-content-end small" style={{ width: '25%', maxWidth: '80px' }}>
                                    <span className="d-flex">비밀번호 확인</span>
                                </div>
                                <div className="d-flex m-0 p-0" style={{ width: '75%', maxWidth: '90%' }}>
                                    <input type="password" id="editInfoCfPw" className="form-control form-control-sm" style={{ width: '70%', maxWidth: '90%' }} placeholder={!isCanModifyPW ? "******" : "새 비밀번호를 한 번 더 입력해 주세요."} disabled={!isCanModifyPW ? true : false} autoComplete="new-password" value={passwordForConfirm} onChange={onChangePasswordForConfirm} />
                                    {
                                        // !isCanModifyPW ?
                                        //     <input type="password" id="editInfoCfPw" className="form-control form-control-sm" style={{ width: '70%', maxWidth: '90%' }} placeholder="******" value={passwordForConfirm} disabled /> :
                                        //     <input type="password" id="editInfoCfPw" className="form-control form-control-sm" style={{ width: '70%', maxWidth: '90%' }} placeholder="New Password for confirm" onChange={onChangePasswordForConfirm} value={passwordForConfirm} />
                                    }
                                </div>
                            </div>
                        }
                        <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-around">
                            <div className="d-flex m-0 p-0 align-items-center justify-content-end small" style={{ width: '25%', maxWidth: '80px' }}>
                                <span className="d-flex">휴대폰 번호</span>
                            </div>
                            <div className="d-flex m-0 p-0" style={{ width: '75%', maxWidth: '90%' }}>
                                <input type="text" id="editInfoPn" className="form-control form-control-sm" maxLength={13} placeholder="휴대폰 번호를 입력해 주세요." disabled={(!isCanModifyPN || isPhoneTestOK) ? true : false} onChange={onChangePhoneNumber} value={phoneNumber} />
                                <div className="input-group-append align-items-center" style={{ minWidth: 'max-content' }}>
                                    <button type="button" className="btn btn-sm btn-primary m-0 px-3" disabled={isPhoneTestStart ? true : false} onClick={!isCanModifyPN ? handleIsCanModifyPN : checkPhoneNumber}>
                                        {
                                            !isCanModifyPN ? "변경하기" : "인증하기"
                                        }
                                    </button>
                                    {
                                        !isPhoneTestOK && isPhoneTestStart && isAliveCertifyTime &&
                                        <span className="text-center red-text small m-0 ml-1 p-0">
                                            {getDisp()}
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            !ConstData.IS_LOCAL_VERSION && isCanModifyPN && isPhoneTestStart && // local version에서는 휴대폰 번호 인증 과정 생략.. 클라우드에서도 인증 요청 시작했을 경우에만 인증번호가 보이게 수정 by hjkim 20230119
                            <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-around">
                                <div className="d-flex m-0 p-0 align-items-center justify-content-end small" style={{ width: '25%', maxWidth: '80px' }}>
                                    <span className="d-flex">인증번호</span>
                                </div>
                                <div className="d-flex m-0 p-0" style={{ width: '75%', maxWidth: '90%' }}>
                                    <input type="text" id="editInfoCd" className="form-control form-control-sm" placeholder="인증번호를 입력해 주세요." disabled={(!isCanModifyPN || isPhoneTestOK) ? true : false} onChange={onChangeConfirmNumber} value={confirmNumber} />
                                    {
                                        isPhoneTestStart && !isPhoneTestOK &&
                                        <div className="input-group-append w-25">
                                            <button className="btn btn-md btn-primary m-0 px-3" type="button" onClick={checkConfirmPhoneNumber}>
                                                인증
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-around">
                            <div className="d-flex m-0 p-0 align-items-center justify-content-end small" style={{ width: '25%', maxWidth: '80px' }}>
                                <span className="d-flex">닉네임</span>
                            </div>
                            <div className="d-flex m-0 p-0" style={{ width: '75%', maxWidth: '90%' }}>
                                <input type="text" id="editInfoNn" className="form-control form-control-sm" placeholder="닉네임을 입력해 주세요." onChange={onChangeUserNickname} value={userNickname} />
                            </div>
                        </div>
                        <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-around">
                            <div className="d-flex m-0 p-0 align-items-center justify-content-end small" style={{ width: '25%', maxWidth: '80px' }}>
                                <span className="d-flex">E-Mail</span>
                            </div>
                            <div className="d-flex m-0 p-0" style={{ width: '75%', maxWidth: '90%' }}>
                                <input type="email" id="editInfoEm" className="form-control form-control-sm" placeholder="이메일 주소를 입력해 주세요." onChange={onChangeUserEmail} value={userEmail} />
                            </div>
                        </div>
                        <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-around">
                            <div className="d-flex m-0 p-0 align-items-center justify-content-end small" style={{ width: '25%', maxWidth: '80px' }}>
                                <span className="d-flex">식별코드</span>
                            </div>
                            <div className="d-flex m-0 p-0" style={{ width: '75%', maxWidth: '90%' }}>
                                <input type="text" id="editInfoExCode" className="form-control form-control-sm" placeholder="식별코드를 입력해 주세요." onChange={onChangeUsetExtraCode} value={userExtraCode} />
                            </div>
                        </div>
                        <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-around">
                            <div className="d-flex m-0 p-0 align-items-center justify-content-end small" style={{ width: '25%', maxWidth: '80px' }}>
                                <span className="d-flex">프로필</span>
                            </div>
                            <div className="d-flex m-0 p-0" style={{ width: '75%', maxWidth: '90%' }}>
                                <input type="text" id="editInfoPp" className="form-control form-control-sm" placeholder="프로필을 입력해 주세요." onChange={onChangeUserProfile} value={userProfile} />
                            </div>
                        </div>
                        <div className="d-flex w-100 my-2 py-2 justify-content-around">
                            <div className="d-flex m-0 p-0 align-items-center justify-content-end small" style={{ width: '25%', maxWidth: '80px' }}>
                                <span className="d-flex">프로필 이미지</span>
                            </div>
                            <div className="m-0 p-0" style={{ width: '75%', maxWidth: '90%' }}>
                                <div className="d-flex w-100 px-2" style={{ overflow: 'auto' }}>
                                    <div className="d-flex mr-2 p-0 align-items-center">
                                        <input className="d-flex mr-1 p-0" type="radio" name="profileImgs" id="profileImg1" value="1" onChange={onChangeProfileImg} defaultChecked={userProfileImg === ConstData.USER_PROFILE_IMG.KIND_1 ? true : false} />
                                        <label className="d-flex m-0" htmlFor="profileImg1">
                                            <img src={ConstData.IMG_DIR + "profile_01.png"} alt="" />
                                        </label>
                                    </div>
                                    <div className="d-flex mr-2 p-0 align-items-center">
                                        <input className="d-flex mr-1 p-0" type="radio" name="profileImgs" id="profileImg2" value="2" onChange={onChangeProfileImg} defaultChecked={userProfileImg === ConstData.USER_PROFILE_IMG.KIND_2 ? true : false} />
                                        <label className="d-flex m-0" htmlFor="profileImg2">
                                            <img src={ConstData.IMG_DIR + "profile_02.png"} alt="" />
                                        </label>
                                    </div>
                                    <div className="d-flex mr-2 p-0 align-items-center">
                                        <input className="d-flex mr-1 p-0" type="radio" name="profileImgs" id="profileImg3" value="3" onChange={onChangeProfileImg} defaultChecked={userProfileImg === ConstData.USER_PROFILE_IMG.KIND_3 ? true : false} />
                                        <label className="d-flex m-0" htmlFor="profileImg3">
                                            <img src={ConstData.IMG_DIR + "profile_03.png"} alt="" />
                                        </label>
                                    </div>
                                    <div className="d-flex mr-2 p-0 align-items-center">
                                        <input className="d-flex mr-1 p-0" type="radio" name="profileImgs" id="profileImg4" value="4" onChange={onChangeProfileImg} defaultChecked={userProfileImg === ConstData.USER_PROFILE_IMG.KIND_4 ? true : false} />
                                        <label className="d-flex m-0" htmlFor="profileImg4">
                                            <img src={ConstData.IMG_DIR + "profile_04.png"} alt="" />
                                        </label>
                                    </div>
                                    <div className="d-flex mr-2 p-0 align-items-center">
                                        <input className="d-flex mr-1 p-0" type="radio" name="profileImgs" id="profileImg5" value="5" onChange={onChangeProfileImg} defaultChecked={userProfileImg === ConstData.USER_PROFILE_IMG.KIND_5 ? true : false} />
                                        <label className="d-flex m-0" htmlFor="profileImg5">
                                            <img src={ConstData.IMG_DIR + "profile_05.png"} alt="" />
                                        </label>
                                    </div>
                                    <div className="d-flex mr-2 p-0 align-items-center">
                                        <input className="d-flex mr-1 p-0" type="radio" name="profileImgs" id="profileImg6" value="6" onChange={onChangeProfileImg} defaultChecked={userProfileImg === ConstData.USER_PROFILE_IMG.KIND_6 ? true : false} />
                                        <label className="d-flex m-0" htmlFor="profileImg6">
                                            <img src={ConstData.IMG_DIR + "profile_06.png"} alt="" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            alertMessage !== '' &&
                            <div className="d-flex justify-content-center">
                                <Alerts kind={alertKind} message={alertMessage} />
                            </div>
                        }
                        <div className="d-flex justify-content-center align-items-center my-2">
                            <button type="button" className="btn btn-sm btn-primary" onClick={checkUpdatetUser}>
                                확인
                            </button>
                            <button type="button" className="btn btn-sm btn-danger" onClick={handleClickCancel}>
                                취소
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
        </Modal>
    );
}
export default UserUpdate;