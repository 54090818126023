import React, { useState, useEffect } from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import { FaChalkboardTeacher, FaPen, FaCog, FaMinus } from 'react-icons/fa';

import { format } from 'date-fns';

import ModifyAlertModal from './modal/ModifyAlertModal';
import RemoveAlertModal from './modal/RemoveAlertModal';
import WebListArrowButton from '../etc/button/WebListArrowButton';

const WebB2CStudioInfo = ({ list_studio, list_display, selectedUserInfo, handleSelectedFunc }) => {
    
    const [isOpened, setIsOpened] = useState(false);
    
    const [lUserInfo, setUserInfo] = useState(selectedUserInfo);

    const [isModifyModalOpened, setIsModifyModalOpened] = useState(false);
    const [modifyKind, setModifyKind] = useState("");
    const [modifyItem, setModifyItem] = useState(null);
    
    const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false);
    const [removeKind, setRemoveKind] = useState("");
    const [removeItem, setRemoveItem] = useState(null);
    
    useEffect(() => {
        handleSelectedFunc({ selectedFunc: "GET_STUDIO_LIST", kind:"b2c"});
        
        return () => {
            console.log('WebB2CStudioInfo() component invisible');
        }
    }, []);

    useEffect(() => {
        setUserInfo(selectedUserInfo);
    }, [selectedUserInfo]);   

    // modify
    const toggleModifyAlertModalOpened = (isOpened) => {
        setIsModifyModalOpened(isOpened);
    }

    const onHandleModifyStudioName = ({studioSeq, studioName}) => {
        setModifyKind("name");
        setModifyItem({studioSeq, studioName});
        toggleModifyAlertModalOpened(true);
    }

    const onHandleModifyGroupLimit = ({ studioSeq, studioName, groupLimit }) => {
        setModifyKind("group-limit");
        setModifyItem({studioSeq, studioName, groupLimit});
        toggleModifyAlertModalOpened(true);
    }

    const handleModifyStudioInfo = ({kind, info, data}) => {
        toggleModifyAlertModalOpened(false);
        
        if (kind === "name") {
            if (info.studioName !== data && data !== "") {
                handleSelectedFunc({selectedFunc:"MODIFY", kind, data:{studioSeq:info.studioSeq, studioName:data}});
            }
        } else if (kind === "group-limit") {
            if (info.groupLimit !== data) {
                handleSelectedFunc({selectedFunc:"MODIFY", kind, data:{studioSeq:info.studioSeq, groupLimit:data}});
            }
        }
    }

    // remove
    const toggleRemoveAlertModalOpened = (isOpened) => {
        setIsRemoveModalOpened(isOpened);
    }

    const onHandleRemoveStudio = ({ studioSeq, studioName, subDomainSeq, studioOwner, date }) => {
        setRemoveKind("studio");
        setRemoveItem({ studioSeq, studioName, studioOwner: subDomainSeq === 1 ? studioOwner : subDomainSeq, date });
        toggleRemoveAlertModalOpened(true);
    }

    const onHandleRemoveDisplayFromStudio = ({ studioSeq, studioName, displaySeq, date }) => {
        setRemoveKind("studio-display");
        setRemoveItem({ studioSeq, studioName, displaySeq, date });
        toggleRemoveAlertModalOpened(true);
    }

    const onHandleRemoveDisplay = ({ displaySeq, date }) => {
        setRemoveKind("display");
        setRemoveItem({ displaySeq, date });
        toggleRemoveAlertModalOpened(true);
    }

    const handleRemoveItem = ({ kind, info }) => {
        toggleRemoveAlertModalOpened(false);
        handleSelectedFunc({ selectedFunc: "REMOVE", kind, data: info });
    } 

    return (
        <div className="web-main list-area">
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaChalkboardTeacher className="font-lg mr-2" aria-hidden="true" />랜선스튜디오
                        </span>
                    </div>
                    <WebListArrowButton toggle_id={'myStudioList'} list_array={list_studio} isOpened={isOpened} />
                </div>
                <UncontrolledCollapse toggler="#myStudioList" defaultOpen={true} onEntering={() => setIsOpened(true)} onExiting={() => setIsOpened(false)}>
                    <div className="px-2">
                        <div className="web-search-box">
                            <div className="web-search-option-area white">
                                <div className="web-search-option">
                                    <div className="web-search-select-label">
                                        <span><b>서비스 명</b></span>
                                    </div>
                                    <div className="web-search-select-box">
                                        <select className="custom-select small my-1" style={{ width: '100%', fontSize: '0.9rem' }} disabled >
                                            <option value={-1}>HiClassTV</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="web-search-option">
                                    <div className="web-search-select-label">
                                        <span><b>사용자 ID</b></span>
                                    </div>
                                    <div className="web-search-select-box">
                                        <div className="d-flex input-group justify-content-center m-0">
                                            <input type="text" className="d-flex flex-fill web-search-input my-1 px-2 py-1" placeholder={lUserInfo !== null && lUserInfo !== undefined ? lUserInfo.userID : ""} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column w-100 my-2">
                        {
                            list_studio !== undefined && list_studio !== null && list_studio.length > 0 ?
                                <table className="d-flex flex-column table table-sm flex-list b-top">
                                    <thead>
                                        <tr className="d-flex">
                                            <th className="area-10">
                                                <b>No.</b>
                                            </th>
                                            <th className="area-60">
                                                <div className="only-web text-center w-100">
                                                    <span className="area-50"><b>스튜디오 명</b></span>
                                                    <span className="area-25"><b>연결 인원</b></span>
                                                    <span className="area-25"><b>생성일</b></span>
                                                </div>
                                                <div className="only-mobile w-100">
                                                    <b>스튜디오 정보</b>
                                                </div>
                                            </th>
                                            <th className="area-20">
                                                <b>디스플레이</b>
                                            </th>
                                            <th className="area-10"></th>
                                        </tr>
                                        {/* <tr className="only-web">
                                            <th className="area-65" style={{ display: 'flex' }}>
                                                <span className="area-55"><b>스튜디오 명</b></span>
                                                <span className="area-25"><b>연결 인원</b></span>
                                                <span className="area-20"><b>생성일</b></span>
                                            </th>
                                            <th className="area-20"><b>디스플레이</b></th>
                                            <th className="area-15"></th>
                                        </tr> */}
                                    </thead>
                                    {
                                        list_studio.map((studio, sIdx) => (
                                            <tbody key={"studio-" + studio.studioSeq}>
                                                <tr className="d-flex">
                                                    <td className="landscape-to-portrait align-items-center justify-content-center area-10">
                                                        <span className="font-sm">{sIdx + 1}</span>
                                                    </td>
                                                    <td className="landscape-to-portrait area-60">
                                                        <div className="list-row area-50">
                                                            <div className="text-area">
                                                                <span className="font-sm info-title hiclass-text" title={studio.studioName}>{studio.studioName}</span>
                                                                <FaPen className="font-sm grey-text ml-2 button" style={{verticalAlign:'text-top'}} onClick={() => onHandleModifyStudioName({ studioSeq: studio.studioSeq, studioName: studio.studioName })} />
                                                            </div>
                                                        </div>
                                                        <div className="list-row area-25">
                                                            <span className="only-mobile">연결 인원 :&nbsp;</span>
                                                            <span className="font-sm font-weight-bold">{studio.groupLimit + " 명"}</span>
                                                            <FaCog className="font-sm grey-text ml-1 button" onClick={() => onHandleModifyGroupLimit({ studioSeq: studio.studioSeq, studioName: studio.studioName, groupLimit: studio.groupLimit })} />
                                                        </div>
                                                        <div className="list-row area-25">
                                                            <span className="only-mobile">생성일 :&nbsp;</span>
                                                            <span className="font-sm">{studio.createDT}</span>
                                                        </div>
                                                    </td>
                                                    <td className="landscape-to-portrait align-items-center justify-content-center area-20">
                                                        <span className="font-sm">{studio.list_display.length + " Display"}</span>
                                                        <button type="button" className="btn btn-sm btn-outline-primary px-2 py-1 m-0 small ml-2" id={"show_" + studio.studioSeq + "_display"} disabled={studio.list_display.length > 0 ? false : true}>
                                                            조회
                                                        </button>
                                                    </td>
                                                    <td className="landscape-to-portrait align-items-center justify-content-end area-10">
                                                        <button type="button" className="btn btn-sm m-0 py-1 px-2 red white-text" title="삭제" onClick={() => onHandleRemoveStudio({studioSeq:studio.studioSeq, studioName:studio.studioName, subDomainSeq: studio.subDomainSeq, studioOwner: studio.studioOwner, date:studio.createDT})}>
                                                            <b>삭제</b>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className="d-flex justify-content-center px-1">
                                                    <td className="d-flex p-0 area-90">
                                                        <UncontrolledCollapse toggler={"#show_" + studio.studioSeq + "_display"} defaultOpen={false} style={{ width: '100%' }}>
                                                            <table className="d-flex flex-column table table-sm sub-list table-hover mb-0">
                                                                <thead>
                                                                    <tr className="d-flex">
                                                                        <th className="area-15">
                                                                            <span className="font-sm"><b>No.</b></span>
                                                                        </th>
                                                                        <th className="area-70">
                                                                            <div className="only-web w-100">
                                                                                <span className="font-sm text-left area-35"><b>구분</b></span>
                                                                                <span className="font-sm text-left area-65"><b>생성일</b></span>
                                                                            </div>
                                                                            <div className="only-mobile font-sm w-100">
                                                                                <b>디스플레이 정보</b>
                                                                            </div>
                                                                        </th>
                                                                        <th className="area-15"></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        studio.list_display && studio.list_display.length > 0 ?
                                                                            studio.list_display.map((display, idx) => (
                                                                                <tr className="d-flex" key={"studio-" + studio.studioSeq + "-display-" + display.displaySeq}>
                                                                                    <td className="landscape-to-portrait align-items-center justify-content-center area-15">
                                                                                        <span className="font-sm">{idx + 1}</span>
                                                                                    </td>
                                                                                    <td className="landscape-to-portrait area-70">
                                                                                        <div className="list-row justify-content-start area-35">
                                                                                            <div className="text-area">
                                                                                                <span className="font-sm hiclass-text" title={'Display No.' + display.displaySeq}>
                                                                                                    {'Display No.' + display.displaySeq}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="list-row justify-content-start area-65">
                                                                                            <span className="only-mobile">생성일 :&nbsp;</span>
                                                                                            <span className="font-sm">{display.pchsDT}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="landscape-to-portrait align-items-center justify-content-center area-15">
                                                                                        <button type="button" className="btn btn-sm m-0 py-0 px-2 red white-text" title="등록 해제" onClick={() => onHandleRemoveDisplayFromStudio({ studioSeq: studio.studioSeq, studioName: studio.studioName, displaySeq: display.displaySeq, date: display.pchsDT })}>
                                                                                            <FaMinus />
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            )) :
                                                                            <tr className="d-flex w-100">
                                                                                <td className="d-flex w-100 p-0">
                                                                                    <div className="d-flex view w-100 m-3 justify-content-center grey-text" style={{ fontSize: '.9rem' }}>
                                                                                        디스플레이 목록이 없습니다.
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                    }
                                                                </tbody> 
                                                            </table> 
                                                        </UncontrolledCollapse>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))
                                    }
                                </table> :
                                <div className="d-flex view m-3 justify-content-center grey-text" style={{fontSize:'1rem'}}>
                                    스튜디오 목록이 없습니다.
                                </div>
                        }
                        </div>
                    </div>
                    <div className="px-2">
                        <div className="d-flex flex-column mt-2 pt-2 w-100" style={{borderTop:'2px solid #dee2e6'}}>
                            {
                                list_display !== undefined && list_display !== null && list_display.length > 0 ?
                                    <>
                                        <span className="py-2 info-title">
                                            ㆍ미등록 디스플레이 (<b>{list_display.length}</b>)
                                        </span>
                                        {
                                            list_display.length > 0 ?
                                                <table className="d-flex flex-column table table-sm sub-list table-hover mb-0 b-top">
                                                    <thead>
                                                        <tr className="d-flex">
                                                            <th className="area-15">
                                                                <span className="font-sm"><b>No.</b></span>
                                                            </th>
                                                            <th className="area-70">
                                                                <div className="only-web w-100">
                                                                    <span className="font-sm text-left area-35"><b>구분</b></span>
                                                                    <span className="font-sm text-left area-65"><b>생성일</b></span>
                                                                </div>
                                                                <div className="only-mobile font-sm w-100">
                                                                    <b>디스플레이 정보</b>
                                                                </div>
                                                            </th>
                                                            <th className="area-15"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            list_display.map((display, idx) => (
                                                                <tr className="d-flex" key={"display_" + display.displaySeq}>
                                                                    <td className="landscape-to-portrait align-items-center justify-content-center area-15">
                                                                        <span className="font-sm">{idx + 1}</span>
                                                                    </td>
                                                                    <td className="landscape-to-portrait area-70">
                                                                        <div className="list-row justify-content-start area-35">
                                                                            <div className="text-area">
                                                                                <span className="font-sm hiclass-text" title={'Display No.' + display.displaySeq}>
                                                                                    {'Display No.' + display.displaySeq}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="list-row justify-content-start area-65">
                                                                            <span className="only-mobile">생성일 :&nbsp;</span>
                                                                            <span className="font-sm">{display.pchsDT}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="landscape-to-portrait align-items-center justify-content-center area-15">
                                                                        <button type="button" className="btn btn-sm m-0 py-1 px-2 red white-text" title="영구 삭제" onClick={() => onHandleRemoveDisplay({ displaySeq: display.displaySeq, date: display.pchsDT })}>
                                                                            <b>삭제</b>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table> :
                                                <div className="d-flex view m-3 justify-content-center grey-text py-2" style={{ fontSize: '1rem' }}>
                                                    미등록 디스플레이 목록이 없습니다.
                                                </div>
                                        }
                                    </> :
                                    <div className="d-flex view m-3 justify-content-center grey-text py-2" style={{fontSize:'1rem'}}>
                                        미등록 디스플레이 목록이 없습니다.
                                    </div>
                            }                        
                        </div>
                    </div>
                </UncontrolledCollapse>
            </div>
            <RemoveAlertModal
                isAlertModalOpened={isRemoveModalOpened}
                kind={removeKind}
                info={removeItem}
                handleRemoveItem={handleRemoveItem}
                toggleModalOpened={toggleRemoveAlertModalOpened}
            />
            <ModifyAlertModal
                isAlertModalOpened={isModifyModalOpened}
                kind={modifyKind}
                info={modifyItem}
                handleModifyStudioInfo={handleModifyStudioInfo}
                toggleModalOpened={toggleModifyAlertModalOpened}
            />
        </div>
    )
}

export default WebB2CStudioInfo;