import React, { useState, useEffect } from 'react';
import { FaServer, FaPlusCircle } from 'react-icons/fa';
import { UncontrolledCollapse } from 'reactstrap';
import { enAdminTap } from '../../lib/ConstCommand';
import WebLocalServerList from './list/WebLocalServerList';
import WebConfigSearchBoxExceptUser from './menu/WebConfigSearchBoxExceptUser';
import WebAddLocalServer from './WebAddLocalServer';

const WebLocalServerConfig = ({ selectedSubDomainInfo, list_localSite, list_configLocalServer, list_configSubDomain, handleSelectedFunc }) => {
    const [isAddLocalServerOK, setIsAddLocalServerOK] = useState(false);

    useEffect(() => {
        //console.log('WebLocalServerConfig visible');
        
        return () => {
            //console.log('WebLocalServerConfig invisible');
        } 
    }, []);

    useEffect(() => {
        setIsAddLocalServerOK(true);
    }, [list_configLocalServer]);

    const onChangeIsAddLocalServerOK = () => {
        setIsAddLocalServerOK(!isAddLocalServerOK);
    }

    return (
        <div className="web-main list-area" style={{ maxWidth: '900px' }}>
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaServer className="font-lg mr-2" aria-hidden="true" />로컬 서버
                        </span>
                        <button type="button" className="d-flex btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" id="addLocalServer">
                            <FaPlusCircle className="font-md mr-1" />로컬 서버 등록
                        </button>
                    </div>
                </div>
                <WebConfigSearchBoxExceptUser
                    adminTap={enAdminTap.LocalServer}
                    selectedSubDomainInfo={selectedSubDomainInfo}
                    list_configSubDomain={list_configSubDomain}
                    handleSearchBoxFunc={handleSelectedFunc}
                />
                <UncontrolledCollapse toggler="#addLocalServer" defaultOpen={false}>
                    <WebAddLocalServer 
                        selectedSubDomainInfo={selectedSubDomainInfo}
                        list_localSite={list_localSite}
                        isAddLocalServerOK={isAddLocalServerOK}
                        onChangeIsAddLocalServerOK={onChangeIsAddLocalServerOK}
                        handleSelectedFunc={handleSelectedFunc}
                    />
                </UncontrolledCollapse>
                <WebLocalServerList
                    list_localSite={list_localSite}
                    list_localServer={list_configLocalServer}
                    handleSelectedFunc={handleSelectedFunc}
                />
            </div>
        </div>
    )
};

export default WebLocalServerConfig;