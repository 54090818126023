module.exports = {
    LOGIN                         : 0,
    LOGOUT                        : 1,
    LOGIN_WEB                     : 2,
    EDIT_USER_INFO                : 3,

    LIVE_INFO_INIT                : 5,

    ADD_FRIEND                    : 10,
    APPLY_FRIEND                  : 11,
    CANCEL_FRIEND_APPLY           : 12,
    CANCELED_FRIEND_APPLY         : 13,
    REJECT_FRIEND_APPLY           : 14,
    REJECTED_FRIEND_APPLY         : 15,
    REMOVE_FRIEND                 : 16,

    CHATROOM_MEMBER_REMOVE        : 100,
    CHATROOM_MEMBER_REMOVED       : 101,

    CHATROOM_JOIN                 : 102,
    CHATROOM_UPDATE               : 103,
    CHATROOM_REMOVE               : 104,

    /*** C# 에서 사용 시작 */
    SEND_MEDIASOUP_CODE_START     : 105,
    CHATROOM_FINISH               : 105,
    CHATROOM_EXIT                 : 106,

    CHATROOM_LIVETALK             : 107,
    CHATROOM_VOICETALK            : 108,
    CHATROOM_NONE                 : 109,
    SEND_MEDIASOUP_CODE_END       : 109,
    /*** C# 에서 사용 끝 */

    CHATROOM_MEMBER_LIVETALK      : 110,
    CHATROOM_MEMBER_VOICETALK     : 111,
    CHATROOM_MEMBER_NONE          : 112,

    PERFORM_CHATROOM              : 115, 

    LIVE_ON                       : 120,
    LIVE_OFF                      : 121,

    LIVE_REMOVE                   : 122,
    LIVE_APPLY                    : 123,

    LIVE_MEMBER_ADD               : 125,
    LIVE_MEMBER_REJECT            : 126,
    LIVE_MEMBER_REJECTED          : 127,
    LIVE_MEMBER_REMOVE            : 128,
    LIVE_MEMBER_REMOVED           : 129,

    /** C#에서 사용 시작  */
    SEND_HANDSUP_CODE_START       : 150,
    LIVE_JOIN                     : 150, 
    LIVE_EXIT                     : 151,
    HANDS_UP_INIT_DONE            : 152,        // 153 guest 용 hands up init(?) 만들기.

    HANDS_UP_MODE_START           : 155,        // 선생님이 Hands up mode를 시작할 때 사용  -> IMGE_MODE_START,VIEW_MODE_CAMERA
    HANDS_UP_MODE_STOP            : 156,        // 선생님이 Hands up mode를 끌 때 사용      -> IMGE_MODE_STOP

    P2P_MODE_START                : 160,        // 혹시 모를 기존의 P2P MODE START 변수 사용 에러 처리를 위해 ... 나중에 지워야함
    P2P_AUDIO_START               : 160,        // 선생님이 선택한 학생(들)과 P2P audio를 할 때 사용
    P2P_AUDIO_STOP                : 161,        // 선생님이 선택한 학생(들)과 P2P audio를 멈출 때 사용
    P2P_MODE_START_4              : 162,        // 선생님이 선택한 학생(들)과 P2P를 할 때 사용
    P2P_MODE_STOP                 : 163,        // 선생님이 선택한 학생(들)과 P2P를 종료할 때 사용-> IMGE_MODE_START

    P2P_SOUND_ON                  : 170,        // p2p sound on
    P2P_SOUND_OFF                 : 171,        // p2p sound off
    P2P_MODE_RESTART              : 172,        // restart p2p,
    P2P_SOUND_READY_LOCAL         : 173,        // for local sound init

    P2P_SOUND_DISABLE             : 175,        // p2p sound button disable
    P2P_SOUND_ENABLE              : 176,        // p2p sound button enable
    // VIEW_MODE_CAMERA              : 175,        // camera mode로 변경
    // VIEW_MODE_SCREEN              : 176,        // screen mode로 변경

    HANDS_UP_ON                   : 180,        // 학생이 Hands up 할 때 사용
    HANDS_UP_OFF                  : 181,        // 학생이 Hands up을 취소하거나, 선생님이 해당 학생의 Hands up을 종료시킬 때 사용

    HANDS_UP_ACTIVE               : 185,
    HANDS_UP_INACTIVE             : 186,

    UPDATE_SCREEN_IMAGE           : 190,        // 학생들이 이미지 업로드 후 선생님께 이미지 업데이트를 알릴 때 사용

    SEND_HANDSUP_CODE_END         : 190,
    /** C#에서 사용 끝 */

    LIVE_SOUND_LEVEL_1            : 200,        // 학생들의 음성 level
    LIVE_SOUND_LEVEL_2            : 201,
    LIVE_SOUND_LEVEL_3            : 202,
    LIVE_SOUND_LEVEL_4            : 203,
    LIVE_SOUND_LEVEL_5            : 204,

    SOUND_MUTE_MODE               : 210,
    SOUND_LISTEN_MODE             : 211,  

    DISPLAY_ON                    : 220,        // display on
    DISPLAY_OFF                   : 221,        // display off
};