import React, { useEffect, useState } from 'react';

import { stQnAItemType } from '../../../lib/ConstCommand';

import Item from './item/Item';

const QuizCardForStudent = ({ cardInfo, isFinished, handleSelectedCardFunc }) => {

    const [question, setQuestion] = useState(null);
    const [example, setExample] = useState(null);
    const [answer, setAnswer] = useState(null);
    const [selectedAnswer, setSelectedAnswer] = useState("");
    const [congratulationData, setCongratulationData] = useState(null);
    const [answerExampleYN, setAnswerExampleYN] = useState("Y");
    //const [lAl] = useState(null);

    useEffect(() => {
        console.log(cardInfo);
        if (cardInfo.data && cardInfo.data.length > 0) {
            let exampleInfo;

            cardInfo.data.forEach(item => {
                switch(item.type) {
                    case stQnAItemType.Question : 
                        setQuestion(item.data);

                        if (item.exYN === 'Y') {
                            exampleInfo = {
                                type : item.exType,
                                no : item.exNum,
                                list : []
                            }
                            setAnswerExampleYN("Y");
                        } else {
                            setAnswerExampleYN("N");
                        }
                        break;

                    case stQnAItemType.Example:
                        let exArr = [];

                        item.data.forEach(ex => {
                            exArr.push(ex);
                        });

                        if (exampleInfo) {
                            exampleInfo = {
                                ...exampleInfo,
                                list : exArr
                            };

                            setExample(exampleInfo);
                        }
                        break;

                    case stQnAItemType.Answer:
                        console.log(item.data);
                        console.log(item.exYN);
                        console.log(item.data.data);
                        if (typeof item.data === "string") {
                            setAnswer(item.data);
                        } else if (typeof item.data === "object") {
                            setAnswer(item.data.data);
                        } else {
                            console.log("item.data - ", item.data);
                        }
                        /* if (answerExampleYN === "Y") {
                            console.log("QuizCardForStudent setAnswer(item.data) - ", item.data);
                            setAnswer(item.data);
                        } else if (answerExampleYN === "N") {
                            console.log("QuizCardForStudent setAnswer(item.data.data) - ", item.data);
                            setAnswer(item.data.data);
                        } else {
                            console.log("QuizCardForStudent Answer - ", item.data);
                        } */
                        break;

                    case stQnAItemType.Congratulation:
                        setCongratulationData(item.data);
                        break;

                    default:
                        break;
                }
            });
        }
    }, [cardInfo]);

    useEffect(() => {
        if (isFinished) {
            //handleSelectedCardFunc({ selectedFunc: 'SEND_MSG_WATSON', data: selectedAnswer.no});
            if (congratulationData) {
                //console.log('축하카드 있음', congratulationData);
                handleSelectedCardFunc({ 
                    selectedFunc: 'GET_NEXT_CARD_AFTER_CONGRATS_CARD', 
                    data: congratulationData, 
                    isCongratsVisible: (selectedAnswer.no === answer.no ? true : false), 
                    isQuiz: cardInfo.code > 0 ? false : true ,
                    answer: selectedAnswer
                });
            } else {
                //console.log('축하카드 없음');
                handleSelectedCardFunc({ 
                    selectedFunc: 'GET_NEXT_CARD', 
                    isQuiz: cardInfo.code > 0 ? false : true,
                    answer: selectedAnswer 
                });
            }
        }
    }, [isFinished]);

    const onHandleKeyPad = ({ no, data }) => {
        if (cardInfo.code > 0) {
            handleSelectedCardFunc({ selectedFunc: 'SEND_MSG_WATSON', data: no});
        } 

        setSelectedAnswer({no, data});
        handleSelectedCardFunc({ selectedFunc: 'SET_PASS_POSSIBLE', data: { cardCode: cardInfo.code, msgIndex: cardInfo.idx } });
    }

    const onChangeAnswerValue = (e) => {
        console.log(e.target.value);
        setSelectedAnswer(e.target.value);

        handleSelectedCardFunc({ selectedFunc: 'SET_PASS_POSSIBLE', data: { cardCode: cardInfo.code, msgIndex: cardInfo.idx } });
    }

    return (
        cardInfo.data && cardInfo.data.length > 0 ?
            <div className="quiz-card">
                {
                    question &&
                        question.map((item, index) => (
                            <Item key={'question-' + index} info={item} />
                        ))
                }
                {
                    example && 
                        <div className={"example-area " + example.type}>
                            {
                                example.list.map((item, index) => (
                                    <div key={'ex-' + index} className="example-item student">
                                        <span style={{ width: '8%' }}>{item.no}.</span>
                                        <Item info={item} title={'ex-' + item.no} />
                                    </div>
                                ))
                            }
                            <span className="mt-3 font-sm font-weight-bold">
                                선택지
                            </span>
                                <div className={"keyPad-area" + (example.list.length > 5 ? " more5" : "")}>
                                    {
                                        example.list.map((item, index) => (
                                            isFinished ? 
                                                /*item.no === answer.no ?
                                                    <button key={'keyPad-' + index} className={"keyPad-item" + (item.no === selectedAnswer.no ? " correct" : " answer")} disabled>
                                                        {item.no}
                                                    </button> :
                                                    <button key={'keyPad-' + index} className={"keyPad-item" + (item.no === selectedAnswer.no ? " wrong" : "")} disabled>
                                                        {item.no}
                                                    </button>*/
                                                    <button key={'keyPad-' + index} className={"keyPad-item" + (selectedAnswer.no === item.no ? " selected" : "")} disabled>
                                                        {item.no}
                                                    </button> :
                                            selectedAnswer ?
                                                <button key={'keyPad-' + index} className={"keyPad-item" + (selectedAnswer.no === item.no ? " selected" : "")} onClick={() => onHandleKeyPad({ no:item.no, data:item.data })}>
                                                    {item.no}
                                                </button> :
                                            <button key={'keyPad-' + index} className="keyPad-item" onClick={() => onHandleKeyPad({ no:item.no, data:item.data })}>
                                                {item.no}
                                            </button>
                                        ))
                                    }
                                </div>
                        </div>
                }
                {
                    answerExampleYN === "N" && 
                    <div className="answer-area input-area mt-2">
                        <input type="text" className="answer-area" placeholder="정답을 입력하세요" value={selectedAnswer} onChange={onChangeAnswerValue} />
                    </div>
                }
                {
                console.log(answer)
                }
            </div> :
            <></>
    );
}

export default QuizCardForStudent;