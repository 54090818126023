import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UncontrolledCollapse } from 'reactstrap';
import { FaUserFriends } from 'react-icons/fa';

import WebFriendList from './list/WebFriendList';
import WebListArrowButton from '../etc/button/WebListArrowButton';

const WebMyFriendList = ({ list_friends, handleSelectedFriendFunc }) => {
    let history = useHistory();

    const [isFriendsOpened, setIsFriendsOpened] = useState(false);

    useEffect(() => {
        if (list_friends.length > 0) {
            setIsFriendsOpened(true);
        }
    }, [list_friends]);

    return(
        <div className="web-main list-area">
            <div className="card p-2">
                <div className="view view-cascade gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <span className="d-flex button white-text mx-1" title="친구 관리" onClick={() => { history.push('/list_friend') }}>
                        <FaUserFriends className="font-lg mr-2" aria-hidden="true" />친구
                    </span>
                    <WebListArrowButton toggle_id={'friendListToggler'} list_array={list_friends} isOpened={isFriendsOpened} />
                </div>
                <UncontrolledCollapse toggler="#friendListToggler" defaultOpen={true} onEntering={() => setIsFriendsOpened(true)} onExiting={() => setIsFriendsOpened(false)}>
                    <WebFriendList rowKey={'friend-'} list_friends={list_friends} />
                </UncontrolledCollapse>
            </div>
        </div>
    );
}

export default WebMyFriendList;