import React from 'react';

import MainContainer from '../containers/MainContainer';
import WebContentTemplate from '../components/template/WebContentTemplate';
import FriendListContainer from '../containers/FriendListContainer';

const WebManageFriendPage = ({ browserType, currentPageType, cookies, routeProps }) => {
    return (
        <MainContainer 
            browserType={browserType} 
            cookies={cookies} 
            currentPageType={currentPageType} 
            routeProps={routeProps}
        >
            <WebContentTemplate>
                <FriendListContainer
                    browserType={browserType}
                    routeProps={routeProps}
                    currentPageType={currentPageType}
                />
            </WebContentTemplate>
        </MainContainer>
    );
};

export default WebManageFriendPage;