import { getSendToMQBytes } from '../lib/classTalk/EncodeDecode';
import { enTokenCMD } from '../lib/classTalk/CodeEnum';

import { getDefaultDirectKey, getHandsUpBindKey, getHandsUpSendKey, getTeamUpBindKey, getTeamUpSendKey } from '../lib/rbmq/rbmqKey';
import ConstData from './ConstData';

const amqExchange = { name: "amq.topic", kind: "topic" };

//로그인시 eo브라우져에게 전달
const convertMQ_Info = (info) => {
    return ({
        action: "connection",
        host: info.SVER_IP,
        vHost: info.VRTL_HOST,
        port: info.SVER_PORT,
        userName: info.USER_ID,
        passWord: info.PSWD,
        exchange: info.EXCH_NAME,
    });
}

export const callEoForLogin = (rbmq_info, user_info, mode, gateWay, version, list_service, mainAddurl) => {
    const userSeq = user_info.USER_SEQ;
    let temp = mode === "hiclass" ? 1 : 0;

    const connectInfo = convertMQ_Info(rbmq_info);
    connectInfo.exchanges = [amqExchange];

    const key = getDefaultDirectKey(userSeq, temp);
    const keyArr = getHandsUpBindKey(userSeq, 0);

    const bindInfo = {
        action: "bind",
        infos: [
            { exchange: amqExchange.name, key },
            { exchange: amqExchange.name, key: keyArr[0] },
            { exchange: amqExchange.name, key: keyArr[1] }
        ],
        topicSendKeyLC: getHandsUpSendKey(userSeq, 0, 0)
    };

    let isLocalYn = ConstData.IS_LOCAL_VERSION ? 'Y' : 'N';

    const callData = { userSeq, userName: user_info.USER_NICKNAME, routingKey: key, userGateWay: gateWay, version, list_service, isLocalYn, handsUpUrl: mainAddurl };
    sendToBrowserMediaScreen(
        JSON.stringify({ connectInfo, bindInfo }),
        JSON.stringify({ callKind: "initMediaSoup", callData })
    );
}

export const callEoForLogout = (userSeq, mode) => {
    let temp = mode === "hiclass" ? 1 : 0;
    const keyArr = getHandsUpBindKey(userSeq, 0);
    const bindInfo = {
        action: "unbind",
        infos: [
            { exchange: amqExchange.name, key: getDefaultDirectKey(userSeq, temp) },
            { exchange: amqExchange.name, key: keyArr[0] },
            { exchange: amqExchange.name, key: keyArr[1] }
        ],
        topicSendKeyLC: "RESET"
    };

    sendToBrowserMediaScreen(JSON.stringify({ connectInfo: { action: "disconnection" }, bindInfo }), JSON.stringify({ callKind: "closeMediaSoup" }));
}

export const callEoForCreateMixedClass = (serverUserSeq, localUserSeq, localLiveSeq) => {
    const callData = { actionKind: "create", serverUserSeq, localUserSeq, localLiveSeq };
    sendToBrowserMediaScreen("", JSON.stringify({ callKind: "mixedClassAction", callData }));
}

export const callEoForDeleteMixedClass = (localUserSeq, localLiveSeq) => {
    const callData = { actionKind: "delete", localUserSeq, localLiveSeq };
    sendToBrowserMediaScreen("", JSON.stringify({ callKind: "mixedClassAction", callData }));
}

export const callEoForUpdateMixedClassStatus = (kind, serverUserSeq, localUserSeq, localLiveSeq) => {
    const callData = { actionKind: kind, serverUserSeq, localUserSeq, localLiveSeq };
    sendToBrowserMediaScreen("", JSON.stringify({ callKind: "mixedClassAction", callData }));
}

export const callEoForSendMixedClassMailLink = (localLiveSeq, liveName, liveTeacherNickname, list_member) => {
    const callData = { actionKind: "sendMailLink", localLiveSeq, liveName, liveTeacherNickname, list_member };
    sendToBrowserMediaScreen("", JSON.stringify({ callKind: "mixedClassAction", callData }));
}

export const callEoForRequestServerUserAuth = (localUserSeq, serverUserID, serverUserPW, isKeepSaving) => {
    const callData = { actionKind: "requestAuth", localUserSeq, serverUserID, serverUserPW, isKeepSaving };
    sendToBrowserMediaScreen("", JSON.stringify({ callKind: "mixedClassAction", callData }));
}

export const callEoForSendMediaScreen = (actionKind, actionData) => {
    console.log('callEoForSendMediaScreen');
    let sendKind = actionKind;
    let sendData = actionData;
    //console.log(actionKind);
    //console.log(actionData);

    sendToBrowserMediaScreen("", JSON.stringify({ callKind: sendKind, callData: sendData }));
}

export const callEoForSendWhisperAction = (actionKind, actionData, etcData) => {
    let sendData = {
        kind: actionKind,
        data: actionData
    };

    console.log(`callEoForSendWhisperAction -- sendData[`, sendData, `]`);
    sendToBrowserAction(JSON.stringify({ kind: 'whisper', data: sendData}), JSON.stringify(etcData));
}

export const callEoForSendAction = (actionKind, actionData, etcData) => {
    console.log(actionKind, actionData, etcData);
    
    let sendKind = actionKind;
    let sendData = actionData;
    //console.log(actionKind);
    //console.log(actionData);

    //callEoForSendAction("message_talk", { text: msgText, emoticSeq: state.selectedEmoticonSeq, userNickname });

    if (actionKind === "whisperFileShare") {
        const { filename, userNickname } = actionData;

        const bytesData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_UploadAndShareFiles,
            filename
        }, userNickname);
        sendData = btoa(String.fromCharCode.apply(null, bytesData.forHiclass));

        sendToBrowserAction(JSON.stringify({ kind: sendKind, data: sendData }), etcData);
    } else if (actionKind === "quiz") {
        const { kind, action, information, userNickname } = actionData;

        const bytesData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
            text: JSON.stringify({ kind, action, information }),
        }, userNickname);

        sendKind = "sendRawData";
        sendData = btoa(String.fromCharCode.apply(null, bytesData.forHiclass));

        sendToBrowserAction(JSON.stringify({ kind: sendKind, data: sendData }), etcData);
    } else if (actionKind === "audio_memo") {
        const { voiceData, userSeq, userNickname } = actionData;
        let reader = new FileReader();
        let array;
        reader.readAsArrayBuffer(voiceData);
        reader.onload = () => {
            array = reader.result;
            //console.log("callEoForSendAction() - audio_memo onload");
            const bytesData =
                getSendToMQBytes({
                    tokenCmd: enTokenCMD.chat_Voice,
                    CLASSID: 0,
                    PENID: userSeq,
                    data: array
                }, userNickname);
            sendKind = "sendRawData";
            sendData = btoa(String.fromCharCode.apply(null, bytesData.forHiclass));

            //sendToBrowserAction(JSON.stringify({ kind: sendKind, data: sendData }), JSON.stringify(etcData));
            sendToBrowserAction(JSON.stringify({ kind: sendKind, data: sendData }), etcData);
        };
    } else {
        if (actionKind === "message_talk") {
            const { text, emoticSeq, userNickname } = actionData;
            const bytesData = getSendToMQBytes({
                tokenCmd: enTokenCMD.chat_Text,
                emoGIDno: 0,   /* 0번 고정 */
                emoMIDno: emoticSeq,
                text
            }, userNickname);
            sendKind = "sendRawData";
            sendData = btoa(String.fromCharCode.apply(null, bytesData.forHiclass));
        }

        //sendToBrowserAction(JSON.stringify({ kind: sendKind, data: sendData }), JSON.stringify(etcData));
        sendToBrowserAction(JSON.stringify({ kind: sendKind, data: sendData }), etcData);
    }
}

export const callEoForSendMessage = (tokenCmd, actionKind, actionData, etcData) => {
    let sendKind = actionKind;
    let sendData = actionData;
    let sendEtcData = etcData === undefined || etcData === null ? "" : etcData;
    console.log('callEoForSendMessage - ', sendKind, sendData, sendEtcData);

    if (tokenCmd === enTokenCMD.ct_SendToWebJSonInfo) {
        const { kind, action, information, userNickname } = actionData;
        const bytesData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
            text: JSON.stringify({ kind, action, information })
        }, userNickname);
        sendData = btoa(String.fromCharCode.apply(null, bytesData.forHiclass));

        sendToBrowserAction(JSON.stringify({ kind: sendKind, data: sendData }), sendEtcData);
    }
}

export const callEoForJoinChatRoom = (roomSeq, userSeq, chatRoomName, chatRoomType, members_info) => {
    const keyArr = getTeamUpBindKey(roomSeq, userSeq);
    const bindInfo = {
        action: "bind",
        infos: [
            { exchange: amqExchange.name, key: keyArr[0] },
            { exchange: amqExchange.name, key: keyArr[1] }
        ],
        topicSendKeyCC: getTeamUpSendKey(roomSeq, userSeq)
    };

    const chatRoomInfo = { chatRoomSeq: roomSeq, chatRoomName, chatRoomType };
    const callData = { membersInfo: members_info, chatRoomInfo };

    sendToBrowserMediaScreen(JSON.stringify({ bindInfo }), JSON.stringify({ callKind: "joinChatRoom", callData }));
}

export const callEoForFinishChatRoom = (roomSeq, userSeq) => {
    const keyArr = getTeamUpBindKey(roomSeq, userSeq);
    const bindInfo = {
        action: "unbind",
        infos: [
            { exchange: amqExchange.name, key: keyArr[0] },
            { exchange: amqExchange.name, key: keyArr[1] }
        ],
        topicSendKeyCC: "RESET"
    };
    const callData = { chatRoomSeq: roomSeq, userSeq };

    sendToBrowserMediaScreen(JSON.stringify({ bindInfo }), JSON.stringify({ callKind: "finishChatRoom", callData }));
}

export const callEoForUpdateChatRoomName = (roomSeq, chatRoomName) => {
    const callData = {
        chatRoomSeq: roomSeq,
        chatRoomName: chatRoomName
    };

    sendToBrowserMediaScreen("", JSON.stringify({ callKind: "updateChatRoomName", callData }));
}

export const callEoForUpdateChatRoomMemberName = (roomSeq, userSeq, userNickname) => {
    const callData = {
        chatRoomSeq: roomSeq,
        userSeq: userSeq,
        userNickname: userNickname
    };

    sendToBrowserMediaScreen("", JSON.stringify({ callKind: "updateChatRoomMemberName", callData }));
}

export const callEoForAddChatRoomMembers = (roomSeq, members_info) => {
    const callData = {
        chatRoomSeq: roomSeq,
        membersInfo: members_info
    };

    sendToBrowserMediaScreen("", JSON.stringify({ callKind: "addChatRoomMembers", callData }));
}

export const callEoForUpdateHandsUpWindowStatus = (userSeq, status) => {
    const callData = { userSeq, status };
    sendToBrowserHandsUp(JSON.stringify({ kind: "updateLiveHandsUpWindowStatus", data: callData }));
}

export const callEoForUpdateGroupStudyWindowStatus = (userSeq, status) => {
    const callData = { userSeq, status };
    sendToBrowserHandsUp(JSON.stringify({ kind: "updateLiveGroupStudyWindowStatus", data: callData }));
}

export const callEoForUpdateHandsUpStatus = (userSeq, liveSeq, list_member, selectStatus, handsUpYN, groupLimit, startSeqOfSmartTV, binders) => {
    const callData = { userSeq, liveSeq, list_member, selectStatus, handsUpYN, groupLimit, startSeqOfSmartTV, binders };
    sendToBrowserHandsUp(JSON.stringify({ kind: "updateLiveHandsUpStatus", data: callData }));
}

export const callEoForUpdateBroadcastStatus = (liveSeq, list_guest, openYN) => {
    const callData = { liveSeq, list_guest, openYN };
    sendToBrowserHandsUp(JSON.stringify({ kind: "updateBroadcastStatus", data: callData }));
}

export const toggleMediaSoupSenderCall = () => {
    sendToBrowserMediaScreen("", JSON.stringify({ callKind: "toggleSender" }));
}

export const callEoAddLiveMember = (liveSeq, member_info) => {
    const callData = {
        liveSeq,
        userSeq         : member_info.userSeq,
        userNickname    : member_info.userNickname,
        userJoinstatus  : member_info.userJoinstatus,
        liveCertiYN     : member_info.liveCertiYN,
        isInkON         : member_info.isInkON,
        isSoundON       : member_info.isSoundON,
        isScreenON      : member_info.isScreenON,
        isSelect        : member_info.isSelect,
        isPullScreen    : member_info.isPullScreen,
        isPushScreen    : member_info.isPushScreen
    };

    sendToBrowserHandsUp(JSON.stringify({ kind: "addLiveMember", data: callData }));
}

// remocon action //
export const callEoForUpdateHandsUpMemberStatus = ({ kind, memberSeqList, memberSeq, isSelect }) => {
    let callData;
    if (memberSeq !== undefined && memberSeq !== null) {
        callData = {
            userSeq: memberSeq,
            isSelect: isSelect
        };
    } else {
        callData = {
            userSeqList: memberSeqList,
            isSelect: isSelect
        };
    }

    sendToBrowserHandsUp(JSON.stringify({ kind, data: callData }));
}

export const callEoForUpdateTeamUpMemberStatus = ({ kind, memberSeqList, memberSeq, isSelect }) => {
    let callData;
    if (memberSeq !== undefined && memberSeq !== null) {
        callData = {
            userSeq: memberSeq,
            isSelect: isSelect
        };
    } else {
        callData = {
            userSeqList: memberSeqList,
            isSelect: isSelect
        };
    }

    sendToBrowserMediaScreen("", JSON.stringify({ callKind: kind, data: callData }));
}

export const callEoForSelectSoundDeviceInfo = ({ kind, command, target, value }) => {
    const callData = { kind, value, target };
    sendToBrowserDeviceInfo(JSON.stringify({ callKind: command, callData }));
}
////////////////////

const sendToBrowserAction = (data1, data2) => {
    //console.log('sendToBrowserAction - ACTION_SEND');
    if (window.hiClassTvWebApi !== undefined) {
        window.hiClassTvWebApi("ACTION_SEND", data1, data2);
    } else if (window.eoapi !== undefined && window.eoapi !== null) {
        window.eoapi.extInvoke("ACTION_SEND", [data1, data2]);
    }
}

const sendToBrowserJson = (data1, data2) => {
    console.log('sendToBrowserJson - JSON_SEND');

    if (window.hiClassTvWebApi !== undefined) {
        window.hiClassTvWebApi("JSON_SEND", data1, data2);
    } else if (window.eoapi !== undefined && window.eoapi !== null) {
        window.eoapi.extInvoke("JSON_SEND", [data1, data2]);
    }
}

const sendToBrowserMediaScreen = (data1, data2) => {
    console.log('sendToBrowserMediaScreen - MEDIA_SCREEN');
    if (window.hiClassTvWebApi !== undefined) {
        window.hiClassTvWebApi("MEDIA_SCREEN", data1, data2);
    } else if (window.eoapi !== undefined && window.eoapi !== null) {
        window.eoapi.extInvoke("MEDIA_SCREEN", [data1, data2]);
    }
}

const sendToBrowserHandsUp = (data) => {
    console.log('sendToBrowserHandsUp - HANDS_UP_SEND');
    if (window.hiClassTvWebApi !== undefined) {
        window.hiClassTvWebApi("HANDS_UP_SEND", "", data);
    } else if (window.eoapi !== undefined && window.eoapi !== null) {
        window.eoapi.extInvoke("HANDS_UP_SEND", ["", data]);
    }
}

const sendToBrowserDeviceInfo = (data) => {
    console.log('sendToBrowserDeviceInfo - MEDIA_SCREEN');
    if (window.hiClassTvWebApi !== undefined) {
        window.hiClassTvWebApi("MEDIA_SCREEN", "", data);
    } else if (window.eoapi !== undefined && window.eoapi !== null) {
        window.eoapi.extInvoke("MEDIA_SCREEN", ["", data]);
    }
}