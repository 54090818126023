import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UncontrolledCollapse } from 'reactstrap';
import { FaLaptop, FaPlusCircle, FaCircle } from 'react-icons/fa';

import ConstData from '../../lib/ConstData';
import { getEntryMemberCount } from '../../lib/func/ExportFunction';

import CustomProfileImage from '../user/profile/CustomProfileImage';
import Alerts from '../etc/list/Alerts';
import WebListArrowButton from '../etc/button/WebListArrowButton';

const WebMyBroadcastLiveList = ({ list_broadcastLive, isCertifiedLiveCode, isCreateLiveSuccess, createdLiveCode, handleSelectedFunc, alertKind, alertMessage }) => {
    let history = useHistory();

    const [liveCode, setLiveCode] = useState("");
    const [liveName, setLiveName] = useState("");
    const [lAlertMessage, setAlertMessage] = useState("");
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [isOpened, setIsOpened] = useState(false);
    const [lIsCertifiedLiveCode, setIsCertifiedLiveCode] = useState(false);

    useEffect(() => {
        if (list_broadcastLive.length > 0) {
            setIsOpened(true);
        }
    }, [list_broadcastLive]);

    useEffect(() => {
        setLiveCode(createdLiveCode);
    }, [createdLiveCode]);

    useEffect(() => {
        setIsCertifiedLiveCode(isCertifiedLiveCode);
    }, [isCertifiedLiveCode]);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    useEffect(() => {
        if (isCreateLiveSuccess) {
            setLiveName("");
        }
    }, [isCreateLiveSuccess]);

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            checkCreateLive();
        }
    }

    const onChangeLiveName = (e) => {
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage("");
        }

        let str = e.target.value;
        if (str.length > 50) {
            str = str.substr(0, 50);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage('클래스 이름은 50자 이내로 입력해야 합니다.');
        }

        setLiveName(str);
    }

    const onChangeLiveCode = (e) => {
        if (lIsCertifiedLiveCode) {
            handleSelectedFunc({ selectedFunc:"TOGGLE_CERTIFIED_CODE_WEB" });
        }

        let str = checkInputValue(e.target.value);
        if (str.length > 10) {
            str = str.substr(0, 10);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage('코드는 10자 이내로 입력해야 합니다.');
        }

        setLiveCode(str);
    }

    const checkCreateLive = () => {
        if (lIsCertifiedLiveCode) { // 클래스 코드 중복체크 또는 자동생성 성공한 경우
            if (liveName === "" || liveName === undefined || liveName === null) {
                setAlertMessage("클래스 이름을 입력하십시오");
                setAlertKind(ConstData.ALERT_KIND.DANGER);
            } else {
                handleSelectedFunc({ selectedFunc: "CREATE_OK_WEB", liveName: liveName, liveCode: liveCode });
            }
        } else { // 클래스 코드 중복체크 또는 자동생성을 하지 않은 경우
            if (liveCode === "" || liveCode === undefined || liveCode === null) {
                setAlertMessage("클래스 코드를 입력하십시오");
                setAlertKind(ConstData.ALERT_KIND.DANGER);
            } else {
                setAlertMessage("클래스 코드 중복체크를 해 주십시오");
                setAlertKind(ConstData.ALERT_KIND.DANGER);
            }
        }
    }

    const checkInputValue = (str) => {
        let convertStr = str.replace(' ', '');
        convertStr = convertStr.replace(/[^a-z0-9@_.-]/gi, '');
        return convertStr;
    }

    const onCloseAddLive = () => {
        setLiveCode("");
        setLiveName("");
        setAlertKind(ConstData.ALERT_KIND.NONE);
        setAlertMessage("");
    }

    return(
        <div className="web-main list-area">
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaLaptop className="font-lg mr-2" aria-hidden="true" />진행 CLASS
                        </span>
                        <button type="button" className="btn d-flex btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" id="createBroadcastLive">
                            <FaPlusCircle className="font-md mr-1" />클래스 생성
                        </button>
                    </div>
                    <WebListArrowButton toggle_id={'broadcastLiveList'} list_array={list_broadcastLive} isOpened={isOpened} />
                </div>
                <UncontrolledCollapse toggler="#createBroadcastLive" defaultOpen={false} onExiting={onCloseAddLive}>
                    <div className="d-flex py-3 align-items-center justify-content-center border-bottom">
                        <div className="card web-list-card border-primary">
                            <div className="input-area mt-0">
                                <div className="input-label">
                                    <span><b>클래스 이름</b></span>
                                </div>
                                <div className="input-data">
                                    <div className="d-flex input-group justify-content-center">
                                        <input type="text" id="inputLiveName" value={liveName} className="d-flex flex-fill web-search-input" placeholder="클래스 이름을 입력하세요" onChange={onChangeLiveName} />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex w-100 justify-content-between">
                                <span style={{ width: '35%', maxWidth: '80px' }}></span>
                                <span className="input-description area-75">- 1자~50자, 한글, 영문, 숫자, 특수문자 사용 가능</span>
                            </div>
                            <div className="input-area">
                                <div className="input-label">
                                    <span><b>클래스 코드</b></span>
                                </div>
                                <div className="input-data">
                                    <div className="d-flex input-group justify-content-center">
                                        <input type="email" id="inputLiveCode" value={liveCode} className="d-flex flex-fill web-search-input mr-2" style={{width:'50%'}} placeholder="클래스 코드를 입력하세요" onChange={onChangeLiveCode} onKeyPress={handleKeyPress} />
                                        <div className="d-flex" style={{minWidth:'max-content', verticalAlign:'middle'}}>
                                            <button type="button" className="d-flex btn btn-sm hiclasstv-blue align-items-center white-text p-1 m-0" style={{width:'max-content'}} onClick={() => handleSelectedFunc({selectedFunc:"GET_CODE_WEB"})}>
                                                자동생성
                                            </button>
                                            <button type="button" className="d-flex btn btn-sm hiclasstv-blue align-items-center white-text p-1 m-0 ml-1" style={{width:'max-content'}} onClick={() => handleSelectedFunc({ selectedFunc: "CHECK_WEB", liveCode: liveCode })} disabled={lIsCertifiedLiveCode === false && liveCode !== "" ? false : true}>
                                                중복체크
                                            </button>
                                        </div>                  
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex w-100 justify-content-between">
                                <span style={{ width: '35%', maxWidth: '80px' }}></span>
                                <span className="input-description area-75">- 1자~10자, 영문, 숫자 사용 가능</span>
                            </div>
                            <Alerts kind={lAlertKind} message={lAlertMessage} />
                            <div className="d-flex text-center mt-3">
                                <button type="button" className="btn btn-md hiclasstv-blue white-text m-0 px-4 py-2" onClick={() => handleSelectedFunc({ selectedFunc: "CREATE_OK_WEB", liveName: liveName, liveCode: liveCode })} disabled={lIsCertifiedLiveCode === true && liveName !== "" ? false : true}>
                                    <b>생성</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </UncontrolledCollapse>
                <div className="px-2">
                    {
                        list_broadcastLive !== undefined && list_broadcastLive !== null && list_broadcastLive.length > 0 ?
                        <UncontrolledCollapse toggler="#broadcastLiveList" defaultOpen={true} onEntering={() => setIsOpened(true)} onExiting={() => setIsOpened(false)}>
                            <table className="d-flex flex-column table table-sm flex-list mb-0">
                                <thead>
                                    <tr className="d-flex">
                                        <th className="area-45">
                                            <b>클래스 이름</b>
                                        </th>
                                        <th className="area-25">
                                            <b>인원</b>
                                        </th>
                                        <th className="area-15">
                                            <b>멤버</b>
                                        </th>
                                        <th className="area-15"></th>
                                    </tr>
                                </thead>
                                    {
                                        list_broadcastLive.map(live => (
                                            <tbody key={live.liveSeq}>
                                                <tr className="d-flex">
                                                    <td className="d-flex align-items-center justify-content-center area-45">
                                                        <div className="text-area">
                                                            <span className={(live.liveStatus === 'Y' ? "info-title " : "") + "hiclass-text"} title={live.liveName}><b>{live.liveName}</b></span>
                                                            {
                                                                live.liveStatus === "Y" ?
                                                                    <span className="live-status on ml-1">ON</span> :
                                                                    <span className="live-status off ml-1">OFF</span>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="d-flex align-items-center justify-content-center area-25">
                                                        {
                                                            live.list_member !== undefined && live.list_member !== null ?
                                                                <>
                                                                    <span className="green-text font-weight-bold">{getEntryMemberCount('handsUp', live.list_member)}</span>
                                                                    <span>&nbsp;{"/ " + live.list_member.length}</span>
                                                                </> :
                                                                <>
                                                                    <b>0</b> / 0
                                                                </>
                                                        }
                                                    </td>
                                                    <td className="d-flex align-items-center justify-content-center area-15">
                                                        <button type="button" className="btn btn-sm btn-outline-primary py-1 px-2 m-0" id={"show_"+live.liveSeq+"_member"}>조회</button>
                                                    </td>
                                                    <td className="d-flex align-items-center justify-content-center area-15">
                                                        <button type="button" className="btn btn-sm hiclasstv-yellow white-text py-1 px-2 m-0" onClick={() => handleSelectedFunc({selectedFunc:"JOIN", liveSeq:live.liveSeq, liveName:live.liveName})}>입장</button>
                                                    </td>
                                                </tr>
                                                <tr className="d-flex">
                                                    <td className="d-flex w-100 p-0">
                                                        <UncontrolledCollapse toggler={"#show_"+live.liveSeq+"_member"} defaultOpen={false} style={{width:'100%'}}>
                                                            <table className="d-flex flex-column table-sm sub-list table-hover mb-0">
                                                                <thead>
                                                                    <tr className="d-flex">
                                                                        <th className="d-flex align-items-center area-80">
                                                                            <span className="pl-5"><b>멤버</b></span>
                                                                        </th>
                                                                        <th className="d-flex align-items-center justify-content-center area-20">
                                                                            <b>상태</b>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        live.list_member !== undefined && live.list_member !== null && live.list_waitMember !== undefined && live.list_waitMember !== null &&
                                                                        (live.list_member.length > 0 || live.list_waitMember.length > 0) ?
                                                                            <>
                                                                                {
                                                                                    live.list_member.map(
                                                                                        member => (
                                                                                            <tr key={member.userSeq} className="d-flex">
                                                                                                <td className="d-flex align-items-center full">
                                                                                                    <div className="d-flex align-items-center justify-content-center area-5">
                                                                                                        {
                                                                                                            member.userJoinStatus === 'Y' &&
                                                                                                            <span className="hiclass-text px-1">
                                                                                                                <FaCircle className="d-flex font-sm green-text" />
                                                                                                            </span>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="d-flex align-items-center justify-content-center area-15 fit-content">
                                                                                                        <CustomProfileImage url={member.profileImgUrl} style={{width:'2rem', height:'2rem'}} alt={member.userNickname} />
                                                                                                    </div>
                                                                                                    <div className="d-flex full">
                                                                                                        <div className="text-area">
                                                                                                            <span className="hiclass-text" title={member.userNickname}>{member.userNickname}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className="d-flex align-items-center justify-content-end area-30 fit-content">
                                                                                                    {
                                                                                                        member.liveCertiYN === "Y" &&
                                                                                                        <span className="small grey-text px-2">승인완료</span>
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    )
                                                                                }{
                                                                                    live.list_waitMember.map(
                                                                                        member => (
                                                                                            <tr key={member.userSeq} className="d-flex">
                                                                                                <td className="d-flex align-items-center full">
                                                                                                    <div className="area-5" />
                                                                                                    <div className="d-flex align-items-center justify-content-center area-15 fit-content">
                                                                                                        <CustomProfileImage url={member.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={member.userNickname} />
                                                                                                    </div>
                                                                                                    <div className="d-flex full">
                                                                                                        <div className="text-area">
                                                                                                            <span className="hiclass-text hiclasstv-darkgrey-text" title={member.userNickname}><b>{member.userNickname}</b></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className="d-flex align-items-center justify-content-end area-30 fit-content">
                                                                                                    {
                                                                                                        member.liveCertiYN === "N" &&
                                                                                                        <>
                                                                                                            <button type="button" className="btn d-flex btn-sm green white-text m-0 py-1 px-2 small" style={{ width: 'max-content' }} onClick={() => handleSelectedFunc({ selectedFunc: "MEMBER_ACCEPT_WEB", liveSeq: live.liveSeq, memberSeq: member.userSeq })}>승인</button>
                                                                                                            <button type="button" className="btn d-flex btn-sm red white-text m-0 ml-1 py-1 px-2 small" style={{ width: 'max-content' }} onClick={() => handleSelectedFunc({ selectedFunc: "MEMBER_REJECT_WEB", liveSeq: live.liveSeq, memberSeq: member.userSeq })}>거절</button>
                                                                                                        </>
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    )
                                                                                }
                                                                            </> :
                                                                            <tr className="d-flex w-100">
                                                                                <td className="d-flex w-100 p-0">
                                                                                    <div className="d-flex w-100 view m-3 justify-content-center grey-text" style={{ fontSize: '0.9rem' }}>
                                                                                        해당 클래스의 멤버가 없습니다.
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </UncontrolledCollapse>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))
                                    }
                            </table>
                        </UncontrolledCollapse> :
                        <div className="view m-3 text-center grey-text">
                            생성한 클래스 목록이 없습니다.
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
export default WebMyBroadcastLiveList;