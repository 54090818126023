import React from 'react';

import WebConfigSubDomainInfo from './WebConfigSubDomainInfo';

const WebConfigSubDomainList = ({ userKind, isStandardType, list_subDomain, goToConfigPage, goToConfigMemberPage, goToPassListPage, toggleModalOpened, togglePWModalOpened, toggleSubDomainAdminModalOpened, toggleCreateSubDomainAdminModalOpened }) => {
    return (
        list_subDomain && list_subDomain.length > 0 ?
            <table className="d-flex flex-column table-sm flex-list mb-0">
                <thead>
                    <tr className="d-flex">
                        <th className="area-35">
                            <b>서브 도메인 이름</b>
                        </th>
                        <th className="area-25">
                            <div className="only-web w-100">
                                <span className="area-60"><b>접속현황</b></span>
                                <span className="area-40"><b>멤버</b></span>
                            </div>
                            <div className="only-mobile w-100" style={{ justifyContent: 'center' }}>
                                <b>사용자</b>
                            </div>
                        </th>
                        <th className="area-25">
                            <div className="only-web w-100">
                                <span className="area-50"><b>팀업</b></span>
                                <span className="area-50"><b>클래스</b></span>
                            </div>
                            <div className="only-mobile w-100">
                                <b>팀업/클래스</b>
                            </div>
                        </th>
                        <th className="area-15">
                            {
                                isStandardType &&
                                <b>이용권</b>
                            }
                        </th>
                    </tr>
                </thead>
                {
                    list_subDomain.map((subDomain, idx) => (
                        <WebConfigSubDomainInfo
                            key={"config-" + subDomain.subDomainSeq}
                            idx={idx}
                            userKind={userKind}
                            isStandardType={isStandardType}
                            subDomain_info={subDomain}
                            goToConfigPage={goToConfigPage}
                            goToConfigMemberPage={goToConfigMemberPage}
                            goToPassListPage={goToPassListPage}
                            toggleModalOpened={toggleModalOpened}
                            togglePWModalOpened={togglePWModalOpened}
                            toggleSubDomainAdminModalOpened={toggleSubDomainAdminModalOpened}
                            toggleCreateSubDomainAdminModalOpened={toggleCreateSubDomainAdminModalOpened}
                        />
                    ))
                }
            </table> :
            <div className="d-flex view m-3 justify-content-center grey-text">
                관리 가능한 서브 도메인 목록이 없습니다.
            </div>
    );
}

export default WebConfigSubDomainList;