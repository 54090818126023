import React from 'react';

import WebWhisperArea from './WebWhisperArea';
import InputGroupBox from '../classtalk/InputGroupBox';
import FunctionList from '../classtalk/FunctionList';
import EmoticItem from '../classtalk/emoticon/EmoticItem';
import AudioRecord from '../classtalk/voiceMemo/AudioRecord';
import EmoticList from '../classtalk/emoticon/EmoticList';

const WebWhisperModal = ({ openInfo, isLiveChattingLocked, msgInfo, handleSelectedClassTalkFunc }) => {
    
    return (
        <div className='d-flex flex-column'>
            <WebWhisperArea
                msgInfo={msgInfo}
                handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
            />
            {
                openInfo.selectedEmoticonSeq > -1 &&
                <EmoticItem
                    selIndex={openInfo.selectedEmoticonSeq}
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                />
            } {
                openInfo.isEmoticonSelected &&
                <EmoticList
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                />
            } {
                openInfo.isAudioOpened ?
                <AudioRecord
                    onHandleClassTalkFunc={handleSelectedClassTalkFunc}
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                /> :
                <InputGroupBox
                    selIndex={openInfo.selectedEmoticonSeq}
                    isLiveChattingLocked={isLiveChattingLocked}
                    isRunningWatson={false}
                    isSpeakingMode={false}
                    onHandleClassTalkFunc={handleSelectedClassTalkFunc}
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                />
            } {
                openInfo.isAddFunctionOpened &&
                <FunctionList
                    isWhisper={true}
                    isChatting={true}
                    isRunningWatson={false}
                    isSpeakingMode={false}
                    isActiveLive={false}
                    isPanelWindowOpened={false}
                    onHandleClassTalkFunc={handleSelectedClassTalkFunc}
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                />
            }
        </div>
        
    )
}

export default WebWhisperModal;
