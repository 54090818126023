import React from 'react';
import { FaCrown } from 'react-icons/fa';

const WebConfigAdminGrantButton = ({ subDomainSeq, subDomainName, member_info, toggleModalOpened }) => {
    return (
        <div className="list-row area-50">
            <span 
                className={"button small outline bold p-1 mr-1" + (member_info.isConfigAdmin ? " outline-hiclasstv-yellow hiclasstv-yellow-text" : " outline-hiclasstv-grey hiclasstv-grey-text")} 
                style={{ borderRadius: '1rem', boxShadow: 'none' }} 
                title={member_info.isConfigAdmin ? "관리자 지정 해제" : "관리자 추가"}
                onClick={() => toggleModalOpened(true, subDomainSeq, subDomainName, member_info)}
            >
                <FaCrown className="d-flex font-sm" />
            </span>
        </div>
    );
}

export default WebConfigAdminGrantButton;