import { createAction, handleActions } from 'redux-actions'
import produce from 'immer';
import createRequestThunk from '../lib/createRequestThunk';
import createMultiAction from '../lib/createMultiAction'

import ConstData from '../lib/ConstData';

import { enTokenCMD } from '../lib/classTalk/CodeEnum';

import { callEoForJoinChatRoom, callEoForFinishChatRoom, 
         callEoForUpdateChatRoomName, callEoForUpdateChatRoomMemberName, 
         callEoForAddChatRoomMembers, callEoForSendAction, callEoForSendMessage } from '../lib/EoBrowserSendUtil';

import { getTeamUpBindKey, getTeamUpSendKey, getHandsUpBindKey, getHandsUpSendKey } from '../lib/rbmq/rbmqKey';

import { create_web, create, update_name, update_type, 
         join, finish, exit, remove_chat, remove_chat_member, 
         get_inviteable_list, invite_chat_member } from '../lib/api/chat';

import { FRIEND_CHAT_RESULT } from './friend';
import { APPEND_ALERT_MESSAGE, APPEND_ALERT_MESSAGE_WITH_RESTAPI } from './classTalk';

   
// web
const CREATE_WEB_CHATROOM                   = 'chat/CREATE_WEB_CHATROOM';
const CREATE_WEB_CHATROOM_SUCCESS           = 'chat/CREATE_WEB_CHATROOM_SUCCESS';

export const createWebChatRoom              = createRequestThunk(CREATE_WEB_CHATROOM, create_web);

// hiclass
export const CHAT_INIT                      = 'chat/CHAT_INIT';
export const CHAT_STATE_INIT                = 'chat/CHAT_STATE_INIT';
const CHAT_STATE_INIT_SUCCESS               = 'chat/CHAT_STATE_INIT_SUCCESS';

//export const START_ALONE_CHATTING         = 'chat/START_ALONE_CHATTING';
const START_ALONE_CHATTING                  = 'chat/START_ALONE_CHATTING';
export const STOP_ALONE_CHATTING            = 'chat/STOP_ALONE_CHATTING';

const CREATE_CHATROOM                       = 'chat/CREATE_CHATROOM';
const CREATE_CHATROOM_SUCCESS               = 'chat/CREATE_CHATROOM_SUCCESS';

//const SAVE_CHATROOM_INFO                  = 'chat/SAVE_CHATROOM_INFO';
//const SAVE_CHATROOM_INFO_SUCCESS          = 'chat/SAVE_CHATROOM_INFO_SUCCESS';

const UPDATE_CHATROOM_NAME                  = 'chat/UPDATE_CHATROOM_NAME';
const UPDATE_CHATROOM_NAME_SUCCESS          = 'chat/UPDATE_CHATROOM_NAME_SUCCESS';

const UPDATE_CHATROOM_TYPE                  = 'chat/UPDATE_CHATROOM_TYPE';
const UPDATE_CHATROOM_TYPE_SUCCESS          = 'chat/UPDATE_CHATROOM_TYPE_SUCCESS';

const JOIN_CHATTING                         = 'chat/JOIN_CHATTING';
const JOIN_CHATTING_SUCCESS                 = 'chat/JOIN_CHATTING_SUCCESS';

const FINISH_CHATTING                       = 'chat/FINISH_CHATTING';
const FINISH_CHATTING_SUCCESS               = 'chat/FINISH_CHATTING_SUCCESS';

const EXIT_CHATROOM                         = 'chat/EXIT_CHATROOM';
const EXIT_CHATROOM_SUCCESS                 = 'chat/EXIT_CHATROOM_SUCCESS';

const REMOVE_CHATROOM                       = 'chat/REMOVE_CHATROOM';
const REMOVE_CHATROOM_SUCCESS               = 'chat/REMOVE_CHATROOM_SUCCESS';

const REMOVE_EXIT_CHATROOM                  = 'chat/REMOVE_EXIT_CHATROOM';
const REMOVE_EXIT_CHATROOM_SUCCESS          = 'chat/REMOVE_EXIT_CHATROOM_SUCCESS';

const GET_LIST_INVITE_CHAT                  = 'chat/GET_LIST_INVITE_CHAT';
const GET_LIST_INVITE_CHAT_SUCCESS          = 'chat/GET_LIST_INVITE_CHAT_SUCCESS';

const INVITE_CHAT_MEMBER                    = 'chat/INVITE_CHAT_MEMBER';
const INVITE_CHAT_MEMBER_SUCCESS            = 'chat/INVITE_CHAT_MEMBER_SUCCESS';

const REMOVE_CHATROOM_MEMBER                = 'chat/REMOVE_CHATROOM_MEMBER';
const REMOVE_CHATROOM_MEMBER_SUCCESS        = 'chat/REMOVE_CHATROOM_MEMBER_SUCCESS';

const START_ALONE_CHATTING_AFTER_FINISH_CHAT            = 'chat/START_ALONE_CHATTING_AFTER_FINISH_CHAT';
const START_ALONE_CHATTING_AFTER_FINISH_CHAT_SUCCESS    = 'chat/START_ALONE_CHATTING_AFTER_FINISH_CHAT_SUCCESS';

const TEAMUP_OPEN_TOGGLE                    = 'chat/TEAMUP_OPEN_TOGGLE';

// remocon func
const REMOCON_TEAMUP_INK_STATUS             = 'chat/REMOCON_TEAMUP_INK_STATUS';
const REMOCON_TEAMUP_VOICE_STATUS           = 'chat/REMOCON_TEAMUP_VOICE_STATUS';
const REMOCON_TEAMUP_LIVE_STATUS            = 'chat/REMOCON_TEAMUP_LIVE_STATUS';
const REMOCON_TEAMUP_PULL_SCREEN            = 'chat/REMOCON_TEAMUP_PULL_SCREEN';
const REMOCON_TEAMUP_PUSH_SCREEN            = 'chat/REMOCON_TEAMUP_PUSH_SCREEN';

const UPDATE_TEAMUP_MEMBER_STATUS           = 'chat/UPDATE_TEAMUP_MEMBER_STATUS';

// page transfer
const CHANGE_TRUE_TO_GOTO_PAGE              = 'chat/CHANGE_TRUE_TO_GOTO_PAGE';
const CHANGE_FALSE_TO_GOTO_CHATTING         = 'chat/CHANGE_FALSE_TO_GOTO_CHATTING';
const CHANGE_FALSE_TO_GOTO_LIST_CHAT        = 'chat/CHANGE_FALSE_TO_GOTO_LIST_CHAT';
const CHANGE_FALSE_TO_GOTO_LIST_LIVE        = 'chat/CHANGE_FALSE_TO_GOTO_LIST_LIVE';
const CHANGE_FALSE_TO_GOTO_LIST_FRIEND      = 'chat/CHANGE_FALSE_TO_GOTO_LIST_FRIEND';

// modal
// - chatting
const TOGGLE_CHAT_MENU                      = 'chat/TOGGLE_CHAT_MENU';
const TOGGLE_CHAT_MENU_LIVE                 = 'chat/TOGGLE_CHAT_MENU_LIVE';
const CLOSE_MODAL_CHAT_MENU                 = 'chat/CLOSE_MODAL_CHAT_MENU';

// - chat list
const SEARCH_CHAT_LIST                      = 'chat/SEARCH_CHAT_LIST';

const TOGGLE_SEARCH_CHAT_LIST               = 'chat/TOGGLE_SEARCH_CHAT_LIST';
const TOGGLE_CREATE_CHATROOM                = 'chat/TOGGLE_CREATE_CHATROOM';
const TOGGLE_PARTICIPATE_CHATROOM           = 'chat/TOGGLE_PARTICIPATE_CHATROOM';
const TOGGLE_CONFIG_CHAT_LIST               = 'chat/TOGGLE_CONFIG_CHAT_LIST';
const TOGGLE_EDIT_CHATROOM                  = 'chat/TOGGLE_EDIT_CHATROOM';
const TOGGLE_EDIT_CHATROOM_MEMBER           = 'chat/TOGGLE_EDIT_CHATROOM_MEMBER';
const TOGGLE_REMOVE_CHATROOM_MEMBER         = 'chat/TOGGLE_REMOVE_CHATROOM_MEMBER';
const TOGGLE_REMOVE_CHATROOM                = 'chat/TOGGLE_REMOVE_CHATROOM';
const TOGGLE_DELETE_EXIT_CHATROOM           = 'chat/TOGGLE_DELETE_EXIT_CHATROOM';
const TOGGLE_EXIT_CHATROOM                  = 'chat/TOGGLE_EXIT_CHATROOM';
const TOGGLE_ALERT_MODAL                    = 'chat/TOGGLE_ALERT_MODAL';

const CLOSE_MODAL_CHAT_LIST                 = 'chat/CLOSE_MODAL_CHAT_LIST';
const CLOSE_MODAL_CONFIG_CHAT_LIST          = 'chat/CLOSE_MODAL_CONFIG_CHAT_LIST';

export const createChatRoom                 = createRequestThunk(CREATE_CHATROOM, create);
export const createP2PChatRoom              = createRequestThunk(CREATE_CHATROOM, create, [FRIEND_CHAT_RESULT]);
//export const saveModifiedChatRoomInfo     = createRequestThunk(SAVE_CHATROOM_INFO, save);
export const updateChatRoomName             = createRequestThunk(UPDATE_CHATROOM_NAME, update_name);
export const updateChatRoomType             = createRequestThunk(UPDATE_CHATROOM_TYPE, update_type);
export const joinChatting                   = createRequestThunk(JOIN_CHATTING, join, [APPEND_ALERT_MESSAGE_WITH_RESTAPI]);
export const finishChatting                 = createRequestThunk(FINISH_CHATTING, finish/*, [TALK_STATE_INIT]*/);
export const exitChatRoom                   = createRequestThunk(EXIT_CHATROOM, exit);
export const deleteChatRoom                 = createRequestThunk(REMOVE_CHATROOM, remove_chat);
export const deleteExitChatRoom             = createRequestThunk(REMOVE_EXIT_CHATROOM, remove_chat);
export const getInviteableFriends           = createRequestThunk(GET_LIST_INVITE_CHAT, get_inviteable_list);
export const inviteChatRoomMember           = createRequestThunk(INVITE_CHAT_MEMBER, invite_chat_member);
export const deleteChatRoomMember           = createRequestThunk(REMOVE_CHATROOM_MEMBER, remove_chat_member);
export const startAloneChattingAfterFinishChat = createRequestThunk(START_ALONE_CHATTING_AFTER_FINISH_CHAT, finish, [APPEND_ALERT_MESSAGE_WITH_RESTAPI]);

export const startAloneChatting             = createAction(START_ALONE_CHATTING);

export const teamupOpenToggle               = createAction(TEAMUP_OPEN_TOGGLE);

export const remoconTeamupInkStatus         = createAction(REMOCON_TEAMUP_INK_STATUS);
export const remoconTeamupVoiceStatus       = createAction(REMOCON_TEAMUP_VOICE_STATUS);
export const remoconTeamupLiveStatus        = createAction(REMOCON_TEAMUP_LIVE_STATUS);
export const remoconTeamupPullScreen        = createAction(REMOCON_TEAMUP_PULL_SCREEN);
export const remoconTeamupPushScreen        = createAction(REMOCON_TEAMUP_PUSH_SCREEN);

export const updateTeamupMemberStatus       = createAction(UPDATE_TEAMUP_MEMBER_STATUS);

export const gotoPage                       = createAction(CHANGE_TRUE_TO_GOTO_PAGE);
export const changeFalseToGotoChatList      = createAction(CHANGE_FALSE_TO_GOTO_LIST_CHAT);
export const changeFalseToGotoLiveList      = createAction(CHANGE_FALSE_TO_GOTO_LIST_LIVE);
export const changeFalseToGotoFriendList    = createAction(CHANGE_FALSE_TO_GOTO_LIST_FRIEND);
export const changeFalseToGotoChatting      = createAction(CHANGE_FALSE_TO_GOTO_CHATTING);

export const toggle_chatMenu                = createAction(TOGGLE_CHAT_MENU);
export const toggle_chatMenu_liveList       = createAction(TOGGLE_CHAT_MENU_LIVE);
export const closeChatMenuModal             = createAction(CLOSE_MODAL_CHAT_MENU);

export const toggle_searchChatList          = createAction(TOGGLE_SEARCH_CHAT_LIST);
export const toggle_createChatRoom          = createAction(TOGGLE_CREATE_CHATROOM);
export const toggle_participateChatRoom     = createAction(TOGGLE_PARTICIPATE_CHATROOM);
export const toggle_configChatList          = createAction(TOGGLE_CONFIG_CHAT_LIST);
export const toggle_editChatRoom            = createAction(TOGGLE_EDIT_CHATROOM);
export const toggle_editChatRoomMember      = createAction(TOGGLE_EDIT_CHATROOM_MEMBER);
export const toggle_removeChatRoomMember    = createAction(TOGGLE_REMOVE_CHATROOM_MEMBER);
export const toggle_deleteChatRoom          = createAction(TOGGLE_REMOVE_CHATROOM);
export const toggle_exitChatRoom            = createAction(TOGGLE_EXIT_CHATROOM);
export const toggle_deleteExitChatRoom      = createAction(TOGGLE_DELETE_EXIT_CHATROOM);
export const toggle_alertModal              = createAction(TOGGLE_ALERT_MODAL);
export const closeModal                     = createAction(CLOSE_MODAL_CHAT_LIST);
export const closeConfigModal               = createAction(CLOSE_MODAL_CONFIG_CHAT_LIST);

export const searchChatListResult           = createAction(SEARCH_CHAT_LIST);

// MQ function //
const MQ_LOGIN_CHAT                 = 'chat/MQ_LOGIN_CHAT';
const MQ_LOGOUT_CHAT                = 'chat/MQ_LOGOUT_CHAT';

const MQ_CHAT_JOIN                  = 'chat/MQ_CHAT_JOIN';
const MQ_CHAT_FINISH                = 'chat/MQ_CHAT_FINISH';
const MQ_CHAT_EXIT                  = 'chat/MQ_CHAT_EXIT';
const MQ_CHAT_CREATE                = 'chat/MQ_CHAT_CREATE';
const MQ_CHAT_UPDATE                = 'chat/MQ_CHAT_UPDATE';
const MQ_CHAT_REMOVE                = 'chat/MQ_CHAT_REMOVE';

const MQ_CHAT_NAME_UPDATE           = 'chat/MQ_CHAT_NAME_UPDATE';
const MQ_CHAT_MODE_UPDATE           = 'chat/MQ_CHAT_MODE_UPDATE';
const MQ_CHAT_TYPE_UPDATE           = 'chat/MQ_CHAT_TYPE_UPDATE';

const MQ_CHAT_MEMBER_INVITE         = 'chat/MQ_CHAT_MEMBER_INVITE';
const MQ_CHAT_MEMBER_ADD            = 'chat/MQ_CHAT_MEMBER_ADD';

const MQ_CHAT_MEMBER_REMOVE         = 'chat/MQ_CHAT_MEMBER_REMOVE';
const MQ_CHAT_MEMBER_REMOVED        = 'chat/MQ_CHAT_MEMBER_REMOVED';

const MQ_UPDATE_CHAT_MEMBER_INFO    = 'chat/MQ_UPDATE_CHAT_MEMBER_INFO';

const MQ_CHAT_MSG                   = 'chat/MQ_CHAT_MSG';

export const mqLoginChat            = createAction(MQ_LOGIN_CHAT);
export const mqLogoutChat           = createAction(MQ_LOGOUT_CHAT);

export const mqJoinChat             = createMultiAction(MQ_CHAT_JOIN, "chat", APPEND_ALERT_MESSAGE);
export const mqFinishChat           = createMultiAction(MQ_CHAT_FINISH, "chat", APPEND_ALERT_MESSAGE);
export const mqExitChat             = createMultiAction(MQ_CHAT_EXIT, "chat", APPEND_ALERT_MESSAGE);
export const mqCreateChat           = createAction(MQ_CHAT_CREATE);
export const mqUpdateChat           = createAction(MQ_CHAT_UPDATE);
export const mqRemoveChat           = createAction(MQ_CHAT_REMOVE);

export const mqNameUpdateChat       = createAction(MQ_CHAT_NAME_UPDATE);
export const mqModeUpdateChat       = createAction(MQ_CHAT_MODE_UPDATE);
export const mqTypeUpdateChat       = createAction(MQ_CHAT_TYPE_UPDATE);

export const mqInviteChatMember     = createAction(MQ_CHAT_MEMBER_INVITE);
export const mqAddChatMember        = createMultiAction(MQ_CHAT_MEMBER_ADD, "chat", APPEND_ALERT_MESSAGE);

export const mqRemoveChatMember     = createMultiAction(MQ_CHAT_MEMBER_REMOVE, "chat", APPEND_ALERT_MESSAGE);
export const mqRemovedChatMember    = createAction(MQ_CHAT_MEMBER_REMOVED);

//export const mqLiveTalkChat         = createMultiAction(MQ_CHAT_LIVETALK, "chat", APPEND_ALERT_MESSAGE*/);
//export const mqVoiceTalkChat        = createMultiAction(MQ_CHAT_VOICETALK, "chat", APPEND_ALERT_MESSAGE*/);
//export const mqNoneTalkChat         = createMultiAction(MQ_CHAT_NONETALK, "chat", APPEND_ALERT_MESSAGE*/);

//export const mqLiveTalkChatMember   = createMultiAction(MQ_CHAT_MEMBER_LIVETALK, "chat", APPEND_ALERT_MESSAGE*/);
//export const mqVoiceTalkChatMember  = createMultiAction(MQ_CHAT_MEMBER_VOICETALK, "chat", APPEND_ALERT_MESSAGE*/);
//export const mqNoneTalkChatMember   = createMultiAction(MQ_CHAT_MEMBER_NONETALK, "chat", APPEND_ALERT_MESSAGE*/);

export const mqUpdateChatMemberInfo = createAction(MQ_UPDATE_CHAT_MEMBER_INFO);

export const mqChatMsg              = createMultiAction(MQ_CHAT_MSG, "chat",);

const initialState = {
    list_chatting                   : [], // 내 팀업 목록
    list_searchChatting             : [],
    list_inviteableFriends          : [], // 초대 가능한 친구 목록

    isAloneChatting                 : true,

    isGotoChatting                  : false,
    isGotoChatList                  : false,
    isGotoLiveList                  : false,
    isGotoFriendList                : false,
    isOpened                        : false,
    isOpenedTeamUp                  : false,

    /**
     *  selectedChatRoomInfo = {
     *      chatRoomSeq, chatRoomName, chatRoomCreator, list_member
     *  }
     * 
     *  participateChatRoomInfo = {
     *      chatRoomSeq, chatRoomName
     *  }
     * 
     *  performChatRoomInfo = {
     *      chatRoomSeq, chatRoomName, chatRoomType, chatRoomUpdateDT, list_member,
     *      isInkSwitch, isVoiceSwitch, isScreenSwitch, isPullSwitch, isPushSwitch
     *  }
     */
    selectedChatRoomInfo            : {         // 수정하고자 하는 팀업 정보
        chatRoomSeq                 : -1,
        chatRoomName                : '',
        chatRoomCreator             : -1,
        list_member                 : []
    },

    participateChatRoomInfo         : {         // 참여 요청하는 팀업 정보
        chatRoomSeq                 : -1,
        chatRoomName                : '',
    },

    performChatRoomInfo             : {
        chatRoomSeq                 : -1,
        chatRoomName                : '',
        chatRoomType                : '',
        chatRoomUpdateDT            : '',
        list_member                 : [],
        isInkSwitch                 : false,
        isVoiceSwitch               : false,
        isScreenSwitch              : false,
        isPullSwitch                : false,
        isPushSwitch                : false
    },

    // modal
    isAlertModal                    : false,
    modalType                       : 'NONE',
    modalTargetUserNickname         : '',

    removedChatRoomName             : '',

    deleteChatRoomMemberInfo        : {
        chatRoomSeq                 : -1,
        userSeq                     : -1,
        userNickname                : '',
        profileImgUrl               : '',
        entryYN                     : 'N',
    },

    selectedFunc                     : 'NONE',
    isChattingMenuOpened             : false,    // ━━━━━━━┓
    isLiveListModalOpened            : false,    //        ┃
    isInviteMemberModalOpened        : false,    //        ┣━ chatting
    isAlertRemovedModalOpened        : false,    // ━━━━━━━┛

    isSearchChatListModalOpened      : false,    // ━━━━━━━┓
    isCreateChatRoomModalOpened      : false,    //        ┣━ chat list
    isParticipateChatRoomModalOpened : false,    // ━━━━━━━┛

    isConfigChatListModalOpened      : false,    // ━━━━━━━┓
    isEditChatRoomModalOpened        : false,    //        ┃
    isEditChatRoomMemberModalOpened  : false,    //        ┃
    isDeleteChatRoomModalOpened      : false,    //        ┣━ config chat list
    isDeleteExitChatRoomModalOpened  : false,    //        ┃
    isDeleteChatRoomMemberModalOpened: false,    //        ┃
    isExitChatRoomModalOpened        : false,    //        ┃
    isExitPerformChatRoomModalOpened : false,    // ━━━━━━━┛

    nextPhaseData                    : '',

    myTeamUpSendKey                  : null,
    myTeamUpBindKeyArr               : [],
    myHandsUpSendKey                 : null,
    myHandsUpBindKeyArr              : [],
};

const chat = handleActions({
    [CHAT_INIT]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isLoginSuccess } = action.payload;

            if (isLoginSuccess) {
                const { list_chatting, user_info } = action.payload;

                let clist_chatting = [];

                if (list_chatting !== undefined && list_chatting !== null) {
                    clist_chatting = list_chatting.map(row => ({
                        chatRoomSeq         : row.CT_ROOM_SEQ,
                        chatRoomName        : row.CT_ROOM_NAME,
                        chatRoomType        : row.CT_ROOM_TYPE,
                        chatRoomCreator     : row.CT_CREATOR,
                        chatRoomUpdateDT    : row.CT_ROOM_UPDATE_DT,
                        entryCnt            : row.ENTRY_CNT,
                        entryYN             : row.ENTRY_YN,
                        isInkSwitch         : true,
                        isVoiceSwitch       : true,
                        isScreenSwitch      : true,
                        isPushSwitch        : true,
                        isPullSwitch        : true,
                        list_member         : row.list_member === undefined || row.list_member === null ? 
                                                [] : 
                                                row.list_member.map(memRow => ({
                                                    userSeq         : memRow.USER_SEQ,
                                                    userNickname    : memRow.USER_NICKNAME,
                                                    userProfile     : memRow.USER_PROFILE,
                                                    profileImgUrl   : memRow.USER_PROFILE_IMG,
                                                    userGateWay     : memRow.gateWay,
                                                    userStatus      : memRow.MEMBER_STATUS,
                                                    entryYN         : memRow.ENTRY_YN,
                                                }))

                    }));
                }

                return {
                    ...state,
                    list_chatting       : clist_chatting,
                    myHandsUpSendKey    : user_info === undefined || user_info === null ? null : getHandsUpSendKey(user_info.USER_SEQ, 0, 0),
                    myHandsUpBindKeyArr : user_info === undefined || user_info === null ? [] : getHandsUpBindKey(user_info.USER_SEQ, 0)
                }
            } else {
                return {
                    ...state
                }
            }
        } else {
            return {
                ...state
            }
        }
    },

    [CHAT_STATE_INIT_SUCCESS]: (state, action) => {
        const { userSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('CHAT_STATE_INIT_SUCCESS');

        if (isSuccessed) {
            const { isLogoutSuccess } = action.payload;

            if (isLogoutSuccess) {
                if (state.performChatRoomInfo.chatRoomSeq > 0) {
                    callEoForFinishChatRoom(state.performChatRoomInfo.chatRoomSeq, userSeq);
                }

                return {
                    ...state,
                    list_chatting                       : [], 
                    list_searchChatting                 : [],
                    list_inviteableFriends              : [],

                    isAloneChatting                     : true,

                    isGotoChatting                      : false,
                    isGotoChatList                      : true,
                    isGotoLiveList                      : false,
                    isGotoFriendList                    : false,
                    isOpened                            : false,
                    isOpenedTeamUp                      : false,

                    selectedChatRoomInfo                : {
                        chatRoomSeq                     : -1,
                        chatRoomName                    : '',
                        chatRoomCreator                 : -1,
                        list_member                     : []
                    },

                    participateChatRoomInfo             : {
                        chatRoomSeq                     : -1,
                        chatRoomName                    : ''
                    },

                    performChatRoomInfo                 : {
                        chatRoomSeq                     : -1,
                        chatRoomName                    : '',
                        chatRoomType                    : '',
                        chatRoomUpdateDT                : '',
                        list_member                     : [],
                        isInkSwitch                     : false,
                        isVoiceSwitch                   : false,
                        isScreenSwitch                  : false,
                        isPullSwitch                    : false,
                        isPushSwitch                    : false
                    },

                    isAlertModal                        : false,
                    modalType                           : 'NONE',
                    modalTargetUserNickname             : '',

                    selectedFunc                        : 'NONE',
                    isChattingMenuOpened                : false,
                    isInviteMemberModalOpened           : false,
                    isLiveListModalOpened               : false,
                    isSearchChatListModalOpened         : false,
                    isCreateChatRoomModalOpened         : false,
                    isParticipateChatRoomModalOpened    : false,
                    isConfigChatListModalOpened         : false, 
                    isEditChatRoomModalOpened           : false,
                    isEditChatRoomMemberModalOpened     : false,
                    isDeleteChatRoomMemberModalOpened   : false,
                    isDeleteChatRoomModalOpened         : false,
                    isDeleteExitChatRoomModalOpened     : false,
                    isExitChatRoomModalOpened           : false, 
                    isExitPerformChatRoomModalOpened    : false,
                    isAlertRemovedModalOpened           : false,

                    removedChatRoomName                 : '',

                    deleteChatRoomMemberInfo            : {
                        chatRoomSeq                     : -1,
                        userSeq                         : -1,
                        userNickname                    : '',
                        profileImgUrl                   : '',
                        entryYN                         : 'N',
                    },

                    nextPhaseData                       : '',

                    myTeamUpSendKey                     : null,
                    myTeamUpBindKeyArr                  : [],
                    myHandsUpSendKey                    : null,
                    myHandsUpBindKeyArr                 : [],
                }
            } else {
                //console.log('isLogoutSuccess is ', isLogoutSuccess);
                return {
                    ...state,
                }
            }
        } else {
            //console.log('isSuccessed is ', isSuccessed);
            return {
                ...state,
            }
        }
    },

    [SEARCH_CHAT_LIST]: (state, action) => {
        const { result } = action.payload;

        if (result !== undefined && result !== null) {
            return {
                ...state,
                list_searchChatting     : result
            }
        } else {
            return {
                ...state
            }
        }
    },

    [START_ALONE_CHATTING]: (state, action) => {
        //console.log('START_ALONE_CHATTING');

        return {
            ...state,
            performChatRoomInfo         : {
                chatRoomSeq             : 0,
                chatRoomName            : '나와의 채팅',
                chatRoomType            : '',
                chatRoomUpdateDT        : '',
                list_member             : [],
                isInkSwitch             : false,
                isVoiceSwitch           : false,
                isScreenSwitch          : false,
                isPullSwitch            : false,
                isPushSwitch            : false
            },
            isGotoChatting              : true,
            isAloneChatting             : true
        }
    },

    [STOP_ALONE_CHATTING]: (state, action) => {
        //console.log('STOP_ALONE_CHATTING');

        return produce (state, draft => {
            if (draft.performChatRoomInfo.chatRoomSeq === 0) {
                draft.performChatRoomInfo.chatRoomSeq = -1;
                draft.performChatRoomInfo.chatRoomName = '';
            }

            draft.isAloneChatting = false;
        });
    },

    [JOIN_CHATTING_SUCCESS]: (state, action) => {
        const { chatRoomSeq, userSeq } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isEntrySuccess } = action.payload;

            if (isEntrySuccess) {
                const { chatRoomUpdateDT } = action.payload;

                const chat_info = state.list_chatting.find(info => info.chatRoomSeq === chatRoomSeq);
                if (chat_info !== undefined && chat_info !== null) {
                    if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                        const members_info = chat_info.list_member.map(row => ({
                            userSeq         : row.userSeq,
                            userNickname    : row.userNickname,
                            entryYN         : row.entryYN,
                            userGateWay     : row.userGateWay
                        })).filter(info => info.userSeq !== userSeq);

                        if (state.performChatRoomInfo.chatRoomSeq > 0) {
                            callEoForFinishChatRoom(state.performChatRoomInfo.chatRoomSeq, userSeq); // 기존 팀업 나가기
                        }

                        callEoForJoinChatRoom(chatRoomSeq, userSeq, chat_info.chatRoomName, chat_info.chatRoomType, members_info);
                    }
                }

                return produce(state, draft => {
                    if (draft.performChatRoomInfo.chatRoomSeq > 0) {    // 기존에 참여 중이던 팀업이 있는 경우...
                        const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === draft.performChatRoomInfo.chatRoomSeq);
                        if (chat_info !== undefined && chat_info !== null) {
                            chat_info.entryYN = 'N';
                            if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                                const member_info = chat_info.list_member.find(info => info.userSeq === userSeq);
                                if (member_info !== undefined && member_info !== null) {
                                    member_info.entryYN = 'N';
                                }
                            }
                        }
                    }

                    const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === chatRoomSeq);
                    if (chat_info !== undefined && chat_info !== null) {
                        chat_info.entryYN = 'Y';

                        draft.performChatRoomInfo = {
                            chatRoomSeq             : chatRoomSeq,
                            chatRoomName            : chat_info.chatRoomName,
                            chatRoomType            : chat_info.chatRoomType,
                            chatRoomUpdateDT        : chatRoomUpdateDT,
                            list_member             : chat_info.list_member === undefined || chat_info.list_member === null ? 
                                                        [] : 
                                                        chat_info.list_member,
                            isInkSwitch             : chat_info.isInkSwitch,
                            isVoiceSwitch           : chat_info.isVoiceSwitch,
                            isScreenSwitch          : chat_info.isScreenSwitch,
                            isPullSwitch            : chat_info.isPullSwitch,
                            isPushSwitch            : chat_info.isPushSwitch
                        };

                        if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                            const member_info = chat_info.list_member.find(info => info.userSeq === userSeq);
                            if (member_info !== undefined && member_info !== null) {
                                member_info.userStatus = ConstData.USER_STATUS.STUDYING;
                                member_info.entryYN = 'Y';
                            }

                            draft.performChatRoomInfo.list_member = chat_info.list_member.map(member => ({
                                ...member,
                                isInkON         : chat_info.isInkSwitch,
                                isVoiceON       : chat_info.isVoiceSwitch,
                                isLiveON        : chat_info.isScreenSwitch,
                                isPullScreen    : chat_info.isPullSwitch,
                                isPushScreen    : chat_info.isPushSwitch,
                            }));
                        }

                        draft.myTeamUpSendKey = getTeamUpSendKey(chatRoomSeq, userSeq);
                        draft.myTeamUpBindKeyArr = getTeamUpBindKey(chatRoomSeq, userSeq);
                        
                        draft.isGotoChatting = true;
                        draft.isAloneChatting = false;
                        draft.isSearchChatListModalOpened = false;
                        draft.isParticipateChatRoomModalOpened = false;
                    }
                });
            } else {
                //console.log('JOIN_CHATTING - isEntrySuccess is ', isEntrySuccess);
                return {
                    ...state,
                }
            }
        } else {
            const { errMsg } = action.payload;
            //console.log('JOIN_CHATTING - isSuccessd is ', isSuccessed);
            //console.log(errMsg);

            return {
                ...state,
            }
        }
    },

    [CREATE_WEB_CHATROOM_SUCCESS]: (state, action) => {
        const { userArr } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('CREATE_WEB_CHATROOM_SUCCESS');

        if (isSuccessed) {
            const { isExist } = action.payload;

            if (isExist === true) {
                //console.log('create web isExist is true... web에서 이런 경우가 생길 수 있나...?');

                return {
                    ...state,
                }
            } else if (isExist === false) {
                const { isCreateSuccess } = action.payload;

                if (isCreateSuccess) {
                    const { chatRoom_info } = action.payload;

                    if (chatRoom_info !== undefined && chatRoom_info !== null) {
                        let list_member = [];

                        if (userArr !== undefined && userArr !== null) {
                            list_member = userArr.map(row => ({
                                userSeq         : row.userSeq, 
                                userNickname    : row.userNickname,
                                userProfile     : row.userProfile,
                                userStatus      : row.userStatus,
                                profileImgUrl   : row.profileImgUrl, 
                                entryYN         : 'N'
                            }));
                        }

                        const newChatRoom_info = {
                            chatRoomSeq         : chatRoom_info.chatRoomSeq,
                            chatRoomName        : chatRoom_info.chatRoomName,
                            chatRoomType        : chatRoom_info.chatRoomType,
                            chatRoomUpdateDT    : chatRoom_info.chatRoomUpdateDT,
                            isInkSwitch         : false,
                            isVoiceSwitch       : false,
                            isScreenSwitch      : false,
                            isPushSwitch        : false,
                            isPullSwitch        : false,
                            entryCnt            : 0,
                            entryYN             : 'N',
                            list_member         : list_member
                        };

                        return {
                            ...state,
                            list_chatting : state.list_chatting.concat(newChatRoom_info)
                        }
                    } else {
                        //console.log('CREATE_WEB_CHATROOM - chatRoom_info => ', chatRoom_info);
                        return {
                            ...state,
                        }
                    }
                } else {
                    //console.log('CREATE_WEB_CHATROOM - isCreateSuccess is ', isCreateSuccess);
                    return {
                        ...state,
                    }
                }
            } else {
                //console.log('CREATE_WEB_CHATROOM - isExist is ', isExist);
                return {
                    ...state,
                }
            }
        } else {
            const { errMsg } = action.payload;
            //console.log('CREATE_WEB_CHATROOM - isSuccessed is ', isSuccessed);
            //console.log(errMsg);
            return {
                ...state,
            }
        }
    },

    [CREATE_CHATROOM_SUCCESS]: (state, action) => {
        const { creatorSEQ, userArr, isWithEntry, selectedFunc } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('CREATE_CHATROOM_SUCCESS');

        if (isSuccessed) {
            const { isExist } = action.payload;

            if (isExist === true) { // 이미 존재하는 팀업
                const { chatRoomSEQ } = action.payload;

                if (isWithEntry && isWithEntry === 'Y') { // 바로 입장하는 경우 (from 프로필)
                    const { isEntrySuccess } = action.payload;

                    if (isEntrySuccess) {
                        if (state.performChatRoomInfo.chatRoomSeq === chatRoomSEQ) { // 현재 참여 중인 팀업과 같은 방인 경우
                            return {
                                ...state,
                                isGotoChatting : true,
                                isCreateChatRoomModalOpened : false
                            }
                        } else {
                            if (state.performChatRoomInfo.chatRoomSeq > 0) { // 현재 참여 중인 팀업이 있는 경우
                                callEoForFinishChatRoom(state.performChatRoomInfo.chatRoomSeq, creatorSEQ); // 기존 팀업에서 나감
                            }

                            const chat_info = state.list_chatting.find(info => info.chatRoomSeq === chatRoomSEQ);
                            if (chat_info !== undefined && chat_info !== null) {
                                if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                                    const list_member = chat_info.list_member.filter(info => info.userSeq !== creatorSEQ);
                                    // 이미 목록에 있는 팀업이므로, join 메세지를 보냄
                                    callEoForJoinChatRoom(chatRoomSEQ, creatorSEQ, chat_info.chatRoomName, chat_info.chatRoomType, list_member);
                                }
                            }

                            return produce (state, draft => {
                                if (draft.performChatRoomInfo.chatRoomSeq > 0) { // 현재 참여 중인 팀업이 있는 경우
                                    const beforeChat_info = draft.list_chatting.find(info => info.chatRoomSeq === draft.performChatRoomInfo.chatRoomSeq);
                                    if (beforeChat_info !== undefined && beforeChat_info !== null) {
                                        beforeChat_info.entryYN = 'N';

                                        if (beforeChat_info.list_member !== undefined && beforeChat_info.list_member !== null) {
                                            const member_info = beforeChat_info.list_member.find(info => info.userSeq === creatorSEQ);
                                            if (member_info !== undefined && member_info !== null) {
                                                member_info.userStatus = ConstData.USER_STATUS.STUDYING;
                                                member_info.entryYN = 'N';
                                            }
                                        }
                                    }
                                }

                                const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === chatRoomSEQ);
                                if (chat_info !== undefined && chat_info !== null) {
                                    chat_info.entryYN = 'Y';

                                    draft.performChatRoomInfo = {
                                        chatRoomSeq             : chatRoomSEQ,
                                        chatRoomName            : chat_info.chatRoomName,
                                        chatRoomType            : chat_info.chatRoomType,
                                        chatRoomUpdateDT        : chat_info.chatRoomUpdateDT,
                                        list_member             : chat_info.list_member === undefined || chat_info.list_member === null ? 
                                                                    [] :
                                                                    chat_info.list_member,
                                        isInkSwitch             : chat_info.isInkSwitch,
                                        isVoiceSwitch           : chat_info.isVoiceSwitch,
                                        isScreenSwitch          : chat_info.isScreenSwitch,
                                        isPushSwitch            : chat_info.isPushSwitch,
                                        isPullSwitch            : chat_info.isPullSwitch
                                    };

                                    if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                                        const member_info = chat_info.list_member.find(info => info.userSeq === creatorSEQ);
                                        if (member_info !== undefined && member_info !== null) {
                                            member_info.userStatus = ConstData.USER_STATUS.STUDYING;
                                            member_info.entryYN = 'Y';
                                        }

                                        draft.performChatRoomInfo.list_member = chat_info.list_member.map(member => ({
                                            ...member,
                                            isInkON         : chat_info.isInkSwitch,
                                            isVoiceON       : chat_info.isVoiceSwitch,
                                            isLiveON        : chat_info.isScreenSwitch,
                                            isPullScreen    : chat_info.isPullSwitch,
                                            isPushScreen    : chat_info.isPushSwitch,
                                        }));
                                    }

                                    draft.myTeamUpSendKey = getTeamUpSendKey(chatRoomSEQ, creatorSEQ);
                                    draft.myTeamUpBindKeyArr = getTeamUpBindKey(chatRoomSEQ, creatorSEQ);

                                    draft.isGotoChatting = true;
                                }

                                draft.selectedFunc = "NONE";
                                draft.isCreateChatRoomModalOpened = false;
                            });
                        }
                    } else {
                        //console.log('CREATE_CHATROOM - isEntrySuccess is ', isEntrySuccess);
                        return {
                            ...state,
                        }
                    }
                } else if (isWithEntry && isWithEntry === 'N') { // 팀업 입장을 선택하길 원하는 경우 (from 팀업 생성)
                    if (state.performChatRoomInfo.chatRoomSeq === -1) { // 참여 중인 팀업이 없는 경우
                        //console.log('참여 중인 팀업이 없으면서 입장 여부 선택하고 들어가는 경우..... 있으면 말해주세요ㅠ');   
                        return {
                            ...state
                        }
                    } else { // 참여 중인 팀업이 있는 경우
                        if (state.performChatRoomInfo.chatRoomSeq === chatRoomSEQ) { // 생성할 팀업 == 참여 중인 팀업
                            return {
                                ...state,
                                isGotoChatting                  : true,
                                isCreateChatRoomModalOpened     : false,
                                selectedFunc                    : "NONE"
                            }
                        } else { // 생성할 팀업 != 참여 중인 팀업
                            const chat_info = state.list_chatting.find(info => info.chatRoomSeq === chatRoomSEQ);
                            if (chat_info !== undefined && chat_info !== null) {
                                return {
                                    ...state,
                                    selectedFunc                        : "NONE",
                                    participateChatRoomInfo             : {
                                        chatRoomSeq                     : chat_info.chatRoomSeq,
                                        chatRoomName                    : chat_info.chatRoomName
                                    },
                                    isParticipateChatRoomModalOpened    : true,
                                    isCreateChatRoomModalOpened         : false
                                }
                            } else {
                                //console.log('CREATE_CHATROOM - chat_info is ', chat_info);
                                return {
                                    ...state,
                                }
                            }
                        }
                    }
                } else {
                    //console.log('CREATE_CHATROOM - isWithEntry is ', isWithEntry);
                    return {
                        ...state,
                    }
                }
            } else if (isExist === false) { // 새로 생성되는 팀업
                const { isCreateSuccess } = action.payload;

                if (isCreateSuccess) {
                    const { chatRoom_info, list_memberGateWay } = action.payload;
                    //console.log('list_memberGateWay - ', list_memberGateWay);

                    if (chatRoom_info !== undefined && chatRoom_info !== null) {
                        if (userArr !== undefined && userArr !== null && list_memberGateWay !== undefined && list_memberGateWay !== null) {
                            if (isWithEntry && isWithEntry === 'Y') { // 바로 입장하는 경우 (from 프로필)
                                const { isEntrySuccess } = action.payload;

                                if (isEntrySuccess) {
                                    if (state.performChatRoomInfo.chatRoomSeq > 0) { // 현재 참여 중인 팀업이 있는 경우
                                        const chat_info = state.list_chatting.find(info => info.chatRoomSeq === state.performChatRoomInfo.chatRoomSeq);
                                        if (chat_info !== undefined && chat_info !== null) {
                                            callEoForFinishChatRoom(chat_info.chatRoomSeq, creatorSEQ);
                                        }
                                    }

                                    const list_member = [];

                                    list_memberGateWay.forEach(memberGateWay_info => {
                                        const member_info = userArr.find(info => info.userSeq === memberGateWay_info.userSeq);
                                        if (member_info !== undefined && member_info !== null) {
                                            list_member.push({
                                                ...member_info,
                                                userGateWay     : memberGateWay_info.gateWay,
                                                entryYN         : member_info.userSeq === creatorSEQ ? 'Y' : 'N',
                                                isInkON         : true,
                                                isVoiceON       : true,
                                                isLiveON        : true,
                                                isPullScreen    : true,
                                                isPushScreen    : true
                                            });
                                        }
                                    });

                                    //console.log('list_member -> ', list_member);

                                    const newChatRoom_info = {
                                        chatRoomSeq         : chatRoom_info.chatRoomSeq,
                                        chatRoomName        : chatRoom_info.chatRoomName,
                                        chatRoomType        : chatRoom_info.chatRoomType,
                                        chatRoomCreator     : chatRoom_info.chatRoomCreator,
                                        chatRoomUpdateDT    : chatRoom_info.chatRoomUpdateDT,
                                        entryCnt            : 1,
                                        entryYN             : 'Y',
                                        isInkSwitch         : true,
                                        isVoiceSwitch       : true,
                                        isScreenSwitch      : true,
                                        isPullSwitch        : true,
                                        isPushSwitch        : true,
                                        list_member         : list_member
                                    };

                                    const members_info = list_member.map(row => ({
                                        userSeq             : row.userSeq,
                                        userNickname        : row.userNickname,
                                        userGateWay         : row.userGateWay,
                                        entryYN             : row.entryYN
                                    })).filter(info => info.userSeq !== creatorSEQ);

                                    callEoForJoinChatRoom(newChatRoom_info.chatRoomSeq, creatorSEQ, newChatRoom_info.chatRoomName, newChatRoom_info.chatRoomType, members_info);

                                    return produce (state, draft => {
                                        if (draft.performChatRoomInfo.chatRoomSeq > 0) { // 현재 참여 중인 팀업이 있는 경우
                                            const beforeChat_info = draft.list_chatting.find(info => info.chatRoomSeq === draft.performChatRoomInfo.chatRoomSeq);
                                            if (beforeChat_info !== undefined && beforeChat_info !== null) {
                                                beforeChat_info.entryYN = 'N';

                                                if (beforeChat_info.list_member !== undefined && beforeChat_info.list_member !== null) {
                                                    const member_info = beforeChat_info.list_member.find(info => info.userSeq === creatorSEQ);
                                                    if (member_info !== undefined && member_info !== null) {
                                                        member_info.userStatus = ConstData.USER_STATUS.STUDYING;
                                                        member_info.entryYN = 'N';
                                                    }
                                                }
                                            }
                                        }

                                        if (newChatRoom_info !== undefined && newChatRoom_info !== null) {
                                            draft.performChatRoomInfo = {
                                                chatRoomSeq             : newChatRoom_info.chatRoomSeq,
                                                chatRoomName            : newChatRoom_info.chatRoomName,
                                                chatRoomType            : newChatRoom_info.chatRoomType,
                                                chatRoomUpdateDT        : newChatRoom_info.chatRoomUpdateDT,
                                                entryCnt                : newChatRoom_info.entryCnt,
                                                entryYN                 : newChatRoom_info.entryYN,
                                                isInkSwitch             : true,
                                                isVoiceSwitch           : true,
                                                isScreenSwitch          : true,
                                                isPushSwitch            : true,
                                                isPullSwitch            : true,
                                                list_member             : newChatRoom_info.list_member === undefined || newChatRoom_info.list_member === null ? 
                                                                            [] :
                                                                            newChatRoom_info.list_member
                                            };

                                            draft.list_chatting.push(newChatRoom_info);
                                            
                                            draft.myTeamUpSendKey = getTeamUpSendKey(newChatRoom_info.chatRoomSeq, creatorSEQ);
                                            draft.myTeamUpBindKeyArr = getTeamUpBindKey(newChatRoom_info.chatRoomSeq, creatorSEQ);

                                            draft.isGotoChatting = true;
                                        }

                                        draft.selectedFunc = "NONE";
                                        draft.isCreateChatRoomModalOpened = false;
                                    });
                                } else {
                                    //console.log('CREATE_CHATROOM - isEntrySuccess is ', isEntrySuccess);
                                    return {
                                        ...state,
                                    }
                                }
                            } else if (isWithEntry && isWithEntry === 'N') { // 팀업 입장을 선택하길 원하는 경우 (from 팀업 생성)
                                const list_member = [];

                                list_memberGateWay.forEach(memberGateWay_info => {
                                    const member_info = userArr.find(info => info.userSeq === memberGateWay_info.userSeq);
                                    if (member_info !== undefined && member_info !== null) {
                                        list_member.push({
                                            ...member_info,
                                            userGateWay     : memberGateWay_info.gateWay,
                                            entryYN         : 'N',
                                            isInkON         : true,
                                            isVoiceON       : true,
                                            isLiveON        : true,
                                            isPullScreen    : true,
                                            isPushScreen    : true
                                        });
                                    }
                                });

                                //console.log('list_member -> ', list_member);

                                const newChatRoom_info = {
                                    chatRoomSeq         : chatRoom_info.chatRoomSeq,
                                    chatRoomName        : chatRoom_info.chatRoomName,
                                    chatRoomType        : chatRoom_info.chatRoomType,
                                    chatRoomCreator     : chatRoom_info.chatRoomCreator,
                                    chatRoomUpdateDT    : chatRoom_info.chatRoomUpdateDT,
                                    entryCnt            : 0,
                                    entryYN             : 'N',
                                    isInkSwitch         : true,
                                    isVoiceSwitch       : true,
                                    isScreenSwitch      : true,
                                    isPullSwitch        : true,
                                    isPushSwitch        : true,
                                    list_member         : list_member
                                };

                                return {
                                    ...state,
                                    selectedFunc                : "NONE",
                                    list_chatting               : state.list_chatting.concat(newChatRoom_info),

                                    participateChatRoomInfo     : {
                                        chatRoomSeq             : newChatRoom_info.chatRoomSeq,
                                        chatRoomName            : newChatRoom_info.chatRoomName
                                    },

                                    isCreateChatRoomModalOpened : false,
                                    isParticipateChatRoomModalOpened : !state.isParticipateChatRoomModalOpened
                                }
                            } else {
                                //console.log('CREATE_CHATROOM - isWithEntry is ', isWithEntry);
                                return {
                                    ...state,
                                }
                            }
                        } else {
                            //console.log('CREATE_CHATROOM - userArr is ', userArr);
                            return {
                                ...state,
                            }
                        }
                    } else {
                        //console.log('CREATE_CHATROOM - chatRoom_info => ', chatRoom_info);
                        return {
                            ...state,
                        }
                    }
                } else {
                    //console.log('CREATE_CHATROOM - isCreateSuccess is ', isCreateSuccess);
                    return {
                        ...state,
                    }
                }
            } else {
                //console.log('CREATE_CHATROOM - isExist is ', isExist);
                return {
                    ...state,
                }
            }
        } else {
            const { errMsg } = action.payload;
            //console.log('CREATE_CHATROOM - isSuccessed is ', isSuccessed);
            //console.log(errMsg);
            return {
                ...state,
            }
        }
    },

    [UPDATE_CHATROOM_NAME_SUCCESS]: (state, action) => {
        const { chatRoomSeq, chatRoomName } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('UPDATE_CHATROOM_NAME_SUCCESS');

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                if (state.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                    callEoForUpdateChatRoomName(chatRoomSeq, chatRoomName);
                }

                return produce(state, draft => {
                    if(draft.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                        draft.performChatRoomInfo.chatRoomName = chatRoomName;
                    }

                    const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === chatRoomSeq);
                    if(chat_info !== undefined && chat_info !== null) {
                        chat_info.chatRoomName = chatRoomName;
                    }

                    draft.isEditChatRoomModalOpened = false;
                });
            } else {
                //console.log('UPDATE_CHATROOM_NAME - isUpdateSuccess is ', isUpdateSuccess);
                return {
                    ...state,
                }
            }
        } else {
            const { errMsg } = action.payload;
            //console.log('UPDATE_CHATROOM_NAME - isSuccessed is ', isSuccessed, errMsg);
            return {
                ...state,
            }
        }
    },

    [UPDATE_CHATROOM_TYPE_SUCCESS]: (state, action) => {
        const { chatRoomSeq, chatRoomType } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('UPDATE_CHATROOM_TYPE_SUCCESS');

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;
            
            if (isUpdateSuccess) {
                return produce(state, draft => {
                    if(draft.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                        draft.performChatRoomInfo.chatRoomType = chatRoomType;
                    }

                    const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === chatRoomSeq);
                    if(chat_info !== undefined && chat_info !== null) {
                        chat_info.chatRoomType = chatRoomType;
                    }

                    draft.isEditChatRoomModalOpened = false;
                });
            } else {
                //console.log('UPDATE_CHATROOM_TYPE - isUpdateSuccess is ', isUpdateSuccess);
                return {
                    ...state,
                }
            }
        } else {
            const { errMsg } = action.payload;
            //console.log('UPDATE_CHATROOM_TYPE - isSuccessed is ', isSuccessed, errMsg);
            return {
                ...state,
            }
        }
    },

    [START_ALONE_CHATTING_AFTER_FINISH_CHAT_SUCCESS]: (state, action) => {
        const { chatRoomSeq, userSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('START_ALONE_CHATTING_AFTER_FINISH_CHAT_SUCCESS');

        if (isSuccessed) {
            const { isFinishSuccess } = action.payload;

            if (isFinishSuccess) {
                if (state.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                    callEoForFinishChatRoom(chatRoomSeq, userSeq);
                }

                return produce(state, draft => {
                    const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === chatRoomSeq);
                    if (chat_info !== undefined && chat_info !== null && chat_info.list_member !== undefined && chat_info.list_member !== null) {
                        chat_info.entryYN = 'N';

                        const member_info = chat_info.list_member.find(info => info.userSeq === userSeq);
                        if (member_info !== undefined && member_info !== null) {
                            member_info.userStatus = ConstData.USER_STATUS.LOG_IN;
                            member_info.entryYN = 'N';
                        }
                    }

                    draft.myTeamUpSendKey = null;
                    draft.myTeamUpBindKeyArr = [];

                    draft.performChatRoomInfo = {
                        chatRoomSeq             : 0,
                        chatRoomName            : '나와의 채팅',
                        chatRoomType            : '',
                        chatRoomUpdateDT        : '',
                        list_member             : [],
                        isInkSwitch             : false,
                        isVoiceSwitch           : false,
                        isScreenSwitch          : false,
                        isPullSwitch            : false,
                        isPushSwitch            : false
                    };

                    draft.isAloneChatting = true;
                    draft.isGotoChatting = true;
                    draft.isParticipateChatRoomModalOpened = false;
                });
            } else {
                //console.log('START_ALONE_CHATTING_AFTER_FINISH_CHAT - isFinishSuccess is ', isFinishSuccess);
                return {
                    ...state,
                }
            } 
        } else {
            const { errMsg } = action.payload;
            //console.log('START_ALONE_CHATTING_AFTER_FINISH_CHAT - isSuccessed is ', isSuccessed, errMsg);
            return {
                ...state,
            }
        }
    },

    [FINISH_CHATTING_SUCCESS]: (state, action) => {
        const { chatRoomSeq, userSeq } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isFinishSuccess } = action.payload;

            if (isFinishSuccess) {
                if (state.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                    callEoForFinishChatRoom(chatRoomSeq, userSeq);
                }

                return produce(state, draft => {
                    const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === chatRoomSeq);
                    if (chat_info !== undefined && chat_info !== null && chat_info.list_member !== undefined && chat_info.list_member !== null) {
                        chat_info.entryYN = 'N';

                        const member_info = chat_info.list_member.find(info => info.userSeq === userSeq);
                        if (member_info !== undefined && member_info !== null) {
                            member_info.userStatus = ConstData.USER_STATUS.LOG_IN;
                            member_info.entryYN = 'N';
                        }
                    }

                    draft.myTeamUpSendKey = null;
                    draft.myTeamUpBindKeyArr = [];

                    draft.performChatRoomInfo = {
                        chatRoomSeq             : -1,
                        chatRoomName            : '',
                        chatRoomType            : '',
                        chatRoomUpdateDT        : '',
                        list_member             : [],
                        isInkSwitch             : false,
                        isVoiceSwitch           : false,
                        isScreenSwitch          : false,
                        isPullSwitch            : false,
                        isPushSwitch            : false
                    };

                    draft.isGotoChatting = true;
                    draft.isAloneChatting = true;
                });
            } else {
                //console.log('FINISH_CHATTING - isFinishSuccess is ', isFinishSuccess);
                return {
                    ...state,
                }
            } 
        } else {
            const { errMsg } = action.payload;
            //console.log('FINISH_CHATTING - isSuccessed is ', isSuccessed, errMsg);
            return {
                ...state,
            }
        }    
    },

    [EXIT_CHATROOM_SUCCESS]: (state, action) => {
        const {chatRoomSeq, userSeq } = action.parms;
        const {isSuccessed} = action.payload;

        if (isSuccessed) {
            const { isExitSuccess } = action.payload;

            if (isExitSuccess) {
                if (state.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                    callEoForFinishChatRoom(chatRoomSeq, userSeq);
                }

                return produce (state, draft => {
                    if (draft.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                        draft.performChatRoomInfo = {
                            chatRoomSeq             : -1,
                            chatRoomName            : '',
                            chatRoomType            : '',
                            chatRoomUpdateDT        : '',
                            list_member             : [],
                            isInkSwitch             : false,
                            isVoiceSwitch           : false,
                            isScreenSwitch          : false,
                            isPullSwitch            : false,
                            isPushSwitch            : false
                        };

                        draft.myTeamUpSendKey = null;
                        draft.myTeamUpBindKeyArr = [];

                        draft.isExitPerformChatRoomModalOpened = false;
                    }

                    draft.list_chatting = draft.list_chatting.filter(info => info.chatRoomSeq !== chatRoomSeq);
                    draft.isExitChatRoomModalOpened = false;
                    draft.isAloneChatting = true;
                });
            } else {
                //console.log('EXIT_CHATROOM - isExitSuccess is ', isExitSuccess);
                return {
                    ...state,
                }
            }
        } else {
            const { errMsg } = action.payload;
            //console.log('EXIT_CHATROOM - isSuccessed is ', isSuccessed, errMsg);
            return {
                ...state,
            }
        }
    },

    [REMOVE_CHATROOM_SUCCESS]: (state, action) => {
        const { chatRoomSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('REMOVE_CHATROOM_SUCCESS');

        if (isSuccessed) {
            const { isRemoveSuccess } = action.payload;

            if (isRemoveSuccess) {
                return {
                    ...state,
                    list_chatting : state.list_chatting.filter(row => row.chatRoomSeq !== chatRoomSeq),
                    isDeleteChatRoomModalOpened : false
                }
            } else {
                //console.log('REMOVE_CHATROOM - isRemoveSuccess is ', isRemoveSuccess);
                return {
                    ...state
                }
            }
        } else {
            const { errMsg } = action.payload;
            //console.log('REMOVE_CHATROOM - isSuccessed is ', isSuccessed, errMsg);

            return {
                ...state
            }
        }
    },

    [REMOVE_EXIT_CHATROOM_SUCCESS]: (state, action) => {
        const { chatRoomSeq, userSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('REMOVE_EXIT_CHATROOM_SUCCESS');

        if(isSuccessed) {
            const { isRemoveSuccess } = action.payload;

            if(isRemoveSuccess) {
                if (state.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                    callEoForFinishChatRoom(chatRoomSeq, userSeq);
                }

                return produce (state, draft => {
                    if (draft.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                        draft.performChatRoomInfo = {
                            chatRoomSeq             : 0,
                            chatRoomName            : '나와의 채팅',
                            chatRoomType            : '',
                            chatRoomUpdateDT        : '',
                            list_member             : [],
                            isInkSwitch             : false,
                            isVoiceSwitch           : false,
                            isScreenSwitch          : false,
                            isPullSwitch            : false,
                            isPushSwitch            : false
                        };

                        draft.isAloneChatting = true;
                    }

                    draft.isAloneChatting = true;
                    draft.list_chatting = draft.list_chatting.filter(info => info.chatRoomSeq !== chatRoomSeq);
                    draft.isDeleteExitChatRoomModalOpened = false;
                });
            } else {
                //console.log('REMOVE_EXIT_CHATROOM - isRemoveSuccess is ', isRemoveSuccess);
                return {
                    ...state,
                }
            }
        } else {
            //console.log('REMOVE_EXIT_CHATROOM - isSuccessed is ', isSuccessed);
            return {
                ...state,
            }
        }
    },

    [GET_LIST_INVITE_CHAT_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log('GET_LIST_INVITE_CHAT_SUCCESS');

        if (isSuccessed) {
            const { list_user } = action.payload;

            if (list_user !== undefined && list_user !== null) {
                return {
                    ...state,
                    isChattingMenuOpened : false,
                    isInviteMemberModalOpened : !state.isInviteMemberModalOpened,
                    isLiveListModalOpened : false,

                    list_inviteableFriends : list_user.map(user => ({
                        userSeq             : user.FRIEND_SEQ,
                        userNickname        : user.USER_NICKNAME,
                        userProfile         : user.USER_PROFILE,
                        profileImgUrl       : user.USER_PROFILE_IMG,
                        isPossibleInvite    : user.CAN_YN
                    }))
                }
            } else {
                //console.log('GET_LIST_INVITE_CHAT - list_user => ', list_user);
                return {
                    ...state,
                }
            }
        } else {
            const { errMsg } = action.payload;
            //console.log('GET_LIST_INVITE_CHAT - isSuccessed is ', isSuccessed, errMsg);
            return {
                ...state
            }
        }
    },

    [INVITE_CHAT_MEMBER_SUCCESS]: (state, action) => {
        const { chatRoomSeq, userSEQList } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isInviteSuccess } = action.payload;

            if (isInviteSuccess) {
                const { members_info } = action.payload;

                if (members_info !== undefined && members_info !== null) {
                    let list_member = members_info.map(row => ({
                        userSeq         : row.USER_SEQ,
                        userNickname    : row.USER_NICKNAME,
                        userProfile     : row.USER_PROFILE,
                        profileImgUrl   : row.USER_PROFILE_IMG,
                        userStatus      : row.MEMBER_STATUS,
                        userGateWay     : row.gateWay === undefined || row.gateWay === null ? 'soup.server' : row.gateWay,
                        entryYN         : row.ENTRY_YN
                    }));

                    if (state.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                        list_member = list_member.map(member => ({
                            ...member,
                            isInkON         : state.performChatRoomInfo.isInkSwitch,
                            isLiveON        : state.performChatRoomInfo.isScreenSwitch,
                            isVoiceON       : state.performChatRoomInfo.isVoiceSwitch,
                            isPullScreen    : state.performChatRoomInfo.isPullSwitch,
                            isPushScreen    : state.performChatRoomInfo.isPushSwitch,
                        }))

                        // call eo invite member
                    }
                    
                    return produce(state, draft => {
                        if (draft.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                            draft.performChatRoomInfo.list_member = list_member;
                        }

                        draft.isInviteMemberModalOpened = false;

                        const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === chatRoomSeq);
                        if (chat_info !== undefined && chat_info !== null) {
                            chat_info.list_member = list_member;
                        }

                        if (userSEQList !== undefined && userSEQList !== null) {
                            userSEQList.forEach(userSeq => {
                                const user_info = draft.list_inviteableFriends.find(info => info.userSeq === userSeq);
                                if (user_info !== undefined && user_info !== null) {
                                    user_info.isPossibleInvite = 'N';
                                }
                            });
                        }
                    });
                } else {
                    //console.log('INVITE_CHAT_MEMBER - members_info is ', members_info);
                    return {
                        ...state
                    }
                }
            } else {
                //console.log('INVITE_CHAT_MEMBER - isInviteSuccess is ', isInviteSuccess);
                return {
                    ...state
                }
            }
        } else {
            const { errMsg } = action.payload;
            //console.log('INVITE_CHAT_MEMBER - isSuccessed is', isSuccessed, errMsg);
            return {
                ...state
            }
        }
    },

    [REMOVE_CHATROOM_MEMBER_SUCCESS]: (state, action) => {
        const { chatRoomSeq, removeUserSeq } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isRemoveSuccess } = action.payload;

            if (isRemoveSuccess) {
                if (state.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                    // call eo remove member
                }

                return produce(state, draft => {
                    if (draft.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                        if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                            draft.performChatRoomInfo.list_member = draft.performChatRoomInfo.list_member.filter(info => info.userSeq !== removeUserSeq);
                        }
                    }

                    if (draft.selectedChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                        if (draft.selectedChatRoomInfo.list_member !== undefined && draft.selectedChatRoomInfo.list_member !== null) {
                            draft.selectedChatRoomInfo.list_member = draft.selectedChatRoomInfo.list_member.filter(info => info.userSeq !== removeUserSeq);
                        }
                    }

                    const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === chatRoomSeq);
                    if (chat_info !== undefined && chat_info !== null) {
                        if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                            chat_info.list_member = chat_info.list_member.filter(info => info.userSeq !== removeUserSeq);
                        }
                    }

                    draft.deleteChatRoomMemberInfo = {
                        chatRoomSeq                 : -1,
                        userSeq                     : -1,
                        userNickname                : '',
                        profileImgUrl               : '',
                        entryYN                     : 'N',
                    };

                    draft.isDeleteChatRoomMemberModalOpened = false;
                });
            } else {
                //console.log('REMOVE_CHATROOM_MEMBER - isRemoveSuccess is ', isRemoveSuccess);
                return {
                    ...state
                }
            }
        } else {
            const { errMsg } = action.payload;
            //console.log('REMOVE_CHATROOM_MEMBER - isSuccessed is ', isSuccessed, errMsg);
            return {
                ...state
            }
        }
    },

    /**********************************************************/
    ////////////////////////// 리모콘 //////////////////////////
    /**********************************************************/
    
    // from HiClass
    [UPDATE_TEAMUP_MEMBER_STATUS]: (state, action) => {
        const { command } = action.payload;
        //console.log('UPDATE_TEAMUP_MEMBER_STATUS');

        switch(command) {
            case 'voice' : 
                break;

            case 'live' :
                break;

            default:
                break;
        }

        return {
            ...state
        }
    },

    [REMOCON_TEAMUP_INK_STATUS]: (state, action) => {
        const { selectedFunc, memberSeq, memberSeqList, isSelect } = action.payload;
        //console.log('REMOCON_TEAMUP_INK_STATUS');

        if (selectedFunc === 'TEAMUP_LIST') {
            const actionData = {
                target      : 'teamup',
                command     : 'inkEnable',
                isAll       : true,
                userSeq     : -1,
                isSelect    : isSelect
            };

            callEoForSendAction('remocon_info', actionData);

            return produce(state, draft => {
                if (draft.performChatRoomInfo.chatRoomSeq > 0) {
                    draft.performChatRoomInfo.isInkSwitch = isSelect;

                    if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                        if (memberSeqList !== undefined && memberSeqList !== null) {
                            memberSeqList.forEach(member => {
                                const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === member.userSeq);
                                if (member_info !== undefined && member_info !== null) {
                                    member_info.isInkON = isSelect;
                                }
                            });
                        }
                    }

                    const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === draft.performChatRoomInfo.chatRoomSeq);
                    if (chat_info !== undefined && chat_info !== null) {
                        chat_info.isInkSwitch = isSelect;
                    }
                }
            });
        } else {
            const actionData = {
                target      : 'teamup',
                command     : 'inkEnable',
                isAll       : false,
                userSeq     : memberSeq,
                isSelect    : isSelect
            };

            callEoForSendAction('remocon_info', actionData);

            return produce(state, draft => {
                if (draft.performChatRoomInfo.chatRoomSeq > 0) {
                    if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                        const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === memberSeq);
                        if (member_info !== undefined && member_info !== null) {
                            member_info.isInkON = isSelect;
                        }
                    }
                }
            });
        }
    },
    
    [REMOCON_TEAMUP_VOICE_STATUS]: (state, action) => {
        const { selectedFunc, memberSeq, memberSeqList, isSelect } = action.payload;
        //console.log('REMOCON_TEAMUP_VOICE_STATUS');

        if (selectedFunc === 'TEAMUP_LIST') {
            const actionData = {
                target      : 'teamup',
                command     : 'soundControl',
                isAll       : true,
                userSeq     : -1,
                isSelect    : isSelect,
            };

            callEoForSendAction('remocon_info', actionData);

            return produce(state, draft => {
                if (draft.performChatRoomInfo.chatRoomSeq > 0) {
                    draft.performChatRoomInfo.isVoiceSwitch = isSelect;

                    if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                        if (memberSeqList !== undefined && memberSeqList !== null) {
                            memberSeqList.forEach(member => {
                                const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === member.userSeq);
                                if (member_info !== undefined && member_info !== null) {
                                    member_info.isVoiceON = isSelect;
                                }
                            });
                        }
                    }

                    const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === draft.performChatRoomInfo.chatRoomSeq);
                    if (chat_info !== undefined && chat_info !== null) {
                        chat_info.isVoiceSwitch = isSelect;
                    }
                }
            });
        } else {
            const actionData = {
                target      : 'teamup',
                command     : 'soundControl',
                isAll       : false,
                userSeq     : memberSeq,
                isSelect    : isSelect,
            };

            callEoForSendAction('remocon_info', actionData);

            return produce(state, draft => {
                if (draft.performChatRoomInfo.chatRoomSeq > 0) {
                    if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                        const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === memberSeq);
                        if (member_info !== undefined && member_info !== null) {
                            member_info.isVoiceON = isSelect;
                        }
                    }
                }
            });
        }
    },
    
    [REMOCON_TEAMUP_LIVE_STATUS]: (state, action) => {
        const { selectedFunc, memberSeq, memberSeqList, isSelect } = action.payload;
        //console.log('REMOCON_TEAMUP_LIVE_STATUS');

        if (selectedFunc === 'TEAMUP_LIST') {
            const actionData = {
                target      : 'teamup',
                command     : 'screenControl',
                isAll       : true,
                userSeq     : -1,
                isSelect    : isSelect,
            };

            callEoForSendAction('remocon_info', actionData);

            return produce(state, draft => {
                if (draft.performChatRoomInfo.chatRoomSeq > 0) {
                    draft.performChatRoomInfo.isScreenSwitch = isSelect;

                    if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                        if (memberSeqList !== undefined && memberSeqList !== null) {
                            memberSeqList.forEach(member => {
                                const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === member.userSeq);
                                if (member_info !== undefined && member_info !== null) {
                                    member_info.isLiveON = isSelect;
                                }
                            });
                        }
                    }

                    const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === draft.performChatRoomInfo.chatRoomSeq);
                    if (chat_info !== undefined && chat_info !== null) {
                        chat_info.isScreenSwitch = isSelect;
                    }
                }
            });
        } else {
            const actionData = {
                target      : 'teamup',
                command     : 'screenControl',
                isAll       : false,
                userSeq     : memberSeq,
                isSelect    : isSelect,
            };

            callEoForSendAction('remocon_info', actionData);

            return produce(state, draft => {
                if (draft.performChatRoomInfo.chatRoomSeq > 0) {
                    if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                        const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === memberSeq);
                        if (member_info !== undefined && member_info !== null) {
                            member_info.isLiveON = isSelect;
                        }
                    }
                }
            });
        }
    },

    [REMOCON_TEAMUP_PULL_SCREEN]: (state, action) => {
        const { selectedFunc, memberSeq, memberSeqList, isSelect } = action.payload;
        //console.log('REMOCON_TEAMUP_PULL_SCREEN');

        if (selectedFunc === 'TEAMUP_LIST') {
            const actionData = {
                target      : 'teamup',
                command     : 'pullScreen',
                isAll       : true,
                userSeq     : -1,
                isSelect    : isSelect,
            };

            callEoForSendAction('remocon_info', actionData);

            return produce(state, draft => {
                if (draft.performChatRoomInfo.chatRoomSeq > 0) {
                    draft.performChatRoomInfo.isPullSwitch = isSelect;

                    if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                        if (memberSeqList !== undefined && memberSeqList !== null) {
                            memberSeqList.forEach(member => {
                                const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === member.userSeq);
                                if (member_info !== undefined && member_info !== null) {
                                    member_info.isPullScreen = isSelect;
                                }
                            });
                        }
                    }

                    const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === draft.performChatRoomInfo.chatRoomSeq);
                    if (chat_info !== undefined && chat_info !== null) {
                        chat_info.isPullSwitch = isSelect;
                    }
                }
            });
        } else {
            const actionData = {
                target      : 'teamup',
                command     : 'pullScreen',
                isAll       : false,
                userSeq     : memberSeq,
                isSelect    : isSelect,
            };

            callEoForSendAction('remocon_info', actionData);

            return produce(state, draft => {
                if (draft.performChatRoomInfo.chatRoomSeq > 0) {
                    if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                        const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === memberSeq);
                        if (member_info !== undefined && member_info !== null) {
                            member_info.isPullScreen = isSelect;
                        }
                    }
                }
            });
        }
    },
    
    [REMOCON_TEAMUP_PUSH_SCREEN]: (state, action) => {
        const { selectedFunc, memberSeq, memberSeqList, isSelect } = action.payload;
        //console.log('REMOCON_TEAMUP_PUSH_SCREEN');

        if (selectedFunc === 'TEAMUP_LIST') {
            const actionData = {
                target      : 'teamup',
                command     : 'pushScreen',
                isAll       : true,
                userSeq     : -1,
                isSelect    : isSelect,
            };

            callEoForSendAction('remocon_info', actionData);

            return produce(state, draft => {
                if (draft.performChatRoomInfo.chatRoomSeq > 0) {
                    draft.performChatRoomInfo.isPushSwitch = isSelect;

                    if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                        if (memberSeqList !== undefined && memberSeqList !== null) {
                            memberSeqList.forEach(member => {
                                const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === member.userSeq);
                                if (member_info !== undefined && member_info !== null) {
                                    member_info.isPushScreen = isSelect;
                                }
                            });
                        }
                    }

                    const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === draft.performChatRoomInfo.chatRoomSeq);
                    if (chat_info !== undefined && chat_info !== null) {
                        chat_info.isPushSwitch = isSelect;
                    }
                }
            });
        } else {
            const actionData = {
                target      : 'teamup',
                command     : 'pushScreen',
                isAll       : false,
                userSeq     : memberSeq,
                isSelect    : isSelect,
            };

            callEoForSendAction('remocon_info', actionData);

            return produce(state, draft => {
                if (draft.performChatRoomInfo.chatRoomSeq > 0) {
                    if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                        const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === memberSeq);
                        if (member_info !== undefined && member_info !== null) {
                            member_info.isPushScreen = isSelect;
                        }
                    }
                }
            });
        }
    },
    
    /**********************************************************/
    /////////////////////////// 모 달 ///////////////////////////
    /**********************************************************/

    [TEAMUP_OPEN_TOGGLE]: (state, action) => {
        //console.log('TEAMUP_OPEN_TOGGLE');
        
        if (state.isOpenedTeamUp) {
            callEoForSendAction('mediaOpen', { action: 'CLOSE' });
        } else {
            callEoForSendAction('mediaOpen', { action: 'OPEN' });
        }
        
        return {
            ...state,
            isOpenedTeamUp  : !state.isOpenedTeamUp
        }
    },

    [TOGGLE_ALERT_MODAL]: (state, action) => {
        const { selectedFunc, userNickname } = action.payload;
        //console.log('TOGGLE_ALERT_MODAL', selectedFunc, userNickname);
        
        if(selectedFunc === 'NONE') {
            return {
                ...state,
                selectedFunc    : selectedFunc,
                isAlertModal    : false
            }
        } else {
            return {
                ...state,
                isAlertModal            : !state.isAlertModal,
                modalType               : selectedFunc,
                modalTargetUserNickname : userNickname
            }
        }
    },

    [CLOSE_MODAL_CHAT_MENU]: (state, action) => {
        //console.log('CLOSE_MODAL_CHAT_MENU', action.payload);

        return {
            ...state,
            isChattingMenuOpened        : false,
            isInviteMemberModalOpened   : false,
            isLiveListModalOpened       : false
        }
    },

    [TOGGLE_CHAT_MENU]: (state, action) => {
        //console.log('TOGGLE_CHAT_MENU', action.payload);
        //console.log(`performChatRoomInfo chatRoomSeq[${state.performChatRoomInfo.chatRoomSeq}]`);
        //console.log(state.performChatRoomInfo.list_member);

        return {
            ...state,
            isChattingMenuOpened        : !state.isChattingMenuOpened,
            isInviteMemberModalOpened   : false,
            isLiveListModalOpened       : false
        }
    },

    [TOGGLE_CHAT_MENU_LIVE]: (state, action) => {
        //console.log('TOGGLE_CHAT_MENU_LIVE', action.payload);

        return {
            ...state,
            isLiveListModalOpened   : !state.isLiveListModalOpened,
            isChattingMenuOpened    : !state.isChattingMenuOpened
        }
    },

    [CLOSE_MODAL_CHAT_LIST]: (state, action) => {
        //console.log('CLOSE_MODAL_CHAT_LIST', action.payload);

        return {
            ...state,
            selectedFunc                        : action.payload === undefined? 'NONE' : action.payload,
            removedChatRoomName                 : '',
            isAlertRemovedModalOpened           : false,
            isSearchChatListModalOpened         : false,
            isCreateChatRoomModalOpened         : false,
            isConfigChatListModalOpened         : false,
            isParticipateChatRoomModalOpened    : false,
            isEditChatRoomModalOpened           : false,
            isEditChatRoomMemberModalOpened     : false,
            isDeleteChatRoomModalOpened         : false,
            isDeleteChatRoomMemberModalOpened   : false,
        }
    },

    [TOGGLE_SEARCH_CHAT_LIST]: (state, action) => {
        //console.log('TOGGLE_SEARCH_CHAT_LIST', action.payload);

        return {
            ...state,
            selectedFunc                        : action.payload === undefined? 'NONE' : action.payload,
            isSearchChatListModalOpened         : !state.isSearchChatListModalOpened,
            isCreateChatRoomModalOpened         : false,
            isConfigChatListModalOpened         : false,
            isParticipateChatRoomModalOpened    : false,
        }
    },

    [TOGGLE_CREATE_CHATROOM]: (state, action) => {
        //console.log('TOGGLE_CREATE_CHATROOM', action.payload);
        
        return {
            ...state,
            selectedFunc                        : action.payload === undefined? 'NONE' : action.payload,
            isSearchChatListModalOpened         : false,
            isCreateChatRoomModalOpened         : !state.isCreateChatRoomModalOpened,
            isConfigChatListModalOpened         : false,
            isParticipateChatRoomModalOpened    : false,
        }
    },

    [TOGGLE_PARTICIPATE_CHATROOM]: (state, action) => {
        const { chatRoomSeq, chatRoomName } = action.payload;
        //console.log('TOGGLE_PARTICIPATE_CHATROOM', action.payload);

        return {
            ...state,
            participateChatRoomInfo             : {
                chatRoomSeq                     : chatRoomSeq,
                chatRoomName                    : chatRoomName
            },
            selectedFunc                        : action.payload === undefined? 'NONE' : action.payload,
            isSearchChatListModalOpened         : false,
            isCreateChatRoomModalOpened         : false,
            isConfigChatListModalOpened         : false,
            isParticipateChatRoomModalOpened    : !state.isParticipateChatRoomModalOpened,
        }
    },

    [CLOSE_MODAL_CONFIG_CHAT_LIST]: (state, action) => {
        //console.log('CLOSE_MODAL_CONFIG_CHAT_LIST', action.payload);

        return {
            ...state,
            selectedFunc                        : action.payload === undefined ? 'NONE' : action.payload,
            isEditChatRoomModalOpened           : false,
            isEditChatRoomMemberModalOpened     : false,
            isDeleteChatRoomMemberModalOpened   : false,
            isDeleteChatRoomModalOpened         : false,
            isDeleteExitChatRoomModalOpened     : false,
            isExitChatRoomModalOpened           : false,
            isExitPerformChatRoomModalOpened    : false,
        }
    },

    [TOGGLE_CONFIG_CHAT_LIST]: (state, action) => {
        //console.log('TOGGLE_CONFIG_CHAT_LIST', action.payload);

        return {
            ...state,
            selectedFunc                        : action.payload === undefined ? 'NONE' : action.payload,
            isSearchChatListModalOpened         : false,
            isCreateChatRoomModalOpened         : false,
            isConfigChatListModalOpened         : !state.isConfigChatListModalOpened,
            isParticipateChatRoomModalOpened    : false,
            isEditChatRoomModalOpened           : false,
            isEditChatRoomMemberModalOpened     : false,
            isDeleteChatRoomModalOpened         : false,
            isDeleteExitChatRoomModalOpened     : false,
            isDeleteChatRoomMemberModalOpened   : false,
            isExitChatRoomModalOpened           : false,
            isExitPerformChatRoomModalOpened    : false,
        }
    },

    [TOGGLE_EDIT_CHATROOM_MEMBER]: (state, action) => {
        const { selectedFunc, chatRoomSeq, chatRoomCreator, list_member } = action.payload;
        //console.log('TOGGLE_EDIT_CHATROOM_MEMBER', action.payload);

        return {
            ...state,
            selectedFunc                        : selectedFunc === undefined ? 'NONE' : selectedFunc,
            selectedChatRoomInfo                : {
                chatRoomSeq                     : chatRoomSeq,
                list_member                     : list_member,
                chatRoomCreator                 : chatRoomCreator
            },
            isConfigChatListModalOpened         : state.isConfigChatListModalOpened,
            isEditChatRoomModalOpened           : false,
            isEditChatRoomMemberModalOpened     : !state.isEditChatRoomMemberModalOpened,
            isDeleteChatRoomModalOpened         : false,
            isDeleteExitChatRoomModalOpened     : false,
            isDeleteChatRoomMemberModalOpened   : false,
            isExitChatRoomModalOpened           : false,
            isExitPerformChatRoomModalOpened    : false,
        }
    },

    [TOGGLE_REMOVE_CHATROOM_MEMBER]: (state, action) => {
        const { selectedFunc, chatRoomSeq, memberSeq, userNickname, profileImgUrl, entryYN } = action.payload;
        //console.log('TOGGLE_REMOVE_CHATROOM_MEMBER', action.payload);

        return {
            ...state,
            selectedFunc                        : selectedFunc === undefined ? 'NONE' : selectedFunc,
            deleteChatRoomMemberInfo            : {
                chatRoomSeq                     : chatRoomSeq,
                userSeq                         : memberSeq,
                userNickname                    : userNickname,
                profileImgUrl                   : profileImgUrl,
                entryYN                         : entryYN,
            },
            isDeleteChatRoomMemberModalOpened   : !state.isDeleteChatRoomMemberModalOpened
        }
    },

    [TOGGLE_EDIT_CHATROOM]: (state, action) => {
        const { selectedFunc, chatRoomName, chatRoomSeq } = action.payload;
        //console.log('TOGGLE_EDIT_CHATROOM', action.payload);
        
        return {
            ...state,
            selectedFunc                        : selectedFunc === undefined ? 'NONE' : selectedFunc,
            selectedChatRoomInfo                : {
                chatRoomSeq                     : chatRoomSeq,
                chatRoomName                    : chatRoomName
            },
            isConfigChatListModalOpened         : state.isConfigChatListModalOpened,
            isEditChatRoomModalOpened           : !state.isEditChatRoomModalOpened,
            isEditChatRoomMemberModalOpened     : false,
            isDeleteChatRoomModalOpened         : false,
            isDeleteExitChatRoomModalOpened     : false,
            isDeleteChatRoomMemberModalOpened   : false,
            isExitChatRoomModalOpened           : false,
            isExitPerformChatRoomModalOpened    : false,
        }
    },

    [TOGGLE_REMOVE_CHATROOM]: (state, action) => {
        const { selectedFunc, chatRoomSeq, chatRoomName, list_member } = action.payload;
        //console.log('TOGGLE_REMOVE_CHATROOM', action.payload);

        return {
            ...state,
            selectedFunc                        : selectedFunc === undefined ? 'NONE' : selectedFunc,
            selectedChatRoomInfo                : {
                chatRoomSeq                     : chatRoomSeq,
                chatRoomName                    : chatRoomName,
                list_member                     : list_member
            },
            isConfigChatListModalOpened         : state.isConfigChatListModalOpened,
            isEditChatRoomModalOpened           : false,
            isEditChatRoomMemberModalOpened     : false,
            isDeleteChatRoomModalOpened         : !state.isDeleteChatRoomModalOpened,
            isDeleteExitChatRoomModalOpened     : false,
            isDeleteChatRoomMemberModalOpened   : false,
            isExitChatRoomModalOpened           : false,
            isExitPerformChatRoomModalOpened    : false,
        }
    },

    [TOGGLE_DELETE_EXIT_CHATROOM]: (state, action) => {
        const { selectedFunc, chatRoomSeq, chatRoomName, list_member } = action.payload;
        //console.log('TOGGLE_DELETE_EXIT_CHATROOM', action.payload);
        
        return {
            ...state,
            selectedFunc                        : selectedFunc === undefined ? 'NONE' : selectedFunc,
            selectedChatRoomInfo                : {
                chatRoomSeq                     : chatRoomSeq,
                chatRoomName                    : chatRoomName,
                list_member                     : list_member
            },
            isConfigChatListModalOpened         : state.isConfigChatListModalOpened,
            isEditChatRoomModalOpened           : false,
            isEditChatRoomMemberModalOpened     : false,
            isDeleteChatRoomModalOpened         : false,
            isDeleteExitChatRoomModalOpened     : !state.isDeleteExitChatRoomModalOpened,
            isDeleteChatRoomMemberModalOpened   : false,
            isExitChatRoomModalOpened           : false,
            isExitPerformChatRoomModalOpened    : false,
        }
    },

    [TOGGLE_EXIT_CHATROOM]: (state, action) => {
        const { selectedFunc, chatRoomSeq, chatRoomName, entryYN } = action.payload;
        //console.log('TOGGLE_EXIT_CHATROOM', action.payload);
        
        return {
            ...state,
            selectedFunc                        : selectedFunc === undefined ? 'NONE' : selectedFunc,
            selectedChatRoomInfo                : {
                chatRoomSeq                     : chatRoomSeq,
                chatRoomName                    : chatRoomName
            },
            isConfigChatListModalOpened         : state.isConfigChatListModalOpened,
            isEditChatRoomModalOpened           : false,
            isEditChatRoomMemberModalOpened     : false,
            isDeleteChatRoomModalOpened         : false,
            isDeleteExitChatRoomModalOpened     : false,
            isDeleteChatRoomMemberModalOpened   : false,
            isExitChatRoomModalOpened           : entryYN === 'N' ? !state.isExitChatRoomModalOpened : false,
            isExitPerformChatRoomModalOpened    : entryYN === 'Y' ? !state.isExitPerformChatRoomModalOpened : false
        }
    },

    /**********************************************************/
    ///////////////////////// 페이지 이동 /////////////////////////
    /**********************************************************/

    [CHANGE_TRUE_TO_GOTO_PAGE]: (state, action) => {
        const { page } = action.payload;
        //console.log('CHANGE_TRUE_TO_GOTO_PAGE', page);

        if(page === 'CHATTING') {
            return {
                ...state,
                isGotoChatting      : true,
                isGotoChatList      : false,
                isGotoLiveList      : false,
                isGotoFriendList    : false
            }
        } else if(page === 'LIST_CHAT') {
            return {
                ...state,
                isGotoChatting      : false,
                isGotoChatList      : true,
                isGotoLiveList      : false,
                isGotoFriendList    : false
            }
        } else if(page === 'LIST_LIVE') {
            return {
                ...state,
                isGotoChatting      : false,
                isGotoChatList      : false,
                isGotoLiveList      : true,
                isGotoFriendList    : false
            }
        } else {
            return {
                ...state,
                isGotoChatting      : false,
                isGotoChatList      : false,
                isGotoLiveList      : false,
                isGotoFriendList    : true
            }
        }            
    },

    [CHANGE_FALSE_TO_GOTO_CHATTING]: (state, action) => {
        return {
            ...state,
            isGotoChatting : false
        }
    },

    [CHANGE_FALSE_TO_GOTO_LIST_FRIEND]: (state, action) => {
        return {
            ...state,
            isGotoFriendList : false
        }
    },

    [CHANGE_FALSE_TO_GOTO_LIST_CHAT]: (state, action) => {
        return {
            ...state,
            isGotoChatList : false
        }
    },

    [CHANGE_FALSE_TO_GOTO_LIST_LIVE]: (state, action) => {
        return {
            ...state,
            isGotoLiveList : false
        }
    },

    /**********************************************************/
    /////////////////////////// RBMQ ///////////////////////////
    /**********************************************************/

    [MQ_LOGIN_CHAT]: (state, action) => {
        const { userSeq } = action.payload;

        return produce(state, draft => {
            if (draft.performChatRoomInfo.chatRoomSeq > 0) {
                if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                    const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info !== undefined && member_info !== null) {
                        member_info.userStatus = ConstData.USER_STATUS.LOG_IN;
                    }
                }
            }

            draft.list_chatting.forEach(chat_info => {  // 팀업마다 사용자를 찾아서 변경
                if (chat_info !== undefined && chat_info !== null) {
                    if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                        const member_info = chat_info.list_member.find(info => info.userSeq === userSeq);
                        if (member_info !== undefined && member_info !== null) {
                            member_info.userStatus = ConstData.USER_STATUS.LOG_IN;
                        }
                    }
                }
            });
        });
    },

    [MQ_LOGOUT_CHAT]: (state, action) => {
        const { userSeq, isMe } = action.payload;
        //console.log('MQ_LOGOUT_CHAT - ', userSeq);

        return produce(state, draft => {
            if (draft.performChatRoomInfo.chatRoomSeq > 0) {
                if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                    const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info !== undefined && member_info !== null) {
                        member_info.userStatus = ConstData.USER_STATUS.LOG_OUT;
                        if (member_info.entryYN === 'Y') {
                            member_info.entryYN = 'N';

                            let nickname = member_info.userNickname;
                            if (nickname.length > 8) {
                                nickname = nickname.substring(0, 6) + '...';
                            }
                            draft.nextPhaseData = nickname + ' 님이 팀업을 종료하였습니다.';
                        }
                    }
                }
            }

            draft.list_chatting.forEach(chat_info => {
                if (chat_info !== undefined && chat_info !== null) {
                    if (isMe) {
                        chat_info.entryYN = 'N';
                    }

                    if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                        const member_info = chat_info.list_member.find(info => info.userSeq === userSeq);
                        if (member_info !== undefined && member_info !== null) {
                            member_info.userStatus = ConstData.USER_STATUS.LOG_OUT;
                            member_info.entryYN = 'N';
                        }
                    }
                }
            });
        });
    },

    [MQ_CHAT_JOIN]: (state, action) => {
        const { join_info, isMe } = action.payload;
        const { chatRoomSeq, userSeq } = join_info;
        //console.log(`MQ_CHAT_JOIN - chatRoomSeq[${chatRoomSeq}] / userSeq[${userSeq}]`);

        return produce(state, draft => {
            if (draft.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                    const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info !== undefined && member_info !== null) {
                        member_info.userStatus = ConstData.USER_STATUS.STUDYING;
                        member_info.entryYN = 'Y';

                        let nickname = member_info.userNickname;
                        if (nickname.length > 8) {
                            nickname = nickname.substring(0, 6) + '...';
                        }
                        draft.nextPhaseData = nickname + ' 님이 팀업에 참여하였습니다.';
                    }
                }
            }

            if (draft.selectedChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                if (draft.selectedChatRoomInfo.list_member !== undefined && draft.selectedChatRoomInfo.list_member !== null) {
                    const member_info = draft.selectedChatRoomInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info !== undefined && member_info !== null) {
                        member_info.userStatus = ConstData.USER_STATUS.STUDYING;
                        member_info.entryYN = 'Y';
                    }
                }
            }

            const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === chatRoomSeq);
            if (chat_info !== undefined && chat_info !== null) {
                if (isMe) {
                    chat_info.entryYN = 'Y';
                }

                if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                    const member_info = chat_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info !== undefined && member_info !== null) {
                        member_info.userStatus = ConstData.USER_STATUS.STUDYING;
                        member_info.entryYN = 'Y';
                    }
                }
            }
        });
    },

    [MQ_CHAT_FINISH]: (state, action) => {
        const { roomSeq, userSeq, isMe } = action.payload;
        //console.log(`MQ_CHAT_FINISH - roomSeq[${roomSeq}] / userSeq[${userSeq}]`);

        return produce(state, draft => {
            if (draft.performChatRoomInfo.chatRoomSeq === roomSeq) {
                if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                    const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info !== undefined && member_info !== null) {
                        member_info.userStatus = ConstData.USER_STATUS.LOG_IN;
                        if (member_info.entryYN === 'Y') {
                            member_info.entryYN = 'N';

                            let nickname = member_info.userNickname;
                            if (nickname.length > 8) {
                                nickname = nickname.substring(0, 6) + '...';
                            }
                            draft.nextPhaseData = nickname + ' 님이 팀업을 종료하였습니다.';
                        }
                    }
                }
            }

            if (draft.selectedChatRoomInfo.chatRoomSeq === roomSeq) {
                if (draft.selectedChatRoomInfo.list_member !== undefined && draft.selectedChatRoomInfo.list_member !== null) {
                    const member_info = draft.selectedChatRoomInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info !== undefined && member_info !== null) {
                        member_info.userStatus = ConstData.USER_STATUS.LOG_IN;
                        member_info.entryYN = 'N';
                    }
                }
            }

            const searchChat_info = draft.list_searchChatting.find(info => info.chatRoomSeq === roomSeq);
            if (searchChat_info !== undefined && searchChat_info !== null) {
                if (isMe) {
                    searchChat_info.entryYN = 'N';
                }

                if (searchChat_info.list_member !== undefined && searchChat_info.list_member !== null) {
                    const member_info = searchChat_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info !== undefined && member_info !== null) {
                        member_info.userStatus = ConstData.USER_STATUS.LOG_IN;
                        member_info.entryYN = 'N';
                    }
                }
            }

            const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === roomSeq);
            if (chat_info !== undefined && chat_info !== null) {
                if (isMe) {
                    chat_info.entryYN = 'N';
                }

                if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                    const member_info = chat_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info !== undefined && member_info !== null) {
                        member_info.userStatus = ConstData.USER_STATUS.LOG_IN;
                        member_info.entryYN = 'N';
                    }
                }
            }
        });
    },

    [MQ_CHAT_EXIT]: (state, action) => {
        const { roomSeq, userSeq } = action.payload;
        //console.log(`MQ_CHAT_EXIT - roomSeq[${roomSeq}] / userSeq[${userSeq}]`);

        return produce(state, draft => {
            if (draft.performChatRoomInfo.chatRoomSeq === roomSeq) {
                if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                    const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info !== undefined && member_info !== null) {
                        let nickname = member_info.userNickname;
                        if (nickname.length > 8) {
                            nickname = nickname.substring(0, 6) + '...';
                        }
                        draft.nextPhaseData = nickname + ' 님이 팀업을 나갔습니다.';

                        draft.performChatRoomInfo.list_member = draft.performChatRoomInfo.list_member.filter(info => info.userSeq !== userSeq);
                    }
                }
            }

            if (draft.selectedChatRoomInfo.chatRoomSeq === roomSeq) {
                if (draft.selectedChatRoomInfo.list_member !== undefined && draft.selectedChatRoomInfo.list_member !== null) {
                    draft.selectedChatRoomInfo.list_member = draft.selectedChatRoomInfo.list_member.filter(info => info.userSeq !== userSeq);
                }
            }

            const searchChat_info = draft.list_searchChatting.find(info => info.chatRoomSeq === roomSeq);
            if (searchChat_info !== undefined && searchChat_info !== null) {
                if (searchChat_info.list_member !== undefined && searchChat_info.list_member !== null) {
                    searchChat_info.list_member = searchChat_info.list_member.filter(info => info.userSeq !== userSeq);
                }
            }

            const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === roomSeq);
            if (chat_info !== undefined && chat_info !== null) {
                if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                    chat_info.list_member = chat_info.list_member.filter(info => info.userSeq !== userSeq);
                }
            }
        });
    },

    [MQ_CHAT_CREATE]: (state, action) => {
        const { chatRoom_info } = action.payload;
        //console.log('MQ_CHAT_CREATE - chatRoom_info => ', chatRoom_info);

        if (chatRoom_info !== undefined && chatRoom_info !== null) {
            return produce (state, draft => {
                draft.list_searchChatting.push(chatRoom_info);

                if (chatRoom_info.list_member !== undefined && chatRoom_info.list_member !== null) {
                    chatRoom_info.list_member = chatRoom_info.list_member.map(member_info => ({
                        ...member_info,
                        isInkON         : true,
                        isVoiceON       : true,
                        isLiveON        : true,
                        isPullScreen    : true,
                        isPushScreen    : true
                    }));
                }

                draft.list_chatting.push({
                    ...chatRoom_info,
                    isInkSwitch     : true,
                    isVoiceSwitch   : true,
                    isScreenSwitch  : true,
                    isPullSwitch    : true,
                    isPushSwitch    : true
                });
            });
        } else {
            //console.log('MQ_CHAT_CREATE - chatRoom_info => ', chatRoom_info);
            return {
                ...state
            }
        }
    },

    [MQ_CHAT_UPDATE]: (state, action) => {
        const { chatRoom_info } = action.payload;
        //console.log('MQ_CHAT_UPDATE');
        
        if (chatRoom_info !== undefined && chatRoom_info !== null) {
            return produce(state, draft => {
                if (draft.performChatRoomInfo.chatRoomSeq === chatRoom_info.chatRoomSeq) {
                    draft.performChatRoomInfo.chatRoomName = chatRoom_info.chatRoomName;
                    draft.performChatRoomInfo.chatRoomType = chatRoom_info.chatRoomType;
                    draft.performChatRoomInfo.chatRoomUpdateDT = chatRoom_info.chatRoomUpdateDT;
                }

                if (draft.selectedChatRoomInfo.chatRoomSeq === chatRoom_info.chatRoomSeq) {
                    draft.selectedChatRoomInfo.chatRoomName = chatRoom_info.chatRoomName;
                    if (chatRoom_info.list_member !== undefined && chatRoom_info.list_member !== null) {
                        draft.selectedChatRoomInfo.list_member = chatRoom_info.list_member;
                    }
                }

                if (draft.participateChatRoomInfo.chatRoomSeq === chatRoom_info.chatRoomSeq) {
                    draft.participateChatRoomInfo.chatRoomName = chatRoom_info.chatRoomName;
                }

                const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === chatRoom_info.chatRoomSeq);
                if (chat_info !== undefined && chat_info !== null) {
                    chat_info.chatRoomName = chatRoom_info.chatRoomName;
                    chat_info.chatRoomType = chatRoom_info.chatRoomType;
                    chat_info.chatRoomUpdateDT = chatRoom_info.chatRoomUpdateDT;
                    chat_info.entryCnt = chatRoom_info.entryCnt;
                    chat_info.entryYN = chatRoom_info.entryYN;
                    if (chatRoom_info.list_member !== undefined && chatRoom_info !== null) {
                        chat_info.list_member = chatRoom_info.list_member;
                    }
                }

                const searchChat_info = draft.list_searchChatting.find(info => info.chatRoomSeq === chatRoom_info.chatRoomSeq);
                if (searchChat_info !== undefined && searchChat_info !== null) {
                    searchChat_info.chatRoomName = chatRoom_info.chatRoomName;
                    searchChat_info.chatRoomType = chatRoom_info.chatRoomType;
                    searchChat_info.chatRoomUpdateDT = chatRoom_info.chatRoomUpdateDT;
                    searchChat_info.entryCnt = chatRoom_info.entryCnt;
                    searchChat_info.entryYN = chatRoom_info.entryYN;
                    if (chatRoom_info.list_member !== undefined && chatRoom_info.list_member !== null) {
                        searchChat_info.list_member = chatRoom_info.list_member;
                    }
                }
            });
        } else {
            //console.log('MQ_CHAT_UPDATE chatRoom_info => ', chatRoom_info);
            return {
                ...state
            }
        }
    },

    [MQ_CHAT_NAME_UPDATE]: (state, action) => {
        const { chatRoom_info } = action.payload;
        //console.log('MQ_CHAT_NAME_UPDATE');

        if (chatRoom_info !== undefined && chatRoom_info !== null) {
            if (state.performChatRoomInfo.chatRoomSeq === chatRoom_info.chatRoomSeq) {
                callEoForUpdateChatRoomName(chatRoom_info.chatRoomSeq, chatRoom_info.chatRoomName);
            }

            return produce (state, draft => {
                if (draft.performChatRoomInfo.chatRoomSeq === chatRoom_info.chatRoomSeq) {
                    draft.performChatRoomInfo.chatRoomName = chatRoom_info.chatRoomName;
                }

                if (draft.selectedChatRoomInfo.chatRoomSeq === chatRoom_info.chatRoomSeq) {
                    draft.selectedChatRoomInfo.chatRoomName = chatRoom_info.chatRoomName;
                }

                if (draft.participateChatRoomInfo.chatRoomSeq === chatRoom_info.chatRoomSeq) {
                    draft.performChatRoomInfo.chatRoomName = chatRoom_info.chatRoomName;
                }

                const searchChat_info = draft.list_searchChatting.find(info => info.chatRoomSeq === chatRoom_info.chatRoomSeq);
                if (searchChat_info !== undefined && searchChat_info !== null) {
                    searchChat_info.chatRoomName = chatRoom_info.chatRoomName;
                }

                const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === chatRoom_info.chatRoomSeq);
                if (chat_info !== undefined && chat_info !== null) {
                    chat_info.chatRoomName = chatRoom_info.chatRoomName;
                }
            });
        } else {
            //console.log('MQ_CHAT_NAME_UPDATE - chatRoom_info => ', chatRoom_info);
            return {
                ...state
            }
        }
    },

    [MQ_CHAT_TYPE_UPDATE]: (state, action) => {
        const { chatRoom_info } = action.payload;

        if (chatRoom_info !== undefined && chatRoom_info !== null) {
            return produce (state, draft => {
                if (draft.performChatRoomInfo.chatRoomSeq === chatRoom_info.chatRoomSeq) {
                    draft.performChatRoomInfo.chatRoomType = chatRoom_info.chatRoomType;
                }

                const searchChat_info = draft.list_searchChatting.find(info => info.chatRoomSeq === chatRoom_info.chatRoomSeq);
                if (searchChat_info !== undefined && searchChat_info !== null) {
                    searchChat_info.chatRoomType = chatRoom_info.chatRoomType;
                }

                const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === chatRoom_info.chatRoomSeq);
                if (chat_info !== undefined && chat_info !== null) {
                    chat_info.chatRoomType = chatRoom_info.chatRoomType;
                }
            });
        } else {
            //console.log('MQ_CHAT_TYPE_UPDATE - chatRoom_info => ', chatRoom_info);
            return {
                ...state
            }
        }
    },

    [MQ_CHAT_REMOVE]: (state, action) => {
        const { roomSeq } = action.payload;
        //console.log(`MQ_CHAT_REMOVE chatRoomSeq[${roomSeq}]`);

        if (state.performChatRoomInfo.chatRoomSeq === roomSeq) {
            // call eo remove...
        }

        return produce(state, draft => {
            if (draft.performChatRoomInfo.chatRoomSeq === roomSeq) {
                draft.isAlertRemovedModalOpened = true;
                draft.removedChatRoomName = state.performChatRoomInfo.chatRoomName;

                draft.performChatRoomInfo = {
                    chatRoomSeq             : -1,
                    chatRoomName            : '',
                    chatRoomType            : '',
                    chatRoomUpdateDT        : '',
                    list_member             : [],
                    isInkSwitch             : false,
                    isVoiceSwitch           : false,
                    isScreenSwitch          : false,
                    isPullSwitch            : false,
                    isPushSwitch            : false
                };

                draft.isGotoChatList = true;
                draft.isAloneChatting = true;
                draft.isChattingMenuOpened = false;
                draft.isLiveListModalOpened = false;
                draft.isInviteMemberModalOpened = false;

                draft.myTeamUpSendKey = null;
                draft.myTeamUpBindKeyArr = [];
            }

            if (draft.selectedChatRoomInfo.chatRoomSeq === roomSeq) {
                draft.selectedChatRoomInfo = {
                    chatRoomSeq             : -1,
                    chatRoomName            : '',
                    chatRoomCreator         : -1,
                    list_member             : []
                };

                draft.isEditChatRoomMemberModalOpened = false;
            }

            if (draft.participateChatRoomInfo.chatRoomSeq === roomSeq) {
                draft.participateChatRoomInfo = {
                    chatRoomSeq             : -1,
                    chatRoomName            : ''
                };

                draft.isParticipateChatRoomModalOpened = false;
            }

            draft.list_searchChatting = draft.list_searchChatting.filter(info => info.chatRoomSeq !== roomSeq);
            draft.list_chatting = draft.list_chatting.filter(info => info.chatRoomSeq !== roomSeq);
        });
    },

    [MQ_CHAT_MEMBER_INVITE]: (state, action) => {
        const { chatRoom_info } = action.payload;

        if (chatRoom_info !== undefined && chatRoom_info !== null) {
            return produce (state, draft => {
                draft.list_searchChatting.push(chatRoom_info);

                if (chatRoom_info.list_member !== undefined && chatRoom_info.list_member !== null) {
                    chatRoom_info.list_member = chatRoom_info.list_member.map(member_info => ({
                        ...member_info,
                        isInkON         : true,
                        isVoiceON       : true,
                        isLiveON        : true,
                        isPullScreen    : true,
                        isPushScreen    : true
                    }));
                }

                draft.list_chatting.push({
                    ...chatRoom_info,
                    isInkSwitch         : true,
                    isVoiceSwitch       : true,
                    isScreenSwitch      : true,
                    isPullSwitch        : true,
                    isPushSwitch        : true
                });
            });
        } else {
            //console.log('MQ_CHAT_MEMBER_INVITE - chatRoom_info => ', chatRoom_info);
            return {
                ...state
            }
        }
    },

    [MQ_CHAT_MEMBER_ADD]: (state, action) => {
        const { add_info } = action.payload;
        //console.log('MQ_CHAT_MEMBER_ADD');

        if (add_info !== undefined && add_info !== null) {
            const { chatRoomSeq, members_info } = add_info;

            if (chatRoomSeq >= 0 && members_info !== undefined && members_info !== null) {
                if (state.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                    callEoForAddChatRoomMembers(chatRoomSeq, members_info);
                }

                return produce (state, draft => {
                    if (draft.performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                        if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                            members_info.forEach(member_info => {
                                draft.performChatRoomInfo.list_member.push({
                                    ...member_info,
                                    isInkON         : draft.performChatRoomInfo.isInkSwitch,
                                    isVoiceON       : draft.performChatRoomInfo.isVoiceSwitch,
                                    isLiveON        : draft.performChatRoomInfo.isScreenSwitch,
                                    isPullScreen    : draft.performChatRoomInfo.isPullSwitch,
                                    isPushScreen    : draft.performChatRoomInfo.isPushSwitch
                                });
                            });
                        } else {
                            draft.performChatRoomInfo.list_member = members_info.map(member_info => ({
                                ...member_info,
                                isInkON         : draft.performChatRoomInfo.isInkSwitch,
                                isVoiceON       : draft.performChatRoomInfo.isVoiceSwitch,
                                isLiveON        : draft.performChatRoomInfo.isScreenSwitch,
                                isPullScreen    : draft.performChatRoomInfo.isPullSwitch,
                                isPushScreen    : draft.performChatRoomInfo.isPushSwitch
                            }));
                        }

                        let nickname = members_info.map(info => info.userNickname).join('님, ');
                        draft.nextPhaseData = nickname + ' 님이 초대되었습니다';
                    }

                    if (draft.selectedChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                        if (draft.selectedChatRoomInfo.list_member !== undefined && draft.selectedChatRoomInfo.list_member !== null) {
                            draft.selectedChatRoomInfo.list_member = draft.selectedChatRoomInfo.list_member.concat(members_info);
                        } else {
                            draft.selectedChatRoomInfo.list_member = members_info;
                        }
                    }

                    const searchChat_info = draft.list_searchChatting.find(info => info.chatRoomSeq === chatRoomSeq);
                    if (searchChat_info !== undefined && searchChat_info !== null) {
                        if (searchChat_info.list_member !== undefined && searchChat_info.list_member !== null) {
                            searchChat_info.list_member = searchChat_info.list_member.concat(members_info);
                        } else {
                            searchChat_info.list_member = members_info;
                        }
                    }

                    const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === chatRoomSeq);
                    if (chat_info !== undefined && chat_info !== null) {
                        if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                            members_info.forEach(member_info => {
                                chat_info.list_member.push({
                                    ...member_info,
                                    isInkON         : chat_info.isInkSwitch,
                                    isVoiceON       : chat_info.isVoiceSwitch,
                                    isLiveON        : chat_info.isScreenSwitch,
                                    isPullScreen    : chat_info.isPullSwitch,
                                    isPushScreen    : chat_info.isPushSwitch
                                });
                            });
                        } else {
                            chat_info.list_member = members_info.map(member_info => ({
                                ...member_info,
                                isInkON         : chat_info.isInkSwitch,
                                isVoiceON       : chat_info.isVoiceSwitch,
                                isLiveON        : chat_info.isScreenSwitch,
                                isPullScreen    : chat_info.isPullSwitch,
                                isPushScreen    : chat_info.isPushSwitch
                            }));
                        }
                    }
                });
            } else {
                //console.log(`MQ_CHAT_MEMBER_ADD - chatRoomSeq[${chatRoomSeq}], members_info => `, members_info);
                return {
                    ...state
                }
            }
        } else {
            //console.log('MQ_CHAT_MEMBER_ADD - add_info => ', add_info);
            return {
                ...state
            }
        }
    },

    [MQ_CHAT_MEMBER_REMOVE]: (state, action) => {
        const { roomSeq, userSeq } = action.payload;
        //console.log(`MQ_CHAT_MEMBER_REMOVE - roomSeq[${roomSeq}], userSeq[${userSeq}]`);

        if (state.performChatRoomInfo.chatRoomSeq === roomSeq) {
            // call eo...
        }

        return produce (state, draft => {
            if (draft.performChatRoomInfo.chatRoomSeq === roomSeq) {
                if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                    const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info !== undefined && member_info !== null) {
                        let nickname = member_info.userNickname;
                        if (nickname.length > 8) {
                            nickname = nickname.substring(0, 6) + '...';
                        }
                        draft.nextPhaseData = nickname + ' 님이 팀업 멤버에서 제외되었습니다.';

                        draft.performChatRoomInfo.list_member = draft.performChatRoomInfo.list_member.filter(info => info.userSeq !== userSeq);
                    }
                }
            }

            if (draft.selectedChatRoomInfo.chatRoomSeq === roomSeq) {
                if (draft.selectedChatRoomInfo.list_member !== undefined && draft.selectedChatRoomInfo.list_member !== null) {
                    draft.selectedChatRoomInfo.list_member = draft.selectedChatRoomInfo.list_member.filter(info => info.userSeq !== userSeq);
                }
            }

            const searchChat_info = draft.list_searchChatting.find(info => info.chatRoomSeq === roomSeq);
            if (searchChat_info !== undefined && searchChat_info !== null) {
                if (searchChat_info.list_member !== undefined && searchChat_info.list_member !== null) {
                    searchChat_info.list_member = searchChat_info.list_member.filter(info => info.userSeq !== userSeq);
                }
            }

            const chat_info = draft.list_chatting.find(info => info.chatRoomSeq === roomSeq);
            if (chat_info !== undefined && chat_info !== null) {
                if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                    chat_info.list_member = chat_info.list_member.filter(info => info.userSeq !== userSeq);
                }
            }
        });
    }, 

    [MQ_CHAT_MEMBER_REMOVED]: (state, action) => {
        const { roomSeq, userSeq } = action.payload;
        //console.log(`MQ_CHAT_MEMBER_REMOVED - roomSeq[${roomSeq}], userSeq[${userSeq}]`);

        if (state.performChatRoomInfo.chatRoomSeq === roomSeq) {
            // call eo...
        }
        
        return produce (state, draft => {
            if (draft.performChatRoomInfo.chatRoomSeq === roomSeq) {
                draft.isAlertRemovedModalOpened = true;
                draft.removedChatRoomName = state.performChatRoomInfo.chatRoomName;

                draft.performChatRoomInfo = {
                    chatRoomSeq             : -1,
                    chatRoomName            : '',
                    chatRoomType            : '',
                    chatRoomUpdateDT        : '',
                    list_member             : [],
                    isInkSwitch             : false,
                    isVoiceSwitch           : false,
                    isScreenSwitch          : false,
                    isPullSwitch            : false,
                    isPushSwitch            : false
                };

                draft.isGotoChatList = true;
                draft.isAloneChatting = true;
                draft.isChattingMenuOpened = false;
                draft.isLiveListModalOpened = false;
                draft.isInviteMemberModalOpened = false;

                draft.myTeamUpSendKey = null;
                draft.myTeamUpBindKeyArr = [];
            }

            if (draft.selectedChatRoomInfo.chatRoomSeq === roomSeq) {
                draft.selectedChatRoomInfo = {
                    chatRoomSeq             : -1,
                    chatRoomName            : '',
                    chatRoomCreator         : -1,
                    list_member             : [],
                };
                
                draft.isEditChatRoomMemberModalOpened = false;
            }

            if (draft.participateChatRoomInfo.chatRoomSeq === roomSeq) {
                draft.participateChatRoomInfo = {
                    chatRoomSeq             : -1,
                    chatRoomName            : ''
                };

                draft.isParticipateChatRoomModalOpened = false;
            }

            draft.list_searchChatting = draft.list_searchChatting.filter(info => info.chatRoomSeq !== roomSeq);
            draft.list_chatting = draft.list_chatting.filter(info => info.chatRoomSeq !== roomSeq);
        });
    },

    [MQ_UPDATE_CHAT_MEMBER_INFO]: (state, action) => {
        const { update_info } = action.payload;

        if (update_info !== undefined && update_info !== null) {
            const { userSeq, userNickname, userProfile, profileImgUrl } = update_info;

            if (userNickname !== undefined && userNickname !== null) {
                if (state.performChatRoomInfo.chatRoomSeq > 0) {
                    if (state.performChatRoomInfo.list_member !== undefined && state.performChatRoomInfo.list_member !== null) {
                        const member_info = state.performChatRoomInfo.list_member.find(info => info.userSeq === userSeq);
                        if (member_info !== undefined && member_info !== null) {
                            callEoForUpdateChatRoomMemberName(state.performChatRoomInfo.chatRoomSeq, userSeq, userNickname);
                        }
                    }
                }
            }

            return produce(state, draft => {
                if (draft.performChatRoomInfo.chatRoomSeq > 0) {
                    if (draft.performChatRoomInfo.list_member !== undefined && draft.performChatRoomInfo.list_member !== null) {
                        const member_info = draft.performChatRoomInfo.list_member.find(info => info.userSeq === userSeq);
                        if (member_info !== undefined && member_info !== null) {
                            member_info.userNickname    = userNickname !== null ? userNickname : member_info.userNickname;
                            member_info.userProfile     = userProfile !== null ? userProfile : member_info.userProfile;
                            member_info.profileImgUrl   = profileImgUrl !== null ? profileImgUrl : member_info.profileImgUrl;
                        }
                    }
                }

                if (draft.selectedChatRoomInfo.chatRoomSeq > 0) {
                    if (draft.selectedChatRoomInfo.list_member !== undefined && draft.selectedChatRoomInfo.list_member !== null) {
                        const member_info = draft.selectedChatRoomInfo.list_member.find(info => info.userSeq === userSeq);
                        if (member_info !== undefined && member_info !== null) {
                            member_info.userNickname    = userNickname !== null ? userNickname : member_info.userNickname;
                            member_info.userProfile     = userProfile !== null ? userProfile : member_info.userProfile;
                            member_info.profileImgUrl   = profileImgUrl !== null ? profileImgUrl : member_info.profileImgUrl;
                        }
                    }
                }

                draft.list_searchChatting.forEach(chat_info => {
                    if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                        const member_info = chat_info.list_member.find(info => info.userSeq === userSeq);
                        if (member_info !== undefined && member_info !== null) {
                            member_info.userNickname    = userNickname !== null ? userNickname : member_info.userNickname;
                            member_info.userProfile     = userProfile !== null ? userProfile : member_info.userProfile;
                            member_info.profileImgUrl   = profileImgUrl !== null ? profileImgUrl : member_info.profileImgUrl;
                        }
                    }
                });

                draft.list_chatting.forEach(chat_info => {
                    if (chat_info.list_member !== undefined && chat_info.list_member !== null) {
                        const member_info = chat_info.list_member.find(info => info.userSeq === userSeq);
                        if (member_info !== undefined && member_info !== null) {
                            member_info.userNickname    = userNickname !== null ? userNickname : member_info.userNickname;
                            member_info.userProfile     = userProfile !== null ? userProfile : member_info.userProfile;
                            member_info.profileImgUrl   = profileImgUrl !== null ? profileImgUrl : member_info.profileImgUrl;
                        }
                    }
                });
            });
        } else {
            //console.log('MQ_UPDATE_CHAT_MEMBER_INFO - update_info => ', update_info);
            return {
                ...state
            }
        }
    },

    [MQ_CHAT_MSG]: (state, action) => {
        const { msg_info } = action.payload;
        //console.log('MQ_CHAT_MSG - msg_info => ', msg_info);

        return {
            ...state
        }
    },
}, initialState);

export default chat;