import React from "react";
import { useHistory } from "react-router-dom";
import { FaHome, FaComments, FaDownload, FaLaptop, FaChalkboardTeacher, FaUserFriends, FaInfoCircle, FaCogs, FaGift, FaTicketAlt } from "react-icons/fa";

import { enWebPage } from "../../../lib/ConstCommand";
import ConstData from "../../../lib/ConstData";

const WebSideMenu = ({ currentPageType, userKind, isConfigAdmin, isCreateAdmin, isStandardType, isUsablePass, goToAloneChatting }) => {
    let history = useHistory();

    const callMethod = () => {
        if (isUsablePass) {
            //console.log("is usable pass");
        } else {
            //console.log("is unusable pass");
        }

        goToAloneChatting();
    }

    return (
        <div className="web-side-menu">
            <ul>
                <li className={currentPageType === enWebPage.Home ? "active" : ""} title="메인으로" onClick={() => { history.push("/main") }}>
                    <div><FaHome className="font-lg" aria-hidden="true" /></div>
                    <span className="ml-3 align-middle">MAIN</span>
                </li>
                { // ** 임시 ** 현재 사용 안 하므로 주석 처리 (for GS인증 시험) by hjkim 20230912
                    /* <li className={currentPageType === enWebPage.Friend ? "active" : ""} title="친구 관리" onClick={() => { history.push("/list_friend") }}>
                        <div><FaUserFriends className="font-lg" aria-hidden="true" /></div>
                        <span className="ml-3 align-middle">FRIEND</span>
                    </li> */
                }
                <li className={currentPageType === enWebPage.LiveClassTalk ? "active" : ""} title="나와의 채팅" onClick={() => callMethod()}>
                    <div><FaComments className="font-lg" aria-hidden="true" /></div>
                    <span className="ml-3 align-middle">CHATTING</span>
                </li>
                <li className={currentPageType === enWebPage.Live ? "active" : ""} title="클래스 목록 조회" onClick={() => { history.push("/list_live") }}>
                    <div><FaLaptop className="font-lg" aria-hidden="true" /></div>
                    <span className="ml-3 align-middle">CLASS</span>
                </li>
                { // ** 임시 ** 매뉴얼 작업할 동안 주석 처리 by hjkim 20230503
                    /* isStandardType && (userKind === "hiclasstv" || isConfigAdmin) &&
                    <li className={currentPageType === enWebPage.Studio ? "active" : ""} title="내 스튜디오 목록 조회" onClick={() => { history.push("/list_studio") }}>
                        <div><FaChalkboardTeacher className="font-lg" aria-hidden="true" /></div>
                        <span className="ml-3 align-middle">STUDIO</span>
                    </li> */
                }
                { // ** 임시 ** 매뉴얼 작업할 동안 주석 처리 by hjkim 20230503
                    /* !ConstData.IS_LOCAL_VERSION && isStandardType &&
                    <li className={currentPageType === enWebPage.Promotion ? "active" : ""} title="진행 중인 이벤트" onClick={() => { history.push("/promotion") }}>
                        <div><FaGift className="font-lg" aria-hidden="true" /></div>
                        <span className="ml-3 align-middle">PROMOTION</span>
                    </li> */
                }
                {
                    !ConstData.IS_LOCAL_VERSION && isStandardType && (userKind === "hiclasstv" || (isConfigAdmin || isCreateAdmin)) &&
                    <li className={currentPageType === enWebPage.Pass ? "active" : ""} title="이용권 조회" onClick={() => { history.push("/pass") }}>
                        <div><FaTicketAlt className="font-lg" aria-hidden="true" /></div>
                        <span className="ml-3 align-middle">PASS</span>
                    </li>
                }
                <li className={currentPageType === enWebPage.Download ? "active" : ""} title="다운로드" onClick={() => { history.push("/download") }}>
                    <div><FaDownload className="font-lg" aria-hidden="true" /></div>
                    <span className="ml-3 align-middle">DOWNLOAD</span>
                </li>
                <li className={currentPageType === enWebPage.Info ? "active" : ""} title="정보 더 보기" onClick={() => { history.push("/info") }}>
                    <div><FaInfoCircle className="font-lg" aria-hidden="true" /></div>
                    <span className="ml-3 align-middle">INFO</span>
                </li>
                {
                    (isConfigAdmin || isCreateAdmin) &&
                    <li className={currentPageType === enWebPage.Manage ? "active" : ""} title="관리자 페이지" onClick={() => { history.push("/admin") }}>
                        <div><FaCogs className="font-lg" aria-hidden="true" /></div>
                        <span className="ml-3 align-middle">MANAGE</span>
                    </li>
                }
            </ul>
        </div>
    );
}

export default WebSideMenu;