import React, { useState } from 'react';

const ChatTitle = ({ isLocalVersion }) => {
    if (isLocalVersion)  {
        return <span className="white-text"><b>채팅방</b></span>
    } else {
        return <span className="white-text"><b>나와의 채팅</b></span>
    }
}

export default ChatTitle;