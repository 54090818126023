import React from 'react';
import { Redirect } from 'react-router';

import MainContainer from '../containers/MainContainer';
import LiveContainer from '../containers/LiveContainer';

const WebLivePage = ({ browserType, currentPageType, isLoginYn, cookies, isJoinLive, routeProps }) => {
    if (isLoginYn === 'N') {
        return <Redirect to='/main' />;
    }

    return (
        <MainContainer 
            browserType={browserType} 
            cookies={cookies} 
            currentPageType={currentPageType} 
            routeProps={routeProps}
        >
            <LiveContainer
                routeProps={routeProps}
                cookies={cookies} 
                isJoinLive={isJoinLive}
                browserType={browserType}
                currentPageType={currentPageType}
            />
        </MainContainer>
    );
};

export default WebLivePage;