import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaSitemap, FaFileImage, FaAngleLeft } from 'react-icons/fa';

import ConstData from '../../../lib/ConstData';
import Alerts from '../../etc/list/Alerts';

const getLogoImage = (filename) => {
    //return ConstData.LOGO_IMG_DIR + filename + '.png'
    let time = new Date().getTime();
    return `${ConstData.LOGO_IMG_DIR}${filename}.png?${time}`
};

const WebSubDomainDetailConfig = ({ subDomain_info, isChangeLogoOK, alertKind, alertMessage, handleSelectedFunc }) => {
    /**
     * subDomain_info 
     * - subDomainSeq
     * - subDomainName
     */
    /*const subDomain_info = {
        subDomainSeq : 1,
        subDomainName : 'hiclasstv'
    };*/

    const [imageData, setImageData] = useState({ image: null, filename: "" });

    const [lAlertMessage, setAlertMessage] = useState("");
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    useEffect(() => {
        if (isChangeLogoOK) {
            setImageData({ image: null, filename: "" });
            handleSelectedFunc({ selectedFunc: "FALSE_TO_CHANGE_LOGO" });
        }
    }, [isChangeLogoOK, handleSelectedFunc]);

    const handleChangeFile = (e) => {
        if (e.target.files !== undefined && e.target.files !== null) {
            if (e.target.files[0] !== undefined && e.target.files[0] !== null) {
                if (e.target.files[0].size > 204800) {
                    setAlertMessage("200KB 이하 이미지 파일만 업로드 가능합니다.");
                    setAlertKind(ConstData.ALERT_KIND.DANGER);
                } else {
                    if (e.target.files[0].name !== undefined && e.target.files[0].name !== null) {
                        let filename = e.target.files[0].name;
                        previewImage(e.target.files[0], filename);
                        setAlertKind(ConstData.ALERT_KIND.WARNING);
                        setAlertMessage("저장 버튼을 눌러야 수정사항이 적용됩니다.");
                    } else {
                        if (lAlertKind === ConstData.ALERT_KIND.DANGER) {
                            setAlertKind(ConstData.ALERT_KIND.NONE);
                            setAlertMessage("");
                        }
                    }
                }
            } else {
                if (lAlertKind === ConstData.ALERT_KIND.DANGER) {
                    setAlertKind(ConstData.ALERT_KIND.NONE);
                    setAlertMessage("");
                }
            }
        } else {
            if (lAlertKind === ConstData.ALERT_KIND.DANGER) {
                setAlertKind(ConstData.ALERT_KIND.NONE);
                setAlertMessage("");
            }
        }
    }

    const previewImage = (file, filename) => {
        if (file !== undefined && file !== null) {
            var reader = new FileReader();
            reader.onload = () => {
                setImageData({ image:reader.result, filename:filename });
            };
    
            reader.readAsDataURL(file);
        }
    }

    const convertDataURIToBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
    
        var bb = new Blob([ab], { "type" : mimeString });

        return bb;
    }

    const checkChangeLogo = () => {
        if (imageData.image !== undefined && imageData.image !== null &&
            imageData.filename !== undefined && imageData.filename !== null && imageData.filename !== "") {
            var blob = convertDataURIToBlob(imageData.image);
            handleSelectedFunc({ selectedFunc: 'CHANGE_LOGO', subDomainSeq: subDomain_info.subDomainSeq, subDomainName: subDomain_info.subDomainName, data: blob, filename: imageData.filename });
        } else {
            setAlertMessage("로고 이미지를 등록해주십시오");
            setAlertKind(ConstData.ALERT_KIND.DANGER);
        }
    }

    return (
        <div className="web-main">
            <div className="card p-2">
                <div className="d-flex flex-column w-100 px-2">
                    <div className="d-flex my-2">
                        <button type="button" className="d-flex hiclasstv-btn b-none rounded align-items-center py-1 black-text" title="서브 도메인 목록 보기" onClick={() => handleSelectedFunc({ selectedFunc: "GOTO_SUB_DOMAIN_HOME" })}>
                            <FaAngleLeft className="d-flex m-1" />
                            <span className="small">목록으로</span>
                        </button>
                    </div>
                    <div className="d-flex flex-column">
                        <span className="hiclasstv-blue-text my-2" style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                            <FaSitemap className="font-lg mr-2" aria-hidden="true" />서브 도메인 정보 수정
                        </span>
                    </div>
                </div>
                <div className="d-flex flex-column w-100 px-2">
                    <div className="d-flex align-items-center my-1 p-0">
                        <div className="d-flex px-1 justify-content-between" style={{ width: '20%', minWidth: 'max-content', maxWidth: '20%' }}>
                            <span><b>서브 도메인 명</b></span>
                        </div>
                        <div className="d-flex px-1" style={{ width: '80%', maxWidth: '90%' }}>
                            <span><b>{subDomain_info.subDomainName}</b></span>
                        </div>
                    </div>
                    <div className="d-flex my-1 p-0" style={{ height: 'max-content', minHeight: 'max-content', maxHeight: 'max-content' }}>
                        <div className="d-flex px-1 justify-content-between" style={{ width: '20%', minWidth: 'max-content', maxWidth: '20%' }}>
                            <span><b>서브 도메인 로고</b></span>
                        </div>
                        <div className="d-flex flex-column m-0 p-0" style={{ width: '80%', maxWidth: '90%' }}>
                            <div className="d-flex w-100 mb-1 p-0">
                                <div className="d-flex button px-1" style={{ width: '50%', maxWidth: '50%', height: '100%' }}>
                                    <div className="d-flex flex-column align-items-center justify-content-center w-100 p-2" style={{ border: '1px solid grey', borderRadius: '0.5rem', marginBottom: '0.5rem', height: '100%', minHeight: '5.625rem', maxHeight: '100%' }}>
                                        <img className="hiclasstv-image-preview" src={getLogoImage(subDomain_info.subDomainName)} alt={subDomain_info.subDomainName} />
                                        <span className="hiclass-text font-xsm grey-text my-1 px-2">로고 이미지</span>
                                    </div>
                                </div>
                                <div className="d-flex button px-1" style={{ width: '50%', maxWidth: '50%' }}>
                                    <label className="d-flex flex-column justify-content-center align-items-center w-100 p-2" htmlFor="logo_file" style={{ border: '1px solid grey', borderRadius: '0.5rem', height: '100%', minHeight: '5.625rem', maxHeight: '100%' }}>
                                        {
                                            imageData.image !== null ?
                                                <img className="hiclasstv-image-preview" src={imageData.image} alt="" /> :
                                                <span className="hiclasstv-image-preview">
                                                    <FaFileImage className="font-lg grey-text" aria-hidden="true" />
                                                </span>
                                        }
                                        <span className="hiclass-text font-xsm grey-text my-1 px-2">
                                            {imageData.image !== null ? imageData.filename : "파일선택 (.png)"}
                                        </span>
                                    </label>
                                    <input type="file" accept=".png" className="p-0" id="logo_file" style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', overflow: 'hidden', border: '0', clip: 'rect(0,0,0,0)' }} onChange={handleChangeFile} />
                                </div>
                            </div>
                            <Alerts kind={lAlertKind} message={lAlertMessage} />
                            <div className="d-flex justify-content-center align-items-center my-1">
                                <button type="button" className="btn btn-sm btn-primary" disabled={imageData.image === null} onClick={checkChangeLogo}>
                                    저장
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WebSubDomainDetailConfig;