import React from 'react';
import { Modal } from 'reactstrap';

const RemoveAlertModal = ({ kind, info, isAlertModalOpened, handleRemoveItem, toggleModalOpened }) => {
    return(
        <Modal isOpen={isAlertModalOpened} centered backdrop={true} toggle={() => toggleModalOpened(false)}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="d-flex flex-column justify-content-center align-items-center m-0 p-0">
                        <div className="d-flex justify-content-center dark-grey-text w-100">
                            <div className="d-flex justify-content-center align-items-center">
                                <span>
                                    {
                                        kind === "studio" ?
                                            <b>해당 스튜디오를 삭제하시겠습니까?</b> :
                                        kind === "studio-display" ?
                                            <b>해당 디스플레이를 등록 해제하시겠습니까?</b> :
                                        kind === "display" ?
                                            <b>해당 디스플레이를 삭제하시겠습니까?</b> :
                                            <></>
                                    }
                                </span>
                            </div>                            
                        </div>
                        <div className="d-flex flex-column align-items-center my-3 p-2 hiclasstv-grey" style={{ borderRadius: '1rem', width: '90%' }}>
                            {
                                kind === "studio" ?
                                    <div className="d-flex flex-column align-items-center w-100 p-2">
                                        <div className="d-flex align-items-center" style={{width:'90%'}}>
                                            <span className="dark-grey-text" style={{width:'40%'}}>[스튜디오 이름]</span>
                                            <div className="d-flex align-items-center px-2" style={{width:'60%'}}>
                                                <span className="hiclass-text dark-grey-text" title={info.studioName}>
                                                    <b>{info.studioName}</b>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center" style={{width:'90%'}}>
                                            <span className="dark-grey-text" style={{width:'40%'}}>[생성일]</span>
                                            <div className="d-flex align-items-center px-2" style={{width:'60%'}}>
                                                <span className="hiclass-text dark-grey-text" title={info.date}>
                                                    {info.date}
                                                </span>
                                            </div>
                                        </div>
                                    </div> :
                                kind === "studio-display" ?
                                    <div className="d-flex flex-column align-items-center w-100 p-2">
                                        <div className="d-flex align-items-center" style={{width:'90%'}}>
                                            <span className="dark-grey-text" style={{width:'40%'}}>[연결된 스튜디오]</span>
                                            <div className="d-flex align-items-center px-2" style={{width:'60%'}}>
                                                <span className="hiclass-text dark-grey-text" title={info.studioName}>
                                                    <b>{info.studioName}</b>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center" style={{width:'90%'}}>
                                            <span className="dark-grey-text" style={{width:'40%'}}>[생성일]</span>
                                            <div className="d-flex align-items-center px-2" style={{width:'60%'}}>
                                                <span className="hiclass-text dark-grey-text" title={info.date}>
                                                    {info.date}
                                                </span>
                                            </div>
                                        </div>
                                    </div> :
                                kind === "display" ?
                                    <div className="d-flex flex-column align-items-center w-100 p-2">
                                        <div className="d-flex align-items-center" style={{width:'90%'}}>
                                            <span className="dark-grey-text" style={{width:'40%'}}>[생성일]</span>
                                            <div className="d-flex align-items-center px-2" style={{width:'60%'}}>
                                                <span className="hiclass-text dark-grey-text" title={info.date}>
                                                    {info.date}
                                                </span>
                                            </div>
                                        </div>
                                    </div> :
                                    <></>
                            }
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="button" className="btn btn-sm green white-text m-0 mr-1 py-1 px-2" onClick={() => handleRemoveItem({kind, info})}>확인</button>
                            <button type="button" className="btn btn-sm red white-text m-0 mr-1 py-1 px-2" onClick={() => toggleModalOpened(false)}>취소</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default RemoveAlertModal;