import React from 'react';
import { useHistory } from 'react-router-dom';

import WebLectureInfo from './WebLectureInfo';

const WebLectureList = ({ rowKey, isUsablePass, list_lecture, handleSelectedFunc }) => {
    let history = useHistory();

    const callMethod = ({ lectureCode, lectureKind }) => {        
        if (isUsablePass) {
            //console.log("is usable pass");
        } else {
            //console.log("is unusable pass");
        }

        history.push('/live');
        handleSelectedFunc({ selectedFunc: "SELECT_LECTURE", lectureCode, lectureKind });
    }

    return (
        list_lecture !== undefined && list_lecture !== null && list_lecture.length > 0 ? 
        <table className="d-flex flex-column table table-sm table-hover mb-0" style={{ tableLayout: 'fixed' }}>
            <tbody>
                {
                    list_lecture.map((lecture, idx) => (
                        <WebLectureInfo key={rowKey + idx} lecture_info={lecture} callMethod={callMethod} />
                    ))
                }
            </tbody>
        </table> :
        <div className="view m-3 text-center grey-text">
            수강 가능한 강의 목록이 없습니다.
        </div>
    );
}

export default WebLectureList;