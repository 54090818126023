import React, { useState, useEffect, useRef } from 'react';

import { stAttachmentType, stQuizDataType } from '../../../../lib/ConstCommand';

const ImageItem = ({ image }) => {
    const imgRef = useRef(null);
    const isMount = useRef(false);

    useEffect(() => {
        isMount.current = true;
        return () => {
            isMount.current = false;
            if (imgRef && imgRef.current) {
                if (imgRef.current.src && imgRef.current.src !== '') {
                    URL.revokeObjectURL(imgRef.current.src);
                    imgRef.current.src = '';
                }

                imgRef.current = null;
            }
        }
    }, []);

    useEffect(() => {
        if (image !== undefined && image !== null) {
            if (image.data !== undefined && image.data !== null) {
                if (image.type === stAttachmentType.URL || image.type === stQuizDataType.URL) {
                    (async function fetchData() {
                        try {
                            const res = await fetch(image.data);
                            const blob = await res.blob();
                            if (isMount.current) {
                                let objURL = URL.createObjectURL(blob);

                                if (imgRef && imgRef.current) {
                                    imgRef.current.src = objURL;
                                    // imgRef.current.onload = function () {
                                    //     //cleanup.
                                    //     URL.revokeObjectURL(objURL);
                                    // }
                                }
                            }
                        } catch (err) {
                            console.log("fetchData(ImageItem) - err => ", err);
                        }
                    })();
                } else {
                    let byteString = atob(image.data.split(',')[1]);
                    let mimeString = image.data.split(',')[0].split(':')[1].split(';')[0];
                    let ab = new ArrayBuffer(byteString.length);
                    let ia = new Uint8Array(ab);

                    for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                    }

                    let blob = new Blob([ab], { type: mimeString });
                    if (isMount.current) {
                        let objURL = URL.createObjectURL(blob);

                        if (imgRef && imgRef.current) {
                            imgRef.current.src = objURL;
                            // imgRef.current.onload = function () {
                            //     //cleanup.
                            //     URL.revokeObjectURL(objURL);
                            // }
                        }
                    }
                }
            }
        }
    }, [image]);

    return (
        <img className="image-item" ref={imgRef} type="item" alt="" />
    );
}

export default ImageItem;