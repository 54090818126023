import React from 'react';
import MainTemplate from '../components/template/MainTemplate';
import MainContainer from '../containers/MainContainer';
import ChattingContainer from '../containers/ChattingContainer';
import ContentTemplate from '../components/template/ContentTemplate';

const ChattingPage = ({ cookies, browserType, selectMainTap, routeProps }) => {
    return (
        <MainTemplate>
            <MainContainer 
                browserType={browserType} 
                cookies={cookies} 
                routeProps={routeProps}
            >
                <ContentTemplate selectMainTap={selectMainTap}>
                    <ChattingContainer routeProps={routeProps} browserType={browserType} />
                </ContentTemplate>
            </MainContainer>
        </MainTemplate>
    );
};

export default ChattingPage;