import React from 'react';
import { Modal } from 'reactstrap';
import { FaTimes, FaSearch } from 'react-icons/fa';

import ConstData from '../../../lib/ConstData';

const FindIdPasswordWindow = ({ isFindIdPasswordOpened, findIdPasswordOption, findIdPasswordStep, handleSelectedFunc, children }) => {
    return (
        <Modal isOpen={isFindIdPasswordOpened} backdrop={false}>
            <div className="card p-2 align-items-center justify-content-center">
                <div className="title-area justify-content-end text-right mb-3 p-0">
                    <button type="button" className="hiclasstv-btn p-0" title="닫기" onClick={() => handleSelectedFunc({selectedFunc:"CLOSE", mode:'web'})}>
                        <FaTimes className="d-flex m-1 black-text" />
                    </button>
                </div>
                <div className="d-flex align-items-center justify-content-center w-100">
                    <div className="d-flex align-items-center font-lg font-weight-bold blue-text">
                        <FaSearch className="d-flex mr-3" />
                        {
                            findIdPasswordOption === ConstData.FIND_OPTION.START ? 
                                "ID / 비밀번호 찾기" : 
                            findIdPasswordOption === ConstData.FIND_OPTION.ID ?
                                "ID 찾기" :
                            findIdPasswordOption === ConstData.FIND_OPTION.PW ?  
                                <>
                                    {
                                        findIdPasswordStep === ConstData.FIND_STEP.RESET_PW ?
                                            "비밀번호 재설정" :
                                            "비밀번호 찾기"
                                    }
                                </> :
                            <></>
                        }
                    </div>
                </div>
                {children}
            </div>
        </Modal>
    );
}

export default FindIdPasswordWindow;