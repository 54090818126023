import React from 'react';
import { BsX } from 'react-icons/bs';

const RemoveLive = ({ selectedLiveInfo, handleSelectedConfigFunc }) => {

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" onClick={() => handleSelectedConfigFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            {
                selectedLiveInfo.list_member.length > 0 || selectedLiveInfo.list_waitMember.length > 0 ?
                    <>
                        <div className="d-flex text-center my-2 dark-grey-text small">
                            해당 클래스에는 멤버가 존재합니다.<br />
                            클래스를 삭제하시겠습니까?
                        </div>
                        <div className="content-area dark-grey-text my-0">
                            <span className="small mb-1">[클래스 이름]</span>
                            <div className="text-area text-center area-80 mb-3">
                                <span className="hiclass-text" title={selectedLiveInfo.liveName}>
                                    <b>{selectedLiveInfo.liveName}</b>
                                </span>
                            </div>
                            {
                                selectedLiveInfo.list_member.length > 0 &&
                                <>
                                    <span className="small mb-1">[클래스 멤버]</span>
                                    {
                                        selectedLiveInfo.list_member.length > 1 ?
                                            <div className="text-area text-center area-80">
                                                <span className="hiclass-text" title={selectedLiveInfo.list_member.map(mbr => mbr.userNickname).join(" | ")}><b>{selectedLiveInfo.list_member[0].userNickname}</b></span>
                                                <span className="hiclass-text ml-1">외 {selectedLiveInfo.list_member.length - 1}명</span>
                                            </div> :
                                            <div className="text-area text-center area-80">
                                                <span className="hiclass-text" title={selectedLiveInfo.list_member.map(mbr => mbr.userNickname)}>
                                                    <b>{selectedLiveInfo.list_member.map(mbr => mbr.userNickname)}</b>
                                                </span>
                                            </div>
                                    }
                                </>
                            } {
                                selectedLiveInfo.list_waitMember.length > 0 &&
                                <>
                                    <span className="small mt-3 mb-1">[클래스 대기 멤버]</span>
                                    {
                                        selectedLiveInfo.list_waitMember.length > 1 ?
                                            <div className="text-area text-center area-80">
                                                <span className="hiclass-text" title={selectedLiveInfo.list_waitMember.map(mbr => mbr.userNickname).join(" | ")}><b>{selectedLiveInfo.list_waitMember[0].userNickname}</b></span>
                                                <span className="hiclass-text ml-1">외 {selectedLiveInfo.list_waitMember.length - 1}명</span>
                                            </div> :
                                            <div className="text-area text-center area-80">
                                                <span className="hiclass-text" title={selectedLiveInfo.list_waitMember.map(mbr => mbr.userNickname)}>
                                                    <b>{selectedLiveInfo.list_waitMember.map(mbr => mbr.userNickname)}</b>
                                                </span>
                                            </div>
                                    }
                                </>
                            }
                        </div>
                        <div className="d-flex justify-content-center my-2">
                            <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" onClick={() => handleSelectedConfigFunc({ selectedFunc: "REMOVE", liveSeq: selectedLiveInfo.liveSeq })}>예</button>
                            <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => handleSelectedConfigFunc({ selectedFunc: "NONE" })}>아니오</button>
                        </div>
                    </> :
                    <>
                        <div className="d-flex text-center my-2 dark-grey-text small">
                            해당 클래스를 삭제하시겠습니까?
                        </div>
                        <div className="content-area dark-grey-text my-0">
                            <span className="small mb-1">[클래스 이름]</span>
                            <div className="text-area text-center area-80">
                                <span className="hiclass-text" title={selectedLiveInfo.liveName}>
                                    <b>{selectedLiveInfo.liveName}</b>
                                </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center my-2">
                            <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" onClick={() => handleSelectedConfigFunc({ selectedFunc: "REMOVE", liveSeq: selectedLiveInfo.liveSeq })}>예</button>
                            <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => handleSelectedConfigFunc({ selectedFunc: "NONE" })}>아니오</button>
                        </div>
                    </>
            }
        </div>
    );
}

export default RemoveLive;