import React, { useState, useEffect } from 'react';
import { BsX } from 'react-icons/bs';
import { FaSearch } from 'react-icons/fa';

import CustomProfileImage from '../../user/profile/CustomProfileImage';
import ConstData from '../../../lib/ConstData';
import Alerts from '../../etc/list/Alerts';

const AddFriendForSearchKeyword = ({ searchResult, handleSelectedFunc }) => {

    const [lSearchValue, setSearchValue] = useState("");
    const [lUserSelectArr, setUserSelectArr] = useState("");
    const [lAlertMessage, setAlertMessage] = useState("");
    const [lAlertKind, setAlertKind] = useState("");

    useEffect(() => {
        let userArr = searchResult.map(list => ({userSeq: list.userSeq, isSelect: false}));
        setUserSelectArr(userArr);
    }, [searchResult]);

    useEffect(() => {
        setAlertMessage("");
        setAlertKind(ConstData.ALERT_KIND.NONE);
    }, [lSearchValue]);

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            checkSearchValue();
        }
    }

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const onChangeSearchValue = (e) => {
        setSearchValue(e.target.value);
    }

    const onChangeSelectValue = (e) => {
        const {id, checked } = e.target;
        
        if (lUserSelectArr && lUserSelectArr.length > 0) {
            const arr = lUserSelectArr.map(
                (info) => {
                    let value = info.userSeq == id ? { userSeq : info.userSeq, isSelect : checked } : info;
                    return value;
                }
            );
            setUserSelectArr(arr);
        } else {

        }
    }

    const onClickApply = (selectedFunc) => {
        let arr = [];

        if(lUserSelectArr !== undefined && lUserSelectArr !== null && lUserSelectArr.length > 0) {
            lUserSelectArr.forEach(element => {
                if(element.isSelect){
                    arr.push(element.userSeq);
                }
            });
            handleSelectedFunc({selectedFunc:selectedFunc, friendUserSEQList:arr, mode:"hiclass"});
        }
    }

    const checkSearchValue = () => {
        if (lSearchValue === "" || lSearchValue === undefined || lSearchValue === null) {
            setAlertMessage("검색어를 입력하십시오");
            setAlertKind(ConstData.ALERT_KIND.DANGER);
        } else {
            handleSelectedFunc({ selectedFunc: "KEYWORD", searchValue: lSearchValue, mode: "hiclass" });
        }
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area">
                <span>
                    <b>키워드로 친구 추가</b>
                </span>
                <span className="func-btn" style={{position:'absolute', right:'0'}} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="input-area py-2">
                <input type="text" className="search" placeholder="검색" onChange={onChangeSearchValue} onKeyPress={handleKeyPress} />
                <div className="input-group-append ml-1" title="검색" onClick={checkSearchValue}>
                    <span className="d-flex hiclasstv-btn b-none rounded p-1">
                        <FaSearch className="d-flex font-md black-text" />
                    </span>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center px-1">
                <Alerts kind={lAlertKind} message={lAlertMessage} />
                {/* { 위에거에 스타일 적용 추가해야할지 확인해보기 mt-0 mb-2 */}
            </div>
            {
                searchResult !== undefined && searchResult !== null && searchResult.length > 0 ?
                    <div className="list-group" style={{overflow:'auto'}}>
                        {
                            searchResult.map(
                                friend => (
                                    <div className="d-flex list-group-hitem list-group-hitem-action justify-content-center align-items-center p-2 white" key={friend.userSeq}>
                                        <div className="d-flex area-15 fit-content">
                                            <CustomProfileImage url={friend.profileImgUrl} alt={friend.userNickname} />
                                        </div>
                                        <div className="text-area flex-column align-items-start">
                                            <span className="hiclass-text" title={friend.userNickname}><b>{friend.userNickname}</b></span>
                                            <span className="hiclass-text hiclasstv-deepDarkgrey-text small" title={friend.userProfile}>{friend.userProfile}</span>
                                        </div>
                                        <div className="d-flex area-15 ml-1">
                                            <div className="custom-control custom-checkbox pr-0 pl-2" style={{display:'inherit'}}>
                                                {
                                                    friend.isCanApply === 'Y' ?
                                                        <input type="checkbox" className="custom-control-input" style={{position:'inherit'}} id={friend.userSeq} onChange={onChangeSelectValue} /> :
                                                        <input type="checkbox" className="custom-control-input" style={{position:'inherit'}} id={friend.userSeq} checked={false} disabled />
                                                }
                                                <label className="custom-control-label" htmlFor={friend.userSeq} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        }
                        <div className="d-flex justify-content-center my-1">
                            <button type="button" className="btn btn-primary white-text m-0 my-1 py-1 px-2" style={{ borderRadius: "0.5rem" }} onClick={() => onClickApply("APPLY")}>신청</button>
                        </div>
                    </div> :
                    <div className="text-center">
                        <span>검색 결과가 없습니다.</span>
                    </div>
            }
        </div>
    );
}
export default AddFriendForSearchKeyword;