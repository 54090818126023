export const enableAutoTTS = () => {
    if (typeof window === 'undefined') {
        return;
    }

    let userAgent = navigator.userAgent.toLowerCase();
    let isiOS = /ipad|iphone|ipod/.test(userAgent);
    isiOS = /macintosh/.test(userAgent) && navigator.maxTouchPoints > 2 ? true : isiOS;
    if (!isiOS) {
        return;
    }

    const simulateSpeech = () => {
        let word = new SpeechSynthesisUtterance('hello');
        word.volume = 0;
        speechSynthesis.speak(word);
        document.removeEventListener('click', simulateSpeech);
    }

    document.addEventListener('click', simulateSpeech);
}
enableAutoTTS();