import React, { useEffect, useState } from "react";

import { FaExpandArrowsAlt, FaCompressArrowsAlt, FaComment, FaCamera, FaDesktop, FaPortrait } from "react-icons/fa";

// import connectAble from "../../image/liveTool/connectAble.png";
// import disconnectImg from "../../image/liveTool/disconnect.png";
// import disableConnectImg from "../../image/liveTool/disconnectDisable.png";
// import micOnImg from "../../image/liveTool/micOn.png";
// import micOffImg from "../../image/liveTool/micOffDisable.png";
// import disableMicOnImg from "../../image/liveTool/micOnDisable.png";
// import showTeacherImg from "../../image/liveTool/showTeacherScreen.png";
// import hideTeacherImg from "../../image/liveTool/hideTeacherScreen.png";

const LiveControlTool = ({ userNickname, performLiveInfo, performLiveStatusInfo, performHandsUpStatusInfo, classTalkVisibility, teacherViewVisibility, myCamViewVisibility, isFullScreenMode, viewKind, handleSelectedLiveFunc }) => {

    const [teacherInfo, setTeacherInfo] = useState(performLiveInfo.teacher_info ? performLiveInfo.teacher_info : { userSeq: -1, userNickname: "" });
    // const [liveSeq, setLiveSeq] = useState(performLiveInfo.liveSeq ? performLiveInfo.liveSeq : -1);
    // const [handsUpYN, setHandsUpYN] = useState(performLiveInfo.handsUpYN ? performLiveInfo.handsUpYN : "N");

    // const [isConnect, setIsConnect] = useState(performLiveStatusInfo.isConnect !== undefined && performLiveStatusInfo.isConnect !== null ? performLiveStatusInfo.isConnect : false);
    // const [myHandsUpYN, setMyHandsUpYN] = useState(performHandsUpStatusInfo.myHandsUpYN ? performHandsUpStatusInfo.myHandsUpYN : "N");
    // const [connectStatus, setConnectStatus] = useState(performHandsUpStatusInfo.connectStatus ? performHandsUpStatusInfo.connectStatus : "disable");
    // const [micStatus, setMicStatus] = useState(performHandsUpStatusInfo.micStatus ? performHandsUpStatusInfo.micStatus : "disable");

    useEffect(() => {
        if (performLiveInfo.teacher_info && performLiveInfo.teacher_info.userSeq > 0) {
            setTeacherInfo({
                userSeq: performLiveInfo.teacher_info.userSeq,
                userNickname: performLiveInfo.teacher_info.userNickname
            });
        }
    }, [performLiveInfo.teacher_info]);

    const toggleFullScreenMode = () => {
        if (!teacherViewVisibility) return;

        if (performLiveStatusInfo.isConnect) {
            handleSelectedLiveFunc({ selectedFunc: "CHANGE_VIEW_SIZE" });
        }
    }

    return (
        <div className="live-toolbar-area bottom small">
            <div className="toolbar-landscape">
                <div className="d-flex align-items-center"
                    data-toggle="tooltip"
                    title={classTalkVisibility === "hidden" ? "클래스톡 열기" : "클래스톡 닫기"}
                >
                    <button type="button" className="d-flex hiclasstv-button p-2" style={{ minWidth: '44px' }}
                        /* title={classTalkVisibility === "hidden" ? "클래스톡 열기" : "클래스톡 닫기"} */
                        onClick={() => handleSelectedLiveFunc({ selectedFunc: "TOGGLE_CLASSTALK" })}>
                        {
                            /* classTalkVisibility === "hidden" ?
                                <FaComment className="font-ml" style={{ color: 'rgb(255, 0, 0)' }} aria-hidden="true" alt="클래스톡 열기" /> :
                                <FaComment className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} aria-hidden="true" alt="클래스톡 닫기" /> */
                        }
                        <FaComment
                            className="font-ml"
                            style={{ color: classTalkVisibility === "hidden" ? 'rgb(255, 0, 0)' : 'rgb(55, 230, 71)' }}
                            alt={classTalkVisibility === "hidden" ? "클래스톡 열기" : "클래스톡 닫기"}
                            aria-hidden="true"
                        />
                    </button>
                </div>
                <div className="d-flex align-items-center"
                    data-toggle="tooltip"
                    title={teacherViewVisibility === "hidden" ? "화면 보기" : "화면 닫기"}
                >
                    <button type="button" className="d-flex hiclasstv-button p-2"
                        /* title={teacherViewVisibility === "hidden" ? "화면 보기" : "화면 닫기"} */
                        onClick={() => handleSelectedLiveFunc({ selectedFunc: "TOGGLE_TEACHER_VIEW" })}>
                        {
                            /* teacherViewVisibility === "hidden" ?
                                <img src="/images/liveTool/hideTeacherScreen.png" aria-hidden="true" className="hiclasstv-live-icon" title="화면 보기" alt="화면 보기" /> :
                                <img src="/images/liveTool/showTeacherScreen.png" aria-hidden="true" className="hiclasstv-live-icon" title="화면 닫기" alt="화면 닫기" /> */
                        }
                        <img
                            className="hiclasstv-live-icon"
                            src={teacherViewVisibility === "hidden" ? "/images/liveTool/hideTeacherScreen.png" : "/images/liveTool/showTeacherScreen.png"}
                            /* title={teacherViewVisibility === "hidden" ? "화면 보기" : "화면 닫기"} */
                            alt={teacherViewVisibility === "hidden" ? "화면 보기" : "화면 닫기"}
                            aria-hidden="true"
                        />
                    </button>
                </div>
                <div className="d-flex align-items-center"
                    data-toggle="tooltip"
                    title={isFullScreenMode ? "전체화면 종료" : "전체화면"}
                >
                    <button type="button" className="d-flex hiclasstv-button p-2" style={{ minWidth: '44px' }}
                        /* data-toggle="tooltip" */
                        /* title={isFullScreenMode ? "전체화면 종료" : "전체화면"} */
                        disabled={!performLiveStatusInfo.isConnect || teacherViewVisibility === "hidden"}
                        onClick={() => toggleFullScreenMode()}>
                        {
                            /* (!performLiveStatusInfo.isConnect || teacherViewVisibility === "hidden") ?
                                <FaExpandArrowsAlt className="font-ml" style={{ color: 'rgb(158, 158, 158)' }} aria-hidden="true" title="전체화면" /> :
                                isFullScreenMode ?
                                    <FaCompressArrowsAlt className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} aria-hidden="true" title="전체화면 종료" /> :
                                    <FaExpandArrowsAlt className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} aria-hidden="true" title="전체화면" /> */
                            performLiveStatusInfo.isConnect && isFullScreenMode && teacherViewVisibility !== "hidden" ?
                                <FaCompressArrowsAlt className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} alt="전체화면 종료" aria-hidden="true" /> :
                                <FaExpandArrowsAlt className="font-ml" style={{ color: (!performLiveStatusInfo.isConnect || teacherViewVisibility === "hidden") ? 'rgb(158, 158, 158)' : 'rgb(55, 230, 71)' }} alt="전체화면" aria-hidden="true" />
                        }
                    </button>
                </div>
                <div className="d-flex align-items-center"
                    data-toggle="tooltip"
                    title={performHandsUpStatusInfo.micStatus === "disable" || performHandsUpStatusInfo.micStatus === "off" ? "음성 켜기" : "음성 끄기"}
                >
                    <button type="button" className="d-flex hiclasstv-button p-2" disabled={performHandsUpStatusInfo.micStatus === "disable" || performHandsUpStatusInfo.micStatus === "muteDisable"} onClick={() => handleSelectedLiveFunc({ selectedFunc: "PROC_MIC" })}>
                        {
                            /* performHandsUpStatusInfo.micStatus === "disable" || performHandsUpStatusInfo.micStatus === "off" ?
                                <img src="/images/liveTool/micOffDisable.png" aria-hidden="true" className="hiclasstv-live-icon" title="음성 켜기" alt="음성 켜기" /> :
                                // performHandsUpStatusInfo.micStatus === "off" ?
                                //     <img src="/images/liveTool/micOffDisable.png" aria-hidden="true" className="hiclasstv-live-icon" title="음성 켜기" alt="음성 켜기" /> :
                                performHandsUpStatusInfo.micStatus === "mute" ?
                                    <img src="/images/liveTool/micOn.png" aria-hidden="true" className="hiclasstv-live-icon" title="음성 끄기" alt="음성 끄기" /> :
                                    <img src="/images/liveTool/micOnDisable.png" aria-hidden="true" className="hiclasstv-live-icon" title="음성 끄기" alt="음성 끄기" /> */
                        }
                        <img
                            className="hiclasstv-live-icon"
                            src={performHandsUpStatusInfo.micStatus === "disable" || performHandsUpStatusInfo.micStatus === "off" ? "/images/liveTool/micOffDisable.png" : performHandsUpStatusInfo.micStatus === "mute" ? "/images/liveTool/micOn.png" : "/images/liveTool/micOnDisable.png"}
                            /* title={performHandsUpStatusInfo.micStatus === "disable" || performHandsUpStatusInfo.micStatus === "off" ? "음성 켜기" : "음성 끄기"} */
                            alt={performHandsUpStatusInfo.micStatus === "disable" || performHandsUpStatusInfo.micStatus === "off" ? "음성 켜기" : "음성 끄기"}
                            aria-hidden="true"
                        />
                    </button>
                </div>
                <div className="d-flex align-items-center"
                    data-toggle="tooltip"
                    title={(performHandsUpStatusInfo.connectStatus === "disable" || performHandsUpStatusInfo.connectStatus === "disconnect") ? "연결하기" : "연결중"}
                >
                    <button type="button" className="d-flex hiclasstv-button p-2" disabled={(performLiveInfo.handsUpYN !== "Y" && performHandsUpStatusInfo.connectStatus === "disable")} onClick={() => handleSelectedLiveFunc({ selectedFunc: "PROC_CONN" })}>
                        {
                            /* performHandsUpStatusInfo.connectStatus === "disable" ?
                                <img src="/images/liveTool/disconnectDisable.png" aria-hidden="true" className="hiclasstv-live-icon" title="연결하기" alt="연결하기" /> :
                                performHandsUpStatusInfo.connectStatus === "disconnect" ?
                                    <img src="/images/liveTool/disconnect.png" aria-hidden="true" className="hiclasstv-live-icon" title="연결하기" alt="연결하기" /> :
                                    <img src="/images/liveTool/connectAble.png" aria-hidden="true" className="hiclasstv-live-icon" title="연결중" alt="연결중" /> */
                        }
                        <img
                            className="hiclasstv-live-icon"
                            src={performHandsUpStatusInfo.connectStatus === "disable" ? "/images/liveTool/disconnectDisable.png" : performHandsUpStatusInfo.connectStatus === "disconnect" ? "/images/liveTool/disconnect.png" : "/images/liveTool/connectAble.png"}
                            /* title={(performHandsUpStatusInfo.connectStatus === "disable" || performHandsUpStatusInfo.connectStatus === "disconnect") ? "연결하기" : "연결중"} */
                            alt={(performHandsUpStatusInfo.connectStatus === "disable" || performHandsUpStatusInfo.connectStatus === "disconnect") ? "연결하기" : "연결중"}
                            aria-hidden="true"
                        />
                    </button>
                </div>
                <div className="d-flex align-items-center"
                    data-toggle="tooltip"
                    title={(performLiveInfo.liveSeq === -1 || performLiveInfo.handsUpYN === "N") || viewKind === "CAMERA" ? "스크린으로 전환" : "카메라로 전환"}
                >
                    <button type="button" className="d-flex hiclasstv-button p-2" style={{ minWidth: '44px' }} disabled={performLiveInfo.handsUpYN !== "Y"} onClick={() => handleSelectedLiveFunc({ selectedFunc: "CHANGE_VIEW_KIND" })}>
                        {
                            /* (performLiveInfo.liveSeq === -1 || performLiveInfo.handsUpYN === "N") ?
                                <FaCamera className="font-ml" style={{ color: 'rgb(158, 158, 158)' }} aria-hidden="true" title="스크린으로 전환" /> :
                                viewKind === "CAMERA" ?
                                    <FaCamera className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} aria-hidden="true" title="스크린으로 전환" /> :
                                    <FaDesktop className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} aria-hidden="true" title="카메라로 전환" /> */
                            (performLiveInfo.liveSeq === -1 || performLiveInfo.handsUpYN === "N") || viewKind === "CAMERA" ?
                                <FaCamera className="font-ml" style={{ color: (performLiveInfo.liveSeq === -1 || performLiveInfo.handsUpYN === "N") ? 'rgb(158, 158, 158)' : 'rgb(55, 230, 71)' }} alt="스크린으로 전환" aria-hidden="true" /> :
                                <FaDesktop className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} alt="카메라로 전환" aria-hidden="true" />
                        }
                    </button>
                </div>
                {
                    /* (performLiveInfo.handsUpYN === "Y" && lHandsUpVisibility === "visible") &&
                    <div className="d-flex align-items-center"
                        data-toggle="tooltip"
                        title="저요저요"
                    >
                        <button type="button" className="d-flex hiclasstv-button hiclasstv-grey rounded ml-1 p-2" onClick={() => handleSelectedLiveFunc({ selectedFunc: "HANDS_UP" })}>
                            <FaHandPaper className={"font-md" + (performHandsUpStatusInfo.myHandsUpYN === "Y" ? " hiclasstv-yellow-text icon-gradation" : " white-text")} alt="저요저요" aria-hidden="true" />
                        </button>
                    </div> */
                }
                {
                    performLiveInfo.liveSeq > 0 && performLiveInfo.handsUpYN === "Y" &&
                    <div className="d-flex align-items-center"
                        data-toggle="tooltip"
                        title={(viewKind === "CAMERA" || myCamViewVisibility === "none") ? "마이 캠 켜기" : "마이 캠 끄기"}
                    >
                        <button id="btnChangeViewKind" type="button" className="d-flex hiclasstv-button p-2" style={{ minWidth: '44px' }} disabled={viewKind !== "SCREEN"} onClick={() => handleSelectedLiveFunc({ selectedFunc: "TOGGLE_MYCAM_VIEW" })}>
                            {
                                /* viewKind !== "SCREEN" ?
                                    <FaPortrait className="font-ml" style={{ color: 'rgb(158, 158, 158)' }} aria-hidden="true" title="마이 캠 켜기" /> :
                                    myCamViewVisibility === "none" ?
                                        <FaPortrait className="font-ml" style={{ color: 'rgb(255, 0, 0)' }} aria-hidden="true" title="마이 캠 켜기" /> :
                                        <FaPortrait className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} aria-hidden="true" title="마이 캠 끄기" /> */
                            }
                            <FaPortrait
                                className="font-ml"
                                style={{ color: viewKind === "CAMERA" ? 'rgb(158, 158, 158)' : myCamViewVisibility === "none" ? 'rgb(255, 0, 0)' : 'rgb(55, 230, 71)' }}
                                /* title={(viewKind === "CAMERA" || myCamViewVisibility === "none") ? "마이 캠 켜기" : "마이 캠 끄기"} */
                                alt={(viewKind === "CAMERA" || myCamViewVisibility === "none") ? "마이 캠 켜기" : "마이 캠 끄기"}
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                }
                <div className="d-flex align-items-center w-100">
                    {
                        performLiveInfo.liveSeq !== -1 &&
                        <div className="d-flex align-items-center" style={{ minWidth: '1%', width: '100%', maxWidth: 'max-content' }}>
                            <span className="web-list-text w-100 px-1" title={"[진행자] " + teacherInfo.userNickname}><b>{teacherInfo.userNickname}&nbsp;&nbsp;</b></span>|
                        </div>
                    }
                    <div className="d-flex" style={{ minWidth: '10%', width: '100%', maxWidth: 'max-content', visibility: 'visible' }}>
                        <span className="web-list-text w-100 px-1" title={"[나] " + userNickname}>{userNickname}</span>
                    </div>
                </div>
            </div>

            {/* 가로로 화면 전환하라는 안내문구 띄우기 */}
            <div className="toolbar-portrait">
                <div className="d-flex red justify-content-center w-100 px-1">
                    <span className="white-text">※ 채팅 {performLiveStatusInfo.isConnect ? " 및 영상 시청 " : ""}이외의 모든 기능은 가로모드에서 지원합니다.</span>
                </div>
                <div className="d-flex align-items-center">
                    {
                        performLiveInfo.liveSeq !== -1 &&
                        <div className="d-flex align-items-center" style={{ minWidth: '1%', width: '100%', maxWidth: 'max-content' }}>
                            <span className="web-list-text w-100 px-1" title={"[진행자] " + teacherInfo.userNickname}><b>{teacherInfo.userNickname}&nbsp;&nbsp;</b></span>|
                        </div>
                    }
                    <div className="d-flex" style={{ minWidth: '10%', width: '100%', maxWidth: 'max-content', visibility: 'visible' }}>
                        <span className="web-list-text w-100 px-1" title={"[나] " + userNickname}>{userNickname}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LiveControlTool;