import React, { useMemo } from 'react';

import { getEntryMemberCount } from '../../../lib/func/ExportFunction';

const WebUserStatus = ({ kind, list_member, memberCnt }) => {
    
    const entryMemberCount = useMemo(() => getEntryMemberCount(kind, list_member), [kind, list_member]);

    return (
        list_member !== undefined && list_member !== null ? 
            <>
                {
                    entryMemberCount > 0 ? 
                    <span className="green-text font-weight-bold">{entryMemberCount}</span> :
                    <span>{entryMemberCount}</span>
                }
                <span>&nbsp;{' / ' + list_member.length + ' 명'}</span>
            </> :
        memberCnt !== undefined && memberCnt !== null ?
            <span>{memberCnt + ' 명'}</span> :
            <></>
    );
}

export default WebUserStatus;