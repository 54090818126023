import React, { useEffect, useState } from 'react';
import { FaServer, FaPlusCircle } from 'react-icons/fa';
import { UncontrolledCollapse } from 'reactstrap';
import WebLiteServerList from './list/WebLiteServerList';
import WebAddLiteServer from './WebAddLiteServer';

const WebLiteServerConfig = ({ selectedSubDomainInfo, list_configLiteServer, handleSelectedFunc }) => {
    const [isAddLiteServerOK, setIsAddLiteServerOK] = useState(false);

    useEffect(() => {
        //console.log('WebLiteLocalServerConfig visible');

        handleSelectedFunc({ selectedFunc: "GET_LITE_SERVER_LIST" });
        
        return () => {
            //console.log('WebLiteLocalServerConfig invisible');
        }
    }, []);

    useEffect(() => {
        setIsAddLiteServerOK(true);
    }, [list_configLiteServer]);

    const onChangeIsAddLiteServerOK = () => {
        setIsAddLiteServerOK(!isAddLiteServerOK);
    }

    return (
        <div className="web-main list-area" style={{ maxWidth: '900px' }}>
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaServer className="font-lg mr-2" aria-hidden="true" />Lite 로컬 서버
                        </span>
                        <button type="button" className="d-flex btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" id="addLiteLocalServer">
                            <FaPlusCircle className="font-md mr-1" />Lite 로컬 서버 등록
                        </button>
                    </div>
                </div>
                <UncontrolledCollapse toggler="#addLiteLocalServer" defaultOpen={false}>
                    <WebAddLiteServer 
                        selectedSubDomainInfo={selectedSubDomainInfo}
                        isAddLiteServerOK={isAddLiteServerOK}
                        onChangeIsAddLiteServerOK={onChangeIsAddLiteServerOK}
                        handleSelectedFunc={handleSelectedFunc}
                    />
                </UncontrolledCollapse>
                <WebLiteServerList
                    list_liteServer={list_configLiteServer}
                    handleSelectedFunc={handleSelectedFunc}
                />
            </div>
        </div>
    )
};
export default WebLiteServerConfig;