import React, { useState } from "react";
import { FaLockOpen } from "react-icons/fa";

const WebLiteServerInfo = ({ idx, liteServer_info, handleSelectedFunc }) => {
    const [lMemo, setMemo] = useState(liteServer_info.liteServerMemo === null ? "" : liteServer_info.liteServerMemo);

    const onChangeMemo = (e) => {
        setMemo(e.target.value);
    }

    const onHandleModifyServer = () => {
        handleSelectedFunc({ selectedFunc: "UPDATE_LITE_SERVER", data: { liteServerSeq: liteServer_info.liteServerSeq, authCode: liteServer_info.authCode, memo: lMemo } });
    }

    const onHandleRemoveServer = () => {
        handleSelectedFunc({ selectedFunc: "DELETE_LITE_SERVER", data: liteServer_info });
    }

    const onHandleInitServer = () => {
        handleSelectedFunc({ selectedFunc: "INIT_LITE_SERVER", data: { liteServerSeq: liteServer_info.liteServerSeq, authCode: liteServer_info.authCode, initStatus: "Y" } });
    }

    return (
        <>
            <tr className="d-flex">
                <td className="d-flex align-items-center justify-content-center area-10">
                    <span>{liteServer_info.liteServerSeq}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-20">
                    <span className={"hiclass-text" + (liteServer_info.liteLocalSiteSeq === -1 ? " grey-text" : "")} title={liteServer_info.liteLocalSiteName}>{liteServer_info.liteLocalSiteName}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-15">
                    <span className={"hiclass-text" + (liteServer_info.liteLocalSiteSeq === -1 ? " grey-text" : "")} title={liteServer_info.liteServerName}>{liteServer_info.liteServerName}</span>
                    {
                        //liteServer_info.publicStatus &&
                        idx === 2 &&
                        <FaLockOpen className="font-md green-text ml-2" title="공개가 허용된 수업입니다." />
                    }
                </td>
                <td className="d-flex align-items-center justify-content-center area-15">
                    <div className="text-area">
                        <input
                            type="text"
                            value={lMemo}
                            className="web-search-input transparent m-0 p-0"
                            style={{ color: 'unset !important', borderColor: 'unset' }}
                            placeholder={lMemo && lMemo !== "" ? lMemo : "메모를 입력해 주세요."}
                            onChange={onChangeMemo}
                        />
                    </div>
                </td>
                <td className="d-flex align-items-center justify-content-center area-15">
                    <span className={"hiclass-text" + (liteServer_info.liteLocalSiteSeq === -1 ? " grey-text" : "")} title={liteServer_info.ipAddress}>{liteServer_info.ipAddress}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-15">
                    <span>{liteServer_info.authCode}</span>
                </td>
                <td className="d-flex flex-column align-items-center justify-content-center area-10 p-0">
                    <button type="button" className="btn btn-sm green white-text py-1 px-2 area-60" title="변경사항저장" onClick={() => onHandleModifyServer()}>
                        <b>저장</b>
                    </button>
                    <button type="button" className="btn btn-sm hiclasstv-yellow white-text mt-0 py-1 px-2 area-60" title="재설정" onClick={() => onHandleInitServer()} disabled={liteServer_info.initStatus === "Y" ? true : false}>
                        <b>재설정</b>
                    </button>
                </td>
                {/*<td className="d-flex align-items-center justify-content-center area-10 p-0">
                    <button type="button" className="btn btn-sm red white-text py-1 px-2 " title="삭제" onClick={() => onHandleRemoveServer()}>
                        <b>삭제</b>
                    </button>
                </td>*/}
            </tr>
        </>
    );
}

export default WebLiteServerInfo;