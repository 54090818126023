import React, { useState, useEffect, useRef } from "react";
import { BsX } from "react-icons/bs";
import { FaPen } from "react-icons/fa";

import ConstData from "../../../lib/ConstData";
import Alerts from "../../etc/list/Alerts";

const QuizList = ({ list_quiz, isEditQuizModalOpened, handleSelectedFunc }) => {
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [lAlertMessage, setAlertMessage] = useState("");

    const timerRef = useRef(null);
    const isMount = useRef(false);

    useEffect(() => {
        isMount.current = true;
        return () => {
            isMount.current = false;
            if (timerRef && timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
        }
    }, []);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const onHandleSelectQuiz = (quiz_info) => {
        if (isEditQuizModalOpened) {
            if (lAlertMessage === "") {
                setAlertMessage("퀴즈를 수정 중일 경우 해당 퀴즈의 퀴즈 정보를 확인할 수 없습니다.");
                setAlertKind(ConstData.ALERT_KIND.DANGER);

                timerRef.current = setTimeout(() => {
                    if (isMount.current) { // 혹시 모를 오류 방지를 위해... by hjkim 20230103 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
                        setAlertMessage("");
                        setAlertKind(ConstData.ALERT_KIND.NONE);
                    }
                }, 5000);
            } else {
                console.log("already defined alertMessage");
            }
        } else {
            handleSelectedFunc({ selectedFunc: "SELECT_QUIZ", quizSeq: quiz_info.quizSeq });
        }
    }

    const onHandleEditQuizModal = (e, quiz_info) => {
        //console.log(quiz_info);
        e.stopPropagation();
        handleSelectedFunc({ selectedFunc: "OPEN_EDIT_QUIZ_MODAL", quizSeq: quiz_info.quizSeq });
    }

    return (
        <div style={{ height: '100%', maxHeight: '40%', minHeight: '300px' }} onClick={handlePreventClick}>
            <div className="title-area justify-content-center">
                <span>
                    <b>Quiz 목록</b>
                </span>
                <span className="func-btn" style={{ position: 'absolute', right: 0 }} title="닫기" onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex">
                <button type="button" className="btn btn-sm hiclasstv-blue white-text" onClick={() => handleSelectedFunc({ selectedFunc: "OPEN_CREATE_QUIZ_MODAL" })}>
                    + Quiz 추가
                </button>
            </div>
            {
                lAlertMessage !== "" &&
                <Alerts kind={lAlertKind} message={lAlertMessage} style={{ transition: '2.5s' }} />
            }
            {
                (list_quiz && list_quiz.length > 0) ?
                    list_quiz.map(quiz => (
                        <div className="d-flex list-group-hitem list-group-hitem-action p-2" style={{ minHeight: '2.5rem' }} key={quiz.quizSeq} onClick={() => onHandleSelectQuiz(quiz)}>
                            <div className="text-area justify-content-between">
                                <span className="hiclass-text" title="">Q. {quiz.quizTitle}</span>
                                <button type="button" className="btn btn-sm hiclasstv-btn-transparent my-0 px-1" onClick={(e) => onHandleEditQuizModal(e, quiz)}>
                                    <FaPen className="font-sm hiclasstv-grey-text" />
                                </button>
                            </div>
                        </div>
                    )) :
                    <div className="grey-text" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', height: '60%' }}>
                        퀴즈 목록이 존재하지 않습니다.
                    </div>
            }
        </div>
    );
}

export default QuizList;