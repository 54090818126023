import React from 'react';

const EmoticList = ({handleSelectedClassTalkFunc}) => {

    const getImageList = () => {
        let images = [];

        for( let i = 1; i <= 63; i++){
            images.push(
                <button key={i} className="classtalk-emo-button">
                    <img 
                        className="classtalk-emo-image" 
                        alt="" 
                        // src={require(`../images/et${i.toString().padStart(2, '0')}.png`)}
                        src={`/images/classtalk/emoticon/et${i.toString().padStart(2, '0')}.png`}
                        onClick={() => handleSelectedClassTalkFunc({selectedFunc:"EMOTICON_SELECT", emoticonSeq:i})} 
                    />
                </button>);
        }
        return images;
    }

    return(
        <div className="d-flex overflow-auto" style={{border:'.25rem solid rgb(246, 248, 250)'}}>
            <div className="d-flex justify-content-between flex-wrap w-100 overflow-auto" style={{backgroundColor:'rgb(246, 248, 250)', maxHeight:'max-content'}}>
                {getImageList()}
            </div>
        </div>
    );
}
export default EmoticList;