import React from 'react';
import { FaVolumeUp, FaSyncAlt, FaPaperPlane } from 'react-icons/fa';
// import watsonImg from '../../image/watson/watson.png';

import { ctWatsonStatus } from '../../../lib/ConstCommand';

const WatsonImage = ({ watsonStatus, onClick }) => {
    switch (watsonStatus) {
        case ctWatsonStatus.Listening : 
            return (
                <div style={{ height: '100%', position:'relative' }}>
                    <span className="hiclasstv-tooltip">마이크가 꺼졌습니다.</span>
                    <button type="button" className="hiclasstv-classtalk-button hiclasstv-blue" title="마이크 OFF" onClick={onClick}>
                        <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <FaPaperPlane className="font-xsm hiclasstv-yellow-text icon-gradation" style={{ position: 'absolute', right: '2px', bottom: '2px', zIndex: '5' }} aria-hidden="true" />
                            <img src="/images/watson/watson.png" style={{ width: '80%', height: '80%' }} alt="" />
                        </div>
                    </button>
                </div>
            );
        case ctWatsonStatus.Speaking : 
            return (
                <div style={{ height: '100%', position:'relative' }}>
                    {/* <span className="hiclasstv-tooltip">WATSON이 말하고 있습니다.</span> */}
                    <button type="button" className="hiclasstv-classtalk-button hiclasstv-blue" title="왓슨 말하는 중">
                        <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <FaVolumeUp className="font-xsm hiclasstv-yellow-text icon-gradation" style={{ position: 'absolute', right: '2px', bottom: '2px', zIndex: '5' }} aria-hidden="true" />
                            <img src="/images/watson/watson.png" style={{ width: '80%', height: '80%' }} alt="" />
                        </div>
                    </button>
                </div>
            );
        case ctWatsonStatus.Processing : 
            return (
                <div style={{ height: '100%', position:'relative' }}>
                    <span className="hiclasstv-tooltip">WATSON이 질문을 인식 중입니다.</span>
                    <button type="button" className="hiclasstv-classtalk-button hiclasstv-blue" title="왓슨 처리중">
                        <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <FaSyncAlt className="font-xsm icon-spin hiclasstv-yellow-text icon-spin" style={{ position: 'absolute', right: '2px', bottom: '2px', zIndex: '5' }} aria-hidden="true" />
                            <img src="/images/watson/watson.png" style={{ width: '80%', height: '80%' }} alt="" />
                        </div>
                    </button>
                </div>
            );
        default : 
            return (
                <button type="button" className="hiclasstv-classtalk-button hiclasstv-blue-text" onClick={onClick}>
                    <FaPaperPlane className="font-md plus" aria-hidden="true" />
                </button>
            );
    }
};

export default WatsonImage;