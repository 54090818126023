import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import Alerts from '../../etc/list/Alerts';

import ConstData from '../../../lib/ConstData';

const WebWarningAlertModal = ({ isModalOpened, warningKind, toggleModalOpened }) => {

    const [lWarningKind, setWarningKind] = useState(warningKind);
    const [lAlertMessage, setAlertMessage] = useState("");
    //const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);

    useEffect(() => {
        if (warningKind === "RESET_USER_PW") {
            setAlertMessage("자신의 비밀번호는 회원 정보 수정을 이용해 주세요.");
        } else if (warningKind === "RESET_USER_STATUS") {
            setAlertMessage("자신의 웹 로그인 상태를 초기화 할 수 없습니다.");
        } else if (warningKind === "SUB_DOMAIN_ADMIN") {
            setAlertMessage("자신에게 서브 도메인 관리 권한을 부여/해제 할 수 없습니다.");
        } else if (warningKind === "SUB_DOMAIN_CREATE_ADMIN") {
            setAlertMessage("자신에게 서브 도메인 추가 권한을 부여/해제 할 수 없습니다.");
        } else {
            console.log('WebWarningAlertModal() useEffect warningKind is ', warningKind);
        }
    }, [lWarningKind]);

    const onCloseModal = () => {
        setWarningKind("");
        setAlertMessage("");
        //setAlertKind(ConstData.ALERT_KIND.NONE);
    }

    return (
        <Modal isOpen={isModalOpened} centered backdrop={true} onClosed={onCloseModal}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        ※ 경고 ※
                    </div>
                    <div className="content-area white dark-grey-text m-0">
                        <Alerts kind={ConstData.ALERT_KIND.DANGER} message={lAlertMessage} />
                    </div>
                    <button type="button" className="btn green white-text py-1 px-2" onClick={() => toggleModalOpened(false)}>확인</button>
                </div>
            </div>
        </Modal>
    );
}
export default WebWarningAlertModal;