import React from "react";

const WebPassRefundList = () => {
    return (
        <div className="d-flex justify-content-center my-3">
            <span style={{ fontWeight: 'bold' }}>
                현재 지원하지 않는 기능입니다.
            </span>
        </div>
    );
}

export default WebPassRefundList;