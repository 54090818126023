import React, { useState, useEffect } from 'react';

import { remoconType } from '../../lib/classTalk/CodeEnum';

// import inkstate from '../image/toolbar/white_inkmemo.png';
// import sound from '../image/toolbar/white_soundOn.png';
// import screen from '../image/toolbar/white_screenOn.png'
// import pullScreen from '../image/toolbar/white_pullScreen.png';

const TeamUpMemberPanel = ({ index, userInfo, viewCMD, member, onChangeSelectMember }) => {
    return (
        <div className="d-flex flex-column col align-items-center justify-content-center text-center p-0">

        </div>
    );
}

const RemoconMenu = ({ userInfo, viewCMD, activeLive, performChatRoomInfo, handleSelectedFunc }) => {
    const [lUserSelectTeamupArr, setUserSelectTeamupArr] = useState([]);
    const [lUserSelectHandsupArr, setUserSelectHandsupArr] = useState([]);

    useEffect(() => {
        let user = {
            userSeq     : -1,
            userNickname: "user",
            entryYN     : false,
        };

        for (let i = 0; i < 8; i++) {
            lUserSelectTeamupArr.push({ ...user, userNickname: user.userNickname + (i + 1) });
        }

        /* lUserSelectHandsupArr.push({
            userSeq:userInfo.userSeq,
            userNickname:userInfo.userNickname,
            entryYN:true
        }); */

        for (let i = 0; i < 39; i++) {
            lUserSelectHandsupArr.push({ ...user, userNickname: user.userNickname + (i < 9 ? "0" + (i + 1) : (i + 1)) });
        }
    }, []);

    useEffect(() => {
        if (performChatRoomInfo.list_member !== undefined && performChatRoomInfo.list_member !== null) {
            setUserSelectTeamupArr(current => current.map((value, index) => {
                if (index < performChatRoomInfo.list_member.length) {
                    let member = performChatRoomInfo.list_member[index];
                    return value = {
                        userSeq         : member.userSeq,
                        userNickname    : member.userNickname,
                        entryYN         : member.entryYN,
                        isInkON         : member.isInkON,
                        isVoiceON       : member.isVoiceON,
                        isLiveON        : member.isLiveON,
                        isPullScreen    : member.isPullScreen,
                        isPushScreen    : member.isPushScreen,
                    };
                } else {
                    return value;
                }
            }));

            /* let arr = lUserSelectTeamupArr.map((value, idx) => {
                if (idx < performChatRoomInfo.list_member.length) {
                    let member = performChatRoomInfo.list_member[idx];
                    return value = {
                        userSeq         : member.userSeq,
                        userNickname    : member.userNickname,
                        entryYN         : member.entryYN,
                        isInkON         : member.isInkON,
                        isVoiceON       : member.isVoiceON,
                        isLiveON        : member.isLiveON,
                        isPullScreen    : member.isPullScreen,
                        isPushScreen    : member.isPushScreen,
                    }
                } else {
                    return value;
                }
            });
            setUserSelectTeamupArr(arr); */
        }
    }, [performChatRoomInfo.list_member]);

    useEffect(() => {
        if (activeLive.list_member !== undefined && activeLive.list_member !== null) {
            setUserSelectHandsupArr(current => current.map((value, index) => {
                if (index < activeLive.list_member.length) {
                    let member = activeLive.list_member[index];
                    return value = {                        
                        userSeq         : member.userSeq,
                        userNickname    : member.userNickname,
                        entryYN         : member.userJoinStatus,
                        isInkON         : member.isInkON,
                        isSoundON       : member.isSoundON,
                        isScreenON      : member.isScreenON,
                        isSelect        : member.isSelect,
                        isPullScreen    : member.isPullScreen,
                        isPushScreen    : member.isPushScreen,
                    };
                } else {
                    return value;
                }
            }));

            /* let arr = lUserSelectHandsupArr.map((value, index) => {
                if (index < activeLive.list_member.length) {
                    let member = activeLive.list_member[index];
                    return value = {                        
                        userSeq         : member.userSeq,
                        userNickname    : member.userNickname,
                        entryYN         : member.userJoinStatus,
                        isInkON         : member.isInkON,
                        isSoundON       : member.isSoundON,
                        isScreenON      : member.isScreenON,
                        isSelect        : member.isSelect,
                        isPullScreen    : member.isPullScreen,
                        isPushScreen    : member.isPushScreen,
                    }
                } else {
                    return value;
                }
            });
            setUserSelectHandsupArr(arr); */
        }
    }, [activeLive.list_member]);

    const handelPreventClick = (e) => {
        e.stopPropagation();
    }

    const onStartSelectMemberP2P = () => {
        let selectedArr = [];
        lUserSelectHandsupArr.forEach((info) => {
            if (info.userSeq !== -1) {
                if (info.isSelect) {
                    if (selectedArr.length < 4) {
                        selectedArr.push(info.userSeq);
                    } else {
                        return;
                    }
                }
            }
        });

        if (selectedArr.length > 0) {
            handleSelectedFunc({ selectedFunc: "HANDSUP_LIST", kind: "SELECT", memberSeqList: selectedArr });
        }
    }

    const onChangeTeamupSelectAll = (e) => {
        switch (viewCMD) {
            case remoconType.Ink:
                if (performChatRoomInfo.chatRoomSeq !== -1) {
                    let selectedArr = [];
                    const arr = lUserSelectTeamupArr.map((info) => {
                        if (info.userSeq !== -1) {
                            if (info.entryYN) {
                                selectedArr.push({ userSeq: info.userSeq });
                                let value = {
                                    ...info,
                                    isInkON: e.target.checked,
                                };
                                return value;
                            } else {
                                return info;
                            }
                        } else {
                            return info;
                        }
                    });
                    setUserSelectTeamupArr(arr);
                    handleSelectedFunc({ selectedFunc: "TEAMUP_LIST", kind: "INK", memberSeqList: selectedArr, isSelect: e.target.checked });
                }
                break;

            case remoconType.PullScreen:
                if (performChatRoomInfo.chatRoomSeq !== -1) {
                    let selectedArr = [];
                    const arr = lUserSelectTeamupArr.map((info) => {
                        if (info.userSeq !== -1) {
                            if (info.entryYN) {
                                selectedArr.push({ userSeq: info.userSeq });
                                let value = {
                                    ...info,
                                    isPullScreen: e.target.checked,
                                };
                                return value;
                            } else {
                                return info;
                            }
                        } else {
                            return info;
                        }
                    });
                    setUserSelectTeamupArr(arr);
                    handleSelectedFunc({ selectedFunc: "TEAMUP_LIST", kind: "PULL", memberSeqList: selectedArr, isSelect: e.target.checked });
                }
                break;

            case remoconType.PushScreen:
                if (performChatRoomInfo.chatRoomSeq !== -1) {
                    let selectedArr = [];
                    const arr = lUserSelectTeamupArr.map((info) => {
                        if (info.userSeq !== -1) {
                            if (info.entryYN) {
                                selectedArr.push({ userSeq: info.userSeq });
                                let value = {
                                    ...info,
                                    isPushScreen: e.target.checked,
                                };
                                return value;
                            } else {
                                return info;
                            }
                        } else {
                            return info;
                        }
                    });
                    setUserSelectTeamupArr(arr);
                    handleSelectedFunc({ selectedFunc: "TEAMUP_LIST", kind: "PUSH", memberSeqList: selectedArr, isSelect: e.target.checked });
                }
                break;

            case remoconType.SoundControl:
                if (performChatRoomInfo.chatRoomSeq !== -1) {
                    let selectedArr = [];
                    const arr = lUserSelectTeamupArr.map((info) => {
                        if (info.userSeq !== -1) {
                            if (info.entryYN) {
                                selectedArr.push({ userSeq: info.userSeq });
                                let value = {
                                    ...info,
                                    isVoiceON: e.target.checked,
                                };
                                return value;
                            } else {
                                return info;
                            }
                        } else {
                            return info;
                        }
                    });
                    setUserSelectTeamupArr(arr);
                    handleSelectedFunc({ selectedFunc: "TEAMUP_LIST", kind: "VOICE", memberSeqList: selectedArr, isSelect: e.target.checked });
                }
                break;

            case remoconType.ScreenControl:
                if (performChatRoomInfo.chatRoomSeq !== -1) {
                    let selectedArr = [];
                    const arr = lUserSelectTeamupArr.map((info) => {
                        if (info.userSeq !== -1) {
                            if (info.entryYN) {
                                selectedArr.push({ userSeq: info.userSeq });
                                let value = {
                                    ...info,
                                    isLiveON: e.target.checked,
                                };
                                return value;
                            } else {
                                return info;
                            }
                        } else {
                            return info;
                        }
                    });
                    setUserSelectTeamupArr(arr);
                    handleSelectedFunc({ selectedFunc: "TEAMUP_LIST", kind: "LIVE", memberSeqList: selectedArr, isSelect: e.target.checked });
                }
                break;

            default:
                break;
        }
    }

    const onChangeHandsupSelectAll = (e) => {
        switch (viewCMD) {
            case remoconType.Ink:
                if (activeLive.liveSeq !== -1) {
                    let selectedArr = [];
                    const arr = lUserSelectHandsupArr.map((info) => {
                        if (info.userSeq !== -1) {
                            if (info.entryYN) {
                                selectedArr.push({ userSeq: info.userSeq });
                                let value = {
                                    ...info,
                                    isInkON: e.target.checked,
                                };
                                return value;
                            } else {
                                return info;
                            }
                        } else {
                            return info;
                        }
                    });
                    setUserSelectHandsupArr(arr);
                    handleSelectedFunc({ selectedFunc: "HANDSUP_LIST", kind: "INK", memberSeqList: selectedArr, isSelect: e.target.checked });
                }
                break;

            case remoconType.PullScreen:
                if (activeLive.liveSeq !== -1) {
                    let selectedArr = [];
                    const arr = lUserSelectHandsupArr.map((info) => {
                        if (info.userSeq !== -1) {
                            if (info.entryYN) {
                                selectedArr.push({ userSeq: info.userSeq });
                                let value = {
                                    ...info,
                                    isPullScreen: e.target.checked,
                                };
                                return value;
                            } else {
                                return info;
                            }
                        } else {
                            return info;
                        }
                    });
                    setUserSelectHandsupArr(arr);
                    handleSelectedFunc({ selectedFunc: "HANDSUP_LIST", kind: "PULL", memberSeqList: selectedArr, isSelect: e.target.checked });
                }
                break;

            case remoconType.PushScreen:
                if (activeLive.liveSeq !== -1) {
                    let selectedArr = [];
                    const arr = lUserSelectHandsupArr.map((info) => {
                        if (info.userSeq !== -1) {
                            if (info.entryYN) {
                                selectedArr.push({ userSeq: info.userSeq });
                                let value = {
                                    ...info,
                                    isPushScreen: e.target.checked,
                                };
                                return value;
                            } else {
                                return info;
                            }
                        } else {
                            return info;
                        }
                    });
                    setUserSelectHandsupArr(arr);
                    handleSelectedFunc({ selectedFunc: "HANDSUP_LIST", kind: "PUSH", memberSeqList: selectedArr, isSelect: e.target.checked });
                }
                break;

            case remoconType.SoundControl:
                if (activeLive.liveSeq !== -1) {
                    let selectedArr = [];
                    const arr = lUserSelectHandsupArr.map((info) => {
                        if (info.userSeq !== -1) {
                            if (info.entryYN) {
                                selectedArr.push({ userSeq: info.userSeq });
                                let value = {
                                    ...info,
                                    isSoundON: e.target.checked,
                                };
                                return value;
                            } else {
                                return info;
                            }
                        } else {
                            return info;
                        }
                    });
                    setUserSelectHandsupArr(arr);
                    handleSelectedFunc({ selectedFunc: "HANDSUP_LIST", kind: "SOUND", memberSeqList: selectedArr, isSelect: e.target.checked });
                }
                break;

            case remoconType.ScreenControl:
                if (activeLive.liveSeq !== -1) {
                    let selectedArr = [];
                    const arr = lUserSelectHandsupArr.map((info) => {
                        if (info.userSeq !== -1) {
                            if (info.entryYN) {
                                selectedArr.push({ userSeq: info.userSeq });
                                let value = {
                                    ...info,
                                    isScreenON: e.target.checked,
                                };
                                return value;
                            } else {
                                return info;
                            }
                        } else {
                            return info;
                        }
                    });
                    setUserSelectHandsupArr(arr);
                    handleSelectedFunc({ selectedFunc: "HANDSUP_LIST", kind: "SCREEN", memberSeqList: selectedArr, isSelect: e.target.checked });
                }
                break;

            case remoconType.HandsUP:
                if (activeLive.liveSeq !== -1) {
                    let selectedArr = [];
                    const arr = lUserSelectHandsupArr.map((info) => {
                        if (info.userSeq !== -1) {
                            if (info.entryYN) {
                                selectedArr.push({ userSeq: info.userSeq });
                                let value = {
                                    ...info,
                                    isSelect: e.target.checked,
                                };
                                return value;
                            } else {
                                return info;
                            }
                        } else {
                            return info;
                        }
                    });
                    setUserSelectHandsupArr(arr);
                    handleSelectedFunc({ selectedFunc: "HANDSUP_LIST", kind: "SELECT", memberSeqList: selectedArr, isSelect: e.target.checked });
                }
                break;

            default:
                break;
        }
    }

    const onChangeSelectHandsupMember = (cmd, userSeq, flag) => {
        // console.log(activeLive, cmd, userSeq, flag);
        switch (cmd) {
            case remoconType.Ink:
                if (activeLive.liveSeq !== -1) {
                    const arr = lUserSelectHandsupArr.map((info) => {
                        let value = info.userSeq === userSeq ? {
                            userSeq         : info.userSeq,
                            userNickname    : info.userNickname,
                            entryYN         : info.entryYN,
                            isInkON         : !flag,
                            isSoundON       : info.isSoundON,
                            isScreenON      : info.isScreenON,
                            isSelect        : info.isSelect,
                            isPullScreen    : info.isPullScreen,
                            isPushScreen    : info.isPushScreen,
                        } : info;

                        return value;
                    });
                    setUserSelectHandsupArr(arr);
                    handleSelectedFunc({ selectedFunc: "HANDSUP_INK_ON", memberSeq: userSeq, isSelect: !flag });
                }
                break;

            case remoconType.SoundControl:
                if (activeLive.liveSeq !== -1) {
                    const arr = lUserSelectHandsupArr.map((info) => {
                        let value = info.userSeq === userSeq ? {
                            userSeq         : info.userSeq,
                            userNickname    : info.userNickname,
                            entryYN         : info.entryYN,
                            isInkON         : info.isInkON,
                            isSoundON       : !flag,
                            isScreenON      : info.isScreenON,
                            isSelect        : info.isSelect,
                            isPullScreen    : info.isPullScreen,
                            isPushScreen    : info.isPushScreen,
                        } : info;

                        return value;
                    });
                    setUserSelectHandsupArr(arr);
                    handleSelectedFunc({ selectedFunc: "HANDSUP_SOUND_ON", memberSeq: userSeq, isSelect: !flag });
                }
                break;

            case remoconType.ScreenControl:
                if (activeLive.liveSeq !== -1) {
                    const arr = lUserSelectHandsupArr.map((info) => {
                        let value = info.userSeq === userSeq ? {
                            userSeq         : info.userSeq,
                            userNickname    : info.userNickname,
                            entryYN         : info.entryYN,
                            isInkON         : info.isInkON,
                            isSoundON       : info.isSoundON,
                            isScreenON      : !flag,
                            isSelect        : info.isSelect,
                            isPullScreen    : info.isPullScreen,
                            isPushScreen    : info.isPushScreen,
                        } : info;

                        return value;
                    });
                    setUserSelectHandsupArr(arr);
                    handleSelectedFunc({ selectedFunc: "HANDSUP_SCREEN_ON", memberSeq: userSeq, isSelect: !flag });
                }
                break;

            case remoconType.HandsUP:
                if (activeLive.liveSeq !== -1) {
                    const arr = lUserSelectHandsupArr.map((info) => {
                        let value = info.userSeq === userSeq ? {
                            userSeq         : info.userSeq,
                            userNickname    : info.userNickname,
                            entryYN         : info.entryYN,
                            isInkON         : info.isInkON,
                            isSoundON       : info.isSoundON,
                            isScreenON      : info.isScreenON,
                            isSelect        : !flag,
                            isPullScreen    : info.isPullScreen,
                            isPushScreen    : info.isPushScreen,
                        } : info;

                        return value;
                    });
                    setUserSelectHandsupArr(arr);
                    handleSelectedFunc({ selectedFunc: "HANDSUP_SELECT", memberSeq: userSeq, isSelect: !flag });
                }
                break;

            case remoconType.PullScreen:
                if (activeLive.liveSeq !== -1) {
                    const arr = lUserSelectHandsupArr.map((info) => {
                        let value = info.userSeq === userSeq ? {
                            userSeq         : info.userSeq,
                            userNickname    : info.userNickname,
                            entryYN         : info.entryYN,
                            isInkON         : info.isInkON,
                            isSoundON       : info.isSoundON,
                            isScreenON      : info.isScreenON,
                            isSelect        : info.isSelect,
                            isPullScreen    : !flag,
                            isPushScreen    : info.isPushScreen,
                        } : info;

                        return value;
                    });
                    setUserSelectHandsupArr(arr);
                    handleSelectedFunc({ selectedFunc: "HANDSUP_PULL_SCREEN", memberSeq: userSeq, isSelect: !flag });
                }
                break;

            case remoconType.PushScreen:
                if (activeLive.liveSeq !== -1) {
                    const arr = lUserSelectHandsupArr.map((info) => {
                        let value = info.userSeq === userSeq ? {
                            userSeq         : info.userSeq,
                            userNickname    : info.userNickname,
                            entryYN         : info.entryYN,
                            isInkON         : info.isInkON,
                            isSoundON       : info.isSoundON,
                            isScreenON      : info.isScreenON,
                            isSelect        : info.isSelect,
                            isPullScreen    : info.isPullScreen,
                            isPushScreen    : !flag,
                        } : info;

                        return value;
                    });
                    setUserSelectHandsupArr(arr);
                    handleSelectedFunc({ selectedFunc: "HANDSUP_PUSH_SCREEN", memberSeq: userSeq, isSelect: !flag });
                }
                break;

            default:
                break;
        }
    }

    const onChangeSelectTeamupMember = (cmd, userSeq, flag, kind) => {
        switch (cmd) {
            case remoconType.Ink:
                if (performChatRoomInfo.chatRoomSeq !== -1) {
                    const arr = lUserSelectTeamupArr.map((info) => {
                        let value = info.userSeq === userSeq ? {
                            userSeq         : info.userSeq,
                            userNickname    : info.userNickname,
                            entryYN         : info.entryYN,
                            isInkON         : !flag,
                            isVoiceON       : info.isVoiceON,
                            isLiveON        : info.isLiveON,
                            isPullScreen    : info.isPullScreen,
                            isPushScreen    : info.isPushScreen,
                        } : info;

                        return value;
                    });
                    setUserSelectTeamupArr(arr);
                    handleSelectedFunc({ selectedFunc: "TEAMUP_INK_ON", memberSeq: userSeq, isSelect: !flag });
                }
                break;

            case remoconType.SoundControl:
                if (performChatRoomInfo.chatRoomSeq !== -1) {
                    const arr = lUserSelectTeamupArr.map((info) => {
                        let value = info.userSeq === userSeq ? {
                            userSeq         : info.userSeq,
                            userNickname    : info.userNickname,
                            entryYN         : info.entryYN,
                            isInkON         : info.isInkON,
                            isVoiceON       : !flag,
                            isLiveON        : info.isLiveON,
                            isPullScreen    : info.isPullScreen,
                            isPushScreen    : info.isPushScreen,
                        } : info;

                        return value;
                    });
                    setUserSelectTeamupArr(arr);
                    handleSelectedFunc({ selectedFunc: "TEAMUP_VOICE_ON", memberSeq: userSeq, isSelect: !flag });
                }
                break;

            case remoconType.ScreenControl:
                if (performChatRoomInfo.chatRoomSeq !== -1) {
                    const arr = lUserSelectTeamupArr.map((info) => {
                        let value = info.userSeq === userSeq ? {
                            userSeq         : info.userSeq,
                            userNickname    : info.userNickname,
                            entryYN         : info.entryYN,            
                            isInkON         : info.isInkON,
                            isVoiceON       : info.isVoiceON,
                            isLiveON        : !flag,
                            isPullScreen    : info.isPullScreen,
                            isPushScreen    : info.isPushScreen,
                        } : info;

                        return value;
                    });
                    setUserSelectTeamupArr(arr);
                    handleSelectedFunc({ selectedFunc: "TEAMUP_LIVE_ON", memberSeq: userSeq, isSelect: !flag });
                }
                break;


            case remoconType.PullScreen:
                if (performChatRoomInfo.chatRoomSeq !== -1) {
                    const arr = lUserSelectTeamupArr.map((info) => {
                        let value = info.userSeq === userSeq ? {
                            userSeq         : info.userSeq,
                            userNickname    : info.userNickname,
                            entryYN         : info.entryYN,
                            isInkON         : info.isInkON,
                            isVoiceON       : info.isVoiceON,
                            isLiveON        : info.isLiveON,
                            isPullScreen    : !flag,
                            isPushScreen    : info.isPushScreen,
                        } : info;

                        return value;
                    });
                    setUserSelectTeamupArr(arr);
                    handleSelectedFunc({ selectedFunc: "TEAMUP_PULL_SCREEN", memberSeq: userSeq, isSelect: !flag });
                }
                break;

            case remoconType.PushScreen:
                if (performChatRoomInfo.chatRoomSeq !== -1) {
                    const arr = lUserSelectTeamupArr.map((info) => {
                        let value = info.userSeq === userSeq ? {
                            userSeq         : info.userSeq,
                            userNickname    : info.userNickname,
                            entryYN         : info.entryYN,            
                            isInkON         : info.isInkON,
                            isVoiceON       : info.isVoiceON,
                            isLiveON        : info.isLiveON,
                            isPullScreen    : info.isPullScreen,
                            isPushScreen    : !flag,
                        } : info;

                        return value;
                    });
                    setUserSelectTeamupArr(arr);
                    handleSelectedFunc({ selectedFunc: "TEAMUP_PUSH_SCREEN", memberSeq: userSeq, isSelect: !flag });
                }
                break;

            default:
                break;
        }
    }

    const getEntryCount = (members) => {
        let cnt = 0;
        members.forEach(member => {
            if (member.userJoinStatus === "Y") {
                cnt++;
            }
        });
        return cnt;
    }

    return ( 
        <div className="d-flex flex-column" style={{ minHeight:'50%' }} onClick={handelPreventClick}>
            <div className="d-flex flex-column w-100" style={{ height: '30%', minHeight: 'max-content', maxHeight: 'max-content' }}>
                {
                    performChatRoomInfo.chatRoomSeq !== -1 ?
                        <div className="title-area sub hiclasstv-grey hiclasstv-blue-text" title={performChatRoomInfo.chatRoomName}>
                            <div className="text-area justify-content-start">
                                <span className="hiclass-text"><b>{performChatRoomInfo.chatRoomName}</b></span>
                                <span className="hiclass-text hiclasstv-darkgrey-text pl-1">({performChatRoomInfo.list_member.length})</span>
                            </div>
                        </div> :
                        <div className="title-area sub hiclasstv-grey hiclasstv-darkgrey-text">
                            현재 참여 중인 팀업 없음
                        </div>
                }
                <div className="container w-100 p-0">
                    <div className="row row-cols-4 m-0 px-1 py-2">
                        {
                            performChatRoomInfo.chatRoomSeq > 0 ?
                                lUserSelectTeamupArr.map((member, index) =>
                                    member.userSeq !== -1 ?
                                        member.userSeq !== userInfo.userSeq ?
                                            <div className="d-flex flex-column col align-items-center justify-content-center text-center p-0" key={index}>
                                                {
                                                    member.entryYN === "Y" ?
                                                        <>
                                                            {
                                                                viewCMD === remoconType.Ink ?
                                                                    <button className={"d-flex align-items-center justify-content-center no-effect hiclasstv-green round" + (member.isInkON ? " outline bold outline-red" : "")} title={member.userNickname} style={{ width: '2.5rem', height: '2.5rem' }} onClick={() => onChangeSelectTeamupMember(remoconType.Ink, member.userSeq, member.isInkON)}>
                                                                        <span className="small">{index + 1}</span>
                                                                    </button> :
                                                                viewCMD === remoconType.SoundControl ?
                                                                    <button className={"d-flex align-items-center justify-content-center no-effect hiclasstv-green round" + (member.isVoiceON ? " outline bold outline-red" : "")} title={member.userNickname} style={{ width: '2.5rem', height: '2.5rem' }} onClick={() => onChangeSelectTeamupMember(remoconType.SoundControl, member.userSeq, member.isVoiceON)}>
                                                                        <span className="small">{index + 1}</span>
                                                                    </button> :
                                                                viewCMD === remoconType.ScreenControl ?
                                                                    <button className={"d-flex align-items-center justify-content-center no-effect hiclasstv-green round" + (member.isLiveON ? " outline bold outline-red" : "")} title={member.userNickname} style={{ width: '2.5rem', height: '2.5rem' }} onClick={() => onChangeSelectTeamupMember(remoconType.ScreenControl, member.userSeq, member.isLiveON)}>
                                                                        <span className="small">{index + 1}</span>
                                                                    </button> :
                                                                viewCMD === remoconType.PullScreen ?
                                                                    <button className={"d-flex align-items-center justify-content-center no-effect hiclasstv-green round" + (member.isPullScreen ? " outline bold outline-red" : "")} title={member.userNickname} style={{ width: '2.5rem', height: '2.5rem' }} onClick={() => onChangeSelectTeamupMember(remoconType.PullScreen, member.userSeq, member.isPullScreen)}>
                                                                        <span className="small">{index + 1}</span>
                                                                    </button> :
                                                                viewCMD === remoconType.PushScreen ?
                                                                    <button className={"d-flex align-items-center justify-content-center no-effect hiclasstv-green round" + (member.isPushScreen ? " outline bold outline-red" : "")} title={member.userNickname} style={{ width: '2.5rem', height: '2.5rem' }} onClick={() => onChangeSelectTeamupMember(remoconType.PushScreen, member.userSeq, member.isPushScreen)}>
                                                                        <span className="small">{index + 1}</span>
                                                                    </button> :
                                                                <button className="d-flex align-items-center justify-content-center no-effect hiclasstv-green round" style={{ width:'2.5rem', height: '2.5rem' }} disabled>
                                                                    <span className="small">{index + 1}</span>
                                                                </button>
                                                            }
                                                            <div className="text-area my-1 px-1">
                                                                <span className="hiclass-text" title={member.userNickname}>{member.userNickname}</span>
                                                            </div>
                                                        </> :
                                                        <>
                                                            <button className="d-flex align-items-center justify-content-center no-effect hiclasstv-darkgrey round" style={{ width:'2.5rem', height: '2.5rem' }} disabled>
                                                                <span className="small">{index + 1}</span>
                                                            </button>
                                                            <div className="text-area my-1 px-1">
                                                                <span className="hiclass-text small" title={member.userNickname}>{member.userNickname}</span>
                                                            </div>
                                                        </>
                                                }
                                            </div> :
                                            <div className="d-flex flex-column col align-items-center justify-content-center text-center p-0" key={index}>
                                                <button className="d-flex align-items-center justify-content-center no-effect hiclasstv-green round" title={member.userNickname} style={{ width:'2.5rem', height: '2.5rem' }} disabled>
                                                    <span className="small">{index + 1}</span>
                                                </button>
                                                <div className="text-area my-1 px-1">
                                                    <span className="hiclass-text small" title={member.userNickname}>나</span>
                                                </div>
                                            </div> :
                                        <div className="d-flex flex-column col align-items-center justify-content-center text-center p-0" key={index}>
                                            <button className="d-flex align-items-center justify-content-center no-effect hiclasstv-grey round" style={{ width:'2.5rem', height: '2.5rem' }} disabled>
                                                <span className="small">{index + 1}</span>
                                            </button>
                                            <div className="text-area my-1 px-1">
                                                <span className="hiclass-text small" title={member.userNickname}>{member.userNickname}</span>
                                            </div>
                                        </div>
                                ) :
                                <>
                                    <div className="d-flex flex-column col align-items-center justify-content-center text-center p-0" key={0}>
                                        <button className="d-flex align-items-center justify-content-center no-effect hiclasstv-green round" style={{ width:'2.5rem', height: '2.5rem' }} disabled>
                                            <span className="small">나</span>
                                        </button>
                                        <div className="text-area my-1 px-1">
                                            <span className="hiclass-text small" title={userInfo.userNickname}>{userInfo.userNickname}</span>
                                        </div>
                                    </div>
                                    {
                                        lUserSelectTeamupArr.map((member, index) =>
                                            index + 1 < lUserSelectTeamupArr.length &&
                                            <div className="d-flex flex-column col align-items-center justify-content-center text-center p-0" key={index + 1}>
                                                <button className="d-flex align-items-center justify-content-center no-effect hiclasstv-grey round" style={{ width:'2.5rem', height: '2.5rem' }} disabled>
                                                    <span className="small">{index + 1}</span>
                                                </button>
                                                <div className="text-area my-1 px-1">
                                                    <span className="hiclass-text small" title={member.userNickname}>{member.userNickname}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-end custom-control custom-switch px-2 mb-2">
                    {
                        viewCMD === remoconType.HandsUP ?
                            <>
                                <input type="checkbox" className="custom-control-input m-0 p-0" id="teamupCheckAll" checked={false} disabled />
                                <label className="d-flex custom-control-label small align-items-center" htmlFor="teamupCheckAll">check All</label>
                            </> :
                            <>
                                {
                                    performChatRoomInfo.chatRoomSeq > 0 ?
                                        viewCMD === remoconType.Ink ?
                                            <>
                                                <input type="checkbox" className="custom-control-input m-0 p-0" id="teamupCheckAll" checked={performChatRoomInfo.isInkSwitch} onChange={onChangeTeamupSelectAll} disabled={false} />
                                                <label className="d-flex custom-control-label small align-items-center" htmlFor="teamupCheckAll">check All</label>
                                            </> :
                                        viewCMD === remoconType.SoundControl ?
                                            <>
                                                <input type="checkbox" className="custom-control-input m-0 p-0" id="teamupCheckAll" checked={performChatRoomInfo.isVoiceSwitch} onChange={onChangeTeamupSelectAll} disabled={false} />
                                                <label className="d-flex custom-control-label small align-items-center" htmlFor="teamupCheckAll">check All</label>
                                            </> :
                                        viewCMD === remoconType.ScreenControl ?
                                            <>
                                                <input type="checkbox" className="custom-control-input m-0 p-0" id="teamupCheckAll" checked={performChatRoomInfo.isScreenSwitch} onChange={onChangeTeamupSelectAll} disabled={false} />
                                                <label className="d-flex custom-control-label small align-items-center" htmlFor="teamupCheckAll">check All</label>
                                            </> :
                                        viewCMD === remoconType.PushScreen ?
                                            <>
                                                <input type="checkbox" className="custom-control-input m-0 p-0" id="teamupCheckAll" checked={performChatRoomInfo.isPushSwitch} onChange={onChangeTeamupSelectAll} disabled={false} />
                                                <label className="d-flex custom-control-label small align-items-center" htmlFor="teamupCheckAll">check All</label>
                                            </> :
                                        viewCMD === remoconType.PullScreen ?
                                            <>
                                                <input type="checkbox" className="custom-control-input m-0 p-0" id="teamupCheckAll" checked={performChatRoomInfo.isPullSwitch} onChange={onChangeTeamupSelectAll} disabled={false} />
                                                <label className="d-flex custom-control-label small align-items-center" htmlFor="teamupCheckAll">check All</label>
                                            </> :
                                        <>
                                            <input type="checkbox" className="custom-control-input m-0 p-0" id="teamupCheckAll" disabled />
                                            <label className="d-flex custom-control-label small align-items-center" htmlFor="teamupCheckAll">check All</label>
                                        </> :
                                    <>
                                        <input type="checkbox" className="custom-control-input m-0 p-0" id="teamupCheckAll" disabled />
                                        <label className="d-flex custom-control-label small align-items-center" htmlFor="teamupCheckAll">check All</label>
                                    </>
                                }
                            </>
                    }
                </div>
            </div>
            <div className="d-flex flex-column" style={{ minHeight: '10%' }}>
                {
                    activeLive.liveSeq === -1 ?
                        <div className="title-area sub hiclasstv-grey hiclasstv-darkgrey-text">
                            현재 선택된 클래스 없음
                        </div> :
                    activeLive.liveSeq !== -1 && activeLive.list_member.length > 0 ?
                        <div className="title-area sub hiclasstv-grey hiclasstv-blue-text">
                            <div className="text-area justify-content-start">
                                <span className="hiclass-text" title={activeLive.liveName}><b>{activeLive.liveName}</b></span>
                                <span className="hiclass-text hiclasstv-darkgrey-text small pl-1" title={"접속자 " + getEntryCount(activeLive.list_member) + "명"}>({getEntryCount(activeLive.list_member)} / {activeLive.list_member.length})</span>
                            </div>
                        </div> :
                        <div className="title-area sub hiclasstv-grey hiclasstv-blue-text">
                            <div className="text-area justify-content-start">
                                <span className="hiclass-text" title={activeLive.liveName}><b>{activeLive.liveName}</b></span>
                                <span className="hiclass-text hiclasstv-darkgrey-text small pl-1" title="참여 중인 멤버 없음">(0)</span>
                            </div>
                        </div>
                        
                }
                <div className={"container w-100 p-0" + (activeLive.liveSeq !== -1 ? " scroll-show-active" : " scroll-show")} style={{maxHeight:'100%', overflow:'auto'}}>
                    <div className="row row-cols-4 m-0 px-1 py-2">
                        <div className="d-flex flex-column col align-items-center justify-content-center text-center p-0" key={0}>
                            <button className="d-flex align-items-center justify-content-center no-effect hiclasstv-green round" title={userInfo.userNickname} style={{ width: '2.5rem', height: '2.5rem' }} disabled>
                                <span className="small">나</span>
                            </button>
                            <div className="text-area my-1 px-1">
                                <span className="hiclass-text small" title={userInfo.userNickname}>{userInfo.userNickname}</span>
                            </div>
                        </div>
                        {
                            activeLive.liveSeq > 0 ?
                                lUserSelectHandsupArr.map((member, index) => 
                                    member.userSeq !== -1 ?
                                        <div className="d-flex flex-column col align-items-center justify-content-center text-center p-0" key={index + 1}>
                                            {
                                                member.entryYN === "Y" ?
                                                    <>
                                                        {
                                                            viewCMD === remoconType.Ink ?
                                                                <button className={"d-flex align-items-center justify-content-center no-effect hiclasstv-green round" + (member.isInkON ? " outline bold outline-red" : "")} title={member.userNickname} style={{ width: '2.5rem', height: '2.5rem' }} onClick={() => onChangeSelectHandsupMember(remoconType.Ink, member.userSeq, member.isInkON)}>
                                                                    <span className="small">{index + 1}</span>
                                                                </button> :
                                                            viewCMD === remoconType.SoundControl ?
                                                                <button className={"d-flex align-items-center justify-content-center no-effect hiclasstv-green round" + (member.isSoundON ? " outline bold outline-red" : "")} title={member.userNickname} style={{ width: '2.5rem', height: '2.5rem' }} onClick={() => onChangeSelectHandsupMember(remoconType.SoundControl, member.userSeq, member.isSoundON)}>
                                                                    <span className="small">{index + 1}</span>
                                                                </button> :
                                                            viewCMD === remoconType.ScreenControl ?
                                                                <button className={"d-flex align-items-center justify-content-center no-effect hiclasstv-green round" + (member.isScreenON ? " outline bold outline-red" : "")} title={member.userNickname} style={{ width: '2.5rem', height: '2.5rem' }} onClick={() => onChangeSelectHandsupMember(remoconType.ScreenControl, member.userSeq, member.isScreenON)}>
                                                                    <span className="small">{index + 1}</span>
                                                                </button> :
                                                            viewCMD === remoconType.HandsUP ?
                                                                <button className={"d-flex align-items-center justify-content-center no-effect hiclasstv-green round" + (member.isSelect ? " outline bold outline-red" : "")} title={member.userNickname} style={{ width: '2.5rem', height: '2.5rem' }} onClick={() => onChangeSelectHandsupMember(remoconType.HandsUP, member.userSeq, member.isSelect)}>
                                                                    <span className="small">{index + 1}</span>
                                                                </button> :
                                                            viewCMD === remoconType.PullScreen ?
                                                                <button className={"d-flex align-items-center justify-content-center no-effect hiclasstv-green round" + (member.isPullScreen ? " outline bold outline-red" : "")} title={member.userNickname} style={{ width: '2.5rem', height: '2.5rem' }} onClick={() => onChangeSelectHandsupMember(remoconType.PullScreen, member.userSeq, member.isPullScreen)}>
                                                                    <span className="small">{index + 1}</span>
                                                                </button> :
                                                            viewCMD === remoconType.PushScreen ?
                                                                <button className={"d-flex align-items-center justify-content-center no-effect hiclasstv-green round" + (member.isPushScreen ? " outline bold outline-red" : "")} title={member.userNickname} style={{ width: '2.5rem', height: '2.5rem' }} onClick={() => onChangeSelectHandsupMember(remoconType.PushScreen, member.userSeq, member.isPushScreen)}>
                                                                    <span className="small">{index + 1}</span>
                                                                </button> :
                                                            <button className="d-flex align-items-center justify-content-center no-effect hiclasstv-green round" title={member.userNickname} style={{ width: '2.5rem', height: '2.5rem' }} disabled>
                                                                <span className="small">{index + 1}</span>
                                                            </button>                            
                                                        }
                                                        <div className="text-area my-1 px-1">
                                                            <span className="hiclass-text small" title={member.userNickname}>{member.userNickname}</span>
                                                        </div>
                                                    </> :
                                                    <>
                                                        <button className="d-flex align-items-center justify-content-center no-effect hiclasstv-darkgrey round" style={{ width: '2.5rem', height: '2.5rem' }} disabled>
                                                            <span className="small">{index + 1}</span>
                                                        </button>
                                                        <div className="text-area my-1 px-1">
                                                            <span className="hiclass-text small" title={member.userNickname}>{member.userNickname}</span>
                                                        </div>
                                                    </>
                                            }
                                        </div> :
                                        <div className="d-flex flex-column col align-items-center justify-content-center text-center p-0" key={index + 1}>
                                            <button className="d-flex align-items-center justify-content-center no-effect hiclasstv-grey round" style={{ width: '2.5rem', height: '2.5rem' }} disabled>
                                                <span className="small">{index + 1}</span>
                                            </button>
                                            <div className="text-area my-1 px-1">
                                                <span className="hiclass-text small" title={member.userNickname}>{member.userNickname}</span>
                                            </div>
                                        </div>
                                ) :
                                lUserSelectHandsupArr.map((member, index) => 
                                    <div className="d-flex flex-column col align-items-center justify-content-center text-center p-0" key={index + 1}>
                                        <button className="d-flex align-items-center justify-content-center no-effect hiclasstv-grey round" style={{ width: '2.5rem', height: '2.5rem' }} disabled>
                                            <span className="small">{index + 1}</span>
                                        </button>
                                        <div className="text-area my-1 px-1">
                                            <span className="hiclass-text small" title={member.userNickname}>{member.userNickname}</span>
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-end custom-control custom-switch px-2 mt-2">
                    {
                        viewCMD === remoconType.HandsUP ?
                            <button className="d-flex no-effect hiclasstv-blue white-text px-2" onClick={() => onStartSelectMemberP2P()} disabled={lUserSelectHandsupArr.length > 0 ? false : true}>
                                <span className="small">P2P Start</span>
                            </button> :
                            <>
                                {
                                    activeLive.liveSeq !== -1 ?
                                        viewCMD === remoconType.Ink ?
                                            <>
                                                <input type="checkbox" className="custom-control-input m-0 p-0" id="handsupCheckAll" checked={activeLive.isInkSwitch} onChange={onChangeHandsupSelectAll} disabled={false} />
                                                <label className="d-flex custom-control-label small align-items-center" htmlFor="handsupCheckAll">check All</label>
                                            </> :
                                        viewCMD === remoconType.SoundControl ?
                                            <>
                                                <input type="checkbox" className="custom-control-input m-0 p-0" id="handsupCheckAll" checked={activeLive.isVoiceSwitch} onChange={onChangeHandsupSelectAll} disabled={false} />
                                                <label className="d-flex custom-control-label small align-items-center" htmlFor="handsupCheckAll">check All</label>
                                            </> :
                                        viewCMD === remoconType.ScreenControl ?
                                            <>
                                                <input type="checkbox" className="custom-control-input m-0 p-0" id="handsupCheckAll" checked={activeLive.isScreenSwitch} onChange={onChangeHandsupSelectAll} disabled={false} />
                                                <label className="d-flex custom-control-label small align-items-center" htmlFor="handsupCheckAll">check All</label>
                                            </> :
                                        viewCMD === remoconType.PushScreen ?
                                            <>
                                                <input type="checkbox" className="custom-control-input m-0 p-0" id="handsupCheckAll" checked={activeLive.isPushSwitch} onChange={onChangeHandsupSelectAll} disabled={false} />
                                                <label className="d-flex custom-control-label small align-items-center" htmlFor="handsupCheckAll">check All</label>
                                            </> :
                                        viewCMD === remoconType.PullScreen ?
                                            <>
                                                <input type="checkbox" className="custom-control-input m-0 p-0" id="handsupCheckAll" checked={activeLive.isPullSwitch} onChange={onChangeHandsupSelectAll} disabled={false} />
                                                <label className="d-flex custom-control-label small align-items-center" htmlFor="handsupCheckAll">check All</label>
                                            </> :
                                        <>
                                            <input type="checkbox" className="custom-control-input m-0 p-0" id="handsupCheckAll" disabled />
                                            <label className="d-flex custom-control-label small align-items-center" htmlFor="handsupCheckAll">check All</label>
                                        </> :
                                    <>
                                        <input type="checkbox" className="custom-control-input m-0 p-0" id="handsupCheckAll" disabled />
                                        <label className="d-flex custom-control-label small align-items-center" htmlFor="handsupCheckAll">check All</label>
                                    </>
                                }
                            </>
                    }
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between p-3" style={{height:'20%', maxHeight:'max-content'}}>
                <button className={"footer-menu p-0 round" + (viewCMD === remoconType.SoundControl ? " active" : "")} title="소리" onClick={() => handleSelectedFunc({ selectedFunc: "SOUND_CONTROL" })}>
                    <img src="/images/toolbar/white_soundOn.png" className="p-1" style={{width:'3rem'}} alt="" />
                </button>
                {/*<button className={"footer-menu p-0 round" + (viewCMD === remoconType.ScreenControl ? " active" : "")} title="영상" onClick={() => handleSelectedFunc({ selectedFunc: "SCREEN_CONTROL" })}>
                    <img src="/images/toolbar/white_screenOn.png" className="p-1" style={{width:'3rem'}} alt="" />
                </button>
                <button className={"footer-menu p-0 round" + (viewCMD === remoconType.HandsUP ? " active" : "")} title="핸즈업" onClick={() => handleSelectedFunc({ selectedFunc: "HANDS_UP" })}>
                    <img src="/images/toolbar/white_handsup.png" className="p-1" style={{width:'3rem'}} alt="" />
                </button>
                <button className={"footer-menu p-0 round" + (viewCMD === remoconType.PushScreen ? " active" : "")} title="화면 내보내기" onClick={() => handleSelectedFunc({ selectedFunc: "PUSH_SCREEN" })}>
                    <img src="/images/toolbar/white_pushScreen.png" className="p-1" style={{width:'3rem'}} alt="" />
                </button>*/}
                <button className={"footer-menu p-0 round" + (viewCMD === remoconType.PullScreen ? " active" : "")} title="화면 가져오기" onClick={() => handleSelectedFunc({ selectedFunc: "PULL_SCREEN" })}>
                    <img src="/images/toolbar/white_pullScreen.png" className="p-1" style={{width:'3rem'}} alt="" />
                </button>
                <button className={"footer-menu p-0 round" + (viewCMD === remoconType.Ink ? " active" : "")} title="잉크챗" onClick={() => handleSelectedFunc({ selectedFunc: "INK_STATUS" })}>
                    <img src="/images/toolbar/white_inkmemo.png" className="p-1" style={{width:'3rem'}} alt="" />
                </button>
            </div>
        </div>
    );
}

export default RemoconMenu;