import React from 'react';

const WhisperChattingInfo = ({ stdInfo }) => {
    return (
        <div className="title-area main hiclasstv-blue">
            <div className="text-area justify-content-start">
                <span className="hiclass-text white-text" title={stdInfo.stdNickname}>
                    {stdInfo.stdNickname}
                </span>
            </div>
        </div>
    );
}

export default WhisperChattingInfo;