import React from "react";

import { ctRcvKind } from "../../../lib/classTalk/CodeEnum";

const collectURL = (match, p1, p2, p3, p4, p5, p6, p7, p8, offset, string) => {
    let obj = { match, p1, p2, p3, p4, p5, p6, p7, p8, offset, string };
    // console.log("collectURL called - match => ", match);
    // console.log("obj => ", obj);
    let href = !match.includes("://") ? "//" + match : match;
    // console.log("href => ", href);
    let result = '<a href=' + href + ' target=_blank>' + match + '</a>';
    // console.log("result => ", result);
    return result;
}

const autoLink = (message) => {
    const regExp =
        /(?:(?:(https?|ftp|telnet):\/\/|[^\s\t\r\n[\]`<>"'])((?:[\w$\-_.+!*'(),]|%[0-9a-f][0-9a-f])*:(?:[\w$\-_.+!*'(),;?&=]|%[0-9a-f][0-9a-f])+@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?::([0-9]+))?((?:\/(?:[\w$\-_.+!*'(),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s/?.:<>|#]*(?:\.[^\s/?:<>|#]+)*))?(\/?[?;](?:[a-z0-9-]+(?:=[^\s:&<>]*)?&)*[a-z0-9-]+(?:=[^\s:&<>]*)?)?(#[\w-]+)?)/gmi;

    return message.replace(regExp, collectURL);
}

const Message = ({ msgInfo }) => {
    switch (msgInfo.rcvKind) {
        case ctRcvKind.Student:
            return (
                <span className="ct-bubble bubble-msg msg-rcv msg-bg-cyan"
                    dangerouslySetInnerHTML={{__html: autoLink(msgInfo.text)}}
                >
                </span>
            );

        case ctRcvKind.Teacher:
            return (
                <span className="ct-bubble bubble-msg msg-rcv msg-bg-blue"
                    dangerouslySetInnerHTML={{__html: autoLink(msgInfo.text)}}
                >
                </span>
            );

        case ctRcvKind.Team:
            return (
                <span className="ct-bubble bubble-msg msg-rcv msg-bg-red"
                    dangerouslySetInnerHTML={{__html: autoLink(msgInfo.text)}}
                >
                </span>
            );

        case ctRcvKind.RcvWatson:
        case ctRcvKind.Card:
            return (
                <span className="ct-bubble bubble-msg msg-rcv msg-bg-violet-btext"
                    dangerouslySetInnerHTML={{__html: autoLink(msgInfo.text)}}
                >
                </span>
            );

        case ctRcvKind.infoPentalk:
            return (
                <span className="ct-bubble bubble-msg msg-rcv msg-bg-indigo-btext"
                    dangerouslySetInnerHTML={{ __html: autoLink(msgInfo.text) }}
                >
                </span>
            );

        default:
            return (
                <span className="ct-bubble bubble-msg msg-rcv msg-bg-red"
                    dangerouslySetInnerHTML={{__html: autoLink(msgInfo.text)}}
                >
                </span>
            );
    }
}

const TextMessage = ({ indexKey, kind, msgInfo, handleSelectUrl }) => {
    return (
        msgInfo.text !== undefined && msgInfo.text !== null && msgInfo.text !== "" &&
            kind === "whisper" ?
            <span className="ct-bubble bubble-msg msg-rcv msg-bg-orange" style={{ cursor: 'pointer' }} onClick={() => handleSelectUrl(msgInfo.kind, msgInfo, indexKey)}
                dangerouslySetInnerHTML={{__html: autoLink(msgInfo.text)}}
            >
            </span> :
            msgInfo.text !== "" &&
            <Message msgInfo={msgInfo} />
    );
}

export default TextMessage;