import axios from 'axios';
import ConstData from '../ConstData';
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const get_build_version_info = () => {
    return axios.get(`${ConstData.REST_API_HOST_ADD}/hiclasstv/version`, options);
};

export const get_hiclasstv_info = () => {
    return axios.get(`${ConstData.REST_API_HOST_ADD}/`, options);
};

export const get_refresh_hiclasstv_info = () => {
    return axios.get(`${ConstData.REST_API_HOST_ADD}/hiclasstv/info`, options);
};

export const get_refresh_my_friend_list = ({ userSeq }) => {
    return axios.get(`${ConstData.REST_API_HOST_ADD}/hiclasstv/${userSeq}/list?kind=friend`, options);
};

export const get_download_info = () => {
    return axios.get(`${ConstData.REST_API_HOST_ADD}/hiclasstv/info/download`, options);
};
