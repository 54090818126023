/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';

const QnA011 = ({ isOpened, onChangeOpenedFlag }) => {
    useEffect(() => {
        let content = document.getElementById("qna11");

        if (isOpened) {
            content.style.maxHeight = content.scrollHeight + "px";
        } else {
            content.style.maxHeight = null;
        }
    }, [isOpened]);

    return (
        <div className="accordion">
            <div className="accordion-title" onClick={() => onChangeOpenedFlag(10)}>
                <span className="hiclasstv-deepDarkgrey-text button">
                    <b>11. 비대면 원격교육시 소통은 어떻게 하나요?</b>
                </span>
            </div>
            <div className="accordion-content hiclasstv-darkgrey-text" id="qna11">
                <span className="d-flex" style={{ minWidth: 'max-content' }}>A.&nbsp;</span>
                <p>
                    다양한 소통이 가능합니다.<br />
                    기본적으로 교사(회의 진행자)가 작성한 콘텐츠를 공유하여 보게 되고 판서를 하면 실시간으로 모든 사용자 화면에 씌여집니다.
                    당연히 음성은 전달됩니다.
                    또한 카톡처럼 채팅을 할 수 있고 쪽지를 보낼 수 있으며 파일 공유도 가능합니다.
                    개인의 화면을 내보내기(Push)/가져오기(Pull) 하여 콘텐츠로 소통할 수도 있습니다.
                </p>
            </div>
        </div>
    );
}
export default QnA011;