import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { enWebPage } from "../../lib/ConstCommand";

import WebAdminStudioInfo from "../../components/studio/WebAdminStudioInfo";
import WebB2BStudioInfo from "../../components/studio/WebB2BStudioInfo";
import WebB2CStudioInfo from "../../components/studio/WebB2CStudioInfo";

import {
    getMyManageSubDomainList,
    findUser
} from "../../modules/admin";

import {
    getOwnerStudioList,
    getAdminStudioList,
    initStudioList,
    callCreateStudio,
    callCreateDisplayInfo,
    callModifyStudioName,
    callModifyStudioGroupLimit,
    callRemoveStudio,
    callRemoveDisplayFromStudio,
    callRemoveDisplayInfo
} from "../../modules/studio";

class WebStudioContainer extends Component {

    handleSelectedFunc = ({ selectedFunc, subDomainSeq, subDomainName, selectedUserSeq, kind, data, keyword }) => {
        // console.log("studio handleSelectedFunc - ", selectedFunc, kind, subDomainName, subDomainSeq, userSeq, data);

        switch (selectedFunc) {
            case "INIT_STUDIO_LIST":
                this.props.initStudioList();
                break;

            case "GET_MANAGE_INFO":
                this.props.getMyManageSubDomainList({
                    userSeq: this.props.userSeq,
                    userKind: this.props.userKind
                });
                break;

            case "GET_STUDIO_LIST":
                if (kind === "b2c") {
                    this.props.getOwnerStudioList({ userSeq: this.props.userSeq });
                } else if (kind === "b2b") {
                    this.props.getAdminStudioList({
                        subDomain: subDomainName,
                        subDomainSeq
                    });
                } else if (kind === "admin") {
                    this.props.getAdminStudioList({
                        subDomain: subDomainName,
                        subDomainSeq,
                        userSeq: selectedUserSeq
                    });
                }
                break;

            case "CREATE_STUDIO":
                if (kind === "b2c") {
                    this.props.callCreateStudio({
                        subDomainSeq: 1,
                        userSeq: selectedUserSeq,
                        studioName: data.studioName,
                        groupLimit: data.groupLimit
                    });
                } else {
                    this.props.callCreateStudio({
                        subDomainSeq,
                        studioName: data.studioName,
                        groupLimit: data.groupLimit
                    });
                }
                break;

            case "MODIFY":
                console.log("MODIFY - ", data);
                if (kind === "name") {
                    // console.log("스튜디오 이름 수정");
                    this.props.callModifyStudioName(data);
                } else if (kind === "group-limit") {
                    // console.log("스튜디오 연결 인원 수 변경");
                    this.props.callModifyStudioGroupLimit(data);
                }
                break;

            case "ADD_DISPLAY":
                if (kind === "b2c") {
                    if (data.kind === "assign") {
                        // console.log("개인 스튜디오 연결한 디스플레이 추가");
                        this.props.callCreateDisplayInfo({
                            userSeq: selectedUserSeq,
                            subDomainSeq,
                            studioSeq: data.studioSeq
                        });
                    } else {
                        // console.log("개인 스튜디오 연결 없이 디스플레이 추가");
                        this.props.callCreateDisplayInfo({
                            userSeq: selectedUserSeq,
                            subDomainSeq
                        });
                    }
                } else {
                    if (data.kind === "assign") {
                        // console.log("서브 도메인 스튜디오 연결한 디스플레이 추가");
                        this.props.callCreateDisplayInfo({
                            subDomainSeq,
                            studioSeq: data.studioSeq
                        });
                    } else {
                        // console.log("서브 도메인 스튜디오 연결 없이 디스플레이 추가");
                        this.props.callCreateDisplayInfo({ subDomainSeq });
                    }
                }
                break;

            case "REMOVE":
                console.log("REMOVE - ", data);
                if (kind === "studio") {
                    // console.log("스튜디오 삭제 - ", data);
                    this.props.callRemoveStudio(data);
                } else if (kind === "studio-display") {
                    // console.log("스튜디오에 연결된 디스플레이 삭제");
                    this.props.callRemoveDisplayFromStudio(data);
                } else if (kind === "display") {
                    // console.log("미등록 디스플레이 삭제");
                    this.props.callRemoveDisplayInfo(data);
                }
                break;

            case "FIND_USER":
                this.props.findUser({
                    kind: "search-user",
                    userKind: subDomainName,
                    keyWord: keyword
                });
                break;

            default:
                break;
        }
    }

    render() {
        if (this.props.user_info.userKind !== "hiclasstv" && !this.props.isConfigAdmin) {
            return <Redirect to="/main" />;
        }

        return (
            this.props.currentPageType === enWebPage.Studio ?
                this.props.user_info.userKind === "hiclasstv" ?
                    <WebB2CStudioInfo // ........... hiclasstv 개인 사용자가 보는 스튜디오 목록 조회 페이지 (B2C)
                        selectedUserInfo={this.props.user_info}
                        list_studio={this.props.list_studio}
                        list_display={this.props.list_display}
                        handleSelectedFunc={this.handleSelectedFunc}
                    /> :
                    <WebB2BStudioInfo // ........... 서브 도메인 관리자가 보는 스튜디오 목록 조회 페이지 (B2B)
                        list_configSubDomain={this.props.list_configSubDomain}
                        list_studio={this.props.list_studio}
                        list_display={this.props.list_display}
                        handleSelectedFunc={this.handleSelectedFunc}
                    /> :
                <WebAdminStudioInfo // ........... hiclasstv 사이트 관리자가 보는 스튜디오 조회 페이지 (STUDIO, DISPLAY 조회)
                    list_configSubDomain={this.props.list_configSubDomain}
                    selectedSubDomainInfo={this.props.selectedSubDomainInfo}
                    selectedUserInfo={this.props.selectedUserInfo}
                    searchResult={this.props.searchResult}
                    list_studio={this.props.list_studio}
                    list_display={this.props.list_display}
                    handleSelectedFunc={this.handleSelectedFunc}
                />
        );
    }
}

export default connect(
    ({ user, admin, studio }) => ({
        isConfigAdmin           : user.isConfigAdmin,

        user_info               : {
            userSeq             : user.userSeq,
            userKind            : user.userKind,
            userID              : user.userID,
            userNickname        : user.userNickname
        },

        list_configSubDomain      : admin.list_configSubDomain,
        selectedSubDomainInfo     : admin.selectedSubDomainInfo,
        selectedUserInfo        : admin.selectedUserInfo,

        searchResult            : admin.searchResult,

        list_studio             : studio.list_studio,
        list_display            : studio.list_display,
    }),
    {
        initStudioList,
        getOwnerStudioList,
        getAdminStudioList,

        callCreateStudio,
        callCreateDisplayInfo,
        callModifyStudioName,
        callModifyStudioGroupLimit,
        callRemoveStudio,
        callRemoveDisplayFromStudio,
        callRemoveDisplayInfo,

        getMyManageSubDomainList,
        findUser
    }
)(WebStudioContainer);