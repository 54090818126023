import React, { useState, useEffect } from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import { FaInfoCircle, FaPodcast, FaCircle, FaTimes } from 'react-icons/fa';

import { sendGetState } from '../../lib/MediaSoupClient';
import WebListArrowButton from '../etc/button/WebListArrowButton';
import { stMediaSoupKind } from '../../lib/ConstCommand';

const WebMediaConfig = ({ media_info }) => {
    const [isOpened, setIsOpened] = useState(false);
    const [lLiveInfo, setLiveInfo] = useState(null);
    const [lRoomInfo, setRoomInfo] = useState(null);

    useEffect(() => {
        console.log('WebMediaConfig() init');
        //sendGetState();

        return () => {
            console.log('WebMediaConfig() component invisible');
        }
    }, []);

    useEffect(() => {
        if (media_info !== undefined && media_info !== null) {
            console.log(media_info);

            if (media_info.live_info !== undefined && media_info.live_info !== null) {
                setLiveInfo(media_info.live_info);
            }

            if (media_info.room_info !== undefined && media_info.room_info !== null) {
                setRoomInfo(media_info.room_info);
            }
        }
    }, [media_info]);

    const onClickBtn = (e) => {
        sendGetState(e.target.value);
    }

    return (
        <div className="web-main list-area" style={{ maxWidth: '900px' }}>
            <div className="card p-2">
                <div className="d-flex">
                {
                    stMediaSoupKind.map((info, index) => (
                        <button className="btn btn-sm btn-outline-primary py-1 px-2 m-1" key={"ms-" + index} value={info.value} onClick={onClickBtn}>{info.value}</button>
                    ))
                }
                </div>
            </div>
            <div className="card p-2 mt-5">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <span className="d-flex white-text mx-1">
                        <FaInfoCircle className="font-lg mr-2" aria-hidden="true" />HandsUP Info
                    </span>
                    <WebListArrowButton toggle_id={'mediaLiveInfoList'} list_array={lLiveInfo} isOpened={isOpened} />
                </div>
                <div className="px-2">
                    {
                        lLiveInfo !== undefined && lLiveInfo !== null && lLiveInfo.length > 0 ?
                            <UncontrolledCollapse toggler="#mediaLiveInfoList" defaultOpen={true} onEntering={() => setIsOpened(true)} onExiting={() => setIsOpened(false)}>
                                <table className="d-flex flex-column table flex-list mb-0">
                                    <thead>
                                        <tr className="d-flex">
                                            {/* <th className="area-15">
                                                <b>LiveSEQ</b>
                                            </th>
                                            <th className="area-15">
                                                <b>ReceiverSEQ</b>
                                            </th>
                                            <th className="area-15">
                                                <b>Worker</b>
                                            </th>
                                            <th className="area-55">
                                                <b>Sender</b>
                                            </th> */}
                                            <th className="area-15">
                                                <b>LiveSEQ</b>
                                            </th>
                                            <th className="area-15">
                                                <b>Worker</b>
                                            </th>
                                            <th className="area-70">
                                                <b>Sender</b>
                                            </th>
                                        </tr>
                                    </thead>
                                    {
                                        lLiveInfo.map(
                                            (live, lIdx) => (
                                                <tbody key={"lkey_" + lIdx}>
                                                    <tr className="d-flex">
                                                        {/* <td className="d-flex align-items-center justify-content-center area-15">
                                                            <span className="hiclass-text"><b>{live.liveSeq}</b></span>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center area-15">
                                                            <span className="hiclass-text"><b>{live.receiverSeq}</b></span>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center area-15">
                                                            <span className="hiclass-text"><b>{live.workerSeq}</b></span>
                                                        </td> */}
                                                        <td className="d-flex align-items-center justify-content-center area-15">
                                                            <span className="hiclass-text"><b>{live.liveSeq}</b></span>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center area-15">
                                                            <span className="hiclass-text"><b>{live.workerSeq}</b></span>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center area-70">
                                                            {
                                                                live.sender !== undefined && live.sender !== null && live.sender.length > 0 ?
                                                                    <table className="d-flex flex-column table-sm sub-list mediasoup mb-0 w-100">
                                                                        <thead>
                                                                            <tr className="d-flex">
                                                                                {/* <th className="d-flex align-items-center justify-content-center area-20">
                                                                                    <b>UserSEQ</b>
                                                                                </th> */}
                                                                                <th className="d-flex flex-column align-items-center justify-content-center area-50">
                                                                                    <b>Producer</b>
                                                                                    {/* <div className="d-flex pt-1 w-100">
                                                                                        <span className="area-30">UserSEQ</span>
                                                                                        <span className="area-35">Video</span>
                                                                                        <span className="area-35">Audio</span>
                                                                                    </div> */}
                                                                                </th>
                                                                                <th className="d-flex flex-column align-items-center justify-content-center area-50">
                                                                                    {/* <b>Receiver</b>
                                                                                    <div className="d-flex pt-1 w-100">
                                                                                        <span className="area-30">UserSEQ</span>
                                                                                        <span className="area-35">Video</span>
                                                                                        <span className="area-35">Audio</span>
                                                                                    </div> */}
                                                                                    <b>Receiver</b>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                live.sender.map(
                                                                                    (info, index) => (
                                                                                        <tr className="d-flex" key={lIdx + "-sender-" + index}>
                                                                                            {/* <td className="d-flex align-items-center justify-content-center area-20">
                                                                                                <span><b>{info.userSeq}</b></span>
                                                                                            </td> */}
                                                                                            <td className="d-flex align-items-center justify-content-center area-50">
                                                                                                {
                                                                                                    /* info.producer ? 
                                                                                                        <>
                                                                                                            <span className="area-30">{info.producer.userSeq}</span>
                                                                                                            <span className="text-center area-35">
                                                                                                                {
                                                                                                                    info.producer.isVideoSend ?
                                                                                                                        <FaCircle className="font-sm green-text" /> :
                                                                                                                        <FaTimes className="font-sm red-text" />
                                                                                                                }
                                                                                                            </span>
                                                                                                            <span className="text-center area-35">
                                                                                                                {
                                                                                                                    info.producer.isAudioSend?
                                                                                                                        <FaCircle className="font-sm green-text" /> :
                                                                                                                        <FaTimes className="font-sm red-text" />
                                                                                                                }
                                                                                                            </span>
                                                                                                        </> : 
                                                                                                        <span className="small grey-text">정보 없음</span> */
                                                                                                    info.producer ?
                                                                                                        <div className="mediasoup-div">
                                                                                                            <div>
                                                                                                                <span className="area-30">UserSEQ</span>
                                                                                                                <span className="area-35">Video</span>
                                                                                                                <span className="area-35">Audio</span>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <span className="area-30">{info.producer.userSeq}</span>
                                                                                                                <span className="area-35">
                                                                                                                    {
                                                                                                                        info.producer.isVideoSend ?
                                                                                                                            <FaCircle className="font-sm green-text" /> :
                                                                                                                            <FaTimes className="font-sm red-text" />
                                                                                                                    }
                                                                                                                </span>
                                                                                                                <span className="area-35">
                                                                                                                    {
                                                                                                                        info.producer.isAudioSend ?
                                                                                                                            <FaCircle className="font-sm green-text" /> :
                                                                                                                            <FaTimes className="font-sm red-text" />
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div> :
                                                                                                        <span className="small grey-text">정보 없음</span>
                                                                                                }
                                                                                            </td>
                                                                                            <td className="d-flex flex-column nohover align-items-center justify-content-center area-50">
                                                                                                <button type="button" className="btn btn-sm btn-outline-primary px-2 py-1 m-0" id={"show_" + index + "_handsUp_rcv_" + lIdx}>
                                                                                                    조회
                                                                                                </button>
                                                                                                <UncontrolledCollapse toggler={"#show_" + index + "_handsUp_rcv_" + lIdx} defaultOpen={false} style={{ width: '100%' }}>
                                                                                                    {
                                                                                                        info.receiver && info.receiver.length > 0 ?
                                                                                                            <table className="d-flex flex-column table-sm sub-list mediasoup table-hover mt-2 mb-0 w-100">
                                                                                                                <thead>
                                                                                                                    <tr className="d-flex">
                                                                                                                        <th className="d-flex align-items-center justify-content-center mb-0 w-100">
                                                                                                                            <span className="area-30">UserSEQ</span>
                                                                                                                            <span className="area-35">Video</span>
                                                                                                                            <span className="area-35">Audio</span>
                                                                                                                        </th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        info.receiver.map((receiver, rIdx) => (
                                                                                                                            <tr className="d-flex" key={"handsUp_" + index + "_rcv_" + rIdx}>
                                                                                                                                <td className="d-flex align-items-center justify-content-center area-30">
                                                                                                                                    {receiver.userSeq}
                                                                                                                                </td>
                                                                                                                                <td className="d-flex align-items-center justify-content-center area-35">
                                                                                                                                    {
                                                                                                                                        receiver.isVideoRcv ?
                                                                                                                                            <FaCircle className="font-sm green-text" /> :
                                                                                                                                            <FaTimes className="font-sm red-text" />
                                                                                                                                    }
                                                                                                                                </td>
                                                                                                                                <td className="d-flex align-items-center justify-content-center area-35">
                                                                                                                                    {
                                                                                                                                        receiver.isAudioRcv ?
                                                                                                                                            <FaCircle className="font-sm green-text" /> :
                                                                                                                                            <FaTimes className="font-sm red-text" />
                                                                                                                                    }
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        ))
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table> :
                                                                                                            <span className="small grey-text">정보 없음</span>
                                                                                                        /* <>
                                                                                                            <span className="area-30">{info.receiver.userSeq}</span>
                                                                                                            <span className="text-center area-35">
                                                                                                                {
                                                                                                                    info.receiver.isVideoRcv ?
                                                                                                                        <FaCircle className="font-sm green-text" /> :
                                                                                                                        <FaTimes className="font-sm red-text" />
                                                                                                                }
                                                                                                            </span>
                                                                                                            <span className="text-center area-35">
                                                                                                                {
                                                                                                                    info.receiver.isAudioRcv ?
                                                                                                                        <FaCircle className="font-sm green-text" /> :
                                                                                                                        <FaTimes className="font-sm red-text" />
                                                                                                                }
                                                                                                            </span>
                                                                                                        </> : 
                                                                                                        <span className="small grey-text">정보 없음</span> */
                                                                                                    }
                                                                                                </UncontrolledCollapse>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </table> :
                                                                    <span className="grey-text">Sender 정보가 없습니다.</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )
                                        )
                                    }
                                </table>
                            </UncontrolledCollapse> :
                            <div className="view m-3 text-center grey-text">
                                실행 중인 클래스가 없습니다.
                            </div>
                    }
                </div>
            </div>
            <div className="card p-2 mt-5">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <span className="d-flex white-text mx-1">
                        <FaInfoCircle className="font-lg mr-2" aria-hidden="true" />Room Info
                    </span>
                    <WebListArrowButton toggle_id={'mediaRoomInfoList'} list_array={lRoomInfo} isOpened={isOpened} />
                </div>
                <div className="px-2">
                    {
                        lRoomInfo !== undefined && lRoomInfo !== null && lRoomInfo.length > 0 ?
                            <UncontrolledCollapse toggler="#mediaRoomInfoList" defaultOpen={true} onEntering={() => setIsOpened(true)} onExiting={() => setIsOpened(false)}>
                                <table className="d-flex flex-column table table-sm flex-list mb-0 w-100">
                                    <thead>
                                        <tr className="d-flex">
                                            <th className="d-flex align-items-center justify-content-center area-15">
                                                <b>LiveSEQ</b>
                                            </th>
                                            <th className="d-flex align-items-center justify-content-center area-15">
                                                <b>Worker</b>
                                            </th>
                                            <th className="d-flex flex-column align-items-center justify-content-center area-55">
                                                <b>Producer</b>
                                                <div className="d-flex pt-2 w-100">
                                                    <span className="area-25">
                                                        <b>UserSEQ</b>
                                                    </span>
                                                    <span className="area-25">
                                                        <b>Video</b>
                                                    </span>
                                                    <span className="area-25">
                                                        <b>Audio</b>
                                                    </span>
                                                    <span className="area-25">
                                                        <b>Mic</b>
                                                    </span>
                                                </div>
                                            </th>
                                            <th className="d-flex area-15"></th>
                                        </tr>
                                    </thead>
                                    {
                                        lRoomInfo.map(
                                            (room, rIdx) => (
                                                <tbody key={"rkey_" + rIdx}>
                                                    <tr className="d-flex">
                                                        <td className="d-flex align-items-center justify-content-center area-15">
                                                            <span><b>{room.liveSeq}</b></span>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center area-15">
                                                            <span><b>{room.workerSeq}</b></span>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center area-55">
                                                            {
                                                                room.producer !== undefined && room.producer !== null ?
                                                                    <>
                                                                        <span className="text-center area-25">
                                                                            <b>{room.producer.userSeq}</b>
                                                                        </span>
                                                                        <span className="text-center area-25">
                                                                            {
                                                                                room.producer.isVideoSend ?
                                                                                    <FaCircle className="font-sm green-text" /> :
                                                                                    <FaTimes className="font-sm red-text" />
                                                                            }
                                                                        </span>
                                                                        <span className="text-center area-25">
                                                                            {
                                                                                room.producer.isAudioSend ?
                                                                                    <FaCircle className="font-sm green-text" /> :
                                                                                    <FaTimes className="font-sm red-text" />
                                                                            }
                                                                        </span>
                                                                        <span className="text-center area-25">
                                                                            {
                                                                                room.producer.isMicSend ?
                                                                                    <FaCircle className="font-sm green-text" /> :
                                                                                    <FaTimes className="font-sm red-text" />
                                                                            }
                                                                        </span>
                                                                    </> :
                                                                    <span className="small grey-text">정보 없음</span>
                                                            }
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center area-15">
                                                            <button type="button" className="btn btn-sm btn-outline-primary px-2 py-1 m-0" id={"show_" + rIdx + "_subRoom"}>
                                                                조회
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="d-flex">
                                                        <td className="d-flex w-100">
                                                            <UncontrolledCollapse toggler={"#show_" + rIdx + "_subRoom"} defaultOpen={false} style={{ width: '100%' }}>
                                                                <table className="d-flex flex-column table-sm sub-list mb-0">
                                                                    <thead>
                                                                        <tr className="d-flex">
                                                                            <th className="d-flex align-items-center justify-content-center area-30">
                                                                                <b>RoomCode</b>
                                                                            </th>
                                                                            <th className="d-flex align-items-center justify-content-center area-70">
                                                                                <b>Consumer</b>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            room.subRoom_info !== undefined && room.subRoom_info !== null && room.subRoom_info.length > 0 ?
                                                                                room.subRoom_info.map(
                                                                                    (subRoom, sIdx) => (
                                                                                        <tr className="d-flex" key={rIdx + "-subRoom-" + sIdx}>
                                                                                            <td className="d-flex align-items-center justify-content-center area-30">
                                                                                                <b>{subRoom.roomCode}</b>
                                                                                                    <FaPodcast className={"font-md ml-1" + (subRoom.isStarted ? " green-text" : " grey-text")} />
                                                                                            </td>
                                                                                            <td className="d-flex align-items-center justify-content-center area-70">
                                                                                                {
                                                                                                    subRoom.consumers !== undefined && subRoom.consumers !== null && subRoom.consumers.length > 0 ?
                                                                                                        <table className="d-flex flex-column table-sm sub-list mediasoup table-hover mb-0 w-100">
                                                                                                            <thead>
                                                                                                                <tr className="d-flex">
                                                                                                                    <th className="area-25">UserSEQ</th>
                                                                                                                    <th className="area-25">VideoRcv</th>
                                                                                                                    <th className="area-25">AudioRcv</th>
                                                                                                                    <th className="area-25">MicRcv</th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                {
                                                                                                                    subRoom.consumers.map(
                                                                                                                        (consumer, cIdx) => (
                                                                                                                            <tr className="d-flex" key={"subRoom-" + sIdx + "-cs-" + cIdx}>
                                                                                                                                <td className="d-flex align-items-center justify-content-center area-25">
                                                                                                                                    {consumer.userSeq}
                                                                                                                                </td>
                                                                                                                                <td className="d-flex align-items-center justify-content-center area-25">
                                                                                                                                    {
                                                                                                                                        consumer.isVideoRcv ?
                                                                                                                                            <FaCircle className="font-sm green-text" /> :
                                                                                                                                            <FaTimes className="font-sm red-text" />
                                                                                                                                    }
                                                                                                                                </td>
                                                                                                                                <td className="d-flex align-items-center justify-content-center area-25">
                                                                                                                                    {
                                                                                                                                        consumer.isAudioRcv ?
                                                                                                                                            <FaCircle className="font-sm green-text" /> :
                                                                                                                                            <FaTimes className="font-sm red-text" />
                                                                                                                                    }
                                                                                                                                </td>
                                                                                                                                <td className="d-flex align-items-center justify-content-center area-25">
                                                                                                                                    {
                                                                                                                                        consumer.isMicRcv ?
                                                                                                                                            <FaCircle className="font-sm green-text" /> :
                                                                                                                                            <FaTimes className="font-sm red-text" />
                                                                                                                                    }
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        )
                                                                                                                    )
                                                                                                                }
                                                                                                            </tbody>
                                                                                                        </table> :
                                                                                                        <span className="grey-text">Consumer 정보가 없습니다.</span>
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                ) :
                                                                                <tr className="d-flex">
                                                                                    <td>
                                                                                        <span className="grey-text">
                                                                                            SubRoom 정보가 없습니다.
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </UncontrolledCollapse>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )
                                        )
                                    }
                                </table>
                            </UncontrolledCollapse> :
                            <div className="view m-3 text-center grey-text">
                                진행 중인 방송이 없습니다.
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}
export default WebMediaConfig;