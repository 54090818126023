import React, { useState, useEffect } from 'react';

import ConstData from '../../lib/ConstData';
import Alerts from '../etc/list/Alerts';

const WebAddLiteServer = ({ selectedSubDomainInfo, isAddLiteServerOK, alertMessage, alertKind, handleSelectedFunc, onChangeIsAddLiteServerOK }) => {

    const [localServerMemo, setLocalServerMemo] = useState("");

    const [lAlertMessage, setAlertMessage] = useState("");
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    useEffect(() => {
        if (isAddLiteServerOK) {
            setLocalServerMemo("");
            onChangeIsAddLiteServerOK();
        }
    }, [isAddLiteServerOK]);

    const onChangeLocalServerMemo = (e) => {
        setLocalServerMemo(e.target.value);
    }
    
    const onClickAddLocalServer = (e) => {
        handleSelectedFunc({ selectedFunc: 'CREATE_LITE_SERVER', data: { liteServerMemo: localServerMemo, subDomainSeq: selectedSubDomainInfo.subDomainSeq }});
    }

    return (
        <div className="d-flex py-3 align-items-center justify-content-center border-bottom">
            <div className="card web-list-card border-primary">
                <div className="input-area">
                    <div className="input-label font-md">
                        <span><b>로컬 서버 메모</b></span>
                    </div>
                    <div className="input-data">
                        <div className="d-flex input-group justify-content">
                            <input type="email" id="inputLocalServerMemo" value={localServerMemo} className="d-flex flex-fill web-search-input mr-2" placeholder="로컬 서버 메모를 입력하세요" style={{width:'70%'}} onChange={onChangeLocalServerMemo} />
                        </div>
                    </div>
                </div>
                <Alerts kind={lAlertKind} message={lAlertMessage} />
                <div className="d-flex text-center mt-3">
                    <button type="button" className="btn btn-md hiclasstv-blue white-text m-0 px-4 py-1" onClick={onClickAddLocalServer}>
                        <b>생성</b>
                    </button>
                </div>
            </div>
        </div>
    );
}
export default WebAddLiteServer;