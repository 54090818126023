import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';

import CustomProfileImage from '../../user/profile/CustomProfileImage';

const WebUserLoginResetAlertModal = ({ isModalOpened, selectedMemberInfo, toggleModalOpened, onClickResetUserStatus, alertKind, alertMessage }) => {

    const [memberInfo, setMemberInfo] = useState(selectedMemberInfo);
    const [lAlertMessage, setAlertMessage] = useState(alertMessage);
    const [lAlertKind, setAlertKind] = useState(alertKind);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    return (
        <Modal isOpen={isModalOpened} centered backdrop={true}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        <span>
                            해당 멤버의 로그인 상태를 초기화하시겠습니까?<br />
                            로그인 상태가 초기화될 경우<br />
                            해당 멤버는 재로그인 후 정상적으로 이용 가능합니다.
                        </span>
                    </div>
                    <div className="content-area dark-grey-text">
                        <span className="small mb-1">[초기화할 멤버]</span>
                        <div className="d-flex align-items-center justify-content-center area-80">
                            <div className="area-15 fit-content">
                                <CustomProfileImage url={memberInfo.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={memberInfo.userNickname} />
                            </div>
                            <div className="text-area text-center">
                                <span className="hiclass-text" title={memberInfo.userNickname}>
                                    <b>{memberInfo.userNickname}</b>
                                </span>
                            </div>    
                        </div>
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <button type="button" className="btn red white-text mr-1 py-1 px-2" onClick={() => onClickResetUserStatus(memberInfo.mode, memberInfo.subDomainSeq, memberInfo.userSeq)}>예</button>
                        <button type="button" className="btn green white-text ml-1 py-1 px-2" onClick={() => toggleModalOpened(false)}>아니오</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default WebUserLoginResetAlertModal;