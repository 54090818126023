import React from 'react';

import Item from './item/Item';

const InkMediaCard = ({ cardInfo }) => {
    return (
        cardInfo.data !== undefined && cardInfo.data !== null && cardInfo.data.length > 0 ?
            <div>
                {
                    cardInfo.data.map((itemInfo, idx) => (
                        <Item key={'card-' + idx} info={itemInfo} />
                    ))
                }
            </div> :
            <></>
    );
}

export default InkMediaCard;