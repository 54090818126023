import React, { Component } from 'react';
import { connect } from 'react-redux';

import MyWatchingLiveListContainer from './live/MyWatchingLiveListContainer';

import LiveAlertModalExpiredPass from '../components/live/modal/LiveAlertModalExpiredPass';

import {
    joinLive,
    applyLive,
    reapplyLive,
    certifyCode,
    cancelCertifyCode,
    closeLiveAlertModal,
    toggle_modalLiveAlert,
    requestJoinMixedClassLive
} from '../modules/live';

// live 목록은  web에서만 보입니다.
class WatcingLiveListContainer extends Component {

    handleSelectedFunc = ({ selectedFunc, liveSeq, liveName, liveCode, list_member, memberSeq, mixedClassSeq }) => {
        switch (selectedFunc) {
            case "GO_TO_LIVE_PAGE":
                this.props.routeProps.history.push(`/live/${liveSeq}`);
                break;

            case "SEARCH":
                this.props.certifyCode({
                    liveCode,
                    userSeq: this.props.userSeq,
                    mode: "web"
                });
                break;

            case "APPLY":
                this.props.applyLive({
                    liveSeq,
                    userSeq: this.props.userSeq,
                    mode: "web"
                });
                break;

            case "JOIN":
                if (this.props.isUsablePass && this.props.userKind !== "hiclasstv") {
                    this.props.toggle_modalLiveAlert({
                        liveSeq,
                        liveName
                    });
                } else {
                    console.log(`isUsablePass[${this.props.isUsablePass}]`);
                    if (!this.props.isUsablePass) {
                        console.log("WatcingLiveListContainer - 이건 무슨 경우인가요..?ㅁ?.. 아마도.. teacher 이용권 없어요.../ㅁ/...?");
                    }

                    if (mixedClassSeq >= 0) {
                        this.props.requestJoinMixedClassLive({
                            liveSeq,
                            liveName,
                            userSeq: this.props.userSeq,
                            userNickname: this.props.userNickname,
                            mixedClassSeq
                        });
                    } else {
                        this.props.joinLive({
                            liveSeq,
                            liveName,
                            userSeq: this.props.userSeq,
                            userNickname: this.props.userNickname
                        });
                    }
                }
                break;

            case "RE_APPLY":
                this.props.reapplyLive({
                    liveSeq,
                    userSeq: this.props.userSeq,
                    mode: "web"
                });
                break;

            case "CANCEL":
                this.props.cancelCertifyCode({ selectedFunc });
                break;

            case "LIVE_ALERT_TOGGLE":
                this.props.closeLiveAlertModal();
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <>
                <LiveAlertModalExpiredPass
                    userKind={this.props.userKind}
                    live_info={this.props.modalAlertLiveInfo}
                    isLiveAlertModalExpiredPass={this.props.isLiveAlertModalExpiredPassOpened}
                    handleSelectedFunc={this.handleSelectedFunc}
                />
                <MyWatchingLiveListContainer
                    routeProps={this.props.routeProps}
                    handleSelectedFunc={this.handleSelectedFunc}
                />
            </>
        );
    }
}

export default connect(
    ({ user, live }) => ({
        userSeq                             : user.userSeq,
        userNickname                        : user.userNickname,
        userKind                            : user.userKind,

        isUsablePass                        : live.performLiveInfo.isUsablePass,
        modalAlertLiveInfo                  : live.modalAlertLiveInfo,
        isLiveAlertModalExpiredPassOpened   : live.isLiveAlertModalExpiredPassOpened
    }),
    {
        joinLive,
        applyLive,
        reapplyLive,
        certifyCode,
        cancelCertifyCode,
        closeLiveAlertModal,
        toggle_modalLiveAlert,
        requestJoinMixedClassLive
    }
)(WatcingLiveListContainer);