import React, { useState, useEffect }  from "react";
import { Modal } from "reactstrap";
import { IoMdClose } from "react-icons/io";

const ModifyAlertModal = ({ isModalOpened, handleAction_info, handleModifyAlertModal }) => {
    const [lQuizTitle, setQuizTitle] = useState();

    useEffect(() => {
        if (handleAction_info !== undefined && handleAction_info !== null) {
            if (handleAction_info.save_info !== undefined && handleAction_info.save_info !== null) {
                if (handleAction_info.save_info.quizTitle && handleAction_info.save_info.quizTitle !== '') {
                    console.log('handleAction_info.save_info.quizTitle => ', handleAction_info.save_info.quizTitle);
                    setQuizTitle(handleAction_info.save_info.quizTitle);
                }
            }
        }
    }, [handleAction_info]);

    return (
        <Modal isOpen={isModalOpened} size="sm" wrapClassName="quiz-modal" centered backdrop={false}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" onClick={() => handleModifyAlertModal(false)}>
                    <IoMdClose />
                </span>
            </div>
            <div className="d-flex flex-column align-items-center w-100">
                <div className="d-flex text-center my-2 dark-grey-text small">
                    퀴즈를 수정한 내용으로 저장할 경우<br />
                    기존 내용으로 복구할 수 없게 됩니다.<br />
                    퀴즈를 저장하시겠습니까?
                </div>
                <div className="content-area dark-grey-text mt-2 mb-0">
                    <span className="small mb-1">[퀴즈제목]</span>
                    <div className="text-area text-center area-80">
                        <span className="hiclass-text" title={lQuizTitle}>
                            <b>{lQuizTitle}</b>
                        </span>
                    </div>
                </div>
                <div className="d-flex justify-content-center my-2">
                    <button type="button" className="btn modal-btn modal-btn-success" onClick={() => handleModifyAlertModal(true)}>
                        예
                    </button>
                    <button type="button" className="btn modal-btn modal-btn-danger" onClick={() => handleModifyAlertModal(false)}>
                        아니오
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModifyAlertModal;