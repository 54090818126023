import React from 'react';

import WebSideMenu from '../../components/etc/menu/WebSideMenu';
import WebFooter from '../../components/etc/WebFooter';

const WebContentTemplate = ({ children, currentPageType, userKind, isUsablePass, isConfigAdmin, isCreateAdmin, isStandardType, goToAloneChatting }) => {
    
    return (
        <>
            <div className="d-flex" style={{ overflow: 'auto' }}>
                <WebSideMenu
                    currentPageType={currentPageType}
                    isStandardType={isStandardType}
                    userKind={userKind}
                    isConfigAdmin={isConfigAdmin}
                    isCreateAdmin={isCreateAdmin}
                    isUsablePass={isUsablePass}
                    goToAloneChatting={goToAloneChatting}
                />
                <div className="content-template-web">
                    {children}
                </div>
            </div>
            <WebFooter />
        </>
    );
}

export default WebContentTemplate;