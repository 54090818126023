/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';

const QnA010 = ({ isOpened, onChangeOpenedFlag }) => {
    useEffect(() => {
        let content = document.getElementById("qna10");

        if (isOpened) {
            content.style.maxHeight = content.scrollHeight + "px";
        } else {
            content.style.maxHeight = null;
        }
    }, [isOpened]);

    return (
        <div className="accordion">
            <div className="accordion-title" onClick={() => onChangeOpenedFlag(9)}>
                <span className="hiclasstv-deepDarkgrey-text button">
                    <b>10. 어떤 형태로 비대면 원격 교육을 하게 되나요?</b>
                </span>
            </div>
            <div className="accordion-content hiclasstv-darkgrey-text" id="qna10">
                <span className="d-flex" style={{ minWidth: 'max-content' }}>A.&nbsp;</span>
                <p>
                    HiClassTV는 다양한 교육 환경을 수용합니다.<br />
                    교실과 교실, 회의실과 회의실, 개인과 개인, 개인과 그룹 등, 교육 환경에서 발생할 수 있는 경우를 지원합니다.<br />
                    그러므로 비대면 회의 및 강의를 할 수 있고 온/오프라인 구분 없는 가상 직무 교육, 가상 교실 수업을 수행할 수 있습니다.
                </p>
            </div>
        </div>
    );
}
export default QnA010;