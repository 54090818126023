import ConstData from "./ConstData";

import { callCurrentFuncProc, getCurrentFuncProc } from "./rbmq/rcvMsgFromRbmq";
import { sendCommandToRbmq, sendObjectToRbmq, sendP2PSoundOn } from "./RabbitmqClient";
import { clearAudio, clearVideo } from "./HandsUpClient";

import { changeLiveStatusInfo, toggleReadyLiveConsume } from "../modules/live";
import { mqRcvMediaInfo } from "../modules/admin";

import { InvalidStateError, UnsupportedError } from "mediasoup-client/lib/errors";

const mediasoup = require("mediasoup-client");

let clientInfo = {
    rcvTeacherSeq           : -1,
    liveSeq                 : -1,
    userSeq                 : -1,
    userSeqArr              : [],
    groupSeq                : -1,

    routingKey              : "",
    routingKeyArr           : [],

    device                  : null,
    rtpCapabilities         : null,

    sendTransportStatus     : "ended",

    sendTransport           : null,
    rcvTransport            : null,

    sendTransportCallback   : null,
    sendTransportErrback    : null,
    rcvTransportCallback    : null,
    rcvTransportErrback     : null,

    isForSmartTV            : false,

    produceInfo             : {
        videoProducer       : null,
        audioProducer       : null,

        videoStatus         : "ended",
        audioStatus         : "ended",

        videoTrack          : null,
        audioTrack          : null,
    },

    consumeInfo             : {
        isReceived          : false,

        isVideoReceiveable  : false,
        isAudioReceiveable  : false,
        isMicReceiveable    : false,
        
        isVideoReceived     : false,
        isAudioReceived     : false,
        isMicReceived       : false,

        isAudioStop         : false,
        isMuteAudio         : true,
        isMuteMic           : true, // default
        isSelectedMode      : false, // handsup 체크 or p2p 선택되었는지 여부

        isExitConsume       : false,

        mediaKind           : null,

        videoConsumer       : null,
        audioConsumer       : null,
        micConsumer         : null,

        testInterval        : null,

        videoEl             : null,
        audioEl             : null,
        micEl               : null,

        videoStream         : null,
        audioStream         : null,
        micStream           : null,

        audioVolume         : 10,
        micVolume           : 0
    },

    handsupInfo             : {
        mediaKind           : null,
        list_p2pConsumeInfo : [],
        list_consumeInfo    : []
    },

    // videoBand               : {
    //     // width               : 240,  // default value
    //     // height              : 135,  // default value
    //     // frameRate           : 5,    // default value
    //     // cameraMaxWidth      : 640,  // default camera max value
    //     // cameraMaxHeight     : 360,  // default camera max value
    //     // cameraMaxFrameRate  : 16,   // default camera max value
    //     // // screenMaxWidth      : 1280, // default screen max value
    //     // // screenMaxHeight     : 720,  // default screen max value
    //     // screenMaxWidth      : 1920, // default screen max value
    //     // screenMaxHeight     : 1080, // default screen max value
    //     // screenMaxFrameRate  : 24,   // default screen max value

    //     default             : {
    //         width           : 240,  // default value
    //         height          : 135,  // default value
    //         frameRate       : 5,    // default value
    //     },
    //     cameraMax           : {
    //         width           : 640,  // default camera max value
    //         height          : 360,  // default camera max value
    //         frameRate       : 16,   // default camera max value
    //     },
    //     screenMax           : {
    //         // width           : 1280, // default screen max value
    //         // height          : 720,  // default screen max value
    //         width           : 1920, // default screen max value
    //         height          : 1080, // default screen max value
    //         frameRate       : 24,   // default screen max value
    //     },
    //     smartTVDefault      : {
    //         width           : 320,  // default value
    //         height          : 180,  // default value
    //         frameRate       : 5,    // default value
    //     }
    // },
};

let videoBand = {
    // width               : 240,  // default value
    // height              : 135,  // default value
    // frameRate           : 5,    // default value
    // cameraMaxWidth      : 640,  // default camera max value
    // cameraMaxHeight     : 360,  // default camera max value
    // cameraMaxFrameRate  : 16,   // default camera max value
    // // screenMaxWidth      : 1280, // default screen max value
    // // screenMaxHeight     : 720,  // default screen max value
    // screenMaxWidth      : 1920, // default screen max value
    // screenMaxHeight     : 1080, // default screen max value
    // screenMaxFrameRate  : 24,   // default screen max value

    default             : {
        width           : 240,  // default value
        height          : 135,  // default value
        frameRate       : 5,    // default value
    },
    cameraMax           : {
        width           : 640,  // default camera max value
        height          : 360,  // default camera max value
        frameRate       : 16,   // default camera max value
    },
    screenMax           : {
        width           : 1280, // default screen max value
        height          : 720,  // default screen max value
        // width           : 1920, // default screen max value
        // height          : 1080, // default screen max value
        // width           : 3840, // default screen max value
        // height          : 2160, // default screen max value
        frameRate       : 16,   // default screen max value
        // frameRate       : 24,   // default screen max value
    },
    smartTVDefault      : {
        width           : 640,  // default value
        height          : 360,  // default value
        frameRate       : 5,    // default value
    }
};

/**
 * client 정보 설정
 * @param {Number} userSeq 
 * @param {String} routingKey 
 */
export const initClientInfo = (userSeq, routingKey) => {
    clientInfo.userSeq = userSeq;
    clientInfo.routingKey = routingKey;
}

/**
 * client live 정보 설정
 * @param {Number} rcvSeq   = teacherSeq 
 * @param {Number} liveSeq 
 */
export const initLiveInfo = (rcvSeq, liveSeq) => {
    clientInfo.rcvTeacherSeq = rcvSeq;
    clientInfo.liveSeq = liveSeq;
    clientInfo.audioVolume = 10;
    clientInfo.micVolume = 0;

    if (clientInfo.consumeInfo.videoConsumer !== null) {
        // console.log("clientInfo.consumeInfo.videoConsumer not null");
        clientInfo.consumeInfo.videoConsumer = null;
    }

    if (clientInfo.consumeInfo.videoStream !== null) {
        // console.log("clientInfo.consumeInfo.videoStream not null");
        clientInfo.consumeInfo.videoStream = null;
        clientInfo.consumeInfo.isVideoReceived = false;
    }

    if (clientInfo.consumeInfo.audioConsumer !== null) {
        // console.log("clientInfo.consumeInfo.audioConsumer not null");
        clientInfo.consumeInfo.audioConsumer = null;
    }

    if (clientInfo.consumeInfo.audioStream !== null) {
        // console.log("clientInfo.consumeInfo.audioStream not null");
        clientInfo.consumeInfo.audioStream = null;
        clientInfo.consumeInfo.isAudioReceived = false;
    }

    if (clientInfo.consumeInfo.micConsumer !== null) {
        // console.log("clientInfo.consumeInfo.micConsumer not null");
        clientInfo.consumeInfo.micConsumer = null;
    }

    if (clientInfo.consumeInfo.micStream !== null) {
        // console.log("clientInfo.consumeInfo.micStream not null");
        clientInfo.consumeInfo.micStream = null;
        clientInfo.consumeInfo.isMicReceived = false;
    }

    if (clientInfo.rcvTransport !== null) {
        // console.log("clientInfo.rcvTransport not null");
        clientInfo.rcvTransport = null;
        // console.log("clientInfo.rcvTransport = null");
    }

    if (clientInfo.sendTransport !== null) {
        // console.log("clientInfo.sendTransport not null");
        clientInfo.sendTransport = null;
        // console.log("clientInfo.sendTransport = null");
    }
}

/**
 * smarttv 정보 설정
 * @param {Number} userSeq 
 * @param {String} routingKey 
 */
export const initClientInfoForSmartTV = (userSeq, routingKey) => {
    console.log(`initGroupSmartTVInfo - userSeq[${userSeq}], routingKey[${routingKey}]`);
    clientInfo.userSeq = userSeq;
    clientInfo.routingKey = routingKey;

    initSubscribeHandsUpConsumeInfo(userSeq, "video");
}

/**
 * smarttv group 정보 설정
 * @param {Number} groupSeq 
 * @param {Array} list_member 
 * @param {Array} routingKeyArr 
 */
export const initClientInfoForGroupSmartTV = (groupSeq, list_member, routingKeyArr) => {
    console.log(`initClientInfoForGroupSmartTV - groupSeq[${groupSeq}], list_member => `, list_member, ", routingKeyArr => ", routingKeyArr);
    clientInfo.groupSeq = groupSeq;
    clientInfo.userSeqArr = list_member;
    clientInfo.routingKeyArr = routingKeyArr;
    clientInfo.handsupInfo.list_consumeInfo = []; // 재접속을 위해 값 초기화
    clientInfo.handsupInfo.list_p2pConsumeInfo = []; // 재접속을 위해 값 초기화

    if (clientInfo.userSeqArr && clientInfo.userSeqArr.length > 0) {
        for (let i = 0; i < clientInfo.userSeqArr.length; i++) {
            let user_info = clientInfo.userSeqArr[i];
            initSubscribeHandsUpConsumeInfo(user_info.userSeq, "video");
        }
    }
}

/**
 * smarttv group 멤버 별 videoEl, audioEl 세팅해주기 (?)
 * @param {Number} uSeq 
 * @param {Element} videoEl 
 * @param {Element} audioEl 
 */
export const setElementForGroupSmartTV = (userSeq, videoEl, audioEl) => {
    console.log(`setElementForGroupSmartTV - userSeq[${userSeq}]`, videoEl, audioEl);
    const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === userSeq);
    if (consumeInfo) {
        console.log("setElementForGroupSmartTV case 1");
        consumeInfo.videoEl = videoEl;
        consumeInfo.audioEl = audioEl;

        sendJoinConsume("handsup", userSeq);
    } else {
        const p2pInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === userSeq);
        if (p2pInfo) {
            console.log("setElementForGroupSmartTV case 2");
            p2pInfo.videoEl = videoEl;
            p2pInfo.audioEl = audioEl;

            sendJoinConsume("handsup", userSeq);
        } else {
            console.log("setElementForGroupSmartTV case 3 ... 이럴땐 어떻게 해야..?ㅁ?");
        }
    }
}

/**
 * 소그룹 멤버 별 videoEl 세팅해주기 (?)
 * @param {Number} uSeq 
 * @param {Element} videoEl 
 * @param {Element} audioEl 
 */
export const setElementForSmallGroup = (uSeq, videoEl, audioEl) => {
    console.log(`setElementForSmallGroup - uSeq[${uSeq}], videoEl, audioEl => `, videoEl, audioEl);
    const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === uSeq);
    if (consumeInfo) {
        console.log(`setElementForSmallGroup - uSeq[${uSeq}] case 1`, videoEl, audioEl);
        consumeInfo.videoEl = videoEl;
        // consumeInfo.audioEl = audioEl;

        sendJoinConsume("handsup", uSeq);
    } else {
        const p2pInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === uSeq);
        if (p2pInfo) {
            console.log(`setElementForSmallGroup - uSeq[${uSeq}] case 2`, videoEl, audioEl);
            p2pInfo.videoEl = videoEl;
            // p2pInfo.audioEl = audioEl;

            sendJoinConsume("handsup", uSeq);
        } else {
            console.log(`setElementForSmallGroup - uSeq[${uSeq}] case 3`, videoEl, audioEl);
        }
    }
}

/**
 * handsup consume 정보 클리어
 * @param {Object} info 
 * @returns {Promise<Boolean | Error>}
 */
const clearHandsUpInfo = (info) => {
    return new Promise((resolve, reject) => {
        try {
            if (info.audioConsumer) {
                if (info.audioStream) {
                    info.audioStream.removeTrack(info.audioConsumer.track);
                    info.audioStream = null;
                }

                if (!info.audioConsumer.closed) {
                    info.audioConsumer.close();
                }

                info.audioConsumer = null;
            }

            info.audioStream = null;

            if (info.audioEl) {
                info.audioEl.pause();

                if (info.audioEl.parentNode) {
                    info.audioEl.parentNode.removeChild(info.audioEl);
                }

                info.audioEl = null;
            }

            resolve(true);
        } catch (err) {
            console.log("clearHandsUpInfo() - err => ", err);
            reject(err);
        }
    });
}

/**
 * 모든 handsup consume 정보 클리어
 * @returns {Promise<Boolean | Error>}
 */
export const initHandsUpConsumeInfo = () => {
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.handsupInfo.list_consumeInfo && clientInfo.handsupInfo.list_consumeInfo.length > 0) {
                const promises = clientInfo.handsupInfo.list_consumeInfo.map((info) => clearHandsUpInfo(info));
                await Promise.all(promises);

                clientInfo.handsupInfo.list_consumeInfo = [];
            }

            if (clientInfo.handsupInfo.list_p2pConsumeInfo && clientInfo.handsupInfo.list_p2pConsumeInfo.length > 0) {
                const promises = clientInfo.handsupInfo.list_p2pConsumeInfo.map((info) => clearHandsUpInfo(info));
                await Promise.all(promises);

                clientInfo.handsupInfo.list_p2pConsumeInfo = [];
            }
            resolve(true);
        } catch (err) {
            console.log(`initHandsUpConsumeInfo - err => `, err);
            resolve(false);
        }
    });
}

/**  MEDIA SOUP */
/**
 * Create a new Device to connect to mediasoup server.
 * device가 null일 경우 서버와 연결할 수 없음
 * @returns {Promise<Boolean>}
 * @private 
 */
const loadDevice = async () => {
    return new Promise(async (resolve, reject) => {
        if (clientInfo.device === undefined || clientInfo.device === null) {
            try {
                clientInfo.device = new mediasoup.Device();
            } catch (err) {
                console.log("(1)loadDevice() - err => ", err);
                if (err.name === "UnsupportedError") {
                    console.log("(1)browser not supported mediasoup client device");
                }

                reject(err);
            } finally {
                console.log("(1)loadDevice clientInfo.rtpCapabilities => ", clientInfo.rtpCapabilities);
                // 이 부분이 기존에는 왜 없었지...?
                if (clientInfo.device !== undefined && clientInfo.device !== null) {
                    await clientInfo.device.load({ routerRtpCapabilities: clientInfo.rtpCapabilities });
                }

                resolve(true);
            }
        } else {
            if (!clientInfo.device.loaded) {
                console.log("loadDevice - device not loaded");
                console.log("(2)loadDevice clientInfo.rtpCapabilities => ", clientInfo.rtpCapabilities);
                try {
                    await clientInfo.device.load({ routerRtpCapabilities: clientInfo.rtpCapabilities });
                } catch (err) {
                    console.log("(2)loadDevice() - err => ", err);
                    if (err.name === "UnsupportedError") {
                        console.log("(2)browser not supported mediasoup client device");
                    }

                    reject(err);
                }
            }

            resolve(true);
        }
    });
}

/**  MEDIA SOUP (consume) */
/**
 * live subscribe (=consume) 요청 (기본은 video 부터 consume 요청)
 * @param {HTMLVideoElement} vElm 
 * @param {HTMLAudioElement} aElm 
 * @param {HTMLAudioElement} mElm 
 */
export const subscribe = async (vElm, aElm, mElm) => {
    try {
        //console.log("subscribe---000");
        clientInfo.consumeInfo.videoEl = vElm;
        clientInfo.consumeInfo.videoStream = null;

        clientInfo.consumeInfo.audioEl = aElm;
        clientInfo.consumeInfo.audioStream = null;

        clientInfo.consumeInfo.micEl = mElm;
        clientInfo.consumeInfo.micStream = null;

        await loadDevice();

        if (clientInfo.consumeInfo.isVideoReceiveable) {
            clientInfo.consumeInfo.mediaKind = "video";
            if (clientInfo.rcvTransport === null) {
                sendCreateConsumerTransport(clientInfo.rcvTeacherSeq);
            } else {
                sendConsume("video", "live", clientInfo.rcvTeacherSeq);
            }
        } else if (clientInfo.consumeInfo.isAudioReceiveable) {
            if (clientInfo.consumeInfo.isAudioStop) {
                clientInfo.consumeInfo.mediaKind = null;
                let store = window.hiclasstv.store;
                store.dispatch(changeLiveStatusInfo({ kind: "audio", status: "off" }));
            } else {
                clientInfo.consumeInfo.mediaKind = "audio";
                if (clientInfo.rcvTransport === null) {
                    sendCreateConsumerTransport(clientInfo.rcvTeacherSeq);
                } else {
                    sendConsume("audio", "live", clientInfo.rcvTeacherSeq);
                }
                //let store = window.hiclasstv.store;
                //store.dispatch(changeLiveStatusInfo({ kind: "audio", status: "on" }));
            }
        } else if (clientInfo.consumeInfo.isMicReceiveable) {
            clientInfo.consumeInfo.mediaKind = "mic";
            if (clientInfo.rcvTransport === null) {
                sendCreateConsumerTransport(clientInfo.rcvTeacherSeq);
            } else {
                sendConsume("mic", "live", clientInfo.rcvTeacherSeq);
            }
            //let store = window.hiclasstv.store;
            //store.dispatch(changeLiveStatusInfo({ kind: "mic", status: "on" }));
        }
    } catch (err) {
        console.log("subscribe(loadDevice) - err => ", err);
        if (err instanceof UnsupportedError) {
            alert(`[Unsupported] can not subscribe(loadDevice) ... because ${err.message}`);
        } else if (err instanceof InvalidStateError) {
            alert(`[InvalidState] can not subscribe(loadDevice) ... because ${err.message}`);
        } else if (err instanceof TypeError) {
            alert(`[TypeError] can not subscribe(loadDevice) ... because ${err.message}`);
        } else {
            alert(`[Error] can not subscribe(loadDevice) ... because ${err.message}`);
        }
    }
}

/**
 * live audio subscribe (=consume) 요청
 */
export const subscribeAudio = async () => {
    try {
        await loadDevice();

        if (clientInfo.consumeInfo.isAudioReceiveable) {
            if (clientInfo.rcvTransport === null) {
                clientInfo.consumeInfo.mediaKind = "audio";
                sendCreateConsumerTransport(clientInfo.rcvTeacherSeq);
            } else {
                sendConsume("audio", "live", clientInfo.rcvTeacherSeq);
            }
        }
    } catch (err) {
        console.log("subscribeAudio(loadDevice) - err => ", err);
        if (err instanceof UnsupportedError) {
            alert(`[Unsupported] can not subscribeAudio(loadDevice) ... because ${err.message}`);
        } else if (err instanceof InvalidStateError) {
            alert(`[InvalidState] can not subscribeAudio(loadDevice) ... because ${err.message}`);
        } else if (err instanceof TypeError) {
            alert(`[TypeError] can not subscribeAudio(loadDevice) ... because ${err.message}`);
        } else {
            alert(`[Error] can not subscribeAudio(loadDevice) ... because ${err.message}`);
        }
    }
}

/**
 * live unsubscribe 요청
 * isForSoundOnly가 true일 경우 mic/audio 만 unsubscribe
 * isForSoundOnly가 false일 경우 mic/audio/video 모두 unsubscribe
 * @param {Boolean} isForSoundOnly 
 * @param {Boolean} isExitConsume 
 * @param {Boolean} isForSmartTV
 */
export const unsubscribe = (isForSoundOnly, isExitConsume, isForSmartTV) => {
    console.log(`unsubscribe - isForSoundOnly[${isForSoundOnly}], isExitConsume[${isExitConsume}]`);
    if (isForSoundOnly) {
        //clientInfo.consumeInfo.isAudioStop = true; // 기존 선생님 소리를 끄는 기능. 현재 사용하지 않으므로 주석처리 by hjkim 201008
        //clientInfo.consumeInfo.isMicStop = true;
    }

    if (isForSmartTV) {
        clientInfo.consumeInfo.isExitConsume = false;
        sendUnsubscribe(false, "live", clientInfo.rcvTeacherSeq);
        unsubscribeAfterForSmartTV();
    } else {
        console.log(`unsubscribe - consumeInfo.isVideoReceived[${clientInfo.consumeInfo.isVideoReceived}], consumeInfo.isAudioReceived[${clientInfo.consumeInfo.isAudioReceived}], consumeInfo.isMicReceived[${clientInfo.consumeInfo.isMicReceived}]`);
        if (clientInfo.consumeInfo.isVideoReceived || clientInfo.consumeInfo.isAudioReceived || clientInfo.consumeInfo.isMicReceived) {
            if (isExitConsume) {
                console.log("unsubscribe case 1");
                clientInfo.consumeInfo.isExitConsume = true;
                sendUnsubscribe(false, "live", clientInfo.rcvTeacherSeq);
            } else {
                console.log("unsubscribe case 2");
                clientInfo.consumeInfo.isExitConsume = false;
                sendUnsubscribe(isForSoundOnly, "live", clientInfo.rcvTeacherSeq);
            }
        } else {
            if (isExitConsume) {
                console.log("unsubscribe case 3");
                clientInfo.consumeInfo.isExitConsume = true;
                sendExitConsume(clientInfo.rcvTeacherSeq);
            }
        }
    }
}

/** MEDIA SOUP (handsUp consume) */
/**
 * for handsup p2p consume control.
 * listen / mute / unsubscribe
 * @param {Number} rcvSeq 
 * @param {Array} list_user 
 * @param {Boolean} isP2PStop 
 * @returns {Promise<Boolean | Error>}
 * @private
 */
const handleHandsUpConsumeForP2P = async (rcvSeq, list_user, isP2PStop) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (list_user && list_user.length > 0) {
                const check_info = list_user.find(uSeq => uSeq === rcvSeq);
                if (check_info === undefined || check_info === null) { // list_user 에 포함되지 않는 경우 unsubscribe/mute.. by hjkim 20220224
                    const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === rcvSeq);
                    if (consumeInfo === undefined || consumeInfo === null) { // audio consume 중이 아닌 case by hjkim 20220224
                        const p2pConsumeInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === rcvSeq);
                        if (p2pConsumeInfo === undefined || p2pConsumeInfo === null) { // p2p consume 중이 아닌 case by hjkim 20220224
                            console.log(`handleHandsUpConsumeForP2P case 1 rcvSeq[${rcvSeq}] not doing`);
                        } else { // list_user 에 포함되지 않는 경우 unsubscribe 한다... by hjkim 20220224
                            console.log(`handleHandsUpConsumeForP2P case 2 rcvSeq[${p2pConsumeInfo.rcvSeq}] unsubscribe`);
                            sendUnsubscribe(true, "handsup", p2pConsumeInfo.rcvSeq);
                        }
                    } else { // audio consume 중이지만 list_user 에 포함되지 않는 경우 mute 한다... by hjkim 20220224
                        console.log(`handleHandsUpConsumeForP2P case 3 rcvSeq[${consumeInfo.rcvSeq}] mute`);
                        consumeInfo.isP2PMode = false;
                        await muteAudioConsume(consumeInfo.rcvSeq);
                    }
                    resolve(true);
                } else { // list_user 에 포함되는 경우 그대로 듣는다.. by hjkim 20220224
                    const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === rcvSeq);
                    if (consumeInfo === undefined || consumeInfo === null) { // audio consume 중이 아닌 case by hjkim 20220224
                        const p2pConsumeInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === rcvSeq);
                        if (p2pConsumeInfo === undefined || p2pConsumeInfo === null) { // p2p consume 중이 아닌 case by hjkim 20220224
                            console.log(`handleHandsUpConsumeForP2P case 4 rcvSeq[${rcvSeq}] not doing`);
                        } else { // 이미 p2p consume 중이던 경우 그대로 듣는다... by hjkim 20220224
                            console.log(`handleHandsUpConsumeForP2P case 5 rcvSeq[${p2pConsumeInfo.rcvSeq}] p2p continue listen`);
                            p2pConsumeInfo.isP2PMode = true;
                        }
                    } else { // audio consume 중이고 list_user 에 포함되는 경우 그대로 listen 한다... by hjkim 20220224
                        console.log(`handleHandsUpConsumeForP2P case 6 rcvSeq[${consumeInfo.rcvSeq}] audio listen`);
                        consumeInfo.isP2PMode = true;
                        await listenAudioConsume(consumeInfo.rcvSeq);
                    }
                    resolve(true);
                }
            } else { // list_user가 아무도 없거나 내가 포함되지 않은 경우 by hjkim 20220224
                const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === rcvSeq);
                if (consumeInfo === undefined || consumeInfo === null) { // audio consume 중이 아닌 case by hjkim 20220224
                    const p2pConsumeInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === rcvSeq);
                    if (p2pConsumeInfo === undefined || p2pConsumeInfo === null) { // p2p consume 중이 아닌 case by hjkim 20220224
                        console.log(`handleHandsUpConsumeForP2P case 7 rcvSeq[${rcvSeq}] not doing`);
                    } else { // 이미 p2p consume 중이던 경우 unsubscribe 한다... by hjkim 20220224
                        console.log(`handleHandsUpConsumeForP2P case 8 rcvSeq[${p2pConsumeInfo.rcvSeq}] unsubscribe`);
                        sendUnsubscribe(true, "handsup", p2pConsumeInfo.rcvSeq);
                    }
                } else { // list_user가 아무도 없거나 내가 포함되지 않은 경우 by hjkim 20220224
                    if (isP2PStop) { // list_user가 아무도 없는 경우 p2p 종료 모드 기존 audio 들을 다시 듣는다 by hjkim 20220224
                        console.log(`handleAudioConsumeForP2P case 9 rcvSeq[${consumeInfo.rcvSeq}] audio listen`);
                        consumeInfo.isP2PMode = false;
                        await listenAudioConsume(consumeInfo.rcvSeq);
                    } else { // 내가 포함되지 않은 경우 audio consume 중이지만 mute 한다... by hjkim 20220224
                        consumeInfo.isP2PMode = false;
                        await muteAudioConsume(consumeInfo.rcvSeq);
                    }
                }
                resolve(true);
            }
        } catch (err) {
            console.log("handleHandsUpConsumeForP2P err => ", err);
            reject(err);
        }
    });
}

/**
 * for handsup p2p consume control.
 * @param {Array} list_user 
 * @param {Boolean} isP2PStop 
 * @returns {Promise<Boolean | Error>}
 */
export const handleP2PConsume = async (list_user, isP2PStop) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.handsupInfo.list_consumeInfo && clientInfo.handsupInfo.list_consumeInfo.length > 0) {
                const promises = clientInfo.handsupInfo.list_consumeInfo.map((info) => handleHandsUpConsumeForP2P(info.rcvSeq, list_user, isP2PStop));
                await Promise.all(promises);
            }

            if (clientInfo.handsupInfo.list_p2pConsumeInfo && clientInfo.handsupInfo.list_p2pConsumeInfo.length > 0) {
                const promises = clientInfo.handsupInfo.list_p2pConsumeInfo.map((info) => handleHandsUpConsumeForP2P(info.rcvSeq, list_user, isP2PStop));
                await Promise.all(promises);
            }
            resolve();
        } catch (err) {
            console.log("handleP2PConsume err => ", err);
            reject(err);
        }
    });
}

/**
 * handsup p2p subscribe (=consume)
 * @param {Number} rcvSeq 
 * @param {Number} idx 
 * @returns {Promise<Boolean>}
 */
export const subscribeHandsUpP2P = async (rcvSeq, idx) => {
    return new Promise(async (resolve, reject) => {
        if (rcvSeq === undefined || rcvSeq === null || rcvSeq < 0) {
            console.log(`------subscribeHandsUpP2P------ rcvSeq[${rcvSeq}]`);
            resolve(true);
        } else {
            const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === rcvSeq);
            if (consumeInfo === undefined || consumeInfo === null) { // 기존 audio consume에 없는 경우이므로 p2p consume check ... by hjkim 20220224
                const p2pConsumeInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === rcvSeq);
                if (p2pConsumeInfo === undefined || p2pConsumeInfo === null) { // 기존 audio, p2p consume에 없는 경우이므로 joinConsume... by hjkim 20220224
                    const handsAudioConsumeDiv = document.getElementById("handsAudioConsumeDiv");
                    if (handsAudioConsumeDiv) {
                        console.log(`subscribeHandsUpP2P case 1 rcvSeq[${rcvSeq}] join consume`);

                        let audioEl = document.createElement("audio");
                        audioEl.id = "handsAudioCousumeEl-" + rcvSeq;
                        audioEl.style.display = "none";
                        audioEl.autoPlay = true;
                        audioEl.autoplay = true;
                        handsAudioConsumeDiv.appendChild(audioEl);

                        let consumeInfo = {
                            rcvSeq              : rcvSeq,

                            mediaKind           : "",

                            audioConsumer       : null,
                            audioStream         : null,
                            audioEl             : audioEl,

                            videoConsumer       : null,
                            videoStream         : null,
                            videoEl             : null,

                            //isMicStop           : false,
                            isMuteAudio         : false,
                            isMicReceiveable    : false,
                            isAudioReceiveable  : false,
                            isAudioReceived     : false,

                            isVideoReceiveable  : false,
                            isVideoReceived     : false,

                            isP2PMode           : false
                        };

                        clientInfo.handsupInfo.list_p2pConsumeInfo.push(consumeInfo);
                        sendJoinConsume("handsup", rcvSeq);
                    } else {
                        console.log(`subscribeHandsUpP2P case 1-1 rcvSeq[${rcvSeq}] handsAudioConsumeDiv is `, handsAudioConsumeDiv);
                    }
                } else { // 기존 p2p consume에 포함되어 있는 경우 그대로 둔다... (아마도 계속 재생중일 것이다...) by hjkim 20220224
                    if (p2pConsumeInfo.isAudioReceived) {
                        console.log(`subscribeHandsUpP2P case 2-1 rcvSeq[${p2pConsumeInfo.rcvSeq}], isP2PMode[${p2pConsumeInfo.isP2PMode}]`);
                        console.log(`isAudioReceived[${p2pConsumeInfo.isAudioReceived}], isAudioReceiveable[${p2pConsumeInfo.isAudioReceiveable}]`);
                    } else {
                        console.log(`subscribeHandsUpP2P case 2-2 rcvSeq[${p2pConsumeInfo.rcvSeq}], isP2PMode[${p2pConsumeInfo.isP2PMode}]`);
                        console.log(`isAudioReceived[${p2pConsumeInfo.isAudioReceived}], isAudioReceiveable[${p2pConsumeInfo.isAudioReceiveable}]`);
                    }
                }
            } else { // 기존 audio consume에 포함된 경우 그대로 둔다... (아마도 계속 재생중일 것이다...) by hjkim 20220224
                console.log(`subscribeHandsUpP2P case 3 rcvSeq[${consumeInfo.rcvSeq}], isP2PMode[${consumeInfo.isP2PMode}]`);
                if (!consumeInfo.isP2PMode) consumeInfo.isP2PMode = true;
                if (consumeInfo.isAudioReceived) {
                    console.log(`subscribeHandsUpP2P case 3-1 rcvSeq[${consumeInfo.rcvSeq}], isP2PMode[${consumeInfo.isP2PMode}]`);
                    console.log(`isAudioReceived[${consumeInfo.isAudioReceived}], isAudioReceiveable[${consumeInfo.isAudioReceiveable}]`);
                } else {
                    console.log(`subscribeHandsUpP2P case 3-2 rcvSeq[${consumeInfo.rcvSeq}], isP2PMode[${consumeInfo.isP2PMode}]`);
                    console.log(`isAudioReceived[${consumeInfo.isAudioReceived}], isAudioReceiveable[${consumeInfo.isAudioReceiveable}]`);
                }
            }
            resolve(true);
        }
    });
}

/**
 * handsup audio subscribe (=consume)
 * @param {Number} rcvSeq 
 * @param {String} kind 
 * @returns {Promise<Boolean>}
 */
export const subscribeHandsUpAudio = async (rcvSeq, kind) => {
    return new Promise(async (resolve, reject) => {
        if (rcvSeq === undefined || rcvSeq === null || rcvSeq < 0) {
            console.log(`------subscribeHandsUpAudio------ rcvSeq[${rcvSeq}]`);
            resolve(true);
        } else {
            const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === rcvSeq);
            if (consumeInfo === undefined || consumeInfo === null) { // 기존 audio consume에 없는 경우이므로 p2p consume check ... by hjkim 20220224
                const p2pConsumeInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === rcvSeq);
                if (p2pConsumeInfo === undefined || p2pConsumeInfo === null) { // 기존 audio, p2p consume에 없는 경우이므로 joinConsume... by hjkim 20220224
                    const handsAudioConsumeDiv = document.getElementById("handsAudioConsumeDiv");
                    if (handsAudioConsumeDiv) {
                        console.log(`subscribeHandsUpAudio case 1 rcvSeq[${rcvSeq}] join consume`);

                        let audioEl = document.createElement("audio");
                        audioEl.id = "handsAudioCousumeEl-" + rcvSeq;
                        audioEl.style.display = "none";
                        audioEl.autoPlay = true;
                        audioEl.autoplay = true;
                        handsAudioConsumeDiv.appendChild(audioEl);

                        let consumeInfo = {
                            rcvSeq              : rcvSeq,

                            mediaKind           : kind,

                            audioConsumer       : null,
                            audioStream         : null,
                            audioEl             : audioEl,

                            videoConsumer       : null,
                            videoStream         : null,
                            videoEl             : null,

                            //isMicStop           : false,
                            isMuteAudio         : false,
                            isMicReceiveable    : false,
                            isAudioReceiveable  : false,
                            isAudioReceived     : false,

                            isVideoReceiveable  : false,
                            isVideoReceived     : false,

                            isP2PMode           : false
                        };

                        clientInfo.handsupInfo.list_consumeInfo.push(consumeInfo);

                        if (kind !== "video") {
                            sendJoinConsume("handsup", rcvSeq);
                        }
                    } else {
                        console.log(`subscribeHandsUpAudio case 1-1 rcvSeq[${rcvSeq}] handsAudioConsumeDiv is `, handsAudioConsumeDiv);
                    }
                } else { // 기존 p2p 중에 체크된 경우이므로 audio consume 으로 이동한다... by hjkim 20220224
                    p2pConsumeInfo.isP2PMode = true;
                    clientInfo.handsupInfo.list_consumeInfo.push(p2pConsumeInfo);
                    clientInfo.handsupInfo.list_p2pConsumeInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.filter(info => info.rcvSeq !== rcvSeq);

                    if (p2pConsumeInfo.isAudioReceived) {
                        console.log(`subscribeHandsUpAudio case 2-1 rcvSeq[${p2pConsumeInfo.rcvSeq}], isP2PMode[${p2pConsumeInfo.isP2PMode}] list_p2pConsumeInfo 에서 list_consumeInfo 이동`);
                        console.log(`isAudioReceived[${p2pConsumeInfo.isAudioReceived}], isAudioReceiveable[${p2pConsumeInfo.isAudioReceiveable}]`);
                    } else {
                        console.log(`subscribeHandsUpAudio case 2-2 rcvSeq[${p2pConsumeInfo.rcvSeq}], isP2PMode[${p2pConsumeInfo.isP2PMode}] list_p2pConsumeInfo 에서 list_consumeInfo 이동`);
                        console.log(`isAudioReceived[${p2pConsumeInfo.isAudioReceived}], isAudioReceiveable[${p2pConsumeInfo.isAudioReceiveable}]`);
                    }
                }
            } else { // 이미 audio consume 중인 유저이므로 아무것도 하지 않는다... by hjkim 20220224
                if (consumeInfo.isAudioReceived) {
                    console.log(`subscribeHandsUpAudio case 3-1 rcvSeq[${consumeInfo.rcvSeq}], isP2PMode[${consumeInfo.isP2PMode}] already consume user. not doing`);
                    console.log(`isAudioReceived[${consumeInfo.isAudioReceived}], isAudioReceiveable[${consumeInfo.isAudioReceiveable}]`);
                } else {
                    console.log(`subscribeHandsUpAudio case 3-2 rcvSeq[${consumeInfo.rcvSeq}], isP2PMode[${consumeInfo.isP2PMode}] already consume user. not doing`);
                    console.log(`isAudioReceived[${consumeInfo.isAudioReceived}], isAudioReceiveable[${consumeInfo.isAudioReceiveable}]`);
                }
            }
            resolve(true);
        }
    });
}

/**
 * handsup audio subscribe (=consume) with array
 * @param {Array} list_user 
 * @returns {Promise<Boolean | Error>}
 */
export const subscribeHandsUpAudioArray = async (list_user) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (list_user && list_user.length > 0) {
                if (clientInfo.handsupInfo) {
                    const promises = list_user.map((rcvSeq) => subscribeHandsUpAudio(rcvSeq, "array"));
                    await Promise.all(promises);
                }
            } else {
                console.log(`------subscribeHandsUpAudioArray------ list_user[${list_user}]`);
            }
            resolve(true);
        } catch (err) {
            console.log("subscribeHandsUpAudioArray err => ", err);
            reject(err);
        }
    });
}

/**
 * handsup audio subscribe (=consume)
 * @param {Number} rcvSeq 
 * @param {String} kind 
 * @returns {Promise<Boolean>}
 */
export const initSubscribeHandsUpConsumeInfo = async (rcvSeq, kind) => {
    console.log(`initSubscribeHandsUpConsumeInfo - rcvSeq[${rcvSeq}], kind[${kind}]`);
    return new Promise(async (resolve, reject) => {
        if (rcvSeq === undefined || rcvSeq === null || rcvSeq < 0) {
            console.log(`------initSubscribeHandsUpConsumeInfo------ rcvSeq[${rcvSeq}]`);
            resolve(true);
        } else {
            const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === rcvSeq);
            if (consumeInfo === undefined || consumeInfo === null) { // 기존 audio consume에 없는 경우이므로 p2p consume check ... by hjkim 20220224
                const p2pConsumeInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === rcvSeq);
                if (p2pConsumeInfo === undefined || p2pConsumeInfo === null) { // 기존 audio, p2p consume에 없는 경우이므로 joinConsume... by hjkim 20220224
                    console.log(`initSubscribeHandsUpConsumeInfo case 1 rcvSeq[${rcvSeq}] init consume info`);

                    let consumeInfo = {
                        rcvSeq              : rcvSeq,

                        mediaKind           : kind,

                        audioConsumer       : null,
                        audioStream         : null,
                        audioEl             : null,

                        videoConsumer       : null,
                        videoStream         : null,
                        videoEl             : null,

                        //isMicStop           : false,
                        isMuteAudio         : false,
                        isMicReceiveable    : false,
                        isAudioReceiveable  : false,
                        isAudioReceived     : false,

                        isVideoReceiveable  : false,
                        isVideoReceived     : false,

                        isP2PMode           : false
                    };

                    clientInfo.handsupInfo.list_consumeInfo.push(consumeInfo);
                    // sendJoinConsume("handsup", rcvSeq);
                } else { // 기존 p2p 중에 체크된 경우이므로 audio consume 으로 이동한다... by hjkim 20220224
                    p2pConsumeInfo.isP2PMode = true;
                    clientInfo.handsupInfo.list_consumeInfo.push(p2pConsumeInfo);
                    clientInfo.handsupInfo.list_p2pConsumeInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.filter(info => info.rcvSeq !== rcvSeq);

                    if (p2pConsumeInfo.isAudioReceived) {
                        console.log(`initSubscribeHandsUpConsumeInfo case 2-1 rcvSeq[${p2pConsumeInfo.rcvSeq}], isP2PMode[${p2pConsumeInfo.isP2PMode}] list_p2pConsumeInfo 에서 list_consumeInfo 이동`);
                        console.log(`isAudioReceived[${p2pConsumeInfo.isAudioReceived}], isAudioReceiveable[${p2pConsumeInfo.isAudioReceiveable}]`);
                    } else {
                        console.log(`initSubscribeHandsUpConsumeInfo case 2-2 rcvSeq[${p2pConsumeInfo.rcvSeq}], isP2PMode[${p2pConsumeInfo.isP2PMode}] list_p2pConsumeInfo 에서 list_consumeInfo 이동`);
                        console.log(`isAudioReceived[${p2pConsumeInfo.isAudioReceived}], isAudioReceiveable[${p2pConsumeInfo.isAudioReceiveable}]`);
                    }
                }
            } else { // 이미 audio consume 중인 유저이므로 아무것도 하지 않는다... by hjkim 20220224
                if (consumeInfo.isAudioReceived) {
                    console.log(`initSubscribeHandsUpConsumeInfo case 3-1 rcvSeq[${consumeInfo.rcvSeq}], isP2PMode[${consumeInfo.isP2PMode}] already consume user. not doing`);
                    console.log(`isAudioReceived[${consumeInfo.isAudioReceived}], isAudioReceiveable[${consumeInfo.isAudioReceiveable}]`);
                } else {
                    console.log(`initSubscribeHandsUpConsumeInfo case 3-2 rcvSeq[${consumeInfo.rcvSeq}], isP2PMode[${consumeInfo.isP2PMode}] already consume user. not doing`);
                    console.log(`isAudioReceived[${consumeInfo.isAudioReceived}], isAudioReceiveable[${consumeInfo.isAudioReceiveable}]`);
                }
            }
            resolve(true);
        }
    });
}

/**
 * handsup audio unsubscribe
 * @param {Boolean} isForSoundOnly 
 * @param {Number} rcvSeq 
 * @returns {Promise<Boolean | Error>}
 * @private
 */
const unsubscribeHandsUpAudioConsume = async (isForSoundOnly, rcvSeq) => {
    return new Promise((resolve, reject) => {
        try {
            const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === rcvSeq);
            if (consumeInfo) { // audio consume 중인 case by hjkim 20220224
                if (consumeInfo.isP2PMode) { // p2p 중에 체크 해제된 경우이므로 p2p consume 으로 이동한다... by hjkim 20220224
                    console.log(`unsubscribeHandsUpAudioConsume case 1 rcvSeq[${consumeInfo.rcvSeq}], isP2PMode[${consumeInfo.isP2PMode}] list_consumeInfo 에서 list_p2pConsumeInfo 이동`);
                    clientInfo.handsupInfo.list_p2pConsumeInfo.push(consumeInfo);
                    clientInfo.handsupInfo.list_consumeInfo = clientInfo.handsupInfo.list_consumeInfo.filter(info => info.rcvSeq !== rcvSeq);
                } else { // p2p 중이 아니고, 그냥 체크 해제된 경우이므로 unsubscribe 한다... by hjkim 20220224
                    console.log(`unsubscribeHandsUpAudioConsume case 2 rcvSeq[${consumeInfo.rcvSeq}], isP2PMode[${consumeInfo.isP2PMode}] unsubscribe`);
                    sendUnsubscribe(isForSoundOnly, "handsup", consumeInfo.rcvSeq);
                }
            } else { // audio consume 중이 아닌 경우인데 이런 경우가 있을까..? by hjkim 20220224
                console.log(`unsubscribeHandsUpAudioConsume case 3 rcvSeq[${rcvSeq}]`);
            }
            resolve(true);
        } catch (err) {
            console.log("unsubscribeHandsUpAudioConsume err => ", err);
            reject(err);
        }
    });
}

/**
 * handsup audio unsubscribe
 * rcvSeq 있을 경우 rcvSeq만 unsubscribe
 * rcvSeq 없을 경우 모든 handsup audio unsubscribe
 * @param {Number | null} rcvSeq 
 * @returns {Promise<Boolean | Error>}
 */
export const unsubscribeHandsUpAudio = async (rcvSeq) => {
    //console.log("unsubscribeHandsUpAudio - rcvSeq => ", rcvSeq);
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.handsupInfo.list_consumeInfo && clientInfo.handsupInfo.list_consumeInfo.length > 0) {
                if (rcvSeq === undefined || rcvSeq === null) {
                    const promises = clientInfo.handsupInfo.list_consumeInfo.map((info) => unsubscribeHandsUpAudioConsume(true, info.rcvSeq));
                    await Promise.all(promises);
                } else {
                    await unsubscribeHandsUpAudioConsume(true, rcvSeq);
                }
            }

            resolve(true);
        } catch (err) {
            console.log("unsubscribeHandsUpAudio err => ", err);
            reject(err);
        }
    });
}

/**
 * handsup audio, p2p consume - unsubscribe / mute 
 * @param {Boolean} isForSoundOnly 
 * @param {Number} rcvSeq 
 * @returns {Promise<Boolean | Error>}
 * @private
 */
const unsubscribeHandsUpConsume = async (isForSoundOnly, rcvSeq) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.handsupInfo.list_consumeInfo && clientInfo.handsupInfo.list_p2pConsumeInfo) {
                const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === rcvSeq);
                if (consumeInfo === undefined || consumeInfo === null) { // p2p check 해야하는 case
                    const p2pConsumeInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === rcvSeq);
                    if (p2pConsumeInfo === undefined || p2pConsumeInfo === null) {
                        console.log("unsubscribeHandsUpConsume case 1");  // 이런 경우가 있어선 안되겠지만.. 혹시라도 있을까..?
                    } else {
                        console.log("unsubscribeHandsUpConsume case 2");
                        await muteHandsUpP2PConsume(p2pConsumeInfo.rcvSeq);
                        sendUnsubscribe(isForSoundOnly, "handsup", p2pConsumeInfo.rcvSeq);
                    }
                } else {
                    console.log("unsubscribeHandsUpConsume case 3");
                    await muteAudioConsume(consumeInfo.rcvSeq);
                    sendUnsubscribe(isForSoundOnly, "handsup", consumeInfo.rcvSeq);
                }
            }
            resolve(true);
        } catch (err) {
            console.log("unsubscribeHandsUpP2PConsume err => ", err);
            reject(err);
        }
    });
}

/**
 * handsup audio, p2p consume 정리 
 * @param {Boolean} isForSoundOnly 
 * @returns {Promise<Boolean | Error>}
 */
export const unsubscribeHandsUpConsumeAll = async (isForSoundOnly) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.handsupInfo.list_p2pConsumeInfo && clientInfo.handsupInfo.list_p2pConsumeInfo.length > 0) {
                const promises = clientInfo.handsupInfo.list_p2pConsumeInfo.map((info) => unsubscribeHandsUpConsume(isForSoundOnly, info.rcvSeq));
                await Promise.all(promises);
            }

            if (clientInfo.handsupInfo.list_consumeInfo && clientInfo.handsupInfo.list_consumeInfo.length > 0) {
                const promises = clientInfo.handsupInfo.list_consumeInfo.map((info) => unsubscribeHandsUpConsume(isForSoundOnly, info.rcvSeq));
                await Promise.all(promises);
            }

            resolve(true);
        } catch (err) {
            console.log("unsubscribeHandsUpConsumeAll err => ", err);
            reject(err);
        }
    });
}

/**
 * p2p mute
 * @param {Number} rcvSeq    userSeq
 * @returns {Promise<Boolean | Error>}
 * @private
 */
const muteHandsUpP2PConsume = async (rcvSeq) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.handsupInfo.list_p2pConsumeInfo && clientInfo.handsupInfo.list_p2pConsumeInfo.length > 0) {
                const p2pConsumeInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === rcvSeq);
                if (p2pConsumeInfo) {
                    console.log(`muteHandsUpP2PConsume - rcvSeq[${p2pConsumeInfo.rcvSeq}], isAudioReceived[${p2pConsumeInfo.isAudioReceived}], isMuteAudio[${p2pConsumeInfo.isMuteAudio}]`);
                    if (p2pConsumeInfo.audioConsumer) {
                        if (p2pConsumeInfo.audioEl) {
                            console.log(`(1)muteHandsUpP2PConsume - audioEl paused[${p2pConsumeInfo.audioEl.paused}], muted[${p2pConsumeInfo.audioEl.muted}], volume[${p2pConsumeInfo.audioEl.volume}]`);
                            if (p2pConsumeInfo.audioEl.srcObject) {
                                p2pConsumeInfo.audioEl.pause();
                                p2pConsumeInfo.audioEl.srcObject = null;
                            }
                        }

                        if (p2pConsumeInfo.audioStream !== null) {
                            p2pConsumeInfo.audioStream.removeTrack(p2pConsumeInfo.audioConsumer.track);
                            p2pConsumeInfo.audioStream = null;
                        }
                    }

                    p2pConsumeInfo.isMuteAudio = true;
                }
            }

            resolve(true);
        } catch (err) {
            console.log("------muteHandsUpP2PConsume------error => ", err);
            reject(err);
        }
    });
}

/**
 * audio consume mute
 * @param {Number} rcvSeq 
 * @returns {Promise<Boolean | Error>}
 * @private
 */
const muteAudioConsume = async (rcvSeq) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.handsupInfo.list_consumeInfo && clientInfo.handsupInfo.list_consumeInfo.length > 0) {
                const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === rcvSeq);
                if (consumeInfo) {
                    console.log(`muteAudioConsume - rcvSeq[${consumeInfo.rcvSeq}], isAudioReceived[${consumeInfo.isAudioReceived}], isMuteAudio[${consumeInfo.isMuteAudio}]`);
                    if (consumeInfo.audioConsumer) {
                        if (consumeInfo.audioEl) {
                            console.log(`(1)muteAudioConsume - audioEl paused[${consumeInfo.audioEl.paused}], muted[${consumeInfo.audioEl.muted}], volume[${consumeInfo.audioEl.volume}]`);
                            if (consumeInfo.audioEl.srcObject) {
                                consumeInfo.audioEl.pause();
                                consumeInfo.audioEl.srcObject = null;
                            }
                        }

                        if (consumeInfo.audioStream !== null) {
                            consumeInfo.audioStream.removeTrack(consumeInfo.audioConsumer.track);
                            consumeInfo.audioStream = null;
                        }
                    }

                    consumeInfo.isMuteAudio = true;
                }
            }

            resolve(true);
        } catch (err) {
            console.log("------muteAudioConsume------error => ", err);
            reject(err);
        }
    });
}

/**
 * 모든 audio consume mute
 * @returns {Promise<Boolean | Error>}
 */
export const muteAudioConsumeAll = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.handsupInfo.list_consumeInfo && clientInfo.handsupInfo.list_consumeInfo.length > 0) {
                const promises = clientInfo.handsupInfo.list_consumeInfo.map((info) => muteAudioConsume(info.rcvSeq));
                await Promise.all(promises);
            }

            resolve(true);
        } catch (err) {
            console.log("------muteAudioConsumeAll------error => ", err);
            reject(err);
        }
    });
}

/**
 * 모든 consume clear
 * @param {Boolean} isForSoundOnly 
 * @param {Number} rcvSeq 
 * @returns {Promise<Boolean | Error>}
 */
const clearHandsUpConsume = async (isForSoundOnly, rcvSeq) => {
    return new Promise((resolve, reject) => {
        try {
            const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === rcvSeq);
            if (consumeInfo) {
                // isForSoundOnly 값과 상관없이 audio 는 항상 unsubscribe 해야 하기 때문에 true인지 체크 안하고 바로 처리... by hjkim 20231108
                // let idx = clientInfo.handsupInfo.list_consumeInfo.indexOf(consumeInfo);
                if (consumeInfo.audioConsumer) {
                    if (consumeInfo.audioStream) {
                        if (consumeInfo.audioEl) {
                            consumeInfo.audioEl.pause();

                            if (consumeInfo.audioEl.parentNode) {
                                consumeInfo.audioEl.parentNode.removeChild(consumeInfo.audioEl);
                            }

                            consumeInfo.audioEl = null;
                        }

                        consumeInfo.audioStream.removeTrack(consumeInfo.audioConsumer.track);
                        consumeInfo.audioStream = null;
                    }

                    if (!consumeInfo.audioConsumer.closed) {
                        consumeInfo.audioConsumer.close();
                    }

                    consumeInfo.audioConsumer = null;
                }

                if (isForSoundOnly === false) { // isForSoundOnly 가 false 면 all unsubscribe 이기 때문에 video 를 여기서 처리... by hjkim 20231108
                    if (consumeInfo.videoConsumer) {
                        if (consumeInfo.videoStream) {
                            if (consumeInfo.videoEl) {
                                consumeInfo.videoEl.pause();
                                consumeInfo.videoEl.currentTime = 0;
                            }

                            consumeInfo.videoStream.removeTrack(consumeInfo.videoConsumer.track);
                            consumeInfo.videoStream = null;
                        }

                        if (!consumeInfo.videoConsumer.closed) {
                            consumeInfo.videoConsumer.close();
                        }

                        consumeInfo.videoConsumer = null;

                        if (consumeInfo.videoEl) {
                            // consumeInfo.videoEl.currentTime = 0;
                            consumeInfo.videoEl.poster = "/images/connect.png";
                            // consumeInfo.videoEl.poster = "/images/android_notconnected.png";
                        }
                    }
                }
            } else {
                const p2pInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === rcvSeq);
                if (p2pInfo) {
                    // isForSoundOnly 값과 상관없이 audio 는 항상 unsubscribe 해야 하기 때문에 true인지 체크 안하고 바로 처리... by hjkim 20231108
                    // let idx = clientInfo.handsupInfo.list_p2pConsumeInfo.indexOf(p2pInfo);
                    if (p2pInfo.audioConsumer) {
                        if (p2pInfo.audioStream) {
                            if (p2pInfo.audioEl) {
                                p2pInfo.audioEl.pause();

                                if (p2pInfo.audioEl.parentNode) {
                                    p2pInfo.audioEl.parentNode.removeChild(p2pInfo.audioEl);
                                }

                                p2pInfo.audioEl = null;
                            }

                            p2pInfo.audioStream.removeTrack(p2pInfo.audioConsumer.track);
                            p2pInfo.audioStream = null;
                        }

                        if (!p2pInfo.audioConsumer.closed) {
                            p2pInfo.audioConsumer.close();
                        }

                        p2pInfo.audioConsumer = null;
                    }

                    if (isForSoundOnly === false) { // isForSoundOnly 가 false 면 all unsubscribe 이기 때문에 video 를 여기서 처리... by hjkim 20231108
                        if (p2pInfo.videoConsumer) {
                            if (p2pInfo.videoStream) {
                                if (p2pInfo.videoEl) {
                                    p2pInfo.videoEl.pause();
                                    p2pInfo.videoEl.currentTime = 0;
                                }

                                p2pInfo.videoStream.removeTrack(p2pInfo.videoConsumer.track);
                                p2pInfo.videoStream = null;
                            }

                            if (!p2pInfo.videoConsumer.closed) {
                                p2pInfo.videoConsumer.close();
                            }

                            p2pInfo.videoConsumer = null;

                            if (p2pInfo.videoEl) {
                                // consumeInfo.videoEl.currentTime = 0;
                                p2pInfo.videoEl.poster = "/images/connect.png";
                                // p2pInfo.videoEl.poster = "/images/android_notconnected.png";
                            }
                        }
                    }
                }
            }
            resolve(true);
        } catch (err) {
            console.log("clearHandsUpConsume err => ", err);
            reject(err);
        }
    });
}

/**
 * 모든 consume clear
 * @param {Boolean} isForSoundOnly 
 * @returns {Promise<Boolean | Error>}
 */
const clearHandsUpConsumeAll = async (isForSoundOnly) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.handsupInfo.list_p2pConsumeInfo && clientInfo.handsupInfo.list_p2pConsumeInfo.length > 0) {
                const promises = clientInfo.handsupInfo.list_p2pConsumeInfo.map((info) => clearHandsUpConsume(isForSoundOnly, info.rcvSeq));
                await Promise.all(promises);
            }

            if (clientInfo.handsupInfo.list_consumeInfo && clientInfo.handsupInfo.list_consumeInfo.length > 0) {
                const promises = clientInfo.handsupInfo.list_consumeInfo.map((info) => clearHandsUpConsume(isForSoundOnly, info.rcvSeq));
                await Promise.all(promises);
            }

            resolve(true);
        } catch (err) {
            console.log("clearHandsUpConsumeAll err => ", err);
            reject(err);
        }
    });
}

/**
 * audio consume 을 가지고 있는지 체크
 * @returns {Boolean}
 */
export const hasAudioConsume = () => {
    if (clientInfo.handsupInfo.list_consumeInfo && clientInfo.handsupInfo.list_consumeInfo.length > 0) {
        return true;
    } else {
        return false;
    }
}

/**
 * audio consume listen
 * @param {Number} rcvSeq 
 * @returns {Promise<Boolean | Error>}
 */
export const listenAudioConsume = async (rcvSeq) => {
    console.log(`listenAudioConsume - rcvSeq[${rcvSeq}]`);
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.handsupInfo.list_consumeInfo && clientInfo.handsupInfo.list_consumeInfo.length > 0) {
                const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === rcvSeq);
                if (consumeInfo) {
                    console.log(`listenAudioConsume - rcvSeq[${consumeInfo.rcvSeq}], isAudioReceived[${consumeInfo.isAudioReceived}], isMuteAudio[${consumeInfo.isMuteAudio}]`);
                    if (consumeInfo.audioConsumer) {
                        if (consumeInfo.audioStream === null) {
                            consumeInfo.audioStream = new MediaStream();
                            consumeInfo.audioStream.addTrack(consumeInfo.audioConsumer.track);
                        } else {
                            if (consumeInfo.audioStream.getTracks().length > 0) {
                                console.log("listenAudioConsume - consumeInfo.audioStream getTracks.length > 0");
                            } else {
                                consumeInfo.audioStream.addTrack(consumeInfo.audioConsumer.track);
                            }
                        }

                        if (consumeInfo.audioEl) {
                            if (consumeInfo.audioEl.srcObject === null) {
                                consumeInfo.audioEl.srcObject = consumeInfo.audioStream;
                                consumeInfo.audioEl.play();
                                console.log(`(1)listenAudioConsume - audioEl paused[${consumeInfo.audioEl.paused}], muted[${consumeInfo.audioEl.muted}], volume[${consumeInfo.audioEl.volume}]`);
                            } else {
                                console.log("listenAudioConsume - audioEl.srcObject not null. already listen");
                                console.log(`(2)listenAudioConsume - audioEl paused[${consumeInfo.audioEl.paused}], muted[${consumeInfo.audioEl.muted}], volume[${consumeInfo.audioEl.volume}]`);
                            }
                        }
                    }

                    consumeInfo.isMuteAudio = false;
                }
            }

            resolve(true);
        } catch (err) {
            console.log("------listenAudioConsume------error => ", err);
            reject(err);
        }
    });
}

/**
 * 모든 audio consume listen
 * @returns {Promise<Boolean | Error>}
 */
export const listenAudioConsumeAll = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.handsupInfo.list_consumeInfo && clientInfo.handsupInfo.list_consumeInfo.length > 0) {
                const promises = clientInfo.handsupInfo.list_consumeInfo.map((info) => listenAudioConsume(info.rcvSeq));
                await Promise.all(promises);
            }

            resolve(true);
        } catch (err) {
            console.log("------listenAudioConsumeAll------error => ", err);
            reject(err);
        }
    });
}

/**  MEDIA SOUP (audio/mic consume-cotrol) */
/**
 * 선생님(클래스 진행자) 마이크 mute
 * @returns {Promise<Boolean | Error>}
 * @private 
 */
const muteMicTrack = () => {
    console.log(`muteMicTrack - rcvTeacherSeq[${clientInfo.rcvTeacherSeq}], isMicReceived[${clientInfo.consumeInfo.isMicReceived}], isSelectedMode[${clientInfo.consumeInfo.isSelectedMode}]`);
    return new Promise((resolve, reject) => {
        try {
            if (clientInfo.consumeInfo.micConsumer) { // mic consumer 체크 ... by hjkim 20220222
                if (clientInfo.consumeInfo.micEl) {
                    if (clientInfo.consumeInfo.micEl.srcObject) {
                        clientInfo.consumeInfo.micEl.pause();
                        clientInfo.consumeInfo.micEl.srcObject = null;
                    }
                }

                if (clientInfo.consumeInfo.micStream !== null) {
                    clientInfo.consumeInfo.micStream.removeTrack(clientInfo.consumeInfo.micConsumer.track);
                    clientInfo.consumeInfo.micStream = null;
                }
            }

            resolve(true);
        } catch (err) {
            console.log("------muteMicTrack------error => ", err);
            reject(err);
        }
    });
}

/**
 * 선생님(클래스 진행자) 마이크 listen
 * @returns {Promise<Boolean | Error>}
 * @private 
 */
const listenMicTrack = () => {
    console.log(`listenMicTrack - rcvTeacherSeq[${clientInfo.rcvTeacherSeq}], isMicReceived[${clientInfo.consumeInfo.isMicReceived}], isSelectedMode[${clientInfo.consumeInfo.isSelectedMode}]`);
    return new Promise((resolve, reject) => {
        try {
            if (clientInfo.consumeInfo.micConsumer) { // mic consumer 체크 ... by hjkim 20220222
                if (clientInfo.consumeInfo.micStream === null) {
                    clientInfo.consumeInfo.micStream = new MediaStream();
                    clientInfo.consumeInfo.micStream.addTrack(clientInfo.consumeInfo.micConsumer.track);
                } else {
                    if (clientInfo.consumeInfo.micStream.getTracks().length > 0) {
                        console.log(`listenMicTrack - micStream already has audio track. length[${clientInfo.consumeInfo.micStream.getTracks().length}]`);
                    } else {
                        clientInfo.consumeInfo.micStream.addTrack(clientInfo.consumeInfo.micConsumer.track);
                    }
                }

                if (clientInfo.consumeInfo.micEl) {
                    if (clientInfo.consumeInfo.micEl.srcObject === null) {
                        clientInfo.consumeInfo.micEl.srcObject = clientInfo.consumeInfo.micStream;
                        if (ConstData.IS_LOCAL_VERSION) clientInfo.consumeInfo.micEl.volume = 0;
                        clientInfo.consumeInfo.micEl.play();
                    } else {
                        console.log("listenMicTrack - micEl.srcObject not null. already listen");
                    }
                }
            }

            resolve(true);
        } catch (err) {
            console.log("------listenMicTrack------error => ", err);
            reject(err);
        }
    });
}

/**
 * 선생님(클래스 진행자) 오디오 mute
 * @returns {Promise<Boolean | Error>}
 * @private 
 */
const muteAudioTrack = () => {
    console.log(`muteAudioTrack - rcvTeacherSeq[${clientInfo.rcvTeacherSeq}], isAudioReceived[${clientInfo.consumeInfo.isAudioReceived}], isSelectedMode[${clientInfo.consumeInfo.isSelectedMode}]`);
    return new Promise((resolve, reject) => {
        try {
            if (clientInfo.consumeInfo.audioConsumer) {
                if (clientInfo.consumeInfo.audioEl) {
                    if (clientInfo.consumeInfo.audioEl.srcObject) {
                        clientInfo.consumeInfo.audioEl.pause();
                        clientInfo.consumeInfo.audioEl.srcObject = null;
                    }
                }

                if (clientInfo.consumeInfo.audioStream !== null) {
                    clientInfo.consumeInfo.audioStream.removeTrack(clientInfo.consumeInfo.audioConsumer.track);
                    clientInfo.consumeInfo.audioStream = null;
                }
            }

            resolve(true);
        } catch (err) {
            console.log("------muteAudioTrack------error => ", err);
            reject(err);
        }
    });
}

/**
 * 선생님(클래스 진행자) 오디오 listen
 * @returns {Promise<Boolean | Error>}
 * @private 
 */
const listenAudioTrack = () => {
    console.log(`listenAudioTrack - rcvTeacherSeq[${clientInfo.rcvTeacherSeq}], isAudioReceived[${clientInfo.consumeInfo.isAudioReceived}], isSelectedMode[${clientInfo.consumeInfo.isSelectedMode}]`);
    return new Promise((resolve, reject) => {
        try {
            if (clientInfo.consumeInfo.audioConsumer) { // audio consume 중인지 체크 ... by hjkim 20220211
                if (clientInfo.consumeInfo.audioStream === null) {
                    clientInfo.consumeInfo.audioStream = new MediaStream();
                    clientInfo.consumeInfo.audioStream.addTrack(clientInfo.consumeInfo.audioConsumer.track);
                } else {
                    if (clientInfo.consumeInfo.audioStream.getTracks().length > 0) {
                        console.log(`listenAudioTrack - audioStream already has audio track. length[${clientInfo.consumeInfo.audioStream.getTracks().length}]`);
                    } else {
                        clientInfo.consumeInfo.audioStream.addTrack(clientInfo.consumeInfo.audioConsumer.track);
                    }
                }

                if (clientInfo.consumeInfo.audioStream !== null) {
                    if (clientInfo.consumeInfo.audioEl) {
                        if (clientInfo.consumeInfo.audioEl.srcObject === null) {
                            clientInfo.consumeInfo.audioEl.pause();
                            clientInfo.consumeInfo.audioEl.srcObject = clientInfo.consumeInfo.audioStream;
                            if (ConstData.IS_LOCAL_VERSION) clientInfo.consumeInfo.audioEl.volume = 0;
                            clientInfo.consumeInfo.audioEl.play();
                        } else { // 이미 audio 듣고 있는 상태 ... 20220211
                            console.log("listenAudioTrack - audioEl.srcObject not null. already listen");
                        }
                    }
                }
            }

            resolve(true);
        } catch (err) {
            console.log("------listenAudioTrack------error => ", err);
            reject(err);
        }
    });
}

/**
 * handsup 창에서 선택 또는 p2p 중일 때 
 * audio mute / mic listen / isSelectedMode = true.
 * @returns {Promise<Boolean>}
 */
export const setSelectedMode = async () => {
    return new Promise(async (resolve) => {
        try {
            clientInfo.consumeInfo.isSelectedMode = true;
            await muteAudioTrack();
            await listenMicTrack();
            resolve(true);
        } catch (err) {
            console.log("setSelectedMode err => ", err);
            resolve(false);
        }
    });
}

/**
 * handsup 창에서 선택되지 않고 p2p도 아닐 때 
 * default : audio listen / mic off / isSelectedMode = false.
 * 예외 : isNeedMuteAudio가 true면 audio listen 하지 않는다.
 * @param {Boolean} isNeedMuteAudio 
 * @returns {Promise<Boolean>}
 */
export const setUnselectedMode = async (isNeedMuteAudio) => {
    return new Promise(async (resolve) => {
        try {
            clientInfo.consumeInfo.isSelectedMode = false;
            await muteMicTrack();
            if (isNeedMuteAudio !== true) {
                await listenAudioTrack();
            }
            resolve(true);
        } catch (err) {
            console.log("setUnselectedMode err => ", err);
            resolve(false);
        }
    });
}

/**  MEDIA SOUP SEND (consume) */
/**
 * send joinConsume request to mediaSoup server
 * @param {String} roomKind 
 * @param {Number} rcvSeq 
 */
export const sendJoinConsume = (roomKind, rcvSeq) => {
    console.log(`sendJoinConsume - roomKind[${roomKind}], rcvSeq[${rcvSeq}], clientInfo - userSeq[${clientInfo.userSeq}], liveSeq[${clientInfo.liveSeq}], routingKey[${clientInfo.routingKey}]`);
    const data = {
        kind            : "mediaSoup",
        action          : "joinConsume",
        prodUserSeq     : rcvSeq,
        roomKind        : roomKind,     // live or handsup
        liveSeq         : clientInfo.liveSeq,
        consumUserSeq   : clientInfo.userSeq,
        sendRotingKey   : clientInfo.routingKey
    };

    sendObjectToRbmq(data);
}

/**
 * send exitConsume request to mediaSoup server for live
 * @param {Number} rcvSeq 
 */
export const sendExitConsume = (rcvSeq) => {
    console.log(`sendExitConsume - rcvSeq[${rcvSeq}], clientInfo - userSeq[${clientInfo.userSeq}], liveSeq[${clientInfo.liveSeq}], routingKey[${clientInfo.routingKey}]`);
    const data = {
        kind            : "mediaSoup",
        action          : "exitConsume",
        prodUserSeq     : rcvSeq,
        roomKind        : "live",
        liveSeq         : clientInfo.liveSeq,
        consumUserSeq   : clientInfo.userSeq,
        sendRotingKey   : clientInfo.routingKey
    };

    sendObjectToRbmq(data);
}

/**
 * send exitConsume request to mediaSoup server for handsup
 * @param {Boolean} isAudioReceiveable 
 * @param {Number} rcvSeq 
 * @returns {Promise<Boolean>}
 * @private 
 */
const sendExitConsumeHandsUp = (isAudioReceiveable, rcvSeq) => {
    console.log(`sendExitConsumeHandsUp - rcvSeq[${rcvSeq}], isAudioReceiveable[${isAudioReceiveable}], clientInfo - userSeq[${clientInfo.userSeq}], liveSeq[${clientInfo.liveSeq}], routingKey[${clientInfo.routingKey}]`);
    return new Promise((resolve, reject) => {
        const data = {
            kind            : "mediaSoup",
            action          : "exitConsume",
            prodUserSeq     : rcvSeq,
            roomKind        : "handsup",
            liveSeq         : clientInfo.liveSeq,
            consumUserSeq   : clientInfo.userSeq,
            sendRotingKey   : clientInfo.routingKey
        };

        sendObjectToRbmq(data);
        resolve(true);
    });
}

/**
 * send unsubscribe request to mediaSoup server
 * @param {Boolean} isForSoundOnly 
 * @param {String} roomKind 
 * @param {Number} rcvSeq 
 * @private 
 */
const sendUnsubscribe = (isForSoundOnly, roomKind, rcvSeq) => {
    console.log(`sendUnsubscribe - rcvSeq[${rcvSeq}], roomKind[${roomKind}], isForSoundOnly[${isForSoundOnly}], clientInfo - userSeq[${clientInfo.userSeq}], liveSeq[${clientInfo.liveSeq}], routingKey[${clientInfo.routingKey}]`);
    const data = {
        kind            : "mediaSoup",
        action          : "unsubscrib",
        prodUserSeq     : rcvSeq,
        roomKind        : roomKind,     // live or handsup
        liveSeq         : clientInfo.liveSeq,
        consumUserSeq   : clientInfo.userSeq,
        isForSoundOnly  : isForSoundOnly,
        sendRotingKey   : clientInfo.routingKey
    };

    sendObjectToRbmq(data);
}

/**
 * send consume request to mediaSoup server
 * @param {String} mediaKind 
 * @param {String} roomKind 
 * @param {Number} rcvSeq 
 * @private 
 */
const sendConsume = (mediaKind, roomKind, rcvSeq) => {
    console.log(`sendConsume - rcvSeq[${rcvSeq}], roomKind[${roomKind}], mediaKind[${mediaKind}], clientInfo - userSeq[${clientInfo.userSeq}], liveSeq[${clientInfo.liveSeq}], routingKey[${clientInfo.routingKey}]`);
    const { rtpCapabilities } = clientInfo.device;
    const data = {
        kind            : "mediaSoup",
        action          : "consume",
        mediaKind       : mediaKind,
        prodUserSeq     : rcvSeq,
        roomKind        : roomKind,     // live or handsup
        liveSeq         : clientInfo.liveSeq,
        rtpCapabilities : rtpCapabilities,
        consumUserSeq   : clientInfo.userSeq,
        sendRotingKey   : clientInfo.routingKey
    };

    sendObjectToRbmq(data);
}

/**
 * send resume request to mediaSoup server
 * @param {String} mediaKind 
 * @param {String} roomKind 
 * @param {Number} rcvSeq 
 * @private 
 */
const sendResume = (mediaKind, roomKind, rcvSeq) => {
    console.log(`sendResume - rcvSeq[${rcvSeq}], roomKind[${roomKind}], mediaKind[${mediaKind}], clientInfo - userSeq[${clientInfo.userSeq}], liveSeq[${clientInfo.liveSeq}], routingKey[${clientInfo.routingKey}]`);
    const data = {
        kind            : "mediaSoup",
        action          : "resume",
        mediaKind       : mediaKind,
        prodUserSeq     : rcvSeq,
        roomKind        : roomKind,     // live or handsup
        liveSeq         : clientInfo.liveSeq,
        consumUserSeq   : clientInfo.userSeq,
        sendRotingKey   : clientInfo.routingKey
    };

    sendObjectToRbmq(data);
}

/**
 * send createConsumerTransport request to mediaSoup server
 * @param {Number} rcvSeq 
 * @private 
 */
const sendCreateConsumerTransport = (rcvSeq) => {
    console.log(`sendCreateConsumerTransport - rcvSeq[${rcvSeq}], clientInfo - userSeq[${clientInfo.userSeq}], liveSeq[${clientInfo.liveSeq}], routingKey[${clientInfo.routingKey}]`);
    const data = {
        kind            : "mediaSoup",
        action          : "createConsumerTransport",
        prodUserSeq     : rcvSeq,
        roomKind        : "live",
        liveSeq         : clientInfo.liveSeq,
        consumUserSeq   : clientInfo.userSeq,
        sendRotingKey   : clientInfo.routingKey
    };

    sendObjectToRbmq(data);
}

/**
 * send connectConsumerTransport request to mediaSoup server
 * @param {DtlsParameters} dtlsParameters 
 * @param {Number} rcvSeq 
 * @private 
 */
const sendConnectConsumerTransport = (dtlsParameters, rcvSeq) => {
    console.log(`sendConnectConsumerTransport - rcvSeq[${rcvSeq}], clientInfo - userSeq[${clientInfo.userSeq}], liveSeq[${clientInfo.liveSeq}], routingKey[${clientInfo.routingKey}]`);
    const data = {
        kind            : "mediaSoup",
        action          : "connectConsumerTransport",
        prodUserSeq     : rcvSeq,
        roomKind        : "live",
        liveSeq         : clientInfo.liveSeq,
        dtlsParameters  : dtlsParameters,
        consumUserSeq   : clientInfo.userSeq,
        sendRotingKey   : clientInfo.routingKey
    };

    sendObjectToRbmq(data);
}

/**
 * send changeConsumerScreen request to mediaSoup server
 * @param {Array} smallSeqArr 
 * @param {Array} bigSeqArr 
 */
export const sendChangeConsumerScreen = (smallSeqArr, bigSeqArr) => {
    console.log("sendChangeConsumerScreen - smallSeqArr => ", smallSeqArr);
    const data = {
        kind            : "mediaSoup",
        action          : "changeConsumerScreen",
        roomKind        : "live",
        liveSeq         : clientInfo.liveSeq,
        consumUserSeq   : clientInfo.userSeq,
        samllUserSeqArr : smallSeqArr,
        bigUserSeqArr   : bigSeqArr,
        sendRotingKey   : clientInfo.routingKey
    };
    
    sendObjectToRbmq(data);
}

/**  MEDIA SOUP RCV (consume-callback) */
/**
 * joinConsume 요청 후 서버에서 받은 데이터 처리
 * @param {JSON} rtnValue 
 */
export const joinConsumeAfter = (rtnValue) => {
    const { rcvUserSeq, roomKind, isVideoReceiveable, isAudioReceiveable, isMicReceiveable } = rtnValue;
    // console.log(`joinConsumeAfter - roomKind[${roomKind}], rcvUserSeq[${rcvUserSeq}], isVideoReceiveable[${isVideoReceiveable}], isAudioReceiveable[${isAudioReceiveable}], isMicReceiveable[${isMicReceiveable}]`);
    console.log(`joinConsumeAfter(${roomKind}) - rcvUserSeq[${rcvUserSeq}], isVideoReceiveable[${isVideoReceiveable}], isAudioReceiveable[${isAudioReceiveable}], isMicReceiveable[${isMicReceiveable}]`);

    if (roomKind === "handsup") { // handsup case
        const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === rcvUserSeq);
        if (consumeInfo) {
            consumeInfo.isAudioReceiveable = isAudioReceiveable;
            consumeInfo.isVideoReceiveable = isVideoReceiveable;
            console.log(`joinConsumeAfter(handsup) - mediaKind[${consumeInfo.mediaKind}]`);

            if (isVideoReceiveable || isAudioReceiveable) {
                if (clientInfo.rcvTransport !== null) {
                    if (consumeInfo.mediaKind === "video" || consumeInfo.mediaKind === "all") { // for smart tv & small group - video consume case by hjkim 20231108
                        console.log("joinConsumeAfter(handsup) case 1 - sendConsume(video)");
                        sendConsume("video", "handsup", rcvUserSeq);
                        // console.log("joinConsumeAfter(handsup) case 1-1 - consumeInfo.mediaKind init");
                        // consumeInfo.mediaKind = "";
                    } else { // default handsup - audio consume case by hjkim 20231108
                        console.log("joinConsumeAfter(handsup) case 2 - sendConsume(audio)");
                        sendConsume("audio", "handsup", rcvUserSeq);
                        // console.log("joinConsumeAfter(handsup) case 2-1 - consumeInfo.mediaKind init");
                        // consumeInfo.mediaKind = "";
                    }
                } else {
                    console.log("joinConsumeAfter(handsup) case 3 ... 이럴땐 어떻게해야하지..?");
                }
            } else {
                console.log("joinConsumeAfter(handsup) case 4");
            }
        } else {
            const p2pInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === rcvUserSeq);
            if (p2pInfo) {
                p2pInfo.isAudioReceiveable = isAudioReceiveable;
                p2pInfo.isVideoReceiveable = isVideoReceiveable;
                console.log(`joinConsumeAfter(handsup) - mediaKind[${p2pInfo.mediaKind}]`);

                if (isVideoReceiveable || isAudioReceiveable) {
                    if (clientInfo.rcvTransport !== null) {
                        if (p2pInfo.mediaKind === "video" || p2pInfo.mediaKind === "all") { // for smart tv & small group - video consume case by hjkim 20231108
                            console.log("joinConsumeAfter(handsup) case 5 - sendConsume(video)");
                            sendConsume("video", "handsup", rcvUserSeq);
                            // console.log("joinConsumeAfter(handsup) case 5-1 - p2pInfo.mediaKind init");
                            // p2pInfo.mediaKind = "";
                        } else { // default handsup - audio consume case by hjkim 20231108
                            console.log("joinConsumeAfter(handsup) case 6 - sendConsume(audio)");
                            sendConsume("audio", "handsup", rcvUserSeq);
                            // console.log("joinConsumeAfter(handsup) case 6-1 - p2pInfo.mediaKind init");
                            // p2pInfo.mediaKind = "";
                        }
                    } else {
                        console.log("joinConsumeAfter(handsup) case 7 ... 이럴땐 어떻게해야하지..?");
                    }
                } else {
                    console.log("joinConsumeAfter(handsup) case 8");
                }
            }
        }
    } else {
        clientInfo.consumeInfo.isVideoReceiveable = isVideoReceiveable;
        clientInfo.consumeInfo.isAudioReceiveable = isAudioReceiveable;
        clientInfo.consumeInfo.isMicReceiveable = isMicReceiveable;

        if (isVideoReceiveable || isAudioReceiveable || isMicReceiveable) {
            console.log("joinConsumeAfter(live) case 1");
            if (clientInfo.rtpCapabilities === undefined || clientInfo.rtpCapabilities === null) {
                clientInfo.rtpCapabilities = rtnValue.rtpCapabilities;
            }

            let store = window.hiclasstv.store;
            store.dispatch(changeLiveStatusInfo({ kind: "connect" }));
        } else {
            console.log("joinConsumeAfter(live) case 2");
            let store = window.hiclasstv.store;
            // store.dispatch(changeLiveStatusInfo({ kind: "unconnectable", status: "off" }));
            store.dispatch(changeLiveStatusInfo({ kind: "unconnectable", status: "disable" }));
            store.dispatch(toggleReadyLiveConsume({ isReadyLiveConsume: false }));
        }
    }
}

/**
 * exitConsume 요청 후 서버에서 받은 데이터 처리
 * @param {JSON} rtnValue 
 */
export const exitConsumeAfter = (rtnValue) => {
    const { roomKind, prodUserSeq } = rtnValue;
    console.log(`exitConsumeAfter -  roomKind[${roomKind}], prodUserSeq[${prodUserSeq}]`);

    if (roomKind === "handsup") {
        const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === prodUserSeq);
        if (consumeInfo) {
            console.log(`exitConsumeAfter - remove list_consumeInfo case prodUserSeq[${prodUserSeq}]`);
            let idx = clientInfo.handsupInfo.list_consumeInfo.indexOf(consumeInfo);
            clientInfo.handsupInfo.list_consumeInfo.splice(idx, 1);
        } else {
            const p2pInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === prodUserSeq);
            if (p2pInfo) {
                console.log(`exitConsumeAfter - remove list_consumeInfo case prodUserSeq[${prodUserSeq}]`);
                let idx = clientInfo.handsupInfo.list_p2pConsumeInfo.indexOf(p2pInfo);
                clientInfo.handsupInfo.list_p2pConsumeInfo.splice(idx, 1);
            } else {
                console.log(`exitConsumeAfter - already removed case prodUserSeq[${prodUserSeq}]`);
            }
        }
    } else {
        if (prodUserSeq === clientInfo.rcvTeacherSeq) {
            clientInfo.consumeInfo.isExitConsume = false;

            let store = window.hiclasstv.store;
            store.dispatch(changeLiveStatusInfo({ kind: "unconnectable", status: "disable" }));
        }
    }
}

/**
 * exitConsume 요청 후 서버에서 데이터 못 받는 경우
 * smart tv & qr login 처리 
 * @param {String} roomKind 
 * @param {Number} prodUserSeq 
 */
const exitConsumeAfterForCleaning = (roomKind, prodUserSeq) => {
    console.log(`exitConsumeAfterForCleaning - roomKind[${roomKind}], prodUserSeq[${prodUserSeq}]`);
    if (roomKind === "handsup") {
        const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === prodUserSeq);
        if (consumeInfo) {
            console.log(`exitConsumeAfterForCleaning - remove list_consumeInfo case prodUserSeq[${prodUserSeq}]`);
            let idx = clientInfo.handsupInfo.list_consumeInfo.indexOf(consumeInfo);
            clientInfo.handsupInfo.list_consumeInfo.splice(idx, 1);
        } else {
            const p2pInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === prodUserSeq);
            if (p2pInfo) {
                console.log(`exitConsumeAfterForCleaning - remove list_consumeInfo case prodUserSeq[${prodUserSeq}]`);
                let idx = clientInfo.handsupInfo.list_p2pConsumeInfo.indexOf(p2pInfo);
                clientInfo.handsupInfo.list_p2pConsumeInfo.splice(idx, 1);
            } else {
                console.log(`exitConsumeAfterForCleaning - already removed case prodUserSeq[${prodUserSeq}]`);
            }
        }
    } else {
        if (prodUserSeq === clientInfo.rcvTeacherSeq) {
            clientInfo.consumeInfo.isExitConsume = false;
    
            if (window.hiclasstv) {
                let store = window.hiclasstv.store;
                store.dispatch(changeLiveStatusInfo({ kind: "unconnectable", status: "disable" }));
            }
        }
    }
}

/**
 * createConsumerTransport 요청 후 서버에서 받은 데이터 처리
 * @param {JSON} rtnValue 
 */
export const createConsumerTransportAfter = async (rtnValue) => {
    const { rcvUserSeq, transportParams } = rtnValue;
    console.log(`createConsumerTransportAfter -  rcvUserSeq[${rcvUserSeq}]`);

    try {
        await loadDevice();
    } catch (err) {
        console.log("createConsumerTransportAfter(loadDevice) - err => ", err);
        if (err instanceof UnsupportedError) {
            alert(`[Unsupported] can not createConsumerTransportAfter(loadDevice) ... because ${err.message}`);
        } else if (err instanceof InvalidStateError) {
            alert(`[InvalidState] can not createConsumerTransportAfter(loadDevice) ... because ${err.message}`);
        } else if (err instanceof TypeError) {
            alert(`[TypeError] can not createConsumerTransportAfter(loadDevice) ... because ${err.message}`);
        } else {
            alert(`[Error] can not createConsumerTransportAfter(loadDevice) ... because ${err.message}`);
        }
    } finally {
        if (clientInfo.device === undefined || clientInfo.device === null) {
            console.log("createConsumerTransportAfter - clientInfo.device is => ", clientInfo.device);
            return;
        }

        let transport = null;

        try {
            transport = clientInfo.device.createRecvTransport({
                id: transportParams.id,
                dtlsParameters: transportParams.dtlsParameters,
                iceCandidates: transportParams.iceCandidates,
                iceParameters: transportParams.iceParameters
            });

            if (transport !== null) {
                transport.on("connect", ({ dtlsParameters }, callback, errback) => {
                    clientInfo.rcvTransportCallback = callback;
                    clientInfo.rcvTransportErrback = errback;
                    sendConnectConsumerTransport(dtlsParameters, rcvUserSeq);
                });

                transport.on("connectionstatechange", (state) => {
                    console.log(`consumer-transport connectionstatechange ... state[${state}]`);
                    switch (state) {
                        case "connecting": break;
                        case "connected": break;
                        case "closed": break;
                        case "failed":
                            if (!transport.closed) {
                                transport.close();
                            }
                            break;
                        default:
                            changeConnectionStateConsumerTransportAfter(state);
                            break;
                    }
                });

                clientInfo.rcvTransport = transport;
                sendConsume(clientInfo.consumeInfo.mediaKind, "live", rcvUserSeq);
            } else {
                console.log(`[Unsupported] can not create recv transport ... id[${transportParams.id}], transport => `, transport);
                alert(`[Unsupported] can not create recv transport ... id[${transportParams.id}]`);
            }
        } catch (err) {
            console.log("createConsumerTransportAfter(createRecvTransport) - err => ", err);
            if (err instanceof InvalidStateError) {
                alert(`[InvalidState] can not create recv transport ... because ${err.message}`);
            } else if (err instanceof TypeError) {
                alert(`[TypeError] can not create recv transport ... because ${err.message}`);
            } else {
                alert(`[Error] can not create recv transport ... because ${err.message}`);
            }
        }
    }
}

/**
 * connectConsumerTransport 요청 후 서버에서 받은 데이터 처리
 * @param {JSON} rtnValue 
 */
export const connectConsumerTransportAfter = (rtnValue) => {
    const { rcvUserSeq } = rtnValue;
    console.log(`connectConsumerTransportAfter -  rcvUserSeq[${rcvUserSeq}]`);
    clientInfo.rcvTransportCallback();
    let store = window.hiclasstv.store;
    store.dispatch(toggleReadyLiveConsume({ isReadyLiveConsume: true }));
}

/**
 * consume 요청 후 서버에서 받은 데이터 처리
 * @param {JSON} rtnValue 
 */
export const consumeAfter = async (rtnValue) => {
    const { roomKind, mediaKind, rcvUserSeq, parm } = rtnValue;
    const { producerId, id, rtpParameters } = parm;
    console.log(`consumeAfter -  roomKind[${roomKind}], mediaKind[${mediaKind}], rcvUserSeq[${rcvUserSeq}]`);

    if (clientInfo.rcvTransport === null) {
        console.log("consumeAfter() - clientInfo.rcvTransport is null");
        return;
    }

    let codecOptions = {};
    let consumer = null;

    try {
        consumer = await clientInfo.rcvTransport.consume({
            id,
            producerId,
            kind: mediaKind === "mic" ? "audio" : mediaKind,
            rtpParameters,
            codecOptions,
        });

        if (consumer !== null) {
            if (roomKind === "handsup") {
                consumer.on("transportclose", () => {
                    const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.audioConsumer === consumer);
                    if (consumeInfo) {
                        if (consumeInfo.audioConsumer) {
                            if (consumeInfo.audioStream) {
                                try {
                                    if (consumeInfo.audioEl) {
                                        consumeInfo.audioEl.pause();
                                    }
                                    consumeInfo.audioStream.removeTrack(consumer.track);

                                    if (!consumeInfo.audioConsumer.closed) {
                                        consumeInfo.audioConsumer.close();
                                    }

                                    consumeInfo.audioConsumer = null;
                                } catch (err) {
                                    console.log("------consumer.transportclose------error => ", err);
                                } finally {
                                    consumeInfo.audioStream = null;
                                }
                            }
                        }

                        // for smart tv & small group video ... by hjkim 20231108
                        if (consumeInfo.videoConsumer) {
                            if (consumeInfo.videoStream) {
                                try {
                                    if (consumeInfo.videoEl) {
                                        consumeInfo.videoEl.pause();
                                        consumeInfo.videoEl.currentTime = 0;
                                    }
                                    consumeInfo.videoStream.removeTrack(consumer.track);

                                    if (!consumeInfo.videoConsumer.closed) {
                                        consumeInfo.videoConsumer.close();
                                    }

                                    consumeInfo.videoConsumer = null;
                                } catch (err) {
                                    console.log("------consumer.transportclose------error => ", err);
                                } finally {
                                    consumeInfo.videoStream = null;
                                    // consumeInfo.videoEl.currentTime = 0;
                                    consumeInfo.videoEl.poster = "/images/connect.png";
                                    // consumeInfo.videoEl.poster = "/images/android_notconnected.png";
                                }
                            }
                        }
                    } else {
                        const p2pInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.audioConsumer === consumer);
                        if (p2pInfo) {
                            if (p2pInfo.audioConsumer) {
                                if (p2pInfo.audioStream) {
                                    try {
                                        p2pInfo.audioEl.pause();
                                        p2pInfo.audioStream.removeTrack(consumer.track);

                                        if (!consumeInfo.audioConsumer.closed) {
                                            consumeInfo.audioConsumer.close();
                                        }

                                        consumeInfo.audioConsumer = null;
                                    } catch (err) {
                                        console.log("------consumer.transportclose------error => ", err);
                                    } finally {
                                        p2pInfo.audioStream = null;
                                    }
                                }
                            }

                            // for smart tv & small group video ... by hjkim 20231108
                            if (p2pInfo.videoConsumer) {
                                if (p2pInfo.videoStream) {
                                    try {
                                        p2pInfo.videoEl.pause();
                                        p2pInfo.videoStream.removeTrack(consumer.track);

                                        if (!consumeInfo.videoConsumer.closed) {
                                            consumeInfo.videoConsumer.close();
                                        }

                                        consumeInfo.videoConsumer = null;
                                    } catch (err) {
                                        console.log("------consumer.transportclose------error => ", err);
                                    } finally {
                                        p2pInfo.videoStream = null;
                                        p2pInfo.videoEl.currentTime = 0;
                                        p2pInfo.videoEl.poster = "/images/connect.png";
                                        // p2pInfo.videoEl.poster = "/images/android_notconnected.png";
                                    }
                                }
                            }
                        }
                    }
                });

                if (mediaKind === "video") {
                    const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === rcvUserSeq);
                    if (consumeInfo) {
                        console.log(`(1) consumeAfter - rcvSeq[${consumeInfo.rcvSeq}], isVideoReceived[${consumeInfo.isVideoReceived}], isVideoReceiveable[${consumeInfo.isVideoReceiveable}]`);
                        if (consumeInfo.isVideoReceiveable && !consumeInfo.isVideoReceived) { // for smart tv & small group video ... by hjkim 20231108
                            consumeInfo.isVideoReceived = true;
                            consumeInfo.videoConsumer = consumer;

                            if (consumeInfo.videoStream === null) {
                                consumeInfo.videoStream = new MediaStream();
                            }

                            console.log(`(1) consumeAfter - rcvSeq[${consumeInfo.rcvSeq}], consumer.track => `, consumer.track);
                            consumeInfo.videoStream.addTrack(consumer.track);
                            console.log(`(1) consumeAfter - rcvSeq[${consumeInfo.rcvSeq}] addTrack done.`);

                            if (consumeInfo.isAudioReceiveable) {
                                if (consumeInfo.isAudioReceiveable && !consumeInfo.isAudioReceived) { // audio consume 해야하는 case ... by hjkim 20231108
                                    if (consumeInfo.isMuteAudio) { // audio mute mode 일 경우 audio consume 하지 않는다 ... by hjkim 20231108
                                        console.log("handsup(video) consumeAfter case 1");
                                        sendResume("video", roomKind, rcvUserSeq);
                                    } else { // audio consume ...
                                        console.log("handsup(video) consumeAfter case 2");
                                        sendConsume("audio", roomKind, rcvUserSeq);
                                    }
                                } else { // 이미 audio consume 중이거나 audio 없는 case ... by hjkim 20231108
                                    console.log("handsup(video) consumeAfter case 3");
                                    sendResume("video", roomKind, rcvUserSeq);
                                }
                            } else { // audio 없는 case ... by hjkim 20231108
                                console.log("handsup(video) consumeAfter case 4");
                                sendResume("video", roomKind, rcvUserSeq);
                            }
                        } else {
                            console.log("handsup(video) consumeAfter case 3");
                        }
                    } else {
                        const p2pInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === rcvUserSeq);
                        if (p2pInfo) {
                            console.log(`(2) consumeAfter - rcvSeq[${p2pInfo.rcvSeq}], isVideoReceived[${p2pInfo.isVideoReceived}], isVideoReceiveable[${p2pInfo.isVideoReceiveable}]`);
                            if (p2pInfo.isVideoReceiveable && !p2pInfo.isVideoReceived) { // for smart tv & small group video ... by hjkim 20231108
                                p2pInfo.isVideoReceived = true;
                                p2pInfo.videoConsumer = consumer;

                                if (p2pInfo.videoStream === null) {
                                    p2pInfo.videoStream = new MediaStream();
                                }

                                console.log(`(2) consumeAfter - rcvSeq[${p2pInfo.rcvSeq}], consumer.track => `, consumer.track);
                                p2pInfo.videoStream.addTrack(consumer.track);
                                console.log(`(2) consumeAfter - rcvSeq[${p2pInfo.rcvSeq}] addTrack done.`);

                                if (p2pInfo.isAudioReceiveable) {
                                    if (p2pInfo.isAudioReceiveable && !p2pInfo.isAudioReceived) { // audio consume 해야하는 case ... by hjkim 20231108
                                        if (p2pInfo.isMuteAudio) { // audio mute mode 일 경우 audio consume 하지 않는다 ... by hjkim 20231108
                                            console.log("handsup(video) consumeAfter case 5");
                                            sendResume("video", roomKind, rcvUserSeq);
                                        } else { // audio consume ...
                                            console.log("handsup(video) consumeAfter case 6");
                                            sendConsume("audio", roomKind, rcvUserSeq);
                                        }
                                    } else { // 이미 audio consume 중이거나 audio 없는 case ... by hjkim 20231108
                                        console.log("handsup(video) consumeAfter case 7");
                                        sendResume("video", roomKind, rcvUserSeq);
                                    }
                                } else { // audio 없는 case ... by hjkim 20231108
                                    console.log("handsup(video) consumeAfter case 8");
                                    sendResume("video", roomKind, rcvUserSeq);
                                }
                            } else {
                                console.log("handsup(video) consumeAfter case 9");
                            }
                        } else {
                            console.log("handsup(video) consumeAfter case 10");
                        }
                    }
                } else if (mediaKind === "audio") {
                    const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === rcvUserSeq);
                    if (consumeInfo) {
                        console.log(`(3) consumeAfter - rcvSeq[${consumeInfo.rcvSeq}], isAudioReceived[${consumeInfo.isAudioReceived}], isAudioReceiveable[${consumeInfo.isAudioReceiveable}], isMuteAudio[${consumeInfo.isMuteAudio}]`);
                        if (consumeInfo.isAudioReceiveable && !consumeInfo.isAudioReceived) {
                            consumeInfo.isAudioReceived = true;
                            consumeInfo.audioConsumer = consumer;

                            if (consumeInfo.isMuteAudio) {
                                console.log("handsup(audio) consumeAfter case 1");
                            } else {
                                if (consumeInfo.audioStream === null) {
                                    consumeInfo.audioStream = new MediaStream();
                                }

                                console.log(`(3) consumeAfter - rcvSeq[${consumeInfo.rcvSeq}], consumer.track => `, consumer.track);
                                consumeInfo.audioStream.addTrack(consumer.track);
                                console.log(`(3) consumeAfter - rcvSeq[${consumeInfo.rcvSeq}] addTrack done.`);

                                if (consumeInfo.audioEl === null) {
                                    const handsAudioConsumeDiv = document.getElementById("handsAudioConsumeDiv");

                                    let audioEl = document.createElement("audio");
                                    audioEl.id = "handsAudioCousumeEl-" + rcvUserSeq;
                                    audioEl.style.display = "none";
                                    audioEl.autoPlay = true;
                                    audioEl.autoplay = true;
                                    handsAudioConsumeDiv.appendChild(audioEl);

                                    consumeInfo.audioEl = audioEl;
                                }

                                console.log(`(3) consumeAfter - rcvSeq[${consumeInfo.rcvSeq}], consumeInfo.audioEl => `, consumeInfo.audioEl);
                                console.log(`(3) consumeAfter - rcvSeq[${consumeInfo.rcvSeq}], consumeInfo.audioStream => `, consumeInfo.audioStream);

                                consumeInfo.audioEl.srcObject = consumeInfo.audioStream;
                                consumeInfo.audioEl.play();

                                if (consumeInfo.isVideoReceiveable && consumeInfo.isVideoReceived) {
                                    if (consumeInfo.videoEl && consumeInfo.videoEl.srcObject === null) {
                                        console.log("handsup(audio) consumeAfter case 2");
                                        sendResume("video", roomKind, rcvUserSeq);
                                    } else {
                                        console.log("handsup(audio) consumeAfter case 3");
                                        sendResume("audio", roomKind, rcvUserSeq);
                                    }
                                } else {
                                    console.log("handsup(audio) consumeAfter case 4");
                                    sendResume("audio", roomKind, rcvUserSeq);
                                }
                            }
                        } else {
                            console.log("handsup(audio) consumeAfter case 5");
                        }
                    } else {
                        const p2pInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === rcvUserSeq);
                        if (p2pInfo) {
                            console.log(`(4) consumeAfter - rcvSeq[${p2pInfo.rcvSeq}], isAudioReceived[${p2pInfo.isAudioReceived}], isAudioReceiveable[${p2pInfo.isAudioReceiveable}], , isMuteAudio[${p2pInfo.isMuteAudio}]`);
                            if (p2pInfo.isAudioReceiveable && !p2pInfo.isAudioReceived) {
                                p2pInfo.isAudioReceived = true;
                                p2pInfo.audioConsumer = consumer;

                                if (p2pInfo.isMuteAudio) {
                                    console.log("handsup(audio) consumeAfter case 6");
                                } else {
                                    if (p2pInfo.audioStream === null) {
                                        p2pInfo.audioStream = new MediaStream();
                                    }

                                    console.log(`(4) consumeAfter - rcvSeq[${p2pInfo.rcvSeq}], consumer.track => `, consumer.track);
                                    p2pInfo.audioStream.addTrack(consumer.track);
                                    console.log(`(4) consumeAfter - rcvSeq[${p2pInfo.rcvSeq}] addTrack done.`);

                                    if (p2pInfo.audioEl === null) {
                                        const handsAudioConsumeDiv = document.getElementById("handsAudioConsumeDiv");

                                        let audioEl = document.createElement("audio");
                                        audioEl.id = "handsAudioCousumeEl-" + rcvUserSeq;
                                        audioEl.style.display = "none";
                                        audioEl.autoPlay = true;
                                        audioEl.autoplay = true;
                                        handsAudioConsumeDiv.appendChild(audioEl);

                                        p2pInfo.audioEl = audioEl;
                                    }

                                    console.log(`(3) consumeAfter - rcvSeq[${p2pInfo.rcvSeq}], p2pInfo.audioEl => `, p2pInfo.audioEl);
                                    console.log(`(3) consumeAfter - rcvSeq[${p2pInfo.rcvSeq}], p2pInfo.audioStream => `, p2pInfo.audioStream);

                                    p2pInfo.audioEl.srcObject = p2pInfo.audioStream;
                                    p2pInfo.audioEl.play();

                                    if (p2pInfo.isVideoReceiveable && p2pInfo.isVideoReceived) {
                                        if (p2pInfo.videoEl && p2pInfo.videoEl.srcObject === null) {
                                            console.log("handsup(audio) consumeAfter case 7");
                                            sendResume("video", roomKind, rcvUserSeq);
                                        } else {
                                            console.log("handsup(audio) consumeAfter case 8");
                                            sendResume("audio", roomKind, rcvUserSeq);
                                        }
                                    } else {
                                        console.log("handsup(audio) consumeAfter case 9");
                                        sendResume("audio", roomKind, rcvUserSeq);
                                    }
                                }
                            } else {
                                console.log("handsup(audio) consumeAfter case 10");
                            }
                        } else {
                            console.log("handsup(audio) consumeAfter case 11");
                        }
                    }
                }
            } else {
                consumer.on("transportclose", closeTransportAfter);

                if (mediaKind === "mic") {
                    console.log(`consumeAfter - mediaKind[${mediaKind}], isMicReceiveable[${clientInfo.consumeInfo.isMicReceiveable}], isMicReceived[${clientInfo.consumeInfo.isMicReceived}], isSelectedMode[${clientInfo.consumeInfo.isSelectedMode}]`);
                    if (!clientInfo.consumeInfo.isMicReceived) { // 중복 consume 방지용 ... by hjkim 20200210
                        clientInfo.consumeInfo.isMicReceived = true;
                        clientInfo.consumeInfo.micConsumer = consumer;

                        if (clientInfo.consumeInfo.isSelectedMode) {
                            if (clientInfo.consumeInfo.micStream === null) {
                                clientInfo.consumeInfo.micStream = new MediaStream();
                            }

                            clientInfo.consumeInfo.micStream.addTrack(clientInfo.consumeInfo.micConsumer.track);
                            clientInfo.consumeInfo.micEl.srcObject = clientInfo.consumeInfo.micStream;
                            if (ConstData.IS_LOCAL_VERSION) clientInfo.consumeInfo.micEl.volume = 0;
                            clientInfo.consumeInfo.micEl.play();

                            let store = window.hiclasstv.store;
                            store.dispatch(changeLiveStatusInfo({ kind: "mic", status: "on" }));
                        } else {
                            let store = window.hiclasstv.store;
                            store.dispatch(changeLiveStatusInfo({ kind: "mic", status: "off" }));
                        }

                        if (clientInfo.consumeInfo.isVideoReceiveable && clientInfo.consumeInfo.isVideoReceived) {
                            if (clientInfo.consumeInfo.videoEl && clientInfo.consumeInfo.videoEl.srcObject === null) {
                                sendResume("video", roomKind, rcvUserSeq);
                            }
                        }
                    }
                } else {
                    if (mediaKind === "video") {
                        console.log(`consumeAfter - mediaKind[${mediaKind}], isVideoReceiveable[${clientInfo.consumeInfo.isVideoReceiveable}], isVideoReceived[${clientInfo.consumeInfo.isVideoReceived}]`);
                        if (!clientInfo.consumeInfo.isVideoReceived) { // 중복 consume 방지용 ... by hjkim 20200210
                            clientInfo.consumeInfo.isVideoReceived = true;
                            clientInfo.consumeInfo.videoConsumer = consumer;

                            //console.log(`addTrack kind[${consumer.kind}] mediaKind[${mediaKind}]`);
                            if (clientInfo.consumeInfo.videoStream === null) {
                                clientInfo.consumeInfo.videoStream = new MediaStream();
                            }

                            clientInfo.consumeInfo.videoStream.addTrack(clientInfo.consumeInfo.videoConsumer.track);

                            if (clientInfo.consumeInfo.isAudioReceiveable || clientInfo.consumeInfo.isMicReceiveable) {
                                if (clientInfo.consumeInfo.isAudioReceiveable && !clientInfo.consumeInfo.isAudioReceived) { // audio consume 해야하는 case ... by hjkim 20220210
                                    if (clientInfo.consumeInfo.isAudioStop) { // 지금은 안 쓰는 기능이지만 선생님 소리 끄기용 ... by hjkim 20220210
                                        sendResume("video", roomKind, rcvUserSeq);

                                        let store = window.hiclasstv.store;
                                        store.dispatch(changeLiveStatusInfo({ kind: "audio", status: "off" }));
                                    } else { // audio consume ...
                                        sendConsume("audio", roomKind, rcvUserSeq);

                                        let store = window.hiclasstv.store;
                                        store.dispatch(changeLiveStatusInfo({ kind: "audio", status: "on" }));
                                    }
                                } else { // 이미 audio consume 중이거나 audio 없는 case ... by hjkim 20220210
                                    if (clientInfo.consumeInfo.isMicReceiveable && !clientInfo.consumeInfo.isMicReceived) { // mic consume 해야하는 case ... by hjkim 20220210
                                        sendConsume("mic", roomKind, rcvUserSeq);
                                    } else { // 이미 mic consume 중이거나 mic 없는 case ... by hjkim 20220210
                                        sendResume("video", roomKind, rcvUserSeq);
                                    }
                                }
                            } else { // audio, mic 없는 case 추가 ... by hjkim 20220210
                                sendResume("video", roomKind, rcvUserSeq);
                            }
                        }
                    } else if (mediaKind === "audio") {
                        console.log(`consumeAfter - mediaKind[${mediaKind}], isAudioReceiveable[${clientInfo.consumeInfo.isAudioReceiveable}], isAudioReceived[${clientInfo.consumeInfo.isAudioReceived}]`);
                        if (!clientInfo.consumeInfo.isAudioReceived) {
                            clientInfo.consumeInfo.isAudioReceived = true;

                            clientInfo.consumeInfo.audioConsumer = consumer;

                            if (clientInfo.consumeInfo.isMicReceiveable && !clientInfo.consumeInfo.isMicReceived) { // mic consume 해야하는 case ... by hjkim 20220210
                                if (clientInfo.consumeInfo.audioStream === null) {
                                    clientInfo.consumeInfo.audioStream = new MediaStream();
                                    //console.log(`(1)addTrack kind[${clientInfo.consumeInfo.audioConsumer.kind}] mediaKind[${mediaKind}]`);
                                    clientInfo.consumeInfo.audioStream.addTrack(clientInfo.consumeInfo.audioConsumer.track);
                                } else {
                                    if (clientInfo.consumeInfo.audioStream.getTracks().length > 0 || clientInfo.consumeInfo.audioStream.getTracks().length !== 0) {
                                        console.log(`(2)addTrack kind[${clientInfo.consumeInfo.audioConsumer.kind}] already has track`);
                                    } else {
                                        console.log(`(3)addTrack kind[${clientInfo.consumeInfo.audioConsumer.kind}] mediaKind[${mediaKind}]`);
                                        clientInfo.consumeInfo.audioStream.addTrack(clientInfo.consumeInfo.audioConsumer.track);
                                    }
                                }

                                if (clientInfo.consumeInfo.audioEl) {
                                    clientInfo.consumeInfo.audioEl.srcObject = clientInfo.consumeInfo.audioStream;
                                    if (ConstData.IS_LOCAL_VERSION) clientInfo.consumeInfo.audioEl.volume = 0;
                                    clientInfo.consumeInfo.audioEl.play();
                                }

                                sendConsume("mic", roomKind, rcvUserSeq);
                            } else { // 이미 mic consume 중이거나 mic 없는 case ... by hjkim 20220210
                                if (!clientInfo.consumeInfo.isSelectedMode) { // 셀렉트(선택) 안된 case ... by hjkim 20220210
                                    if (clientInfo.consumeInfo.audioStream === null) {
                                        clientInfo.consumeInfo.audioStream = new MediaStream();
                                        //console.log(`(4)addTrack kind[${clientInfo.consumeInfo.audioConsumer.kind}] mediaKind[${mediaKind}]`);
                                        clientInfo.consumeInfo.audioStream.addTrack(clientInfo.consumeInfo.audioConsumer.track);
                                    } else {
                                        if (clientInfo.consumeInfo.audioStream.getTracks().length > 0 || clientInfo.consumeInfo.audioStream.getTracks().length !== 0) {
                                            console.log(`(5)addTrack kind[${clientInfo.consumeInfo.audioConsumer.kind}] already has track`);
                                        } else {
                                            console.log(`(6)addTrack kind[${clientInfo.consumeInfo.audioConsumer.kind}] mediaKind[${mediaKind}]`);
                                            clientInfo.consumeInfo.audioStream.addTrack(clientInfo.consumeInfo.audioConsumer.track);
                                        }
                                    }

                                    if (clientInfo.consumeInfo.audioEl) {
                                        clientInfo.consumeInfo.audioEl.srcObject = clientInfo.consumeInfo.audioStream;
                                        if (ConstData.IS_LOCAL_VERSION) clientInfo.consumeInfo.audioEl.volume = 0;
                                        clientInfo.consumeInfo.audioEl.play();
                                    }

                                    if (clientInfo.consumeInfo.isVideoReceiveable && clientInfo.consumeInfo.isVideoReceived) {
                                        if (clientInfo.consumeInfo.videoEl && clientInfo.consumeInfo.videoEl.srcObject === null) {
                                            sendResume("video", roomKind, rcvUserSeq);
                                        } else {
                                            sendResume("audio", roomKind, rcvUserSeq);
                                        }
                                    } else {
                                        sendResume("audio", roomKind, rcvUserSeq);
                                    }
                                } else { // 셀렉트(선택)된 case ... by hjkim 20231113
                                    console.log("consumeAfter case else ... 셀렉트(선택)된 경우 ...");
                                    // 이 경우 audio consume은 play하지 않아야 하므로 다음 단계로 넘어가기 위해 video 있는 경우 sendResume
                                    if (clientInfo.consumeInfo.isVideoReceiveable && clientInfo.consumeInfo.isVideoReceived) {
                                        if (clientInfo.consumeInfo.videoEl && clientInfo.consumeInfo.videoEl.srcObject === null) {
                                            sendResume("video", roomKind, rcvUserSeq);
                                        } else {
                                            console.log("consumeAfter case else ... (1)");
                                        }
                                    } else {
                                        console.log("consumeAfter case else ... (2)");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            console.log(`[Unsupported] cannot consume this Producer ... producerId[${producerId}]`);
            alert(`[Unsupported] cannot consume this Producer ... producerId[${producerId}]`);
        }
    } catch (err) {
        console.log("consumeAfter() - err => ", err);
        if (err instanceof UnsupportedError) {
            alert(`[Unsupported] cannot consume ... because ${err.message}`);
        } else if (err instanceof InvalidStateError) {
            alert(`[InvalidState] cannot consume ... because ${err.message}`);
        } else if (err instanceof TypeError) {
            alert(`[TypeError] cannot consume ... because ${err.message}`);
        } else {
            alert(`[Error] cannot consume ... because ${err.message}`);
        }
    }
}

/**
 * resume 요청 후 서버에서 받은 데이터 처리
 * @param {JSON} rtnValue 
 */
export const resumeAfter = async (rtnValue) => {
    const { kind, roomKind, prodUserSeq } = rtnValue;
    console.log(`resumeAfter -  kind[${kind}], roomKind[${roomKind}], prodUserSeq[${prodUserSeq}]`);

    if (roomKind === "handsup") {
        console.log("resumeAfter roomKind is handsup");

        let consumer;

        if (kind === "video") {
            const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === prodUserSeq);
            if (consumeInfo) {
                consumer = consumeInfo.videoConsumer;
                consumer.resume();

                if (consumeInfo.videoStream === null) {
                    console.log(`(1) resumeAfter(handsup) - prodUserSeq[${prodUserSeq}], videoStream is null. addTrack. consumer.kind[${consumer.kind}], [${kind}]`);
                    consumeInfo.videoStream = new MediaStream();
                    consumeInfo.videoStream.addTrack(consumer.track);
                }

                console.log(`(1) resumeAfter(handsup) - prodUserSeq[${prodUserSeq}], consumeInfo.videoEl => `, consumeInfo.videoEl);
                console.log(`(1) resumeAfter(handsup) - prodUserSeq[${prodUserSeq}], consumeInfo.videoStream => `, consumeInfo.videoStream);

                consumeInfo.videoEl.srcObject = consumeInfo.videoStream;
                consumeInfo.videoEl.play();

                // callCurrentFuncProc(prodUserSeq, "image_full_mode");
                callCurrentFuncProc(prodUserSeq, "connect-client");
            } else {
                const p2pInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === prodUserSeq);
                if (p2pInfo) {
                    consumer = p2pInfo.videoConsumer;
                    consumer.resume();

                    if (p2pInfo.videoStream === null) {
                        console.log(`(2) resumeAfter(handsup) - prodUserSeq[${prodUserSeq}], videoStream is null. addTrack. consumer.kind[${consumer.kind}], [${kind}]`);
                        p2pInfo.videoStream = new MediaStream();
                        p2pInfo.videoStream.addTrack(consumer.track);
                    }

                    console.log(`(2) resumeAfter(handsup) - prodUserSeq[${prodUserSeq}], p2pInfo.videoEl => `, p2pInfo.videoEl);
                    console.log(`(2) resumeAfter(handsup) - prodUserSeq[${prodUserSeq}], p2pInfo.videoStream => `, p2pInfo.videoStream);

                    p2pInfo.videoEl.srcObject = p2pInfo.videoStream;
                    p2pInfo.videoEl.play();

                    // callCurrentFuncProc(prodUserSeq, "image_full_mode");
                    callCurrentFuncProc(prodUserSeq, "connect-client");
                }
            }
        } else if (kind === "audio") {
            const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === prodUserSeq);
            if (consumeInfo) {
                consumer = consumeInfo.audioConsumer;
                consumer.resume();

                if (consumeInfo.audioStream === null) {
                    consumeInfo.audioStream = new MediaStream();
                    console.log(`(3) resumeAfter(handsup) - audioStream is null. addTrack. consumer.kind[${consumer.kind}], [${kind}]`);
                    consumeInfo.audioStream.addTrack(consumer.track);
                } else {
                    if (consumeInfo.audioStream.getTracks().length > 0 || consumeInfo.audioStream.getTracks().length !== 0) {
                        console.log(`(4) resumeAfter(handsup) - addTrack consumer.kind[${consumer.kind}] already has track`);
                    } else {
                        console.log(`(5) resumeAfter(handsup) - addTrack consumer.kind[${consumer.kind}] kind[${kind}]`);
                        consumeInfo.audioStream.addTrack(consumer.track);
                    }
                }

                consumeInfo.audioEl.srcObject = consumeInfo.audioStream;
                if (ConstData.IS_LOCAL_VERSION) consumeInfo.audioEl.volume = 0;
                consumeInfo.audioEl.play();
            } else {
                const p2pInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === prodUserSeq);
                if (p2pInfo) {
                    consumer = p2pInfo.audioConsumer;
                    consumer.resume();

                    if (p2pInfo.audioStream === null) {
                        p2pInfo.audioStream = new MediaStream();
                        console.log(`(6) resumeAfter(handsup) - audioStream is null. addTrack. consumer.kind[${consumer.kind}], [${kind}]`);
                        p2pInfo.audioStream.addTrack(consumer.track);
                    } else {
                        if (p2pInfo.audioStream.getTracks().length > 0 || p2pInfo.audioStream.getTracks().length !== 0) {
                            console.log(`(7) resumeAfter(handsup) - addTrack consumer.kind[${consumer.kind}] already has track`);
                        } else {
                            console.log(`(8) resumeAfter(handsup) - addTrack consumer.kind[${consumer.kind}] kind[${kind}]`);
                            p2pInfo.audioStream.addTrack(consumer.track);
                        }
                    }

                    p2pInfo.audioEl.srcObject = p2pInfo.audioStream;
                    if (ConstData.IS_LOCAL_VERSION) p2pInfo.audioEl.volume = 0;
                    p2pInfo.audioEl.play();
                }
            }
        }
    } else {
        let consumer;

        if (kind === "video") {
            consumer = clientInfo.consumeInfo.videoConsumer;
            consumer.resume();

            if (clientInfo.consumeInfo.videoStream === null) {
                console.log(`resumeAfter - videoStream is null. addTrack. consumer.kind[${consumer.kind}], [${kind}]`);
                clientInfo.consumeInfo.videoStream = new MediaStream();
                clientInfo.consumeInfo.videoStream.addTrack(consumer.track);
            }

            clientInfo.consumeInfo.videoEl.srcObject = clientInfo.consumeInfo.videoStream;

            let store = window.hiclasstv.store;
            store.dispatch(changeLiveStatusInfo({ kind: "video", status: "on" }));
        } else if (kind === "audio") {
            consumer = clientInfo.consumeInfo.audioConsumer;
            consumer.resume();

            if (clientInfo.consumeInfo.audioStream === null) {
                clientInfo.consumeInfo.audioStream = new MediaStream();
                //console.log(`(1)resumeAfter addTrack consumer.kind[${clientInfo.consumeInfo.audioConsumer.kind}] kind[${kind}]`);
                clientInfo.consumeInfo.audioStream.addTrack(clientInfo.consumeInfo.audioConsumer.track);
            } else {
                if (clientInfo.consumeInfo.audioStream.getTracks().length > 0 || clientInfo.consumeInfo.audioStream.getTracks().length !== 0) {
                    console.log(`(2)resumeAfter addTrack consumer.kind[${clientInfo.consumeInfo.audioConsumer.kind}] already has track`);
                } else {
                    console.log(`(3)resumeAfter addTrack consumer.kind[${clientInfo.consumeInfo.audioConsumer.kind}] kind[${kind}]`);
                    clientInfo.consumeInfo.audioStream.addTrack(clientInfo.consumeInfo.audioConsumer.track);
                }
            }

            clientInfo.consumeInfo.audioEl.srcObject = clientInfo.consumeInfo.audioStream;
            if (ConstData.IS_LOCAL_VERSION) clientInfo.consumeInfo.audioEl.volume = 0;
            clientInfo.consumeInfo.audioEl.play();

            let store = window.hiclasstv.store;
            store.dispatch(changeLiveStatusInfo({ kind: "audio", status: "on" }));
        }
    }
}

/**
 * joinConsume 요청했던 사용자의 새로운 produce 이벤트 발생 시 서버에서 보내주는 데이터 처리
 * @param {String} roomKind 
 * @param {JSON} rtnValue 
 */
export const newProducerAfter = (roomKind, rtnValue) => {
    const { userSeq, isVideoReceiveable, isAudioReceiveable, isMicReceiveable } = rtnValue;
    console.log(`newProducerAfter - roomKind[${roomKind}], userSeq[${userSeq}], isVideoReceiveable[${isVideoReceiveable}], isAudioReceiveable[${isAudioReceiveable}], isMicReceiveable[${isMicReceiveable}]`);

    if (clientInfo.rtpCapabilities === undefined || clientInfo.rtpCapabilities === null) {
        clientInfo.rtpCapabilities = rtnValue.rtpCapabilities;
    }

    if (roomKind === "handsup") {
        const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === userSeq);
        if (consumeInfo) {
            console.log(`(1) newProducerAfter - rcvSeq[${consumeInfo.rcvSeq}], isAudioReceived[${consumeInfo.isAudioReceived}], isAudioReceiveable[${consumeInfo.isAudioReceiveable}]`);
            console.log(`(1) newProducerAfter - rcvSeq[${consumeInfo.rcvSeq}], isVideoReceived[${consumeInfo.isVideoReceived}], isVideoReceiveable[${consumeInfo.isVideoReceiveable}]`);
            consumeInfo.isAudioReceiveable = isAudioReceiveable;
            consumeInfo.isVideoReceiveable = isVideoReceiveable;

            if (isVideoReceiveable || isAudioReceiveable) {
                console.log(`(1) newProducerAfter - rcvSeq[${consumeInfo.rcvSeq}], isVideoReceived[${consumeInfo.isVideoReceived}], isAudioReceived[${consumeInfo.isAudioReceived}]`);
                if (clientInfo.rcvTransport !== null) {
                    if (consumeInfo.mediaKind === "video" || consumeInfo.mediaKind === "all") { // for smart tv & small group - video + audio consume case by hjkim 20231108
                        if (isVideoReceiveable && !consumeInfo.isVideoReceived) { // for smart tv & small group - video consume 요청해야하는 case by hjkim 20231108
                            console.log("newProducerAfter(handsup) case 1 - sendConsume(video)");
                            sendConsume("video", "handsup", userSeq);
                        } else {
                            if (isAudioReceiveable && !consumeInfo.isAudioReceived) { // for smart tv & small group - audio consume 요청해야하는 case by hjkim 20231108
                                console.log("newProducerAfter(handsup) case 2 - sendConsume(audio)");
                                sendConsume("audio", "handsup", userSeq);
                            } else {
                                console.log("newProducerAfter(handsup) case 3 ... 이런 경우엔 뭘해야하지?ㅁ?");
                            }
                        }
                    } else { // default handsup - audio consume case by hjkim 20231108
                        if (isAudioReceiveable && !consumeInfo.isAudioReceived) { // default handsup - audio consume 요청해야하는 case by hjkim 20231108
                            console.log("newProducerAfter(handsup) case 4 - sendConsume(audio)");
                            sendConsume("audio", "handsup", userSeq);
                        } else {
                            console.log("newProducerAfter(handsup) case 5 ... 이런 경우엔 뭘해야하지?ㅁ?");
                        }
                    }
                } else {
                    console.log("newProducerAfter(handsup) case 6 ... 이럴땐 어떻게해야하지..?");
                }
            } else {
                console.log("newProducerAfter(handsup) case 7");
            }
        } else {
            const p2pInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === userSeq);
            if (p2pInfo) {
                console.log(`(2) newProducerAfter - rcvSeq[${p2pInfo.rcvSeq}], isAudioReceived[${p2pInfo.isAudioReceived}], isAudioReceiveable[${p2pInfo.isAudioReceiveable}]`);
                console.log(`(2) newProducerAfter - rcvSeq[${p2pInfo.rcvSeq}], isVideoReceived[${p2pInfo.isVideoReceived}], isVideoReceiveable[${p2pInfo.isVideoReceiveable}]`);
                p2pInfo.isAudioReceiveable = isAudioReceiveable;
                p2pInfo.isVideoReceiveable = isVideoReceiveable;

                if (isVideoReceiveable || isAudioReceiveable) {
                    console.log(`(1) newProducerAfter - rcvSeq[${p2pInfo.rcvSeq}], isVideoReceived[${p2pInfo.isVideoReceived}], isAudioReceived[${p2pInfo.isAudioReceived}]`);
                    if (clientInfo.rcvTransport !== null) {
                        if (p2pInfo.mediaKind === "video" || p2pInfo.mediaKind === "all") { // for smart tv & small group - video + audio consume case by hjkim 20231108
                            if (isVideoReceiveable && !p2pInfo.isVideoReceived) { // for smart tv & small group - video consume 요청해야하는 case by hjkim 20231108
                                console.log("newProducerAfter(handsup) case 8 - sendConsume(video)");
                                sendConsume("video", "handsup", userSeq);
                            } else {
                                if (isAudioReceiveable && !p2pInfo.isAudioReceived) { // for smart tv & small group - audio consume 요청해야하는 case by hjkim 20231108
                                    console.log("newProducerAfter(handsup) case 9 - sendConsume(audio)");
                                    sendConsume("audio", "handsup", userSeq);
                                } else {
                                    console.log("newProducerAfter(handsup) case 10 ... 이런 경우엔 뭘해야하지?ㅁ?");
                                }
                            }
                        } else { // default handsup - audio consume case by hjkim 20231108
                            if (isAudioReceiveable && !p2pInfo.isAudioReceived) { // default handsup - audio consume 요청해야하는 case by hjkim 20231108
                                console.log("newProducerAfter(handsup) case 11 - sendConsume(audio)");
                                sendConsume("audio", "handsup", userSeq);
                            } else {
                                console.log("newProducerAfter(handsup) case 12 ... 이런 경우엔 뭘해야하지?ㅁ?");
                            }
                        }
                    } else {
                        console.log("newProducerAfter(handsup) case 13 ... 이럴땐 어떻게해야하지..?");
                    }
                } else {
                    console.log("newProducerAfter(handsup) case 14");
                }
            }
        }
    } else {
        clientInfo.consumeInfo.isVideoReceiveable = isVideoReceiveable;
        clientInfo.consumeInfo.isAudioReceiveable = isAudioReceiveable;
        clientInfo.consumeInfo.isMicReceiveable = isMicReceiveable;

        if (isVideoReceiveable || isAudioReceiveable || isMicReceiveable) {
            console.log(`newProducerAfter - isVideoReceived[${clientInfo.consumeInfo.isVideoReceived}], isAudioReceived[${clientInfo.consumeInfo.isAudioReceived}], isMicReceived[${clientInfo.consumeInfo.isMicReceived}]`);
            if (clientInfo.rcvTransport === null) { // 학생이 미리 입장 후 live 시작될 때 video, audio, mic consume 요청해야하는 case - by hjkim 20220209
                console.log("newProducerAfter - case 1");
                let store = window.hiclasstv.store;
                store.dispatch(changeLiveStatusInfo({ kind: "connect" }));
            } else { // 무언가 하나라도 consume 중인 case ... by hjkim 20220401
                if (isVideoReceiveable && !clientInfo.consumeInfo.isVideoReceived) { // video consume 요청해야하는 case by hjkim 20220401
                    console.log("newProducerAfter - case 2");
                    sendConsume("video", "live", clientInfo.rcvTeacherSeq);
                } else {
                    if (isAudioReceiveable && !clientInfo.consumeInfo.isAudioReceived) { // audio consume 요청해야하는 case by hjkim 20220401
                        console.log("newProducerAfter - case 3");
                        sendConsume("audio", "live", clientInfo.rcvTeacherSeq);
                    } else {
                        if (isMicReceiveable && !clientInfo.consumeInfo.isMicReceived) { // mic consume 요청해야하는 case - by hjkim 20220401
                            console.log("newProducerAfter - case 4");
                            sendConsume("mic", "live", clientInfo.rcvTeacherSeq);
                        } else {
                            console.log("newProducerAfter - case 5 ... 이런 경우엔 뭘해야하지?ㅁ?");
                        }
                    }
                }
            }
        } else {
            console.log("newProducerAfter - case 6");
            let store = window.hiclasstv.store;
            // store.dispatch(changeLiveStatusInfo({ kind: "unconnectable", status: "off" }));
            store.dispatch(changeLiveStatusInfo({ kind: "unconnectable", status: "disable" }));
            store.dispatch(toggleReadyLiveConsume({ isReadyLiveConsume: false }));
        }
    }
}

/**
 * joinConsume 요청했던 사용자의 produce 종료 이벤트 발생 시 서버에서 보내주는 데이터 처리
 * @param {String} roomKind 
 * @param {JSON} rtnValue 
 */
export const closeProducerAfter = async (roomKind, rtnValue) => {
    const { kind, userSeq } = rtnValue;
    console.log(`closeProducerAfter -  roomKind[${roomKind}], kind[${kind}], userSeq[${userSeq}], rtnValue => `, rtnValue);

    if (roomKind === "handsup") {
        const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === userSeq);
        if (consumeInfo) {
            if (kind === "audio" || kind === "ALL") {
                if (consumeInfo.audioConsumer) {
                    consumeInfo.isAudioReceived = false;
                    consumeInfo.isAudioReceiveable = false;

                    if (consumeInfo.audioEl) {
                        consumeInfo.audioEl.pause();
                        consumeInfo.audioEl.srcObject = null;
                    }

                    if (consumeInfo.audioStream !== null) {
                        console.log(`(1) closeProducerAfter -  kind[${kind}], userSeq[${userSeq}]`);
                        consumeInfo.audioStream.removeTrack(consumeInfo.audioConsumer.track);
                        consumeInfo.audioStream = null;
                    }

                    if (!consumeInfo.audioConsumer.closed) {
                        consumeInfo.audioConsumer.close();
                    }

                    consumeInfo.audioConsumer = null;
                }
            }

            if (kind === "video" || kind === "ALL") {
                if (consumeInfo.videoConsumer) {
                    consumeInfo.isVideoReceived = false;
                    consumeInfo.isVideoReceiveable = false;

                    if (consumeInfo.videoStream) {
                        try {
                            console.log(`(2) closeProducerAfter -  kind[${kind}], userSeq[${userSeq}]`);
                            if (consumeInfo.videoEl) {
                                consumeInfo.videoEl.pause();
                            }
                            consumeInfo.videoStream.removeTrack(consumeInfo.videoConsumer.track);
                        } catch (err) {
                            console.log("------closeProducerAfter------error => ", err);
                        } finally {
                            consumeInfo.videoStream = null;
                            if (consumeInfo.videoEl) {
                                consumeInfo.videoEl.srcObject = consumeInfo.videoStream;
                                consumeInfo.videoEl.currentTime = 0;
                                consumeInfo.videoEl.poster = "/images/connect.png";    
                                // consumeInfo.videoEl.poster = "/images/android_notconnected.png";
                            }
                        }
                    }

                    if (!consumeInfo.videoConsumer.closed) {
                        consumeInfo.videoConsumer.close();
                    }

                    consumeInfo.videoConsumer = null;
                }
            }
        } else {
            const p2pInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === userSeq);
            if (p2pInfo) {
                if (kind === "audio" || kind === "ALL") {
                    if (p2pInfo.audioConsumer) {
                        p2pInfo.isAudioReceived = false;
                        p2pInfo.isAudioReceiveable = false;

                        if (p2pInfo.audioEl) {
                            p2pInfo.audioEl.pause();
                            p2pInfo.audioEl.srcObject = null;
                        }

                        if (p2pInfo.audioStream !== null) {
                            console.log(`(3) closeProducerAfter -  kind[${kind}], userSeq[${userSeq}]`);
                            p2pInfo.audioStream.removeTrack(p2pInfo.audioConsumer.track);
                            p2pInfo.audioStream = null;
                        }

                        if (!p2pInfo.audioConsumer.closed) {
                            p2pInfo.audioConsumer.close();
                        }

                        p2pInfo.audioConsumer = null;
                    }
                }

                if (kind === "video" || kind === "ALL") {
                    if (p2pInfo.videoConsumer) {
                        p2pInfo.isVideoReceived = false;
                        p2pInfo.isVideoReceiveable = false;

                        if (p2pInfo.videoStream) {
                            try {
                                console.log(`(4) closeProducerAfter -  kind[${kind}], userSeq[${userSeq}]`);
                                p2pInfo.videoEl.pause();
                                p2pInfo.videoStream.removeTrack(p2pInfo.videoConsumer.track);
                            } catch (err) {
                                console.log("------closeProducerAfter------error => ", err);
                            } finally {
                                p2pInfo.videoStream = null;
                                p2pInfo.videoEl.currentTime = 0;
                                p2pInfo.videoEl.poster = "/images/connect.png";
                                // p2pInfo.videoEl.poster = "/images/android_notconnected.png";
                            }
                        }

                        if (!p2pInfo.videoConsumer.closed) {
                            p2pInfo.videoConsumer.close();
                        }

                        p2pInfo.videoConsumer = null;
                    }
                }
            }
        }
    } else {
        if (kind === "mic" || kind === "ALL") {
            if (clientInfo.consumeInfo.micConsumer) {
                clientInfo.consumeInfo.isMicReceived = false;
                clientInfo.consumeInfo.isMicReceiveable = false;

                if (clientInfo.consumeInfo.micEl) {
                    clientInfo.consumeInfo.micEl.pause();
                    clientInfo.consumeInfo.micEl.srcObject = null;
                }

                if (clientInfo.consumeInfo.micStream) {
                    clientInfo.consumeInfo.micStream.removeTrack(clientInfo.consumeInfo.micConsumer.track);
                }

                if (!clientInfo.consumeInfo.micConsumer.closed) {
                    clientInfo.consumeInfo.micConsumer.close();
                }

                clientInfo.consumeInfo.micStream = null;
                clientInfo.consumeInfo.micConsumer = null;
            }

            clientInfo.consumeInfo.isMuteMic = true;

            let store = window.hiclasstv.store;
            store.dispatch(changeLiveStatusInfo({ kind: "mic", status: "disable" }));
        }

        if (kind === "audio" || kind === "ALL") {
            if (clientInfo.consumeInfo.audioConsumer) {
                clientInfo.consumeInfo.isAudioReceived = false;
                clientInfo.consumeInfo.isAudioReceiveable = false;

                if (clientInfo.consumeInfo.audioEl) {
                    clientInfo.consumeInfo.audioEl.pause();
                    clientInfo.consumeInfo.audioEl.srcObject = null;
                }

                if (clientInfo.consumeInfo.audioStream) {
                    clientInfo.consumeInfo.audioStream.removeTrack(clientInfo.consumeInfo.audioConsumer.track);
                }

                if (!clientInfo.consumeInfo.audioConsumer.closed) {
                    clientInfo.consumeInfo.audioConsumer.close();
                }

                clientInfo.consumeInfo.audioStream = null;
                clientInfo.consumeInfo.audioConsumer = null;
            }

            clientInfo.consumeInfo.isMuteAudio = false;

            let store = window.hiclasstv.store;
            store.dispatch(changeLiveStatusInfo({ kind: "audio", status: "disable" }));
        }

        if (kind === "video" || kind === "ALL") {
            if (clientInfo.consumeInfo.videoConsumer) {
                clientInfo.consumeInfo.isVideoReceived = false;
                clientInfo.consumeInfo.isVideoReceiveable = false;

                if (clientInfo.consumeInfo.videoEl) {
                    clientInfo.consumeInfo.videoEl.pause();
                    clientInfo.consumeInfo.videoEl.srcObject = null;
                }

                if (clientInfo.consumeInfo.videoStream) {
                    clientInfo.consumeInfo.videoStream.removeTrack(clientInfo.consumeInfo.videoConsumer.track);
                }

                if (!clientInfo.consumeInfo.videoConsumer.closed) {
                    clientInfo.consumeInfo.videoConsumer.close();
                }

                clientInfo.consumeInfo.videoStream = null;
                clientInfo.consumeInfo.videoConsumer = null;
            }

            let store = window.hiclasstv.store;
            store.dispatch(changeLiveStatusInfo({ kind: "video", status: "disable" }));
        }

        if (clientInfo.consumeInfo.micConsumer === null && clientInfo.consumeInfo.audioConsumer === null && clientInfo.consumeInfo.videoConsumer === null) {
            if (clientInfo.rcvTransport) {
                if (!clientInfo.rcvTransport.closed) {
                    clientInfo.rcvTransport.close();
                }

                clientInfo.rcvTransport = null;
            }

            let store = window.hiclasstv.store;
            // store.dispatch(changeLiveStatusInfo({ kind: "unconnectable", status: "off" }));
            store.dispatch(changeLiveStatusInfo({ kind: "unconnectable", status: "disable" }));
            store.dispatch(toggleReadyLiveConsume({ isReadyLiveConsume: false }));
        }
    }
}

/**
 * unsubscribe 요청 후 서버에서 받은 데이터 처리
 * @param {JSON} rtnValue 
 */
export const unsubscribeAfter = async (rtnValue) => {
    const { consumUserSeq, isForSoundOnly, prodUserSeq, roomKind } = rtnValue;
    console.log(`unsubscribeAfter -  roomKind[${roomKind}], prodUserSeq[${prodUserSeq}], consumUserSeq[${consumUserSeq}], isForSoundOnly[${isForSoundOnly}]`);

    if (consumUserSeq === clientInfo.userSeq) {
        if (roomKind === "handsup") {
            const consumeInfo = clientInfo.handsupInfo.list_consumeInfo.find(info => info.rcvSeq === prodUserSeq);
            if (consumeInfo) {
                // isForSoundOnly 값과 상관없이 audio 는 항상 unsubscribe 해야 하기 때문에 true인지 체크 안하고 바로 처리... by hjkim 20231108
                // let idx = clientInfo.handsupInfo.list_consumeInfo.indexOf(consumeInfo);
                if (consumeInfo.audioConsumer) {
                    if (consumeInfo.audioStream) {
                        if (consumeInfo.audioEl) {
                            if (consumeInfo.audioEl.parentNode) {
                                consumeInfo.audioEl.parentNode.removeChild(consumeInfo.audioEl);
                            }

                            consumeInfo.audioEl = null;
                        }

                        consumeInfo.audioStream.removeTrack(consumeInfo.audioConsumer.track);
                        consumeInfo.audioStream = null;
                    }

                    if (!consumeInfo.audioConsumer.closed) {
                        consumeInfo.audioConsumer.close();
                    }

                    consumeInfo.audioConsumer = null;
                }

                if (isForSoundOnly === false) { // isForSoundOnly 가 false 면 all unsubscribe 이기 때문에 video 를 여기서 처리... by hjkim 20231108
                    if (consumeInfo.videoConsumer) {
                        if (consumeInfo.videoStream) {
                            if (consumeInfo.videoEl) {
                                consumeInfo.videoEl.pause();
                                consumeInfo.videoEl.currentTime = 0;
                            }

                            consumeInfo.videoStream.removeTrack(consumeInfo.videoConsumer.track);
                            consumeInfo.videoStream = null;
                        }

                        if (!consumeInfo.videoConsumer.closed) {
                            consumeInfo.videoConsumer.close();
                        }

                        consumeInfo.videoConsumer = null;

                        if (consumeInfo.videoEl) {
                            // consumeInfo.videoEl.currentTime = 0;
                            consumeInfo.videoEl.poster = "/images/connect.png";
                            // consumeInfo.videoEl.poster = "/images/android_notconnected.png";
                        }
                    }
                }

                // if (consumeInfo.isExitConsume) {
                    sendExitConsumeHandsUp(consumeInfo.isAudioReceiveable, consumeInfo.rcvSeq);
                // }

                exitConsumeAfterForCleaning(roomKind, consumeInfo.rcvSeq);

                callCurrentFuncProc(prodUserSeq, "disconnect");
            } else {
                const p2pInfo = clientInfo.handsupInfo.list_p2pConsumeInfo.find(info => info.rcvSeq === prodUserSeq);
                if (p2pInfo) {
                    // isForSoundOnly 값과 상관없이 audio 는 항상 unsubscribe 해야 하기 때문에 true인지 체크 안하고 바로 처리... by hjkim 20231108
                    // let idx = clientInfo.handsupInfo.list_p2pConsumeInfo.indexOf(p2pInfo);
                    if (p2pInfo.audioConsumer) {
                        if (p2pInfo.audioStream) {
                            if (p2pInfo.audioEl) {
                                if (p2pInfo.audioEl.parentNode) {
                                    p2pInfo.audioEl.parentNode.removeChild(p2pInfo.audioEl);
                                }

                                p2pInfo.audioEl = null;
                            }

                            p2pInfo.audioStream.removeTrack(p2pInfo.audioConsumer.track);
                            p2pInfo.audioStream = null;
                        }

                        if (!p2pInfo.audioConsumer.closed) {
                            p2pInfo.audioConsumer.close();
                        }

                        p2pInfo.audioConsumer = null;
                    }

                    if (isForSoundOnly === false) { // isForSoundOnly 가 false 면 all unsubscribe 이기 때문에 video 를 여기서 처리... by hjkim 20231108
                        if (p2pInfo.videoConsumer) {
                            if (p2pInfo.videoStream) {
                                if (p2pInfo.videoEl) {
                                    p2pInfo.videoEl.pause();
                                    p2pInfo.videoEl.currentTime = 0;
                                }

                                p2pInfo.videoStream.removeTrack(p2pInfo.videoConsumer.track);
                                p2pInfo.videoStream = null;
                            }

                            if (!p2pInfo.videoConsumer.closed) {
                                p2pInfo.videoConsumer.close();
                            }

                            p2pInfo.videoConsumer = null;

                            if (p2pInfo.videoEl) {
                                // consumeInfo.videoEl.currentTime = 0;
                                p2pInfo.videoEl.poster = "/images/connect.png";
                                // p2pInfo.videoEl.poster = "/images/android_notconnected.png";
                            }
                        }
                    }

                    // if (p2pInfo.isExitConsume) {
                        sendExitConsumeHandsUp(p2pInfo.isAudioReceiveable, p2pInfo.rcvSeq);
                    // }

                    exitConsumeAfterForCleaning(roomKind, p2pInfo.rcvSeq);

                    callCurrentFuncProc(prodUserSeq, "disconnect");
                }
            }
        } else {
            // isForSoundOnly 값과 상관없이 audio / mic 는 항상 unsubscribe 해야 하기 때문에 true인지 체크 안하고 바로 처리... by hjkim 20220209
            if (clientInfo.consumeInfo.micConsumer) {
                clientInfo.consumeInfo.isMicReceived = false;

                if (clientInfo.consumeInfo.micEl) {
                    clientInfo.consumeInfo.micEl.pause();
                    clientInfo.consumeInfo.micEl.srcObject = null;
                }

                if (clientInfo.consumeInfo.micStream) {
                    clientInfo.consumeInfo.micStream.removeTrack(clientInfo.consumeInfo.micConsumer.track);
                }

                if (!clientInfo.consumeInfo.micConsumer.closed) {
                    clientInfo.consumeInfo.micConsumer.close();
                }

                clientInfo.consumeInfo.micStream = null;
                clientInfo.consumeInfo.micConsumer = null;

                let store = window.hiclasstv.store;
                store.dispatch(changeLiveStatusInfo({ kind: "mic", status: "off" }));
            }

            if (clientInfo.consumeInfo.audioConsumer) {
                clientInfo.consumeInfo.isAudioReceived = false;

                if (clientInfo.consumeInfo.audioEl) {
                    clientInfo.consumeInfo.audioEl.pause();
                    clientInfo.consumeInfo.audioEl.srcObject = null;
                }

                if (clientInfo.consumeInfo.audioStream) {
                    clientInfo.consumeInfo.audioStream.removeTrack(clientInfo.consumeInfo.audioConsumer.track);
                }

                if (!clientInfo.consumeInfo.audioConsumer.closed) {
                    clientInfo.consumeInfo.audioConsumer.close();
                }

                clientInfo.consumeInfo.audioStream = null;
                clientInfo.consumeInfo.audioConsumer = null;

                let store = window.hiclasstv.store;
                store.dispatch(changeLiveStatusInfo({ kind: "audio", status: "off" }));
            }

            if (isForSoundOnly === false) { // isForSoundOnly 가 false 면 all unsubscribe 이기 때문에 video 를 여기서 처리... by hjkim 20220209
                if (clientInfo.consumeInfo.videoConsumer) {
                    clientInfo.consumeInfo.isVideoReceived = false;

                    if (clientInfo.consumeInfo.videoEl) {
                        clientInfo.consumeInfo.videoEl.pause();
                        clientInfo.consumeInfo.videoEl.srcObject = null;
                    }

                    if (clientInfo.consumeInfo.videoStream) {
                        clientInfo.consumeInfo.videoStream.removeTrack(clientInfo.consumeInfo.videoConsumer.track);
                    }

                    if (!clientInfo.consumeInfo.videoConsumer.closed) {
                        clientInfo.consumeInfo.videoConsumer.close();
                    }

                    clientInfo.consumeInfo.videoStream = null;
                    clientInfo.consumeInfo.videoConsumer = null;

                    let store = window.hiclasstv.store;
                    store.dispatch(changeLiveStatusInfo({ kind: "video", status: "off" }));
                }

                if (clientInfo.rcvTransport) {
                    if (!clientInfo.rcvTransport.closed) {
                        clientInfo.rcvTransport.close();
                    }

                    clientInfo.rcvTransport = null;

                    let store = window.hiclasstv.store;
                    store.dispatch(toggleReadyLiveConsume({ isReadyLiveConsume: false }));
                }

                if (clientInfo.consumeInfo.isExitConsume) {
                    sendExitConsume(prodUserSeq);
                }

                let store = window.hiclasstv.store;
                store.dispatch(changeLiveStatusInfo({ kind: "disconnect", status: "off" }));
            }
        }
    } else {
        console.log("------unsubscribeAfter------ consumUserSeq is ", consumUserSeq);
    }
}

/** 
 * unsubscribe 요청 후 서버에서 데이터 데이터 못 받는
 * smart tv handsup consumer 정리
 */
const unsubscribeHandsUpConsumeAfterForClear = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.handsupInfo.list_p2pConsumeInfo && clientInfo.handsupInfo.list_p2pConsumeInfo.length > 0) {
                const promises = clientInfo.handsupInfo.list_p2pConsumeInfo.map((info) => clearHandsUpConsume(false, info.rcvSeq));
                await Promise.all(promises);

                clientInfo.handsupInfo.list_p2pConsumeInfo = [];
            }

            if (clientInfo.handsupInfo.list_consumeInfo && clientInfo.handsupInfo.list_consumeInfo.length > 0) {
                const promises = clientInfo.handsupInfo.list_consumeInfo.map((info) => clearHandsUpConsume(false, info.rcvSeq));
                await Promise.all(promises);

                clientInfo.handsupInfo.list_consumeInfo = [];
            }

            resolve(true);
        } catch (err) {
            console.log("unsubscribeHandsUpConsumeAfterForClear err => ", err);
            reject(err);
        }
    });
}

/**
 * unsubscribe 요청 후 서버에서 데이터 데이터 못 받는
 * smart tv consumer, transport 정리
 */
export const unsubscribeAfterForSmartTV = async () => {
    console.log("unsubscribeAfterForSmartTV");
    await unsubscribeHandsUpConsumeAfterForClear();

    // isForSoundOnly 값과 상관없이 audio / mic 는 항상 unsubscribe 해야 하기 때문에 true인지 체크 안하고 바로 처리... by hjkim 20220209
    if (clientInfo.consumeInfo.micConsumer) {
        clientInfo.consumeInfo.isMicReceived = false;

        if (clientInfo.consumeInfo.micEl) {
            clientInfo.consumeInfo.micEl.pause();
            clientInfo.consumeInfo.micEl.srcObject = null;
        }

        if (clientInfo.consumeInfo.micStream) {
            clientInfo.consumeInfo.micStream.removeTrack(clientInfo.consumeInfo.micConsumer.track);
        }

        if (!clientInfo.consumeInfo.micConsumer.closed) {
            clientInfo.consumeInfo.micConsumer.close();
        }

        clientInfo.consumeInfo.micStream = null;
        clientInfo.consumeInfo.micConsumer = null;

        let store = window.hiclasstv.store;
        store.dispatch(changeLiveStatusInfo({ kind: "mic", status: "off" }));
    }

    if (clientInfo.consumeInfo.audioConsumer) {
        clientInfo.consumeInfo.isAudioReceived = false;

        if (clientInfo.consumeInfo.audioEl) {
            clientInfo.consumeInfo.audioEl.pause();
            clientInfo.consumeInfo.audioEl.srcObject = null;
        }

        if (clientInfo.consumeInfo.audioStream) {
            clientInfo.consumeInfo.audioStream.removeTrack(clientInfo.consumeInfo.audioConsumer.track);
        }

        if (!clientInfo.consumeInfo.audioConsumer.closed) {
            clientInfo.consumeInfo.audioConsumer.close();
        }

        clientInfo.consumeInfo.audioStream = null;
        clientInfo.consumeInfo.audioConsumer = null;

        let store = window.hiclasstv.store;
        store.dispatch(changeLiveStatusInfo({ kind: "audio", status: "off" }));
    }

    if (clientInfo.consumeInfo.videoConsumer) {
        clientInfo.consumeInfo.isVideoReceived = false;

        if (clientInfo.consumeInfo.videoEl) {
            clientInfo.consumeInfo.videoEl.pause();
            clientInfo.consumeInfo.videoEl.srcObject = null;
        }

        if (clientInfo.consumeInfo.videoStream) {
            clientInfo.consumeInfo.videoStream.removeTrack(clientInfo.consumeInfo.videoConsumer.track);
        }

        if (!clientInfo.consumeInfo.videoConsumer.closed) {
            clientInfo.consumeInfo.videoConsumer.close();
        }

        clientInfo.consumeInfo.videoStream = null;
        clientInfo.consumeInfo.videoConsumer = null;

        let store = window.hiclasstv.store;
        store.dispatch(changeLiveStatusInfo({ kind: "video", status: "off" }));
    }

    if (clientInfo.rcvTransport) {
        if (!clientInfo.rcvTransport.closed) {
            clientInfo.rcvTransport.close();
        }

        clientInfo.rcvTransport = null;

        console.log("call toggleReadyLiveConsume");
        let store = window.hiclasstv.store;
        store.dispatch(toggleReadyLiveConsume({ isReadyLiveConsume: false }));
    }

    /* if (clientInfo.consumeInfo.isExitConsume) {
        sendExitConsume(clientInfo.rcvTeacherSeq);
    } */

    sendExitConsume(clientInfo.rcvTeacherSeq);

    /* if (window.hiclasstv) {
        let store = window.hiclasstv.store;
        store.dispatch(changeLiveStatusInfo({ kind: "disconnect", status: "off" }));
    } */

    exitConsumeAfterForCleaning("live", clientInfo.rcvTeacherSeq);
}

/**
 * consumer transport에서
 * connection state change event 발생 시 데이터 처리
 * @private 
 */
const changeConnectionStateConsumerTransportAfter = async (kind) => {
    console.log(`changeConnectionStateConsumerTransportAfter -  kind[${kind}], prodUserSeq[${clientInfo.userSeq}], consumUserSeq[${clientInfo.rcvTeacherSeq}]`);
    if (kind === "disconnected") {
        await unsubscribeHandsUpConsumeAfterForClear();

        // isForSoundOnly 값과 상관없이 audio / mic 는 항상 unsubscribe 해야 하기 때문에 true인지 체크 안하고 바로 처리... by hjkim 20230403
        if (clientInfo.consumeInfo.micConsumer) {
            clientInfo.consumeInfo.isMicReceived = false;

            if (clientInfo.consumeInfo.micEl) {
                clientInfo.consumeInfo.micEl.pause();
                clientInfo.consumeInfo.micEl.srcObject = null;
            }

            if (clientInfo.consumeInfo.micStream) {
                clientInfo.consumeInfo.micStream.removeTrack(clientInfo.consumeInfo.micConsumer.track);
            }

            if (!clientInfo.consumeInfo.micConsumer.closed) {
                clientInfo.consumeInfo.micConsumer.close();
            }

            clientInfo.consumeInfo.micStream = null;
            clientInfo.consumeInfo.micConsumer = null;

            let store = window.hiclasstv.store;
            store.dispatch(changeLiveStatusInfo({ kind: "mic", status: "off" }));
        }

        if (clientInfo.consumeInfo.audioConsumer) {
            clientInfo.consumeInfo.isAudioReceived = false;

            if (clientInfo.consumeInfo.audioEl) {
                clientInfo.consumeInfo.audioEl.pause();
                clientInfo.consumeInfo.audioEl.srcObject = null;
            }

            if (clientInfo.consumeInfo.audioStream) {
                clientInfo.consumeInfo.audioStream.removeTrack(clientInfo.consumeInfo.audioConsumer.track);
            }

            if (!clientInfo.consumeInfo.audioConsumer.closed) {
                clientInfo.consumeInfo.audioConsumer.close();
            }

            clientInfo.consumeInfo.audioStream = null;
            clientInfo.consumeInfo.audioConsumer = null;

            let store = window.hiclasstv.store;
            store.dispatch(changeLiveStatusInfo({ kind: "audio", status: "off" }));
        }

        if (clientInfo.consumeInfo.videoConsumer) {
            clientInfo.consumeInfo.isVideoReceived = false;

            if (clientInfo.consumeInfo.videoEl) {
                clientInfo.consumeInfo.videoEl.pause();
                clientInfo.consumeInfo.videoEl.srcObject = null;
            }

            if (clientInfo.consumeInfo.videoStream) {
                clientInfo.consumeInfo.videoStream.removeTrack(clientInfo.consumeInfo.videoConsumer.track);
            }

            if (!clientInfo.consumeInfo.videoConsumer.closed) {
                clientInfo.consumeInfo.videoConsumer.close();
            }

            clientInfo.consumeInfo.videoStream = null;
            clientInfo.consumeInfo.videoConsumer = null;

            let store = window.hiclasstv.store;
            store.dispatch(changeLiveStatusInfo({ kind: "video", status: "off" }));
        }

        if (clientInfo.rcvTransport) {
            if (!clientInfo.rcvTransport.closed) {
                clientInfo.rcvTransport.close();
            }

            clientInfo.rcvTransport = null;

            let store = window.hiclasstv.store;
            store.dispatch(toggleReadyLiveConsume({ isReadyLiveConsume: false }));
        }

        /* if (clientInfo.consumeInfo.isExitConsume) {
            sendExitConsume(clientInfo.rcvTeacherSeq);
        } */

        sendExitConsume(clientInfo.rcvTeacherSeq);

        if (window.hiclasstv) {
            let store = window.hiclasstv.store;
            store.dispatch(changeLiveStatusInfo({ kind: "disconnect", status: "off" }));
        }

        exitConsumeAfterForCleaning("live", clientInfo.rcvTeacherSeq);
    }
}

/**
 * transport가 close되었을 경우 데이터 처리
 * @private 
 */
const closeTransportAfter = async () => {
    await unsubscribeHandsUpConsumeAfterForClear();

    if (clientInfo.consumeInfo.micConsumer) {
        clientInfo.consumeInfo.isMicReceived = false;
        clientInfo.consumeInfo.isMuteMic = true;

        if (clientInfo.consumeInfo.micStream) {
            if (clientInfo.consumeInfo.micEl && clientInfo.consumeInfo.micEl.srcObject !== null) {
                clientInfo.consumeInfo.micEl.pause();
                clientInfo.consumeInfo.micEl.srcObject = null;
            }

            clientInfo.consumeInfo.micStream.removeTrack(clientInfo.consumeInfo.micConsumer.track);
            clientInfo.consumeInfo.micStream = null;
        }

        clientInfo.consumeInfo.micConsumer = null;
    }

    if (clientInfo.consumeInfo.audioConsumer) {
        clientInfo.consumeInfo.isAudioReceived = false;
        clientInfo.consumeInfo.isMuteAudio = true;

        if (clientInfo.consumeInfo.audioStream) {
            clientInfo.consumeInfo.audioStream.removeTrack(clientInfo.consumeInfo.audioConsumer.track);
        }

        clientInfo.consumeInfo.audioStream = null;
        clientInfo.consumeInfo.audioConsumer = null;
    }

    if (clientInfo.consumeInfo.videoConsumer) {
        clientInfo.consumeInfo.isVideoReceived = false;

        if (clientInfo.consumeInfo.videoStream) {
            clientInfo.consumeInfo.videoStream.removeTrack(clientInfo.consumeInfo.videoConsumer.track);
        }

        clientInfo.consumeInfo.videoStream = null;
        clientInfo.consumeInfo.videoConsumer = null;
    }

    clientInfo.rcvTransport = null;

    let store = window.hiclasstv.store;
    // store.dispatch(changeLiveStatusInfo({ kind: "videoDisable", status: "disable" }));
    // store.dispatch(changeLiveStatusInfo({ kind: "unconnectable", status: "off" }));
    store.dispatch(changeLiveStatusInfo({ kind: "unconnectable", status: "disable" }));
    store.dispatch(toggleReadyLiveConsume({ isReadyLiveConsume: false }));
}

/**  MEDIA SOUP (produce) */
const produce = async (transport, track, isSimulcast) => {
    const params = { track: track };
    let producer = null;

    if (isSimulcast) {
        params.encodings = [
            { maxBitrate: 100000 },
            { maxBitrate: 300000 },
            { maxBitrate: 900000 },
        ];
        params.codecOptions = {
            videoGoogleStartBitrate: 1000
        };
    }

    try {
        producer = await transport.produce(params);

        if (producer === null) {
            console.log(`[Unsupported] cannot produce this track ... isSimulcast[${isSimulcast}]`);
            alert(`[Unsupported] cannot produce this track ... isSimulcast[${isSimulcast}]`);
        }
    } catch (err) {
        console.log("produce() - err => ", err);
        if (err instanceof UnsupportedError) {
            alert(`[Unsupported] cannot produce ... because ${err.message}`);
        } else if (err instanceof InvalidStateError) {
            alert(`[InvalidState] cannot produce ... because ${err.message}`);
        } else if (err instanceof TypeError) {
            alert(`[TypeError] cannot produce ... because ${err.message}`);
        } else {
            alert(`[Error] cannot produce ... because ${err.message}`);
        }
    }

    return producer;
}

/**
 * audio produce 시작
 * @param {MediaStreamTrack} track 
 */
export const produceAudio = async (track) => {
    console.log("produceAudio---000");
    clientInfo.produceInfo.audioStatus = "start";
    if (track !== undefined && track !== null) {
        clientInfo.produceInfo.audioTrack = track;
        //clientInfo.produceInfo.audioTrack.enabled = false;    // default mode enabled로 변경 by hjkim 20201218
    }
    console.log("produceAudio---111");

    if (clientInfo.sendTransportStatus === "ended") {
        console.log("produceAudio---222");
        if (clientInfo.sendTransport === null) {
            console.log("produceAudio---333");
            clientInfo.sendTransportStatus = "transport";
            sendCreateProducerTransport();
            console.log("produceAudio---444");
        } else {
            console.log("produceAudio---555");
            clientInfo.sendTransportStatus = "audio";
            if (clientInfo.produceInfo.audioProducer === null) {
                console.log("produceAudio---666");
                try {
                    console.log("produceAudio---777");
                    const isSimulcast = false;
                    const producer = await produce(clientInfo.sendTransport, clientInfo.produceInfo.audioTrack, isSimulcast);
                    clientInfo.produceInfo.audioProducer = producer;
                    console.log("produceAudio---888");
                } catch (err) {
                    console.log("produceAudio(produce) - err => ", err);
                }
            } else {
                console.log("produceAudio---999");
                await clientInfo.produceInfo.audioProducer.replaceTrack({ track: clientInfo.produceInfo.audioTrack });
                clientInfo.sendTransportStatus = "ended";
                clientInfo.produceInfo.audioStatus = "ended";
                console.log("produceAudio---101010");
            }
            console.log("produceAudio---11 11 11");
        }
    } else {
        console.log(`produceAudio - clientInfo.sendTransportStatus[${clientInfo.sendTransportStatus}]`);
    }
}

/**
 * video produce 시작
 * @param {MediaStreamTrack} track 
 * @param {Boolean} isHighQualityMode 
 * @param {Boolean} isCameraStream 
 */
export const produceVideo = async (track, isHighQualityMode, isCameraStream) => {
    console.log("produceVideo---000");
    clientInfo.produceInfo.videoStatus = "start";
    if (track !== undefined && track !== null) {
        clientInfo.produceInfo.videoTrack = track;
        console.log("produceVideo---111");

        let band_info = {};

        if (isHighQualityMode) {
            if (isCameraStream) {
                console.log("produceVideo - isHighQualityMode is true - camera stream");
                band_info = videoBand.cameraMax;
                // await clientInfo.produceInfo.videoTrack.applyConstraints({ width: 640, height: 360, frameRate: 16, resizeMode: "crop-and-scale" });
                console.log("produceVideo - setting band info -> camera max band info (1)");
            } else {
                console.log("produceVideo - isHighQualityMode is true - screen stream");
                band_info = videoBand.screenMax;
                // await clientInfo.produceInfo.videoTrack.applyConstraints({ width: 1280, height: 720, frameRate: 24, resizeMode: "crop-and-scale" });
                // await clientInfo.produceInfo.videoTrack.applyConstraints({ width: 1920, height: 1080, frameRate: 24, resizeMode: "crop-and-scale" });
                console.log("produceVideo - setting band info -> screen max band info (2)");
            }
        } else {
            console.log("produceVideo - isHighQualityMode is false");
            band_info = videoBand.default;
            console.log("produceVideo - setting band info -> default band info (3)");
        }

        await clientInfo.produceInfo.videoTrack.applyConstraints({
            width: band_info.width,
            height: band_info.height,
            frameRate: band_info.frameRate,
            resizeMode: "crop-and-scale"
        });

        console.log("produceVideo - videoTrack.applyConstraints is done.");
    }

    if (clientInfo.sendTransportStatus === "ended") {
        console.log("produceVideo---222");
        if (clientInfo.sendTransport === null) {
            clientInfo.sendTransportStatus = "transport";
            sendCreateProducerTransport();
            console.log("produceVideo---333");
        } else {
            clientInfo.sendTransportStatus = "video";
            console.log("produceVideo---444");
            if (clientInfo.produceInfo.videoProducer === null) {
                console.log("produceVideo---555");
                try {
                    const isSimulcast = false;
                    const producer = await produce(clientInfo.sendTransport, clientInfo.produceInfo.videoTrack, isSimulcast);
                    clientInfo.produceInfo.videoProducer = producer;
                    console.log("produceVideo---666");
                } catch (err) {
                    console.log("produceVideo(produce) - err => ", err);
                }
            } else {
                console.log("produceVideo---777");
                await clientInfo.produceInfo.videoProducer.replaceTrack({ track: clientInfo.produceInfo.videoTrack });
                console.log("produceVideo---888");
                clientInfo.sendTransportStatus = "ended";
                console.log("produceVideo---999");
                clientInfo.produceInfo.videoStatus = "ended";
            }
        }
    } else {
        console.log(`produceVideo - clientInfo.sendTransportStatus[${clientInfo.sendTransportStatus}]`);
    }
}

/**
 * video produce 시작
 * @param {MediaStreamTrack} track 
 * @param {Boolean} isP2PMode 
 * @param {Boolean} isSmartTVMode 
 * @param {Boolean} isCameraStream 
 */
export const produceVideoForTest = async (track, isP2PMode, isSmartTVMode, isCameraStream) => {
    console.log("produceVideoForTest---000");
    clientInfo.produceInfo.videoStatus = "start";
    if (track instanceof MediaStreamTrack) {
        clientInfo.produceInfo.videoTrack = track;
        console.log("produceVideoForTest---111");

        let band_info = {};

        if (isP2PMode) {
            if (isCameraStream) {
                console.log("(1) produceVideoForTest - isP2PMode is true - camera stream");
                band_info = videoBand.cameraMax;
                console.log("(1) produceVideoForTest - setting band info -> camera max band info");
            } else {
                console.log("(2) produceVideoForTest - isP2PMode is true - screen stream");
                band_info = videoBand.screenMax;
                console.log("(2) produceVideoForTest - setting band info -> screen max band info");
            }
        } else {
            if (isSmartTVMode) {
                if (isCameraStream) {
                    console.log("(3) produceVideoForTest - isP2PMode is false, isSmartTVMode is true - camera stream");
                    band_info = videoBand.cameraMax;
                    console.log("(3) produceVideoForTest - setting band info -> camera max band info");
                } else {
                    console.log("(4) produceVideoForTest - isP2PMode is false, isSmartTVMode is true - screen stream");
                    band_info = videoBand.smartTVDefault;
                    console.log("(4) produceVideoForTest - setting band info -> smarttv band info");
                }
            } else {
                console.log("(5) produceVideoForTest - isP2PMode is false, isSmartTVMode is false");
                band_info = videoBand.default;
                console.log("(5) produceVideoForTest - setting band info -> default band info");
            }
        }

        console.log("produceVideoForTest - band_info => ", band_info);

        await clientInfo.produceInfo.videoTrack.applyConstraints({
            width: band_info.width,
            height: band_info.height,
            frameRate: band_info.frameRate,
            resizeMode: "crop-and-scale"
        });

        console.log("produceVideoForTest - videoTrack.applyConstraints is done.");

        if (clientInfo.sendTransportStatus === "ended") {
            console.log("produceVideoForTest---222");
            if (clientInfo.sendTransport === null) {
                clientInfo.sendTransportStatus = "transport";
                sendCreateProducerTransport();
                console.log("produceVideoForTest---333");
            } else {
                clientInfo.sendTransportStatus = "video";
                console.log("produceVideoForTest---444");
                if (clientInfo.produceInfo.videoProducer === null) {
                    console.log("produceVideoForTest---555");
                    try {
                        const isSimulcast = false;
                        const producer = await produce(clientInfo.sendTransport, clientInfo.produceInfo.videoTrack, isSimulcast);
                        clientInfo.produceInfo.videoProducer = producer;
                        console.log("produceVideoForTest---666");
                    } catch (err) {
                        console.log("produceVideoForTest(produce) - err => ", err);
                    }
                } else {
                    console.log("produceVideoForTest---777");
                    await clientInfo.produceInfo.videoProducer.replaceTrack({ track: clientInfo.produceInfo.videoTrack });
                    console.log("produceVideoForTest---888");
                    clientInfo.sendTransportStatus = "ended";
                    console.log("produceVideoForTest---999");
                    clientInfo.produceInfo.videoStatus = "ended";
                }
            }
        } else {
            console.log(`produceVideoForTest - clientInfo.sendTransportStatus[${clientInfo.sendTransportStatus}]`);
        }
    }
}

/**
 * video track Resolution 변경
 * @param {Boolean} isHighQualityMode 
 * @param {Boolean} isCameraStream 
 * @returns {Promise<Boolean>}
 */
export const setVideoResolution = async (isHighQualityMode, isCameraStream) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.produceInfo.videoProducer) {
                if (clientInfo.produceInfo.videoProducer.track) {
                    if (isHighQualityMode) {
                        if (isCameraStream) {
                            console.log("setVideoResolution - setVideoResolution - isHighQualityMode is true - camera stream");
                            await clientInfo.produceInfo.videoProducer.track.applyConstraints({ width: 640, height: 360, frameRate: 16, resizeMode: "crop-and-scale" });
                            console.log("setVideoResolution - videoTrack.applyConstraints is done (1)");
                        } else {
                            console.log("setVideoResolution - isHighQualityMode is true - display stream");
                            await clientInfo.produceInfo.videoProducer.track.applyConstraints({ width: 1280, height: 720, frameRate: 24, resizeMode: "crop-and-scale" });
                            console.log("setVideoResolution - videoTrack.applyConstraints is done (2)");
                        }
                    } else {
                        console.log("setVideoResolution - isHighQualityMode is false");
                        await clientInfo.produceInfo.videoProducer.track.applyConstraints({ width: 240, height: 135, frameRate: 5, resizeMode: "crop-and-scale" });
                        console.log("setVideoResolution - videoTrack.applyConstraints is done (3)");
                    }
                }
            }

            resolve();
        } catch (err) {
            console.log("setVideoResolution - err => ", err);
        }
    });
}

/**
 * video track Resolution 변경
 * @param {Boolean} isP2PMode 
 * @param {Boolean} isSmartTVMode  
 * @param {Boolean} isCameraStream 
 * @returns {Promise<Boolean>}
 */
export const setVideoResolutionForTest = async (isP2PMode, isSmartTVMode, isCameraStream) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.produceInfo.videoProducer) {
                if (clientInfo.produceInfo.videoProducer.track) {
                    let band_info = {};

                    if (isP2PMode) {
                        if (isCameraStream) {
                            console.log("(1) setVideoResolutionForTest - isP2PMode is true - camera stream");
                            band_info = videoBand.cameraMax;
                            console.log("(1) setVideoResolutionForTest - setting band info -> camera max band info");
                        } else {
                            console.log("(2) setVideoResolutionForTest - isP2PMode is true - screen stream");
                            band_info = videoBand.screenMax;
                            console.log("(2) setVideoResolutionForTest - setting band info -> screen max band info");
                        }
                    } else {
                        if (isSmartTVMode) {
                            if (isCameraStream) {
                                console.log("(3) setVideoResolutionForTest - isP2PMode is false, isSmartTVMode is true - camera stream");
                                band_info = videoBand.cameraMax;
                                console.log("(3) setVideoResolutionForTest - setting band info -> camera max band info");
                            } else {
                                console.log("(4) setVideoResolutionForTest - isP2PMode is false, isSmartTVMode is true - screen stream");
                                band_info = videoBand.smartTVDefault;
                                console.log("(4) setVideoResolutionForTest - setting band info -> smarttv band info");
                            }
                        } else {
                            console.log("(5) setVideoResolutionForTest - isP2PMode is false, isSmartTVMode is false");
                            band_info = videoBand.default;
                            console.log("(5) setVideoResolutionForTest - setting band info -> default band info");
                        }
                    }

                    console.log("setVideoResolutionForTest - band_info => ", band_info);

                    await clientInfo.produceInfo.videoTrack.applyConstraints({
                        width: band_info.width,
                        height: band_info.height,
                        frameRate: band_info.frameRate,
                        resizeMode: "crop-and-scale"
                    });

                    console.log("setVideoResolutionForTest - videoTrack.applyConstraints is done.");
                }
            }

            resolve();
        } catch (err) {
            console.log("setVideoResolutionForTest - err => ", err);
        }
    });
}

/**
 * 
 * @param {Boolean} isHighQualityMode 
 * @param {Boolean} isCameraStream 
 * @param {Number} width 
 * @param {Number} height 
 * @param {Number} frameRate 
 * @returns {Promise<Boolean>}
 */
export const setScreenVideoResolutionValue = async (isHighQualityMode, isCameraStream, width, height, frameRate) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.produceInfo.videoProducer) {
                if (clientInfo.produceInfo.videoProducer.track) {
                    if (isHighQualityMode) {
                        if (isCameraStream) {
                            console.log("setScreenVideoResolutionValue - isHighQualityMode is true - now camera stream");
                            console.log("setScreenVideoResolutionValue - just setting screen max band value. (1)");
                        } else {
                            console.log("setScreenVideoResolutionValue - isHighQualityMode is true - now screen stream");
                            console.log("setScreenVideoResolutionValue - setting sceen max band and change constraints");
                        }
                    } else {
                        console.log("setScreenVideoResolutionValue - isHighQualityMode is false");
                        console.log("setScreenVideoResolutionValue - just setting screen max band value. (2)");
                    }

                    videoBand.screenMax.width = width;
                    videoBand.screenMax.height = height;
                    videoBand.screenMax.frameRate = frameRate;

                    console.log("setScreenVideoResolutionValue - setting screen max band is done.");

                    if (isHighQualityMode) {
                        if (!isCameraStream) {
                            await clientInfo.produceInfo.videoProducer.track.applyConstraints({
                                width: videoBand.screenMax.width,
                                height: videoBand.screenMax.height,
                                frameRate: videoBand.screenMax.frameRate,
                                resizeMode: "crop-and-scale"
                            });

                            console.log("setScreenVideoResolutionValue - videoTrack.applyConstraints is done.");
                        }
                    }
                }
            }

            resolve();
        } catch (err) {
            console.log("setScreenVideoResolutionValue - err => ", err);
        }
    });
}

/**
 * 
 * @param {Boolean} isHighQualityMode 
 * @param {Boolean} isCameraStream 
 * @param {Number} width 
 * @param {Number} height 
 * @param {Number} frameRate 
 * @returns {Promise<Boolean>}
 */
export const setCameraVideoResolutionValue = async (isHighQualityMode, isCameraStream, width, height, frameRate) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (clientInfo.produceInfo.videoProducer) {
                if (clientInfo.produceInfo.videoProducer.track) {
                    if (isHighQualityMode) {
                        if (isCameraStream) {
                            console.log("setCameraVideoResolutionValue - isHighQualityMode is true - now camera stream");
                            console.log("setCameraVideoResolutionValue - setting camera max band and change constraints");
                        } else {
                            console.log("setCameraVideoResolutionValue - isHighQualityMode is true - now screen stream");
                            console.log("setCameraVideoResolutionValue - just setting camera max band value. (1)");
                        }
                    } else {
                        console.log("setCameraVideoResolutionValue - isHighQualityMode is false");
                        console.log("setCameraVideoResolutionValue - just setting camera max band value. (2)");
                    }

                    videoBand.cameraMax.width = width;
                    videoBand.cameraMax.height = height;
                    videoBand.cameraMax.frameRate = frameRate;

                    console.log("setCameraVideoResolutionValue - setting camera max band is done.");

                    if (isHighQualityMode) {
                        if (isCameraStream) {
                            await clientInfo.produceInfo.videoProducer.track.applyConstraints({
                                width: videoBand.cameraMax.width,
                                height: videoBand.cameraMax.height,
                                frameRate: videoBand.cameraMax.frameRate,
                                resizeMode: "crop-and-scale"
                            });

                            console.log("setCameraVideoResolutionValue - videoTrack.applyConstraints is done.");
                        }
                    }
                }
            }

            resolve();
        } catch (err) {
            console.log("setCameraVideoResolutionValue - err => ", err);
        }
    });
}

/**  MEDIA SOUP (produce-cotrol) */
/**
 * audio track enable
 */
export const enableAudioTrack = () => {
    if (clientInfo.produceInfo.audioTrack !== undefined && clientInfo.produceInfo.audioTrack !== null) {
        clientInfo.produceInfo.audioTrack.enabled = true;
    }
}

/**
 * audio track disable
 */
export const disableAudioTrack = () => {
    if (clientInfo.produceInfo.audioTrack !== undefined && clientInfo.produceInfo.audioTrack !== null) {
        clientInfo.produceInfo.audioTrack.enabled = false;
    }
}

/**
 * 학생이 마이크로 말하는 경우 시스템 스피커 볼륨 조절
 * @param {Number} volume 
 */
export const controlSpeakerVolume = (volume) => {
    if (typeof volume === "number") {
        if (clientInfo.consumeInfo.audioEl) {
            console.log(`bofore - clientInfo.consumeInfo.audioEl.volume[${clientInfo.consumeInfo.audioEl.volume}]`);
            clientInfo.consumeInfo.audioEl.volume = volume;
            console.log(`after - clientInfo.consumeInfo.audioEl.volume[${clientInfo.consumeInfo.audioEl.volume}]`);
        }

        if (clientInfo.consumeInfo.micEl) {
            console.log(`bofore - clientInfo.consumeInfo.micEl.volume[${clientInfo.consumeInfo.micEl.volume}]`);
            clientInfo.consumeInfo.micEl.volume = volume;
            console.log(`after - clientInfo.consumeInfo.micEl.volume[${clientInfo.consumeInfo.micEl.volume}]`);            
        }
    }
}

/**  MEDIA SOUP SEND (produce) */
/**
 * send live_createProducerTransport request to mediaSoup server
 * @private 
 */
const sendCreateProducerTransport = () => {
    console.log(`sendCreateProducerTransport - clientInfo - userSeq[${clientInfo.userSeq}], liveSeq[${clientInfo.liveSeq}], rcvTeacherSeq[${clientInfo.rcvTeacherSeq}], routingKey[${clientInfo.routingKey}]`);
    const data = {
        kind            : "mediaSoup",
        action          : "live_createProducerTransport",
        prodUserSeq     : clientInfo.userSeq,
        liveSeq         : clientInfo.liveSeq,
        teacherSeq      : clientInfo.rcvTeacherSeq,
        sendRotingKey   : clientInfo.routingKey
    };

    sendObjectToRbmq(data);
}

/**
 * send live_connectProducerTransport request to mediaSoup server
 * @param {DtlsParameters} dtlsParameters 
 * @private 
 */
const sendConnectProducerTransport = (dtlsParameters) => {
    console.log(`sendConnectProducerTransport - clientInfo - userSeq[${clientInfo.userSeq}], liveSeq[${clientInfo.liveSeq}], rcvTeacherSeq[${clientInfo.rcvTeacherSeq}], routingKey[${clientInfo.routingKey}]`);
    const data = {
        kind            : "mediaSoup",
        action          : "live_connectProducerTransport",
        liveSeq         : clientInfo.liveSeq,
        prodUserSeq     : clientInfo.userSeq,
        dtlsParameters  : dtlsParameters,
        teacherSeq      : clientInfo.rcvTeacherSeq,
        sendRotingKey   : clientInfo.routingKey
    };

    sendObjectToRbmq(data);
}

/**
 * send live_produce request to mediaSoup server
 * @param {String} mediaKind 
 * @param {RtpCapabilities} rtpParameters 
 */
const sendProduce = (mediaKind, rtpParameters) => {
    console.log(`sendProduce - mediaKind[${mediaKind}], rtpParameters => `, rtpParameters, `, clientInfo - userSeq[${clientInfo.userSeq}], liveSeq[${clientInfo.liveSeq}], rcvTeacherSeq[${clientInfo.rcvTeacherSeq}], routingKey[${clientInfo.routingKey}]`);
    const data = {
        kind            : "mediaSoup",
        action          : "live_produce",
        mediaKind       : mediaKind,
        liveSeq         : clientInfo.liveSeq,
        prodUserSeq     : clientInfo.userSeq,
        rtpParameters   : rtpParameters,
        teacherSeq      : clientInfo.rcvTeacherSeq,
        sendRotingKey   : clientInfo.routingKey
    };

    sendObjectToRbmq(data);
}

/**
 * send live_unpublish request to mediaSoup server
 * @param {String} mediaKind 
 */
export const sendUnpublish = (mediaKind) => {
    console.log(`sendUnpublish - mediaKind[${mediaKind}], clientInfo - userSeq[${clientInfo.userSeq}], liveSeq[${clientInfo.liveSeq}], rcvTeacherSeq[${clientInfo.rcvTeacherSeq}], routingKey[${clientInfo.routingKey}]`);
    if (clientInfo.sendTransport === null) { // mediaSoup server에 아무것도 produce 하고 있지 않은 상태... by hjkim 20220311
        if (mediaKind === "ALL") {
            clearAudio();
            clearVideo();
        } else if (mediaKind === "video") {
            // 뭔가 여기서 해야하려나..?ㅁ?
        } else if (mediaKind === "audio") {
            // 뭔가 여기서 해야하려나..?ㅁ?
        }
    } else { // mediaSoup server에 audio / video 중 하나 이상 produce 하고 있는 상태... by hjkim 20220311
        if (mediaKind === "ALL") {
            const data = {
                kind            : "mediaSoup",
                action          : "live_unpublish",
                mediaKind       : mediaKind,
                liveSeq         : clientInfo.liveSeq,
                prodUserSeq     : clientInfo.userSeq,
                teacherSeq      : clientInfo.rcvTeacherSeq,
                sendRotingKey   : clientInfo.routingKey
            };

            sendObjectToRbmq(data);
        } else {
            if (mediaKind === "video" && clientInfo.produceInfo.videoProducer !== null) {
                const data = {
                    kind            : "mediaSoup",
                    action          : "live_unpublish",
                    mediaKind       : mediaKind,
                    liveSeq         : clientInfo.liveSeq,
                    prodUserSeq     : clientInfo.userSeq,
                    teacherSeq      : clientInfo.rcvTeacherSeq,
                    sendRotingKey   : clientInfo.routingKey
                };

                sendObjectToRbmq(data);
            }

            if (mediaKind === "audio" && clientInfo.produceInfo.audioProducer !== null) {
                const data = {
                    kind            : "mediaSoup",
                    action          : "live_unpublish",
                    mediaKind       : mediaKind,
                    liveSeq         : clientInfo.liveSeq,
                    prodUserSeq     : clientInfo.userSeq,
                    teacherSeq      : clientInfo.rcvTeacherSeq,
                    sendRotingKey   : clientInfo.routingKey
                };

                sendObjectToRbmq(data);
            }
        }
    }
}

/**
 * send live_endProduceCallback request to mediaSoup server
 * @param {String} mediaKind 
 * @returns {Promise<Boolean>}
 */
export const sendEndProduceCallback = (mediaKind) => {
    console.log(`sendEndProduceCallback - mediaKind[${mediaKind}], clientInfo - userSeq[${clientInfo.userSeq}], liveSeq[${clientInfo.liveSeq}], rcvTeacherSeq[${clientInfo.rcvTeacherSeq}], routingKey[${clientInfo.routingKey}]`);
    return new Promise((resolve, reject) => {
        const data = {
            kind            : "mediaSoup",
            action          : "live_endProduceCallback",
            mediaKind       : mediaKind,
            liveSeq         : clientInfo.liveSeq,
            prodUserSeq     : clientInfo.userSeq,
            teacherSeq      : clientInfo.rcvTeacherSeq,
            sendRotingKey   : clientInfo.routingKey
        };

        sendObjectToRbmq(data);
        resolve(true);
    });
}

/**  MEDIA SOUP RCV (produce-callback) */
/**
 * live_createProducerTransport 요청 후 서버에서 받은 데이터 처리
 * @param {JSON} rtnValue 
 */
export const createProducerTransportAfter = async (rtnValue) => {
    const { transportParams } = rtnValue;
    console.log("createProducerTransportAfter - ", rtnValue.rtpCapabilities);

    if (clientInfo.rtpCapabilities === undefined || clientInfo.rtpCapabilities === null) {
        clientInfo.rtpCapabilities = rtnValue.rtpCapabilities;
    }

    try {
        await loadDevice();
    } catch (err) {
        console.log("createProducerTransportAfter(loadDevice) - err => ", err);
        if (err instanceof UnsupportedError) {
            alert(`[Unsupported] can not createProducerTransportAfter(loadDevice) ... because ${err.message}`);
        } else if (err instanceof InvalidStateError) {
            alert(`[InvalidState] can not createProducerTransportAfter(loadDevice) ... because ${err.message}`);
        } else if (err instanceof TypeError) {
            alert(`[TypeError] can not createProducerTransportAfter(loadDevice) ... because ${err.message}`);
        } else {
            alert(`[Error] can not createProducerTransportAfter(loadDevice) ... because ${err.message}`);
        }
    } finally {
        if (clientInfo.device === undefined || clientInfo.device === null) {
            console.log("createProducerTransportAfter - clientInfo.device is => ", clientInfo.device);
            return;
        }

        try {
            let canProduceAudio = clientInfo.device.canProduce("audio");
            let canProduceVideo = clientInfo.device.canProduce("video");

            console.log(`createProducerTransportAfter - canProduceAudio[${canProduceAudio}], canProduceVideo[${canProduceVideo}]`);
        } catch (err) {
            console.log("createProducerTransportAfter(canProduce) - err => ", err);
        } finally {
            let transport = null;

            try {
                transport = clientInfo.device.createSendTransport({
                    id: transportParams.id,
                    dtlsParameters: transportParams.dtlsParameters,
                    iceCandidates: transportParams.iceCandidates,
                    iceParameters: transportParams.iceParameters
                });

                if (transport !== null) {
                    transport.on("connect", ({ dtlsParameters }, callback, errback) => {
                        clientInfo.sendTransportCallback = callback;
                        clientInfo.sendTransportErrback = errback;
                        sendConnectProducerTransport(dtlsParameters);
                    });

                    transport.on("produce", async ({ kind, rtpParameters }, callback, errback) => {
                        clientInfo.produceInfo.produceCallback = callback;
                        clientInfo.produceInfo.produceErrback = errback;
                        sendProduce(kind, rtpParameters);
                    });

                    transport.on("connectionstatechange", (state) => {
                        console.log(`produce-transport connectionstatechange ... state[${state}]`);
                        switch (state) {
                            case "connecting": break;
                            case "connected": break;
                            case "closed": break;
                            case "failed":
                                if (!transport.closed) {
                                    transport.close();
                                }
                                break;
                            default: break;
                        }
                    });

                    clientInfo.sendTransport = transport;

                    if (clientInfo.produceInfo.videoStatus === "start") {
                        clientInfo.sendTransportStatus = "video";
                        try {
                            const track = clientInfo.produceInfo.videoTrack;
                            const isSimulcast = false;
                            const producer = await produce(transport, track, isSimulcast);
                            clientInfo.produceInfo.videoProducer = producer;
                        } catch (err) {
                            console.log("createProducerTransportAfter(produce) - err => ", err);
                        }
                    } else if (clientInfo.produceInfo.audioStatus === "start") {
                        clientInfo.sendTransportStatus = "audio";
                        try {
                            const track = clientInfo.produceInfo.audioTrack;
                            const isSimulcast = false;
                            const producer = await produce(transport, track, isSimulcast);
                            clientInfo.produceInfo.audioProducer = producer;
                        } catch (err) {
                            console.log("createProducerTransportAfter(produce) - err => ", err);
                        }
                    } else {
                        clientInfo.sendTransportStatus = "ended";
                    }
                } else {
                    console.log(`[Unsupported] can not create send transport ... id[${transportParams.id}], transport => `, transport);
                    alert(`[Unsupported] can not create send transport ... id[${transportParams.id}]`);
                }
            } catch (err) {
                console.log("createProducerTransportAfter(createSendTransport) - err => ", err);
                if (err instanceof InvalidStateError) {
                    alert(`[InvalidState] can not create send transport ... because ${err.message}`);
                } else if (err instanceof TypeError) {
                    alert(`[TypeError] can not create send transport ... because ${err.message}`);
                } else {
                    alert(`[Error] can not create send transport ... because ${err.message}`);
                }
            }
        }
    }
}

/**
 * live_connectProducerTransport 요청 후 서버에서 받은 데이터 처리
 */
export const connectProducerTransportAfter = () => {
    console.log("connectProducerTransportAfter");
    clientInfo.sendTransportCallback();
}

/**
 * live_produce 요청 후 서버에서 받은 데이터 처리
 * @param {JSON} rtnValue 
 */
export const produceAfter = async (rtnValue) => {
    const { id, kind } = rtnValue;
    console.log(`produceAfter - kind[${kind}], id[${id}]`);

    clientInfo.produceInfo.produceCallback(id);

    try {
        await sendEndProduceCallback(kind);
    } catch (err) {
        console.log("produceAfter() err => ", err);
    } finally {
        endProduceAfter(kind);
    }
}

/**
 * live_produce 요청 후 서버에서 받은 데이터 처리
 * @param {String} mediaKind 
 */
const endProduceAfter = async (mediaKind) => {
    console.log(`endProduceAfter - mediaKind[${mediaKind}]`);
    if (mediaKind === "video") {
        clientInfo.produceInfo.videoStatus = "ended";
    } else if (mediaKind === "audio" || mediaKind === "ALL") {
        clientInfo.produceInfo.audioStatus = "ended";
        sendP2PSoundOn(clientInfo.liveSeq, clientInfo.userSeq);
    }

    if (clientInfo.produceInfo.videoStatus === "start") {
        clientInfo.sendTransportStatus = "video";
        try {
            const track = clientInfo.produceInfo.videoTrack;
            const isSimulcast = false;
            const producer = await produce(clientInfo.sendTransport, track, isSimulcast);
            clientInfo.produceInfo.videoProducer = producer;
        } catch (err) {
            console.log("endProduceAfter(produce) - err => ", err);
        }
    } else if (clientInfo.produceInfo.audioStatus === "start") {
        clientInfo.sendTransportStatus = "audio";
        try {
            const track = clientInfo.produceInfo.audioTrack;
            const isSimulcast = false;
            const producer = await produce(clientInfo.sendTransport, track, isSimulcast);
            clientInfo.produceInfo.audioProducer = producer;
        } catch (err) {
            console.log("endProduceAfter(produce) - err => ", err);
        }
    } else {
        clientInfo.sendTransportStatus = "ended";
    }
}

/**
 * live_unpublish 요청 후 서버에서 받은 데이터 처리
 * @param {JSON} rtnValue 
 */
export const unpublishAfter = (rtnValue) => {
    const { mediaKind } = rtnValue;
    console.log(`unpublishAfter - mediaKind[${mediaKind}]`);

    if (mediaKind === "video" || mediaKind === "ALL") {
        if (clientInfo.produceInfo.videoTrack !== null) {
            clientInfo.produceInfo.videoTrack.stop();
            clientInfo.produceInfo.videoTrack = null;
        }

        if (clientInfo.produceInfo.videoProducer !== undefined && clientInfo.produceInfo.videoProducer !== null) {
            if (!clientInfo.produceInfo.videoProducer.closed) {
                clientInfo.produceInfo.videoProducer.close();
            }
            clientInfo.produceInfo.videoProducer = null;
        }
    }

    if (mediaKind === "audio" || mediaKind === "ALL") {
        if (clientInfo.produceInfo.audioTrack !== null) {
            clientInfo.produceInfo.audioTrack.stop();
            clientInfo.produceInfo.audioTrack = null;
        }

        if (clientInfo.produceInfo.audioProducer !== undefined && clientInfo.produceInfo.audioProducer !== null) {
            if (!clientInfo.produceInfo.audioProducer.closed) {
                clientInfo.produceInfo.audioProducer.close();
            }
            clientInfo.produceInfo.audioProducer = null;
        }

        clearAudio();
    }

    if (clientInfo.produceInfo.audioProducer === null && clientInfo.produceInfo.videoProducer === null) {
        if (clientInfo.sendTransport !== undefined && clientInfo.sendTransport !== null) {
            if (!clientInfo.sendTransport.closed) {
                clientInfo.sendTransport.close();
            }
            clientInfo.sendTransport = null;
        }
    }

    if (mediaKind === "ALL") {
        clearVideo();
    }
}

/**  MEDIA SOUP FOR ADMIN */
/**
 * media 서버에 현재 서버의 상태값 요청
 * @param {String} routingKey 
 */
export const sendGetState = async (routingKey) => {
    console.log(`sendGetState - clientInfo - userSeq[${clientInfo.userSeq}], liveSeq[${clientInfo.liveSeq}], rcvTeacherSeq[${clientInfo.rcvTeacherSeq}], routingKey[${clientInfo.routingKey}]`);
    const data = {
        kind            : "mediaSoup",
        action          : "getState",
        sendRotingKey   : clientInfo.routingKey
    };

    //sendObjectToRbmq(data);
    sendCommandToRbmq(routingKey, data);
}

/**
 * getState 요청 후 서버에서 받은 데이터 처리
 * @param {JSON} rtnValue 
 */
export const getStateAfter = (rtnValue) => {
    const { roomInfo, liveInfo } = rtnValue;

    let store = window.hiclasstv.store;
    store.dispatch(mqRcvMediaInfo({ roomInfo, liveInfo }));
}