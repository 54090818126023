import { onBroadcast, toggleBroadcastTimeNotify } from "../modules/live";

let list_timeout = [];
let timerMap = new Map();

export const addTimer = (liveSeq, broadcast) => {
    if (liveSeq > 0) {
        let timerArr = timerMap.get(liveSeq);

        if (timerArr === undefined || timerArr === null) {
            timerArr = [];
        }

        let nowDT = new Date(Date.now());
        //let broadcast = live_info.list_broadcast.find(info => info.broadcastSeq === broadcastSeq);
        let strtDateTime = broadcast.broadcastDateTime.strt.replace(' ', '');
        let strtDt = new Date();
        strtDt.setFullYear(strtDateTime.substring(0, 4));
        strtDt.setMonth(strtDateTime.substring(4, 6) - 1);
        strtDt.setDate(strtDateTime.substring(6, 8));
        strtDt.setHours(strtDateTime.substring(8, 10));
        strtDt.setMinutes(strtDateTime.substring(10, 12));
        strtDt.setSeconds(strtDateTime.substring(12, 14));
        
        if (nowDT > strtDt) {
            var store = window.hiclasstv.store;
            store.dispatch(onBroadcast({ broadcastSeq: broadcast.broadcastSeq, liveSeq }));
        } else {
            let time = strtDt - nowDT;
            let timer = setTimeout(notifyTimer, time, broadcast.broadcastSeq, liveSeq);
            timerArr.push(timer);
        }

        //console.log('timerArr - ', timerArr);
        timerMap.set(liveSeq, timerArr);
    }
};

export const initializeTimer = (liveSeq, list_broadcast) => {
    console.log('initializeTimer - ', liveSeq, list_broadcast);
    if (liveSeq > 0 && list_broadcast && list_broadcast.length > 0) {
        let timerArr = [];
        let nowDT = new Date(Date.now());

        for (let i = 0; i < list_broadcast.length; i++) {
            let broadcast = list_broadcast[i];
            let strtDateTime = broadcast.broadcastDateTime.strt.replace(' ', '');
            let strtDt = new Date();
            strtDt.setFullYear(strtDateTime.substring(0, 4));
            strtDt.setMonth(strtDateTime.substring(4, 6) - 1);
            strtDt.setDate(strtDateTime.substring(6, 8));
            strtDt.setHours(strtDateTime.substring(8, 10));
            strtDt.setMinutes(strtDateTime.substring(10, 12));
            strtDt.setSeconds(strtDateTime.substring(12, 14));

            if (nowDT > strtDt) {
                var store = window.hiclasstv.store;
                store.dispatch(onBroadcast({ broadcastSeq: broadcast.broadcastSeq, liveSeq }));
            } else {
                let time = strtDt - nowDT;
                if (time > 5000) {
                    let timer = setTimeout(notifyTimer, (time - 5000), broadcast.broadcastSeq, liveSeq);
                    timerArr.push(timer);
                } else {
                    let timer = setTimeout(notifyTimer, time, broadcast.broadcastSeq, liveSeq);
                    timerArr.push(timer);
                }
            }
        }

        //console.log('initializeTimer - timerArr => ', timerArr);
        timerMap.set(liveSeq, timerArr);
    }
};

export const cancelTimer = (liveSeq) => {
    if (liveSeq > 0) {
        const timerArr = timerMap.get(liveSeq);
        if (timerArr) {
            for (let i = 0; i < timerArr.length; i++) {
                clearTimeout(timerArr[i]);
            }

            timerMap.delete(liveSeq);
        }
    }
};

export const clearTimer = () => {
    if (timerMap && timerMap.size > 0) {
        timerMap.forEach((info, key) => {
            info.forEach(timer => {
                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }
            });
        });

        timerMap.clear();
    }
};

const notifyTimer = (broadcastSeq, liveSeq) => {
    console.log('notifyTimer');
    var store = window.hiclasstv.store;
    const { live } = store.getState();
    const live_info = live.list_live.find(info => info.liveSeq === liveSeq);
    console.log(live_info);
    if (live_info) {
        const broadcast_info = live_info.list_broadcast.find(info => info.broadcastSeq === broadcastSeq);
        console.log(broadcast_info);
        if (broadcast_info) {
            let notifyInfo = {
                broadcastDateTime : broadcast_info.broadcastDateTime,
                liveName          : live_info.liveName
            };

            store.dispatch(toggleBroadcastTimeNotify({ isShowing: true, notifyInfo }));
            setTimeout(broadcastTimer, 5000, broadcastSeq, liveSeq);
        }
    }
};

const broadcastTimer = (broadcastSeq, liveSeq) => {
    var store = window.hiclasstv.store;
    store.dispatch(onBroadcast({ broadcastSeq, liveSeq: liveSeq }));
};