import React, { useEffect } from "react";
import { MDBNavItem } from "mdbreact";

import { enPassTap } from "../../../lib/ConstCommand";

const WebHeaderPassMenu = ({ isMobile, isPassPage, hiclasstvInfo, tossAndHandleSelectedFunc }) => {

    useEffect(() => {
        if (isMobile) {
            let navbar = document.getElementById("navbar");
            let totalWidth = navbar.offsetWidth;
            let baseWidth = navbar.parentElement.offsetWidth;

            let offset = -1;

            if (isPassPage) {
                switch (hiclasstvInfo.currentPassTap) {
                    case enPassTap.PRDT_LIST:
                        offset = document.getElementById("tool_pass").offsetLeft;
                        break;

                    case enPassTap.PCHS_LIST:
                        offset = document.getElementById("tool_pass_pchs").offsetLeft;
                        break;

                    default:
                        break;
                }
            } else {
                offset = offset = document.getElementById("tool_pass").offsetLeft;
            }

            let position = -1;

            if (totalWidth - offset < baseWidth) {
                position = totalWidth - baseWidth;
            } else {
                position = offset - 12;
            }

            navbar.parentElement.scrollTo(position, 0);
        }
    }, [isMobile, isPassPage, hiclasstvInfo]);

    return (
        isMobile === true ?
            <>
                <li className="nav_item">
                    <span className={((isPassPage && hiclasstvInfo.currentPassTap === enPassTap.PRDT_LIST) ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_PASS_PRDT_LIST")}>이용권 구매</span>
                </li>
                <li className="nav_item" id="tool_pass_pchs">
                    <span className={((isPassPage && hiclasstvInfo.currentPassTap === enPassTap.PCHS_LIST) ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_PASS_PCHS_LIST")}>내 이용권 조회</span>
                </li>
            </> :
            <>
                <MDBNavItem className="mx-2">
                    <button className={"hiclasstv-btn-transparent my-0" + ((isPassPage && hiclasstvInfo.currentPassTap === enPassTap.PRDT_LIST) ? " hiclasstv-darkblue-text" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_PASS_PRDT_LIST")}>
                        <b>이용권 구매</b>
                    </button>
                </MDBNavItem>
                <MDBNavItem className="mx-2">
                    <button className={"hiclasstv-btn-transparent my-0" + ((isPassPage && hiclasstvInfo.currentPassTap === enPassTap.PCHS_LIST) ? " hiclasstv-darkblue-text" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_PASS_PCHS_LIST")}>
                        <b>내 이용권 조회</b>
                    </button>
                </MDBNavItem>
                <MDBNavItem className="mx-2">
                    <button className={"hiclasstv-btn-transparent my-0" + ((isPassPage && hiclasstvInfo.currentPassTap === enPassTap.REFUND_LIST) ? " hiclasstv-darkblue-text" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_PASS_REFUND_LIST")}>
                        <b>이용권 환불</b>
                    </button>
                </MDBNavItem>
            </>
    );
}

export default WebHeaderPassMenu;