import axios from 'axios';
import ConstData from '../ConstData';
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const create_session_info = ({ mySubDomainSeq, lectureCode, kind }) => {
    return axios.get(`${ConstData.REST_API_HOST_ADD}/ai/watson/${mySubDomainSeq}/${lectureCode}`, options);
};

export const send_message = ({ mySubDomainSeq, lectureCode, sessionCode, sendMessage, lectures, userSeq, context, dialogHist }) => {
    let sendVal = {
        sessionCode,
        message: sendMessage.substr(0, 250),
        lectures,
        userSeq,
        dialogHist
    };

    if (context !== '') {
        sendVal.context = context;
    }

    return axios.post(`${ConstData.REST_API_HOST_ADD}/ai/watson/${mySubDomainSeq}/${lectureCode}`, sendVal, options);
};

export const remove_session_info = ({ mySubDomainSeq, lectureCode, sessionCode, hiclassMode, hiclassSession }) => {
    console.log('remove_session_info');
    if (hiclassMode && hiclassMode !== '' && hiclassSession && hiclassSession !== '') {
        return axios.put(`${ConstData.REST_API_HOST_ADD}/ai/watson/${mySubDomainSeq}/${lectureCode}`, {
            sessionCode,
            hiclassMode,
            hiclassSession
        }, options);
    } else {
        return axios.put(`${ConstData.REST_API_HOST_ADD}/ai/watson/${mySubDomainSeq}/${lectureCode}`, {
            sessionCode
        }, options);
    }
};