import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

//import KaKao from 'kakaojs';

import MyFriendListContainer from './friend/MyFriendListContainer';

import RightSideModal from '../components/template/modal/RightSideModal';
import ToppedModal from '../components/template/modal/ToppedModal';
import AllCoveredModal from '../components/template/modal/AllCoveredModal';
import CenteredModal from '../components/template/modal/CenteredModal';

import EditFriendsList from '../components/friend/modal/EditFriendsList';
import AddFriendWindow from '../components/friend/modal/AddFriendWindow';
import AddRecommendFriends from '../components/friend/modal/AddRecommendFriends';
import AddFriendForSearchKeyword from '../components/friend/modal/AddFriendForSearchKeyword';
import AddFriendForSearchID from '../components/friend/modal/AddFriendForSearchID';
import SearchMyFriends from '../components/friend/modal/SearchMyFriends';

import MyProfileWindow from '../components/user/modal/MyProfileWindow';
import FriendProfileWindow from '../components/friend/modal/FriendProfileWindow';

import SoundDeviceChoice from '../components/etc/SoundDeviceChoice';
import SoundAlertModal from '../components/etc/modal/SoundAlertModal';

import { enBrowserType, enWebPage } from '../lib/ConstCommand';

import { toggle_myProfile, 
         toggle_friendProfile, 
         toggle_addFriends, 
         toggle_searchFriendsList, 
         toggle_editFriendsList, 
         toggle_addByRecommend, 
         toggle_addByKeyword, 
         toggle_addByID, 
         closeModal, 
         searchFriendListResult, 
         searchList, 
         searchIdUser, 
         confirmFriend, 
         applyMutiFriends, 
         rejectApplyFriend, 
         cancelApplyfriend, 
         removeFriend, 
         getFriendList, 
         applyFriend } from '../modules/friend';

import { changeFalseToGotoFriendList, 
         createWebChatRoom, 
         createP2PChatRoom,  
         startAloneChatting, 
         startAloneChattingAfterFinishChat, 
         gotoPage } from '../modules/chat';

//import { startLive } from '../modules/live'; 

import { userUpdate, requestServerUserAuth, toggle_editInfoModal, setMessageBlank } from '../modules/user';

import { settingSoundDeviceOpen, 
         selectSoundDevice, 
         setSoundDeviceVolume,
         setMyMicEchoStatus,
         toggleSoundAlertModal } from '../modules/hiclasstv';    

class FriendListContainer extends Component {

    componentDidMount() {
        const { browserType, currentPageType, userInfo } = this.props;
        const { isGotoFriendList, changeFalseToGotoFriendList, getFriendList } = this.props;

        if (browserType === enBrowserType.HiClass) {
            if (isGotoFriendList) {
                changeFalseToGotoFriendList();
            }
        } else {
            if (currentPageType === enWebPage.Friend) {
                getFriendList({ userSeq: userInfo.userSeq });
            }
        }
    }

    handleSearchValue = ({ keyword }) => {
        const { list_friends, searchFriendListResult } = this.props;

        let newKeyword = keyword.toLowerCase();

        try {
            console.log(list_friends, newKeyword);
            let result = list_friends.filter(
                (friend) => {
                    return (friend.userNickname.toLowerCase().indexOf(newKeyword) > -1 || friend.userProfile.toLowerCase().indexOf(newKeyword) > -1);
                }
            );
    
            searchFriendListResult({ result });
        } catch (err) {
            console.log("handleSearchValue() - err => ", err);
        }
    }

    getChatRoomMember = (rcvUserArr) => {
        const { userInfo } = this.props;
        let userArr = [{ userSeq:userInfo.userSeq,
                         userNickname:userInfo.userNickname,
                         userProfile:userInfo.userProfile,
                         profileImgUrl:userInfo.profileImgUrl,
                         userStatus:"STUDYING" }];
        userArr = userArr.concat(rcvUserArr);
        console.log("member : ", userArr);

        return userArr;
    }

    getChatRoomName = (rcvUserArr) => {
        const { userInfo } = this.props;
        let userArr = [{ userSeq:userInfo.userSeq,
                         userNickname:userInfo.userNickname,
                         userProfile:userInfo.userProfile,
                         profileImgUrl:userInfo.profileImgUrl,
                         userStatus:"STUDYING" }];
        userArr = userArr.concat(rcvUserArr);
        let name = userArr.map(user => user.userNickname);
        name = name.join(', ')+" 님의 팀업";

        return name;
    }
    
    callFriends = (userNickname, friendUserNickname) => {
        if (window.Kakao !== undefined && window.Kakao !== null) {
            window.Kakao.Link.sendCustom({
                templateId : 29512,
                templateArgs : {
                    'send_user' : userNickname,
                    'rcv_user' : friendUserNickname
                }
            });
        } else {
            console.log("window.Kakao is ", window.Kakao);
        }
    }

    handleSelectedFriendFunc = ({ selectedFunc, data, friendSeq, friend_info, type, userNickname, userProfile}) => {
        const { userInfo, performChatRoomInfo } = this.props;
        const { getChatRoomName, getChatRoomMember } = this;

        switch(selectedFunc) {
            case "ME" :
                this.props.toggle_myProfile({ selectedFunc, userSeq: userInfo.userSeq });
                break;

            case "FRIEND" :
                this.props.toggle_friendProfile({ selectedFunc, friendSeq });
                break;

            case "CREATE" :
                let chatRoomName = getChatRoomName(friend_info);
                let userArr = getChatRoomMember(friend_info);
                this.props.createP2PChatRoom({ chatRoomName, creatorSEQ: userInfo.userSeq, userArr, isWithEntry: "Y" });
                break;

            case "CREATE_WEB" :
                let chatRoomName2 = getChatRoomName(friend_info);   
                let userArr2 = getChatRoomMember(friend_info);             
                this.props.createWebChatRoom({ chatRoomName: chatRoomName2, creatorSEQ: userInfo.userSeq, userArr: userArr2 });
                break;

            case "CREATE_TALK" :
                let chatRoomName3 = getChatRoomName(friend_info);     
                let userArr3 = getChatRoomMember(friend_info);           
                this.props.createP2PChatRoom({ chatRoomName: chatRoomName3, creatorSEQ: userInfo.userSeq, userArr: userArr3, isWithTalk: type, isWithEntry: "Y" });
                break;

            case "JOIN_MP2P" :
                if (performChatRoomInfo.chatRoomSeq > 0) {
                    this.props.startAloneChattingAfterFinishChat({ chatRoomSeq: performChatRoomInfo.chatRoomSeq, userSeq: userInfo.userSeq, msgText: "나와의 채팅을 시작합니다." });
                } else {
                    if (performChatRoomInfo.chatRoomSeq === 0) {
                        this.props.gotoPage({ page: "CHATTING" });
                    } else {
                        //startLive({ userSeq: userInfo.userSeq, msgText: "나와의 채팅을 시작합니다." });
                        this.props.startAloneChatting();
                    }
                }
                break;

            case "CALL" : 
                this.callFriends(userInfo.userNickname, friend_info.userNickname);
                break

            case "EDIT_TOGGLE" :
                this.props.toggle_editInfoModal();
                break;

            case "EDIT" :
                this.props.userUpdate({ mode: "hiclass", userSeq: userInfo.userSeq, userNickname, userProfile: userProfile });
                break;

            case "CERTIFY_SERVER_USER" :
                this.props.requestServerUserAuth({ localUserSeq: userInfo.userSeq, serverUserID: data.serverUserID, serverUserPW: data.serverUserPW, isKeepSaving: data.isKeepSaving });
                break;

            case "SET_MESSAGE_BLANK" : 
                this.props.setMessageBlank();
                break;

            case "NONE" :
                this.props.closeModal({ selectedFunc: "NONE" });
                break;

            default:
                break;
        }
    }

    handleSelectedAudioInfo = ({ selectedFunc, kind, value, idx }) => {
        switch (selectedFunc) {
            case "NONE" :
                this.props.settingSoundDeviceOpen();
                break;

            case "OPEN_SOUND_SETTING" : 
                this.props.settingSoundDeviceOpen();
                break;

            case "SET_MY_MIC_ECHO" : 
                this.props.setMyMicEchoStatus({ selectedFunc, kind: "setCheckedSelfMicEcho", value });
                break;

            case "SET_MY_SUB_MIC":
            case "SET_PARTNER_SPEAKER":
            case "SET_HANDSUP_PARTNER_SPEAKER" :
                if (kind === "DEVICE") {
                    this.props.selectSoundDevice({ selectedFunc, kind: "changeAudioInfo", value, idx });
                } else if (kind === "VOLUME") {
                    this.props.setSoundDeviceVolume({ selectedFunc, kind: "updateVolume", value });
                }
                break;

            case "TOGGLE_SOUND_ALERT" :
                this.props.toggleSoundAlertModal();
                break;

            default:
                break;
        }
    }

    handleSelectedFunc = ({ selectedFunc, searchValue, friendSeq, friendUserSEQList, mode }) => {
        const { userInfo, userKind } = this.props;
        
        switch(selectedFunc) {
            case "SEARCH_TOGGLE" :
                this.props.toggle_searchFriendsList(selectedFunc);
                break;

            case "ADD" :
                this.props.toggle_addFriends({ selectedFunc });
                break;

            case "EDIT" :
                this.props.getFriendList({ userSeq: userInfo.userSeq });
                this.props.toggle_editFriendsList(selectedFunc);
                break;

            case "RECOMMEND" :
                this.props.getFriendList({ userSeq: userInfo.userSeq });
                this.props.toggle_addByRecommend(selectedFunc);
                break;

            case "KEYWORD_TOGGLE" :
                this.props.toggle_addByKeyword(selectedFunc);
                break;

            case "KEYWORD" :
                this.props.searchList({ selectedFunc, userSeq: userInfo.userSeq, keyWord: searchValue, kind: "search", mode, userKind });
                break;

            case "ID_TOGGLE" :
                this.props.toggle_addByID(selectedFunc);
                break;

            case "ID" :
                this.props.searchIdUser({ selectedFunc, userSeq: userInfo.userSeq, userId: searchValue, kind: "search-ID", mode, userKind });
                break;

            case "ACCEPT" :
                //console.log(`[${selectedFunc}], userSeq[${userInfo.userSeq}], friendSeq[${friendSeq}], mode[${mode}]`);
                this.props.confirmFriend({ userSeq: userInfo.userSeq, confirmUserSeq: friendSeq, mode });
                break;

            case "REJECT" :
                this.props.rejectApplyFriend({ userSeq: userInfo.userSeq, rejectUserSeq: friendSeq, mode });
                break;

            case "APPLY_SINGLE" :
                this.props.applyFriend({ userSeq: userInfo.userSeq, applyUserSeq: friendSeq, mode });
                break;

            case "APPLY" :
                this.props.applyMutiFriends({ userSeq: userInfo.userSeq, friendUserSEQList, mode });
                break;

            case "CANCEL" :
                this.props.cancelApplyfriend({ userSeq: userInfo.userSeq, cancelUserSeq: friendSeq, mode });
                break;

            case "DELETE" :
                this.props.removeFriend({ userSeq: userInfo.userSeq, deleteUserSeq: friendSeq, mode });
                break;

            case "NONE" :
                this.props.closeModal(selectedFunc);
                break;

            default:
                break;
        }
    }

    render() {
        const { browserType, currentPageType } = this.props;
        const { isMyProfileModalOpened, isFriendProfileModalOpened, isEditInfoModalOpened, isSearchFriendsModalOpened, isEditFriendsModalOpened, isAddFriendWindowModalOpened,
                isAddByRecommendModalOpened, isAddByKeywordModalOpened, isAddByIDModalOpened } = this.props;
        const { isGotoChatting, isGotoLiveList, isGotoChatList, selectedFunc,
                list_friends, list_searchFriends, list_rcvRequestFriends, list_sendRequestFriends, 
                userInfo, friend_info, performChatRoomInfo, searchResult, searchResultId, certifiedServerUserInfo } = this.props;
        const { handleSelectedFunc, handleSearchValue, handleSelectedAudioInfo, handleSelectedFriendFunc } = this;         

        if (browserType === enBrowserType.HiClass) {
            if(isGotoChatting) {
                return <Redirect to='/chatting_hiclass' />;
            } else if(isGotoLiveList) {
                return <Redirect to='/list_live_hiclass' />;
            } else if(isGotoChatList) {
                return <Redirect to='/list_chat_hiclass'/>;
            }
        
            return (
                <>
                    {
                        isMyProfileModalOpened &&
                        <AllCoveredModal>
                            <MyProfileWindow
                                user_info={userInfo}
                                isEditInfoModalOpened={isEditInfoModalOpened}
                                performChatRoomInfo={performChatRoomInfo}
                                certifiedServerUserInfo={certifiedServerUserInfo}
                                handleSelectedFriendFunc={handleSelectedFriendFunc}
                            />
                        </AllCoveredModal>
                    } {
                        isFriendProfileModalOpened &&
                        <AllCoveredModal>
                            <FriendProfileWindow
                                friend_info={friend_info}
                                performChatRoomInfo={performChatRoomInfo}
                                handleSelectedFriendFunc={handleSelectedFriendFunc}
                            />
                        </AllCoveredModal>
                    } {
                        isSearchFriendsModalOpened &&
                            <RightSideModal modalClose={handleSelectedFunc}>
                                <SearchMyFriends 
                                    userInfo={userInfo}
                                    list_friends={list_friends}
                                    list_searchFriends={list_searchFriends}
                                    handleSelectedFriendFunc={handleSelectedFriendFunc}
                                    handleSearchValue={handleSearchValue}
                                />
                            </RightSideModal>
                    } {
                        isEditFriendsModalOpened &&
                            <RightSideModal modalClose={handleSelectedFunc}>
                                <EditFriendsList
                                    list_friends={list_friends}
                                    list_rcvRequestFriends={list_rcvRequestFriends}
                                    list_sendRequestFriends={list_sendRequestFriends}
                                    handleSelectedFunc={handleSelectedFunc}
                                />
                            </RightSideModal>
                    } {
                        isAddFriendWindowModalOpened &&
                            <ToppedModal modalClose={handleSelectedFunc}>
                                <AddFriendWindow 
                                    handleSelectedFunc={handleSelectedFunc}
                                    countRecommendFriends={list_rcvRequestFriends.length}
                                    selectedFunc={selectedFunc}
                                />
                            </ToppedModal>
                    } {
                        isAddByRecommendModalOpened && 
                            <RightSideModal modalClose={handleSelectedFunc}>
                                <AddRecommendFriends
                                    list_rcvRequestFriends={list_rcvRequestFriends}
                                    handleSelectedFunc={handleSelectedFunc}
                                 />
                            </RightSideModal>
                    } {
                        isAddByKeywordModalOpened &&
                            <RightSideModal modalClose={handleSelectedFunc}>
                                <AddFriendForSearchKeyword 
                                    handleSelectedFunc={handleSelectedFunc}
                                    searchResult={searchResult}
                                />
                            </RightSideModal>
                    } {
                        isAddByIDModalOpened &&
                            <RightSideModal modalClose={handleSelectedFunc}>
                                <AddFriendForSearchID 
                                    handleSelectedFunc={handleSelectedFunc}
                                    searchResult={searchResultId}
                                />
                            </RightSideModal>
                    }{
                        this.props.isSettingDeviceOpened &&
                        <RightSideModal modalClose={handleSelectedFunc}>
                            <SoundDeviceChoice
                                isMyMicEchoEnable={this.props.isMyMicEchoEnable}
                                myMicInfo={this.props.myMicInfo}
                                teampUpSpeakerInfo={this.props.teampUpSpeakerInfo}
                                handsUpSpeakerInfo={this.props.handsUpSpeakerInfo}
                                handleSelectedFunc={handleSelectedAudioInfo}
                            />
                        </RightSideModal>
                    }
                    {
                        this.props.isSoundAlertModalOpened && 
                        <CenteredModal modalClose={handleSelectedFunc}>
                            <SoundAlertModal
                                isModalOpened={this.props.isSoundAlertModalOpened}
                                alertKind={this.props.alertKind}
                                alertMessage={this.props.alertMessage}
                                handleSelectedFunc={handleSelectedAudioInfo}
                            />
                        </CenteredModal>
                    }
                    <MyFriendListContainer 
                        browserType={browserType}
                        handleSelectedFunc={handleSelectedFunc}
                        handleSelectedAudioInfo={handleSelectedAudioInfo}
                        handleSelectedFriendFunc={handleSelectedFriendFunc}
                        selectedFunc={selectedFunc}
                    />
                </>
            );
        } else {
            return (
                <MyFriendListContainer
                    browserType={browserType}
                    currentPageType={currentPageType}
                    handleSelectedFriendFunc={handleSelectedFriendFunc}
                    handleSelectedFunc={handleSelectedFunc}
                />
            );
        }
    }
}

export default connect(
    ({ user, chat, friend, hiclasstv }) => ({ 
        userInfo                : {
            userSeq                     : user.userSeq,
            userNickname                : user.userNickname,
            userStatus                  : user.myStatus,
            userProfile                 : user.userProfile,
            profileImgUrl               : user.profileImgUrl
        },

        userKind                        : user.userKind,

        friend_info                     : friend.selectedFriend_info,

        certifiedServerUserInfo                   : {
            serverUserSeq               : user.serverUserSeq,
            alertKind                   : user.userInfoAlertKind,
            alertMessage                : user.userInfoMessage
        },

        performChatRoomInfo             : chat.performChatRoomInfo,

        isEditInfoModalOpened           : user.isEditInfoModalOpened,

        isMyProfileModalOpened          : friend.isMyProfileModalOpened,
        isFriendProfileModalOpened      : friend.isFriendProfileModalOpened,

        isGotoFriendList                : chat.isGotoFriendList,
        isGotoChatList                  : chat.isGotoChatList,
        isGotoChatting                  : chat.isGotoChatting,
        isGotoLiveList                  : chat.isGotoLiveList,

        isSearchFriendsModalOpened      : friend.isSearchFriendsModalOpened,
        isEditFriendsModalOpened        : friend.isEditFriendsModalOpened,
        isAddFriendWindowModalOpened    : friend.isAddFriendWindowModalOpened,
        isAddByRecommendModalOpened     : friend.isAddByRecommendModalOpened,
        isAddByKeywordModalOpened       : friend.isAddByKeywordModalOpened,
        isAddByIDModalOpened            : friend.isAddByIDModalOpened,
        
        selectedFunc                    : friend.selectedFunc,
        searchKind                      : friend.searchKind,
        searchValue                     : friend.searchValue,
        searchResult                    : friend.searchResult,
        searchResultId                  : friend.searchResultId,

        list_friends                    : friend.list_friends,
        list_searchFriends              : friend.list_searchFriends,
        list_rcvRequestFriends          : friend.list_rcvRequestFriends,
        list_sendRequestFriends         : friend.list_sendRequestFriends,        

        // 음향조절
        isSettingDeviceOpened           : hiclasstv.isSettingDeviceOpened,
        isSoundAlertModalOpened         : hiclasstv.isSoundAlertModalOpened,

        isMyMicEchoEnable               : hiclasstv.isMyMicEchoEnable,
        myMicInfo                       : hiclasstv.myMicInfo,
        teampUpSpeakerInfo              : hiclasstv.teampUpSpeakerInfo,
        handsUpSpeakerInfo              : hiclasstv.handsUpSpeakerInfo,

        alertKind                       : hiclasstv.alertKind,
        alertMessage                    : hiclasstv.alertMessage,
    }),
    {
        closeModal,
        setMessageBlank,
        changeFalseToGotoFriendList,
        
        toggle_myProfile,
        toggle_friendProfile,
        toggle_editInfoModal,

        toggle_addFriends,
        toggle_editFriendsList,
        toggle_searchFriendsList,
        toggle_addByRecommend,
        toggle_addByKeyword,
        toggle_addByID,

        gotoPage,
        createP2PChatRoom,
        createWebChatRoom,
        startAloneChatting,
        startAloneChattingAfterFinishChat,
        //startLive,
        userUpdate,
        searchFriendListResult,

        getFriendList,
        applyFriend,
        applyMutiFriends,
        confirmFriend,
        rejectApplyFriend,
        removeFriend,
        cancelApplyfriend,
        searchIdUser,
        searchList,

        requestServerUserAuth,

        settingSoundDeviceOpen, 
        selectSoundDevice, 
        setSoundDeviceVolume,
        setMyMicEchoStatus,
        toggleSoundAlertModal
    }
)(FriendListContainer);