import React from "react";
import { Link } from "react-router-dom";

import ConstData from "../../lib/ConstData";

// import hiclassTV from "../../images/hiclassTV.png";

const WebHeader = ({ userKind, isLoginYn, handleSelectedFunc }) => {

    const onClickHomeImg = () => {
        if (isLoginYn === "N") {
            handleSelectedFunc({ selectedFunc: "REFRESH_HOME" });
        }
    }

    return (
        <Link to="/main" style={{ textDecoration: 'none' }}>
            <img style={{ width: 'auto', height: '100%', maxHeight: '75px' }} src={(userKind === "hiclasstv" || userKind === "local") ? "/images/hiclassTV.png" : ConstData.LOGO_IMG_DIR + userKind + ".png"} onClick={onClickHomeImg} alt={userKind + "_logo"} />
        </Link>
    );
}

export default WebHeader;