import { createAction, handleActions } from 'redux-actions'
import produce from 'immer';

import createRequestThunk from '../lib/createRequestThunk';
import ConstData from '../lib/ConstData';

import { display, search, apply_multi, apply, confirm, reject, remove_friend,
        cancel_apply, invite, search_id, search_contacts } from '../lib/api/friend';

export const FRIEND_INIT        = 'friend/FRIEND_INIT';
export const FRIEND_STATE_INIT  = 'friend/FRIEND_STATE_INIT';
export const FRIEND_CHAT_RESULT = 'friend/FRIEND_CHAT_RESULT';

const CHANGE_USER_STATUS            = 'friend/CHANGE_USER_STATUS';
const CHANGE_SEARCH_KIND            = 'friend/CHANGE_SEARCH_KIND';
const FRIEND_LIST                   = 'friendInfo/FRIEND_LIST';
const FRIEND_LIST_SUCCESS           = 'friendInfo/FRIEND_LIST_SUCCESS';
const SEARCH_LIST                   = 'friendInfo/SEARCH_LIST';
const SEARCH_LIST_SUCCESS           = 'friendInfo/SEARCH_LIST_SUCCESS';
const SEARCH_ID                     = 'friendInfo/SEARCH_ID';
const SEARCH_ID_SUCCESS             = 'friendInfo/SEARCH_ID_SUCCESS';
const SEARCH_CONTACTS               = 'friendInfo/SEARCH_CONTACTS';
//const SEARCH_CONTACTS_SUCCESS       = 'friendInfo/SEARCH_CONTACTS_SUCCESS';
const CHANGE_IS_OPEN_SEARCH         = 'friend/CHANGE_IS_OPEN_SEARCH';

const FRIEND_UPDATE_SUCCESS         = 'friendInfo/FRIEND_UPDATE_SUCCESS';
const APPLY_FRIEND                  = 'friendInfo/APPLY_FRIEND';
const APPLY_FRIEND_SUCCESS          = 'friendInfo/APPLY_FRIEND_SUCCESS';
const APPLY_MULTI_FRIEND            = 'friendInfo/APPLY_MULTI_FRIEND';
const APPLY_MULTI_FRIEND_SUCCESS    = 'friendInfo/APPLY_MULTI_FRIEND_SUCCESS';
const CANCEL_APPLY_FRIEND           = 'friendInfo/CANCEL_APPLY_FRIEND';
const CANCEL_APPLY_FRIEND_SUCCESS   = 'friendInfo/CANCEL_APPLY_FRIEND_SUCCESS';
const CONFIRM_FRIEND                = 'friendInfo/CONFIRM_FRIEND';
const CONFIRM_FRIEND_SUCCESS        = 'friendInfo/CONFIRM_FRIEND_SUCCESS';
const REMOVE_FRIEND                 = 'friendInfo/REMOVE_FRIEND';
const REMOVE_FRIEND_SUCCESS         = 'friendInfo/REMOVE_FRIEND_SUCCESS';
const REJECT_APPLY_FRIEND           = 'friendInfo/REJECT_APPLY_FRIEND';
const REJECT_APPLY_FRIEND_SUCCESS   = 'friendInfo/REJECT_APPLY_FRIEND_SUCCESS';
const INVITE_FRIEND                 = 'friendInfo/INVITE_FRIEND';
//const INVITE_FRIEND_SUCCESS         = 'friendInfo/INVITE_FRIEND_SUCCESS';

const SEARCH_FRIEND_LIST            = 'friend/SEARCH_FRIEND_LIST';

const TOGGLE_MY_PROFILE             = 'friend/TOGGLE_MY_PROFILE';
const TOGGLE_FRIEND_PROFILE         = 'friend/TOGGLE_FRIEND_PROFILE';
const TOGGLE_SEARCH_FRIENDS         = 'friend/TOGGLE_SEARCH_FRIENDS';
const TOGGLE_EDIT_FRIENDS_LIST      = 'friend/TOGGLE_EDIT_FRIENDS_LIST';
const TOGGLE_ADD_FRIENDS            = 'friend/TOGGLE_ADD_FRIENDS';
const TOGGLE_ADD_FRIENDS_RECOMMEND  = 'friend/TOGGLE_ADD_FRIENDS_RECOMMEND';
const TOGGLE_ADD_FRIENDS_KEYWORD    = 'friend/TOGGLE_ADD_FRIENDS_KEYWORD';
const TOGGLE_ADD_FRIENDS_ID         = 'friend/TOGGLE_ADD_FRIENDS_ID';
const CLOSE_MODAL_FRIENDS_LIST      = 'friend/CLOSE_MODAL_FRIENDS_LIST';

export const applyFriend            = createRequestThunk(APPLY_FRIEND, apply);
export const applyMutiFriends       = createRequestThunk(APPLY_MULTI_FRIEND, apply_multi);
export const cancelApplyfriend      = createRequestThunk(CANCEL_APPLY_FRIEND, cancel_apply);
export const confirmFriend          = createRequestThunk(CONFIRM_FRIEND, confirm);
export const removeFriend           = createRequestThunk(REMOVE_FRIEND, remove_friend);
export const rejectApplyFriend      = createRequestThunk(REJECT_APPLY_FRIEND, reject);
export const inviteFriend           = createRequestThunk(INVITE_FRIEND, invite);

export const changeUserStatus       = createAction(CHANGE_USER_STATUS);
export const changeSearchKind       = createAction(CHANGE_SEARCH_KIND);
export const updatedComponent       = createAction(FRIEND_UPDATE_SUCCESS);
export const changeIsOpenSearch     = createAction(CHANGE_IS_OPEN_SEARCH);

export const getFriendList          = createRequestThunk(FRIEND_LIST, display);
export const friendList             = createRequestThunk(FRIEND_LIST, display);
export const searchList             = createRequestThunk(SEARCH_LIST, search);
export const searchIdUser           = createRequestThunk(SEARCH_ID, search_id);
export const searchContactsUser     = createRequestThunk(SEARCH_CONTACTS, search_contacts);

export const searchFriendListResult = createAction(SEARCH_FRIEND_LIST);

export const toggle_myProfile          = createAction(TOGGLE_MY_PROFILE);
export const toggle_friendProfile      = createAction(TOGGLE_FRIEND_PROFILE);
export const toggle_editFriendsList    = createAction(TOGGLE_EDIT_FRIENDS_LIST);
export const toggle_addFriends         = createAction(TOGGLE_ADD_FRIENDS);
export const toggle_searchFriendsList  = createAction(TOGGLE_SEARCH_FRIENDS);
export const toggle_addByRecommend     = createAction(TOGGLE_ADD_FRIENDS_RECOMMEND);
export const toggle_addByKeyword       = createAction(TOGGLE_ADD_FRIENDS_KEYWORD);
export const toggle_addByID            = createAction(TOGGLE_ADD_FRIENDS_ID);
export const closeModal                = createAction(CLOSE_MODAL_FRIENDS_LIST);

/******************************************************************/

const MQ_LOGIN_FRIEND           = 'friend/MQ_LOGIN_FRIEND';
const MQ_LOGOUT_FRIEND          = 'friend/MQ_LOGOUT_FRIEND';

const MQ_APPLY_FRIEND           = 'friend/MQ_APPLY_FRIEND';
const MQ_SEND_APPLY_FRIEND      = 'friend/MQ_SEND_APPLY_FRIEND';
const MQ_ADD_FRIEND             = 'friend/MQ_ADD_FRIEND';
const MQ_MULTIPLE_ADD_FRIEND    = 'friend/MQ_MULTIPLE_ADD_FRIEND';
const MQ_REMOVE_FRIEND          = 'friend/MQ_REMOVE_FRIEND';
const MQ_CANCEL_FRIEND          = 'friend/MQ_CANCEL_FRIEND';
const MQ_CANCEL_FRIEND_ME       = 'friend/MQ_CANCEL_FRIEND_ME';
const MQ_REJECT_FRIEND          = 'friend/MQ_REJECT_FRIEND';
const MQ_REJECT_FRIEND_ME       = 'friend/MQ_REJECT_FRIEND_ME';
const MQ_MULTIPLE_APPLY_FRIEND  = 'friend/MQ_MULTIPLE_APPLY_FRIEND';
const MQ_UPDATE_FRIEND_STATUS   = 'friend/MQ_UPDATE_FRIEND_STATUS';
const MQ_UPDATE_FRIEND_INFO     = 'friend/MQ_UPDATE_FRIEND_INFO';

export const mqLoginFriend          = createAction(MQ_LOGIN_FRIEND);
export const mqLogoutFriend         = createAction(MQ_LOGOUT_FRIEND);

export const mqApplyFriend          = createAction(MQ_APPLY_FRIEND);
export const mqSendApplyFriend      = createAction(MQ_SEND_APPLY_FRIEND);
export const mqAddFriend            = createAction(MQ_ADD_FRIEND);
export const mqMultipleAddFriend    = createAction(MQ_MULTIPLE_ADD_FRIEND);
export const mqRemoveFriend         = createAction(MQ_REMOVE_FRIEND);
export const mqCancelFriend         = createAction(MQ_CANCEL_FRIEND);
export const mqCancelFriendMe       = createAction(MQ_CANCEL_FRIEND_ME);
export const mqRejectFriend         = createAction(MQ_REJECT_FRIEND);
export const mqRejectFriendMe       = createAction(MQ_REJECT_FRIEND_ME);
export const mqMultipleApplyFriend  = createAction(MQ_MULTIPLE_APPLY_FRIEND);
export const mqUpdateFriendStatus   = createAction(MQ_UPDATE_FRIEND_STATUS);
export const mqUpdateFriendInfo     = createAction(MQ_UPDATE_FRIEND_INFO);

const initialState = {
    list_friends                    : [],               // 나의 친구 리스트 (사용자순번, 이름, 프로필, 프로필이미지, 상태(for 접속/비접속 판단), apply상태)
    list_searchFriends              : [],
    list_rcvRequestFriends          : [],               // 나에게 친구 요청한 리스트 (사용자순번, 이름, 프로필, 프로필 이미지, apply상태)
    list_sendRequestFriends         : [],               // 내가 친구 요청한 리스트 (사용자순번, 이름, 프로필, 프로필이미지, apply상태)

    selectedFriend_info             : [],               // 선택한 친구 정보

    isNeedToUpdate                  : false,
    isSearchOpened                  : false,
    
    searchKind                      : 'search',         // 사용자 검색 구분(키워드,ID검색(ID),주소록(ADDRESS BOOK))
                                                        // 주소록은 핸드폰 어플에서 가능
    searchValue                     : '',               // 검색값
    searchResult                    : [],               // 검색결과(사용자순번,이름,프로필,친구여부,친구상태)
    searchResultId                  : [],               // 검색결과(사용자순번,이름,프로필,친구여부,친구상태)
    searchResultContacts            : [],               // 검색결과(사용자순번,이름,프로필,친구여부,친구상태)
    web_searchResult                : [],
    web_searchResultId              : [],
    
    isOpenSearch                    : false,

    selectedFunc                    : 'NONE',
    
    isMyProfileModalOpened          : false,
    isFriendProfileModalOpened      : false,
    isSearchFriendsModalOpened      : false,
    isEditFriendsModalOpened        : false,
    isAddFriendWindowModalOpened    : false,
    isAddByRecommendModalOpened     : false,
    isAddByKeywordModalOpened       : false,
    isAddByIDModalOpened            : false
};

const friend = handleActions({
    [FRIEND_INIT]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isLoginSuccess } = action.payload;

            if (isLoginSuccess) {
                const { list_friends, list_rcvRequestFriends, list_sendRequestFriends } = action.payload;
                let tmp_list_friends = [];
                let tmp_list_rcvRequestFriends = [];
                let tmp_list_sendRequestFriends = [];

                if (list_friends !== undefined && list_friends !== null) {
                    tmp_list_friends = list_friends.map(row => ({
                                            userSeq: row.FRIEND_SEQ,
                                            userNickname: row.USER_NICKNAME,
                                            userProfile: row.USER_PROFILE,
                                            profileImgUrl: row.USER_PROFILE_IMG,
                                            userStatus: row.FRIEND_STATUS,
                                            applyStatus: row.APPLY_STATUS
                                        }));
                }

                if (list_rcvRequestFriends !== undefined && list_rcvRequestFriends !== null) {
                    tmp_list_rcvRequestFriends = list_rcvRequestFriends.map(row => ({
                                                    userSeq: row.SENDER_SEQ,
                                                    userNickname: row.USER_NICKNAME,
                                                    userProfile: row.USER_PROFILE,
                                                    profileImgUrl: row.USER_PROFILE_IMG,
                                                    applyStatus: row.APPLY_STATUS,
                                                    //userStatus : row.FRIEND_STATUS,
                                                    //status : row.APPLY_STATUS
                                                }));
                }

                if (list_sendRequestFriends !== undefined && list_sendRequestFriends !== null) {
                    tmp_list_sendRequestFriends = list_sendRequestFriends.map(row => ({
                                                    userSeq: row.RECEIVER_SEQ,
                                                    userNickname: row.USER_NICKNAME,
                                                    userProfile: row.USER_PROFILE,
                                                    profileImgUrl: row.USER_PROFILE_IMG,
                                                    applyStatus: row.APPLY_STATUS,
                                                    //userStatus : row.FRIEND_STATUS,
                                                    //status : row.APPLY_STATUS
                                                }));
                }

                return {
                    ...state,
                    list_friends: tmp_list_friends,
                    list_rcvRequestFriends: tmp_list_rcvRequestFriends,
                    list_sendRequestFriends: tmp_list_sendRequestFriends
                }
            } else {
                return {
                    ...state
                }
            }
        } else {
            return {
                ...state
            }
        }
    },

    [FRIEND_STATE_INIT]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log('FRIEND_STATE_INIT');

        if (isSuccessed) {
            const { isLogoutSuccess } = action.payload;

            if (isLogoutSuccess) {
                return {
                    ...state,
                    list_friends            : [], 
                    list_searchFriends       : [],
                    list_rcvRequestFriends  : [], 
                    list_sendRequestFriends : [],

                    selectedFriend_info     : [],

                    isNeedToUpdate          : false,
                    isSearchOpened          : false,
                    
                    searchKind              : 'search', 
                        
                    searchValue             : '', 
                    searchResult            : [], 
                    searchResultId          : [], 
                    searchResultContacts    : [], 
                    web_searchResult        : [],
                    web_searchResultId      : [],
                    
                    isOpenSearch            : false,

                    selectedFunc            : 'NONE',
                    
                    isMyProfileModalOpened          : false,
                    isFriendProfileModalOpened      : false,
                    isSearchFriendsModalOpened      : false,
                    isEditFriendsModalOpened        : false,
                    isAddFriendWindowModalOpened    : false,
                    isAddByRecommendModalOpened     : false,
                    isAddByKeywordModalOpened       : false,
                    isAddByIDModalOpened            : false
                }
            } else {
                //console.log('FRIEND_STATE_INIT - isLogoutSuccess => ', isLogoutSuccess);
                return {
                    ...state,
                }
            }
        } else {
            //console.log('FRIEND_STATE_INIT - isSuccessed => ', isSuccessed);
            return {
                ...state,
            }
        }
    },

    [SEARCH_FRIEND_LIST]: (state, action) => {
        const { result } = action.payload;
        //console.log('SEARCH_FRIEND_LIST - result => ', result);

        return {
            ...state,
            list_searchFriends : result
        }
    },

    [FRIEND_CHAT_RESULT]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isEntrySuccess } = action.payload;

            if (isEntrySuccess) {  // 1대1 채팅 입장에 성공한 경우
                const { isMyProfileModalOpened, isFriendProfileModalOpened } = state;

                if (isMyProfileModalOpened) {
                    return {
                        ...state,
                        isMyProfileModalOpened : false
                    }
                } else {
                    if (isFriendProfileModalOpened) {
                        return {
                            ...state,
                            isFriendProfileModalOpened : false
                        }
                    }
                }
            } else {
                //console.log('FRIEND_CHAT_RESULT - isEntrySuccess => ', isEntrySuccess);
                return {
                    ...state,
                }
            }
        } else {
            //console.log('FRIEND_CHAT_RESULT - isSuccessed => ', isSuccessed);
            return {
                ...state,
            }
        }
    },

    [FRIEND_UPDATE_SUCCESS]: (state, action) => {
        return {
            ...state,
            isNeedToUpdate : false
        }
    },

    [CHANGE_IS_OPEN_SEARCH]: (state, action) => {
        const { isOpenedSearch } = action.payload;

        return {
            ...state,
            isOpenSearch : isOpenedSearch
        }
    },

    [CHANGE_SEARCH_KIND]: (state, action) => {
        const { searchKind } = action.payload;

        return {
            ...state,
            searchKind : searchKind,
            searchValue : '',
            searchResult : [],
            searchResultId : [],
            searchResultContacts : [],
        }
    },

    [CHANGE_USER_STATUS]: (state, action) => {
        const { userSeq, userStatus } = action.payload;

        return produce (state, draft => {
            const user_info = draft.list_friends.find(info => info.userSeq === userSeq);
            if (user_info !== undefined && user_info !== null) {
                user_info.userStatus = userStatus;
            }
        });
    },

    [FRIEND_LIST_SUCCESS]: (state, action) => {
        const { list_friends, list_rcvRequestFriends, list_sendRequestFriends } = action.payload;

        let tmp_list_friends = [];
        let tmp_list_rcvRequestFriends = [];
        let tmp_list_sendRequestFriends = [];

        if (list_friends !== undefined && list_friends !== null) {
            tmp_list_friends = list_friends.map(row => ({
                userSeq: row.FRIEND_SEQ,
                userNickname: row.USER_NICKNAME,
                userProfile: row.USER_PROFILE,
                profileImgUrl: row.USER_PROFILE_IMG,
                userStatus: row.FRIEND_STATUS,
                applyStatus: row.APPLY_STATUS
            }));
        }

        if (list_rcvRequestFriends !== undefined && list_rcvRequestFriends !== null) {
            tmp_list_rcvRequestFriends = list_rcvRequestFriends.map(row => ({
                userSeq: row.SENDER_SEQ,
                userNickname: row.USER_NICKNAME,
                userProfile: row.USER_PROFILE,
                profileImgUrl: row.USER_PROFILE_IMG,
                applyStatus: row.APPLY_STATUS,
                //userStatus : row.FRIEND_STATUS,
                //status : row.APPLY_STATUS
            }));
        }

        if (list_sendRequestFriends !== undefined && list_sendRequestFriends !== null) {
            tmp_list_sendRequestFriends = list_sendRequestFriends.map(row => ({
                userSeq: row.RECEIVER_SEQ,
                userNickname: row.USER_NICKNAME,
                userProfile: row.USER_PROFILE,
                profileImgUrl: row.USER_PROFILE_IMG,
                applyStatus: row.APPLY_STATUS,
                //userStatus : row.FRIEND_STATUS,
                //status : row.APPLY_STATUS
            }));
        }
        
        return {
            ...state,
            list_friends: tmp_list_friends,
            list_rcvRequestFriends: tmp_list_rcvRequestFriends,
            list_sendRequestFriends: tmp_list_sendRequestFriends
        }
    },

    [SEARCH_LIST_SUCCESS]: (state, action) => {
        const { selectedFunc, kind, keyWord, mode } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('SEARCH_LIST_SUCCESS - kind => ', kind);
        
        if (isSuccessed) {
            const { list_user } = action.payload;
            let tmp_list_user = [];

            if (list_user !== undefined && list_user !== null) {
                tmp_list_user = list_user.map(row => ({
                    userSeq         : row.USER_SEQ,
                    userNickname    : row.USER_NICKNAME,
                    userProfile     : row.USER_PROFILE,
                    profileImgUrl   : row.USER_PROFILE_IMG,
                    isCanApply      : row.PUBLIC_YN,
                    applyStatus     : row.APPLY_STATUS,
                    //isSendUser      : row.SEND_YN,
                }));
            }

            if (mode === 'hiclass') {
                return {
                    ...state,
                    selectedFunc        : selectedFunc,
                    searchKind          : kind,
                    searchValue         : keyWord,
                    searchResult        : tmp_list_user
                }
            } else {    // web search case
                return {
                    ...state,
                    selectedFunc        : selectedFunc,
                    web_searchResult    : tmp_list_user
                }
            }
        } else {
            if (mode === 'hiclass') {
                return {
                    ...state,
                    searchKind          : kind,
                    searchValue         : keyWord,
                    searchResult        : []
                }
            } else {
                return {
                    ...state,
                    web_searchResult    : []
                }
            }
        }
    },

    [SEARCH_ID_SUCCESS]: (state, action) => {
        const { selectedFunc, kind, userId, mode } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('SEARCH_ID_SUCCESS - kind => ', kind);

        if (isSuccessed) {
            const { user_info } = action.payload;
            let tmp_user_info = [];

            if (user_info !== undefined && user_info !== null) {
                tmp_user_info = user_info.map(row => ({
                    userSeq         : row.USER_SEQ,
                    userNickname    : row.USER_NICKNAME,
                    userProfile     : row.USER_PROFILE,
                    profileImgUrl   : row.USER_PROFILE_IMG,
                    isCanApply      : row.PUBLIC_YN,
                    applyStatus     : row.APPLY_STATUS,
                    //isSendUser      : row.SEND_YN,
                }));
            }

            if (mode === 'hiclass') {
                return {
                    ...state,
                    selectedFunc        : selectedFunc,
                    searchKind          : kind,
                    searchValue         : userId,
                    searchResultId      : tmp_user_info
                }
            } else {    // web search case
                return {
                    ...state,
                    selectedFunc        : selectedFunc,
                    web_searchResultId  : tmp_user_info
                }
            }
        } else {
            if(mode === 'hiclass') {
                return {
                    ...state,
                    searchKind          : kind,
                    searchValue         : userId,        
                    searchResultId      : []
                }
            } else {
                return {
                    ...state,
                    web_searchResultId  : []
                }
            }
        }
    },

    [APPLY_MULTI_FRIEND_SUCCESS]: (state, action) => {
        const { mode } = action.parms;
        const { selectedFunc } = state;
        const { isSuccessed } = action.payload;
        //console.log('APPLY_MULTI_FRIEND_SUCCESS -> ', selectedFunc);

        if (isSuccessed) {
            const { isApplySuccess, isApproveSuccess } = action.payload;
            let list_apply = [];
            let list_accept = [];

            if (isApplySuccess) {
                const { list_applyUserSEQ } = action.payload;
                if (list_applyUserSEQ !== undefined && list_applyUserSEQ !== null) {
                    list_apply = list_applyUserSEQ;
                }
            } else {
                //console.log('APPLY_MULTI_FRIEND - isApplySuccess => ', isApplySuccess);
            }

            if (isApproveSuccess) {
                const { list_acceptUserSEQ } = action.payload;
                if (list_acceptUserSEQ !== undefined && list_acceptUserSEQ !== null) {
                    list_accept = list_acceptUserSEQ;
                }
            } else {
                //console.log('APPLY_MULTI_FRIEND - isApproveSuccess => ', isApproveSuccess);
            }

            return produce (state, draft => {
                let user_info;

                list_apply.forEach(userSeq => {
                    if(mode === 'hiclass') {
                        if(selectedFunc === 'KEYWORD') {
                            if(draft.searchResult !== undefined && draft.searchResult !== null) {
                                user_info = draft.searchResult.find(info => info.userSeq === userSeq);
                            }
                        } else {
                            if(draft.searchResultId !== undefined && draft.searchResultId !== null) {
                                user_info = draft.searchResultId.find(info => info.userSeq === userSeq);
                            }
                        }
                    } else {
                        if(selectedFunc === 'KEYWORD') {
                            if(draft.web_searchResult !== undefined && draft.web_searchResult !== null) {
                                user_info = draft.web_searchResult.find(info => info.userSeq === userSeq);
                            }
                            
                        } else {
                            if(draft.web_searchResultId !== undefined && draft.web_searchResultId !== null) {
                                user_info = draft.web_searchResultId.find(info => info.userSeq === userSeq);
                            }
                        }
                    }

                    if(user_info !== undefined && user_info !== null) {
                        user_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPLY;
                        user_info.isCanApply = 'N';

                        if(draft.list_sendRequestFriends !== undefined && draft.list_sendRequestFriends !== null) {
                            const check_info = draft.list_sendRequestFriends.find(info => info.userSeq === userSeq);
                            if(check_info !== undefined && check_info !== null) {   // 거절당한 친구에게 검색창에서 친구신청 하는 경우
                                //console.log('[검색] - 거절당한 친구에게 신청');
                                check_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPLY;
                                check_info.isCanApply = 'N';
                            } else {
                                //console.log('[검색] - 신규 친구신청');
                                draft.list_sendRequestFriends.push(user_info);      
                            }
                        }
                    } else {
                        if(draft.list_sendRequestFriends !== undefined && draft.list_sendRequestFriends !== null) {
                            user_info = draft.list_sendRequestFriends.find(info => info.userSeq === userSeq);

                            if(user_info !== undefined && user_info !== null) { // 거절당한 친구에게 목록에서 친구신청 하는 경우
                                //console.log('[목록] - 거절당한 친구에게 신청');
                                user_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPLY;
                                user_info.isCanApply = 'N';
                            }
                        }
                    }
                });

                list_accept.forEach(userSeq => {
                    if(mode === 'hiclass') {
                        if(selectedFunc === 'KEYWORD') {
                            if(draft.searchResult !== undefined && draft.searchResult !== null) {
                                user_info = draft.searchResult.find(info => info.userSeq === userSeq);
                            }
                        } else {
                            if(draft.searchResultId !== undefined && draft.searchResultId !== null) {
                                user_info = draft.searchResultId.find(info => info.userSeq === userSeq);
                            }
                        }
                    } else {
                        if(selectedFunc === 'KEYWORD') {
                            if(draft.web_searchResult !== undefined && draft.web_searchResult !== null) {
                                user_info = draft.web_searchResult.find(info => info.userSeq === userSeq);
                            }
                            
                        } else {
                            if(draft.web_searchResultId !== undefined && draft.web_searchResultId !== null) {
                                user_info = draft.web_searchResultId.find(info => info.userSeq === userSeq);
                            }
                        }
                    }

                    if (user_info !== undefined && user_info !== null) {
                        user_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPROVE;
                        user_info.isCanApply = 'N';

                        if(draft.list_rcvRequestFriends !== undefined && draft.list_rcvRequestFriends !== null) {
                            const check_info = draft.list_rcvRequestFriends.find(info => info.userSeq === userSeq);
                            if (check_info !== undefined && check_info !== null) {  // 검색창에서 나에게 친구 신청한 사용자에게 신청 -> 쌍방 친구 됨 
                                //console.log('[검색] - 나에게 신청한 친구에게 신청 -> 쌍방 친구 됨');
                                check_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPROVE;
                                check_info.isCanApply = 'N';

                                draft.list_friends.push(check_info);
                            }
                        } else {
                            //console.log('이 케이스는 없어야 함! 나에게 요청한 친구에게 내가 apply 했지만 나에게 요청한 리스트에 해당 친구가 없음');
                        }
                    } else {
                        //console.log('이 케이스는 없어야 함! 검색창이 아닌 곳에서 나에게 요청한 친구에게 apply');
                    }

                    draft.list_rcvRequestFriends = draft.list_rcvRequestFriends.filter(info => info.userSeq !== userSeq);
                });
            });
        } else {
            //console.log('APPLY_MULTI_FRIEND - isSuccessed => ', isSuccessed);

            return {
                ...state,
            }
        }
    },

    [APPLY_FRIEND_SUCCESS]: (state, action) => {
        const { applyUserSeq } = action.parms;
        const { selectedFunc } = state;
        const { isSuccessed } = action.payload;
        //console.log('APPLY_FRIEND_SUCCESS => ', selectedFunc);

        if (isSuccessed) {
            const { isApplySuccess } = action.payload;

            if (isApplySuccess) {
                return produce (state, draft => {
                    if (draft.list_sendRequestFriends !== undefined && draft.list_sendRequestFriends !== null) {
                        const user_info = draft.list_sendRequestFriends.find(info => info.userSeq === applyUserSeq);
                        if (user_info !== undefined && user_info !== null) {
                            user_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPLY;
                            user_info.isCanApply = 'N';
                        }
                    }

                    if (draft.searchResult !== undefined && draft.searchResult !== null) {
                        const user_info = draft.searchResult.find(info => info.userSeq === applyUserSeq);
                        if (user_info !== undefined && user_info !== null) {
                            user_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPLY;
                            user_info.isCanApply = 'N';

                            const check_info = draft.list_sendRequestFriends.find(info => info.userSeq === applyUserSeq);
                            if (check_info !== undefined && check_info !== null) {
                                check_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPLY;
                                user_info.isCanApply = 'N';
                            } else {
                                draft.list_sendRequestFriends.push(user_info);
                            }
                        }
                    }

                    if (draft.searchResultId !== undefined && draft.searchResultId !== null) {
                        const user_info = draft.searchResultId.find(info => info.userSeq === applyUserSeq);
                        if (user_info !== undefined && user_info !== null) {
                            user_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPLY;
                            user_info.isCanApply = 'N';

                            const check_info = draft.list_sendRequestFriends.find(info => info.userSeq === applyUserSeq);
                            if (check_info !== undefined && check_info !== null) {
                                check_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPLY;
                                user_info.isCanApply = 'N';
                            } else {
                                draft.list_sendRequestFriends.push(user_info);
                            }
                        }
                    }
                });
            } else {
                //console.log('APPLY_FRIEND - isApplySuccess => ', isApplySuccess);
                const { isApproveSuccess } = action.payload;

                if (isApproveSuccess) {
                    return produce (state, draft => {
                        if (draft.list_rcvRequestFriends !== undefined && draft.list_rcvRequestFriends !== null) {
                            const user_info = draft.list_rcvRequestFriends.find(info => info.userSeq === applyUserSeq);
                            if (user_info !== undefined && user_info !== null) {
                                user_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPROVE;
                                user_info.isCanApply = 'N';

                                const check_info = draft.list_friends.find(info => info.userSeq === applyUserSeq);
                                if (check_info !== undefined && check_info !== null) {
                                    check_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPROVE;
                                    check_info.isCanApply = 'N';
                                } else {
                                    draft.list_friends.push(user_info);
                                }
                            }
                        }

                        if (draft.searchResult !== undefined && draft.searchResult !== null) {
                            const user_info = draft.searchResult.find(info => info.userSeq === applyUserSeq);
                            if (user_info !== undefined && user_info !== null) {
                                user_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPROVE;
                                user_info.isCanApply = 'N';

                                const check_info = draft.list_rcvRequestFriends.find(info => info.userSeq === applyUserSeq);
                                if (check_info !== undefined && check_info !== null) {
                                    check_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPROVE;
                                    user_info.isCanApply = 'N';
                                } 
                                
                                const check_info2 = draft.list_friends.find(info => info.userSeq === applyUserSeq);
                                if (check_info2 !== undefined && check_info2 !== null) {
                                    check_info2.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPROVE;
                                    check_info2.isCanApply = 'N';
                                } else {
                                    draft.list_friends.push(user_info);
                                }
                            }   
                        }

                        if (draft.searchResultId !== undefined && draft.searchResultId !== null) {
                            const user_info = draft.searchResultId.find(info => info.userSeq === applyUserSeq);
                            if (user_info !== undefined && user_info !== null) {
                                user_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPROVE;
                                user_info.isCanApply = 'N';

                                const check_info = draft.list_rcvRequestFriends.find(info => info.userSeq === applyUserSeq);
                                if (check_info !== undefined && check_info !== null) {
                                    check_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPROVE;
                                    user_info.isCanApply = 'N';
                                } 
                                
                                const check_info2 = draft.list_friends.find(info => info.userSeq === applyUserSeq);
                                if (check_info2 !== undefined && check_info2 !== null) {
                                    check_info2.applyStatus = ConstData.FRIEND_APPLY_STATUS.APPROVE;
                                    check_info2.isCanApply = 'N';
                                } else {
                                    draft.list_friends.push(user_info);
                                }
                            }
                        }

                        draft.list_sendRequestFriends = draft.list_sendRequestFriends.filter(info => info.userSeq !== applyUserSeq);
                        draft.list_rcvRequestFriends = draft.list_rcvRequestFriends.filter(info => info.userSeq !== applyUserSeq);
                    });
                } else {
                    //console.log('APPLY_FRIEND - isApproveSuccess => ', isApproveSuccess);
                    
                    return {
                        ...state,
                    }
                }
            }
        } else {
            //console.log('APPLY_FRIEND - isSuccessed => ', isSuccessed);

            return {
                ...state,
            }
        }
    },

    [CONFIRM_FRIEND_SUCCESS]: (state, action) => {
        const { confirmUserSeq } = action.parms;
        const { isSuccessed } = action.payload;
        console.log('CONFIRM_FRIEND_SUCCESS - ', action.payload);

        if (isSuccessed) {
            const { isApproveSuccess } = action.payload;

            if (isApproveSuccess) {
                const { user_info } = action.payload;

                if (user_info !== undefined && user_info !== null) {
                    const newFriendInfo = {
                        userSeq         : user_info.USER_SEQ, 
                        userNickname    : user_info.USER_NICKNAME,
                        userProfile     : user_info.USER_PROFILE,
                        profileImgUrl  : user_info.USER_PROFILE_IMG,
                        applyStatus     : user_info.APPLY_STATUS,
                        userStatus      : user_info.FRIEND_STATUS
                    };
                    
                    return {
                        ...state,
                        list_friends : state.list_friends.concat(newFriendInfo),
                        list_rcvRequestFriends : state.list_rcvRequestFriends.filter(row => row.userSeq !== confirmUserSeq)
                    }
                }
            } else {
                //console.log('CONFIRM_FRIEND - isApproveSuccess => ', isApproveSuccess);

                return {
                    ...state,
                }
            }
        } else {
            //console.log('CONFIRM_FRIEND - isSuccessed => ', isSuccessed);

            return  {
                ...state,
            }
        }
    },

    [REJECT_APPLY_FRIEND_SUCCESS]: (state, action) => {
        const { rejectUserSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('REJECT_APPLY_FRIEND_SUCCESS');

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                return {
                    ...state,
                    list_rcvRequestFriends : state.list_rcvRequestFriends.filter(row => row.userSeq !== rejectUserSeq)
                }
            } else {
                //log('REJECT_APPLY_FRIEND - isUpdateSuccess => ', isUpdateSuccess);

                return {
                    ...state,
                }
            }
        } else {
            //console.log('REJECT_APPLY_FRIEND - isSuccessed => ', isSuccessed);

            return {
                ...state,
            }
        }
    },

    [REMOVE_FRIEND_SUCCESS]: (state, action) => {
        const { deleteUserSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('REMOVE_FRIEND_SUCCESS');

        if (isSuccessed) {
            const { isRemoveSuccess } = action.payload;

            if (isRemoveSuccess) {
                return {
                    ...state,
                    list_friends : state.list_friends.filter(row => row.userSeq !== deleteUserSeq)
                }
            } else {
                //console.log('REMOVE_FRIEND - isRemoveSuccess => ', isRemoveSuccess);

                return {
                    ...state,
                }
            }
        } else {
            //console.log('REMOVE_FRIEND - isSuccessed => ', isSuccessed);

            return {
                ...state,
            }
        }
    },

    [CANCEL_APPLY_FRIEND_SUCCESS]: (state, action) => {
        const { cancelUserSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('CANCEL_APPLY_FRIEND_SUCCESS');

        if (isSuccessed) {
            const { isCancelSuccess } = action.payload;

            if (isCancelSuccess) {
                return {
                    ...state,
                    list_sendRequestFriends : state.list_sendRequestFriends.filter(row => row.userSeq !== cancelUserSeq)
                }
            } else {
                //log('CANCEL_APPLY_FRIEND - isCancelSuccess => ', isCancelSuccess);

                return {
                    ...state,
                }
            }
        } else {
            //console.log('CANCEL_APPLY_FRIEND - isSuccessed => ', isSuccessed);

            return {
                ...state,
            }
        }
    },

    /**********************************************************/
    /////////////////////////// 모 달 ///////////////////////////
    /**********************************************************/

    [CLOSE_MODAL_FRIENDS_LIST]: (state, action) => {
        //console.log('CLOSE_MODAL_FRIENDS_LIST => ', action.payload);

        return {
            ...state,
            selectedFunc                : action.payload === undefined? 'NONE' : action.payload,
            searchResult                : [],
            searchResultId              : [],
            searchResultContacts        : [],
            
            isMyProfileModalOpened      : false,
            isFriendProfileModalOpened  : false,
            isSearchFriendsModalOpened  : false,
            isEditFriendsModalOpened    : false,
            isAddFriendWindowModalOpened : false,
            isAddByRecommendModalOpened : false,
            isAddByKeywordModalOpened   : false,
            isAddByIDModalOpened        : false
        }
    },

    [TOGGLE_MY_PROFILE]: (state, action) => {
        //log('TOGGLE_MY_PROFILE => ', action.payload);

        return {
            ...state,
            selectedFunc                : action.payload === undefined? 'NONE' : action.payload,
            isMyProfileModalOpened      : !state.isMyProfileModalOpened,
            isFriendProfileModalOpened  : false,
            isSearchFriendsModalOpened  : false,
            isEditFriendsModalOpened    : false,
            isAddFriendWindowModalOpened : false,
            isAddByRecommendModalOpened : false,
            isAddByKeywordModalOpened   : false,
            isAddByIDModalOpened        : false
        }
    },

    [TOGGLE_FRIEND_PROFILE]: (state, action) => {
        const { selectedFunc, friendSeq } = action.payload;
        //console.log('TOGGLE_FRIEND_PROFILE => ', action.payload);
        
        if (selectedFunc === 'FRIEND') {
            const selUserInfo = state.list_friends.find(info => info.userSeq === friendSeq);
            if (selUserInfo !== undefined && selUserInfo !== null) {
                //console.log('selUserInfo -> ', selUserInfo);
                
                return {
                    ...state,
                    selectedFriend_info             : selUserInfo,
                    selectedFunc                    : 'FRIEND',
                    isMyProfileModalOpened          : false,
                    isFriendProfileModalOpened      : true,
                    isSearchFriendsModalOpened      : false,
                    isEditFriendsModalOpened        : false,
                    isAddFriendWindowModalOpened    : false,
                    isAddByRecommendModalOpened     : false,
                    isAddByKeywordModalOpened       : false,
                    isAddByIDModalOpened            : false
                }
            } else {
                return {
                    ...state,
                    selectedFriend_info             : [],
                    selectedFunc                    : 'NONE',
                    isMyProfileModalOpened          : false,
                    isFriendProfileModalOpened      : false,
                    isSearchFriendsModalOpened      : false,
                    isEditFriendsModalOpened        : false,
                    isAddFriendWindowModalOpened    : false,
                    isAddByRecommendModalOpened     : false,
                    isAddByKeywordModalOpened       : false,
                    isAddByIDModalOpened            : false
                }
            }
        } else {
            return {
                ...state,
                selectedFriend_info             : [],
                selectedFunc                    : action.payload === undefined? 'NONE' : selectedFunc,
                isMyProfileModalOpened          : false,
                isFriendProfileModalOpened      : false,
                isSearchFriendsModalOpened      : false,
                isEditFriendsModalOpened        : false,
                isAddFriendWindowModalOpened    : false,
                isAddByRecommendModalOpened     : false,
                isAddByKeywordModalOpened       : false,
                isAddByIDModalOpened            : false
            }
        }
    },

    [TOGGLE_SEARCH_FRIENDS]: (state, action) => {
        //log('TOGGLE_SEARCH_FRIENDS => ', action.payload);

        return {
            ...state,
            selectedFunc                    : action.payload === undefined? 'NONE' : action.payload,
            isSearchFriendsModalOpened      : !state.isSearchFriendsModalOpened,
            isEditFriendsModalOpened        : false,
            isAddFriendWindowModalOpened    : false,
            isAddByRecommendModalOpened     : false,
            isAddByKeywordModalOpened       : false,
            isAddByIDModalOpened            : false
        }
    },

    [TOGGLE_EDIT_FRIENDS_LIST]: (state, action) => {
        //console.log('TOGGLE_EDIT_FRIENDS_LIST => ', action.payload);

        return {
            ...state,
            selectedFunc                    : action.payload === undefined? 'NONE' : action.payload,
            isMyProfileModalOpened          : false,
            isFriendProfileModalOpened      : false,
            isEditFriendsModalOpened        : !state.isEditFriendsModalOpened,
            isSearchFriendsModalOpened      : false,
            isAddFriendWindowModalOpened    : false,
            isAddByRecommendModalOpened     : false,
            isAddByKeywordModalOpened       : false,
            isAddByIDModalOpened            : false
        }
    },

    [TOGGLE_ADD_FRIENDS]: (state, action) => {
        //console.log('TOGGLE_ADD_FRIENDS => ', action.payload);

        return {
            ...state,
            selectedFunc                    : action.payload === undefined? 'NONE' : action.payload,
            searchResult                    : [],
            searchResultId                  : [],
            isSearchFriendsModalOpened      : false,
            isEditFriendsModalOpened        : false,
            isAddFriendWindowModalOpened    : !state.isAddFriendWindowModalOpened,
            isAddByRecommendModalOpened     : false,
            isAddByKeywordModalOpened       : false,
            isAddByIDModalOpened            : false
        }
    },

    [TOGGLE_ADD_FRIENDS_RECOMMEND]: (state, action) => {
        //console.log('TOGGLE_ADD_FRIENDS_RECOMMEND => ', action.payload);

        return {
            ...state,
            selectedFunc                    : action.payload === undefined? 'NONE' : action.payload,
            isSearchFriendsModalOpened      : false,
            isEditFriendsModalOpened        : false,
            isAddFriendWindowModalOpened    : false,
            isAddByRecommendModalOpened     : !state.isAddByRecommendModalOpened,
            isAddByKeywordModalOpened       : false,
            isAddByIDModalOpened            : false
        }
    },

    [TOGGLE_ADD_FRIENDS_KEYWORD]: (state, action) => {
        //console.log('TOGGLE_ADD_FRIENDS_KEYWORD => ', action.payload);

        return {
            ...state,
            selectedFunc                    : action.payload === undefined? 'NONE' : action.payload,
            isSearchFriendsModalOpened      : false,
            isEditFriendsModalOpened        : false,
            isAddFriendWindowModalOpened    : false,
            isAddByRecommendModalOpened     : false,
            isAddByKeywordModalOpened       : !state.isAddByKeywordModalOpened,
            isAddByIDModalOpened            : false
        }
    },

    [TOGGLE_ADD_FRIENDS_ID]: (state, action) => {
        //console.log('TOGGLE_ADD_FRIENDS_ID => ', action.payload);

        return {
            ...state,
            selectedFunc                    : action.payload === undefined? 'NONE' : action.payload,
            isSearchFriendsModalOpened      : false,
            isEditFriendsModalOpened        : false,
            isAddFriendWindowModalOpened    : false,
            isAddByRecommendModalOpened     : false,
            isAddByKeywordModalOpened       : false,
            isAddByIDModalOpened            : !state.isAddByIDModalOpened
        }
    },

    /**********************************************************/
    /////////////////////////// RBMQ ///////////////////////////
    /**********************************************************/

    [MQ_LOGIN_FRIEND]: (state, action) => {
        const { userSeq } = action.payload;
        //console.log('MQ_LOGIN_FRIEND => ', userSeq);
        
        return produce (state, draft => {
            const user_info = draft.list_friends.find(info => info.userSeq === userSeq);
            if (user_info !== undefined && user_info !== null) {
                user_info.userStatus = ConstData.USER_STATUS.LOG_IN;
            }
        });
    },

    [MQ_UPDATE_FRIEND_STATUS]: (state, action) => {
        const { userSeq, status } = action.payload;
        //console.log('MQ_UPDATE_FRIEND_STATUS => ', userSeq);
        
        return produce (state, draft => {
            const user_info = draft.list_friends.find(info => info.userSeq === userSeq);
            if (user_info !== undefined && user_info !== null) {
                user_info.userStatus = status;
            }
        });
    },

    [MQ_LOGOUT_FRIEND]: (state, action) => {
        const { userSeq } = action.payload;
        //console.log('MQ_LOGOUT_FRIEND => ', userSeq);
        
        return produce (state, draft => {
            const user_info = draft.list_friends.find(info => info.userSeq === userSeq);
            if (user_info !== undefined && user_info !== null) {
                user_info.userStatus = ConstData.USER_STATUS.LOG_OUT;
            }
        });
    },

    [MQ_APPLY_FRIEND]: (state, action) => {
        const { user_info } = action.payload;
        //console.log('MQ_APPLY_FRIEND => ', user_info);

        return {
            ...state,
            list_rcvRequestFriends : state.list_rcvRequestFriends.concat(user_info)
        }
    },

    [MQ_SEND_APPLY_FRIEND]: (state, action) => {
        const { user_info } = action.payload;
        //console.log('MQ_SEND_APPLY_FRIEND => ', user_info);

        return {
            ...state,
            list_sendRequestFriends     : state.list_sendRequestFriends.concat(user_info)
        }
    },

    [MQ_MULTIPLE_APPLY_FRIEND]: (state, action) => {
        const { list_user } = action.payload;
        //console.log('MQ_MULTIPLE_APPLY_FRIEND => ', list_user);

        return {
            ...state,
            list_sendRequestFriends     : state.list_sendRequestFriends.concat(list_user)
        }
    },

    [MQ_ADD_FRIEND]: (state, action) => {
        const { user_info } = action.payload;
        //console.log('MQ_ADD_FRIEND => ', user_info);

        return {
            ...state,
            list_friends                : state.list_friends.concat(user_info),
            list_sendRequestFriends     : state.list_sendRequestFriends.filter(info => info.userSeq !== user_info.userSeq),
            list_rcvRequestFriends      : state.list_rcvRequestFriends.filter(info => info.userSeq !== user_info.userSeq)
        }
    },

    [MQ_MULTIPLE_ADD_FRIEND]: (state, action) => {
        const { list_user } = action.payload;
        //console.log('MQ_MULTIPLE_ADD_FRIEND => ', list_user);

        if (list_user !== undefined && list_user !== null) {
            return produce (state, draft => {
                list_user.forEach(user_info => {
                    draft.list_rcvRequestFriends = draft.list_rcvRequestFriends.filter(info => info.userSeq !== user_info.userSeq);
                    draft.list_sendRequestFriends = draft.list_sendRequestFriends.filter(info => info.userSeq !== user_info.userSeq);
                    draft.list_friends.push(user_info);
                });
            });
        } else {
            //console.log('MQ_MULTIPLE_ADD_FRIEND - list_user => ', list_user);
            return {
                ...state,
            }
        }
    },

    [MQ_REMOVE_FRIEND]: (state, action) => {
        const { userSeq } = action.payload;
        //console.log('MQ_REMOVE_FRIEND => ', userSeq);

        return {
            ...state,
            list_friends                : state.list_friends.filter(info => info.userSeq!==userSeq)
        }
    },

    [MQ_CANCEL_FRIEND]: (state, action) => {
        const { userSeq } = action.payload;
        //console.log('MQ_CANCEL_FRIEND => ', userSeq);

        return {
            ...state,
            list_rcvRequestFriends      : state.list_rcvRequestFriends.filter(info => info.userSeq !== userSeq)
        }
    },

    [MQ_CANCEL_FRIEND_ME]: (state, action) => {
        const { userSeq } = action.payload;
        //console.log('MQ_CANCEL_FRIEND_ME => ', userSeq);
        
        return {
            ...state,
            list_sendRequestFriends     : state.list_sendRequestFriends.filter(info => info.userSeq !== userSeq)
        }
    },

    [MQ_REJECT_FRIEND]: (state, action) => {
        const { userSeq } = action.payload;
        //console.log('MQ_REJECT_FRIEND => ', userSeq);

        return produce (state, draft => {
            const user_info = draft.list_sendRequestFriends.find(info => info.userSeq === userSeq);
            if (user_info !== undefined && user_info !== null) {
                user_info.applyStatus = ConstData.FRIEND_APPLY_STATUS.REJECT;
            }
        });
    },

    [MQ_REJECT_FRIEND_ME]: (state, action) => {
        const { userSeq } = action.payload;
        //console.log('MQ_REJECT_FRIEND_ME=> ', userSeq);

        return {
            ...state,
            list_rcvRequestFriends      : state.list_rcvRequestFriends.filter(info => info.userSeq !== userSeq)
        }
    },

    [MQ_UPDATE_FRIEND_INFO]: (state, action) => {
        const { update_info } = action.payload;
        const { userSeq, userNickname, userProfile, profileImgUrl } = update_info;

        return produce (state, draft => {
            if (draft.selectedFriend_info !== undefined && draft.selectedFriend_info !== null) {
                if (draft.selectedFriend_info.userSeq === userSeq) {
                    draft.selectedFriend_info.userNickname = userNickname !== null ? userNickname : draft.selectedFriend_info.userNickname;
                    draft.selectedFriend_info.userProfile = userProfile !== null ? userProfile : draft.selectedFriend_info.userProfile;
                    draft.selectedFriend_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : draft.selectedFriend_info.profileImgUrl;
                }
            }

            if (draft.list_friends !== undefined && draft.list_friends !== null) {
                const user_info = draft.list_friends.find(info => info.userSeq === userSeq);
                if (user_info !== undefined && user_info !== null) {
                    user_info.userNickname = userNickname !== null ? userNickname : user_info.userNickname;
                    user_info.userProfile = userProfile !== null ? userProfile : user_info.userProfile;
                    user_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : user_info.profileImgUrl;
                }
            }
            
            if (draft.list_sendRequestFriends !== undefined && draft.list_sendRequestFriends !== null) {
                const user_info = draft.list_sendRequestFriends.find(info => info.userSeq === userSeq);
                if (user_info !== undefined && user_info !== null) {
                    user_info.userNickname = userNickname !== null ? userNickname : user_info.userNickname;
                    user_info.userProfile = userProfile !== null ? userProfile : user_info.userProfile;
                    user_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : user_info.profileImgUrl;
                }
            }
            
            if (draft.list_rcvRequestFriends !== undefined && draft.list_rcvRequestFriends !== null) {
                const user_info = draft.list_rcvRequestFriends.find(info => info.userSeq === userSeq);
                if (user_info !== undefined && user_info !== null) {
                    user_info.userNickname = userNickname !== null ? userNickname : user_info.userNickname;
                    user_info.userProfile = userProfile !== null ? userProfile : user_info.userProfile;
                    user_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : user_info.profileImgUrl;
                }
            }
            
            if (draft.list_searchFriends !== undefined && draft.list_searchFriends !== null) {
                const user_info = draft.list_searchFriends.find(info => info.userSeq === userSeq);
                if (user_info !== undefined && user_info !== null) {
                    user_info.userNickname = userNickname !== null ? userNickname : user_info.userNickname;
                    user_info.userProfile = userProfile !== null ? userProfile : user_info.userProfile;
                    user_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : user_info.profileImgUrl;
                }
            }

            if (draft.searchResult !== undefined && draft.searchResult !== null) {
                const user_info = draft.searchResult.find(info => info.userSeq === userSeq);
                if (user_info !== undefined && user_info !== null) {
                    user_info.userNickname = userNickname !== null ? userNickname : user_info.userNickname;
                    user_info.userProfile = userProfile !== null ? userProfile : user_info.userProfile;
                    user_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : user_info.profileImgUrl;
                }
            }

            if (draft.searchResultId !== undefined && draft.searchResultId !== null) {
                const user_info = draft.searchResultId.find(info => info.userSeq === userSeq);
                if (user_info !== undefined && user_info !== null) {
                    user_info.userNickname = userNickname !== null ? userNickname : user_info.userNickname;
                    user_info.userProfile = userProfile !== null ? userProfile : user_info.userProfile;
                    user_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : user_info.profileImgUrl;
                }
            }

            if (draft.web_searchResult !== undefined && draft.web_searchResult !== null) {
                const user_info = draft.web_searchResult.find(info => info.userSeq === userSeq);
                if (user_info !== undefined && user_info !== null) {
                    user_info.userNickname = userNickname !== null ? userNickname : user_info.userNickname;
                    user_info.userProfile = userProfile !== null ? userProfile : user_info.userProfile;
                    user_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : user_info.profileImgUrl;
                }
            }

            if (draft.web_searchResultId !== undefined && draft.web_searchResultId !== null) {
                const user_info = draft.web_searchResultId.find(info => info.userSeq === userSeq);
                if (user_info !== undefined && user_info !== null) {
                    user_info.userNickname = userNickname !== null ? userNickname : user_info.userNickname;
                    user_info.userProfile = userProfile !== null ? userProfile : user_info.userProfile;
                    user_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : user_info.profileImgUrl;
                }
            }
        });
    },
}, initialState);

export default friend;