/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

// import contents01 from '../../image/info/contents/contents01.png';
// import contents02 from '../../image/info/contents/contents02.png';
// import contents03 from '../../image/info/contents/contents03.png';
// import contents04 from '../../image/info/contents/contents04.png';

const WebHiClassInfoContents = () => {
    return (
        <div className="d-flex flex-column w-100" style={{ margin: '10px 0px', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
            <div className="d-flex justify-content-center align-items-center w-100" style={{ margin: '2rem 0' }}>
                <div className="d-flex flex-column justify-content-center">
                    <div className="d-flex flex-column w-100">
                        <div className="d-flex flex-wrap" style={{ fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-blue-text">새로운&nbsp;</span>
                            <span className="hiclasstv-darkblue-text">디지로그 콘텐츠</span>
                        </div>
                        <div className="d-flex my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                            <div className="d-flex flex-wrap mt-2 mb-5">
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/contents/contents01.png" alt="contents01.png" />
                                </div>
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex" style={{ fontSize: '1rem' }}>
                                        볼펜으로 종이에 쓰듯 전자펜으로 종이에 쓰면, 컴퓨터 화면과 종이에 동시에 판서가 되어 사람과 컴퓨터, 사람과 인터넷을 연결합니다.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                            <div className="d-flex flex-wrap mb-5">
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex" style={{ fontSize: '1rem' }}>
                                        노트나 책, 종이 카드, 인터넷 콘텐츠, 동영상 등을 일체화 하거나 동기화 하여, 함께 작동함으로 콘텐츠의 가치를 높여 드립니다.
                                    </span>
                                </div>
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/contents/contents02.png" alt="contents02.png" />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                            <div className="d-flex flex-wrap mb-5">
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/contents/contents03.png" alt="contents03.png" />
                                </div>
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex" style={{ fontSize: '1rem' }}>
                                        노트와 책, 칠판과의 경계를 허물어 손글씨 와 콘텐츠를 인터넷과 함께 사용하여 학습 및 업무 효과를 극대화 합니다.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex my-4 w-100">
                            <div className="d-flex flex-wrap mb-2">
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex" style={{ fontSize: '1rem' }}>
                                        전자종이 기반의 다양한 페이퍼 태블릿으로 인공지능 콘텐츠를 쉽게 사용합니다. 또한 음성, 키보드, 펜으로 편리하게 소통합니다.
                                    </span>
                                </div>
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/contents/contents04.png" alt="contents04.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WebHiClassInfoContents;