import React, { Component } from 'react';
import { connect } from 'react-redux';

import { enBrowserType } from '../../lib/ConstCommand';

import LiveListTitleTemplate from '../../components/template/title/LiveListTitleTemplate';

import PerformStudioInfo from '../../components/studio/PerformStudioInfo';
import StudioList from '../../components/studio/StudioList';
import MyBroadcastLiveList from '../../components/live/MyBroadcastLiveList';
import WebMyBroadcastLiveList from '../../components/live/WebMyBroadcastLiveList';

class MyBroadcastLiveListContainer extends Component {

    state = {
        isUpdate : false
    }

    render() {
        return (
            this.props.browserType === enBrowserType.HiClass ?
                <>
                    <LiveListTitleTemplate
                        isOnAirLive={this.props.isOnAirLive}
                        isOpenedHandsUp={this.props.isOpenedHandsUp}
                        isOpenedGroupStudy={this.props.isOpenedGroupStudy}
                        handleSelectedFunc={this.props.handleSelectedFunc}
                        selectedFunc={this.props.selectedFunc}
                    />
                    <div className="d-flex flex-column" style={{ height: '100%', minHeight: '50%' }} >
                        {
                            this.props.connectedStudioInfo === null ?
                                <StudioList
                                    userSeq={this.props.userSeq}
                                    list_studio={this.props.list_studio}
                                    list_live={this.props.list_live}
                                    handleSelectedFunc={this.props.handleSelectedFunc}
                                /> :
                                <PerformStudioInfo
                                    userSeq={this.props.userSeq}
                                    studio_info={this.props.connectedStudioInfo}
                                    list_live={this.props.list_live}
                                    handleSelectedFunc={this.props.handleSelectedFunc}
                                />
                        }
                        <MyBroadcastLiveList
                            isVisibleTitle={this.props.list_studio && this.props.list_studio.length > 0 ? true : false}
                            isShowing={this.props.isShowingBroadcastTimeNotify}
                            notifyInfo={this.props.notifyInfo}
                            list_live={this.props.list_live}
                            connectedStudioInfo={this.props.connectedStudioInfo}
                            serverUserSeq={this.props.serverUserSeq}
                            handleSelectedFunc={this.props.handleSelectedFunc}
                        />
                    </div>
                </> :
                <WebMyBroadcastLiveList
                    list_broadcastLive={this.props.list_live}
                    alertKind={this.props.alertKind}
                    alertMessage={this.props.alertMessage}
                    isCertifiedLiveCode={this.props.isCertifiedLiveCode}
                    isCreateLiveSuccess={this.props.isCreateLiveSuccess}
                    createdLiveCode={this.props.createdLiveCode}
                    searchLiveInfo={this.props.searchLiveInfo}
                    handleSelectedFunc={this.props.handleSelectedFunc}
                />
        );
    }
}

export default connect(
    ({ user, live, studio }) => ({
        userSeq                         : user.userSeq,
        serverUserSeq                   : user.serverUserSeq,

        isGotoWebLive                   : live.isGotoWebLive,
        performLiveSeq                  : live.performLiveInfo.liveSeq,

        list_live                       : live.list_live,
        isOnAirLive                     : live.isOnAirLive,

        createdLiveCode                 : live.web_createdLiveCode,
        isCertifiedLiveCode             : live.web_isCertifiedLiveCode,
        isCreateLiveSuccess             : live.web_isCreateLiveSuccess,

        isOpenedHandsUp                 : live.isOpenedHandsUp,
        isOpenedGroupStudy              : live.isOpenedGroupStudy,

        notifyInfo                      : live.notifyInfo,
        isShowingBroadcastTimeNotify    : live.isShowingBroadcastTimeNotify,

        searchLiveInfo                  : live.web_searchLiveInfo,

        alertKind                       : live.web_alertKind,
        alertMessage                    : live.web_alertMessage,

        list_studio                     : studio.list_studio,
        connectedStudioInfo             : studio.connectedStudioInfo,
    }),
    {

    }
)(MyBroadcastLiveListContainer);