/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';

const QnA004 = ({ isOpened, onChangeOpenedFlag }) => {
    useEffect(() => {
        let content = document.getElementById("qna04");

        if (isOpened) {
            content.style.maxHeight = content.scrollHeight + "px";
        } else {
            content.style.maxHeight = null;
        }
    }, [isOpened]);

    return (
        <div className="accordion">
            <div className="accordion-title" onClick={() => onChangeOpenedFlag(3)}>
                <span className="hiclasstv-deepDarkgrey-text button">
                    <b>4. 상세한 제품소개서를 볼 수 있나요?</b>
                </span>
            </div>
            <div className="accordion-content hiclasstv-darkgrey-text" id="qna04">
                <span className="d-flex" style={{ minWidth: 'max-content' }}>A.&nbsp;</span>
                <p>
                    HiClassTV에 관심을 가져 주심에 감사드립니다.<br />
                    아래 연락처로 문의해 주시면 제품소개서를 보내 드리겠습니다.<br />
                    <b>전화 : (02) 3484-4800</b><br />
                    <b>메일 : HiClassTV@easy.co.kr</b>
                </p>
            </div>
        </div>
    );
}
export default QnA004;