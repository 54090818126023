import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { FaExclamation, FaRegGrinBeamSweat } from 'react-icons/fa';

import ConstData from '../../../lib/ConstData';

import Alerts from '../list/Alerts';

const SoundAlertModal = ({ isModalOpened, alertKind, alertMessage, handleSelectedFunc }) => {
    
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [lAlertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    const onCloseModal = () => {
        setAlertKind('');
        setAlertMessage('');
    };
    
    return (
        <Modal isOpen={isModalOpened} centered backdrop={true} onClosed={onCloseModal}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="content-area white dark-grey-text m-0 p-0">
                        <Alerts kind={lAlertKind} message={lAlertMessage} />
                    </div>
                    <button type="button" className="btn btn-primary white-text py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "TOGGLE_SOUND_ALERT" })}>확인</button>
                </div>
            </div>
        </Modal>
    );
};

export default SoundAlertModal;