import React, { useState, useEffect } from "react";
import { MDBNavItem } from "mdbreact";
import { FaQrcode, FaTv } from "react-icons/fa";
/* import { GiTv } from "react-icons/gi";
import { HiMiniTv, HiOutlineTv, HiTv  } from "react-icons/hi2";
import { LiaTvSolid } from "react-icons/lia";
import { IoIosTv, IoMdTv  } from "react-icons/io";
import { IoTv, IoTvOutline, IoTvSharp } from "react-icons/io5";
import { LuTv, LuTv2 } from "react-icons/lu";
import { MdConnectedTv, MdLiveTv, MdOutlineConnectedTv, MdOutlineLiveTv, MdOutlineResetTv
    , MdOutlineTv, MdOutlineTvOff, MdResetTv, MdTv, MdTvOff } from "react-icons/md";
import { RiTv2Fill, RiTv2Line, RiTvFill, RiTvLine } from "react-icons/ri";
import { TbDeviceTv, TbDeviceTvOff, TbDeviceTvOld } from "react-icons/tb"; */

import ConstData from "../../../lib/ConstData";
import { enInfoTap } from "../../../lib/ConstCommand";

const WebHeaderInfoMenu = ({ isMobile, isLoginYn, isInfoPage, hiclasstvInfo, tossAndHandleSelectedFunc }) => {

    const [isLinkMenuOpened, setIsLinkMenuOpened] = useState(false);

    useEffect(() => {
        if (isMobile) {
            if (isLoginYn === "Y") {
                let navbar = document.getElementById("navbar");
                let totalWidth = navbar.offsetWidth;
                let baseWidth = navbar.parentElement.offsetWidth;

                let offset = -1;

                if (isInfoPage) {
                    switch (hiclasstvInfo.currentInfoTap) {
                        case enInfoTap.Home:
                            offset = document.getElementById("tool_info").offsetLeft;
                            break;

                        case enInfoTap.Edutech:
                            offset = document.getElementById("tool_info_edutech").offsetLeft;
                            break;

                        case enInfoTap.Contents:
                            offset = document.getElementById("tool_info_contents").offsetLeft;
                            break;

                        case enInfoTap.SmartSheet:
                            offset = document.getElementById("tool_info_sheet").offsetLeft;
                            break;

                        case enInfoTap.Download:
                            offset = document.getElementById("tool_info").offsetLeft;
                            break;

                        case enInfoTap.FAQ:
                            offset = document.getElementById("tool_info_faq").offsetLeft;
                            break;

                        case enInfoTap.SHARP:
                            offset = document.getElementById("tool_info_sharp").offsetLeft;
                            break;

                        default:
                            offset = document.getElementById("tool_info").offsetLeft;
                            break;
                    }
                } else {
                    offset = document.getElementById("tool_info").offsetLeft;
                }

                let position = -1;

                if (totalWidth - offset < baseWidth) {
                    position = totalWidth - baseWidth;
                } else {
                    position = offset - 12;
                }

                navbar.parentElement.scrollTo(position, 0);
            } else {
                let navbar = document.getElementById("navbar");
                let totalWidth = navbar.offsetWidth;
                let baseWidth = navbar.parentElement.offsetWidth;

                let offset = -1;

                if (hiclasstvInfo.currentInfoTap === enInfoTap.LINK) {
                    offset = document.getElementById("tool_link").offsetLeft;
                }

                let position = -1;

                if (totalWidth - offset < baseWidth) {
                    position = totalWidth - baseWidth;
                } else {
                    position = offset - 12;
                }

                navbar.parentElement.scrollTo(position, 0);
            }
        }
    }, [isMobile, isLoginYn, isInfoPage, hiclasstvInfo]);

    const onClickMenu = (kind) => {
        if (kind === "link") {
            setIsLinkMenuOpened(!isLinkMenuOpened);
            tossAndHandleSelectedFunc("SHOW_LINK");
        }
    }

    const onClickGoToQRLoginPage = () => {
        // console.log("go to qr login page.");
        let origin = window.location.origin;
        window.location = origin + "/main-qrLogin/1001";
        // window.location = ConstData.MAIN_HOST_ADD + "/main-qrLogin/1001";
    }

    const onClickGoToSmartTVLoginPage = (mode) => {
        // console.log("go to smart tv login page.");
        // console.log(window.location);
        let regEx = new RegExp("https://", "gi");
        let origin = window.location.origin;
        let port = window.location.port;
        let host = origin.replace(regEx, "http://");
        // let host = ConstData.MAIN_HOST_ADD.replace(regEx, "http://");
        let location = host;

        if (port !== "3000") {
            location += ":8080";
        }

        if (mode === "web-smarttv") {
            location += "/smarttv_login/1001";
        } else {
            location += "/group_smarttv_login/1001";
        }

        if (location !== "") {
            console.log(location);
            window.location = location;
        }

        /* if (port !== "3000") {
            // window.location = host + ":8080/smart_view/login/1001";
            window.location = host + ":8080/smarttv_login/1001";
        } else {
            // window.location = host + "/smart_view/login/1001";
            window.location = host + "/smarttv_login/1001";
        } */
    }

    return (
        isMobile === true ?
            <>
                <li className="nav_item">
                    <span className={((isInfoPage && hiclasstvInfo.currentInfoTap === enInfoTap.Home) ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_HOME")}>홈</span>
                </li>
                <li className="nav_item" id="tool_info_edutech">
                    <span className={((isInfoPage && hiclasstvInfo.currentInfoTap === enInfoTap.Edutech) ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_EDUTECH")}>에듀텍서비스</span>
                </li>
                <li className="nav_item" id="tool_info_contents">
                    <span className={((isInfoPage && hiclasstvInfo.currentInfoTap === enInfoTap.Contents) ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_CONTENTS")}>콘텐츠</span>
                </li>
                { // ** 임시 ** 현재 사용 안 하므로 주석 처리 by ykhan 20220421 
                    /* <li className="nav_item" id="tool_info_sheet">
                        <span className={((isInfoPage && hiclasstvInfo.currentInfoTap === enInfoTap.SmartSheet) ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_GREEN_SMART")}>스마트칠판</span>
                    </li> */
                }
                {
                    isLoginYn === "N" &&
                    <li className="nav_item" id="tool_info_download">
                        <span className={((isInfoPage && hiclasstvInfo.currentInfoTap === enInfoTap.Download) ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_DOWNLOAD")}>다운로드</span>
                    </li>
                }
                <li className="nav_item" id="tool_info_faq">
                    <span className={((isInfoPage && hiclasstvInfo.currentInfoTap === enInfoTap.FAQ) ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_QNA")}>FAQ</span>
                </li>
                <li className="nav_item">
                    <a href="https://www.youtube.com/channel/UCAMX1XVXrItw3mlbwFCC4TQ/featured" target="_blank" rel="noopener noreferrer">
                        <span className="white-text">동영상</span>
                    </a>
                </li>
                {
                    ConstData.IS_LOCAL_VERSION && isLoginYn === "N" ?
                        <li id="tool_link" className="nav_item flex-column" onClick={() => onClickMenu("link")}>
                            <span className="white-text">LINK</span>
                        </li> :
                        <li className="nav_item">
                            <a href="https://test.hiclass.tv/hiclass_ai_player/" target="_blank" rel="noopener noreferrer">
                                <span className="white-text">AI</span>
                            </a>
                        </li>
                }
                {
                    isLinkMenuOpened && 
                    <div className="d-flex menu-category">
                        <li className="nav_item flex-column" onClick={onClickGoToQRLoginPage}>
                            <FaQrcode className="font-md plus white-text" />
                            <span className="font-xxsm white-text">QR</span>
                        </li>
                        <li className="nav_item flex-column" onClick={() => onClickGoToSmartTVLoginPage("web-smarttv")}>
                            <FaTv className="font-md plus white-text" />
                            <span className="font-xxsm white-text">SmartTV</span>
                        </li>
                        <li className="nav_item flex-column" onClick={() => onClickGoToSmartTVLoginPage("web-group-smarttv")}>
                            <FaTv className="font-md plus white-text" />
                            <span className="font-xxsm white-text">GroupSmartTV</span>
                        </li>
                    </div>
                }
                { // ** 임시 ** 매뉴얼 작업할 동안 주석 처리 by hjkim 20230503
                    /* <li className="nav_item">
                        <span className={((isInfoPage && hiclasstvInfo.currentInfoTap === enInfoTap.SHARP) ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_SHARP")}>SHOP</span>
                    </li> */
                }
                { // ** 임시 ** 현재 사용 안 하므로 주석 처리 by ykhan 20201210 
                    /* isLoginYn === "N" &&
                    <li className="nav_item">
                        <span className={(hiclasstvInfo.currentInfoTap === enInfoTap.Event ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => tossAndHandleSelectedFunc("GOTO_PROMOTION")}>EVENT</span>
                    </li> */
                }
            </> :
            <>
                <MDBNavItem className="mx-2">
                    <button className={"hiclasstv-btn-transparent my-0" + ((isInfoPage && hiclasstvInfo.currentInfoTap === enInfoTap.Home) ? " hiclasstv-darkblue-text" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_HOME")}>
                        <b>홈</b>
                    </button>
                </MDBNavItem>
                <MDBNavItem className="mx-2">
                    <button className={"hiclasstv-btn-transparent my-0" + ((isInfoPage && hiclasstvInfo.currentInfoTap === enInfoTap.Edutech) ? " hiclasstv-darkblue-text" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_EDUTECH")}>
                        <b>에듀텍서비스</b>
                    </button>
                </MDBNavItem>
                <MDBNavItem className="mx-2">
                    <button className={"hiclasstv-btn-transparent my-0" + ((isInfoPage && hiclasstvInfo.currentInfoTap === enInfoTap.Contents) ? " hiclasstv-darkblue-text" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_CONTENTS")}>
                        <b>콘텐츠</b>
                    </button>
                </MDBNavItem>
                { // ** 임시 ** 현재 사용 안 하므로 주석 처리 by ykhan 20220421 
                    /* <MDBNavItem className="mx-2">
                        <button className={"hiclasstv-btn-transparent my-0" + ((isInfoPage && hiclasstvInfo.currentInfoTap === enInfoTap.SmartSheet) ? " hiclasstv-darkblue-text" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_GREEN_SMART")}>
                            <b>스마트칠판</b>
                        </button>
                    </MDBNavItem> */
                }
                {
                    isLoginYn === "N" &&
                    <MDBNavItem className="mx-2">
                        <button className={"hiclasstv-btn-transparent my-0" + ((isInfoPage && hiclasstvInfo.currentInfoTap === enInfoTap.Download) ? " hiclasstv-darkblue-text" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_DOWNLOAD")}>
                            <b>다운로드</b>
                        </button>
                    </MDBNavItem>
                }
                <MDBNavItem className="mx-2">
                    <button className={"hiclasstv-btn-transparent my-0" + ((isInfoPage && hiclasstvInfo.currentInfoTap === enInfoTap.FAQ) ? " hiclasstv-darkblue-text" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_QNA")}>
                        <b>FAQ</b>
                    </button>
                </MDBNavItem>
                <MDBNavItem className="mx-2">
                    <button className="hiclasstv-btn-transparent my-0" style={{ width: 'max-content' }}>
                        <a href="https://www.youtube.com/channel/UCAMX1XVXrItw3mlbwFCC4TQ/featured" target="_blank" rel="noopener noreferrer">
                            <span className="d-flex white-text align-items-center">
                                <b>동영상</b>
                            </span>
                        </a>
                    </button>
                </MDBNavItem>
                { // ** 임시 ** 현재 사용 안 하므로 주석 처리 by ykhan 20201210 
                    /* isLoginYn === "N" &&
                    <MDBNavItem className="mx-2">
                        <button className={"hiclasstv-btn-transparent" + (hiclasstvInfo.currentInfoTap === enInfoTap.Event ? " hiclasstv-darkblue-text" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("GOTO_PROMOTION")}>
                            <b>EVENT</b>
                        </button>
                    </MDBNavItem> */
                }
            </>
    );
}

export default WebHeaderInfoMenu;