import React from "react";

import ConstData from "../../../lib/ConstData";

const WebUserStatusButton = ({ subDomainSeq, member_info, toggleModalOpened }) => {
    return (
        <div className="list-row align-items-center justify-content-center area-50">
            <span className={(member_info.webUserStatus === ConstData.LOGIN_STATUS.LOG_IN ? "login" : member_info.webUserStatus === ConstData.LOGIN_STATUS.LOG_OUT ? "logout" : "studying")}
                title={(member_info.webUserStatus !== ConstData.LOGIN_STATUS.LOG_OUT ? "로그아웃" : "")} onClick={() => toggleModalOpened(true, "web", subDomainSeq, member_info, member_info.webUserStatus)}
            >
                WEB
            </span>
            <span className="mx-1"> | </span>
            <span className={(member_info.userStatus === ConstData.LOGIN_STATUS.LOG_IN ? "login" : member_info.userStatus === ConstData.LOGIN_STATUS.LOG_OUT ? "logout" : "studying")}
                title={(member_info.userStatus !== ConstData.LOGIN_STATUS.LOG_OUT ? "로그아웃" : "")} onClick={() => toggleModalOpened(true, "hiclass", subDomainSeq, member_info, member_info.userStatus)}
            >
                HICLASS
            </span>
        </div>
    );
}

export default WebUserStatusButton;