import React, { useEffect, useState } from 'react';
import { FaServer, FaPlusCircle } from 'react-icons/fa';
import { UncontrolledCollapse } from 'reactstrap';
import WebAddLecture from './WebAddLecture';
import WebLectureList from './list/WebLectureList';
import WebConfigSearchBoxExceptUser from './menu/WebConfigSearchBoxExceptUser';
import { enAdminTap } from '../../lib/ConstCommand';

const WebLectureConfig = ({ selectedSubDomainInfo, list_configSubDomain, list_lecture, list_lectureKind, list_lectureType, handleSelectedFunc }) => {
    const [isAddLectureOK, setIsAddLectureOK] = useState(false);

    useEffect(() => {
        
    });

    const onChangeIsAddLectureOK = () => {
        setIsAddLectureOK(!isAddLectureOK);
    }

    return (
        <div className="web-main list-area" style={{ maxWidth: '900px' }}>
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaServer className="font-lg mr-2" aria-hidden="true" />내 강의
                        </span>
                        <button type="button" className="d-flex btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" id="addLecture">
                            <FaPlusCircle className="font-md mr-1" />강의 생성
                        </button>
                    </div>
                </div>
                <WebConfigSearchBoxExceptUser
                    adminTap={enAdminTap.Lecture}
                    selectedSubDomainInfo={selectedSubDomainInfo}
                    list_configSubDomain={list_configSubDomain}
                    handleSearchBoxFunc={handleSelectedFunc}
                />
                <UncontrolledCollapse toggler="#addLecture" defaultOpen={false}>
                    <WebAddLecture
                        selectedSubDomainInfo={selectedSubDomainInfo}
                        isAddLectureOK={isAddLectureOK}
                        list_lectureKind={list_lectureKind}
                        list_lectureType={list_lectureType}
                        onChangeIsAddLectureOK={onChangeIsAddLectureOK}
                        handleSelectedFunc={handleSelectedFunc}
                    />
                </UncontrolledCollapse>
                <WebLectureList
                    list_lecture={list_lecture}
                    handleSelectedFunc={handleSelectedFunc}
                />
            </div>
        </div>
    );
}
export default WebLectureConfig;