import React, { useState, useEffect } from 'react';

const LinkItem = ({ info }) => {
    const [urlData, setUrlData] = useState(null);
    const [ogTitle, setOGTitle] = useState("");
    const [ogImage, setOGImage] = useState("");
    const [ogDescription, setOGDescription] = useState("");

    useEffect(() => {
        if (info && info.length > 0) {
            info.forEach(item => {
                if (item.kind === 'url') {
                    setUrlData(item.data);
                } else if (item.kind === 'meta') {
                    item.data.forEach(meta => {

                        switch (meta.name) {
                            case "og:title":
                                setOGTitle(meta.data);
                                break;

                            case "og:image":
                                setOGImage(meta.data);
                                break;

                            case "og:description":
                                setOGDescription(meta.data);
                                break;

                            default:
                                break;
                        }
                    });
                }
            });
        }
    }, [info]);

    return (
        urlData !== undefined && urlData !== null ?
            <div>
                <div className="link-item" href={urlData} target="_blank" type="item">
                    {
                        ogImage &&
                        <div className="og-img">
                            <img className="w-100" src={ogImage} alt="" />
                        </div>
                    } {
                        (ogTitle || ogDescription) &&
                        <div className="og-text">
                            {
                                ogTitle &&
                                <span className="og-title" title={ogTitle}>
                                    {ogTitle}
                                </span>
                            } {
                                ogDescription &&
                                <span className="og-desc" title={ogDescription}>
                                    {ogDescription}
                                </span>
                            }
                        </div>
                    }
                </div>
                <span className="text-item" type="item">
                    <a href={urlData} target="_blank" rel="noopener noreferrer"><u>{urlData}</u></a>
                </span>
            </div> :
            <></>
    );
}

export default LinkItem;