import { enTabPacketType } from './CodeEnum';
import { decodeForWeb, encodeForWeb } from './Token';

const encodePacket = (data, dataType, name) => {
    let rtnValue = {};
    let idx = 0;

    let encoder = new TextEncoder();
    let nameData = encoder.encode(name);

    let int64Buff = new ArrayBuffer(8);
    let int64Int8View = new Uint8Array(int64Buff);
    let int64Int32View = new Int32Array(int64Buff);
    let pktLength = 4 + 1 + 1 + nameData.length + 4 + data.length;

    int64Int32View[0] = pktLength;
    int64Int32View[1] = data.length;

    let arrayBuffer = new ArrayBuffer(pktLength);
    let int8View = new Uint8Array(arrayBuffer);

    for (let i = 0; i < 4; i++) {
        int8View[idx++] = int64Int8View[i];
    }

    int8View[idx++] = dataType;
    int8View[idx++] = nameData.length;

    for (let i = 0; i < nameData.length; i++) {
        int8View[idx++] = nameData[i];
    }

    for (let i = 0; i < 4; i++) {
        int8View[idx++] = int64Int8View[4 + i];
    }

    for (let i = 0; i < data.length; i++) {
        int8View[idx++] = data[i];
    }

    rtnValue.forHiclass = Array.from(int8View);
    rtnValue.forWeb = arrayBuffer;

    return rtnValue;
};

const encodeBinary = (data, name) => {
    return encodePacket(data, enTabPacketType.Binary, name);
};

export const encodeText = (data, name) => {
    return this.encodePacket(data, enTabPacketType.Text, name);
};

export const decodingPacket = (rawBuffer) => {
    let idx = 0;

    let int8View = new Uint8Array(rawBuffer);

    let int32Buff = new ArrayBuffer(4);
    let int32Int8View = new Uint8Array(int32Buff);
    let int32View = new Int32Array(int32Buff);

    int32Int8View[0] = int8View[idx++];                         // [0]
    int32Int8View[1] = int8View[idx++];                         // [1]
    int32Int8View[2] = int8View[idx++];                         // [2]
    int32Int8View[3] = int8View[idx++];                         // [3]
    let pktLength = int32View[0];
    let pktType = int8View[idx++];                              // [4]

    let nameLength = int8View[idx++];                           // [5]
    let nameBuffer = rawBuffer.slice(idx, idx + nameLength);    // [6]
    let decoder = new TextDecoder('utf-8');
    let packName = decoder.decode(nameBuffer);
    idx = idx + nameLength;

    int32Int8View[0] = int8View[idx++];                         // [6 + nameLength]
    int32Int8View[1] = int8View[idx++];                         // [7 + nameLength]
    int32Int8View[2] = int8View[idx++];                         // [8 + nameLength]
    int32Int8View[3] = int8View[idx++];                         // [9 + nameLength]
    let dataLength = int32View[0];

    let dataBuffer = rawBuffer.slice(idx);                      // [10 + nameLength]
    let dataObject = decodeForWeb(dataBuffer);

    return Object.assign({ name: packName }, dataObject);
};

export const getSendToMQBytes = (sendObject, senderName) => {
    const sendData = encodeForWeb(sendObject);
    const sendDataBin = encodeBinary(sendData, senderName);

    return sendDataBin;
};