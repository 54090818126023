import React, { useState, useRef, useHistory } from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import { FaCog } from 'react-icons/fa';

import WebUserStatus from '../../etc/list/WebUserStatus';
import WebConfigMemberList from './WebConfigMemberList';

const WebConfigSubDomainInfo = ({ idx, userKind, isStandardType, subDomain_info, goToConfigPage, goToConfigMemberPage, goToPassListPage, toggleModalOpened, togglePWModalOpened, toggleSubDomainAdminModalOpened, toggleCreateSubDomainAdminModalOpened }) => {

    const [isOpened, setIsOpened] = useState(false);

    const enterTimeRef = useRef(0);
    const exitTimeRef = useRef(0);
    
    const onEnteringEvent = () => {
        enterTimeRef.current = Date.now();
        console.log('onEnteringEvent - ', enterTimeRef.current);
        //setIsOpened(true);
    }

    const onEnteredEvent = () => {
        let enteredTime = Date.now() - enterTimeRef.current;
        console.log('onEnteredEvent - ', enteredTime);
        setIsOpened(true);
    }

    const onExitingEvent = () => {
        exitTimeRef.current = Date.now();
        console.log('onExitingEvent - ', exitTimeRef.current);
        setIsOpened(false);
    }

    const onExitedEvent = ()=> {
        let exitedTime = Date.now() - exitTimeRef.current;
        console.log('onExitedEvent - ', exitedTime);
        setIsOpened(false);
    }

    const goToMemberConfigPage = () => {
        
    }

    return (
        <tbody>
            <tr className="d-flex">
                <td className="d-flex align-items-center justify-content-center area-35">
                    <div className="text-area" style={{ textAlign: 'center' }}>
                        <span className="info-title hiclass-text" title={subDomain_info.subDomainName}>{subDomain_info.subDomainName}</span>
                        <FaCog className="font-sm grey-text ml-1 button" style={{verticalAlign:'text-top'}} onClick={() => goToConfigPage({ subDomain_info: { subDomainSeq: subDomain_info.subDomainSeq, subDomainName: subDomain_info.subDomainName, selectedIdx: idx }})} />
                    </div>
                </td>
                <td className="landscape-to-portrait align-items-center justify-content-center small area-25">
                    <div className="list-row justify-content-center area-60">
                        <WebUserStatus kind={'userStatus'} list_member={subDomain_info.list_member} />
                    </div>
                    <div className="list-row justify-content-center area-40">
                        {/* <button type="button" className="btn btn-sm btn-outline-primary px-2 py-1 m-0" id={"show_" + subDomain_info.subDomainSeq + "_member"}>
                            조회
                        </button> */}
                        <button type="button" className="btn btn-sm btn-outline-primary px-2 py-1 m-0" onClick={() => goToConfigMemberPage({ subDomain_info: { ...subDomain_info, selectedIdx: idx }})}>
                            조회
                        </button>
                    </div>
                </td>
                <td className="landscape-to-portrait small area-25">
                    <div className="list-row area-50">
                        <span className="only-mobile">T :&nbsp;</span>{subDomain_info.chatRoomCnt}
                    </div>
                    <div className="list-row area-50">
                        <span className="only-mobile">L :&nbsp;</span>
                        {
                            subDomain_info.liveOnCnt > 0 ?
                            <span className="green-text font-weight-bold">{subDomain_info.liveOnCnt}</span> :
                            <span>{subDomain_info.liveOnCnt}</span>
                        }
                        <span>&nbsp;{' / ' + subDomain_info.liveCnt}</span>
                    </div>
                </td>
                <td className="d-flex align-items-center justify-content-center area-15">
                    {
                        isStandardType &&
                        <button type="button" className="btn btn-sm btn-outline-primary px-2 py-1 m-0" onClick={() => goToPassListPage({ subDomainSeq: subDomain_info.subDomainSeq })}>
                            조회
                        </button>
                    }
                </td>
            </tr>
            {/* <tr className="d-flex">
                <td className="d-flex w-100 p-0">
                    <UncontrolledCollapse className="w-100" toggler={"#show_" + subDomain_info.subDomainSeq + "_member"} defaultOpen={false} onEntered={onEnteredEvent} onEntering={onEnteringEvent} onExiting={onExitingEvent} onExited={onExitedEvent}>
                        <WebConfigMemberList 
                            userKind={userKind}
                            isOpened={isOpened}
                            subDomain_info={subDomain_info}
                            list_member={subDomain_info.list_member}
                            toggleModalOpened={toggleModalOpened}
                            togglePWModalOpened={togglePWModalOpened}
                            toggleSubDomainAdminModalOpened={toggleSubDomainAdminModalOpened}
                            toggleCreateSubDomainAdminModalOpened={toggleCreateSubDomainAdminModalOpened}
                        />
                    </UncontrolledCollapse>
                </td>
            </tr> */}
        </tbody>
    );
}

export default WebConfigSubDomainInfo;