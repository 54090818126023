import React from 'react';

const WebLiveJoinButton = ({ handleSelectedFunc }) => {
    return (
        <button type="button" className="btn btn-sm hiclasstv-yellow white-text m-0 py-1 px-2" title="입장하기" onClick={handleSelectedFunc}>
            <b>입장</b>
        </button>
    )
}

export default WebLiveJoinButton;