import React, { useState, useEffect, useRef, useCallback } from "react";
import { FaRegDotCircle, FaRegStopCircle } from "react-icons/fa";

import MicRecorder from "mic-recorder-to-mp3";
import { callBuildPermissionsQuery } from "../../../lib/func/ExportFunction";

const AudioRecord = ({ onHandleClassTalkFunc, handleSelectedClassTalkFunc }) => {

    const interval = useRef(null);
    const mp3Recorder = useRef(new MicRecorder({ bitRate: 128 }));

    const [isTryToGetPermission, setIsTryToGetPermission] = useState(false);
    const [isRecording, setRecording] = useState(false);
    const [isBlocked, setBlocked] = useState(true);
    const [curTime, setCurTime] = useState(0);
    const [startTime, setStartTime] = useState(0);

    const getPermissionHandler = useCallback(async () => {
        if (isTryToGetPermission) {
            let constraints = { audio: true };  // use default audio input

            if (navigator.permissions === undefined || navigator.permissions === null) {
                callBuildPermissionsQuery();
            }

            if (navigator.mediaDevices === undefined || navigator.mediaDevices === null) {
                navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
                navigator.getUserMedia({ audio: true },
                    () => {
                        setBlocked(false);
                    },
                    (err) => {
                        console.log("AudioRecord(1) - err => ", err);
                        setBlocked(true);
                        alert("마이크 사용 권한이 없어 음성 메모 기능을 사용할 수 없습니다.");
                        handleSelectedClassTalkFunc({ selectedFunc: "CLOSE_VOICE_MEMO" });
                    });
            } else {
                let audioStream = null;
                let errMsg = "사용가능한 마이크가 없거나 마이크 기능을 제공하지 않는 브라우져입니다.";
                let isGranted = false;

                try {
                    const result = await navigator.permissions.query({ name: "microphone" });
                    if (result.state === "granted") { // 매번 권한 요청하는 대신 기존 권한 허용 여부 먼저 체크로 수정 by hjkim 20220208
                        isGranted = true;
                        setBlocked(false);
                    } else {
                        audioStream = await navigator.mediaDevices.getUserMedia(constraints);
                    }
                } catch (err) {
                    console.log("AudioRecord(2) - err => ", err);
                    if (err instanceof DOMException) {
                        console.log(`DOMException name[${err.name}], message: ${err.message}`);
                        if (err.name === "NotAllowedError") {
                            errMsg = "마이크 사용 권한이 없어 음성 메모 기능을 사용할 수 없습니다.";
                        } else if (err.name === "NotReadableError") {
                            errMsg = "다른 프로그램에서 해당 장치를 사용하고 있어 음성 메모 기능을 사용할 수 없습니다.";
                        } else if (err.name === "AbortError") {
                            errMsg = "해당 장치를 사용할 수 없는 문제가 발생하여 음성 메모 기능을 사용할 수 없습니다.";
                        } else if (err.name === "NotFoundError" || err.name === "OverconstrainedError") {
                            errMsg = "사용 가능한 마이크 장치가 없어 음성 메모 기능을 사용할 수 없습니다.";
                        } else if (err.name === "SecurityError") {
                            errMsg = "마이크 기능을 제공하지 않는 브라우져입니다.";
                        }
                    } else if (err instanceof TypeError) {
                        console.log(`TypeError name[${err.name}], message: ${err.message}`);
                        errMsg = "마이크 기능을 제공하지 않는 브라우져입니다.";
                    } else {
                        console.log(`Error name[${err.name}], message: ${err.message}`);
                    }
                } finally {
                    if (audioStream !== null || isGranted) {
                        setBlocked(false);
                    } else {
                        setBlocked(true);
                        alert(errMsg);
                        handleSelectedClassTalkFunc({ selectedFunc: "CLOSE_VOICE_MEMO" });
                    }
                }

                /* let audioStream = null;
                let errMsg = "사용가능한 마이크가 없거나 마이크 기능을 제공하지 않는 브라우져입니다.";

                try {
                    const result = await navigator.permissions.query({ name: "microphone" });
                    if (result.state === "granted") { // 매번 권한 요청하는 대신 기존 권한 허용 여부 먼저 체크로 수정 by hjkim 20220208
                        setBlocked(false);
                    } else {
                        try {
                            audioStream = await navigator.mediaDevices.getUserMedia(constraints);
                        } catch (err) {
                            console.log("AudioRecord(2) - err => ", err);
                            if (err instanceof DOMException) {
                                console.log(`DOMException name[${err.name}], message: ${err.message}`);
                                if (err.name === "NotAllowedError") {
                                    errMsg = "마이크 사용 권한이 없어 음성 메모 기능을 사용할 수 없습니다.";
                                } else if (err.name === "NotReadableError") {
                                    errMsg = "다른 프로그램에서 해당 장치를 사용하고 있어 음성 메모 기능을 사용할 수 없습니다.";
                                } else if (err.name === "AbortError") {
                                    errMsg = "해당 장치를 사용할 수 없는 문제가 발생하여 음성 메모 기능을 사용할 수 없습니다.";
                                } else if (err.name === "NotFoundError" || err.name === "OverconstrainedError") {
                                    errMsg = "사용 가능한 마이크 장치가 없어 음성 메모 기능을 사용할 수 없습니다.";
                                } else if (err.name === "SecurityError") {
                                    errMsg = "마이크 기능을 제공하지 않는 브라우져입니다.";
                                }
                            } else if (err instanceof TypeError) {
                                console.log(`TypeError name[${err.name}], message: ${err.message}`);
                                errMsg = "마이크 기능을 제공하지 않는 브라우져입니다.";
                            }
                        } finally {
                            if (audioStream !== null) {
                                setBlocked(false);
                            } else {
                                setBlocked(true);
                                alert(errMsg);
                                handleSelectedClassTalkFunc({ selectedFunc: "CLOSE_VOICE_MEMO" });
                            }
                        }
                    }
                } catch (err) {
                    console.log("AudioRecord(3) - err => ", err);
                    if (err instanceof DOMException) {
                        console.log(`DOMException name[${err.name}], message: ${err.message}`);
                        if (err.name === "NotAllowedError") {
                            errMsg = "마이크 사용 권한이 없어 음성 메모 기능을 사용할 수 없습니다.";
                        } else if (err.name === "NotReadableError") {
                            errMsg = "다른 프로그램에서 해당 장치를 사용하고 있어 음성 메모 기능을 사용할 수 없습니다.";
                        } else if (err.name === "AbortError") {
                            errMsg = "해당 장치를 사용할 수 없는 문제가 발생하여 음성 메모 기능을 사용할 수 없습니다.";
                        } else if (err.name === "NotFoundError" || err.name === "OverconstrainedError") {
                            errMsg = "사용 가능한 마이크 장치가 없어 음성 메모 기능을 사용할 수 없습니다.";
                        } else if (err.name === "SecurityError") {
                            errMsg = "마이크 기능을 제공하지 않는 브라우져입니다.";
                        }
                    } else if (err instanceof TypeError) {
                        console.log(`TypeError name[${err.name}], message: ${err.message}`);
                        errMsg = "마이크 기능을 제공하지 않는 브라우져입니다.";
                    }
                    setBlocked(true);
                    alert(errMsg);
                    handleSelectedClassTalkFunc({ selectedFunc: "CLOSE_VOICE_MEMO" });
                } */
            }
        }
    }, [isTryToGetPermission]);

    useEffect(() => {
        return () => {
            if (mp3Recorder && mp3Recorder.current) {
                mp3Recorder.current.stop();
                mp3Recorder.current = null;
            }

            if (interval && interval.current) {
                clearInterval(interval.current);
                interval.current = null;
            }
        };
    }, []);

    useEffect(() => {
        getPermissionHandler();
    }, [getPermissionHandler]);

    useEffect(() => {
        if (isTryToGetPermission) {
            if (isBlocked) {
                console.log("AudioRecord - Permission Denied");
            } else {
                if (mp3Recorder && mp3Recorder.current) {
                    mp3Recorder.current.start()
                    .then(() => {
                        const v = new Date().getTime();
                        setStartTime(v);
                        setCurTime(v);
                        setRecording(true);
                    })
                    .catch((err) => {
                        console.log("mp3Recorder.start() - err => ", err);
                        if (err instanceof Error) {
                            console.log(`Error name[${err.name}], message: ${err.message}`);
                            let errMsg = `오류가 발생하여 음성 메모 기능을 사용할 수 없습니다. [Error name[${err.name}], message: ${err.message}]`;
                            alert(errMsg);
                        }
                        handleSelectedClassTalkFunc({ selectedFunc: "CLOSE_VOICE_MEMO" });
                    });
                }
            }
        }
    }, [isBlocked, isTryToGetPermission]);

    useEffect(() => {
        if (isRecording) {
            interval.current = setInterval(() => {
                handleTimer();
            }, 1000);
        } else {
            if (interval && interval.current) {
                clearInterval(interval.current);
                interval.current = null;
            }
        }
    }, [isRecording]);

    const handleTimer = () => {
        const v = new Date().getTime();
        setCurTime(v);
    }

    const handleStart = (e) => {
        if (!isTryToGetPermission) {
            setIsTryToGetPermission(true);
        } else {
            console.log("already recording now...");
        }
    }

    const handleStop = (e) => {
        if (isRecording) {
            if (mp3Recorder && mp3Recorder.current) {
                mp3Recorder.current.stop().getMp3()
                .then(([buffer, blob]) => {
                    const filename = "au" + Date.now().toString() + ".mp3";
                    let reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = () => {
                        onHandleClassTalkFunc({ selectedFunc: "SEND_VOICE_MEMO", data: blob, fileURL: reader.result, filename });
                    };
                })
                .catch((err) => {
                    console.log("mp3Recorder.stop() - err => ", err);
                    if (err instanceof Error) {
                        console.log(`Error name[${err.name}], message: ${err.message}`);
                        let errMsg = `오류가 발생하여 음성 메모를 전송하지 못했습니다. [Error name[${err.name}], message: ${err.message}]`;
                        alert(errMsg);
                    }
                    handleSelectedClassTalkFunc({ selectedFunc: "CLOSE_VOICE_MEMO" });
                });

                setRecording(false);
                setIsTryToGetPermission(false);
            }
        } else {
            handleSelectedClassTalkFunc({ selectedFunc: "CLOSE_VOICE_MEMO" });
        }
    }

    const getDisp = () => {
        const tmp = curTime - startTime;
        const t = Math.floor(tmp / 1000);
        const m = Math.floor(t / 60);

        const ss = t % 60;
        const mm = m % 60;
        const hh = Math.floor(mm / 60);

        const str_s = "0" + parseInt(ss).toString();
        const str_m = "0" + parseInt(mm).toString();
        const str_h = "0" + parseInt(hh).toString();

        const str_time = str_h.substr(str_h.length - 2, 2) + ":" + str_m.substr(str_m.length - 2, 2) + ":" + str_s.substr(str_s.length - 2, 2);
        //console.log(str_time);

        return str_time;
    }

    return (
        <div className="title-area sub hiclasstv-blue">
            {
                isRecording &&
                <div className="d-flex justify-content-start align-items-center mr-1">
                    <div className="spinner-border spinner-border-sm red-text" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <span className="white-text small ml-1" >&nbsp;Recording...</span>
                </div>
            }
            <div className="d-flex justify-content-center align-itmes-center px-1">
                <button className="d-flex rounded p-1" title="녹음 시작" onClick={() => handleStart()}>
                    <FaRegDotCircle className="d-flex font-md red-text " />
                </button>
                <button className="d-flex rounded p-1" title="녹음 종료" onClick={() => handleStop()}>
                    <FaRegStopCircle className="d-flex font-md white-text" />
                </button>
                <div className="d-flex align-items-center">
                    <span className="hiclass-text hiclasstv-yellow-text px-1">
                        {getDisp()}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default AudioRecord;