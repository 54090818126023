import React, { Component } from 'react';
import { connect } from 'react-redux';

import ConstData from '../../../lib/ConstData';

import { enTokenCMD } from '../../../lib/classTalk/CodeEnum';
import { ctWebActionType } from '../../../lib/ConstCommand';
import { getMySmallGroupSendKey } from '../../../lib/RabbitmqClient';

import PerformWatchingLiveInfo from '../../../components/live/PerformWatchingLiveInfo';
import CenteredModalInLive from '../../../components/template/modal/CenteredModalInLive';
import RightSideModalInLive from '../../../components/template/modal/RightSideModalInLive';
import ClassTalkLive from '../../../components/live/ClassTalkLive';
import InkMemoWindow from '../../../components/classtalk/inkMemo/InkMemoWindow';
import WatchingLiveMember from '../../../components/live/modal/WatchingLiveMember';
import WebWhisperModal from '../../../components/whisper/WebWhisperModal';

import { sendQuizAnswer } from '../../../modules/quiz';

import { unselectLecture, clearPerformLectureInfo } from '../../../modules/lecture';

import { watsonCreateSession,
         watsonSendMessage, 
         watsonRemoveSession,
         watsonToggle,
         exitWatsonMode,
         falseToWatsonError } from '../../../modules/watson';

import { toggle_classTalk,
         appendSendMessage, 
         appendSendAudioMemo,
         appendSendInkMemo,
         appendSendFileShare, 
         emoticonSelectedToggle, 
         emoticonSelectedCancel, 
         addFunctionOpenedToggle, 
         selectEmoticonSeq, 
         selectFunctionSeq, 
         inkMemoOpenedToggle, 
         inkMemoClosedToggle,
         inkMemo_changeTool,
         voiceMemoClosedToggle,
         toggleFileShareMessage,
         openWhisperModal,
         respondWhisper,
         closeWhisperModal,
         webIsWhisperMode,
         setPassPossible } from '../../../modules/classTalkLive';

import { closeWatchLiveModal,
         changeLiveConnect, 
         changeLiveConnectStatus, 
         changeLiveSoundStatus, 
         changeLiveStatusInfo } from '../../../modules/live';

class ClassTalkContainer extends Component {

    componentDidMount() {
        const { mySubDomainSeq, isJoinLive, isLectureLive, lecture_info } = this.props;
        if (!isJoinLive && isLectureLive) {
            if (lecture_info.lectureKind === 'watson' || lecture_info.lectureKind === 'dialog') {
                this.props.watsonCreateSession({ 
                    mySubDomainSeq, 
                    lectureCode: lecture_info.lectureCode, 
                    kind: 'select-lecture' 
                });
                //this.props.toggle_classTalk({ visibility: 'visible' });
            } else {
                //this.props.toggle_classTalk({ visibility: 'visible' });
            }
        }
    }

    componentWillUnmount() {
        this.props.exitWatsonMode();
    }

    handleSelectedClassTalkFunc = ({ selectedFunc, emoticonSeq, func, msgText, data, dataUrl, filename, fileURL, idx, status, tool }) => {
        const { userSeq, userNickname, mySubDomainSeq, profileImgUrl, dialogHist } = this.props;

        switch(selectedFunc) {
            // --------------------------- Watson --------------------------- //
            case "SEND_MSG_WATSON" :
                //console.log(msgText);
                this.props.watsonSendMessage({ 
                    mySubDomainSeq,
                    userSeq,
                    userNickname,
                    dialogHist,
                    lectureCode : this.props.watsonInfo.modelCode, 
                    sessionCode : this.props.watsonInfo.sessionCode,
                    sendMessage : msgText, 
                    lectures    : this.props.list_lecture,
                    context     : this.props.watsonInfo.context
                });
                break;

            case "SEND_QUIZ_ANSWER" :
                //console.log('SEND_QUIZ_ANSWER - ', data);
                this.props.sendQuizAnswer({ userNickname, data, userSeq }); //profileImgUrl 포함
                break;

            case "SET_PASS_POSSIBLE" : 
            this.props.setPassPossible({ data });
            break;

            case "GET_NEXT_CARD" :
                this.props.watsonSendMessage({ 
                    mySubDomainSeq,
                    userSeq,
                    userNickname, 
                    dialogHist,
                    lectureCode : this.props.watsonInfo.modelCode, 
                    sessionCode : this.props.watsonInfo.sessionCode,
                    sendMessage : this.props.watsonInfo.language === 'ko-KR' ? '다음' : 'next', 
                    lectures    : this.props.list_lecture,
                    context     : this.props.watsonInfo.context
                });
                break;
                
            case "WATSON_TOGGLE" :
                this.props.watsonToggle({ mode: "web" });
                break;

            case "WATSON_IMG" : 
                if (fileURL !== undefined && fileURL !== null) {
                    window.open(fileURL, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,width=800,height=600");
                }
                break;

            case "EXIT_WATSON" : 
                this.props.unselectLecture();
                break;

            case "FALSE_TO_WATSON_ERROR":
                this.props.falseToWatsonError();
                break;
                
            // --------------------------- Emoticon --------------------------- //
            case "EMOTICON_TOGGLE" :
                this.props.emoticonSelectedToggle();
                break;

            case "EMOTICON_SELECT" :
                this.props.selectEmoticonSeq(emoticonSeq);
                break;

            case "EMOTICON_CANCLE" :
                this.props.emoticonSelectedCancel();
                break;
                
            // --------------------------- Send Message --------------------------- //
            case "SEND_MSG" :
                this.props.appendSendMessage({ msgText, userNickname, profileImgUrl });
                break;

            case "SEND_VOICE_MEMO" :
                //this.props.appendSendAudioMemo({ mode:"web", liveSeq:performLiveInfo.liveSeq, data, teacherSeq : performLiveInfo.teacher_info.userSeq, filename, userSeq, userNickname, command:enTokenCMD.chat_Voice });
                this.props.appendSendAudioMemo({ selectedFunc, data, url: fileURL, userNickname, userSeq });
                break;

            // --------------------------- Classtalk func --------------------------- //
            case "FUNC_TOGGLE" :
                this.props.addFunctionOpenedToggle();
                break;

            case "FUNC_SELECT" :
                this.props.selectFunctionSeq(func);

                switch(func) {
                    case ctWebActionType.Whisper:
                        this.props.webIsWhisperMode({ web_isWhisper: !this.props.web_isWhisper });
                        break;

                    case ctWebActionType.InkMemo :
                        this.props.inkMemoOpenedToggle();
                        break;

                    case ctWebActionType.VoiceMemo :
                        break;

                    case ctWebActionType.FileShare :
                        this.props.appendSendFileShare({ 
                            userSeq, 
                            userNickname, 
                            data, 
                            mode: "web", 
                            filename, 
                            liveSeq: this.props.performLiveInfo.liveSeq, 
                            teacherSeq: this.props.performLiveInfo.teacher_info.userSeq, 
                            command: enTokenCMD.ct_UploadAndShareFiles, 
                            isWhisper: this.props.isWhisperModalOpened || this.props.web_isWhisper,
                            smallGroupKey: getMySmallGroupSendKey()
                        });
                        break;

                    case ctWebActionType.WatsonS_KR :
                        if (!this.props.isRunningWatson) {
                            //unselectLecture();
                            this.props.watsonCreateSession({ 
                                mySubDomainSeq, 
                                lectureCode: ConstData.WATSON_HICLASS_MODEL_CODE.KR, 
                                kind: 'standard' 
                            });
                        } else {
                            let hiclassMode = "";
                            let hiclassSession = "";

                            if (this.props.isLectureMode) {
                                hiclassMode = this.props.savedHiclassWatsonInfo.modelCode;
                                hiclassSession = this.props.savedHiclassWatsonInfo.sessionCode;
                            }
                            this.props.watsonRemoveSession({ 
                                mySubDomainSeq, 
                                hiclassMode, 
                                hiclassSession, 
                                lectureCode: this.props.watsonInfo.modelCode, 
                                sessionCode: this.props.watsonInfo.sessionCode
                            });
                            this.props.clearPerformLectureInfo();
                        }
                        break;

                    default:
                        break;
                }
                break;

            case "CLOSE_VOICE_MEMO" :
                this.props.voiceMemoClosedToggle();
                break;            

            case "INK_MEMO" :
                if (!this.props.isLiveChattingLocked) this.props.inkMemoOpenedToggle({ selectedFunc, fileURL });
                break;

            case "FILE_DOWNLOAD" :
                console.log('FILE_DOWNLOAD -- ', fileURL, idx);
                this.handleFileDownload(fileURL, idx);
                break;

            case "FILE_OPEN" :
                console.log('FILE_OPEN -- ', fileURL);
                if (fileURL !== undefined && fileURL !== null) {
                    window.open(fileURL);
                }
                break;

            case "PAGE_JPEG" : 
                break;
            
            case "INK_MEMO_TOOL" :
                this.props.inkMemo_changeTool(tool);
                break;

            case "SEND_INK_MEMO" :
                this.props.appendSendInkMemo({ data, url: dataUrl, userSeq, userNickname });
                break;

            case "CLOSE_INK_MEMO" :
                this.props.inkMemoClosedToggle();
                break;

            case "OPEN_WHISPER" :
                this.props.openWhisperModal({ data, idx });
                break;

            case "RESPOND_WHISPER" :
                this.props.respondWhisper({ msgText, userNickname, profileImgUrl });
                break;

            case "CLOSE_WHISPER_MODAL" :
                this.props.closeWhisperModal();
                break;
                
            case "LIVE_CON" :
                this.props.changeLiveConnect({ isConnect: status });
                break;

            case "LIVE_CON_STATUS" :
                this.props.changeLiveConnectStatus({ status });
                break;
                
            case "LIVE_SOUND_STATUS" :
                this.props.changeLiveSoundStatus({ status });
                break;

            case "LIVE_RECON" :
                this.props.changeLiveStatusInfo({ kind: 'video' });
                break;

            case "NONE" : 
                this.props.closeWatchLiveModal();
                break;
            
            default:
                this.props.handleSelectedLiveFunc({ selectedFunc });
                break;
        }
    }

    handleFileDownload = async (url, idx) => {
        // 기존 코드---------여기부터
        /* const filename = url.substr(url.lastIndexOf('/') + 1);
        let link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();

        this.props.toggleFileShareMessage({ idx: idx, isClickedBtn: true }); */
        // 기존 코드---------여기까지 ... by hjkim 20221021
        try {
            console.log('handleFileDownload - file download function start');
            const requestUrl = new URL(url, ConstData.MAIN_HOST_ADD);
            console.log('handleFileDownload - requestUrl => ', requestUrl);
            const response = await fetch(requestUrl, { method: 'GET' });
            console.log('handleFileDownload - fetch get success');
            const blob = await response.blob();
            console.log('handleFileDownload - response.blob success');
            const objUrl = window.URL.createObjectURL(blob);
            console.log('handleFileDownload - URL.createObjectURL(blob) success');

            const filename = url.substr(url.lastIndexOf('/') + 1);
            const link = document.createElement('a');
            document.body.appendChild(link);        // createObjectURL로 생성한 url은 document 내에서만 유효하므로... by hjkim 20221021
            link.download = filename;               // download 속성을 줘야 a tag 클릭 시 다운로드 이벤트가 발생... by hjkim 20221021
            link.href = objUrl;
            console.log('handleFileDownload - file download try link click');
            link.click();
            console.log('handleFileDownload - file download started');

            document.body.removeChild(link);        // clean up - 쓰임을 다한 a tag document 에서 삭제... by hjkim 20221021
            window.URL.revokeObjectURL(objUrl);     // clean up - 쓰임을 다한 objUrl 객체 삭제... by hjkim 20221021
            console.log('handleFileDownload - removeChild(link), URL.revokeObjectURL(objUrl)');

            this.props.toggleFileShareMessage({ idx: idx, isClickedBtn: true });
            console.log('handleFileDownload - file download function finish');
        } catch (err) {
            console.log('handleFileDownload() - err => ', err);
        }
    }

    render() {
        const { userSeq } = this.props;
        const { performLiveInfo, performLiveStatusInfo, performHandsUpStatusInfo } = this.props;
        const { classTalkVisibility } = this.props;
        const { handleSelectedClassTalkFunc } = this;
        
        return (
            <div id="classtalk" className={(performLiveStatusInfo.isConnect ? " connect" : "")} style={{ visibility: `${classTalkVisibility}` }}>
                {
                    this.props.isLiveMemberModalOpened &&
                    <RightSideModalInLive 
                        modalClose={handleSelectedClassTalkFunc} 
                        style={{ margin: '5px', visibility: 'inherit' }}
                    >
                        <WatchingLiveMember
                            style={{ visibility: 'inherit' }}
                            userSeq={userSeq}
                            performLiveInfo={performLiveInfo}
                            handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                        />
                    </RightSideModalInLive>
                } {
                    this.props.isWhisperModalOpened &&
                    <CenteredModalInLive
                        modalClose={handleSelectedClassTalkFunc}
                        style={{ margin: '5px', visibility: `${classTalkVisibility}`}}
                    >
                        <WebWhisperModal
                            openInfo={this.props.openInfo}
                            msgInfo={this.props.whisperMsgInfo}
                            isLiveChattingLocked={this.props.isLiveChattingLocked}
                            handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                        />
                    </CenteredModalInLive>
                } {
                    !this.props.isLiveChattingLocked && this.props.isInkMemoOpened &&
                    <CenteredModalInLive 
                        modalClose={handleSelectedClassTalkFunc} 
                        style={{ margin: '5px', visibility: `${classTalkVisibility}` }}
                    >
                        <InkMemoWindow
                            style={{ visibility: `${classTalkVisibility}` }}
                            toolMode={this.props.toolMode}
                            inkMemoFile={this.props.inkMemoFile}
                            handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                        />
                    </CenteredModalInLive>
                } 
                <PerformWatchingLiveInfo
                    isGuestYn={this.props.isGuestYn}
                    broadcastOpenYN={this.props.broadcastOpenYN}
                    performLiveInfo={performLiveInfo}
                    isFinishLiveAfterExitWatson={this.props.isFinishLiveAfterExitWatson}
                    handleSelectedLiveFunc={this.props.handleSelectedLiveFunc}
                />
                <ClassTalkLive
                    classTalkVisibility={classTalkVisibility}
                    web_isWhisper={this.props.web_isWhisper}
                    openInfo={this.props.isWhisperModalOpened ? this.props.tmpOpenInfo : this.props.openInfo}
                    isFinishLecture={this.props.isFinishLecture}
                    isLiveChattingLocked={this.props.isLiveChattingLocked}
                    isLectureMode={this.props.isLectureMode}
                    isLectureLive={this.props.isLectureLive}
                    classTalkMsg={this.props.classTalkMsg}
                    isWatsonError={this.props.isWatsonError}
                    isRunningWatson={this.props.isRunningWatson}
                    isSpeakingMode={this.props.isSpeakingMode}
                    watsonInfo={this.props.watsonInfo}
                    savedHiclassWatsonInfo={this.props.savedHiclassWatsonInfo}
                    handsUpYN={performLiveInfo.handsUpYN}
                    myHandsUpYN={performHandsUpStatusInfo.myHandsUpYN}
                    connectStatus={performLiveStatusInfo.connectStatus}
                    isConnectedOnScreenApp={this.props.isConnectedOnScreenApp}
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                />
            </div>
        );
    }
}

export default connect(
    ({ classTalkLive, user, live, lecture, watson, hiclasstv }) => ({
        //userNickname                : user.userNickname,
        profileImgUrl               : user.profileImgUrl,
        
        isInkMemoOpened             : classTalkLive.isInkMemoOpened,
        inkMemoFile                 : classTalkLive.inkMemoFile,        
        toolMode                    : classTalkLive.toolMode,
        
        classTalkMsg                : classTalkLive.receivedMsg,

        web_isWhisper               : classTalkLive.web_isWhisper,
        whisperMsgInfo              : classTalkLive.whisperMsgInfo,

        isLiveMemberModalOpened     : live.isLiveMemberModalOpened,
        
        isLiveChattingLocked        : live.isLiveChattingLocked,

        tmpOpenInfo     : {
            isEmoticonSelected      : false,
            selectEmoticonSeq       : -1,
            isAddFunctionOpened     : false,
            isAudioOpened           : false,
            audioStatus             : 'STOPED'
        },
        openInfo        : {
            isEmoticonSelected      : classTalkLive.isEmoticonSelected,
            selectedEmoticonSeq     : classTalkLive.selectedEmoticonSeq,
            isAddFunctionOpened     : classTalkLive.isAddFunctionOpened,
            isAudioOpened           : classTalkLive.isAudioOpened,
            audioStatus             : classTalkLive.audioStatus,
        },

        list_lecture                : lecture.list_lecture.map(row => (row.lectureCode)),
        lecture_info                : {
            lectureKind             : lecture.performLectureInfo.lectureKind,
            lectureCode             : lecture.performLectureInfo.lectureCode
        },
        
        isFinishLiveAfterExitWatson : watson.isFinishLiveAfterExitWatson,

        isLectureMode               : watson.isLectureMode,
        isFinishLecture             : watson.isFinishLecture,
        savedHiclassWatsonInfo      : watson.savedHiclassWatsonInfo,

        isLectureLive               : lecture.isLectureLive,

        selectedLanguageCode        : watson.selectedLanguageCode,
        isWatsonError               : watson.isWatsonError,
        isRunningWatson             : watson.isRunningWatson,
        isSpeakingMode              : watson.isSpeakingMode,

        watsonInfo                  : watson.watsonInfo,
        dialogHist                  : watson.dialogHist,

        isConnectedOnScreenApp      : hiclasstv.isConnectedOnScreenApp,
    }),
    {
        toggle_classTalk,
        inkMemoOpenedToggle,
        inkMemoClosedToggle,
        inkMemo_changeTool,
        closeWatchLiveModal,
        emoticonSelectedToggle,
        emoticonSelectedCancel,
        addFunctionOpenedToggle,

        selectEmoticonSeq,
        selectFunctionSeq,

        appendSendMessage,
        appendSendAudioMemo,
        appendSendInkMemo,
        appendSendFileShare,

        openWhisperModal,
        respondWhisper,
        closeWhisperModal,
        webIsWhisperMode,

        voiceMemoClosedToggle,
        toggleFileShareMessage,
        setPassPossible,
        
        unselectLecture,
        clearPerformLectureInfo,

        exitWatsonMode,
        watsonToggle,
        watsonCreateSession,
        watsonSendMessage,
        watsonRemoveSession,
        falseToWatsonError,

        changeLiveConnect,
        changeLiveConnectStatus,
        changeLiveSoundStatus,
        changeLiveStatusInfo,

        sendQuizAnswer
    }
)(ClassTalkContainer);