import React, { useMemo } from 'react';

import { getMemberProfileImage } from '../../../lib/func/ExportFunction';

const DefaultProfileImage = ({ url, alt }) => {
    const profileImgUrl = useMemo(() => getMemberProfileImage(url), [url]);
    
    return (
        <img className="hiclasstv-profile-img" src={profileImgUrl} alt={alt} />
    );
};

export default DefaultProfileImage;