import React from 'react';
import { BsX } from 'react-icons/bs';
import { FaPlus, FaCircle, FaListUl } from 'react-icons/fa';

import CustomProfileImage from '../../user/profile/CustomProfileImage';

const ChattingMenu = ({ userSeq, performChatRoomInfo, isOnAirLive, handleSelectedFunc }) => {

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area justify-content-center">
                <span>
                    <b>팀업 상대</b>
                </span>
                <span className="func-btn" style={{position: 'absolute', right: 0}} title="닫기" onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="title-area h-auto" title="팀업 멤버 초대" onClick={() => handleSelectedFunc({ selectedFunc: "INVITE", chatRoomSeq: performChatRoomInfo.chatRoomSeq })}>
                <button className="d-flex button w-100 align-items-center justify-content-start p-2">
                    <FaPlus className="d-flex font-md mr-2" />멤버 초대
                </button>
            </div>
            {
                performChatRoomInfo.list_member !== undefined && performChatRoomInfo.list_member !== null && performChatRoomInfo.list_member.length > 0 ?
                    <div className="list-group" style={{overflow: 'auto'}}>
                        {
                            performChatRoomInfo.list_member.map(
                                member => (
                                    <div className="d-flex list-group-hitem list-group-hitem-action py-2 px-1 justify-content-center align-items-center" key={member.userSeq}>
                                        <div className="d-flex area-15 fit-content">
                                            <CustomProfileImage url={member.profileImgUrl} alt={member.userNickname} />
                                        </div>
                                        <div className="text-area justify-content-start">
                                            {
                                                member.userSeq === userSeq &&
                                                    <div className="d-flex round white-text grey darken-2 mr-1 small">
                                                        <span className="m-0 p-1">&nbsp;나&nbsp;</span>
                                                    </div>
                                            }
                                            <span className="hiclass-text" title={member.userNickname}><b>{member.userNickname}</b></span>
                                        </div>
                                        {
                                            member.entryYN === 'Y' &&
                                            <div className="d-flex area-10 ml-1">
                                                <FaCircle className="d-flex font-md green-text" />
                                            </div>
                                        }
                                    </div>
                                )
                            )
                        }
                    </div> :
                    <></>
            }
            <div className="title-area h-auto" onClick={() => handleSelectedFunc({ selectedFunc: "LIST_LIVE" })}>
                <button className="d-flex button w-100 align-items-center justify-content-start p-2" title="내 클래스 목록 조회">
                    <FaListUl className="d-flex font-md mr-2" />내 클래스 목록
                </button>
                {
                    isOnAirLive && 
                        <button type="button" className="btn btn-sm red white-text m-0 py-1 px-2" style={{borderRadius:'1rem'}} title="클래스 전체 OFF" onClick={() => handleSelectedFunc({selectedFunc:"STOP_LIVE"})}>
                            onAir
                        </button>
                }
            </div>
        </div>
    );
}

export default ChattingMenu;