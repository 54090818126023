import React from 'react';

const ToppedModal = ({ modalClose, children }) => {
    return(
        <div className="hiclasstv-modal topped" onClick={() => modalClose({selectedFunc:"NONE"})}>
            {children}
        </div>
    ) 
}

export default ToppedModal;