import React from 'react';

import { getEntryMemberCount } from '../../lib/func/ExportFunction';

// import studyingImg from '../../images/studying.png';

const ConfigMyChatList = ({list_chatting, userSeq, handleSelectedFunc }) => {
    
    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return(
        list_chatting.length > 0 ?
        <div className="list-group scroll-show-active light" style={{ overflow:'auto' }}>
            {
                list_chatting.map(
                    chatting => (
                        <div className={"d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" + (chatting.entryYN === "Y" ? " active" : "")} key={chatting.chatRoomSeq} onClick={() => handleSelectedFunc({ selectedFunc: "MEMBER_TOGGLE", chatRoomSeq: chatting.chatRoomSeq, chatRoomCreator: chatting.chatRoomCreator, list_member: chatting.list_member })}>
                            <div className="d-flex area-15 fit-content">
                                <img className="hiclasstv-chatting-img mr-2" src="/images/studying.png" alt="" />
                            </div>
                            <div className="text-area flex-column align-items-start">
                                <div className="text-area justify-content-start">
                                    <span className="hiclass-text" title={chatting.chatRoomName}><b>{chatting.chatRoomName}</b></span>
                                    <span className="hiclass-text hiclasstv-deepDarkgrey-text">&nbsp;{getEntryMemberCount('teamUp', chatting.list_member)}</span>
                                </div>
                                <span className="hiclass-text hiclasstv-darkgrey-text small" title={chatting.list_member.map(mbr => mbr.userNickname).join(" | ")}>
                                    {chatting.list_member.map(mbr => (mbr.userNickname)).join(" | ")}
                                </span>
                            </div>
                            <div className="d-flex flex-column area-25 ml-2" onClick={handlePreventClick}>
                                {
                                    chatting.chatRoomCreator === userSeq ?
                                        <>
                                            <button type="button" className="btn btn-sm grey white-text m-0 py-1 px-2" style={{ borderRadius: '1rem' }} onClick={() => handleSelectedFunc({ selectedFunc: "EDIT_TOGGLE", chatRoomSeq: chatting.chatRoomSeq, chatRoomName: chatting.chatRoomName })}>
                                                수정
                                            </button>
                                            <button type="button" className="btn btn-sm red white-text m-0 mt-1 py-1 px-2" style={{ borderRadius: '1rem' }} onClick={() => handleSelectedFunc({ selectedFunc: chatting.entryYN === "Y" ? "DELETE_EXIT_TOGGLE" : "DELETE_TOGGLE", chatRoomSeq: chatting.chatRoomSeq, chatRoomName: chatting.chatRoomName, list_member: chatting.list_member })}>
                                                나가기
                                            </button>
                                        </> :
                                        <button type="button" className="btn btn-sm red white-text m-0 py-1 px-2" style={{ borderRadius: '1rem' }} onClick={() => handleSelectedFunc({ selectedFunc: "EXIT_TOGGLE", chatRoomSeq: chatting.chatRoomSeq, chatRoomName: chatting.chatRoomName, entryYN:chatting.entryYN })}>
                                            나가기
                                        </button>
                                }
                            </div>
                        </div>
                    )
                )
            }
        </div> :
        <div className="view p-2 text-center grey-text">
            팀업이 존재하지 않습니다.
        </div>
    );
}
export default ConfigMyChatList;