import React from 'react';

import { getEntryMemberCount } from '../../lib/func/ExportFunction';
// import studyingImg from '../../images/studying.png';

const MyChatList = ({ userSeq, list_chatting, handleSelectedFunc }) => {

    return(
        <div className="list-group scroll-show-active light" style={{ overflow: 'auto' }}>
            <div className="d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" onClick={()=>handleSelectedFunc({selectedFunc:"START_LIVE"})}>
                <div className="d-flex area-15 fit-content">
                    <img className="hiclasstv-chatting-img mr-2" src="/images/studying.png" alt="" />
                </div>
                <div className="text-area justify-content-start">
                    <span className="hiclass-text"><b>나와의 채팅</b></span>
                </div>
            </div>
            {
                list_chatting.map(
                    chatting => (
                        <div className={"d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" + (chatting.entryYN === "Y" ? " active" : "")} key={chatting.chatRoomSeq} onClick={()=>handleSelectedFunc({selectedFunc:"PARTICIPATE", chatRoomSeq: chatting.chatRoomSeq, chatRoomName: chatting.chatRoomName })}>
                            <div className="d-flex area-15 fit-content">
                                <img className="hiclasstv-chatting-img mr-2" src="/images/studying.png" alt="" />
                                {/*
                                <div className="hiclasstv-chatting-img mr-2">
                                    {
                                        chatting.list_member.length > 2 ?
                                            <div className="d-flex w-100 h-100 red">
                                                {
                                                    chatting.list_member.length === 3 ?
                                                        <div className="member-profile w-100 h-100 yellow">
                                                            {
                                                                chatting.list_member.map(
                                                                    member => (
                                                                        member.userSeq !== userSeq &&
                                                                        <div className="member-3 green">
                                                                            {member.userNickname}
                                                                        </div>
                                                                    )
                                                                )
                                                            }
                                                        </div> :
                                                    chatting.list_member.length === 4 ?
                                                        <div className="member-4">

                                                        </div> :
                                                    <div className="member-5">

                                                    </div>
                                                }
                                            </div> :
                                            <img className="hiclasstv-chatting-img mr-2" src="/images/studying.png" alt="" />
                                    }
                                </div>
                                */}                             
                            </div>
                            <div className="text-area flex-column align-items-start">
                                <div className="text-area justify-content-start">
                                    {
                                        //chatting.chatRoomTalkStatus !== "N" &&
                                        //<span className="mr-1 hiclasstv-blue white-text px-1" style={{fontWeight:'bold'}}>{chatting.chatRoomTalkStatus}</span>
                                    }
                                    <span className="hiclass-text" title={chatting.chatRoomName}><b>{chatting.chatRoomName}</b></span>
                                    <span className="hiclass-text hiclasstv-deepDarkgrey-text">&nbsp;{getEntryMemberCount('teamUp', chatting.list_member)}</span>
                                </div>
                                <span className="hiclass-text hiclasstv-darkgrey-text small" title={chatting.list_member.map(mbr => (mbr.userNickname)).join(" | ")}>
                                    {chatting.list_member.map(mbr => (mbr.userNickname)).join(" | ")}
                                </span>
                            </div>
                        </div>
                    )
                )
            }
        </div>
    );
}
export default MyChatList;