import React from "react";

import ServicePolicy from "./ServicePolicy";

const WebPolicy = () => {
    return (
        <div className="web-main list-area align-items-center justify-content-center p-2">
            <h3>
                <b>HiClass.TV 서비스 약관</b>
            </h3>
            <div className="web-policy-box no-modal p-2 my-2 scroll-show">
                <ServicePolicy />
            </div>
            <div className="footer-template-web" style={{ border: 'none' }}>
                <span>1992~2022 © Easy Systems Inc.</span>
            </div>
        </div>
    );
};

export default WebPolicy;