import React, { useEffect, useState } from 'react';
import { BsX } from 'react-icons/bs';
import ConstData from '../../../lib/ConstData';

import Alerts from '../../etc/list/Alerts';

const EditLive = ({ selectedLiveInfo, serverUserSeq, alertKind, alertMessage, handleSelectedConfigFunc }) => {
    const { liveSeq, liveName, mixedClassExistYn, livePublicYn } = selectedLiveInfo;

    const [lAlertKind, setAlertKind] = useState(alertKind);
    const [lAlertMessage, setAlertMessage] = useState(alertMessage);

    const [lLiveName, setLiveName] = useState(liveName);
    const [lStatus, setStatus] = useState(mixedClassExistYn === undefined ? false : mixedClassExistYn);
    const [lPublicYn, setPulicYn] = useState(livePublicYn === undefined ? "N" : livePublicYn);
    
    useEffect(() => {
        setAlertKind(alertKind);
        setAlertMessage(alertMessage);
    }, [alertKind, alertMessage]);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const onChangeInputLiveName = (e) => {
        let str = e.target.value;
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage("");
        }

        if (str.length > 50) {
            str = str.substr(0, 50);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage('클래스 이름은 50자 이내로 입력해야 합니다.');
        }

        setLiveName(str);
    }

    const onChangeMixedClassStatus = (e) => {
        if (e.target.value === 'true') {
            console.log('mixed class status Y');
            setStatus(true);
        } else {
            console.log('mixed class status N');
            setStatus(false);
        }
    }

    const onChangeLivePublicYn = (e) => {
        setPulicYn(e.target.value);
        handleSelectedConfigFunc({ selectedFunc: "UPDATE_PULIC_YN", liveSeq, publicYn: e.target.value });
    }

    const handleSaveBroadcastLiveInfo = () => {
        /* if (ConstData.IS_LOCAL_VERSION) {
            if (serverUserSeq > -1) {
                if (lLiveName === "") {
                    handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_LIVE_INFO", liveSeq, liveName, status: lStatus });
                } else {
                    handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_LIVE_INFO", liveSeq, liveName: lLiveName, status: lStatus });
                }
            } else {
                handleSelectedConfigFunc({ selectedFunc: "MESSAGE", message: "서버 사용자 인증 후,<br> Mixed Class 기능을 사용할 수 있습니다." });
            }
        } else {
            if (lLiveName === "") {
                handleSelectedConfigFunc({ selectedFunc: "UPDATE_LIVE_INFO", liveSeq, liveName });
            } else {
                handleSelectedConfigFunc({ selectedFunc: "UPDATE_LIVE_INFO", liveSeq, liveName: lLiveName });
            }
        } */
        if (lLiveName === "") {
            handleSelectedConfigFunc({ selectedFunc: "UPDATE_LIVE_INFO", liveSeq, liveName });
        } else {
            handleSelectedConfigFunc({ selectedFunc: "UPDATE_LIVE_INFO", liveSeq, liveName: lLiveName });
        }
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area">
                <span>
                    <b>클래스 정보 수정</b>
                </span>
                <span className="func-btn" style={{position:'absolute', right:'0'}} onClick={() => handleSelectedConfigFunc({selectedFunc:"NONE"})}>
                    <BsX />
                </span>
            </div>
            <div className="input-area mt-2">
                <span className="input-label pr-2 small area-30" style={{ justifyContent: 'flex-start' }}>
                    클래스 이름
                </span>
                <div className="input-data">
                    <input type="text" title={"변경 전 이름 : " + liveName} placeholder={liveName} onChange={onChangeInputLiveName} />
                </div>
            </div>
            <div className="d-flex w-100 justify-content-between">
                <span style={{ width: '35%', maxWidth: '80px' }}></span>
                <span className="input-description area-75">- 1자~50자 이내로 입력</span>
            </div>
            <div className="d-flex w-100 justify-content-between">
                <span style={{ width: '35%', maxWidth: '80px' }}></span>
                <span className="input-description area-75">- 한글, 영문, 숫자, 특수문자 사용 가능</span>
            </div>
            {/* {
                ConstData.IS_LOCAL_VERSION && selectedLiveInfo.liveStatus === "Y" && 
                <div className="input-area">
                    <span className="input-label pr-2 small area-30" style={{ justifyContent: 'flex-start' }}>
                        Mixed Class
                    </span>
                    <div className="input-data area-70">
                        <span className="d-flex pb-1 area-50">
                            <input className="d-flex mr-2 p-0" type="radio" name="mixedClassStatus" id="mixedOn" value={true} onChange={onChangeMixedClassStatus} defaultChecked={mixedClassExistYn === 'Y' ? true : false} />
                            ON
                        </span>
                        <span className="d-flex pb-1 area-50">
                            <input className="d-flex mr-2 p-0" type="radio" name="mixedClassStatus" id="mixedOff" value={false} onChange={onChangeMixedClassStatus} defaultChecked={mixedClassExistYn === 'N' ? true : false} />
                            OFF
                        </span>
                    </div>
                </div>
            }
            {
                <div className="input-area">
                    <span className="input-label pr-2 small area-30" style={{ justifyContent: 'flex-start' }}>
                        클래스 공개 여부
                    </span>
                    <div className="input-data area-70">
                        <span className="d-flex pb-1 area-50">
                            <input className="d-flex mr-2 p-0" type="radio" name="livePublicYnStatus" id="livePublicYes" value={"Y"} onChange={onChangeLivePublicYn} defaultChecked={lPublicYn === "Y" ? true : false} />
                            공개
                        </span>
                        <span className="d-flex pb-1 area-50">
                            <input className="d-flex mr-2 p-0" type="radio" name="livePublicYnStatus" id="livePublicNo" value={"N"} onChange={onChangeLivePublicYn} defaultChecked={lPublicYn === "N" ? true : false} />
                            비공개
                        </span>
                    </div>
                </div>
            } */}
            <Alerts kind={lAlertKind} message={lAlertMessage} style={{ marginTop:0 }} />
            <div className="d-flex justify-content-center align-items-center my-2">
                <button type="button" className="btn btn-sm small hiclasstv-blue white-text m-0 py-1 px-2" onClick={() => handleSaveBroadcastLiveInfo()}>수정</button>
            </div>
        </div>
    );
}

export default EditLive;