import React, { useState, useEffect } from 'react';
import { FaCircle } from 'react-icons/fa';

import CustomProfileImage from '../../user/profile/CustomProfileImage';

const WatchingLiveMember = ({ userSeq, performLiveInfo, handleSelectedClassTalkFunc }) => {

    const [teacherInfo, setTeacherInfo] = useState({ userSeq: -1, userNickname: '', userProfile: '', profileImgUrl: '1' });
    const [list_member, setListMember] = useState([]);
    //const [list_guest, setListGuest] = useState([]);

    useEffect(() => {
        if (performLiveInfo.teacher_info !== undefined && performLiveInfo.teacher_info !== null) {
            setTeacherInfo({
                userSeq         : performLiveInfo.teacher_info.userSeq,
                userNickname    : performLiveInfo.teacher_info.userNickname,
                userProfile     : performLiveInfo.teacher_info.userProfile,
                profileImgUrl   : performLiveInfo.teacher_info.profileImgUrl
            });
        }
    }, [performLiveInfo.teacher_info]);

    useEffect(() => {
        if (performLiveInfo.list_member !== undefined && performLiveInfo.list_member !== null) {
            setListMember(performLiveInfo.list_member);
        }
    }, [performLiveInfo.list_member]);

    /*
    useEffect(() => {
        if (performLiveInfo.list_guest !== undefined && performLiveInfo.list_guest !== null) {
            setListGuest(performLiveInfo.list_guest);
        }
    }, [performLiveInfo.list_guest]);
    */

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }
    
    return (
        <div onClick={handlePreventClick}>
            <div className="title-area justify-content-center">
                <div className="d-flex align-items-center">
                    <span className="hiclass-text"><b>클래스 멤버 조회</b></span>
                </div>
                <span className="hiclasstv-darkgrey-text px-2 button" style={{position:'absolute', right:'0'}} title="닫기" onClick={() => handleSelectedClassTalkFunc({selectedFunc:"NONE"})}>
                    닫기
                </span>
            </div>
            <div className="list-group" style={{overflow:'auto'}}>
                <div className="d-flex flex-column list-group-hitem py-2 px-1" style={{minHeight:'max-content'}}>
                    <span className="grey-text small pb-1"><b>클래스 진행자</b></span>
                    <div className="d-flex w-100">
                        <div className="d-flex area-15 fit-content align-items-center">
                            <CustomProfileImage url={teacherInfo.profileImgUrl} alt={teacherInfo.userNickname} />
                        </div>
                        <div className="text-area flex-column align-items-start">
                            <span className="hiclass-text" title={teacherInfo.userNickname}><b>{teacherInfo.userNickname}</b></span>
                            <span className="hiclass-text hiclasstv-deepDarkgrey-text small" title={teacherInfo.userProfile}>{teacherInfo.userProfile}</span>
                        </div>
                    </div>
                </div>
                {
                    list_member.map(
                        member => (
                            <div className="d-flex list-group-hitem list-group-hitem-action white py-2 px-1 justify-content-center align-items-center" key={member.userSeq}>
                                <div className="d-flex area-15 fit-content">
                                    <CustomProfileImage url={member.profileImgUrl} style={{ width: '2rem', height:'2rem' }} alt={member.userNickname} />
                                </div>
                                <div className="text-area justify-content-start">
                                    {
                                        member.userSeq === userSeq ?
                                            <div className="d-flex round white-text grey darken-2 mr-1 small">
                                                <span className="p-1">&nbsp;나&nbsp;</span>
                                            </div> :
                                            <></>
                                    }
                                    <span className="hiclass-text" title={member.userNickname}><b>{member.userNickname}</b></span>
                                </div>
                                {
                                    member.userJoinStatus === 'Y' ?
                                        <div className="d-flex area-10 ml-1">
                                            <FaCircle className="d-flex font-md green-text" />
                                        </div> :
                                        <></>
                                }
                            </div>
                        )
                    )
                } {/*
                    list_guest.map(
                        member => (
                            <div className="d-flex list-group-hitem list-group-hitem-action white py-2 px-1 justify-content-center align-items-center" key={member.userSeq}>
                                <div className="d-flex area-15 fit-content">
                                    <CustomProfileImage url={member.profileImgUrl} style={{ width: '2rem', height:'2rem' }} alt={member.userNickname} />
                                </div>
                                <div className="text-area justify-content-start">
                                    {
                                        member.userSeq === userSeq ?
                                            <div className="d-flex round white-text grey darken-2 mr-1 small">
                                                <span className="p-1">&nbsp;나&nbsp;</span>
                                            </div> :
                                            <></>
                                    }
                                    <span className="hiclass-text" title={member.userNickname}><b>{member.userNickname}</b></span>
                                </div>
                                {
                                    member.userJoinStatus === 'Y' ?
                                        <div className="d-flex area-10 ml-1">
                                            <FaCircle className="d-flex font-md green-text" />
                                        </div> :
                                        <></>
                                }
                            </div>
                        )
                    )
                */}
            </div>
        </div>
    );
}

export default WatchingLiveMember;