import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import createRequestThunk from '../lib/createRequestThunk';

import ConstData from '../lib/ConstData';

import { remoconType } from '../lib/classTalk/CodeEnum';
import { callEoForSelectSoundDeviceInfo, callEoForSendAction } from '../lib/EoBrowserSendUtil';

import { get_download_info, get_build_version_info } from '../lib/api/hiclasstv';
import { get_live_member_list_by_qrLogin, get_live_info_by_cookies } from '../lib/api/live';
import { check_local_server_auth_code, get_local_server_info_by_seq } from '../lib/api/local';

import { enAdminTap, enInfoTap, enPassTap } from '../lib/ConstCommand';

export const HICLASSTV_STATE_INIT           = "hiclasstv/HICLASSTV_STATE_INIT";
export const SHOW_PROMOTION_LIST            = "hiclasstv/SHOW_PROMOTION_LIST";
export const FINISH_HICLASSTV_STATE_INIT    = "hiclasstv/FINISH_HICLASSTV_STATE_INIT";
export const SET_SMART_TV_GROUP_MEMBER      = "hiclasstv/SET_SMART_TV_GROUP_MEMBER";

export const SET_LIVE_INFO_BY_COOKIES       = "hiclasstv/SET_LIVE_INFO_BY_COOKIES";

const HICLASSTV_DOWNLOAD_INFO               = "hiclasstv/HICLASSTV_DOWNLOAD_INFO";
const HICLASSTV_DOWNLOAD_INFO_SUCCESS       = "hiclasstv/HICLASSTV_DOWNLOAD_INFO_SUCCESS";

const HICLASSTV_VERSION_INFO                = "hiclasstv/HICLASSTV_VERSION_INFO";
const HICLASSTV_VERSION_INFO_SUCCESS        = "hiclasstv/HICLASSTV_VERSION_INFO_SUCCESS";

const TOGGLE_NOTICE_MODAL                   = "hiclasstv/TOGGLE_NOTICE_MODAL";
const TOGGLE_RETRY_LOGIN_MODAL              = "hiclasstv/TOGGLE_RETRY_LOGIN_MODAL";

const CHANGE_REMOCON_TAP                    = "hiclasstv/CHANGE_REMOCON_TAP";
const SET_AUDIO_MIXER_STATUS                = "hiclasstv/SET_AUDIO_MIXER_STATUS";
const SET_MY_MIC_ECHO_STATUS                = "hiclasstv/SET_MY_MIC_ECHO_STATUS";
const SET_TEAMUP_SOUND_DEVICE_INFO          = "hiclasstv/SET_TEAMUP_SOUND_DEVICE_INFO";
const SET_HANDSUP_SOUND_DEVICE_INFO         = "hiclasstv/SET_HANDSUP_SOUND_DEVICE_INFO";
const SETTING_SOUND_DEVICE_OPEN             = "hiclasstv/SETTING_SOUND_DEVICE_OPEN";
const SELECT_SOUND_DEVICE                   = "hiclasstv/SELECT_SOUND_DEVICE";
const CHANGE_SOUND_VOLUME                   = "hiclasstv/CHANGE_SOUND_VOLUME";
const TOGGLE_SOUND_ALERT_MODAL              = "hiclasstv/TOGGLE_SOUND_ALERT_MODAL";
export const INIT_ALL_CHECK_STATUS          = "hiclasstv/INIT_ALL_CHECK_STATUS";

export const CHANGE_SHOW_PASS_KIND          = "hiclasstv/CHANGE_SHOW_PASS_KIND";
const CHANGE_SHOW_INFO_KIND                 = "hiclasstv/CHANGE_SHOW_INFO_KIND";
const CHANGE_SHOW_ADMIN_KIND                = "hiclasstv/CHANGE_SHOW_ADMIN_KIND";
const CHANGE_SHOW_MENU_KIND                 = "hiclasstv/CHANGE_SHOW_MENU_KIND";

const CHANGE_WINDOW_PENTALK_BOARD           = "hiclasstv/CHANGE_WINDOW_PENTALK_BOARD";

const GET_USER_LIST_BY_QR_LOGIN             = "hiclasstv/GET_USER_LIST_BY_QR_LOGIN";
const GET_USER_LIST_BY_QR_LOGIN_SUCCESS     = "hiclasstv/GET_USER_LIST_BY_QR_LOGIN_SUCCESS";
const GET_LIVE_INFO_BY_COOKIES              = "hiclasstv/GET_LIVE_INFO_BY_COOKIES";
const GET_LIVE_INFO_BY_COOKIES_SUCCESS      = "hiclasstv/GET_LIVE_INFO_BY_COOKIES_SUCCESS";
export const INIT_QR_LIVE_INFO              = "hiclasstv/INIT_QR_LIVE_INFO";

export const changeShowPassKind             = createAction(CHANGE_SHOW_PASS_KIND);
export const changeShowInfoKind             = createAction(CHANGE_SHOW_INFO_KIND);
export const changeShowAdminKind            = createAction(CHANGE_SHOW_ADMIN_KIND);
export const changeShowMenuKind             = createAction(CHANGE_SHOW_MENU_KIND);

export const loadDownloadInfo               = createRequestThunk(HICLASSTV_DOWNLOAD_INFO, get_download_info);
export const getVersionInfo                 = createRequestThunk(HICLASSTV_VERSION_INFO, get_build_version_info);

export const toggleNoticeModal              = createAction(TOGGLE_NOTICE_MODAL);
export const toggleRetryLoginModal          = createAction(TOGGLE_RETRY_LOGIN_MODAL);

export const changeRemoconTap               = createAction(CHANGE_REMOCON_TAP);
export const setAudioMixerStatus            = createAction(SET_AUDIO_MIXER_STATUS);
export const setMyMicEchoStatus             = createAction(SET_MY_MIC_ECHO_STATUS);
export const setTeamupSoundDeviceInfo       = createAction(SET_TEAMUP_SOUND_DEVICE_INFO);
export const setHandsupSoundDeviceInfo      = createAction(SET_HANDSUP_SOUND_DEVICE_INFO);
export const settingSoundDeviceOpen         = createAction(SETTING_SOUND_DEVICE_OPEN);
export const selectSoundDevice              = createAction(SELECT_SOUND_DEVICE);
export const setSoundDeviceVolume           = createAction(CHANGE_SOUND_VOLUME);
export const toggleSoundAlertModal          = createAction(TOGGLE_SOUND_ALERT_MODAL);

export const changeWindowPentalkBoard       = createAction(CHANGE_WINDOW_PENTALK_BOARD);

export const getUserListByQRLogIn           = createRequestThunk(GET_USER_LIST_BY_QR_LOGIN, get_live_member_list_by_qrLogin);
export const getLiveInfoByCookies           = createRequestThunk(GET_LIVE_INFO_BY_COOKIES, get_live_info_by_cookies);
export const initQrLiveInfo                 = createAction(INIT_QR_LIVE_INFO);

// * * * * * * * local Server  * * * * * * * //
const CHECK_LOCAL_SERVER_AUTH_CODE          = "hiclasstv/CHECK_LOCAL_SERVER_AUTH_CODE";
const CHECK_LOCAL_SERVER_AUTH_CODE_SUCCESS  = "hiclasstv/CHECK_LOCAL_SERVER_AUTH_CODE_SUCCESS"

const GET_LOCAL_SERVER_INFO                 = "hiclasstv/GET_LOCAL_SERVER_INFO";
const GET_LOCAL_SERVER_INFO_SUCCESS         = "hiclasstv/GET_LOCAL_SERVER_INFO_SUCCESS";

const GET_CLOUD_SERVER_INFO                 = "hiclasstv/GET_CLOUD_SERVER_INFO";

const TOGGLE_SETTING_LOCAL_INFO             = "hiclasstv/TOGGLE_SETTING_LOCAL_INFO";

export const checkLocalServerAuthCode       = createRequestThunk(CHECK_LOCAL_SERVER_AUTH_CODE, check_local_server_auth_code);
export const getLocalServerInfo             = createRequestThunk(GET_LOCAL_SERVER_INFO, get_local_server_info_by_seq);
export const getCloudServerInfo             = createAction(GET_CLOUD_SERVER_INFO);
export const toggle_settingLocalInfoModal   = createAction(TOGGLE_SETTING_LOCAL_INFO);
// * * * * * * * * * * * * * * * * * * * * * //

// * * * * * * * android app  * * * * * * * * //
const CONNECTED_ON_SCREEN_APP               = "hiclasstv/CONNECTED_ON_SCREEN_APP";
const DISCONNECTED_ON_SCREEN_APP            = "hiclasstv/DISCONNECTED_ON_SCREEN_APP";
const CONNECTED_ON_SCREEN_APP_ARR           = "hiclasstv/CONNECTED_ON_SCREEN_APP_ARR";
const DISCONNECTED_ON_SCREEN_APP_ARR        = "hiclasstv/DISCONNECTED_ON_SCREEN_APP_ARR";

export const connectedOnScreenApp           = createAction(CONNECTED_ON_SCREEN_APP);
export const disconnectedOnScreenApp        = createAction(DISCONNECTED_ON_SCREEN_APP);
export const connectedOnScreenAppArr        = createAction(CONNECTED_ON_SCREEN_APP_ARR);
export const disconnectedOnScreenAppArr     = createAction(DISCONNECTED_ON_SCREEN_APP_ARR);
// * * * * * * * * * * * * * * * * * * * * * //

const initialState = {
    isShowAllMenu                       : false,

    currentPassTap                      : enPassTap.PRDT_LIST,
    currentInfoTap                      : enInfoTap.Home,
    currentAdminTap                     : enAdminTap.Home,

    remoconCMD                          : remoconType.SoundControl,

    isMyMicEchoEnable                   : false,

    myMicInfo                           : { 
        selectedIndex                   : -1, 
        list_mic                        : [],
        volume                          : 70
    },
    teampUpSpeakerInfo                  : {
        selectedIndex                   : -1,
        list_speaker                    : [],
        volume                          : 70
    },
    handsUpSpeakerInfo                  : {
        selectedIndex                   : -1,
        list_speaker                    : [],
        volume                          : 70
    },

    isSettingDeviceOpened               : false,
    isSoundAlertModalOpened             : false,

    alertMessage                        : "",
    alertKind                           : ConstData.ALERT_KIND.NONE,

    isNoticeModalOpened                 : true,
    isCheckedVersion                    : false,

    isRetryLoginModalOpened             : true,

    version_info                        : null,
    download_info                       : null,

    list_qrLoginMember                  : [],
    qrLiveInfo                          : {
        liveSeq                         : -1,
        liveName                        : ""
    },
    isInvalidRoute                      : false,

    cookiesLiveInfo                     : "",

    // ********** L O C A L ********** //
    checkAuthCodeResult                 : {
        isCorrectAuthCode               : false,
        localServerSeq                  : -1,
        localServerName                 : null,
        alertMessage                    : "",
        alertKind                       : ConstData.ALERT_KIND.NONE
    },

    localServerInfo                     : {
        isCloud                         : false,
        serverName                      : "",
        serverIP                        : "",
        alwaysCloudStatus               : false
    },

    isSettingLocalInfoModalOpened       : false,

    /** android app */
    isConnectedOnScreenApp              : false,
    // list_isConnectedOnScreenApp         : [false, false, false, false],     // 그룹 = 4개
    list_isConnectedOnScreenApp         : [     // 그룹 = 4개
        { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
        { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
        { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
        { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
    ],
};

const hiclasstv = handleActions({
    // init data 
    [HICLASSTV_STATE_INIT]: (state, action) => {
        const { isSuccessed } = action.payload;
        // console.log("HICLASSTV_STATE_INIT - ", action.payload);

        if (isSuccessed) {
            const { isLogoutSuccess } = action.payload;

            if (isLogoutSuccess) {
                return {
                    ...state,
                    currentPassTap              : enPassTap.PRDT_LIST,
                    currentInfoTap              : enInfoTap.Home,
                    currentAdminTap             : enAdminTap.Home,

                    isShowAllMenu               : false,

                    remoconCMD                  : remoconType.SoundControl,

                    isMyMicEchoEnable           : false,
                    myMicList                   : [],

                    myMicInfo                   : { 
                        selectedIndex           : -1, 
                        list_mic                : [],
                        volume                  : 70
                    },
                    teampUpSpeakerInfo          : {
                        selectedIndex           : -1,
                        list_speaker            : [],
                        volume                  : 70
                    },
                    handsUpSpeakerInfo          : {
                        selectedIndex           : -1,
                        list_speaker            : [],
                        volume                  : 70
                    },

                    isSettingDeviceOpened       : false,
                    isNoticeModalOpened         : true,
                    isCheckedVersion            : false,
                    isSettingLocalInfoModalOpened : false,

                    list_qrLoginMember          : [],
                    isInvalidRoute              : false,

                    cookiesLiveInfo             : "",

                    isConnectedOnScreenApp      : false,
                    // list_isConnectedOnScreenApp         : [false, false, false, false],     // 그룹 = 4개
                    list_isConnectedOnScreenApp: [     // 그룹 = 4개
                        { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
                        { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
                        { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
                        { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
                    ],
                }
            } else {
                console.log("HICLASSTV_STATE_INIT - isLogoutSuccess => ", isLogoutSuccess);

                return {
                    ...state,
                }
            }
        } else {
            console.log("HICLASSTV_STATE_INIT - isSuccessed => ", isSuccessed);

            return {
                ...state,
            }
        }
    },

    [FINISH_HICLASSTV_STATE_INIT]: (state, action) => {
        const { isSuccessed } = action.payload;
        // console.log("FINISH_HICLASSTV_STATE_INIT - ", action.payload);

        if (isSuccessed) {                
            const { isFinishSuccess } = action.payload;

            if (isFinishSuccess) {
                return {
                    ...state,
                    cookiesLiveInfo : ""
                }
            } else {
                return {
                    ...state
                }
            }
        } else {
            return {
                ...state
            }
        }
    }, 

    [GET_USER_LIST_BY_QR_LOGIN_SUCCESS]: (state, action) => {
        const { mode } = action.parms;
        const { isSuccessed } = action.payload;
        // console.log("GET_USER_LIST_BY_QR_LOGIN - ", action.parms, action.payload);

        if (isSuccessed) {
            const { list_member, live_info } = action.payload;

            let liveInfo = {
                liveSeq     : -1,
                liveName    : "",
                groupLimit  : 4     // default value is 4
            };

            let list_qrMember = [];
            let list_qrLoginMember = [];
            let teacher_info = {
                userSeq         : -1,
                userID          : "",
                userNickname    : ""
            };

            let list_isConnectedOnScreenApp = [];

            if (live_info) {
                liveInfo = {
                    liveSeq     : live_info.LIVE_SEQ,
                    liveName    : live_info.LIVE_NAME,
                    groupLimit  : live_info.GROUP_LIMIT_NUM ? live_info.GROUP_LIMIT_NUM : 4
                };

                /* let teacher_info = {
                    userSeq         : live_info.LIVE_TEACHER_SEQ,
                    userID          : live_info.USER_ID,
                    userNickname    : live_info.USER_NICKNAME
                }; */

                teacher_info.userSeq = live_info.LIVE_TEACHER_SEQ;
                teacher_info.userID = live_info.USER_ID;
                teacher_info.userNickname = live_info.USER_NICKNAME;

                liveInfo.teacher_info = teacher_info;

                if (mode !== "web-group-smarttv") { 
                    list_qrMember.push(teacher_info);   // group 이 아니면 teacher_info가 0번 index ... by hjkim 20231205
                    list_isConnectedOnScreenApp = state.list_isConnectedOnScreenApp.filter((info, index) => index === 0);   // group 이 아니면 list는 1개만 셋팅 ... by hjkim 20231208
                } else {
                    let groupLimit = 4;
                    if (liveInfo.groupLimit <= 4) groupLimit = 4;
                    else if (liveInfo.groupLimit <= 6) groupLimit = 6;
                    else groupLimit = 9;

                    list_isConnectedOnScreenApp = new Array(groupLimit).fill({ userSeq: -1, userNickname: "", isConnectedOnScreenApp: false });

                    /* if (liveInfo.groupLimit === 5) {
                        list_isConnectedOnScreenApp = new Array(liveInfo.groupLimit + 1).fill({ userSeq: -1, userNickname: "", isConnectedOnScreenApp: false });
                    } else {
                        list_isConnectedOnScreenApp = new Array(liveInfo.groupLimit).fill({ userSeq: -1, userNickname: "", isConnectedOnScreenApp: false });
                    } */

                    /* list_isConnectedOnScreenApp = [
                        { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
                        { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
                        { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
                        { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
                    ]; */
                }
            }

            if (list_member) {
                list_qrLoginMember = list_qrMember.concat(list_member.map(row => ({
                    userSeq         : row.USER_SEQ,
                    userID          : row.USER_ID,
                    userNickname    : row.USER_NICKNAME,
                    userJoinStatus  : row.ENTRY_YN,
                })));
            }

            if (mode === "web-group-smarttv") { // group 이면 teacher_info가 제일 마지막 index ... by hjkim 20231205
                list_qrLoginMember.push(teacher_info);
            }

            // console.log("list_isConnectedOnScreenApp => ", list_isConnectedOnScreenApp, list_isConnectedOnScreenApp.length);
            // console.log("list_qrLoginMember => ", list_qrLoginMember);

            return {
                ...state,
                qrLiveInfo                  : liveInfo,
                list_qrLoginMember          : list_qrLoginMember,
                list_isConnectedOnScreenApp : list_isConnectedOnScreenApp
            }
        } else {
            console.log("GET_USER_LIST_BY_QR_LOGIN - isSuccessed => ", isSuccessed);

            return  {
                ...state,
                isInvalidRoute : true,
                list_qrLoginMember : []
            }
        }
    },

    [GET_LIVE_INFO_BY_COOKIES_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        // console.log("GET_LIVE_INFO_BY_COOKIES - ", action.payload);

        if (isSuccessed) {
            const { live_info } = action.payload;

            let liveInfo = {
                liveSeq : -1,
                liveName : ""
            };

            if (live_info) {
                liveInfo = {
                    liveSeq : live_info.LIVE_SEQ,
                    liveName : live_info.LIVE_NAME
                };

                return {
                    ...state,
                    cookiesLiveInfo : liveInfo
                }
            } else {
                console.log("GET_LIVE_INFO_BY_COOKIES - live_info => ", live_info);

                return  {
                    ...state
                }
            }
        } else {
            console.log("GET_LIVE_INFO_BY_COOKIES - isSuccessed => ", isSuccessed);

            return  {
                ...state
            }
        }
    },

    [INIT_QR_LIVE_INFO]: (state, action) => {
        console.log("INIT_QR_LIVE_INFO");

        return {
            ...state,
            qrLiveInfo      : {
                liveSeq     : -1,
                liveName    : ""
            }
        }
    },

    [HICLASSTV_VERSION_INFO_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        // console.log("HICLASSTV_VERSION_INFO - ", action.payload);

        if (isSuccessed) {
            const { version } = action.payload;

            if (version && version !== "") {
                callEoForSendAction("buildVersionInfo", { version: version });

                return {
                    ...state,
                    isCheckedVersion    : true,
                    version_info        : version
                }
            } else {
                console.log("HICLASSTV_VERSION_INFO - version => ", version);

                return  {
                    ...state,
                    isCheckedVersion    : true
                }
            }
        } else {
            console.log("HICLASSTV_VERSION_INFO - isSuccessed => ", isSuccessed);

            return  {
                ...state,
                isCheckedVersion    : true
            }
        }
    },

    [HICLASSTV_DOWNLOAD_INFO_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("HICLASSTV_DOWNLOAD_INFO - ", action.payload);

        if (isSuccessed) {
            const { info_download } = action.payload;

            if (info_download) {
                return {
                    ...state,
                    download_info: {
                        pentalk_info: { version: info_download.PENTALK_VER, updateDT: info_download.PENTALK_UPDT },
                        pentalkWifi_info: { version: info_download.PENTALK_WIFI_VER, updateDT: info_download.PENTALK_WIFI_UPDT },
                        pentalkBoard_info: { version: info_download.PENTALK_BOARD_VER, updateDT: info_download.PENTALK_BOARD_UPDT },
                        pentalkManual_info: { version: info_download.PENTALK_VER, updateDT: info_download.PENTALK_MN_UPDT },
                        pentalkCloudManual_info: { version: info_download.PENTALK_VER, updateDT: info_download.PENTALK_CLOUD_MN_UPDT },
                        pentalkWifiManual_info: { version: info_download.PENTALK_WIFI_VER, updateDT: info_download.PENTALK_WIFI_MN_UPDT },
                        pencam_info: { version: info_download.PENCAM_VER, updateDT: info_download.PENCAM_UPDT },
                        penMgr_info: { version: info_download.PEN_MGR_VER, updateDT: info_download.PEN_MGR_UPDT }
                    }
                }
            } else {
                console.log("HICLASSTV_DOWNLOAD_INFO - info_download => ", info_download);

                return  {
                    ...state
                }
            }
        } else {
            console.log("HICLASSTV_DOWNLOAD_INFO - isSuccessed => ", isSuccessed);

            return  {
                ...state
            }
        }
    },

    [TOGGLE_NOTICE_MODAL]: (state, action) => {
        const { status } = action.payload;
        // console.log("TOGGLE_NOTICE_MODAL - ", action.payload);

        return {
            ...state,
            isNoticeModalOpened : status
        }
    },

    [TOGGLE_RETRY_LOGIN_MODAL]: (state, action) => {
        const { status } = action.payload;
        // console.log("TOGGLE_RETRY_LOGIN_MODAL - ", action.payload);

        return {
            ...state,
            isRetryLoginModalOpened : status
        }
    },

    [CHANGE_WINDOW_PENTALK_BOARD]: (state, action) => {
        // console.log("CHANGE_WINDOW_PENTALK_BOARD - ");

        callEoForSendAction("switchPentalkBoardWindow", "");

        return{
            ...state
        }
    },

    // REMOCON
    [SET_AUDIO_MIXER_STATUS]: (state, action) => {
        const { status } = action.payload;
        // console.log("SET_AUDIO_MIXER_STATUS - ", action.payload);

        if (status) {
            const subMic = state.myMicInfo.list_mic.find(info => info.label === "Pentalk Audio Mixer");
            if (subMic) {
                let idx = state.myMicInfo.list_mic.indexOf(subMic);
                callEoForSelectSoundDeviceInfo({ kind: "changeAudioInfo", command: "mySubMicInfo", value: subMic.deviceId });

                return {
                    ...state,
                    myMicInfo   : {
                        ...state.myMicInfo,
                        selectedIndex: idx
                    }
                }
            } else {
                console.log("SET_AUDIO_MIXER_STATUS - There isn't exist [Pentalk Audio Mixer]");

                return{
                    ...state,
                    myMicInfo   : {
                        ...state.myMicInfo,
                        selectedIndex: -1
                    }
                }
            }
        } else {
            if (state.myMicInfo.list_mic[state.myMicInfo.selectedIndex].label === "Pentalk Audio Mixer") {
                const subMic = state.myMicInfo.list_mic.find(info => info.deviceId === "default");
                if (subMic) {
                    let idx = state.myMicInfo.list_mic.indexOf(subMic);
                    callEoForSelectSoundDeviceInfo({ kind: "changeAudioInfo", command: "mySubMicInfo", value: subMic.deviceId });

                    return {
                        ...state,
                        myMicInfo   : {
                            ...state.myMicInfo,
                            selectedIndex: idx
                        }
                    }
                } else {
                    console.log("SET_AUDIO_MIXER_STATUS - There isn't exist [Default - Mic Info]");

                    return{
                        ...state,
                        myMicInfo   : {
                            ...state.myMicInfo,
                            selectedIndex: -1
                        }
                    }
                }
            } else {
                return {
                    ...state
                }
            }
        }
    },

    [SET_MY_MIC_ECHO_STATUS]: (state, action) => {
        const { kind, value } = action.payload;
        // console.log("SET_MY_MIC_ECHO_STATUS - ", action.payload);

        callEoForSelectSoundDeviceInfo({ kind, command: "mySubMicInfo", value });

        return {
            ...state,
            isMyMicEchoEnable : value
        }
    },

    [SET_TEAMUP_SOUND_DEVICE_INFO]: (state, action) => {
        const { audioInputInfo, audioOutputInfo } = action.payload;
        // console.log("SET_TEAMUP_SOUND_DEVICE_INFO - ", action.payload);

        const inputInfo = {
            selectedIndex: -1,
            list_mic: [],
            volume: state.myMicInfo.volume
        };

        const outputInfo = {
            selectedIndex: -1,
            list_speaker: [],
            volume: state.teampUpSpeakerInfo.volume
        };

        if (audioInputInfo) {
            inputInfo.selectedIndex = audioInputInfo.selectedIndex;
            inputInfo.list_mic = audioInputInfo.audioInputList ? audioInputInfo.audioInputList : [];
        }

        if (audioOutputInfo) {
            outputInfo.selectedIndex = audioOutputInfo.partnerSelectedIndex;
            outputInfo.list_speaker = audioOutputInfo.audioOutputList ? audioOutputInfo.audioOutputList : [];
        }

        return {
            ...state,
            myMicInfo           : inputInfo,    // 20220607 by hjkim
            teampUpSpeakerInfo  : outputInfo
        }
    },

    [SET_HANDSUP_SOUND_DEVICE_INFO]: (state, action) => {
        const { audioOutputInfo } = action.payload;
        // console.log("SET_HANDSUP_SOUND_DEVICE_INFO - ", action.payload);

        const outputInfo = {
            selectedIndex: -1,
            list_speaker: [],
            volume: state.handsUpSpeakerInfo.volume
        };
        
        if (audioOutputInfo) {
            outputInfo.selectedIndex = audioOutputInfo.partnerSelectedIndex;
            outputInfo.list_speaker = audioOutputInfo.audioOutputList ? audioOutputInfo.audioOutputList : [];
        }

        return {
            ...state,
            handsUpSpeakerInfo  : outputInfo    // 20220607 by hjkim
        }
    },

    [SETTING_SOUND_DEVICE_OPEN]: (state, action) => {
        // console.log("SETTING_SOUND_DEVICE_OPEN - ");

        return {
            ...state,
            isSettingDeviceOpened : !state.isSettingDeviceOpened
        }
    },

    [SELECT_SOUND_DEVICE]: (state, action) => {
        const { selectedFunc, kind, value, idx } = action.payload;
        // console.log("SELECT_SOUND_DEVICE - ", action.payload);

        if (selectedFunc === "SET_MY_SUB_MIC") {
            callEoForSelectSoundDeviceInfo({ kind, command: "mySubMicInfo", value });

            let isNeedAlert = false;
            const micInfo = state.myMicInfo.list_mic[idx];
            if (micInfo) {
                let partnerIdx = state.teampUpSpeakerInfo.selectedIndex;
                const partnerSpeakerInfo = state.teampUpSpeakerInfo.list_speaker[partnerIdx];

                if (partnerSpeakerInfo) {
                    if (micInfo.groupId === partnerSpeakerInfo.groupId) {
                        // console.log("SELECT_SOUND_DEVICE - micInfo.groupId same partnerSpeakerInfo.groupId");
                        isNeedAlert = true;
                    } else {
                        // console.log("SELECT_SOUND_DEVICE - micInfo.groupId different partnerSpeakerInfo.groupId");
                        const partnerSpeakerInfo_2 = state.teampUpSpeakerInfo.list_speaker.find(info => info.groupId === micInfo.groupId);
                        if (partnerSpeakerInfo_2) {
                            let partnerLabel = partnerSpeakerInfo_2.label;
                            let handsUpSpeakerIdx = state.handsUpSpeakerInfo.selectedIndex;
                            const handsUpSpeakerInfo = state.handsUpSpeakerInfo.list_speaker[handsUpSpeakerIdx];

                            if (handsUpSpeakerInfo) {
                                if (handsUpSpeakerInfo.label === partnerLabel) {
                                    // console.log("SELECT_SOUND_DEVICE - micInfo.groupId same handsUpSpeakerInfo.groupId (use label)");
                                    isNeedAlert = true;
                                } else {
                                    console.log("SELECT_SOUND_DEVICE - micInfo.groupId different handsUpSpeakerInfo.groupId (use label)");
                                }
                            }
                        }
                    }
                }
            }

            if (isNeedAlert) {
                return {
                    ...state,
                    myMicInfo   : {
                        ...state.myMicInfo,
                        selectedIndex: idx
                    },
                    isSoundAlertModalOpened : true,
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    alertMessage            : "마이크와 스피커를<br>동일한 기기로 설정하여<br>사용하는 경우<br>소리 울림 현상이<br>발생할 수 있습니다."
                }
            } else {
                return {
                    ...state,
                    myMicInfo   : {
                        ...state.myMicInfo,
                        selectedIndex: idx
                    }
                }
            }
        } else if (selectedFunc === "SET_PARTNER_SPEAKER") {
            callEoForSelectSoundDeviceInfo({ kind, command: "partnerSpeakerInfo", target: "teamup", value });

            let isNeedAlert = false;
            const partnerSpeakerInfo = state.teampUpSpeakerInfo.list_speaker[idx];
            if (partnerSpeakerInfo) {
                let micIdx = state.myMicInfo.selectedIndex;
                const micInfo = state.myMicInfo.list_mic[micIdx];
                if (micInfo) {
                    if (partnerSpeakerInfo.groupId === micInfo.groupId) {
                        // console.log("SELECT_SOUND_DEVICE - partnerSpeakerInfo.groupId same micInfo.groupId");
                        isNeedAlert = true;
                    } else {
                        console.log("SELECT_SOUND_DEVICE - partnerSpeakerInfo.groupId different micInfo.groupId");
                    }
                }
            }

            if (isNeedAlert) {
                return {
                    ...state,
                    teampUpSpeakerInfo  : {
                        ...state.teampUpSpeakerInfo,
                        selectedIndex: idx
                    },
                    isSoundAlertModalOpened : true,
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    alertMessage            : "마이크와 스피커를<br>동일한 기기로 설정하여<br>사용하는 경우<br>소리 울림 현상이<br>발생할 수 있습니다."
                }
            } else {
                return {
                    ...state,
                    teampUpSpeakerInfo  : {
                        ...state.teampUpSpeakerInfo,
                        selectedIndex: idx
                    }
                }
            }
        } else if (selectedFunc === "SET_HANDSUP_PARTNER_SPEAKER") {
            callEoForSelectSoundDeviceInfo({ kind, command: "partnerSpeakerInfo", target: "handsup", value });

            let isNeedAlert = false;
            const handsUpSpeakerInfo = state.handsUpSpeakerInfo.list_speaker[idx];
            if (handsUpSpeakerInfo) {
                const partnerSpeakerInfo = state.teampUpSpeakerInfo.list_speaker.find(info => info.label === handsUpSpeakerInfo.label);
                if (partnerSpeakerInfo) {
                    let micIdx = state.myMicInfo.selectedIndex;
                    const micInfo = state.myMicInfo.list_mic[micIdx];
                    if (partnerSpeakerInfo.groupId === micInfo.groupId) {
                        // console.log("SELECT_SOUND_DEVICE - handsUpSpeakerInfo.groupId same micInfo.groupId (use label)");
                        isNeedAlert = true;
                    } else {
                        console.log("SELECT_SOUND_DEVICE - handsUpSpeakerInfo.groupId different micInfo.groupId (use label)");
                    }
                }
            }

            if (isNeedAlert) {
                return {
                    ...state,
                    handsUpSpeakerInfo  : {
                        ...state.handsUpSpeakerInfo,
                        selectedIndex: idx
                    },
                    isSoundAlertModalOpened : true,
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    alertMessage            : "마이크와 스피커를<br>동일한 기기로 설정하여<br>사용하는 경우<br>소리 울림 현상이<br>발생할 수 있습니다."
                }
            } else {
                return {
                    ...state,
                    handsUpSpeakerInfo  : {
                        ...state.handsUpSpeakerInfo,
                        selectedIndex: idx
                    }
                }
            }
        } else {
            console.log("SELECT_SOUND_DEVICE - selectedFunc => ", selectedFunc);
            return {
                ...state
            }
        }
    },

    [CHANGE_SOUND_VOLUME]: (state, action) => {
        const { selectedFunc, kind, value } = action.payload;
        // console.log("CHANGE_SOUND_VOLUME - ", action.payload);

        if (selectedFunc === "SET_MY_SUB_MIC") {
            callEoForSelectSoundDeviceInfo({ kind, command: "mySubMicInfo", value });
            return {
                ...state,
                myMicInfo   : {
                    ...state.myMicInfo,
                    volume: value
                }
            }
        } else if (selectedFunc === "SET_PARTNER_SPEAKER") {
            callEoForSelectSoundDeviceInfo({ kind, command: "partnerSpeakerInfo", target: "teamup", value });
            return {
                ...state,
                teampUpSpeakerInfo  : {
                    ...state.teampUpSpeakerInfo,
                    volume: value
                }
            }
        } else if (selectedFunc === "SET_HANDSUP_PARTNER_SPEAKER") {
            callEoForSelectSoundDeviceInfo({ kind, command: "partnerSpeakerInfo", target: "handsup", value });
            return {
                ...state,
                handsUpSpeakerInfo  : {
                    ...state.handsUpSpeakerInfo,
                    volume: value
                }
            }
        } else {
            console.log("CHANGE_SOUND_VOLUME - selectedFunc => ", selectedFunc);
            return {
                ...state
            }
        }
    },

    [TOGGLE_SOUND_ALERT_MODAL]: (state, action) => {
        // console.log("TOGGLE_SOUND_ALERT_MODAL - ", action.payload);

        return {
            ...state,
            isSoundAlertModalOpened : false,
            alertMessage            : "",
            alertKind               : ConstData.NONE
        }
    },

    [CHANGE_REMOCON_TAP]: (state, action) => {
        // console.log("CHANGE_REMOCON_TAP - ", action.payload);

        return {
            ...state,
            remoconCMD : action.payload
        }
    },

    [INIT_ALL_CHECK_STATUS]: (state, action) => {
        // console.log("INIT_ALL_CHECK_STATUS - ");

        return {
            ...state,
            isInkAllCheck        : false,
            isVoiceAllCheck      : false,
            isScreenAllCheck     : false,
            isPullScreenAllCheck : false,
            isPushScreenAllCheck : false,
        }
    },

    [CHANGE_SHOW_PASS_KIND]: (state, action) => {
        const { selectedFunc } = action.payload;
        // console.log("CHANGE_SHOW_PASS_KIND - ", action.payload);
        let selectedTap = enPassTap.PRDT_LIST;

        if (selectedFunc === "SHOW_PASS_PRDT_LIST") {
            selectedTap = enPassTap.PRDT_LIST;
        } else if (selectedFunc === "SHOW_PASS_PCHS_LIST") {
            selectedTap = enPassTap.PCHS_LIST;
        } else if (selectedFunc === "SHOW_PASS_REFUND_LIST") {
            selectedTap = enPassTap.REFUND_LIST;
        }

        return {
            ...state,
            currentPassTap      : selectedTap,
            isShowAllMenu       : false
        }
    },

    [CHANGE_SHOW_INFO_KIND]: (state, action) => {
        const { selectedFunc } = action.payload;
        // console.log("CHANGE_SHOW_INFO_KIND - ", action.payload);
        let selectedTap = enInfoTap.Home;

        if (selectedFunc === "SHOW_HOME") {
            selectedTap = enInfoTap.Home;
        } else if (selectedFunc === "SHOW_EDUTECH") {
            selectedTap = enInfoTap.Edutech;
        } else if (selectedFunc === "SHOW_CONTENTS") {
            selectedTap = enInfoTap.Contents;
        } else if (selectedFunc === "SHOW_GREEN_SMART") {
            selectedTap = enInfoTap.SmartSheet;
        } else if (selectedFunc === "SHOW_DOWNLOAD") {
            selectedTap = enInfoTap.Download;
        } else if (selectedFunc === "SHOW_QNA") {
            selectedTap = enInfoTap.FAQ;
        } else if (selectedFunc === "GOTO_PROMOTION") {
            selectedTap = enInfoTap.Event;
        } else if (selectedFunc === "SHOW_LINK") {
            selectedTap = enInfoTap.LINK;
        }

        return {
            ...state,
            currentInfoTap      : selectedTap,
            isShowAllMenu       : false
        }
    },

    [CHANGE_SHOW_ADMIN_KIND]: (state, action) => {
        const { selectedFunc } = action.payload;
        // console.log("CHANGE_SHOW_ADMIN_KIND - ", action.payload);
        let selectedTap = enAdminTap.Home;

        if (selectedFunc === "SHOW_ADMIN_HOME") {
            selectedTap = enAdminTap.Home;
        } else if (selectedFunc === "SHOW_ADMIN_LECTURE") {
            selectedTap = enAdminTap.Lecture;
        } else if (selectedFunc === "SHOW_ADMIN_LOCAL_SERVER") {
            selectedTap = enAdminTap.LocalServer;
        } else if (selectedFunc === "SHOW_ADMIN_LOCAL_SITE") {
            selectedTap = enAdminTap.LocalSite;
        } else if (selectedFunc === "SHOW_ADMIN_LITE_SERVER") {
            selectedTap = enAdminTap.LiteLocalServer;
        } else if (selectedFunc === "SHOW_ADMIN_STUDIO") {
            selectedTap = enAdminTap.Studio;
        } else if (selectedFunc === "SHOW_ADMIN_MEDIA") {
            selectedTap = enAdminTap.Media;
        }

        return {
            ...state,
            currentAdminTap     : selectedTap,
            isShowAllMenu       : false
        }
    },

    [CHANGE_SHOW_MENU_KIND]: (state, action) => {
        const { selectedFunc } = action.payload;
        // console.log("CHANGE_SHOW_MENU_KIND - ", action.payload);

        if (selectedFunc === "SHOW_HEADER_BIG_MENU") {
            return {
                ...state,
                isShowAllMenu : !state.isShowAllMenu
            }
        } else {
            return {
                ...state,
                isShowAllMenu : false
            }
        }            
    },

    /**********************************************************/
    /////////////////////// LOCAL SERVER ///////////////////////
    /**********************************************************/

    [CHECK_LOCAL_SERVER_AUTH_CODE_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        // console.log("CHECK_LOCAL_SERVER_AUTH_CODE - ", action.payload);

        if (isSuccessed) {
            const { isExist } = action.payload;

            if (isExist) {
                const { localServerSEQ, localServerName } = action.payload;

                return {
                    ...state,
                    checkAuthCodeResult     : {
                        localServerSeq      : localServerSEQ,
                        localServerName     : localServerName,
                        isCorrectAuthCode   : true,
                        alertKind           : ConstData.ALERT_KIND.SUCCESS,
                        alertMessage        : "인증에 성공하였습니다.<br>[확인]을 누르면 설정이 완료됩니다."
                    }
                }
            } else {
                return {
                    ...state,
                    checkAuthCodeResult     : {
                        localServerSeq      : -1,
                        localServerName     : null,
                        isCorrectAuthCode   : false,
                        alertKind           : ConstData.ALERT_KIND.DANGER,
                        alertMessage        : "유효하지 않은 인증코드입니다.<br>인증코드를 확인하세요."
                    }
                }
            }
        } else {
            return {
                ...state,
                checkAuthCodeResult     : {
                    localServerSeq      : -1,
                    localServerName     : null,
                    isCorrectAuthCode   : false,
                    alertKind           : ConstData.ALERT_KIND.DANGER,
                    alertMessage        : "인증코드 조회에 실패했습니다.<br>인증코드를 확인하세요."
                }
            }
        }
    },

    [GET_LOCAL_SERVER_INFO_SUCCESS]: (state, action) => {
        const { localServerSeq } = action.parms;
        const { isSuccessed } = action.payload;
        // console.log("GET_LOCAL_SERVER_INFO - ", action.payload);

        if (isSuccessed) {
            const { local_server_info } = action.payload;

            if (local_server_info) {
                /* if (local_server_info.LOGIN_YN === "N") {

                } else {
                    const info = {
                        isCloud             : false,
                        serverName          : local_server_info.LOCAL_SERVER_NM,
                        serverIP            : local_server_info.IP_ADDRESS === "localhost:3000" ?
                                            "http://" + local_server_info.IP_ADDRESS :
                                            "https://" + local_server_info.IP_ADDRESS,
                        alwaysCloudStatus   : state.localServerInfo.alwaysCloudStatus
                    };
        
                    let newURL = new URL(info.serverIP);
                    callEoForSendAction("changeIp", { ip: newURL.host, queryString: "", localServerSeq });
    
                    return {
                        ...state,
                        localServerInfo     : info
                    }
                } */

                const info = {
                    isCloud             : false,
                    serverName          : local_server_info.LOCAL_SERVER_NM,
                    serverIP            : local_server_info.IP_ADDRESS === "localhost:3000" ?
                                        "http://" + local_server_info.IP_ADDRESS :
                                        "https://" + local_server_info.IP_ADDRESS,
                    alwaysCloudStatus   : state.localServerInfo.alwaysCloudStatus
                };

                let newURL = new URL(info.serverIP);
                callEoForSendAction("changeIp", { ip: newURL.host, queryString: "", localServerSeq });

                return {
                    ...state,
                    localServerInfo     : info
                }
            } else {
                console.log("GET_LOCAL_SERVER_INFO - local_server_info => ", local_server_info);

                return {
                    ...state
                }
            }
        } else {
            console.log("GET_LOCAL_SERVER_INFO - ", action.payload);

            return {
                ...state
            }
        }
    },

    [GET_CLOUD_SERVER_INFO]: (state, action) => {
        const { cloudHost, alwaysCloudStatus } = action.payload;
        // console.log("GET_CLOUD_SERVER_INFO - ", action.payload);

        let newURL = new URL(cloudHost);
        if (alwaysCloudStatus === true) {
            let queryString = `alwaysCloudStatus=${alwaysCloudStatus.toString()}`;
            callEoForSendAction("changeIp", { ip: newURL.host, queryString, localServerSeq: -1 });
        } else {
            let queryString = `alwaysCloudStatus=${alwaysCloudStatus.toString()}`;
            callEoForSendAction("changeIp", { ip: newURL.host, queryString, localServerSeq: -1 });
        }

        return {
            ...state,
            localServerInfo     : {
                isCloud             : true,
                serverName          : "",
                serverIP            : newURL,
                alwaysCloudStatus   : alwaysCloudStatus
            }
        }
    },

    [TOGGLE_SETTING_LOCAL_INFO]: (state, action) => {
        // console.log("TOGGLE_SETTING_LOCAL_INFO - ");

        return {
            ...state,
            isSettingLocalInfoModalOpened   : !state.isSettingLocalInfoModalOpened,
            checkAuthCodeResult             : {
                isCorrectAuthCode           : false,
                localServerSeq              : -1,
                localServerName             : null,
                alertMessage                : "",
                alertKind                   : ConstData.ALERT_KIND.NONE
            }
        }
    },

    /**********************************************************/
    /////////////////////// Android App ///////////////////////
    /**********************************************************/
    [CONNECTED_ON_SCREEN_APP]: (state, action) => {
        // console.log("CONNECTED_ON_SCREEN_APP - ");
        return {
            ...state,
            isConnectedOnScreenApp  : true
        }
    },

    [DISCONNECTED_ON_SCREEN_APP]: (state, action) => {
        // console.log("DISCONNECTED_ON_SCREEN_APP - ");
        return {
            ...state,
            isConnectedOnScreenApp  : false
        }
    },

    [CONNECTED_ON_SCREEN_APP_ARR]: (state, action) => {
        const { userSeq, idx } = action.payload;
        console.log("CONNECTED_ON_SCREEN_APP_ARR - ", action.payload);

        return produce(state, draft => {
            /* if (typeof idx === "number") {
                draft.list_isConnectedOnScreenApp[idx] = true;
            } */

            const connectedOnScreenApp_info = draft.list_isConnectedOnScreenApp.find(info => info.userSeq === userSeq);
            if (connectedOnScreenApp_info) {
                connectedOnScreenApp_info.isConnectedOnScreenApp = true;
            }
        });
    },

    [DISCONNECTED_ON_SCREEN_APP_ARR]: (state, action) => {
        const { userSeq, idx } = action.payload;
        console.log("DISCONNECTED_ON_SCREEN_APP_ARR - ", action.payload);

        return produce(state, draft => {
            /* if (typeof idx === "number") {
                draft.list_isConnectedOnScreenApp[idx] = false;
            } */

            const connectedOnScreenApp_info = draft.list_isConnectedOnScreenApp.find(info => info.userSeq === userSeq);
            if (connectedOnScreenApp_info) {
                connectedOnScreenApp_info.isConnectedOnScreenApp = false;
            }
        });
    },

    [SET_SMART_TV_GROUP_MEMBER]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("SET_SMART_TV_GROUP_MEMBER - ", action.parms);

        if (isSuccessed) {
            const { isLoginSuccess } = action.payload;

            if (isLoginSuccess) {   // 로그인에 성공한 경우
                const { list_member } = action.parms;

                return produce(state, draft => {
                    // draft.list_isConnectedOnScreenApp = new Array(list_member.length);
                    if (list_member) {
                        list_member.forEach((member_info, idx) => {
                            draft.list_isConnectedOnScreenApp[idx] = {
                                userSeq: member_info.userSeq,
                                userNickname: member_info.userNickname,
                                isConnectedOnScreenApp: member_info.isConnectedOnScreenApp,
                            };
                            /* draft.list_isConnectedOnScreenApp[idx].userSeq = member_info.userSeq;
                            draft.list_isConnectedOnScreenApp[idx].userNickname = member_info.userNickname;
                            draft.list_isConnectedOnScreenApp[idx].isConnectedOnScreenApp = false; */
                        });
                    }
                });
            } else { // 로그인 시도를 여러번 하는 경우, 데이터 값 엉킴 문제 처리 부분
                return {
                    ...state,
                }
            }
        } else {
            return {
                ...state,
                list_isConnectedOnScreenApp: [     // 그룹 = 4개
                    { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
                    { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
                    { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
                    { userSeq: -1, userNickname: "", isConnectedOnScreenApp: false },
                ],
            }
        }
    },
}, initialState);

export default hiclasstv;