import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

const handlePreventClick = (e) => {
    e.stopPropagation();
};

const AlertStudio = ({ userSeq, studio_info, list_live, handleFunc }) => {
    
    const [onAirLiveList, setOnAirLiveList] = useState([]);

    useEffect(() => {
        if (list_live !== undefined && list_live !== null) {
            const list_onAirLive = list_live.filter(info => info.liveStatus === 'Y');
            if (list_onAirLive !== undefined && list_onAirLive !== null) {
                setOnAirLiveList(list_onAirLive);
            }
        }
    }, []);

    return (
        studio_info !== undefined && studio_info !== null &&
            <div onClick={handlePreventClick}>
                <div className="d-flex justify-content-end w-100">
                    <button className="hiclasstv-btn b-none p-0" onClick={() => handleFunc({ selectedFunc: "CONFIRM_ALERT" })}>
                        <FaTimes className="d-flex m-1 black-text" />
                    </button>
                </div>
                {
                    studio_info.assignedUserSeq === userSeq ? 
                        <div className="d-flex text-center my-2 dark-grey-text small">
                            스튜디오와 연결을 해제하시겠습니까?<br />
                            해제 시 모든 클래스 방송이 종료됩니다.
                        </div> :
                        <div className="d-flex text-center my-2 dark-grey-text small">
                            스튜디오와 연결할 수 없습니다.<br />
                            이미 다른 사용자가 사용 중인 스튜디오입니다.
                        </div>
                }
                <div className="content-area dark-grey-text">
                    <span className="small mb-1">[스튜디오 이름]</span>
                    <div className="text-area text-center area-80">
                        <span className="hiclass-text" title={studio_info.studioName}>
                            <b>{studio_info.studioName}</b>
                        </span>
                    </div>
                    {
                        onAirLiveList.length > 0 ? 
                            <>
                                <span className="small mb-1">[OnAir 클래스]</span>
                                <div className="text-area text-center area-80">
                                    <span className="hiclass-text" title={onAirLiveList[0].liveName + '외 ' + (onAirLiveList.length - 1)}>
                                        <b>{onAirLiveList[0].liveName + '외 ' + (onAirLiveList.length - 1)}</b>
                                    </span>
                                </div>
                            </> : 
                            <></>
                    }
                </div>
                {
                    studio_info.assignedUserSeq === userSeq ? 
                        <div className="d-flex justify-content-center my-2">
                            <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => handleFunc({ selectedFunc: "DIS_CON_STUDIO", studio_info })}>확인</button>
                            <button type="button" className="btn btn-sm small red white-text m-0 ml-1 py-1 px-2" onClick={() => handleFunc({ selectedFunc: "CONFIRM_ALERT" })}>취소</button>
                        </div> :
                        <div className="d-flex justify-content-center my-2">
                            <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => handleFunc({ selectedFunc: "CONFIRM_ALERT" })}>확인</button>
                        </div>
                }
            </div>
    );
};

export default AlertStudio;