import React, { useEffect, useState } from "react";

const WebLectureInfo = ({ idx, lecture_info, handleSelectedFunc }) => {   
    return (
        <>
            <tr className="d-flex">
                <td className="d-flex align-items-center justify-content-center area-30">
                    <span>{lecture_info.lectureName}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-15">
                    <span>{lecture_info.lectureCode}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-10">
                    <span>{lecture_info.lectureKind}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-15">
                    <span>{lecture_info.lectureCrdntCode}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-10">
                    <span>{lecture_info.lectureType}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-10">
                    <span>{lecture_info.lectureStepNum}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-10">
                    조회
                </td>
            </tr>
        </>
    );
}
export default WebLectureInfo;