import React from 'react';
import { Modal } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

const ForceLogoutAlertModal = ({ isModalOpened, handleSelectedFunc }) => {
    return (
        <Modal isOpen={isModalOpened} backdrop={false} centered toggle={() => handleSelectedFunc({ selectedFunc: "FORCE_LOGOUT_ALERT_TOGGLE" })}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        <span>다른 브라우저에서 로그인 중입니다.</span>
                    </div>
                    <div className="content-area dark-gray-text">
                        <div className="text-area text-center">
                            <span>강제 로그아웃하고,</span>
                            <span>현재 브라우저에서 로그인하시겠습니까?</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <button type="button" className="btn btn-md green white-text py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "FORCE_LOG_OUT" })}>확인</button>
                        <button type="button" className="btn btn-md red white-text py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "FORCE_LOGOUT_ALERT_TOGGLE" })}>취소</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ForceLogoutAlertModal;