import React, { useState, useEffect, useRef } from "react";
import { Modal } from "reactstrap";
import { FaTimes, FaSignInAlt, FaUser, FaUserAlt, FaUserCheck, FaUserEdit, FaUserLock, FaLock, FaMobileAlt, FaKey, FaUserTag, FaMailBulk, FaPortrait, FaFeatherAlt, FaUserAstronaut, FaUserCircle, FaImage } from "react-icons/fa";
import { FaEnvelopeOpen, FaEnvelope, FaIdBadge, FaIdCardAlt, FaIdCard, FaRobot, FaTabletAlt, FaTag, FaTags, FaRegAddressCard, FaRegEnvelopeOpen, FaRegEnvelope, FaRegIdBadge, FaRegIdCard } from "react-icons/fa";

import ConstData from "../../lib/ConstData";
import { stUserType } from "../../lib/ConstCommand";

import ServicePolicy from "../etc/ServicePolicy";
import Alerts from "../etc/list/Alerts";

const UserRegist = ({ isInfoOpened, userInfo, userInfoAlertKind, userInfoMessage, handleSelectedFunc }) => {

    const timer = useRef();

    const [isAgreePolicy, setIsAgreePolicy] = useState(false);
    const [isRegistStep, setIsRegistStep] = useState(false);

    const [userID, setUserID] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [confirmNumber, setConfirmNumber] = useState("");
    const [userNickname, setUserNickname] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [emailConfirmNumber, setEmailConfirmNumber] = useState("");
    const [userProfile, setUserProfile] = useState("");
    const [userProfileImg, setUserProfileImg] = useState("1");
    const [userExtraCode, setUserExtraCode] = useState("");
    const [userType, setUserType] = useState("");
    const [isPhoneTestOK, setIsPhoneTestOK] = useState(false);
    const [isPhoneTestStart, setIsPhoneTestStart] = useState(false);
    const [isIdCheckOK, setIsIdCheckOK] = useState(false);
    const [isEmailTestStart, setIsEmailTestStart] = useState(false);
    const [isEmailTestOK, setIsEmailTestOK] = useState(false);
    const [modifyPhoneTestMode, setModifyPhoneTestMode] = useState(false);
    const [modifyEmailTestMode, setModifyEmailTestMode] = useState(false);

    const [isAliveCertifyTime, setIsAliveCertifyTime] = useState(false);
    const [certifyTime, setCertifyTime] = useState(0);

    const [alertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        if (isPhoneTestStart && !isAliveCertifyTime) {
            //handleSelectedFunc({selectedFunc:"FALSE_TO_PHONE_CHECK_SEND",message:"인증 가능 시간 2분이 초과되었습니다. 인증번호를 다시 발급받으세요."});
            handleSelectedFunc({ selectedFunc: "CHANGE_ALERT_MESSAGE", kind: "phoneCertification", message: "인증 가능 시간 2분이 초과되었습니다. 인증번호를 다시 발급받으세요." });
        }

        if (isEmailTestStart && !isAliveCertifyTime) {
            //handleSelectedFunc({selectedFunc:"FALSE_TO_EMAIL_CHECK_SEND",message:"인증 가능 시간 2분이 초과되었습니다. 인증번호를 다시 발급받으세요."});
            handleSelectedFunc({ selectedFunc: "CHANGE_ALERT_MESSAGE", kind: "emailCertification", message: "인증 가능 시간 2분이 초과되었습니다. 인증번호를 다시 발급받으세요." });
        }
    }, [isPhoneTestStart, isEmailTestStart, isAliveCertifyTime]);

    useEffect(() => {
        if (certifyTime > 0) {
            timer.current = setInterval(() => {
                setCertifyTime(c => c - 1);
            }, 1000);
        } else {
            if (certifyTime === -1) {

            } else {
                setIsAliveCertifyTime(false);
            }
        }
        if (timer.current !== null) {
            return () => clearInterval(timer.current);
        }
    }, [certifyTime]);

    useEffect(() => {
        setAlertKind(userInfoAlertKind);
        setAlertMessage(userInfoMessage);
    }, [userInfoAlertKind, userInfoMessage]);

    /* useEffect(() => {
        if (alertKind !== userInfoAlertKind) {
            setAlertKind(userInfoAlertKind);
        }
        setAlertMessage(userInfoMessage);
    }, [userInfoMessage]); */

    useEffect(() => {
        if (userInfo.isPhoneTestOK) {
            setCertifyTime(-1);
            setConfirmNumber("인증완료");
        }
        setIsPhoneTestOK(userInfo.isPhoneTestOK);
    }, [userInfo.isPhoneTestOK]);

    useEffect(() => {
        if (userInfo.isPhoneTestStart) {
            setCertifyTime(120);
            setIsAliveCertifyTime(true);
            setModifyPhoneTestMode(false);
        }
        setIsPhoneTestStart(userInfo.isPhoneTestStart);
    }, [userInfo.isPhoneTestStart]);

    useEffect(() => {
        if (userInfo.isEmailTestOK) {
            setCertifyTime(-1);
            setEmailConfirmNumber("인증완료");
        }
        setIsEmailTestOK(userInfo.isEmailTestOK);
    }, [userInfo.isEmailTestOK]);

    useEffect(() => {
        if (userInfo.isEmailTestStart) {
            setCertifyTime(120);
            setIsAliveCertifyTime(true);
            setModifyEmailTestMode(false);
        }
        setIsEmailTestStart(userInfo.isEmailTestStart)
    }, [userInfo.isEmailTestStart]);

    useEffect(() => {
        setIsIdCheckOK(userInfo.isIdCheckOK);
    }, [userInfo.isIdCheckOK]);

    const onChangeAgree = (e) => {
        setIsAgreePolicy(e.target.checked);
    }

    const agreeToPolicy = () => {
        setIsRegistStep(true);
    }

    const onChangeUserId = (e) => {
        if (userInfo.isIdCheckOK) {
            handleSelectedFunc({ selectedFunc: "CHANGE_ALERT_MESSAGE", kind: "idCheck", message: "아이디가 변경되었습니다. 아이디 중복체크를 다시 진행하세요." });
        }
        let str = e.target.value;
        str = str.replace(' ', '');
        if (str.length < 4 || str.length > 16) {
            if (str.length > 16) str = str.substr(0, 16);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("아이디는 4자~16자만 입력 가능합니다.");
        }
        setUserID(str);
    }

    const onChangePassword = (e) => {
        let isChecked = checkPasswordPattern(e.target.value);

        if (!isChecked) {
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("비밀번호는 8자리 이상 영문자, 숫자, 특수문자로 구성하여야 합니다.");
        } else {
            setAlertKind(ConstData.ALERT_KIND.SUCCESS);
            setAlertMessage("사용 가능한 비밀번호입니다.");
        }
        setPassword(e.target.value);
    }

    const onChangePasswordForConfirm = (e) => {
        let isChecked = checkPasswordPattern(e.target.value);

        if (password !== e.target.value) {
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("비밀번호가 일치하지 않습니다.");
        } else {
            if (!isChecked) {
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage("비밀번호는 8자리 이상 영문자, 숫자, 특수문자로 구성하여야 합니다.");
            } else {
                setAlertKind(ConstData.ALERT_KIND.NONE);
                setAlertMessage("");
            }
        }
        setConfirmPassword(e.target.value);
    }

    const onChangePhoneNumber = (e) => {
        if (userInfo.isPhoneTestOK || userInfo.isPhoneTestStart) {
            handleSelectedFunc({ selectedFunc: "CHANGE_ALERT_MESSAGE", kind: "phoneCertification", message: "휴대폰 번호가 변경되었습니다. 인증을 다시 진행해 주세요." });
        }

        let str = autoHypenPhoneNumber(e.target.value);
        setPhoneNumber(str);
    }

    const onChangeConfirmNumber = (e) => {
        let str = e.target.value;
        //str = str.replace(/[^0-9]/g, '');
        setConfirmNumber(str);
    }

    const onChangeEmailConfirmNumber = (e) => {
        let str = e.target.value;
        str = str.replace(/[^0-9]/g, '');
        setEmailConfirmNumber(str);
    }

    const onChangeUserNickname = (e) => {
        let str = e.target.value;
        str = str.replace(' ', '');
        if (str.length > 15) {
            str = str.substr(0, 15);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("닉네임은 15자 이내로 입력해야 합니다.");
        }
        setUserNickname(str);
    }

    const onChangeUserEmail = (e) => {
        if (userInfo.isEmailTestOK || userInfo.isEmailTestStart) {
            // email 인증 기능이 추가되면 주석해제하기 by hjkim 20230118
            // handleSelectedFunc({ selectedFunc: "CHANGE_ALERT_MESSAGE", kind: "emailCertification", message: "이메일이 변경되었습니다. 인증을 다시 진행해 주세요." });
        }

        let str = e.target.value;
        str = str.replace(' ', '');
        if (str.length > 50) {
            str = str.substr(0, 50);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("이메일은 50자 이내로 입력해야 합니다.");
        }
        setUserEmail(str);
    }

    const onChangeUserExtraCode = (e) => {
        let str = e.target.value;
        str = str.replace(' ', '');
        if (str.length > 50) {
            str = str.substr(0, 50);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("식별코드는 50자 이내로 입력해야 합니다.");
        }
        setUserExtraCode(str);
    }

    const onChangeUserType = (e) => {
        let str = e.target.value;
        setUserType(str);
    }

    const onChangeUserProfile = (e) => {
        let str = e.target.value;
        if (str.length > 30) {
            str = str.substr(0, 30);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("프로필은 30자 이내로 입력해야 합니다.");
        }
        setUserProfile(str);
    }

    const onChangeProfileImg = (e) => {
        setUserProfileImg(e.target.value);
    }

    const getDisp = () => {
        const ss = certifyTime % 60;
        const mm = Math.floor(certifyTime / 60);

        const str_s = "0" + parseInt(ss).toString();
        const str_m = "0" + parseInt(mm).toString();

        const str_time = str_m.substr(str_m.length - 2, 2) + ":" + str_s.substr(str_s.length - 2, 2);

        return str_time;
    }

    const checkInputValue = (str) => {
        let convertStr = str.replace(/[^a-z0-9@_.-]/gi, '');
        return convertStr;
    }

    const checkID = () => {
        if (userID === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "사용자 ID를 입력하세요." });
            return;
        }

        console.log("사용자 ID 이상 없음");
        handleSelectedFunc({ selectedFunc: "ID_CHECK", checkID: userID });

        /* if (userID === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "사용자 ID를 입력하세요." });
        } else {
            handleSelectedFunc({ selectedFunc: "ID_CHECK", checkID: userID });
            //handleIdCheck( {userID} );            //query로 넘겨줌
        } */
    }

    const checkPasswordValue = (str) => {
        let number_pattern = /[0-9]/;				        // 숫자
        let alpha_pattern = /[a-zA-Z]/;			            // 문자
        let special_pattern = /[~!@#$%^&*()_+|<>?:{}]/;     // 특수문자

        if (!number_pattern.test(str) || !alpha_pattern.test(str) || !special_pattern.test(str)) {
            return false;
        } else {
            return true;
        }
    }

    const checkPasswordPattern = (str) => {
        let pattern1 = /[0-9]/;                         // 숫자
        let pattern2 = /[a-zA-Z]/;			            // 문자
        let pattern3 = /[~!@#$%^&*()_+|<>?:{}]/;        // 특수문자

        if (!pattern1.test(str) || !pattern2.test(str) || !pattern3.test(str) || str.length < 8) {
            // alert("비밀번호는 8자리 이상 문자, 숫자, 특수문자로 구성하여야 합니다.");
            return false;
        } else {
            return true;
        }
    }

    const checkSpace = (str) => {
        if (str.search(/\s/) !== -1) {
            return true;
        } else {
            return false;
        }
    }

    const checkEmailAddress = () => {
        if (isEmailTestOK === true && userEmail !== "") {
            console.log("EMAIL_CHANHE_MODE");
            setModifyEmailTestMode(true);
            setEmailConfirmNumber("");
            handleSelectedFunc({ selectedFunc: "CHANGE_ALERT_MESSAGE", kind: "emailChange", message: "" });
            return;
        }

        if (userEmail === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 주소를 확인해 주세요." });
            return;
        }

        if (userEmail.indexOf("@") === -1) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
            return;
        }

        if (userEmail.indexOf(".") === -1) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
            return;
        }

        if (userEmail.indexOf(".") >= (userEmail.length - 2)) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
            return;
        }

        console.log("이메일 이상 없음");
        handleSelectedFunc({ selectedFunc: "EMAIL_CHECK", userEmail });

        /* if (userEmail === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 주소를 확인해 주세요." });
        } else {
            if (userEmail.indexOf("@") === -1) {
                //console.log("@ index : "+userEmail.indexOf("@"));
                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
            } else {
                if (userEmail.indexOf(".") === -1) {
                    handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
                } else {
                    if (userEmail.indexOf(".") >= (userEmail.length - 2)) {
                        handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
                    } else {
                        console.log("이메일 이상 없음");
                        handleSelectedFunc({ selectedFunc: "EMAIL_CHECK", userEmail });
                    }
                }
            }
        } */
    }

    const checkPhoneNumber = () => {
        if (isPhoneTestOK === true && phoneNumber !== "") {
            console.log("PHONE_CHANHE_MODE");
            setModifyPhoneTestMode(true);
            setConfirmNumber("");
            handleSelectedFunc({ selectedFunc: "CHANGE_ALERT_MESSAGE", kind: "phoneChange", message: "" });
            return;
        }

        if (phoneNumber === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "휴대폰 번호를 확인해 주세요." });
            return;
        }

        console.log("휴대폰 번호 이상 없음");
        handleSelectedFunc({ selectedFunc: "PHONE_CHECK", phoneNumber });

        /* if (phoneNumber === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "휴대폰 번호를 확인해 주세요." });
        } else {
            handleSelectedFunc({ selectedFunc: "PHONE_CHECK", phoneNumber });
            //handlePhoneCheckSend(phoneNumber);      //payload로 넘겨줌
        } */
    }

    const checkConfirmPhoneNumber = () => {
        if (!userInfo.isPhoneTestStart) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 먼저 수신해 주세요." });
            return;
        }

        if (confirmNumber === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 확인해 주세요." });
            return;
        }

        console.log("인증번호 이상 없음 for 휴대폰 번호");
        handleSelectedFunc({ selectedFunc: "PHONE_CHECK_CONFIRM", phoneNumber, confirmNumber });

        /* if (!userInfo.isPhoneTestStart) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 먼저 수신해 주세요." });
        } else {
            if (confirmNumber === "") {
                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 확인해 주세요." });
            } else {
                handleSelectedFunc({ selectedFunc: "PHONE_CHECK_CONFIRM", phoneNumber, confirmNumber });
                //handlePhoneCheckConfirm(phoneNumber, confirmNumber); //payload로 넘겨줌
            }
        } */
    }

    const checkEmailConfirmNumber = () => {
        if (!userInfo.isEmailTestStart) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 먼저 수신해 주세요." });
            return;
        }

        if (emailConfirmNumber === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 확인해 주세요." });
            return;
        }

        console.log("인증번호 이상 없음 for 이메일");
        handleSelectedFunc({ selectedFunc: "EMAIL_CHECK_CONFIRM", userEmail, confirmNumber: emailConfirmNumber });

        /* if (!userInfo.isEmailTestStart) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 먼저 수신해 주세요." });
        } else {
            if (emailConfirmNumber === "") {
                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "인증번호를 확인해 주세요." });
            } else {
                handleSelectedFunc({ selectedFunc: "EMAIL_CHECK_CONFIRM", userEmail, confirmNumber: emailConfirmNumber });
            }
        } */
    }

    const autoHypenPhoneNumber = (str) => {
        str = str.replace(/[^0-9]/g, '');
        var tmp = '';

        if (str.length < 4) {
            return str;
        } else if (str.length < 7) {
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3);
            return tmp;
        } else if (str.length < 11) {
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 3);
            tmp += '-';
            tmp += str.substr(6);
            return tmp;
        } else {
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 4);
            tmp += '-';
            tmp += str.substr(7);
            return tmp;
        }
    }

    const checkRegistUser = () => {
        if (userID === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "아이디를 입력해 주세요." });
            return;
        }

        if (!userInfo.isIdCheckOK) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "아이디 중복체크를 진행해 주세요." });
            return;
        }

        if (password === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "비밀번호를 입력해 주세요." });
            return;
        }

        if (!checkPasswordPattern(password)) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "비밀번호는 8자리 이상 영문자, 숫자, 특수문자로 구성하여야 합니다." });
            return;
        }

        if (password !== confirmPassword) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "비밀번호가 일치하지 않습니다." });
            return;
        }

        if (phoneNumber === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "휴대폰 번호를 입력해 주세요." });
            return;
        }

        if (!ConstData.IS_LOCAL_VERSION) {
            if (!userInfo.isPhoneTestOK) {
                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "휴대폰 번호 인증을 진행해 주세요." });
                return;
            }
        }

        if (userEmail === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 주소를 입력해 주세요." });
            return;
        }

        if (userEmail.indexOf("@") === -1) {
            //console.log("@ index : "+userEmail.indexOf("@"));
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
            return;
        }

        if (userEmail.indexOf(".") === -1) {
            //console.log(". index : "+userEmail.indexOf("."));
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
            return;
        }

        if (userEmail.indexOf(".") >= (userEmail.length - 2)) {
            //console.log(". index : "+userEmail.indexOf(".")+" / length : "+userEmail.length);
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
            return;
        }

        // email 인증 기능이 추가되면 주석해제하기 by hjkim 20230119
        /* if (!ConstData.IS_LOCAL_VERSION) {
            if (!userInfo.isEmailTestOK) {
                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 인증을 진행해 주세요." });
                return;
            }
        } */

        if (userNickname === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "닉네임을 입력해 주세요." });
            return;
        }

        if (userProfile === "") {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "프로필을 입력해 주세요." });
            return;
        }

        // 아무 이상 없는 경우 **임시: 매뉴얼 작업할 동안 userType 값 선택할 수 없으므로 고정 by hjkim 20230503
        console.log("회원가입 데이터 아무이상 없음");
        handleSelectedFunc({
            selectedFunc: "REGIST",
            registInfo: ({ userID, password, userNickname, userEmail, phoneNumber, userExtraCode, userProfile, profileImgUrl: userProfileImg, userType: stUserType.User })
        });

        /* if (!userInfo.isIdCheckOK) {
            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "아이디 먼저 중복체크를 진행해 주세요." });
        } else {
            if (password === "") {
                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "비밀번호를 입력해 주세요." });
            } else {
                if (!checkPasswordValue(password)) {
                    handleSelectedFunc({ selectedFunc: "MESSAGE", message: "비밀번호는 영문자, 숫자, 특수문자 조합을 입력해야 합니다." });
                } else {
                    if (password !== confirmPassword) {
                        handleSelectedFunc({ selectedFunc: "MESSAGE", message: "비밀번호가 일치하지 않습니다." });
                    } else {
                        if (!userInfo.isPhoneTestOK) {
                            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "휴대폰 번호 인증을 진행해 주세요." });
                        } else {
                            if (userNickname === "") {
                                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "닉네임을 입력해 주세요." });
                            } else {
                                if (userEmail === "") {
                                    handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 주소를 입력해 주세요." });
                                } else {
                                    if (userEmail.indexOf("@") === -1) {
                                        //console.log("@ index : "+userEmail.indexOf("@"));
                                        handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
                                    } else {
                                        console.log(". index : " + userEmail.indexOf("."));
                                        if (userEmail.indexOf(".") === -1) {
                                            //console.log(". index : "+userEmail.indexOf("."));
                                            handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
                                        } else {
                                            if (userEmail.indexOf(".") >= (userEmail.length - 2)) {
                                                //console.log(". index : "+userEmail.indexOf(".")+" / length : "+userEmail.length);
                                                handleSelectedFunc({ selectedFunc: "MESSAGE", message: "이메일 형식이 잘못되었습니다. 확인해 주세요." });
                                            } else {
                                                if (userProfile === "") {
                                                    handleSelectedFunc({ selectedFunc: "MESSAGE", message: "프로필을 입력해 주세요." });
                                                } else { // 아무 이상 없는 경우
                                                    console.log("아무이상 없음");
                                                    handleSelectedFunc({
                                                        selectedFunc: "REGIST",
                                                        registInfo: ({ userID, password, userNickname, userEmail, phoneNumber, userExtraCode, userProfile, profileImgUrl: userProfileImg })
                                                    });
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } */
    }

    const onCloseModal = () => {
        setIsAgreePolicy(false);
        setIsRegistStep(false);
        setUserID("");
        setPassword("");
        setConfirmPassword("");
        setPhoneNumber("");
        setConfirmNumber("");
        setUserNickname("");
        setUserEmail("");
        setUserProfile("");
        setUserProfileImg("1");
        setUserExtraCode("");
        setUserType("");
        setIsIdCheckOK(false);
        setIsPhoneTestOK(false);
        setIsPhoneTestStart(false);
        setIsEmailTestStart(false);
        setIsEmailTestOK(false);
        setEmailConfirmNumber("");
        setAlertKind(ConstData.ALERT_KIND.NONE);
        setAlertMessage("");
        if (timer && timer.current !== null) {
            return () => clearInterval(timer.current);
        }
        setCertifyTime(0);
    }

    return (
        <Modal isOpen={isInfoOpened} centered backdrop={true} onClosed={() => onCloseModal()}>
            {
                !isRegistStep ?
                    <div className="card container-fluid p-0 m-0">
                        <div className="card-body" style={{ fontSize: '0.9rem' }}>
                            <div className="d-flex w-100 justify-content-end align-items-center p-0 mb-3">
                                <button type="button" className="hiclasstv-button px-2 py-1" title="닫기" onClick={() => handleSelectedFunc({ selectedFunc: "REGIST_TOGGLE" })}>
                                    <FaTimes className="d-flex m-1" />
                                </button>
                            </div>
                            <div className="d-flex w-100 justify-content-center align-items-center">
                                <h3 className="m-0"><b>HiClass.TV 서비스 약관</b></h3>
                            </div>
                            <div className="d-flex w-100 justify-content-center align-items-center">
                                <div className="web-policy-box p-2 my-2 scroll-show">
                                    <ServicePolicy />
                                </div>
                            </div>
                            <div className="d-flex w-100 justify-content-center align-items-center mb-4">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="agree" onChange={onChangeAgree} />
                                    <label className="custom-control-label" htmlFor="agree">위 내용에 동의합니다.</label>
                                </div>
                            </div>
                            <div className="d-flex w-100 justify-content-center align-items-center my-2">
                                <button type="button" className="btn btn-md btn-primary" disabled={!isAgreePolicy} onClick={() => agreeToPolicy()}>
                                    다음
                                </button>
                                <button type="button" className="btn btn-md btn-danger" onClick={() => handleSelectedFunc({ selectedFunc: "REGIST_TOGGLE" })}>
                                    취소
                                </button>
                            </div>
                        </div>
                    </div> :
                    <div className="card container-fluid p-0 m-0">
                        <div className="card-body" style={{ fontSize: '0.9rem' }}>
                            <div className="d-flex w-100 justify-content-end align-items-center p-0 m-0">
                                <button type="button" className="hiclasstv-button px-2 py-1" data-toggle="tooltip" title="닫기" onClick={() => handleSelectedFunc({ selectedFunc: "REGIST_TOGGLE" })}>
                                    <FaTimes className="d-flex m-1" />
                                </button>
                            </div>
                            <div className="d-flex justify-content-center blue-text mb-1">
                                <FaSignInAlt className="font-xlg mr-2" data-toggle="tooltip" title="회원가입" />
                                <h3 className="m-0">회원가입:</h3>
                            </div>
                            <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                                <div className="d-flex align-items-center justify-content-center m-0 p-0" style={{ width: '10%' }}>
                                    <FaUserCheck className="font-md plus blue-text" data-toggle="tooltip" title="아이디" />
                                    {/* <FaUser className="font-md plus blue-text" data-toggle="tooltip" title="아이디" /> */}
                                </div>
                                <div className="d-flex m-0 p-0" style={{ width: '90%' }}>
                                    <input type="text" id="prefixInsideId" className="form-control" placeholder="아이디를 입력해 주세요." onChange={onChangeUserId} value={userID} />
                                    <div className="input-group-append" style={{ minWidth: 'max-content' }}>
                                        <button type="button" className={"btn btn-sm m-0 px-3" + (!isIdCheckOK ? " btn-primary" : " btn-blue-grey")} disabled={isIdCheckOK ? true : false} onClick={checkID}>
                                            {
                                                !isIdCheckOK ? "중복검사" : "검사완료"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex w-100 justify-content-end">
                                <span className="input-description">4자~16자, 영문, 숫자 사용</span>
                            </div>
                            <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                                <div className="d-flex m-0 p-0 align-items-center justify-content-center" style={{ width: '10%' }}>
                                    <FaUserLock className="font-md plus blue-text" data-toggle="tooltip" title="비밀번호" />
                                    {/* <FaLock className="font-md plus blue-text" data-toggle="tooltip" title="비밀번호" /> */}
                                </div>
                                <input type="password" id="prefixInsidePw" className="form-control" style={{ width: '90%' }} placeholder="비밀번호를 입력해 주세요." onChange={onChangePassword} value={password} />
                            </div>
                            <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                                <div className="d-flex m-0 p-0 align-items-center justify-content-center" style={{ width: '10%' }}>
                                    <FaUserLock className="font-md plus blue-text" data-toggle="tooltip" title="비밀번호 확인" />
                                    {/* <FaLock className="font-md plus blue-text" data-toggle="tooltip" title="비밀번호 확인" /> */}
                                </div>
                                <input type="password" id="prefixInsidePwConfirm" className="form-control" style={{ width: '90%' }} placeholder="비밀번호를 한 번 더 입력해 주세요." onChange={onChangePasswordForConfirm} value={confirmPassword} />
                            </div>
                            <div className="d-flex w-100 justify-content-end">
                                <span className="input-description">8자~20자, 영문, 숫자, 특수문자 사용</span>
                            </div>
                            {/* <div className="d-flex w-100 justify-content-end">
                                <span className="input-description">6자~20자, 영문, 숫자, 특수문자 사용</span>
                            </div> */}
                            <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                                <div className="d-flex m-0 p-0 align-items-center justify-content-center" style={{ width: '10%' }}>
                                    <FaMobileAlt className="font-md plus blue-text" data-toggle="tooltip" title="휴대폰 번호" />
                                </div>
                                <div className="d-flex m-0 p-0" style={{ width: '90%' }}>
                                    <input type="text" id="prefixInsidePn" maxLength={13} className="form-control" placeholder="휴대폰 번호를 입력해 주세요." disabled={(isPhoneTestStart || isPhoneTestOK) ? true : false} onChange={onChangePhoneNumber} value={phoneNumber} />
                                    {
                                        !ConstData.IS_LOCAL_VERSION &&
                                        <div className="input-group-append align-items-center" style={{ minWidth: 'max-content' }}>
                                            <button type="button" className={"btn btn-sm m-0 px-3" + ((!isPhoneTestStart || isPhoneTestOK) ? " btn-primary" : " btn-blue-grey")} disabled={isPhoneTestStart ? true : false} onClick={checkPhoneNumber}>
                                                {
                                                    isPhoneTestOK && !modifyPhoneTestMode ?
                                                        "변경하기" :
                                                    isPhoneTestOK && modifyPhoneTestMode ?
                                                        "인증하기" :
                                                    isPhoneTestStart ? "인증대기" : "인증하기"
                                                }
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                !isPhoneTestOK && isPhoneTestStart && isAliveCertifyTime &&
                                <div className="d-flex w-100 justify-content-end">
                                    <div className="input-description">
                                        <span className="pr-1">
                                            남은 시간 :
                                        </span>
                                        <span className="red-text">
                                            {getDisp()}
                                        </span>
                                    </div>
                                </div>
                            }
                            {
                                !ConstData.IS_LOCAL_VERSION && (isPhoneTestStart || isPhoneTestOK) && // local version에서는 휴대폰 번호 인증 과정 생략.. 클라우드에서도 인증 요청 시작했을 경우에만 인증번호가 보이게 수정 by hjkim 20230119
                                <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                                    <div className="d-flex m-0 p-0 align-items-center justify-content-center" style={{ width: '10%' }}>
                                        <FaKey className="font-md plus blue-text" data-toggle="tooltip" title="인증번호" />
                                    </div>
                                    <div className="d-flex m-0 p-0" style={{ width: '90%' }}>
                                        <input type="text" id="prefixInsideCd" className="form-control" placeholder="인증번호를 입력해 주세요." disabled={isPhoneTestOK ? true : false} onChange={onChangeConfirmNumber} value={confirmNumber} />
                                        {
                                            !isPhoneTestOK &&
                                            <div className="input-group-append" style={{ minWidth: 'max-content' }}>
                                                <button type="button" className={"btn btn-sm m-0 px-3" + ((!isPhoneTestStart || isPhoneTestOK) ? " btn-blue-grey" : " btn-primary")} disabled={(!isPhoneTestStart || isPhoneTestOK) ? true : false} onClick={checkConfirmPhoneNumber}>
                                                    인증확인
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                                <div className="d-flex m-0 p-0 align-items-center justify-content-center" style={{ width: '10%' }}>
                                    <FaEnvelope className="font-md plus blue-text" data-toggle="tooltip" title="이메일" />
                                    {/* <FaMailBulk className="font-md plus blue-text" data-toggle="tooltip" title="이메일" /> */}
                                </div>
                                <input type="email" id="prefixInsideEm" className="form-control" style={{ width: '90%' }} placeholder="이메일 주소를 입력해 주세요." disabled={(isEmailTestStart || isEmailTestOK ? true : false)} onChange={onChangeUserEmail} value={userEmail} />
                                { // email 인증 기능이 추가되면 주석해제하기 by hjkim 20230131
                                    /* !ConstData.IS_LOCAL_VERSION &&
                                    <div className="input-group-append align-items-center"  style={{ minWidth: 'max-content' }}>
                                        <button type="button" className={"btn btn-sm m-0 px-3" + ((!isEmailTestStart || isEmailTestOK) ? " btn-primary" : " btn-blue-grey")} disabled={isEmailTestStart ? true : false} onClick={checkEmailAddress}>
                                            {
                                                isEmailTestOK && !modifyEmailTestMode ?
                                                    "변경하기" :
                                                isEmailTestOK && modifyEmailTestMode ?
                                                    "인증하기" :
                                                isEmailTestStart ? "인증대기" : "인증하기"
                                            }
                                        </button>
                                    </div> */
                                }
                            </div>
                            { // email 인증 기능이 추가되면 주석해제하기 by hjkim 20230131
                                /* !isEmailTestOK && isEmailTestStart && isAliveCertifyTime &&
                                <div className="d-flex w-100 justify-content-end">
                                    <div className="input-description">
                                        <span className="pr-1">
                                            남은 시간 :
                                        </span>
                                        <span className="red-text">
                                            {getDisp()}
                                        </span>
                                    </div>
                                </div> */
                            }
                            { // email 인증 기능이 추가되면 주석해제하기 by hjkim 20230131
                                /* !ConstData.IS_LOCAL_VERSION && (isEmailTestStart || isEmailTestOK) && // local version에서는 이메일 인증 과정 생략.. 클라우드에서도 인증 요청 시작했을 경우에만 인증번호가 보이게 수정 by hjkim 20230119
                                <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                                    <div className="d-flex m-0 p-0 align-items-center justify-content-center" style={{ width: '10%' }}>
                                        <FaKey className="font-md plus blue-text" data-toggle="tooltip" title="인증번호" />
                                    </div>
                                    <div className="d-flex m-0 p-0" style={{ width: '90%' }}>
                                        <input type="text" id="prefixInsideEmCd" className="form-control" placeholder="인증번호를 입력해 주세요." disabled={isEmailTestOK ? true : false} onChange={onChangeEmailConfirmNumber} value={emailConfirmNumber} />
                                        {
                                            !isEmailTestOK &&
                                            <div className="input-group-append" style={{ minWidth: 'max-content' }}>
                                                <button type="button" className={"btn btn-sm m-0 px-3" + ((!isEmailTestStart || isEmailTestOK) ? " btn-blue-grey" : " btn-primary")} disabled={(!isEmailTestStart || isEmailTestOK) ? true : false} onClick={checkEmailConfirmNumber}>
                                                    인증확인
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div> */
                            }
                            <div className="d-flex w-100 justify-content-end">
                                <span className="input-description">50자 이내로 이메일 형식에 맞춰 입력 가능</span>
                            </div>
                            <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                                <div className="d-flex m-0 p-0 align-items-center justify-content-center" style={{ width: '10%' }}>
                                    <FaUserEdit className="font-md plus blue-text" data-toggle="tooltip" title="닉네임" />
                                    {/* <FaUserTag className="font-md plus blue-text" data-toggle="tooltip" title="닉네임" /> */}
                                </div>
                                <input type="text" id="prefixInsideNn" className="form-control" style={{ width: '90%' }} placeholder="닉네임을 입력해 주세요." onChange={onChangeUserNickname} value={userNickname} />
                            </div>
                            <div className="d-flex w-100 justify-content-end">
                                <span className="input-description">1자~15자, 한글, 영문, 숫자, 특수문자 사용 가능</span>
                            </div>
                            <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                                <div className="d-flex m-0 p-0 align-items-center justify-content-center" style={{ width: '10%' }}>
                                    <FaUserTag className="font-md plus blue-text" data-toggle="tooltip" title="프로필" />
                                    {/* <FaPortrait className="font-md plus blue-text" data-toggle="tooltip" title="프로필" /> */}
                                </div>
                                <input type="text" id="prefixInsidePp" className="form-control" style={{ width: '90%' }} placeholder="프로필을 입력해 주세요." onChange={onChangeUserProfile} value={userProfile} />
                            </div>
                            <div className="d-flex w-100 justify-content-end">
                                <span className="input-description">1자~30자, 한글, 영문, 숫자, 특수문자 사용 가능</span>
                            </div>
                            <div className="d-flex w-100 my-2 justify-content-between">
                                <div className="d-flex m-0 p-0 align-items-center justify-content-center" style={{ width: '10%' }}>
                                    <FaUserCircle className="font-md plus blue-text" data-toggle="tooltip" title="프로필 이미지" />
                                    {/* <FaImage className="font-md plus blue-text" data-toggle="tooltip" title="프로필 이미지" /> */}
                                </div>
                                <div className="m-0 p-0" style={{ width: '90%' }}>
                                    <div className="container m-0 p-0">
                                        <div className="row m-0 p-0">
                                            <div className="d-flex w-100 px-2 justify-content-between" style={{ overflow: 'auto' }}>
                                                <div className="d-flex mr-2 p-0 align-items-center">
                                                    <input className="d-flex mr-2 p-0" type="radio" name="profileImgs" id="profileImg1" value="1" onChange={onChangeProfileImg} defaultChecked />
                                                    <label className="d-flex m-0" htmlFor="profileImg1">
                                                        <img src={ConstData.IMG_DIR + "profile_01.png"} alt="" />
                                                    </label>
                                                </div>
                                                <div className="d-flex mr-2 p-0 align-items-center">
                                                    <input className="d-flex mr-2 p-0" type="radio" name="profileImgs" id="profileImg2" autoComplete="off" value="2" onChange={onChangeProfileImg} />
                                                    <label className="d-flex m-0" htmlFor="profileImg2">
                                                        <img src={ConstData.IMG_DIR + "profile_02.png"} alt="" />
                                                    </label>
                                                </div>
                                                <div className="d-flex mr-2 p-0 align-items-center">
                                                    <input className="d-flex mr-2 p-0" type="radio" name="profileImgs" id="profileImg3" autoComplete="off" value="3" onChange={onChangeProfileImg} />
                                                    <label className="d-flex m-0" htmlFor="profileImg3">
                                                        <img src={ConstData.IMG_DIR + "profile_03.png"} alt="" />
                                                    </label>
                                                </div>
                                                <div className="d-flex mr-2 p-0 align-items-center">
                                                    <input className="d-flex mr-2" type="radio" name="profileImgs" id="profileImg4" autoComplete="off" value="4" onChange={onChangeProfileImg} />
                                                    <label className="d-flex m-0" htmlFor="profileImg4">
                                                        <img src={ConstData.IMG_DIR + "profile_04.png"} alt="" />
                                                    </label>
                                                </div>
                                                <div className="d-flex mr-2 p-0 align-items-center">
                                                    <input className="d-flex mr-2" type="radio" name="profileImgs" id="profileImg5" autoComplete="off" value="5" onChange={onChangeProfileImg} />
                                                    <label className="d-flex m-0" htmlFor="profileImg5">
                                                        <img src={ConstData.IMG_DIR + "profile_05.png"} alt="" />
                                                    </label>
                                                </div>
                                                <div className="d-flex mr-2 p-0 align-items-center">
                                                    <input className="d-flex mr-2" type="radio" name="profileImgs" id="profileImg6" autoComplete="off" value="6" onChange={onChangeProfileImg} />
                                                    <label className="d-flex m-0" htmlFor="profileImg6">
                                                        <img src={ConstData.IMG_DIR + "profile_06.png"} alt="" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {// 임시로 매뉴얼 작업할 동안 주석 처리 by hjkim 20230503
                                /* !ConstData.IS_LOCAL_VERSION &&
                                <div className="d-flex w-100 my-2 justify-content-between">
                                    <div className="d-flex m-0 p-0 align-items-center justify-content-center" style={{ width: '10%' }}>
                                        //<FaRobot className="font-md plus blue-text" data-toggle="tooltip" title="사용자 타입" />//
                                        <FaUserAstronaut className="font-md plus blue-text" data-toggle="tooltip" title="사용자 타입" />
                                    </div>
                                    <div className="m-0 p-0" style={{ width: '90%' }}>
                                        <div className="container m-0 p-0 hiclasstv-darkgrey-text">
                                            <div className="row m-0 p-0">
                                                <div className="d-flex w-100 px-2 justify-content-start" style={{ overflow: 'auto' }}>
                                                    <div className="d-flex mr-2 p-0 align-items-center">
                                                        <input className="d-flex mr-2 p-0" type="radio" name="userTypes" id="userType1" value={stUserType.User} onChange={onChangeUserType} defaultChecked />
                                                        <label className="form-check-label" htmlFor="userType1">일반</label>
                                                    </div>
                                                    <div className="d-flex mr-2 p-0 align-items-center">
                                                        <input className="d-flex mr-2 p-0" type="radio" name="userTypes" id="userType2" autoComplete="off" value={stUserType.Robot} onChange={onChangeUserType} />
                                                        <label className="form-check-label" htmlFor="userType2">로봇</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */
                            }
                            <div className="d-flex w-100 md-form form-group input-group p-0 my-2 justify-content-between">
                                <div className="d-flex m-0 p-0 align-items-center justify-content-center" style={{ width: '10%' }}>
                                    <FaFeatherAlt className="font-md plus blue-text" data-toggle="tooltip" title="식별코드" />
                                </div>
                                <input type="text" id="prefixInsideExCode" className="form-control" style={{ width: '90%' }} placeholder="식별코드를 입력해 주세요." onChange={onChangeUserExtraCode} value={userExtraCode} />
                            </div>
                            <div className="d-flex w-100 justify-content-end">
                                <span className="input-description">생략 또는 1자~50자, 한글, 영문, 숫자, 특수문자 사용 가능</span>
                            </div>
                            {
                                alertMessage !== '' &&
                                <div className="d-flex justify-content-center">
                                    <Alerts kind={alertKind} message={alertMessage} />
                                </div>
                            }
                            <div className="d-flex justify-content-center align-items-center my-2">
                                <button type="button" className="btn btn-md btn-primary" onClick={checkRegistUser}>
                                    회원가입
                                </button>
                                <button type="button" className="btn btn-md btn-danger" onClick={() => handleSelectedFunc({ selectedFunc: "REGIST_TOGGLE" })}>
                                    취소
                                </button>
                            </div>
                        </div>
                    </div>
            }
        </Modal>
    );
}

export default UserRegist;