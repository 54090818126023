import React, { useEffect, useState } from "react";
import { UncontrolledCollapse } from "reactstrap";
import { FaBroadcastTower, FaLock, FaLockOpen, FaCheckCircle, FaTimesCircle, FaCog } from "react-icons/fa";

const WebLocalServerInfo = ({ idx, localServer_info, list_localSite, handleSelectedFunc }) => {
    const [lLocalSiteInfo, setLocalSiteInfo] = useState({ idx: 0, localSiteSeq: -1, localSiteName: "" });

    const [lPortNumber, setPortNumber] = useState("");
    const [lMemo, setMemo] = useState("");

    useEffect(() => {
        if (list_localSite && list_localSite.length > 0) {
            setLocalSiteInfo({ idx: 0, localSiteSeq: list_localSite[0].localSiteSeq, localSiteName: list_localSite[0].localSiteName });
        }
    }, [list_localSite]);

    useEffect(() => {
        if (localServer_info && localServer_info.localSiteSeq !== -1) {
            let c_local_site_info = { idx: 0, localSiteSeq: -1, localSiteName: "" };

            if (list_localSite && list_localSite.length > 0) {
                c_local_site_info = list_localSite.find(info => info.localSiteSeq === localServer_info.localSiteSeq);
                let index = list_localSite.indexOf(c_local_site_info);

                setLocalSiteInfo({ idx: index, localSiteSeq: c_local_site_info.localSiteSeq, localSiteName: c_local_site_info.localSiteName });
            }
        }
    }, [localServer_info]);

    const onChangePortNumber = (e) => {
        let str = e.target.value;
        str = str.replace(/[^0-9]/g, "");
        setPortNumber(str);
    }

    const onChangeMemo = (e) => {
        setMemo(e.target.value);
    }

    const onHandleLocalServerPortNumber = () => {
        if (lPortNumber === "" || lPortNumber < 0) return;

        if (lMemo === "") {
            handleSelectedFunc({ selectedFunc: "CHANGE_LOCAL_SERVER_PORT_NUM", data: { localServer_info, portNumber: lPortNumber } });
        } else {
            handleSelectedFunc({ selectedFunc: "CHANGE_LOCAL_SERVER_PORT_NUM", data: { localServer_info, portNumber: lPortNumber, historyMemo: lMemo } });
        }
    }

    const onChangeSelectLocalSite = (e) => {
        if (list_localSite && list_localSite.length > 0) {
            let idx = e.target.value;
            setLocalSiteInfo({ idx, localSiteSeq: list_localSite[idx].localSiteSeq, localSiteName: list_localSite[idx].localSiteName });
        }
    }

    const onHandleModifyLocalSite = () => {
        handleSelectedFunc({ selectedFunc: "CHANGE_LOCAL_SITE", data: { localServerSeq: localServer_info.localServerSeq, localSiteSeq: lLocalSiteInfo.localSiteSeq, localSiteName: lLocalSiteInfo.localSiteName }});
    }

    const onHandleRemoveServer = () => {
        handleSelectedFunc({ selectedFunc: "DELETE_LOCAL_SERVER", data: localServer_info });
    }

    return (
        <>
            <tr className="d-flex">
                <td className="d-flex align-items-center justify-content-center area-10">
                    <span>{localServer_info.localServerSeq}</span>
                </td>
                <td className="d-flex align-items-center area-25">
                    <div className="text-area">
                        <span className="hiclass-text" style={{ width: '80%' }} title={localServer_info.localSiteName}>{localServer_info.localSiteName}</span>
                        <FaCog className="font-sm grey-text ml-1 button" style={{verticalAlign:'text-top'}} id={"changeLocalSite_" + idx} />
                    </div>
                </td>
                <td className="d-flex align-items-center justify-content-center area-30">
                    <div className="d-flex align-items-center justify-content-center" style={{ width: '15%' }}>
                        {
                            localServer_info.serverStatus === "Y" ?
                                <FaBroadcastTower className="font-ml green-text" /> :
                                <FaBroadcastTower className="font-ml red-text" />
                        }
                    </div>
                    <div className="d-flex align-items-center justify-content-start" style={{ width: '85%' }}>
                        <div className="text-area">
                            <span className="info-title hiclass-text" title={localServer_info.localServerName}>
                                {localServer_info.localServerName}
                            </span>
                        </div>
                    </div>
                </td>
                <td className="d-flex align-items-center justify-content-center area-15">
                    <span>{localServer_info.authCode}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-10">
                    {
                        localServer_info.isPortOpened ?
                            <FaLockOpen className="font-md green-text" /> :
                            <FaLock className="font-md red-text" />
                    }
                </td>
                <td className="d-flex align-items-center justify-content-center area-10 p-0">
                    <button className="btn btn-sm btn-outline-primary px-2 py-1 small ml-2" type="button" id={"changePortInfo_" + idx}>
                        포트관리
                    </button>
                </td>
                {/*<td className="d-flex align-items-center justify-content-center area-10 p-0">
                    <button type="button" className="btn btn-sm red white-text py-1 px-2 " title="삭제" onClick={() => onHandleRemoveServer()}>
                        <b>삭제</b>
                    </button>
                </td>*/}
            </tr>
            <tr className="d-flex b-none">
                <td className="d-flex w-100 p-0">
                    <UncontrolledCollapse toggler={"#changeLocalSite_" + idx} className="w-100" defaultOpen={false}>
                        {
                            <div className="d-flex align-items-center justify-content-center w-100">
                                <div className="d-flex justify-content-between area-90 hiclasstv-lightgrey round p-2 pl-4 mb-2">
                                    <div className="d-flex align-items-center font-md area-80">
                                        <span className="font-weight-bold area-20">로컬 사이트.</span>
                                        <select className="custom-select small my-1" style={{ width: '100%', fontSize: '0.9rem' }} value={lLocalSiteInfo.idx} onChange={onChangeSelectLocalSite}>
                                            {
                                                list_localSite && list_localSite.length > 0 ?
                                                    list_localSite.map((info, index) => (
                                                        <option value={index} key={"itemOption-" + index}>{info.localSiteName}</option>
                                                    )) :
                                                    <option value={-1}>
                                                        선택 가능한 로컬 사이트가 없습니다.
                                                    </option>
                                            }
                                        </select>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center area-10">
                                        <button type="button" className="d-flex transparent b-none rounded p-1" title="저장" onClick={() => onHandleModifyLocalSite()}>
                                            <FaCheckCircle className="d-flex font-md m-1 green-text" />
                                        </button> 
                                    </div>
                                </div>
                            </div>
                        }
                    </UncontrolledCollapse>
                </td>
            </tr>
            <tr className="d-flex b-none">
                <td className="d-flex w-100 p-0">
                    <UncontrolledCollapse toggler={"#changePortInfo_" + idx} className="w-100" defaultOpen={false}>
                        {
                            localServer_info.isPortOpened ?
                                <div className="d-flex align-items-center justify-content-center w-100">
                                    <div className="d-flex align-items-center justify-content-around area-90 hiclasstv-lightgrey round p-2 mb-2">
                                        <div className="d-flex area-30 align-items-center font-md">
                                            <span className="font-weight-bold">Port Num. &nbsp;</span>
                                            [{localServer_info.portNumber}]
                                        </div>
                                        <div className="d-flex area-50 font-md">
                                            <span className="font-weight-bold">메모.&nbsp;</span>
                                            {localServer_info.historyMemo !== undefined && localServer_info.historyMemo !== null ? localServer_info.historyMemo : "없음."}
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center area-10">
                                            <button type="button" className="btn btn-sm btn-outline-danger px-2 py-1 small m-0" title="포트 닫기" id={"changePortInfo" + idx} onClick={() => handleSelectedFunc({ selectedFunc: "CLOSE_LOCAL_SERVER_PORT", data: { localServer_info } })}>
                                                닫기
                                            </button>
                                        </div>
                                    </div>
                                </div> :
                                <div className="d-flex align-items-center justify-content-center w-100">
                                    <div className="d-flex align-items-center justify-content-between area-90 hiclasstv-lightgrey round p-2 pl-4 mb-2">
                                        <div className="d-flex area-30 align-items-center font-md">
                                            <span className="font-weight-bold">Port Num.&nbsp;</span>
                                            <input
                                                type="text"
                                                value={lPortNumber}
                                                className="web-search-input transparent area-50 m-0"
                                                placeholder={lPortNumber && lPortNumber !== "" ? lPortNumber : "포트 번호"}
                                                onChange={onChangePortNumber}
                                            />
                                        </div>
                                        <div className="d-flex area-50 align-items-center font-md">
                                            <span className="font-weight-bold">메모.&nbsp;</span>
                                            <input
                                                type="text"
                                                value={lMemo}
                                                className="web-search-input transparent area-80 m-0"
                                                placeholder={lMemo && lMemo !== "" ? lMemo : "메모를 입력해 주세요."}
                                                onChange={onChangeMemo}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center area-10">
                                            {
                                                localServer_info.serverStatus === "Y" ?
                                                    <button type="button" className="d-flex transparent b-none rounded p-1" title="포트 열기" onClick={() => onHandleLocalServerPortNumber()}>
                                                        <FaCheckCircle className="d-flex font-md m-1 green-text" />
                                                    </button> :
                                                    <button type="button" className="d-flex transparent b-none rounded p-1" title="포트 열기" onClick={() => onHandleLocalServerPortNumber()} disabled={localServer_info.serverStatus === "N"}>
                                                        <FaCheckCircle className="d-flex font-md m-1 light-grey-text" />
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                    </UncontrolledCollapse>
                </td>
            </tr>
        </>
    );
}

export default WebLocalServerInfo;