/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';

const QnA009 = ({ isOpened, onChangeOpenedFlag }) => {
    useEffect(() => {
        let content = document.getElementById("qna09");

        if (isOpened) {
            content.style.maxHeight = content.scrollHeight + "px";
        } else {
            content.style.maxHeight = null;
        }
    }, [isOpened]);

    return (
        <div className="accordion">
            <div className="accordion-title" onClick={() => onChangeOpenedFlag(8)}>
                <span className="hiclasstv-deepDarkgrey-text button">
                    <b>9. 실시간 유튜브 중계도 가능한가요?</b>
                </span>
            </div>
            <div className="accordion-content hiclasstv-darkgrey-text" id="qna09">
                <span className="d-flex" style={{ minWidth: 'max-content' }}>A.&nbsp;</span>
                <p>
                    Pencam을 활용하여 현재 진행하고 있는 수업 내용을 실시간으로 유튜브를 통해서 방송할 수 있습니다. <br />
                </p>
            </div>
        </div>
    );
}
export default QnA009;