/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

// import logo from '../../image/info/main/logo.png';
// import main01 from '../../image/info/main/main01.png';
// import main02 from '../../image/info/main/main02.png';
// import icon01 from '../../image/info/main/icon01.png';
// import icon02 from '../../image/info/main/icon02.png';
// import icon03 from '../../image/info/main/icon03.png';
// import icon04 from '../../image/info/main/icon04.png';
// import icon05 from '../../image/info/main/icon05.png';

const WebHiClassInfoHome = () => {
    return (
        <div className="d-flex flex-column w-100 align-items-center" style={{ margin: '10px 0px', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
            <div className="d-flex justify-content-center align-items-center w-100 flex-wrap" style={{ margin: '2rem 0' }}>
                <div className="d-flex justify-content-center align-items-center" style={{ width: '50%', minWidth: '5%', maxWidth: 'max-content' }}>
                    <img src="/images/info/main/logo.png" style={{ display: 'flex', width: '80%' }} alt="" />
                </div>
                <div className="d-flex flex-column justify-content-center" style={{ minWidth: '10%' }}>
                    <div className="d-flex flex-wrap justify-content-center" style={{ fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                        <span>HiClassTV&nbsp;</span>
                        <span className="hiclasstv-blue-text">Pentalk</span>
                    </div>
                    <div className="d-flex text-center">
                        <span className="d-flex" style={{ fontSize: '1.4rem', fontWeight: '400', lineHeight: 'normal' }}>
                            인공지능 기반 비대면 원격교육/협업 플랫폼
                        </span>
                    </div>
                </div>
            </div>
            <div className="d-flex" style={{ margin: '2rem 0' }}>
                <img src="/images/info/main/main01.png" className="w-100 h-100" style={{ objectFit: 'contain' }} alt="" />
            </div>
            <div className="d-flex align-items-center justify-content-center text-center my-2">
                <span className="dark-grey-text" style={{ fontSize: '1.1rem', fontWeight: '500' }}>
                    사람과 AI가 함께하는 세상, 기술로 모든 사람이 좀 더 편하게 소통 할 수 있는 세상을 만들어 갑니다.
                </span>
            </div>
            <div className="d-flex w-75" style={{ margin: '2rem 0' }}>
                <img src="/images/info/main/main02.png" className="w-100 h-100" style={{ objectFit: 'contain' }} alt="" />
            </div>
            <div className="d-flex flex-column align-items-center w-100">
                <div className="d-flex flex-wrap">
                    {/*
                    <div className="d-flex col-md-6 my-5 justify-content-center p-0">
                        <div className="card h-100 mx-2">
                            <div className="card-body d-flex justify-content-center text-center" style={{ position: 'relative' }}>
                                <div className="info-circle">
                                    <span className="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <img src="/images/info/main/icon01.png" style={{ width: '70%', height: '70%' }} alt="" />
                                    </span>
                                </div>
                                <div className="py-4 px-2">
                                    <span className="d-flex justify-content-center my-3" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                        업무 효율성
                                    </span>
                                    <span className="d-flex justify-content-center my-2" style={{ fontSize: '1rem', fontWeight: '400' }}>
                                        팀과 팀을 연결하고, 팀원과 팀원을 연결하여, 언제 어디서나 업무의 효율성을 극대화 합니다.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    */}
                    <div className="d-flex col-md-6 my-5 justify-content-center p-0">
                        <div className="card h-100 mx-2">
                            <div className="card-body d-flex justify-content-center text-center" style={{ position: 'relative' }}>
                                <div className="info-circle">
                                    <span className="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <img src="/images/info/main/icon02.png" style={{ width: '70%', height: '70%' }} alt="" />
                                    </span>
                                </div>
                                <div className="py-4 px-2">
                                    <span className="d-flex justify-content-center my-3" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                        제약없는 연결
                                    </span>
                                    <span className="d-flex justify-content-center my-2" style={{ fontSize: '1rem', fontWeight: '400' }}>
                                        교실과 교실, 칠판과 칠판, 학생과 학생을 연결하여 언제 어디서나 강의하고 학습합니다.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex col-md-6 my-5 justify-content-center p-0">
                        <div className="card h-100 mx-2">
                            <div className="card-body d-flex justify-content-center text-center" style={{ position: 'relative' }}>
                                <div className="info-circle">
                                    <span className="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <img src="/images/info/main/icon03.png" style={{ width: '70%', height: '70%' }} alt="" />
                                    </span>
                                </div>
                                <div className="py-4 px-2">
                                    <span className="d-flex justify-content-center my-3" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                        녹화, 공유 콘텐츠 생성
                                    </span>
                                    <span className="d-flex justify-content-center my-2" style={{ fontSize: '1rem', fontWeight: '400' }}>
                                        강의하고 공유하며, 배우는 과정을 녹화하고 방송하여, 새로운 콘텐츠를 생성하고 결과를 공유합니다.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex col-md-6 my-5 justify-content-center p-0'>
                        <div className='card h-100 mx-2'>
                            <div className='card-body d-flex justify-content-center text-center' style={{ position: 'relative' }}>
                                <div className='info-circle'>
                                    <span className='d-flex w-100 h-100 align-items-center justify-content-center'>
                                        <img src="/images/info/main/icon05.png" style={{ width: '95%', height: '95%' }} alt="" />
                                    </span>
                                </div>
                                <div className='py-4 px-2'>
                                    <span className='d-flex justify-content-center my-3' style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                        대면ㆍ비대면 혼합 스마트 클래스
                                    </span>
                                    <span className='d-flex justify-content-center my-2' style={{ fontSize: '1rem', fontWeight: '400' }}>
                                        교실과 온라인 (대면과 비대면) 수업을 함께 진행하는 O2O 스마트 클래스를 지원합니다.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex col-md-6 my-5 justify-content-center p-0">
                        <div className="card h-100 mx-2">
                            <div className="card-body d-flex justify-content-center text-center" style={{ position: 'relative' }}>
                                <div className="info-circle">
                                    <span className="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <img src="/images/info/main/icon04.png" style={{ width: '70%', height: '70%' }} alt="" />
                                    </span>
                                </div>
                                <div className="py-4 px-2">
                                    <span className="d-flex justify-content-center my-3" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                        모든기기 사용
                                    </span>
                                    <span className="d-flex justify-content-center my-2" style={{ fontSize: '1rem', fontWeight: '400' }}>
                                        데스크탑, 노트북,모바일 등 모든 기기에서 한번 클릭으로 이 모든 과정에 참여할 수 있습니다.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WebHiClassInfoHome;