import React from "react";

import AppServicePolicy from "./AppServicePolicy";

const WebAppPolicy = () => {
    return (
        <div className="web-main list-area align-items-center justify-content-center p-2">
            <h3>
                <b>Pentalk onScreen 개인정보처리방침</b>
            </h3>
            <div className="web-policy-box no-modal p-2 my-2 scroll-show">
                <AppServicePolicy />
            </div>
            <div className="footer-template-web" style={{ border: 'none' }}>
                <span>1992~2022 © Easy Systems Inc.</span>
            </div>
        </div>
    );
};

export default WebAppPolicy;