/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import QnA001 from './QnA/QnA001';
import QnA002 from './QnA/QnA002';
import QnA003 from './QnA/QnA003';
import QnA004 from './QnA/QnA004';
import QnA005 from './QnA/QnA005';
import QnA006 from './QnA/QnA006';
import QnA007 from './QnA/QnA007';
import QnA008 from './QnA/QnA008';
import QnA009 from './QnA/QnA009';
import QnA010 from './QnA/QnA010';
import QnA011 from './QnA/QnA011';

const WebHiClassInfoQnA = () => {
    const [list_qna, setQnaList] = useState([
        {isOpened:false}, // [0] ..... QnA001
        {isOpened:false}, // [1] ..... QnA002
        {isOpened:false}, // [2] ..... QnA003
        {isOpened:false}, // [3] ..... QnA004
        {isOpened:false}, // [4] ..... QnA005
        {isOpened:false}, // [5] ..... QnA006
        {isOpened:false}, // [6] ..... QnA007
        {isOpened:false}, // [7] ..... QnA008
        {isOpened:false}, // [8] ..... QnA009
        {isOpened:false}, // [9] ..... QnA010
        {isOpened:false}, // [10] .... QnA011
    ]);

    const onChangeOpenedFlag = (index) => {
        if (index < list_qna.length) {
            const arr = list_qna.map(
                (info, idx) => {
                    //console.log("list_qna[", idx, "] ---- ", info);
                    let value = idx === index ? {isOpened: !info.isOpened} : {isOpened: false};
                    return value;
                }
            );
            setQnaList(arr);
        }
    }

    return(
        <div className="d-flex flex-column w-100" style={{ margin: '10px 0px', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
            <div className="d-flex justify-content-center align-items-center w-100" style={{ margin: '2rem 0' }}>
                <div className="d-flex flex-column w-100">
                    <div className="d-flex flex-wrap" style={{ fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                        <span className="hiclasstv-blue-text">HiClassTV&nbsp;</span>
                        <span className="hiclasstv-darkblue-text">FAQ</span>
                    </div>
                    <div className="d-flex flex-column mt-4 w-100">
                        <QnA001 isOpened={list_qna[0].isOpened} onChangeOpenedFlag={onChangeOpenedFlag} />
                        <QnA002 isOpened={list_qna[1].isOpened} onChangeOpenedFlag={onChangeOpenedFlag} />
                        <QnA003 isOpened={list_qna[2].isOpened} onChangeOpenedFlag={onChangeOpenedFlag} />
                        <QnA004 isOpened={list_qna[3].isOpened} onChangeOpenedFlag={onChangeOpenedFlag} />
                        <QnA005 isOpened={list_qna[4].isOpened} onChangeOpenedFlag={onChangeOpenedFlag} />
                        <QnA006 isOpened={list_qna[5].isOpened} onChangeOpenedFlag={onChangeOpenedFlag} />
                        <QnA007 isOpened={list_qna[6].isOpened} onChangeOpenedFlag={onChangeOpenedFlag} />
                        <QnA008 isOpened={list_qna[7].isOpened} onChangeOpenedFlag={onChangeOpenedFlag} />
                        <QnA009 isOpened={list_qna[8].isOpened} onChangeOpenedFlag={onChangeOpenedFlag} />
                        <QnA010 isOpened={list_qna[9].isOpened} onChangeOpenedFlag={onChangeOpenedFlag} />
                        <QnA011 isOpened={list_qna[10].isOpened} onChangeOpenedFlag={onChangeOpenedFlag} />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default WebHiClassInfoQnA;