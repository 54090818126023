import React from 'react';

const RoleScript = ({ role, script }) => {
    return (
        <>
            <span className="text-item" type="item"><span className="font-weight-bold">{role}</span>{' : ' + script}</span>
        </>
    );
};

export default RoleScript;