import React from 'react';

const ConfigLiveListTitleTemplate = ({handleSelectedFunc}) => {

    return(
        <div className="title-area main hiclasstv-blue white-text">
            <div className="d-flex align-items-center" style={{position:'absolute', left:'0'}} onClick={()=>handleSelectedFunc({selectedFunc:"NONE"})}>
                <span className="button px-2"><b>닫기</b></span>
            </div>
            <div className="d-flex align-items-center">
                <b>클래스 관리</b>
            </div>
            <div />
        </div>
    );
}

export default ConfigLiveListTitleTemplate;