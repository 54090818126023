import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import createRequestThunk from '../lib/createRequestThunk';
import tossPhaseAction from '../lib/tossPhaseAction';

import { SUB_DOMAIN_INFO_INIT } from './admin';
import {
    get_all_pass_list,
    get_prdt_pass_list,
    get_pchs_pass_list,
    get_pchs_pass_list_with_manage_info,
    add_prdt_pass,
    regist_b2b_pass,
    regist_b2c_pass,
    use_pass,
    refund_pass,
    partial_refund_pass
} from '../lib/api/pass';

import { CHANGE_SHOW_PASS_KIND } from './hiclasstv';
import ConstData from '../lib/ConstData';

const INIT_PCHS_PASS                = 'pass/INIT_PCHS_PASS';

const GET_ALL_PASS_LIST             = 'pass/GET_ALL_PASS_LIST';
const GET_ALL_PASS_LIST_SUCCESS     = 'pass/GET_ALL_PASS_LIST_SUCCESS';

const GET_PRDT_PASS                 = 'pass/GET_PRDT_PASS';
const GET_PRDT_PASS_SUCCESS         = 'pass/GET_PRDT_PASS_SUCCESS';

const GET_PCHS_PASS                 = 'pass/GET_PCHS_PASS';
const GET_PCHS_PASS_SUCCESS         = 'pass/GET_PCHS_PASS_SUCCESS';
const GET_PCHS_MANAGE_PASS          = 'pass/GET_PCHS_MANAGE_PASS';
const GET_PCHS_MANAGE_PASS_SUCCESS  = 'pass/GET_PCHS_MANAGE_PASS_SUCCESS';

const ADD_PRDT_PASS                 = "pass/ADD_PRDT_PASS";
const ADD_PRDT_PASS_SUCCESS         = "pass/ADD_PRDT_PASS_SUCCESS";

const REGIST_PASS_B2B               = 'pass/REGIST_PASS_B2B';
const REGIST_PASS_B2B_SUCCESS       = 'pass/REGIST_PASS_B2B_SUCCESS';
const REGIST_PASS_B2C               = 'pass/REGIST_PASS_B2C';
const REGIST_PASS_B2C_SUCCESS       = 'pass/REGIST_PASS_B2C_SUCCESS';

const USE_PASS                      = 'pass/USE_PASS';
const USE_PASS_SUCCESS              = 'pass/USE_PASS_SUCCESS';

const BUY_PASS_SUCCESS              = 'pass/BUY_PASS_SUCCESS';

const REFUND_PASS                   = 'pass/REFUND_PASS';
const REFUND_PASS_SUCCESS           = 'pass/REFUND_PASS_SUCCESS';
const PARTIAL_REFUND_PASS           = 'pass/PARTIAL_REFUND_PASS';
const PARTIAL_REFUND_PASS_SUCCESS   = 'pass/PARTIAL_REFUND_PASS_SUCCESS';

export const initPurchasePassList   = createAction(INIT_PCHS_PASS);
export const getAllPassList         = createRequestThunk(GET_ALL_PASS_LIST, get_all_pass_list);
export const getProductPass         = createRequestThunk(GET_PRDT_PASS, get_prdt_pass_list);
export const getPurchasePass        = createRequestThunk(GET_PCHS_PASS, get_pchs_pass_list);
export const getPurchaseManagePass  = createRequestThunk(GET_PCHS_MANAGE_PASS, get_pchs_pass_list_with_manage_info, [SUB_DOMAIN_INFO_INIT]);

export const addProductPass         = createRequestThunk(ADD_PRDT_PASS, add_prdt_pass);

export const registPassB2B          = createRequestThunk(REGIST_PASS_B2B, regist_b2b_pass);
export const registPassB2C          = createRequestThunk(REGIST_PASS_B2C, regist_b2c_pass);
export const updatePassUseInfo      = createRequestThunk(USE_PASS, use_pass);

export const buyPass                = tossPhaseAction(BUY_PASS_SUCCESS, [CHANGE_SHOW_PASS_KIND]);

export const refundPass             = createRequestThunk(REFUND_PASS, refund_pass);
export const partialRefundPass      = createRequestThunk(PARTIAL_REFUND_PASS, partial_refund_pass);

const initialState = {
    list_pass           : [],
    list_prdtPass       : [],
    list_pchsPass       : [],

    /** 일단 하드코딩... 추후에 restApi에서 받아오도록 처리.. */
    list_prdtKindCode       : [
        { kindSeq:1, kindCode:'PURCH', kindName:'구매 이용권'},
        { kindSeq:2, kindCode:'PROMT', kindName:'프로모션 이용권'}
    ],
    list_prdtScaleCode      : [
        /*{ scaleSeq:10, scaleCode:'B2C', scaleName:'B2C 대상', maxScale:1},*/
        { scaleSeq:20, scaleCode:'L50', scaleName:'50명 미만', maxScale:50 },
        { scaleSeq:30, scaleCode:'L100', scaleName:'100명 미만', maxScale:100 },
        { scaleSeq:40, scaleCode:'L500', scaleName:'500명 미만', maxScale:500 },
        { scaleSeq:50, scaleCode:'H500', scaleName:'500명 이상', maxScale:100000 },
    ],
    list_prdtTargetCode     : [
        { targetSeq:1, targetCode:'B2B', targetName:'B2B' },
        { targetSeq:2, targetCode:'B2C', targetName:'B2C' },
    ],
    list_prdtTermCode       : [
        { termSeq:10, termCode:'D30', termName:'30일', termValue:30 },
        { termSeq:20, termCode:'D60', termName:'60일', termValue:60 },
        { termSeq:30, termCode:'D90', termName:'90일', termValue:90 },
        { termSeq:40, termCode:'D180', termName:'180일', termValue:180 },
        { termSeq:50, termCode:'D365', termName:'1년(365일)', termValue:365 },
        { termSeq:60, termCode:'D730', termName:'2년(730일)', termValue:730 },
    ],

    alertKind           : ConstData.ALERT_KIND.NONE,
    alertMessage        : "",
};

const pass = handleActions({
    [INIT_PCHS_PASS]: (state, action) => {
        return {
            ...state,
            list_pchsPass : []
        }
    },

    [GET_ALL_PASS_LIST_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("GET_ALL_PASS_LIST_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { list_pass } = action.payload;
            let list_tmp = [];

            if (list_pass !== undefined && list_pass !== null) {
                list_tmp = list_pass.map(row => ({
                    prdtServiceSeq  : row.PRDT_SVC_SEQ,
                    prdtServiceName : row.PRDT_SVC_NM,
                    extraYN         : row.PRDT_EXTRA_YN,
                    list_service    : row.serviceList.map(
                        service => ({
                            serviceSeq  : service.SVC_SEQ,
                            serviceName : service.SVC_NM
                        })
                    ),
                    list_passItem       : row.itemList.map(
                        item => ({
                            passSeq     : item.PRDT_SEQ,
                            passName    : item.PRDT_NM,
                            passPrice   : item.PRDT_PRICE
                        })
                    )
                }))
            }

            return {
                ...state,
                list_pass : list_tmp
            }
        } else {
            console.log("isSuccessed is ", isSuccessed);
            
            return {
                ...state,
                list_pass   : []
            }
        }
    },

    [GET_PRDT_PASS_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("GET_PRDT_PASS_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { list_product } = action.payload;
            let list_tmp = [];

            if (list_product !== undefined && list_product !== null) {
                list_tmp = list_product.map(row => ({
                    prdtSeq         : row.PRDT_SEQ,
                    prdtName        : row.PRDT_NM,
                    prdtKindCode    : row.PRDT_KIND_CD,
                    prdtKindName    : row.PRDT_KIND_NM,
                    prdtTargetCode  : row.PRDT_TRGT_CD,
                    prdtTargetName  : row.PRDT_TRGT_NM,
                    prdtScaleCode   : row.PRDT_SCAL_CD,
                    prdtScaleName   : row.PRDT_SCAL_NM,
                    prdtTermCode    : row.PRDT_TERM_CD,
                    prdtTermName    : row.PRDT_TERM_NM,
                    prdtTermValue   : row.PRDT_TERM_VAL
                }));
            }

            return {
                ...state,
                list_prdtPass   : list_tmp
            }
        } else {
            console.log("isSuccessed is ", isSuccessed);
            
            return {
                ...state,
                list_prdtPass   : []
            }
        }
    },

    [GET_PCHS_PASS_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("GET_PCHS_PASS_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { list_purchase } = action.payload;
            let list_tmp = [];

            if (list_purchase !== undefined && list_purchase !== null) {
                list_tmp = list_purchase.map(row => ({
                    pchsSeq     : row.PCHS_SEQ,
                    pchsDT      : row.PCHS_DTTM,
                    pchsOfficeType : row.PCHS_OFC_TYPE,
                    useDtInfo   : {
                        strtDT  : row.USE_STRT_DATE,
                        endDT   : row.USE_END_DATE
                    },
                    cancelYN    : row.CANCL_YN,
                    cancelType      : row.CANCL_TYPE,
                    cancelDt        : row.CANCL_DTTM,
                    prdtInfo        : {
                        name        : row.PRDT_NM,
                        price       : row.PRDT_PRICE,
                        kindName    : row.PRDT_KIND_NM,
                        kindCode    : row.PRDT_KIND_CD,
                        scaleName   : row.PRDT_SCAL_NM,
                        scaleCode   : row.PRDT_SCAL_CD,
                        targetName  : row.PRDT_TRGT_NM,
                        targetCode  : row.PRDT_TRGT_CD,
                        termName    : row.PRDT_TERM_NM,
                        termCode    : row.PRDT_TERM_CD,
                        termValue   : row.PRDT_TERM_VAL
                    }
                }));
            }

            return {
                ...state,
                list_pchsPass   : list_tmp
            }
        } else {
            console.log("isSuccessed is ", isSuccessed);
            
            return {
                ...state,
                list_pchsPass   : []
            }
        }
    },

    [GET_PCHS_MANAGE_PASS_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("GET_PCHS_MANAGE_PASS - ", action.payload);

        if (isSuccessed) {
            const { list_purchase } = action.payload;
            let list_tmp = [];

            if (list_purchase !== undefined && list_purchase !== null) {
                list_tmp = list_purchase.map(row => ({
                    pchsSeq     : row.PCHS_SEQ,
                    pchsDT      : row.PCHS_DTTM,
                    pchsOfficeType : row.PCHS_OFC_TYPE,
                    useDtInfo   : {
                        strtDT  : row.USE_STRT_DATE,
                        endDT   : row.USE_END_DATE
                    },
                    cancelYN    : row.CANCL_YN,
                    cancelType      : row.CANCL_TYPE,
                    cancelDt        : row.CANCL_DTTM,
                    prdtInfo        : {
                        name        : row.PRDT_NM,
                        price       : row.PRDT_PRICE,
                        kindName    : row.PRDT_KIND_NM,
                        kindCode    : row.PRDT_KIND_CD,
                        scaleName   : row.PRDT_SCAL_NM,
                        scaleCode   : row.PRDT_SCAL_CD,
                        targetName  : row.PRDT_TRGT_NM,
                        targetCode  : row.PRDT_TRGT_CD,
                        termName    : row.PRDT_TERM_NM,
                        termCode    : row.PRDT_TERM_CD,
                        termValue   : row.PRDT_TERM_VAL
                    }
                }));
            }

            return {
                ...state,
                list_pchsPass   : list_tmp
            }
        } else {
            console.log("isSuccessed is ", isSuccessed);
            
            return {
                ...state,
                list_pchsPass   : []
            }
        }
    },

    [ADD_PRDT_PASS_SUCCESS]: (state, action) => {
        const { prdt_info } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("ADD_PRDT_PASS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isAddSuccess } = action.payload;

            if (isAddSuccess) {
                const { prdtSEQ } = action.payload;

                let newPrdt_info = {
                    prdtSeq         : prdtSEQ,
                    prdtName        : prdt_info.passName,
                    prdtKindCode    : prdt_info.kindCode,
                    // prdtKindName    : prdt_info.PRDT_KIND_NM,
                    prdtTargetCode  : prdt_info.targetCode,
                    // prdtTargetName  : prdt_info.PRDT_TRGT_NM,
                    prdtScaleCode   : prdt_info.scaleCode,
                    // prdtScaleName   : prdt_info.PRDT_SCAL_NM,
                    prdtTermCode    : prdt_info.termCode,
                    // prdtTermName    : prdt_info.PRDT_TERM_NM,
                    // prdtTermValue   : prdt_info.PRDT_TERM_VAL
                };

                console.log("newPrdt_info => ", newPrdt_info);

                return {
                    ...state,
                    alertKind           : ConstData.ALERT_KIND.SUCCESS,
                    alertMessage        : "성공적으로 상품이 등록되었습니다.",
                    list_prdtPass       : state.list_prdtPass.concat(newPrdt_info)
                }
            } else {
                console.log("ADD_PRDT_PASS - isAddSuccess is ", isAddSuccess, action.payload);
            
                return {
                    ...state,
                    alertKind           : ConstData.ALERT_KIND.DANGER,
                    alertMessage        : "상품 등록 중 오류가 발생했습니다. [Code.PS334.0]",
                }
            }
        } else {
            console.log("ADD_PRDT_PASS - isSuccessed is ", isSuccessed, action.payload);
            
            return {
                ...state,
                alertKind           : ConstData.ALERT_KIND.DANGER,
                alertMessage        : "오류가 발생하여 요청을 완료하지 못했습니다.<br>다시 시도해 주세요."
                                      + "<br>같은 현상이 반복될 경우<br>관리자에게 문의하시기 바랍니다. [Code.PS344.0]"
            }
        }
    },

    [BUY_PASS_SUCCESS]: (state, action) => {
        const { isSuccessed, isAddSuccess, pchs_info } = action.payload;

        if (isSuccessed) {
            if (isAddSuccess) {
                if (pchs_info) {
                    return {
                        ...state,
                        list_pchsPass : state.list_pchsPass.concat(pchs_info)
                    }
                } else {
                    console.log('BUY_PASS_SUCCESS pchs_info => ', pchs_info);
                    return {
                        ...state
                    }
                }
            } else {
                console.log('BUY_PASS_SUCCESS isAddSuccess => ', isAddSuccess);

                return {
                    ...state
                }
            }
        } else {
            console.log('BUY_PASS_SUCCESS isSuccessed => ', isSuccessed);

            return {
                ...state
            }
        }
    },

    [REGIST_PASS_B2B_SUCCESS]: (state, action) => {
        const { prdtPassInfo, pchsDate, pchsOfficeType } = action.parms;
        const { isSuccessed } = action.payload;
        console.log('REGIST_PASS_B2B_SUCCESS');

        if (isSuccessed) {
            const { isAddSuccess } = action.payload;

            if (isAddSuccess) {
                const { pchsSEQ } = action.payload;

                if (pchsSEQ !== undefined && pchsSEQ !== null && pchsSEQ > -1) {
                    const pchsPassInfo = {
                        pchsSeq : pchsSEQ,
                        pchsDT : pchsDate,
                        pchsOfficeType : pchsOfficeType,
                        useDtInfo : {
                            strtDT  : null,
                            endDT   : null,
                        },
                        cancelYN    : 'N',
                        cancelType      : null,
                        cancelDt        : null,
                        prdtInfo        : {
                            name        : prdtPassInfo.prdtName,
                            price       : prdtPassInfo.prdtPrice,
                            kindName    : prdtPassInfo.prdtKindName,
                            kindCode    : prdtPassInfo.prdtKindCode,
                            scaleName   : prdtPassInfo.prdtScaleName,
                            scaleCode   : prdtPassInfo.prdtScaleCode,
                            targetName  : prdtPassInfo.prdtTargetName,
                            targetCode  : prdtPassInfo.prdtTargetCode,
                            termName    : prdtPassInfo.prdtTermName,
                            termCode    : prdtPassInfo.prdtTermCode,
                            termValue   : prdtPassInfo.prdtTermValue
                        }
                    }

                    return {
                        ...state,
                        list_pchsPass : state.list_pchsPass.concat(pchsPassInfo)
                    }
                } else {
                    console.log('pchsSEQ is else... ', pchsSEQ);

                    return {
                        ...state,
                    }
                }
            } else {
                console.log("isAddSuccess is ", isAddSuccess);
            
                return {
                    ...state,
                }
            }
        } else {
            console.log("isSuccessed is ", isSuccessed);
            
            return {
                ...state,
            }
        }
    },

    [REGIST_PASS_B2C_SUCCESS]: (state, action) => {
        const { prdtPassInfo, pchsDate, pchsOfficeType } = action.parms;
        const { isSuccessed } = action.payload;
        console.log('REGIST_PASS_B2C_SUCCESS');

        if (isSuccessed) {
            const { isAddSuccess } = action.payload;

            if (isAddSuccess) {
                const { pchsSEQ } = action.payload;

                if (pchsSEQ !== undefined && pchsSEQ !== null && pchsSEQ > -1) {
                    const pchsPassInfo = {
                        pchsSeq : pchsSEQ,
                        pchsDT : pchsDate,
                        pchsOfficeType : pchsOfficeType,
                        useDtInfo : {
                            strtDT  : null,
                            endDT   : null,
                        },
                        cancelYN    : 'N',
                        cancelType      : null,
                        cancelDt        : null,
                        prdtInfo        : {
                            name        : prdtPassInfo.prdtName,
                            price       : prdtPassInfo.prdtPrice,
                            kindName    : prdtPassInfo.prdtKindName,
                            kindCode    : prdtPassInfo.prdtKindCode,
                            scaleName   : prdtPassInfo.prdtScaleName,
                            scaleCode   : prdtPassInfo.prdtScaleCode,
                            targetName  : prdtPassInfo.prdtTargetName,
                            targetCode  : prdtPassInfo.prdtTargetCode,
                            termName    : prdtPassInfo.prdtTermName,
                            termCode    : prdtPassInfo.prdtTermCode,
                            termValue   : prdtPassInfo.prdtTermValue
                        }
                    }

                    return {
                        ...state,
                        list_pchsPass : state.list_pchsPass.concat(pchsPassInfo)
                    }
                } else {
                    console.log('pchsSEQ is else... ', pchsSEQ);

                    return {
                        ...state,
                    }
                }
            } else {
                console.log("isAddSuccess is ", isAddSuccess);
            
                return {
                    ...state,
                }
            }
        } else {
            console.log("isSuccessed is ", isSuccessed);
            
            return {
                ...state,
            }
        }
    },

    [USE_PASS_SUCCESS]: (state, action) => {
        const { pchsSeq, useDateInfo } = action.parms;
        const { startUseDT, endUseDT } = useDateInfo;
        const { isSuccessed } = action.payload;
        console.log('USE_PASS_SUCCESS');

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                return produce (state, draft => {
                    const pass_info = draft.list_pchsPass.find(info => info.pchsSeq === pchsSeq);
                    if (pass_info !== undefined && pass_info !== null) {
                        const useDtInfo = pass_info.useDtInfo;
                        useDtInfo.strtDT = startUseDT;
                        useDtInfo.endDT = endUseDT;
                    }
                });
            } else {
                console.log("isUpdateSuccess is ", isUpdateSuccess);
            
                return {
                    ...state,
                }
            }
        } else {
            console.log("isSuccessed is ", isSuccessed);
            
            return {
                ...state,
            }
        }
    },

    [REFUND_PASS_SUCCESS]: (state, action) => {
        const { pchsSeq } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isRefundSuccess } = action.payload;

            if (isRefundSuccess) {
                const { cancelDt } = action.payload;

                return produce (state, draft => {
                    const pchs_info = draft.list_pchsPass.find(info => info.pchsSeq === pchsSeq);
                    if (pchs_info !== undefined && pchs_info !== null) {
                        pchs_info.cancelYN = 'Y';
                        pchs_info.cancelType = 'REFUND';
                        pchs_info.cancelDt = cancelDt;
                    }
                });
            } else {
                console.log('REFUND_PASS isRefundSuccess => ', isRefundSuccess);
                return {
                    ...state,
                }
            }
        } else {
            console.log('REFUND_PASS isSuccessed => ', isSuccessed);
            return {
                ...state,
            }
        }
    },

    [PARTIAL_REFUND_PASS_SUCCESS]: (state, action) => {
        const { pchsSeq, price } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isRefundSuccess } = action.payload;

            if (isRefundSuccess) {
                const { cancelDt } = action.payload;

                return produce (state, draft => {
                    const pchs_info = draft.list_pchsPass.find(info => info.pchsSeq === pchsSeq);
                    if (pchs_info !== undefined && pchs_info !== null) {
                        pchs_info.cancelYN = 'Y';
                        pchs_info.cancelType = 'P-REFUND';
                        pchs_info.cancelDt = cancelDt;
                    }
                });
            } else {
                console.log('PARTIAL_REFUND_PASS isRefundSuccess => ', isRefundSuccess);
                return {
                    ...state,
                }
            }
        } else {
            console.log('PARTIAL_REFUND_PASS isSuccessed => ', isSuccessed);
            return {
                ...state,
            }
        }
    },
}, initialState);

export default pass;