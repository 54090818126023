import React from 'react';
import { Modal } from 'reactstrap';

const LiveReJoinAlertModal = ({ isAlertModalOpened, handleSelectedFunc }) => {
    return (
        <Modal isOpen={isAlertModalOpened} centered backdrop={false}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="content-area dark-grey-text">
                        <span className="small mb-2"></span>
                        <div className="text-area text-center">
                            <span className="hiclass-text area-70">
                                이전 로그인 정보를 유지하시겠습니까?
                            </span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button type="button" className="btn btn-primary" onClick={() => handleSelectedFunc({ selectedFunc: "TOGGLE_RETRY_LOGIN_MODAL", value: true })}>확인</button>
                        <button type="button"  className="btn btn-white btn-outline-primary" onClick={() => handleSelectedFunc({ selectedFunc: "TOGGLE_RETRY_LOGIN_MODAL", value: false })}>닫기</button>
                    </div>                    
                </div>
            </div>
        </Modal>
    );
}

export default LiveReJoinAlertModal;