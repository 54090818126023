import React from 'react';

import ConstData from '../../../lib/ConstData';

const WebFriendConfigButton = ({ kind, friend_info, handleSelectedFunc }) => {
    switch (friend_info.applyStatus) {
        case ConstData.FRIEND_APPLY_STATUS.APPLY :
            return (
                kind === 'send' ? 
                    <td className="d-flex flex-column p-0 px-1 align-items-end justify-content-center area-40 fit-content">
                        <span className="small">수락대기</span>
                        <button type="button" className="btn btn-sm red white-text py-1 px-2 m-0" onClick={() => handleSelectedFunc({ selectedFunc: "CANCEL", friendSeq: friend_info.userSeq, mode: "web" })}>
                            취소
                        </button>
                    </td> :
                kind === 'rcv' ?
                    <td className="d-flex flex-column justify-content-center align-items-center px-1 area-20 fit-content">
                        <button type="button" className="btn btn-sm green white-text px-2 py-1 m-0 mb-1" onClick={() => handleSelectedFunc({ selectedFunc: "ACCEPT", friendSeq: friend_info.userSeq, mode: "web" })}>
                            수락
                        </button>
                        <button type="button" className="btn btn-sm red white-text px-2 py-1 m-0" onClick={() => handleSelectedFunc({ selectedFunc: "REJECT", friendSeq: friend_info.userSeq, mode: "web" })}>
                            거절
                        </button>
                    </td> :
                    <></>
            );

        case ConstData.FRIEND_APPLY_STATUS.APPROVE :
            return (
                <td className="d-flex px-1 align-items-center area-20 fit-content">
                    <button type="button" className="btn btn-sm red white-text py-1 px-2 m-0" onClick={() => handleSelectedFunc({ selectedFunc: "DELETE", friendSeq: friend_info.userSeq, mode: "web" })}>
                        삭제
                    </button>
                </td>
            );

        case ConstData.FRIEND_APPLY_STATUS.REJECT :
            return (
                <td className="d-flex flex-column px-1 align-items-end justify-content-center area-40 fit-content">
                    <span className="small">거절됨</span>
                    <div className="d-flex">
                        <button type="button" className="btn btn-sm green white-text py-1 px-2 m-0" onClick={() => handleSelectedFunc({ selectedFunc: "APPLY_SINGLE", friendSeq: friend_info.userSeq, mode: "web" })}>
                            신청
                        </button>
                        <button type="button" className="btn btn-sm red white-text py-1 px-2 m-0 ml-1" onClick={() => handleSelectedFunc({ selectedFunc: "CANCEL", friendSeq: friend_info.userSeq, mode: "web" })}>
                            취소
                        </button>
                    </div>
                </td>
            );

        default :
            return <></>;
    }
}

export default WebFriendConfigButton;