import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import ChatTitle from './ChatTitle';

import ConstData from '../../lib/ConstData';

const AloneChattingInfo = ({ isOnAirLive, isChattingLock, isAndroidTouchScreenLock, handleSelectedChatFunc }) => {

    const onChangeAndroidTabletLock = () => {
        handleSelectedChatFunc({ selectedFunc: "TOGGLE_TABLET_LOCK" });
    }

    const onChangeChattingLock = (e) => {
        // setIsChattingLocked(e.target.checked);
        // handleSelectedChatFunc({ selectedFunc: 'TOGGLE_CHATTING_LOCK', status: !isChattingLocked });
        handleSelectedChatFunc({ selectedFunc: 'TOGGLE_CHATTING_LOCK' });
    }

    return (
        <div className="title-area main justify-content-between hiclasstv-blue">
            <div className="text-area fit-content justify-content-start" style={{ minWidth: '40%' }}>
                <ChatTitle isLocalVersion={ConstData.IS_LOCAL_VERSION} />
            </div>
            <div className="d-flex align-items-center justify-content-end mt-1 white-text font-xsm" /* style={{ visibility: 'collapse' }} */>
                <span>태블릿 잠금</span>
                <div className="custom-control custom-switch ml-1">
                    <input type="checkbox" className="custom-control-input" id="tabletLock" checked={isAndroidTouchScreenLock} onChange={onChangeAndroidTabletLock} />
                    <label className="custom-control-label" htmlFor="tabletLock" />
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-1 white-text font-xsm">
                <span>채팅방 잠금</span>
                <div className="custom-control custom-switch ml-1">
                    <input type="checkbox" className="custom-control-input" id="chattingLock" checked={isChattingLock} disabled={isAndroidTouchScreenLock} onChange={onChangeChattingLock} />
                    <label className="custom-control-label" htmlFor="chattingLock" />
                </div>
            </div>
            {/*<div className='d-flex custom-control custom-switch pl-2'>
                <input type="checkbox" className="custom-control-input" id="frozenChat" checked={isFrozenChat} onChange={onChangeFrozenChat} disabled={false} />
                <label className='d-flex custom-control-label align-items-center white-text font-sm' htmlFor='frozenChat'>채팅 잠금</label>                    
            </div>*/}
            <div className="d-flex area-10">
                <button type="button" className="d-flex p-1 rounded" title="더보기" onClick={() => handleSelectedChatFunc({ selectedFunc: "LIST_LIVE" })}>
                    <FaBars className="d-flex font-md white-text" />
                </button>
            </div>
        </div>
    );
}

export default AloneChattingInfo;