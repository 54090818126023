import React from 'react';
import { Modal } from 'reactstrap';

const LiveAlertModalRemove = ({ live_info, isLiveAlertRemoveModalOpened, handleSelectedFunc }) => {
    
    return(
        <Modal isOpen={isLiveAlertRemoveModalOpened} centered  backdrop={false}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        <span>클래스 [</span>
                        <div className="title">
                            <span className="hiclass-text" title={live_info.liveName}><b>{live_info.liveName}</b></span>
                        </div>
                        <span>] 가 삭제되었습니다.</span>
                    </div>
                    <div className="content-area dark-grey-text">
                        <span className="small mb-2">[클래스 진행자]</span>
                        <div className="text-area text-center area-80">
                            <span className="hiclass-text" title={live_info.teacher_info.userNickname}>
                                <b>{live_info.teacher_info.userNickname}</b>
                            </span>
                        </div>
                    </div>
                    <button type="button" className="btn btn-md green white-text mr-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "LIVE_ALERT_TOGGLE" })}>확인</button>
                </div>
            </div>
        </Modal>
    );
}
export default LiveAlertModalRemove;