/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';

const QnA003 = ({ isOpened, onChangeOpenedFlag }) => {
    useEffect(() => {
        let content = document.getElementById("qna03");

        if (isOpened) {
            content.style.maxHeight = content.scrollHeight + "px";
        } else {
            content.style.maxHeight = null;
        }
    }, [isOpened]);

    return (
        <div className="accordion">
            <div className="accordion-title" onClick={() => onChangeOpenedFlag(2)}>
                <span className="hiclasstv-deepDarkgrey-text button">
                    <b>3. HiClassTV를 사용할 시 어떤 장치가 필요한가요?</b>
                </span>
            </div>
            <div className="accordion-content hiclasstv-darkgrey-text" id="qna03">
                <span className="d-flex" style={{ minWidth: 'max-content' }}>A.&nbsp;</span>
                <p>
                    기본적으로 Windows기반의 PC와 Android기반의 태블릿과 휴대폰에서 상용 가능하며  Chrome 기반의 웹브라우저를 통해서 서비스가 이루어집니다. <br />
                    보조 장치로는 전자칠판을 사용하여 학습의 효과를 극대화 시킬 수 있습니다. <br />
                    특히 당사의 전자칠판과 관련된 여러 도구는 어떤 상황에서도 교육 효과와 효율성을 높이는데 특화되어 있습니다.
                </p>
            </div>
        </div>
    );
}
export default QnA003;