import { createAction, handleActions } from 'redux-actions'
import produce from 'immer';
import createRequestThunk from '../lib/createRequestThunk';

import ConstData from '../lib/ConstData';

export const PRDT_SERVICE_INIT           = 'service/PRDT_SERVICE_INIT';
export const PRDT_SERVICE_STATE_INIT     = 'service/PRDT_SERVICE_STATE_INIT';


const initialState = {
    list_prdtService        : [],
};

const service = handleActions({
    [PRDT_SERVICE_INIT]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isLoginSuccess } = action.payload;

            if (isLoginSuccess) {
                const { isUsablePass, list_prdtService } = action.payload;
                //console.log('PRDT_SERVICE_INIT - list_prdtService => ', list_prdtService);

                if (isUsablePass) {
                    if (list_prdtService !== undefined && list_prdtService !== null && list_prdtService.length > 0) {
                        return {
                            ...state,
                            list_prdtService        : list_prdtService.map(row => ({
                                svcSeq              : row.SVC_SEQ,
                                svcName             : row.SVC_NM,
                                funcCode            : row.FUNC_CD,
                                funcAttributeText   : row.FUNC_ATRB_STRN
                            }))
                        }
                    } else {
                        console.log('list_prdtService is ', list_prdtService);
                        return {
                            ...state
                        }
                    }
                } else {
                    return {
                        ...state
                    }
                }
            } else {
                return {
                    ...state
                }
            }
        } else {
            return {
                ...state
            }
        }
    },

    [PRDT_SERVICE_STATE_INIT]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isLogoutSuccess } = action.payload;

            if (isLogoutSuccess) {
                return {
                    ...state,
                    list_prdtService    : []
                }
            } else {
                return {
                    ...state
                }
            }
        } else {
            return {
                ...state
            }
        }
    }
}, initialState);

export default service;