import React, { useMemo } from 'react';

import { getMemberProfileImage } from '../../../lib/func/ExportFunction';

const CustomProfileImage = ({ url, alt, style }) => {
    const profileImgUrl = useMemo(() => getMemberProfileImage(url), [url]);
    
    return (
        <img className="hiclasstv-profile-img" src={profileImgUrl} style={style} alt={alt} />
    );
};

export default CustomProfileImage;