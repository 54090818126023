import React from 'react';
import WebRequestedWatchLive from './WebRequestedWatchLive';
import WebWatchLiveInfo from './WebWatchLiveInfo';

const WebWatchLiveList = ({ isGuestYn, broadcastOpenYN, list_watchLive, list_requestedWatchLive, handleSelectedFunc }) => {
    return (
        list_watchLive !== undefined && list_watchLive !== null &&
        list_requestedWatchLive !== undefined && list_requestedWatchLive !== null &&
        (list_watchLive.length > 0 || list_requestedWatchLive.length > 0) ?
        <table className="d-flex flex-column table table-sm table-hover flex-list wlive-list mb-0">
            <thead>
                <tr className="d-flex">
                    <th className="area-80">
                        <div className="only-web w-100">
                            <span className="area-60">
                                <b>클래스 이름</b>
                            </span>
                            <span className="area-40">
                                <b>진행자</b>
                            </span>
                        </div>
                        <div className="only-mobile w-100">
                            <b>클래스 정보</b>
                        </div>
                    </th>
                    <th className="area-20">
                        <b>상태</b>
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    list_watchLive.map(live => (
                        <WebWatchLiveInfo
                            key={'watch-' + live.liveSeq}
                            isGuestYn={isGuestYn}
                            broadcastOpenYN={broadcastOpenYN}
                            live_info={live}
                            handleSelectedFunc={handleSelectedFunc}
                        />
                    ))
                }
                {
                    list_requestedWatchLive.map(live => (
                        <WebRequestedWatchLive
                            key={'requested-watch-' + live.liveSeq}
                            live_info={live}
                            handleSelectedFunc={handleSelectedFunc}
                        />
                    ))
                }
            </tbody>
        </table> :
        <div className="view m-3 text-center grey-text">
            현재 참여 중인 클래스 목록이 없습니다.
        </div>
    )
}

export default WebWatchLiveList;