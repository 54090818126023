import React from 'react';

import DefaultProfileImage from '../../user/profile/DefaultProfileImage';
import WebFriendConfigButton from '../button/WebFriendConfigButton';

const WebConfigFriendInfo = ({ friend_info, handleSelectedFunc }) => {
    return (
        <tr className="d-flex px-2">
            <td className="d-flex align-items-center justify-content-center area-15 fit-content">
                <DefaultProfileImage url={friend_info.profileImgUrl} alt={friend_info.userNickname} />
            </td>
            <td className="d-flex full">
                <div className="d-flex flex-column text-area">
                    <span className="hiclass-text" title={friend_info.userNickname}><b>{friend_info.userNickname}</b></span>
                    <span className="hiclass-text small grey-text" title={friend_info.userProfile}>{friend_info.userProfile}</span>
                </div>
            </td>
            <WebFriendConfigButton friend_info={friend_info} handleSelectedFunc={handleSelectedFunc} />
        </tr>
    );
}

export default WebConfigFriendInfo;