import React, { useState, useEffect, useRef } from "react";
import { UncontrolledCollapse } from "reactstrap";
import { FaAngleLeft, FaPlusCircle } from "react-icons/fa";

import ConstData from "../../../lib/ConstData";

import WebPagination from "../../etc/WebPagination";
import WebConfigMemberInfo from "../list/WebConfigMemberInfo";
import WebUserLoginResetAlertModal from "../modal/WebUserLoginResetAlertModal";
import WebUserPWResetAlertModal from "../modal/WebUserPWResetAlertModal";
import WebSubDomainConfigAdminUserAlertModal from "../modal/WebSubDomainConfigAdminUserAlertModal";
import WebSubDomainCreateAdminUserAlertModal from "../modal/WebSubDomainCreateAdminUserAlertModal";
import WebWarningAlertModal from "../modal/WebWarningAlertModal";
import WebUserStatus from "../../etc/list/WebUserStatus";
import WebCreateUsers from "./WebCreateUsers";

const WebSubDomainDetailInfo = ({ userSeq, userKind, isStandardType, subDomain_info, handleSelectedFunc }) => {

    const [list, setList] = useState([]);
    const [limit, setLimit] = useState(30);
    const [page, setPage] = useState(1);
    // const offset = (page - 1) * limit;
    const [offset, setOffset] = useState((page - 1) * limit);
    const [blockNum, setBlockNum] = useState(0);

    const [isUserResetStatusModalOpened, setIsUserResetStatusModalOpened] = useState(false);
    const [isPWResetStatusModalOpened, setIsPWResetStatusModalOpened] = useState(false);
    const [isConfigAdminModalOpened, setIsConfigAdminModalOpened] = useState(false);
    const [isCreateAdminModalOpened, setIsCreateAdminModalOpened] = useState(false);
    const [isWarningAlertOpened, setIsWarningAlertOpened] = useState(false);
    const [lWarningKind, setWarningKind] = useState("");

    const [selectedMemberInfo, setSelectedMemberInfo] = useState({
        mode: "",
        subDomainSeq: -1,
        subDomainName: "",
        userSeq: -1,
        userNickname: "",
        userProfile: "",
        profileImgUrl: "",
        isConfigAdmin: false,
        isCreateAdmin: false
    });

    const [isOpened, setIsOpened] = useState(false);

    const enterTimeRef = useRef(0);
    const exitTimeRef = useRef(0);

    const toggleRef = useRef(null);

    useEffect(() => {
        if (subDomain_info.list_member) {
            setList(subDomain_info.list_member);
        }    
    }, [subDomain_info.list_member]);

    useEffect(() => {
        if (subDomain_info.list_createUser) {
            toggleRef.current.updateCreateUsers(subDomain_info.list_createUser);
        }
    }, [subDomain_info.list_createUser]);

    useEffect(() => {
        let offset = (page - 1) * limit;
        console.log(`page[${page}], offset[${offset}]`);
        setOffset(offset);
    }, [page, limit]);

    const toggleModalOpened = (isModalOpened, mode, subDomainSeq, memberInfo, userStatus) => {
        if (userStatus !== ConstData.LOGIN_STATUS.LOG_OUT) {
            if (mode === "web" && memberInfo !== undefined && memberInfo !== null && memberInfo.userSeq === userSeq) {
                console.log("웹에서 자기 자신을 로그아웃할 수 없음!!!!");
                toggleWarningAlertModalOpened(true, "RESET_USER_STATUS");
            } else {
                if (isModalOpened) {
                    setSelectedMemberInfo({
                        ...selectedMemberInfo,
                        mode,
                        subDomainSeq,
                        subDomainName: "",
                        userSeq: memberInfo.userSeq,
                        userNickname: memberInfo.userNickname,
                        userProfile: memberInfo.userProfile,
                        profileImgUrl: memberInfo.profileImgUrl,
                        isConfigAdmin: memberInfo.isConfigAdmin
                    });
                } else {
                    setSelectedMemberInfo({
                        mode: "",
                        subDomainSeq: -1,
                        subDomainName: "",
                        userSeq: -1,
                        userNickname: "",
                        userProfile: "",
                        profileImgUrl: "",
                        isConfigAdmin: false,
                        isCreateAdmin: false
                    });
                }

                setIsUserResetStatusModalOpened(isModalOpened);
            }
        } else {
            console.log("toggleModalOpened() userStatus is LOG_OUT");
        }
    }

    const togglePWModalOpened = (isModalOpened, subDomainSeq, memberInfo) => {
        if (memberInfo !== undefined && memberInfo !== null && memberInfo.userSeq === userSeq) {
            console.log("자기 자신의 비밀번호는 회원정보 수정을 이용해야함!!!!");
            toggleWarningAlertModalOpened(true, "RESET_USER_PW");
        } else {
            if (isModalOpened) {
                setSelectedMemberInfo({
                    ...selectedMemberInfo,
                    mode: "",
                    subDomainSeq,
                    subDomainName: "",
                    userSeq: memberInfo.userSeq,
                    userNickname: memberInfo.userNickname,
                    userProfile: memberInfo.userProfile,
                    profileImgUrl: memberInfo.profileImgUrl,
                    isConfigAdmin: memberInfo.isConfigAdmin
                });
            } else {
                setSelectedMemberInfo({
                    mode: "",
                    subDomainSeq: -1,
                    subDomainName: "",
                    userSeq: -1,
                    userNickname: "",
                    userProfile: "",
                    profileImgUrl: "",
                    isConfigAdmin: false,
                    isCreateAdmin: false
                });
            }

            setIsPWResetStatusModalOpened(isModalOpened);
        }
    }

    const toggleCreateSubDomainAdminModalOpened = (isModalOpened, subDomainSeq, subDomainName, memberInfo) => {
        if (memberInfo !== undefined && memberInfo !== null && memberInfo.userSeq === userSeq) {
            toggleWarningAlertModalOpened(true, "SUB_DOMAIN_CREATE_ADMIN");
        } else {
            if (isModalOpened) {
                setSelectedMemberInfo({
                    ...selectedMemberInfo,
                    mode: "",
                    subDomainSeq,
                    subDomainName,
                    userSeq: memberInfo.userSeq,
                    userNickname: memberInfo.userNickname,
                    userProfile: memberInfo.userProfile,
                    profileImgUrl: memberInfo.profileImgUrl,
                    isCreateAdmin: memberInfo.isCreateAdmin
                });
            } else {
                setSelectedMemberInfo({
                    mode: "",
                    subDomainSeq: -1,
                    subDomainName: "",
                    userSeq: -1,
                    userNickname: "",
                    userProfile: "",
                    profileImgUrl: "",
                    isConfigAdmin: false,
                    isCreateAdmin: false
                });
            }

            setIsCreateAdminModalOpened(isModalOpened);
        }
    }

    const toggleSubDomainAdminModalOpened = (isModalOpened, subDomainSeq, subDomainName, memberInfo) => {
        if (memberInfo !== undefined && memberInfo !== null && memberInfo.userSeq === userSeq) {
            toggleWarningAlertModalOpened(true, "SUB_DOMAIN_ADMIN");
        } else {
            if (isModalOpened) {
                setSelectedMemberInfo({
                    ...selectedMemberInfo,
                    mode: "",
                    subDomainSeq,
                    subDomainName,
                    userSeq: memberInfo.userSeq,
                    userNickname: memberInfo.userNickname,
                    userProfile: memberInfo.userProfile,
                    profileImgUrl: memberInfo.profileImgUrl,
                    isConfigAdmin: memberInfo.isConfigAdmin
                });
            } else {
                setSelectedMemberInfo({
                    mode: "",
                    subDomainSeq: -1,
                    subDomainName: "",
                    userSeq: -1,
                    userNickname: "",
                    userProfile: "",
                    profileImgUrl: "",
                    isConfigAdmin: false,
                    isCreateAdmin: false
                });
            }

            setIsConfigAdminModalOpened(isModalOpened);
        }
    }

    const toggleWarningAlertModalOpened = (isModalOpened, warningKind) => {
        if (isModalOpened) {
            setWarningKind(warningKind);
        } else {
            setWarningKind("");
        }

        setIsWarningAlertOpened(isModalOpened);
    }

    const onClickResetUserStatus = (mode, subDomainSeq, userSeq) => {
        handleSelectedFunc({ selectedFunc: "RESET_USER_STATUS", selectedUserSeq: userSeq, subDomainSeq, mode });
        setIsUserResetStatusModalOpened(false);
    }

    const onClickResetUserPW = (subDomainSeq, userSeq, password) => {
        handleSelectedFunc({ selectedFunc: "RESET_USER_PW", selectedUserSeq: userSeq, subDomainSeq, password });
        setIsPWResetStatusModalOpened(false);
    }

    const onClickConfigAdminIcon = (subDomainSeq, memberInfo) => {
        if (memberInfo !== undefined && memberInfo !== null && memberInfo.isConfigAdmin) {
            handleSelectedFunc({ selectedFunc: "REMOVE_SUB_DOMAIN_ADMIN", subDomainSeq, selectedUserSeq: memberInfo.userSeq });
        } else {
            handleSelectedFunc({ selectedFunc: "ADD_SUB_DOMAIN_ADMIN", subDomainSeq, selectedUserSeq: memberInfo.userSeq });
        }

        setIsConfigAdminModalOpened(false);
    }

    const onClickCreateAdminIcon = (subDomainSeq, memberInfo) => {
        if (memberInfo !== undefined && memberInfo !== null && memberInfo.isCreateAdmin) {
            handleSelectedFunc({ selectedFunc: "REMOVE_CREATE_SUB_DOMAIN_ADMIN", subDomainSeq, selectedUserSeq: memberInfo.userSeq });
        } else {
            handleSelectedFunc({ selectedFunc: "ADD_CREATE_SUB_DOMAIN_ADMIN", subDomainSeq, selectedUserSeq: memberInfo.userSeq });
        }

        setIsCreateAdminModalOpened(false);
    }

    const onClickAddNewUsers = () => {

    }

    const goToPassListPage = ({ subDomainSeq }) => {
        if (subDomainSeq === 1) {
            handleSelectedFunc({ selectedFunc: "GET_PCHS_LIST", kind: "USER", subDomainSeq, selectedUserSeq: userSeq });
        } else {
            handleSelectedFunc({ selectedFunc: "GET_PCHS_LIST", kind: "SUB_DOMAIN", subDomainSeq });
        }
    }

    const onEnteringEvent = () => {
        enterTimeRef.current = Date.now();
        console.log("onEnteringEvent - ", enterTimeRef.current);
        //setIsOpened(true);
    }

    const onEnteredEvent = () => {
        let enteredTime = Date.now() - enterTimeRef.current;
        console.log("onEnteredEvent - ", enteredTime);
        setIsOpened(true);
    }

    const onExitingEvent = () => {
        exitTimeRef.current = Date.now();
        console.log("onExitingEvent - ", exitTimeRef.current);
        if (toggleRef.current) {
            toggleRef.current.updateCreateUsers([
                {
                    userID: "",
                    userPW: "",
                    userNickname: "",
                    userProfile: "",
                    isIdCheckOK: false,
                    isJoinSuccess: false,
                    isExist: false,
                    userSeq: -1,
                    errMsg: ""
                }
            ]);
        }
        setIsOpened(false);
    }

    const onExitedEvent = ()=> {
        let exitedTime = Date.now() - exitTimeRef.current;
        console.log("onExitedEvent - ", exitedTime);
        setIsOpened(false);
    }

    return (
        <div className="web-main list-area" style={{ maxWidth: '900px' }}>
            <div className="d-flex flex-column w-100">
                <div className="d-flex my-2">
                    <button type="button" className="d-flex hiclasstv-btn b-none rounded align-items-center py-1 black-text" title="서브 도메인 목록 보기" onClick={() => handleSelectedFunc({ selectedFunc: "GOTO_SUB_DOMAIN_HOME" })}>
                        <FaAngleLeft className="d-flex m-1" />
                        <span className="small">목록으로</span>
                    </button>
                </div>
                <table className="d-flex flex-column table-sm flex-list mb-0">
                    <thead>
                        <tr className="d-flex">
                            <th className="area-35">
                                <b>서브 도메인 이름</b>
                            </th>
                            <th className="area-40">
                                <div className="only-web w-100">
                                    <span className="area-60"><b>접속현황</b></span>
                                    <span className="area-40"><b>멤버</b></span>
                                    {/* <span className="w-100"><b>접속현황</b></span> */}
                                </div>
                                <div className="only-mobile w-100" style={{ justifyContent: 'center' }}>
                                    <b>사용자</b>
                                </div>
                            </th>
                            <th className="area-25">
                                <div className="only-web w-100">
                                    <span className="area-50"><b>팀업</b></span>
                                    <span className="area-50"><b>클래스</b></span>
                                </div>
                                <div className="only-mobile w-100">
                                    <b>팀업/클래스</b>
                                </div>
                            </th>
                            {/* <th className="area-15">
                                {
                                    isStandardType &&
                                    <b>이용권</b>
                                }
                            </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="d-flex">
                            <td className="d-flex align-items-center justify-content-center area-35">
                                <div className="text-area" style={{ textAlign: 'center' }}>
                                    <span className="info-title hiclass-text" title={subDomain_info.subDomainName}>{subDomain_info.subDomainName}</span>
                                    {/* <FaCog className="font-sm grey-text ml-1 button" style={{ verticalAlign: 'text-top' }} onClick={() => goToConfigPage({ subDomain_info: { subDomainSeq: subDomain_info.subDomainSeq, subDomainName: subDomain_info.subDomainName, selectedIdx: idx } })} /> */}
                                </div>
                            </td>
                            <td className="landscape-to-portrait align-items-center justify-content-center small area-40">
                                {/* <div className="list-row justify-content-center w-100">
                                    <WebUserStatus kind={"userStatus"} list_member={subDomain_info.list_member} />
                                </div> */}
                                <div className="list-row justify-content-center area-60">
                                    <WebUserStatus kind={"userStatus"} list_member={subDomain_info.list_member} />
                                </div>
                                <div className="list-row justify-content-center area-40">
                                    <div className="add-btn-area">
                                        <button type="button" id="addNewUsers" className="hiclasstv-btn-transparent hiclasstv-blue-text p-0">
                                            <FaPlusCircle className="font-lg" />
                                            <span className="font-weight-bold ml-2">사용자 추가</span>
                                        </button>
                                    </div>
                                </div>
                            </td>
                            <td className="landscape-to-portrait small area-25">
                                <div className="list-row area-50">
                                    <span className="only-mobile">T :&nbsp;</span>{subDomain_info.chatRoomCnt}
                                </div>
                                <div className="list-row area-50">
                                    <span className="only-mobile">L :&nbsp;</span>
                                    {
                                        subDomain_info.liveOnCnt > 0 ?
                                            <span className="green-text font-weight-bold">{subDomain_info.liveOnCnt}</span> :
                                            <span>{subDomain_info.liveOnCnt}</span>
                                    }
                                    <span>&nbsp;{" / " + subDomain_info.liveCnt}</span>
                                </div>
                            </td>
                            {/* <td className="d-flex align-items-center justify-content-center area-15">
                                {
                                    isStandardType &&
                                    <button type="button" className="btn btn-sm btn-outline-primary px-2 py-1 m-0" onClick={() => goToPassListPage({ subDomainSeq: subDomain_info.subDomainSeq })}>
                                        조회
                                    </button>
                                }
                            </td> */}
                        </tr>
                        <tr className="d-flex b-none">
                            <td className="d-flex align-items-center w-100 p-0">
                                <UncontrolledCollapse className="w-100" toggler="#addNewUsers" defaultOpen={false} onEntered={onEnteredEvent} onEntering={onEnteringEvent} onExiting={onExitingEvent} onExited={onExitedEvent}>
                                    <WebCreateUsers
                                        ref={toggleRef}
                                        subDomainSeq={subDomain_info.subDomainSeq}
                                        handleSelectedFunc={handleSelectedFunc}
                                    />
                                </UncontrolledCollapse>
                            </td>
                        </tr>
                        <tr className="d-flex">
                            <td className="d-flex w-100 p-0">
                                <WebPagination
                                    total={subDomain_info.list_member.length}
                                    limit={limit}
                                    page={page}
                                    setPage={setPage}
                                    blockNum={blockNum}
                                    setBlockNum={setBlockNum}
                                />
                            </td>
                        </tr>
                        <tr className="d-flex">
                            <td className="d-flex w-100 p-0">
                                <table className="d-flex flex-column table-sm sub-list table-hover w-100 mb-0">
                                    <thead>
                                        <tr className="d-flex">
                                            <th className="d-flex align-items-center justify-content-center area-40">
                                                <b>닉네임</b>
                                            </th>
                                            <th className="d-flex align-items-center justify-content-center area-25">
                                                <b>상태</b>
                                            </th>
                                            <th className="area-35">
                                                <div className="only-web w-100">
                                                    <span className="area-50"><b>마지막 로그인 시간</b></span>
                                                    <span className="area-50"><b>가입일</b></span>
                                                </div>
                                                <div className="only-mobile w-100" style={{ justifyContent: 'center' }}>
                                                    <b>접속시간/가입일</b>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list && list.length > 0 ?
                                                list.slice(offset, offset + limit).map(member => (
                                                    <WebConfigMemberInfo
                                                        key={"config-" + subDomain_info.subDomainSeq + "-member-" + member.userSeq}
                                                        userKind={userKind}
                                                        subDomain_info={subDomain_info}
                                                        member_info={member}
                                                        toggleModalOpened={toggleModalOpened}
                                                        togglePWModalOpened={togglePWModalOpened}
                                                        toggleSubDomainAdminModalOpened={toggleSubDomainAdminModalOpened}
                                                        toggleCreateSubDomainAdminModalOpened={toggleCreateSubDomainAdminModalOpened}
                                                    />
                                                )) :
                                                <tr className="d-flex">
                                                    <td className="d-flex align-items-center justify-content-center w-100 p-0">
                                                        <div className="view grey-text m-3" style={{ fontSize: '.9rem' }}>
                                                            관리 가능한 회원 목록이 없습니다.
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                    {/* <tfoot>
                        <WebPagination
                            total={subDomain_info.list_member.length}
                            limit={limit}
                            page={page}
                            setPage={setPage}
                            blockNum={blockNum}
                            setBlockNum={setBlockNum}
                        />
                    </tfoot> */}
                </table>
                {/* <footer>
                    <WebPagination
                        total={subDomain_info.list_member.length}
                        limit={limit}
                        page={page}
                        setPage={setPage}
                        blockNum={blockNum}
                        setBlockNum={setBlockNum}
                    />
                </footer> */}
            </div>
            {
                isUserResetStatusModalOpened &&
                <WebUserLoginResetAlertModal
                    isModalOpened={isUserResetStatusModalOpened}
                    selectedMemberInfo={selectedMemberInfo}
                    toggleModalOpened={toggleModalOpened}
                    onClickResetUserStatus={onClickResetUserStatus}
                />
            }
            {
                isPWResetStatusModalOpened &&
                <WebUserPWResetAlertModal
                    isModalOpened={isPWResetStatusModalOpened}
                    selectedMemberInfo={selectedMemberInfo}
                    toggleModalOpened={togglePWModalOpened}
                    onClickResetUserPW={onClickResetUserPW}
                />
            }
            {
                isConfigAdminModalOpened &&
                <WebSubDomainConfigAdminUserAlertModal
                    isModalOpened={isConfigAdminModalOpened}
                    selectedMemberInfo={selectedMemberInfo}
                    toggleModalOpened={toggleSubDomainAdminModalOpened}
                    onClickAdminIcon={onClickConfigAdminIcon}
                />
            }
            {
                isCreateAdminModalOpened &&
                <WebSubDomainCreateAdminUserAlertModal
                    isModalOpened={isCreateAdminModalOpened}
                    selectedMemberInfo={selectedMemberInfo}
                    toggleModalOpened={toggleCreateSubDomainAdminModalOpened}
                    onClickAdminIcon={onClickCreateAdminIcon}
                />
            }
            {
                isWarningAlertOpened &&
                <WebWarningAlertModal
                    isModalOpened={isWarningAlertOpened}
                    warningKind={lWarningKind}
                    toggleModalOpened={toggleWarningAlertModalOpened}
                />
            }
        </div>
    );
}

export default WebSubDomainDetailInfo;