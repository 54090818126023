import React from 'react';

import FooterMenu from '../../components/etc/FooterMenu';

const ContentTemplate = ({ children, selectMainTap, changeWindowPentalkBoard }) => {
    return (
        <>
            <div className="content-template-pentalk">
                {children}
            </div>
            <FooterMenu
                selectMainTap={selectMainTap}
                changeWindowPentalkBoard={changeWindowPentalkBoard}
            />
        </>
    )
}

export default ContentTemplate;