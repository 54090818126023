import React, { Component } from "react";
import { connect } from "react-redux";

import WebHiClassInfo from "../../components/etc/info/WebHiClassInfo";

import {
    loadDownloadInfo
} from "../../modules/hiclasstv";

class WebHiClassInfoContainer extends Component {
    componentDidMount = () => {
        var infoView = document.getElementById("infoView");
        infoView.parentElement.scrollTo(0, 0);
    }

    handleSelectedFunc = ({ selectedFunc }) => {
        switch (selectedFunc) {
            case "GET_DOWNLOAD_INFO":
                this.props.loadDownloadInfo();
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <div className="web-main info-area align-items-center" id="infoView">
                <WebHiClassInfo
                    currentInfoTap={this.props.currentInfoTap}
                    download_info={this.props.download_info}
                    handleSelectedFunc={this.handleSelectedFunc}
                />
            </div>
        );
    }
}

export default connect(
    ({ hiclasstv }) => ({
        currentInfoTap      : hiclasstv.currentInfoTap,
        download_info       : hiclasstv.download_info
    }),
    {
        loadDownloadInfo
    }
)(WebHiClassInfoContainer);