import React, { useState, useEffect } from 'react';
import WebLocalServerInfo from './WebLocalServerInfo';

const WebLocalServerList = ({ list_localServer, list_localSite, handleSelectedFunc }) => {
    return (
        <div className="d-flex flex-column w-100 my-2">
            {
                list_localServer && list_localServer.length > 0 ?
                    <table className="d-flex flex-column table-sm flex-list mb-0">
                        <thead>
                            <tr className="d-flex">
                                <th className="d-flex align-items-center justify-content-center area-10">
                                    <b>Seq.</b>
                                </th>
                                <th className="d-flex align-items-center justify-content-center area-25">
                                    <b>Local Site</b>
                                </th>
                                <th className="d-flex align-items-center justify-content-center area-30">
                                    <b>Server Name</b>
                                </th>
                                <th className="d-flex align-items-center justify-content-center area-15">
                                    <b>Auth Code</b>
                                </th>
                                <th className="d-flex align-items-center justify-content-center area-10">
                                    <b>Port Status</b>
                                </th>
                                <th className="d-flex align-items-center justify-content-center area-10" />
                                {/*<th className="d-flex align-items-center justify-content-center area-10" />*/}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list_localServer.map((localServer, idx) => (
                                    <WebLocalServerInfo 
                                        key={"config-local-" + idx}
                                        idx={idx}
                                        list_localSite={list_localSite}
                                        localServer_info={localServer}
                                        handleSelectedFunc={handleSelectedFunc}
                                    />
                                ))
                            }
                        </tbody>
                    </table> :
                    <div className="d-flex view m-3 justify-content-center grey-text" style={{ fontSize: '1rem' }}>
                        관리 가능한 로컬 서버 목록이 없습니다.
                    </div>
            }
        </div>
    );
}
export default WebLocalServerList;