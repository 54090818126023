import React, { useState, useEffect } from 'react';
import { BsX } from 'react-icons/bs';

import CustomProfileImage from '../../user/profile/CustomProfileImage';

const DeleteExitChatRoom = ({ userSeq, selectedChatRoomInfo, handleSelectedFunc }) => {
    const [lSelectedChatRoomMembers, setSelectedChatRoomMembers] = useState([]);
    const [lSelectedChatRoomEntryMembers, setSelectedChatRoomEntryMembers] = useState([]);

    useEffect(() => {
        if (userSeq >= 0 && selectedChatRoomInfo.list_member !== undefined && selectedChatRoomInfo.list_member !== null) {
            const list_member = selectedChatRoomInfo.list_member.filter(info => info.userSeq !== userSeq);
            setSelectedChatRoomMembers(list_member);
        }
    }, [userSeq, selectedChatRoomInfo.list_member]);

    useEffect(() => {
        if (lSelectedChatRoomMembers.length > 0) {
            const list_entryMember = lSelectedChatRoomMembers.filter(info => info.entryYN === 'Y');
            setSelectedChatRoomEntryMembers(list_entryMember);
        }
    }, [lSelectedChatRoomMembers]);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" onClick={() => handleSelectedFunc({selectedFunc:"NONE"})}>
                    <BsX />
                </span>
            </div>
            {
                lSelectedChatRoomEntryMembers.length > 0 ?
                    <>
                        <div className="d-flex text-center my-2 dark-grey-text small">
                            팀업에 참여 중인 멤버가 존재합니다.<br />
                            팀업을 삭제할 수 없습니다.
                        </div>
                        <div className="content-area dark-grey-text my-0">
                            <span className="small mb-1">[팀업 이름]</span>
                            <div className="text-area text-center area-80 mb-3">
                                <span className="hiclass-text" title={selectedChatRoomInfo.chatRoomName}>
                                    <b>{selectedChatRoomInfo.chatRoomName}</b>
                                </span>
                            </div>
                            <span className="small mb-1">[참여 중인 멤버]</span>
                            {
                                lSelectedChatRoomEntryMembers.map(
                                    member => (
                                        <div className="text-area text-center area-80" key={member.userSeq}>
                                            <div className="area-15 fit-content">
                                                <CustomProfileImage url={member.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={member.userNickname} />
                                            </div>
                                            <span className="hiclass-text" title={member.userNickname}><b>{member.userNickname}</b></span>
                                        </div>
                                    )
                                )
                            }
                        </div>
                        <div className="d-flex justify-content-center my-2">
                            <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={()=>handleSelectedFunc({selectedFunc:"NONE"})}>닫기</button>
                        </div>
                    </> :
                    <>
                        {
                            lSelectedChatRoomMembers.length > 0 ?
                                <>
                                    <div className="d-flex text-center my-2 dark-grey-text small">
                                        현재 참여 중인 팀업입니다.<br />
                                        해당 팀업에는 멤버가 존재합니다.<br />
                                        나갈 경우, 해당 팀업은 삭제됩니다<br />
                                        삭제하시겠습니까?
                                    </div>
                                    <div className="content-area dark-grey-text my-0">
                                        <span className="small mb-1">[팀업 이름]</span>
                                        <div className="text-area text-center area-80 mb-3">
                                            <span className="hiclass-text" title={selectedChatRoomInfo.chatRoomName}>
                                                <b>{selectedChatRoomInfo.chatRoomName}</b>
                                            </span>
                                        </div>
                                        <span className="small mb-1">[멤버]</span>
                                        {
                                            lSelectedChatRoomMembers.length > 1 ?
                                                <div className="text-area text-center area-80">
                                                    <span className="hiclass-text" title={lSelectedChatRoomMembers.map(mbr => mbr.userNickname).join(" | ")}><b>{lSelectedChatRoomMembers[0].userNickname}</b></span>
                                                    <span className="hiclass-text ml-1">외 {lSelectedChatRoomMembers.length - 1}명</span>
                                                </div> :
                                                <div className="text-area text-center area-80">
                                                    <span className="hiclass-text" title={lSelectedChatRoomMembers.map(mbr => mbr.userNickname)}>
                                                        <b>{lSelectedChatRoomMembers.map(mbr => mbr.userNickname)}</b>
                                                    </span>
                                                </div>
                                        }
                                    </div>
                                    <div className="d-flex justify-content-center my-2">
                                        <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "DELETE_EXIT", chatRoomSeq: selectedChatRoomInfo.chatRoomSeq })}>예</button>
                                        <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>아니오</button>
                                    </div>
                                </> :
                                <>
                                    <div className="d-flex text-center my-2 dark-grey-text small">
                                        현재 참여 중인 팀업입니다.<br />
                                        나갈 경우, 해당 팀업은 삭제됩니다.<br />
                                        삭제하시겠습니까?
                                    </div>
                                    <div className="content-area dark-grey-text my-0">
                                        <span className="small mb-1">[팀업 이름]</span>
                                        <div className="text-area text-center area-80">
                                            <span className="hiclass-text" title={selectedChatRoomInfo.chatRoomName}>
                                                <b>{selectedChatRoomInfo.chatRoomName}</b>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center my-2">
                                        <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "DELETE_EXIT", chatRoomSeq: selectedChatRoomInfo.chatRoomSeq })}>예</button>
                                        <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>아니오</button>
                                    </div>
                                </>
                        }
                    </>
            }
        </div>
    );
}

export default DeleteExitChatRoom;