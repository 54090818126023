import React from 'react';

import MainContainer from '../containers/MainContainer';
import WebContentTemplate from '../components/template/WebContentTemplate';
import WebPromotionContainer from '../containers/etc/WebPromotionContainer';

const WebPromotionPage = ({ browserType, currentPageType, cookies, routeProps, isPromotionInfo }) => {
    return (
        <MainContainer 
            browserType={browserType} 
            cookies={cookies} 
            currentPageType={currentPageType} 
            routeProps={routeProps}
        >
            <WebContentTemplate>
                <WebPromotionContainer
                    browserType={browserType}
                    currentPageType={currentPageType}
                    routeProps={routeProps}
                    isPromotionInfo={isPromotionInfo}
                />
            </WebContentTemplate>
        </MainContainer>
    );
};

export default WebPromotionPage;