import React from 'react';
import { Modal } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

const WebPassPolicy = ({ isModalOpened, togglePolicyModalOpened }) => {
    return (
        <Modal isOpen={isModalOpened} size='lg' centered backdrop={true} toggle={() => togglePolicyModalOpened(false)}>
            <div className="card p-2 align-items-center justify-content-center">
                <div className="title-area justify-content-end mb-3">
                    <button className="hiclasstv-btn p-0" title="닫기" onClick={() => togglePolicyModalOpened(false)}>
                        <FaTimes className="d-flex m-1 black-text" />
                    </button>
                </div>
                <h3>
                    이용권 환불 정책
                </h3>
                <div className="d-flex w-100 px-2">
                    <div className="web-policy-box w-100 p-2 my-4 scroll-show">
                        <span>
                            <p className="last-p">
                                <b>HiClass.TV 서비스 이용권 환불 정책은 아래 내용을 따릅니다.</b>
                            </p>
                            <h3>환불액</h3>
                            <table className="table table-sm text-center">
                                <thead className="hiclasstv-grey">
                                    <tr>
                                        <td className="align-middle" rowSpan="2">이용권 사용 이전</td>
                                        <td colSpan="2">이용권 사용 이후</td>
                                    </tr>
                                    <tr>
                                        <td>이용 기간 만료 15일 전</td>
                                        <td>잔여 이용일수 15일 초과하는 경우</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span>전액 환불</span>
                                        </td>
                                        <td>
                                            <span>환불 불가</span>
                                        </td>
                                        <td>
                                            <span>결제금액-결제금액X(이용일수/전체이용일수)-수수료(결제금액의 10%)</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </span>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default WebPassPolicy;