import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

const LogoutAlertModal = ({ isModalOpened, handleSelectedFunc }) => {
    return (
        <Modal isOpen={isModalOpened} backdrop={false} centered toggle={() => handleSelectedFunc({ selectedFunc: "LOGOUT_ALERT_TOGGLE" })}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        <span>강제 로그아웃 알림</span>
                    </div>
                    <div className="content-area dark-gray-text">
                        <div className="text-area text-center">
                            <span>다른 브라우저에서 새롭게 로그인되어</span>
                            <span>현재 브라우저에서 강제 로그아웃됩니다.</span>
                        </div>
                    </div>
                    <button type="button" className="btn btn-md green white-text py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "LOGOUT_ALERT_TOGGLE" })}>확인</button>
                </div>
            </div>
        </Modal>
    );
}

export default LogoutAlertModal;