import React from 'react';
import { FaTimes } from 'react-icons/fa';

const handlePreventClick = (e) => {
    e.stopPropagation();
};

const SelectStudio = ({ studio_info, handleFunc }) => {
    return (
        studio_info !== undefined && studio_info !== null &&
            <div onClick={handlePreventClick}>
                <div className="d-flex justify-content-end w-100">
                    <button className="hiclasstv-btn b-none p-0" onClick={() => handleFunc({ selectedFunc: "CANCEL_STUDIO_CON", studio_info })}>
                        <FaTimes className="d-flex m-1 black-text" />
                    </button>
                </div>
                <div className="d-flex text-center my-2 dark-grey-text small">
                    <span>
                        디스플레이 화면을 터치해 주세요.<br />
                        터치 후 스튜디오와 연결됩니다.
                    </span>
                </div>
                <div className="content-area dark-grey-text">
                    <span className="small mb-1">[스튜디오 이름]</span>
                    <div className="text-area text-center area-80">
                        <span className="hiclass-text" title={studio_info.studioName}>
                            <b>{studio_info.studioName}</b>
                        </span>
                    </div>
                </div>
                <div className="d-flex justify-content-center my-2">
                    <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => handleFunc({ selectedFunc: "CANCEL_STUDIO_CON", studio_info })}>취소</button>
                </div>
            </div>
    );
};

export default SelectStudio;