import axios from 'axios';
import ConstData from '../ConstData';
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const login = ({ loginInfo, mode, userKind }) => {
    //console.log('api login', loginInfo, mode, userKind);
    //console.log('api login', `${ConstData.REST_API_HOST_ADD}/user/login?mode=${mode}`);
    return axios.post(`${ConstData.REST_API_HOST_ADD}/user/login?mode=${mode}`, { //payload 형식
        userID: loginInfo.userID,
        userPW: loginInfo.password,
        isAutoLogin: loginInfo.isAutoLogin,
        userKind
    }, options);
};

export const auto_login = ({ loginInfo, mode, tokenKey, userKind, lectureCode }) => {
    //console.log('auto_login - ', tokenKey, userKind);
    return axios.post(`${ConstData.REST_API_HOST_ADD}/user/login/auto?kind=login&mode=${mode}`, { //payload 형식
        tokenKey,
        userKind
    }, options);
};

export const login_guest = ({ loginInfo, mode, guestLoginKey, userKind }) => {
    //console.log('login_guest - ', guestLoginKey, userKind);

    return axios.post(`${ConstData.REST_API_HOST_ADD}/user/login/auto?kind=guest&mode=${mode}`, { //payload 형식
        guestLoginKey,
        userKind
    }, options);
}

export const login_qrcode = ({ loginInfo, mode, userSeq, userKind }) => {
    //console.log('login_qrcode - ', userSeq, userKind);

    return axios.post(`${ConstData.REST_API_HOST_ADD}/user/login/auto?kind=qr-login&mode=${mode}`, { //payload 형식
        userSEQ: userSeq,
        userKind
    }, options);
}

export const login_through_notification = ({ loginInfo, mode, broadcastKey, userKind }) => {
    //console.log('login_through_notification - ', loginInfo, broadcastKey, userKind);

    return axios.post(`${ConstData.REST_API_HOST_ADD}/user/login/extra?kind=login-through-key&mode=${mode}`, {
        userID: loginInfo.userID,
        userPW: loginInfo.password,
        isAutoLogin: loginInfo.isAutoLogin,
        broadcastKey,
        userKind
    }, options)
}

export const login_smart_tv = ({ mode, userSeq, liveSeq, userKind }) => {
    console.log(`api login_smart_tv - mode[${mode}], userKind[${userKind}], userSeq[${userSeq}], liveSeq[${liveSeq}]`);
    let protocol = window.location.protocol;
    if (ConstData.IS_LOCAL_VERSION && protocol === 'http:') {
        let newRestAPIUrl = ConstData.REST_API_HOST_ADD;
        newRestAPIUrl = newRestAPIUrl.replace('https:', 'http:');
        newRestAPIUrl = newRestAPIUrl.replace(':8001', ':8000');

        console.log(`api login_smart_tv - newRestAPIUrl => `, newRestAPIUrl);

        return axios.post(`${newRestAPIUrl}/user/login/auto-all?kind=smart-tv-login&mode=${mode}`, { //payload 형식
            userSEQ: userSeq,
            liveSEQ: liveSeq,
            userKind
        }, options);
    } else {
        return axios.post(`${ConstData.REST_API_HOST_ADD}/user/login/auto?kind=smart-tv-login&mode=${mode}`, { //payload 형식
            userSEQ: userSeq,
            liveSEQ: liveSeq,
            userKind
        }, options);
    }
}

export const login_group_smart_tv = ({ mode, userSeq, liveSeq, userKind, groupSeq, list_member, groupName }) => {
    console.log(`api login_group_smart_tv - mode[${mode}], groupSeq[${groupSeq}], groupName[${groupName}], userKind[${userKind}], userSeq[${userSeq}], liveSeq[${liveSeq}], list_member => `, list_member);
    let protocol = window.location.protocol;
    if (ConstData.IS_LOCAL_VERSION && protocol === 'http:') {
        let newRestAPIUrl = ConstData.REST_API_HOST_ADD;
        newRestAPIUrl = newRestAPIUrl.replace('https:', 'http:');
        newRestAPIUrl = newRestAPIUrl.replace(':8001', ':8000');

        console.log(`api login_smart_tv - newRestAPIUrl => `, newRestAPIUrl);

        return axios.post(`${newRestAPIUrl}/user/login/auto-all?kind=group-smart-tv-login&mode=${mode}`, { //payload 형식
            userSEQ: groupSeq,
            liveSEQ: liveSeq,
            userKind
        }, options);
    } else {
        return axios.post(`${ConstData.REST_API_HOST_ADD}/user/login/auto?kind=group-smart-tv-login&mode=${mode}`, { //payload 형식
            userSEQ: groupSeq,
            liveSEQ: liveSeq,
            userKind
        }, options);
    }
}

export const logout = ({ userSeq, mode, liveSeq, isClickLogout }) => {
    let url = `${ConstData.REST_API_HOST_ADD}/user/${userSeq}/logout?mode=${mode}`;
    if (liveSeq >= 0) {
        url += `&liveSEQ=${liveSeq}`;
    }

    if (isClickLogout) {
        url += `&isClickLogout=${isClickLogout}`;
    }

    /* if (liveSeq >= 0) {
        return axios.delete(`${ConstData.REST_API_HOST_ADD}/user/${userSeq}/logout?mode=${mode}&liveSEQ=${liveSeq}`, options);
    } else {
        return axios.delete(`${ConstData.REST_API_HOST_ADD}/user/${userSeq}/logout?mode=${mode}`, options);
    } */

    return axios.delete(url, options);
};

export const force_logout = ({ userSeq, mode }) => {
    return axios.delete(`${ConstData.REST_API_HOST_ADD}/user/${userSeq}/logout?kind=force-logout&mode=${mode}`, options);
};

export const notify_logout_to_android_app = ({ userSeq }) => {
    console.log(`api notify_logout_to_android_app - userSeq[${userSeq}]`);
    return axios.delete(`${ConstData.REST_API_HOST_ADD}/user/${userSeq}/logout?mode=android-app`, options);
};

export const request_mixed_class_login = ({ tokenKey, mixedClassSeq, userKind }) => {
    /* return axios.post(`${ConstData.REST_API_HOST_ADD}/user/login/auto?kind=mixed-class-check&mode=web`, { //payload 형식
        tokenKey,
        userKind
    }); */
    return axios.post(`${ConstData.REST_API_HOST_ADD}/user/login/auto?kind=mixed-class-check&mode=web`, { //payload 형식
        mixedClassSEQ: mixedClassSeq,
        userKind
    }, options);
};

export const idcheck = ({ userID, userKind }) => {
    return axios.get(`${ConstData.REST_API_HOST_ADD}/user?kind=check-ID&userID=${userID}&userKind=${userKind}`, options); //query 형식
};

export const send_phone_check = ({ phoneNumber }) => {
    return axios.post(`${ConstData.REST_API_HOST_ADD}/user/certi?kind=phone`, { //query&payload 형식
        userPN: phoneNumber
    }, options);
};

export const send_email_check = ({ userEmail }) => {
    return axios.post(`${ConstData.REST_API_HOST_ADD}/user/certi?kind=email`, { //query&payload 형식
        userEmail: userEmail
    }, options);
};

export const confirm_phone_check = ({ certiCodeSeq, confirmNumber }) => {
    //console.log(`api confirm_phone_check `)
    return axios.put(`${ConstData.REST_API_HOST_ADD}/user/certi?kind=phone`, {
        certiSEQ: certiCodeSeq,
        certi_code: confirmNumber
    }, options);
};

export const confirm_email_check = ({ certiCodeSeq, confirmNumber }) => {
    return axios.put(`${ConstData.REST_API_HOST_ADD}/user/certi?kind=email`, {
        certiSEQ: certiCodeSeq,
        certi_code: confirmNumber
    }, options);
};

export const regist_user = ({ userID, password, userNickname, userEmail, phoneNumber, userProfile, profileImgUrl, userExtraCode, userKind, userType }) => {
    return axios.post(`${ConstData.REST_API_HOST_ADD}/user`, { //payload 형식
        userID: userID,
        userPN: phoneNumber,
        userEmail: userEmail,
        userNickname: userNickname,
        userPW: password,
        userProfile: userProfile,
        userProfileIMG: profileImgUrl,
        userExtraCode: userExtraCode === '' ? null : userExtraCode,
        userKind: userKind,
        userType: userType
    }, options);
};

export const update_user_info = ({ mode, userSeq, phoneNumber, userEmail, password, userNickname, userProfile, userExtraCode, profileImgUrl }) => {
    return axios.put(`${ConstData.REST_API_HOST_ADD}/user/${userSeq}?mode=${mode}`, { //param&query&payload 형식
        userPN: phoneNumber === undefined ? null : phoneNumber,
        userEmail: userEmail === undefined ? null : userEmail,
        userNickname: userNickname === undefined ? null : userNickname,
        userPW: password === undefined ? null : password,
        userProfile: userProfile === undefined ? null : userProfile,
        userExtraCode: (userExtraCode === undefined || userExtraCode === '') ? null : userExtraCode,
        userProfileIMG: profileImgUrl === undefined ? null : profileImgUrl
    }, options);
};

export const search = ({ userSeq, keyWord }) => {
    return axios.get(`${ConstData.REST_API_HOST_ADD}/user?kind=search&userSEQ=${userSeq}&keyword=${keyWord}`, options);
};

export const search_my_id = ({ userPN, userKind }) => {
    return axios.post(`${ConstData.REST_API_HOST_ADD}/user/help?kind=search-myID`, {
        userPN: userPN,
        userKind: userKind
    }, options);
};

export const compare_info = ({ userPN, userID, userKind }) => {
    return axios.post(`${ConstData.REST_API_HOST_ADD}/user/help?kind=compare`, {
        userPN: userPN,
        userID: userID,
        userKind: userKind
    }, options);
};

export const reset_my_pw = ({ userID, userPW }) => {
    return axios.put(`${ConstData.REST_API_HOST_ADD}/user/help?kind=reset-pw`, {
        userID: userID,
        userPW: userPW
    }, options);
};