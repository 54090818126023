import React from "react";

const AudioMemoMessage = ({ indexKey, msgInfo, handleSelectUrl }) => {
    return (
        <button type="button" className="btn btn-sm mx-1 px-2 py-1" style={{ alignSelf: 'flex-start', paddingTop: '0.25rem', paddingLeft: '0.25rem', borderRadius: '0.5rem' }} onClick={() => handleSelectUrl(msgInfo.kind, msgInfo.urlInfo, indexKey)}>
            Play
        </button>
    );
}

export default AudioMemoMessage;