import React from "react";

import ConstData from "../../../lib/ConstData";

const Alerts = ({ kind, message, style }) => {
    switch (kind) {
        case ConstData.ALERT_KIND.DANGER:
            return <div className="alert-area alert-danger" style={(style ? style : {})} dangerouslySetInnerHTML={{ __html: message }}></div>;

        case ConstData.ALERT_KIND.SUCCESS:
            return <div className="alert-area alert-success" style={(style ? style : {})} dangerouslySetInnerHTML={{ __html: message }}></div>;

        case ConstData.ALERT_KIND.WARNING:
            return <div className="alert-area alert-warning" style={(style ? style : {})} dangerouslySetInnerHTML={{ __html: message }}></div>;

        case ConstData.ALERT_KIND.INFO:
            return <div className="alert-area alert-info" style={(style ? style : {})} dangerouslySetInnerHTML={{ __html: message }}></div>;

        default:
            return <></>
    }
}

export default Alerts;