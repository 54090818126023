/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

// import edu01 from '../../image/info/edu/onChat.png';
// import edu02 from '../../image/info/edu/onNote.png';
// import edu03 from '../../image/info/edu/teamUp.png';
// import edu04 from '../../image/info/edu/handsUp.png';
// import edu05 from '../../image/info/edu/onAir.png';
// import edu06 from '../../image/info/edu/onMedia.png';
// import edu07 from '../../image/info/edu/onBoard.png';

const WebHiClassInfoEdutech = () => {
    return (
        <div className="d-flex flex-column w-100" style={{ margin: '10px 0px', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
            <div className="d-flex justify-content-center align-items-center w-100" style={{ margin: '2rem 0' }}>
                <div className="d-flex flex-column justify-content-center">

                    <div className="d-flex w-100">
                        <div className="d-flex flex-wrap" style={{ fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span>HiClassTV&nbsp;</span>
                            <span className="hiclasstv-blue-text">Pentalk</span>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100 my-4">
                        <div className="d-flex my-2">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                토론하고 강의하고 녹화하고 방송하는 인공지능 기반 플랫폼
                            </span>
                        </div>
                        <div className="d-flex flex-wrap" style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-coral-text">OnChat</span>
                        </div>
                        <div className="d-flex flex-column my-3">
                            <span className="d-flex hiclasstv-darkblue-text mt-3" style={{ fontSize: '1.2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                팀미팅, 강의, 협업, 교실수업 등에서 사용되는 3개의 독립된 App으로 구성
                            </span>
                            <span className="d-flex flex-column my-3" style={{ fontSize: '1rem' }}>
                                <span className="my-1">ㆍPentalk OnChat ... 인공지능 기반 Chatbot</span>
                                <span className="my-1">ㆍPencam OnAir ... 영상녹화 및 방송</span>
                                <span className="my-1">ㆍHiClass OnBoard ... PDF/PDF3D 기반 콘텐츠 콘테이너</span>
                            </span>
                        </div>
                        <div className="d-flex flex-column mb-5">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '1.2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                스마트펜 및 스마트패턴 기술을 지원, 윈도우 HID 표준 장치 지원
                            </span>
                            <span className="d-flex mt-3 w-100 h-100 align-items-center justify-content-center">
                                <img src="/images/info/edu/onChat.png" className="w-100 h-100" alt="" />
                            </span>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100 my-4">
                        <div className="d-flex my-2">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                HTML5 브라우저 기반 수강용 웹클라이언트
                            </span>
                        </div>
                        <div className="d-flex flex-wrap" style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-coral-text">OnNote</span>
                        </div>
                        <div className="d-flex flex-column mt-3 mb-5">
                            <span className="d-flex flex-column my-3" style={{ fontSize: '1rem' }}>
                                <span className="my-1">ㆍ강의 수강자, HandsUp 학생 등의 웹노트로 에지/크롬 브라우저 또는 Safari(iOS) 에서 사용</span>
                                <span className="my-1">ㆍ강의실 화면보기, 강의실 영상보기, 강의자와 채팅하기, 질문하기 등 다양한 도구 지원</span>
                                <span className="my-1">ㆍPencam OnAir 를 사용하여 녹화 및 방송 가능 ( Windows Only )</span>
                                <span className="my-1">ㆍAi Tutor 를 사용하여 인공지능기반 학습 및 콘텐츠 등을 사용</span>
                            </span>
                            <span className="d-flex mt-3 w-100 h-100 align-items-center justify-content-center">
                                <img src="/images/info/edu/onNote.png" className="w-100 h-100" alt="" />
                            </span>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100 my-4">
                        <div className="d-flex my-2">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                비대면 쌍방향 공유, 협업
                            </span>
                        </div>
                        <div className="d-flex flex-wrap" style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-coral-text">TeamUp</span>
                        </div>
                        <div className="d-flex flex-column mt-3 mb-5">
                            <span className="d-flex flex-column my-3" style={{ fontSize: '1rem' }}>
                                <span className="my-1">ㆍ칠판과 칠판, 교실과 교실, 미팅룸과 미팅룸, 팀원과 팀원을 인터넷상에서 연결</span>
                                <span className="my-1">ㆍ영상, 음성, 문자, 손글씨, 강의자료 등을 공유하며, 녹화하고 방송</span>
                                <span className="my-1">ㆍ인공지능기반 Help Desk, 학습, 검색 등 다양한 경험들을 제공</span>
                            </span>
                            <span className="d-flex mt-3 w-100 h-100 align-items-center justify-content-center">
                                <img src="/images/info/edu/teamUp.png" className="w-100 h-100" alt="" />
                            </span>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100 my-4">
                        <div className="d-flex my-2">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                Leader 주도형 비대면 협업
                            </span>
                        </div>
                        <div className="d-flex flex-wrap" style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-coral-text">클래스(Class)</span>
                        </div>
                        <div className="d-flex flex-column mt-3 mb-5">
                            <span className="d-flex flex-column my-3" style={{ fontSize: '1rem' }}>
                                <span className="my-1">ㆍ리더/강사/팀원/선생님은 클래스(Class) 강의실을 펜톡(Pentalk)상에서 구축하고 운영함</span>
                                <span className="my-1">ㆍ리더/강사/팀원/선생님의 화면을 방송, 토론/업무/강의 등을 선도</span>
                                <span className="my-1">ㆍ수강자 전체를 펜톡 모니터(Pentalk Monitor)를 통하여 동시에 4명씩 그룹 별로 라이브 미팅… 이 과정을 청취</span>
                                <span className="my-1">ㆍ펜톡(Pentalk)을 통하여 항상 쌍방향 소통 및 그룹 별 모둠 강의 등이 가능</span>
                                <span className="my-1">ㆍ수강자는 웹 브라우저 상의 웹 노트를 사용하여 설치 및 유지보수 단순화</span>
                            </span>
                            <span className="d-flex mt-3 w-100 h-100 align-items-center justify-content-center">
                                <img src="/images/info/edu/handsUp.png" className="w-100 h-100" alt="" />
                            </span>
                        </div>
                    </div>


                    <div className="d-flex w-100 mt-5">
                        <div className="d-flex flex-wrap" style={{ fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span>HiClass&nbsp;</span>
                            <span className="hiclasstv-blue-text">Pencam</span>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100 my-4">
                        <div className="d-flex my-2">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                간편한 강의 녹화+방송 S/W
                            </span>
                        </div>
                        <div className="d-flex flex-wrap" style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-coral-text">OnAir</span>
                        </div>
                        <div className="d-flex flex-column mt-3 mb-5">
                            <span className="d-flex flex-column my-3" style={{ fontSize: '1rem' }}>
                                <span className="my-1">ㆍOne Touch 강의 녹화 ( MP4, EBS 고화질, FHD )</span>
                                <span className="my-1">ㆍHD 부터 FHD 까지 화면의 모든 액션을 녹화</span>
                                <span className="my-1">ㆍ녹화 일시정지 및 재 녹화 지원</span>
                                <span className="my-1">ㆍ스피커와 마이크소리를 믹싱하여 녹화하고 방송하는 기능 </span>
                                <span className="my-1">ㆍYoutube등에 rtmp로 라이브로 방송</span>
                                <span className="my-1">ㆍ웹캠 화면을 띄워 함께 녹화하고 방송</span>
                                <span className="my-1">ㆍ마이크 및 스피커 음량제어</span>
                                <span className="my-1">ㆍHiClass OnBoard와 함께 하는 경우 영상에 판서하는 과정을 녹화</span>
                                <span className="my-1">ㆍ전문 지식없이 누구나 쉽게 사용할 수 있는 UI/UX</span>
                                <span className="my-1">ㆍPaperTablet의 펜터치 기능으로 가비지 없는 녹화 및 방송</span>
                                <span className="my-1">ㆍ주(1) 모니터, 부(2) 모니터 선택적 녹화 및 방송</span>
                            </span>
                            <span className="d-flex mt-3 w-100 h-100 align-items-center justify-content-center">
                                <img src="/images/info/edu/onAir.png" className="w-100 h-100" alt="" />
                            </span>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100 my-4">
                        <div className="d-flex my-2">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                간편한 영상 편집 S/W
                            </span>
                        </div>
                        <div className="d-flex flex-wrap" style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-coral-text">OnMedia</span>
                        </div>
                        <div className="d-flex flex-column mt-3 mb-5">
                            <span className="d-flex flex-column my-3" style={{ fontSize: '1rem' }}>
                                <span className="my-1">ㆍMP4, AVI, MKV, Mov,FLV 등 미디어파일을 EBS 고화질 동영상으로 변환</span>
                                <span className="my-1">ㆍ원하는 영역의 영상을 잘라내거나 분할</span>
                                <span className="my-1">ㆍ잘라낸 영상을 다시 다른 곳으로 삽입하거나 다른 영상을 원하는 위치에 삽입 </span>
                                <span className="my-1">ㆍ동영상 앞으로, 뒤로 재생 / 정지화면 앞 뒤로 이동 / 2배속 앞뒤로 이동</span>
                                <span className="my-1">ㆍGPU 또는 SW 코덱을 이용 / 4K 까지 지원</span>
                            </span>
                            <span className="d-flex mt-3 w-100 h-100 align-items-center justify-content-center">
                                <img src="/images/info/edu/onMedia.png" className="w-100 h-100" alt="" />
                            </span>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100 my-4">
                        <div className="d-flex my-2">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                PDF기반 판서 + 콘텐츠 콘테이너 S/W
                            </span>
                        </div>
                        <div className="d-flex flex-wrap" style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-coral-text">OnBoard</span>
                        </div>
                        <div className="d-flex flex-column mt-3 mb-5">
                            <span className="d-flex flex-column my-3" style={{ fontSize: '1rem' }}>
                                <span className="my-1">ㆍPentalk을 통하여 상대방 교실, 팀원, 미팅룸의 다른 OnBoard와 소통</span>
                                <span className="my-1">ㆍPencam을 통하여 강의를 녹화 하거나 유튜브 등에 방송</span>
                                <span className="my-1">ㆍ화면을 보내고 받고, 판서하는 잉크를 실시간으로 공유</span>
                                <span className="my-1">ㆍ모둠수업 창을 이용하여 Virtual Class(HandsUp) 참여 학생이나 팀원과 1:1 로 첨삭 수업 및 모둠 수업</span>
                                <span className="my-1">ㆍPDF 아노테이션 저작기능 / PDF 3D Object 지원</span>
                                <span className="my-1">ㆍ핫키로 윈도우 화면에 직접 판서 / 핫키로 모든 웹페이지 및 윈도우 앱 위에 판서</span>
                            </span>
                            <span className="d-flex my-3 w-100 h-100 align-items-center justify-content-center">
                                <img src="/images/info/edu/onBoard.png" className="w-100 h-100" alt="" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WebHiClassInfoEdutech;