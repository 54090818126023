import React from 'react';
import PolicyContainer from '../containers/PolicyContainer';

const WebPolicyPage = ({ browserType, currentPageType, cookies, routeProps }) => {
    return (
        <PolicyContainer
            browserType={browserType}
            cookies={cookies}
            currentPageType={currentPageType}
            routeProps={routeProps}
        />
    );
};

export default WebPolicyPage;