import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';

import CustomProfileImage from '../../user/profile/CustomProfileImage';

const WebSubDomainCreateAdminUserAlertModal = ({ isModalOpened, selectedMemberInfo, toggleModalOpened, onClickAdminIcon, alertKind, alertMessage }) => {

    const [memberInfo, setMemberInfo] = useState(selectedMemberInfo);
    const [lAlertMessage, setAlertMessage] = useState(alertMessage);
    const [lAlertKind, setAlertKind] = useState(alertKind);

    useEffect(() => {
        setMemberInfo(selectedMemberInfo);
    }, [selectedMemberInfo]);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    return (
        <Modal isOpen={isModalOpened} centered backdrop={true}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        <span>
                            {
                                memberInfo.isCreateAdmin ? 
                                    "해당 멤버에게서 서브 도메인 추가 권한을 해제하시겠습니까?" : 
                                    "해당 멤버에게 서브 도메인 추가 권한을 부여하시겠습니까?"
                            }
                        </span>
                    </div>
                    <div className="content-area dark-grey-text">
                        <div className="d-flex flex-column align-items-center justify-content-center area-80">
                            <span className="small mb-1">[선택한 멤버]</span>
                            <div className="d-flex align-items-center justify-content-center w-100">
                                <div className="area-15 fit-content">
                                    <CustomProfileImage url={memberInfo.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={memberInfo.userNickname} />
                                </div>
                                <span className="hiclass-text" title={memberInfo.userNickname}>
                                    <b>{memberInfo.userNickname}</b>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <button type="button" className="btn red white-text mr-1 py-1 px-2" onClick={() => onClickAdminIcon(memberInfo.subDomainSeq, memberInfo)}>예</button>
                        <button type="button" className="btn green white-text ml-1 py-1 px-2" onClick={() => toggleModalOpened(false)}>아니오</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default WebSubDomainCreateAdminUserAlertModal;