import React, { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from "react";
import { useHistory } from "react-router-dom";
import { FaArrowLeft, FaAndroid, FaInfoCircle } from "react-icons/fa";
// import { MdNotificationsActive } from "react-icons/md";
// import { PiAppWindow, PiGridFour } from "react-icons/pi";

import { enWebPage } from "../../../lib/ConstCommand";
import { enTokenCMD } from "../../../lib/classTalk/CodeEnum";

import { unsubscribe } from "../../../lib/MediaSoupClient";
import {
    xmitCmd_AndroidMessageForSmartTV,
    xmitCmd_MessageForSmartTV
} from "../../../lib/RabbitmqClient";

// import showTeacherImg from "../../image/liveTool/showTeacherScreen.png";
// import hideTeacherImg from "../../image/liveTool/hideTeacherScreen.png";

const SmartTVControlTool = forwardRef(({ currentPageType, userNickname, teacherInfo, performLiveInfo, performLiveStatusInfo, smartTVBindInfo, isConnectedOnScreenApp, list_isConnectedOnScreenApp, onToggleImageView, onToggleViewMode, handleSelectedFunc }, ref) => {
    let history = useHistory();

    const [lViewMode, setViewMode] = useState("default");
    const [imageViewVisibility, setImageViewVisibility] = useState("visible");
    const [isConnectApp, setIsConnectApp] = useState(isConnectedOnScreenApp);
    // const [isDisabled, setIsDisabled] = useState(false);

    useImperativeHandle(ref, () => ({
        // updateDisabled: value => setIsDisabled(value),
        updateViewMode: value => {
            if (value !== lViewMode) {
                setViewMode(value);
            }
        },
        updateControlTool: value => update(value)
    }));

    useEffect(() => {
        setIsConnectApp(isConnectedOnScreenApp);
    }, [isConnectedOnScreenApp]);

    const update = ({ viewMode, visibility, isDisabled }) => {
        let msg = `updateView - viewMode[${viewMode}], visibility[${visibility}], isDisabled[${isDisabled}]`;
        console.log(msg);

        if (viewMode && viewMode !== lViewMode) {
            setViewMode(viewMode);
        }

        if (visibility && visibility !== imageViewVisibility) {
            setImageViewVisibility(visibility);
        }

        /* if (isDisabled !== undefined) {
            setIsDisabled(isDisabled);
        } */
    }

    const onClickGoBack = () => {
        // console.log("onClickGoBack - call history.goBack");
        // history.goBack();
        // console.log("onClickGoBack - call location.reload");
        // window.location.reload();
        if (performLiveInfo.liveSeq > -1) { // live에 입장한 case by hjkim 20231211
            if (performLiveInfo.liveStatus === "Y" && performLiveStatusInfo.connectStatus === "on") {
                unsubscribe(false, true, true);
            }

            if (list_isConnectedOnScreenApp && list_isConnectedOnScreenApp.length > 0) {
                for (let i = 0; i < list_isConnectedOnScreenApp.length; i++) {
                    let info = list_isConnectedOnScreenApp[i];
                    // xmitCmd_AndroidMessageForSmartTV("android", "ct_SmartTVLogout", enTokenCMD.ct_Logout, info.userSeq, info.userNickname, live_info.liveSeq, smartTVTempKey);
                    xmitCmd_MessageForSmartTV("smart_tv", "ct_SmartTVLogout", enTokenCMD.ct_Logout, info.userSeq, info.userNickname, performLiveInfo.liveSeq, false);

                    if (info.isConnectedOnScreenApp) {
                        xmitCmd_AndroidMessageForSmartTV("android", "ct_SmartTVLogout", enTokenCMD.ct_Logout, info.userSeq, info.userNickname, performLiveInfo.liveSeq, performLiveInfo.smartTVTempKey);
                    }
                }
            }
        }

        if (currentPageType === enWebPage.SmartTV) {
            window.location.replace(`/smarttv_login/${performLiveInfo.liveSeq}`);
        } else {
            window.location.replace(`/group_smarttv_login/${performLiveInfo.liveSeq}`);
        }
    }

    const onClickImageViewVisibility = () => {
        if (imageViewVisibility === "hidden") {
            setImageViewVisibility("visible");
            onToggleImageView({ display: "block", value: "visible" });
        } else {
            setImageViewVisibility("hidden");
            onToggleImageView({ display: "none", value: "hidden" });
        }
    }

    const onClickChangeViewMode = () => {
        if (lViewMode === "default") {
            setViewMode("multi-view");
            // setIsDisabled(true);
            onToggleViewMode({ value: "multi-view" });
        } else {
            setViewMode("default");
            onToggleViewMode({ value: "default" });
        }
    }

    const onClickConnectApp = () => {
        if (isConnectApp) {
            handleSelectedFunc({ selectedFunc: "TRY_DISCONN_APP" });
        } else {
            handleSelectedFunc({ selectedFunc: "TRY_CONN_APP" });
        }
    }

    const onClickRequestConnInfo = () => {
        if (smartTVBindInfo.bindKeyArr.length === 0 || smartTVBindInfo.startSeqOfSmartTV === -1) {
            handleSelectedFunc({ selectedFunc: "TRY_REQ_CONN_INFO" });
        }
    }

    return (
        <div className="live-toolbar-area bottom small">
            <div className="toolbar-landscape">
                <button type="button" className="d-flex hiclasstv-button p-2" title="뒤로 가기" onClick={onClickGoBack}>
                    <FaArrowLeft className="font-ml" style={{ color: 'rgb(158, 158, 158)' }} alt="뒤로 가기" aria-hidden="true" />
                </button>
                <button type="button" className="d-flex hiclasstv-button p-2" title={lViewMode !== "default" ? "학생 화면 닫기" : imageViewVisibility === "hidden" ? "학생 화면 보기" : "학생 화면 닫기"} disabled={/* isDisabled ||  */lViewMode !== "default"} onClick={onClickImageViewVisibility}>
                    {
                        lViewMode !== "default" ? 
                            <img className="hiclasstv-live-icon" src="/images/smartTVTool/showTeacherScreenDisable.png" alt="학생 화면 닫기" aria-hidden="true" /> :
                        imageViewVisibility === "hidden" ?
                            <img className="hiclasstv-live-icon" src="/images/smartTVTool/showTeacherScreen.png" alt="학생 화면 보기" aria-hidden="true" /> :
                            <img className="hiclasstv-live-icon" src="/images/smartTVTool/hideTeacherScreen.png" alt="학생 화면 닫기" aria-hidden="true" />
                    }
                </button>
                {
                    currentPageType === enWebPage.GroupSmartTV &&
                    <button type="button" className="d-flex hiclasstv-button p-2" title={lViewMode === "default" ? "분할 모드 전환" : "기본 화면 전환"} disabled={imageViewVisibility === "hidden"} onClick={onClickChangeViewMode}>
                        {
                            imageViewVisibility === "hidden" ? 
                                <img className="hiclasstv-live-icon" src="/images/smartTVTool/showMultiView4Disable.png" alt="분할 모드 전환" aria-hidden="true" /> :
                            lViewMode === "default" ?
                                <img className="hiclasstv-live-icon" src="/images/smartTVTool/showMultiView4.png" alt="분할 모드 전환" aria-hidden="true" /> :
                                <img className="hiclasstv-live-icon" src="/images/smartTVTool/showDefaultView.png" alt="기본 화면 전환" aria-hidden="true" />
                        }
                    </button>
                }
                <button type="button" className="d-flex hiclasstv-button p-2" title={isConnectApp ? "앱 연결 끊기" : "앱 연결하기"} onClick={onClickConnectApp}>
                    {
                        isConnectApp ?
                            <FaAndroid className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} alt="앱 연결 끊기" aria-hidden="true" /> :
                            <FaAndroid className="font-ml" style={{ color: 'rgb(255, 0, 0)' }} alt="앱 연결하기" aria-hidden="true" />
                    }
                </button>
                {
                    !(smartTVBindInfo.bindKeyArr.length > 0 || smartTVBindInfo.startSeqOfSmartTV > -1) &&
                    <button type="button" className="d-flex hiclasstv-button p-2" title="스마트TV 연결 정보 요청" disabled={smartTVBindInfo.bindKeyArr.length > 0 || smartTVBindInfo.startSeqOfSmartTV > -1} onClick={onClickRequestConnInfo}>
                        <FaInfoCircle
                            className="font-ml"
                            style={{ color: smartTVBindInfo.bindKeyArr.length > 0 || smartTVBindInfo.startSeqOfSmartTV > -1 ? 'lightgray' : 'rgb(55, 230, 71)' }}
                            alt="스마트TV 연결 정보 요청"
                            aria-hidden="true"
                        />
                    </button>
                }
                <div className="d-flex align-items-center w-100">
                    <div className="d-flex align-items-center" style={{ minWidth: '1%', width: '100%', maxWidth: 'max-content' }}>
                        <span className="web-list-text w-100 px-1" title={"[진행자] " + teacherInfo.userNickname}>
                            <b>{"[진행자] " + teacherInfo.userNickname}&nbsp;&nbsp;</b>
                        </span>|
                    </div>
                    <div className="d-flex" style={{ minWidth: '10%', width: '100%', maxWidth: 'max-content', visibility: 'visible' }}>
                        <span className="web-list-text w-100 px-1" title={(currentPageType === enWebPage.SmartTV ? "[공유 학생] " : "[공유 그룹] ") + userNickname}>
                            {(currentPageType === enWebPage.SmartTV ? "[공유 학생] " : "[공유 그룹] ") + userNickname}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SmartTVControlTool;