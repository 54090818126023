import axios from 'axios';
import ConstData from '../ConstData';
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const get_promotion_init_list = ({ kind }) => {
    //console.log('kind - ', kind);

    return axios.get(`${ConstData.REST_API_HOST_ADD}/promotion?kind=${kind}`, options);
};

export const get_promotion_register_info = ({ promotionSeq }) => {
    //console.log('promotionSeq - ', promotionSeq);

    return axios.get(`${ConstData.REST_API_HOST_ADD}/promotion/${promotionSeq}/registerInfo`, options);
};

export const regist_promotion = ({ promotionSeq, info }) => {
    //console.log('promotionSeq - ' + promotionSeq + ' info : ' + info);

    return axios.post(`${ConstData.REST_API_HOST_ADD}/promotion/${promotionSeq}/register`, {
        emailAddress: info.email,
        licenseCode: info.license,
        itemInforsList: info.list_item
    }, options);
};

export const get_promotion_request_list = ({ promotionSeq, option }) => {
    //console.log('promotionSeq - ' + promotionSeq + ' option : ', option);

    if (option.keyword !== '') {
        return axios.get(`${ConstData.REST_API_HOST_ADD}/promotion/${promotionSeq}/registerList?term=${option.term}&issue=${option.issue}&item=${option.item}&keyword=${option.keyword}`, options);
    } else {
        return axios.get(`${ConstData.REST_API_HOST_ADD}/promotion/${promotionSeq}/registerList?term=${option.term}&issue=${option.issue}&item=${option.item}`, options);
    }
};

export const publish_coupon_code = ({ promotionSeq, requestSeq }) => {
    //console.log('promotionSeq - ' + promotionSeq + ' requestSeq - ' + requestSeq);

    return axios.put(`${ConstData.REST_API_HOST_ADD}/promotion/${promotionSeq}/couponissue/${requestSeq}`, {}, options);
};

export const regist_coupon_code = ({ promotionSeq, kind, userSeq, info }) => {
    //console.log('promotionSeq - ' + promotionSeq);

    return axios.put(`${ConstData.REST_API_HOST_ADD}/promotion?kind=${kind}`, {
        couponCode: info.couponCode,
        userSEQ: userSeq
    }, options)
};