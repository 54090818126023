import React, { Component } from 'react';
import { connect } from 'react-redux';
import BroadcastLiveListContainer from './BroadcastLiveListContainer';
import WatchingLiveListContainer from './WatchingLiveListContainer';

class LiveListContainer extends Component {
    
    componentDidMount() {
        //뒤로가기 막는 소스
        //console.log(`LiveListContainer-componentDidMount isGotoWebLive[${this.props.isGotoWebLive}]`);
        const { isRbmqServerConnected, isGotoWebLive, performLiveSeq } = this.props;
        
        /* if (isRbmqServerConnected && isGotoWebLive && performLiveSeq > -1) {
            console.log('LiveListContainer-componentDidMount go to web live page');
            this.props.routeProps.history.push(`/live/${performLiveSeq}`);
        } */
    }

    shouldComponentUpdate(nextProps) {
        return (JSON.stringify(nextProps) !== JSON.stringify(this.props));
    }

    componentDidUpdate(prevProps) {
        //console.log(`LiveListContainer-componentDidUpdate prevProps.isGotoWebLive[${prevProps.isGotoWebLive}], isGotoWebLive[${this.props.isGotoWebLive}]`);
        const { isGotoWebLive, performLiveSeq, isRbmqServerConnected, performLiveRobotLiveYN } = this.props;
        //console.log(isGotoWebLive, performLiveSeq);
        console.log('performLiveRobotLiveYN - ', performLiveRobotLiveYN);
        
        /*if (prevProps.performLiveSeq !== this.props.performLiveSeq && this.props.performLiveSeq > -1) {
            console.log('perform Live Info is changed ----- ', this.props.performLiveSeq);
            this.props.routeProps.history.push(`/live/${this.props.performLiveSeq}`);
        }*/

        if (prevProps.isGotoWebLive !== this.props.isGotoWebLive && this.props.isGotoWebLive) {
            if (this.props.isRbmqServerConnected && performLiveSeq > -1) {
                console.log('LiveListContainer-componentDidUpdate go to web live page');
                if (this.props.performLiveRobotLiveYN !== 'Y') {
                    this.props.routeProps.history.push(`/live/${performLiveSeq}`);
                }
            }
        }
    }

    componentWillUnmount() {
        //console.log(`LiveListContainer-componentWillUnmount isGotoWebLive[${this.props.isGotoWebLive}]`);
    }
    
    render() {
        const { routeProps, browserType, currentPageType } = this.props;

        return (
            <>
                <BroadcastLiveListContainer
                    routeProps={routeProps}
                    browserType={browserType}
                    currentPageType={currentPageType}
                />
                <WatchingLiveListContainer
                    routeProps={routeProps}
                    browserType={browserType}
                    currentPageType={currentPageType}
                />
            </>
        );
    }
}

export default connect(
    ({ user, live }) => ({
        isRbmqServerConnected   : user.isRbmqServerConnected,

        isGotoWebLive           : live.isGotoWebLive,
        performLiveSeq          : live.performLiveInfo.liveSeq,
        performLiveRobotLiveYN  : live.performLiveInfo.robotLiveYN,
    }),
    {

    }
)(LiveListContainer);