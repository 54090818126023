import React, { useState, useEffect } from 'react';

const getGroup = (groupNum) => {
    if (groupNum > 0) {
        return groupNum + '그룹';
    } else {
        return '선택된 그룹 없음';
    }
};

const getGroupDetail = (groupNum) => {
    switch (groupNum) {
        case 1: return 'H00 H01 H02 H03';
        case 2: return 'H04 H05 H06 H07';
        case 3: return 'H08 H09 H10 H11';
        case 4: return 'H12 H13 H14 H15';
        case 5: return 'H16 H17 H18 H19';
        case 6: return 'H20 H21 H22 H23';
        case 7: return 'H24 H25 H26 H27';
        case 8: return 'H28 H29 H30 H31';
        case 9: return 'H32 H33 H34 H35';
        case 10: return 'H36 H37 H38 H39';
        default : return ' ';
    };
};

const getGroupDetailFor9 = (groupNum) => {
    switch (groupNum) {
        case 1: return 'H00 H01 H02 H03 H04 H05 H06 H07 H08';
        case 2: return 'H09 H10 H11 H12 H13 H14 H15 H16 H17';
        case 3: return 'H18 H19 H20 H21 H22 H23 H24 H25 H26';
        case 4: return 'H27 H28 H29 H30 H31 H32 H33 H34 H35';
        case 5: return 'H36 H37 H38 H39';
        default : return ' ';
    };
};

const GroupInfoDetail = ({ group, groupDetail }) => {
    return (
        <div className="hiclass-text hiclasstv-darkgrey-text small">
            {group + ' [' + groupDetail + ']'}
        </div>
    );
};

const GroupInfo = ({ groupLimit, groupNum }) => {
    const [group, setGroup] = useState(groupNum + '그룹');
    const [groupDetail, setGroupDetail] = useState('H00');

    useEffect(() => {
        if (groupLimit === 4) {
            setGroup(getGroup(groupNum));
            setGroupDetail(getGroupDetail(groupNum));
        } else {
            setGroup(getGroup(groupNum));
            setGroupDetail(getGroupDetailFor9(groupNum));
        }
    }, [groupLimit, groupNum]);

    return <GroupInfoDetail group={group} groupDetail={groupDetail} />;
};

export default GroupInfo;