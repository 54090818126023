import React from 'react';
import { BsX } from 'react-icons/bs';

import CustomProfileImage from '../../user/profile/CustomProfileImage';

const ChatRoomMember = ({ selectedChatRoomInfo, handleSelectedFunc, userSeq }) => {

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area">
                <span>
                    <b>멤버</b>
                </span>
                <span className="func-btn" style={{position: 'absolute', right: 0}} title="닫기" onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="list-group" style={{overflow:'auto'}}>
                {
                    selectedChatRoomInfo.list_member !== undefined && selectedChatRoomInfo.list_member !== null && selectedChatRoomInfo.list_member.length > 0 ?
                        selectedChatRoomInfo.list_member.map(
                            member => (
                                member.userSeq !== userSeq ?
                                    <div className="d-flex list-group-hitem list-group-hitem-action py-2 px-1 justify-content-center align-items-center" key={member.userSeq}>
                                        <div className="d-flex area-15 fit-content">
                                            <CustomProfileImage url={member.profileImgUrl} alt={member.userNickname} />
                                        </div>
                                        <div className="text-area flex-column align-items-start">
                                            <span className="hiclass-text" title={member.userNickname}><b>{member.userNickname}</b></span>
                                            <span className="hiclass-text hiclasstv-deepDarkgrey-text small" title={member.userProfile}>{member.userProfile}</span>
                                        </div>
                                        {
                                            selectedChatRoomInfo.chatRoomCreator === userSeq ?
                                                <div className="d-flex flex-column area-25 ml-2" onClick={handlePreventClick}>
                                                    <button type="button" className="btn btn-sm red white-text m-0 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "MEMBER_DELETE_TOGGLE", chatRoomSeq: selectedChatRoomInfo.chatRoomSeq, memberSeq: member.userSeq, userNickname: member.userNickname, profileImgUrl:member.profileImgUrl, entryYN: member.entryYN })}>
                                                        삭제
                                                    </button>
                                                </div> :
                                                <></>
                                        }
                                    </div> :
                                    <div className="d-flex list-group-hitem list-group-hitem-action py-2 px-1 justify-content-center align-items-center" key={member.userSeq}>
                                        <div className="d-flex area-15 fit-content">
                                            <CustomProfileImage url={member.profileImgUrl} alt={member.userNickname} />
                                        </div>
                                        <div className="text-area justify-content-start align-items-center">
                                            <span className="hiclass-text">
                                                <b>나</b>
                                            </span>
                                        </div>
                                    </div>
                            )
                        ) :
                        <></>
                }
            </div>
        </div>
    );
}

export default ChatRoomMember;