import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { FaPlusSquare, FaSmile, FaPaperPlane } from "react-icons/fa";

import SpeakingModeWatsonImage from "./watson/SpeakingModeWatsonImage";
import WatsonImage from "./watson/WatsonImage";
import useWatson from "./watson/useWatson";
import { ctWatsonStatus, langCode } from "../../lib/ConstCommand";
import { ctBubbleKind } from "../../lib/classTalk/CodeEnum";

const pixelsToREM = (pixels) => {
    console.log(pixels, pixels / 16);
    // return pixels * 0.06;
    return pixels / 16;
}

const remToPixels = (rem) => {
    return rem * 16;
}

const timeOutMessage_Kr = [
    "너랑 대화하고 싶어",
    "나랑 같이 대화하자",
    "친구야! 난 너랑 대화하는게 좋아",
    "왓슨이 심심해요",
    "나 심심해 같이 대화하자",
    "같이 놀자",
    "같이 놀아요",
    "같이 공부해요",
    "같이 공부하자",
    "우리 즐겁게 이어서 대화하자"
];

const timeOutMessage_En = [
    "I like to talk to you",
    "Let's talk together",
    "Hey friend, I like talking with you",
    "Watson is bored",
    "I'm bored let's talk together!",
    "Let's play together",
    "Let's study together",
    "Let's study",
    "Let's happily continue on",
];

const getTimeOutMessage = (idx, lang) => {
    if (idx > -1) {
        if (lang === langCode.Korea.value) {
            return timeOutMessage_Kr[idx];
        } else if (lang === langCode.USA.value) {
            return timeOutMessage_En[idx];
        } else {
            return null;
        }
    } else {
        return null;
    }
};

const checkLanguage = (langCode, value_1, value_2) => {
    if (langCode !== undefined && langCode !== null) {
        const code_1 = langCode.substr(0, 2);
        const code_2 = langCode.substr(3, 2);

        if (code_1 === value_1 && code_2 === value_2) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

const InputGroupBoxInLive = ({ classTalkVisibility, isLiveChattingLocked, web_isWhisper, isAddFunctionOpened, isFinishLecture, isLectureLive, isLectureMode, selIndex, isWatsonError, isRunningWatson, isSpeakingMode, watsonInfo, handleSelectedClassTalkFunc }) => {
    const [lMessageValue, setMessageValue] = useState("");
    const [tHeight, setTHeight] = useState("32px");
    // const [bottom, setBottom] = useState(2.1);

    const [voicesIndex_KR, setVoicesIndex_KR] = useState(-1);
    const [voicesIndex_EN, setVoicesIndex_EN] = useState(-1);

    const [watsonStatus, setWatsonStatus] = useState(ctWatsonStatus.Exit);
    const [randomN, setRandomN] = useState(-1);

    const [bubbleOpacity, setBubbleOpacity] = useState(0.25);
    const bubbleTimeout = useRef(null);

    const memoTimeOutMessage = useMemo(() => getTimeOutMessage(randomN, watsonInfo.language), [randomN, watsonInfo.language]);

    const selectedClassTalkFuncHandler = useCallback(({ selectedFunc, msgText }) => {
        handleSelectedClassTalkFunc({ selectedFunc, msgText });
    }, []);

    const onSpeechCallback = (e) => {
        if (e !== undefined && e !== null) {
            //if (e.target instanceof SpeechSynthesisUtterance) {
            if (e.type === "start") {
                if (isRunningWatson) {
                    if (isLectureMode && isFinishLecture) {
                        stopRecognition();
                    } else {
                        setWatsonStatus(ctWatsonStatus.Speaking);
                    }
                }
            } else if (e.type === "end") {
                if (e.target.isLast && isRunningWatson) {
                    if (isSpeakingMode) {
                        if (isLectureLive) { // 강의 목록에서 선택해서 들어온 경우 by hjkim 20220930
                            if (isFinishLecture) {
                                clearWatson();
                                handleSelectedClassTalkFunc({ selectedFunc: "EXIT_WATSON" });
                            } else {
                                if (e.target.isNeedMicOn) {
                                    initRecognition({ noAnswer: e.target.noAnswer, lang: e.target.lang, watsonLanguageCode: watsonInfo.language });
                                } else {
                                    setWatsonStatus(ctWatsonStatus.Listening);
                                }
                            }
                        } else { // 클래스톡 [+] 메뉴에서 watson 실행한 경우 by hjkim 20220930
                            if (e.target.isNeedMicOn) {
                                initRecognition({ noAnswer: e.target.noAnswer, lang: e.target.lang, watsonLanguageCode: watsonInfo.language });
                            } else {
                                setWatsonStatus(ctWatsonStatus.Listening);
                            }
                        }
                    } else {
                        setWatsonStatus(ctWatsonStatus.Listening);
                    }
                    setRandomN(-1);
                }
            } else if (e.type === "error") {
                setRandomN(-1);
            }
            //}
        }
    };

    const onRecogCallback = (e) => {
        if (e !== undefined && e !== null) {
            //if (e.target instanceof window.SpeechRecognition) {
            //console.log(`onRecogCallback() - type[${e.type}]`);
            if (e.type === "start") {
                if (bubbleTimeout.current !== undefined && bubbleTimeout.current !== null) {
                    clearTimeout(bubbleTimeout.current);
                    bubbleTimeout.current = null;
                }

                if (isRunningWatson) {
                    setWatsonStatus(ctWatsonStatus.Listening);
                    setBubbleOpacity(0.25);
                    bubbleTimeout.current = setTimeout(() => setBubbleOpacity(0), 3000);
                }
            } else if (e.type === "speechend" || e.type === "end") {
                if (bubbleTimeout.current !== undefined && bubbleTimeout.current !== null) {
                    clearTimeout(bubbleTimeout.current);
                    bubbleTimeout.current = null;
                }
            } else if (e.type === "error") {
                if (e.error === "no-speech") {
                    let noAnswer = e.target.noAnswer;
                    let noAnswerLang = e.target.noAnswerLang;
                    clearRecognition();

                    if (noAnswer !== undefined && noAnswer !== null && noAnswer !== "") {
                        if (noAnswerLang !== undefined && noAnswerLang !== null) {
                            if (noAnswerLang === langCode.Korea.value || noAnswerLang === langCode.Korea.subValue) {
                                if (voicesIndex_KR > -1) {
                                    speak({ enIndex: voicesIndex_EN, krIndex: voicesIndex_KR, textData: [{ text: noAnswer, lang: noAnswerLang }], isLast: true, isNeedMicOn: true, noAnswer, watsonLanguageCode: watsonInfo.language });
                                }
                            } else {
                                if (voicesIndex_EN > -1) {
                                    speak({ enIndex: voicesIndex_EN, krIndex: voicesIndex_KR, textData: [{ text: noAnswer, lang: noAnswerLang }], isLast: true, isNeedMicOn: true, noAnswer, watsonLanguageCode: watsonInfo.language });
                                }
                            }
                        }
                    } else {
                        if (watsonInfo.language === langCode.Korea.value || watsonInfo.language === langCode.Korea.subValue) {
                            if (voicesIndex_KR > -1) {
                                let rNumber = Math.floor(Math.random() * timeOutMessage_Kr.length);
                                setRandomN(rNumber);
                            }
                        } else {
                            if (voicesIndex_EN > -1) {
                                let rNumber = Math.floor(Math.random() * timeOutMessage_En.length);
                                setRandomN(rNumber);
                            }
                        }
                    }
                } else if (e.error === "aborted") {

                }
            } else if (e.type === "result") {
                for (let i = e.resultIndex; i < e.results.length; i++) {
                    let transcript = e.results[i][0].transcript;
                    transcript.replace("\n", "<br>");

                    if (e.results[i].isFinal) {
                        if (transcript && transcript !== "" && e.results[i][0].confidence > 0) {
                            stopRecognition();
                            selectedClassTalkFuncHandler({ selectedFunc: "SEND_MSG_WATSON", msgText: transcript });
                            setMessageValue("");
                            setWatsonStatus(ctWatsonStatus.Processing);
                        } else {
                            setMessageValue(transcript);
                        }
                    } else {
                        setMessageValue(transcript);
                    }
                }
            }
            //}
        }
    };

    const rcvMessageHandler = ({ watsonMsgInfo }) => {
        cancel();   // cancel old speech bubble
        //clearWatson();

        let isLast = false;
        for (let i = 0; i < watsonMsgInfo.length; i++) {
            if (i === (watsonMsgInfo.length - 1)) {
                isLast = true;
            }

            if (watsonMsgInfo[i].kind === ctBubbleKind.RcvMsg) {
                let msgInfo = watsonMsgInfo[i].msgInfo;
                if (msgInfo) {
                    if (msgInfo.kind === undefined || msgInfo.kind === null) {
                        if (msgInfo.text && msgInfo.text !== "") {
                            speak({ enIndex: voicesIndex_EN, krIndex: voicesIndex_KR, textData: msgInfo.text, isLast, isNeedMicOn: msgInfo.isNeedMicOn, watsonLanguageCode: watsonInfo.language });
                        }
                    } else if (msgInfo.kind === "watson_text") {
                        speak({ enIndex: voicesIndex_EN, krIndex: voicesIndex_KR, textData: msgInfo.text, isLast, isNeedMicOn: msgInfo.isNeedMicOn, noAnswer: msgInfo.noAnswer, watsonLanguageCode: watsonInfo.language });
                    } else if (msgInfo.kind === "watson_image") {
                        if (msgInfo.text && msgInfo.text !== "") {
                            speak({ enIndex: voicesIndex_EN, krIndex: voicesIndex_KR, textData: msgInfo.text, isLast, isNeedMicOn: msgInfo.isNeedMicOn, noAnswer: msgInfo.noAnswer, watsonLanguageCode: watsonInfo.language });
                        }
                    } else {
                        console.log("watsonMsgInfo[i].msgInfo.kind => ", msgInfo.kind);
                    }
                }
            }
        }
    };

    const randomMessageHandler = ({ randomMessage }) => {
        if (bubbleTimeout.current !== undefined && bubbleTimeout.current !== null) {
            clearTimeout(bubbleTimeout.current);
            bubbleTimeout.current = null;
        }

        speak({ enIndex: voicesIndex_EN, krIndex: voicesIndex_KR, textData: randomMessage, isLast: true, isNeedMicOn: true, watsonLanguageCode: watsonInfo.language });
    };

    const { voices, listening, initRecognition, stopRecognition, clearRecognition, getVoices, speak, cancel } = useWatson({ isRunningWatson, isSpeakingMode, isLectureLive, onSpeechCallback, onRecogCallback });

    useEffect(() => {

        return () => {
            if (bubbleTimeout.current !== undefined && bubbleTimeout.current !== null) {
                clearTimeout(bubbleTimeout.current);
                bubbleTimeout.current = null;
            }

            const clear = async () => {
                await clearWatson();
            };

            clear();
        }
    }, []);

    useEffect(() => {
        console.log(classTalkVisibility);
    }, [classTalkVisibility]);

    useEffect(() => {
        if (isRunningWatson) {
            getVoices();
        } else {
            clearWatson();
        }
    }, [isRunningWatson]);

    useEffect(() => {
        if (voices.length > 0) {
            let enIdx = -1;
            let krIdx = -1;

            for (let i = 0; i < voices.length; i++) {
                if ((checkLanguage(voices[i].lang, langCode.USA.lang, langCode.USA.contry) && enIdx === -1) || (checkLanguage(voices[i].lang, langCode.USA.lang, langCode.USA.contry) && voices[i].name.indexOf("Google") !== -1)) {
                    enIdx = i;
                }
                if ((checkLanguage(voices[i].lang, langCode.Korea.lang, langCode.Korea.contry) && krIdx === -1) || (checkLanguage(voices[i].lang, langCode.Korea.lang, langCode.Korea.contry) && voices[i].name.indexOf("Google") !== -1)) {
                    krIdx = i;
                }
            }

            if (enIdx === -1 && krIdx === -1) {
                alert("can not use english and korean");
            }

            setVoicesIndex_EN(enIdx);
            setVoicesIndex_KR(krIdx);
        }
    }, [voices]);

    useEffect(() => {
        if (isRunningWatson) {
            if (watsonInfo.language === langCode.Korea.value) {
                if (voicesIndex_KR > -1) {
                    if (watsonInfo.msg !== undefined && watsonInfo.msg !== null && watsonInfo.msg.length > 0) {
                        rcvMessageHandler({ watsonMsgInfo: watsonInfo.msg });
                    }
                }
            } else {
                if (voicesIndex_EN > -1) {
                    if (watsonInfo.msg !== undefined && watsonInfo.msg !== null && watsonInfo.msg.length > 0) {
                        rcvMessageHandler({ watsonMsgInfo: watsonInfo.msg });
                    }
                }
            }
        }
    }, [voicesIndex_EN, voicesIndex_KR, isRunningWatson, watsonInfo.language, watsonInfo.msg]);

    useEffect(() => {
        if (isRunningWatson && memoTimeOutMessage !== null) {
            randomMessageHandler({ randomMessage: memoTimeOutMessage });
        }
    }, [isRunningWatson, memoTimeOutMessage]);

    useEffect(() => {
        if (lMessageValue === "") {
            setTHeight("32px");
            // setBottom("2.1rem");
        }
    }, [lMessageValue]);

    /* useEffect(() => {
        setMessageValue("");

        if (isSpeakingMode) {
            if (!listening) {
                if (watsonInfo) {
                    initRecognition({ noAnswer: "", lang: watsonInfo.language, watsonLanguageCode: watsonInfo.language });
                }
            } else {
                toggleRecognition();
            }
        } else {
            toggleRecognition();   
        }
    }, [isSpeakingMode]); */

    /* useEffect(() => {
        if (isWatsonError) {
            if (watsonStatus === ctWatsonStatus.Processing) {
                setWatsonStatus(ctWatsonStatus.Speaking);
                handleSelectedClassTalkFunc({ selectedFunc : "FALSE_TO_WATSON_ERROR" });
            }
        }
    }, [isWatsonError, watsonStatus]); */

    const clearWatson = () => {
        return new Promise(async (resolve, reject) => {
            setVoicesIndex_EN(-1);
            setVoicesIndex_KR(-1);
            clearRecognition();
            cancel();
            resolve();
        });
    }

    const onChangeMessageValue = (e) => {
        if (e.target.scrollHeight > 32) {
            let newHeight;

            if (e.target.scrollHeight > 160) {
                newHeight = "160px";
            } else {
                newHeight = e.target.scrollHeight + "px";
            }

            if (newHeight !== tHeight) {
                setTHeight(newHeight);
            }
        } else {
            let newHeight = "32px";
            if (newHeight !== tHeight) {
                setTHeight(newHeight);
            }
        }
        setMessageValue(e.target.value);
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            createSend();
            e.preventDefault();
        }
    }

    const createSend = () => {
        if (isRunningWatson && !isFinishLecture) {
            if (lMessageValue !== "") {
                if (listening) {
                    clearRecognition();
                }

                handleSelectedClassTalkFunc({ selectedFunc: "SEND_MSG_WATSON", msgText: lMessageValue });
                setMessageValue("");
            } else {
                setMessageValue("");

                let nowSpeakingMode = isSpeakingMode;

                if (nowSpeakingMode) { // 현재 스피킹모드였다면 입력모드로 전환해야 한다... by hjkim 20220929
                    clearRecognition();
                } else { // 현재 입력모드였다면 스피킹모드로 전환해야 한다... by hjkim 20220929
                    if (watsonInfo) {
                        initRecognition({ noAnswer: "", lang: watsonInfo.language, watsonLanguageCode: watsonInfo.language });
                    }
                }

                handleSelectedClassTalkFunc({ selectedFunc: "WATSON_TOGGLE" });
            }
        } else {
            if (selIndex < 0) {
                if (lMessageValue !== "") {
                    handleSelectedClassTalkFunc({ selectedFunc: "SEND_MSG", msgText: lMessageValue });
                    setMessageValue("");
                } else {
                    console.log("createSend() - 메시지 입력x");
                }
            } else {
                handleSelectedClassTalkFunc({ selectedFunc: "SEND_MSG", msgText: lMessageValue });
                setMessageValue("");
            }
        }
    }

    return (
        <div className="hiclasstv-classtalk-input-main" style={{ height: `${tHeight}`, minHeight: '2rem' }}>
            <div className={"touchLockGuard" + ((classTalkVisibility === "visible") && isLiveChattingLocked ? " visible" : "")}>
                채팅이 잠겼습니다.
            </div>
            <button type="button" className="hiclasstv-classtalk-button hiclasstv-blue-text" title="메뉴 더보기" onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_TOGGLE" })}>
                <FaPlusSquare className="font-md plus" aria-hidden="true" />
            </button>
            <div className="hiclasstv-classtalk-input-box">
                <textarea className="hiclasstv-classtalk-input" placeholder="메세지를 입력하세요" onChange={onChangeMessageValue} value={lMessageValue} onKeyPress={handleKeyPress} />
                {
                    (!isRunningWatson || isFinishLecture) &&
                    <div className="input-group-append align-items-center h-100">
                        <button type="button" className="hiclasstv-classtalk-button hiclasstv-yellow-text" title="이모티콘 선택" onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "EMOTICON_TOGGLE" })}>
                            <FaSmile className="font-md plus" aria-hidden="true" />
                        </button>
                    </div>
                }
            </div>
            {
                isFinishLecture ?
                    <button type="button" className="hiclasstv-classtalk-button hiclasstv-blue-text" onClick={createSend}>
                        <FaPaperPlane className="font-md plus" aria-hidden="true" />
                    </button> :
                    <>
                        {
                            isRunningWatson && isSpeakingMode ?
                                <SpeakingModeWatsonImage bubbleOpacity={bubbleOpacity} watsonStatus={watsonStatus} onClick={createSend} /> :
                                isRunningWatson && isSpeakingMode === false ?
                                    <WatsonImage watsonStatus={watsonStatus} onClick={createSend} /> :
                                    <button type="button" className="hiclasstv-classtalk-button hiclasstv-blue-text" onClick={createSend}>
                                        <FaPaperPlane className="font-md plus" aria-hidden="true" />
                                    </button>
                        }
                    </>
            }
        </div>
    );
}

export default InputGroupBoxInLive;