import React from 'react';

const WebBroadcastLiveStatus = ({ liveStatus, liveName }) => {
    return (
        <div className="text-area">
            <span className={(liveStatus === 'Y' ? "info-title " : "") + "hiclass-text"} title={liveName}>
                <b>{liveName}</b>
            </span>
            {
                liveStatus === 'Y' ?
                <span className="live-status on ml-1">ON</span> :
                <span className="live-status off ml-1">OFF</span>
            }
        </div>
    )
}

const WebWatchLiveStatus = ({ isGuestYn, broadcastOpenYN, liveStatus, liveName }) => {
    return (
        <div className="text-area">
            {
                isGuestYn === 'Y' ?
                    broadcastOpenYN === 'Y' && liveStatus === 'Y' && 
                    <span className="live-status onAir mr-1">
                        <b>onAir</b>
                    </span> :
                    liveStatus === 'Y' && 
                    <span className="live-status onAir mr-1">
                        <b>onAir</b>
                    </span>
            }
            {/* {
                liveStatus === 'Y' &&
                <span className="live-status onAir mr-1">
                    <b>onAir</b>
                </span>
            } */}
            <span className={(liveStatus === 'Y' ? "info-title " : "") + "hiclass-text"} title={liveName}>
                <b>{liveName}</b>
            </span>
        </div>
    );
}

const WebLiveStatus = ({ kind, isGuestYn, broadcastOpenYN, liveStatus, liveName }) => {
    switch (kind) {
        case 'broadcast' :
            return <WebBroadcastLiveStatus liveStatus={liveStatus} liveName={liveName} />;

        default :
            return <WebWatchLiveStatus isGuestYn={isGuestYn} broadcastOpenYN={broadcastOpenYN} liveStatus={liveStatus} liveName={liveName} />;
    }
}

export default WebLiveStatus;