import React from 'react';
import { Modal } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

const WebRefundAlert = ({ isModalOpened, refundType, refundPrice, pchs_info, toggleModalOepened, handleSelectedFunc }) => {
    
    const onClickAccept = () => {
        if (refundType === 'REFUND') {
            toggleModalOepened(false);
            handleSelectedFunc({ selectedFunc: 'REFUND_PASS', pchsSeq: pchs_info.pchsSeq });
        } else if (refundType === 'P-REFUND') {
            toggleModalOepened(false);
            handleSelectedFunc({ selectedFunc: 'P_REFUND_PASS', pchsSeq: pchs_info.pchsSeq, price : refundPrice });
        } else {
            // 환불 불가
        }
    }
    
    return (
        <Modal isOpen={isModalOpened} centered toggle={() => toggleModalOepened(false)}>
            <div className="card p-2 align-items-center justify-content-center">
                <div className="title-area justify-content-end mb-3">
                    <button className="hiclasstv-btn p-0" title="닫기" onClick={() => toggleModalOepened(false)}>
                        <FaTimes className="d-flex m-1 black-text" />
                    </button>
                </div>
                <h3>
                    이용권 환불 신청
                </h3>
                {
                    refundType !== 'None' ? 
                    <div className="d-flex flex-column text-center w-100 px-2">
                        <span>
                            이용권 환불 규정에 따라 아래와 같은 금액이 환불될 예정입니다.
                        </span>
                        <span><b>{refundPrice + '원'}</b></span>
                        <span>
                            환불을 진행하시겠습니까?
                        </span>
                        <button type="button" className="btn btn-danger btn-sm" onClick={onClickAccept}>네</button>
                    </div> :
                    <div className="d-flex flex-column text-center w-100 px-2">
                        <span>
                            이용권 환불 규정에 따라 환불이 불가능합니다.
                        </span>
                        <button type="button" className="btn btn-danger btn-sm" onClick={() => toggleModalOepened(false)}>네</button>
                    </div>
                }
            </div>
        </Modal>
    );
};

export default WebRefundAlert;