import React, { Component } from "react";
import { connect } from "react-redux";

import WebHeader from "../../components/etc/WebHeader";
import UserInfoContainer from "../user/UserInfoContainer";

import {
    toggleNoticeModal
} from "../../modules/hiclasstv";

class WebHeaderTitleContainer extends Component {

    handleSelectedFunc = ({ selectedFunc }) => {
        switch (selectedFunc) {
            case "REFRESH_HOME":
                this.props.toggleNoticeModal({ status: true });
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <div className="d-flex flex-column w-100">
                <div className="header-title">
                    <WebHeader
                        userKind={this.props.userKind}
                        isLoginYn={this.props.isLoginYn}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
                </div>
                <div className="header-menu">
                    <UserInfoContainer
                        isLoginYn={this.props.isLoginYn}
                        routeProps={this.props.routeProps}
                        currentPageType={this.props.currentPageType}
                        currentManagePageType={this.props.currentManagePageType}
                        goToAloneChatting={this.props.goToAloneChatting}
                    />
                </div>
            </div>
        );
    }
}

export default connect(
    ({ user }) => ({
        userKind        : user.userKind,
        isLoginYn       : user.isLoginYn
    }),
    {
        toggleNoticeModal
    }
)(WebHeaderTitleContainer);