import React from "react";

const createArr = (n) => {
    const iArr = new Array(n);

    for (let i = 0; i < n; i++) {
        iArr[i] = i + 1;
    }

    return iArr;
}

const WebPagination = ({ total, limit, page, setPage, blockNum, setBlockNum }) => {

    const pagenationLimit = 10; // 보여줄 페이지네이션 개수

    const totalPage = Math.ceil(total / limit);

    const blockArea = Number(blockNum * pagenationLimit);
    const nArr = createArr(Number(totalPage));
    const pArr = nArr.slice(blockArea, Number(pagenationLimit) + blockArea);

    const firstPage = () => {
        setPage(1);
        setBlockNum(0);
    }

    const lastPage = () => {
        setPage(totalPage);
        setBlockNum(Math.ceil(totalPage / pagenationLimit) - 1);
    }

    const prevPage = () => {
        if (page <= 1) return;

        if (page -1 <= pagenationLimit * blockNum) {
            setBlockNum(prevPage => prevPage - 1);
        }
        setPage(prevPage => prevPage - 1);
    }

    const nextPage = () => {
        if (page >= totalPage) return;

        if (pagenationLimit * Number(blockNum + 1) < Number(page + 1)) {
            setBlockNum(prevPage => prevPage + 1);
        }
        setPage(prevPage => prevPage + 1);
    }

    return (
        <nav className="hiclasstv-page-wrapper" /* style={{ gap: '4px', margin: '16px' }} */>
            <button
                className="hiclasstv-page-btn move-to-first"
                onClick={firstPage}
                disabled={page === 1}>
                &lt;&lt;
            </button>
            <button
                className="hiclasstv-page-btn move-to-previous"
                onClick={prevPage}
                disabled={page === 1}>
                &lt;
            </button>
            {
                /* Array(totalPage)
                    .fill()
                    .map((_, i) => (
                        <button
                            className="hiclasstv-page-btn"
                            key={"page-" + (i + 1)}
                            onClick={() => setPage(i + 1)}
                            aria-current={page === i + 1 ? "page" : null}>
                            {i + 1}
                        </button>
                    )) */
                pArr.map((n, i) => (
                    <button
                        className="hiclasstv-page-btn"
                        key={"page-" + n}
                        onClick={() => setPage(n)}
                        aria-current={page === n ? "page" : null}>
                        {n}
                    </button>
                ))
            }
            <button
                className="hiclasstv-page-btn move-to-next"
                onClick={nextPage}
                disabled={page === totalPage}>
                &gt;
            </button>
            <button
                className="hiclasstv-page-btn move-to-last"
                onClick={lastPage}
                disabled={page === totalPage}>
                &gt;&gt;
            </button>
        </nav>
    );
}

export default WebPagination;