//https://yeun.github.io/open-color/
const palette = {
    gray : [
        '#f8f9fa',
        '#f1f3f5',
        '#e9ecef',
        '#dee2e6',
        '#ced4da',
        '#adb5bd',
        '#868e96',
        '#495057',
        '#343a40',
        '#212529'
    ],
    cyan : [
        '#e3fafc',
        '#c5f6fa',
        '#99e9f2',
        '#66d9e8',
        '#3bc9db',
        '#22b8cf',
        '#15aabf',
        '#1098ad',
        '#0c8599',
        '#0b7285'
    ],
    orange : [
        '#fff4e6',
        '#ffe8cc',
        '#ffd8a8',
        '#ffc078',
        '#ffa94d',
        '#ff922b',
        '#fd7e14',
        '#f76707',
        '#e8590c',
        '#d9480f'
    ],
    red : [
        '#fff5f5',
        '#ffe3e3',
        '#ffc9c9',
        '#ffa8a8',
        '#ff8787',
        '#ff6b6b',
        '#fa5252',
        '#f03e3e',
        '#e03131',
        '#c92a2a'
    ],
    indigo : [
        '#edf2ff',
        '#dbe4ff',
        '#bac8ff',
        '#91a7ff',
        '#748ffc',
        '#5c7cfa',
        '#4c6ef5',
        '#4263eb',
        '#3b5bdb',
        '#364fc7'
    ],
    yellow : [
        '#fff9db',
        '#fff3bf',
        '#ffec99',
        '#ffe066',
        '#ffd43b',
        '#fcc419',
        '#fab005',
        '#f59f00',
        '#f08c00',
        '#e67700'
    ],
    green : [
        '#ebfbee',
        '#d3f9d8',
        '#b2f2bb',
        '#8ce99a',
        '#69db7c',
        '#51cf66',
        '#40c057',
        '#37b24d',
        '#2f9e44',
        '#2b8a3e'
    ],
    blue : [
        '#e7f5ff',
        '#d0ebff',
        '#a5d8ff',
        '#74c0fc',
        '#4dabf7',
        '#339af0',
        '#228be6',
        '#1c7ed6',
        '#1971c2',
        '#1864ab'
    ],
    violet : [
        '#f3f0ff',
        '#e5dbff',
        '#d0bfff',
        '#b197fc',
        '#9775fa',
        '#845ef7',
        '#7950f2',
        '#7048e8',
        '#6741d9',
        '#5f3dc4'
    ],
    white : [
        '#ffffff',
    ],

}
export default palette;