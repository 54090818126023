import React, { useEffect, useRef } from 'react';

import { stAttachmentType, stQuizDataType } from '../../../../lib/ConstCommand';

const AudioItem = ({ audio }) => {
    const audioRef = useRef(null);
    const isMount = useRef(false);

    useEffect(() => {
        isMount.current = true;
        return () => {
            isMount.current = false;
            if (audioRef && audioRef.current) {
                if (!audioRef.current.paused) {
                    audioRef.current.pause();
                    audioRef.current.currentTime = 0;
                }

                if (audioRef.current.src && audioRef.current.src !== '') {
                    URL.revokeObjectURL(audioRef.current.src);
                    audioRef.current.src = '';
                }

                audioRef.current = null;
            }
        }
    }, []);

    useEffect(() => {
        if (audio !== undefined && audio !== null) {
            if (audio.data !== undefined && audio.data !== null) {
                if (audio.type === stAttachmentType.URL || audio.type === stQuizDataType.URL) {
                    (async function fetchData() {
                        try {
                            const res = await fetch(audio.data);
                            const blob = await res.blob();
                            if (isMount.current) {
                                let objURL = URL.createObjectURL(blob);

                                if (audioRef && audioRef.current) {
                                    audioRef.current.src = objURL;
                                    // audioRef.current.onload = function () {
                                    //     //cleanup.
                                    //     URL.revokeObjectURL(objURL);
                                    // }
                                }
                            }
                        } catch (err) {
                            console.log("fetchData(AudioItem) - err => ", err);
                        }
                    })();
                } else {
                    let byteString = atob(audio.data.split(',')[1]);
                    let mimeString = audio.data.split(',')[0].split(':')[1].split(';')[0];
                    let ab = new ArrayBuffer(byteString.length);
                    let ia = new Uint8Array(ab);

                    for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                    }

                    let blob = new Blob([ab], { type: mimeString });
                    if (isMount.current) {
                        let objURL = URL.createObjectURL(blob);

                        if (audioRef && audioRef.current) {
                            audioRef.current.src = objURL;
                            // audioRef.current.onload = function () {
                            //     //cleanup.
                            //     URL.revokeObjectURL(objURL);
                            // }
                        }
                    }
                }
            }
        }
    }, [audio]);

    return (
        <audio ref={audioRef} className="audio-item" type="item" controls controlsList="nodownload" />
    );
}

export default AudioItem;