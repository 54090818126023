import React from 'react';

const AppServicePolicy = () => {
    
    return (
        <span>
            <p className="last-p"><b>㈜이지시스템(Easy Systems Inc.) 및 Pentalk onScreen과 관련된 SW와 SW서비스 (이하 “onScreen” 이라 칭함)의 웹사이트, 제품 및 서비스, 관련 소프트웨어(이하 일괄하여 "서비스"라 칭함)를 개인, 법인 및 단체 등의 사용자(이하 “회원”이라 칭함)가 본 서비스를 유료 또는 무료로 사용하는 것에 대하여 본 약관에 동의하고 준수하는 것을 조건으로 합니다. 동의 전에 본 약관을 주의 깊게 읽으십시오.</b></p>
            
            <h3>1. 개인정보처리방침</h3>
            <ul>
                <li>
                    <span className="sub-title">1.1 개인정보 수집</span><br />
                    본 서비스에서는 개인 정보를 수집하지 않습니다.
                </li>
                <li>
                    <span className="sub-title">1.2 개인정보 이용</span><br />
                    본 서비스에서는 개인 정보를 수집하지 않으며, 이용하지 않습니다.
                </li>
                <li>
                    <span className="sub-title">1.3 개인정보 제공</span><br />
                    본 서비스에서는 개인 정보를 수집하지 않으며, 제공하지 않습니다.
                </li>
                <li>
                    <span className="sub-title">1.4 개인정보 파기</span><br />
                    본 서비스에서는 개인 정보를 수집하지 않으며, 저장하지 않습니다.
                </li>
                <li>
                    <span className="sub-title">1.4 개인위치정보의 처리</span><br />
                    본 서비스에서는 개인의 위치 정보를 수집하지 않으며, 저장하지 않습니다.
                </li>
                <li>
                    <span className="sub-title">1.5 개인정보처리방침이 변경되는 경우</span><br />
                    법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보처리방침을 수정할 수 있습니다. 개인정보처리방침이 변경되는 경우 이지시스템은 변경 사항을 게시하며, 변경된 개인정보처리방침은 게시한 날로부터 7일 후부터 효력이 발생합니다.
                    다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 미리 알려드리겠습니다.
                </li>
            </ul>
            
            <h3>2. 기타 사항</h3>
            <ul>
                <li>
                    <span className="sub-title">2.1 법률 및 관할</span><br />
                    본 계약 및 그 해석에는 대한민국의 관련 법을 적용합니다. 별지에 의해 규정된 경우를 제외하고, 당사자들은 서울특별시 금천구에 소재하는 법원, 소재하는 법원의 전속관할에 동의합니다.
                </li>
                <li>
                    <span className="sub-title">2.2 계약 법인</span><br />                    
                    본 서비스 약관에 따른 계약 법인은 HiClassTV 및 onScreen의 당사자인 ㈜이지시스템 이 됩니다.
                </li>
                <li>
                    <span className="sub-title">2.3 포기 및 분리 가능성</span><br />
                    일방 당사자가 본 계약상의 권리를 행사하지 않거나 본 계약의 조항을 집행하지 않는 경우에도 이는 해당 권리 또는 해당 조항의 집행 가능성의 포기 또는 박탈로 간주되지 않습니다. 관할 법원에 따라 본 계약의 특정 조항이 불법이거나, 무효하거나, 집행 불가능할 경우 해당 조항은 기존 조항과 최대한으로 동일한 경제적 효과를 달성할 수 있도록 수정하며 계약의 나머지 부분은 전적으로 유효성을 유지합니다.
                </li>
                <li>
                    <span className="sub-title">2.4 일반 규정</span><br />
                    본 계약은 본 계약의 주제에 관한 양 당사자 간의 완전한 이해와 합의를 구체화한 것이며, 해당 주제와 관련하여 지금까지 행해진 양 당사자 간의 모든 이해와 합의를 대체합니다. 단, 회원 또는 회원의 회사가 별도의 서면 계약을 체결하였거나 또는 회원의 본 서비스 사용에 적용되는 별도의 계약을 언급하는 주문서에 서명한 경우, 본 계약과 해당 계약의 내용이 충돌하는 경우에는 해당 계약이 우선 적용됩니다. HiClassTV는 단독 재량에 의해 본 계약 조건을 때에 따라 변경하거나 보충할 수 있습니다. HiClassTV는 본 계약에 중대한 변경 사항 발생시 회원에게 통지하도록 최선을 다 합니다. 특정 서비스에 참여하기 위해 회원의 소프트웨어 다운로드 및/또는 추가 약관에 대한 동의가 요구될 수 있습니다. 해당 추가 약관에 명시적으로 제시되어 있지 않는 한, 해당 추가 약관은 본 계약에 포함됩니다.
                </li>
            </ul>
        </span>
    )
}

export default AppServicePolicy;