import React from 'react';

const WebFooter = () => {
    return (
        <div className="footer-template-web">
            <span>
                <span className="font-weight-bold mr-2">(주)이지시스템</span>
                <span>서울 금천구 가산동 371-28 우림라이온스밸리 C동 13층</span>
                <span className="mx-2">|</span>
                <span>대표 이승호</span>
                <span className="mx-2">|</span>
                <span>사업자등록번호 220-81-03309</span>
                <span className="mx-2">|</span>
                <span>전화 02-3484-4800</span>
                <span className="mx-2">|</span>
                <span>팩스 02-2026-3999</span>
                <br />
                <span className="font-weight-bold">
                    <a href="/policy">이용약관 및 개인정보처리방침</a>
                </span>
                <span className="mx-2">|</span>
                <span>1992~2022 © Easy Systems Inc.</span>
            </span>
        </div>
    );
}

export default WebFooter;