
import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { enWebPage } from '../../../lib/ConstCommand';

import { subscribe, unsubscribe } from '../../../lib/MediaSoupClient';
import { initHandsUpFunc, startHandsUpMode, stopHandsUpMode, 
         setHandsUpActive, setHandsUpInactive, clearHandsUpMode, initialize } from '../../../lib/HandsUpClient';
import { connectLive, disconnectLive, disconnectLiveForQRLogin, initMyLiveInfo } from '../../../lib/RabbitmqClient';
import { callExitFullScreen/* , callRequestFullScreen */ } from '../../../lib/func/ExportFunction';

import LiveSmallGroupWindow from './LiveSmallGroupWindow';

// import ConnectImg from '../../../images/connect.png';
// import AndroidConnectImg from '../../../images/android_connect.png';

const ConnectImgComp = styled.img`
    width : '100px';
    height : '100px;
`;

const VideoComp = ConnectImgComp.withComponent("video");

const isMobile = (navigator.userAgent.toLowerCase().indexOf('android') > -1 || navigator.userAgent.toLowerCase().indexOf('iphone') > -1 || (navigator.userAgent.toLowerCase().indexOf('macintosh') > -1 && navigator.maxTouchPoints > 2));

const LiveMediaSoupRcv = ({ userSeq, userLoginPageType, performLiveInfo, performLiveStatusInfo, performHandsUpStatusInfo, smallGroupInfo, clickElementPoint, teacherViewVisibility, myCamViewVisibility, isRbmqServerConnected, isReadyLiveConsume, isRetryConnect, isFullScreenMode, isGuestYn, broadcastOpenYN, isLiveGuestAlertModalOpened, isLiveMixedAlertModalOpened, isLectureAlertModalOpened, handleSelectedLiveFunc, handleControlToolFunc }) => {

    const interval = useRef(null);
    const volumeInterval = useRef(null);

    const [teacherInfo, setTeacherInfo] = useState(performLiveInfo && performLiveInfo.teacher_info ?
        performLiveInfo.teacher_info : { userSeq: -1, userNickname: '', userGateWay: '' });

    const [lWidth, setWidth] = useState(isMobile ? '160px' : '240px');
    const [lHeight, setHeight] = useState(isMobile ? '90px' : '135px');
    const [lHandsUpWidth, setHandsUpWidth] = useState(isMobile ? '160px' : '240px');
    const [lHandsUpHeight, setHandsUpHeight] = useState(isMobile ? '90px' : '135px');

    const [lHandsUpLeftOffset, setHandsUpLeftOffset] = useState(isMobile ? '162px' : '241px');

    const [lHandsUpVideoDisplayMode, setHandsUpVideoDisplayMode] = useState('flex');
    const [lHandsUpImgDisplayMode, setHandsUpImgDisplayMode] = useState('none');

    const [isMyCamFullScreenMode, setIsMyCamFullScreenMode] = useState(false);

    // handsUp이 videoEl / imgEl 중 어떤 것을 사용 중인지 판별용으로 사용 ... by hjkim 20221109
    const isHandsUpImgFlex = useRef(false);

    const videoEl = useRef(null);
    const audioEl = useRef(null);
    const micEl = useRef(null);
    const handsupVideoEl = useRef(null);
    const handsupImgEl = useRef(null);
    const myCamVideoEl = useRef(null);

    const teacherViewAreaRef = useRef(null);
    const handsUpAreaRef = useRef(null);

    const liveMediaSoupHandler = useCallback(() => {
        //console.log(`liveMediaSoupHandler - liveSeq[${performLiveInfo.liveSeq}], connectStatus[${performLiveStatusInfo.connectStatus}]`);
        if ((isLiveGuestAlertModalOpened === false) && (isLiveMixedAlertModalOpened === false)) {
            if (teacherInfo.userSeq > -1 && performLiveInfo.liveSeq > -1 && teacherInfo.userGateWay && teacherInfo.userGateWay !== '') {
                if (isGuestYn === 'Y') {
                    if (broadcastOpenYN === 'Y') {
                        if (performLiveInfo.liveStatus === 'Y') {
                            if (performLiveStatusInfo.connectStatus === 'disable') {
                                //console.log(`(1)liveMediaSoupHandler - liveStatus[${performLiveInfo.liveStatus}] connectStatus[${performLiveStatusInfo.connectStatus}]`);
                                connectLive(teacherInfo.userSeq, performLiveInfo.liveSeq, teacherInfo.userGateWay);
                            } else {
                                //console.log(`(1-1)liveMediaSoupHandler - liveStatus[${performLiveInfo.liveStatus}] connectStatus[${performLiveStatusInfo.connectStatus}]`);
                            }
                        } else {
                            if (performLiveStatusInfo.connectStatus !== 'disable') {
                                //console.log(`(2)liveMediaSoupHandler - liveStatus[${performLiveInfo.liveStatus}] connectStatus[${performLiveStatusInfo.connectStatus}]`);
                                disconnectLive();
                            } else {
                                //console.log(`(2-1)liveMediaSoupHandler - liveStatus[${performLiveInfo.liveStatus}] connectStatus[${performLiveStatusInfo.connectStatus}]`);
                            }
                        }
                    } else { // broadcastOpenYN === 'N' case...
                        if (performLiveStatusInfo.connectStatus !== 'disable') {
                            //console.log(`(3)liveMediaSoupHandler - liveStatus[${performLiveInfo.liveStatus}] connectStatus[${performLiveStatusInfo.connectStatus}]`);
                            disconnectLive();
                        } else {
                            //console.log(`(3-1)liveMediaSoupHandler - liveStatus[${performLiveInfo.liveStatus}] connectStatus[${performLiveStatusInfo.connectStatus}]`);
                        }
                    }
                } else { // live member인 case 
                    if (performLiveInfo.liveStatus === 'Y') {
                        if (performLiveStatusInfo.connectStatus === 'disable') {
                            //console.log(`(4)liveMediaSoupHandler - liveStatus[${performLiveInfo.liveStatus}] connectStatus[${performLiveStatusInfo.connectStatus}]`);
                            connectLive(teacherInfo.userSeq, performLiveInfo.liveSeq, teacherInfo.userGateWay);
                        } else {
                            //console.log(`(4-1)liveMediaSoupHandler - liveStatus[${performLiveInfo.liveStatus}] connectStatus[${performLiveStatusInfo.connectStatus}]`);
                        }
                    } else { // liveStatus === 'N' case
                        if (performLiveStatusInfo.connectStatus !== 'disable') {
                            //console.log(`(5)liveMediaSoupHandler - liveStatus[${performLiveInfo.liveStatus}] connectStatus[${performLiveStatusInfo.connectStatus}]`);
                            disconnectLive();
                        } else {
                            //console.log(`(5-1)liveMediaSoupHandler - liveStatus[${performLiveInfo.liveStatus}] connectStatus[${performLiveStatusInfo.connectStatus}]`);
                        }
                    }
                }
            } else { /** 선생님 클래스 종료 전 학생이 끝내기 버튼을 누르고 나갔을 경우를 위한 처리 by hjkim 20220404 */
                if (performLiveStatusInfo.connectStatus !== 'disable') {
                    if (userLoginPageType !== enWebPage.LiveQRLogin && userLoginPageType !== enWebPage.MixedClassLogin) {
                        // console.log(`(6)liveMediaSoupHandler - liveStatus[${performLiveInfo.liveStatus}] connectStatus[${performLiveStatusInfo.connectStatus}]`);
                        disconnectLive();
                    } else {
                        // console.log(`(6-1)liveMediaSoupHandler - liveStatus[${performLiveInfo.liveStatus}] connectStatus[${performLiveStatusInfo.connectStatus}]`);
                        unsubscribe(false, true);
                        disconnectLiveForQRLogin();
                    }
                } else {
                    //console.log(`(6-2)liveMediaSoupHandler - liveStatus[${performLiveInfo.liveStatus}] connectStatus[${performLiveStatusInfo.connectStatus}]`);
                }
            }
        }
    }, [isLiveGuestAlertModalOpened, isLiveMixedAlertModalOpened, isGuestYn, broadcastOpenYN, teacherInfo.userSeq, teacherInfo.userGateWay, performLiveInfo.liveSeq, performLiveInfo.liveStatus, performLiveStatusInfo.connectStatus, userLoginPageType]);

    const liveHandsUpHandler = useCallback(() => {
        //console.log(`liveHandsUpHandler - isGuestYn[${isGuestYn}], broadcastOpenYN[${broadcastOpenYN}], handsUpSelectYN[${performLiveInfo.handsUpSelectYN}]`);
        //console.log(`liveHandsUpHandler - liveSeq[${performLiveInfo.liveSeq}], teacherUserSeq[${teacherInfo.userSeq}], userSeq[${userSeq}]`);
        //console.log(`liveHandsUpHandler - handsUpYN[${performLiveInfo.handsUpYN}], handsUpSelectYN[${performLiveInfo.handsUpSelectYN}], connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
        if ((isLiveGuestAlertModalOpened === false) && (isLiveMixedAlertModalOpened === false)) {
            if (performLiveInfo.liveSeq > -1 && teacherInfo.userSeq > -1 && userSeq > -1) {
                if (isGuestYn === 'Y') {
                    if (broadcastOpenYN === 'Y') {
                        if (performLiveInfo.liveStatus === 'Y') {
                            if (performLiveInfo.handsUpYN === 'Y') {
                                if (performLiveStatusInfo.isConnect) {
                                    if (performHandsUpStatusInfo.connectStatus !== 'connect') {
                                        console.log(`(1)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                                        startHandsUpMode(performLiveInfo.liveSeq, teacherInfo.userSeq, userSeq, performLiveInfo.handsUpSelectYN, handsupVideoEl.current, handsupImgEl.current, myCamVideoEl.current, interval, volumeInterval);//, handsupAudioEl.current);
                                    } else {
                                        //console.log(`(1-1)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                                    }
                                } else {
                                    console.log(`(1-2)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                                }
                            } else { // performLiveInfo.handsUpYN === 'N' case
                                if (performHandsUpStatusInfo.connectStatus !== 'disable') {
                                    console.log(`(2)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                                    stopHandsUpMode(performLiveInfo.liveSeq, userSeq, false, false);
                                } else {
                                    //console.log(`(2-1)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                                }
                            }
                        } else { // performLiveInfo.liveStatus === 'N' case
                            if (performHandsUpStatusInfo.connectStatus !== 'disable') {
                                //console.log(`(3)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                                stopHandsUpMode(performLiveInfo.liveSeq, userSeq, false, true);
                            } else {
                                //console.log(`(3-1)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                            }
                        }
                    } else { // broadcastOpenYN === 'N' case...
                        if (performHandsUpStatusInfo.connectStatus !== 'disable') {
                            //console.log(`(4)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                            stopHandsUpMode(performLiveInfo.liveSeq, userSeq, false, true);
                        } else {
                            //console.log(`(4-1)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                        }
                    }
                } else { // live member인 case 
                    if (performLiveInfo.liveStatus === 'Y') {
                        if (performLiveInfo.handsUpYN === 'Y') {
                            if (performLiveStatusInfo.isConnect) {
                                if (performHandsUpStatusInfo.connectStatus !== 'connect') {
                                    console.log(`(5)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                                    startHandsUpMode(performLiveInfo.liveSeq, teacherInfo.userSeq, userSeq, performLiveInfo.handsUpSelectYN, handsupVideoEl.current, handsupImgEl.current, myCamVideoEl.current, interval, volumeInterval);//, handsupAudioEl.current);
                                } else {
                                    //console.log(`(5-1)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                                }
                            } else {
                                console.log(`(5-2)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                            }
                        } else { // performLiveInfo.handsUpYN === 'N' case
                            if (performHandsUpStatusInfo.connectStatus !== 'disable') {
                                //console.log(`(6)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                                stopHandsUpMode(performLiveInfo.liveSeq, userSeq, false, false);
                            } else {
                                //console.log(`(6-1)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                            }
                        }
                    } else { // performLiveInfo.liveStatus === 'N' case
                        if (performHandsUpStatusInfo.connectStatus !== 'disable') {
                            //console.log(`(7)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                            stopHandsUpMode(performLiveInfo.liveSeq, userSeq, false, true);
                        } else {
                            //console.log(`(7-1)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                        }
                    }
                }
            } else { /** 선생님 클래스 종료 전 학생이 끝내기 버튼을 누르고 나갔을 경우를 위한 처리 by hjkim 20220404 */
                if (performHandsUpStatusInfo.connectStatus !== 'disable') {
                    //console.log(`(8)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                    clearHandsUpMode();
                } else {
                    //console.log(`(9)liveHandsUpHandler - liveStatus[${performLiveInfo.liveStatus}] isConnect[${performLiveStatusInfo.isConnect}] handsUpYN[${performLiveInfo.handsUpYN}] connectStatus[${performHandsUpStatusInfo.connectStatus}]`);
                }
            }
        }
    }, [isLiveGuestAlertModalOpened, isLiveMixedAlertModalOpened, isGuestYn, broadcastOpenYN, performLiveInfo.liveSeq, teacherInfo.userSeq, userSeq, performLiveInfo.liveStatus, performLiveStatusInfo.isConnect, performLiveInfo.handsUpYN, performLiveInfo.handsUpSelectYN, performHandsUpStatusInfo.connectStatus]);

    const liveHandsUpActiveHandler = useCallback(() => {
        if (performLiveInfo.liveSeq > -1 && userSeq > -1) {
            if (performLiveInfo.liveStatus === 'Y' && performLiveInfo.handsUpYN === 'Y' && performHandsUpStatusInfo.connectStatus === 'connect') {
                if (performLiveInfo.handsUpSelectYN === 'Y') {
                    setHandsUpActive(performLiveInfo.liveSeq, userSeq);
                } else {
                    setHandsUpInactive(performLiveInfo.liveSeq, userSeq);
                }
            }
        }
    }, [performLiveInfo.liveSeq, userSeq, performLiveInfo.liveStatus, performLiveInfo.handsUpYN, performLiveInfo.handsUpSelectYN, performHandsUpStatusInfo.connectStatus]);

    const liveTeacherViewHandler = useCallback(() => {
        if (teacherViewVisibility !== 'visible') return;

        if (isFullScreenMode) {
            setWidth('100%');
            setHeight('100%');
            setHandsUpLeftOffset('unset');
        } else {
            setWidth(isMobile ? '160px' : '240px');
            setHeight(isMobile ? '90px' : '135px');
            setHandsUpLeftOffset(isMobile ? '162px' : '241px');
        }
    }, [isFullScreenMode, teacherViewVisibility]);

    const liveMyCamViewHandler = useCallback(() => {
        if (isFullScreenMode) {
            if (isMyCamFullScreenMode) {
                setIsMyCamFullScreenMode(false);
            } else {
                setHandsUpWidth(isMobile ? '160px' : '240px');
                setHandsUpHeight(isMobile ? '90px' : '135px');
                setHandsUpLeftOffset('unset');
            }
        } else {
            if (isMyCamFullScreenMode) {
                if (myCamViewVisibility === 'none') {
                    setIsMyCamFullScreenMode(false);
                } else {
                    setHandsUpWidth('100%');
                    setHandsUpHeight('100%');
                    setHandsUpLeftOffset('unset');
                }
            } else {
                setHandsUpWidth(isMobile ? '160px' : '240px');
                setHandsUpHeight(isMobile ? '90px' : '135px');
                setHandsUpLeftOffset(isMobile ? '162px' : '241px');
            }
        }
    }, [isFullScreenMode, isMyCamFullScreenMode, myCamViewVisibility]);

    const imageProcess = (status, value) => {
        switch (status) {
            case 'viewKind_camera': {
                isHandsUpImgFlex.current = false;

                setHandsUpImgDisplayMode('none');
                setHandsUpVideoDisplayMode('flex');

                handleControlToolFunc({ target: 'viewKind', value: 'CAMERA' });
                handleControlToolFunc({ target: 'myCamViewVisibility', value: 'none' });

                const changeButton = document.getElementById('btnChangeViewKind');
                if (changeButton) changeButton.disabled = false;
            } break;

            case 'viewKind_screen': {
                isHandsUpImgFlex.current = false;

                setHandsUpImgDisplayMode('none');
                setHandsUpVideoDisplayMode('flex');

                handleControlToolFunc({ target: 'viewKind', value: 'SCREEN' });
                handleControlToolFunc({ target: 'myCamViewVisibility', value: 'none' });

                const changeButton = document.getElementById('btnChangeViewKind');
                if (changeButton) changeButton.disabled = false;
            } break;

            case 'android_appConnect':
                isHandsUpImgFlex.current = true;

                if (handsupImgEl.current !== null) {
                    handsupImgEl.current.src = "/images/android_connect.png";
                }

                setHandsUpVideoDisplayMode('none');
                setHandsUpImgDisplayMode('flex');
                break;

            case 'hImg_visible':
                isHandsUpImgFlex.current = true;

                setHandsUpVideoDisplayMode('none');
                setHandsUpImgDisplayMode('flex');
                break;

            case 'h_disconnect':
                isHandsUpImgFlex.current = true;

                if (handsupImgEl.current !== null) {
                    handsupImgEl.current.src = "/images/connect.png";
                }

                setHandsUpVideoDisplayMode('none');
                setHandsUpImgDisplayMode('flex');

                handleControlToolFunc({ target: 'isFullScreenMode', value: false });
                break;

            case 'myCam_visible':
                if (isHandsUpImgFlex.current) {
                    setHandsUpVideoDisplayMode('none');
                    setHandsUpImgDisplayMode('none');
                } else {
                    setHandsUpImgDisplayMode('none');
                    setHandsUpVideoDisplayMode('none');
                }

                handleControlToolFunc({ target: 'myCamViewVisibility', value: 'flex' });
                break;

            case 'myCam_invisible':
                if (isHandsUpImgFlex.current) {
                    setHandsUpVideoDisplayMode('none');
                    setHandsUpImgDisplayMode('flex');
                } else {
                    setHandsUpImgDisplayMode('none');
                    setHandsUpVideoDisplayMode('flex');
                }

                handleControlToolFunc({ target: 'myCamViewVisibility', value: 'none' });
                break;

            case 'camera_noLongerAvailable':
                handleSelectedLiveFunc({ selectedFunc: "MEDIA_ALERT", data: { kind: "camera", mediaDevice_info: value } });
                break;

            case 'myCam_noLongerAvailable':
                handleSelectedLiveFunc({ selectedFunc: "MEDIA_ALERT", data: { kind: "myCam", mediaDevice_info: value } });
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        initHandsUpFunc(imageProcess);
        initMyLiveInfo(performLiveInfo);

        if (performLiveInfo.liveSeq >= 0 && performLiveInfo.liveStatus === 'N') {
            initialize();
        }

        return () => {
            callExitFullScreen();

            if (interval.current != null) {
                clearInterval(interval.current)
                interval.current = null;
            }

            if (volumeInterval.current !== null) {
                clearInterval(volumeInterval.current);
                volumeInterval.current = null;
            }
        }
    }, []);

    /* useEffect(() => {
        if (!isLectureAlertModalOpened) {
            //callRequestFullScreen();
        }
    }, [isLectureAlertModalOpened]); */

    useEffect(() => {
        if (performLiveInfo.teacher_info) {
            setTeacherInfo({
                userSeq: performLiveInfo.teacher_info.userSeq,
                userNickname: performLiveInfo.teacher_info.userNickname,
                userGateWay: performLiveInfo.teacher_info.userGateWay
            });
        }
    }, [performLiveInfo.teacher_info]);

    useEffect(() => {
        liveMediaSoupHandler();
    }, [liveMediaSoupHandler]);

    useEffect(() => {
        liveHandsUpHandler();
    }, [liveHandsUpHandler]);

    useEffect(() => {
        liveHandsUpActiveHandler();
    }, [liveHandsUpActiveHandler]);

    useEffect(() => {
        liveTeacherViewHandler();
    }, [liveTeacherViewHandler]);

    useEffect(() => {
        liveMyCamViewHandler();
    }, [liveMyCamViewHandler]);

    useEffect(() => {
        if (performLiveStatusInfo.isConnect) {
            subscribe(videoEl.current, audioEl.current, micEl.current);
            handleControlToolFunc({ target: 'teacherViewVisibility', value: 'visible' });
        }
    }, [performLiveStatusInfo.isConnect]);

    useEffect(() => {
        if (performLiveInfo.handsUpYN === 'Y' && performHandsUpStatusInfo.connectStatus === 'connect') {
            handleControlToolFunc({ target: 'teacherViewVisibility', value: 'visible' });
        }
    }, [performLiveInfo.handsUpYN, performHandsUpStatusInfo.connectStatus]);

    useEffect(() => {
        if (isRetryConnect) {
            startHandsUpMode(performLiveInfo.liveSeq, teacherInfo.userSeq, userSeq, performLiveInfo.handsUpSelectYN, handsupVideoEl.current, handsupImgEl.current, myCamVideoEl.current, interval, volumeInterval/*, handsupAudioEl.current*/);
            handleControlToolFunc({ target: 'isRetryConnect' });
        }
    }, [isRetryConnect]);

    useEffect(() => {
        if (clickElementPoint === undefined || clickElementPoint === null) return;

        const pointInBound = (point, rect) => {
            if (rect.originX <= point.x && point.x <= (rect.originX + rect.width)
                && rect.originY <= point.y && point.y <= (rect.originY + rect.height)) {
                return true;
            }
            return false;
        }

        let teacherBound = { originX: 0, originY: 0, width: 0, height: 0 };
        if (teacherViewAreaRef.current) {
            teacherBound.originX = teacherViewAreaRef.current.offsetLeft;
            teacherBound.originY = teacherViewAreaRef.current.offsetTop;
            teacherBound.width = (teacherViewAreaRef.current.offsetLeft + teacherViewAreaRef.current.offsetWidth) - 1;
            teacherBound.height = (teacherViewAreaRef.current.offsetTop + teacherViewAreaRef.current.offsetHeight) - 1;
        }

        let handsUpBound = { originX: 0, originY: 0, width: 0, height: 0 };
        if (handsUpAreaRef.current) {
            handsUpBound.originX = handsUpAreaRef.current.offsetLeft;
            handsUpBound.originY = handsUpAreaRef.current.offsetTop;
            handsUpBound.width = (handsUpAreaRef.current.offsetLeft + handsUpAreaRef.current.offsetWidth) - 1;
            handsUpBound.height = (handsUpAreaRef.current.offsetTop + handsUpAreaRef.current.offsetHeight) - 1;
        }

        if (!isMyCamFullScreenMode && pointInBound(clickElementPoint, teacherBound)) {
            onHandleScreenMode('teacher');
        } else if (pointInBound(clickElementPoint, handsUpBound)) {
            onHandleScreenMode('myCam');
        }
    }, [clickElementPoint]);

    const onHandleScreenMode = (kind) => {
        if (!kind || kind === '') return;
        if (kind === 'teacher' && videoEl.current === undefined) return;
        if (kind === 'myCam' && myCamVideoEl.current === undefined) return;

        if (kind === 'teacher' && videoEl.current && videoEl.current.paused) return;
        if (kind === 'myCam' && myCamVideoEl.current && myCamVideoEl.current.paused) return;

        // 1. teacher - small / myCam - small
        // 2. teacher - full / myCam - small
        // 3. teacher - small / myCam - full
        // 4. teacher - full / myCam - small

        if (kind === 'teacher') {
            if (!isMyCamFullScreenMode) {
                handleControlToolFunc({ target: 'isFullScreenMode', value: !isFullScreenMode });
            } else {
                setIsMyCamFullScreenMode(!isMyCamFullScreenMode);
            }
        } else if (kind === 'myCam') {
            setIsMyCamFullScreenMode(!isMyCamFullScreenMode);
        }
    }

    return (
        <div className="live-area mediasoup">
            {
                performLiveInfo.handsUpYN === 'Y' &&
                <div ref={handsUpAreaRef} style={{ position: 'absolute', width: `${lHandsUpWidth}`, height: `${lHandsUpHeight}`, visibility: `${teacherViewVisibility}`, right: `${lHandsUpLeftOffset}`, margin: `${isMyCamFullScreenMode ? '0px' : '2px'}`, zIndex: `${isFullScreenMode ? 46 : 45/* 1 : 'unset' */}` }} onClick={() => onHandleScreenMode('myCam')}>
                    <video id="handsup_video_comp" ref={handsupVideoEl} className="mediasoup_comp handsup" style={{ display: `${lHandsUpVideoDisplayMode}` }} playsInline autoPlay muted />
                    <video id="handsup_myCam_comp" ref={myCamVideoEl} className={"mediasoup_comp handsup" + (isMyCamFullScreenMode ? " b-none" : "")} style={{ display: `${myCamViewVisibility}` }} playsInline autoPlay muted />
                    {
                        myCamViewVisibility === 'none' &&
                        <img id="handsup_img_comp" ref={handsupImgEl} className="mediasoup_comp" style={{ display: `${lHandsUpImgDisplayMode}` }} src="/images/connect.png" alt="" />
                    }
                    <div id="handsAudioConsumeDiv" style={{ display: 'none' }} />
                </div>
            }
            <div ref={teacherViewAreaRef} style={{ position: 'relative', width: `${lWidth}`, height: `${lHeight}`, margin: `${isFullScreenMode ? '0px' : '2px'}`, zIndex: `${isMyCamFullScreenMode ? 46 : 45/* 1 : 'unset' */}`/* , border: performLiveStatusInfo.isConnect ? '2px solid rgb(75, 145, 215)' : 'none' */ }} onClick={() => onHandleScreenMode('teacher')}>
                {
                    performLiveStatusInfo.isConnect ?
                        <VideoComp id="mediasoup_video_comp" ref={videoEl} className={"mediasoup_comp teacher" /* + (isFullScreenMode ? " b-none" : "") */} style={{ visibility: `${teacherViewVisibility}` }} playsInline autoPlay /> :
                        <ConnectImgComp id="mediasoup_img_comp" className="mediasoup_comp teacher" style={{ visibility: `${teacherViewVisibility}` }} src="/images/connect.png" alt="" />
                }
                {/* <div style={{ background: 'rgba(255, 255, 255, .4)' }}>
                    <span>선생님 화면</span>
                </div> */}
                <span className="mediasoup_comp teacher" style={{ visibility: `${teacherViewVisibility}` }}>진행자 화면</span>
                <audio id="audio_comp" ref={audioEl} style={{ display: 'none' }} autoPlay />
                <audio id="mic_comp" ref={micEl} style={{ display: 'none' }} />
            </div>
            {
                performLiveInfo.handsUpYN === 'Y' && smallGroupInfo.status &&
                <LiveSmallGroupWindow
                    userSeq={userSeq}
                    performLiveSeq={performLiveInfo.liveSeq}
                    smallGroupInfo={smallGroupInfo}
                    visibility={lHandsUpVideoDisplayMode}
                    isRbmqServerConnected={isRbmqServerConnected}
                    isReadyLiveConsume={isReadyLiveConsume}
                />
            }
        </div>
    );
}

export default LiveMediaSoupRcv;