import React, { useState, useEffect } from "react";
import { BsX } from "react-icons/bs";

import { ctActionType } from "../../../lib/ConstCommand";

const FileDirRemoveModal = ({ handleSelectedClassTalkFunc }) => {
    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "TOGGLE_FILE_DIR_REMOVE" })}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex text-center my-2 dark-grey-text small">
                공유 파일 폴더 삭제 시<br />
                삭제된 파일 및 폴더는 복구하실 수 없습니다. <br />
                공유 파일 폴더를 삭제하시겠습니까?
            </div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.FileClear })}>예</button>
                <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "TOGGLE_FILE_DIR_REMOVE" })}>아니오</button>
            </div>
        </div>
    );
}

export default FileDirRemoveModal;