import React from 'react';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';

const WebListArrowButton = ({ toggle_id, list_array, isOpened }) => {
    return (
        <button type="button" className="d-flex btn btn-sm round white m-2 p-1" id={toggle_id}
            disabled={list_array !== undefined && list_array !== null ? (list_array.length > 0 ? false : true) : true}>
            {
                list_array !== undefined && list_array !== null && list_array.length > 0 && isOpened ?
                    <FaCaretUp className="font-md hiclasstv-blue-text" aria-hidden="true" /> :
                    <FaCaretDown className="font-md hiclasstv-blue-text" aria-hidden="true" />
            }
        </button>
    )
}

export default WebListArrowButton;