import React, { useState, useEffect } from 'react';

import ConstData from '../../lib/ConstData';
import Alerts from '../etc/list/Alerts';

const WebAddLecture = ({ selectedSubDomainInfo, isAddLectureOK, list_lectureKind, list_lectureType, alertMessage, alertKind, handleSelectedFunc, onChangeIsAddLectureOK }) => {
    const [lectureName, setLectureName] = useState("");
    const [lectureCode, setLectureCode] = useState("");
    const [lectureCrdntCode, setLectureCrdntCode] = useState("");
    const [lectureStepNum, setLectureStepNum] = useState(0);

    const [lLectureKindInfo, setLectureKindInfo] = useState({ idx: 0, lectureKindSeq: -1, lectureKindName: "" });
    const [lLectureTypeInfo, setLectureTypeInfo] = useState({ idx: 0, lectureTypeSeq: -1, lectureTypeName: "" });

    const [lAlertMessage, setAlertMessage] = useState("");
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    useEffect(() => {
        if (list_lectureKind && list_lectureKind.length > 0) {
            setLectureKindInfo({ idx: 0, lectureKindSeq: list_lectureKind[0].lectureKindSeq, lectureKindName: list_lectureKind[0].lectureKindName });
        }
    }, [list_lectureKind]);

    useEffect(() => {
        if (list_lectureType && list_lectureType.length > 0) {
            setLectureTypeInfo({ idx: 0, lectureTypeSeq: list_lectureType[0].lectureTypeSeq, lectureTypeName: list_lectureType[0].lectureTypeName });
        }
    }, [list_lectureType]);

    useEffect(() => {
        if (isAddLectureOK) {
            setLectureName("");
            setLectureCode("");
            setLectureCrdntCode("");
            setLectureStepNum(0);
            onChangeIsAddLectureOK();

            if (list_lectureKind && list_lectureKind.length > 0) {
                setLectureKindInfo({ idx: 0, lectureKindSeq: list_lectureKind[0].lectureKindSeq, lectureKindName: list_lectureKind[0].lectureKindName });
            } else {
                setLectureKindInfo({ idx: 0, lectureKindSeq: -1, lectureKindName: "" });
            }

            if (list_lectureType && list_lectureType.length > 0) {
                setLectureTypeInfo({ idx: 0, lectureTypeSeq: list_lectureType[0].lectureTypeSeq, lectureTypeName: list_lectureType[0].lectureTypeName });
            } else {
                setLectureTypeInfo({ idx: 0, lectureTypeSeq: -1, lectureTypeName: "" });
            }
        }
    }, [isAddLectureOK]);

    const onChangeLectureName = (e) => {
        setLectureName(e.target.value);
    }

    const onChangeLectureCode = (e) => {
        setLectureCode(e.target.value);
    }

    const onChangeSelectLectureKind = (e) => {
        if (list_lectureKind && list_lectureKind.length > 0) {
            let idx = e.target.value;
            setLectureKindInfo({ idx, lectureKindSeq: list_lectureKind[idx].lectureKindSeq, lectureKindName: list_lectureKind[idx].lectureKindName });
        }
    }

    const onChangeLectureCrdntCode = (e) => {
        setLectureCrdntCode(e.target.value);
    }

    const onChangeSelectLectureType = (e) => {
        if (list_lectureType && list_lectureType.length > 0) {
            let idx = e.target.value;
            setLectureTypeInfo({ idx, lectureTypeSeq: list_lectureType[idx].lectureTypeSeq, lectureTypeName: list_lectureType[idx].lectureTypeName });
        }
    }

    const onChangeLectureStepNum = (e) => {
        setLectureStepNum(e.target.value);
    }
 
    const onClickAddLecture = (e) => {
        handleSelectedFunc({ 
            selectedFunc: 'CREATE_LECTURE', 
            data: { 
                lectureName, 
                lectureCode, 
                lectureCrdntCode, 
                lectureStepNum, 
                lectureKind: lLectureKindInfo.lectureKindSeq, 
                lectureType: lLectureTypeInfo.lectureTypeSeq, 
                subDomainSeq: selectedSubDomainInfo.subDomainSeq
            } 
        });
    }

    return (
        <div className="d-flex py-3 align-items-center justify-content-center border-bottom">
            <div className="card web-list-card border-primary">
                <div className="input-area">
                    <div className="input-label font-md">
                        <span><b>강의 명</b></span>
                    </div>
                    <div className="input-data">
                        <div className="d-flex input-group justify-content">
                            <input type="text" id="inputLectureName" value={lectureName} className="d-flex flex-fill web-search-input mr-2" placeholder="강의 명을 입력하세요" style={{width:'70%'}} onChange={onChangeLectureName} />
                        </div>
                    </div>
                </div>
                <div className="input-area">
                    <div className="input-label font-md">
                        <span><b>강의 코드</b></span>
                    </div>
                    <div className="input-data">
                        <div className="d-flex input-group justify-content">
                            <input type="text" id="inputLectureCode" value={lectureCode} className="d-flex flex-fill web-search-input mr-2" placeholder="강의 코드를 입력하세요" style={{width:'70%'}} onChange={onChangeLectureCode} />
                        </div>
                    </div>
                </div>
                <div className="input-area">
                    <div className="input-label font-md">
                        <span><b>강의 종류</b></span>
                    </div>
                    <div className="input-data">
                        <select className="custom-select small my-1" style={{ width: '100%', fontSize: '0.9rem' }} value={lLectureKindInfo.idx} onChange={onChangeSelectLectureKind}>
                            {
                                list_lectureKind && list_lectureKind.length > 0 ?
                                    list_lectureKind.map((info, index) => (
                                        <option value={index} key={"itemOption-" + index}>{info.lectureKindName}</option>
                                    )) :
                                    <option value={-1}>
                                        선택 가능한 로컬 사이트가 없습니다.
                                    </option>
                            }
                        </select>
                    </div>
                </div>
                <div className="input-area">
                    <div className="input-label font-md">
                        <span><b>어시스턴트 정보</b></span>
                    </div>
                    <div className="input-data">
                        <div className="d-flex input-group justify-content">
                            <input type="email" id="inputLectureCrdntCode" value={lectureCrdntCode} className="d-flex flex-fill web-search-input mr-2" placeholder="어시스턴트 정보를 입력하세요" style={{width:'70%'}} onChange={onChangeLectureCrdntCode} />
                        </div>
                    </div>
                </div>
                <div className="input-area">
                    <div className="input-label font-md">
                        <span><b>강의내용 유형</b></span>
                    </div>
                    <div className="input-data">
                        <select className="custom-select small my-1" style={{ width: '100%', fontSize: '0.9rem' }} value={lLectureTypeInfo.idx} onChange={onChangeSelectLectureType}>
                            {
                                list_lectureType && list_lectureType.length > 0 ?
                                    list_lectureType.map((info, index) => (
                                        <option value={index} key={"itemOption-" + index}>{info.lectureTypeName}</option>
                                    )) :
                                    <option value={-1}>
                                        선택 가능한 강의내용 유형이 없습니다.
                                    </option>
                            }
                        </select>
                    </div>
                </div>
                <div className="input-area">
                    <div className="input-label font-md">
                        <span><b>스텝순번</b></span>
                    </div>
                    <div className="input-data">
                        <div className="d-flex input-group justify-content">
                            <input type="number" id="inputLectureStepNum" value={lectureStepNum} className="d-flex flex-fill web-search-input mr-2" placeholder="스텝순번을 입력하세요" style={{width:'70%'}} onChange={onChangeLectureStepNum} />
                        </div>
                    </div>
                </div>
                <Alerts kind={lAlertKind} message={lAlertMessage} />
                <div className="d-flex text-center mt-3">
                    <button type="button" className="btn btn-md hiclasstv-blue white-text m-0 px-4 py-1" onClick={onClickAddLecture}>
                        <b>생성</b>
                    </button>
                </div>
            </div>
        </div>
    )
};
export default WebAddLecture;