import React from 'react';
import { BsX } from 'react-icons/bs';

import CustomProfileImage from '../../user/profile/CustomProfileImage';

const DeleteChatRoomMember = ({ deleteChatRoomMemberInfo, handleSelectedFunc }) => {

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" onClick={()=>handleSelectedFunc({selectedFunc:"NONE"})}>
                    <BsX />
                </span>
            </div>
            {
                deleteChatRoomMemberInfo.entryYN === "Y" ?
                    <div className="d-flex text-center my-2 dark-grey-text small">
                        해당 멤버는 현재 팀업에 참여 중입니다.<br />
                        삭제된 멤버는 팀업에 참여할 수 없습니다.<br />
                        삭제하시겠습니까?
                    </div> :
                    <div className="d-flex text-center my-2 dark-grey-text small">
                        삭제된 멤버는 팀업에 참여할 수 없습니다.<br />
                        삭제하시겠습니까?
                    </div>
            }
            <div className="content-area dark-grey-text my-0">
                <span className="small mb-1">[삭제할 멤버]</span>
                <div className="text-area text-center area-80">
                    <div className="area-15 fit-content">
                        <CustomProfileImage url={deleteChatRoomMemberInfo.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={deleteChatRoomMemberInfo.userNickname} />
                    </div>
                    <span className="hiclass-text" title={deleteChatRoomMemberInfo.userNickname}><b>{deleteChatRoomMemberInfo.userNickname}</b></span>
                </div>
            </div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc:"MEMBER_DELETE", chatRoomSeq:deleteChatRoomMemberInfo.chatRoomSeq, memberSeq:deleteChatRoomMemberInfo.userSeq })}>예</button>
                <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc:"NONE"})}>아니오</button>
            </div>
        </div>
    );
}

export default DeleteChatRoomMember;