import React, { Component } from "react";
import { connect } from "react-redux";

import { enWebPage } from "../../../lib/ConstCommand";
import { enTokenCMD } from "../../../lib/classTalk/CodeEnum";

import {
    unsubscribe
} from "../../../lib/MediaSoupClient";

import {
    sendGetSmartTVBindInfo,
    xmitCmd_AndroidMessageForSmartTV,
    xmitCmd_MessageForSmartTV
} from "../../../lib/RabbitmqClient";

import SmartTVAlertModal from "../../../components/live/smartTV/modal/SmartTVAlertModal";
import SmartTVAppAlertModal from "../../../components/live/smartTV/modal/SmartTVAppAlertModal";
// import SmartTVView from "../../../components/live/smartTV/SmartTVView";
import SmartTVRcvView from "../../../components/live/smartTV/SmartTVRcvView";
import SmartTVModal from "../../../components/live/smartTV/modal/SmartTVModal";

import {
    logoutBySmartTV
} from "../../../modules/user";

import {
    falseToRequestCloseModal,
    falseToSmartTVAlertModal
} from "../../../modules/live";

class SmartTVContainer extends Component {

    state = {
        isModalOpened       : true,
        isAlertModalOpened  : false,
    }

    componentDidMount() {
        const { userSeq } = this.props;
        if (userSeq === -1 || userSeq === null) {
            const { routeProps } = this.props;
            if (routeProps.match && routeProps.match.params) {
                const liveSeq = routeProps.match.params.liveSeq;
                if (liveSeq && liveSeq !== "" && parseInt(liveSeq) > 0) {
                    if (this.props.currentPageType === enWebPage.SmartTV) {
                        // this.props.routeProps.history.push(`/smart_view/login/${liveSeq}`);
                        this.props.routeProps.history.push(`/smarttv_login/${liveSeq}`);
                    } else {
                        // this.props.routeProps.history.push(`/group_smart_view/login/${liveSeq}`);
                        this.props.routeProps.history.push(`/group_smarttv_login/${liveSeq}`);
                    }
                } else {
                    if (this.props.currentPageType === enWebPage.SmartTV) {
                        // this.props.routeProps.history.push("/smart_view/login/-1");
                        this.props.routeProps.history.push("/smarttv_login/-1");
                    } else {
                        // this.props.routeProps.history.push("/group_smart_view/login/-1");
                        this.props.routeProps.history.push("/group_smarttv_login/-1");
                    }
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isRbmqServerConnected !== this.props.isRbmqServerConnected && this.props.isRbmqServerConnected) {
            if (this.props.currentPageType === enWebPage.SmartTV) {
                // console.log("case enWebPage.SmartTV:) call xmitCmd_AndroidMessageForSmartTV");
                // xmitCmd_AndroidMessageForSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.ct_Poll, this.props.userSeq, this.props.userNickname, this.props.live_info.liveSeq, this.props.smartTVTempKey);
            } else {
                // console.log("case enWebPage.GroupSmartTV:) call xmitCmd_AndroidMessageForSmartTV");
                // console.log(this.props.live_info);
                // let list_member = this.props.live_info.list_member;
                // console.log(list_member);
                /* for (let i = 0; i < list_member.length; i++) {
                    let user_info = list_member[i];
                    // let userSeq = list_member[i].userSeq;
                    xmitCmd_AndroidMessageForSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.ct_Poll, user_info.userSeq, user_info.userNickname, this.props.live_info.liveSeq, this.props.smartTVTempKey);
                } */
                // xmitCmd_AndroidMessageForGroupSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.ct_Poll, list_member, this.props.live_info.liveSeq, this.props.smartTVTempKey);
            }
        }

        if ((prevProps.isRequestCloseModal !== this.props.isRequestCloseModal) && this.props.isRequestCloseModal) {
            // console.log("call xmitCmd_AndroidMessageForSmartTV - 2");
            if (this.props.currentPageType === enWebPage.SmartTV) {
                // console.log("case enWebPage.SmartTV:) call xmitCmd_AndroidMessageForSmartTV - 2");
                // xmitCmd_AndroidMessageForSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.ct_Poll, this.props.userSeq, this.props.userNickname, this.props.live_info.liveSeq, this.props.smartTVTempKey);
            } else {
                // console.log("case enWebPage.GroupSmartTV:) call xmitCmd_AndroidMessageForSmartTV - 2");
                // console.log(this.props.live_info);
                // let list_member = this.props.live_info.list_member;
                // console.log(list_member);
                /* for (let i = 0; i < list_member.length; i++) {
                    let user_info = list_member[i];
                    // let userSeq = list_member[i].userSeq;
                    xmitCmd_AndroidMessageForSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.ct_Poll, user_info.userSeq, user_info.userNickname, this.props.live_info.liveSeq, this.props.smartTVTempKey);
                } */
                // xmitCmd_AndroidMessageForGroupSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.ct_Poll, list_member, this.props.live_info.liveSeq, this.props.smartTVTempKey);
            }
            this.props.falseToRequestCloseModal();
        }

        if (this.props.isRequestCloseModal) {
            this.props.falseToRequestCloseModal();
        }
    }

    componentWillUnmount() {
        /* if (this.props.currentPageType === enWebPage.SmartTV) {
            const { userSeq, userNickname, live_info, performLiveStatusInfo, smartTVTempKey } = this.props;

            if (live_info.liveSeq > -1) { // live에 입장한 case by hjkim 20230307
                if (live_info.liveStatus === "Y" && performLiveStatusInfo.connectStatus === "on") {
                    unsubscribe(false, true, true);
                }

                if (this.props.isConnectedOnScreenApp) {
                    xmitCmd_AndroidMessageForSmartTV("android", "ct_SmartTVLogout", enTokenCMD.ct_Logout, userSeq, userNickname, live_info.liveSeq, smartTVTempKey);
                }
            }

            this.props.logoutBySmartTV();
        } */

        const { live_info, list_isConnectedOnScreenApp, performLiveStatusInfo, smartTVTempKey } = this.props;
        if (live_info.liveSeq > -1) { // live에 입장한 case by hjkim 20231211
            if (live_info.liveStatus === "Y" && performLiveStatusInfo.connectStatus === "on") {
                unsubscribe(false, true, true);
            }

            if (list_isConnectedOnScreenApp && list_isConnectedOnScreenApp.length > 0) {
                for (let i = 0; i < list_isConnectedOnScreenApp.length; i++) {
                    let info = list_isConnectedOnScreenApp[i];
                    // xmitCmd_AndroidMessageForSmartTV("android", "ct_SmartTVLogout", enTokenCMD.ct_Logout, info.userSeq, info.userNickname, live_info.liveSeq, smartTVTempKey);
                    xmitCmd_MessageForSmartTV("smart_tv", "ct_SmartTVLogout", enTokenCMD.ct_Logout, info.userSeq, info.userNickname, live_info.liveSeq, false);

                    if (info.isConnectedOnScreenApp) {
                        xmitCmd_AndroidMessageForSmartTV("android", "ct_SmartTVLogout", enTokenCMD.ct_Logout, info.userSeq, info.userNickname, live_info.liveSeq, smartTVTempKey);
                    }
                }
            }
        }

        this.props.logoutBySmartTV();
    }

    handleSelectedFunc = ({ selectedFunc }) => {
        const { userSeq, userNickname, live_info } = this.props;

        switch (selectedFunc) {
            case "TRY_CONN_APP":
                if (this.props.isRbmqServerConnected) {
                    xmitCmd_AndroidMessageForSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.ct_Poll, userSeq, userNickname, live_info.liveSeq, this.props.smartTVTempKey);
                } else {
                    alert("인터넷 환경에 문제가 있어 연결을 시도할 수 없습니다. 관리자에게 문의하세요.");
                    // window.history.back();
                    window.location.reload();
                }
                break;

            case "TRY_DISCONN_APP":
                xmitCmd_AndroidMessageForSmartTV("android", "ct_SmartTVLogout", enTokenCMD.ct_Logout, userSeq, userNickname, live_info.liveSeq, this.props.smartTVTempKey);
                break;

            case "DISCONN_APP":
                //this.setState({ isAlertModalOpened: true });
                break;

            case "CONNECTED_APP":
                //this.setState({ isAlertModalOpened: false });
                break;

            case "TRY_REQ_CONN_INFO":
                sendGetSmartTVBindInfo();
                break;

            case "CLOSE_SMART_TV_ALERT_MODAL":
                this.props.falseToSmartTVAlertModal();
                break;

            case "CLOSE_SMART_TV_MODAL":
                this.setState({ ...this.state, isModalOpened: false });
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <div id="full-area">
                <SmartTVModal
                    isAlertModalOpened={this.state.isModalOpened}
                    handleSelectedFunc={this.handleSelectedFunc}
                />
                <SmartTVAlertModal
                    isAlertModalOpened={this.props.isSmartTVAlertModalOpened}
                    handleSelectedFunc={this.handleSelectedFunc}
                />
                <SmartTVAppAlertModal
                    isAlertModalOpened={this.state.isAlertModalOpened}
                    handleSelectedFunc={this.handleSelectedFunc}
                />
                {/* <SmartTVView
                    userNickname={this.props.userNickname}
                    teacherInfo={this.props.live_info.teacher_info}
                    performLiveInfo={this.props.live_info}
                    performLiveStatusInfo={this.props.performLiveStatusInfo}
                    smartTVBindInfo={this.props.smartTVBindInfo}
                    isConnectedOnScreenApp={this.props.isConnectedOnScreenApp}
                    handleSelectedFunc={this.handleSelectedFunc}
                /> */}
                {
                    /* this.props.currentPageType === enWebPage.SmartTV ?
                        <SmartTVView
                            currentPageType={this.props.currentPageType}
                            userSeq={this.props.userSeq}
                            userNickname={this.props.userNickname}
                            teacherInfo={this.props.live_info.teacher_info}
                            performLiveInfo={this.props.live_info}
                            performLiveStatusInfo={this.props.performLiveStatusInfo}
                            smartTVBindInfo={this.props.smartTVBindInfo}
                            isModalOpened={this.state.isModalOpened}
                            isRbmqServerConnected={this.props.isRbmqServerConnected}
                            isReadyLiveConsume={this.props.isReadyLiveConsume}
                            isConnectedOnScreenApp={this.props.isConnectedOnScreenApp}
                            handleSelectedFunc={this.handleSelectedFunc}
                        /> : */
                        <SmartTVRcvView
                            currentPageType={this.props.currentPageType}
                            userNickname={this.props.userNickname}
                            teacherInfo={this.props.live_info.teacher_info}
                            performLiveInfo={{
                                ...this.props.live_info,
                                smartTVTempKey: this.props.smartTVTempKey
                            }}
                            performLiveStatusInfo={this.props.performLiveStatusInfo}
                            smartTVBindInfo={this.props.smartTVBindInfo}
                            isModalOpened={this.state.isModalOpened}
                            isRbmqServerConnected={this.props.isRbmqServerConnected}
                            isReadyLiveConsume={this.props.isReadyLiveConsume}
                            isConnectedOnScreenApp={this.props.isConnectedOnScreenApp}
                            list_isConnectedOnScreenApp={this.props.list_isConnectedOnScreenApp}
                            handleSelectedFunc={this.handleSelectedFunc}
                        />
                }
            </div>
        );
    }
}

export default connect(
    ({ user, live, hiclasstv }) => ({
        userKind                    : user.userKind,
        userSeq                     : user.userSeq,
        userNickname                : user.userNickname,

        isLoginYn                   : user.isLoginYn,

        live_info                   : live.smartTVLiveInfo,
        performLiveStatusInfo       : live.performLiveStatusInfo,
        smartTVBindInfo             : live.smartTVBindInfo,
        isRequestCloseModal         : live.isRequestCloseModal,
        isSmartTVAlertModalOpened   : live.isSmartTVAlertModalOpened,
        isReadyLiveConsume          : live.isReadyLiveConsume,

        smartTVTempKey              : user.smartTVTempKey,
        isRbmqServerConnected       : user.isRbmqServerConnected,

        isConnectedOnScreenApp      : hiclasstv.isConnectedOnScreenApp,
        list_isConnectedOnScreenApp : hiclasstv.list_isConnectedOnScreenApp,
    }),
    {
        logoutBySmartTV,
        falseToRequestCloseModal,
        falseToSmartTVAlertModal
    }
)(SmartTVContainer);