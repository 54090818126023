import React, { Component } from "react";
import { connect } from "react-redux";

import { enWebPage, enBrowserType } from "../lib/ConstCommand";

import {
    getUserListByQRLogIn,
    initQrLiveInfo
} from "../modules/hiclasstv";

import {
    joinLive
} from "../modules/live";

import {
    logInByQRCode,
    loginBySmartTV,
    logInByGroupSmartTV,
    logOut,
    forceLogOut,
    toggle_forceLogoutAlertModal
} from "../modules/user";

import QRLogInWindow from "../components/etc/QRLogInWindow";
import GroupQRLogInWindow from "../components/etc/GroupQRLogInWindow";
import ForceLogoutAlertModal from "../components/user/modal/ForceLogoutAlertModal";
import MainContainer from "./MainContainer";
import WebContentTemplate from "../components/template/WebContentTemplate";
import MainAllListContainer from "./MainAllListContainer";

class QRLogInContainer extends Component {
    componentDidMount() {
        // console.log("QRLogInContainer(1) - currentPageType, isLoginYn => ", this.props.currentPageType, this.props.isLoginYn);
        // 클래스 입장 후 뒤로 가기했을 경우이므로 로그아웃 처리 ... by hjkim 20220824
        if (this.props.isLoginYn === "Y" && this.props.currentPageType === enWebPage.LiveQRLogin) {
            console.log("QRLogInContainer(1) - have to request logOut ... userSeq => ", this.props.userSeq);
            this.props.logOut({
                userSeq: this.props.userSeq,
                mode: "web",
                isClickLogout: true
            });
        } else {
            // 스마트TV 뒤로 가기했을 경우 데이터 중복 요청 방지용 ... by hjkim 20230403
            if (this.props.isLoginYn === "N") {
                if (this.props.routeProps.match && this.props.routeProps.match.params) {
                    // const liveSeq = parseInt(this.props.routeProps.match.params.liveSeq);
                    const liveSeq = this.props.routeProps.match.params.liveSeq;
                    if (liveSeq && liveSeq !== "" && parseInt(liveSeq) > 0) {
                        let mode = "web";

                        if (this.props.currentPageType === enWebPage.GroupSmartTV) {
                            mode = "web-group-smarttv";
                        }

                        if (this.props.currentPageType === enWebPage.SmartTV) {
                            mode = "web-smarttv";
                        }
                        console.log(`QRLogInContainer(1) - have to request getUserListByQRLogIn ... mode[${mode}], liveSeq => `, liveSeq);

                        this.props.getUserListByQRLogIn({
                            liveSeq: parseInt(liveSeq),
                            mode,
                        });
                    } else {
                        console.log("QRLogInContainer(1) - can not request getUserListByQRLogIn ... liveSeq => ", liveSeq);
                    }
                }
            }
        }
    }

    shouldComponentUpdate(nextProps) {
        return (JSON.stringify(nextProps) !== JSON.stringify(this.props));
    }

    componentDidUpdate(prevProps) {
        // this.handleLogMessage("currentPageType", prevProps.currentPageType, this.props.currentPageType);
        // this.handleLogMessage("isGotoWebLive", prevProps.isGotoWebLive, this.props.isGotoWebLive);
        // this.handleLogMessage("isRbmqServerConnected", prevProps.isRbmqServerConnected, this.props.isRbmqServerConnected);
        if (prevProps.isLoginYn !== this.props.isLoginYn && this.props.isLoginYn === "Y") {
            if (this.props.currentPageType === enWebPage.SmartTV || this.props.currentPageType === enWebPage.GroupSmartTV) {
                if (this.props.routeProps.match && this.props.routeProps.match.params) {
                    const liveSeq = this.props.routeProps.match.params.liveSeq;
                    if (liveSeq && liveSeq !== "" && parseInt(liveSeq) > 0) {
                        console.log("QRLogInContainer(2) - go to smart tv page ... liveSeq => ", liveSeq);
                        if (this.props.currentPageType === enWebPage.SmartTV) {
                            // this.props.routeProps.history.push(`/smart_view/${liveSeq}`);
                            this.props.routeProps.history.push(`/smarttv/${liveSeq}`);
                        } else {
                            // this.props.routeProps.history.push(`/group_smart_view/${liveSeq}`);
                            this.props.routeProps.history.push(`/group_smarttv/${liveSeq}`);
                        }
                    } else {
                        console.log("QRLogInContainer(2) - go to -1 smart tv page ... liveSeq => ", liveSeq);
                        if (this.props.currentPageType === enWebPage.SmartTV) {
                            // this.props.routeProps.history.push("/smart_view/-1");
                            this.props.routeProps.history.push("/smarttv/-1");
                        } else {
                            // this.props.routeProps.history.push("/group_smart_view/-1");
                            this.props.routeProps.history.push("/group_smarttv/-1");
                        }
                    }
                }
            } else {
                if (this.props.live_info && this.props.live_info.liveSeq > 0 && this.props.live_info.liveName && this.props.live_info.liveName !== "") {
                    console.log("QRLogInContainer(3) - have to request joinLive ... live_info => ", this.props.live_info);
                    this.props.joinLive({
                        liveSeq: this.props.live_info.liveSeq,
                        liveName: this.props.live_info.liveName,
                        userSeq: this.props.userSeq,
                        userNickname: this.props.userNickname,
                        profileImgUrl: this.props.profileImgUrl
                    });
                } else {
                    console.log("QRLogInContainer(3) - can not request joinLive ... live_info => ", this.props.live_info);
                    // 강제 로그아웃 후 로그인 시도하는 경우이므로 login main 페이지로 이동
                }
            }
        } else {
            // 클래스 입장 후 뒤로 가기했을 경우 로그아웃이 실행되기 때문에 didmount때 받아온 데이터가 모두 사라지므로 로그인 가능 목록 재호출.. by hjkim 20220824
            if (prevProps.isLoginYn !== this.props.isLoginYn && this.props.isLoginYn === "N") {
                // 여기서는 스마트TV, QR로그인 모두 같은 처리를 해야하기 때문에 따로 케이스 구분하지 않음
                if (this.props.routeProps.match && this.props.routeProps.match.params) {
                    const liveSeq = this.props.routeProps.match.params.liveSeq;
                    if (liveSeq && liveSeq !== "" && parseInt(liveSeq) > 0) {
                        let mode = "web";

                        if (this.props.currentPageType === enWebPage.GroupSmartTV) {
                            mode = "web-group-smarttv";
                        }

                        if (this.props.currentPageType === enWebPage.SmartTV) {
                            mode = "web-smarttv";
                        }
                        console.log(`QRLogInContainer(4) - have to request getUserListByQRLogIn ... mode[${mode}], liveSeq => `, liveSeq);

                        this.props.getUserListByQRLogIn({
                            liveSeq: parseInt(liveSeq),
                            mode,
                        });
                    } else {
                        console.log("QRLogInContainer(4) - can not request getUserListByQRLogIn ... liveSeq => ", liveSeq);
                    }
                }
            }
        }

        if (this.props.currentPageType !== enWebPage.SmartTV) {
            if (prevProps.isNeedRetryingLogin !== this.props.isNeedRetryingLogin && this.props.isNeedRetryingLogin) {
                const { login_info } = this.props;
                console.log("QRLogInContainer(5) - have to request logInByQRCode ... login_info => ", this.props.login_info);
                /* let timerProc = setTimeout(() => {
                    console.log("QRLogInContainer(timerProc) - logInByQRCode => ", login_info);
                    this.props.logInByQRCode({
                        loginInfo: {
                            userSeq: login_info.userSeq,
                            userNickname: login_info.nickname
                        },
                        userSeq: login_info.userSeq,
                        userKind: this.props.userKind,
                        mode: "web",
                        kind: "qr-login"
                    });
                }, 100); */
                this.props.logInByQRCode({
                    loginInfo: {
                        userSeq: login_info.userSeq,
                        userNickname: login_info.nickname
                    },
                    userSeq: login_info.userSeq,
                    userKind: this.props.userKind,
                    mode: "web",
                    kind: "qr-login"
                });
                this.props.initQrLiveInfo();
            }

            if (prevProps.isGotoWebLive !== this.props.isGotoWebLive && this.props.isGotoWebLive) {
                if (this.props.isRbmqServerConnected && this.props.performLiveSeq > -1) {
                    console.log("QRLogInContainer(6) - go to live page ... liveSeq => ", this.props.performLiveSeq);
                    this.props.routeProps.history.push(`/live/${this.props.performLiveSeq}`);
                }
            } else {
                if (prevProps.isRbmqServerConnected !== this.props.isRbmqServerConnected && this.props.isRbmqServerConnected) {
                    if (this.props.isGotoWebLive && this.props.performLiveSeq > -1) {
                        if (this.props.performLiveRobotLiveYN === "N") {
                            console.log("QRLogInContainer(7) - go to live page ... liveSeq => ", this.props.performLiveSeq);
                            this.props.routeProps.history.push(`/live/${this.props.performLiveSeq}`);
                        }
                    }
                }
            }
        }
    }

    handleSelectedFunc = ({ selectedFunc, loginInfo }) => {
        switch (selectedFunc) {
            case "LOGIN_BY_QR":
                console.log(loginInfo);
                this.logInByQR(loginInfo);
                break;

            case "FORCE_LOG_OUT":
                console.log("FORCE_LOG_OUT - qr");
                this.props.forceLogOut({
                    userSeq: this.props.login_info.userSeq,
                    mode: this.props.browserType === enBrowserType.Web ? "web" : "hiclass"
                });
                break;

            case "FORCE_LOGOUT_ALERT_TOGGLE":
                this.props.toggle_forceLogoutAlertModal();
                break;

            default:
                break;
        }
    }

    handleLogMessage = (propsName, prevProps, thisProps) => {
        console.log(`handleLogMessage() - ${propsName}:) prevProps[${prevProps}], thisProps[${thisProps}]`);
    }

    logInByQR = ({ userID, userSeq, userNickname, groupSeq, list_member, groupName }) => {
        if (this.props.currentPageType === enWebPage.SmartTV) {
            if (this.props.routeProps.match && this.props.routeProps.match.params) {
                const liveSeq = this.props.routeProps.match.params.liveSeq;
                this.props.loginBySmartTV({
                    userSeq,
                    liveSeq,
                    userKind: this.props.userKind,
                    list_member: [
                        { userSeq, userID, userNickname }
                    ],
                    mode: "web",
                    kind: "smart-tv-login", 
                });
            }
        } else {
            if (userSeq >= 0) {
                console.log("QRLogInContainer - logInByQR => ", userSeq);
                this.props.logInByQRCode({
                    loginInfo: {
                        userSeq,
                        userNickname
                    },
                    userID,
                    userSeq,
                    userKind: this.props.userKind,
                    mode: "web",
                    kind: "qr-login"
                });
            } else {
                console.log("QRLogInContainer - logInByQR => userSeq is -1 ");
                if (groupSeq > -1) {
                    const liveSeq = this.props.routeProps.match.params.liveSeq;
                    this.props.logInByGroupSmartTV({
                        isSuccessed: true,
                        isLoginSuccess: true,
                        loginInfo: {
                            userSeq,
                            userNickname
                        },
                        userID,
                        userSeq,
                        userKind: this.props.userKind,
                        liveSeq,
                        groupSeq,
                        list_member,
                        groupName,
                        live_info: this.props.live_info,
                        mode: "web",
                        kind: "group-smart-tv-login"
                    });
                }
            }
        }
    }

    render() {
        return (
            <>
                {
                    this.props.isLoginYn === "Y" && this.props.live_info.liveSeq === -1 ?
                        <MainContainer
                            browserType={this.props.browserType}
                            cookies={this.props.cookies}
                            currentPageType={this.props.currentPageType}
                            routeProps={this.props.routeProps}
                        >
                            <WebContentTemplate>
                                <MainAllListContainer
                                    browserType={this.props.browserType}
                                    cookies={this.props.cookies}
                                    currentPageType={this.props.currentPageType}
                                    routeProps={this.props.routeProps} />
                            </WebContentTemplate>
                        </MainContainer> :
                        <>
                            {
                                this.props.currentPageType === enWebPage.GroupSmartTV ?
                                    <GroupQRLogInWindow
                                        live_info={this.props.live_info}
                                        list_member={this.props.list_member}
                                        alertKind={this.props.alertKind}
                                        loginMessage={this.props.loginMessage}
                                        isInvalidRoute={this.props.isInvalidRoute}
                                        currentPageType={this.props.currentPageType}
                                        handleSelectedFunc={this.handleSelectedFunc}
                                    /> :
                                    <QRLogInWindow
                                        live_info={this.props.live_info}
                                        list_member={this.props.list_member}
                                        alertKind={this.props.alertKind}
                                        loginMessage={this.props.loginMessage}
                                        isInvalidRoute={this.props.isInvalidRoute}
                                        currentPageType={this.props.currentPageType}
                                        handleSelectedFunc={this.handleSelectedFunc}
                                    />
                            }
                            <ForceLogoutAlertModal
                                isModalOpened={this.props.isForceLogoutModalOpened}
                                handleSelectedFunc={this.handleSelectedFunc}
                            />
                        </>
                }
            </>
        );
    }
}

export default connect(
    ({ user, hiclasstv, live }) => ({
        isLoginYn                   : user.isLoginYn,

        userSeq                     : user.userSeq,
        userNickname                : user.userNickname,
        profileImgUrl               : user.profileImgUrl,
        userKind                    : user.userKind,

        login_info                  : user.login_info,

        alertKind                   : user.alertKind,
        loginMessage                : user.loginMessage,

        isRbmqServerConnected       : user.isRbmqServerConnected,

        isNeedRetryingLogin         : user.isNeedRetryingLogin,
        isForceLogoutModalOpened    : user.isForceLogoutModalOpened,

        isGotoWebLive               : live.isGotoWebLive,

        performLiveSeq              : live.performLiveInfo.liveSeq,
        performLiveRobotLiveYN      : live.performLiveInfo.robotLiveYN,

        live_info                   : hiclasstv.qrLiveInfo,
        list_member                 : hiclasstv.list_qrLoginMember,

        isInvalidRoute              : hiclasstv.isInvalidRoute,
    }),
    {
        getUserListByQRLogIn,
        logInByQRCode,
        loginBySmartTV,
        logInByGroupSmartTV,

        joinLive,
        logOut,

        forceLogOut,
        initQrLiveInfo,

        toggle_forceLogoutAlertModal
    }
)(QRLogInContainer);