import React from 'react';

const RightSideModal = ({modalClose, children}) => {
    return(
        <div className="hiclasstv-modal right-side" onClick={()=>modalClose({selectedFunc:"NONE"})}>
            {children}
        </div>
    ) 
}

export default RightSideModal;