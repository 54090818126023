/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

// import greenSmart01 from '../../image/info/green/greenSmart01.jpg'
// import greenSmart02 from '../../image/info/green/greenSmart02.jpg'
// import greenSmart03 from '../../image/info/green/greenSmart03.jpg'
// import greenSmart04 from '../../image/info/green/greenSmart04.jpg'

const WebHiClassInfoGreenSmartDevice = () => {
    return (
        <div className="d-flex flex-column w-100" style={{ margin: '10px 0px', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
            <div className="d-flex justify-content-center align-items-center w-100" style={{ margin: '2rem 0' }}>
                <div className="d-flex flex-column justify-content-center">
                    <div className="d-flex flex-column w-100">
                        <div className="d-flex flex-wrap" style={{ fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-blue-text">스마트&nbsp;</span>
                            <span className="hiclasstv-darkblue-text">칠판</span>
                        </div>
                        <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                            <div className="d-flex flex-wrap align-items-center">
                                <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold' }}>스마트시트&nbsp;</span>
                                <span className="d-flex grey-text" style={{ fontSize: '1.3rem', fontWeight: '400' }}>기존 칠판위에 쉽게 부착하여 전자칠판으로 변신</span>
                            </div>
                            <div className="d-flex flex-wrap mt-2 mb-5">
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/green/greenSmart01.jpg" alt="" />
                                </div>
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex flex-column" style={{ fontSize: '1rem' }}>
                                        <span className="my-1">ㆍ무 반사 화면 및 일반 화이트보드 기능 제공</span>
                                        <span className="my-1">ㆍ드라이-이레이즈 마커펜 사용</span>
                                        <span className="my-1">ㆍ동시 최대 7개의 전자펜 지원</span>
                                        <span className="my-1">ㆍ3No … No Power, No EMI, No Heat</span>
                                        <span className="my-1">ㆍAirfree 점착처리로 기존 칠판, 일반 벽지, 유리면 등에 쉽게 부착</span>
                                        <span className="my-1">ㆍ더러워지면 쉽게 재시공이 가능하여 전자 칠판을 소모품 화</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                            <div className="d-flex flex-wrap align-items-center">
                                <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold' }}>스마트자석시트&nbsp;</span>
                                <span className="d-flex grey-text" style={{ fontSize: '1.3rem', fontWeight: '400' }}>기존 칠판 위에 쉽게 탈부착하여 전자 칠판으로 변신</span>
                            </div>
                            <div className="d-flex flex-wrap mt-2 mb-5">
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/green/greenSmart02.jpg" alt="" />
                                </div>
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex flex-column" style={{ fontSize: '1rem' }}>
                                        <span className="my-1">ㆍ자석시트(Magnetic Sheet)와 스마트시트를 결합</span>
                                        <span className="my-1">ㆍ자석부착 기능이 있는 기존 녹색 칠판, 법랑 화이트보드 등에 쉽게 탈부착이 가능</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                            <div className="d-flex flex-wrap align-items-center">
                                <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold' }}>스마트필름&nbsp;</span>
                                <span className="d-flex grey-text" style={{ fontSize: '1.3rem', fontWeight: '400' }}>투명 필름과 전자펜을 이용하여 전자칠판 기능을 구현</span>
                            </div>
                            <div className="d-flex flex-wrap mb-5">
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/green/greenSmart03.jpg" alt="" />
                                </div>
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex flex-column" style={{ fontSize: '1rem' }}>
                                        <span className="my-1">ㆍ일반 TV/모니터에 결합하여 전자칠판 기능을 구현</span>
                                        <span className="my-1">ㆍ복잡한 센서와 전기, 컴퓨터에 연결하는 장치 등이 필요하지 않음</span>
                                        <span className="my-1">ㆍ모니터에서 전자칠판과 같은 터치 기능을 구현</span>
                                        <span className="my-1">ㆍ동시에 7개의 펜이 연결되어, 선생님과 학생이 동시에 판서하는 모둠 수업을 지원</span>
                                        <span className="my-1">ㆍTV/모니터용 전자칠판도 소모품 화</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column my-4 w-100">
                            <div className="d-flex flex-wrap align-items-center">
                                <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold' }}>페이퍼태블릿&nbsp;</span>
                                <span className="d-flex grey-text" style={{ fontSize: '1.3rem', fontWeight: '400' }}>전자종이 기반의 태블릿</span>
                            </div>
                            <div className="d-flex flex-wrap mb-2">
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/green/greenSmart04.jpg" alt="" />
                                </div>
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex flex-column" style={{ fontSize: '1rem' }}>
                                        <span className="my-1">ㆍ판서 S/W와 연계되어 칠판을 떠나서도  HiClass 기능을 수행</span>
                                        <span className="my-1">ㆍ잉크색, 잉크 두께, 잉크 모드 등 지원</span>
                                        <span className="my-1">ㆍ페이지 이동 지원</span>
                                        <span className="my-1">ㆍ화면에 판서기능 지원</span>
                                        <span className="my-1">ㆍPencam 과 연동되어 녹화, 마이크 및 웹캠 제어</span>
                                        <span className="my-1">ㆍHotkey 모드에서 PPT, 워드 등에 PageUp/PageDown 키보드 전송지원</span>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WebHiClassInfoGreenSmartDevice;