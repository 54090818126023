import React, { useState } from 'react';
import { FaTimes, FaSearch } from 'react-icons/fa';

import CustomProfileImage from '../../user/profile/CustomProfileImage';

const CreateChatRoom = ({ userInfo, list_friends, handleSelectedFunc }) => {
    
    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const userSelectArr = list_friends.map(
                            friend => ({
                                userSeq:friend.userSeq, 
                                userNickname:friend.userNickname, 
                                userProfile:friend.userProfile,
                                profileImgUrl:friend.profileImgUrl, 
                                isSelect:false
                            }));

    const [lUserSelectArr, setUserSelectArr] = useState(userSelectArr);

    const onClickSearch = (e) => {
        console.log("search click");
    }

    const getSelectedMemberCount = (selectArr) => {
        let count = 0;

        selectArr.forEach(element => {
            if(element.isSelect) {
                count++;
            }
        });

        return count;
    }

    const onChangeUnSelectValue = ({userSeq}) => {
        const arr = lUserSelectArr.map(
            info => {
                let value = info.userSeq == userSeq ? {
                    userSeq:info.userSeq,
                    userNickname:info.userNickname,
                    userProfile:info.userProfile,
                    profileImgUrl:info.profileImgUrl,
                    isSelect:false
                } : info;

                return value;
            }
        );
        setUserSelectArr(arr);
    }

    const onChangeSelectValue = (e) => {        
        const { id, checked } = e.target;

        if(lUserSelectArr && lUserSelectArr.length > 0) {
            const arr = lUserSelectArr.map(
                info => {
                    let value = info.userSeq == id ? {
                        userSeq:info.userSeq,
                        userNickname:info.userNickname,
                        userProfile:info.userProfile,
                        profileImgUrl:info.profileImgUrl,
                        isSelect:checked 
                    } : info;

                    return value;
                }
            ); 
            setUserSelectArr(arr);
        } else {
            const arr = userSelectArr.map(
                info => {
                    let value = info.userSeq == id ? {
                        userSeq:info.userSeq,
                        userNickname:info.userNickname,
                        userProfile:info.userProfile,
                        profileImgUrl:info.profileImgUrl,
                        isSelect:checked
                    } : info;
                    
                    return value;
                }
            );
            setUserSelectArr(arr);
        }
    }

    const createChatRoom = () => {
        let selectedArr = [];
        let chatRoomName;

        if (lUserSelectArr && lUserSelectArr.length > 0) {
            selectedArr = lUserSelectArr.filter(member => member.isSelect === true);

            if (selectedArr.length > 0) {
                const { userSeq, userNickname, userProfile, profileImgUrl } = userInfo;

                let userArr = [{ userSeq, userNickname, userProfile, profileImgUrl, userStatus: "LOG_IN" }];
                userArr = userArr.concat(selectedArr);

                let nameArr = userArr.map(user => user.userNickname).join(", ");
                chatRoomName = nameArr + " 님의 팀업";

                handleSelectedFunc({selectedFunc:"CREATE_OK", chatRoomName:chatRoomName, list_member:userArr});
            } else {
                handleSelectedFunc({ selectedFunc: "NONE" });
            }
        } else {
            handleSelectedFunc({ selectedFunc: "NONE" });
        }
    }

    const onChangeSearchValue = (e) => {
        console.log("change search value");
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area justify-content-between">
                <span className="hiclasstv-darkgrey-text button" title="취소" onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    취소
                </span>
                <div className="text-area px-2">
                    <span><b>팀업 상대 선택</b></span>
                    <span className="hiclasstv-darkgrey-text mx-1 font-weight-bold">{getSelectedMemberCount(lUserSelectArr)}</span>
                </div>
                <span className="hiclasstv-darkgrey-text button" title="생성" onClick={createChatRoom}>
                    확인
                </span>
            </div>
            {
                getSelectedMemberCount(lUserSelectArr) > 0 ?
                    <div className="list-group list-group-horizontal w-100 scroll-show scroll-hor-show" style={{minHeight:'max-content', overflow: 'auto' }}>
                        {
                            lUserSelectArr.map(
                                select => (
                                    select.isSelect ?
                                        <div className="list-group-hitem list-group-hitem-action flex-column align-items-center" key={select.userSeq}>
                                            <div className="d-flex justify-content-center">
                                                <div className="d-flex pt-2">
                                                    <CustomProfileImage url={select.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={select.userNickname} />
                                                </div>
                                                <div className="d-flex align-items-start ml-n3">
                                                    <button type="button" className="d-flex btn btn-sm white m-0 p-1" style={{ borderRadius: '1rem' }} title="취소" onClick={() => onChangeUnSelectValue({userSeq:select.userSeq})}>
                                                        <FaTimes className="font-sm" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="text-area pt-1">
                                                <span className="hiclass-text" title={select.userNickname}><b>{select.userNickname}</b></span>
                                            </div>
                                        </div> :
                                        <div key={select.userSeq} />
                                )
                            )
                        }
                    </div> :
                    <></>
            }
            <div className="input-area">
                <input type="text" className="search" placeholder="닉네임, 프로필 검색" onChange={onChangeSearchValue} />
                <div className="input-group-append ml-1" title="검색" onClick={onClickSearch}>
                    <span className="d-flex hiclasstv-btn b-none rounded p-1">
                        <FaSearch className="d-flex font-md black-text" />
                    </span>
                </div>
            </div>
            <div className="list-group" style={{overflow:'auto'}}>
                {
                    list_friends.map(
                        (friend, index) => (
                            <div className="d-flex list-group-hitem list-group-hitem-action py-2 px-1 justify-content-center align-items-center" key={friend.userSeq}>
                                <div className="d-flex area-15 fit-content">
                                    <CustomProfileImage url={friend.profileImgUrl} alt={friend.userNickname} />
                                </div>
                                <div className="text-area flex-column align-items-start">
                                    <span className="hiclass-text" title={friend.userNickname}><b>{friend.userNickname}</b></span>
                                    <span className="hiclass-text hiclasstv-deepDarkgrey-text small" title={friend.userProfile}>{friend.userProfile}</span>
                                </div>
                                <div className="d-flex area-15 ml-1">
                                    <div className="custom-control custom-checkbox pr-0 pl-2" style={{display:'inherit'}}>
                                        <input type="checkbox" className="custom-control-input" style={{position:'inherit'}} id={friend.userSeq} checked={lUserSelectArr[index].isSelect} onChange={onChangeSelectValue} />
                                        <label className="custom-control-label" htmlFor={friend.userSeq} />
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
            </div>
        </div>
    );
}
export default CreateChatRoom;