import React from "react";

import { enFindOption } from "../../../lib/ConstCommand";

import ChoiceFindInfo from "./ChoiceFindInfo";
import FindStep from "./FindStep";

const FindOption = ({ ...props }) => {
    switch (props.findIdPasswordOption) {
        case enFindOption.START:
            return <ChoiceFindInfo {...props} />;

        case enFindOption.ID:
        case enFindOption.PW:
            return <FindStep {...props} />;

        default:
            return <></>;
    }
}

export default FindOption;