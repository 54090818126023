import React from 'react';

const WebLectureInfo = ({ lecture_info, callMethod }) => {
    return (
        <tr className="d-flex w-100 px-2">
            <td className="d-flex align-items-center full">
                <span className="hiclass-text" title={"강의 명 [" + lecture_info.lectureName + "]"}>
                    <b>{lecture_info.lectureName}</b>
                </span>
            </td>
            <td className="d-flex align-items-center justify-content-end area-30 fit-content">
                <button type="button" className="btn btn-md m-0 py-1 px-2 green white-text" title="강의 페이지로 이동" onClick={() => callMethod({ lectureCode: lecture_info.lectureCode, lectureKind: lecture_info.lectureKind })}>
                    <b>공부하기</b>
                </button>
            </td>
        </tr>
    );
}

export default WebLectureInfo;