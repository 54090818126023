import React, { useState, useEffect } from 'react';
import { UncontrolledCollapse } from 'reactstrap';

import ConstData from '../../lib/ConstData';

import { FaChalkboardTeacher, FaMinus, FaPlusCircle, FaSearch } from 'react-icons/fa';

import { format } from 'date-fns';

import WebFindUser from '../admin/modal/WebFindUser';
import RemoveAlertModal from './modal/RemoveAlertModal';
import Alerts from '../etc/list/Alerts';

const WebAdminStudioInfo = ({ list_studio, list_display, list_configSubDomain, selectedSubDomainInfo, selectedUserInfo, searchResult, handleSelectedFunc }) => {
    
    const [isFindUserModalOpened, setIsFindUserModalOpened] = useState(false);
    
    const [lUserInfo, setUserInfo] = useState(selectedUserInfo);
    const [selectedSubDomainOption, setSelectedSubDomainOption] = useState(selectedSubDomainInfo);

    const [lSearchValue, setSearchValue] = useState("");

    const [isAddStudioOpened, setIsAddStudioOpened] = useState(false);
    const [isAddDisplayOpened, setisAddDisplayOpened] = useState(false);

    const [studioName, setStudioName] = useState("");
    const [groupLimit, setGroupLimit] = useState(4);

    const [isAssignDisplay, setIsAssignDisplay] = useState(false);

    const [selectedStudioOption, setSelectedStudioOption] = useState({idx: 0, studioSeq: -1 });

    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [lAlertMessage, setAlertMessage] = useState("");
    
    const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false);
    const [removeKind, setRemoveKind] = useState("");
    const [removeItem, setRemoveItem] = useState(null);
    
    useEffect(() => {
        if (list_configSubDomain === undefined || list_configSubDomain === null || list_configSubDomain.length === 0) {
            handleSelectedFunc({ selectedFunc: "GET_MANAGE_INFO"});
        }
        
        return () => {
            console.log('WebAdminStudioInfo() component invisible');
        }
    }, []);

    useEffect(() => {
        if (list_configSubDomain !== undefined && list_configSubDomain !== null && list_configSubDomain.length > 0) {
            setSelectedSubDomainOption({ idx: 0, subDomainSeq: list_configSubDomain[0].subDomainSeq, subDomainName: list_configSubDomain[0].subDomainName });
        }
    }, [list_configSubDomain]);

    useEffect(() => {
        if (selectedSubDomainInfo !== undefined && selectedSubDomainInfo !== null && selectedSubDomainInfo.subDomainSeq !== -1) {
            setSelectedSubDomainOption({ idx: selectedSubDomainInfo.selectedIdx, subDomainSeq: selectedSubDomainInfo.subDomainSeq, subDomainName: selectedSubDomainInfo.subDomainName });
        }
    }, [selectedSubDomainInfo]);

    useEffect(() => {
        if (selectedSubDomainOption !== undefined && selectedSubDomainOption !== null) {
            if (selectedSubDomainOption.subDomainSeq !== -1) {

                if (selectedSubDomainOption.subDomainSeq === 1) {
                    setUserInfo(selectedUserInfo);
                    handleSelectedFunc({ selectedFunc: "INIT_STUDIO_LIST"});
                } else {
                    setUserInfo({ userSeq: -1, userID: "", userNickname: "" });
                    handleSelectedFunc({ selectedFunc: "GET_STUDIO_LIST", kind: "b2b", subDomainSeq: selectedSubDomainOption.subDomainSeq, subDomainName: selectedSubDomainOption.subDomainName });
                }
            }
        }
    }, [selectedSubDomainOption]);

    useEffect(() => {
        setUserInfo(selectedUserInfo);
    }, [selectedUserInfo]);

    useEffect(() => {
        if (lUserInfo !== undefined && lUserInfo !== null && lUserInfo.userSeq > -1) {
            handleSelectedFunc({ selectedFunc: "GET_STUDIO_LIST", kind: "admin", subDomainSeq: selectedSubDomainOption.subDomainSeq, subDomainName: selectedSubDomainOption.subDomainName, selectedUserSeq: lUserInfo.userSeq });
        }
    }, [lUserInfo]);

    useEffect(() => {
        if (list_studio !== undefined && list_studio !== null && list_studio.length > 0) {
            if (selectedStudioOption.studioSeq === -1) {
                setSelectedStudioOption({idx:0, studioSeq:list_studio[0].studioSeq});
            }
        }        
    }, [list_studio]);

    const selectSubDomain = (e) => {
        if (list_configSubDomain !== undefined && list_configSubDomain !== null && list_configSubDomain.length > 0) {
            let idx = e.target.value;
            setSelectedSubDomainOption({ idx, subDomainSeq: list_configSubDomain[idx].subDomainSeq, subDomainName: list_configSubDomain[idx].subDomainName });
        }
    }
    
    const toggleAddStudio = (isOpened) => {
        console.log("toggle add studio");
        setIsAddStudioOpened(isOpened);
        setisAddDisplayOpened(false);
    }

    const toggleAddDisplay = (isOpened) => {
        console.log("toggle add display");
        setisAddDisplayOpened(isOpened);
        setIsAddStudioOpened(false);
    }

    // 유저 검색
    const onChangeSearchValue = (e) => {
        setSearchValue(e.target.value);
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            toggleFindUserModal(true);
        }
    }

    const toggleFindUserModal = (isModalOpened) => {
        setIsFindUserModalOpened(isModalOpened);
    }

    const handleSelectUser = ({ userSeq, userID, userNickname }) => {
        setSearchValue(userID);
        setUserInfo({ userSeq, userID, userNickname });
        toggleFindUserModal(false);
    }

    // 스튜디오 생성
    const onChangeStudioName = (e) => {
        setStudioName(e.target.value);
    }

    const onChangeGroupLimit = (e) => {
        setGroupLimit(e.target.value);
    }    

    const handleAddStudio = () => {
        if (studioName === "") {
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("스튜디오 이름을 입력하십시오.");
        } else {
            if (selectedSubDomainOption.subDomainSeq !== -1) {
                if (selectedSubDomainOption.subDomainSeq === 1) {
                    if (lUserInfo.userSeq !== -1) {
                        handleSelectedFunc({ selectedFunc: "CREATE_STUDIO", kind: "b2c", subDomainSeq: 1, selectedUserSeq: lUserInfo.userSeq, data:{studioName, groupLimit} });
                        setAlertKind(ConstData.ALERT_KIND.NONE);
                        setAlertMessage("");
                    }
                } else {
                    handleSelectedFunc({ selectedFunc: "CREATE_STUDIO", kind: "b2b", subDomainSeq: selectedSubDomainOption.subDomainSeq, data:{studioName, groupLimit} });
                    setAlertKind(ConstData.ALERT_KIND.NONE);
                    setAlertMessage("");
                }
            }
        }        
    }

    // 디스플레이 추가
    const onChangeAssignStudio = (e) => {
        console.log(e.target.value);

        if (e.target.value === "true") {
            setIsAssignDisplay(true);
        } else {
            setIsAssignDisplay(false);
        }
    }

    const selectStudio = (e) => {
        if (list_studio !== undefined && list_studio !== null && list_studio.length > 0) {
            let idx = e.target.value;
            setSelectedStudioOption({idx, studioSeq:list_studio[idx].studioSeq});
        }
    }

    const handleAddDisplay = () => {
        if (isAssignDisplay && selectedStudioOption.studioSeq !== -1) {
            if (selectedSubDomainOption.subDomainSeq !== -1) {
                if (selectedSubDomainOption.subDomainSeq === 1) {
                    if (lUserInfo.userSeq !== -1) {
                        handleSelectedFunc({selectedFunc:"ADD_DISPLAY", kind:"b2c", subDomainSeq:1, selectedUserSeq:lUserInfo.userSeq, data:{kind:"assign", studioSeq:selectedStudioOption.studioSeq}});
                    }
                } else {
                    handleSelectedFunc({selectedFunc:"ADD_DISPLAY", kind:"b2b", subDomainSeq:selectedSubDomainOption.subDomainSeq, data:{kind:"assign", studioSeq:selectedStudioOption.studioSeq}});
                }
            }
        } else {
            if (selectedSubDomainOption.subDomainSeq === 1) {
                if (lUserInfo.userSeq !== -1) {
                    handleSelectedFunc({selectedFunc:"ADD_DISPLAY", kind:"b2c", subDomainSeq:1, selectedUserSeq:lUserInfo.userSeq, data:{kind:"none"}});
                }
            } else {
                handleSelectedFunc({selectedFunc:"ADD_DISPLAY", kind:"b2b", subDomainSeq:selectedSubDomainOption.subDomainSeq, data:{kind:"none"}});
            }
        }
    }

    // remove
    const toggleRemoveAlertModalOpened = (flag) => {
        setIsRemoveModalOpened(flag);
    }

    const onHandleRemoveStudio = ({ studioSeq, studioName, subDomainSeq, studioOwner, date }) => {
        console.log(subDomainSeq, studioOwner);
        setRemoveKind("studio");
        setRemoveItem({ studioSeq, studioName, studioOwner: subDomainSeq === 1 ? studioOwner : subDomainSeq, date });
        toggleRemoveAlertModalOpened(true);
    }

    const onHandleRemoveDisplayFromStudio = ({ studioSeq, studioName, displaySeq, date }) => {
        setRemoveKind("studio-display");
        setRemoveItem({ studioSeq, studioName, displaySeq, date });
        toggleRemoveAlertModalOpened(true);
    }

    const onHandleRemoveDisplay = ({ displaySeq, date }) => {
        setRemoveKind("display");
        setRemoveItem({ displaySeq, date });
        toggleRemoveAlertModalOpened(true);
    }

    const handleRemoveItem  = ({kind, info}) => {
        console.log("remove item [", kind, "] => ", info);
        toggleRemoveAlertModalOpened(false);
        handleSelectedFunc({ selectedFunc: "REMOVE", kind, data: info });
    }

    return (
        <div className="web-main list-area" style={{ maxWidth: '900px' }}>
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaChalkboardTeacher className="font-lg mr-2" aria-hidden="true" />랜선스튜디오
                        </span>
                        <button style={{display:'none', visibility:'hidden'}} id="addStudio">addStudio</button>
                        <button style={{display:'none', visibility:'hidden'}} id="addDisplay">addDisplay</button>
                        <button type="button" className="d-flex btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" onClick={() => toggleAddStudio(!isAddStudioOpened)}>
                            <FaPlusCircle className="font-md mr-1" />스튜디오 생성
                        </button>
                        <button type="button" className="d-flex btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" onClick={() => toggleAddDisplay(!isAddDisplayOpened)}>
                            <FaPlusCircle className="font-md mr-1" />디스플레이 추가
                        </button>
                    </div>
                </div>
                <div className="px-2">
                    <div className="web-search-box">
                        <div className="web-search-option-area white">
                            <div className="web-search-option">
                                <div className="web-search-select-label">
                                    <span><b>서브 도메인</b></span>
                                </div>
                                <div className="web-search-select-box">
                                    <select className="custom-select small my-1" style={{ width: '100%', fontSize: '0.9rem' }} value={selectedSubDomainOption.idx} onChange={selectSubDomain} >
                                        {
                                            list_configSubDomain !== undefined && list_configSubDomain !== null && list_configSubDomain.length > 0 ?
                                                list_configSubDomain.map(
                                                    (info, index) => (
                                                        <option value={index} key={"item-" + index}>{info.subDomainName}</option>
                                                    )
                                                ) : 
                                                <option value={-1}>
                                                    관리 가능한 서비스가 없습니다.
                                                </option>
                                                
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="web-search-option">
                                <div className="web-search-select-label">
                                    <span><b>사용자 ID</b></span>
                                </div>
                                <div className="web-search-select-box">
                                    <div className="d-flex input-group justify-content-center m-0">
                                        <input 
                                            type="text" 
                                            value={lSearchValue}
                                            className="d-flex flex-fill web-search-input my-1 mr-1 px-2 py-1" 
                                            style={{width:'70%'}}
                                            placeholder={lUserInfo.userID}
                                            onChange={onChangeSearchValue}
                                            onKeyPress={handleKeyPress}
                                            disabled={selectedSubDomainOption.subDomainSeq !== 1}
                                        />
                                        <div className="d-flex align-items-center input-group-append">
                                            <button type="button" className="d-flex hiclasstv-btn b-none rounded px-1 black-text" disabled={selectedSubDomainOption.subDomainSeq !== 1} onClick={() => toggleFindUserModal(!isFindUserModalOpened)}>
                                                <FaSearch className="d-flex font-md m-1" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <UncontrolledCollapse toggler="#addStudio" isOpen={isAddStudioOpened ? true : false}>
                    <div className="d-flex py-3 align-items-center justify-content-center border-bottom">
                        <div className="card web-list-card border-primary">
                            <div className="input-area mt-0">
                                <div className="input-label">
                                    <span><b>스튜디오 이름</b></span>
                                </div>
                                <div className="input-data">
                                    <div className="d-flex input-group justify-content-center">
                                        <input type="text" id="inputStudioName" value={studioName} className="d-flex flex-fill web-search-input" placeholder="스튜디오 이름을 입력하세요" onChange={onChangeStudioName} />
                                    </div>
                                </div>
                            </div>
                            <div className="input-area">
                                <div className="input-label">
                                    <span><b>그룹 연결 인원</b></span>
                                </div>
                                <div className="input-data">
                                    <div className="d-flex pr-2 align-items-center">
                                        <input className="d-flex mr-1 p-0" type="radio" name="groupOfStudioLimit" id="limit_4" value={4} defaultChecked={true} onChange={onChangeGroupLimit} />
                                        <span>4 명</span>
                                    </div>
                                    <div className="d-flex pl-2 align-items-center">
                                        <input className="d-flex mr-1 p-0" type="radio" name="groupOfStudioLimit" id="limit_9" value={9} onChange={onChangeGroupLimit} />
                                        <span>9 명</span>
                                    </div>
                                </div>
                            </div>
                            <Alerts kind={lAlertKind} message={lAlertMessage} />
                            {/* 위에 거에 스타일 적용해야할지 확인해보기 red lighten-5 red-text, green lighten-4 green-text */}
                            <div className="d-flex text-center mt-3">
                                <button type="button" className="btn btn-md hiclasstv-blue white-text m-0 px-4 py-1" onClick={() => handleAddStudio()} disabled={selectedSubDomainOption.subDomainSeq === -1 || lUserInfo.userSeq === -1 || studioName === "" ? true : false}>
                                    <b>등록</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </UncontrolledCollapse>
                <UncontrolledCollapse toggler="#addDisplay" isOpen={isAddDisplayOpened ? true : false}>
                    <div className="d-flex py-3 align-items-center justify-content-center border-bottom">
                        <div className="card web-list-card border-primary">
                            <div className="input-area" style={{alignItems:'unset'}}>
                                <div className="input-label">
                                    <span><b>스튜디오 연결</b></span>
                                </div>
                                <div className="input-data flex-column">
                                    <div className="d-flex">
                                        <div className="d-flex pr-2 align-items-center">
                                            <input className="d-flex mr-1 p-0" type="radio" name="assignDisplay" id="assign" value={true} onChange={onChangeAssignStudio} />
                                            <span>연결</span>
                                        </div>
                                        <div className="d-flex pl-2 align-items-center">
                                            <input className="d-flex mr-1 p-0" type="radio" name="assignDisplay" id="notAssign" value={false} defaultChecked={true} onChange={onChangeAssignStudio} />
                                            <span>연결 안 함</span>
                                        </div>
                                    </div>
                                    {
                                        isAssignDisplay &&
                                        <div className="web-search-select-box w-100">
                                            <select className="custom-select small my-1" style={{ width: '100%', fontSize: '0.9rem' }} value={selectedStudioOption.idx} onChange={selectStudio} >
                                                {
                                                    list_studio !== undefined && list_studio !== null && list_studio.length > 0 ?
                                                        list_studio.map(
                                                            (info, index) => (
                                                                <option value={index} key={"item-" + index}>{info.studioName}</option>
                                                            )
                                                        ) :
                                                        <option value={-1}>
                                                            선택 가능한 스튜디오가 없습니다.
                                                        </option>

                                                }
                                            </select>
                                        </div>
                                    }
                                </div>
                            </div>
                            <Alerts kind={lAlertKind} message={lAlertMessage} />
                            {/* 위에 거에 스타일 적용해야할지 확인해보기 red lighten-5 red-text, green lighten-4 green-text */}
                            <div className="d-flex text-center mt-3">
                                <button type="button" className="btn btn-md hiclasstv-blue white-text m-0 px-4 py-1" onClick={() => handleAddDisplay()} disabled={selectedSubDomainOption.subDomainSeq === -1 || lUserInfo.userSeq === -1 ? true : false}>
                                    <b>등록</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </UncontrolledCollapse>
                <div className="px-2">
                    <div className="d-flex flex-column w-100 mt-2">
                        {
                            list_studio !== undefined && list_studio !== null && list_studio.length > 0 ?
                                <table className="d-flex flex-column table table-sm flex-list mb-0 b-top">
                                    <thead>
                                        <tr className="d-flex">
                                            <th className="area-15">
                                                <b>No.</b>
                                            </th>
                                            <th className="area-45">
                                                <div className="only-web text-center w-100">
                                                    <span className="area-70"><b>스튜디오 명</b></span>
                                                    <span className="area-30"><b>생성일</b></span>
                                                </div>
                                                <div className="only-mobile w-100">
                                                    <b>스튜디오 정보</b>
                                                </div>
                                            </th>
                                            <th className="area-25">
                                                <b>디스플레이</b>
                                            </th>
                                            <th className="area-15"></th>
                                        </tr>
                                    </thead>
                                    {
                                        list_studio.map(
                                            (studio, sIdx) => (
                                                <tbody key={"studio-" + studio.studioSeq}>
                                                    <tr className="d-flex">
                                                        <td className="d-flex align-items-center justify-content-center area-15">
                                                            <span className="font-sm">{sIdx + 1}</span>
                                                        </td>
                                                        <td className="landscape-to-portrait area-45">
                                                            <div className="list-row area-70">
                                                                <div className="text-area">
                                                                    <span className="font-sm info-title hiclass-text" title={studio.studioName}>{studio.studioName}</span>
                                                                </div>                                                                            
                                                            </div>
                                                            <div className="list-row area-30">
                                                                <span className="only-mobile">생성일 :&nbsp;</span>
                                                                <span className="font-sm">{studio.createDT}</span>
                                                            </div>
                                                        </td>
                                                        <td className="landscape-to-portrait align-items-center justify-content-center area-25">
                                                            <span className="font-sm">{studio.list_display.length + " Display"}</span>
                                                            <button className="btn btn-sm btn-outline-primary px-2 py-1 m-0 small ml-2" type="button" id={"show_" + studio.studioSeq + "_display"} disabled={studio.list_display.length > 0 ? false : true}>
                                                                조회
                                                            </button>
                                                        </td>
                                                        <td className="landscape-to-portrait align-items-center justify-content-center area-15">
                                                            <button type="button" className="btn btn-sm m-0 py-1 px-2 red white-text" title="삭제" onClick={() => onHandleRemoveStudio({studioSeq:studio.studioSeq, studioName:studio.studioName, subDomainSeq: studio.subDomainSeq, studioOwner: studio.studioOwner, date:studio.createDT})}>
                                                                <b>삭제</b>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="d-flex justify-content-center px-1">
                                                        <td className="d-flex p-0 area-90">
                                                            <UncontrolledCollapse toggler={"#show_" + studio.studioSeq + "_display"} defaultOpen={false} style={{ width: '100%' }}>
                                                                <table className="d-flex flex-column table-sm sub-list table-hover mb-0">
                                                                    <thead>
                                                                        <tr className="d-flex">
                                                                            <th className="area-15">
                                                                                <span className="font-sm"><b>No.</b></span>
                                                                            </th>
                                                                            <th className="area-70">
                                                                                <div className="only-web w-100">
                                                                                    <span className="font-sm text-left area-35"><b>구분</b></span>
                                                                                    <span className="font-sm text-left area-65"><b>생성일</b></span>
                                                                                </div>
                                                                                <div className="only-mobile font-sm w-100">
                                                                                    <b>디스플레이 정보</b>
                                                                                </div>
                                                                            </th>
                                                                            <th className="area-15"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            studio.list_display !== undefined && studio.list_display !== null && studio.list_display.length > 0 ?
                                                                                studio.list_display.map(
                                                                                    (display, idx) => (
                                                                                        <tr className="d-flex" key={"studio-" + studio.studioSeq + "-display-" + display.displaySeq}>
                                                                                            <td className="landscape-to-portrait align-items-center justify-content-center area-15">
                                                                                                <span className="font-sm">{idx + 1}</span>
                                                                                            </td>
                                                                                            <td className="landscape-to-portrait area-70">
                                                                                                <div className="list-row justify-content-start area-35">
                                                                                                    <div className="text-area">
                                                                                                        <span className="font-sm hiclass-text" title={'Display No.' + display.displaySeq}>
                                                                                                            {'Display No.' + display.displaySeq}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="list-row justify-content-start area-65">
                                                                                                    <span className="only-mobile">생성일 :&nbsp;</span>
                                                                                                    <span className="font-sm">{display.pchsDT}</span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="landscape-to-portrait align-items-center justify-content-center area-15">
                                                                                                <button type="button" className="btn btn-sm m-0 py-0 px-2 red white-text" title="등록 해제" onClick={() => onHandleRemoveDisplayFromStudio({studioSeq:studio.studioSeq, studioName:studio.studioName, displaySeq:display.displaySeq, date:display.pchsDT})}>
                                                                                                    <FaMinus />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                ) :
                                                                                <tr className="d-flex w-100">
                                                                                    <td className="d-flex w-100 p-0">
                                                                                        <div className="d-flex view w-100 m-3 justify-content-center grey-text" style={{ fontSize: '0.9rem' }}>
                                                                                            디스플레이 목록이 없습니다.
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                        }
                                                                    </tbody> 
                                                                </table> 
                                                            </UncontrolledCollapse>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )
                                        )
                                    }
                                </table> :
                                <div className="d-flex view m-3 justify-content-center grey-text" style={{fontSize:'1rem'}}>
                                    스튜디오 목록이 없습니다.
                                </div>
                    }
                    </div>                    
                </div>
                <div className="px-2">
                    <div className="d-flex flex-column mt-2 pt-2 w-100" style={{borderTop:'2px solid #dee2e6'}}>
                        {
                            list_display !== undefined && list_display !== null && list_display.length > 0 ?
                                <>
                                    <span className="py-2 info-title">
                                        ㆍ미등록 디스플레이 (<b>{list_display.length}</b>)
                                    </span>
                                    {
                                        list_display.length > 0 ?
                                            <table className="d-flex flex-column table table-sm sub-list table-hover b-top">
                                                <thead>
                                                    <tr className="d-flex">
                                                        <th className="area-15">
                                                            <span className="font-sm"><b>No.</b></span>
                                                        </th>
                                                        <th className="area-70">
                                                            <div className="only-web w-100">
                                                                <span className="font-sm text-left area-35"><b>구분</b></span>
                                                                <span className="font-sm text-left area-65"><b>생성일</b></span>
                                                            </div>
                                                            <div className="only-mobile font-sm w-100">
                                                                <b>디스플레이 정보</b>
                                                            </div>
                                                        </th>
                                                        <th className="area-15"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        list_display.map((display, idx) => (
                                                            <tr className="d-flex" key={"display_" + display.displaySeq}>
                                                                <td className="landscape-to-portrait align-items-center justify-content-center area-15">
                                                                    <span className="font-sm">{idx + 1}</span>
                                                                </td>
                                                                <td className="landscape-to-portrait area-70">
                                                                    <div className="list-row justify-content-start area-35">
                                                                        <div className="text-area">
                                                                            <span className="font-sm hiclass-text" title={'Display No.' + display.displaySeq}>
                                                                                {'Display No.' + display.displaySeq}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="list-row justify-content-start area-65">
                                                                        <span className="only-mobile">생성일 :&nbsp;</span>
                                                                        <span className="font-sm">{display.pchsDT}</span>
                                                                    </div>
                                                                </td>
                                                                <td className="landscape-to-portrait align-items-center justify-content-center area-15">
                                                                    <button type="button" className="btn btn-sm m-0 py-1 px-2 red white-text" title="영구 삭제" onClick={() => onHandleRemoveDisplay({ displaySeq: display.displaySeq, date: display.pchsDT })}>
                                                                        <b>삭제</b>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table> :
                                            <div className="d-flex view m-3 justify-content-center grey-text py-2" style={{ fontSize: '1rem' }}>
                                                미등록 디스플레이 목록이 없습니다.
                                            </div>
                                    }
                                </> :
                                <div className="d-flex view m-3 justify-content-center grey-text py-2" style={{fontSize:'1rem'}}>
                                    미등록 디스플레이 목록이 없습니다.
                                </div>
                        }                        
                    </div>
                </div>
            </div>
            <WebFindUser
                isModalOpened={isFindUserModalOpened}
                toggleModalOpened={toggleFindUserModal}
                subDomainInfo={selectedSubDomainOption}
                searchValue={lSearchValue}
                searchResult={searchResult}
                handleSelectUser={handleSelectUser}
                handleSelectedFunc={handleSelectedFunc}
            />
            <RemoveAlertModal
                isAlertModalOpened={isRemoveModalOpened}
                kind={removeKind}
                info={removeItem}
                handleRemoveItem ={handleRemoveItem}
                toggleModalOpened={toggleRemoveAlertModalOpened}
            />
        </div>
    )
}

export default WebAdminStudioInfo;