import React from 'react';

const RightSideModalInLive = ({modalClose, children}) => {
    return(
        <div className="hiclasstv-modal right-side live" onClick={()=>modalClose({selectedFunc:"NONE"})}>
            {children}
        </div>
    ) 
}

export default RightSideModalInLive;