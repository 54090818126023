/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

// import title from '../../image/info/main/title.png';
// import main01 from '../../image/info/main/main01.png';
// import main02 from '../../image/info/main/main02.png';
// import advantage01 from '../../image/info/main/main_advantage1.png';
// import advantage02 from '../../image/info/main/main_advantage2.png';

const HiClassInfo = () => {
    return (
        <div className="d-flex flex-column h-100 overflow-auto" style={{ margin: "0px 1px 0px 0px", padding: "0px 20px 20px 20px", backgroundColor: "white" }}>
            <div className="card border-primary mb-3 overflow-auto">
                <div className="card-header">
                    <b>HiClassTV Info</b>
                </div>
                <div className="card-body overflow-auto text-primary text-center" style={{ width: '100%', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
                    <img src="/images/info/main/title.png" className="w-100 mb-2" alt="" />
                    <img src="/images/info/main/main01.png" className="w-100 my-2 mb-3" alt="" />
                    <span className="grey-text" style={{ fontSize: '0.9rem', fontWeight: '400' }}>
                        사람과 AI가 함께하는 세상, <br />기술로 모든 사람이 좀 더 편하게 소통 할 수 있는 세상을 만들어 갑니다.
                    </span>
                    <img src="/images/info/main/main02.png" className="w-100 my-2 mt-3" alt="" />
                    <img src="/images/info/main/main_advantage1.png" className="w-100 mt-2" alt="" />
                    <img src="/images/info/main/main_advantage2.png" className="w-100 mb-2" alt="" />
                </div>
            </div>
        </div>
    );
}

export default HiClassInfo;