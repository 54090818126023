/** New Rabbitmq */
export const getDefaultDirectKey = function (userSeq, mode) {
    return 'C.0.' + userSeq + '.' + mode;
};

export const getOtherDirectKey = function (userSeq, mode, liveSeq) {
    return 'C.0.' + userSeq + '.' + mode + '.' + liveSeq;
};

export const getHandsUpDirectKey = function (teacherSeq, liveSeq, userSeq, rcvUserSeq) {
    return 'L.' + teacherSeq + '.' + liveSeq + '.' + userSeq + '.' + rcvUserSeq;
};

export const getHandsUpBindKey = function (teacherSeq, userSeq) {
    return ['L.' + teacherSeq + '.*.*', 'L.' + teacherSeq + '.*.*.' + userSeq];
};

export const getHandsUpSendKey = function (teacherSeq, liveSeq, userSeq) {
    return 'L.' + teacherSeq + '.' + liveSeq + '.' + userSeq;
};

export const getTeamUpBindKey = function (roomSeq, userSeq) {
    return ['C.' + roomSeq + '.*', 'C.' + roomSeq + '.*.' + userSeq];
};

export const getTeamUpSendKey = function (roomSeq, userSeq) {
    return 'C.' + roomSeq + '.' + userSeq;
};

export const getLocalServerKey = (localServerSeq) => {
    return 'S.' + localServerSeq;
};