import { combineReducers } from "redux";
import user from "./user";
import friend from "./friend";
import loading from "./loading";
import hiclasstv from "./hiclasstv";
import chat from "./chat";
import live from "./live";
import lecture from "./lecture";
import quiz from "./quiz";
import classTalk from "./classTalk";
import classTalkLive from "./classTalkLive";
import promotion from "./promotion";
import pass from "./pass";
import admin from "./admin";
import studio from "./studio";
import watson from "./watson";
import service from "./service";
import whisper from "./whisper";
// import robot from "./robot";

const rootReducer = combineReducers({
    user,
    friend,
    chat,
    classTalk,
    loading,
    hiclasstv,
    live,
    classTalkLive,
    lecture,
    quiz,
    promotion,
    pass,
    admin,
    studio,
    watson,
    service,
    whisper
});

export default rootReducer;