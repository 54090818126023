import React from 'react';
import { FaCircle } from 'react-icons/fa';

import CustomProfileImage from '../user/profile/CustomProfileImage';
import ConstData from '../../lib/ConstData';

const MyFriendList = ({ userInfo, list_friends, handleSelectedFriendFunc }) => {
    return(
        <div className="list-group" style={{minHeight:'50%'}}>
            <div className="d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" onClick={() => handleSelectedFriendFunc({ selectedFunc: "ME" })}>
                <div className="d-flex area-15 fit-content">
                    <CustomProfileImage url={userInfo.profileImgUrl} alt={userInfo.userNickname} />
                </div>
                <div className="text-area flex-column align-items-start">
                    <span className="hiclass-text" title={userInfo.userNickname}><b>{userInfo.userNickname}</b></span>
                    <span className="hiclass-text hiclasstv-deepDarkgrey-text small" title={userInfo.userProfile}>{userInfo.userProfile}</span>
                </div>
            </div>
            {/*
            <div className="title-area sub hiclasstv-blue white-text">
                <b>친구 ({list_friends.length})</b>
            </div>
            <div className="list-group scroll-show-active light" style={{ height: '100%', overflow: 'auto' }}>
                {
                    list_friends.length > 0 ?
                        list_friends.map(
                            friend => (
                                <div className="d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" key={friend.userSeq} onClick={() => handleSelectedFriendFunc({ selectedFunc: "FRIEND", friendSeq: friend.userSeq })}>
                                    <div className="d-flex area-15 fit-content">
                                        <CustomProfileImage url={friend.profileImgUrl} alt={friend.userNickname} />
                                    </div>
                                    <div className="text-area flex-column align-items-start">
                                        <span className="hiclass-text" title={friend.userNickname}><b>{friend.userNickname}</b></span>
                                        <span className="hiclass-text hiclasstv-deepDarkgrey-text small" title={friend.userProfile}>{friend.userProfile}</span>
                                    </div>
                                    {
                                        friend.userStatus === ConstData.USER_STATUS.STUDYING ?
                                            <div className="d-flex area-10 ml-1">
                                                <FaCircle className="d-flex font-md hiclasstv-yellow-text" />
                                            </div> :
                                        friend.userStatus === ConstData.USER_STATUS.LOG_IN ?
                                            <div className="d-flex area-10 ml-1">
                                                <FaCircle className="d-flex font-md green-text" />
                                            </div> :
                                        <></>
                                    }
                                </div>
                            )
                        ) :
                        <div className="view p-2 text-center grey-text">
                            친구가 존재하지 않습니다.
                        </div>
                }
            </div>
            */}
        </div>
    );
}
export default MyFriendList;