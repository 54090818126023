import React from 'react';
import { BsX } from 'react-icons/bs';
import { FaSearch, FaCircle } from 'react-icons/fa';

import CustomProfileImage from '../../user/profile/CustomProfileImage';

const BroadcastLiveMember = ({ selectedLiveInfo, handleSelectedFunc }) => {

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const onChangeSearchValue = (e) => {
        console.log("search value");
    }

    const onClickSearch = () => {
        console.log("click search");
    }
    
    return (
        <div onClick={handlePreventClick}>
            <div className="title-area">
                <div className="text-area" style={{maxWidth:'70%'}}>
                    <span>[</span>
                    <div className="title wmax-70">
                        <span className="hiclass-text font-weight-bold" title={selectedLiveInfo.liveName}>{selectedLiveInfo.liveName}</span>
                    </div>
                    <span>] 멤버</span>
                </div>
                <span className="func-btn" style={{position:'absolute', right:'0'}} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="input-area py-1">
                <input type="text" className="search" placeholder="닉네임, 프로필 검색" onChange={onChangeSearchValue} />
                <div className="input-group-append ml-1" title="검색" onClick={onClickSearch}>
                    <span className="d-flex hiclasstv-btn b-none rounded p-1">
                        <FaSearch className="d-flex font-md black-text" />
                    </span>
                </div>
            </div>
            <div className="list-group" style={{overflow:'auto'}}>
                <div className="title-area sub hiclasstv-grey white-text">
                    <b>승인대기 ({selectedLiveInfo.list_waitMember.length})</b>
                </div>
                {
                    selectedLiveInfo.list_waitMember.length > 0 ?
                        <div className="list-group" style={{overflow:'auto', height:'30%', minHeight: '4rem', maxHeight:'max-content'}}>
                            {
                                selectedLiveInfo.list_waitMember.map(
                                    member => (
                                        <div className="d-flex list-group-hitem list-group-hitem-action py-2 px-1 justify-content-center align-items-center" key={member.userSeq}>
                                            <div className="d-flex area-15 fit-content">
                                                <CustomProfileImage url={member.profileImgUrl} alt={member.userNickname} />
                                            </div>
                                            <div className="text-area flex-column align-items-start">
                                                <span className="hiclass-text" title={member.userNickname}><b>{member.userNickname}</b></span>
                                                <span className="hiclass-text hiclasstv-deepDarkgrey-text small" title={member.userProfile}>{member.userProfile}</span>
                                            </div>
                                            <div className="d-flex area-25 ml-2" onClick={handlePreventClick}>
                                                <button type="button" className="btn btn-sm green white-text m-0 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "MEMBER_ACCEPT", liveSeq: selectedLiveInfo.liveSeq, memberSeq: member.userSeq })}>
                                                    승인
                                                </button>
                                            </div>
                                        </div>
                                    )
                                )
                            }                        
                        </div> :
                        <div className="text-center grey-text p-2">
                            <b>가입 요청한 멤버가 없습니다.</b>
                        </div>
                }
                <div className="title-area sub hiclasstv-blue white-text">
                    <b>멤버 ({selectedLiveInfo.list_member.length})</b>
                </div>
                {
                    selectedLiveInfo.list_member.length > 0 ?
                        <div className="list-group" style={{overflow:'auto'}}>
                            {
                                selectedLiveInfo.list_member.map(
                                    member => (
                                        <div className="d-flex list-group-hitem list-group-hitem-action py-2 px-1 justify-content-center align-items-center" key={member.userSeq}>
                                            <div className="d-flex area-15 fit-content">
                                                <CustomProfileImage url={member.profileImgUrl} alt={member.userNickname} />
                                            </div>
                                            <div className="text-area flex-column align-items-start">
                                                <span className="hiclass-text" title={member.userNickname}><b>{member.userNickname}</b></span>
                                                <span className="hiclass-text hiclasstv-deepDarkgrey-text small" title={member.userProfile}>{member.userProfile}</span>
                                            </div>
                                            {
                                                member.userJoinStatus === 'Y' ?
                                                    <div className="d-flex area-10 ml-1">
                                                        <FaCircle className="d-flex font-md hiclasstv-yellow-text" />
                                                    </div> :
                                                    <></>
                                            }
                                        </div>
                                    )
                                ) 
                            }
                        </div>:
                        <div className="text-center grey-text p-2">
                            <b>멤버가 존재하지 않습니다.</b>
                        </div>
                }
            </div>
        </div>
    );
}

export default BroadcastLiveMember;