import React, { useState } from "react";
import { UncontrolledCollapse } from "reactstrap";

import { FaMinus, FaLockOpen } from 'react-icons/fa';

const WebLocalSiteInfo = ({ idx, localSite_info, handleSelectedFunc }) => {
    
    const onHandleRemoveSite = () => {
        handleSelectedFunc({ selectedFunc: "DELETE_LOCAL_SITE", data: localSite_info.localSiteSeq });
    }

    const onChangeOpenOption = (localSiteSeq, flag) => {
        if (flag) {
            handleSelectedFunc({ selectedFunc: "SET_LOCAL_SITE_OPEN", data: { localSiteSeq: localSite_info.localSiteSeq, openLocalSiteSeq: localSiteSeq }});

            // 해당 site 공유 허용 : openable list => open list
        } else {
            handleSelectedFunc({ selectedFunc: "SET_LOCAL_SITE_CLOSE", data: { localSiteSeq: localSite_info.localSiteSeq, openLocalSiteSeq: localSiteSeq } });
            // 해당 site 공유 중지 : open list => openable list
        }
    }

    return (
        <>
            <tr className="d-flex">
                <td className="d-flex align-items-center justify-content-center area-10">
                    <span>{localSite_info.localSiteSeq}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-70">
                    <span>{localSite_info.localSiteName}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-10 p-0">
                    <button className="btn btn-sm btn-outline-primary px-2 py-1 small ml-2" type="button" id={"openSiteList_" + idx}>
                        목록보기
                    </button>
                </td>
                <td className="d-flex align-items-center justify-content-center area-10 p-0">
                    <button type="button" className="btn btn-sm red white-text py-1 px-2 " title="로컬 사이트 삭제" onClick={() => onHandleRemoveSite()}>
                        <b>삭제{/*<FaMinus />*/}</b>
                    </button>
                </td>            
            </tr>
            <tr className="d-flex b-none">
                <td className="d-flex w-100 p-0">
                    <UncontrolledCollapse toggler={"#openSiteList_" + idx} className="w-100" defaultOpen={false}>
                        <table className="d-flex flex-column table-sm sub-list table-hover mb-0" style={{ background: 'rgb(235, 235, 235)' }}>
                            <thead>
                                <tr className="d-flex">
                                    <th className="d-flex align-items-center justify-content-center area-10">
                                        <b>Seq.</b>
                                    </th>
                                    <th className="d-flex align-items-center justify-content-center area-70">
                                        <b>사이트 이름</b>
                                    </th>
                                    <th className="area-10" />
                                    <th className="area-10" />
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    localSite_info.list_openLocalSite && localSite_info.list_openableLocalSite && (localSite_info.list_openLocalSite.length > 0 || localSite_info.list_openableLocalSite.length > 0) ?
                                    <>
                                        {
                                            localSite_info.list_openLocalSite.map(
                                                site => (
                                                    <tr key={site.localSiteSeq} className="d-flex">
                                                        <td className="d-flex align-items-center justify-content-center area-10">
                                                            <span className="font-weight-bold">{site.localSiteSeq}</span>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center area-70">
                                                            <FaLockOpen className="font-sm mr-2 green-text" style={{ verticalAlign: 'text-top' }} />
                                                            <span className="font-weight-bold">{site.localSiteName}</span>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center area-10 p-0">
                                                            <button type="button" className="btn btn-sm red white-text py-1 px-2 " title="공유 닫기" onClick={() => onChangeOpenOption(site.localSiteSeq, false)}>
                                                                <b>close</b>
                                                            </button>
                                                        </td>
                                                        <td className="area-10" />
                                                    </tr>
                                                )
                                            )
                                        }{
                                            localSite_info.list_openableLocalSite.map(
                                                site => (
                                                    <tr key={site.localSiteSeq} className="d-flex">
                                                        <td className="d-flex align-items-center justify-content-center area-10">
                                                            <span className="font-weight-bold">{site.localSiteSeq}</span>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center area-70">
                                                            <span className="font-weight-bold">{site.localSiteName}</span>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center area-10 p-0">
                                                            <button type="button" className="btn btn-sm green white-text py-1 px-2 " title="공유 열기" onClick={() => onChangeOpenOption(site.localSiteSeq, true)}>
                                                                <b>open</b>
                                                            </button>
                                                        </td>
                                                        <td className="area-10" />
                                                    </tr>
                                                )
                                            )
                                        }
                                    </> :
                                    <tr className="d-flex w-100">
                                        <td className="d-flex w-100 p-0">
                                            <div className="d-flex w-100 view m-3 justify-content-center grey-text" style={{ fontSize: '0.9rem' }}>
                                                로컬 사이트 목록이 존재하지 않습니다.
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </UncontrolledCollapse>
                </td>
            </tr>
        </>
    );
};

export default WebLocalSiteInfo;