import React, { useEffect, useState, useRef } from "react";
import { stQnAItemType } from "../../../lib/ConstCommand";
import { BsX } from "react-icons/bs";

import Item from "../../lecture/card/item/Item";

const QuizResponseInfo = ({ responseInfo, handleSelectedFunc }) => {

    const [question, setQuestion] = useState(null);
    const [example, setExample] = useState(null);
    const [answer, setAnswer] = useState(null);
    //const [answerExampleYN, setAnswerExampleYN] = useState("Y");
    const answerExampleYN = useRef("Y")

    const [response, setResponse] = useState(null);

    useEffect(() => {
        if (responseInfo.quiz_info && responseInfo.quiz_info.data && responseInfo.quiz_info.data.length > 0) {
            let exampleInfo;

            for (let i = 0; i < responseInfo.quiz_info.data.length; i++) {
                let item = responseInfo.quiz_info.data[i];

                switch (item.type) {
                    case stQnAItemType.Question:
                        setQuestion(item.data);

                        if (item.exYN === "Y") {
                            exampleInfo = {
                                type: item.exType,
                                no: item.exNum,
                                list: []
                            }

                            //setAnswerExampleYN("Y");
                            answerExampleYN.current = "Y"
                        } else {
                            //setAnswerExampleYN("N");
                            answerExampleYN.current = "N";
                        }
                        break;

                    case stQnAItemType.Example:
                        let exArr = [];

                        for (let j = 0; j < item.data.length; j++) {
                            let ex = item.data[j];

                            exArr.push(ex);
                        }

                        if (exampleInfo) {
                            exampleInfo = {
                                ...exampleInfo,
                                list: exArr
                            };

                            setExample(exampleInfo);
                        }
                        break;

                    case stQnAItemType.Answer:
                        /* if (typeof item.data === "string") {
                            console.log("QuizResponseInfo Answer - ", item.data);
                            setAnswer(item.data);
                        } else if (typeof item.data === "object") {
                            console.log("QuizResponseInfo Answer - ", item.data);
                            setAnswer(item.data.data);
                        } else {
                            console.log("QuizResponseInfo Answer - ", item.data);
                        } */
                        if (answerExampleYN.current === "Y") {
                            console.log("QuizResponseInfo setAnswer(item.data) - ", item.data);
                            setAnswer(item.data);
                        } else if (answerExampleYN.current === "N") {
                            console.log("QuizResponseInfo setAnswer(item.data.data) - ", item.data);
                            setAnswer(item.data.data);
                        } else {
                            console.log("QuizResponseInfo Answer - ", item.data);
                        }
                        break;

                    default:
                        break;
                }
            }
        }

        if (responseInfo.list_example && responseInfo.list_example.length > 0) {
            let totalCnt = responseInfo.responseCount;

            let response_info = responseInfo.list_example.map(
                (info) => {
                    let value = {
                        ...info,
                        value: totalCnt > 0 ? (info.count / totalCnt) * 100 : 0
                    };

                    return value;
                }
            );

            setResponse(response_info);
        }
    }, [responseInfo]);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div className="d-flex flex-column align-items-start" style={{ minHeight: '300px', maxHeight: '600px' }} onClick={handlePreventClick}>
            <div className="title-area justify-content-center">
                <span>결과보기</span>
                <span className="func-btn" style={{ position: 'absolute', right: 0 }} title="닫기" onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex w-100">
                {
                    responseInfo.quiz_info && responseInfo.quiz_info.data && responseInfo.quiz_info.data.length > 0 ?
                        <div className="quiz-card w-100 p-2">
                            {
                                question && question.map((item, index) => (
                                    <Item key={"question-" + index} info={item} />
                                ))
                            } 
                            {
                                example &&
                                <>
                                    <div className={"example-area " + example.type}>
                                        {
                                            example.list.map((item, index) => (
                                                <div key={"ex-" + index} className={"example-item" + (item.no === answer.no ? " blue-text font-weight-bold" : "")}>
                                                    <span style={{ width: '5%' }}>{item.no}</span>
                                                    <Item info={item} title={"ex-" + item.no} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="d-flex flex-column my-2 py-2" style={{ textAlign: 'center', background: 'aliceblue' }}>
                                        {
                                            answer.kind === "text" ?
                                                <span className="blue-text font-weight-bold py-1">정답 [ {answer.no} - {answer.data} ]</span> :
                                                <span className="blue-text font-weight-bold py-1">정답 [ {answer.no} ]</span>
                                        }
                                        {console.log("answer - ", answer)}
                                        {console.log("answer.no - ", answer.no)}
                                         {
                                            response && response.map((item, index) => (
                                                <div key={"response-" + index} className={(item.no === answer.no ? " answer" : "")} style={{ textAlign: 'start' }}>
                                                    <span className="font-sm mx-2">{item.no}</span>
                                                    {
                                                        item.value > 0 &&
                                                        <div className="resp-progress" style={{ width: `${item.value}%` }} />
                                                    }
                                                    <span className="resp-value">{Math.ceil(item.value)} %</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            }
                            {
                                answerExampleYN.current === "N" && 
                                <div className="d-flex flex-column my-2 py-2" style={{ textAlign: 'center', background: 'aliceblue' }}>
                                    <span className="blue-text font-weight-bold py-1">정답 [ {answer} ]</span>
                                </div>
                            }
                            <button className="response hiclasstv-button hiclasstv-blue font-md white-text w-100 p-1" onClick={() => handleSelectedFunc({ selectedFunc: "SHOW_QUIZ_RESPONSE_RESULT", data: responseInfo.idx })}>
                                응답 결과
                            </button>
                        </div> :
                        <></>
                }
            </div>
        </div>
    )
}

export default QuizResponseInfo;