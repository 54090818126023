import React from 'react';

const WebUserResetPWButton = ({ subDomainSeq, member_info, toggleModalOpened }) => {
    return (
        <div className="list-row align-items-center justify-content-center area-50">
            <button type="button" className="btn btn-sm small outline bold white m-0 ml-2 p-1 outline-red red-text" title="비밀번호 초기화" onClick={() => toggleModalOpened(true, subDomainSeq, member_info)}>
                RESET PW
            </button>
        </div>
    );
}

export default WebUserResetPWButton;