import React, { Component } from "react";
import { connect } from "react-redux";

import WebHiClassDownload from "../../components/etc/info/WebHiClassDownload";

import {
    loadDownloadInfo
} from "../../modules/hiclasstv";

class WebDownloadContainer extends Component {

    handleSelectedFunc = ({ selectedFunc }) => {
        switch (selectedFunc) {
            case "GET_DOWNLOAD_INFO":
                this.props.loadDownloadInfo();
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <div className="web-main info-area align-items-center">
                <WebHiClassDownload
                    download_info={this.props.download_info}
                    handleSelectedFunc={this.handleSelectedFunc}
                />
            </div>
        );
    }
}

export default connect(
    ({ hiclasstv }) => ({
        download_info       : hiclasstv.download_info
    }),
    {
        loadDownloadInfo
    }
)(WebDownloadContainer);