import React, { useState, useEffect } from "react";
import { FiMinusSquare } from "react-icons/fi";

import { stQuizItemType, stQuizDataKind, stQuizDataType } from "../../lib/ConstCommand";
import ExampleDataInput from "./example/ExampleDataInput";
import ConstData from "../../lib/ConstData";

const ExampleDataTypeInput = ({ kind, type, onChangeExampleType }) => {
    const [exampleDataType, setExampleDataType] = useState(type);

    useEffect(() => {
        setExampleDataType(type);
    }, [type]);

    const onChangeExampleDataType = (e) => {
        setExampleDataType(e.target.value);
        onChangeExampleType(e.target.value);
    }

    switch (kind) {
        case stQuizDataKind.Image:
        case stQuizDataKind.Audio:
            return (
                <div className="input-group">
                    <span className="input-group-text border-0"></span>
                    <input type="radio" className="form-check-input" id="exampleDataTypeFile" value={stQuizDataType.File} checked={exampleDataType === stQuizDataType.File} onChange={onChangeExampleDataType} />
                    <label className="form-check-label" htmlFor="exampleDataTypeFile">{stQuizDataType.File}</label>
                    <input type="radio" className="form-check-input" id="exampleDataTypeURL" value={stQuizDataType.URL} checked={exampleDataType === stQuizDataType.URL} onChange={onChangeExampleDataType} />
                    <label className="form-check-label" htmlFor="exampleDataTypeURL">{stQuizDataType.URL}</label>
                </div>
            );

        case stQuizDataKind.Video:
            return (
                <div className="input-group">
                    <span className="input-group-text border-0"></span>
                    <input type="radio" className="form-check-input" id="exampleDataTypeFile" value={stQuizDataType.File} checked={exampleDataType === stQuizDataType.File} onChange={onChangeExampleDataType} />
                    <label className="form-check-label" htmlFor="exampleDataTypeFile">{stQuizDataType.File}</label>
                    <input type="radio" className="form-check-input" id="exampleDataTypeURL" value={stQuizDataType.URL} checked={exampleDataType === stQuizDataType.URL} onChange={onChangeExampleDataType} />
                    <label className="form-check-label" htmlFor="exampleDataTypeURL">{stQuizDataType.URL}</label>
                    <input type="radio" className="form-check-input" id="exampleDataTypeIframe" value={stQuizDataType.Iframe} checked={exampleDataType === stQuizDataType.Iframe} onChange={onChangeExampleDataType} />
                    <label className="form-check-label" htmlFor="exampleDataTypeIframe">{stQuizDataType.Iframe}</label>
                    {/* <input type="radio" className="form-check-input" id="exampleDataTypeObject" value={stQuizDataType.Object} checked={exampleDataType === stQuizDataType.Object} onChange={onChangeExampleDataType} />
                    <label className="form-check-label" htmlFor="exampleDataTypeObject">{stQuizDataType.Object}</label>
                    <input type="radio" className="form-check-input" id="exampleDataTypeEmbed" value={stQuizDataType.Embed} checked={exampleDataType === stQuizDataType.Embed} onChange={onChangeExampleDataType} />
                    <label className="form-check-label" htmlFor="exampleDataTypeEmbed">{stQuizDataType.Embed}</label> */}
                </div>
            );

        default:
            return <></>;
    }
}

const QuizExampleData = ({ index, isAnswer, exampleData_info, lExampleUploadInfo, lAlertKind, lAlertMessage, setAlertKind, setAlertMessage, onChangeAnswerData, handleChangeExampleData, handleRemoveExampleData, handleChangeRecentlyExampleKind, handleExampleFileUpload }) => {
    //const [lExampleItemIndex, setExampleItemIndex] = useState(index);
    const [lExampleDataIndex, setExampleDataIndex] = useState(index);
    const [lExampleKind, setExampleKind] = useState(exampleData_info ? exampleData_info.exampleKind : stQuizDataKind.Text);
    const [lExampleType, setExampleType] = useState(exampleData_info ? exampleData_info.exampleType : stQuizDataType.None);
    const [lExampleData, setExampleData] = useState(exampleData_info ? exampleData_info.exampleData : '');
    const [lUploadUrlYN, setUploadUrlYN] = useState(exampleData_info ? exampleData_info.uploadUrlYN : 'N');
    const [lUploadPath, setUploadPath] = useState(exampleData_info ? exampleData_info.uploadPath : '');

    useEffect(() => {
        //setExampleItemIndex(index);
        setExampleDataIndex(index);

        if (exampleData_info) {
            if (exampleData_info.exampleKind) {
                setExampleKind(exampleData_info.exampleKind);
            }

            if (exampleData_info.exampleType) {
                setExampleType(exampleData_info.exampleType);
            }

            if (exampleData_info.exampleData !== undefined && exampleData_info.exampleData !== null) {
                setExampleData(exampleData_info.exampleData);
            }

            if (exampleData_info.uploadUrlYN) {
                setUploadUrlYN(exampleData_info.uploadUrlYN);
            }

            if (exampleData_info.uploadPath) {
                setUploadPath(exampleData_info.uploadPath);
            }
        }
    }, [index, exampleData_info]);

    const onChangeExampleKind = (e) => {
        let exampleKind = e.target.value;
        let exampleType = stQuizDataType.None;
        let uploadUrlYN = 'N';
        let uploadPath ='';

        switch (exampleKind) {
            case stQuizDataKind.Text:
                exampleType = stQuizDataType.None;
                break;

            case stQuizDataKind.Image:
            case stQuizDataKind.Audio:
            case stQuizDataKind.Video:
                exampleType = stQuizDataType.File;
                break;

            default:
                exampleType = stQuizDataType.None;
                break;
        }

        if (lExampleKind !== exampleKind && lExampleData !== '') {
            setExampleData('');
            setExampleKind(exampleKind);
            setExampleType(exampleType);
            setUploadUrlYN(uploadUrlYN);
            setUploadPath(uploadPath);

            handleChangeExampleData({
                index: lExampleDataIndex,
                exampleKind,
                exampleType,
                exampleData: '',
                uploadUrlYN,
                uploadPath
            });
        } else {
            setExampleKind(exampleKind);
            setExampleType(exampleType);

            handleChangeExampleData({
                index: lExampleDataIndex,
                exampleKind,
                exampleType,
                exampleData: lExampleData,
                uploadUrlYN: lUploadUrlYN,
                uploadPath: lUploadPath
            });
        }

        handleChangeRecentlyExampleKind(e.target.value);
    }

    const onChangeExampleType = (exampleType) => {
        if (lExampleType !== exampleType && lExampleData !== '') {
            setExampleData('');
            setExampleType(exampleType);
            setUploadUrlYN('N');
            setUploadPath('');

            handleChangeExampleData({
                index: lExampleDataIndex,
                exampleKind: lExampleKind,
                exampleType,
                exampleData: '',
                uploadUrlYN: 'N',
                uploadPath: ''
            });
        } else {
            setExampleType(exampleType);

            handleChangeExampleData({
                index: lExampleDataIndex,
                exampleKind: lExampleKind,
                exampleType,
                exampleData: lExampleData,
                uploadUrlYN: lUploadUrlYN,
                uploadPath: lUploadPath
            });
        }
    }

    const onChangeExampleData = (e) => {
        if (e !== undefined && e.target !== undefined) {
            if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
                setAlertMessage("");
                setAlertKind(ConstData.ALERT_KIND.NONE);
            }

            let exampleData = e.target.value;
            if (exampleData.length > 150) {
                exampleData = exampleData.substr(0, 150);
                setAlertMessage("퀴즈의 보기는 150자 이내로 입력해야 합니다.");
                setAlertKind(ConstData.ALERT_KIND.DANGER);
            }
            let uploadUrlYN = 'N';
            let uploadPath ='';
            setExampleData(exampleData);
            setUploadUrlYN(uploadUrlYN);
            setUploadPath(uploadPath);

            handleChangeExampleData({
                index: lExampleDataIndex,
                exampleKind: lExampleKind,
                exampleType: lExampleType,
                exampleData,
                uploadUrlYN,
                uploadPath
            });
        } else {
            let exampleData = e;
            let uploadUrlYN = e !== '' ? 'Y' : 'N';
            setExampleData(exampleData);
            setUploadUrlYN(uploadUrlYN);

            handleChangeExampleData({
                index: lExampleDataIndex,
                exampleKind: lExampleKind,
                exampleType: lExampleType,
                exampleData,
                uploadUrlYN,
                uploadPath: lUploadPath
            });
        }
    }

    const onClickRemoveExampleData = () => {
        let example_data = {
            exampleKind: lExampleKind,
            exampleType: lExampleType,
            exampleData: lExampleData,
            uploadUrlYN: lUploadUrlYN,
            uploadPath: lUploadPath
        };

        handleRemoveExampleData(lExampleDataIndex, example_data);
    }

    const handleUploadPath = (uploadPath) => {
        if (lUploadPath !== uploadPath && uploadPath === '') {
            setExampleData('');
            setUploadUrlYN('N');
            setUploadPath(uploadPath);

            handleChangeExampleData({
                index: lExampleDataIndex,
                exampleKind: lExampleKind,
                exampleType: lExampleType,
                exampleData: '',
                uploadUrlYN: 'N',
                uploadPath
            });
        } else {
            let uploadUrlYN = uploadPath !== '' ? 'Y' : 'N';
            let exampleData = uploadPath !== '' ? lExampleData : '';
            setExampleData(exampleData);
            setUploadPath(uploadPath);
            setUploadUrlYN(uploadUrlYN);

            handleChangeExampleData({
                index: lExampleDataIndex,
                exampleKind: lExampleKind,
                exampleType: lExampleType,
                exampleData,
                uploadUrlYN,
                uploadPath
            });
        }
    }

    return (
        <>
            <ExampleDataTypeInput
                kind={lExampleKind}
                type={lExampleType}
                onChangeExampleType={onChangeExampleType}
            />
            <div className="data-area">
                <div className="minus-btn-area" onClick={onClickRemoveExampleData}>
                    <FiMinusSquare className="font-lg hiclasstv-coral-text" />
                </div>
                <div className="input-group mb-2">
                    {/* <span className="input-group-text">{stExampleItem[lExampleDataIndex]}</span> */}
                    {
                        lExampleUploadInfo && lExampleUploadInfo.seq === lExampleDataIndex ?
                            <ExampleDataInput
                                index={lExampleDataIndex}
                                kind={lExampleKind}
                                type={lExampleType}
                                data={lExampleData}
                                uploadUrlYN={lUploadUrlYN}
                                lExampleUploadInfo={lExampleUploadInfo}
                                onChangeExampleData={onChangeExampleData}
                                onChangeExampleType={onChangeExampleType}
                                handleUploadPath={handleUploadPath}
                                handleExampleFileUpload={handleExampleFileUpload}
                            /> :
                            <ExampleDataInput
                                index={lExampleDataIndex}
                                kind={lExampleKind}
                                type={lExampleType}
                                data={lExampleData}
                                uploadUrlYN={lUploadUrlYN}
                                onChangeExampleData={onChangeExampleData}
                                onChangeExampleType={onChangeExampleType}
                                handleUploadPath={handleUploadPath}
                                handleExampleFileUpload={handleExampleFileUpload}
                            />
                    }
                    <span className="input-group-text">
                        <input type="radio" className="form-check-input" id={'exampleDataRadio-' + lExampleDataIndex} value={lExampleDataIndex} checked={isAnswer} onChange={onChangeAnswerData} />
                    </span>
                    <span className="input-group-text border-0" style={{ color: '#4f4f4f' }}>Type.</span>
                    <div className="select-wrapper" style={{ flex: '1 1 auto', width: 'fit-content' }}>
                        <select className="form-select" value={lExampleKind} onChange={onChangeExampleKind}>
                            {
                                stQuizItemType.map((info, index) => (
                                    <option key={"question-kind-option-item-" + index} value={info}>
                                        {info}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
        </>
    );
}

export default QuizExampleData;