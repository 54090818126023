import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import {
    joinLive,
    requestJoinMixedClassLive,
    toggleIsNeedGoToHome
} from "../modules/live";

import {
    setLoginMessage,
    requestMixedClassLoginList,
    reqeustMixedClassLogout
} from "../modules/user";

import MixedClassLoginWindow from "../components/live/mixedClass/MixedClassLoginWindow";
import { sendRequestMixedClassLogin } from "../lib/RabbitmqClient";

class MixedLoginContainer extends Component {

    componentDidMount() {
        if (this.props.isLoginYn === "Y") {
            console.log("QRLogInContainer(1) - have to request logOut ... userSeq => ", this.props.userSeq);
            this.props.reqeustMixedClassLogout({
                userSeq: this.props.userSeq,
                mode: "web"
            });
        } else {
            if (!this.props.isRbmqServerConnected) {
                const mixedClassSeq = this.props.routeProps.match.params.mixedClassSeq;
                if (mixedClassSeq && mixedClassSeq !== "" && parseInt(mixedClassSeq)) {
                    console.log("MixedLoginContainer() - have to request getUserListForMixedLogin ... mixedClassSeq => ", mixedClassSeq);
                    this.props.requestMixedClassLoginList({
                        mixedClassSeq: parseInt(mixedClassSeq),
                        userKind: this.props.userKind
                    });
                } else {
                    console.log("MixedLoginContainer() - can not request getUserListForMixedLogin ... mixedClassSeq => ", mixedClassSeq);
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isRbmqServerConnected !== this.props.isRbmqServerConnected && this.props.isRbmqServerConnected) {
            console.log("MixedLoginContainer() - temp key connected.");
            const mixedClassSeq = this.props.routeProps.match.params.mixedClassSeq;
            if (mixedClassSeq && mixedClassSeq !== "" && parseInt(mixedClassSeq)) {
                console.log("MixedLoginContainer() - have to request getUserListForMixedLogin ... mixedClassSeq => ", mixedClassSeq);
            } else {
                console.log("MixedLoginContainer() - can not request getUserListForMixedLogin ... mixedClassSeq => ", mixedClassSeq);
            }
        } else {
            // 클래스 입장 후 뒤로 가기했을 경우 로그아웃이 실행되기 때문에 didmount때 받아온 데이터가 모두 사라지므로 로그인 가능 목록 재호출.. by hjkim 20230417
            if (prevProps.isRbmqServerConnected !== this.props.isRbmqServerConnected && !this.props.isRbmqServerConnected) {
                const mixedClassSeq = this.props.routeProps.match.params.mixedClassSeq;
                if (mixedClassSeq && mixedClassSeq !== "" && parseInt(mixedClassSeq)) {
                    console.log("MixedLoginContainer() - have to request getUserListForMixedLogin ... mixedClassSeq => ", mixedClassSeq);
                    this.props.requestMixedClassLoginList({
                        mixedClassSeq: parseInt(mixedClassSeq),
                        userKind: this.props.userKind
                    });
                } else {
                    console.log("MixedLoginContainer() - can not request getUserListForMixedLogin ... mixedClassSeq => ", mixedClassSeq);
                }
            }
        }

        if (prevProps.isLoginYn !== this.props.isLoginYn && this.props.isLoginYn === "Y") {
            this.handleSelectedFunc({ selectedFunc: "JOIN" });
        }

        if (prevProps.isGotoWebLive !== this.props.isGotoWebLive && this.props.isGotoWebLive) {
            console.log("MixedLoginContainer() - go to live page ... liveSeq => ", this.props.performLiveSeq);
            if (this.props.isRbmqServerConnected && this.props.performLiveSeq > -1) {
                console.log("MixedLoginContainer() - go to live page ... liveSeq => ", this.props.performLiveSeq);
                this.props.routeProps.history.push(`/live/${this.props.performLiveSeq}`);
            }
        }
    }

    handleSelectedFunc = ({ selectedFunc, live_info, loginInfo }) => {
        switch (selectedFunc) {
            case "GET_LOGIN_INFO":
                break;

            case "JOIN":
                /* this.props.handleSelectedFunc({
                    selectedFunc: "JOIN",
                    liveSeq: this.props.mixedClassInfo.liveSeq,
                    liveName: this.props.mixedClassInfo.liveName,
                    mixedClassSeq: this.props.mixedClassInfo.mixedClassSeq
                }); */

                this.props.requestJoinMixedClassLive({
                    liveSeq: this.props.mixedClassInfo.liveSeq,
                    liveName: this.props.mixedClassInfo.liveName,
                    userSeq: this.props.userSeq,
                    userNickname: this.props.userNickname,
                    mixedClassSeq: this.props.mixedClassInfo.mixedClassSeq
                });
                break;

            case "LOGIN_FOR_MIXED":
                sendRequestMixedClassLogin(loginInfo.userSeq);
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <>
                <MixedClassLoginWindow 
                    live_info={this.props.mixedClassInfo}
                    list_member={this.props.mixedClassInfo.list_member}
                    isInvalidRoute={this.props.isInvalidRoute}
                    alertKind={this.props.alertKind}
                    loginMessage={this.props.loginMessage}
                    handleSelectedFunc={this.handleSelectedFunc}
                />
            </>
        );
    }
}

export default connect(
    ({ live, user, hiclasstv }) => ({
        isLoginYn                   : user.isLoginYn,

        userSeq                     : user.userSeq,
        userNickname                : user.userNickname,
        mySubDomainSeq              : user.mySubDomainSeq,

        userKind                    : user.userKind,

        mixedClassInfo              : live.mixedClassInfo,

        isGotoWebLive               : live.isGotoWebLive,

        performLiveSeq              : live.performLiveInfo.liveSeq,

        alertKind                   : user.alertKind,
        loginMessage                : user.loginMessage,

        isRbmqServerConnected       : user.isRbmqServerConnected,

        isInvalidRoute              : live.isInvalidRoute
    }),
    {
        toggleIsNeedGoToHome,
        requestJoinMixedClassLive,

        requestMixedClassLoginList,
        reqeustMixedClassLogout
    }
)(MixedLoginContainer);