import React, { Component } from 'react';
import { connect } from 'react-redux';

import LiveAlertModal from '../components/live/modal/LiveAlertModal';
import LiveAlertModalRemoveMember from '../components/live/modal/LiveAlertModalRemoveMember';
import LiveAlertModalFinish from '../components/live/modal/LiveAlertModalFinish';
import LiveAlertModalRemove from '../components/live/modal/LiveAlertModalRemove';
import LiveAlertModalMemberLimit from '../components/live/modal/LiveAlertModalMemberLimit';
import AlertModal from '../components/etc/modal/AlertModal';
//import ChatListContainer from './ChatListContainer';
import WebLectureContainer from './lecture/WebLectureContainer';
import FriendListContainer from './FriendListContainer';
import LiveListContainer from './LiveListContainer';

import { joinLive, closeLiveAlertModal, toggleInvalidBroadcastKeyModal } from '../modules/live';
import { toggleInvalidLectureCodeModal } from '../modules/lecture';
import { getLiveInfoByCookies } from '../modules/hiclasstv';
import ConstData from '../lib/ConstData';

class MainAllListContainer extends Component {
    componentDidMount() {        
        const { cookies } = this.props;

        if (Number(cookies.get('liveSeq')) > 0 && this.props.live_info === "") {
            console.log('not yet setting live Info');
            let liveSeq = Number(cookies.get('liveSeq'));
            this.props.getLiveInfoByCookies({ liveSeq, mode: 'web' });
        }
    }

    componentDidUpdate(prevProps) {
        const { cookies } = this.props;

        if (Number(cookies.get('liveSeq')) > 0 && prevProps.live_info !== this.props.live_info && this.props.live_info.liveSeq > -1) {
            const { liveSeq, liveName } = this.props.live_info;

            if (liveSeq > 0 && liveName !== "") {
                console.log('componentDidUpdate - ', liveSeq, liveName);
                console.log(`userNickname - ${this.props.userNickname}`);
                this.props.joinLive({ liveSeq, liveName, userSeq:this.props.userSeq, userNickname: this.props.userNickname, profileImgUrl: this.props.profileImgUrl });
            }   
        }

        if (this.props.isGotoWebLive && this.props.isRbmqServerConnected && this.props.performLiveSeq > -1) {
            this.props.routeProps.history.push(`/live/${this.props.performLiveSeq}`);
        }
    }

    handleSelectedFunc = ({ selectedFunc }) => {

        switch (selectedFunc) {
            case "LIVE_ALERT_TOGGLE" :
                this.props.closeLiveAlertModal();
                break;

            case "TOGGLE_ALERT_MODAL" :
                this.props.toggleInvalidLectureCodeModal({ isModalOpened: false });
                this.props.toggleInvalidBroadcastKeyModal({isModalOpened: false});
                break;

            default :
                break;
        }
    }

    render() {
        const { routeProps, browserType, currentPageType } = this.props;
        const { lecture_alert, live_alert } = this.props;
        const { closedLive_info, isLiveAlertModalOpened, isLiveAlertFinishModalOpened, 
                isLiveAlertRemoveModalOpened, isAlertModalRemoveMemberOpened, 
                isLiveAlertMemberLimitOpened, isInvalidLectureCodeModalOpened, isInvalidBroadcastKeyModalOpened } = this.props;
        const { handleSelectedFunc } = this;

        return (
            <>
                <LiveAlertModal 
                    live_info={closedLive_info}
                    isAlertModalOpened={isLiveAlertModalOpened}
                    handleSelectedFunc={handleSelectedFunc}
                />
                <LiveAlertModalFinish 
                    live_info={closedLive_info}
                    isLiveAlertFinishModalOpened={isLiveAlertFinishModalOpened}
                    handleSelectedFunc={handleSelectedFunc}
                />
                <LiveAlertModalRemove 
                    live_info={closedLive_info}
                    isLiveAlertRemoveModalOpened={isLiveAlertRemoveModalOpened}
                    handleSelectedFunc={handleSelectedFunc}
                />
                <LiveAlertModalRemoveMember
                    live_info={closedLive_info}
                    isAlertModalRemoveMemberOpened={isAlertModalRemoveMemberOpened}
                    handleSelectedFunc={handleSelectedFunc}
                />
                <LiveAlertModalMemberLimit 
                    live_info={closedLive_info}
                    isLiveAlertMemberLimitOpened={isLiveAlertMemberLimitOpened}
                    handleSelectedFunc={handleSelectedFunc}
                />
                <AlertModal 
                    isModalOpened={isInvalidLectureCodeModalOpened}
                    alertKind={lecture_alert.alertKind}
                    alertMessage={lecture_alert.alertMessage}
                    handleSelectedFunc={handleSelectedFunc}
                />
                <AlertModal 
                    isModalOpened={isInvalidBroadcastKeyModalOpened}
                    alertKind={live_alert.alertKind}
                    alertMessage={live_alert.alertMessage}
                    handleSelectedFunc={handleSelectedFunc}
                />
                {/*
                <div className="d-flex justify-content-center p-0" style={{margin:"0px 50px",width:"100%",height:"auto"}}>
                    <ChatListContainer 
                        browserType={browserType}
                        currentPageType={currentPageType}
                    />
                </div>
                */}
                <div className="d-flex flex-column align-items-center w-100 h-100 overflow-auto">
                    {/* <iframe src="http://localhost:3000" title='test_page' width={1000} height={1000} /> */}
                    <LiveListContainer
                        routeProps={routeProps}
                        browserType={browserType}
                        currentPageType={currentPageType}
                    />
                    {
                        !ConstData.IS_LOCAL_VERSION &&
                        <WebLectureContainer routeProps={routeProps} />
                    }
                    { // 임시... 친구 기능 뺌... BY hjkim 20230912
                    /* <FriendListContainer
                        browserType={browserType}
                        currentPageType={currentPageType}
                    /> */}
                </div>
            </>
        );
    }
}

export default connect(
    ({ user, live, lecture, hiclasstv }) => ({
        userSeq                         : user.userSeq,
        userNickname                    : user.userNickname,
        userProfile                     : user.userProfile,
        profileImgUrl                   : user.profileImgUrl,
        isLoginYn                       : user.isLoginYn,
        mySubDomainSeq                  : user.mySubDomainSeq,

        list_lecture                    : lecture.list_lecture,

        isInvalidLectureCodeModalOpened : lecture.isInvalidLectureCodeModalOpened,
        lecture_alert                   : {
            alertKind                   : lecture.alertKind,
            alertMessage                : lecture.alertMessage
        },

        isInvalidBroadcastKeyModalOpened: live.isInvalidBroadcastKeyModalOpened,
        live_alert                      : {
            alertKind       : live.alertKind,
            alertMessage    : live.alertMessage
        },

        isLiveAlertModalOpened          : live.isLiveAlertModalOpened,
        isLiveAlertFinishModalOpened    : live.isLiveAlertFinishModalOpened,
        isLiveAlertRemoveModalOpened    : live.isLiveAlertRemoveModalOpened,
        isAlertModalRemoveMemberOpened  : live.isLiveAlertModalRemoveMemberOpened,
        isLiveAlertMemberLimitOpened    : live.isLiveAlertModalMemberLimitOpened,

        isRbmqServerConnected           : user.isRbmqServerConnected,
        isGotoWebLive                   : live.isGotoWebLive,
        performLiveSeq                  : live.performLiveInfo.liveSeq,
        live_info                       : hiclasstv.cookiesLiveInfo,

        closedLive_info                 : {
            liveName         : live.closedPerformLiveInfo.liveName,
            teacher_info     : live.closedPerformLiveInfo.teacher_info
        }
    }),
    {
        joinLive,

        getLiveInfoByCookies,

        closeLiveAlertModal,
        toggleInvalidLectureCodeModal,
        toggleInvalidBroadcastKeyModal
    }
)(MainAllListContainer);