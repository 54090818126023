import React from 'react';

import { ctMsgType } from '../../../lib/ConstCommand';
import ThumbnailMessage from '../../classtalk/template/ThumbnailMessage';

const WatsonMessage = ({ indexKey, msgInfo, handleSelectUrl }) => {
    return (
        <>
        {
            msgInfo.text !== undefined && msgInfo.text !== null && msgInfo.text.length > 0 &&
            msgInfo.text.map(
                (msg, idx) => (
                    <span key={idx} className="ct-bubble bubble-msg msg-rcv msg-bg-violet-btext mb-1">{msg.text}</span>
                )
            )
        }
        {
            msgInfo.kind === ctMsgType.WatsonImage &&
            <ThumbnailMessage msgInfo={msgInfo} handleSelectUrl={() => handleSelectUrl(msgInfo.kind, msgInfo.urlInfo, indexKey)} />
        }
        </>
    );
}

export default WatsonMessage;