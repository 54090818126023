import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

import { getEntryMemberCount } from '../../../lib/func/ExportFunction';

const SearchLiveList = ({ list_live, list_searchLive, handleSearchValue, handleSelectedFunc }) => {

    const [isChangedSearchValue, setChangedSearchValue] = useState(false);
    const [isClickSearchIcon, setClickSearchIcon] = useState(false);
    const [lSearchValue, setSearchValue] = useState("");

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setClickSearchIcon(true);
            handleSearchClick({ keyword: lSearchValue });
        }
    }

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const handleSearchClick = () => {
        setClickSearchIcon(true);
        handleSearchValue({ keyword: lSearchValue });
    }

    const onChangeSearchValue = (e) => {
        setChangedSearchValue(true);
        setClickSearchIcon(false);
        setSearchValue(e.target.value);
        handleSearchValue({ keyword: e.target.value });
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="input-area pt-1">
                <input type="text" className="search" placeholder="검색어를 입력하세요" onChange={onChangeSearchValue} onKeyPress={handleKeyPress} />
                <div className="input-group-append ml-1" title="검색" onClick={handleSearchClick}>
                    <span className="d-flex hiclasstv-btn b-none rounded p-1">
                        <FaSearch className="d-flex font-md black-text" />
                    </span>
                </div>
            </div>
            {
                isClickSearchIcon === true ?
                    <div className="py-1 hiclasstv-lightgrey">
                        <span className="px-2 font-weight-bold grey-text">클래스</span>
                    </div> :
                    <></>
            }
            <div className="list-group scroll-show-active light" style={{ overflow: 'auto' }}>
                {
                    isChangedSearchValue === false ?
                        list_live.length > 0 ?
                            list_live.map(
                                live => (
                                    <div className={"d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" + (live.selectStatus === 'Y' ? " active" : "")} key={live.liveSeq} onClick={() => handleSelectedFunc((live.selectStatus === 'Y' ? { selectedFunc: (live.selectStatus === "Y" ? "UNSELECT_ACTIVE_LIVE" : "SELECT_ACTIVE_LIVE"), liveSeq: live.liveSeq } : { selectedFunc: "DISABLE" }))}>
                                        <div className="text-area flex-column align-items-start">
                                            <div className="text-area justify-content-start">
                                                <span className="hiclass-text" title={live.liveName}><b>{live.liveName}</b></span>
                                                <span className="hiclass-text hiclasstv-darkgrey-text">&nbsp;{getEntryMemberCount('handsUp', live.list_member)}</span>
                                            </div>
                                            <div className="hiclass-text hiclasstv-darkgrey-text small">
                                                참여자&nbsp;<b>{live.list_member.length}</b>명
                                            </div>
                                        </div>
                                        <div className="d-flex area-20 ml-2" onClick={handlePreventClick}>
                                            {
                                                live.liveStatus === "N" ?
                                                    <button type="button" className="btn btn-sm hiclasstv-grey white-text m-0 py-1 px-2" style={{ borderRadius: '1rem' }} onClick={() => handleSelectedFunc({ selectedFunc: "ONAIR", liveSeq: live.liveSeq, liveName: live.liveName, list_broadcast: live.list_broadcast })}>
                                                        onAir
                                                    </button> :
                                                    <button type="button" className="btn btn-sm green white-text m-0 py-1 px-2" style={{ borderRadius: '1rem' }} onClick={() => handleSelectedFunc({ selectedFunc: "OFFAIR_TOGGLE", liveSeq: live.liveSeq })}>
                                                        onAir
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                )
                            ) :
                            <div className="view p-2 text-center grey-text">
                                검색 결과가 없습니다.
                            </div> :
                        list_searchLive.length > 0 ?
                            list_searchLive.map(
                                live => (
                                    <div className={"d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" + (live.selectStatus === 'Y' ? " active" : "")} key={live.liveSeq} onClick={() => handleSelectedFunc((live.selectStatus === "Y" ? { selectedFunc: (live.selectStatus === "Y" ? "UNSELECT_ACTIVE_LIVE" : "SELECT_ACTIVE_LIVE"), liveSeq: live.liveSeq } : { selectedFunc: "DISABLE" }))}>
                                        <div className="text-area flex-column align-items-start">
                                            <div className="text-area justify-content-start">
                                                <span className="hiclass-text" title={live.liveName}><b>{live.liveName}</b></span>
                                                <span className="hiclass-text hiclasstv-darkgrey-text">&nbsp;{getEntryMemberCount('handsUp', live.list_member)}</span>
                                            </div>
                                            <div className="hiclass-text hiclasstv-darkgrey-text small">
                                                참여자&nbsp;<b>{live.list_member.length}</b>명
                                            </div>
                                        </div>
                                        <div className="d-flex area-20 ml-2" onClick={handlePreventClick}>
                                            {
                                                live.liveStatus === "N" ?
                                                    <button type="button" className="btn btn-sm hiclasstv-grey white-text m-0 py-1 px-2" style={{ borderRadius: '1rem' }} onClick={() => handleSelectedFunc({ selectedFunc: "ONAIR", liveSeq: live.liveSeq, liveName: live.liveName, list_broadcast: live.list_broadcast })}>
                                                        onAir
                                                    </button> :
                                                    <button type="button" className="btn btn-sm green white-text m-0 py-1 px-2" style={{ borderRadius: '1rem' }} onClick={() => handleSelectedFunc({ selectedFunc: "OFFAIR_TOGGLE", liveSeq: live.liveSeq })}>
                                                        onAir
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                )
                            ) :
                            <div className="view p-2 text-center grey-text">
                                검색 결과가 없습니다.
                            </div>
                }
            </div>
        </div>
    );
}

export default SearchLiveList;