import React, {useState} from 'react';

const ChoiceFindInfo = ({ handleSelectedFunc }) => {

    const [selectOption, setOption] = useState("ID");

    const handleSelectOption = (e) => {
        setOption(e.target.value);
    }

    return (
        <div className="d-flex flex-column align-items-center my-2 area-70">
            <div className="d-flex flex-column py-4">
                <div className="d-flex align-items-center mb-2">
                    <input className="d-flex mr-1" type="radio" name="groupOfFindIdPwRadios" id="findIdPwRadio1" value="ID" defaultChecked onChange={handleSelectOption} />
                    회원 ID 찾기
                </div>
                <div className="d-flex align-items-center mt-2">
                    <input className="d-flex mr-1" type="radio" name="groupOfFindIdPwRadios" id="findIdPwRadio2" value="PW" onChange={handleSelectOption} />
                    비밀번호 찾기
                </div>
            </div>
            <div className="d-flex w-100 justify-content-center">
                <button className="btn btn-sm btn-primary" onClick={() => handleSelectedFunc({selectedFunc:"FIND", type:selectOption})}>찾기</button>
                <button className="btn btn-sm hiclasstv-grey" onClick={() => handleSelectedFunc({selectedFunc:"CLOSE"})}>취소</button>
            </div>
        </div>
    );
}

export default ChoiceFindInfo;