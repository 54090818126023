import axios from 'axios';
import ConstData from '../ConstData';
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

// 클래스 삭제
export const remove_live = ({ liveSeq, userSeq, mode }) => {
    return axios.delete(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}/${userSeq}?kind=remove&mode=${mode}`, options);
};

// 클래스 이름 변경
export const update_live_name = ({ liveSeq, userSeq, liveName, mode }) => {
    return axios.put(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}/${userSeq}?kind=edit-name&mode=${mode}`, {
        liveName
    }, options);
};

// 클래스 그룹 연결 인원 변경
export const update_live_groupLimit = ({ liveSeqArr, userSeq, groupLimit }) => {
    return axios.put(`${ConstData.REST_API_HOST_ADD}/live/${userSeq}?kind=edit-groupLimit`, {
        liveSEQList: liveSeqArr,
        groupLimit
    }, options);
}

// 클래스 방송 알림
export const notify_live_broadcast = ({ liveSeq, userSeq, data, mode }) => {
    return axios.put(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}/${userSeq}?kind=notify-broadcast&mode=${mode}`, {
        startDate: data.startDate,
        endDate: data.endDate
    }, options);
};

export const notify_live_broadcastWithGuest = ({ liveSeq, userSeq, data, mode }) => {
    //console.log(data);
    return axios.put(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}/${userSeq}?kind=notify-broadcast&mode=${mode}`, {
        startDate: data.startDate,
        endDate: data.endDate,
        list_guest: data.list_guest
    }, options);
};

// 클래스 방송 오픈
export const live_on = ({ liveSeq, userSeq, mode, groupLimit, studioSeq, isChattingLock, isAndroidTouchScreenLock }) => {
    //console.log('live on api');
    let data = { liveStatus: "Y" };
    if (groupLimit !== undefined && groupLimit !== null) {
        data.groupLimit = groupLimit;
    }

    if (studioSeq !== undefined && studioSeq !== null) {
        data.studioSEQ = studioSeq;
    }

    if (isChattingLock !== undefined && isChattingLock !== null) {
        data.isChattingLock = isChattingLock;
    }

    if (isAndroidTouchScreenLock !== undefined && isAndroidTouchScreenLock !== null) {
        data.isAndroidTouchScreenLock = isAndroidTouchScreenLock;
    }

    /*if (groupLimit !== undefined && groupLimit !== null) {
        console.log('live on api called(1)');
        return axios.put(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}/${userSeq}?kind=update-status&mode=${mode}`, {
            liveStatus : 'Y',
            groupLimit
        });
    } else {
        console.log('live on api called(2)');
        return axios.put(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}/${userSeq}?kind=update-status&mode=${mode}`, {
            liveStatus : 'Y'
        });
    }*/

    return axios.put(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}/${userSeq}?kind=update-status&mode=${mode}`, data, options);
};

// 클래스 방송 닫음
export const live_off = ({ liveSeq, userSeq, mode }) => {
    return axios.put(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}/${userSeq}?kind=update-status&mode=${mode}`, {
        liveStatus: 'N'
    }, options);
};

// 클래스 생성
export const create_live = ({ teacherSeq, liveName, liveCode, robotLiveYN, mode }) => {
    //console.log('api) create live ) teacher : '+teacherSeq+' / name : '+liveName+' / code : '+liveCode);
    return axios.post(`${ConstData.REST_API_HOST_ADD}/live?kind=create&mode=${mode}`, {
        teacherSEQ: teacherSeq,
        liveName,
        liveCode,
        robotLiveYN: robotLiveYN === undefined || robotLiveYN === null ? 'N' : robotLiveYN
    }, options);
};

// 클래스 멤버 허가 & 거절
export const update_live_member = ({ liveSeq, userSeq, liveCertiYN, mode }) => {
    //console.log('api) update live member');
    return axios.put(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}/${userSeq}?kind=update-member&mode=${mode}`, {
        liveCertiYN
    }, options);
};

// 클래스 멤버 삭제
export const remove_live_member = ({ liveSeq, userSeq, mode }) => {
    //console.log('api) remove live member');
    return axios.put(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}/${userSeq}?kind=remove-member&mode=${mode}`, {
        liveCertiYN: 'N'
    }, options);
};

// 클래스 코드 생성
export const create_code = ({ mode }) => {
    console.log('api) create code');
    /* return axios.put(`${ConstData.REST_API_HOST_ADD}/live/code?kind=create-code&mode=${mode}`, {
        liveCode : null
    }); */
    return axios.get(`${ConstData.REST_API_HOST_ADD}/live/code?kind=create-code`, options);
};

// 클래스 코드 중복 확인
export const check_code = ({ liveCode, mode }) => {
    console.log('api) check code');
    /* return axios.put(`${ConstData.REST_API_HOST_ADD}/live/code?kind=check-code&mode=${mode}`, {
        liveCode
    }); */
    return axios.get(`${ConstData.REST_API_HOST_ADD}/live/code?kind=check-code&liveCode=${liveCode}`, options);
};

// 클래스 코드 인증
export const certify_code = ({ liveCode, userSeq, mode }) => {
    console.log('api) certify code : ', liveCode, userSeq, mode);
    /* return axios.put(`${ConstData.REST_API_HOST_ADD}/live/code?kind=certify-code&mode=${mode}`, {
        liveCode,
        userSEQ : userSeq
    }); */
    return axios.put(`${ConstData.REST_API_HOST_ADD}/live/code?kind=certify-code`, {
        liveCode,
        userSEQ: userSeq
    }, options);
};

// 클래스 신청
export const apply_live = ({ liveSeq, userSeq, mode }) => {
    //console.log('api) apply live : ',liveSeq, userSeq, mode);
    return axios.post(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}/${userSeq}?kind=apply&mode=${mode}`, {}, options);
};

// 클래스 재신청
export const reapply_live = ({ liveSeq, userSeq, mode }) => {
    //console.log('api) apply live : ',liveSeq, userSeq, mode);
    return axios.post(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}/${userSeq}?kind=reapply&mode=${mode}`, {}, options);
};

// 내가 선생님인 클래스 리스트
export const get_my_live_list = ({ userSeq, mode }) => {
    //console.log('api) get mylivelist');
    return axios.get(`${ConstData.REST_API_HOST_ADD}/live/${userSeq}?kind=teacher&mode=${mode}`, options);
};

// 내가 학생인 클래스 리스트
export const get_my_watchlive_list = ({ userSeq, mode }) => {
    //console.log('api) get stdlivelist');
    return axios.get(`${ConstData.REST_API_HOST_ADD}/live/${userSeq}?kind=student&mode=${mode}`, options);
};

// 클래스 클래스 입장
export const join_live = ({ liveSeq, userSeq, userNickname, mixedClassSeq }) => {
    console.log(`api:) join live - liveSeq[${liveSeq}], userSeq[${userSeq}], userNickname[${userNickname}]`);
    return axios.post(`${ConstData.REST_API_HOST_ADD}/live/join/${liveSeq}/${userSeq}?kind=join&mode=web`, {
        userNickname
    }, options);
};

// 클래스 클래스 나가기
export const finish_live = ({ liveSeq, userSeq, mixedClassSeq, serverUserSeq }) => {
    //console.log('api) finish live');
    return axios.delete(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}/${userSeq}?kind=finish&mode=web`, options);
};

// 믹스드 클래스 클래스 입장 요청
export const request_join_mixed_class_live = ({ liveSeq, userSeq, userNickname, mixedClassSeq }) => {
    console.log(`api:) request join mixed class live - liveSeq[${liveSeq}], userSeq[${userSeq}], userNickname[${userNickname}], mixedClassSeq[${mixedClassSeq}]`);
    return axios.post(`${ConstData.REST_API_HOST_ADD}/live/join/${liveSeq}/${userSeq}?kind=mixed-join&mode=web&mixedClassSEQ=${mixedClassSeq}`, {
        userNickname
    }, options);
};

// 믹스드 클래스 클래스 나가기 요청
export const request_finish_mixed_class_live = ({ liveSeq, userSeq, mixedClassSeq }) => {
    return axios.delete(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}/${userSeq}?kind=mixed-finish&mode=web&mixedClassSEQ=${mixedClassSeq}`, options);
};

// 클래스 방송 시작
export const start_live = ({ userSeq }) => {
    //console.log('api ) start live', userSeq);
    return axios.put(`${ConstData.REST_API_HOST_ADD}/live/${userSeq}?kind=start`, {}, options);
};

// 클래스 방송 멈춤
export const stop_live = ({ userSeq }) => {
    //console.log('api ) stop live');
    return axios.put(`${ConstData.REST_API_HOST_ADD}/live/${userSeq}?kind=stop`, {}, options);
};

// hands up image 1초에 한번씩 보내기
export const send_handsup_image = ({ data, filename, liveSeq, teacherSeq, userSeq, groupSendKey }) => {
    if (liveSeq >= 0 && teacherSeq >= 0 && userSeq >= 0) {
        const form = new FormData();
        form.append('image', data, filename);
        form.append('groupSendKey', groupSendKey);
        return axios.post(`${ConstData.REST_API_HOST_ADD}/handsup/${liveSeq}/${teacherSeq}/${userSeq}`, form, options);
    }
};

export const change_handsup_active_status = ({ liveSeq, userSeq, studioSeq, activeYN }) => {
    //console.log('api ) change_handsup_active_status - ', liveSeq, userSeq, studioSeq, activeYN);
    let data = { userSEQ: userSeq };
    if (studioSeq !== undefined && studioSeq !== null) {
        data.studioSEQ = studioSeq;
    }

    return axios.put(`${ConstData.REST_API_HOST_ADD}/handsup/${liveSeq}?kind=active&activeKind=${activeYN}`, data, options);
};

// broadcast oepn
export const broadcast_open = ({ broadcastSeq, liveSeq, userSeq, groupLimit }) => {
    return axios.put(`${ConstData.REST_API_HOST_ADD}/live/broadcast?kind=open`, {
        broadcastSEQ: broadcastSeq,
        liveSEQ: liveSeq
    }, options);
};

// QR 로그인 시, 클래스 멤버 목록 가져오는 API 
export const get_live_member_list_by_qrLogin = ({ liveSeq, mode }) => {
    let protocol = window.location.protocol;
    if (ConstData.IS_LOCAL_VERSION && protocol === 'http:') {
        let newRestAPIUrl = ConstData.REST_API_HOST_ADD;
        newRestAPIUrl = newRestAPIUrl.replace('https:', 'http:');
        newRestAPIUrl = newRestAPIUrl.replace(':8001', ':8000');
        return axios.get(`${newRestAPIUrl}/live/${liveSeq}?kind=qr-login&mode=${mode}`, options);
    } else {
        return axios.get(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}?kind=qr-login&mode=${mode}`, options);
    }
}

export const get_live_info_by_cookies = ({ liveSeq, mode }) => {
    return axios.get(`${ConstData.REST_API_HOST_ADD}/live/${liveSeq}?kind=cookies-login&mode=${mode}`, options);
}

// ********************************************************************************** //