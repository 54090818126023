import React from 'react';

const AllCoveredModal = ({ children}) => {
    const cnt = React.Children.count(children);
    const childs = React.Children.toArray(children);

    //console.log("AllCoveredModal cnt => ", cnt);

    return(
        <div className="hiclasstv-modal all-covered">
            {
                cnt !== 1 && 
                <div className="hiclasstv-modal-all-covered-alert">
                    {childs[0]}
                </div>
            } {
                cnt !== 1 ?
                    childs[1] : 
                    childs[0]
            }
        </div>
    )
}

export default AllCoveredModal;