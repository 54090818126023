import React, { useEffect } from "react";
import { MDBNavItem } from "mdbreact";

import { enAdminTap } from "../../../lib/ConstCommand";

const WebHeaderManageMenu = ({ isMobile, isConfigAdmin, isHiClassDomain, isMediasoupAdmin, isAdminPage, hiclasstvInfo, tossAndHandleSelectedFunc }) => {

    useEffect(() => {
        if (isMobile) {
            let navbar = document.getElementById("navbar");
            let totalWidth = navbar.offsetWidth;
            let baseWidth = navbar.parentElement.offsetWidth;

            let offset = -1;

            if (isAdminPage) {
                switch (hiclasstvInfo.currentAdminTap) {
                    case enAdminTap.Home:
                        offset = document.getElementById("tool_manage").offsetLeft;
                        break;

                    case enAdminTap.Lecture:
                        offset = document.getElementById("tool_manage_lecture").offsetLeft;
                        break;    
    
                    case enAdminTap.LocalServer:
                        offset = document.getElementById("tool_manage_local_server").offsetLeft;
                        break;

                    case enAdminTap.LocalSite:
                        offset = document.getElementById("tool_manage_local_site").offsetLeft;
                        break;

                    case enAdminTap.LiteLocalServer:
                        offset = document.getElementById("tool_manage_lite_server").offsetLeft;
                        break;

                    case enAdminTap.Studio:
                        offset = document.getElementById("tool_manage_studio").offsetLeft;
                        break;
                    
                    case enAdminTap.Media:
                        offset = document.getElementById("tool_manage_media").offsetLeft;
                        break;

                    default:
                        break;
                }
            } else {
                offset = offset = document.getElementById("tool_manage").offsetLeft;
            }

            let position = -1;

            if (totalWidth - offset < baseWidth) {
                position = totalWidth - baseWidth;
            } else {
                position = offset - 12;
            }

            navbar.parentElement.scrollTo(position, 0);
        }
    }, [isMobile, isAdminPage, hiclasstvInfo]);

    return (
        isMobile === true ?
            <>
                <li className="nav_item">
                    <span className={((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.Home) ? "" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_HOME")}>DOMAIN</span>
                </li>
                {/*<li className="nav_item">
                    <span className={((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.Lecture) ? "" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_LECTURE")}>LECTURE</span>
    </li>*/}
                {
                    isConfigAdmin && isMediasoupAdmin &&
                    <>
                        {
                            isHiClassDomain &&
                            <>
                                <li className="nav_item" id="tool_manage_local_server">
                                    <span className={((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.LocalServer) ? "" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_LOCAL_SERVER")}>LOCAL SERVER</span>
                                </li>
                                <li className="nav_item" id="tool_manage_media">
                                    <span className={((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.LocalSite) ? "" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_LOCAL_SITE")}>LOCAL SITE</span>
                                </li>
                                <li className="nav_item" id="tool_manage_lite_server">
                                    <span className={((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.LiteLocalServer) ? "" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_LITE_SERVER")}>LITE LOCAL SERVER</span>
                                </li>
                                <li className="nav_item" id="tool_manage_studio">
                                    <span className={((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.Studio) ? "" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_STUDIO")}>STUDIO</span>
                                </li>
                            </>
                        }
                        <li className="nav_item" id="tool_manage_media">
                            <span className={((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.Media) ? "" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_MEDIA")}>MEDIA</span>
                        </li>
                    </>
                }
            </> :
            <>
                <MDBNavItem className="mx-2">
                    <button className={"hiclasstv-btn-transparent my-0" + ((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.Home) ? " " : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_HOME")}>
                        <b>DOMAIN</b>
                    </button>
                </MDBNavItem>
                {/*<MDBNavItem className="mx-2">
                    <button className={"hiclasstv-btn-transparent my-0" + ((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.Lecture) ? " " : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_LECTURE")}>
                        <b>LECTURE</b>
                    </button>
            </MDBNavItem>*/}
                {
                    isConfigAdmin && isMediasoupAdmin &&
                    <>
                        {
                            isHiClassDomain &&
                            <>
                                <MDBNavItem className="mx-2">
                                    <button className={"hiclasstv-btn-transparent my-0" + ((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.LocalServer) ? " " : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_LOCAL_SERVER")}>
                                        <b>LOCAL SERVER</b>
                                    </button>
                                </MDBNavItem>
                                <MDBNavItem className="mx-2">
                                    <button className={"hiclasstv-btn-transparent my-0" + ((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.LocalSite) ? " " : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_LOCAL_SITE")}>
                                        <b>LOCAL SITE</b>
                                    </button>
                                </MDBNavItem>
                                <MDBNavItem className="mx-2">
                                    <button className={"hiclasstv-btn-transparent my-0" + ((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.LiteLocalServer) ? " " : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_LITE_SERVER")}>
                                        <b>LITE LOCAL SERVER</b>
                                    </button>
                                </MDBNavItem>
                                <MDBNavItem className="mx-2">
                                    <button className={"hiclasstv-btn-transparent my-0" + ((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.Studio) ? " " : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_STUDIO")}>
                                        <b>STUDIO</b>
                                    </button>
                                </MDBNavItem>
                            </>
                        }
                        <MDBNavItem className="mx-2">
                            <button className={"hiclasstv-btn-transparent my-0" + ((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.Media) ? " " : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_MEDIA")}>
                                <b>MEDIA</b>
                            </button>
                        </MDBNavItem>
                    </>
                }
            </>
    );
}

export default WebHeaderManageMenu;