export const enTabPacketType = {
    Text        : 0,
    Binary      : 1
};

export const remoconType = {
    None            : -1,
    Ink             : 0,
    HandsUP         : 1,
    TeamUp          : 2,
    PullScreen      : 3,
    PushScreen      : 4,
    SoundControl    : 5,
    ScreenControl   : 6
};

export const enDrawingMode = {
    Pen             : 0,
    Pencil          : 1,
    Marker          : 2,
    Selector        : 3,
    Hand            : 4,
    EraseStroke     : 5,
    ErasePobyte     : 6  
};

export const enTabStatus = {
    Disconnected    : 0,
    Connected       : 1,
    Already         : 2,
    NoUser          : 3
};

export const enMQType = {
    None        : 0,
    Video       : 1,
    Audio       : 2,
    InkMedia    : 3,
    Files       : 4,
    Office      : 5,
    Download    : 6,
    Voice       : 7
};

export const ctBubbleKind = {
    Info        : 0,
    RcvInfo     : 1,
    SendMsg     : 2,
    RcvMsg      : 3,
    SendWhisper : 4,
    RcvWhisper  : 5,
    SendWatson  : 6,
    RcvWatson   : 7,
    StartWatson : 8,
    StopWatson  : 9
};

export const ctRcvKind = {
    Teacher     : 0,
    Student     : 1,
    Team        : 2,
    SendWatson  : 3,
    RcvWatson   : 4,
    sendPentalk : 5,
    rcvPentalk  : 6,
    infoPentalk : 7,
    sendWhisper : 8,
    rcvWhisper  : 9,
    Card        : 10,
};

export const ctMsgInfoKind = {
    InkMemo     : 0,
    VoiceMemo   : 1,
    FileShare   : 2,
    PageJpeg    : 3
};
  
export const enTokenCMD = {
    NULL                    :0,             //O default로 Token을 만들때 NULL 이 되면 아무것도 하지 않게 되니까 Logic 구성시에 성능 업이 된다.
    
    //ClassTalk/P2P 전송 제어
    ct_Poll                 :1,             //… 나한테 모든것을 맞추도록 하는것과 상대방에게 그렇게 되었다는 답을 강요
    ct_Response             :2, 	        //… Poll 에 대해 내 정보를 Return 한다.
    ct_Visibility           :3, 	        //… CrossTalk 창 전체의 Visibility를 맞춘다.
    ct_Login                :4,		        //… 내가 Login 되었음을 알린다.
    ct_LoginResp            :5,             //...170101 Login Confirm 정보...이걸 받아야만 처리되게 한다.
    ct_Logout               :6,		        //… 내가 Logout 되었음을 알린다.
    ct_LogoutResp           :7,             //...170101 LogOut Confirm 정보

    // Ink 제어 ... 170101 위 제어 명령의 확장을 위하여 아래는 10번부터 시작한다....
    Ink_SDn                 :10,            //1O   SystemPen Down < Stylus, Mouse, Touch….. >
    Ink_SM                  :11,            //O	… SystemPen Move
    Ink_SDM                 :12,            //O	… SystemPen Move with Double Points
    Ink_STM                 :13,            //O	… SystemPen Move with Triple Points
    Ink_SUp                 :14,            //O	… SystemPen Up

    Ink_DDn                 :15,            //O	… DigitalPen Down < SmartPen … Max 35 >
    Ink_DM                  :16,            //O	… DigitalPen Move
    Ink_DDM                 :17,            //O	… DigitalPen Move with Double Points
    Ink_DTM                 :18,            //O	… DigitalPen Move with Triple Points
    Ink_DUp                 :19,            //O	… DigitalPen Up

    Ink_TDn                 :20,            //O… TabletPen Down < Tablet 에서 HiClass 로 들어온 Ink… >
    Ink_TM                  :21,	        //O… TabletPen Move
    Ink_TDM                 :22,	        //O… TabletPen Move with Double Points
    Ink_TTM                 :23,	        //O… TabletPen Move with Triple Points
    Ink_TUp                 :24,	        //O… TabletPen Up
    Ink_Erase1              :25,		    //O… Stroke 하나를 지우는 Token

    // Stroke 제어
    Ink_SingleStroke        :26,        //...InkStroke 하나를 만드는 Token.
    Ink_StrokeCollection    :27,        //...Ink Stroke Collection
    Ink_PageStroke          :28,		//… 현재 페이지의 모든 Ink를 Encoding 하여 Binary Token으로 생성
    Ink_Clear               :29,		//… 현재 페이지의 모든 Stroke 을 지운다.

    Ink_DefaultAttr         :30,     //200710 현재 InkPage의 Mode, Color, Tip 을 한번에 전송한다.
    Ink_Mode                :31,        //… 현재 펜의 Mode를 바꾼다.	< Ink, Marker, Select, EraseByStroke, None >
    Ink_Color               :32,		//… 현재 Ink의 색을 바꾼다. <R><G><B>
    Ink_Tip                 :33,		//… 현재 Ink 의 두께를 바꾼다.
    Ink_Curve               :34,		//… 현재 Ink Rendering 에서 커브화를 할것인지를 나타낸다.
    Ink_RemoveStrokes       :35,        // InkMedia의 Play가 종료되고나면 이 명령에 의해 화면에서 재생된 Stroke들만 찾아서 지운다.

    // Page 제어
    Page_Goto               :36,        //… 특정 페이지로 이동한다.
    Page_Sync               :37,        //… 현재 페이지의 위치와 크기(Zoom) 을 맞춘다.
    Page_TouchScroll        :38,        //… Mouse Wheel 등으로 Scroll 하는 명령
    Page_HandScroll         :39,        //… Mouse Hand로 (특히 칠판) 로 Scroll 하는 명령
    page_PutArtifact        :40,        //...170102 사용자의 현재 InkPage Bitmap을 다른 사용자에게 전송하고, 받은자는 이를 PDF Page의 배경 이미지(artifact)로 저장한다.
    page_GetArtifact        :41,        //...170102 특정 사용자의 현재 InkPage Bitmap을 가져와서 이를 PDF Page의 배경 이미지(artifact)로 저장한다.

    //Contents 제어 … multi-token 사용으로 하나의 전송이 완료되기 전까지 multi-action을 하지 못함.
    cont_PageJpeg           :42,        //… 현재 페이지를 JPEG으로 Capture 하여 보내고 상대방은 이걸 그냥 보드 화면에 꽉차게 넣는다.
    cont_GetPageJpeg        :43,        //… 상대방의 화면을 cont_PageJPeg으로 나에게 보내게 한다.   [OLD]Dicaboard SmartPhone 의 촬영 Animation을 보낸다.
    cont_StudentPageJpeg    :44,        // 학생생화면을 jpeg으로 전송하여 보낸다. 이거는 그냥 펜톡화면에 이미지로 나타난다. 누르면 그때 이미지아노테이션으로 페이지에 삽입된다.
    cont_GetStudentPageJpeg :45,		//… Dicaboard SmartPhone 에서 촬영 확정된 Image를 보낸다.

    cont_PlayMusic          :46,		//… mp3 음성파일을 보내고 나서 자동으로 Play 한다.
    cont_PlayInkMedia       :47,	    //… ImkMedia 파일을 보내고 나서 자동으로 Play 한다.
    cont_PlayPD             :48,		//… PDF 파일을 보내고 나서 자동으로 그 문서를 HiClass 에 열도록 한다.

    chat_FileName           :49,		//… 전송할 파일 이름을 보낸다.
    chat_FileTransfer       :50,	    //… 파일을 전송한다.
    chat_Text               :51,	    //… Text Chatting
    chat_Voice              :52,		//… Voice Chatting
    chat_Memo               :53,        //… Memo Chatting using Pen
    chat_Emoticon           :54,		//… 이모티콘 넘버에 의해 해당 이모티콘이 나타나게 한다.

    //HiClass 제어
    ctl_TPadMove            :55,        //... TPAD 를 움직이면 모든 HiClass 의 TPAD가 움직이게 된다.
    ctl_OnHiPaper           :56,        //... 상대방 HiClass 나 HiNote 에게 HiPaper Mode로 강제로 들어가게 한다.
    ctl_OffHiPaper          :57,        //    상대방 HiClass 나 HiNote 에게 HiPaper Mode에서 강제로 나오게 한다.
    ctl_InkEnable           :58,        //170123 true 면 OnAir, false 면 Ink를 전송하지 않는다.
    ctl_PageInfo            :59,        //170123 현재 HiClass의 페이지 정보를 Client에 전송한다.

    //HiNote, DicaBote 모둠수업 제어...
    modum_PutArtifacts              :60,    //170103 서버에서 모둠 수업용 페이지가 내려옴....자동으로 해당 Page가 Locking됨....HiPaper Mode
    modum_AutoSavePDF               :61,    //170103 서버에서 현재 페이지를 저장하게 함...자동으로 Page 원래대로 해제....Dicaboard/DicaNote Mode
    modum_ScreenMode                :62,    //170130 서버에서 Screen Streaming Mode로 들어감....따라서 DicaNote나 HiNote등 Client들은 메뉴 조정을 해야함...

    //170117 버튼 명령
    control_Button                  :63,    // 페이지 이동, 펜 변경등 버튼 Command

    //170310 RemoteConsole Touch Device
    remocon_Down                    :64,    // Tablet Touch Down
    remocon_Move                    :65,    // Tablet Touch Move
    remocon_Up                      :66,    // Tablet Touch Up
    remocon_RightButton             :67,    // Tablet RightButton
    remocon_DoubleClick             :68,    // Tablet Double Touch

    remocon_StreamMode              :69,    // 170321 HiClass 의 선생님 태블릿에서 HiClass 를 영상모드로 전환하는데 사용...
    page_GetArtifactWithInks        :70,    //...170102 특정 사용자의 현재 InkPage Bitmap을 가져와서 이를 PDF Page의 배경 이미지(artifact)로 저장한다.

    webrtc_ChangeLessonMode         :71,    //170831...Collabo 에서만 사용하고, 상대방의 Lesson Mode를 무조건 나를 기준으로 변경한다....
    webrtc_MyVideoDevice            :72,    //170921...Collabo에서만 사용하고, 현재 내 VideoDevice 즉 HiClass/Webcam/MIC 중 어느것으로 보내고 있는지를 상대방에게 알려준다.
    webrtc_StartEndStreaming        :73,    //170923...상대방의 Streaming을 시작하거나 종료하게 할 수 있다.

    webrtc_PopupMessage             :74,   //171026 MessageBox로 상대방으로 부터 온 메시지를 확인할 수 있게 한다.
    webrtc_RefreshCollaboConnect    :75,   //171107 [Internal Use Only] 상대방의 Collabo Receiver 를 끊었다가 다시 시작한다.

    //---- HiClass Modoom 을 위하여 12.16일 새로 들어간 기능들...WIFI 에서만 쓰이는데 이거는 전부 확장해도 좋은 기능들이라...
    cont_PushCurrentScreen          :76,   //171226 상대방의 [윈도우화면:Hotkey] 이나 현재 InkPage를 jpg Stream으로 보내게 한다.
    ct_PaperDirection               :77,   //171226 HiClass의 DocumentPage의 PageDirection을 보낸다.

    //----180404 Google Assistant 나 Alexa 또는 Windows 에서 HiClass를 음성명령으로 제어하는 Token 
    GG_TextCommand                  :80, 

    //----180925 채팅방에 대한 정보들을 주고 받는다...JHK 그런데 왜 필요하지???
    ct_UserStatusChange             :81,    //로그인 체팅방 로그인 여부 정보
    ct_RoomMemberNumberInfo         :82,    //채팅반 인원수 정보
    ct_LoginUserUnreadChatCnt       :83,    //로그인 사용자 안읽은 채팅수 정보

    //----181025 파일을 Upload하고 거기서 Download 받아서 하는걸로 파일공유 방법을 바꾼다.
    ct_UploadAndShareFiles          :84,
    ct_HelloGetAudio                :85,    //181118 상대방들의 스피커 onoff 현황을 가져온다. "1010" => 0SndPrev:On, 1rcv:Off, 2rcv:On, 3rcv:off
    ct_HelloSetAudio                :86,    //181118 먼저 상대방 SenderPreview의 Audio Preview를 제거하고 "1010" 즉 오디오 데이터를 Return 한다.
    ct_HelloRspAudio                :87,    //HelloRcvAudio/HelloClrAudio 등에 응답하는 Audio 정보 token

    ct_SendToWebJSonInfo            :88,    //190501 [88] Json 형식 명령전달  ---20190418 김주희 추가
    ct_AndroidPageJPEG              :89,    //Android 가 화면잡는것이 안되어 Background에서 잡아서 보내는데 여기서 ImageSource를 만든다.

    ct_ScreenUploadWindow           :90,

    //----220628 핸즈업 이미지 for Pentalk HandsUp
    ct_UpdateHandsUpImage           :91,    //이미지 데이터를 받아 이미지를 갱신한다... ---220628 김혜정 추가 
    rq_DefaultInkAttr               :92,	//230817 클라이언트가 Pencam 에게 Default Ink Attr을 요구한다.
    rq_Ink_PageStroke               :93,    //230817 클라이언트가 Pencam 에게 Default Ink Attr을 요구한다.

    req_ScreenSize                  :94,    //230829 클라이언트가 Pencam에게 현재 모니터의 크기를 요구한다.
    ScreenSize                      :95,    // req_ScreenSize 에 대한 응답 토큰이다.
    ScreenChange                    :96,
    rq_PageClear                    :97,    //231120 Pentalk과 Pencam 이 연결 해제될 경우 페이지 잉크&배경 이미지를 클리어한다. ---김혜정 추가 

    //--------------------------  99 부터는 Error를 처리하는 Pkt Command로 사용한다.
    Err_Token                       :99,    //170523 마지막 한 Byte가 오류나 승인등 서로 Confirm이 필요한 경우에 주고 받는 Token ACK/NACK처리에 사용...
};