import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';
import Alerts from '../../etc/list/Alerts';

import ConstData from '../../../lib/ConstData';

const SettingLocalInfoModal = ({ isOpened, savedLocalServerSeq, checkAuthCodeResult, handleSelectedFunc, saveCookiesAboutLocalInfo }) => {

    //const [lLocalServerSeq, setLocalServerSeq] = useState(localServerInfo.serverSeq);
    const [isSetUpLocalServerInfo, setIsSetUpLocalServerInfo] = useState(savedLocalServerSeq >= 0 ? true : false);
    const [isSettingMode, setIsSettingMode] = useState(savedLocalServerSeq >= 0 ? false : true);
    const [lLocalServerAuthCode, setLocalServerAuthCode] = useState(null);
    const [lAlertMessage, setAlertMessage] = useState("");
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);

    useEffect(() => {
        if (checkAuthCodeResult.isCorrectAuthCode && checkAuthCodeResult.localServerSeq >= 0 && checkAuthCodeResult.localServerName && checkAuthCodeResult.localServerName !== '') {
            setIsSetUpLocalServerInfo(true);
            //setIsSettingMode(false);
        } else {
            setIsSetUpLocalServerInfo(false);
            //setIsSettingMode(true);
        }
    }, [savedLocalServerSeq, checkAuthCodeResult.isCorrectAuthCode, checkAuthCodeResult.localServerSeq, checkAuthCodeResult.localServerName]);

    useEffect(() => {
        setAlertMessage(checkAuthCodeResult.alertMessage);
    }, [checkAuthCodeResult.alertMessage]);

    useEffect(() => {
        setAlertKind(checkAuthCodeResult.alertKind);
    }, [checkAuthCodeResult.alertKind]);

    const checkAuthCodeInputValue = () => {
        if (lLocalServerAuthCode === undefined || lLocalServerAuthCode === null || lLocalServerAuthCode === '') {
            setAlertMessage("인증코드를 입력하세요.");
            setAlertKind(ConstData.ALERT_KIND.DANGER);
        } else {
            handleSelectedFunc({ selectedFunc: "REQUEST_AUTH_CODE_CHECK", value: lLocalServerAuthCode });
        }
    }

    const checkLocalServerInfoValue = () => {
        console.log(`checkLocalServerInfoValue - localServerSeq[${checkAuthCodeResult.localServerSeq}]`);
        if (checkAuthCodeResult.localServerSeq >= 0) {
            saveCookiesAboutLocalInfo({ kind: "localServerSeq", value: checkAuthCodeResult.localServerSeq });
        } else {
            handleSelectedFunc({ selectedFunc: "SETTING_LOCAL_INFO_TOGGLE" });
        }
    }

    const resetLocalServerInfo = () => {
        setIsSettingMode(true);
        setIsSetUpLocalServerInfo(false);
    }

    const onChangeLocalServerAuthCode = (e) => {
        if (lAlertKind !== ConstData.ALERT_KIND.NONE || lAlertMessage !== "") {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage("");
        }
        setLocalServerAuthCode(e.target.value);
    }

    const onClosedModal = () => {
        setAlertKind(ConstData.ALERT_KIND.NONE);
        setAlertMessage("");
        setLocalServerAuthCode(null);
    }

    return (
        <Modal isOpen={isOpened} centered backdrop={true} toggle={() => handleSelectedFunc({ selectedFunc: "SETTING_LOCAL_INFO_TOGGLE" })} onClosed={onClosedModal}>
            <div className="card py-1 px-2 align-items-center justify-content-center">
                    <div className='title-area justify-content-end text-right mb-2 p-0'>
                        <button type="button" className="hiclasstv-btn p-0" title="닫기" onClick={() => handleSelectedFunc({ selectedFunc: "SETTING_LOCAL_INFO_TOGGLE" })}>
                            <FaTimes className="d-flex m-1 black-text" />
                        </button>
                    </div>
                    <div className="content-area transparent dark-grey-text">
                        {
                            isSettingMode ?
                                <div className="input-area">
                                    <span className="input-label small px-1 area-25">
                                        <b>로컬 서버 인증코드</b>
                                    </span>
                                    <div className="input-data">
                                        <input
                                            type="text"
                                            className="small"
                                            placeholder={lLocalServerAuthCode && lLocalServerAuthCode !== '' ? lLocalServerAuthCode : '관리자에게 부여받은 인증코드를 입력해 주세요.'}
                                            onChange={onChangeLocalServerAuthCode} />
                                    </div>
                                </div> : 
                                <div className="text-area text-center">
                                    <span className="small mb-2">
                                        <b>이미 로컬 서버 정보가 설정되어 있습니다.</b>
                                    </span>
                                    <span className="small mb-2">
                                        재설정을 원할 경우 [재설정] 버튼을 눌러주세요.
                                    </span>
                                </div>
                        }
                        <Alerts kind={lAlertKind} message={lAlertMessage} style={{ fontSize: '80%' }} />
                        {
                            isSettingMode ? 
                                isSetUpLocalServerInfo ? 
                                <button className="btn btn-primary btn-sm" onClick={checkLocalServerInfoValue}>
                                    확인
                                </button> :
                                <button className="btn btn-primary btn-sm" onClick={checkAuthCodeInputValue}>
                                    인증
                                </button> :
                                <button className="btn btn-primary btn-sm" onClick={resetLocalServerInfo}>
                                    재설정
                                </button>
                        }
                        {/* {
                            isSettingMode ?
                                <button className='btn btn-primary btn-sm' onClick={checkAuthCodeInputValue}>
                                    인증
                                </button> :
                            isSetUpLocalServerInfo ?
                                <button className='btn btn-primary btn-sm' onClick={checkLocalServerInfoValue}>
                                    확인
                                </button> :
                                <button className='btn btn-primary btn-sm' onClick={() => setIsSettingMode(false)}>
                                    재설정
                                </button>
                        } */}
                    </div>
            </div>
            {/* <div className='card p-2 align-items-center justify-content-center'>
                <div className='title-area justify-content-end text-right mb-3 p-0'>
                    <button type="button" className="hiclasstv-btn p-0" title="닫기" onClick={() => handleSelectedFunc({selectedFunc: "SETTING_LOCAL_INFO_TOGGLE"})}>
                        <FaTimes className="d-flex m-1 black-text" />
                    </button>
                </div>
                <div>
                    
                </div>
                {
                    isSettingMode ?
                        <div className='input-area mt-2'>
                            <span className="input-label pr-2 small area-35">
                                <b>로컬 서버 인증코드</b>
                            </span>
                            <div className='input-data'>
                                <input
                                    type="text"
                                    placeholder={lLocalServerAuthCode && lLocalServerAuthCode !== '' ? lLocalServerAuthCode : '관리자에게 부여받은 인증코드를 입력해 주세요.'}
                                    onChange={onChangeLocalServerAuthCode} />
                            </div>
                        </div> :
                        <div className='input-area mt-2'>

                        </div>
                }
                
                <Alerts kind={lAlertKind} message={lAlertMessage} />
                {
                    isSettingMode ? 
                    <button className='btn btn-primary btn-sm' onClick={checkAuthCodeInputValue}>
                        인증
                    </button> :
                    isSetUpLocalServerInfo ? 
                    <button className='btn btn-primary btn-sm' onClick={checkLocalServerInfoValue}>
                        확인
                    </button> :
                    <button className='btn btn-primary btn-sm' onClick={() => setIsSettingMode(false)}>
                        재설정
                    </button>
                }
            </div> */}
        </Modal>
    );
}

export default SettingLocalInfoModal;