import React, { useState, useEffect } from 'react';
import { BsX } from 'react-icons/bs';

import ConstData from '../../../lib/ConstData';
import Alerts from '../../etc/list/Alerts';

const CreateLive = ({ isCertifiedLiveCode, createdLiveCode, alertMessage, alertKind, handleSelectedFunc }) => {

    const [liveCode, setLiveCode] = useState("클래스 코드");
    const [liveName, setLiveName] = useState("클래스 이름을 입력하세요");
    const [lAlertMessage, setAlertMessage] = useState("");
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [lIsCertifiedLiveCode, setIsCertifiedLiveCode] = useState(isCertifiedLiveCode);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    useEffect(() => {
        setIsCertifiedLiveCode(isCertifiedLiveCode);
    }, [isCertifiedLiveCode]);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    useEffect(() => {
        setLiveCode(createdLiveCode);
    }, [createdLiveCode]);
    
    const handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            checkCreateLive();
        }
    }

    const onChangeLiveCode = (e) => {
        if (lIsCertifiedLiveCode) {
            handleSelectedFunc({ selectedFunc:"TOGGLE_CERTIFIED_CODE" });
        }

        let str = checkInputValue(e.target.value);
        if (str.length > 10) {
            str = str.substr(0, 10);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage('코드는 10자 이내로 입력해야 합니다.');
        }
        setLiveCode(str);
    }

    const onChangeLiveName = (e) => {
        let str = e.target.value;
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage("");
        }

        if (str.length > 50) {
            str = str.substr(0, 50);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage('클래스 이름은 50자 이내로 입력해야 합니다.');
        }

        setLiveName(str);
    }

    const checkCreateLive = () => {
        if (lIsCertifiedLiveCode) { // 클래스 코드 중복체크 또는 자동생성 성공한 경우
            if (liveName === "" || liveName === undefined || liveName === null) {
                setAlertMessage("클래스 이름을 입력하십시오.");
                setAlertKind(ConstData.ALERT_KIND.DANGER);
            } else {
                handleSelectedFunc({ selectedFunc: "CREATE_OK", liveName: liveName, liveCode: liveCode });
            }
        } else { // 클래스 코드 중복체크 또는 자동생성을 하지 않은 경우
            if (liveCode === "" || liveCode === undefined || liveCode === null) {
                setAlertMessage("클래스 코드를 입력하십시오.");
                setAlertKind(ConstData.ALERT_KIND.DANGER);
            } else {
                setAlertMessage("클래스 코드 중복체크를 해 주십시오");
                setAlertKind(ConstData.ALERT_KIND.DANGER);
            }
        }
    }

    const checkInputValue = (str) => {
        let convertStr = str.replace(' ', '');
        convertStr = convertStr.replace(/[^a-z0-9@_.-]/gi, '');
        return convertStr;
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area">
                <span>
                    <b>클래스 생성</b>
                </span>
                <span className="func-btn" style={{position:'absolute', right:'0'}} onClick={() => handleSelectedFunc({ selectedFunc:"NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="input-area mt-2">
                <span className="input-label pr-2 small area-35">
                    <b>클래스 이름</b>
                </span>
                <div className="input-data">
                    <input type="text" placeholder={liveName} onChange={onChangeLiveName} />
                </div>
            </div>
            {/* <div className="d-flex w-100 justify-content-end">
                <span className="input-description">- 1자~50자 이내로 입력</span>
            </div>
            <div className="d-flex w-100 justify-content-end">
                <span className="input-description">- 한글, 영문, 숫자, 특수문자 사용 가능</span>
            </div> */}
            <div className="d-flex w-100 justify-content-between">
                <span style={{ width: '35%', maxWidth: '80px' }}></span>
                <span className="input-description area-75">- 1자~50자 이내로 입력</span>
            </div>
            <div className="d-flex w-100 justify-content-between">
                <span style={{ width: '35%', maxWidth: '80px' }}></span>
                <span className="input-description area-75">- 한글, 영문, 숫자, 특수문자 사용 가능</span>
            </div>
            <div className="input-area">
                <span className="input-label pr-2 small area-35">
                    <b>클래스 코드</b>
                </span>
                <div className="input-data">
                    <input type="text" placeholder={liveCode} value={liveCode} onChange={onChangeLiveCode} onKeyPress={handleKeyPress} />
                    <div className="d-flex align-items-center fit-content">
                        <button type="button" className="btn btn-sm small hiclasstv-blue white-text m-0 px-2" onClick={() => handleSelectedFunc({selectedFunc:"GET_CODE"})}>자동생성</button>
                        <button type="button" className="btn btn-sm small hiclasstv-blue white-text m-0 mx-1 px-2" onClick={() => handleSelectedFunc({selectedFunc:"CHECK", liveCode:liveCode})} disabled={lIsCertifiedLiveCode === false && liveCode !== "" ? false : true}>중복체크</button>
                    </div>
                </div>
            </div>
            {/* <div className="d-flex w-100 justify-content-end">
                <span className="input-description">- 클래스 코드는 1자~10자 이내로 입력</span>
            </div>
            <div className="d-flex w-100 justify-content-end">
                <span className="input-description">- 영문, 숫자 사용 가능</span>
            </div> */}
            <div className="d-flex w-100 justify-content-between">
                <span style={{ width: '35%', maxWidth: '80px' }}></span>
                <span className="input-description area-75">- 1자~10자 이내로 입력</span>
            </div>
            <div className="d-flex w-100 justify-content-between">
                <span style={{ width: '35%', maxWidth: '80px' }}></span>
                <span className="input-description area-75">- 영문, 숫자 사용 가능</span>
            </div>
            <Alerts kind={lAlertKind} message={lAlertMessage} />
            <div className="d-flex justify-content-center align-items-center my-2">
                <button type="button" className="btn btn-sm small hiclasstv-blue white-text m-0 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc:"CREATE_OK", liveName: liveName, liveCode: liveCode })} disabled={lIsCertifiedLiveCode === true && liveName !== "" ? false : true}>생성</button>
            </div>
        
        </div>
    );
}

export default CreateLive;