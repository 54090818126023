import React, { useState, useEffect, createRef } from 'react';
import DatePicker from "react-datepicker";
import { FaTicketAlt, FaPlusCircle, FaSearch } from 'react-icons/fa';

import { term, termKind, kindType, prdtKind } from '../../lib/ConstCommand';

import "react-datepicker/dist/react-datepicker.css";

import WebAddPassProduct from './modal/WebAddPassProduct';
import WebRegistPass from './modal/WebRegistPass';
import WebUsePass from './modal/WebUsePass';
import WebFindUser from '../admin/modal/WebFindUser';
import CustomDatePicker from '../etc/button/CustomDatePicker';

import ko from 'date-fns/locale/ko';
import { format } from 'date-fns';
import WebRefundAlert from './modal/WebRefundAlert';

const WebAdminPassInfo = ({ list_configSubDomain, selectedSubDomainInfo, selectedUserInfo, list_pchsPass, list_prdtKindCode, list_prdtScaleCode, list_prdtTargetCode, list_prdtTermCode, list_prdtPass, searchResult, handleSelectedFunc }) => {
    /**
     * selectedSubDomainInfo
     * - selectedIdx
     * - subDomainSeq 
     * - subDomainName
     */

    var msg1 = "이용권 구매일로부터 5년";
    var msg2 = "해당 프로모션 종료시까지";

    var msg3 = " 이용권 유료 구매 고객";
    var msg4 = " 이용권 프로모션 행사 기준을 충족하는 고객";

    const [isAddPassProductModalOpened, setIsAddPassProductModalOpened] = useState(false);
    const [isRegistPassModalOpened, setIsRegistPassModalOpened] = useState(false);
    const [isUsePassModalOpened, setIsUsePassModalOpened] = useState(false);
    const [isFindUserModalOpened, setIsFindUserModalOpened] = useState(false);
    const [isRefundModalOpened, setIsRefundModalOpened] = useState(false);

    const [lUsePassInfo, setUsePassInfo] = useState(null);
    const [lSubDomainInfo, setSubDomainInfo] = useState(list_configSubDomain);
    const [lUserInfo, setUserInfo] = useState(selectedUserInfo);
    const [selectedSubDomainOption, setSelectedSubDomainOption] = useState(selectedSubDomainInfo);
    const [refundPrice, setRefundPrice] = useState(0);
    const [refundType, setRefundType] = useState('None');

    const [lTypeValue, setTypeValue] = useState('all');
    const [lTermValue, setTermValue] = useState('all');
    const [fromDT, setFromDT] = useState(new Date('2021-01-01'));
    const [toDT, setToDT] = useState(new Date());
    const fromDtRef = new createRef();
    const toDtRef = new createRef();

    const [lSearchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (list_configSubDomain === undefined || list_configSubDomain === null || list_configSubDomain.length === 0) {
            handleSelectedFunc({ selectedFunc: "GET_MANAGE_INFO" });
        }
        //let idx = 0;
        //let subDomainSeq = -1;
        //let subDomainName = "서비스 없음";

        //if (selectedSubDomainInfo === undefined || selectedSubDomainInfo === null || selectedSubDomainInfo.subDomainSeq === -1) {
            //handleSelectedFunc({ selectedFunc: "GET_MANAGE_INFO" });

            //if (lSubDomainInfo.length > 0) {
                //subDomainSeq = lSubDomainInfo[0].subDomainSeq;
                //subDomainName = lSubDomainInfo[0].subDomainName;
            //}
        //} else {
            //idx = selectedSubDomainInfo.selectedIdx;
            //subDomainSeq = selectedSubDomainInfo.subDomainSeq;
            //subDomainName = selectedSubDomainInfo.subDomainName;
        //}

        //setSelectedSubDomainOption({ idx, subDomainSeq, subDomainName });

        return () => {
            console.log('WebAdminPassInfo() component invisible');
        }
    }, []);

    useEffect(() => {
        if (list_configSubDomain !== undefined && list_configSubDomain !== null && list_configSubDomain.length > 0) {
            setSubDomainInfo(list_configSubDomain);
        }
    }, [list_configSubDomain]);

    useEffect(() => {
        if (lSubDomainInfo !== undefined && lSubDomainInfo !== null && lSubDomainInfo.length > 0) {
            setSelectedSubDomainOption({ idx: 0, subDomainSeq: lSubDomainInfo[0].subDomainSeq, subDomainName: lSubDomainInfo[0].subDomainName });
        }
    }, [lSubDomainInfo]);

    useEffect(() => {
        if (selectedSubDomainInfo !== undefined && selectedSubDomainInfo !== null && selectedSubDomainInfo.subDomainSeq !== -1) {
            setSelectedSubDomainOption({ idx: selectedSubDomainInfo.selectedIdx, subDomainSeq: selectedSubDomainInfo.subDomainSeq, subDomainName: selectedSubDomainInfo.subDomainName });
        }
    }, [selectedSubDomainInfo]);

    useEffect(() => {
        if (selectedSubDomainOption !== undefined && selectedSubDomainOption !== null) {
            if (selectedSubDomainOption.subDomainSeq !== -1) {
                if (selectedSubDomainOption.subDomainSeq === 1) {
                    setUserInfo(selectedUserInfo);
                    handleSelectedFunc({ selectedFunc: "INIT_PCHS_LIST" });
                } else {
                    setUserInfo({ userSeq: -1, userID: "", userNickname: "" });
                    handleLookupData({ term: lTermValue, kind: lTypeValue, subDomainSeq: selectedSubDomainOption.subDomainSeq });
                }
            }
        }
    }, [selectedSubDomainOption]);

    useEffect(() => {
        setUserInfo(selectedUserInfo);
    }, [selectedUserInfo]);

    useEffect(() => {
        if (lUserInfo !== undefined && lUserInfo !== null && lUserInfo.userSeq > -1) {
            handleLookupDataB2C({ term: lTermValue, kind: lTypeValue, subDomainSeq: 1, userSeq: lUserInfo.userSeq });
        }
    }, [lUserInfo]);

    const selectSubDomain = (e) => {
        setSelectedSubDomainOption({ idx: e.target.value, subDomainSeq: lSubDomainInfo[e.target.value].subDomainSeq, subDomainName: lSubDomainInfo[e.target.value].subDomainName });
    }

    const onChangeKindType = (e) => {
        setTypeValue(e.target.value);
    }

    const onChangeDatePicker = (kind, date) => {
        if (kind === 'from') {
            //console.log('onChangeDatePicker(from)');
            setFromDT(date);
            setTermValue(null);
        } else {
            //console.log('onChangeDatePicker(to)');
            setToDT(date);
            setTermValue(null);
        }
    }

    const onClickBtnTool = (value) => {
        if (value === termKind.ALL) {
            setTermValue('all');
            handleLookupData({ term: 'all', kind: lTypeValue, subDomainSeq: selectedSubDomainOption.subDomainSeq });
        } else {
            let date = new Date();
            date.setMonth(toDT.getMonth() - value);

            let fromValue = format(date, 'yyyyMMdd');
            let toValue = format(toDT, 'yyyyMMdd');
            let termValue = fromValue + '-' + toValue;

            setFromDT(date);
            setTermValue(termValue);
            handleLookupData({ term: termValue, kind: lTypeValue, subDomainSeq: selectedSubDomainOption.subDomainSeq });
        }
    }

    const handleLookupData = ({ term, kind, subDomainSeq }) => {
        if (subDomainSeq === 1) {
            if (lUserInfo !== undefined && lUserInfo !== null && lUserInfo !== "") {
                handleLookupDataB2C({ term, kind, subDomainSeq, userSeq: lUserInfo.userSeq });
            }
        } else {
            handleLookupDataB2B({ term, kind, subDomainSeq });
        }
    }

    const handleLookupDataB2B = ({ term, kind, subDomainSeq }) => {
        if (lSubDomainInfo.length > 0) {
            let svcSeq = subDomainSeq;

            if (svcSeq === undefined || svcSeq === null) {
                if (lSubDomainInfo !== undefined && lSubDomainInfo !== null) {
                    svcSeq = lSubDomainInfo.subDomainSeq;
                }
            }

            if (svcSeq !== undefined && svcSeq !== null) {
                if (term === undefined || term === null) {
                    let fromValue = format(fromDT, 'yyyyMMdd');
                    let toValue = format(toDT, 'yyyyMMdd');
                    let termValue = fromValue + '-' + toValue;
                    handleSelectedFunc({ selectedFunc: "GET_PCHS_LIST", term: termValue, kind: kind.toLowerCase(), selSubDomainSeq: svcSeq });
                } else {
                    handleSelectedFunc({ selectedFunc: "GET_PCHS_LIST", term, kind: kind.toLowerCase(), selSubDomainSeq: svcSeq });
                }
            } else {
                console.log('handleLookupDataB2B().... svcSeq => ', svcSeq);
            }
        }
    }

    const handleLookupDataB2C = ({ term, kind, subDomainSeq, userSeq }) => {
        if (term === undefined || term === null) {
            let fromValue = format(fromDT, 'yyyyMMdd');
            let toValue = format(toDT, 'yyyyMMdd');
            let termValue = fromValue + '-' + toValue;

            handleSelectedFunc({ selectedFunc: "GET_PCHS_LIST", term: termValue, kind: kind.toLowerCase(), selSubDomainSeq: subDomainSeq, selUserSeq: userSeq });
        } else {
            handleSelectedFunc({ selectedFunc: "GET_PCHS_LIST", term, kind: kind.toLowerCase(), selSubDomainSeq: subDomainSeq, selUserSeq: userSeq });
        }
    }

    // 유저 검색
    const toggleFindUserModal = (isModalOpened) => {
        setIsFindUserModalOpened(isModalOpened);
    }

    const checkSearchValue = () => {
        if (isFindUserModalOpened) {

        } else {

        }
        setIsFindUserModalOpened(!isFindUserModalOpened);
    }

    const onChangeSearchValue = (e) => {
        setSearchValue(e.target.value);
    }

    const handleSelectUser = ({ userSeq, userID, userNickname }) => {
        setSearchValue(userID);
        setUserInfo({ userSeq, userID, userNickname });
        handleLookupDataB2C({ term: lTermValue, kind: lTypeValue, subDomainSeq: 1, userSeq });
        toggleFindUserModal(false);
    }

    /////////////////////////////////////////////////////////////
    ////////////////////// 이용권 사용 //////////////////////////
    /////////////////////////////////////////////////////////////

    const onClickUsePass = (info) => {
        console.log('onClickUsePass => ', info);
        if (isUsePassModalOpened) {
            setUsePassInfo(null);
        } else {
            setUsePassInfo(info);
        }
        setIsUsePassModalOpened(!isUsePassModalOpened);
    }

    const toggleUsePassModal = (isModalOpened) => {
        console.log('toggleUsePassModal() - ', isModalOpened);
        setIsUsePassModalOpened(isModalOpened);
    }

    const handleUsePassDate = ({ pchsSeq, startUseDT, endUseDT }) => {
        console.log('handleUsePassDate() - ', pchsSeq, startUseDT, endUseDT);
        let stdate = format(startUseDT, 'yyyy.MM.dd');
        let enddate = format(endUseDT, 'yyyy.MM.dd');
        handleSelectedFunc({ selectedFunc: "USE_PASS", pchsSeq, useDateInfo: { startUseDT: stdate, endUseDT: enddate } });
        toggleUsePassModal(false);
    }

    ////////////////////////////////////////////////////////////
    ////////////////////// 상품 추가 ////////////////////////////
    ////////////////////////////////////////////////////////////

    const onClickAddPassProduct = (e) => {
        if (isAddPassProductModalOpened) {

        } else {

        }
        setIsAddPassProductModalOpened(!isAddPassProductModalOpened);
    }

    const toggleAddPassProductModal = (isModalOpened) => {
        console.log('toggleAddPassProductModal() - ', isModalOpened);
        setIsAddPassProductModalOpened(isModalOpened);
    }

    ////////////////////////////////////////////////////////////
    /////////////////////// 이용권 등록 /////////////////////////
    ////////////////////////////////////////////////////////////

    const onClickRegistPass = (e) => {
        if (isRegistPassModalOpened) {

        } else {

        }
        setIsRegistPassModalOpened(!isRegistPassModalOpened);
    }

    const toggleRegistPassModal = (isModalOpened) => {
        console.log('toggleRegistPassModal() - ', isModalOpened);
        setIsRegistPassModalOpened(isModalOpened);
    }

    const handleRegistData = ({ kind, prdtSeq, userInfo, subDomainInfo, prdtInfo }) => {
        if (prdtSeq > 0) {
            let date = new Date();
            let pchsDate = format(date, 'yyyy-MM-dd');
            if (kind === 'B2C') {
                handleSelectedFunc({ selectedFunc: "REGIST_PASS_B2C", prdtSeq, selUserSeq: userInfo.userSeq, prdtInfo, pchsDate });
            } else {
                handleSelectedFunc({ selectedFunc: "REGIST_PASS_B2B", prdtSeq, selSubDomainSeq: subDomainInfo.subDomainSeq, prdtInfo, pchsDate });
            }
            toggleRegistPassModal(false);
        } else {
            console.log('handleRegistData() - 존재하지 않는 prdtSeq....');
        }
    }

    const onClickRefund = (pchs_info) => {
        if (pchs_info.useDtInfo.strtDT === null && pchs_info.useDtInfo.endDT === null) {
            // 이용권 사용 전
            if (pchs_info.pchsOfficeType === 'PG') {
                //handleSelectedFunc({ selectedFunc: 'REFUND_PASS', pchsSeq: pchs_info.pchsSeq });
                setRefundPrice(pchs_info.prdtInfo.price);
                setRefundType('REFUND');
                setUsePassInfo(pchs_info);
                setIsRefundModalOpened(true);
            } else {
                console.log('pchsOfficeType => ', pchs_info.pchsOfficeType);
                // pg 결제가 아닌 경우.. 임시로 환불 불가 처리
                setRefundPrice(0);
                setRefundType('None');
                setUsePassInfo(pchs_info);
                setIsRefundModalOpened(true);
            }
        } else {
            // 이용권 사용 후
            let toDay = new Date();
            let strtDT = new Date(pchs_info.useDtInfo.strtDT);
            let endDT = new Date(pchs_info.useDtInfo.endDT);

            if (strtDT.getTime() - toDay.getTime() > 0) {
                // 이용권 사용 예약일이 시작되지 않은 경우 전체 환불
                setRefundPrice(pchs_info.prdtInfo.price);
                setRefundType('REFUND');
                setUsePassInfo(pchs_info);
                setIsRefundModalOpened(true);
            } else {
                let btMs = endDT.getTime() - strtDT.getTime();
                let btDay = btMs / (1000 * 60 * 60 * 24);

                if (btDay > 15 && pchs_info.prdtInfo.price > 0) {
                    // 15일 초과 남은 경우
                    let useMs = toDay.getTime() - strtDT.getTime();
                    let useDay = Math.ceil(useMs / (1000 * 60 * 60 * 24));
                    let price = pchs_info.prdtInfo.price;
                    let r_price = price - (price * (useDay / pchs_info.prdtInfo.termValue)) - (price * 0.1);
                    setRefundPrice(Math.floor(r_price));
                    setRefundType('P-REFUND');
                    setUsePassInfo(pchs_info);
                    setIsRefundModalOpened(true);
                } else {
                    // 15일 이하 남은 경우 환불 불가
                    setRefundPrice(0);
                    setRefundType('None');
                    setUsePassInfo(pchs_info);
                    setIsRefundModalOpened(true);
                }
            }
        }
    }

    return (
        <div className="web-main list-area">
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaTicketAlt className="font-lg mr-2" aria-hidden="true" />이용권 구매 내역
                        </span>
                        <button type="button" className="btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" onClick={() => onClickRegistPass()}>
                            <FaPlusCircle className="font-md mr-1" />이용권 등록
                        </button>
                        <button type="button" className="btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" onClick={() => onClickAddPassProduct()}>
                            <FaPlusCircle className="font-md mr-1" />상품 추가
                        </button>
                    </div>
                </div>
                <div className="px-2">
                    <div className="web-search-box">
                        <div className="web-search-option-area white">
                            <div className="web-search-option">
                                <div className="web-search-select-label">
                                    <span><b>서브 도메인</b></span>
                                </div>
                                <div className="web-search-select-box">
                                    <select className="custom-select small my-1" style={{ width: '100%', fontSize: '0.9rem' }} value={selectedSubDomainOption.idx} onChange={selectSubDomain} >
                                        {
                                            lSubDomainInfo === undefined || lSubDomainInfo === null || lSubDomainInfo.length <= 0 ?
                                                <option value={-1}>
                                                    관리 가능한 서브 도메인 목록이 없습니다.
                                                </option> :
                                                lSubDomainInfo.map(
                                                    (info, index) => (
                                                        <option value={index} key={"item-" + index}>{info.subDomainName}</option>
                                                    )
                                                )
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="web-search-option">
                                <div className="web-search-select-label">
                                    <span><b>사용자 ID</b></span>
                                </div>
                                <div className="web-search-select-box">
                                    <div className="d-flex input-group justify-content-center m-0">
                                        <input type="text" 
                                            className="d-flex flex-fill web-search-input my-1 mr-1 px-2 py-1" 
                                            style={{width:'70%'}}
                                            placeholder={lUserInfo.userID}
                                            value={lSearchValue}
                                            onChange={onChangeSearchValue}
                                            disabled={selectedSubDomainOption.subDomainSeq !== 1}
                                        />
                                        <div className="d-flex align-items-center input-group-append">
                                            <button type="button" className="d-flex hiclasstv-btn b-none rounded px-1 black-text" disabled={selectedSubDomainOption.subDomainSeq !== 1} onClick={checkSearchValue}>
                                                <FaSearch className="d-flex font-md m-1" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="web-search-option-area">
                            <div className="web-toolbar-menu toolbar-menu-btn" role="toolbar">
                                <div className="d-flex w-100 align-items-center justify-content-center">
                                    {
                                        term.map(
                                            (info, index) => (
                                                index === 0 ?
                                                    <button key={"tl-" + index} className="web-toolbar-item" value={info.value} onClick={() => onClickBtnTool(info.value)}>
                                                        전체
                                                    </button> :
                                                    <button key={"tl-" + index} className="web-toolbar-item" value={info.value} onClick={() => onClickBtnTool(info.value)}>
                                                        {info.name}
                                                    </button>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                            <div className="web-toolbar-menu toolbar-menu-date">
                                <DatePicker
                                    locale={ko}
                                    ref={fromDtRef}
                                    selected={fromDT}
                                    maxDate={new Date()}
                                    onChange={date => onChangeDatePicker('from', date)}
                                    popperModifiers={{ preventOverflow: { enabled: true } }}
                                    popperPlacement="auto"
                                    customInput={<CustomDatePicker ref={fromDtRef} dateInfo={fromDT} />}
                                />
                                <span className="mx-2">-</span>
                                <DatePicker
                                    locale={ko}
                                    ref={toDtRef}
                                    selected={toDT}
                                    minDate={fromDT}
                                    maxDate={new Date()}
                                    onChange={date => onChangeDatePicker('to', date)}
                                    popperModifiers={{ preventOverflow: { enabled: true } }}
                                    popperPlacement='auto'
                                    customInput={<CustomDatePicker ref={toDtRef} dateInfo={toDT} />}
                                />
                            </div>
                            <div className="web-toolbar-menu">
                                <div className="web-search-option p-0">
                                    <select className="custom-select small" style={{ fontSize: '0.9rem' }} value={lTypeValue} onChange={onChangeKindType}>
                                        {
                                            kindType.length > 0 ?
                                                kindType.map(
                                                    (info, index) => (
                                                        <option value={info.value} key={"type-" + index}>{info.name}</option>
                                                    )
                                                ) :
                                                <option value='all'>없음</option>
                                        }
                                    </select>
                                </div>
                                <div className="web-search-option p-0">
                                    <button className="btn btn-sm btn-default" style={{ width: '100%', margin: '0 0 0 .375rem', fontSize: '.8rem' }} type="button" onClick={() => handleLookupData({ term: lTermValue, kind: lTypeValue, subDomainSeq: selectedSubDomainOption.subDomainSeq })}>
                                        조회
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column w-100">
                        {
                            list_pchsPass !== undefined && list_pchsPass !== null && list_pchsPass.length > 0 ?
                                <div className="d-flex table-wrapper">
                                    <table className="d-flex flex-column table table-sm mb-0 pass-list">
                                        <tbody>
                                            {
                                                list_pchsPass.map(
                                                    info => (
                                                        <tr key={"pkey_" + info.pchsSeq} className="d-flex">
                                                            <td className="d-flex w-100">
                                                                <div className="pass-info-item web-list-title pchsPass">
                                                                    {
                                                                        info.prdtInfo.kindCode === prdtKind.Purchase ?
                                                                            <div className="d-flex flex-column">
                                                                                <span className="info-title">{"[구매] " + info.prdtInfo.name}</span>
                                                                                <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· 대상 : {info.prdtInfo.termName + msg3}</span>
                                                                                <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· 사용기한 : {msg1}</span>
                                                                                <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· 서비스 이용기한 : 사용 시작 지정일로부터 {info.prdtInfo.termName}</span>
                                                                            </div> :
                                                                            <div className="d-flex flex-column">
                                                                                <span className="info-title">{"[프로모션] " + info.prdtInfo.name}</span>
                                                                                <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· 대상 : {info.prdtInfo.termName + msg4}</span>
                                                                                <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· 사용기한 : {msg2}</span>
                                                                                <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· 서비스 이용기한 : 사용 시작 지정일로부터 {info.prdtInfo.termName}</span>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div className="landscape-to-portrait web-list-item pchsPass">
                                                                    <div className="d-flex flex-column align-items-center">
                                                                        <span className="only-mobile">구매일</span>
                                                                        <span className="hiclass-text">{info.pchsDT}</span>
                                                                    </div>
                                                                    <div className="d-flex align-items-center justify-content-center pass-use-date">
                                                                        {
                                                                            info.cancelYN === 'Y' ? 
                                                                                <div className="d-flex flex-column align-items-center w-50">
                                                                                    <span className="hiclass-text">취소완료</span>
                                                                                    <span className="hiclass-text">{info.cancelDt}</span>
                                                                                </div> :
                                                                            info.useDtInfo.strtDT !== null && info.useDtInfo.endDT !== null ?
                                                                                <>
                                                                                    <div className="d-flex flex-column align-items-center w-50">
                                                                                        <span className="hiclass-text">사용완료</span>
                                                                                        <span className="hiclass-text">{info.useDtInfo.strtDT + "~" + info.useDtInfo.endDT}</span>
                                                                                    </div>
                                                                                    <button type="button" className="btn btn-sm btn-default" onClick={() => onClickRefund(info)}>취소</button>
                                                                                </> : 
                                                                                <>
                                                                                    <button type="button" className="btn btn-sm btn-default" onClick={() => onClickUsePass(info)}>사용</button>
                                                                                    <button type="button" className="btn btn-sm btn-default" onClick={() => onClickRefund(info)}>취소</button>
                                                                                </>
                                                                        }
                                                                        {/* {
                                                                            info.useDtInfo.strtDT !== null && info.useDtInfo.endDT !== null ?
                                                                                <div className="d-flex flex-column align-items-center w-50">
                                                                                    <span className="hiclass-text">사용완료</span>
                                                                                    <span className="hiclass-text">{info.useDtInfo.strtDT + "~" + info.useDtInfo.endDT}</span>
                                                                                </div> :
                                                                                <button type="button" className="btn btn-sm btn-default" onClick={() => onClickUsePass(info)}>사용</button>
                                                                        }
                                                                        {
                                                                            info.cancelYN === 'Y' ? 
                                                                                <div className="d-flex flex-column align-items-center w-50">
                                                                                    <span className="hiclass-text">취소완료</span>
                                                                                    <span className="hiclass-text">{info.cancelDt}</span>
                                                                                </div> :
                                                                                <button type="button" className="btn btn-sm btn-default" onClick={() => onClickRefund(info)}>취소</button>
                                                                        } */}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div> :
                                <div className="view m-3 text-center grey-text">
                                    이용권 구매 내역이 없습니다.
                                </div>
                        }
                    </div>
                </div>
            </div>
            <WebAddPassProduct
                isModalOpened={isAddPassProductModalOpened}
                toggleModalOpened={toggleAddPassProductModal}
                list_prdtKindCode={list_prdtKindCode}
                list_prdtScaleCode={list_prdtScaleCode}
                list_prdtTargetCode={list_prdtTargetCode}
                list_prdtTermCode={list_prdtTermCode}
                handleSelectedFunc={handleSelectedFunc}
            />
            <WebRegistPass
                isModalOpened={isRegistPassModalOpened}
                toggleModalOpened={toggleRegistPassModal}
                subDomainInfo={selectedSubDomainOption}
                userInfo={lUserInfo}
                list_subDomainInfo={list_configSubDomain}
                list_prdtPass={list_prdtPass}
                list_prdtKindCode={list_prdtKindCode}
                list_prdtScaleCode={list_prdtScaleCode}
                list_prdtTargetCode={list_prdtTargetCode}
                list_prdtTermCode={list_prdtTermCode}
                handleRegistData={handleRegistData}
            />
            <WebFindUser
                isModalOpened={isFindUserModalOpened}
                toggleModalOpened={toggleFindUserModal}
                subDomainInfo={selectedSubDomainOption}
                searchResult={searchResult}
                searchValue={lSearchValue}
                handleSelectUser={handleSelectUser}
                handleSelectedFunc={handleSelectedFunc}
            />
            <WebUsePass
                isModalOpened={isUsePassModalOpened}
                toggleModalOpened={toggleUsePassModal}
                handleUsePassDate={handleUsePassDate}
                usePchsInfo={lUsePassInfo}
            />
            <WebRefundAlert 
                isModalOpened={isRefundModalOpened}
                refundPrice={refundPrice}
                refundType={refundType}
                toggleModalOepened={setIsRefundModalOpened}
                pchs_info={lUsePassInfo}
                handleSelectedFunc={handleSelectedFunc}
            />
        </div>
    );
};
export default WebAdminPassInfo;