import React from 'react';
import MainTemplate from '../components/template/MainTemplate';
import WhisperPopupContainer from '../containers/WhisperPopupContainer';

const WhisperPage = ({ browserType, cookies, routeProps }) => {
    return (
        <MainTemplate>
            <WhisperPopupContainer
                browserType={browserType}
                routeProps={routeProps}
                cookies={cookies}
            />
        </MainTemplate>
    );
};

export default WhisperPage;