import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';

import styled from 'styled-components';

import ConstData from '../../../lib/ConstData';
import { initSmallGroupFunc } from '../../../lib/HandsUpClient';
import { setElementForSmallGroup } from '../../../lib/MediaSoupClient';

// import ConnectImg from '../../../images/connect.png';

const ConnectImgComp = styled.img`
    width : 100px;
    height : 100px;
`;

const VideoComp = ConnectImgComp.withComponent("video");

const isMobile = (navigator.userAgent.toLowerCase().indexOf('android') > -1 || navigator.userAgent.toLowerCase().indexOf('iphone') > -1 || (navigator.userAgent.toLowerCase().indexOf('macintosh') > -1 && navigator.maxTouchPoints > 2));

const WebSmallGroupView = forwardRef(({ idx, liveSeq, memberInfo, style, defaultRightValue, isRbmqServerConnected, isReadyLiveConsume }, ref) => {

    const [lRightValue, setRightValue] = useState(defaultRightValue ? defaultRightValue : "2px");
    const [lWidth, setWidth] = useState(isMobile ? '160px' : '240px');
    const [lHeight, setHeight] = useState(isMobile ? '90px' : '135px');
    const [lZIndexValue, setZIndexValue] = useState(5);
    const [lVisibility, setVisibility] = useState("visible");
    const [lStyle, setStyle] = useState(style ? style : {
        position: 'absolute',
        right: '2px',
        top: '2px',
        width: '240px',
        height: '135px',
        paddingLeft: '2px',
        zIndex: 5,
        visibility: 'visible'
    });

    const [lUserNickname, setUserNickname] = useState("");

    const videoEl = useRef(null);
    const audioEl = useRef(null);
    const imgEl = useRef(null);
    const imgPath = useRef();

    useImperativeHandle(ref, () => ({
        // updateStyle: style => setStyle(style),
        // updateStyle: (style, width) => setStyleValue(style, width),
        updateStyle: (right, width, height, zIndex) => setStyleValue(right, width, height, zIndex),
        updateSize: (width, heigt, zIndex) => setSize(width, heigt, zIndex),
        updateVisibility: value => setVisibility(value),
        getVisibility: () => { return lVisibility },
        initConnectImg: () => setNotConnectedImg(),
    }));

    const imageProcess = (status, data) => {
        if (status === "change") {
            if (imgEl.current !== null) {
                const time = Date.now();
                imgEl.current.src = imgPath.current + ".jpg?time=" + time;
            }
        } else if (status === "disconnect") {
            if (imgEl.current !== null) {
                imgEl.current.src = "/images/connect.png";
            }
        } else if (status === "update") {
            if (imgEl.current) {
                if (data !== undefined && data !== null) {
                    let blob = new Blob([data], { type: "image/jpeg" });
                    let url = URL.createObjectURL(blob);

                    imgEl.current.src = url;
                    imgEl.current.onload = function () {
                        //cleanup.
                        URL.revokeObjectURL(url);
                    }
                } else {
                    imgEl.current.src = "/images/connect.png";
                }
            } else {
                if (videoEl.current) {
                    if (data !== undefined && data !== null) {
                        let blob = new Blob([data], { type: "image/jpeg" });
                        let url = URL.createObjectURL(blob);

                        videoEl.current.poster = url;
                        videoEl.current.onload = function () {
                            //cleanup.
                            console.log("videoEl.current.onload called");
                            URL.revokeObjectURL(url);
                        }
                    } else {
                        // imgEl.current.src = "/images/connect.png";
                        videoEl.current.pause();
                        videoEl.current.currentTime = 0;
                        videoEl.current.poster = "/images/connect.png";
                    }
                }
            }
        }
    }

    useEffect(() => {
        // initSmallGroupFunc(memberInfo.userSeq, imgEl, imageProcess);
        initSmallGroupFunc(memberInfo.userSeq, videoEl, imageProcess);

        return () => {
            imgEl.current = null;
            imgPath.current = null;

            initSmallGroupFunc(memberInfo.userSeq, null, imageProcess);
        }
    }, []);

    useEffect(() => {
        if (memberInfo.userNickname && memberInfo.userNickname !== "") {
            setUserNickname(memberInfo.userNickname);
        } else {
            if (idx !== undefined) {
                let number = idx.toString().padStart(2, "0");
                setUserNickname("HiNote " + number);
            } else {
                setUserNickname("HiNote 00");
            }
        }
    }, [idx, memberInfo.userNickname]);

    useEffect(() => {
        // console.log(`SmartTVMediaSoupRcvForStd - userSeq[${userSeq}], isRbmqServerConnected[${isRbmqServerConnected}], isReadyLiveConsume[${isReadyLiveConsume}]`);
        if (isRbmqServerConnected) {
            if (isReadyLiveConsume) {
                setElementForSmallGroup(memberInfo.userSeq, videoEl.current, audioEl.current);
            }
        }
    }, [isRbmqServerConnected, isReadyLiveConsume, memberInfo.userSeq]);

    /* useEffect(() => {
        if (liveSeq && memberInfo && memberInfo.userSeq && memberInfo.userSeq > 0) {
            imgPath.current = ConstData.MAIN_HOST_ADD + "/download/handsUp/" + liveSeq + "/img_" + memberInfo.userSeq;
        } else {
            if (imgEl.current !== null) {
                imgEl.current.src = "/images/connect.png";
            }
        }
    }, [liveSeq, memberInfo]); */

    const setSize = (width, height, zIndex) => {
        setWidth(width);
        setHeight(height);
        setZIndexValue(zIndex);
    }

    const setStyleValue = (right, width, height, zIndex) => {
        setRightValue(right);
        setWidth(width);
        setHeight(height);
        setZIndexValue(zIndex);
    }

    const setNotConnectedImg = () => {
        if (imgEl.current) {
            imgEl.current.src = "/images/connect.png";
        } else {
            if (videoEl.current !== null) {
                videoEl.current.pause();
                videoEl.current.currentTime = 0;
                videoEl.current.poster = "/images/connect.png";
            }
        }
    }

    return (
        <div className="small-group item">
            <VideoComp id={"small-group-item-" + idx} ref={videoEl} poster="/images/connect.png" playsInline autoPlay />
            {/* <ConnectImgComp ref={imgEl} src="/images/connect.png" alt="" /> */}
            <span className="hiclass-text font-sm p-1">
                {memberInfo.userNickname}
            </span>
        </div>
    )
})

export default WebSmallGroupView;