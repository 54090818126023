import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledCollapse } from 'reactstrap';
import { FaComments } from 'react-icons/fa';

import { getEntryMemberCount } from '../../lib/func/ExportFunction';
import WebListArrowButton from '../etc/button/WebListArrowButton';

const WebMyChatList = ({ list_chatting }) => {

    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        if (list_chatting.length > 0) {
            setIsOpened(true);
        }
    }, [list_chatting]);

    return(
        <div className="web-main list-area">
            <div className="card p-2">
                <div className="view view-cascade gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <Link to="/list_chat" style={{textDecoration:'none'}}>
                        <span className="d-flex white-text mx-1">
                            <FaComments className="font-lg mr-2" aria-hidden="true" />팀업
                        </span>
                    </Link>
                    <WebListArrowButton toggle_id={'chatListToggler'} list_array={list_chatting} isOpened={isOpened} />
                </div>
                <div className="px-2">
                    {
                        list_chatting !== undefined && list_chatting !== null && list_chatting.length > 0 ?
                            <UncontrolledCollapse toggler="#chatListToggler" defaultOpen={true} onEntering={() => setIsOpened(true)} onExiting={() => setIsOpened(false)}>
                                <table className="d-flex flex-column table table-sm table-hover mb-0">
                                    <thead>
                                        <tr className="d-flex">
                                            <th className="area-50"><b>팀업 이름</b></th>
                                            <th className="area-25"><b>멤버</b></th>
                                            <th className="area-25"><b>상태</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list_chatting.map(
                                                chatting => (
                                                    <tr key={chatting.chatRoomSeq} className="d-flex">
                                                        <td className="d-flex align-items-center justify-content-center area-50">
                                                            <div className="text-area">
                                                                <span className="hiclass-text" title={chatting.chatRoomName}>
                                                                    <b>{chatting.chatRoomName}</b>
                                                                </span>
                                                                <span className="grey-text">{getEntryMemberCount('teamUp', chatting.list_member)}</span>
                                                            </div>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center area-25">
                                                            <span className="hiclass-text" title={chatting.list_member.map(mbr => mbr.userNickname).join(" |")}>
                                                                {chatting.list_member.map(mbr => mbr.userNickname).join(" | ")}
                                                            </span>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center area-25">
                                                            {chatting.entryYN === "N" ? <></> : <span className="small">참여 중</span>}
                                                        </td>
                                                    </tr>
                                                )
                                            )
                                        }
                                    </tbody>
                                </table>
                            </UncontrolledCollapse> :
                            <div className="view m-3 text-center grey-text">
                                현재 참여 중인 팀업이 없습니다.
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}
export default WebMyChatList;