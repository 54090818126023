import React from 'react';
import { BsX } from 'react-icons/bs';

const RemovedChatRoomMember = ({ handleSelectedFunc, removedChatRoomName }) => {
    
    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" onClick={() => handleSelectedFunc({selectedFunc:"NONE"})}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex text-center my-2 dark-grey-text small">
                팀업 멤버에서 제외되었습니다.
            </div>
            <div className="content-area dark-grey-text my-0">
                <span className="small mb-1">[팀업 이름]</span>
                <div className="text-area text-center area-80">
                    <span className="hiclass-text" title={removedChatRoomName}>
                        <b>{removedChatRoomName}</b>
                    </span>
                </div>
            </div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small red white-text m-0 ml-1 py-1 px-2" onClick={()=>handleSelectedFunc({selectedFunc:"NONE"})}>닫기</button>
            </div>
        </div>
    );
}

export default RemovedChatRoomMember;