import React from 'react';
import { FaFolderPlus } from 'react-icons/fa';

const WebCreateAdminGrantButton = ({ subDomainSeq, subDomainName, member_info, toggleModalOpened }) => {
    switch (subDomainName) {
        case 'hiclasstv' :
        case 'easy' :
            return (
                <div className="list-row area-50">
                    <span 
                        className={"button small outline bold p-1 mr-1" + (member_info.isCreateAdmin ? " outline-hiclasstv-yellow hiclasstv-yellow-text" : " outline-hiclasstv-grey hiclasstv-grey-text")} 
                        style={{ borderRadius: '1rem', boxShadow: 'none' }} 
                        title={member_info.isCreateAdmin ? "사이트 추가 권한 해제" : "사이트 추가 권한 부여"}
                        onClick={() => toggleModalOpened(true, subDomainSeq, subDomainName, member_info)}
                    >
                        <FaFolderPlus className="d-flex font-sm" />
                    </span>
                </div>
            );

        default :
            return <></>;
    }
}

export default WebCreateAdminGrantButton;