import React from 'react';

const ShowResetPassword = ({ isExistUserInfo, handleSelectedFunc, }) => {
    return (
        <div className="d-flex flex-column align-items-center my-2">
            <div className="d-flex font-md py-4 px-2">
                {
                    isExistUserInfo ? 
                        <span>
                            비밀번호 변경 성공!
                        </span> : 
                        <span>
                            비밀번호 변경 도중 오류가 발생하였습니다.
                        </span>
                }
            </div>
            <div className="d-flex w-100 justify-content-center">
                {
                    isExistUserInfo ? 
                        <button className="btn btn-primary btn-sm" onClick={() => handleSelectedFunc({selectedFunc: "LOGIN"})}>로그인하기</button> : 
                        <button className="btn btn-primary btn-sm" onClick={() => handleSelectedFunc({selectedFunc: "CLOSE"})}>확인</button>
                }
            </div>
        </div>
    );
}

export default ShowResetPassword;