import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { FaTimes } from "react-icons/fa";
import { MDBInput } from "mdbreact";

import ConstData from "../../../lib/ConstData";

import Alerts from "../../etc/list/Alerts";

const QRLoginModal = ({ isModalOpened, selectedUser_info, toggleModalOpened, handleSelectedFunc }) => {
    const [liveStudentNickname, setLiveStudentNickname] = useState("");

    const [alertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [alertMessage, setAlertMessage] = useState("");

    const handleKeyPress = (e) => {
        if(e.key === "Enter") {
            onHandleLogin();
        }
    }

    const onChangeLiveStudentNickname = (e) => {
        let str = e.target.value;
        str = str.replace(' ', '');
        if (str.length > 15) {
            str = str.substr(0, 15);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("닉네임은 15자 이내로 입력해야 합니다.");
        } else {
            if (alertMessage !== "") {
                setAlertKind(ConstData.ALERT_KIND.NONE);
                setAlertMessage("");
            }
        }
        setLiveStudentNickname(str);
    }

    const onHandleLogin = () => {
        if (liveStudentNickname === "") {
            handleSelectedFunc({
                selectedFunc: "LOGIN_BY_QR",
                loginInfo: {
                    userID: selectedUser_info.userID,
                    userSeq: selectedUser_info.userSeq,
                    userNickname: selectedUser_info.userNickname
                }
            });
        } else {
            if (liveStudentNickname.length > 15) {
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage("닉네임은 15자 이내로 입력해야 합니다.");
                return;
            } else {
                handleSelectedFunc({
                    selectedFunc: "LOGIN_BY_QR",
                    loginInfo: {
                        userID: selectedUser_info.userID,
                        userSeq: selectedUser_info.userSeq,
                        userNickname: liveStudentNickname
                    }
                });
            }
        }
    }

    const onClosedModal = () => {
        setLiveStudentNickname("");
        setAlertKind(ConstData.ALERT_KIND.NONE);
        setAlertMessage("");
    }

    return (
        <Modal isOpen={isModalOpened} centered backdrop={true} onClosed={onClosedModal} >
            <div className="card container-fluid px-3 m-0">
                <div className="card-body">
                    <div className="d-flex title-area w-100 justify-content-end text-right mb-3">
                        <button type="button" className="hiclasstv-btn p-0" title="닫기" onClick={() => toggleModalOpened(false)}>
                            <FaTimes className="d-flex m-1 black-text" />
                        </button>
                    </div>
                    <div className="d-flex flex-column w-100 justify-content-center align-items-start mb-2">
                        <span className="font-weight-bold font-md plus p-2">
                            현재 클래스만을 위한
                        </span>
                        <span className="font-weight-bold font-md plus pb-2 px-2">
                            닉네임으로 변경할 수 있습니다.
                        </span>
                    </div>
                    <div className="d-flex flex-column align-items-center pt-3 pb-2 area-90">
                        <MDBInput type="text" id="inputNn" className="input-form-control" containerClass="input-form m-0" label="클래스용 닉네임" labelClass="input-form-label" placeholder="닉네임을 입력해 주세요." onChange={onChangeLiveStudentNickname} value={liveStudentNickname} onKeyPress={handleKeyPress} />
                    </div>
                    <div className="d-flex w-100 justify-content-start">
                        <span className="input-description info">· 1자~15자, 한글, 영문, 숫자, 특수문자 사용 가능</span>
                    </div>
                    <div className="d-flex w-100 justify-content-start">
                        <span className="input-description info">· 생략 가능 (미 입력 시 회원 정보에 저장된 닉네임 사용)</span>
                    </div>
                    <div className="d-flex w-100 justify-content-start">
                        <span className="input-description notice">* 일회성(임시) 닉네임이므로 변경하여도 회원 정보에 반영되지 않습니다.</span>
                    </div>
                    {
                        alertMessage !== "" &&
                        <div className="d-flex w-100 justify-content-center align-items-center mb-3">
                            <Alerts kind={alertKind} message={alertMessage} />
                        </div>
                    }
                    <div className="d-flex w-100 justify-content-center align-items-center pt-3">
                        <button className="btn hiclasstv-blue white-text" disabled={selectedUser_info ? false : true} onClick={() => onHandleLogin()}>
                            Login
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default QRLoginModal;