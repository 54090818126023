import React from "react";

import { enFindOption, enFindStep } from "../../../lib/ConstCommand";

import FindPW from "./FindPW";
import FindId from "./FindId";
import FindPWCertifyPhone from "./FindPWCertifyPhone";
import ShowIdList from "./ShowIdList";
import ResetPassword from "./ResetPassword";
import ShowResetPassword from "./ShowResetPassword";

const FindStep = ({ ...props }) => {
    switch (props.findIdPasswordStep) {
        case enFindStep.CHECK_ID:
            return <FindPW {...props} />;

        case enFindStep.CERTIFY_PHONE:
            return (
                props.findIdPasswordOption === enFindOption.ID ?
                    <FindId {...props} /> :
                props.findIdPasswordOption === enFindOption.PW ?
                    <FindPWCertifyPhone {...props} /> :
                    <></>
            );

        case enFindStep.SHOW_ID:
            return <ShowIdList {...props} />;

        case enFindStep.RESET_PW:
            return <ResetPassword {...props} />;

        case enFindStep.END:
            return <ShowResetPassword {...props} />;

        default:
            return <></>;
    }
}

export default FindStep;