import React, { useState } from 'react';
import { FaExclamationTriangle, FaChevronLeft, FaCircle, FaCommentDots, FaUserEdit, FaUserTag, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import ConstData from "../../../lib/ConstData";

import CustomProfileImage from '../profile/CustomProfileImage';
import CertifyServerUser from './CertifyServerUserModal';
import Alerts from '../../etc/list/Alerts';

const MyProfileWindow = ({ user_info, certifiedServerUserInfo, isEditInfoModalOpened, performChatRoomInfo, handleSelectedFriendFunc }) => {

    const [lUserNickname, setUserNickname] = useState(user_info.userNickname);
    const [lUserProfile, setUserProfile] = useState(user_info.userProfile);

    const [isCertifyModalOpened, setIsCertifyModalOpened] = useState(false);

    const [alertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [alertMessage, setAlertMessage] = useState("");
    
    const onChangeNickname = (e) => {
        let str = e.target.value;
        str = str.replace(' ', '');
        if (str.length > 15) {
            str = str.substr(0, 15);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage('닉네임은 15자 이내로 입력해야 합니다.');
        } else {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage("");
        }
        setUserNickname(str);
    }

    const onChangeProfile = (e) => {
        let str = e.target.value;
        if (str.length > 30) {
            str = str.substr(0, 30);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage('프로필은 30자 이내로 입력해야 합니다.');
        } else {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage("");
        }
        setUserProfile(str);
    }

    const handleCheckInputValue = () => {
        let isChangedNickname = false;
        let isChangedProfile = false;

        if (lUserNickname !== user_info.userNickname) {
            isChangedNickname = true;
        }

        if (lUserProfile !== user_info.userProfile) {
            isChangedProfile = true;
        }

        if (!isChangedNickname && !isChangedProfile) {
            handleSelectedFriendFunc({ selectedFunc: "EDIT_TOGGLE"});
        } else {
            let userNickname = isChangedNickname ? lUserNickname : null;
            let userProfile = isChangedProfile ? lUserProfile : null;

            handleSelectedFriendFunc({ selectedFunc: "EDIT", userNickname, userProfile });
        }
    }

    const toggleCertifyServerUserModal = (flag) => {
        setIsCertifyModalOpened(flag);
    handleSelectedFriendFunc({ selectedFunc: 'SET_MESSAGE_BLANK' });
    }

    const handleCertifyServerUser = ({ serverUserID, serverUserPW, isKeepSaving }) => {
        console.log(`handleCertifyServerUser -- ${serverUserID}, ${serverUserPW}, ${isKeepSaving}`);
        handleSelectedFriendFunc({ selectedFunc: 'CERTIFY_SERVER_USER', data: { serverUserID, serverUserPW, isKeepSaving }});
    }

    return (
        <div className="profile">
            {
                performChatRoomInfo.chatRoomSeq > 0 &&
                    <div className="py-4" style={{ top: 0 }}>
                        <FaExclamationTriangle className="font-lg red-text" />
                        <div className="d-flex flex-column text-center mt-2">
                            <span className="red-text"><b>현재 참여 중인 팀업이 존재합니다.</b></span>
                            <span className="blue-grey-text small">나와의 채팅을 시작하면 이전의 모든 대화내용은 삭제됩니다.</span>
                            <span className="hiclasstv-blue white-text font-weight-bold p-2 mt-2 rounded" title={"현재 참여 중인 팀업 [" + performChatRoomInfo.chatRoomName + "]"}>
                                [참여 중] {performChatRoomInfo.chatRoomName}
                            </span>
                        </div>
                    </div>
            }
            <div>
                {
                    isCertifyModalOpened &&
                    <CertifyServerUser
                        isModalOpened={isCertifyModalOpened}
                        serverUserSeq={certifiedServerUserInfo.serverUserSeq}
                        alertKind={certifiedServerUserInfo.alertKind}
                        loginMessage={certifiedServerUserInfo.alertMessage}
                        toggleCertifyServerUserModal={toggleCertifyServerUserModal}
                        handleCertifyServerUser={handleCertifyServerUser}
                    />
                }
                {
                    !isEditInfoModalOpened &&
                        <div className="d-flex align-items-center justify-content-center position-absolute" style={{left:'0'}}>
                            <button type="button" className="no-effect rounded" title="이전" onClick={() => handleSelectedFriendFunc({selectedFunc:"NONE"})}>
                                <FaChevronLeft className="font-md" aria-hidden="true" />
                            </button> 
                        </div>
                }
                <div className="d-flex flex-column justify-content-center align-items-center area-80">
                    <div className="position-relative px-2">
                        <CustomProfileImage url={user_info.profileImgUrl} style={{ width: '5rem' }} alt={user_info.userNickname} />
                        {/*
                            <div className="position-absolute" style={{right:'0', bottom:'0'}}>
                                user_info.userStatus === ConstData.USER_STATUS.LOG_IN && 
                                <FaCircle className="font-lg green-text" aria-hidden="true" />
                            </div>
                        */}
                    </div>
                    {
                        !isEditInfoModalOpened ? 
                            <>
                                <div className="d-flex flex-column align-items-center justify-content-center text-center my-2 w-100">
                                    <span className="hiclass-text font-weight-bold" title={user_info.userNickname}>{user_info.userNickname}</span>
                                    <span className="hiclass-text hiclasstv-deepDarkgrey-text small" title={user_info.userProfile}>{user_info.userProfile}</span>
                                </div>
                                {
                                    ConstData.IS_LOCAL_VERSION && certifiedServerUserInfo.serverUserSeq === -1 &&
                                    <div className='d-flex justify-content-end my-2'>
                                        <span className='font-sm' onClick={() => toggleCertifyServerUserModal(true)}>서버 사용자 인증하기</span>
                                    </div>
                                }                                
                                <div className="d-flex justify-content-center m-1">
                                    <button type="button" className="no-effect rounded mr-1" onClick={() => handleSelectedFriendFunc({selectedFunc:"JOIN_MP2P"})}>
                                        <FaCommentDots className="font-xlg hiclasstv-blue-text mb-2" aria-hidden="true" />
                                        {
                                            ConstData.IS_LOCAL_VERSION ? "채팅방으로 이동" : "나와의 채팅"
                                        }
                                    </button>
                                    <button type="button" className="no-effect rounded ml-1" onClick={() => handleSelectedFriendFunc({selectedFunc:"EDIT_TOGGLE"})}>
                                        <FaUserEdit className="font-xlg hiclasstv-blue-text mb-2" aria-hidden="true" />
                                        프로필 편집
                                    </button>
                                </div>
                            </> : 
                            <>
                                <div className="d-flex flex-column align-items-center justify-content-center text-center my-1 w-100">
                                    <input type="text" className="white w-100" placeholder={user_info.userNickname} title={`변경 전 닉네임 [${user_info.userNickname}]`} onChange={onChangeNickname} value={lUserNickname} />
                                    <div className="d-flex w-100">
                                        <span className="input-description pentalk">1자~15자, 한글, 영문, 숫자, 특수문자 사용 가능</span>
                                    </div>
                                    {/* <div className="d-flex w-100 justify-content-between">
                                        <span style={{ width: '20%', maxWidth: '80px' }}></span>
                                        <span className="input-description area-80">1자~15자, 한글, 영문, 숫자, 특수문자 사용 가능</span>
                                    </div> */}
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center text-center my-1 w-100">
                                    <input type="text" className="white" placeholder={user_info.userProfile} title={`변경 전 프로필 [${user_info.userProfile}]`} onChange={onChangeProfile} value={lUserProfile} />
                                    <div className="d-flex w-100">
                                        <span className="input-description pentalk">1자~30자, 한글, 영문, 숫자, 특수문자 사용 가능</span>
                                    </div>
                                    {/* <div className="d-flex w-100 justify-content-between">
                                        <span style={{ width: '20%', maxWidth: '80px' }}></span>
                                        <span className="input-description area-80">1자~30자, 한글, 영문, 숫자, 특수문자 사용 가능</span>
                                    </div> */}
                                </div>
                                {
                                    alertMessage !== "" &&
                                    <div className="d-flex justify-content-center">
                                        <Alerts kind={alertKind} message={alertMessage} />
                                    </div>
                                }
                                <div className="d-flex justify-content-center m-1">
                                    <button type="button" className="no-effect rounded mr-1" title="수정하기" onClick={() => handleCheckInputValue()}>
                                        <FaCheckCircle className="font-lg green-text" aria-hidden="true" />
                                    </button>
                                    <button type="button" className="no-effect rounded ml-1" title="취소하기" onClick={() => handleSelectedFriendFunc({ selectedFunc: "EDIT_TOGGLE" })}>
                                        <FaTimesCircle className="font-lg red-text" aria-hidden="true" />
                                    </button>
                                </div>
                            </>
                    } 
                </div>
            </div>
            {
                certifiedServerUserInfo.serverUserSeq !== -1 &&
                <div className='d-flex flex-column position-absolute align-items-center jsutify-content-center py-2 exception' style={{ bottom: 0 }}>
                    <span className='hiclasstv-deepDarkgrey-text font-sm'>서버 인증 완료된 사용자입니다.</span>
                    <span className='font-sm font-weight-bold' onClick={() => toggleCertifyServerUserModal(true)}><u>재인증</u></span>
                </div>
            }
        </div>
    );
}

export default MyProfileWindow;