/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';

const QnA005 = ({ isOpened, onChangeOpenedFlag }) => {
    useEffect(() => {
        let content = document.getElementById("qna05");

        if (isOpened) {
            content.style.maxHeight = content.scrollHeight + "px";
        } else {
            content.style.maxHeight = null;
        }
    }, [isOpened]);

    return (
        <div className="accordion">
            <div className="accordion-title" onClick={() => onChangeOpenedFlag(4)}>
                <span className="hiclasstv-deepDarkgrey-text button">
                    <b>5. 제품 사용시 문제가 발생하면 어떻게 도움을 받을 수 있나요?</b>
                </span>
            </div>
            <div className="accordion-content hiclasstv-darkgrey-text" id="qna05">
                <span className="d-flex" style={{ minWidth: 'max-content' }}>A.&nbsp;</span>
                <p>
                    HiClassTV에 관심을 가져 주심에 감사드립니다.<br />
                    사용 시 문제가 발생하면 아래 연락처로 문의해 주십시오.<br />
                    빠른 시간안에 해결할 수 있도록 온라인, 오프라인으로 지원해 드리겠습니다.<br />
                    <b>전화 : (02) 3484-4800</b><br />
                    <b>메일 : HiClassTV@easy.co.kr</b>
                </p>
            </div>
        </div>
    );
}
export default QnA005;