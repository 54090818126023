/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';

const QnA001 = ({isOpened, onChangeOpenedFlag}) => {
    useEffect(() => {        
        let content = document.getElementById("qna01");

        if (isOpened) {
            content.style.maxHeight = content.scrollHeight + "px";
        } else {
            content.style.maxHeight = null;
        }
    }, [isOpened]);

    return(
        <div className="accordion">
            <div className="accordion-title" onClick={() => onChangeOpenedFlag(0)}>
                <span className="hiclasstv-deepDarkgrey-text button">
                    <b>1. 제품에 대한 상담을 받고 싶습니다.</b>
                </span>
            </div>
            <div className="accordion-content hiclasstv-darkgrey-text" id="qna01">
                <span className="d-flex" style={{ minWidth: 'max-content' }}>A.&nbsp;</span>
                <p>
                    HiClassTV에 관심을 가져 주심에 감사드립니다.<br />
                    HiClassTV는 비대면으로 강의나 회의를 할 수 있는 다양한 솔루션을 제공하는 플랫폼입니다.<br />
                    제품에 대한 상담이 필요하실 경우 아래의 전화나 메일로 문의 주시면 성실하게 상담해 드리겠습니다.<br />
                    <b>전화 : (02) 3484-4800</b><br />
                    <b>메일 : HiClassTV@easy.co.kr</b>
                </p>
            </div>
        </div>
    );
}
export default QnA001;