import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

// import studyingImg from '../../../images/studying.png';

const SearchMyChatList = ({ list_chatting, list_searchChatting, handleSearchValue, handleSelectedFunc }) => {

    const [isChangedSearchValue, setChangedSearchValue] = useState(false);
    const [isClickSearchIcon, setClickSearchIcon] = useState(false);
    const [lSearchValue, setSearchValue] = useState("");

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            setClickSearchIcon(true);
            handleSearchClick({keyword:lSearchValue});
        }
    }

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const handleSearchClick = () => {
        setClickSearchIcon(true);
        handleSearchValue({keyword:lSearchValue});
    }

    const onChangeSearchValue = (e) => {
        setChangedSearchValue(true);
        setClickSearchIcon(false);
        setSearchValue(e.target.value);
        handleSearchValue({keyword:e.target.value});
    }

    const getEntryCount = (member) => {
        let cnt = 0;
        member.forEach(element => {
            if(element.entryYN === "Y") {
                cnt++;
            }
        });

        return cnt;
    }

    return(
        <div onClick={handlePreventClick}>
            <div className="input-area pt-1">
                <input type="text" className="search" placeholder="검색어를 입력하세요" onChange={onChangeSearchValue} onKeyPress={handleKeyPress} />
                <div className="input-group-append ml-1" title="검색" onClick={handleSearchClick}>
                    <span className="d-flex hiclasstv-btn b-none rounded p-1">
                        <FaSearch className="d-flex font-md black-text" />
                    </span>
                </div>
            </div>
            {
                isClickSearchIcon === true ? 
                    <div className="py-1 hiclasstv-lightgrey">
                        <span className="px-2 font-weight-bold grey-text">팀업</span>
                    </div> : 
                    <></>
            }
            <div className="list-group" style={{overflow:'auto'}}>
                {
                    isChangedSearchValue === false ?
                        list_chatting.length > 0 ?
                            list_chatting.map(
                                chatting => (
                                    <div className={"d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" + (chatting.entryYN === "Y" ? " active" : "")} key={chatting.chatRoomSeq} onClick={()=>handleSelectedFunc({selectedFunc:"PARTICIPATE", chatRoomSeq:chatting.chatRoomSeq, chatRoomName:chatting.chatRoomName})}>
                                        <div className="d-flex area-15 fit-content">
                                            <img className="hiclasstv-chatting-img mr-2" src="/images/studying.png" alt="" />
                                        </div>
                                        <div className="text-area flex-column align-items-start">
                                            <div className="text-area justify-content-start">
                                                <span className="hiclass-text" title={chatting.chatRoomName}><b>{chatting.chatRoomName}</b></span>
                                                <span className="hiclass-text hiclasstv-darkgrey-text">&nbsp;{getEntryCount(chatting.list_member)}</span>
                                            </div>
                                            <span className="hiclass-text hiclasstv-darkgrey-text small" title={chatting.list_member.map(mbr => mbr.userNickname).join(" | ")}>
                                                {chatting.list_member.map(mbr => (mbr.userNickname)).join(" | ")}
                                            </span>
                                        </div>
                                    </div>
                                )
                            ) :
                            <div className="view p-2 text-center grey-text">
                                검색 결과가 없습니다.
                            </div> :
                        list_searchChatting.length > 0 ?
                            list_searchChatting.map(
                                chatting => (
                                    <div className={"d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" + (chatting.entryYN === "Y" ? " active" : "")} key={chatting.chatRoomSeq} onClick={()=>handleSelectedFunc({selectedFunc:"PARTICIPATE", chatRoomSeq:chatting.chatRoomSeq, chatRoomName:chatting.chatRoomName})}>
                                        <div className="d-flex area-15 fit-content">
                                            <img className="mr-2" src="/images/studying.png" alt="" />
                                        </div>
                                        <div className="text-area flex-column align-items-start">
                                            <div className="text-area justify-content-start">
                                                <span className="hiclass-text" title={chatting.chatRoomName}><b>{chatting.chatRoomName}</b></span>
                                                <span className="hiclass-text hiclasstv-grey-text">&nbsp;{getEntryCount(chatting.list_member)}</span>
                                            </div>
                                            <span className="hiclass-text hiclasstv-darkgrey-text small" title={chatting.list_member.map(mbr => mbr.userNickname).join(" | ")}>
                                                {chatting.list_member.map(mbr => (mbr.userNickname)).join(" | ")}
                                            </span>
                                        </div>
                                    </div>
                                )
                            ) : 
                            <div className="view p-2 text-center grey-text">
                                검색 결과가 없습니다.
                            </div>
                }
            </div>
        </div>
    );
}
export default SearchMyChatList;