import React, { useState, useEffect } from "react";
import { FaPlusSquare, FaSmile, FaMicrophoneAlt, FaVolumeUp, FaSyncAlt, FaPaperPlane } from "react-icons/fa";

import { ctWatsonStatus } from "../../lib/ConstCommand";
// import watsonImg from "../image/watson/watson.png";

const InputGroupBox = ({ selIndex, isLiveChattingLocked, isRunningWatson, isSpeakingMode, watsonStatus, onHandleClassTalkFunc, handleSelectedClassTalkFunc }) => {

    const [lMessageValue, setMessageValue] = useState("");
    const [tHeight, setTHeight] = useState("32px");

    useEffect(() => {

    }, [watsonStatus]);

    useEffect(() => {

    }, [isRunningWatson]);

    useEffect(() => {

    }, [isSpeakingMode]);

    const onChangeMessageValue = (e) => {
        if (e.target.scrollHeight > 32) {
            //console.log("onChangeMessageValue : ", e.target.scrollHeight);
            let newHeight;
            if (e.target.scrollHeight > 160) {
                newHeight = "160px";
            } else {
                newHeight = e.target.scrollHeight + "px";
            }

            if (newHeight !== tHeight) {
                setTHeight(newHeight);
            }
        } else {
            //console.log("onChangeMessageValue : ", e.target.scrollHeight);
            let newHeight = "32px";
            if (newHeight !== tHeight) {
                setTHeight(newHeight);
            }
        }
        setMessageValue(e.target.value);
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            //if (!e.shiftKey) {
            createSend();
            e.preventDefault();
            //}
        }
    }

    const createSend = () => {
        if (isRunningWatson) {
            if (lMessageValue !== "") {
                if (watsonStatus !== ctWatsonStatus.InActive) {
                    handleSelectedClassTalkFunc({ selectedFunc: "SEND_MSG_WATSON", msgText: lMessageValue });
                    setMessageValue("");
                    setTHeight("32px");
                } else {
                    console.log("inactive mode.....");
                    handleSelectedClassTalkFunc({ selectedFunc: "PING_WATSON" });
                }
            } else {
                console.log("createSend() - 이모티콘 선택x, 메시지 입력x");
            }
        } else {
            if (selIndex < 0) {
                if (lMessageValue !== "") {
                    //handleSelectedClassTalkFunc({selectedFunc:"SEND_MSG", msgText:lMessageValue});
                    onHandleClassTalkFunc({ selectedFunc: "SEND_MSG", msgText: lMessageValue });
                    setMessageValue("");
                    setTHeight("32px");
                } else {
                    console.log("createSend() - 메시지 입력x");
                }
            } else {
                //handleSelectedClassTalkFunc({selectedFunc:"SEND_MSG", msgText:lMessageValue});
                onHandleClassTalkFunc({ selectedFunc: "SEND_MSG", msgText: lMessageValue });
                setMessageValue("");
                setTHeight("32px");
            }
        }
    }

    return (
        <div className="hiclasstv-classtalk-input-main" style={{ height: `${tHeight}`, minHeight: '2rem' }}>
            <div className={"touchLockGuard" + (isLiveChattingLocked ? " visible" : "")}>
                채팅이 잠겼습니다.
            </div>
            <button type="button" className="hiclasstv-classtalk-button hiclasstv-blue-text" data-toggle="tooltip" title="메뉴 더보기" onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_TOGGLE" })}>
                <FaPlusSquare className="font-md plus" aria-hidden="true" />
            </button>
            <div className="hiclasstv-classtalk-input-box">
                <textarea className="hiclasstv-classtalk-input" placeholder="메세지를 입력하세요" onChange={onChangeMessageValue} value={lMessageValue} onKeyPress={handleKeyPress} />
                {
                    !isRunningWatson &&
                    <div className="input-group-append align-items-center h-100">
                        <button type="button" className="hiclasstv-classtalk-button hiclasstv-yellow-text" data-toggle="tooltip" title="이모티콘 선택" onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "EMOTICON_TOGGLE" })}>
                            <FaSmile className="font-md plus" aria-hidden="true" />
                        </button>
                    </div>
                }
            </div>
            {
                isRunningWatson ?
                    watsonStatus === ctWatsonStatus.Listening ?
                        <button type="button" className={"hiclasstv-classtalk-button" + (isSpeakingMode ? "" : " hiclasstv-blue")} data-toggle="tooltip" title="왓슨에게 전송" onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "WATSON_TOGGLE" })}>
                            {
                                isSpeakingMode ?
                                    <FaMicrophoneAlt className="font-md plus hiclasstv-coral-text icon-gradation" aria-hidden="true" /> :
                                    <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative', width: '100%', height: '100%' }}>
                                        <FaPaperPlane className="font-xsm hiclasstv-yellow-text icon-gradation" style={{ position: 'absolute', right: '2px', bottom: '2px', zIndex: '5' }} aria-hidden="true" />
                                        <img src="/images/watson/watson.png" style={{ width: '80%', height: '80%' }} alt="" />
                                    </div>
                            }
                        </button> :
                        watsonStatus === ctWatsonStatus.Processing ?
                            <button type="button" className={"hiclasstv-classtalk-button" + (isSpeakingMode ? "" : " hiclasstv-blue")} data-toggle="tooltip" title="왓슨 처리중">
                                {
                                    isSpeakingMode ?
                                        <FaSyncAlt className="font-md plus hiclasstv-violet-text icon-spin" aria-hidden="true" /> :
                                        <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative', width: '100%', height: '100%' }}>
                                            <FaSyncAlt className="font-xsm icon-spin hiclasstv-yellow-text icon-spin" style={{ position: 'absolute', right: '2px', bottom: '2px', zIndex: '5' }} aria-hidden="true" />
                                            <img src="/images/watson/watson.png" style={{ width: '80%', height: '80%' }} alt="" />
                                        </div>
                                }
                            </button> :
                            watsonStatus === ctWatsonStatus.Speaking ?
                                <button type="button" className={"hiclasstv-classtalk-button" + (isSpeakingMode ? "" : " hiclasstv-blue")} data-toggle="tooltip" title="왓슨 처리중">
                                    {
                                        isSpeakingMode ?
                                            <FaVolumeUp className="font-md plus hiclasstv-violet-text icon-gradation" aria-hidden="true" /> :
                                            <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative', width: '100%', height: '100%' }}>
                                                <FaVolumeUp className="font-xsm hiclasstv-yellow-text icon-gradation" style={{ position: 'absolute', right: '2px', bottom: '2px', zIndex: '5' }} aria-hidden="true" />
                                                <img src="/images/watson/watson.png" style={{ width: '80%', height: '80%' }} alt="" />
                                            </div>
                                    }
                                </button> :
                                <button type="button" className="hiclasstv-classtalk-button grey" data-toggle="tooltip" title="왓슨 깨우기" onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "PING_WATSON" })}>
                                    <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative', width: '100%', height: '100%' }}>
                                        <FaPaperPlane className="font-xsm hiclasstv-yellow-text icon-gradation" style={{ position: 'absolute', right: '2px', bottom: '2px', zIndex: '5' }} aria-hidden="true" />
                                        <img src="/images/watson/watson.png" style={{ width: '80%', height: '80%' }} alt="" />
                                    </div>
                                </button> :
                    <button type="button" className="hiclasstv-classtalk-button hiclasstv-blue-text" onClick={createSend}>
                        <FaPaperPlane className="font-md plus" aria-hidden="true" />
                    </button>
            }
        </div>
    );
}

export default InputGroupBox;