import React, { useEffect, useState } from 'react';

import { stQnAItemType } from '../../../lib/ConstCommand';

import Item from './item/Item';

const QuizCardForTeacher = ({ indexKey, cardInfo, activeLive, handleSelectedClassTalkFunc }) => {

    const [question, setQuestion] = useState(null);
    const [example, setExample] = useState(null);
    const [answer, setAnswer] = useState(null);
    const [answerExampleYN, setAnswerExampleYN] = useState("Y");
    const [response, setResponse] = useState(null);
    const [activeMemberCnt, setActiveMemberCnt] = useState(0);

    useEffect(() => {
        if (cardInfo.data && cardInfo.data.data && cardInfo.data.data.length > 0) {
            let exampleInfo;

            cardInfo.data.data.forEach(item => {
                switch(item.type) {
                    case stQnAItemType.Question : 
                        setQuestion(item.data);

                        if (item.exYN === 'Y') {
                            exampleInfo = {
                                type : item.exType,
                                no : item.exNum,
                                list : []
                            }

                            setAnswerExampleYN("Y");
                        } else {
                            setAnswerExampleYN("N");
                        }
                        break;

                    case stQnAItemType.Example:
                        let exArr = [];

                        item.data.forEach(ex => {
                            exArr.push(ex);
                        });

                        if (exampleInfo) {
                            exampleInfo = {
                                ...exampleInfo,
                                list : exArr
                            };

                            setExample(exampleInfo);
                        }
                        break;

                    case stQnAItemType.Answer:
                        if (typeof item.data === "string") {
                            console.log("QuizResponseInfo Answer - ", item.data);
                            setAnswer(item.data);
                        } else if (typeof item.data === "object") {
                            console.log("QuizResponseInfo Answer - ", item.data);
                            setAnswer(item.data.data);
                        } else {
                            console.log("QuizResponseInfo Answer - ", item.data);
                        }
                        /* if (answerExampleYN === "Y") {
                            console.log("QuizCardForTeacher setAnswer(item.data) - ", item.data);
                            setAnswer(item.data);
                        } else if (answerExampleYN === "N") {
                            console.log("QuizCardForTeacher setAnswer(item.data.data) - ", item.data);
                            setAnswer(item.data.data);
                        } else {
                            console.log("QuizCardForTeacher Answer - ", item.data);
                        } */
                        break;

                    default:
                        break;
                }
            });
        }
        
        if (cardInfo.response) {
            setResponse(cardInfo.response);
        }

        if (cardInfo.list_member) {
            setActiveMemberCnt(cardInfo.list_member.length);
        }
    }, [cardInfo]);

    const onHandleShowResponse = () => {
        handleSelectedClassTalkFunc({ selectedFunc: "SHOW_QUIZ_RESPONSE_INFO", idx: indexKey });
    }

    const onHandleResponseFinish = ({ quizSeq, list_member }) => {
        handleSelectedClassTalkFunc({ selectedFunc: "QUIZ_RESPONSE_FINISH", quizSeq, list_member, idx: indexKey, liveSeq: activeLive.liveSeq });
    }

    console.log("cardInfo.isFinishedQuiz - ", cardInfo.isFinishedQuiz);
    console.log("indexKey - ", indexKey);
    console.log("cardInfo.seq - ", cardInfo.seq);
    return (
        cardInfo.data.data && cardInfo.data.data.length > 0 ?
            <div className="quiz-card">
                {
                    question &&
                    question.map((item, index) => (
                        <Item key={'question-' + index} info={item} />
                    ))
                }
                {
                    example &&
                    <div className={"example-area " + example.type}>
                        {
                            example.list.map((item, index) => (
                                <div key={'ex-' + index} className="example-item">
                                    <span style={{ width: '5%' }}>{item.no}.</span>
                                    <Item info={item} title={'ex-' + item.no} />
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    response &&
                    <div className='response-area'>
                        <span className='font-md hiclasstv-blue-text font-weight-bold'>
                            응답률 ({response.length}/{activeMemberCnt/*activeLive.list_member.length*/})
                        </span>
                        {
                            cardInfo.isFinishedQuiz ?
                                <button className='response hiclasstv-button hiclasstv-blue font-md area-50 m-0' onClick={() => onHandleShowResponse()}>
                                    결과보기
                                </button> :
                                <button className='response hiclasstv-button hiclasstv-blue font-md area-50 m-0' onClick={() => onHandleResponseFinish({ quizSeq: cardInfo.seq, list_member: cardInfo.list_member })}>
                                    응답 마감하기
                                </button>
                        }

                    </div>
                }
            </div> :
            <div>
                퀴즈 정보를 읽어올 수 없습니다. 다시 시도해 주세요.
            </div>
    );
}

export default QuizCardForTeacher;