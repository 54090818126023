/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';

const QnA002 = ({ isOpened, onChangeOpenedFlag }) => {
    useEffect(() => {
        let content = document.getElementById("qna02");

        if (isOpened) {
            content.style.maxHeight = content.scrollHeight + "px";
        } else {
            content.style.maxHeight = null;
        }
    }, [isOpened]);

    return (
        <div className="accordion">
            <div className="accordion-title" onClick={() => onChangeOpenedFlag(1)}>
                <span className="hiclasstv-deepDarkgrey-text button">
                    <b>2. 제품의 구성에 대해 설명해 주세요.</b>
                </span>
            </div>
            <div className="accordion-content hiclasstv-darkgrey-text" id="qna02">
                <span className="d-flex" style={{ minWidth: 'max-content' }}>A.&nbsp;</span>
                <p>
                    HiClassTV에 관심을 가져 주심에 감사드립니다.<br />
                    비대면 원격 소통 및 강의 플랫폼인 HiClassTV는 다음과 같이 구성되어 있습니다.<br />
                    <b>ㆍPentalk OnChat</b><br />
                    다양한 방법으로 토론이나 강의를 통해 소통하는 기본 기능입니다.<br />
                    <b>ㆍPencam OnAir</b><br />
                    영상 녹화 및 실시간 방송을 하는 기능입니다.<br />
                    <b>ㆍHiClass OnBoard</b><br />
                    판서를 통해 진행하는 강의에 특화된 기능입니다.<br />
                    <b>ㆍ보조장치</b><br />
                    비대면 업무 수행 시 효율성을 높여 주는 보조 장치로 전자 칠판, 전자 종이, 전자펜, 마이크로폰, 헤드폰 등이 있습니다.
                </p>
            </div>
        </div>
    );
}
export default QnA002;