import React from 'react';

import UserInfo from '../../etc/list/UserInfo';

const WebFriendList = ({ rowKey, list_friends }) => {
    return (
        list_friends !== undefined && list_friends !== null && list_friends.length > 0 ?
        <table className="d-flex flex-column table table-sm table-hover mb-0">
            <tbody>
                {
                    list_friends.map(friend => (
                        <UserInfo key={rowKey + friend.userSeq} user_info={friend} />
                    ))
                }
            </tbody>
        </table> :
        <div className="view m-3 text-center grey-text">
            친구가 존재하지 않습니다.
        </div>
    );
}

export default WebFriendList;