import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { enBrowserType } from '../lib/ConstCommand';
import { cancelTimer, initializeTimer } from '../lib/TimeOutAlert';

import ConfigMyBroadcastLiveListContainer from './live/ConfigMyBroadcastLiveListContainer';
import MyBroadcastLiveListContainer from './live/MyBroadcastLiveListContainer';

import SearchLiveList from '../components/live/modal/SearchLiveList';
import CreateLive from '../components/live/modal/CreateLive';
import BroadcastLiveMember from '../components/live/modal/BroadcastLiveMember';
import OffAirLive from '../components/live/modal/OffAirLive';
import NotifyLiveBroadcast from '../components/live/modal/NotifyLiveBroadcast';
import SendMixedClassLink from '../components/live/modal/SendMixedClassLink';

import RightSideModal from '../components/template/modal/RightSideModal';
import ToppedModal from '../components/template/modal/ToppedModal';
import CenteredModal from '../components/template/modal/CenteredModal';
import AlertErrorModal from '../components/live/modal/AlertErrorModal';

import {
    changeFalseToGotoLiveList
} from '../modules/chat';

import {
    callRequestAssignUser,
    callCancelRequestAssignUser,
    callClearAssignUser
} from '../modules/studio';

import {
    checkCode,
    createCode,
    createLive,
    stopLive,
    onAirLive,
    offTheAirLive,
    activeHandsUp,
    inactiveHandsUp,
    joinMyBroadcastLive,
    updateLiveMember,
    updateLiveGroupLimit,
    notifyLiveBroadcast,
    notifyLiveBroadcastWithGuest,
    getMixedClassMailInfo,
    requestUpdateMixedClassStatus,
    changeFalseToCertifiedLiveCode,
    handsupOpenToggle,
    openGroupStudyWindow,
    closeGroupStudyWindow,
    searchLiveListResult,
    setMessageString,
    closeModal,
    closeErrorAlertModal,
    flaseToInitTimer,
    falseToCertifiedLiveCode,
    toggleBroadcastTimeNotify,
    toggle_sendMixedClassLink,
    toggle_createBroadcastLive,
    toggle_notifyLiveBroadcast,
    toggle_offAirBroadcastLive,
    toggle_showBroadcastLiveMember,
    toggle_configBroadcastLiveList,
    toggle_searchBroadcastLiveList,
} from '../modules/live';

class BroadcastLiveListContainer extends Component {
    componentDidMount() {
        if (this.props.isGotoLiveList) {
            this.props.changeFalseToGotoLiveList();
        }

        if (this.props.isConfigLiveListModalOpened) {
            this.props.closeModal("NONE");
        }
    }

    handleSearchValue = ({ keyword }) => {
        console.log("handleSearchValue - keyword => ", keyword);
        let newKeyword = keyword.toLowerCase();
        console.log("handleSearchValue - newKeyword => ", newKeyword);

        try {
            let result = this.props.list_live.filter((live) => {
                return live.liveName.toLowerCase().indexOf(newKeyword) > -1;
            });

            console.log("handleSearchValue - result => ", result);

            this.props.searchLiveListResult({ result });
        } catch (err) {
            console.log("handleSearchValue() - err => ", err);
        }
    }

    handleSelectedFunc = ({ selectedFunc, liveSeq, liveName, liveCode, list_member, memberSeq, studioSeq, groupLimit, data, list_broadcast, message }) => {
        switch (selectedFunc) {
            case "GO_TO_LIVE_PAGE":
                this.props.routeProps.history.push(`/live/${liveSeq}`);
                break;

            case "NONE":
                this.props.closeModal(selectedFunc);
                break;

            case "SEARCH":
                this.props.toggle_searchBroadcastLiveList(selectedFunc);
                break;

            case "CONFIG":
                this.props.toggle_configBroadcastLiveList(selectedFunc);
                break;

            case "NOTIFY":
                this.props.toggle_notifyLiveBroadcast(selectedFunc);
                break;

            case "NOTIFY_OK":
                if (data.list_guest && data.list_guest.length > 0) {
                    this.props.notifyLiveBroadcastWithGuest({
                        selectedFunc,
                        liveSeq,
                        userSeq: this.props.userSeq,
                        data,
                        mode: 'hiclass'
                    });
                } else {
                    this.props.notifyLiveBroadcast({
                        selectedFunc,
                        liveSeq,
                        userSeq: this.props.userSeq,
                        data,
                        mode: 'hiclass'
                    });
                }
                break;

            case "SEND_MIXED_CLASS_MAIL":
                this.props.toggle_sendMixedClassLink(selectedFunc);
                break;

            case "SEND_MIXED_CLASS_MAIL_OK":
                this.props.getMixedClassMailInfo({
                    selectedFunc,
                    localLiveSeq: liveSeq,
                    userNickname: this.props.userNickname,
                    liveName
                });
                break;

            case "JOIN":
                this.props.joinMyBroadcastLive({
                    liveSeq,
                    userSeq: this.props.userSeq,
                    userNickname: this.props.userNickname,
                    liveName
                });
                break;

            case "GET_CODE":
                this.props.createCode({
                    mode: 'hiclass'
                });
                break;

            case "GET_CODE_WEB":
                this.props.createCode({
                    mode: 'web'
                });
                break;

            case "CHECK":
                this.props.checkCode({
                    liveCode,
                    mode: 'hiclass'
                });
                break;

            case "CHECK_WEB":
                this.props.checkCode({
                    liveCode,
                    mode: 'web'
                });
                break;

            case "CREATE":
                this.props.toggle_createBroadcastLive(selectedFunc);
                break;

            case "CREATE_OK":
                this.props.createLive({
                    chatRoomSeq: this.props.performChatRoomInfo.chatRoomSeq,
                    teacherSeq: this.props.userSeq,
                    liveName,
                    liveCode,
                    mode: 'hiclass'
                });
                break;

            case "CREATE_OK_WEB":
                this.props.createLive({
                    chatRoomSeq: this.props.performChatRoomInfo.chatRoomSeq,
                    teacherSeq: this.props.userSeq,
                    liveName,
                    liveCode,
                    mode: 'web'
                });
                break;

            case "STOP_LIVE":
                this.props.stopLive({
                    userSeq: this.props.userSeq
                });
                break;

            case "ONAIR":
                if (liveName.length > 8) {
                    liveName = liveName.substring(0, 6) + "...";
                }

                if (this.props.connectedStudioInfo === null) {
                    this.props.onAirLive({
                        selectedFunc,
                        liveSeq,
                        userSeq: this.props.userSeq,
                        userNickname: this.props.userNickname,
                        mode: 'hiclass',
                        groupLimit,
                        msgText: "클래스 [" + liveName + "] ON",
                        isChattingLock: this.props.isChattingLock,
                        isAndroidTouchScreenLock: this.props.isAndroidTouchScreenLock
                    });
                } else {
                    this.props.onAirLive({
                        selectedFunc,
                        liveSeq,
                        userSeq: this.props.userSeq,
                        userNickname: this.props.userNickname,
                        mode: 'hiclass',
                        groupLimit,
                        msgText: "클래스 [" + liveName + "] ON",
                        studioSeq: this.props.connectedStudioInfo.studioSeq,
                        isChattingLock: this.props.isChattingLock,
                        isAndroidTouchScreenLock: this.props.isAndroidTouchScreenLock
                    });
                }

                initializeTimer(liveSeq, list_broadcast);
                break;

            case "OFFAIR_TOGGLE":
                this.props.toggle_offAirBroadcastLive({ selectedFunc, liveSeq });
                break;

            case "OFFAIR":
                if (liveName.length > 8) {
                    liveName = liveName.substring(0, 6) + "...";
                }

                if (this.props.serverUserSeq >= 0) {
                    this.props.offTheAirLive({
                        selectedFunc,
                        liveSeq,
                        userSeq: this.props.userSeq,
                        serverUserSeq: this.props.serverUserSeq,
                        mode: 'hiclass',
                        msgText: "클래스 [" + liveName + "] OFF"
                    });
                } else {
                    this.props.offTheAirLive({
                        selectedFunc,
                        liveSeq,
                        userSeq: this.props.userSeq,
                        mode: 'hiclass',
                        msgText: "클래스 [" + liveName + "] OFF"
                    });
                }
                cancelTimer(liveSeq);
                break;

            case "OPEN_GROUP_STUDY_WINDOW":
                this.props.openGroupStudyWindow({
                    userSeq: this.props.userSeq
                });
                break;

            case "CLOSE_GROUP_STUDY_WINDOW":
                this.props.closeGroupStudyWindow({
                    userSeq: this.props.userSeq
                });
                break;

            case "OPEN_HANDS_UP_WINDOW":
            case "CLOSE_HANDS_UP_WINDOW":
                this.props.handsupOpenToggle({
                    userSeq: this.props.userSeq
                });
                break;

            case "SELECT_ACTIVE_LIVE":
                if (this.props.connectedStudioInfo === null) {
                    this.props.activeHandsUp({
                        liveSeq,
                        userSeq: this.props.userSeq,
                        groupLimit,
                        activeYN: 'Y'
                    });
                } else {
                    this.props.activeHandsUp({
                        liveSeq,
                        userSeq: this.props.userSeq,
                        studioSeq: this.props.connectedStudioInfo.studioSeq,
                        groupLimit,
                        activeYN: 'Y'
                    });
                }
                break;

            case "UNSELECT_ACTIVE_LIVE":
                if (this.props.connectedStudioInfo === null) {
                    this.props.inactiveHandsUp({
                        liveSeq,
                        userSeq: this.props.userSeq,
                        activeYN: 'N'
                    });
                } else {
                    this.props.inactiveHandsUp({
                        liveSeq,
                        userSeq: this.props.userSeq,
                        studioSeq: this.props.connectedStudioInfo.studioSeq,
                        activeYN: 'N'
                    });
                }
                break;

            case "MEMBER":
                this.props.toggle_showBroadcastLiveMember({
                    selectedFunc,
                    liveSeq,
                    liveName,
                    list_member
                });
                break;

            case "MEMBER_ACCEPT":
                this.props.updateLiveMember({
                    liveSeq,
                    userSeq: memberSeq,
                    liveCertiYN: 'Y',
                    mode: 'hiclass'
                });
                break;

            case "MEMBER_ACCEPT_WEB":
                this.props.updateLiveMember({
                    liveSeq,
                    userSeq: memberSeq,
                    liveCertiYN: 'Y',
                    mode: 'web'
                });
                break;

            case "MEMBER_REJECT_WEB":
                this.props.updateLiveMember({
                    liveSeq: liveSeq,
                    userSeq: memberSeq,
                    liveCertiYN: 'R',
                    mode: 'web'
                });
                break;

            case "TOGGLE_CERTIFIED_CODE":
                this.props.changeFalseToCertifiedLiveCode({ mode: 'hiclass' });
                break;

            case "TOGGLE_CERTIFIED_CODE_WEB":
                this.props.changeFalseToCertifiedLiveCode({ mode: 'web' });
                break;

            case "REQUEST_CON":
                this.props.callRequestAssignUser({
                    studioSeq,
                    userSeq: this.props.userSeq
                });
                break;

            case "CANCEL_REQUEST":
                this.props.callCancelRequestAssignUser({
                    studioSeq,
                    userSeq: this.props.userSeq
                });
                break;

            case "CLEAR_ASSIGN":
                if (this.props.list_live && this.props.list_live.length > 0) {
                    const list_onAirLive = this.props.list_live.filter(info => info.liveStatus === 'Y');
                    if (list_onAirLive && list_onAirLive.length > 0) {
                        let list_onAirLiveSeq = list_onAirLive.map(row => {
                            return row.liveSeq
                        });
                        this.props.callClearAssignUser({
                            studioSeq,
                            userSeq: this.props.userSeq,
                            list_live: list_onAirLiveSeq
                        });
                    } else {
                        this.props.callClearAssignUser({
                            studioSeq,
                            userSeq: this.props.userSeq,
                            list_live: []
                        });
                    }
                } else {
                    this.props.callClearAssignUser({
                        studioSeq,
                        userSeq: this.props.userSeq,
                        list_live: []
                    });
                }
                break;

            case "UPDATE_GROUP_LIMIT":
                this.props.updateLiveGroupLimit({
                    userSeq: this.props.userSeq,
                    liveSeqArr: liveSeq,
                    groupLimit
                });
                break;

            case "TOGGLE_BROADCAST_NOTIFY":
                this.props.toggleBroadcastTimeNotify({
                    isShowing: false,
                    notifyInfo: {}
                });
                break;

            case "UPDATE_MIXED_CLASS_STATUS":
                data.userSeq = this.props.userSeq;
                data.serverUserSeq = this.props.serverUserSeq;
                this.props.requestUpdateMixedClassStatus(data);
                break;

            case "ALERT_ERROR_TOGGLE":
                this.props.closeErrorAlertModal();
                break;

            case "MESSAGE":
                this.props.setMessageString(message);
                break;

            default:
                break;
        }
    }

    render() {
        if (this.props.browserType === enBrowserType.HiClass) {
            if (this.props.isGotoChatting) {
                return <Redirect to='/chatting_hiclass' />;
            } if (this.props.isGotoChatList) {
                return <Redirect to='/list_chat_hiclass' />;
            } else if (this.props.isGotoFriendList) {
                return <Redirect to='/list_friend_hiclass' />;
            }

            return (
                this.props.isConfigLiveListModalOpened === true ?
                    <ConfigMyBroadcastLiveListContainer
                        alertKind={this.props.alertKind}
                        alertMessage={this.props.alertMessage}
                        handleSelectedFunc={this.handleSelectedFunc}
                    /> :
                    <>
                        {
                            this.props.isOffAirLiveModalOpened &&
                            <CenteredModal modalClose={this.handleSelectedFunc}>
                                <OffAirLive
                                    selectedLiveInfo={this.props.selectedLiveInfo}
                                    handleSelectedFunc={this.handleSelectedFunc}
                                />
                            </CenteredModal>
                        } {
                            this.props.isSearchLiveListModalOpened &&
                            <RightSideModal modalClose={this.handleSelectedFunc}>
                                <SearchLiveList
                                    list_live={this.props.list_live}
                                    list_searchLive={this.props.list_searchLive}
                                    handleSearchValue={this.handleSearchValue}
                                    handleSelectedFunc={this.handleSelectedFunc}
                                />
                            </RightSideModal>
                        } {
                            this.props.isCreateLiveModalOpened &&
                            <ToppedModal modalClose={this.handleSelectedFunc}>
                                <CreateLive
                                    isCertifiedLiveCode={this.props.isCertifiedLiveCode}
                                    createdLiveCode={this.props.createdLiveCode}
                                    alertMessage={this.props.alertMessage}
                                    alertKind={this.props.alertKind}
                                    handleSelectedFunc={this.handleSelectedFunc}
                                />
                            </ToppedModal>
                        } {
                            this.props.isShowLiveMemberModalOpened &&
                            <RightSideModal modalClose={this.handleSelectedFunc}>
                                <BroadcastLiveMember
                                    selectedLiveInfo={this.props.selectedLiveInfo}
                                    handleSelectedFunc={this.handleSelectedFunc}
                                />
                            </RightSideModal>
                        } {
                            this.props.isNotifyLiveBroadcastModalOpened &&
                            <ToppedModal modalClose={this.handleSelectedFunc}>
                                <NotifyLiveBroadcast
                                    list_live={this.props.list_live}
                                    alertMessage={this.props.alertMessage}
                                    alertKind={this.props.alertKind}
                                    handleSelectedFunc={this.handleSelectedFunc}
                                />
                            </ToppedModal>
                        } {
                            this.props.isSendMixedClassLinkModalOpened &&
                            <ToppedModal modalClose={this.handleSelectedFunc}>
                                <SendMixedClassLink
                                    list_live={this.props.list_live}
                                    alertMessage={this.props.alertMessage}
                                    alertKind={this.props.alertKind}
                                    handleSelectedFunc={this.handleSelectedFunc}
                                />
                            </ToppedModal>
                        } {
                            this.props.isErrorAlertModalOpened &&
                            <CenteredModal modalClose={this.handleSelectedFunc}>
                                <AlertErrorModal
                                    alertMessage={this.props.alertMessage}
                                    handleSelectedFunc={this.handleSelectedFunc}
                                />
                            </CenteredModal>
                        }
                        <MyBroadcastLiveListContainer
                            browserType={this.props.browserType}
                            selectedFunc={this.props.selectedFunc}
                            handleSelectedFunc={this.handleSelectedFunc}
                        />
                    </>
            );
        } else {
            return (
                <MyBroadcastLiveListContainer
                    routeProps={this.props.routeProps}
                    browserType={this.props.browserType}
                    handleSelectedFunc={this.handleSelectedFunc}
                />
            );
        }
    }
}

export default connect(
    ({ chat, live, user, studio }) => ({
        isLoginYn                       : user.isLoginYn,
        userSeq                         : user.userSeq,
        userNickname                    : user.userNickname,
        serverUserSeq                   : user.serverUserSeq,
        performChatRoomInfo             : chat.performChatRoomInfo,

        isGotoChatList                  : chat.isGotoChatList,
        isGotoFriendList                : chat.isGotoFriendList,
        isGotoChatting                  : chat.isGotoChatting,
        isGotoLiveList                  : chat.isGotoLiveList,

        selectedLiveInfo                : live.selectedLiveInfo,

        list_live                       : live.list_live,
        list_searchLive                 : live.list_searchLive,

        isCertifiedLiveCode             : live.isCertifiedLiveCode,
        createdLiveCode                 : live.createdLiveCode,
        alertMessage                    : live.alertMessage,
        alertKind                       : live.alertKind,

        selectedFunc                    : live.selectedFunc,

        isNeedInitTimer                 : live.isNeedInitTimer,

        connectedStudioInfo             : studio.connectedStudioInfo,

        isOffAirLiveModalOpened         : live.isOffAirLiveModalOpened,
        isSearchLiveListModalOpened     : live.isSearchLiveListModalOpened,
        isCreateLiveModalOpened         : live.isCreateLiveModalOpened,
        isNotifyLiveBroadcastModalOpened: live.isNotifyLiveBroadcastModalOpened,
        isShowLiveMemberModalOpened     : live.isShowLiveMemberModalOpened,
        isConfigLiveListModalOpened     : live.isConfigLiveListModalOpened,
        isSendMixedClassLinkModalOpened : live.isSendMixedClassLinkModalOpened,
        isErrorAlertModalOpened         : live.isErrorAlertModalOpened,

        isChattingLock                  : live.isChattingLock,
        isAndroidTouchScreenLock        : live.isAndroidTouchScreenLock,
    }),
    {
        checkCode,
        createCode,
        createLive,

        stopLive,
        onAirLive,
        offTheAirLive,
        activeHandsUp,
        inactiveHandsUp,
        joinMyBroadcastLive,

        updateLiveMember,
        updateLiveGroupLimit,

        notifyLiveBroadcast,
        notifyLiveBroadcastWithGuest,

        getMixedClassMailInfo,
        requestUpdateMixedClassStatus,

        handsupOpenToggle,
        openGroupStudyWindow,
        closeGroupStudyWindow,

        changeFalseToGotoLiveList,
        changeFalseToCertifiedLiveCode,

        searchLiveListResult,
        setMessageString,

        callRequestAssignUser,
        callCancelRequestAssignUser,
        callClearAssignUser,

        closeModal,
        closeErrorAlertModal,
        flaseToInitTimer,
        falseToCertifiedLiveCode,

        toggleBroadcastTimeNotify,
        toggle_sendMixedClassLink,
        toggle_createBroadcastLive,
        toggle_notifyLiveBroadcast,
        toggle_offAirBroadcastLive,
        toggle_showBroadcastLiveMember,
        toggle_configBroadcastLiveList,
        toggle_searchBroadcastLiveList,
    }
)(BroadcastLiveListContainer);