import React from 'react';

const MainTemplate = ({children}) => {
    return( 
        <div className="d-flex flex-column hiclasstv-template">
            {children}
        </div>
    );
}

export default MainTemplate;