import React, { useState } from 'react';
import GroupInfo from './etc/GroupInfo';

const DisplayList = ({ list_display, groupLimit }) => {

    return (
        <div className="list-group scroll-show-active light" style={{overflow:'auto'}}>
            {
                list_display !== undefined && list_display !== null && list_display.length > 0 ?
                    list_display.map(
                        (display, idx) => (
                            <div className="d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" key={'display_' + display.displaySeq}>
                                <div className="text-area flex-column align-items-start">
                                    <div className="text-area justify-content-start">
                                        <span className="hiclass-text" title={'Display - ' + idx}><b>{'Display - ' + idx}</b></span>
                                        {
                                            display.displayStatus === 'Y' ?
                                                <span className="hiclass-text green white-text small px-1 ml-1">ON</span> :
                                                <span className="hiclass-text hiclasstv-grey white-text small px-1 ml-1">OFF</span>
                                        }
                                    </div>
                                    <GroupInfo groupLimit={groupLimit} groupNum={display.groupNum} />
                                </div>
                            </div>
                        )
                    ) :
                    <div className="view p-2 text-center grey-text">
                        스튜디오와 연결된 디스플레이 목록이 없습니다.
                    </div>
            }
        </div>
    )
}
export default DisplayList;