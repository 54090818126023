import { createAction, handleActions } from "redux-actions"
import produce from "immer";

import createRequestThunk from "../lib/createRequestThunk";
import ConstData from "../lib/ConstData";

import { enMQType, enDrawingMode, ctRcvKind, enTokenCMD, ctBubbleKind } from "../lib/classTalk/CodeEnum";
import { ctWebActionType, stCardType, stCardItemType, ctMsgType } from "../lib/ConstCommand";

import { upload_file } from "../lib/api/file";
import { sendChatMsg, sendWhisperMsg, sendChatInkMemo, sendChatVoiceMemo, sendInkStroke, sendChangeInkColor, 
         sendChangeInkTipSize, sendChangeInkMode/*,sendClearInkStroke */ } from "../lib/RabbitmqClient";
import { checkUrlText, getUrlText } from "../lib/func/ExportFunction";

export const TALK_LIVE_STATE_INIT                   = "classTalkLive/TALK_LIVE_STATE_INIT";
export const FINISH_LIVE_STATE_INIT                 = "classTalkLive/FINISH_LIVE_STATE_INIT";

export const APPEND_WATSON_MESSAGE_LIVE             = "classTalkLive/APPEND_WATSON_MESSAGE_LIVE";
export const APPEND_QUIZ_MESSAGE_LIVE               = "classTalkLive/APPEND_QUIZ_MESSAGE_LIVE";
export const CHANGE_QUIZ_MESSAGE_LIVE               = "classTalkLive/CHANGE_QUIZ_MESSAGE_LIVE";
export const APPEND_ALERT_LIVE_MESSAGE              = "classTalkLive/APPEND_ALERT_LIVE_MESSAGE";

const APPEND_SEND_MESSAGE                           = "classTalkLive/APPEND_SEND_MESSAGE";
const APPEND_SEND_INK_MEMO                          = "classTalkLive/APPEND_SEND_INK_MEMO";
const APPEND_SEND_AUDIO_MEMO                        = "classTalkLive/APPEND_SEND_AUDIO_MEMO";
const APPEND_SEND_FILE_SHARE                        = "classTalkLive/APPEND_SEND_FILE_SHARE";
const APPEND_SEND_FILE_SHARE_SUCCESS                = "classTalkLive/APPEND_SEND_FILE_SHARE_SUCCESS";
const APPEND_RECEIVE_MESSAGE                        = "classTalkLive/APPEND_RECEIVE_MESSAGE";

export const appendSendMessage                      = createAction(APPEND_SEND_MESSAGE);
export const appendSendAudioMemo                    = createAction(APPEND_SEND_AUDIO_MEMO);
export const appendSendInkMemo                      = createAction(APPEND_SEND_INK_MEMO);
export const appendSendFileShare                    = createRequestThunk(APPEND_SEND_FILE_SHARE, upload_file);
export const appendReceiveMessage                   = createAction(APPEND_RECEIVE_MESSAGE);

const OPEN_WHISPER_MODAL                            = "classTalkLive/OPEN_WHISPER_MODAL";
const RESPOND_WHISPER                               = "classTalkLive/RESPOND_WHISPER";
const CLOSE_WHISPER_MODAL                           = "classTalkLive/CLOSE_WHISPER_MODAL";
const WEB_IS_WHSPER_MODE                            = "classTalkLive/WEB_IS_WHSPER_MODE";

const SET_PASS_POSSIBLE                             = "classTalkLive/SET_PASS_POSSIBLE";

const TOGGLE_FILE_SHARE_MESSAGE                     = "classTalkLive/TOGGLE_FILE_SHARE_MESSAGE";

export const openWhisperModal                       = createAction(OPEN_WHISPER_MODAL);
export const respondWhisper                         = createAction(RESPOND_WHISPER);
export const closeWhisperModal                      = createAction(CLOSE_WHISPER_MODAL);
export const webIsWhisperMode                       = createAction(WEB_IS_WHSPER_MODE);

export const setPassPossible                        = createAction(SET_PASS_POSSIBLE);

export const toggleFileShareMessage                 = createAction(TOGGLE_FILE_SHARE_MESSAGE);

const EMOTICON_TOGGLE_OPENED                        = "classTalkLive/EMOTICON_TOGGLE_OPENED";
const EMOTICON_SELECTED_CANCEL                      = "classTalkLive/EMOTICON_SELECT_CANCEL";
const SELECT_EMOTICON_SEQ                           = "classTalkLive/SELECT_EMOTICON_SEQ";

const ADD_FUNCTION_TOGGLE_OPENED                    = "classTalkLive/ADD_FUNCTION_TOGGLE_OPENED";
const SELECT_FUNCTION_SEQ                           = "classTalkLive/SELECT_FUNCTION_SEQ";

const VOICE_MEMO_TOGGLE_CLOSED                      = "classTalkLive/VOICE_MEMO_TOGGLE_CLOSED";

const INK_MEMO_TOGGLE_OPENED                        = "classTalkLive/INK_MEMO_TOGGLE_OPENED";
const INK_MEMO_TOGGLE_CLOSED                        = "classTalkLive/INK_MEMO_TOGGLE_CLOSED";
const INK_MEMO_TOOL                                 = "classTalkLive/INK_MEMO_TOOL";

const TOGGLE_CLASSTALK                              = "classTalkLive/TOGGLE_CLASSTALK";
const TOGGLE_INK_TOOL_COLOR_PAD                     = "classTalkLive/TOGGLE_INK_TOOL_COLOR_PAD";
const TOGGLE_INK_TOOL_TIP_SIZE_PAD                  = "classTalkLive/TOGGLE_INK_TOOL_TIP_SIZE_PAD";

// function menu
export const emoticonSelectedToggle             = createAction(EMOTICON_TOGGLE_OPENED);
export const emoticonSelectedCancel             = createAction(EMOTICON_SELECTED_CANCEL);
export const selectEmoticonSeq                  = createAction(SELECT_EMOTICON_SEQ);

export const addFunctionOpenedToggle            = createAction(ADD_FUNCTION_TOGGLE_OPENED);
export const selectFunctionSeq                  = createAction(SELECT_FUNCTION_SEQ);

export const voiceMemoClosedToggle              = createAction(VOICE_MEMO_TOGGLE_CLOSED);

export const inkMemoOpenedToggle                = createAction(INK_MEMO_TOGGLE_OPENED);
export const inkMemoClosedToggle                = createAction(INK_MEMO_TOGGLE_CLOSED);
export const inkMemo_changeTool                 = createAction(INK_MEMO_TOOL);

// ------------ ink stroke conversation (hiclass <-> web) ------------------------ //
const SET_IMAGE_SCALE                           = "classTalkLive/SET_IMAGE_SCALE";
const SET_IMAGE_OFFSET                          = "classTalkLive/SET_IMAGE_OFFSET";

const INK_DRAWING_TOOL_MODE                     = "classTalkLive/INK_DRAWING_TOOL_MODE";
const INK_DRAWING_TOOL_COLOR                    = "classTalkLive/INK_DRAWING_TOOL_COLOR";
const INK_DRAWING_TOOL_THICK                    = "classTalkLive/INK_DRAWING_TOOL_THICK";
const INK_DRAWING_CLEAR                         = "classTalkLive/INK_DRAWING_CLEAR";
const INK_DRAWING_UNDO                          = "classTalkLive/INK_DRAWING_UNDO";
const INK_DRAWING_REDO                          = "classTalkLive/INK_DRAWING_REDO";
const INK_UNDO_REDO_SUCCESS                     = "calssTalkLive/INK_UNDO_REDO_SUCCESS";
const SET_UNDO_STATUS                           = "classTalkLive/SET_UNDO_STATUS";
const SET_REDO_STATUS                           = "classTalkLive/SET_REDO_STATUS";
const SET_DRAWING_STATUS                        = "classTalkLive/SET_DRAWING_STATUS";

const CHANGE_TOOLBAR_Z_INDEX                    = "classTalkLive/CHANGE_TOOLBAR_Z_INDEX";
const CLICK_OTHER_ELEMENT                       = "classTalkLive/CLICK_OTHER_ELEMENT";

export const changePerformLiveScaleValue        = createAction(SET_IMAGE_SCALE);
export const changePerformLiveOffsetValue       = createAction(SET_IMAGE_OFFSET);
export const changeDrawingMode                  = createAction(INK_DRAWING_TOOL_MODE);
export const changeToolColor                    = createAction(INK_DRAWING_TOOL_COLOR);
export const changeToolThick                    = createAction(INK_DRAWING_TOOL_THICK);
export const toggle_classTalk                   = createAction(TOGGLE_CLASSTALK);
export const toggleInkToolColorPad              = createAction(TOGGLE_INK_TOOL_COLOR_PAD);
export const toggleInkTipSizePad                = createAction(TOGGLE_INK_TOOL_TIP_SIZE_PAD);

export const clearDrawingCanvas                 = createAction(INK_DRAWING_CLEAR);
export const undoDrawingStroke                  = createAction(INK_DRAWING_UNDO);
export const redoDrawingStroke                  = createAction(INK_DRAWING_REDO);
export const undoRedoSuccess                    = createAction(INK_UNDO_REDO_SUCCESS);
export const setUndoStatus                      = createAction(SET_UNDO_STATUS);
export const setRedoStatus                      = createAction(SET_REDO_STATUS);
export const setDrawingStatus                   = createAction(SET_DRAWING_STATUS);

export const clickElement                       = createAction(CLICK_OTHER_ELEMENT);

const MQ_SEND_INK_STROKE                        = "classTalkLive/MQ_SEND_INK_STROKE";
const MQ_RCV_ERASE_INK_STROKE                   = "classTalkLive/MQ_RCV_ERASE_INK_STROKE";
const MQ_RCV_CLEAR_INK_STROKE                   = "classTalkLive/MQ_RCV_CLEAR_INK_STROKE";
const MQ_RCV_CHANGE_INK_ATTR                    = "classTalkLive/MQ_RCV_CHANGE_INK_ATTR";
const MQ_RCV_CHANGE_INK_MODE                    = "classTalkLive/MQ_RCV_CHANGE_INK_MODE";
const MQ_RCV_CHANGE_INK_COLOR                   = "classTalkLive/MQ_RCV_CHANGE_INK_COLOR";
const MQ_RCV_CHANGE_INK_TIP_SIZE                = "classTalkLive/MQ_RCV_CHANGE_INK_TIP_SIZE";
const MQ_RCV_DRAW_INK_STROKE                    = "classTalkLive/MQ_RCV_DRAW_INK_STROKE";
const MQ_RCV_DRAW_INK_STROKES                   = "classTalkLive/MQ_RCV_DRAW_INK_STROKES";
const MQ_RCV_DRAW_INK_STROKE_DOWN               = "classTalkLive/MQ_RCV_DRAW_INK_STROKE_DOWN";
const MQ_RCV_DRAW_INK_STROKE_MOVE               = "classTalkLive/MQ_RCV_DRAW_INK_STROKE_MOVE";
const MQ_RCV_DRAW_INK_STROKE_DOUBLE_MOVE        = "classTalkLive/MQ_RCV_DRAW_INK_STROKE_DOUBLE_MOVE";
const MQ_RCV_DRAW_INK_STROKE_UP                 = "classTalkLive/MQ_RCV_DRAW_INK_STROKE_UP";

const MQ_RCV_DRAW_CANVAS_IMAGE                  = "classTalkLive/MQ_RCV_DRAW_CANVAS_IMAGE";
const MQ_RCV_CLEAR_PAGE                         = "classTalkLive/MQ_RCV_CLEAR_PAGE";

// send & rcv ink stroke 
export const mqSendInkStroke                    = createAction(MQ_SEND_INK_STROKE);
export const mqClearInkStroke                   = createAction(MQ_RCV_CLEAR_INK_STROKE);
export const mqChangeInkAttr                    = createAction(MQ_RCV_CHANGE_INK_ATTR);
export const mqChangeInkMode                    = createAction(MQ_RCV_CHANGE_INK_MODE);
export const mqChangeInkColor                   = createAction(MQ_RCV_CHANGE_INK_COLOR);
export const mqChangeInkTipSize                 = createAction(MQ_RCV_CHANGE_INK_TIP_SIZE);
export const mqEraseInkStroke                   = createAction(MQ_RCV_ERASE_INK_STROKE);
export const mqDrawInkStroke                    = createAction(MQ_RCV_DRAW_INK_STROKE);
export const mqDrawInkStrokes                   = createAction(MQ_RCV_DRAW_INK_STROKES);
export const mqDrawInkStrokeDown                = createAction(MQ_RCV_DRAW_INK_STROKE_DOWN);
export const mqDrawInkStrokeMove                = createAction(MQ_RCV_DRAW_INK_STROKE_MOVE);
export const mqDrawInkStrokeDoubleMove          = createAction(MQ_RCV_DRAW_INK_STROKE_DOUBLE_MOVE);
export const mqDrawInkStrokeUp                  = createAction(MQ_RCV_DRAW_INK_STROKE_UP);

export const mqDrawCanvasImage                  = createAction(MQ_RCV_DRAW_CANVAS_IMAGE);
export const mqClearPage                        = createAction(MQ_RCV_CLEAR_PAGE);

// ------------------------------------------------------------------------------- //

// ------------ ink stroke conversation (android service -> web) ----------------- //
const ANDROID_PEN_POINT_DOWN                    = "classTalkLive/ANDROID_PEN_POINT_DOWN";
const ANDROID_PEN_POINT_MOVE                    = "classTalkLive/ANDROID_PEN_POINT_MOVE";
const ANDROID_PEN_POINT_DOUBLE_MOVE             = "classTalkLive/ANDROID_PEN_POINT_DOUBLE_MOVE";
const ANDROID_PEN_POINT_UP                      = "classTalkLive/ANDROID_PEN_POINT_UP";

// android pen point
export const androidPenDown                     = createAction(ANDROID_PEN_POINT_DOWN);
export const androidPenMove                     = createAction(ANDROID_PEN_POINT_MOVE);
export const androidPenDoubleMove               = createAction(ANDROID_PEN_POINT_DOUBLE_MOVE);
export const androidPenUp                       = createAction(ANDROID_PEN_POINT_UP);
// ------------------------------------------------------------------------------- //

const initialState = {
    isEmoticonSelected          : false,
    selectedEmoticonSeq         : -1,

    audioStatus                 : "STOPED",
    isAddFunctionOpened         : false,
    isAudioOpened               : false,

    isInkMemoOpened             : false,
    toolMode                    : enDrawingMode.Pen,
    inkMemoFile                 : "",

    classTalkVisibility         : "hidden",

    web_isWhisper               : false,
    isWhisperModalOpened        : false,
    whisperMsgInfo              : "",

    performLiveImgViewSize      : { width: ConstData.SCREEN_RATE.WIDTH, 
                                    height: ConstData.SCREEN_RATE.HEIGHT },
    performLiveImgOffset        : { x: 0, y: 0 },

    isDrawingInkStroke          : false,
    isClearInkStroke            : false,
    isUndo                      : false,
    isRedo                      : false,
    isPossibleUndo              : false,
    isPossibleRedo              : false,
    drawingMode                 : enDrawingMode.Hand,
    tipSize                     : 2,
    tipColor                    : { R: 0, G: 0, B: 0 },

    penTipSize                  : 2,
    penTipColor                 : { R: 0, G: 0, B: 0 },
    markerTipSize               : (4 * 1.5),
    markerTipColor              : { R: 255, G: 255, B: 0 },

    isColorPadOpened            : false,
    isTipSizePadOpened          : false,

    rcvCmd                      : 0,
    rcvMode                     : enDrawingMode.Pen,
    rcvColor                    : { R: 255, G: 0, B: 0 },
    rcvWidth                    : 2.5,
    rcvPoint1                   : { x: 0, y: 0 },
    rcvPoint2                   : { x: 0, y: 0 },
    rcvStroke                   : [],
    rcvStrokes                  : [],

    androidPenPath              : {
        cmd                     : enTokenCMD.NULL,
        point1                  : { x: 0, y: 0 },
        point2                  : { x: 0, y: 0 }
    },

    receivedMsg                 : [
        /* {
            kind: "rcv",
            msgInfo: {
                kind: "card_template",
                rcvName: "Watson",
                cardInfo: {
                    "seq": 0,
                    "visibility": "visible",
                    "type": "audioText",
                    "data": [
                        {
                            "seq": 0,
                            "visibility": "visible",
                            "kind": "text",
                            "type": "none",
                            "data": "ㅏ"
                        },
                        {
                            "seq": 1,
                            "visibility": "visible",
                            "kind": "audio",
                            "type": "url",
                            "data": "https://test.hiclass.tv/download/SAMPLE_01/KO_CH1/lesson-02-audio.mp3"
                        }
                    ]
                }
            }
        },
        {
            kind: "rcv",
            msgInfo: {
                kind: "card_template",
                rcvName: "Watson",
                cardInfo: {
                    "seq": 0,
                    "visibility": "visible",
                    "type": "audioText",
                    "data": [
                        {
                            "seq": 0,
                            "visibility": "visible",
                            "kind": "text",
                            "type": "none",
                            "data": "ㅏ [ㅏ]"
                        },
                        {
                            "seq": 1,
                            "visibility": "visible",
                            "kind": "audio",
                            "type": "url",
                            "data": "https://test.hiclass.tv/download/SAMPLE_01/KO_CH1/lesson-04-audio.mp3"
                        },
                        {
                            "seq": 2,
                            "visibility": "visible",
                            "kind": "text",
                            "type": "none",
                            "data": "아빠 아파트 아이"
                        }
                    ]
                }
            }
        },
        {
            kind: "rcv",
            msgInfo: {
                kind: "card_template",
                rcvName: "Watson",
                cardInfo: {
                    seq: 0,
                    visibility: "visible",
                    type: "inkMedia",
                    data: [
                        {
                            seq: 0,
                            visibility: "visible",
                            kind: "inkMedia",
                            type: "url",
                            data: "https://test.hiclass.tv/download/SAMPLE_01/KO_CH1/lesson-03-inkMedia.im3"
                        }
                    ]
                }
            }
        },
        {
            kind: "rcv",
            msgInfo: {
                kind: "card_template",
                rcvName: "Watson",
                cardInfo : {
                    seq  : 0,
                    visibility : "visible",
                    type : "animatedMP3Text",
                    data : [
                        {
                            seq: 0,
                            visibility: "visible",
                            kind: "animatedMP3",
                            type: "url",
                            data: "https://test.hiclass.tv/download/SAMPLE_01/KO_CH1/lesson-01-animatedMP3.mp3"
                        },
                        {
                            seq: 1,
                            visibility: "visible",
                            kind: "text",
                            type: "none",
                            data: "한글 자모의 열다섯째 글자."
                        },
                        {
                            seq: 2,
                            visibility: "visible",
                            kind: "text",
                            type: "none",
                            data: "이름은 [아] 이다."
                        }
                    ]
                }
            }
        },
        {
            kind: "rcv",
            msgInfo: {
                kind: "card_template",
                rcvName: "Watson",
                cardInfo : {
                    seq  : 0,
                    visibility : "visible",
                    type : "audioText",
                    data : [
                        {
                            seq: 0,
                            visibility: "visible",
                            kind: "audio",
                            type: "url",
                            data: "https://test.hiclass.tv/download/CD_002/chapter_01/1524-1-creepy.mp3"
                        },
                        {
                            seq: 1,
                            visibility: "visible",
                            kind: "text",
                            type: "role-script",
                            data: { role: "Todd", script: "Obviously the first thing is that when we were young, we didn't have smart phones and things like that." }
                        },
                        {
                            seq: 2,
                            visibility: "visible",
                            kind: "text",
                            type: "role-script",
                            data: { role: "Rachel", script: "Yes." }
                        },
                        {
                            seq: 3,
                            visibility: "visible",
                            kind: "text",
                            type: "role-script",
                            data: { role: "Todd", script: "You would have to wait by the phone. Remember that, waiting by the phone. You couldn't go outside because if you were waiting for the call, you had to wait at your house until you got the call, and then-" }
                        },
                        {
                            seq: 4,
                            visibility: "visible",
                            kind: "text",
                            type: "role-script",
                            data: { role: "Rachel", script: "We didn't even have an answering machine at my house." }
                        },
                        {
                            seq: 5,
                            visibility: "visible",
                            kind: "text",
                            type: "role-script",
                            data: { role: "Todd", script: "Wow, my gosh. That's really old school." }
                        },
                        {
                            seq: 6,
                            visibility: "visible",
                            kind: "text",
                            type: "role-script",
                            data: { role: "Rachel", script: "We used to have to wait by the phone." }
                        },
                        {
                            seq: 7,
                            visibility: "visible",
                            kind: "text",
                            type: "none",
                            data: "this is text"
                        }
                    ]
                }
            }
        },
        {
            kind: "rcv",
            msgInfo: {
                kind: "card_template",
                rcvName: "Watson",
                cardInfo : {
                    seq  : 0,
                    visibility : "visible",
                    type : "quiz",
                    data : [
                        {
                            seq: 0,
                            visibility: "visible",
                            type: "question",
                            data: [
                                {
                                    seq: 0,
                                    visibility: "visible",
                                    kind: "text",
                                    type: "none",
                                    data: "빈 칸에 들어갈 글자를 찾아보세요."
                                },
                                {
                                    seq: 1,
                                    visibility: "visible",
                                    kind: "image",
                                    type: "url",
                                    data: "https://test.hiclass.tv/download/SAMPLE_01/KO_CH1/lesson-05-image.jpg"
                                }
                            ],
                            exYN: "Y",
                            exType: "text",
                            exNum: "3"
                        },
                        {
                            seq: 1,
                            visibility: "visible",
                            type: "answer",
                            data: {
                                no: "3",
                                kind: "text",
                                data: "ㅏ"
                            }
                        },
                        {
                            seq: 2,
                            visibility: "visible",
                            type: "example",
                            data: [
                                {
                                    no: "1",
                                    kind: "text",
                                    type: "none",
                                    data: "ㅡ"
                                }, {
                                    no: "2",
                                    kind: "text",
                                    type: "none",
                                    data: "ㅓ"
                                }, {
                                    no: "3",
                                    kind: "text",
                                    type: "none",
                                    data: "ㅏ"
                                }
                            ]
                        }
                    ]
                }
            }
        } */
    ],

    isInkOn                     : false,

    isClickElement              : false,
    clickCheckPoint             : { x: -1, y: -1 },
};

const classTalkLive = handleActions({
    [TALK_LIVE_STATE_INIT]: (state, action) => {
        //console.log("TALK_LIVE_STATE_INIT", action.payload);

        return {
            ...state,
            isEmoticonSelected          : false,
            selectedEmoticonSeq         : -1,

            audioStatus                 : "STOPED",
            isAddFunctionOpened         : false,
            isAudioOpened               : false,

            isClickElement              : false,

            isInkMemoOpened             : false,
            toolMode                    : enDrawingMode.Pen,
            inkMemoFile                 : "",

            isClearInkStroke            : false,
            isUndo                      : false,
            isRedo                      : false,
            isPossibleUndo              : false,
            isPossibleRedo              : false,
            drawingMode                 : enDrawingMode.Hand,
            tipSize                     : 2,
            tipColor                    : { R: 255, G: 0, B: 0 },

            penTipSize                  : 2,
            penTipColor                 : { R: 0, G: 0, B: 0 },
            markerTipSize               : 2,
            markerTipColor              : { R: 0, G: 0, B: 0 },

            classTalkVisibility         : "hidden",

            isWhisperModalOpened        : false,
            web_isWhisper               : false,
            whisperMsgInfo              : "",

            performLiveImgViewSize      : { width: ConstData.SCREEN_RATE.WIDTH, 
                                            height: ConstData.SCREEN_RATE.HEIGHT },
            performLiveImgOffset        : { x: 0, y: 0 },

            isColorPadOpened            : false,
            isTipSizePadOpened          : false,

            rcvStroke                   : [],
            rcvStrokes                  : [],

            receivedMsg                 : [],

            isInkOn                     : false,
        }
    },
    
    [FINISH_LIVE_STATE_INIT]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("FINISH_LIVE_STATE_INIT");
        
        if (isSuccessed) {
            const { isFinishSuccess } = action.payload;

            if (isFinishSuccess) {
                return {
                    ...state,
                    isEmoticonSelected          : false,
                    selectedEmoticonSeq         : -1,

                    audioStatus                 : "STOPED",
                    isAddFunctionOpened         : false,
                    isAudioOpened               : false,

                    isClickElement              : false,

                    isInkMemoOpened             : false,
                    toolMode                    : enDrawingMode.Pen,
                    inkMemoFile                 : "",

                    isClearInkStroke            : false,
                    isUndo                      : false,
                    isRedo                      : false,
                    isPossibleUndo              : false,
                    isPossibleRedo              : false,
                    drawingMode                 : enDrawingMode.Hand,
                    tipSize                     : 2,
                    tipColor                    : { R: 255, G: 0, B: 0 },

                    penTipSize                  : 2,
                    penTipColor                 : { R: 0, G: 0, B: 0 },
                    markerTipSize               : 2,
                    markerTipColor              : { R: 0, G: 0, B: 0 },

                    classTalkVisibility         : "hidden",

                    isWhisperModalOpened        : false,
                    web_isWhisper               : false,
                    whisperMsgInfo              : "",

                    performLiveImgViewSize      : { width: ConstData.SCREEN_RATE.WIDTH, 
                                                    height: ConstData.SCREEN_RATE.HEIGHT },
                    performLiveImgOffset        : { x: 0, y: 0 },

                    isColorPadOpened            : false,
                    isTipSizePadOpened          : false,

                    rcvStroke                   : [],
                    rcvStrokes                  : [],

                    receivedMsg                 : [],

                    isInkOn                     : false,
                }
            } else {
                return {
                    ...state,
                }
            }
        } else {
            return {
                ...state,
            }
        }
    },

    [APPEND_SEND_MESSAGE]: (state, action) => {
        const { msgText, userNickname/*, profileImgUrl*/ } = action.payload;
        //console.log("APPEND_SEND_MESSAGE => ", msgText);
        const { isWhisperModalOpened, web_isWhisper } = state;

        let msgInfo = "";
        let isUrlText = checkUrlText(msgText);
        let urlText = "";
        if (isUrlText) getUrlText(msgText);

        console.log("isUrlText => ", isUrlText);
        console.log("urlText => ", urlText);

        if (isWhisperModalOpened || web_isWhisper) {
            msgInfo = {
                kind : ctBubbleKind.SendWhisper,
                msgInfo : {
                    kind: "whisper",
                    rcvKind: ctRcvKind.sendWhisper,
                    rcvName : userNickname, 
                    text: "",
                    emoticSeq: -1,
                    urlInfo : { text: msgText, emoticSeq: state.selectedEmoticonSeq, isUrlText, urlText },
                    isUrlText,
                    urlText
                }
            }

            sendChatMsg("whisper", msgText, state.selectedEmoticonSeq, userNickname);
        } else {
            msgInfo = {
                kind : ctBubbleKind.SendMsg,
                msgInfo : {
                    rcvKind     : ctRcvKind.Student,
                    text        : msgText,
                    emoticSeq   : state.selectedEmoticonSeq,
                    urlInfo     : "",
                    isUrlText,
                    urlText
                }
            }

            sendChatMsg("normal", msgText, state.selectedEmoticonSeq, userNickname);
        }

        return {
            ...state,
            receivedMsg             : state.receivedMsg.concat(msgInfo),
            isWhisperModalOpened    : false,
            web_isWhisper           : false,
            isEmoticonSelected      : false,
            selectedEmoticonSeq     : -1,
            isAddFunctionOpened     : false,
        }
    },

    [APPEND_SEND_AUDIO_MEMO]: (state, action) => {
        const { userSeq, userNickname, url, data } = action.payload;
        const { isWhisperModalOpened, web_isWhisper  } = state;
        
        let msgInfo = "";
        if (isWhisperModalOpened || web_isWhisper) {
            msgInfo = {
                kind : ctBubbleKind.SendWhisper,
                msgInfo : {
                    kind: "whisper",
                    rcvKind: ctRcvKind.sendWhisper,
                    rcvName: userNickname,
                    text: "",
                    // urlInfo : { text: "음성톡", kind: "audio_memo", urlInfo: url },
                    urlInfo : { text: "음성메모", kind: "audio_memo", urlInfo: url }
                }
            }

            sendChatVoiceMemo("whisper", data, userSeq, userNickname);
        } else {
            msgInfo = {
                kind : ctBubbleKind.SendMsg,
                msgInfo             : {
                    rcvKind         : ctRcvKind.Student,
                    kind            : "audio_memo",
                    // text            : "음성톡",
                    text            : "음성메모",
                    urlInfo         : url
                }
            }

            sendChatVoiceMemo("normal", data, userSeq, userNickname);
        }

        return {
            ...state,
            receivedMsg             : state.receivedMsg.concat(msgInfo),
            isWhisperModalOpened    : false,
            web_isWhisper           : false,
            isAudioOpened           : false,
            audioStatus             : "STOPED",
            isAddFunctionOpened     : false,
        }
    },

    [APPEND_SEND_INK_MEMO]: (state, action) => {
        const { userSeq, userNickname, url, data } = action.payload;
        const { isWhisperModalOpened, web_isWhisper } = state;
        
        let msgInfo = "";
        if (isWhisperModalOpened || web_isWhisper) {
            msgInfo = {
                kind : ctBubbleKind.SendWhisper,
                msgInfo : {
                    kind: "whisper",
                    rcvKind: ctRcvKind.sendWhisper,
                    rcvName: userNickname,
                    text: "",
                    // urlInfo: { text: "메모톡", kind: "ink_memo", urlInfo: url },
                    urlInfo: { text: "잉크메모", kind: "ink_memo", urlInfo: url }
                }
            }

            sendChatInkMemo("whisper", data, userSeq, userNickname);
        } else {
            msgInfo = {
                kind                : ctBubbleKind.SendMsg,
                msgInfo             : {
                    rcvKind         : ctRcvKind.Student,
                    kind            : "ink_memo",
                    // text            : "메모톡",
                    text            : "잉크메모",
                    urlInfo         : url
                }
            }

            sendChatInkMemo("normal", data, userSeq, userNickname);
        }

        return {
            ...state,
            receivedMsg             : state.receivedMsg.concat(msgInfo),
            isWhisperModalOpened    : false,
            web_isWhisper           : false,
            isInkMemoOpened         : false,
            toolMode                : enDrawingMode.Pen,
            inkMemoFile             : "",
            isAddFunctionOpened     : false
        }
    },

    [APPEND_SEND_FILE_SHARE_SUCCESS]: (state, action) => {
        const { filename, userNickname } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("APPEND_SEND_FILE_SHARE_SUCCESS");

        if (isSuccessed) {
            const { isUploadSuccess } = action.payload;

            if (isUploadSuccess) {
                const { path } = action.payload;
                const { isWhisperModalOpened, web_isWhisper } = state;

                let msgInfo = "";
                if (isWhisperModalOpened || web_isWhisper) {
                    msgInfo = {
                        kind : ctBubbleKind.SendWhisper,
                        msgInfo : {
                            kind: "whisper",
                            rcvKind: ctRcvKind.sendWhisper,
                            rcvName: userNickname,
                            text: "",
                            urlInfo : { text: filename + "의 전송이 완료되었습니다"/*, kind: "file_share", urlInfo: path*/ }
                        }
                    }
                } else {
                    msgInfo = {
                        kind                : ctBubbleKind.SendMsg,
                        msgInfo             : {
                            rcvKind         : ctRcvKind.Student,
                            kind            : "file_share",
                            text            : filename + "의 전송이 완료되었습니다.",
                            urlInfo         : path
                        }
                    }
                }
                
                return {
                    ...state,
                    receivedMsg             : state.receivedMsg.concat(msgInfo),
                    isWhisperModalOpened    : false,
                    web_isWhisper           : false,
                    isAddFunctionOpened     : false
                }
            } else {
                console.log("APPEND_SEND_FILE_SHARE - isUploadSuccess => ", isUploadSuccess);

                return {
                    ...state
                }
            }
        } else {
            console.log("APPEND_SEND_FILE_SHARE - isSuccessed => ", isSuccessed);

            return {
                ...state
            }
        }
    },

    [APPEND_RECEIVE_MESSAGE]: (state, action) => {
        const msgInfo = action.payload;
        //console.log("LIVE - APPEND_RECEIVE_MESSAGE => ", msgInfo);

        return {
            ...state,
            receivedMsg: state.receivedMsg.concat({ kind: ctBubbleKind.RcvMsg, msgInfo })
        }
    },

    [APPEND_ALERT_LIVE_MESSAGE]: (state, action) => {
        const msgText = action.payload;
        //console.log("APPEND_ALERT_LIVE_MESSAGE => ", msgText);

        return {
            ...state,
            receivedMsg             : state.receivedMsg.concat({
                kind                : ctBubbleKind.Info,
                msgInfo             : {
                    rcvKind         : ctRcvKind.rcvPentalk,
                    rcvName         : "Pentalk",
                    emoticSeq       : state.selectedEmoticonSeq,
                    text            : msgText,
                    urlInfo         : ""
                }
            }),
            isEmoticonSelected      : false,
            selectedEmoticonSeq     : -1,
            isAddFunctionOpened     : false,
        }
    },

    [OPEN_WHISPER_MODAL]: (state, action) => {
        const { data, idx } = action.payload;

        if (data !== undefined && data !== null && data.kind === "whisper") {
            if (data.urlInfo && data.urlInfo !== "") {
                let msgInfo = {
                    idx,
                    kind        : data.urlInfo.kind,
                    sender      : data.rcvName,
                    emoticSeq   : data.urlInfo.emoticSeq ? data.urlInfo.emoticSeq : -1,
                    text        : data.urlInfo.text,
                    urlInfo     : data.urlInfo.urlInfo
                }

                return {
                    ...state,
                    whisperMsgInfo          : msgInfo,
                    isWhisperModalOpened    : true,
                    isEmoticonSelected      : false,
                    selectedEmoticonSeq     : -1,
                    isAddFunctionOpened     : false,
                    isAudioOpened           : false,
                    audioStatus             : "STOPED",
                }
            } else {
                console.log("OPEN_WHISPER_MODAL - data.urlInfo is ", data);

                return {
                    ...state
                }
            }
        } else {
            console.log("OPEN_WHISPER_MODAL - data is ", data);

            return {
                ...state
            }
        }
    },

    [RESPOND_WHISPER]: (state, action) => {
        const { msgText, userNickname/*, profileImgUrl*/ } = action.payload;
        //console.log("RESPOND_WHISPER => ", msgText);
        sendChatMsg("whisper", msgText, -1, userNickname);

        return {
            ...state,
            whisperMsgInfo          : "",
            isWhisperModalOpened    : false,
            web_isWhisper           : false,
            isEmoticonSelected      : false,
            selectedEmoticonSeq     : -1,
            isAddFunctionOpened     : false,
            isAudioOpened           : false,
            audioStatus             : "STOPED",
        }
    },

    [CLOSE_WHISPER_MODAL]: (state, action) => {
        return {
            ...state,
            whisperMsgInfo          : "",
            isInkMemoOpened         : false,
            isWhisperModalOpened    : false,
            web_isWhisper           : false,
            isEmoticonSelected      : false,
            selectedEmoticonSeq     : -1,
            isAddFunctionOpened     : false,
            isAudioOpened           : false,
            audioStatus             : "STOPED",
        }
    },

    [WEB_IS_WHSPER_MODE]: (state, action) => {
        const { web_isWhisper } = action.payload;
        console.log("WEB_IS_WHSPER_MODE - ", action.payload);

        return {
            ...state,
            web_isWhisper           : web_isWhisper
        }
    },

    [TOGGLE_FILE_SHARE_MESSAGE]: (state, action) => {
        const { idx, isClickedBtn } = action.payload;
        console.log("TOGGLE_FILE_SHARE_MESSAGE");

        if (isClickedBtn) {
            return produce(state, draft => {
                const rcvMsg = draft.receivedMsg[idx];
                if (rcvMsg !== undefined && rcvMsg !== null) {
                    if (rcvMsg.msgInfo !== undefined && rcvMsg.msgInfo !== null) {
                        
                        let url = rcvMsg.msgInfo.urlInfo;
                        if (rcvMsg.msgInfo.kind === "whisper") {
                            url = rcvMsg.msgInfo.urlInfo.urlInfo;
                        }

                        let ext = url.substring(url.lastIndexOf("."), url.length);
                        switch (ext) {
                            case ".mp4":
                            case ".wmv":
                                rcvMsg.msgInfo.type = enMQType.Video;
                                break;

                            case ".mp3":
                            case ".wma":
                                rcvMsg.msgInfo.type = enMQType.Audio;
                                break;

                            case ".im3":
                                rcvMsg.msgInfo.type = enMQType.InkMedia;
                                break;

                            default:
                                rcvMsg.msgInfo.type = enMQType.Files;
                                break;
                        }

                        //rcvMsg.msgInfo.text = "파일 다운로드가 완료되었습니다.";
                        //rcvMsg.msgInfo.isDownloaded = true; // 애매하다....
                    }
                }
            });
        } else {
            return {
                ...state
            }
        }
    },

    [EMOTICON_TOGGLE_OPENED]: (state, action) => {
        return {
            ...state,
            isEmoticonSelected      : !state.isEmoticonSelected,
            selectedEmoticonSeq     : -1,
            isAddFunctionOpened     : false,
        }
    },

    [EMOTICON_SELECTED_CANCEL]: (state, action) => {
        return {
            ...state,
            selectedEmoticonSeq     : -1,
            isAddFunctionOpened     : false,
        }
    },

    [SELECT_EMOTICON_SEQ]: (state, action) => {
        const selectedEmoticonSeq = action.payload;
        //console.log("SELECT_EMOTICON_SEQ => ", selectedEmoticonSeq);

        return {
            ...state,
            isEmoticonSelected      : false,
            selectedEmoticonSeq     : selectedEmoticonSeq,
            isAddFunctionOpened     : false,
        }
    },

    [SELECT_FUNCTION_SEQ]: (state, action) => {
        const selectedFunctionSeq = action.payload;
        //console.log("SELECT_FUNCTION_SEQ => ", selectedFunctionSeq);

        if (selectedFunctionSeq === ctWebActionType.InkMemo) {

        } else if (selectedFunctionSeq === ctWebActionType.FileShare) {

        } else if (selectedFunctionSeq === ctWebActionType.WatsonS_KR) {
            
        } 

        return {
            ...state,
            isEmoticonSelected      : false,
            selectedEmoticonSeq     : -1,
            isAddFunctionOpened     : false,
            isAudioOpened           : selectedFunctionSeq === ctWebActionType.VoiceMemo,
        }
    },

    [ADD_FUNCTION_TOGGLE_OPENED]: (state, action) => {
        return {
            ...state,
            isEmoticonSelected      : false,
            selectedEmoticonSeq     : -1,
            isAddFunctionOpened     : !state.isAddFunctionOpened
        }
    },

    // Watson
    [APPEND_WATSON_MESSAGE_LIVE]: (state, action) => {
        const { msgText } = action.payload;

        return {
            ...state,
            receivedMsg     : state.receivedMsg.concat(msgText)
        }
    },

    // card
    [APPEND_QUIZ_MESSAGE_LIVE]: (state, action) => {
        const { nextPhaseData } = action.payload;
        const { msgText } = nextPhaseData;
        //const { msgText } = action.payload;
        //console.log("APPEND_QUIZ_MESSAGE_LIVE - ", msgText);

        if (msgText !== undefined && msgText !== null && msgText !== "") {
            return produce (state, draft => {
                //console.log("produce");
                const card_info = draft.receivedMsg.find(info => info.kind === ctBubbleKind.RcvMsg && info.msgInfo.kind === "card_template" && info.msgInfo.cardInfo.isCurrent);
                if (card_info !== undefined && card_info !== null) {
                    if (card_info.msgInfo.cardInfo.isCurrent) {
                        //console.log("card_info.msgInfo.cardInfo.isCurrent true");
                        //card_info.msgInfo.cardInfo.isCurrent = false;
                    }
                }

                draft.receivedMsg = draft.receivedMsg.concat(msgText);
            });
        } else {
            return {
                ...state
            }
        }
    },

    [CHANGE_QUIZ_MESSAGE_LIVE]: (state, action) => {
        const { nextPhaseData } = action.payload;
        const { liveSeq, quizSeq, idx } = nextPhaseData;
        console.log("CHANGE_QUIZ_MESSAGE_LIVE - ", action.payload);

        return produce(state, draft => {
            const rcvMsg = draft.receivedMsg.find(info => info.kind === ctBubbleKind.RcvMsg && info.msgInfo && info.msgInfo.cardInfo && (info.msgInfo.cardInfo.idx === idx && info.msgInfo.cardInfo.seq === quizSeq));
            if (rcvMsg && rcvMsg.msgInfo) {
                const msgInfo = rcvMsg.msgInfo;
                if (msgInfo !== undefined && msgInfo !== null) {
                    const card_info = msgInfo.cardInfo;
                    if (card_info !== undefined && card_info !== null) {
                        if (card_info.isCurrent) {
                            console.log("card_infoisCurrent is true");
                            card_info.isCurrent = false;
                            console.log("card_info.isCurrent = false");
                        }
                        if (!card_info.isPossiblePass) {
                            console.log("card_info.isPossiblePass is false");
                            card_info.isPossiblePass = true;
                            console.log("card_info.isPossiblePass = true");
                        }
                        if (!card_info.isFinishedQuiz) {
                            console.log("card_info.isFinishedQuiz is false");
                            card_info.isFinishedQuiz = true;
                            console.log("card_info.isFinishedQuiz = true");
                        }
                    }
                }
            }
        });
    },

    [SET_PASS_POSSIBLE]: (state, action) => {
        const { data } = action.payload;
        const { cardCode } = data;
        console.log("SET_PASS_POSSIBLE - ", cardCode);

        return produce (state, draft => {
            if (data.msgIndex && data.msgIndex > 0) {
                const msg_info = draft.receivedMsg.find(info => info.kind === ctBubbleKind.RcvMsg && info.msgInfo.kind === ctMsgType.Quiz && info.msgInfo.cardInfo.idx === data.msgIndex);
                msg_info.msgInfo.cardInfo.isPossiblePass = true;
            } else {
                console.log("기존 카드");
                const msg_info = draft.receivedMsg.find(info => info.kind === ctBubbleKind.RcvMsg && (info.msgInfo.kind === "card_template" || info.msgInfo.kind === ctMsgType.Quiz) && info.msgInfo.cardInfo.code === cardCode && info.msgInfo.cardInfo.isCurrent);
                if (msg_info) {
                    const card_info = msg_info.msgInfo.cardInfo;
                    if (card_info && card_info.isCurrent) {
                        card_info.isPossiblePass = true;
                    }
                }
            }                    
        });
    },

    // 음성톡
    [VOICE_MEMO_TOGGLE_CLOSED]: (state, action) => {
        return {
            ...state,
            isAudioOpened   : false
        }
    },

    // 메모톡
    [INK_MEMO_TOGGLE_OPENED]: (state, action) => {
        const selectedFunc = action.payload;
        //console.log("INK_MEMO_TOGGLE_OPENED => ", selectedFunc);

        if (selectedFunc !== undefined && selectedFunc !== null) {
            const { fileURL } = action.payload;

            return {
                ...state,
                isInkMemoOpened     : true,
                inkMemoFile         : fileURL
            }
        } else {
            return {
                ...state,
                isInkMemoOpened     : true
            }
        }
    },

    [INK_MEMO_TOGGLE_CLOSED]: (state, action) => {
        //console.log("INK_MEMO_TOGGLE_CLOSED");

        return {
            ...state,
            isInkMemoOpened     : false,
            toolMode            : enDrawingMode.Pen,
            inkMemoFile         : ""
        }
    },

    [INK_MEMO_TOOL]: (state, action) => {
        //console.log("INK_MEMO_TOOL => ", action.payload);

        return {
            ...state,
            toolMode: action.payload
        }
    },

    [TOGGLE_CLASSTALK]: (state, action) => {
        const { visibility } = action.payload;
        //console.log("TOGGLE_CLASSTALK");

        return {
            ...state,
            classTalkVisibility     : visibility
        }
    },

    // ink tool configuration
    [TOGGLE_INK_TOOL_COLOR_PAD]: (state, action) => {
        //console.log("TOGGLE_INK_TOOL_COLOR_PAD");

        return {
            ...state,
            isColorPadOpened        : true,
        }
    },

    [TOGGLE_INK_TOOL_TIP_SIZE_PAD]: (state, action) => {
        //console.log("TOGGLE_INK_TOOL_TIP_SIZE_PAD");

        return {
            ...state,
            isTipSizePadOpened      : true,
        }
    },

    [CLICK_OTHER_ELEMENT]: (state, action) => {
        const { point } = action.payload;
        //console.log("CLICK_OTHER_ELEMENT");

        return {
            ...state,
            isClickElement      : true,
            clickCheckPoint     : point
        }
    },

    // ink stroke conversation
    [INK_DRAWING_TOOL_MODE]: (state, action) => {
        const { tool, userSeq } = action.payload;
        //console.log("INK_DRAWING_TOOL_MODE");

        if (tool === enDrawingMode.Pen || tool === enDrawingMode.Marker) {
            sendChangeInkMode(tool, "", userSeq);
        }

        return {
            ...state,
            drawingMode     : tool,
            tipColor        : tool === enDrawingMode.Pen ? state.penTipColor : 
                              tool === enDrawingMode.Marker ? state.markerTipColor : 
                              state.tipColor,
            tipSize         : tool === enDrawingMode.Pen ? state.penTipSize : 
                              tool === enDrawingMode.Marker ? state.markerTipSize : 
                              state.tipSize,
        }
    },

    [INK_DRAWING_TOOL_COLOR]: (state, action) => {
        const { tool, userSeq } = action.payload;
        //console.log("INK_DRAWING_TOOL_COLOR");
        sendChangeInkColor(tool, "", userSeq);

        return {
            ...state,
            tipColor        : tool,
            penTipColor     : state.drawingMode === enDrawingMode.Pen ? tool : state.penTipColor,
            markerTipColor  : state.drawingMode === enDrawingMode.Marker ? tool : state.markerTipColor
        }
    },

    [INK_DRAWING_TOOL_THICK]: (state, action) => {
        const { tool, userSeq } = action.payload;
        //console.log("INK_DRAWING_TOOL_THICK");
        sendChangeInkTipSize(tool, "", userSeq);
        
        return {
            ...state,
            tipSize         : tool,
            penTipSize      : state.drawingMode === enDrawingMode.Pen ? tool : state.penTipSize,
            markerTipSize   : state.drawingMode === enDrawingMode.Marker ? tool : state.markerTipSize
        }
    },

    [INK_DRAWING_CLEAR]: (state, action) => {
        const { selectedFunc } = action.payload;
        //console.log("INK_DRAWING_CLEAR");
        /* if (selectedFunc === "CLEAR") {
            sendClearInkStroke("");
        } */
        return {
            ...state,
            isClearInkStroke    : selectedFunc === "CLEAR" ? true : false,
            isPossibleUndo      : selectedFunc === "CLEAR" ? true : state.isPossibleUndo,
            isPossibleRedo      : false,
        }
    },

    [SET_IMAGE_SCALE]: (state, action) => {
        const { size } = action.payload;

        return {
            ...state,
            performLiveImgViewSize  : size,
        }
    },

    [SET_IMAGE_OFFSET]: (state, action) => {
        const { offset } = action.payload;

        return {
            ...state,
            performLiveImgOffset    : offset,
        }
    },
    
    // ink undo & redo
    [INK_DRAWING_UNDO]: (state, action) => {
        //console.log("INK_DRAWING_UNDO");

        return {
            ...state,
            isUndo      : true,
        }
    },

    [INK_DRAWING_REDO]: (state, action) => {
        //console.log("INK_DRAWING_REDO");

        return {
            ...state,
            isRedo      : true,
        }
    },

    [INK_UNDO_REDO_SUCCESS]: (state, action) => {
        //console.log("INK_UNDO_REDO_SUCCESS");

        return {
            ...state,
            isUndo      : false,
            isRedo      : false,
        }
    },

    [SET_UNDO_STATUS]: (state, action) => {
        //console.log("SET_UNDO_STATUS");

        return {
            ...state,
            isPossibleUndo  : action.payload
        }
    },

    [SET_REDO_STATUS]: (state, action) => {
        //console.log("SET_REDO_STATUS");

        return {
            ...state,
            isPossibleRedo  : action.payload
        }
    },

    [SET_DRAWING_STATUS]: (state, action) => {
        const { isDrawingInkStroke } = action.payload;
        // console.log("SET_DRAWING_STATUS - ", action.payload);

        return {
            ...state,
            isDrawingInkStroke  : isDrawingInkStroke
        }
    },

    [MQ_SEND_INK_STROKE]: (state, action) => {
        const { stroke, userNickname, userSeq } = action.payload;
        //console.log("MQ_SEND_INK_STROKE");
        sendInkStroke(stroke, userNickname, userSeq);

        return {
            ...state,
            isPossibleRedo  : false,
            isPossibleUndo  : true,
        }
    },

    [MQ_RCV_DRAW_INK_STROKE_DOWN]: (state, action) => {
        const { cmd, point } = action.payload;
        //console.log("MQ_RCV_DRAW_INK_STROKE_DOWN : ", action.payload);

        return {
            ...state,
            rcvCmd          : cmd,
            rcvPoint1       : point,
        }
    },

    [MQ_RCV_DRAW_INK_STROKE_MOVE]: (state, action) => {
        const { cmd, point } = action.payload;
        //console.log("MQ_RCV_DRAW_INK_STROKE_MOVE");

        return {
            ...state,
            rcvCmd          : cmd,
            rcvPoint1       : point,
        }
    },

    [MQ_RCV_DRAW_INK_STROKE_DOUBLE_MOVE]: (state, action) => {
        const { cmd, spoint, epoint } = action.payload;
        //console.log("MQ_RCV_DRAW_INK_STROKE_DOUBLE_MOVE ");

        return {
            ...state,
            rcvCmd      : cmd,
            rcvPoint1   : spoint,
            rcvPoint2   : epoint,
        }
    },

    [MQ_RCV_DRAW_INK_STROKE_UP]: (state, action) => {
        const { cmd, point } = action.payload;
        //console.log("MQ_RCV_DRAW_INK_STROKE_UP : ", action.payload);

        return {
            ...state,
            rcvCmd          : cmd,
            rcvPoint1       : point,
            isPossibleRedo  : false,
            isPossibleUndo  : true,
        }
    },

    [MQ_RCV_DRAW_INK_STROKE]: (state, action) => {
        const { stroke } = action.payload;
        //console.log("INK_DRAWING_SERVER_STROKE");

        return {
            ...state,
            rcvStroke       : stroke,
            isPossibleRedo  : false,
            isPossibleUndo  : true,
        }
    },

    [MQ_RCV_DRAW_INK_STROKES]: (state, action) => {
        const { strokes } = action.payload;
        //console.log("INK_DRAWING_SERVER_STROKES");

        return {
            ...state,
            rcvStrokes      : strokes,
            isPossibleRedo  : false,
            isPossibleUndo  : true,
        }
    },

    [MQ_RCV_ERASE_INK_STROKE]: (state, action) => {
        const { cmd, spoint, epoint } = action.payload;
        //console.log("MQ_RCV_ERASE_INK_STROKE");
        //console.log(spoint, epoint);

        return {
            ...state,
            rcvCmd      : cmd,
            rcvPoint1   : spoint,
            rcvPoint2   : epoint,
        }
    },

    [MQ_RCV_CLEAR_INK_STROKE]: (state, action) => {
        //console.log("INK_CLEAR_STROKE");

        return {
            ...state,
            isClearInkStroke    : true,
            isPossibleRedo      : false,
            isPossibleUndo      : true,
        }
    },

    [MQ_RCV_CHANGE_INK_ATTR]: (state, action) => {
        const { mode, r, g, b, width, height } = action.payload;
        //console.log("attr : ", mode, r, g, b, width, height);

        return {
            ...state,
            rcvMode     : mode,
            rcvColor    : { R: r, G: g, B: b },
            rcvWidth    : (width + height) / 2,
        }
    },

    [MQ_RCV_CHANGE_INK_MODE]: (state, action) => {
        const { mode } = action.payload;
        //console.log("MQ_RCV_CHANGE_INK_MODE - mode => ", mode);

        return {
            ...state,
            rcvMode     : mode
        }
    },

    [MQ_RCV_CHANGE_INK_COLOR]: (state, action) => {
        const { r, g, b } = action.payload;
        //console.log("MQ_RCV_CHANGE_INK_COLOR");

        return {
            ...state,
            rcvColor    : { R: r, G: g, B: b },
        }
    },

    [MQ_RCV_CHANGE_INK_TIP_SIZE]: (state, action) => {
        const { width, height } = action.payload;
        //console.log("MQ_RCV_CHANGE_INK_TIP_SIZE");
        //console.log("width - " + width + ", height- " + height);

        return {
            ...state,
            rcvWidth    : (width + height) / 2,
        }
    },

    [MQ_RCV_DRAW_CANVAS_IMAGE]: (state, action) => {
        //console.log("MQ_RCV_DRAW_CANVAS_IMAGE");

        return {
            ...state,
        }
    },

    [MQ_RCV_CLEAR_PAGE]: (state, action) => {
        const { cmd } = action.payload;
        console.log("MQ_RCV_CLEAR_PAGE - ", action.payload);

        return {
            ...state,
            rcvCmd      : cmd,
            rcvPoint1   : { x: 0, y: 0 },
            rcvPoint2   : { x: 0, y: 0 },
        }
    },

    // android pen point
    [ANDROID_PEN_POINT_DOWN]: (state, action) => {
        const { cmd, point } = action.payload;
        //console.log("ANDROID_PEN_POINT_DOWN - ", cmd, point);

        return {
            ...state,
            androidPenPath: { cmd, point1: point, point2: { x: 0, y: 0 } }
        }
    },

    [ANDROID_PEN_POINT_MOVE]: (state, action) => {
        const { cmd, point } = action.payload;
        //console.log("ANDROID_PEN_POINT_MOVE - ", cmd, point);

        return {
            ...state,
            androidPenPath: { cmd, point1: point, point2: { x: 0, y: 0 } }
        }
    },

    [ANDROID_PEN_POINT_DOUBLE_MOVE]: (state, action) => {
        const { cmd, point1, point2 } = action.payload;
        //console.log("ANDROID_PEN_POINT_DOUBLE_MOVE - ", cmd, point1, point2);

        return {
            ...state,
            androidPenPath: { cmd, point1, point2 }
        }
    },

    [ANDROID_PEN_POINT_UP]: (state, action) => {
        const { cmd, point } = action.payload;
        //console.log("ANDROID_PEN_POINT_UP - ", cmd, point);

        return {
            ...state,
            androidPenPath: { cmd, point1: point, point2: { x: 0, y: 0 } }
        }
    },
}, initialState);

export default classTalkLive;