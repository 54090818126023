import React from 'react';

const CenteredModal = ({modalClose, children}) => {
    return(
        <div className="hiclasstv-modal centered" onClick={()=>modalClose({selectedFunc:"NONE"})}>
            {children}
        </div>
    ) 
}

export default CenteredModal;