import React from 'react';

const WebAddPromotion = () => {

    return (
        <>
            {/*
            <div className="d-flex py-3 align-items-center justify-content-center border-bottom">
                <div className="web-list-card card border-primary">
                    
                    <div className="d-flex text-center mb-3">
                        <button type="button" className="btn btn-md hiclasstv-blue white-text m-0 px-4 py-1" onClick={checkAddPromotion} disabled={promotionName !== "" ? false : true}>
                            <b>생성</b>
                        </button>
                    </div>
                </div>
            </div>
            */}
        </>
    );
}
export default WebAddPromotion;