import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import ConstData from "../../../lib/ConstData";
import {
    enInfoTap,
    enAdminTap,
    enWebPage,
    enManagePage
} from "../../../lib/ConstCommand";

import CustomProfileImage from "../../user/profile/CustomProfileImage";

const WebHeaderBigMenu = ({ userInfo, isStandardType, isHiClassDomain, isMediasoupAdmin, isConfigAdmin, isCreateAdmin, currentPageType, currentManagePageType, hiclasstvInfo, handleSelectedFunc, goToAloneChatting }) => {
    let history = useHistory();

    const [isInfoMenuOpened, setIsInfoMenuOpened] = useState(false);
    const [isManageMenuOpened, setIsManageMenuOpened] = useState(false);

    const onClickMenu = (kind) => {
        console.log("WebHeaderBigMenu onClickMenu - ", kind);
        if (kind === "info") {
            setIsInfoMenuOpened(!isInfoMenuOpened);
            setIsManageMenuOpened(false);
        } else {
            setIsInfoMenuOpened(false);
            setIsManageMenuOpened(!isManageMenuOpened);
        }
    }

    const goToWebPage = (pageType) => {
        if (pageType === enWebPage.Info) {
            if (currentPageType !== enWebPage.Info) {
                history.push("/info");
            }
        } else if (pageType === enWebPage.Manage) {
            if (currentPageType !== enWebPage.Manage || currentManagePageType !== enManagePage.SubDomainInfo) {
                history.push("/admin");
            }
        } else {
            handleSelectedFunc({ selectedFunc: "CLOSE_HEADER_BIG_MENU" });

            switch (pageType) {
                case enWebPage.Home:
                    history.push("/main");
                    break;

                case enWebPage.Friend:
                    history.push("/list_friend");
                    handleSelectedFunc({ selectedFunc: "CLOSE_HEADER_BIG_MENU" });
                    break;

                case enWebPage.LiveClassTalk:
                    goToAloneChatting();
                    break;

                case enWebPage.Live:
                    history.push("/list_live");
                    break;

                case enWebPage.Studio:
                    history.push("/list_studio");
                    break;

                case enWebPage.Promotion:
                    history.push("/promotion");
                    break;

                case enWebPage.Pass:
                    history.push("/pass");
                    break;

                case enWebPage.Download:
                    history.push("/download");
                    break;

                default:
                    break;
            }
        }
    }

    const goToInfoTap = (tap) => {
        setIsInfoMenuOpened(false);
        setIsManageMenuOpened(false);

        if (currentPageType !== enWebPage.Info) {
            history.push("/info");
        }

        handleSelectedFunc({ selectedFunc: tap });
    }

    const goToManageTap = (tap) => {
        setIsInfoMenuOpened(false);
        setIsManageMenuOpened(false);

        if (currentPageType !== enWebPage.Manage || currentManagePageType !== enManagePage.SubDomainInfo) {
            history.push("/admin");
        }

        handleSelectedFunc({ selectedFunc: tap });
    }

    const onClickProfile = () => {
        if (!ConstData.IS_LOCAL_VERSION) {
            handleSelectedFunc({ selectedFunc: "MODIFY_TOGGLE" });
        }
    }

    return (
        <div className="header-big-menu" style={{ borderBottom: '1px #aaaaaa solid' }}>
            <div className="d-flex flex-column w-100" style={{ borderBottom: '1px #aaaaaa solid' }}>
                <div className="d-flex flex-wrap w-100 p-2">
                    <button className={"d-flex hiclasstv-btn-transparent justify-content-start w-25 px-2 m-2" + (currentPageType === enWebPage.Home ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} onClick={() => goToWebPage(enWebPage.Home)}>
                        <span><b>MAIN</b></span>
                    </button>
                    { // ** 임시 ** 현재 사용 안 하므로 주석 처리 (for GS인증 시험) by hjkim 20230912
                        /* <button className={"d-flex hiclasstv-btn-transparent justify-content-start w-25 px-2 m-2" + (currentPageType === enWebPage.Friend ? "  hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} onClick={() => goToWebPage(enWebPage.Friend)}>
                            <span><b>FRIEND</b></span>
                        </button> */
                    }
                    <button className={"d-flex hiclasstv-btn-transparent justify-content-start w-25 px-2 m-2" + (currentPageType === enWebPage.LiveClassTalk ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} onClick={() => goToWebPage(enWebPage.LiveClassTalk)}>
                        <span><b>CHATTING</b></span>
                    </button>
                    <button className={"d-flex hiclasstv-btn-transparent justify-content-start w-25 px-2 m-2" + (currentPageType === enWebPage.Live ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} onClick={() => goToWebPage(enWebPage.Live)}>
                        <span><b>CLASS</b></span>
                    </button>
                    { // ** 임시 ** 매뉴얼 작업할 동안 주석 처리 by hjkim 20230503
                        /* <button className={"d-flex hiclasstv-btn-transparent justify-content-start w-25 px-2 m-2" + (currentPageType === enWebPage.Studio ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} onClick={() => goToWebPage(enWebPage.Studio)}>
                            <span><b>STUDIO</b></span>
                        </button> */
                    }
                    { // ** 임시 ** 현재 사용 안 하므로 주석 처리 by ykhan 20201217 
                        /* !ConstData.IS_LOCAL_VERSION && isStandardType &&
                        <button className={"d-flex hiclasstv-btn-transparent justify-content-start w-25 px-2 m-2" + (currentPageType === enWebPage.Promotion ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} onClick={() => goToWebPage(enWebPage.Promotion)}>
                            <span><b>PROMOTION</b></span>
                        </button> */
                    }
                    {
                        !ConstData.IS_LOCAL_VERSION && isStandardType &&
                        <button className={"d-flex hiclasstv-btn-transparent justify-content-start w-25 px-2 m-2" + (currentPageType === enWebPage.Pass ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} onClick={() => goToWebPage(enWebPage.Pass)}>
                            <span><b>PASS</b></span>
                        </button>
                    }
                    <button className={"d-flex hiclasstv-btn-transparent justify-content-start w-25 px-2 m-2" + (currentPageType === enWebPage.Download ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} onClick={() => goToWebPage(enWebPage.Download)}>
                        <span><b>DOWNLOAD</b></span>
                    </button>
                    <button className={"d-flex hiclasstv-btn-transparent justify-content-start w-25 px-2 m-2" + (currentPageType === enWebPage.Info ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} onClick={() => onClickMenu("info")}>
                        <span><b>INFO</b></span>
                    </button>
                    {
                        (isConfigAdmin || isCreateAdmin) &&
                        <button className={"d-flex hiclasstv-btn-transparent justify-content-start w-25 px-2 m-2" + (currentPageType === enWebPage.Manage ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} onClick={() => onClickMenu("admin")}>
                            <span><b>MANAGE</b></span>
                        </button>
                    }
                </div>
                {
                    ((currentPageType === enWebPage.Info && isInfoMenuOpened) || isInfoMenuOpened) &&
                    <div className="d-flex flex-wrap mx-3 p-2" style={{ borderTop: '1px #aaaaaa solid' }}>
                        <button className={"d-flex hiclasstv-btn-transparent justify-content-start p-2" + ((currentPageType === enWebPage.Info && hiclasstvInfo.currentInfoTap === enInfoTap.Home) ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} style={{ width: '33%' }} onClick={() => goToInfoTap("SHOW_HOME")}>
                            <span><b>홈</b></span>
                        </button>
                        <button className={"d-flex hiclasstv-btn-transparent justify-content-start p-2" + ((currentPageType === enWebPage.Info && hiclasstvInfo.currentInfoTap === enInfoTap.Edutech) ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} style={{ width: '33%' }} onClick={() => goToInfoTap("SHOW_EDUTECH")}>
                            <span><b>에듀텍서비스</b></span>
                        </button>
                        <button className={"d-flex hiclasstv-btn-transparent justify-content-start p-2" + ((currentPageType === enWebPage.Info && hiclasstvInfo.currentInfoTap === enInfoTap.Contents) ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} style={{ width: '33%' }} onClick={() => goToInfoTap("SHOW_CONTENTS")}>
                            <span><b>콘텐츠</b></span>
                        </button>
                        { // ** 임시 ** 현재 사용 안 하므로 주석 처리 by ykhan 20220421 
                            /* <button className={"d-flex hiclasstv-btn-transparent justify-content-start p-2" + ((currentPageType === enWebPage.Info && hiclasstvInfo.currentInfoTap === enInfoTap.SmartSheet) ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} style={{ width: '33%' }} onClick={() => goToInfoTap("SHOW_GREEN_SMART")}>
                                <span><b>스마트칠판</b></span>
                            </button> */
                        }
                        <button className={"d-flex hiclasstv-btn-transparent justify-content-start p-2" + ((currentPageType === enWebPage.Info && hiclasstvInfo.currentInfoTap === enInfoTap.FAQ) ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} style={{ width: '33%' }} onClick={() => goToInfoTap("SHOW_QNA")}>
                            <span><b>FAQ</b></span>
                        </button>
                        <button className="d-flex hiclasstv-btn-transparent justify-content-start p-2" style={{ width: '33%' }}>
                            <a href="https://www.youtube.com/channel/UCAMX1XVXrItw3mlbwFCC4TQ/featured" target="_blank" rel="noopener noreferrer">
                                <span className="hiclasstv-darkgrey-text"><b>동영상</b></span>
                            </a>
                        </button>
                        <button className="d-flex hiclasstv-btn-transparent justify-content-start p-2" style={{ width: '33%' }}>
                            <a href="https://test.hiclass.tv/hiclass_ai_player/" target="_blank" rel="noopener noreferrer">
                                <span className="hiclasstv-darkgrey-text"><b>AI</b></span>
                            </a>
                        </button>
                        { // ** 임시 ** 매뉴얼 작업할 동안 주석 처리 by hjkim 20230503
                            /* <button className={"d-flex hiclasstv-btn-transparent justify-content-start p-2" + ((currentPageType === enWebPage.Info && hiclasstvInfo.currentInfoTap === enInfoTap.SHARP) ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} style={{ width: '33%' }} onClick={() => goToInfoTap("SHOW_SHARP")}>
                                <span><b>SHOP</b></span>
                            </button> */
                        }
                    </div>
                }
                {
                    ((currentPageType === enWebPage.Manage && isManageMenuOpened) || isManageMenuOpened) &&
                    <div className="d-flex flex-wrap mx-3 py-2" style={{ borderTop: '1px #aaaaaa solid' }}>
                        <button className={"d-flex hiclasstv-btn-transparent justify-content-start p-2" + ((currentPageType === enWebPage.Manage && hiclasstvInfo.currentAdminTap === enAdminTap.Home) ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} style={{ width: '33%' }} onClick={() => goToManageTap("SHOW_ADMIN_HOME")}>
                            <span><b>DOMAIN</b></span>
                        </button>
                        {/*<button className={"d-flex hiclasstv-btn-transparent justify-content-start p-2" + ((currentPageType === enWebPage.Manage && hiclasstvInfo.currentAdminTap === enAdminTap.Lecture) ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} style={{ width: '33%' }} onClick={() => goToManageTap("SHOW_ADMIN_LECTURE")}>
                            <span><b>LECTURE</b></span>
                </button>*/}
                        {
                            isConfigAdmin && isMediasoupAdmin &&
                            <>
                                {
                                    isHiClassDomain &&
                                    <>
                                        <button className={"d-flex hiclasstv-btn-transparent justify-content-start p-2" + ((currentPageType === enWebPage.Manage && hiclasstvInfo.currentAdminTap === enAdminTap.LocalServer) ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} style={{ width: '25%' }} onClick={() => goToManageTap("SHOW_ADMIN_LOCAL_SERVER")}>
                                            <span><b>LOCAL SERVER</b></span>
                                        </button>
                                        <button className={"d-flex hiclasstv-btn-transparent justify-content-start p-2" + ((currentPageType === enWebPage.Manage && hiclasstvInfo.currentAdminTap === enAdminTap.LocalSite) ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} style={{ width: '25%' }} onClick={() => goToManageTap("SHOW_ADMIN_LOCAL_SITE")}>
                                            <span><b>LOCAL SITE</b></span>
                                        </button>
                                        <button className={"d-flex hiclasstv-btn-transparent justify-content-start p-2" + ((currentPageType === enWebPage.Manage && hiclasstvInfo.currentAdminTap === enAdminTap.LiteLocalServer) ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} style={{ width: '25%'}} onClick={() => goToManageTap("SHOW_ADMIN_LITE_SERVER")}>
                                            <span><b>LITE LOCAL SERVER</b></span>
                                        </button>
                                        <button className={"d-flex hiclasstv-btn-transparent justify-content-start p-2" + ((currentPageType === enWebPage.Manage && hiclasstvInfo.currentAdminTap === enAdminTap.Studio) ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} style={{ width: '25%' }} onClick={() => goToManageTap("SHOW_ADMIN_STUDIO")}>
                                            <span><b>STUDIO</b></span>
                                        </button>
                                    </>
                                }
                                <button className={"d-flex hiclasstv-btn-transparent justify-content-start p-2" + ((currentPageType === enWebPage.Manage && hiclasstvInfo.currentAdminTap === enAdminTap.Media) ? " hiclasstv-blue-text" : " hiclasstv-darkgrey-text")} style={{ width: '25%' }} onClick={() => goToManageTap("SHOW_ADMIN_MEDIA")}>
                                    <span><b>MEDIA</b></span>
                                </button>
                            </>
                        }
                    </div>
                }
            </div>
            <div className="d-flex justify-content-end hiclasstv-grey hiclasstv-deepDarkgrey-text w-100 py-2">
                <div className="d-flex align-items-center justify-content-end p-2" style={{ minWidth: '10%' }} onClick={onClickProfile}>
                    <div className="d-flex align-items-center justify-content-center area-15 fit-content">
                        <CustomProfileImage url={userInfo.profileImgUrl} style={{ width: '1.7rem', height: '1.7rem' }} alt={userInfo.userNickname} />
                    </div>
                    <div className="d-flex text-area text-center area-80">
                        <span className="hiclass-text" title={userInfo.userNickname}><b>{userInfo.userNickname}</b></span>
                        <span className="hiclass-text">님</span>
                    </div>
                </div>
                <button type="button" className="btn btn-sm red white-text mr-2 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "LOGOUT" })}>
                    <b>Logout</b>
                </button>
                <div className="d-flex align-items-center area-15 fit-content px-3" style={{ borderLeft: '1px #aaaaaa solid' }}>
                    <span className="hiclasstv-deepDarkgrey-text" onClick={() => handleSelectedFunc({ selectedFunc: "CLOSE_HEADER_BIG_MENU" })}>
                        닫기
                    </span>
                </div>
            </div>
        </div>
    );
}

export default WebHeaderBigMenu;