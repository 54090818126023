import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import ConstData from "../../lib/ConstData";
import { enBrowserType, enInfoTap } from "../../lib/ConstCommand";

import WebHiClassInfoContainer from "../etc/WebHiClassInfoContainer";
import WebPromotionContainer from "../etc/WebPromotionContainer";
import FindMemberIdPasswordContainer from "./FindMemberIdPasswordContainer";

import UserLoginAtCloud from "../../components/user/UserLoginAtCloud";
import UserLoginAtLocal from "../../components/user/UserLoginAtLocal";
import SettingLocalInfoModal from "../../components/user/modal/SettingLocalInfoModal";

import WebUserLogin from "../../components/user/WebUserLogin";

import UserRegist from "../../components/user/UserRegist";
import HiClassInfo from "../../components/etc/info/HiClassInfo";

import AlertModal from "../../components/etc/modal/AlertModal";
import LiveReJoinAlertModal from "../../components/live/modal/LiveReJoinAlertModal";
import Notice001 from "../../components/etc/modal/Notice001";
import WebFooter from "../../components/etc/WebFooter";

import { updateReconnMode } from "../../lib/HandsUpClient";

import {
    toggleNoticeModal,
    toggleRetryLoginModal,
    getLocalServerInfo,
    toggle_settingLocalInfoModal,
    checkLocalServerAuthCode,
    getCloudServerInfo
} from "../../modules/hiclasstv";

import {
    logIn,
    autoLogIn,
    logInThroughNotification,
    logInByGuest,
    logInByQRCode,
    idCheck,
    userRegist,
    phoneCheckSend,
    phoneCheckConfirm,
    emailCheckSend,
    emailCheckConfirm,
    falseToPhoneCheckOk,
    falseToPhoneCheckSend,
    falseToEmailCheckOk,
    falseToEmailCheckSend,
    logInToggle,
    userInfoToggle,
    saveCookiesToggle,
    toggle_findIdPasswordModal,
    toggleLogInAlertModal,
    setMessageString,
    changeAlertMessage,
    setLoginMessage,
    requestMixedClassLogin,
    notifyLogoutToAndroid
} from "../../modules/user";

const getIsAndroidDevice = () => {
    let isAndroidDevice = false;

    if (navigator) {
        if (navigator.userAgent) {
            let userAgent = navigator.userAgent.toLowerCase();

            isAndroidDevice = userAgent.indexOf("android") > -1;
        }
    }

    return isAndroidDevice;
}

class UserLoginContainer extends Component {
    componentDidMount() {
        //console.log(`UserLoginContainer componentDidMount - browserType[${browserType}]`);
        if (this.props.browserType === enBrowserType.Web) { // web 접속시 cookie에서 isShow 체크...
            if (!ConstData.IS_LOCAL_VERSION) {
                if (!this.props.cookies.get("isShow")) {
                    this.props.cookies.set("isShow", true);
                }
            }
        } else { // hiclass 접속 시 cookie에서 loginLocation, localServerSeq, alwaysCloudStatus 등 체크...
            if (this.props.routeProps && this.props.routeProps.location) {
                if (this.props.routeProps.location.search && this.props.routeProps.location.search !== "") {
                    const query = queryString.parse(this.props.routeProps.location.search);

                    if (query && query.alwaysCloudStatus && query.alwaysCloudStatus !== "") { // query.alwaysCloudStatus 값이 있으면, local-cloud 이동 시도하는 경우임...
                        if (query.alwaysCloudStatus === "true") {
                            this.saveCookiesAboutLocalInfo({ kind: "alwaysCloudStatus", value: true });
                        } else {
                            this.saveCookiesAboutLocalInfo({ kind: "alwaysCloudStatus", value: false });
                        }
                    } else if (query && query.localServerSeq && query.localServerSeq >= 0) {
                        if (this.props.cookies.get("loginLocation") === "local" && !Number.isNaN(Number(this.props.cookies.get("localServerSeq")))) {
                            let localServerSeq = Number(this.props.cookies.get("localServerSeq"));

                            if (localServerSeq >= 0) {
                                if (this.props.cookies.get("alwaysCloudStatus") === "true") { // local 이지만 항상 클라우드로 가고 싶은 경우
                                    console.log("항상 cloud에 있을래ㅐㅐㅐㅐㅐㅐㅐ!");
                                } else {
                                    console.log("local 페이지로 이동할래!!!!");
                                    this.props.getLocalServerInfo({ localServerSeq: Number(this.props.cookies.get("localServerSeq")) });
                                }
                            } else {
                                console.log("로컬정보 설정 안되어있는 로컬페이지...(?)");
                            }
                        }

                        //if (cookies.get("loginLocation") === undefined) {
                        //    if (ConstData.IS_LOCAL_VERSION) { //local 버전이라고 loginLocation이 없다고 무조건 로컬띄우는건 아닌건 같음...
                        this.saveCookiesAboutLocalInfo({ kind: "loginLocation", value: "local" });
                        //    } else {
                        //        this.saveCookiesAboutLocalInfo({ kind: "loginLocation", value: "cloud" });
                        //    }
                        //}

                        if (this.props.cookies.get("alwaysCloudStatus") === undefined) { // 기본값은 false => localServer 정보 저장 시, 다음 번 로그인부터 local용 UI.
                            this.saveCookiesAboutLocalInfo({ kind: "alwaysCloudStatus", value: false });
                        }

                        if (this.props.cookies.get("localServerSeq") === undefined) {
                            this.saveCookiesAboutLocalInfo({ kind: "localServerSeq", value: query.localServerSeq });
                            this.props.getLocalServerInfo({ localServerSeq: Number(this.props.cookies.get("localServerSeq")) });
                        }
                    } else { // query.alwaysCloudStatus 값이 없으면 pentalk 프로그램 실행하는 경우임
                        console.log(`query => `, query);
                        console.log("넌 뭣하는 경우냐...?ㅁ?");
                    }
                } else { // routeProps.location.search 값이 없으면 pentalk 프로그램 실행하는 경우임
                    if (this.props.cookies.get("loginLocation") === "local" && !Number.isNaN(Number(this.props.cookies.get("localServerSeq")))) {
                        let localServerSeq = Number(this.props.cookies.get("localServerSeq"));

                        if (localServerSeq >= 0) {
                            if (this.props.cookies.get("alwaysCloudStatus") === "true") { // local 이지만 항상 클라우드로 가고 싶은 경우
                                console.log("항상 cloud에 있을래ㅐㅐㅐㅐㅐㅐㅐ!");
                            } else {
                                console.log("local 페이지로 이동할래!!!!");
                                this.props.getLocalServerInfo({ localServerSeq: Number(this.props.cookies.get("localServerSeq")) });
                            }
                        } else {
                            console.log("로컬정보 설정 안되어있는 로컬페이지...(?)");
                        }
                    }

                    if (this.props.cookies.get("loginLocation") === undefined) {
                        if (ConstData.IS_LOCAL_VERSION) { //local 버전이라고 loginLocation이 없다고 무조건 로컬띄우는건 아닌건 같음...
                            this.saveCookiesAboutLocalInfo({ kind: "loginLocation", value: "local" });
                        } else {
                            this.saveCookiesAboutLocalInfo({ kind: "loginLocation", value: "cloud" });
                        }
                    }

                    if (this.props.cookies.get("alwaysCloudStatus") === undefined) { // 기본값은 false => localServer 정보 저장 시, 다음 번 로그인부터 local용 UI.
                        this.saveCookiesAboutLocalInfo({ kind: "alwaysCloudStatus", value: false });
                    }

                    if (this.props.cookies.get("localServerSeq") === undefined) {
                        this.saveCookiesAboutLocalInfo({ kind: "localServerSeq", value: -1 });
                    }
                }
            } else {
                console.log("이런 경우가 있을 수가 있나요..? window.location => ", window.location);
            }
        }
    }

    shouldComponentUpdate(nextProps) { // 기존과 props가 달라졌을 때만 update되도록! 남용은 비효율적. by hjkim 20210402
        return (JSON.stringify(nextProps) !== JSON.stringify(this.props));
    }

    componentDidUpdate(prevProps) {
        if (this.props.browserType === enBrowserType.HiClass) {
            if (prevProps.localServerInfo.serverIP !== this.props.localServerInfo.serverIP) {
                console.log("host address is changed");
                console.log(`origin host ip [${window.location.origin}], new host ip [${this.props.localServerInfo.serverIP}]`);

                if (this.props.localServerInfo.serverIP && this.props.localServerInfo.serverIP !== "") {
                    let pathname = window.location.pathname;
                    let url = new URL(pathname, this.props.localServerInfo.serverIP);

                    if (this.props.localServerInfo.isCloud) {
                        if (this.props.localServerInfo.alwaysCloudStatus === true) {
                            console.log("항상 클라우드로 이동할래ㅐㅐㅐㅐㅐㅐ");
                            url.search = `alwaysCloudStatus=${this.props.localServerInfo.alwaysCloudStatus.toString()}`;
                        } else {
                            console.log("이번 한번만 클라우드로 이동할래ㅐㅐㅐㅐㅐㅐ");
                            url.search = `alwaysCloudStatus=${this.props.localServerInfo.alwaysCloudStatus.toString()}`;
                        }
                        console.log(`클라우드로 이동:) url.href[${url.href}], url.origin[${url.origin}], url[${url}]`);
                    } else {
                        console.log("로컬로 이동할래ㅐㅐㅐㅐㅐ");
                        console.log(`로컬로 이동:) url.href[${url.href}], url.origin[${url.origin}], url[${url}]`);
                    }

                    console.log(`last url=> url.toString() - ${url.toString()}`);
                    //window.location.replace(url.toString());
                }
            }
        }

        if (prevProps.isLoginAlertModalOpened !== this.props.isLoginAlertModalOpened) {
            if (this.props.isLoginAlertModalOpened) {
                if (this.props.routeProps.history !== undefined) {
                    this.props.routeProps.history.push("/main");
                }
            }
        }

        // ?guestLoginKey=CA275FBF143D075E85C4D2B41185CF1A
        if (prevProps.isCheckedSubDomain !== this.props.isCheckedSubDomain && this.props.isCheckedSubDomain === true) {
            if (this.props.routeProps.location !== undefined) {
                if (this.props.routeProps.location.search !== "") {    // query 유무 검사 by hjkim 20210401
                    const query = queryString.parse(this.props.routeProps.location.search);
                    if (query && query !== "") {
                        if (query.tokenKey && query.tokenKey !== "") {
                            this.props.autoLogIn({
                                loginInfo: {
                                    tokenKey: query.tokenKey,
                                    userNickname: "",
                                    lectureCode: query.lectureCode && query.lectureCode !== "" ? query.lectureCode : null
                                },
                                tokenKey: query.tokenKey,
                                userKind: this.props.userKind,
                                lectureCode: query.lectureCode && query.lectureCode !== "" ? query.lectureCode : null,
                                mode: "web",
                                kind: "auto"
                            });
                        } else if (query.broadcastKey && query.broadcastKey !== "") {
                            this.props.logInToggle();
                        } else if (query.guestLoginKey && query.guestLoginKey !== "") {
                            this.props.logInByGuest({
                                loginInfo: {
                                    guestLoginKey: query.guestLoginKey
                                },
                                guestLoginKey: query.guestLoginKey,
                                userKind: this.props.userKind,
                                mode: "web",
                                kind: "guest"
                            });
                        } else if (query.mixedClassTokenKey && query.mixedClassTokenKey !== "") {
                            this.props.requestMixedClassLogin({
                                loginInfo: {
                                    tokenKey: query.mixedClassTokenKey
                                },
                                tokenKey: query.mixedClassTokenKey,
                                userKind: this.props.userKind
                            });
                        } else if (query.alwaysCloudStatus && query.alwaysCloudStatus !== "") {
                            console.log("query.alwaysCloudStatus 가 있는 경우는 local-cloud 이동 case 이므로 not doing...");
                        } else if (query.localServerSeq !== undefined && query.localServerSeq >= 0) {
                            console.log("query.localServerSeq 가 있는 경우 처리 아무것도 안함...");
                        } else {
                            console.log("invalid qeury url. move main page");
                            this.props.toggleLogInAlertModal({ isModalOpened: true });
                        }
                    }
                }
            }
        }
    }

    saveCookiesAboutLocalInfo = ({ kind, value }) => {
        console.log(`saveCookiesAboutLocalInfo - kind[${kind}], value[${value}]`);
        if (this.props.browserType === enBrowserType.HiClass) {
            let newDate = new Date(Date.now());
            newDate.setFullYear(newDate.getFullYear() + 5);
            newDate.setHours(0, 0, 0, 0);
            this.props.cookies.set(kind, value, { path: "/hiclass", expires: newDate });

            if (kind === "localServerSeq" && !Number.isNaN(Number(value))) {
                if (value >= 0) {
                    this.props.toggle_settingLocalInfoModal();
                } else {
                    console.log("value is ", value);
                }
            }
        }
    }

    handleSelectedFunc = ({ selectedFunc, message, kind, checkID, phoneNumber, userEmail, confirmNumber, loginInfo, registInfo, mode, value }) => {
        const { cookies } = this.props;

        switch (selectedFunc) {
            case "NONE":
                break;

            case "FIND_TOGGLE":
                this.props.toggle_findIdPasswordModal(mode);
                break;

            case "LOGIN_TOGGLE":
                this.props.logInToggle();
                break;

            case "LOGIN":
                let lUserKind = this.props.userKind;
                if (lUserKind === "test") {
                    lUserKind = "hiclasstv";
                }

                const query = queryString.parse(this.props.routeProps.location.search);
                if (query && query.broadcastKey && query.broadcastKey !== "") {
                    this.props.logInThroughNotification({
                        loginInfo,
                        userKind: lUserKind,
                        broadcastKey: query.broadcastKey,
                        mode,
                        kind: "auto"
                    });
                    break;
                } else {
                    console.log("UserLoginContainer LOGIN - 이런 경우가 있나요..? query => ", query);
                }

                this.props.logIn({
                    loginInfo,
                    userKind: lUserKind,
                    mode,
                    test: "test"
                });
                break;

            case "REGIST_TOGGLE":
                this.props.userInfoToggle({ kind: "regist" });
                break;

            case "REGIST":
                this.props.userRegist({
                    userID: registInfo.userID,
                    password: registInfo.password,
                    userNickname: registInfo.userNickname,
                    userEmail: registInfo.userEmail,
                    phoneNumber: registInfo.phoneNumber,
                    userProfile: registInfo.userProfile,
                    profileImgUrl: registInfo.profileImgUrl,
                    userExtraCode: registInfo.userExtraCode,
                    userType: registInfo.userType,
                    userKind: this.props.userKind
                });
                break;

            case "ID_CHECK":
                this.props.idCheck({ userID: checkID, userKind: this.props.userKind });
                break;

            // case "FALSE_TO_PHONE_CHECK_SEND":
            //     falseToPhoneCheckSend({ message });
            //     break;

            // case "FLASE_TO_PHONE_CHECK_OK":
            //     falseToPhoneCheckOk({ message });
            //     break;

            case "PHONE_CHECK":
                this.props.phoneCheckSend({ phoneNumber });
                break;

            case "PHONE_CHECK_CONFIRM":
                const { certiCodeSeq } = this.props;
                this.props.phoneCheckConfirm({ certiCodeSeq, confirmNumber });
                break;

            case "EMAIL_CHECK":
                this.props.emailCheckSend({ userEmail });
                break;

            case "EMAIL_CHECK_CONFIRM":
                const { emailCertiCodeSeq } = this.props;
                this.props.emailCheckConfirm({ certiCodeSeq: emailCertiCodeSeq, confirmNumber });
                break;

            // case "FLASE_TO_EMAIL_CHECK_OK":
            //     falseToEmailCheckOk({ message });
            //     break;

            // case "FALSE_TO_EMAIL_CHECK_SEND":
            //     falseToEmailCheckSend({ message });
            //     break;

            case "MESSAGE":
                this.props.setMessageString(message);
                break;

            case "L_MESSAGE":
                this.props.setLoginMessage(message, kind);
                break;

            case "CHANGE_ALERT_MESSAGE":
                this.props.changeAlertMessage(message, kind);
                break;

            case "NO_SHOW":
                this.props.toggleNoticeModal({ status: false });
                this.props.getCookiesInfoHandle();
                break;

            case "CLOSE":
                this.props.toggleNoticeModal({ status: false });
                break;

            case "TOGGLE_ALERT_MODAL":
                this.props.toggleLogInAlertModal({ isModalOpened: false });
                break;

            case "TOGGLE_RETRY_LOGIN_MODAL":
                this.props.toggleRetryLoginModal({ status: false });

                if (value) {
                    let userID = cookies.get("userID");
                    let userSeq = Number(cookies.get("userSeq"));
                    let userNickname = cookies.get("userNickname");
                    let userKind = this.props.userKind;
                    if (userSeq >= 0) {
                        console.log("UserLoginContainer - logInByQRCode => ", userSeq);
                        this.props.logInByQRCode({
                            loginInfo: {
                                userID,
                                userSeq,
                                userNickname
                            },
                            userID,
                            userSeq,
                            userKind,
                            mode: "web",
                            kind: "qr-login"
                        });
                        updateReconnMode(true);
                    } else {
                        console.log("UserLoginContainer - logInByQRCode => userSeq is -1 ");
                    }
                } else {
                    let userSeq = Number(cookies.get("userSeq"));
                    let isAndroidDevice = getIsAndroidDevice();
                    console.log(`TOGGLE_RETRY_LOGIN_MODAL - userSeq[${userSeq}], isAndroidDevice[${isAndroidDevice}], isConnectedOnScreenApp[${this.props.isConnectedOnScreenApp}]`);

                    if (isAndroidDevice || this.props.isConnectedOnScreenApp) {
                        this.props.notifyLogoutToAndroid({ userSeq });
                    }

                    let newDate = new Date(Date.now());
                    newDate.setDate(newDate.getDate() - 1);
                    cookies.set("userID", cookies.get("userID"), { path: "/", expires: newDate });
                    cookies.set("userSeq", cookies.get("userSeq"), { path: "/", expires: newDate });
                    cookies.set("userNickname", cookies.get("userNickname"), { path: "/", expires: newDate });
                    cookies.set("liveSeq", cookies.get("liveSeq"), { path: "/", expires: newDate });
                    cookies.set("viewKind", cookies.get("viewKind"), { path: "/", expires: newDate });
                }
                break;

            case "REQUEST_AUTH_CODE_CHECK":
                this.props.checkLocalServerAuthCode({ authCode: value });
                break;

            case "GO_TO_LOCAL": {
                let savedLocalServerSeq = cookies.get("localServerSeq");
                console.log(`(1)savedLocalServerSeq[${savedLocalServerSeq}], typeof[${typeof savedLocalServerSeq}]`);

                if (!Number.isNaN(Number(cookies.get("localServerSeq")))) { // 저장된 localServerSeq가 유효한지 체크
                    savedLocalServerSeq = Number(cookies.get("localServerSeq"));
                    console.log(`(2)savedLocalServerSeq[${savedLocalServerSeq}], typeof[${typeof savedLocalServerSeq}]`);
                    if (savedLocalServerSeq >= 0) { // 저장된 localServerSeq -1보다 커야 인증된 localServerSeq 임. 그럴 경우에만 local로 설정..
                        console.log(`(1)cookies localServerSeq[${cookies.get("localServerSeq")}]`);
                        this.saveCookiesAboutLocalInfo({ kind: "loginLocation", value: "local" });
                        this.props.getLocalServerInfo({ localServerSeq: savedLocalServerSeq });
                    } else {
                        console.log(`(2)cookies localServerSeq[${cookies.get("localServerSeq")}]`);
                        let alertMsg = "설정된 로컬 서버 정보가 없습니다.<br> 로컬 서버 정보 설정 후 이용 가능합니다.";
                        this.props.setLoginMessage(alertMsg, "danger");
                    }
                } else {
                    console.log(`(3)cookies localServerSeq[${cookies.get("localServerSeq")}]`);
                    let alertMsg = "설정된 로컬 서버 정보가 없습니다.<br> 로컬 서버 정보 설정 후 이용 가능합니다.";
                    this.props.setLoginMessage(alertMsg, "danger");
                }
            } break;

            case "GO_TO_CLOUD":
                console.log(`handleSelectedFunc - GO_TO_CLOUD:) kind[${kind}]`);
                if (kind === true) {
                    this.saveCookiesAboutLocalInfo({ kind: "alwaysCloudStatus", value: kind });
                    this.saveCookiesAboutLocalInfo({ kind: "loginLocation", value: "cloud" });
                    this.props.getCloudServerInfo({ cloudHost: ConstData.CLOUD_HOST_ADD, alwaysCloudStatus: kind });
                } else {
                    this.saveCookiesAboutLocalInfo({ kind: "alwaysCloudStatus", value: kind });
                    this.saveCookiesAboutLocalInfo({ kind: "loginLocation", value: "local" });
                    this.props.getCloudServerInfo({ cloudHost: ConstData.CLOUD_HOST_ADD, alwaysCloudStatus: kind });
                }
                break;

            case "SETTING_LOCAL_INFO_TOGGLE":
                this.props.toggle_settingLocalInfoModal();
                break;

            default:
                break;

        }
    }

    render() {
        return (
            this.props.browserType === enBrowserType.HiClass ?
                <>
                    {
                        this.props.isFindIdPasswordOpened &&
                        <FindMemberIdPasswordContainer
                            isFindIdPasswordOpened={this.props.isFindIdPasswordOpened}
                        />
                    }
                    {
                        this.props.isSettingLocalInfoModalOpened &&
                        <SettingLocalInfoModal
                            isOpened={this.props.isSettingLocalInfoModalOpened}
                            savedLocalServerSeq={Number(this.props.cookies.get("localServerSeq"))}
                            checkAuthCodeResult={this.props.checkAuthCodeResult}
                            handleSelectedFunc={this.handleSelectedFunc}
                            saveCookiesAboutLocalInfo={this.saveCookiesAboutLocalInfo}
                        />
                    }
                    <UserRegist
                        userInfo={this.props.userInfo}
                        isInfoOpened={this.props.isInfoOpened}
                        userInfoAlertKind={this.props.userInfoAlertKind}
                        userInfoMessage={this.props.userInfoMessage}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
                    {
                        ConstData.IS_LOCAL_VERSION ?
                            <UserLoginAtLocal
                                loginMessage={this.props.loginMessage}
                                alertKind={this.props.alertKind}
                                savedAlwaysCloudStatus={this.props.cookies.get("alwaysCloudStatus")}
                                handleSelectedFunc={this.handleSelectedFunc}
                                getCookiesInfoHandle={this.props.getCookiesInfoHandle}
                            /> :
                            <UserLoginAtCloud
                                loginMessage={this.props.loginMessage}
                                alertKind={this.props.alertKind}
                                savedLocalServerSeq={Number(this.props.cookies.get("localServerSeq"))}
                                handleSelectedFunc={this.handleSelectedFunc}
                                getCookiesInfoHandle={this.props.getCookiesInfoHandle}
                            />
                    }
                    <HiClassInfo />
                </> :
                <div className="d-flex flex-column align-items-center w-100">
                    {
                        this.props.isFindIdPasswordOpened &&
                        <FindMemberIdPasswordContainer
                            isFindIdPasswordOpened={this.props.isFindIdPasswordOpened}
                        />
                    }
                    <AlertModal
                        isModalOpened={this.props.isLoginAlertModalOpened}
                        alertKind={this.props.alertKind}
                        alertMessage={this.props.loginMessage}
                        alertType={this.props.alertType}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
                    <WebUserLogin
                        userInfo={this.props.userInfo}
                        userKind={this.props.userKind}
                        isLogInOpened={this.props.isLogInOpened}
                        loginMessage={this.props.loginMessage}
                        alertKind={this.props.alertKind}
                        handleSelectedFunc={this.handleSelectedFunc}
                        getCookiesInfoHandle={this.props.getCookiesInfoHandle}
                    />
                    <UserRegist
                        userInfo={this.props.userInfo}
                        isInfoOpened={this.props.isInfoOpened}
                        userInfoAlertKind={this.props.userInfoAlertKind}
                        userInfoMessage={this.props.userInfoMessage}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
                    <div className="d-flex flex-column align-items-center w-100 h-100 overflow-auto" style={{ padding: "10px 0px" }}>
                        {
                            this.props.hiclasstvInfo.currentInfoTap === enInfoTap.Event ?
                                <WebPromotionContainer
                                    handleUserLoginContainer={this.handleSelectedFunc}
                                /> :
                                <WebHiClassInfoContainer />
                        }
                        <WebFooter />
                    </div>
                    {
                        !ConstData.IS_LOCAL_VERSION && this.props.cookies.get("isShow") === "true" && this.props.isNoticeModalOpened &&
                        <Notice001
                            isModalOpened={this.props.cookies.get("isShow") ? true : false}
                            handleSelectedFunc={this.handleSelectedFunc}
                        />
                    } {
                        Number(this.props.cookies.get("liveSeq")) > 0 && this.props.isRetryLoginModalOpened &&
                        <LiveReJoinAlertModal
                            isAlertModalOpened={this.props.isRetryLoginModalOpened}
                            handleSelectedFunc={this.handleSelectedFunc}
                        />
                    }
                </div>
        );
    }
}

export default connect(
    ({ admin, user, hiclasstv }, { cookies }) => ({
        isFindIdPasswordOpened  : user.isFindIdPasswordOpened,
        //findIdPasswordOption    : user.findIdPasswordOption,

        isCheckedSubDomain      : admin.isCheckedSubDomain,

        hiclasstvInfo           : {
            currentInfoTap      : hiclasstv.currentInfoTap,
            currentAdminTap     : hiclasstv.currentAdminTap
        },

        isConnectedOnScreenApp  : hiclasstv.isConnectedOnScreenApp,

        userInfo                : {
            userID              : user.userID,
            passWD              : user.passWD,
            passConfirm         : user.passConfirm,
            phoneNumber         : user.phoneNumber,
            confirmNumber       : user.confirmNumber,
            userNickname        : user.userNickname,
            userEmail           : user.userEmail,
            userExtraCode       : user.userExtraCode,
            userProfile         : user.userProfile,
            profileImgUrl       : user.profileImgUrl,
            isIdCheckOK         : user.isIdCheckOK,    
            isPhoneTestStart    : user.isPhoneTestStart,
            isPhoneTestOK       : user.isPhoneTestOK,
            isEmailTestStart    : user.isEmailTestStart,
            isEmailTestOK       : user.isEmailTestOK
        },

        userKind                : user.userKind,
        isConfigAdmin           : user.isConfigAdmin,
        isCreateAdmin           : user.isCreateAdmin,

        loginMessage            : user.loginMessage,
        alertKind               : user.alertKind,
        alertType               : user.alertType,
        userInfoAlertKind       : user.userInfoAlertKind,
        userInfoMessage         : user.userInfoMessage,
        isInfoOpened            : user.isRegistInfoOpened,
        isLogInOpened           : user.isLogInOpened,
        isLoginAlertModalOpened : user.isLoginAlertModalOpened,

        certiCodeSeq            : user.certiCodeSeq,
        emailCertiCodeSeq       : user.emailCertiCodeSeq,

        isNeedCookiesInfoSave   : user.isNeedCookiesInfoSave,

        cookies                 : cookies,

        isNoticeModalOpened     : hiclasstv.isNoticeModalOpened,
        isRetryLoginModalOpened : hiclasstv.isRetryLoginModalOpened,

        isSettingLocalInfoModalOpened : hiclasstv.isSettingLocalInfoModalOpened,

        localServerInfo         : hiclasstv.localServerInfo,
        checkAuthCodeResult     : hiclasstv.checkAuthCodeResult,
    }),
    {
        logIn,
        idCheck,
        phoneCheckSend, 
        phoneCheckConfirm,
        emailCheckSend,
        emailCheckConfirm,
        falseToPhoneCheckOk,
        falseToPhoneCheckSend,
        falseToEmailCheckOk,
        falseToEmailCheckSend,
        userRegist,
        userInfoToggle, 
        logInToggle,
        saveCookiesToggle,
        setLoginMessage,
        setMessageString,
        changeAlertMessage,
        toggle_findIdPasswordModal,
        toggleNoticeModal,
        autoLogIn,
        logInThroughNotification,
        logInByGuest,
        logInByQRCode,

        toggleLogInAlertModal,
        toggleRetryLoginModal,
        requestMixedClassLogin,

        getLocalServerInfo,
        toggle_settingLocalInfoModal,
        checkLocalServerAuthCode,
        getCloudServerInfo,

        notifyLogoutToAndroid
    }
)(UserLoginContainer);