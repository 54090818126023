import React from 'react';

import WebConfigFriendInfo from './WebConfigFriendInfo';
import WebRcvRequestFriendInfo from './WebRcvRequestFriendInfo';
import WebSendRequestFriendInfo from './WebSendRequestFriendInfo';
import UserInfo from '../../etc/list/UserInfo';

const WebFriendInfo = ({ kind, friend_info, handleSelectedFunc }) => {
    switch (kind) {
        case 'send' :
            return <WebSendRequestFriendInfo friend_info={friend_info} handleSelectedFunc={handleSelectedFunc} />;

        case 'rcv' :
            return <WebRcvRequestFriendInfo friend_info={friend_info} handleSelectedFunc={handleSelectedFunc} />;

        case 'my' :
            return <WebConfigFriendInfo friend_info={friend_info} handleSelectedFunc={handleSelectedFunc} />

        default :
            return <UserInfo user_info={friend_info} />;
    }
}

const WebConfigFriendList = ({ kind, rowKey, message, list_friends, handleSelectedFunc }) => {
    return (
        list_friends !== undefined && list_friends !== null && list_friends.length > 0 ?
        <table className="d-flex flex-column table table-sm table-hover mb-0">
            <tbody>
                {
                    list_friends.map(friend => (
                        <WebFriendInfo key={rowKey + friend.userSeq} kind={kind} friend_info={friend} handleSelectedFunc={handleSelectedFunc} />
                    ))
                }
            </tbody>
        </table> :
        <div className="view m-3 text-center grey-text">
            {message}
        </div>
    );
}

export default WebConfigFriendList;