import React, { useState, useEffect } from 'react';

const WebAddLocalSite = ({ isAddLocalSiteOK, handleSelectedFunc, onChangeIsAddLocalSiteOK }) => {
    const [localSiteName, setLocalSiteName] = useState("");

    useEffect(() => {
        if (isAddLocalSiteOK) {
            setLocalSiteName("");
            onChangeIsAddLocalSiteOK();
        }
    }, [isAddLocalSiteOK]);

    const onChangeLocalSiteName = (e) => {
        setLocalSiteName(e.target.value);
    }

    const onClickAddLocalSite = (e) => {
        handleSelectedFunc({ selectedFunc: 'CREATE_LOCAL_SITE', data: localSiteName });
    }

    return (
        <div className="d-flex py-3 align-items-center justify-content-center border-bottom">
            <div className="card web-list-card border-primary">
                <div className="input-area">
                    <div className="input-label font-md">
                        <span><b>로컬 사이트 이름</b></span>
                    </div>
                    <div className="input-data">
                        <div className="d-flex input-group justify-content">
                            <input type="email" id="inputLocalSiteName" value={localSiteName} className="d-flex flex-fill web-search-input mr-2" placeholder="로컬 사이트 이름을 입력하세요" style={{width:'70%'}} onChange={onChangeLocalSiteName} />
                        </div>
                    </div>
                </div>
                <div className="d-flex text-center mt-3">
                    <button type="button" className="btn btn-md hiclasstv-blue white-text m-0 px-4 py-1" onClick={onClickAddLocalSite} disabled={localSiteName !== "" ? false : true}>
                        <b>생성</b>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WebAddLocalSite;