import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { startAloneChatting, toggle_searchChatList, toggle_createChatRoom, toggle_configChatList, toggle_participateChatRoom, closeModal } from '../modules/chat';
import { changeFalseToGotoChatList, gotoPage, joinChatting, startAloneChattingAfterFinishChat, createChatRoom, searchChatListResult} from '../modules/chat';
import { /* startLive, */ stopLive } from '../modules/live';

import ConfigMyChatListContainer from './chat/ConfigMyChatListContainer';
import MyChatListContainer from './chat/MyChatListContainer';

import RightSideModal from '../components/template/modal/RightSideModal';
import CenteredModal from '../components/template/modal/CenteredModal';
import AllCoveredModal from '../components/template/modal/AllCoveredModal';

import SearchMyChatList from '../components/chat/modal/SearchMyChatList';
import CreateChatRoom from '../components/chat/modal/CreateChatRoom';
import ParticipateChatRoom from '../components/chat/modal/ParticipateChatRoom';
import RemovedChatRoomMember from '../components/chat/modal/RemovedChatRoomMember';

import { enBrowserType } from '../lib/ConstCommand';
import ConstData from '../lib/ConstData';

class ChatListContainer extends Component {

    componentDidMount() {
        const { isGotoChatList, isConfigChatListModalOpened, changeFalseToGotoChatList, closeModal } = this.props;
        
        if (isGotoChatList) {
            changeFalseToGotoChatList();
        }
        
        if(isConfigChatListModalOpened) {
            closeModal("NONE");
        }
    }

    handleSearchValue = ({ keyword }) => {
        const { list_chatting, searchChatListResult } = this.props;

        let newKeyword = keyword.toLowerCase();

        try {
            let result = list_chatting.filter(
                (chatting) => {
                    return (chatting.chatRoomName.toLowerCase().indexOf(newKeyword) > -1 ||
                        chatting.list_member.filter(member => member.userNickname.toLowerCase().indexOf(newKeyword)) > -1);
                }
            );
    
            searchChatListResult({ result });
        } catch (err) {
            console.log("handleSearchValue() - err => ", err);
        }
    }

    handleSelectedFunc = ({selectedFunc, chatRoomSeq, chatRoomName, list_member}) => {
        const { userInfo, performChatRoomInfo } = this.props;
        const { toggle_participateChatRoom } = this.props;
        const { joinChatting, startAloneChattingAfterFinishChat, createChatRoom, stopLive, gotoPage } = this.props;

        switch(selectedFunc) {
            case "SEARCH" :
                this.props.toggle_searchChatList(selectedFunc);
                break;
            
            case "CREATE" :
                this.props.toggle_createChatRoom(selectedFunc);
                break;

            case "CREATE_OK" :
                if (performChatRoomInfo.chatRoomSeq === -1) {
                    createChatRoom({ selectedFunc, chatRoomName, creatorSEQ: userInfo.userSeq, userArr: list_member, isWithEntry: 'Y' });
                } else {
                    createChatRoom({ selectedFunc, chatRoomName, creatorSEQ: userInfo.userSeq, userArr: list_member, isWithEntry: 'N' });
                }
                break;

            case "START_LIVE" :
                if (performChatRoomInfo.chatRoomSeq > 0) { // if (performChatRoomInfo.chatRoomSeq === -1 ) {    // original
                    toggle_participateChatRoom({ selectedFunc, chatRoomSeq: 0, chatRoomName: ConstData.IS_LOCAL_VERSION ? "채팅방" : "나와의 채팅"});
                } else {
                    if (performChatRoomInfo.chatRoomSeq === 0) {
                        gotoPage({ page: "CHATTING" });
                    } else {
                        //startLive({ userSeq: userInfo.userSeq, msgText: "나와의 채팅을 시작합니다." });
                        this.props.startAloneChatting();
                    }
                }
                break;

            case "STOP_LIVE" :
                stopLive({ userSeq: userInfo.userSeq });
                break;

            case "PARTICIPATE" :
                if(performChatRoomInfo.chatRoomSeq < 0) {
                    if (chatRoomName.length > 8) {
                        chatRoomName = chatRoomName.substring(0, 6) + "...";
                    }
                    joinChatting({ chatRoomSeq, userSeq: userInfo.userSeq, msgText: "[" + chatRoomName + "] 팀업에 참여합니다." });
                } else {
                    if (performChatRoomInfo.chatRoomSeq === chatRoomSeq) {
                        gotoPage({ page: "CHATTING" });
                    } else {
                        toggle_participateChatRoom({ selectedFunc, chatRoomSeq, chatRoomName });
                    }
                }
                break;

            case "PARTICIPATE_OK" :
                if (chatRoomSeq === 0) {
                    startAloneChattingAfterFinishChat({ chatRoomSeq: performChatRoomInfo.chatRoomSeq, userSeq: userInfo.userSeq, msgText: "나와의 채팅을 시작합니다." });
                } else {
                    if (chatRoomName.length > 8) {
                        chatRoomName = chatRoomName.substring(0, 6) + "...";
                    }
                    joinChatting({ chatRoomSeq, userSeq: userInfo.userSeq, msgText: "[" + chatRoomName + "] 팀업에 참여합니다." });
                }
                break;            

            case "ENTER" :
                gotoPage({ page: "CHATTING" });
                break;

            case "CONFIG" :
                this.props.toggle_configChatList(selectedFunc);
                break;           

            case "NONE" :
                this.props.closeModal(selectedFunc);
                break;

            default:
                break;
        }
    }

    render() { 
        const { browserType, isGotoChatting, isGotoLiveList, isGotoFriendList } = this.props;
        const { selectedFunc, removedChatRoomName } = this.props;
        const { userInfo, performChatRoomInfo, participateChatRoomInfo } = this.props;
        const { list_chatting, list_searchChatting, list_friends } = this.props;
        const { handleSelectedFunc, handleSearchValue } = this;

        const { isSearchChatListModalOpened, isCreateChatRoomModalOpened, isParticipateChatRoomModalOpened, isConfigChatListModalOpened, isAlertRemovedModalOpened } = this.props;

        if (browserType === enBrowserType.HiClass) {
            if(isGotoChatting) {
                return <Redirect to = '/chatting_hiclass' />;
            } else if(isGotoLiveList) {
                return <Redirect to = '/list_live_hiclass' />;
            } else if(isGotoFriendList) {
                return <Redirect to = '/list_friend_hiclass' />;
            }

            return (
                isConfigChatListModalOpened === true ?
                    <ConfigMyChatListContainer handleSelectedFunc={handleSelectedFunc} /> :
                    <>
                        {
                            isSearchChatListModalOpened &&
                            <RightSideModal modalClose={handleSelectedFunc}>
                                <SearchMyChatList
                                    list_chatting={list_chatting}
                                    list_searchChatting={list_searchChatting}
                                    handleSelectedFunc={handleSelectedFunc}
                                    handleSearchValue={handleSearchValue}
                                />
                            </RightSideModal>
                        } {
                            isCreateChatRoomModalOpened &&
                            <RightSideModal modalClose={handleSelectedFunc}>
                                <CreateChatRoom
                                    userInfo={userInfo}
                                    list_friends={list_friends}
                                    handleSelectedFunc={handleSelectedFunc}
                                />
                            </RightSideModal>
                        } {
                            isParticipateChatRoomModalOpened &&
                            <CenteredModal modalClose={handleSelectedFunc}>
                                <ParticipateChatRoom
                                    performChatRoomInfo={performChatRoomInfo}
                                    participateChatRoomInfo={participateChatRoomInfo}
                                    handleSelectedFunc={handleSelectedFunc}
                                 />
                            </CenteredModal>
                        } {
                            isAlertRemovedModalOpened &&
                            <AllCoveredModal modalClose={handleSelectedFunc}>
                                <RemovedChatRoomMember
                                    removedChatRoomName={removedChatRoomName}
                                    handleSelectedFunc={handleSelectedFunc}
                                />
                            </AllCoveredModal>
                        }
                        <MyChatListContainer 
                            browserType={browserType}
                            handleSelectedFunc={handleSelectedFunc}
                            selectedFunc={selectedFunc}    
                        />
                    </>
            );
        } else {
            return (
                <MyChatListContainer browserType={browserType} />
            );
        }
    }
}

export default connect(
    ({ user, friend, chat }) => ({
        userInfo            : {
            userSeq         : user.userSeq,
            userNickname    : user.userNickname,
            userProfile     : user.userProfile,
            profileImgUrl   : user.profileImgUrl
        },

        list_friends        : friend.list_friends,
        list_chatting       : chat.list_chatting,
        list_searchChatting : chat.list_searchChatting,

        performChatRoomInfo     : chat.performChatRoomInfo,
        participateChatRoomInfo : chat.participateChatRoomInfo,
        
        removedChatRoomName : chat.removedChatRoomName,

        isGotoChatList      : chat.isGotoChatList,
        isGotoFriendList    : chat.isGotoFriendList,
        isGotoChatting      : chat.isGotoChatting,
        isGotoLiveList      : chat.isGotoLiveList,

        isSearchChatListModalOpened         : chat.isSearchChatListModalOpened,
        isCreateChatRoomModalOpened         : chat.isCreateChatRoomModalOpened,
        isParticipateChatRoomModalOpened    : chat.isParticipateChatRoomModalOpened,
        isConfigChatListModalOpened         : chat.isConfigChatListModalOpened,
        isEditChatRoomModalOpened           : chat.isEditChatRoomModalOpened,
        isAlertRemovedModalOpened           : chat.isAlertRemovedModalOpened,

        selectedFunc        : chat.selectedFunc
    }),

    {
        joinChatting,
        createChatRoom,
        searchChatListResult,
        startAloneChatting,
        startAloneChattingAfterFinishChat,

        //startLive,
        stopLive,

        toggle_searchChatList,
        toggle_createChatRoom,
        toggle_configChatList,
        toggle_participateChatRoom,
        closeModal,
        changeFalseToGotoChatList,
        gotoPage
    }
)(ChatListContainer);