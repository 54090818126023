import React from 'react';
import { BsX } from 'react-icons/bs';

const OffAirLive = ({ selectedLiveInfo, handleSelectedFunc }) => {

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" onClick={() => handleSelectedFunc({ selectedFunc: "OFFAIR_TOGGLE", liveSeq:-1 })}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex text-center my-2 dark-grey-text small">
                클래스 방송이 종료됩니다.<br />
                정말로 종료하시겠습니까?
            </div>
            <div className="content-area dark-grey-text my-0">
                <span className="small mb-1">[클래스 이름]</span>
                <div className="text-area text-center area-80">
                    <span className="hiclass-text" title={selectedLiveInfo.liveName}>
                        <b>{selectedLiveInfo.liveName}</b>
                    </span>
                </div>
            </div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "OFFAIR", liveSeq: selectedLiveInfo.liveSeq, liveName:selectedLiveInfo.liveName })}>예</button>
                <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "OFFAIR_TOGGLE", liveSeq: -1 })}>아니오</button>
            </div>
        </div>
    );
}

export default OffAirLive;