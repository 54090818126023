import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { enPassTap } from "../../lib/ConstCommand";

import WebPassProductList from "../../components/pass/WebPassProductList";
import WebPassPchsList from "../../components/pass/WebPassPchsList";
import WebPassRefundList from "../../components/pass/WebPassRefundList";

// import WebAdminPassInfo from "../../components/pass/WebAdminPassInfo";
// import WebB2CPassInfo from "../../components/pass/WebB2CPassInfo";
// import WebB2BPassInfo from "../../components/pass/WebB2BPassInfo";

import {
    getMyManageSubDomainList,
    findUser
} from "../../modules/admin";

import {
    getAllPassList,
    getProductPass,
    initPurchasePassList,
    getPurchasePass,
    getPurchaseManagePass,
    addProductPass,
    registPassB2B,
    registPassB2C,
    updatePassUseInfo,
    buyPass,
    refundPass,
    partialRefundPass
} from "../../modules/pass";

class WebPassContainer extends Component {

    componentDidMount = () => {
        if (this.props.isCreateAdmin || this.props.isConfigAdmin) {
            this.props.getProductPass();
        }

        if (this.props.user_info.userKind === "hiclasstv") {
            this.props.getAllPassList({ kind: "b2c" });
        } else {
            this.props.getAllPassList({ kind: "b2b" });
        }
    }

    handleSelectedFunc = ({ selectedFunc, term, kind, prdtSeq, prdtInfo, pchsDate, pchsSeq, useDateInfo, selSubDomainSeq, selUserSeq, keyword, subDomainName, data, price }) => {
        switch (selectedFunc) {
            case "INIT_PCHS_LIST":
                this.props.initPurchasePassList();
                break;

            case "GET_MANAGE_INFO":
                this.props.getMyManageSubDomainList({
                    userSeq: this.props.userSeq,
                    userKind: this.props.userKind
                });
                break;

            case "GET_PCHS_LIST":
                console.log("GET_PCHS_LIST - ", term, kind, selSubDomainSeq, selUserSeq);
                this.props.getPurchasePass({
                    term,
                    kindCode: kind,
                    subDomainSeq: selSubDomainSeq,
                    selUserSeq
                });
                break;

            case "REGIST_PASS_B2B":
                console.log("REGIST_PASS_B2B - ", prdtSeq, selSubDomainSeq);
                this.props.registPassB2B({
                    productSeq: prdtSeq,
                    subDomainSeq: selSubDomainSeq,
                    registUserSeq: this.props.userSeq,
                    prdtPassInfo: prdtInfo,
                    pchsDate,
                    pchsOfficeType: "OFFLINE"
                });
                break;

            case "REGIST_PASS_B2C":
                console.log("REGIST_PASS_B2C - ", prdtSeq, selSubDomainSeq, selUserSeq);
                this.props.registPassB2C({
                    productSeq: prdtSeq,
                    subDomainSeq: 1,
                    registUserSeq: this.props.userSeq,
                    passUserSeq: selUserSeq,
                    prdtPassInfo: prdtInfo,
                    pchsDate,
                    pchsOfficeType: "OFFLINE"
                });
                break;

            case "USE_PASS":
                console.log("USE_PASS - ", pchsSeq, useDateInfo);
                this.props.updatePassUseInfo({ pchsSeq, useDateInfo });
                break;

            case "FIND_USER":
                //console.log("FIND_USER - ", keyword, subDomainName);
                this.props.findUser({
                    kind: "search-user",
                    userKind: subDomainName,
                    keyWord: keyword
                });
                break;

            case "PG_PAYMENT_SUCCESS":
                this.props.buyPass({
                    selectedFunc: "SHOW_PASS_PCHS_LIST",
                    isSuccessed: data.isSuccessed,
                    isAddSuccess: data.isAddSuccess,
                    pchs_info: data.pchs_info
                });
                break;

            case "REFUND_PASS":
                this.props.refundPass({ pchsSeq });
                break;

            case "P_REFUND_PASS":
                this.props.partialRefundPass({ pchsSeq, price });
                break;

            case "ADD_PRDT_PASS":
                this.props.addProductPass(data);
                break;

            default:
                break;
        }
    }

    render() {
        if (this.props.user_info.userKind !== "hiclasstv" && !this.props.isConfigAdmin) {
            return (<Redirect to="/main" />);
        }

        switch (this.props.currentPassTap) {
            case enPassTap.PRDT_LIST:
                return (
                    <WebPassProductList
                        user_info={this.props.user_info}
                        list_pass={this.props.list_pass}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
                );

            case enPassTap.PCHS_LIST:
                return (
                    <WebPassPchsList
                        user_info={this.props.user_info}
                        isCreateAdmin={this.props.isCreateAdmin}
                        isConfigAdmin={this.props.isConfigAdmin}
                        list_createSubDomain={this.props.list_createSubDomain}
                        list_configSubDomain={this.props.list_configSubDomain}
                        selectedSubDomainInfo={this.props.selectedSubDomainInfo}
                        selectedUserInfo={this.props.selectedUserInfo}
                        searchResult={this.props.searchResult}
                        list_pchsPass={this.props.list_pchsPass}
                        list_prdtKindCode={this.props.list_prdtKindCode}
                        list_prdtScaleCode={this.props.list_prdtScaleCode}
                        list_prdtTargetCode={this.props.list_prdtTargetCode}
                        list_prdtTermCode={this.props.list_prdtTermCode}
                        list_prdtPass={this.props.list_prdtPass}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
                );

            case enPassTap.REFUND_LIST:
                return (
                    <WebPassRefundList

                    />
                );

            default:
                return <></>;
        }

        /* return (
            this.props.currentPassTap === enPassTap.PRDT_LIST ?
                <WebPassProductList
                    user_info={this.props.user_info}
                    list_pass={this.props.list_pass}
                    handleSelectedFunc={this.handleSelectedFunc}
                /> :
            this.props.currentPassTap === enPassTap.PCHS_LIST ?
                <WebPassPchsList
                    user_info={this.props.user_info}
                    isCreateAdmin={this.props.isCreateAdmin}
                    isConfigAdmin={this.props.isConfigAdmin}
                    list_createSubDomain={this.props.list_createSubDomain}
                    list_configSubDomain={this.props.list_configSubDomain}
                    selectedSubDomainInfo={this.props.selectedSubDomainInfo}
                    selectedUserInfo={this.props.selectedUserInfo}
                    searchResult={this.props.searchResult}
                    list_pchsPass={this.props.list_pchsPass}
                    list_prdtKindCode={this.props.list_prdtKindCode}
                    list_prdtScaleCode={this.props.list_prdtScaleCode}
                    list_prdtTargetCode={this.props.list_prdtTargetCode}
                    list_prdtTermCode={this.props.list_prdtTermCode}
                    list_prdtPass={this.props.list_prdtPass}
                    handleSelectedFunc={this.handleSelectedFunc}
                /> :
            this.props.currentPassTap === enPassTap.REFUND_LIST ?
                <WebPassRefundList

                /> :
                <></>
        ); */
    }
}

export default connect(
    ({ user, pass, admin, hiclasstv }) => ({
        currentPassTap          : hiclasstv.currentPassTap,

        isConfigAdmin           : user.isConfigAdmin,
        isCreateAdmin           : user.isCreateAdmin,

        user_info               : {
            userSeq             : user.userSeq,
            userKind            : user.userKind,
            userID              : user.userID,
            userNickname        : user.userNickname,
            userEmail           : user.userEmail,
            phoneNumber         : user.phoneNumber,
            subDomainSeq        : user.mySubDomainSeq
        },

        list_configSubDomain    : admin.list_configSubDomain,
        list_createSubDomain    : admin.list_createSubDomain,
        selectedSubDomainInfo   : admin.selectedSubDomainInfo,
        selectedUserInfo        : admin.selectedUserInfo,

        list_pass               : pass.list_pass,
        list_prdtPass           : pass.list_prdtPass,
        list_pchsPass           : pass.list_pchsPass,

        list_prdtKindCode       : pass.list_prdtKindCode,
        list_prdtScaleCode      : pass.list_prdtScaleCode,
        list_prdtTargetCode     : pass.list_prdtTargetCode,
        list_prdtTermCode       : pass.list_prdtTermCode,

        searchResult            : admin.searchResult,
    }),
    {
        initPurchasePassList,
        getMyManageSubDomainList,
        getAllPassList,
        getProductPass,
        getPurchasePass,
        getPurchaseManagePass,
        addProductPass,
        registPassB2B,
        registPassB2C,
        updatePassUseInfo,
        findUser,
        buyPass,
        refundPass,
        partialRefundPass
    }
)(WebPassContainer);