import React, {useState} from 'react';
import ConstData from '../../../lib/ConstData';

const FindPW = ({ handleSelectedFunc,  }) => {

    const [userId, setId] = useState("");

    const onChangeID = (e) => {
        let convertStr = e.target.value.replace(/[^a-z0-9_]/gi, '');
        setId(convertStr);
        //console.log(e.target.value);
    }

    return (
        <div className="d-flex flex-column align-items-center my-2 w-100">
            <div className="d-flex flex-column font-md text-center py-4 px-2 w-100">
                <span>
                    HiClassTV에 가입한 ID를 입력하세요.
                </span>
                <div className="input-area">
                    <input type="text" id="findPwId" className="d-flex flex-fill web-search-input" placeholder="ID를 입력하세요" onChange={onChangeID} value={userId} />
                </div>
            </div>
            <div className="d-flex w-100 justify-content-center">
                <button className="btn btn-sm hiclasstv-grey px-3" onClick={() => handleSelectedFunc({selectedFunc:"PRE", step:ConstData.FIND_STEP.START})}>이전</button>
                <button className="btn btn-sm btn-primary px-4" onClick={() => handleSelectedFunc({selectedFunc:"CHECK_ID", userId})} disabled={userId === "" ? true : false}>다음</button>
                <button className="btn btn-sm hiclasstv-grey px-3" onClick={() => handleSelectedFunc({selectedFunc:"CLOSE"})}>취소</button>
            </div>
        </div>
    );
}

export default FindPW;