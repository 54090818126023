import React from 'react';

import WebAdminPassInfo from './WebAdminPassInfo';
import WebB2CPassInfo from './WebB2CPassInfo';
import WebB2BPassInfo from './WebB2BPassInfo';

const WebPassPchsList = (props) => {
    return (
        props.user_info.userKind === 'hiclasstv' ?
            props.isConfigAdmin || props.isCreateAdmin ? 
                <WebAdminPassInfo // ........... hiclasstv 사이트 관리자가 보는 이용권 조회 페이지 (B2B B2C 전체 가능)
                    list_configSubDomain={props.list_configSubDomain}
                    selectedSubDomainInfo={props.selectedSubDomainInfo}
                    selectedUserInfo={props.selectedUserInfo}
                    searchResult={props.searchResult}
                    list_pchsPass={props.list_pchsPass}
                    list_prdtKindCode={props.list_prdtKindCode}
                    list_prdtScaleCode={props.list_prdtScaleCode}
                    list_prdtTargetCode={props.list_prdtTargetCode}
                    list_prdtTermCode={props.list_prdtTermCode}
                    list_prdtPass={props.list_prdtPass}
                    handleSelectedFunc={props.handleSelectedFunc}
                /> :
                <WebB2CPassInfo // ............. 일반 B2C 유저가 보는 이용권 조회 페이지
                    selectedUserInfo={props.user_info}
                    list_pchsPass={props.list_pchsPass}
                    list_prdtPass={props.list_prdtPass}
                    list_prdtKindCode={props.list_prdtKindCode}
                    handleSelectedFunc={props.handleSelectedFunc}
                /> :
        props.user_info.userKind === 'easy' ?
            (props.isConfigAdmin && props.isCreateAdmin) ?
                <WebB2BPassInfo // ................ easy 사이트 관리 권한 & 사이트 추가 권한이 있는 easy 직원이 보는 이용권 조회 페이지
                    isCreator={props.isConfigAdmin}
                    list_configSubDomain={props.list_configSubDomain}
                    selectedSubDomainInfo={props.selectedSubDomainInfo}
                    list_pchsPass={props.list_pchsPass}
                    list_prdtKindCode={props.list_prdtKindCode}
                    list_prdtScaleCode={props.list_prdtScaleCode}
                    list_prdtTargetCode={props.list_prdtTargetCode}
                    list_prdtTermCode={props.list_prdtTermCode}
                    list_prdtPass={props.list_prdtPass}
                    handleSelectedFunc={props.handleSelectedFunc}
                /> :
            props.isConfigAdmin ?
                <WebB2BPassInfo // ................ easy 사이트 관리 권한이 있는 easy 직원이 보는 이용권 조회 페이지
                    isCreator={props.isConfigAdmin}
                    list_configSubDomain={props.list_configSubDomain}
                    selectedSubDomainInfo={props.selectedSubDomainInfo}
                    list_pchsPass={props.list_pchsPass}
                    list_prdtKindCode={props.list_prdtKindCode}
                    list_prdtScaleCode={props.list_prdtScaleCode}
                    list_prdtTargetCode={props.list_prdtTargetCode}
                    list_prdtTermCode={props.list_prdtTermCode}
                    list_prdtPass={props.list_prdtPass}
                    handleSelectedFunc={props.handleSelectedFunc}
                /> :
            props.isCreateAdmin ?
                <WebB2BPassInfo // ................ 사이트 추가 권한이 있는 easy 직원이 보는 이용권 조회 페이지
                    isCreator={props.isCreateAdmin}
                    list_configSubDomain={props.list_createSubDomain}
                    selectedSubDomainInfo={props.selectedSubDomainInfo}
                    list_pchsPass={props.list_pchsPass}
                    list_prdtKindCode={props.list_prdtKindCode}
                    list_prdtScaleCode={props.list_prdtScaleCode}
                    list_prdtTargetCode={props.list_prdtTargetCode}
                    list_prdtTermCode={props.list_prdtTermCode}
                    list_prdtPass={props.list_prdtPass}
                    handleSelectedFunc={props.handleSelectedFunc}
                /> :
                <></> :
        props.isConfigAdmin && 
            <WebB2BPassInfo // ............................ 서브 도메인 관리자가 보는 이용권 조회 페이지
                list_configSubDomain={props.list_configSubDomain}
                list_pchsPass={props.list_pchsPass}
                handleSelectedFunc={props.handleSelectedFunc}
            />
    )
};

export default WebPassPchsList;