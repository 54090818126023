import React from 'react';
import { Modal } from 'reactstrap';

const LivePullScreenAlertModal = ({ isAlertModalOpened, handleSelectedFunc }) => {
    return (
        <Modal isOpen={isAlertModalOpened} centered backdrop={false}>
            <div className="card container-fluid">
                <div className="card-body px-2">
                    <div className="title-area text-center dark-grey-text">
                        <div className="d-flex flex-column">
                            <span>
                                화면 당겨오기 알림
                            </span>
                        </div>
                    </div>
                    <div className="content-area dark-grey-text font-md">
                        <span className="mb-1">
                            Pentalk onScreen 앱과 연동되지 않아
                        </span>
                        <span className="mb-2">
                            웹페이지 이미지를 전송했습니다.
                        </span>
                        <span className="small mb-1">
                            ※ 화면 전송을 원할 경우 Pentalk onScreen 앱과 연동하시기 바랍니다.
                        </span>
                    </div>
                    <button type="button" className="btn btn-md green white-text py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "LIVE_PULL_SCREEN_TOGGLE" })}>확인</button>
                </div>
            </div>
        </Modal>
    );
};

export default LivePullScreenAlertModal;