import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'reactstrap';
import { FaTicketAlt } from 'react-icons/fa';

import { prdtKind, prdtSacle, prdtTarget, prdtTerm } from '../../../lib/ConstCommand';
import ConstData from '../../../lib/ConstData';

const WebAddPassProduct = ({ isModalOpened, toggleModalOpened, list_prdtKindCode, list_prdtScaleCode, list_prdtTargetCode, list_prdtTermCode, handleSelectedFunc }) => {
    const [lKindCode, setKindCode] = useState(prdtKind.Purchase);
    const [lScaleCode, setScaleCode] = useState(prdtSacle.L50.code);
    const [lTargetCode, setTargetCode] = useState(prdtTarget.B2B);
    const [lTermCode, setTermCode] = useState(prdtTerm.D30.code);
    const [lPrdtPassName, setPrdtPassName] = useState("");

    useEffect(() => {
        if (lTargetCode === prdtTarget.B2C) {
            /* if (lScaleCode !== prdtSacle.B2C.code) {
                setScaleCode(prdtSacle.B2C.code);
            } */
            setScaleCode(prdtSacle.B2C.code);
        } else {
            /* if (lScaleCode === prdtSacle.B2C.code) {
                setScaleCode(prdtSacle.L50.code);
            } */
            setScaleCode(prdtSacle.L50.code);
        }
    }, [lTargetCode]);

    const onChangePrdtKind = (e) => {
        setKindCode(e.target.value);
    }

    const onChangePrdtTarget = (e) => {
        setTargetCode(e.target.value);
    }

    const onChangePrdtScale = (e) => {
        setScaleCode(e.target.value);
    }

    const onChangePrdtTerm = (e) => {
        setTermCode(e.target.value);
    }

    const onChangePrdtPassName = (e) => {
        setPrdtPassName(e.target.value);
    }

    const handleKeyPress = () => {

    }

    const onClickAddPrdtPass = (kindCode, targetCode, scaleCode, termCode, passName) => {
        console.log('onClickAddPrdtPass() - ', kindCode, targetCode, scaleCode, termCode, passName);
        handleSelectedFunc({
            selectedFunc: "ADD_PRDT_PASS",
            data: {
                prdt_info: {
                    passName,
                    kindCode,
                    targetCode,
                    scaleCode,
                    termCode,
                }
            }
        });
    }
    
    return (
        <Modal isOpen={isModalOpened} centered size='lg' backdrop={true} toggle={() => toggleModalOpened(false)}>
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <span className="d-flex white-text mx-1 my-2" style={{height:'max-content'}}>
                        <FaTicketAlt className="font-lg mr-2" aria-hidden="true" />이용권 상품 추가
                    </span>
                </div>
                <div className="px-2">
                    <div className="d-flex flex-column w-100">
                        <div className="d-flex justify-content-between p-2 my-2 w-100">
                            <div className="d-flex px-1" style={{ width: '20%', minWidth: 'max-content', maxWidth: '20%' }}>
                                <span className="d-flex my-1"><b>이용권 종류</b></span>
                            </div>
                            <div className="d-flex px-1" style={{ width: '80%', maxWidth: '90%' }}>
                                {
                                    list_prdtKindCode !== undefined && list_prdtKindCode !== null && list_prdtKindCode.length > 0 ?
                                        list_prdtKindCode.map(
                                            (info, index) => (
                                                <div className="d-flex align-items-center w-100" key={"kind-" + index}>
                                                    <input className="d-flex mr-1 p-0" type="radio" name="groupOfPassKind" id={"passKindRadio_" + index} value={info.kindCode} defaultChecked={index === 0} onChange={onChangePrdtKind} />
                                                    <span>{info.kindName}</span>
                                                </div>
                                            )
                                        ) :
                                        <div className="d-flex align-items-center w-100">
                                            <span>선택 가능한 항목이 없습니다.</span>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="d-flex justify-content-between p-2 my-2 w-100">
                            <div className="d-flex px-1" style={{ width: '20%', minWidth: 'max-content', maxWidth: '20%' }}>
                                <span className="d-flex my-1"><b>이용권 대상</b></span>
                            </div>
                            <div className="d-flex px-1" style={{ width: '80%', maxWidth: '90%' }}>
                                {
                                    list_prdtTargetCode !== undefined && list_prdtTargetCode !== null && list_prdtTargetCode.length > 0 ?
                                        list_prdtTargetCode.map(
                                            (info, index) => (
                                                <div className="d-flex align-items-center w-100" key={"target-" + index}>
                                                    <input className="d-flex mr-1 p-0" type="radio" name="groupOfPassTarget" id={"passTargetRadio_" + index} value={info.targetCode} defaultChecked={index === 0} onChange={onChangePrdtTarget} />
                                                    <span>{info.targetName}</span>
                                                </div>
                                            )
                                        ) :
                                        <div className="d-flex align-items-center w-100">
                                            <span>선택 가능한 항목이 없습니다.</span>
                                        </div>
                                }
                            </div>
                        </div>
                        {
                            lTargetCode === prdtTarget.B2B &&
                            <div className="d-flex justify-content-between p-2 my-2 w-100">
                                <div className="d-flex px-1" style={{ width: '20%', minWidth: 'max-content', maxWidth: '20%' }}>
                                    <span className="d-flex my-1"><b>대상 규모</b></span>
                                </div>
                                <div className="d-flex px-1" style={{ width: '80%', maxWidth: '90%' }}>
                                    {
                                        list_prdtScaleCode !== undefined && list_prdtScaleCode !== null && list_prdtScaleCode.length > 0 ?
                                            list_prdtScaleCode.map(
                                                (info, index) => (
                                                    <div className="d-flex align-items-center w-100" key={"scale-" + index}>
                                                        <input className="d-flex mr-1 p-0" type="radio" name="groupOfPassScale" id={"passScaleRadio_" + index} value={info.scaleCode} defaultChecked={index === 0} onChange={onChangePrdtScale} />
                                                        <span>{info.scaleName}</span>
                                                    </div>
                                                )
                                            ) :
                                            <div className="d-flex align-items-center w-100">
                                                <span>선택 가능한 항목이 없습니다.</span>
                                            </div>
                                    }
                                </div>
                            </div>
                        }
                        <div className="d-flex justify-content-between p-2 my-2 w-100">
                            <div className="d-flex px-1" style={{ width: '20%', minWidth: 'max-content', maxWidth: '20%' }}>
                                <span className="d-flex my-1"><b>이용권 기간</b></span>
                            </div>
                            <div className="d-flex justify-content-between px-1" style={{ width: '80%', maxWidth: '90%' }}>
                                {
                                    list_prdtTermCode !== undefined && list_prdtTermCode !== null && list_prdtTermCode.length > 0 ?
                                        list_prdtTermCode.map(
                                            (info, index) => (
                                                <div className="d-flex align-items-center" key={"scale-" + index}>
                                                    <input className="d-flex mr-1 p-0" type="radio" name="groupOfPassTerm" id={"passTermRadio_" + index} value={info.termCode} defaultChecked={index === 0} onChange={onChangePrdtTerm} />
                                                    <span>{info.termName}</span>
                                                </div>
                                            )
                                        ) :
                                        <div className="d-flex align-items-center w-100">
                                            <span>선택 가능한 항목이 없습니다.</span>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="d-flex justify-content-between p-2 my-2 w-100">
                            <div className="d-flex px-1" style={{ width: '20%', minWidth: 'max-content', maxWidth: '20%' }}>
                                <span className="d-flex my-1"><b>이용권 이름</b></span>
                            </div>
                            <div className="d-flex px-1 input-group" style={{ width: '80%', maxWidth: '80%' }}>
                                <input type="text" className="d-flex flex-fill web-search-input px-2 py-1 white" placeholder="이용권 이름을 입력하세요" value={lPrdtPassName} onChange={onChangePrdtPassName} onKeyPress={handleKeyPress} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center p-2 my-2 w-100">
                            <button className="btn btn-sm btn-primary" type="button" onClick={() => onClickAddPrdtPass(lKindCode, lTargetCode, lScaleCode, lTermCode, lPrdtPassName)}>
                                생성
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default WebAddPassProduct;