import axios from 'axios';
import ConstData from '../ConstData';
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const get_all_lecture_list = ({ subDomainSeq, kind }) => {
    return axios.get(`${ConstData.REST_API_HOST_ADD}/lecture?kind=${kind}&subDomainSEQ=${subDomainSeq}`, options);
};

export const get_lecture_list = ({ userSeq, subDomainSeq }) => {
    return axios.get(`${ConstData.REST_API_HOST_ADD}/lecture/${userSeq}/${subDomainSeq}`, options);
};