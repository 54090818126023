import React, { useState, useEffect } from 'react';

import WebSmallGroupView from '../list/WebSmallGroupView';

const LiveSmallGroupWindow = ({ userSeq, performLiveSeq, smallGroupInfo, visibility, isRbmqServerConnected, isReadyLiveConsume }) => {
    const [smallGroupMember, setSmallGroupMember] = useState([]);

    useEffect(() => {
        if (smallGroupInfo.list_member && smallGroupInfo.list_member.length > 0) {
            let arr = [];

            smallGroupInfo.list_member.forEach(mem => {
                let info = {
                    ...mem,
                    imgEl : null
                };
                arr.push(info);
            });
            
            setSmallGroupMember(arr);
        }
    }, [smallGroupInfo]);
    
    return (
        <div className="small-group window" style={{visibility:`${visibility}`}}>
            <div>
                {
                    smallGroupMember && smallGroupMember.length > 0 ?
                        smallGroupMember.map(member => (
                            member.userSeq !== userSeq &&
                            <WebSmallGroupView 
                                key={'sg-member-' + member.userSeq}
                                liveSeq={performLiveSeq}
                                memberInfo={member}
                                isRbmqServerConnected={isRbmqServerConnected}
                                isReadyLiveConsume={isReadyLiveConsume}
                            />
                        )) :
                        <></>
                }
            </div>
        </div>
    );
}

export default LiveSmallGroupWindow;