import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';

import { ctBubbleKind, ctRcvKind } from '../lib/classTalk/CodeEnum';
import { ctWatsonStatus, stCardType } from '../lib/ConstCommand';

import createRequestMultiThunk from '../lib/createRequestMultiThunk';
import createMultiAction from '../lib/createMultiAction';

import { callEoForSendAction } from '../lib/EoBrowserSendUtil';

import { create_session_info, send_message, remove_session_info } from '../lib/api/watson';

import { APPEND_WATSON_MESSAGE_LIVE } from './classTalkLive';
import { APPEND_WATSON_MESSAGE} from './classTalk';

export const WATSON_STATE_INIT                  = 'watson/WATSON_STATE_INIT';
export const FINISH_LIVE_AFTER_WATSON_EXIT      = 'watson/FINISH_LIVE_AFTER_WATSON_EXIT';

const EXIT_WATSON_MODE                          = 'watson/EXIT_WATSON_MODE';

const WATSON_TOGGLE                             = 'watson/WATSON_TOGGLE';
const WATSON_TOGGLE_BY_HICLASS                  = 'watson/WATSON_TOGGLE_BY_HICLASS';

const WATSON_CHANGE_STATUS                      = 'watson/WATSON_CHANGE_STATUS';

const WATSON_PING                               = 'watson/WATSON_PING';

const WATSON_CREATE_SESSION                     = 'watson/WATSON_CREATE_SESSION';
const WATSON_CREATE_SESSION_SUCCESS             = 'watson/WATSON_CREATE_SESSION_SUCCESS';
const WATSON_SEND_MESSAGE                       = 'watson/WATSON_SEND_MESSAGE';
const WATSON_SEND_MESSAGE_SUCCESS               = 'watson/WATSON_SEND_MESSAGE_SUCCESS';
const WATSON_SEND_MESSAGE_TO_HICLASS            = 'watson/WATSON_SEND_MESSAGE_TO_HICLASS';

const WATSON_REMOVE_SESSION                     = 'watson/WATSON_REMOVE_SESSION';
const WATSON_REMOVE_SESSION_SUCCESS             = 'watson/WATSON_REMOVE_SESSION_SUCCESS';
const WATSON_REMOVE_SESSION_FINISH_LIVE         = 'watson/WATSON_REMOVE_SESSION_FINISH_LIVE';
const WATSON_REMOVE_SESSION_FINISH_LIVE_SUCCESS = 'watson/WATSON_REMOVE_SESSION_FINISH_LIVE_SUCCESS';

const FALSE_TO_WATSON_ERROR                     = 'watson/FALSE_TO_WATSON_ERROR';

export const exitWatsonMode                     = createAction(EXIT_WATSON_MODE);

export const watsonToggle                       = createAction(WATSON_TOGGLE);

export const watsonToggleByHiClass              = createMultiAction(WATSON_TOGGLE_BY_HICLASS, "watson", APPEND_WATSON_MESSAGE);
export const watsonSendMessageToHiClass         = createMultiAction(WATSON_SEND_MESSAGE_TO_HICLASS, "watson", APPEND_WATSON_MESSAGE);
export const watsonChangeStatus                 = createMultiAction(WATSON_CHANGE_STATUS, "watson", APPEND_WATSON_MESSAGE);
export const watsonPing                         = createMultiAction(WATSON_PING, "watson");

export const watsonCreateSession                = createRequestMultiThunk(WATSON_CREATE_SESSION, create_session_info, "watson", [APPEND_WATSON_MESSAGE_LIVE]);
export const watsonSendMessage                  = createRequestMultiThunk(WATSON_SEND_MESSAGE, send_message, "watson", [APPEND_WATSON_MESSAGE_LIVE]);
export const watsonRemoveSession                = createRequestMultiThunk(WATSON_REMOVE_SESSION, remove_session_info, "watson", [APPEND_WATSON_MESSAGE_LIVE]);
export const watsonRemoveSessionBeforeFinishLive = createRequestMultiThunk(WATSON_REMOVE_SESSION_FINISH_LIVE, remove_session_info, "watson", [APPEND_WATSON_MESSAGE_LIVE]);

export const falseToWatsonError                 = createAction(FALSE_TO_WATSON_ERROR);

const initialState = {
    isLectureLive               : false,
    isLectureMode               : false,
    isFinishLiveAfterExitWatson : false,
    isFinishLecture             : false,

    savedHiclassWatsonInfo      : {},

    isRunningWatson             : false,
    isSpeakingMode              : false,
    watsonInfo                  : {
        status                  : ctWatsonStatus.Exit,
        msg                     : [],
        modelCode               : "",
        sessionCode             : "",
        language                : "",
        context                 : "",
    },
    isWatsonError               : false,

    selectedLanguageCode        : 'ko-KR',

    msgText                     : [],

    eoBrowserWatsonInfo         : {
        isRunningWatson         : false,
        isSpeakingMode          : true,
        watsonStatus            : ctWatsonStatus.Exit,
        watsonLanguageCode      : 'ko-KR',
        msgText                 : ""
    },
    dialogHist                  : []
};

const watson = handleActions({
    [WATSON_STATE_INIT]: (state, action) => {
        //console.log("WATSON_STATE_INIT");

        return {
            ...state,
            isLectureLive               : false,
            isLectureMode               : false,
            isFinishLecture             : false,
            isFinishLiveAfterExitWatson : false,

            savedHiclassWatsonInfo      : {},
        
            isRunningWatson             : false,
            isSpeakingMode              : false,
            watsonInfo                  : {
                status                  : ctWatsonStatus.Exit,
                msg                     : [],
                modelCode               : "",
                sessionCode             : "",
                language                : "",
                context                 : "",
            },
            isWatsonError               : false,
        
            selectedLanguageCode        : 'ko-KR',
        
            msgText                     : [],
            
            eoBrowserWatsonInfo         : {
                isRunningWatson         : false,
                isSpeakingMode          : true,
                watsonStatus            : ctWatsonStatus.Exit,
                watsonLanguageCode      : 'ko-KR',
                msgText                 : ""
            },
            dialogHist                  : []
        }
    },

    [EXIT_WATSON_MODE]: (state, action) => {
        //console.log("WATSON_INIT");
        
        return {
            ...state,
            isLectureMode               : false,
            isFinishLecture             : false,
            isFinishLiveAfterExitWatson : false,

            savedHiclassWatsonInfo      : {},
        
            isRunningWatson             : false,
            isSpeakingMode              : false,

            watsonInfo                  : {
                status                  : ctWatsonStatus.Exit,
                msg                     : [],
                modelCode               : "",
                sessionCode             : "",
                language                : "",
                context                 : "",
            },
        
            selectedLanguageCode        : "",
        
            msgText                     : [],
            dialogHist                  : []

        }
    },
    
    [WATSON_CHANGE_STATUS]: (state, action) => {
        const { status } = action.payload;
        //console.log("WATSON_CHANGE_STATUS - status : ", status);

        const { eoBrowserWatsonInfo } = state;
        
        let msgInfo = "";

        if (status === ctWatsonStatus.InActive) {               // watson 대기 상태, 이때는 버튼을 누르면 Listen 모드로 이동한다.
            return {
                ...state,
                eoBrowserWatsonInfo : {
                    ...eoBrowserWatsonInfo,
                    watsonStatus    : ctWatsonStatus.InActive,
                    msgText         : msgInfo
                }
            }
        } else if (status === ctWatsonStatus.Listening) {       // watson이 마이크 입력을 기디라는 상태
            return {
                ...state,
                eoBrowserWatsonInfo : {
                    ...eoBrowserWatsonInfo,
                    watsonStatus    : ctWatsonStatus.Listening,
                    msgText         : msgInfo
                }
            }
        } else if (status === ctWatsonStatus.Processing) {      // 한 문장의 입력(isFinal)이 탐지되어 watson으로 보내는 처리 과정 중인 상태
            return {
                ...state,
                eoBrowserWatsonInfo : {
                    ...eoBrowserWatsonInfo,
                    watsonStatus    : ctWatsonStatus.Processing,
                    msgText         : msgInfo
                }
            }
        } else if (status === ctWatsonStatus.Speaking) {        // watson에서 result가 도착하여 TTS로 변환하여 말로 내보내는 상태
            return {
                ...state,
                eoBrowserWatsonInfo : {
                    ...eoBrowserWatsonInfo,
                    watsonStatus    : ctWatsonStatus.Speaking,
                    msgText         : msgInfo
                }
            }
        } else if (status === ctWatsonStatus.Exit) {            // watson이 종료된 상태
            if (eoBrowserWatsonInfo.watsonLanguageCode === 'ko-KR') {
                msgInfo = {
                    kind    : ctBubbleKind.RcvMsg,
                    msgInfo : {
                        iconSeq     : 1,
                        rcvKind     : ctRcvKind.RcvWatson,
                        rcvName     : "Watson",
                        text        : "Watson은 이제 쉬러갈게요~!",
                        urlInfo     : ""
                    }
                }
            } else if (eoBrowserWatsonInfo.watsonLanguageCode === 'en-US') {
                msgInfo = {
                    kind    : ctBubbleKind.RcvMsg,
                    msgInfo : {
                        iconSeq     : 1,
                        rcvKind     : ctRcvKind.RcvWatson,
                        rcvName     : "Watson",
                        text        : "Watson is going to rest now~!",
                        urlInfo     : ""
                    }
                }
            }

            return {
                ...state,
                eoBrowserWatsonInfo : {
                    ...eoBrowserWatsonInfo,
                    watsonLanguageCode  : 'ko-KR',
                    watsonStatus        : ctWatsonStatus.Exit,
                    isRunningWatson     : false,
                    isSpeakingMode      : true,
                    msgText             : msgInfo
                }
            }
        } else {
            console.log("이런 경우가 있나...... status is ", status);

            return {
                ...state,
            }
        }
    },

    [WATSON_TOGGLE]: (state, action) => {
        const { mode } = action.payload;
        //console.log("WATSON_TOGGLE");

        if (mode === "web") {
            const { isSpeakingMode } = state;

            if (isSpeakingMode) {       // text 전송 모드로 변경
                return {
                    ...state,
                    isSpeakingMode: false,
                }
            } else {                    // 음성 전송 모드로 변경
                return {
                    ...state,
                    isSpeakingMode: true,
                }
            }
        } else {
            const { kind, list_watsonLecture } = action.payload;
            const { eoBrowserWatsonInfo } = state;

            if (kind === "func_select") { // function list --- [watson] click
                let preWatsonLanguageCode = eoBrowserWatsonInfo.watsonLanguageCode;
                let preWatsonSpeakingMode = eoBrowserWatsonInfo.isSpeakingMode;
                let preWatsonIsRunning = eoBrowserWatsonInfo.isRunningWatson;

                if (!eoBrowserWatsonInfo.isRunningWatson) {
                    const actionData = {
                        action          : 'start_watson',
                        isStart         : 'Y',
                        isSpeakingMode  : 'Y',
                        LanguageCode    : 'ko-KR',
                        watsonKind      : 'hiclass-kr',
                        lectureList     : list_watsonLecture
                    };

                    preWatsonLanguageCode = 'ko-KR';
                    preWatsonSpeakingMode = true;
                    preWatsonIsRunning = true;

                    callEoForSendAction("watson", actionData);
                }

                let isFunctionSkill = false;

                if (isFunctionSkill) { // 좌측 [∨] 클릭하여 소스 접어두면 됩니다.
                    // 기존 modules-classTalk.js ------ [SELECT_FUNCTION_SEQ] 부분
                    /*
                    if (selectedFunctionSeq === ctActionType.WatsonS_KR) {
                        if (!state.isRunningWatson) {
                            const actionData = {
                                action          : 'start_watson',
                                isStart         : 'Y',
                                isSpeakingMode  : 'Y',
                                LanguageCode    : 'ko-KR',
                                watsonKind      : 'hiclass-kr',
                                lectureList     : list_watsonLecture
                            };
        
                            preSelectedLanguageCode = 'ko-KR';
                            preIsSpeakingMode = true;
                            preIsRunningWatson = true;
            
                            callEoForSendAction("watson", actionData);
                        } else {
                            if (state.isSpeakingMode) {
                                if (state.watsonStatus === ctWatsonStatus.InActive) {
        
                                } else {
        
                                }
                            } else {
                                if (state.watsonStatus === ctWatsonStatus.InActive) {
        
                                } else {
        
                                }
        
                                const actionData = {
                                    action          : 'change_speakMode',
                                    isStart         : 'N',
                                    isSpeakingMode  : 'Y',
                                    LanguageCode    : 'ko-KR',
                                    watsonKind      : 'hiclass-kr',
                                    lectureList     : list_watsonLecture
                                };
        
                                callEoForSendAction("watson", actionData);
                            }
                            
                            preSelectedLanguageCode = 'ko-KR';
                            preIsSpeakingMode = true;
                            preIsRunningWatson = true;
                        }
                    } else if (selectedFunctionSeq === ctActionType.WatsonS_EN) {
                        if (!state.isRunningWatson) {
                            const actionData = {
                                action          : 'start_watson',
                                isStart         : 'Y',
                                isSpeakingMode  : 'Y',
                                LanguageCode    : 'en-US',
                                watsonKind      : 'hiclass-en',
                                lectureList     : list_watsonLecture
                            };
        
                            preSelectedLanguageCode = 'en-US';
                            preIsSpeakingMode = true;
                            preIsRunningWatson = true;
            
                            callEoForSendAction("watson", actionData);
                        } else {
                            if (state.isSpeakingMode) {
                                if (state.watsonStatus === ctWatsonStatus.InActive) {
        
                                } else {
        
                                }
                            } else {
                                if (state.watsonStatus === ctWatsonStatus.InActive) {
        
                                } else {
        
                                }
        
                                const actionData = {
                                    action          : 'change_speakMode',
                                    isStart         : 'N',
                                    isSpeakingMode  : 'Y',
                                    LanguageCode    : 'en-US',
                                    watsonKind      : 'hiclass-en',
                                    lectureList     : list_watsonLecture
                                };
        
                                callEoForSendAction("watson", actionData);
                            }
        
                            preSelectedLanguageCode = 'en-US';
                            preIsSpeakingMode = true;
                            preIsRunningWatson = true;
                        }
                    } else if (selectedFunctionSeq === ctActionType.WatsonT_KR) {
                        // isSpeakingMode가 'N'인 경우는 text 입력 모드
                        if (!state.isRunningWatson) {
                            const actionData = {
                                action          : 'start_watson',
                                isStart         : 'Y',
                                isSpeakingMode  : 'N',
                                LanguageCode    : 'ko-KR',
                                watsonKind      : 'hiclass-kr',
                                lectureList     : list_watsonLecture
                            };
        
                            preSelectedLanguageCode = 'ko-KR';
                            preIsSpeakingMode = false;
                            preIsRunningWatson = true;
            
                            callEoForSendAction("watson", actionData);
                        } else {
                            if (state.isSpeakingMode) {
                                if (state.watsonStatus === ctWatsonStatus.InActive) {
        
                                } else {
        
                                }
        
                                const actionData = {
                                    action          : 'change_speakMode',
                                    isStart         : 'N',
                                    isSpeakingMode  : 'N',
                                    LanguageCode    : 'ko-KR',
                                    watsonKind      : 'hiclass-kr',
                                    lectureList     : list_watsonLecture
                                };
        
                                callEoForSendAction("watson", actionData);
                            } else {
                                if (state.watsonStatus === ctWatsonStatus.InActive) {
        
                                } else {
        
                                }
                            }
        
                            preSelectedLanguageCode = 'ko-KR';
                            preIsSpeakingMode = false;
                            preIsRunningWatson = true;
                        } 
                    } else if (selectedFunctionSeq === ctActionType.WatsonT_EN) {
                        // isSpeakingMode가 'N'인 경우는 text 입력 모드
                        if (!state.isRunningWatson) {
                            const actionData = {
                                action          : 'start_watson',
                                isStart         : 'Y',
                                isSpeakingMode  : 'N',
                                LanguageCode    : 'en-US',
                                watsonKind      : 'hiclass-en',
                                lectureList     : list_watsonLecture
                            };
        
                            preSelectedLanguageCode = 'en-US';
                            preIsSpeakingMode = false;
                            preIsRunningWatson = true;
            
                            callEoForSendAction("watson", actionData);
                        } else {
                            if (state.isSpeakingMode) {
                                if (state.watsonStatus === ctWatsonStatus.InActive) {
        
                                } else {
        
                                }
                                
                                const actionData = {
                                    action          : 'change_speakMode',
                                    isStart         : 'N',
                                    isSpeakingMode  : 'N',
                                    LanguageCode    : 'en-US',
                                    watsonKind      : 'hiclass-en',
                                    lectureList     : list_watsonLecture
                                };
        
                                callEoForSendAction("watson", actionData);
                            } else {
                                if (state.watsonStatus === ctWatsonStatus.InActive) {
        
                                } else {
        
                                }
                            }
        
                            preSelectedLanguageCode = 'en-US';
                            preIsSpeakingMode = false;
                            preIsRunningWatson = true;
                        } 
                    }
                    */
                }

                return {
                    ...state,
                    eoBrowserWatsonInfo : {
                        ...eoBrowserWatsonInfo,
                        isRunningWatson     : preWatsonIsRunning,
                        isSpeakingMode      : preWatsonSpeakingMode,
                        watsonLanguageCode  : preWatsonLanguageCode,
                        msgText             : ""
                    }
                }
            } else { // input group box --- [watson change status] toggle
                if (eoBrowserWatsonInfo.isSpeakingMode) {
                    const actionData = {
                        action          : 'change_speakMode',
                        isStart         : 'N',
                        isSpeakingMode  : 'N',
                        LanguageCode    : eoBrowserWatsonInfo.watsonLanguageCode,
                        watsonKind      : eoBrowserWatsonInfo.watsonLanguageCode === 'ko-KR' ? 'hiclass-kr' : 'hiclass-en',
                        lectureList     : list_watsonLecture
                    };

                    callEoForSendAction("watson", actionData);

                    return {
                        ...state,
                        eoBrowserWatsonInfo : {
                            ...eoBrowserWatsonInfo,
                            //isSpeakingMode : false
                        }
                    }
                } else {
                    const actionData = {
                        action          : 'change_speakMode',
                        isStart         : 'N',
                        isSpeakingMode  : 'Y',
                        LanguageCode    : eoBrowserWatsonInfo.watsonLanguageCode,
                        watsonKind      : eoBrowserWatsonInfo.watsonLanguageCode === 'ko-KR' ? 'hiclass-kr' : 'hiclass-en',
                        lectureList     : list_watsonLecture
                    };

                    callEoForSendAction("watson", actionData);

                    return {
                        ...state,
                        eoBrowserWatsonInfo : {
                            ...eoBrowserWatsonInfo,
                            //isSpeakingMode : true
                        }
                    }
                }
            }
        }
    },

    [WATSON_PING]: (state, action) => {
        const { list_watsonLecture } = action.payload;
        const { eoBrowserWatsonInfo } = state;

        //console.log("WATSON_PING");

        const actionData = {
            action          : 'change_status',
            isStart         : 'N',
            isSpeakingMode  : eoBrowserWatsonInfo.isSpeakingMode ? 'Y' : 'N',
            LanguageCode    : eoBrowserWatsonInfo.watsonLanguageCode,
            watsonKind      : eoBrowserWatsonInfo.watsonLanguageCode === 'ko-KR' ? 'hiclass-kr' : 'hiclass-en',
            lectureList     : list_watsonLecture
        };

        callEoForSendAction("watson", actionData);

        return {
            ...state,
        }
    }, 

    [WATSON_TOGGLE_BY_HICLASS]: (state, action) => {
        const { isRunningWatson } = action.payload;
        const { eoBrowserWatsonInfo } = state;
        //console.log("WATSON_TOGGLE_BY_HICLASS", action.payload);

        let msgInfo = "";

        if (isRunningWatson) {
            if (eoBrowserWatsonInfo.isSpeakingMode) {
                if (eoBrowserWatsonInfo.watsonLanguageCode === 'ko-KR') {
                    msgInfo = {
                        kind            : ctBubbleKind.RcvMsg,
                        msgInfo         : {
                            iconSeq     : 1,
                            rcvKind     : ctRcvKind.RcvWatson,
                            rcvName     : "Watson",
                            text        : "Watson에게 이야기해보세요.",
                            urlInfo     : ""
                        }
                    }
                } else if (eoBrowserWatsonInfo.watsonLanguageCode === 'en-US') {
                    msgInfo = {
                        kind            : ctBubbleKind.RcvMsg,
                        msgInfo     : {
                            iconSeq     : 1,
                            rcvKind     : ctRcvKind.RcvWatson,
                            rcvName     : "Watson",
                            text        : "Talk to Watson",
                            urlInfo     : ""
                        }
                    }
                }
            } else {
                if (eoBrowserWatsonInfo.watsonLanguageCode === 'ko-KR') {
                    msgInfo = {
                        kind            : ctBubbleKind.RcvMsg,
                        msgInfo         : {
                            iconSeq     : 1,
                            rcvKind     : ctRcvKind.RcvWatson,
                            rcvName     : "Watson",
                            text        : "Watson에게 보낼 메세지를 입력해보세요.",
                            urlInfo     : ""
                        }
                    }
                } else if (eoBrowserWatsonInfo.watsonLanguageCode === 'en-US') {
                    msgInfo = {
                        kind            : ctBubbleKind.RcvMsg,
                        msgInfo         : {
                            iconSeq     : 1,
                            rcvKind     : ctRcvKind.RcvWatson,
                            rcvName     : "Watson",
                            text        : "Enter a message to send to Watson",
                            urlInfo     : ""
                        }
                    }
                }
            }

            return {
                ...state,
                eoBrowserWatsonInfo: {
                    ...eoBrowserWatsonInfo,
                    isRunningWatson : true,
                    msgText         : msgInfo
                }
            }
        } else {
            if (eoBrowserWatsonInfo.watsonLanguageCode === 'ko-KR') {
                msgInfo = {
                    kind            : ctBubbleKind.RcvMsg,
                    msgInfo         : {
                        iconSeq     : 1,
                        rcvKind     : ctRcvKind.RcvWatson,
                        rcvName     : "Watson",
                        text        : "Watson은 이제 쉬러갈게요~!",
                        urlInfo     : ""
                    }
                }
            } else if (eoBrowserWatsonInfo.watsonLanguageCode === 'en-US') {
                msgInfo = {
                    kind            : ctBubbleKind.RcvMsg,
                    msgInfo         : {
                        iconSeq     : 1,
                        rcvKind     : ctRcvKind.RcvWatson,
                        rcvName     : "Watson",
                        text        : "Watson is going to rest now~!",
                        urlInfo     : ""
                    }
                }
            }

            return {
                ...state,
                eoBrowserWatsonInfo: {
                    ...eoBrowserWatsonInfo,
                    isRunningWatson     : false,
                    isSpeakingMode      : true,
                    watsonLanguageCode  : 'ko-KR',
                    watsonStatus        : ctWatsonStatus.Exit,
                    msgText             : msgInfo
                }
            }
        }
    },

    [WATSON_CREATE_SESSION_SUCCESS]: (state, action) => {
        const { lectureCode, kind } = action.parms;
        const { isSuccessed } = action.payload;
        console.log('WATSON_CREATE_SESSION - ', action.parms);

        if (isSuccessed) {
            const { language_code, sessionCode, initResult } = action.payload;
            let msgInfo = [];

            if (kind && kind === 'standard') {
                if (language_code === 'ko-KR' || language_code === 'en-US') {
                    msgInfo.push({
                        kind        : ctBubbleKind.RcvMsg,
                        msgInfo     : {
                            iconSeq : 1,
                            rcvKind : ctRcvKind.RcvWatson,
                            rcvName : 'Watson',
                            text    : language_code === 'ko-KR' ? 'Watson에게 이야기해보세요.' : 'Talk to Watson.',
                            urlInfo : ''
                        }
                    });
                }
            }

            if (initResult) {
                const { output } = initResult;

                if (output) {
                    let isNeedMicOn = true;
                    let watSonTextArr = [];
                    let isEnd = false;
                    let imgUrl = '';
                    let textObj;
                    let watSonText = '';
                    let noAnswer = output.meta !== undefined && output.meta.NO_ANSWER !== undefined ? output.meta.NO_ANSWER : '';
                    let watsonContextInfo = output.meta !== undefined && output.meta.CONTEXT !== undefined ? output.meta.CONTEXT : '';

                    output.generic.forEach(info => {
                        if (info.response_type === 'text') {
                            if (output.meta !== undefined && output.meta.REPLACE !== undefined) {
                                let text = info.text;

                                output.meta.REPLACE.forEach(metaInfo => {
                                    let regEx = new RegExp('__' + metaInfo.kind + '__', 'gi');
                                    text = text.replace(regEx, metaInfo.value);

                                    if (noAnswer !== '') {
                                        noAnswer = noAnswer.replace(regEx, metaInfo.value);
                                    }

                                    info.text = text;
                                });
                            }
                            textObj = { text: info.text };

                            if (watSonText !== '') watSonText = info.text;

                            textObj.lang = language_code;

                            if (info.lang) {
                                textObj.lang = info.lang;
                            }

                            watSonTextArr.push(textObj);
                        } else if (info.response_type === 'image') {
                            imgUrl = info.source;
                        }

                        if (info.response_type === 'pause' && info.time === 0) {
                            isEnd = true;
                        }
                    });

                    if (watSonTextArr.length > 0 || imgUrl !== '') {
                        msgInfo.push({
                            kind        : ctBubbleKind.RcvMsg,
                            msgInfo     : {
                                iconSeq     : 1,
                                kind        : imgUrl !== '' ? 'watson_image' : 'watson_text',
                                rcvKind     : ctRcvKind.RcvWatson,
                                rcvName     : 'Watson',
                                emoticSeq   : -1,
                                text        : watSonTextArr,
                                urlInfo     : imgUrl,
                                noAnswer    : noAnswer
                            }
                        });
                    }

                    let chatMsgInfo = msgInfo.map(info => info);

                    if (output.extra && output.extra.isSuccessed) {
                        const { json_info } = output.extra;

                        switch(output.extra.kind) {
                            case 'card':
                                chatMsgInfo.push({
                                    kind        : ctBubbleKind.RcvMsg,
                                    msgInfo     : {
                                        kind        : 'card_template',
                                        rcvKind     : ctRcvKind.Card,
                                        rcvName     : 'Watson',
                                        cardInfo    : {
                                            isCurrent       : true,
                                            isPossiblePass  : (json_info.type === stCardType.Pronunciation || json_info.type === stCardType.Quiz) ? false : true,
                                            code            : json_info.code,
                                            seq             : json_info.seq,
                                            visibility      : json_info.visibility,
                                            type            : json_info.type,
                                            data            : json_info.data ? json_info.data : [],
                                            todo            : json_info.todo ? json_info.todo : []
                                        }
                                    }
                                });
                                isNeedMicOn = json_info.type === stCardType.Pronunciation ? false : true;
                                break;

                            default:
                                break;
                        }
                    }

                    let saveInfo = {
                        modelCode : lectureCode,
                        sessionCode : sessionCode,
                        language : language_code
                    };

                    msgInfo[msgInfo.length - 1].msgInfo.isNeedMicOn = isNeedMicOn;

                    return {
                        ...state,
                        isRunningWatson         : true,
                        isSpeakingMode          : true,
                        isLectureMode           : kind === 'standard' ? false : true,
                        isLectureLive           : kind === 'standard' ? false : true,
                        watsonInfo              : {
                            ...state.watsonInfo,
                            msg                 : msgInfo,
                            //msg                 : chatMsgInfo,
                            modelCode           : lectureCode,
                            sessionCode         : sessionCode,
                            language            : language_code,
                            context             : watsonContextInfo
                        },
                        msgText                 : chatMsgInfo,
                        savedHiclassWatsonInfo  : saveInfo,
                        dialogHist : []
                    }
                } else {
                    console.log('WATSON_CREATE_SESSION - output => ', output);

                    return {
                        ...state
                    }
                }
            } else {
                console.log('WATSON_CREATE_SESSION - initResult => ', initResult);

                return {
                    ...state
                }
            }
        } else {
            console.log('WATSON_CREATE_SESSION - isSuccessed => ', isSuccessed);

            return {
                ...state,
                isRunningWatson     : false
            }
        }
    },

    [WATSON_SEND_MESSAGE_SUCCESS]: (state, action) => {
        const { sendMessage, userNickname } = action.parms;
        const { isSuccessed, output, dialogHist } = action.payload;
        //console.log("WATSON_SEND_MESSAGE - ", action.payload);

        if (isSuccessed) {
            let msgInfo = [];

            msgInfo.push({
                kind        : ctBubbleKind.SendMsg,
                msgInfo     : {
                    iconSeq     : 1,
                    rcvKind     : ctRcvKind.SendWatson,
                    rcvName     : userNickname,
                    emoticSeq   : -1,
                    text        : sendMessage,
                    urlInfo     : ""
                }
            });

            let isNeedMicOn = true;
            let watSonTextArr = [];
            let isEnd = false;
            let imgUrl = "";
            let textObj;
            let watSonText = "";
            let noAnswer = output.meta !== undefined && output.meta.NO_ANSWER !== undefined ? output.meta.NO_ANSWER : "";
            let watSonMessageArr = [];

            output.generic.forEach(info => {
                if (info.response_type === "text") {
                    if (output.meta !== undefined && output.meta.REPLACE !== undefined) {
                        let text = info.text;

                        output.meta.REPLACE.forEach(metaInfo => {
                            var regEx = new RegExp("__" + metaInfo.kind + "__", "gi");
                            text = text.replace(regEx, metaInfo.value);

                            if (noAnswer !== "") {
                                noAnswer = noAnswer.replace(regEx, metaInfo.value);
                            }
                        });

                        info.text = text;
                    }
                    textObj = { text: info.text };

                    if (watSonText !== "") watSonText = info.text;

                    textObj.lang = state.watsonInfo.language;

                    if (info.lang !== undefined) {
                        textObj.lang = info.lang;
                    }

                    watSonTextArr.push(textObj);
                    watSonMessageArr.push({ kind: 'text', msg: textObj });

                } else if (info.response_type === "image") {
                    imgUrl = info.source;

                    watSonMessageArr.push({ kind: 'image', msg: imgUrl });
                }

                if (info.response_type === "pause" && info.time === 0) {
                    isEnd = true;
                }
            });

            if (watSonTextArr.length > 0 || imgUrl !== "") {
                msgInfo.push({
                    kind    : ctBubbleKind.RcvMsg,
                    msgInfo : {
                        iconSeq     : 1,
                        kind        : imgUrl !== "" ? "watson_image" : "watson_text",
                        rcvKind     : ctRcvKind.RcvWatson,
                        rcvName     : "Watson",
                        emoticSeq   : -1,
                        text        : watSonTextArr,
                        urlInfo     : imgUrl,
                        message     : watSonMessageArr,
                        noAnswer    : noAnswer  // noAnswer replace 처리 위해 변경 byhjkim 20201119
                    }
                });
            }

            if (output.newLecture) { // ------------------------- 선택된 강의가 있는 경우,
                let saveInfo = {
                    modelCode   : state.watsonInfo.modelCode,
                    sessionCode : state.watsonInfo.sessionCode,
                    language: state.watsonInfo.language
                }

                watSonTextArr = [];
                imgUrl = "";
                watSonText = "";

                const meta = output.newLecture.sessionCode.initResult.output.meta !== undefined ? output.newLecture.sessionCode.initResult.output.meta : null;
                noAnswer = (meta !== null && meta.NO_ANSWER !== undefined ? meta.NO_ANSWER : "");

                output.newLecture.sessionCode.initResult.output.generic.forEach(info => {
                    if (info.response_type === "text") {
                        if (meta !== null && meta.REPLACE !== undefined) {
                            let text = info.text;

                            meta.REPLACE.forEach(metaInfo => {
                                var regEx = new RegExp("__" + metaInfo.kind + "__", "gi");
                                text = text.replace(regEx, metaInfo.value);

                                if (noAnswer !== "") {
                                    noAnswer = noAnswer.replace(regEx, metaInfo.value);
                                }
                            });

                            info.text = text;
                        }

                        textObj = { text: info.text };

                        if (watSonText !== "") watSonText = info.text;

                        if (info.lang !== undefined) {
                            textObj.lang = info.lang;
                        }

                        watSonTextArr.push(textObj);
                        watSonMessageArr.push({ kind: 'text', msg: textObj });
                    } else if (info.response_type === "image") {
                        imgUrl = info.source;

                        watSonMessageArr.push({ kind: 'image', msg: imgUrl });
                    }
                });

                msgInfo.push({
                    kind    : ctBubbleKind.RcvMsg,
                    msgInfo : {
                        iconSeq     : 1,
                        kind        : imgUrl !== "" ? "watson_image" : "watson_text",
                        rcvKind     : ctRcvKind.RcvWatson,
                        rcvName     : "Watson",
                        emoticSeq   : -1,
                        text        : watSonTextArr,
                        urlInfo     : imgUrl,
                        message     : watSonMessageArr,
                        noAnswer    : noAnswer  // noAnswer replace 처리 위해 변경 by hjkim 20201119
                    }
                });

                let chatMsgInfo = msgInfo.map(info => info);

                if (output.extra && output.extra.isSuccessed) {
                    const { json_info } = output.extra;

                    /* let cardInfo = {};
                    if (json_info.type === stCardType.MultiTurn) {
                        const { currSeq } = action.parms;
                        cardInfo = {
                            isCurrent       : true,
                        }
                    } else {
                        cardInfo = {
                            isCurrent       : true,
                            isPossiblePass  : json_info.type === stCardType.Pronunciation || json_info.type === stCardType.Quiz ? false : true,
                            code            : json_info.code,
                            type            : json_info.type,
                            data            : json_info.data ? json_info.data : [],
                            todo            : json_info.todo ? json_info.todo : []
                        }
                    } */

                    switch(output.extra.kind) {
                        case 'card' :
                            chatMsgInfo.push({
                                kind        : ctBubbleKind.RcvMsg,
                                msgInfo     : {
                                    kind        : 'card_template',
                                    rcvKind     : ctRcvKind.Card,
                                    rcvName     : 'Watson',
                                    cardInfo    : {
                                        isCurrent       : true,
                                        isPossiblePass  : (json_info.type === stCardType.Pronunciation || json_info.type === stCardType.Quiz) ? false : true,
                                        code            : json_info.code,
                                        seq             : json_info.seq,
                                        visibility      : json_info.visibility,
                                        type            : json_info.type,
                                        data            : json_info.data ? json_info.data : [],
                                        todo            : json_info.todo ? json_info.todo : []
                                    }
                                }
                            });
                            isNeedMicOn = json_info.type === stCardType.Pronunciation ? false : true;
                            break;

                        default:
                            break;
                    }
                }

                msgInfo[msgInfo.length - 1].msgInfo.isNeedMicOn = isNeedMicOn;

                return {
                    ...state,
                    isLectureMode           : output.newLecture !== undefined ? true : false,
                    watsonInfo              : {
                        ...state.watsonInfo,
                        msg                 : msgInfo,
                        //msg                 : chatMsgInfo,
                        modelCode           : output.newLecture.modelCode,
                        sessionCode         : output.newLecture.sessionCode.sessionCode,
                        language            : output.newLecture.sessionCode.language_code,
                        context             : meta !== null && meta.CONTEXT !== undefined ? meta.CONTEXT : ""
                    },
                    savedHiclassWatsonInfo  : saveInfo,
                    msgText                 : chatMsgInfo,
                    dialogHist              : dialogHist===undefined?[]:dialogHist
                }
            } else {                 // ------------------------- 선택된 강의가 없는 경우,
                let chatMsgInfo = msgInfo.map(info => info);

                if (output.extra && output.extra.isSuccessed) {
                    const { json_info } = output.extra;

                    switch(output.extra.kind) {
                        case 'card' :
                            chatMsgInfo.push({
                                kind        : ctBubbleKind.RcvMsg,
                                msgInfo     : {
                                    kind        : 'card_template',
                                    rcvKind     : ctRcvKind.Card,
                                    rcvName     : 'Watson',
                                    cardInfo    : {
                                        isCurrent       : true,
                                        isPossiblePass  : (json_info.type === stCardType.Pronunciation || json_info.type === stCardType.Quiz) ? false : true,
                                        code            : json_info.code,
                                        seq             : json_info.seq,
                                        visibility      : json_info.visibility,
                                        type            : json_info.type,
                                        data            : json_info.data ? json_info.data : [],
                                        todo            : json_info.todo ? json_info.todo : []
                                    }
                                }
                            });
                            isNeedMicOn = json_info.type === stCardType.Pronunciation ? false : true;
                            break;

                        default:
                            break;
                    }
                }

                //console.log(`isLectureEnd[${output.isLectureEnd}], isEnd[${isEnd}], isLectureLive[${state.isLectureLive}], isLectureMode[${state.isLectureMode}]`);
                
                if (output.isLectureEnd !== undefined) {
                    if (output.isLectureEnd && isEnd && state.isLectureMode) {
                        if (!state.isLectureLive) { // 클래스톡 [+] 메뉴에서 watson 실행한 경우
                            let info = state.savedHiclassWatsonInfo;

                            if (info.language === 'ko-KR' || info.language === 'en-US') {
                                msgInfo.push({
                                    kind: ctBubbleKind.RcvMsg,
                                    msgInfo: {
                                        iconSeq     : 1,
                                        kind        : "watson_text",
                                        rcvKind     : ctRcvKind.RcvWatson,
                                        rcvName     : "Watson",
                                        emoticSeq   : -1,
                                        text        : [{ text: info.language === 'ko-KR' ? "하이클래스 Watson으로 돌아왔습니다." : "I have returned to HiClass watson new!", lang: info.language }],
                                        urlInfo     : "",
                                        noAnswer    : ""
                                    }
                                });

                                chatMsgInfo.push({
                                    kind: ctBubbleKind.RcvMsg,
                                    msgInfo: {
                                        iconSeq     : 1,
                                        kind        : "watson_text",
                                        rcvKind     : ctRcvKind.RcvWatson,
                                        rcvName     : "Watson",
                                        emoticSeq   : -1,
                                        text        : [{ text: info.language === 'ko-KR' ? "하이클래스 Watson으로 돌아왔습니다." : "I have returned to HiClass watson new!", lang: info.language }],
                                        urlInfo     : "",
                                        noAnswer    : ""
                                    }
                                });
                            }

                            msgInfo[msgInfo.length - 1].msgInfo.isNeedMicOn = isNeedMicOn;
    
                            return {
                                ...state,
                                isLectureMode       : false,
                                watsonInfo          : {
                                    ...state.watsonInfo,
                                    msg             : msgInfo,
                                    //msg             : chatMsgInfo,
                                    modelCode       : info.modelCode,
                                    sessionCode     : info.sessionCode,
                                    language        : info.language,
                                    context         : output.meta !== undefined && output.meta.CONTEXT !== undefined ? output.meta.CONTEXT : ""
                                },
                                msgText             : chatMsgInfo,
                                dialogHist          : dialogHist===undefined?[]:dialogHist

                            }
                        } else if (state.isLectureLive) { // lecture 선택해서 watson 실행된 경우
                            let info = state.watsonInfo;
                            //let chatMsgInfo = msgInfo.map(info => info);

                            if (info.language === 'ko-KR' || info.language === 'en-US') {
                                msgInfo.push({
                                    kind: ctBubbleKind.RcvMsg,
                                    msgInfo: {
                                        iconSeq     : 1,
                                        kind        : "watson_text",
                                        rcvKind     : ctRcvKind.RcvWatson,
                                        rcvName     : "Watson",
                                        emoticSeq   : -1,
                                        text        : [{ text: info.language === 'ko-KR' ? "Watson이 종료되었습니다. Watson은 이제 쉬러갈게요~!" : "Watson has been shut down. Watson is going to rest now~!", lang: info.language }],
                                        urlInfo     : "",
                                        noAnswer    : ""
                                    }
                                });

                                chatMsgInfo.push({
                                    kind: ctBubbleKind.RcvMsg,
                                    msgInfo: {
                                        iconSeq     : 1,
                                        kind        : "watson_text",
                                        rcvKind     : ctRcvKind.RcvWatson,
                                        rcvName     : "Watson",
                                        emoticSeq   : -1,
                                        text        : [{ text: info.language === 'ko-KR' ? "Watson이 종료되었습니다. Watson은 이제 쉬러갈게요~!" : "Watson has been shut down. Watson is going to rest now~!", lang: info.language }],
                                        urlInfo     : "",
                                        noAnswer    : ""
                                    }
                                });
                            }

                            msgInfo[msgInfo.length - 1].msgInfo.isNeedMicOn = isNeedMicOn;
    
                            return {
                                ...state,
                                //isRunningWatson         : false,
                                //isSpeakingMode          : false,
                                //isLectureMode           : false,
                                isFinishLecture         : true,
                                watsonInfo          : {
                                    ...state.watsonInfo,
                                    msg             : msgInfo,
                                    //msg             : chatMsgInfo,
                                    modelCode       : info.modelCode,
                                    sessionCode     : info.sessionCode,
                                    language        : info.language,
                                    context         : output.meta !== undefined && output.meta.CONTEXT !== undefined ? output.meta.CONTEXT : ""
                                },
                                msgText                 : chatMsgInfo,
                                dialogHist              : []

                            }
                        }
                    }

                    msgInfo[msgInfo.length - 1].msgInfo.isNeedMicOn = isNeedMicOn;

                    return {
                        ...state,
                        watsonInfo          : {
                            ...state.watsonInfo,
                            msg             : msgInfo,
                            //msg             : chatMsgInfo,
                            context         : ''
                        },
                        msgText             : chatMsgInfo,
                        dialogHist          : []
                    }
                } else {

                    msgInfo[msgInfo.length - 1].msgInfo.isNeedMicOn = isNeedMicOn;

                    return {
                        ...state,
                        watsonInfo          : {
                            ...state.watsonInfo,
                            msg             : msgInfo,
                            //msg             : chatMsgInfo,
                            context         : ''
                        },
                        msgText             : chatMsgInfo,
                        dialogHist          : dialogHist===undefined?[]:dialogHist
                    }
                }
            }
        } else {
            console.log("WATSON_SEND_MESSAGE - isSuccessed => ", isSuccessed);
            console.log(action.payload);

            let msgInfo = [];

            msgInfo.push({
                kind        : ctBubbleKind.SendMsg,
                msgInfo     : {
                    iconSeq     : 1,
                    rcvKind     : ctRcvKind.SendWatson,
                    rcvName     : userNickname,
                    emoticSeq   : -1,
                    text        : sendMessage,
                    urlInfo     : ""
                }
            });

            msgInfo.push({
                kind    : ctBubbleKind.RcvMsg,
                msgInfo : {
                    iconSeq     : 1,
                    kind        : "watson_text",
                    rcvKind     : ctRcvKind.RcvWatson,
                    rcvName     : "Watson",
                    emoticSeq   : -1,
                    text        : [
                        { text: "시스템 문제로 에러가 발생했습니다.", lang: "ko-KR" },
                        { text: "다시 말씀해 주세요.", lang: "ko-KR" }
                    ],
                    urlInfo     : "",
                    noAnswer    : ""
                }
            });

            return {
                ...state,
                isWatsonError : true,
                msgText       : msgInfo
            } 
        }
    },

    [WATSON_SEND_MESSAGE_TO_HICLASS]: (state, action) => {
        //const { userNickname } = action.parms;
        const { msgText, list_watsonLecture, userNickname } = action.payload;
        const { eoBrowserWatsonInfo } = state;

        const sendData = {
            action          : 'send_msg',
            text            : msgText,
            isStart         : 'N',
            isSpeakingMode  : eoBrowserWatsonInfo.isSpeakingMode ? 'Y' : 'N',
            LanguageCode    : eoBrowserWatsonInfo.watsonLanguageCode,
            watsonKind      : eoBrowserWatsonInfo.watsonLanguageCode === 'ko-KR' ? 'hiclass-kr' : 'hiclass-en',
            lectureList     : list_watsonLecture
        }

        callEoForSendAction("watson", sendData);

        let msgInfo = {
            kind            : ctBubbleKind.SendMsg,
            msgInfo         : {
                rcvKind     : ctRcvKind.SendWatson,
                rcvName     : userNickname,
                emoticSeq   : -1, 
                text        : msgText,
                urlInfo     : ""
            }
        }

        return {
            ...state,
            eoBrowserWatsonInfo : {
                ...eoBrowserWatsonInfo,
                msgText : msgInfo
            }
        }
    },

    [WATSON_REMOVE_SESSION_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        const { watsonInfo } = state;
        console.log("WATSON_REMOVE_SESSION_SUCCESS : ", watsonInfo.language);

        if (isSuccessed) {
            let msgInfo;

            if (watsonInfo.language === 'ko-KR') {
                msgInfo = {
                    iconSeq     : 1,
                    rcvKind     : ctRcvKind.RcvWatson,
                    rcvName     : "Watson",
                    text        : "Watson은 이제 쉬러갈게요~!",
                    urlInfo     : ""
                };
            } else if (watsonInfo.language === 'en-US') {
                msgInfo = {
                    iconSeq     : 1,
                    rcvKind     : ctRcvKind.RcvWatson,
                    rcvName     : "Watson",
                    text        : "Watson is going to rest now~!",
                    urlInfo     : ""
                };
            }

            return {
                ...state,
                isRunningWatson         : false,
                isSpeakingMode          : false,
                isLectureMode           : false,
                isLectureLive           : false,
                watsonInfo              : {
                    status              : state.watsonInfo.status,
                    msg                 : [],
                    modelCode           : "",
                    sessionCode         : "",
                    language            : "",
                    context             : ""
                },
                selectedLanguageCode    : "",
                savedHiclassWatsonInfo  : {},
                msgText: { kind: ctBubbleKind.RcvMsg, msgInfo }
            }
        } else {
            console.log("isSuccessed is ", isSuccessed);

            return {
                ...state,
                isWatsonError : true
            }
        }
    },
    
    [WATSON_REMOVE_SESSION_FINISH_LIVE_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("WATSON_REMOVE_SESSION_FINISH_LIVE_SUCCESS");

        if (isSuccessed) {
            return {
                ...state,
                isFinishLiveAfterExitWatson : true,
                isRunningWatson             : false,
                isSpeakingMode              : false,
                isLectureMode               : false,
                isLectureLive               : false,
                watsonInfo              : {
                    status              : state.watsonInfo.status,
                    msg                 : [],
                    modelCode           : "",
                    sessionCode         : "",
                    language            : "",
                    context             : ""
                },
                selectedLanguageCode        : "",
                savedHiclassWatsonInfo      : {},
                msgText                     : []
            }
        } else {
            console.log("isSuccessed is ", isSuccessed);

            return {
                ...state,
                isWatsonError : true
            }
        }
    },
    
    [FINISH_LIVE_AFTER_WATSON_EXIT]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("FINISH_LIVE_AFTER_WATSON_EXIT");
        
        if (isSuccessed) {
            const { isFinishSuccess } = action.payload;

            if (isFinishSuccess) {
                return {
                    ...state,
                    isFinishLiveAfterExitWatson : false,
                }
            } else {
                return {
                    ...state,
                    isWatsonError : true
                }
            }
        } else {
            return {
                ...state,
                isWatsonError : true
            }
        }
    },

    [FALSE_TO_WATSON_ERROR]: (state, action) => {
        return {
            ...state,
            isWatsonError : false
        }
    }
}, initialState);

export default watson;