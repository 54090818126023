import React, { Component } from 'react';
import { connect } from 'react-redux';

import { exitChatRoom, updateChatRoomName, deleteChatRoom, deleteExitChatRoom, deleteChatRoomMember, 
         closeConfigModal, toggle_alertModal, toggle_exitChatRoom, toggle_editChatRoom, toggle_editChatRoomMember, toggle_deleteChatRoom, toggle_deleteExitChatRoom, toggle_removeChatRoomMember } from '../../modules/chat';

import ConfigChatListTitleTemplate from '../../components/template/title/ConfigChatListTitleTemplate';
import ConfigMyChatList from '../../components/chat/ConfigMyChatList';

import ChatRoomMember from '../../components/chat/modal/ChatRoomMember';
import DeleteChatRoomMember from '../../components/chat/modal/DeleteChatRoomMember';
import EditChatRoom from '../../components/chat/modal/EditChatRoom';
import DeleteChatRoom from '../../components/chat/modal/DeleteChatRoom';
import ExitChatRoom from '../../components/chat/modal/ExitChatRoom';
import ExitPerformChatRoom from '../../components/chat/modal/ExitPerformChatRoom';
import DeleteExitChatRoom from '../../components/chat/modal/DeleteExitChatRoom';
import AlertModal from '../../components/etc/AlertModal';

import AllCoveredModal from '../../components/template/modal/AllCoveredModal';
import ToppedModal from '../../components/template/modal/ToppedModal';
import RightSideModal from '../../components/template/modal/RightSideModal';
import CenteredModal from '../../components/template/modal/CenteredModal';

class ConfigMyChatListContainer extends Component {
    
    handleSelectedConfigFunc = ({ selectedFunc, chatRoomSeq, userNickname, chatRoomName, chatRoomCreator, entryYN, list_member, memberSeq, profileImgUrl }) => {
        const { userSeq } = this.props;
        const { exitChatRoom, updateChatRoomName, deleteChatRoom, deleteExitChatRoom, deleteChatRoomMember,
                closeConfigModal, toggle_alertModal, toggle_exitChatRoom, toggle_editChatRoom, toggle_deleteChatRoom, toggle_deleteExitChatRoom, toggle_editChatRoomMember, toggle_removeChatRoomMember } = this.props;
        
        switch (selectedFunc) {
            case "EXIT_TOGGLE":
                toggle_exitChatRoom({ selectedFunc, chatRoomSeq, chatRoomName, entryYN });
                break;

            case "EXIT":
                exitChatRoom({ chatRoomSeq, userSeq });
                break;

            case "EDIT_TOGGLE":
                toggle_editChatRoom({ selectedFunc, chatRoomSeq, chatRoomName });
                break;

            case "SAVE":
                updateChatRoomName({ selectedFunc, chatRoomSeq, userSeq, chatRoomName });
                break;

            case "DELETE_TOGGLE":
                toggle_deleteChatRoom({ selectedFunc, chatRoomSeq, chatRoomName, list_member });
                break;

            case "DELETE":
                deleteChatRoom({ chatRoomSeq, chatRoomName, userSeq });
                break;

            case "DELETE_EXIT_TOGGLE":
                toggle_deleteExitChatRoom({ selectedFunc, chatRoomSeq, chatRoomName, list_member });
                break;

            case "DELETE_EXIT":
                deleteExitChatRoom({ chatRoomSeq, chatRoomName, userSeq });
                break;

            case "MEMBER_TOGGLE":
                toggle_editChatRoomMember({ selectedFunc, chatRoomSeq, chatRoomCreator, list_member });
                break;

            case "MEMBER_DELETE":
                deleteChatRoomMember({ selectedFunc, chatRoomSeq, userSeq, removeUserSeq: memberSeq });
                break;

            case "MEMBER_DELETE_TOGGLE":
                toggle_removeChatRoomMember({ selectedFunc, chatRoomSeq, memberSeq, userNickname, profileImgUrl, entryYN });
                break;

            case "MEMBER_DELETE_ALERT":
                toggle_alertModal({ selectedFunc, userNickname });
                break;

            case "NONE":
                closeConfigModal({ selectedFunc: selectedFunc });
                break;

            default:
                break;
        }
    }

    render() {
        const { list_chatting, userSeq, selectedChatRoomInfo, deleteChatRoomMemberInfo, handleSelectedFunc,
                isAlertModal, isEditChatRoomModalOpened, isEditChatRoomMemberModalOpened, isDeleteChatRoomMemberModalOpened,
                isExitChatRoomModalOpened, isDeleteChatRoomModalOpened, isDeleteExitChatRoomModalOpened, isExitPerformChatRoomModalOpened,
                modalType, modalTargetUserNickname } = this.props;
        const { handleSelectedConfigFunc } = this;

        return (
            <>
                {
                    isEditChatRoomModalOpened &&
                    <ToppedModal modalClose={handleSelectedConfigFunc}>
                        <EditChatRoom
                            selectedChatRoomInfo={selectedChatRoomInfo}
                            handleSelectedConfigFunc={handleSelectedConfigFunc}
                        />
                    </ToppedModal>
                } {
                    isEditChatRoomMemberModalOpened &&
                    <RightSideModal modalClose={handleSelectedConfigFunc}>
                        <ChatRoomMember
                            userSeq={userSeq}
                            selectedChatRoomInfo={selectedChatRoomInfo}
                            handleSelectedFunc={handleSelectedConfigFunc}
                        />
                    </RightSideModal>
                } {
                    isDeleteChatRoomMemberModalOpened &&
                    <CenteredModal modalClose={handleSelectedConfigFunc}>
                        <DeleteChatRoomMember
                            deleteChatRoomMemberInfo={deleteChatRoomMemberInfo}
                            handleSelectedFunc={handleSelectedConfigFunc}
                        />
                    </CenteredModal>
                } {
                    isExitChatRoomModalOpened &&
                    <CenteredModal modalClose={handleSelectedConfigFunc}>
                        <ExitChatRoom
                            selectedChatRoomInfo={selectedChatRoomInfo}
                            handleSelectedFunc={handleSelectedConfigFunc}
                        />
                    </CenteredModal>
                } {
                    isExitPerformChatRoomModalOpened &&
                    <CenteredModal modalClose={handleSelectedConfigFunc}>
                        <ExitPerformChatRoom
                            selectedChatRoomInfo={selectedChatRoomInfo}
                            handleSelectedFunc={handleSelectedConfigFunc}
                        />
                    </CenteredModal>
                } {
                    isDeleteChatRoomModalOpened &&
                    <CenteredModal modalClose={handleSelectedConfigFunc}>
                        <DeleteChatRoom
                            userSeq={userSeq}
                            selectedChatRoomInfo={selectedChatRoomInfo}
                            handleSelectedFunc={handleSelectedConfigFunc}
                        />
                    </CenteredModal>
                } {
                    isDeleteExitChatRoomModalOpened && 
                    <CenteredModal modalClose={handleSelectedConfigFunc}>
                        <DeleteExitChatRoom
                            userSeq={userSeq}
                            selectedChatRoomInfo={selectedChatRoomInfo}
                            handleSelectedFunc={handleSelectedConfigFunc}
                        />
                    </CenteredModal>
                } {
                    isAlertModal &&
                    <AllCoveredModal modalClose={handleSelectedConfigFunc}>
                        <AlertModal
                            modalType={modalType}
                            modalTargetUserNickname={modalTargetUserNickname}
                            handleSelectedFunc={handleSelectedConfigFunc}
                        />
                    </AllCoveredModal>
                } 
                <ConfigChatListTitleTemplate 
                    handleSelectedFunc={handleSelectedFunc}
                />
                <ConfigMyChatList
                    userSeq={userSeq}
                    list_chatting={list_chatting}
                    handleSelectedFunc={handleSelectedConfigFunc}
                />
            </>
        )
    }
}

export default connect(
    ({ user, chat }) => ({
        userSeq                     : user.userSeq,
        list_chatting               : chat.list_chatting,

        modalType                   : chat.modalType,
        modalTargetUserNickname     : chat.modalTargetUserNickname,

        deleteChatRoomMemberInfo    : chat.deleteChatRoomMemberInfo,

        selectedChatRoomInfo        : chat.selectedChatRoomInfo,

        isAlertModal                        : chat.isAlertModal,
        isEditChatRoomModalOpened           : chat.isEditChatRoomModalOpened,
        isEditChatRoomMemberModalOpened     : chat.isEditChatRoomMemberModalOpened,
        isDeleteChatRoomModalOpened         : chat.isDeleteChatRoomModalOpened,
        isDeleteExitChatRoomModalOpened     : chat.isDeleteExitChatRoomModalOpened,
        isDeleteChatRoomMemberModalOpened   : chat.isDeleteChatRoomMemberModalOpened,
        isExitChatRoomModalOpened           : chat.isExitChatRoomModalOpened,
        isExitPerformChatRoomModalOpened    : chat.isExitPerformChatRoomModalOpened,
    }),
    {
        closeConfigModal,
        toggle_alertModal,
        toggle_exitChatRoom,
        toggle_deleteChatRoom,
        toggle_deleteExitChatRoom,
        toggle_editChatRoom,
        toggle_editChatRoomMember,
        toggle_removeChatRoomMember,

        updateChatRoomName,
        exitChatRoom,
        deleteChatRoom,
        deleteExitChatRoom,
        deleteChatRoomMember
    }
)(ConfigMyChatListContainer);