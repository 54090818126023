import React from 'react';
import { Modal } from 'reactstrap';

const LiveAlertModalExpiredPass = ({ userKind, live_info, isLiveAlertModalExpiredPass, handleSelectedFunc }) => {

    return (
        <Modal isOpen={isLiveAlertModalExpiredPass} centered  backdrop={false}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        <span>클래스 [</span>
                        <div className="title">
                            <span className="hiclass-text" title={live_info.liveName}><b>{live_info.liveName}</b></span>
                        </div>
                        <span>] 에 입장할 수 없습니다.</span>
                    </div>
                    <div className="content-area dark-grey-text">
                        <div className="text-area text-center">
                            {
                                userKind === "hiclasstv" ?
                                    <span>
                                        이용권이 만료되었습니다.<br />
                                        클래스 진행자에게 문의하십시오.
                                    </span> :
                                    <span>
                                        이용권이 만료되었습니다.<br />
                                        사이트 관리자에게 문의하십시오.
                                    </span>
                            }
                        </div>
                    </div>
                    <button type="button" className="btn btn-md green white-text py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "LIVE_ALERT_TOGGLE" })}>확인</button>
                </div>
            </div>
        </Modal>
    );
}

export default LiveAlertModalExpiredPass;