import React from "react";

const InkMemoMessage = ({ indexKey, msgInfo, handleSelectUrl }) => {
    const onLoadImage = () => {
        let classTalkView = document.getElementById("classTalkView");
        classTalkView.scrollTop = classTalkView.scrollHeight - classTalkView.clientHeight;
    }

    return (
        <img className="hiclasstv-classtalk-thumbnail" style={{ alignSelf: 'flex-start', paddingTop: '0.25rem', paddingLeft: '0.25rem' }} src={msgInfo.urlInfo} onLoad={() => onLoadImage()} onClick={() => handleSelectUrl(msgInfo.kind, msgInfo.urlInfo, indexKey)} alt="" />
    );
}

export default InkMemoMessage;