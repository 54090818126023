import { createAction, handleActions } from 'redux-actions'
import produce from 'immer';
import createRequestThunk from '../lib/createRequestThunk';
import tossPhaseAction from '../lib/tossPhaseAction';

import ConstData from '../lib/ConstData';
import { enWebPage, oAlertKind } from '../lib/ConstCommand';
import { getUserKindFromURL } from '../lib/func/ExportFunction';

import { callEoForLogin, callEoForLogout, callEoForRequestServerUserAuth } from '../lib/EoBrowserSendUtil'
import { setRbmqInfo, disconnectRbmq, disconnectRbmqForSmartTV, setRbmqInfoForGuest, setRbmqInfoForMixedClass, setRbmqInfoForSmartTV, subscribeLoginForMixedClass, disconnectTempRbmq, disconnectRbmqForMixed, sendRequestMixedClassLogout, setRbmqInfoForGroupSmartTV } from '../lib/RabbitmqClient';
import { login, 
         auto_login,
         login_through_notification,
         login_guest,
         login_qrcode,
         login_smart_tv,
         logout, 
         force_logout,
         notify_logout_to_android_app,
         request_mixed_class_login,
         regist_user, 
         update_user_info, 
         idcheck, 
         send_phone_check, 
         confirm_phone_check, 
         send_email_check, 
         confirm_email_check, 
         search_my_id, 
         compare_info, 
         reset_my_pw, 
         login_group_smart_tv} from '../lib/api/user';
import { update_server_user_auth_info } from '../lib/api/mixedClass';

import { FRIEND_INIT, FRIEND_STATE_INIT }       from './friend';
import { LIVE_BROADCAST_INIT, LIVE_WATCHING_INIT, LIVE_STATE_INIT, LIVE_WATCHING_INIT_FOR_MIXED_CLASS, LIVE_WATCHING_INIT_FOR_SMART_TV, LIVE_WATCHING_STATE_INIT_FOR_SMART_TV } from './live';
import { CHAT_INIT, CHAT_STATE_INIT }           from './chat';
import { LECTURE_INIT, LECTURE_STATE_INIT }     from './lecture';
import { TALK_STATE_INIT }                      from './classTalk';
import { TALK_LIVE_STATE_INIT }                 from './classTalkLive';
import { WHISPER_STATE_INIT }                   from './whisper';
import { HICLASSTV_STATE_INIT, SET_SMART_TV_GROUP_MEMBER }                 from './hiclasstv';
import { SUB_DOMAIN_STATE_INIT }                from './admin';
import { WATSON_STATE_INIT }                    from './watson';
import { STUDIO_INFO_INIT, STUDIO_STATE_INIT }  from './studio';
import { PRDT_SERVICE_INIT, PRDT_SERVICE_STATE_INIT } from './service';

import { clearTimer } from '../lib/TimeOutAlert';

//action types
const LOG_IN                                = "user/LOG_IN";
const LOG_IN_SUCCESS                        = "user/LOG_IN_SUCCESS";

const SMART_TV_LOG_IN                       = "user/SMART_TV_LOG_IN";
const SMART_TV_LOG_IN_SUCCESS               = "user/SMART_TV_LOG_IN_SUCCESS";
const SMART_TV_GROUP_LOG_IN                 = "user/SMART_TV_GROUP_LOG_IN";
const SMART_TV_GROUP_LOG_IN_SUCCESS         = "user/SMART_TV_GROUP_LOG_IN_SUCCESS";

const SMART_TV_LOG_OUT                      = "user/SMART_TV_LOG_OUT";

const LOG_OUT                               = "user/LOG_OUT";
const LOG_OUT_SUCCESS                       = "user/LOG_OUT_SUCCESS";
const FORCE_LOG_OUT                         = "user/FORCE_LOG_OUT";
const FORCE_LOG_OUT_SUCCESS                 = "user/FORCE_LOG_OUT_SUCCESS";

const FORCE_LOG_OUT_INIT                    = "user/FORCE_LOG_OUT_INIT";

const NOTIFY_LOGOUT_TO_ANDROID              = "user/NOTIFY_LOGOUT_TO_ANDROID";
const NOTIFY_LOGOUT_TO_ANDROID_SUCCESS      = "user/NOTIFY_LOGOUT_TO_ANDROID_SUCCESS";

const ID_CHECK                              = "user/ID_CHECK";
const ID_CHECK_SUCCESS                      = "user/ID_CHECK_SUCCESS";

const PHONE_CHECK_SEND                      = "user/PHONE_CHECK_SEND";
const PHONE_CHECK_SEND_SUCCESS              = "user/PHONE_CHECK_SEND_SUCCESS";
const PHONE_CHECK_CONFIRM                   = "user/PHONE_CHECK_CONFIRM";
const PHONE_CHECK_CONFIRM_SUCCESS           = "user/PHONE_CHECK_CONFIRM_SUCCESS";

const EMAIL_CHECK_SEND                      = "user/EMAIL_CHECK_SEND";
const EMAIL_CHECK_SEND_SUCCESS              = "user/EMAIL_CHECK_SEND_SUCCESS";
const EMAIL_CHECK_CONFIRM                   = "user/EMAIL_CHECK_CONFIRM";
const EMAIL_CHECK_CONFIRM_SUCCESS           = "user/EMAIL_CHECK_CONFIRM_SUCCESS";

const FALSE_TO_PHONE_CHECK_SEND             = "user/FALSE_TO_PHONE_CHECK_SEND";
const FALSE_TO_PHONE_CHECK_OK               = "user/FALSE_TO_PHONE_CHECK_OK";
const FALSE_TO_EMAIL_CHECK_SEND             = "user/FALSE_TO_EMAIL_CHECK_SEND";
const FALSE_TO_EMAIL_CHECK_OK               = "user/FALSE_TO_EMAIL_CHECK_OK";
const FALSE_TO_RETRY_LOGIN                  = "user/FALSE_TO_RETRY_LOGIN";
const FALSE_TO_REMOVE_COOKIES               = "user/FALSE_TO_REMOVE_COOKIES";

//const TEST_KAKAO                            = "user/TEST_KAKAO";
//const TEST_KAKAO_SUCCESS                    = "user/TEST_KAKAO_SUCCESS";

const USER_TERMS_TOGGLE                     = "user/USER_TERMS_TOGGLE";
const USER_TERMS_CHECK                      = "user/USER_TERMS_CHECK";

const USER_REGIST                           = "user/USER_REGIST";
const USER_REGIST_SUCCESS                   = "user/USER_REGIST_SUCCESS";
const USER_UPDATE                           = "user/USER_UPDATE";
const USER_UPDATE_SUCCESS                   = "user/USER_UPDATE_SUCCESS";

const GET_ID_LIST                           = "userInfo/GET_ID_LIST";
const GET_ID_LIST_SUCCESS                   = "userInfo/GET_ID_LIST_SUCCESS";
const CHECK_ID_EXIST                        = "userInfo/CHECK_ID_EXIST";
const CHECK_ID_EXIST_SUCCESS                = "userInfo/CHECK_ID_EXIST_SUCCESS";
const COMPARE_INFO                          = "userInfo/COMPARE_INFO";
const COMPARE_INFO_SUCCESS                  = "userInfo/COMPARE_INFO_SUCCESS";
const RESET_PW                              = "userInfo/RESET_PW";
const RESET_PW_SUCCESS                      = "userInfo/RESET_PW_SUCCESS";

const USER_LOGIN_TOGGLE                     = "userInfo/USER_LOGIN_TOGGLE";
const USER_INFO_TOGGLE                      = "userInfo/USER_INFO_TOGGLE";
const SAVE_COOKIES_TOGGLE                   = "userInfo/SAVE_COOKIES_TOGGLE";
const USER_INFO_POPOVER_TOGGLE              = "userInfo/USERINFO_POPOVER_TOGGLE";

const SET_MESSAGE_BLANK                     = "userInfo/SET_MESSAGE_BLANK";
const SET_LOGIN_MESSAGE                     = "userInfo/SET_LOGIN_MESSAGE";
const SET_ALERT_MESSAGE                     = "userInfo/SET_ALERT_MESSAGE";

const CHANGE_ALERT_MESSAGE                  = "userInfo/CHANGE_ALERT_MESSAGE";

const TOGGLE_EDIT_INFO_MODAL                = "userInfo/TOGGLE_EDIT_INFO_MODAL";
const TOGGLE_FIND_ID_PASSWORD_MODAL         = "userInfo/TOGGLE_FIND_ID_PASSWORD_MODAL";
const TOGGLE_LOGIN_ALERT_MODAL              = "userInfo/TOGGLE_LOGIN_ALERT_MODAL";
const TOGGLE_FORCE_LOGOUT_ALERT_MODAL       = "userInfo/TOGGLE_FORCE_LOGOUT_ALERT_MODAL";
const TOGGLE_LOGOUT_ALERT_MODAL             = "userInfo/TOGGLE_LOGOUT_ALERT_MODAL";

const CHANGE_SELECT_PAGE_TYPE               = "userInfo/CHANGE_SELECT_PAGE_TYPE";
const CHANGE_SELECT_FIND_ID_PASSWORD_OPTION = "userInfo/CHANGE_SELECT_FIND_ID_PASSWORD_OPTION";
const CHANGE_SELECT_FIND_ID_PASSWORD_STEP   = "userInfo/CHANGE_SELECT_FIND_ID_PASSWORD_STEP";

const UPDATE_SERVER_USER_INFO               = "userInfo/UPDATE_SERVER_USER_INFO";

export const logIn                          = createRequestThunk(LOG_IN, login, [FRIEND_INIT, CHAT_INIT, LIVE_BROADCAST_INIT, LIVE_WATCHING_INIT, LECTURE_INIT, STUDIO_INFO_INIT, PRDT_SERVICE_INIT]);
export const autoLogIn                      = createRequestThunk(LOG_IN, auto_login, [FRIEND_INIT, CHAT_INIT, LIVE_BROADCAST_INIT, LIVE_WATCHING_INIT, LECTURE_INIT, STUDIO_INFO_INIT, PRDT_SERVICE_INIT]);
export const logInThroughNotification       = createRequestThunk(LOG_IN, login_through_notification, [FRIEND_INIT, CHAT_INIT, LIVE_BROADCAST_INIT, LIVE_WATCHING_INIT, LECTURE_INIT, STUDIO_INFO_INIT, PRDT_SERVICE_INIT]);
export const logInByQRCode                  = createRequestThunk(LOG_IN, login_qrcode, [FRIEND_INIT, CHAT_INIT, LIVE_BROADCAST_INIT, LIVE_WATCHING_INIT, LECTURE_INIT, STUDIO_INFO_INIT, PRDT_SERVICE_INIT]);
export const logInByGuest                   = createRequestThunk(LOG_IN, login_guest,  [FRIEND_INIT, CHAT_INIT, LIVE_BROADCAST_INIT, LIVE_WATCHING_INIT, LECTURE_INIT]);
export const loginBySmartTV                 = createRequestThunk(SMART_TV_LOG_IN, login_smart_tv, [LIVE_WATCHING_INIT_FOR_SMART_TV, SET_SMART_TV_GROUP_MEMBER]);
export const logInByGroupSmartTV            = createRequestThunk(SMART_TV_GROUP_LOG_IN, login_group_smart_tv, [LIVE_WATCHING_INIT_FOR_SMART_TV, SET_SMART_TV_GROUP_MEMBER]);
// export const logInByGroupSmartTV            = tossPhaseAction(SMART_TV_GROUP_LOG_IN, [LIVE_WATCHING_INIT_FOR_SMART_TV]);
export const logoutBySmartTV                = tossPhaseAction(SMART_TV_LOG_OUT, [LIVE_WATCHING_STATE_INIT_FOR_SMART_TV])
export const logOut                         = createRequestThunk(LOG_OUT, logout, [FRIEND_STATE_INIT, CHAT_STATE_INIT, LIVE_STATE_INIT, TALK_STATE_INIT, TALK_LIVE_STATE_INIT, HICLASSTV_STATE_INIT, WHISPER_STATE_INIT, SUB_DOMAIN_STATE_INIT, LECTURE_STATE_INIT, WATSON_STATE_INIT, STUDIO_STATE_INIT, PRDT_SERVICE_STATE_INIT]);
export const forceLogOut                    = createRequestThunk(FORCE_LOG_OUT, force_logout, [FRIEND_STATE_INIT, CHAT_STATE_INIT, LIVE_STATE_INIT, TALK_STATE_INIT, TALK_LIVE_STATE_INIT, HICLASSTV_STATE_INIT, SUB_DOMAIN_STATE_INIT, LECTURE_STATE_INIT, WATSON_STATE_INIT, STUDIO_STATE_INIT, PRDT_SERVICE_STATE_INIT]);

export const forceLogOutInit                = tossPhaseAction(FORCE_LOG_OUT_INIT, [FRIEND_STATE_INIT, CHAT_STATE_INIT, LIVE_STATE_INIT, TALK_STATE_INIT, TALK_LIVE_STATE_INIT, HICLASSTV_STATE_INIT, SUB_DOMAIN_STATE_INIT, LECTURE_STATE_INIT, WATSON_STATE_INIT, STUDIO_STATE_INIT, PRDT_SERVICE_STATE_INIT]);

export const notifyLogoutToAndroid          = createRequestThunk(NOTIFY_LOGOUT_TO_ANDROID, notify_logout_to_android_app);

export const idCheck                        = createRequestThunk(ID_CHECK, idcheck);
export const phoneCheckSend                 = createRequestThunk(PHONE_CHECK_SEND, send_phone_check);
export const phoneCheckConfirm              = createRequestThunk(PHONE_CHECK_CONFIRM, confirm_phone_check);
export const emailCheckSend                 = createRequestThunk(EMAIL_CHECK_SEND, send_email_check);
export const emailCheckConfirm              = createRequestThunk(EMAIL_CHECK_CONFIRM, confirm_email_check);

export const falseToPhoneCheckSend          = createAction(FALSE_TO_PHONE_CHECK_SEND);
export const falseToPhoneCheckOk            = createAction(FALSE_TO_PHONE_CHECK_OK);
export const falseToEmailCheckSend          = createAction(FALSE_TO_EMAIL_CHECK_SEND);
export const falseToEmailCheckOk            = createAction(FALSE_TO_EMAIL_CHECK_OK);
export const falseToRetryLogin              = createAction(FALSE_TO_RETRY_LOGIN);
export const falseToRemoveCookies           = createAction(FALSE_TO_REMOVE_COOKIES);

export const toggleUserTerms                = createAction(USER_TERMS_TOGGLE);
export const checkUserTerms                 = createAction(USER_TERMS_CHECK);

export const userRegist                     = createRequestThunk(USER_REGIST, regist_user);
export const userUpdate                     = createRequestThunk(USER_UPDATE, update_user_info);

export const getIdList                      = createRequestThunk(GET_ID_LIST, search_my_id);
export const checkExistUserId               = createRequestThunk(CHECK_ID_EXIST, idcheck);
export const compareInfo                    = createRequestThunk(COMPARE_INFO, compare_info);
export const resetPassword                  = createRequestThunk(RESET_PW, reset_my_pw);

export const logInToggle                    = createAction(USER_LOGIN_TOGGLE);
export const userInfoToggle                 = createAction(USER_INFO_TOGGLE);
export const saveCookiesToggle              = createAction(SAVE_COOKIES_TOGGLE);
export const userInfoPopoverToggle          = createAction(USER_INFO_POPOVER_TOGGLE);

export const changeSelectPageType               = createAction(CHANGE_SELECT_PAGE_TYPE);
export const changeSelectFindIdPasswordOption   = createAction(CHANGE_SELECT_FIND_ID_PASSWORD_OPTION);
export const changeSelectFindIdPasswordStep     = createAction(CHANGE_SELECT_FIND_ID_PASSWORD_STEP);

export const setMessageBlank                = createAction(SET_MESSAGE_BLANK);
export const setLoginMessage                = (message, kind) => ({type : SET_LOGIN_MESSAGE, message, kind});
export const setMessageString               = message => ({type : SET_ALERT_MESSAGE, message});
export const changeAlertMessage             = (message, kind) => ({type : CHANGE_ALERT_MESSAGE, message, kind});

export const toggle_editInfoModal           = createAction(TOGGLE_EDIT_INFO_MODAL);
export const toggle_findIdPasswordModal     = createAction(TOGGLE_FIND_ID_PASSWORD_MODAL);
export const toggleLogInAlertModal          = createAction(TOGGLE_LOGIN_ALERT_MODAL);
export const toggle_forceLogoutAlertModal   = createAction(TOGGLE_FORCE_LOGOUT_ALERT_MODAL);
export const toggle_logoutAlertModal        = createAction(TOGGLE_LOGOUT_ALERT_MODAL);

export const updateServerUserInfo           = createAction(UPDATE_SERVER_USER_INFO);

// ============================= [ Mixed Class ] ============================== //
const REQUEST_MIXED_CLASS_LOG_IN            = "user/REQUEST_MIXED_CLASS_LOG_IN";
const REQUEST_MIXED_CLASS_LOG_IN_SUCCESS    = "user/REQUEST_MIXED_CLASS_LOG_IN_SUCCESS";
const REQUEST_MIXED_CLASS_LOG_IN_LIST       = "user/REQUEST_MIXED_CLASS_LOG_IN_LIST";
const REQUEST_MIXED_CLASS_LOG_IN_LIST_SUCCESS = "user/REQUEST_MIXED_CLASS_LOG_IN_LIST_SUCCESS";
const REQUEST_MIXED_CLASS_LOG_OUT           = "user/REQUEST_MIXED_CLASS_LOG_OUT";
const REQUEST_MIXED_CLASS_LOG_OUT_SUCCESS   = "user/REQUEST_MIXED_CLASS_LOG_OUT_SUCCESS";
const MIXED_CLASS_LOGIN_SUCCESS             = "user/MIXED_CLASS_LOGIN_SUCCESS";
const MIXED_CLASS_LOGOUT_SUCCESS            = "user/MIXED_CLASS_LOGOUT_SUCCESS";
const REQUEST_SERVER_USER_AUTH              = "user/REQUEST_SERVER_USER_AUTH";
const UPDATE_SERVER_USER_AUTH_RESULT        = "user/UPDATE_SERVER_USER_AUTH_RESULT";
const SAVE_SERVER_USER_AUTH_INFO            = "user/SAVE_SERVER_USER_AUTH_INFO";
const SAVE_SERVER_USER_AUTH_INFO_SUCCESS    = "user/SAVE_SERVER_USER_AUTH_INFO_SUCCESS";

export const requestMixedClassLogin         = createRequestThunk(REQUEST_MIXED_CLASS_LOG_IN, request_mixed_class_login, [LIVE_WATCHING_INIT_FOR_MIXED_CLASS]);
export const requestMixedClassLoginList     = createRequestThunk(REQUEST_MIXED_CLASS_LOG_IN_LIST, request_mixed_class_login, [LIVE_WATCHING_INIT_FOR_MIXED_CLASS]);
export const reqeustMixedClassLogout        = createAction(REQUEST_MIXED_CLASS_LOG_OUT);
export const mixedClassLoginSuccess         = tossPhaseAction(MIXED_CLASS_LOGIN_SUCCESS, [LIVE_WATCHING_INIT]);
export const mixedClassLogoutSuccess        = tossPhaseAction(MIXED_CLASS_LOGOUT_SUCCESS, [LIVE_STATE_INIT]);
export const requestServerUserAuth          = createAction(REQUEST_SERVER_USER_AUTH);
export const updateServerUserAuthResult     = createAction(UPDATE_SERVER_USER_AUTH_RESULT);
export const saveServerUserAuthInfo         = createRequestThunk(SAVE_SERVER_USER_AUTH_INFO, update_server_user_auth_info);
// ============================================================================ //

// ============================= [ RBMQ status ] ============================== //
const RBMQ_SERVER_CONNECTED                 = "userInfo/RBMQ_SERVER_CONNECTED";
const RBMQ_SERVER_DISCONNECTED              = "userInfo/RBMQ_SERVER_DISCONNECTED";
const RBMQ_SERVER_ERROR                     = "userInfo/RBMQ_SERVER_ERROR";

export const rbmqServerConnected            = createAction(RBMQ_SERVER_CONNECTED);
export const rbmqServerDisconnected         = createAction(RBMQ_SERVER_DISCONNECTED);
export const rbmqServerError                = createAction(RBMQ_SERVER_ERROR);
// ============================================================================ //

// ============================= [ MQ function ] ============================== //
const MQ_UPDATE_INFO                        = "user/MQ_UPDATE_INFO";
export const mqUpdateInfo                   = createAction(MQ_UPDATE_INFO);
// ============================================================================ //

const userSubDomain                         = window.location.hostname.split(".");

const initialState = {
    isLoginYn               : "N",
    isGuestYn               : "N",
    userSeq                 : null,
    userID                  : "",
    passWD                  : "",
    passConfirm             : "",
    phoneNumber             : "",
    confirmNumber           : "",
    userNickname            : "",
    userEmail               : "",
    userProfile             : "",
    profileImgUrl           : "",
    isAutoLogin             : "N",
    mySubDomainSeq          : -1,
    usingPassInfo           : {
        serviceName         : "",
        passProductName     : ""
    },
    userExtraCode           : "",
    userType                : "",
    userLoginPageType       : enWebPage.Home,

    userGateWay             : "",
    userServiceType         : "",

    /** for rbmq 연결 */
    isRbmqServerConnected   : false,

    login_info              : {
        userSeq             : -1,
        id                  : "",
        pw                  : "",
        nickname            : "",
    },

    //login관련 Modal 관련 상태 값
    loginMessage            : "",
    alertKind               : ConstData.ALERT_KIND.NONE,
    alertType               : oAlertKind.ERROR,

    //사용자 정보 관련
    isExistUserInfo         : false,
    //-------hiclass
    isLogInOpened           : false,
    isRegistInfoOpened      : false,
    isUpdateInfoOpened      : false,
    userInfoMessage         : "",
    userInfoAlertKind       : ConstData.ALERT_KIND.NONE,
    isIdCheckOK             : false,
    isPhoneTestStart        : false,
    isPhoneTestOK           : false,
    certiCodeSeq            : -1,

    isEmailTestStart        : false,
    isEmailTestOK           : false,
    emailCertiCodeSeq       : -1,

    //-------web
    web_userInfoMessage     : "",
    web_userInfoAlertKind   : ConstData.ALERT_KIND.NONE,
    web_isPhoneTestOK       : false,
    web_isPhoneTestStart    : false,
    web_certiCodeSeq        : -1,

    list_findUserId         : [],

    isEditInfoModalOpened   : false,
    isFindIdPasswordOpened  : false,
    findIdPasswordOption    : -1,
    findIdPasswordStep      : -1,
    findIdPasswordID        : "",
    isForceLogoutModalOpened : false,
    isLogoutAlertModalOpened : false,

    isNeedCookiesInfoSave   : false,
    isNeedCookiesInfoRemove : false,
    isNeedRetryingLogin     : false,

    isPopoverOpened         : false,

    selectPageType          : null,

    myLoginMode             : ConstData.LOGIN_MODE.NONE,

    userKind                : getUserKindFromURL(userSubDomain),

    isTermsOpened           : false,
    isTermsAccept           : false,

    isConfigAdmin           : false,
    isCreateAdmin           : false,

    isUsablePass            : false,

    isLoginAlertModalOpened : false,

    /** for mixedClass */
    serverUserSeq           : -1,
    localServerSeq          : -1,
    // serverUserSeq           : 1011,
    // localServerSeq          : 5,

    /** for smart tv */
    smartTVTempKey          : null
};

const user = handleActions({
    [LOG_IN_SUCCESS]: (state, action) => {
        const { kind, mode } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("LOG_IN_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isLoginSuccess } = action.payload;

            if (isLoginSuccess) {   // 로그인에 성공한 경우
                const { isUsablePass, user_info, rbmq_info } = action.payload;

                if (state.isLoginYn === "N") {
                    const rbmqServer_info = {
                        exchangeName : rbmq_info.EXCH_NAME,
                        ip           : rbmq_info.SVER_IP, 
                        port         : rbmq_info.SVER_PORT, 
                        wsIp         : rbmq_info.WSVER_IP,
                        wsPort       : rbmq_info.WSVER_PORT,
                        wsPath       : rbmq_info.WSVER_PATH,
                        vHost        : rbmq_info.VRTL_HOST,
                        userPW       : rbmq_info.PSWD,
                        userID       : rbmq_info.USER_ID, 
                    };

                    const usingPassInfo = {
                        serviceName: "",
                        passProductName: ""
                    };

                    if (user_info.pass_info) {
                        usingPassInfo.serviceName = user_info.pass_info.PRDT_SVC_NM;
                        usingPassInfo.passProductName = user_info.pass_info.PRDT_NM;
                    }

                    if (mode && mode === "hiclass") {
                        const { gateWay, version, list_prdtService } = action.payload;
                        const list_service = list_prdtService.map(row => ({
                            svcSeq              : row.SVC_SEQ,
                            svcName             : row.SVC_NM,
                            funcCode            : row.FUNC_CD,
                            funcAttributeText   : row.FUNC_ATRB_STRN
                        }));

                        let userKind = user_info.USER_KIND;
                        let mainAddurl = null; 

                        if (userKind === "hiclasstv" || userKind === "local") {
                            mainAddurl = ConstData.MAIN_HOST_ADD;
                        } else {
                            mainAddurl = `https://${userKind}.hiclass.tv`;
                        }

                        console.log(mainAddurl);

                        if (gateWay && gateWay !== "") {
                            callEoForLogin(rbmq_info, user_info, mode, gateWay, version, list_service, mainAddurl);
                        } else {
                            callEoForLogin(rbmq_info, user_info, mode, "soup.server", version, list_service, mainAddurl);
                        }

                        return {
                            ...state,
                            mqServerInfo        : rbmqServer_info,

                            isLoginYn           : "Y",
                            isGuestYn           : "N",
                            userSeq             : user_info.USER_SEQ,
                            userID              : user_info.USER_ID,
                            userNickname        : user_info.USER_NICKNAME,
                            phoneNumber         : user_info.USER_PHONE_NUMBER,
                            userEmail           : user_info.USER_EMAIL, 
                            userProfile         : user_info.USER_PROFILE,
                            profileImgUrl       : user_info.USER_PROFILE_IMG,
                            userExtraCode       : user_info.USER_EXTRA_CODE !== null ? user_info.USER_EXTRA_CODE : "",
                            userType            : user_info.USER_TYPE,
                            userKind            : user_info.USER_KIND,
                            mySubDomainSeq      : user_info.SUB_DOMAIN_SEQ,
                            usingPassInfo       : usingPassInfo,

                            userGateWay         : action.payload.gateWay ? action.payload.gateWay : "soup.server",
                            userServiceType     : "",

                            userInfoMessage     : "",
                            userInfoAlertKind   : ConstData.ALERT_KIND.NONE,

                            serverUserSeq       : user_info.SERVER_USER_SEQ !== null ? user_info.SERVER_USER_SEQ : -1,
                            loginMessage        : "",
                            alertKind           : ConstData.ALERT_KIND.NONE,

                            isConfigAdmin       : user_info.CONFIG_ADMIN_YN === "Y" ? true : false,
                            isCreateAdmin       : user_info.CREATE_ADMIN_YN === "Y" ? true : false,
                            isUsablePass        : isUsablePass,

                            isRegistInfoOpened  : false,
                            isUpdateInfoOpened  : false,
                            isLogInOpened       : false,
                            isPopoverOpened     : false,
                            isIdCheckOK         : false,
                            isPhoneTestStart    : false,
                            isPhoneTestOK       : false,
                            isNeedRetryingLogin : false,
                            //isAutoLogin         : isAutoLogin,
                            //isNeedCookiesInfoSave : isAutoLogin === "Y" ? true : false
                        }
                    } else if (mode && mode === "web") {
                        const { loginInfo } = action.parms;
                        const userNickname = loginInfo.userNickname && loginInfo.userNickname !== "" ? loginInfo.userNickname : user_info.USER_NICKNAME;
                        const gateWay = action.payload.gateWay ? action.payload.gateWay : "";
                        let userLoginPageType = state.userLoginPageType;

                        if (kind && kind === "guest") {
                            const { joinLiveSeq } = action.payload;

                            setRbmqInfoForGuest(user_info.USER_SEQ, joinLiveSeq, rbmqServer_info);
                        } else {
                            if (kind && kind === "qr-login") {
                                userLoginPageType = enWebPage.LiveQRLogin;
                            }

                            setRbmqInfo(user_info.USER_SEQ, rbmqServer_info, userLoginPageType);
                        }

                        console.log("input userNickname => ", userNickname);

                        return {
                            ...state,
                            mqServerInfo        : rbmqServer_info,

                            isLoginYn           : "Y",
                            isGuestYn           : kind && kind === "guest" ? "Y" : "N",
                            userSeq             : user_info.USER_SEQ,
                            userID              : user_info.USER_ID,
                            userNickname        : userNickname,
                            phoneNumber         : user_info.USER_PHONE_NUMBER,
                            userEmail           : user_info.USER_EMAIL, 
                            userProfile         : user_info.USER_PROFILE,
                            profileImgUrl       : user_info.USER_PROFILE_IMG,
                            userExtraCode       : user_info.USER_EXTRA_CODE !== null ? user_info.USER_EXTRA_CODE : "",
                            userType            : user_info.USER_TYPE,
                            userKind            : state.userKind,
                            mySubDomainSeq      : user_info.SUB_DOMAIN_SEQ,
                            usingPassInfo       : usingPassInfo,

                            userLoginPageType   : userLoginPageType,

                            userGateWay         : gateWay,
                            userServiceType     : "",

                            userInfoMessage     : "",
                            userInfoAlertKind   : ConstData.ALERT_KIND.NONE,

                            serverUserSeq       : user_info.SERVER_USER_SEQ !== null ? user_info.SERVER_USER_SEQ : -1,
                            loginMessage        : "",
                            alertKind           : ConstData.ALERT_KIND.NONE,

                            isConfigAdmin       : user_info.CONFIG_ADMIN_YN === "Y" ? true : false,
                            isCreateAdmin       : user_info.CREATE_ADMIN_YN === "Y" ? true : false,
                            isUsablePass        : isUsablePass,

                            isRegistInfoOpened  : false,
                            isUpdateInfoOpened  : false,
                            isLogInOpened       : false,
                            isPopoverOpened     : false,
                            isIdCheckOK         : false,
                            isPhoneTestStart    : false,
                            isPhoneTestOK       : false,
                            isNeedRetryingLogin : false,
                            //isAutoLogin         : isAutoLogin,
                            //isNeedCookiesInfoSave : isAutoLogin === "Y" ? true : false
                        }
                    } else { // 이런 경우가 있나...?ㅁ?
                        return {
                            ...state
                        }
                    }
                } else {    // 로그인 시도를 여러번 하는 경우, 데이터 값 엉킴 문제 처리 부분
                    return {
                        ...state
                    }
                }
            } else {    // 로그인에 실패한 경우
                const { isLoginExist } = action.payload;
                //console.log("LOG_IN_TRY_FAIL - ", action.payload, action.parms);

                if (isLoginExist) { // 중복 로그인
                    const { userSEQ } = action.payload;

                    if (state.isLoginYn === "N") {
                        if (kind && kind === "auto") {
                            const { tokenKey, lectureCode } = action.parms;

                            return {
                                ...state,
                                login_info                  : {
                                    userSeq                 : userSEQ,
                                    id                      : state.login_info.id,
                                    pw                      : state.login_info.password,
                                    nickname                : state.login_info.nickname,
                                    tokenKey                : tokenKey,
                                    lectureCode             : lectureCode
                                },
                                isForceLogoutModalOpened    : true,
                                // alertKind                   : ConstData.ALERT_KIND.DANGER,
                                // loginMessage                : "이미 사용 중인 아이디입니다. [Code.US518.0]"
                            }
                        } else if (kind && kind === "qr-login") {
                            return {
                                ...state,
                                login_info                  : {
                                    userSeq                 : userSEQ,
                                    id                      : null,
                                    pw                      : null,
                                    nickname                : null,
                                    tokenKey                : null,
                                    lectureCode             : null
                                },
                                isForceLogoutModalOpened    : true,
                                // alertKind                   : ConstData.ALERT_KIND.DANGER,
                                // loginMessage                : "이미 사용 중인 아이디입니다. [Code.US533.0]"
                            }
                        } else {
                            const { loginInfo } = action.parms;

                            return {
                                ...state,
                                login_info                  : {
                                    userSeq                 : userSEQ,
                                    id                      : loginInfo.userID,
                                    pw                      : loginInfo.password,
                                    nickname                : loginInfo.userNickname,
                                    tokenKey                : null,
                                    lectureCode             : null
                                },
                                isForceLogoutModalOpened    : true,
                                // alertKind                   : ConstData.ALERT_KIND.DANGER,
                                // loginMessage                : "이미 사용 중인 아이디입니다. [Code.US550.0]"
                            }
                        }
                    } else {
                        console.log("LOG_IN - (이런 경우가 있나..? 로그인 버튼 두번 눌렸을때려나..?) state.isLoginYn => ", state.isLoginYn);

                        return {
                            ...state
                        }
                    }
                } else {
                    if (mode && mode === "hiclass") {
                        const { isUsedMixedClass } = action.payload;

                        if (isUsedMixedClass) {
                            return {
                                ...state,
                                alertKind               : ConstData.ALERT_KIND.DANGER,
                                loginMessage            : "현재 로컬 서버에서 믹스드 클래스를 사용 중이므로 해당 아이디로는 접속할 수 없습니다. [Code.US568.0]"
                            }
                        } else {
                            const { isUsablePass } = action.payload;

                            if (isUsablePass === false) {
                                return {
                                    ...state,
                                    alertKind           : ConstData.ALERT_KIND.DANGER,
                                    loginMessage        : "이용권이 만료되었습니다.<br>웹페이지에 방문하여 이용권 갱신 후<br>서비스를 다시 이용하실 수 있습니다. [Code.US577.0]"
                                }
                            } else {
                                return {
                                    ...state,
                                    alertKind           : ConstData.ALERT_KIND.DANGER,
                                    loginMessage        : "아이디 혹은 비밀번호가 일치하지 않습니다.<br>입력한 내용을 다시 확인하신 후 재시도해 주세요. [Code.US583.0]"
                                }
                            }
                        }
                    } else {
                        if (kind && kind === "auto") {
                            return {
                                ...state,
                                isLoginAlertModalOpened : true,
                                alertKind               : ConstData.ALERT_KIND.DANGER,
                                loginMessage            : "사용할 수 없는 토큰이거나 유효하지 않은 접근입니다. [Code.US593.0]"
                            }
                        } else if (kind && kind === "guest") {
                            const { errMsg } = action.payload;
                            console.log("LOG_IN_TRY_FAIL - ", errMsg);

                            if (errMsg && errMsg !== "") {
                                return {
                                    ...state,
                                    isLoginAlertModalOpened : true,
                                    alertType               : oAlertKind.INVALID,
                                    alertKind               : ConstData.ALERT_KIND.DANGER,
                                    loginMessage            : errMsg + " [Code.US605.0]"
                                }
                            }  else {
                                return {
                                    ...state,
                                    isLoginAlertModalOpened : true,
                                    alertKind               : ConstData.ALERT_KIND.DANGER,
                                    loginMessage            : "사용할 수 없는 초대 코드이거나 잘못된 접근입니다.<br>관리자에게 문의하시기 바랍니다. [Code.US612.0]"
                                }
                            }
                        } else {
                            return {
                                ...state,
                                alertKind                   : ConstData.ALERT_KIND.DANGER,
                                loginMessage                : "아이디 혹은 비밀번호가 일치하지 않습니다.<br>입력한 내용을 다시 확인하신 후 재시도해 주세요. [Code.US619.0]"
                            }
                        }
                    }
                }
            }
        } else {
            console.log("LOGIN_TRY_FAIL - ", action.payload, action.parms);

            return {
                ...state,
                alertKind           : ConstData.ALERT_KIND.DANGER,
                loginMessage        : "오류가 발생하여 로그인 요청을 완료하지 못했습니다.<br>다시 시도해 주세요. [Code.US631.0]"
            }
        }
    },

    [LOG_OUT_SUCCESS]: (state, action) => {
        const { userSeq, mode } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("LOG_OUT_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isLogoutSuccess } = action.payload;

            if (isLogoutSuccess) {
                if (mode && mode === "hiclass") {
                    callEoForLogout(userSeq, mode);
                    clearTimer();
                } else if (mode && (mode === "web" || mode === "mixed-web")) {
                    disconnectRbmq("user679");
                }

                return {
                    ...state,
                    isLoginYn               : "N",
                    isGuestYn               : "N",
                    userSeq                 : null,
                    userID                  : "",
                    passWD                  : "",
                    passConfirm             : "",
                    phoneNumber             : "",
                    confirmNumber           : "",
                    userNickname            : "",
                    userEmail               : "",
                    userProfile             : "",
                    profileImgUrl           : "",
                    userExtraCode           : "",
                    userType                : "",
                    mySubDomainSeq          : -1,
                    usingPassInfo           : {
                        serviceName         : "",
                        passProductName     : ""
                    },

                    userLoginPageType       : enWebPage.Home,

                    serverUserSeq           : -1,

                    //login 및 사용자 인증 Modal 관련 상태 값
                    loginMessage            : "",
                    alertKind               : ConstData.ALERT_KIND.NONE,

                    userGateWay             : "",
                    userServiceType         : "",

                    /** for rbmq 연결 */
                    //isRbmqServerConnected   : false,

                    login_info              : {
                        userSeq             : -1,
                        id                  : "",
                        pw                  : "",
                        nickname            : ""
                    },

                    isConfigAdmin           : false,
                    isCreateAdmin           : false,

                    isUsablePass            : false,

                    isAutoLogin             : "N",

                    //사용자 정보 관련
                    isExistUserInfo         : false,

                    //-------hiclass
                    isRegistInfoOpened      : false,
                    isUpdateInfoOpened      : false,
                    isLogInOpened           : false,
                    userInfoMessage         : "",
                    userInfoAlertKind       : ConstData.ALERT_KIND.NONE,
                    isIdCheckOK             : false,
                    isPhoneTestStart        : false,
                    isPhoneTestOK           : false,
                    isEmailTestStart        : false,
                    isEmailTestOK           : false,
                    certiCodeSeq            : -1,

                    //-------web
                    web_userInfoMessage     : "",
                    web_userInfoAlertKind   : ConstData.ALERT_KIND.NONE,
                    web_isPhoneTestOK       : false,
                    web_isPhoneTestStart    : false,
                    web_certiCodeSeq        : -1,

                    list_findUserId         : [],

                    isEditInfoModalOpened   : false,
                    isFindIdPasswordOpened  : false,
                    findIdPasswordOption    : -1,
                    findIdPasswordStep      : -1,
                    findIdPasswordID        : "",

                    isNeedCookiesInfoSave   : false,
                    isNeedCookiesInfoRemove : false,

                    isPopoverOpened         : false,

                    selectPageType          : null,

                    myLoginMode             : ConstData.LOGIN_MODE.NONE
                }
            } else {
                console.log("LOG_OUT_TRY_FAIL - isLogoutSuccess => ", isLogoutSuccess, action.payload);

                return {
                    ...state
                }
            }
        } else {
            console.log("LOG_OUT_TRY_FAIL - isSuccessed => ", isSuccessed, action.payload);

            return {
                ...state
            }
        }
    },

    [FORCE_LOG_OUT_SUCCESS]: (state, action) => {
        const { userSeq, mode } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("FORCE_LOG_OUT_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isLogoutSuccess } = action.payload;

            if (isLogoutSuccess) {
                /* if (mode && mode === "hiclass") {
                    callEoForLogout(userSeq, mode);
                    clearTimer();
                } else if (mode && mode === "web") {
                    disconnectRbmq();
                } */

                return {
                    ...state,
                    isForceLogoutModalOpened    : false,
                    isNeedRetryingLogin         : true
                }
            } else {
                console.log("FORCE_LOG_OUT_TRY_FAIL - isLogoutSuccess => ", isLogoutSuccess);

                return {
                    ...state
                }
            }
        } else {
            console.log("FORCE_LOG_OUT_TRY_FAIL - isSuccessed => ", isSuccessed);

            return {
                ...state
            }
        }
    },

    [FORCE_LOG_OUT_INIT]: (state, action) => {
        const { userSeq, mode, isSuccessed } = action.payload;
        console.log("FORCE_LOG_OUT_INIT - ", action.payload);

        if (isSuccessed) {
            const { isLogoutSuccess } = action.payload;

            if (isLogoutSuccess) {
                if (mode && mode === "hiclass") {
                    callEoForLogout(userSeq, mode);
                    clearTimer();
                } else if (mode && mode === "web") {
                    disconnectRbmq("user837");
                }

                return {
                    ...state,
                    isLoginYn               : "N",
                    userSeq                 : null,
                    userID                  : "",
                    passWD                  : "",
                    passConfirm             : "",
                    phoneNumber             : "",
                    confirmNumber           : "",
                    userNickname            : "",
                    userEmail               : "",
                    userProfile             : "",
                    profileImgUrl           : "",
                    userExtraCode           : "",
                    userType                : "",
                    mySubDomainSeq          : -1,
                    usingPassInfo           : {
                        serviceName         : "",
                        passProductName     : ""
                    },

                    userLoginPageType       : enWebPage.Home,

                    isConfigAdmin           : false,
                    isCreateAdmin           : false,

                    isUsablePass            : false,

                    isAutoLogin             : "N",
        
                    //login관련 Modal 관련 상태 값
                    loginMessage            : "",
                    alertKind               : ConstData.ALERT_KIND.NONE,
                    //사용자 정보 관련
                    isExistUserInfo         : false,

                    //-------hiclass
                    isRegistInfoOpened      : false,
                    isUpdateInfoOpened      : false,
                    isLogInOpened           : false,
                    userInfoMessage         : "",
                    userInfoAlertKind       : ConstData.ALERT_KIND.NONE,
                    isIdCheckOK             : false,
                    isPhoneTestStart        : false,
                    isPhoneTestOK           : false,
                    isEmailTestStart        : false,
                    isEmailTestOK           : false,
                    certiCodeSeq            : -1,

                    //-------web
                    web_userInfoMessage     : "",
                    web_userInfoAlertKind   : ConstData.ALERT_KIND.NONE,
                    web_isPhoneTestOK       : false,
                    web_isPhoneTestStart    : false,
                    web_certiCodeSeq        : -1,

                    list_findUserId         : [],

                    isEditInfoModalOpened   : false,
                    isFindIdPasswordOpened  : false,
                    findIdPasswordOption    : -1,
                    findIdPasswordStep      : -1,
                    findIdPasswordID        : "",

                    isLogoutAlertModalOpened : true,

                    isNeedCookiesInfoSave   : false,
                    isNeedCookiesInfoRemove : true,

                    isPopoverOpened         : false,

                    selectPageType          : null,

                    myLoginMode             : ConstData.LOGIN_MODE.NONE
                }
            } else {
                console.log("FORCE_LOG_OUT_INIT_TRY_FAIL - isLogoutSuccess => ", isLogoutSuccess);

                return {
                    ...state
                }
            }
        } else {
            console.log("FORCE_LOG_OUT_INIT_TRY_FAIL - isSuccessed => ", isSuccessed);

            return {
                ...state
            }
        }
    },

    [NOTIFY_LOGOUT_TO_ANDROID_SUCCESS]: (state, action) => {
        const { userSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("NOTIFY_LOGOUT_TO_ANDROID_SUCCESS - ", action.payload, action.parms);
        console.log(`NOTIFY_LOGOUT_TO_ANDROID - userSeq[${userSeq}], isSuccessed[${isSuccessed}]`);

        return {
            ...state
        }
    },

    [REQUEST_MIXED_CLASS_LOG_IN_LIST_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("REQUEST_MIXED_CLASS_LOG_IN_LIST - ", action.payload);

        if (isSuccessed) {
            const { isAuthSuccess } = action.payload;

            if (isAuthSuccess) {
                /* if (action.payload.isRequestSuccess !== undefined) {

                } else {
                    const { rbmq_info, mixedClass_info, isUsablePass } = action.payload;

                    if (mixedClass_info) {
                        const { rbmq_info, isUsablePass } = action.payload;

                        if (rbmq_info) {
                            const rbmqServer_info = {
                                exchangeName : rbmq_info.EXCH_NAME,
                                ip           : rbmq_info.SVER_IP, 
                                port         : rbmq_info.SVER_PORT, 
                                wsIp         : rbmq_info.WSVER_IP,
                                wsPort       : rbmq_info.WSVER_PORT,
                                wsPath       : rbmq_info.WSVER_PATH,
                                vHost        : rbmq_info.VRTL_HOST,
                                userPW       : rbmq_info.PSWD,
                                userID       : rbmq_info.USER_ID, 
                            };
                        }
                    } else {

                    }

                    

                } */
                // const { isRequestSuccess } = action.payload;

                // if (isRequestSuccess) {
                    const { rbmq_info, mixedClass_info, isUsablePass } = action.payload;

                    const rbmqServer_info = {
                        exchangeName : rbmq_info.EXCH_NAME,
                        ip           : rbmq_info.SVER_IP, 
                        port         : rbmq_info.SVER_PORT, 
                        wsIp         : rbmq_info.WSVER_IP,
                        wsPort       : rbmq_info.WSVER_PORT,
                        wsPath       : rbmq_info.WSVER_PATH,
                        vHost        : rbmq_info.VRTL_HOST,
                        userPW       : rbmq_info.PSWD,
                        userID       : rbmq_info.USER_ID, 
                    };

                    // 여기에 임시 키로 bind 하는 process....
                    setRbmqInfoForMixedClass(/* mixedClass_info.localStudentSeq,  */mixedClass_info.tempBindKey, rbmqServer_info, mixedClass_info);

                    return {
                        ...state,
                        mqServerInfo        : rbmqServer_info,
                        isUsablePass        : isUsablePass
                    }
                // } else {
                //     return {
                //         ...state
                //     }
                // }
            } else {
                return {
                    ...state,
                    isLoginAlertModalOpened : true,
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    loginMessage            : "잘못된 접근입니다.<br>관리자에게 문의하세요. [Code.US977.0]"
                }
            }
        } else {
            return {
                ...state,
                isLoginAlertModalOpened : true,
                alertKind               : ConstData.ALERT_KIND.DANGER,
                loginMessage            : "잘못된 접근입니다.<br>관리자에게 문의하세요. [Code.US985.0]"
            }
        }
    },

    [REQUEST_MIXED_CLASS_LOG_OUT]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("REQUEST_MIXED_CLASS_LOG_OUT - ");
        // disconnectRbmqForMixed();
        sendRequestMixedClassLogout(state.userSeq);

        if (isSuccessed) {
            const { isLogoutSuccess } = action.payload;

            if (isLogoutSuccess) {

                return {
                    ...state
                }
            } else {
                console.log("REQUEST_MIXED_CLASS_LOG_OUT - isLogoutSuccess => ", isLogoutSuccess);
            
                return {
                    ...state
                }
            }
        } else {
            console.log("REQUEST_MIXED_CLASS_LOG_OUT - isSuccessed => ", isSuccessed);

            return {
                ...state
            }
        }
    },

    [MIXED_CLASS_LOGIN_SUCCESS]: (state, action) => {
        const { isSuccessed, mode } = action.payload;
        console.log("MIXED_CLASS_LOGIN - ", action.payload);

        if (isSuccessed) {
            const { isLoginSuccess } = action.payload;

            if (isLoginSuccess) {   // 로그인에 성공한 경우
                const { user_info } = action.payload;

                if (state.isLoginYn === "N") {
                    const usingPassInfo = {
                        serviceName: "",
                        passProductName: ""
                    };

                    if (user_info.pass_info) {
                        usingPassInfo.serviceName = user_info.pass_info.PRDT_SVC_NM;
                        usingPassInfo.passProductName = user_info.pass_info.PRDT_NM;
                    }

                    const gateWay = action.payload.gateWay ? action.payload.gateWay : "";

                    if (mode && mode === "web") {
                        const { joinLiveSeq } = action.payload;
                        subscribeLoginForMixedClass(user_info.USER_SEQ, joinLiveSeq);
                    }

                    return {
                        ...state,
                        mqServerInfo        : state.mqServerInfo,

                        isLoginYn           : "Y",
                        isGuestYn           : "N",
                        userSeq             : user_info.USER_SEQ,
                        userID              : user_info.USER_ID,
                        userNickname        : user_info.USER_NICKNAME,
                        phoneNumber         : user_info.USER_PHONE_NUMBER,
                        userEmail           : user_info.USER_EMAIL, 
                        userProfile         : user_info.USER_PROFILE,
                        profileImgUrl       : user_info.USER_PROFILE_IMG,
                        userExtraCode       : user_info.USER_EXTRA_CODE !== null ? user_info.USER_EXTRA_CODE : "",
                        userType            : user_info.USER_TYPE,
                        userKind            : mode === "hiclass" ? user_info.USER_KIND : state.userKind,
                        mySubDomainSeq      : user_info.SUB_DOMAIN_SEQ,
                        usingPassInfo       : usingPassInfo,

                        userLoginPageType   : enWebPage.MixedClassLogin,

                        userGateWay         : gateWay,
                        userServiceType     : "",

                        userInfoMessage     : "",
                        userInfoAlertKind   : ConstData.ALERT_KIND.NONE,

                        isConfigAdmin       : user_info.CONFIG_ADMIN_YN === "Y" ? true : false,
                        isCreateAdmin       : user_info.CREATE_ADMIN_YN === "Y" ? true : false,

                        isRegistInfoOpened  : false,
                        isUpdateInfoOpened  : false,
                        isLogInOpened       : false,
                        isPopoverOpened     : false,
                        isIdCheckOK         : false,
                        isPhoneTestStart    : false,
                        isPhoneTestOK       : false,
                        isNeedRetryingLogin : false,
                        //isAutoLogin         : isAutoLogin,
                        //isNeedCookiesInfoSave : isAutoLogin === "Y" ? true : false
                    }
                } else {    // 로그인 시도를 여러번 하는 경우, 데이터 값 엉킴 문제 처리 부분
                    return {
                        ...state
                    }
                }
            } else {    // 로그인에 실패한 경우
                const { isLoginExist } = action.payload;

                disconnectTempRbmq();

                if (isLoginExist) { // 중복 로그인
                    return {
                        ...state,
                        // isLoginAlertModalOpened : true,
                        alertKind               : ConstData.ALERT_KIND.DANGER,
                        loginMessage            : "이미 사용 중인 아이디입니다. [Code.US112.5]"
                    }
                } else {
                    const { errMsg } = action.payload;

                    if (errMsg && errMsg !== "") {
                        return {
                            ...state,
                            isLoginAlertModalOpened : true,
                            alertType               : oAlertKind.INVALID,
                            alertKind               : ConstData.ALERT_KIND.DANGER,
                            loginMessage            : errMsg + " [Code.US108.2]"
                        }
                    }  else {
                        return {
                            ...state,    
                            isLoginAlertModalOpened : true,
                            alertKind               : ConstData.ALERT_KIND.DANGER,
                            loginMessage            : "잘못된 접근입니다.<br>관리자에게 문의하세요. [Code.US108.9]"
                        }
                    }
                }
            }
        } else {
            disconnectTempRbmq();
            
            return {
                ...state,    
                isLoginAlertModalOpened : true,
                alertKind               : ConstData.ALERT_KIND.DANGER,
                loginMessage            : "오류가 발생하여 접속을 완료하지 못했습니다.<br>다시 시도해 주세요."
                                          + "<br>같은 현상이 반복될 경우<br>관리자에게 문의하시기 바랍니다. [Code.US110.2]"
            }
        }
    },

    [MIXED_CLASS_LOGOUT_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("MIXED_CLASS_LOGOUT_SUCCESS - ");

        if (isSuccessed) {
            const { isLogoutSuccess } = action.payload;

            if (isLogoutSuccess) {
                disconnectRbmqForMixed();

                return {
                    ...state,
                    isLoginYn               : "N",
                    isGuestYn               : "N",
                    userSeq                 : null,
                    userID                  : "",
                    passWD                  : "",
                    passConfirm             : "",
                    phoneNumber             : "",
                    confirmNumber           : "",
                    userNickname            : "",
                    userEmail               : "",
                    userProfile             : "",
                    profileImgUrl           : "",
                    userExtraCode           : "",
                    userType                : "",
                    mySubDomainSeq          : -1,
                    usingPassInfo           : {
                        serviceName         : "",
                        passProductName     : ""
                    },

                    userLoginPageType       : enWebPage.Home,

                    serverUserSeq           : -1,

                    //login 및 사용자 인증 Modal 관련 상태 값
                    loginMessage            : "",
                    alertKind               : ConstData.ALERT_KIND.NONE,

                    userGateWay             : "",
                    userServiceType         : "",

                    /** for rbmq 연결 */
                    //isRbmqServerConnected   : false,

                    login_info              : {
                        userSeq             : -1,
                        id                  : "",
                        pw                  : "",
                        nickname            : ""
                    },

                    isConfigAdmin           : false,
                    isCreateAdmin           : false,

                    isUsablePass            : false,

                    isAutoLogin             : "N",

                    //사용자 정보 관련
                    isExistUserInfo         : false,

                    //-------hiclass
                    isRegistInfoOpened      : false,
                    isUpdateInfoOpened      : false,
                    isLogInOpened           : false,
                    userInfoMessage         : "",
                    userInfoAlertKind       : ConstData.ALERT_KIND.NONE,
                    isIdCheckOK             : false,
                    isPhoneTestStart        : false,
                    isPhoneTestOK           : false,
                    isEmailTestStart        : false,
                    isEmailTestOK           : false,
                    certiCodeSeq            : -1,

                    //-------web
                    web_userInfoMessage     : "",
                    web_userInfoAlertKind   : ConstData.ALERT_KIND.NONE,
                    web_isPhoneTestOK       : false,
                    web_isPhoneTestStart    : false,
                    web_certiCodeSeq        : -1,

                    list_findUserId         : [],

                    isEditInfoModalOpened   : false,
                    isFindIdPasswordOpened  : false,
                    findIdPasswordOption    : -1,
                    findIdPasswordStep      : -1,
                    findIdPasswordID        : "",

                    isNeedCookiesInfoSave   : false,
                    isNeedCookiesInfoRemove : true,

                    isPopoverOpened         : false,

                    selectPageType          : null,

                    myLoginMode             : ConstData.LOGIN_MODE.NONE
                }
            } else {
                console.log("REQUEST_MIXED_CLASS_LOG_OUT - isLogoutSuccess => ", isLogoutSuccess);
            
                return {
                    ...state
                }
            }
        } else {
            console.log("REQUEST_MIXED_CLASS_LOG_OUT - isSuccessed => ", isSuccessed);

            return {
                ...state
            }
        }
    },

    /**********************************************************/
    ////////////////////////// SMART TV ////////////////////////
    /**********************************************************/

    [SMART_TV_LOG_IN_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("SMART_TV_LOG_IN - ", action.payload);

        if (isSuccessed) {
            const { isLoginSuccess } = action.payload;

            if (isLoginSuccess) {   // 로그인에 성공한 경우
                const { user_info, rbmq_info, tempKey } = action.payload;

                if (state.isLoginYn === "N") {
                    const rbmqServer_info = {
                        exchangeName : rbmq_info.EXCH_NAME,
                        ip           : rbmq_info.SVER_IP, 
                        port         : rbmq_info.SVER_PORT, 
                        wsIp         : rbmq_info.WSVER_IP,
                        wsPort       : rbmq_info.WSVER_PORT,
                        wsPath       : rbmq_info.WSVER_PATH,
                        vHost        : rbmq_info.VRTL_HOST,
                        userPW       : rbmq_info.PSWD,
                        userID       : rbmq_info.USER_ID, 
                    };

                    const { live_info } = action.payload;

                    let cLive_info = {
                        liveSeq         : live_info.LIVE_SEQ,
                        liveName        : live_info.LIVE_NAME,
                        liveStatus      : "Y",
                        teacher_info    : {
                            userSeq         : live_info.LIVE_TEACHER_SEQ,
                            userNickname    : live_info.USER_NICKNAME,
                            userProfile     : live_info.USER_PROFILE,
                            profileImgUrl   : live_info.USER_PROFILE_IMG
                        }
                    };

                    setRbmqInfoForSmartTV(user_info.USER_SEQ, user_info.USER_NICKNAME, rbmqServer_info, tempKey, cLive_info);

                    return {
                        ...state,

                        mqServerInfo        : rbmqServer_info,

                        isLoginYn           : "Y",
                        userSeq             : user_info.USER_SEQ,
                        userID              : user_info.USER_ID,
                        userNickname        : user_info.USER_NICKNAME,
                        userProfile         : user_info.USER_PROFILE,
                        profileImgUrl       : user_info.USER_PROFILE_IMG,
                        mySubDomainSeq      : user_info.SUB_DOMAIN_SEQ,
                        userLoginPageType   : enWebPage.SmartTVQRLogin,
                        userGateWay         : action.payload.gateWay ? action.payload.gateWay : "",
                        smartTVTempKey      : tempKey
                    }
                } else { // 로그인 시도를 여러번 하는 경우, 데이터 값 엉킴 문제 처리 부분
                    return {
                        ...state
                    }
                }
            } else {    // 로그인에 실패한 경우
                return {
                    ...state,    
                    isLoginAlertModalOpened : true,
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    loginMessage            : "유효하지 않은 접근입니다. [Code.US117.5]"
                }
            }
        } else {
            return {
                ...state,
                alertKind       : ConstData.ALERT_KIND.DANGER,
                loginMessage    : "오류가 발생하여 접속을 완료하지 못했습니다.<br>다시 시도해 주세요."
                                  + "<br>같은 현상이 반복될 경우<br>관리자에게 문의하시기 바랍니다. [Code.US118.3]"
            }
        }
    },

    [SMART_TV_GROUP_LOG_IN_SUCCESS]: (state, action) => {
        const { groupSeq, groupName, list_member } = action.parms;
        const { isSuccessed } = action.payload;
        // console.log("SMART_TV_GROUP_LOG_IN - ", action.payload);
        // console.log(action.parms);

        if (isSuccessed) {
            const { isLoginSuccess } = action.payload;

            if (isLoginSuccess) {   // 로그인에 성공한 경우
                const { rbmq_info, tempKey, subDomainSeq } = action.payload;

                if (state.isLoginYn === "N") {
                    const rbmqServer_info = {
                        exchangeName : rbmq_info.EXCH_NAME,
                        ip           : rbmq_info.SVER_IP, 
                        port         : rbmq_info.SVER_PORT, 
                        wsIp         : rbmq_info.WSVER_IP,
                        wsPort       : rbmq_info.WSVER_PORT,
                        wsPath       : rbmq_info.WSVER_PATH,
                        vHost        : rbmq_info.VRTL_HOST,
                        userPW       : rbmq_info.PSWD,
                        userID       : rbmq_info.USER_ID, 
                    };

                    const { live_info } = action.payload;

                    let cLive_info = {
                        liveSeq         : live_info.LIVE_SEQ,
                        liveName        : live_info.LIVE_NAME,
                        liveStatus      : "Y",
                        teacher_info    : {
                            userSeq         : live_info.LIVE_TEACHER_SEQ,
                            userNickname    : live_info.USER_NICKNAME,
                            userProfile     : live_info.USER_PROFILE,
                            profileImgUrl   : live_info.USER_PROFILE_IMG
                        }
                    };

                    setRbmqInfoForGroupSmartTV({ groupSeq, groupName, list_member }, rbmqServer_info, tempKey, cLive_info);

                    return {
                        ...state,

                        mqServerInfo        : rbmqServer_info,

                        isLoginYn           : "Y",
                        userSeq             : groupSeq,
                        // userID              : user_info.USER_ID,
                        userNickname        : groupName,
                        userProfile         : groupName,
                        profileImgUrl       : "1",
                        mySubDomainSeq      : subDomainSeq,
                        userLoginPageType   : enWebPage.GroupSmartTVQRLogin,
                        userGateWay         : action.payload.gateWay ? action.payload.gateWay : "",
                        smartTVTempKey      : tempKey
                    }
                } else { // 로그인 시도를 여러번 하는 경우, 데이터 값 엉킴 문제 처리 부분
                    return {
                        ...state
                    }
                }
            } else {    // 로그인에 실패한 경우
                return {
                    ...state,    
                    isLoginAlertModalOpened : true,
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    loginMessage            : "유효하지 않은 접근입니다. [Code.US117.5]"
                }
            }
        } else {
            return {
                ...state,
                alertKind       : ConstData.ALERT_KIND.DANGER,
                loginMessage    : "오류가 발생하여 접속을 완료하지 못했습니다.<br>다시 시도해 주세요."
                                  + "<br>같은 현상이 반복될 경우<br>관리자에게 문의하시기 바랍니다. [Code.US118.3]"
            }
        }
    },

    /**********************************************************/
    ////////////////////////////////////////////////////////////
    /**********************************************************/

    [SMART_TV_LOG_OUT]: (state, action) => {

        disconnectRbmqForSmartTV();

        return {
            ...state,
            isLoginYn               : "N",
            isGuestYn               : "N",
            userSeq                 : null,
            userID                  : "",
            passWD                  : "",
            passConfirm             : "",
            phoneNumber             : "",
            confirmNumber           : "",
            userNickname            : "",
            userEmail               : "",
            userProfile             : "",
            profileImgUrl           : "",
            userExtraCode           : "",
            userType                : "",
            mySubDomainSeq          : -1,
            usingPassInfo           : {
                serviceName         : "",
                passProductName     : ""
            },

            userLoginPageType       : enWebPage.Home,

            serverUserSeq           : -1,

            //login 및 사용자 인증 Modal 관련 상태 값
            loginMessage            : "",
            alertKind               : ConstData.ALERT_KIND.NONE,

            userGateWay             : "",
            userServiceType         : "",

            /** for rbmq 연결 */
            //isRbmqServerConnected   : false,

            login_info              : {
                userSeq             : -1,
                id                  : "",
                pw                  : "",
                nickname            : ""
            },

            isConfigAdmin           : false,
            isCreateAdmin           : false,

            isUsablePass            : false,

            isAutoLogin             : "N",

            //사용자 정보 관련
            isExistUserInfo         : false,

            //-------hiclass
            isRegistInfoOpened      : false,
            isUpdateInfoOpened      : false,
            isLogInOpened           : false,
            userInfoMessage         : "",
            userInfoAlertKind       : ConstData.ALERT_KIND.NONE,
            isIdCheckOK             : false,
            isPhoneTestStart        : false,
            isPhoneTestOK           : false,
            certiCodeSeq            : -1,

            //-------web
            web_userInfoMessage     : "",
            web_userInfoAlertKind   : ConstData.ALERT_KIND.NONE,
            web_isPhoneTestOK       : false,
            web_isPhoneTestStart    : false,
            web_certiCodeSeq        : -1,

            list_findUserId         : [],

            isEditInfoModalOpened   : false,
            isFindIdPasswordOpened  : false,
            findIdPasswordOption    : -1,
            findIdPasswordStep      : -1,
            findIdPasswordID        : "",

            isNeedCookiesInfoSave   : false,

            isPopoverOpened         : false,

            selectPageType          : null,

            myLoginMode             : ConstData.LOGIN_MODE.NONE,

            smartTVTempKey          : null
        }
    },

    // 로그인 창 토글
    [USER_LOGIN_TOGGLE] : (state) => {
        if (state.isLogInOpened === false) {
            if (!state.userSeq || state.userSeq < 0) {
                return{
                    ...state,
                    userID          : "",
                    userPW          : "",
                    isAutoLogin     : "N",
                    isLogInOpened   : !state.isLogInOpened,
                    alertKind       : ConstData.ALERT_KIND.NONE,
                    loginMessage    : "",
                }
            }
        } else {
            return {
                ...state,
                isLogInOpened       : !state.isLogInOpened,
            }
        }
    },

    // 프로필 Popover 토글
    [USER_INFO_POPOVER_TOGGLE]: (state, action) => {
        const { isOpened } = action.payload;
        
        return {
            ...state,
            isPopoverOpened : isOpened
        }
    },

    // 회원가입 & 회원정보 수정 창 토글
    [USER_INFO_TOGGLE]: (state, action) => {
        const { kind } = action.payload;
        //console.log("USER_INFO_TOGGLE ; ", state.isUpdateInfoOpened);

        if (kind === "regist") {
            if (state.isRegistInfoOpened === false) {
                return {
                    ...state,
                    userID                  : "",
                    passWD                  : "",
                    passConfirm             : "",
                    phoneNumber             : "",
                    confirmNumber           : "",
                    userNickname            : "",
                    userEmail               : "",
                    userProfile             : "",
                    profileImgUrl           : "",
                    isIdCheckOK             : false,
                    isPhoneTestOK           : false,
                    isPhoneTestStart        : false,
                    isFindIdPasswordOpened  : false,
                    isEditInfoModalOpened   : false,
                    isTermsAccept           : false,
                    isRegistInfoOpened      : !state.isRegistInfoOpened,
                    userInfoAlertKind       : ConstData.ALERT_KIND.NONE,
                    userInfoMessage         : ""
                }
            } else {
                return {
                    ...state,
                    userID                  : "",
                    passWD                  : "",
                    passConfirm             : "",
                    phoneNumber             : "",
                    confirmNumber           : "",
                    userNickname            : "",
                    userEmail               : "",
                    userProfile             : "",
                    profileImgUrl           : "",
                    isIdCheckOK             : false,
                    isPhoneTestOK           : false,
                    isPhoneTestStart        : false,
                    isTermsAccept           : false,
                    isRegistInfoOpened      : !state.isRegistInfoOpened,
                }
            }
        } else {
            if (state.isUpdateInfoOpened === false) {
                return {
                    ...state,
                    confirmNumber           : "",
                    isIdCheckOK             : false,
                    isPhoneTestOK           : false,
                    isPhoneTestStart        : false,
                    isTermsAccept           : false,
                    isUpdateInfoOpened      : !state.isUpdateInfoOpened,
                    isPopoverOpened         : !state.isPopoverOpened,
                    userInfoAlertKind       : ConstData.ALERT_KIND.NONE,
                    userInfoMessage         : ""
                }
            } else {
                return {
                    ...state,
                    confirmNumber           : "",
                    isIdCheckOK             : false,
                    isPhoneTestOK           : false,
                    isPhoneTestStart        : false,
                    isTermsAccept           : false,
                    isUpdateInfoOpened      : !state.isUpdateInfoOpened,
                    userInfoAlertKind       : ConstData.ALERT_KIND.NONE,
                    userInfoMessage         : ""
                }
            }
        }
    },

    // 쿠키 ?
    [SAVE_COOKIES_TOGGLE]: (state, action) => {
        return {
            ...state,
            isNeedCookiesInfoSave: false
        }
    },

    // 메시지 처리
    [SET_MESSAGE_BLANK] : (state) => {
        return {
            ...state,
            alertKind       : ConstData.ALERT_KIND.NONE,
            loginMessage    : "",
            userInfoAlertKind : ConstData.ALERT_KIND.NONE,
            userInfoMessage : ""
        }
    },

    [SET_LOGIN_MESSAGE]: (state, action) => {
        if (action.kind === "danger") {
            return {
                ...state,
                alertKind       : ConstData.ALERT_KIND.DANGER,
                loginMessage    : action.message
            }
        } else if (action.kind === "success") {
            return {
                ...state,
                alertKind       : ConstData.ALERT_KIND.SUCCESS,
                loginMessage    : action.message
            }
        } else if (action.kind === "none") {
            return{
                ...state,
                alertKind       : ConstData.ALERT_KIND.NONE,
                loginMessage    : action.message
            }
        }
    },

    [SET_ALERT_MESSAGE]: (state, action) => {
        if (action.message === "idCheck") {
            return {
                ...state,
                isIdCheckOK         : false,
                userInfoMessage     : "",
                userInfoAlertKind   : ConstData.ALERT_KIND.NONE
            }
        } else if (action.message === "phoneCertification") {
            return {
                ...state,
                isPhoneTestStart    : false,
                isPhoneTestOK       : false,
                userInfoAlertKind   : ConstData.ALERT_KIND.NONE,
                userInfoMessage     : ""
            }
        } else {
            if (action.message !== "") {
                return {
                    ...state,
                    userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                    userInfoMessage     : action.message
                }
            } else {
                return {
                    ...state,
                    userInfoAlertKind   : ConstData.ALERT_KIND.NONE,
                    userInfoMessage     : ""
                }
            }
        }
    },

    // id check
    [ID_CHECK_SUCCESS]: (state, action) => {
        const { isExist } = action.payload;
        const { userID } = action.parms;

        if (isExist) {
            return {
                ...state,
                userID              : userID,
                isIdCheckOK         : false,
                userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                userInfoMessage     : "이미 존재하는 아이디입니다."
            }
        } else {
            return {
                ...state,
                userID              : userID,
                isIdCheckOK         : true,
                userInfoAlertKind   : ConstData.ALERT_KIND.SUCCESS,
                userInfoMessage     : "사용 가능한 아이디입니다."
            }
        }
    },

    [CHANGE_ALERT_MESSAGE]: (state, action) => {
        const { kind, message } = action;
        //console.log("CHANGE_ALERT_MESSAGE - kind : ", kind);
        //console.log("CHANGE_ALERT_MESSAGE - message : ", message);

        if (kind === "idCheck") {
            return {
                ...state,
                isIdCheckOK         : false,
                userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                userInfoMessage     : message
            }
        } else if (kind === "phoneCertification") {
            return {
                ...state,
                isPhoneTestOK       : false,
                isPhoneTestStart    : false,
                userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                userInfoMessage     : message
            }
        } else if (kind === "emailCertification") {
            return {
                ...state,
                isEmailTestOK       : false,
                isEmailTestStart    : false,
                userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                userInfoMessage     : message
            }
        } else if (kind === "phoneChange") {
            return {
                ...state,
                isPhoneTestOK       : false,
                isPhoneTestStart    : false,
                userInfoAlertKind   : ConstData.ALERT_KIND.NONE,
                userInfoMessage     : ""
            }
        } else if (kind === "emailChange") {
            return {
                ...state,
                isEmailTestOK       : false,
                //isEmailTestStart  : false, email 인증 기능이 추가되면 주석해제하기 by hjkim 20230130
                userInfoAlertKind   : ConstData.ALERT_KIND.NONE,
                userInfoMessage     : ""
            }
        } else {
            console.log("CHANGE_ALERT_MESSAGE - 이런 경우가 있나요.... kind => ", kind);

            return {
                ...state
            }
        }
    },

    [FALSE_TO_PHONE_CHECK_SEND]: (state, action) => {
        const { message } = action.payload;

        return {
            ...state,
            isPhoneTestStart        : false,
            userInfoAlertKind       : ConstData.ALERT_KIND.DANGER,
            userInfoMessage         : message
        }
    },

    [FALSE_TO_PHONE_CHECK_OK]: (state, action) => {
        const { message } = action.payload;

        return {
            ...state,
            isPhoneTestOK           : false,
            userInfoAlertKind       : ConstData.ALERT_KIND.DANGER,
            userInfoMessage         : message,
        }
    },

    [FALSE_TO_EMAIL_CHECK_SEND]: (state, action) => {
        const { message } = action.payload;

        return {
            ...state,
            isEmailTestStart        : false,
            userInfoAlertKind       : ConstData.ALERT_KIND.DANGER,
            userInfoMessage         : message,
        }
    },

    [FALSE_TO_EMAIL_CHECK_OK]: (state, action) => {
        const { message } = action.payload;

        return {
            ...state,
            isEmailTestOK           : false,
            userInfoAlertKind       : ConstData.ALERT_KIND.DANGER,
            userInfoMessage         : message,
        }
    },

    [FALSE_TO_RETRY_LOGIN]: (state, action) => {
        return {
            ...state,
            isNeedRetryingLogin : false
        }
    },

    [FALSE_TO_REMOVE_COOKIES]: (state, action) => {
        console.log("FALSE_TO_REMOVE_COOKIES");

        return {
            ...state,
            isNeedCookiesInfoRemove : false
        }
    },

    // phone certification
    [PHONE_CHECK_SEND_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        const { isFindIdPassword } = action.parms;

        if (isSuccessed) {
            const { isSendSuccess } = action.payload;

            if (isSendSuccess) {
                const { certiSEQ } = action.payload;
                const { certi_code } = action.payload;      // test 용,,, 나중에 지우기

                //console.log("certiSeq : ", certiSEQ);
                //console.log("certi_code : ", certi_code);   // test 용,,, 나중에 지우기

                if (isFindIdPassword) {
                    return {
                        ...state,
                        certiCodeSeq        : certiSEQ,
                        isPhoneTestStart    : true,
                        userInfoAlertKind   : ConstData.ALERT_KIND.SUCCESS,
                        userInfoMessage     : "인증번호를 발송했습니다. 2분 내에 인증하세요."
                    }
                } else {
                    return {
                        ...state,
                        certiCodeSeq        : certiSEQ, 
                        isPhoneTestStart    : true,
                        userInfoAlertKind   : ConstData.ALERT_KIND.SUCCESS,
                        userInfoMessage     : "인증번호를 발송했습니다. 2분 내에 인증하세요."
                    }
                }                
            } else {
                const { isCreateSuccess } = action.payload;

                if (isFindIdPassword) {
                    return {
                        ...state,
                        certiCodeSeq            : -1,
                        isPhoneTestStart        : false,
                        userInfoAlertKind       : ConstData.ALERT_KIND.DANGER,
                        userInfoMessage         : "인증번호 전송 중 오류가 발생했습니다. [Code.US163.7]"
                    }
                } else {
                    if (isCreateSuccess) {
                        return {
                            ...state,
                            certiCodeSeq        : -1,
                            isPhoneTestStart    : false,
                            userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                            userInfoMessage     : "인증번호 전송 중 오류가 발생했습니다. [Code.US164.6]"
                        }
                    } else {
                        return {
                            ...state,
                            certiCodeSeq        : -1,
                            isPhoneTestStart    : false,
                            userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                            userInfoMessage     : "인증번호 생성 중 오류가 발생했습니다. [Code.US165.4]"
                        }
                    }
                }
            }
        } else {
            return {
                ...state,
                certiCodeSeq        : -1,
                isPhoneTestStart    : false,
                userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                userInfoMessage     : "인증번호 생성 중 오류가 발생했습니다. [Code.US166.5]"
            }
        }
    },

    [PHONE_CHECK_CONFIRM_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        const { isFindIdPassword } = action.parms;

        if (isSuccessed) {
            const { isCertifySuccess } = action.payload;

            if (isCertifySuccess) {
                if (isFindIdPassword) {
                    return {
                        ...state,
                        certiCodeSeq        : -1,
                        isPhoneTestOK       : true,
                        userInfoAlertKind   : ConstData.ALERT_KIND.SUCCESS,
                        userInfoMessage     : "휴대폰 번호 인증에 성공했습니다."
                    }
                } else {
                    return {
                        ...state,
                        certiCodeSeq        : -1,
                        isPhoneTestOK       : true,
                        isPhoneTestStart    : false,
                        userInfoAlertKind   : ConstData.ALERT_KIND.SUCCESS,
                        userInfoMessage     : "휴대폰 번호 인증에 성공했습니다."
                    }
                }                
            } else {
                if (isFindIdPassword) {
                    return {
                        ...state,
                        isPhoneTestOK       : false,
                        userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                        userInfoMessage     : "인증번호가 일치하지 않습니다. [Code.US170.2]"
                    }
                } else {
                    //console.log("회원가입용");
                    
                    return {
                        ...state,
                        isPhoneTestOK       : false,
                        userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                        userInfoMessage     : "인증번호가 일치하지 않습니다. [Code.US171.1]"
                    }
                }
            }
        } else {
            return {
                ...state,
                isPhoneTestOK       : false,
                userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                userInfoMessage     : "인증번호 확인 중 오류가 발생했습니다. [Code.US172.0]"
            }
        }
    },

    [EMAIL_CHECK_SEND_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        const { isFindIdPassword } = action.parms;

        if (isSuccessed) {
            const { isSendSuccess } = action.payload;

            if (isSendSuccess) {
                const { certiSEQ } = action.payload;
                const { certi_code } = action.payload;      // test 용,,, 나중에 지우기

                //console.log("certiSeq : ", certiSEQ);
                //console.log("certi_code : ", certi_code);   // test 용,,, 나중에 지우기

                if (isFindIdPassword) {
                    return {
                        ...state,
                        emailCertiCodeSeq   : certiSEQ,
                        isEmailTestStart    : true,
                        userInfoAlertKind   : ConstData.ALERT_KIND.SUCCESS,
                        userInfoMessage     : "인증번호를 발송했습니다. 2분 내에 인증하세요."
                    }
                } else {
                    return {
                        ...state,
                        emailCertiCodeSeq   : certiSEQ,
                        isEmailTestStart    : true,
                        userInfoAlertKind   : ConstData.ALERT_KIND.SUCCESS,
                        userInfoMessage     : "인증번호를 발송했습니다. 2분 내에 인증하세요."
                    }
                }                
            } else {
                const { isCreateSuccess } = action.payload;

                if (isFindIdPassword) {
                    return {
                        ...state,
                        emailCertiCodeSeq       : -1,
                        isEmailTestStart        : false,
                        userInfoAlertKind       : ConstData.ALERT_KIND.DANGER,
                        userInfoMessage         : "인증번호 전송 중 오류가 발생했습니다. [Code.US176.5]"
                    }
                } else {
                    if (isCreateSuccess) {
                        return {
                            ...state,
                            emailCertiCodeSeq   : -1,
                            isEmailTestStart    : false,
                            userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                            userInfoMessage     : "인증번호 전송 중 오류가 발생했습니다. [Code.US177.4]"
                        }
                    } else {
                        return {
                            ...state,
                            emailCertiCodeSeq   : -1,
                            isEmailTestStart    : false,
                            userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                            userInfoMessage     : "인증번호 생성 중 오류가 발생했습니다. [Code.US178.2]"
                        }
                    }
                }
            }
        } else {
            return {
                ...state,
                emailCertiCodeSeq   : -1,
                isEmailTestStart    : false,
                userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                userInfoMessage     : "인증번호 생성 중 오류가 발생했습니다. [Code.US179.3]"
            }
        }
    },

    [EMAIL_CHECK_CONFIRM_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        const { isFindIdPassword } = action.parms;
        //console.log("userID : ", state.findIdPasswordID);

        if (isSuccessed) {
            const { isCertifySuccess } = action.payload;

            if (isCertifySuccess) {
                if (isFindIdPassword) {
                    return {
                        ...state,
                        emailCertiCodeSeq   : -1,
                        isEmailTestOK       : true,
                        userInfoAlertKind   : ConstData.ALERT_KIND.SUCCESS,
                        userInfoMessage     : "이메일 주소 인증을 완료하였습니다."
                    }
                } else {
                    return {
                        ...state,
                        emailCertiCodeSeq   : -1,
                        isEmailTestOK       : true,
                        userInfoAlertKind   : ConstData.ALERT_KIND.SUCCESS,
                        userInfoMessage     : "이메일 주소 인증을 완료하였습니다."
                    }
                }                
            } else {
                if (isFindIdPassword) {
                    return {
                        ...state,
                        isEmailTestOK       : false,
                        userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                        userInfoMessage     : "인증번호가 일치하지 않습니다. [Code.US183.0]"
                    }
                } else {
                    return {
                        ...state,
                        isEmailTestOK       : false,
                        userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                        userInfoMessage     : "인증번호가 일치하지 않습니다. [Code.US183.7]"
                    }
                }
            }
        } else {
            return {
                ...state,
                isEmailTestOK       : false,
                userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                userInfoMessage     : "인증번호 확인 중 오류가 발생했습니다. [Code.US184.6]"
            }
        }
    },

    [USER_TERMS_TOGGLE]: (state, action) => {
        return {
            ...state,
            isTermsOpened : !state.isTermsOpened
        }
    },

    [USER_TERMS_CHECK]: (state, action) => {
        const { isAccept } = action.payload;
        //console.log("USER_TERMS_CHECK - ", action.payload);

        if (isAccept === true) {

        } else {

        }
    },

    // member regist
    [USER_REGIST_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //const { userID, userNickname, userProfile } = action.parms;

        if (isSuccessed) {
            const { userSEQ } = action.payload;

            if (userSEQ > 0) {
                return {
                    ...state,
                    userID              : "",
                    passWD              : "",
                    passConfirm         : "",
                    phoneNumber         : "",
                    confirmNumber       : "",
                    userNickname        : "",
                    userEmail           : "",
                    userExtraCode       : "",
                    userProfile         : "",
                    profileImgUrl       : "",
                    userType            : "",
                    isIdCheckOK         : false,
                    isPhoneTestOK       : false,
                    isPhoneTestStart    : false,
                    certiCodeSeq        : -1,
                    isRegistInfoOpened  : false,
                    alertKind           : ConstData.ALERT_KIND.SUCCESS,
                    loginMessage        : "환영합니다! 회원 가입을 완료하였습니다.",
                    isTermsOpened       : false,
                    isTermsAccept       : false,
                }
            } else {
                return {
                    ...state,
                    alertKind           : ConstData.ALERT_KIND.DANGER,
                    loginMessage        : "일시적인 오류로 회원 가입을 완료하지 못했습니다.<br>다시 시도해 주세요. [Code.US190.5]"
                }
            }
        } else {
            console.log("isSuccessed is ", isSuccessed);

            return {
                ...state,
                alertKind           : ConstData.ALERT_KIND.DANGER,
                loginMessage        : "오류가 발생하여 회원 가입을 완료하지 못했습니다.<br>입력한 정보를 확인한 후 다시 시도해 주세요. [Code.US191.4]"
            }
        }
    },

    // member info modify
    [USER_UPDATE_SUCCESS]: (state, action) => {
        const { userNickname, userEmail, phoneNumber, userExtraCode, userProfile, profileImgUrl/* , password */ } = action.parms;
        const { isSuccessed, isModifySuccess, isChangePWSuccess } = action.payload;

        if (isSuccessed) {
            if (isModifySuccess) {
                if (isChangePWSuccess) {
                    console.log("비밀번호 변경 완료");
                }

                let nickname = userNickname !== undefined && userNickname !== null ? userNickname : state.userNickname;
                let email = userEmail !== undefined && userEmail !== null ? userEmail : state.userEmail;
                let profile = userProfile !== undefined && userProfile !== null ? userProfile : state.userProfile;
                let profileImg = profileImgUrl !== undefined && profileImgUrl !== null ? profileImgUrl : state.profileImgUrl;
                let pn = phoneNumber !== undefined && phoneNumber !== null ? phoneNumber : state.phoneNumber;
                let extraCode = userExtraCode !== undefined && userExtraCode !== null ? userExtraCode : state.userExtraCode;

                return {
                    ...state,
                    loginStatus             : ConstData.LOGIN_STATUS.LOG_IN,
                    userNickname            : nickname,
                    userEmail               : email,
                    phoneNumber             : pn,
                    confirmNumber           : "",
                    userProfile             : profile,
                    profileImgUrl           : profileImg,
                    userExtraCode           : extraCode,
                    isLogInOpened           : false,
                    isUpdateInfoOpened      : false,
                    loginMessage            : "",
                    userInfoMessage         : "",
                    isIdCheckOK             : false,
                    isPhoneTestStart        : false,
                    isPhoneTestOK           : false,
                    userInfoAlertKind       : ConstData.ALERT_KIND.NONE,
                    isEditInfoModalOpened   : false
                }
            } else {
                console.log("이런 경우도 있나요..?");

                return {
                    ...state,
                    userInfoAlertKind       : ConstData.ALERT_KIND.DANGER,
                    userInfoMessage         : "일시적인 오류로 회원정보를 수정하지 못했습니다.<br>다시 시도해 주세요. [Code.US196.3]"
                }
            }
        } else { // 회원정보 수정 sql 실행에 실패한 경우.. 어떻게 처리해야하나..?ㅁ?...
            const { errMsg } = action.parms;
            console.log("USER_UPDATE_FAIL");
            console.log(errMsg);

            return {
                ...state,
                userInfoAlertKind       : ConstData.ALERT_KIND.DANGER,
                userInfoMessage         : "오류가 발생하여 회원정보를 수정하지 못했습니다.<br>다시 시도해 주세요. [Code.US197.4]"
            }
        }
    },

    [GET_ID_LIST_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("GET_ID_LIST_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { isExist } = action.payload;

            if (isExist) {
                const { list_userID } = action.payload;

                return {
                    ...state,
                    isExistUserInfo     : true,
                    findIdPasswordStep  : ConstData.FIND_STEP.SHOW_ID,
                    isPhoneTestStart    : false,
                    isPhoneTestOK       : false,
                    certiCodeSeq        : -1,
                    userInfoAlertKind   : ConstData.ALERT_KIND.NONE,
                    userInfoMessage     : "",
                    list_findUserId     : list_userID
                }                
            } else {
                return {
                    ...state,
                    isExistUserInfo     : false,
                    findIdPasswordStep  : ConstData.FIND_STEP.SHOW_ID,
                    isPhoneTestStart    : false,
                    isPhoneTestOK       : false,
                    certiCodeSeq        : -1,
                    userInfoAlertKind   : ConstData.ALERT_KIND.NONE,
                    userInfoMessage     : "",
                    list_findUserId     : []
                }
            }
        } else {
            const { errMsg } = action.parms;
            console.log("GET_ID_LIST_FAIL");
            console.log(errMsg);

            return {
                ...state,
                userInfoAlertKind       : ConstData.ALERT_KIND.DANGER,
                userInfoMessage         : "오류가 발생하여 회원 목록을 가져오지 못했습니다.<br>다시 시도해 주세요. [Code.US202.1]"
            }
        }
    },

    [CHECK_ID_EXIST_SUCCESS]: (state, action) => {
        const { userID } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("CHECK_ID_EXIST_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { isExist } = action.payload;

            if (isExist) {
                return {
                    ...state,
                    isExistUserInfo     : true,
                    findIdPasswordID    : userID,
                    findIdPasswordStep  : ConstData.FIND_STEP.CERTIFY_PHONE
                }
            } else {
                return {
                    ...state,
                    isExistUserInfo     : false,
                    findIdPasswordID    : "",
                    findIdPasswordStep  : ConstData.FIND_STEP.CERTIFY_PHONE
                }
            }
        } else {
            console.log("CHECK_ID_EXIST_FAIL - ", action.payload);

            return {
                ...state,
                userInfoAlertKind       : ConstData.ALERT_KIND.DANGER,
                userInfoMessage         : "오류가 발생하여 요청하신 작업을 완료하지 못했습니다.<br>다시 시도해 주세요. [Code.US205.5]"
            }
        }
    },

    [COMPARE_INFO_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("COMPARE_INFO_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { isExist } = action.payload;

            if (isExist) { // 존재
                return {
                    ...state,
                    isExistUserInfo     : true,
                    findIdPasswordStep  : ConstData.FIND_STEP.RESET_PW,
                }
            } else { // 없음
                return {
                    ...state,
                    isExistUserInfo     : false,
                    findIdPasswordStep  : ConstData.FIND_STEP.RESET_PW,
                    findIdPasswordID    : "",
                    isPhoneTestStart    : false,
                    isPhoneTestOK       : false,
                    certiCodeSeq        : -1,
                }
            }
        } else {
            console.log("COMPARE_INFO_FAIL - ", action.payload);

            return {
                ...state,
                userInfoAlertKind       : ConstData.ALERT_KIND.DANGER,
                userInfoMessage         : "오류가 발생하여 요청하신 작업을 완료하지 못했습니다.<br>다시 시도해 주세요. [Code.US209.0]"
            }
        }
    },

    [RESET_PW_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("RESET_PW_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { isResetSuccess } = action.payload;

            if (isResetSuccess) {
                return {
                    ...state,
                    isExistUserInfo     : true,
                    findIdPasswordStep  : ConstData.FIND_STEP.END,
                    isPhoneTestOK       : false,
                    isPhoneTestStart    : false,
                    certiCodeSeq        : -1,
                }
            } else {
                return {
                    ...state,
                    isExistUserInfo     : false,
                    isPhoneTestOK       : false,
                    isPhoneTestStart    : false,
                    certiCodeSeq        : -1,
                }
            }
        } else {
            console.log("RESET_PW_FAIL - ", action.payload);

            return {
                ...state,
                userInfoAlertKind       : ConstData.ALERT_KIND.DANGER,
                userInfoMessage         : "오류가 발생하여 요청하신 작업을 완료하지 못했습니다.<br>다시 시도해 주세요. [Code.US212.6]"
            }
        }
        
    },

    [CHANGE_SELECT_PAGE_TYPE]: (state, action) => {
        const { selectedPageType } = action.payload;
        //console.log("CHANGE_SELECT_PAGE_TYPE - ", action.payload);

        return {
            ...state,
            selectPageType              : selectedPageType
        }
    },

    [CHANGE_SELECT_FIND_ID_PASSWORD_OPTION]: (state, action) => {
        const { option } = action.payload;
        //console.log("CHANGE_SELECT_FIND_ID_PASSWORD_OPTION - ", action.payload);

        return {
            ...state,
            isExistUserInfo             : false,
            findIdPasswordID            : "",
            findIdPasswordOption        : option,
            findIdPasswordStep          : option === ConstData.FIND_OPTION.ID ? ConstData.FIND_STEP.CERTIFY_PHONE : ConstData.FIND_STEP.CHECK_ID,
            isPhoneTestStart            : false,
            isPhoneTestOK               : false,
            certiCodeSeq                : -1,
            userInfoAlertKind           : ConstData.ALERT_KIND.NONE,
            userInfoMessage             : "",
        }
    },

    [CHANGE_SELECT_FIND_ID_PASSWORD_STEP]: (state, action) => {
        const { step } = action.payload;
        //console.log("CHANGE_SELECT_FIND_ID_PASSWORD_STEP - ", action.payload);

        if (step === ConstData.FIND_STEP.START) {
            return {
                ...state,
                findIdPasswordOption    : ConstData.FIND_OPTION.START,
                findIdPasswordStep      : ConstData.FIND_STEP.START,
                isPhoneTestStart        : false,
                isPhoneTestOK           : false,
                certiCodeSeq            : -1,
                userInfoAlertKind       : ConstData.ALERT_KIND.NONE,
                userInfoMessage         : "",
            }
        } else {
            return {
                ...state,
                findIdPasswordStep      : step,
                isPhoneTestStart        : false,
                isPhoneTestOK           : false,
                certiCodeSeq            : -1,
                userInfoAlertKind       : ConstData.ALERT_KIND.NONE,
                userInfoMessage         : "",
            }
        }
    },

    // Toggle
    [TOGGLE_EDIT_INFO_MODAL]: (state, action) => {
        return {
            ...state,
            isPhoneTestStart            : false,
            isPhoneTestOK               : false,
            certiCodeSeq                : -1,
            isEditInfoModalOpened       : !state.isEditInfoModalOpened
        }
    },

    [TOGGLE_FIND_ID_PASSWORD_MODAL]: (state, action) => {
        return {
            ...state,
            isExistUserInfo             : !state.isExistUserInfo,
            isPhoneTestStart            : false,
            isPhoneTestOK               : false,
            certiCodeSeq                : -1,
            isFindIdPasswordOpened      : !state.isFindIdPasswordOpened,
            isLogInOpened               : !state.isLogInOpened,
            findIdPasswordOption        : ConstData.FIND_OPTION.START,
            findIdPasswordStep          : ConstData.FIND_STEP.START,
            findIdPasswordID            : "",
        }
    },

    [TOGGLE_LOGIN_ALERT_MODAL]: (state, action) => {
        const { isModalOpened } = action.payload;
        console.log("TOGGLE_LOGIN_ALERT_MODAL - ", action.payload);

        if (isModalOpened) {
            return {
                ...state,
                isLoginAlertModalOpened : true,
                alertKind               : ConstData.ALERT_KIND.DANGER,
                loginMessage            : "유효하지 않은 접근입니다. [Code.US222.3]"
            }
        } else {
            return {
                ...state,
                isLoginAlertModalOpened : false,
                alertKind               : ConstData.ALERT_KIND.NONE,
                loginMessage            : ""
            }
        }
    },

    [TOGGLE_FORCE_LOGOUT_ALERT_MODAL]: (state, action) => {
        return {
            ...state,
            isForceLogoutModalOpened    : !state.isForceLogoutModalOpened,
        }
    },

    [TOGGLE_LOGOUT_ALERT_MODAL]: (state, action) => {
        return {
            ...state,
            isLogoutAlertModalOpened    : !state.isLogoutAlertModalOpened
        }
    },

    /** mixed class */
    [UPDATE_SERVER_USER_INFO]: (state, action) => {
        const { userSeq, localServerSeq } = action.payload;

        return {
            ...state,
            serverUserSeq       : userSeq,
            localServerSeq      : localServerSeq
        }
    },

    [REQUEST_SERVER_USER_AUTH]: (state, action) => {
        const { localUserSeq, serverUserID, serverUserPW, isKeepSaving } = action.payload;
        console.log("REQUEST_SERVER_USER_AUTH - ", action.payload);

        if (localUserSeq >= 0 && serverUserID && serverUserID !== "" && serverUserPW && serverUserPW !== "" && isKeepSaving !== undefined) {
            callEoForRequestServerUserAuth(localUserSeq, serverUserID, serverUserPW, isKeepSaving);
        }

        return {
            ...state
        }
    },

    [UPDATE_SERVER_USER_AUTH_RESULT]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("UPDATE_SERVER_USER_AUTH_RESULT - ", action.payload);

        if (isSuccessed) {
            const { isExist } = action.payload;

            if (isExist) {
                const { serverUserSeq, localServerSeq } = action.payload;

                return {
                    ...state,
                    serverUserSeq       : serverUserSeq,
                    localServerSeq      : localServerSeq,
                    userInfoAlertKind   : ConstData.ALERT_KIND.SUCCESS,
                    userInfoMessage     : "서버 사용자 인증에 성공하였습니다."
                }
            } else {
                return {
                    ...state,
                    serverUserSeq       : -1,
                    localServerSeq      : -1,
                    userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                    userInfoMessage     : "아이디 혹은 비밀번호가 일치하지 않습니다.<br>입력한 내용을 다시 확인하신 후 재시도해 주세요. [Code.US229.6]"
                }
            }
        } else {
            return {
                ...state,
                serverUserSeq       : -1,
                localServerSeq      : -1,
                userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                userInfoMessage     : "서버 사용자 인증 요청에 실패하였습니다.<br>입력한 내용을 다시 확인하신 후 재시도해 주세요."
                                     + "<br>같은 현상이 반복될 경우<br>서버 관리자에게 문의하시기 바랍니다. [Code.US230.6]"
            }
        }
    },

    [SAVE_SERVER_USER_AUTH_INFO_SUCCESS]: (state, action) => {
        const { serverUserSeq, localServerSeq } = action.parms;
        const { isSuccessed } = action.payload;
        console.log(`SAVE_SERVER_USER_AUTH_INFO - isSuccessed[${isSuccessed}]`);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                return {
                    ...state,
                    serverUserSeq       : serverUserSeq,
                    localServerSeq      : localServerSeq,
                    userInfoAlertKind   : ConstData.ALERT_KIND.SUCCESS,
                    userInfoMessage     : "서버 사용자 인증에 성공하였습니다.<br>서버 사용자 인증 정보가 저장되었습니다."
                }
            } else {
                return {
                    ...state,
                    serverUserSeq       : serverUserSeq,
                    localServerSeq      : localServerSeq,
                    userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                    userInfoMessage     : "서버 사용자 인증 정보 저장에 실패하였습니다.<br>입력한 내용을 다시 확인하신 후 재시도해 주세요." 
                                         + "<br>같은 현상이 반복될 경우<br>서버 관리자에게 문의하시기 바랍니다. [Code.US233.4]"
                }
            }
        } else {
            return {
                ...state,
                serverUserSeq       : -1,
                localServerSeq      : -1,
                userInfoAlertKind   : ConstData.ALERT_KIND.DANGER,
                userInfoMessage     : "서버 사용자 인증 요청에 실패하였습니다.<br>입력한 내용을 다시 확인하신 후 재시도해 주세요."
                                     + "<br>같은 현상이 반복될 경우<br>서버 관리자에게 문의하시기 바랍니다. [Code.US234.4]"
            }
        }
    },

    // RBMQ status
    [RBMQ_SERVER_CONNECTED]: (state, action) => {
        // console.log("RBMQ_SERVER_CONNECTED");
        return {
            ...state,
            isRbmqServerConnected : true
        }
    },

    [RBMQ_SERVER_DISCONNECTED]: (state, action) => {
        // console.log("RBMQ_SERVER_DISCONNECTED");
        return {
            ...state,
            isRbmqServerConnected : false
        }
    },

    [RBMQ_SERVER_ERROR]: (state, action) => {
        const { message } = action.payload;
        console.log("RBMQ_SERVER_ERROR - ", message);
        return {
            ...state,
            isRbmqServerConnected : false
        }
    },

    // MQ message 
    [MQ_UPDATE_INFO]: (state, action) => {
        const { update_info } = action.payload;
        const { userSeq, userNickname, userProfile, profileImgUrl } = update_info;

        return produce (state, draft => {
            if (userSeq === draft.userSeq) {
                draft.userNickname = userNickname !== null ? userNickname : draft.userNickname;
                draft.userProfile = userProfile !== null ? userProfile : draft.userProfile;
                draft.profileImgUrl = profileImgUrl !== null ? profileImgUrl : draft.profileImgUrl;
            }
        });
    },
}, initialState);

export default user;