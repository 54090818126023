import React, { Component } from "react";
import { connect } from "react-redux";

import ConstData from "../lib/ConstData";
import { enBrowserType, enWebPage } from "../lib/ConstCommand";

import WebHeaderTitleContainer from "./etc/WebHeaderTitleContainer";
import UserLogInContainer from "./user/UserLoginContainer";

import { saveCookiesToggle, autoLogIn, logIn, logInByQRCode, forceLogOut, toggle_forceLogoutAlertModal, toggle_logoutAlertModal, falseToRemoveCookies } from "../modules/user";
import { changeWindowPentalkBoard, changeShowMenuKind, getVersionInfo } from "../modules/hiclasstv";
import { clearPerformLiveInfo } from "../modules/live";
import { checkSubDomain } from "../modules/admin";

import ForceLogoutAlertModal from "../components/user/modal/ForceLogoutAlertModal";
import LogoutAlertModal from "../components/user/modal/LogoutAlertModal";

const isMobile = (navigator.userAgent.toLowerCase().indexOf("android") > -1 || navigator.userAgent.toLowerCase().indexOf("iphone") > -1 || (navigator.userAgent.toLowerCase().indexOf("macintosh") > -1 && navigator.maxTouchPoints > 2));

class MainContainer extends Component {
    componentDidMount() {
        if (this.props.browserType === enBrowserType.Web) {
            if (!this.props.isCheckedSubDomain && !this.props.isCorrectSubDomain) {
                console.log("MainContainer DidMount() - have to request checkSubDomain");
                this.props.checkSubDomain({ subDomainName: this.props.userKind });
            } else {
                // console.log("MainContainer DidMount() - already checked.");
            }
        } else {
            if (!this.props.isCheckedVersion) {
                this.props.getVersionInfo();
            }
        }

        // console.log(`componentDidMount - this.props.isNeedCookiesInfoRemove[${this.props.isNeedCookiesInfoRemove}]`);
        if (this.props.isNeedCookiesInfoRemove) {
            console.log("have to remove cookeis - live info cookies");
            this.onHandleCookies("delete", "liveSeq", this.props.cookies.get("liveSeq"));
            this.onHandleCookies("delete", "userID", this.props.cookies.get("userID"));
            this.onHandleCookies("delete", "passWD", this.props.cookies.get("passWD"));
            this.onHandleCookies("delete", "userSeq", this.props.cookies.get("userSeq"));
            this.onHandleCookies("delete", "userNickname", this.props.cookies.get("userNickname"));
            this.onHandleCookies("delete", "viewKind", this.props.cookies.get("viewKind"));

            this.props.falseToRemoveCookies();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isCheckedSubDomain !== this.props.isCheckedSubDomain && this.props.isCheckedSubDomain) {
            if (!this.props.isCorrectSubDomain) {
                if (ConstData.IS_LOCAL_VERSION) { // local version 은 어쩔 수 없이 예외 ... by hjkim 20230404
                    // console.log("MainContainer DidUpdate() - not correct local domain ... request main host.");
                    window.location = ConstData.MAIN_HOST_ADD;
                } else {
                    // console.log("MainContainer DidUpdate() - not correct sub domain ... request cloud host.");
                    window.location = ConstData.CLOUD_HOST_ADD;
                }
            } else {
                // console.log("MainContainer DidUpdate() - correct sub domain ...");
            }
        }

        // console.log(`componentDidUpdate - prevProps.isNeedCookiesInfoRemove[${prevProps.isNeedCookiesInfoRemove}], this.props.isNeedCookiesInfoRemove[${this.props.isNeedCookiesInfoRemove}]`);
        if (prevProps.isNeedCookiesInfoRemove !== this.props.isNeedCookiesInfoRemove && this.props.isNeedCookiesInfoRemove) {
            console.log("have to remove cookeis - live info cookies");
            this.onHandleCookies("delete", "liveSeq", this.props.cookies.get("liveSeq"));
            this.onHandleCookies("delete", "userID", this.props.cookies.get("userID"));
            this.onHandleCookies("delete", "passWD", this.props.cookies.get("passWD"));
            this.onHandleCookies("delete", "userSeq", this.props.cookies.get("userSeq"));
            this.onHandleCookies("delete", "userNickname", this.props.cookies.get("userNickname"));
            this.onHandleCookies("delete", "viewKind", this.props.cookies.get("viewKind"));

            this.props.falseToRemoveCookies();
        }

        if (prevProps.isNeedRetryingLogin !== this.props.isNeedRetryingLogin && this.props.isNeedRetryingLogin) {
            const { login_info } = this.props;
            console.log("have to retrying login - login_info => ", login_info);

            if (login_info.tokenKey) {
                this.props.autoLogIn({
                    loginInfo: {
                        tokenKey: login_info.tokenKey,
                        userNickname: login_info.nickname,
                        lectureCode: login_info.lectureCode
                    },
                    tokenKey: login_info.tokenKey,
                    lectureCode: login_info.lectureCode,
                    userKind: this.props.userKind,
                    mode: "web",
                    kind: "auto"
                });
            } else {
                if (login_info.id && login_info.pw) {
                    /* let timerProc = setTimeout(() => {
                        console.log("UserLoginContainer(timerProc) - logIn => ", login_info);
                        this.props.logIn({
                            loginInfo: {
                                userID: login_info.id,
                                password: login_info.pw,
                                userNickname: login_info.nickname,
                                isAutoLogin: false
                            },
                            userKind: this.props.userKind,
                            mode: this.props.browserType === enBrowserType.Web ? "web" : "hiclass"
                        });
                    }, 100); */
                    console.log("UserLoginContainer - logIn => ", login_info);
                    this.props.logIn({
                        loginInfo: {
                            userID: login_info.id,
                            password: login_info.pw,
                            userNickname: login_info.nickname,
                            isAutoLogin: false
                        },
                        userKind: this.props.userKind,
                        mode: this.props.browserType === enBrowserType.Web ? "web" : "hiclass"
                    });
                } else {
                    if (login_info.userSeq >= 0) {
                        console.log("UserLoginContainer - logInByQRCode => ", login_info.userSeq);
                        let timerProc = setTimeout(() => {
                            console.log("UserLoginContainer(timerProc) - logInByQRCode => ", login_info);
                            this.props.logInByQRCode({
                                loginInfo: {
                                    userSeq: login_info.userSeq,
                                    userNickname: login_info.nickname
                                },
                                userSeq: login_info.userSeq,
                                userKind: this.props.userKind,
                                mode: "web",
                                kind: "qr-login"
                            });
                        }, 100);
                        /* this.props.logInByQRCode({
                            loginInfo: {
                                userSeq: login_info.userSeq,
                                userNickname: login_info.nickname
                            },
                            userSeq: login_info.userSeq,
                            userKind: this.props.userKind,
                            mode: "web",
                            kind: "qr-login"
                        }); */
                    } else {
                        console.log("UserLoginContainer - logInByQRCode => login_info.userSeq is -1");
                    }
                }
            }
        }
    }

    getCookiesInfoHandle = () => {
        // ====================================== [Date 사용법] ======================================
        // date.setSeconds(date.getSeconds() + 30) ........... 현재시간 + 30 초
        // date.setMinutes(date.getMinutes() + 1) ............ 현재시간 + 1 분
        // date.setHours(date.getHours() + 1) ................ 현재시간 + 1 시간
        // date.setDate(date.getDate() + 1) .................. 현재시간 + 1일
        // date.setHours(0, 0, 0, 0) ......................... 날짜는 그대로, 시간 00시 00분 00초 변경
        // ===========================================================================================

        const { cookies } = this.props;

        let newDate = new Date(Date.now());
        newDate.setDate(newDate.getDate() + 1);
        newDate.setHours(0, 0, 0, 0);
        cookies.set("isShow", false, { path: "/", expires: newDate });
    }

    setCookiesHandle = ({ isAutoLogin, userID, passWD }) => {
        const { cookies, saveCookiesToggle } = this.props;
        cookies.set("isAutoLogin", isAutoLogin);
        cookies.set("userID", userID);
        cookies.set("passWD", passWD);
        saveCookiesToggle();
    }

    onHandleCookies = (kind, name, value) => {
        const { cookies } = this.props;
        console.log(`onHandleCookies - kind[${kind}], name[${name}], value[${value}]`);

        let newDate = new Date(Date.now());

        if (kind === "set") {
            newDate.setHours(newDate.getHours() + 3);
        } else if (kind === "delete") {
            newDate.setDate(newDate.getDate() - 1);
        }

        cookies.set(name, value, { path: "/", expires: newDate });
    }

    handleSelectedFunc = ({ selectedFunc, isModalOpened }) => {
        switch (selectedFunc) {
            case "LOGOUT_ALERT_TOGGLE":
                this.props.toggle_logoutAlertModal();
                break;

            case "FORCE_LOGOUT_ALERT_TOGGLE":
                this.props.toggle_forceLogoutAlertModal();
                break;

            case "FORCE_LOG_OUT":
                console.log("FORCE_LOG_OUT - main");
                this.props.forceLogOut({
                    userSeq: this.props.login_info.userSeq,
                    mode: this.props.browserType === enBrowserType.Web ? "web" : "hiclass"
                });
                break;

            default:
                break;
        }
    }

    goToAloneChatting = () => {
        this.props.clearPerformLiveInfo();
        if (this.props.routeProps) {
            this.props.routeProps.history.push("/live");
        }
    }

    render() {
        const { routeProps, browserType, currentPageType, isLoginYn, children, cookies } = this.props;
        const { changeWindowPentalkBoard, userKind, isConfigAdmin, isCreateAdmin, isUsablePass, isStandardType } = this.props;
        const { userID, login_info, isForceLogoutModalOpened, isLogoutAlertModalOpened } = this.props;
        const { getCookiesInfoHandle, goToAloneChatting } = this;

        const newChildren = React.cloneElement(
            this.props.children, {
            userKind: this.props.userKind,
            isUsablePass: this.props.isUsablePass,
            isConfigAdmin: this.props.isConfigAdmin,
            isCreateAdmin: this.props.isCreateAdmin,
            isStandardType: this.props.isStandardType,
            currentPageType: this.props.currentPageType,
            changeWindowPentalkBoard: this.props.changeWindowPentalkBoard,
            goToAloneChatting: this.goToAloneChatting
        });

        return (
            <div className={"main-template" + (this.props.browserType === enBrowserType.Web ? " web" : "")}>
                {
                    this.props.browserType === enBrowserType.Web && this.props.currentPageType !== enWebPage.LiveClassTalk &&
                    <div id="header" className="d-flex w-100">
                        <WebHeaderTitleContainer
                            routeProps={this.props.routeProps}
                            currentPageType={this.props.currentPageType}
                            currentManagePageType={this.props.currentManagePageType}
                            goToAloneChatting={this.goToAloneChatting}
                        />
                    </div>
                }
                <div className={"d-flex flex-column main-container" + (isMobile ? " mobile" : "")}>
                    {
                        this.props.isLoginYn === "N" ?
                            <UserLogInContainer
                                cookies={this.props.cookies}
                                routeProps={this.props.routeProps}
                                browserType={this.props.browserType}
                                currentPageType={this.props.currentPageType}
                                isLoginYn={this.props.isLoginYn}
                                getCookiesInfoHandle={this.getCookiesInfoHandle}
                            /> :
                            newChildren
                    }
                </div>
                <ForceLogoutAlertModal
                    isModalOpened={this.props.isForceLogoutModalOpened}
                    login_info={this.props.login_info}
                    handleSelectedFunc={this.handleSelectedFunc}
                />
                <LogoutAlertModal
                    isModalOpened={this.props.isLogoutAlertModalOpened}
                    user_info={{ id: this.props.userID }}
                    handleSelectedFunc={this.handleSelectedFunc}
                />
            </div>
        );
    }
}

export default connect(
    ({ user, admin, hiclasstv }) => ({
        userKind                : user.userKind,
        userID                  : user.userID,
        login_info              : user.login_info,

        isLoginYn               : user.isLoginYn,
        isUsablePass            : user.isUsablePass,

        isConfigAdmin           : user.isConfigAdmin,
        isCreateAdmin           : user.isCreateAdmin,

        isNeedCookiesInfoRemove : user.isNeedCookiesInfoRemove,
        isNeedRetryingLogin     : user.isNeedRetryingLogin,

        isForceLogoutModalOpened : user.isForceLogoutModalOpened,
        isLogoutAlertModalOpened : user.isLogoutAlertModalOpened,

        isStandardType          : admin.isStandardType,

        isCheckedSubDomain      : admin.isCheckedSubDomain,
        isCorrectSubDomain      : admin.isCorrectSubDomain,

        isCheckedVersion        : hiclasstv.isCheckedVersion,
        //cookies                 : cookies
    }),
    {
        autoLogIn,
        saveCookiesToggle,

        clearPerformLiveInfo,

        changeWindowPentalkBoard,
        changeShowMenuKind,

        forceLogOut,
        logIn,
        logInByQRCode,

        toggle_forceLogoutAlertModal,
        toggle_logoutAlertModal,

        checkSubDomain,
        getVersionInfo,

        falseToRemoveCookies
    }
)(MainContainer);