import React from 'react';

const ShowIdList = ({ isExistUserInfo, handleSelectedFunc, list_findUserId}) => {
    return(
        <div className="d-flex flex-column align-items-center justify-content-center w-100 my-2">
            <div className="d-flex flex-column text-center font-md w-100 py-4 px-2">
                {
                    isExistUserInfo ? 
                        <>
                            <span className="small">고객님의 정보와 일치하는 아이디 목록입니다.</span>
                            <ul className="list-group w-100 mt-2">
                                {
                                    list_findUserId.map(
                                        (info, index) => (
                                            <li className="d-flex list-group-item align-items-center justify-content-between" key={index}>
                                                <div className="full mr-2">
                                                    <span className="font-weight-bold" title={info.USER_ID}>{info.USER_ID}</span>
                                                </div>
                                                <span className="fit-content">
                                                    가입 : {info.USER_SIGNUP_DT.substr(0, 10)}
                                                </span>
                                            </li>
                                        )
                                    )
                                }
                            </ul>
                        </>: 
                        <span className="small">
                            고객님의 정보와 일치하는 회원정보가 없습니다.
                        </span>
                }
            </div>
            <div className="d-flex w-100 justify-content-center">
                <button className="btn btn-primary btn-sm" onClick={() => handleSelectedFunc({selectedFunc:"LOGIN"})}>로그인</button>
                {
                    isExistUserInfo && 
                    <button className="btn btn-warning btn-sm" onClick={() => handleSelectedFunc({selectedFunc:"FIND", type:"PW"})}>비밀번호 찾기</button>
                        
                }
            </div>
        </div>
    );
}

export default ShowIdList;