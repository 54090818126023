import React from 'react';
import { FaMicrophoneAlt, FaVolumeUp, FaSyncAlt, FaPaperPlane } from 'react-icons/fa';

import { ctWatsonStatus } from '../../../lib/ConstCommand';

const SpeakingModeWatsonImage = ({ bubbleOpacity, watsonStatus, onClick }) => {
    switch (watsonStatus) {
        case ctWatsonStatus.Listening : 
            return (
                <div style={{ height: '100%', position:'relative' }}>
                    <span className="hiclasstv-tooltip" style={{ opacity: `${bubbleOpacity}` }}>지금 말씀해 주세요.{/* 마이크가 켜졌습니다. */}</span>
                    <button type="button" className="hiclasstv-classtalk-button" title="마이크 ON" onClick={onClick}>
                        <FaMicrophoneAlt className="font-md plus hiclasstv-coral-text icon-gradation" aria-hidden="true" />
                    </button>
                </div>
            );
        case ctWatsonStatus.Speaking : 
            return (
                <div style={{ height: '100%', position:'relative' }}>
                    {/* <span className="hiclasstv-tooltip">WATSON이 말하고 있습니다.</span> */}
                    <button type="button" className="hiclasstv-classtalk-button" title="왓슨 말하는 중">
                        <FaVolumeUp className="font-md plus hiclasstv-violet-text icon-gradation" aria-hidden="true" />
                    </button>
                </div>
            );
        case ctWatsonStatus.Processing : 
            return (
                <div style={{ height: '100%', position:'relative' }}>
                    <span className="hiclasstv-tooltip">WATSON이 질문을 인식 중입니다.</span>
                    <button type="button" className="hiclasstv-classtalk-button" title="왓슨 처리중">
                        <FaSyncAlt className="font-md plus hiclasstv-violet-text icon-spin" aria-hidden="true" />
                    </button>
                </div>
            );
        default : 
            return (
                <button type="button" className="hiclasstv-classtalk-button hiclasstv-blue-text" onClick={onClick}>
                    <FaPaperPlane className="font-md plus" aria-hidden="true" />
                </button>
            );
    }
};

export default SpeakingModeWatsonImage;