import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from 'query-string';

import {
    getQuizInfo,
    createQuiz,
    editQuiz,
    removeQuiz,
    uploadQuizFile,
    removeQuizFile,
    initUploadInfo,
    toggleMoveUrlOk,
    falseToCreateQuizOk
} from "../modules/quiz";

import QuizModal from "../components/quiz/QuizModal";

class QuizPopUpContainer extends Component {

    state = {
        userSeq         : -1
    }

    componentDidMount() {
        const { routeProps } = this.props;
        if (routeProps.location && routeProps.location.search && routeProps.location.search !== '') {
            const query = queryString.parse(routeProps.location.search);
            if (query === undefined || query === null || query === "") {
                return;
            }

            const quizSeq = query.quizSeq;
            const userSeq = query.userSeq;

            console.log(`QuizPopUpContainer componentDidMount - quizSeq[${quizSeq}], userSeq[${userSeq}]`);
            console.log('Number(quizSeq) - ', Number(quizSeq));
            console.log('Number(userSeq) - ', Number(userSeq));

            if (quizSeq && quizSeq !== '' && Number(quizSeq) > 0) {
                // edit mode
                console.log('edit mode', Number(quizSeq));
                this.props.getQuizInfo({ quizSeq });
            } else {
                // create mode
                console.log('create mode', Number(quizSeq));
            }

            if (userSeq && userSeq !== '' && Number(userSeq) > 0) {
                console.log(Number(userSeq));
                this.setState({ userSeq: Number(userSeq) });
            }
        }

        /* if (routeProps.match && routeProps.match.params) {
            const quizSeq = routeProps.match.params.quizSeq;
            //const userSeq = routeProps.match.params.userSeq;

            if (quizSeq && quizSeq !== '' && Number(quizSeq) > 0) {
                // edit mode
                console.log('edit mode');
                this.props.getQuizInfo({ quizSeq: quizSeq });
            } else {
                // create mode
                console.log('create mode', quizSeq);
            }
        } */
    }

    componentDidUpdate(prevProps) {
        console.log('QuizPopUpContainer - componentDidUpdate');
        console.log(`prevProps.quizSeq[${prevProps.modalQuiz_info.quizSeq}], this.props.quizSeq[${this.props.modalQuiz_info.quizSeq}], prevProps.isMoveUrlOK[${prevProps.isMoveUrlOK}], this.props.isMoveUrlOK[${this.props.isMoveUrlOK}]`);
        console.log(prevProps.routeProps, this.props.routeProps);
        if (prevProps.modalQuiz_info.quizSeq === -1 && prevProps.modalQuiz_info.quizSeq !== this.props.modalQuiz_info.quizSeq && this.props.modalQuiz_info.quizSeq !== undefined) {
            console.log(`(1) prevProps.quizSeq[${prevProps.modalQuiz_info.quizSeq}], this.props.quizSeq[${this.props.modalQuiz_info.quizSeq}]`);
            console.log(`(1) prevProps.isMoveUrlOK[${prevProps.isMoveUrlOK}], this.props.isMoveUrlOK[${this.props.isMoveUrlOK}]`);
            console.log('create success! move url');
            this.props.routeProps.history.push(`/quiz_hiclass/?quizSeq=${this.props.modalQuiz_info.quizSeq}&userSeq=${this.state.userSeq}`);
        } else {
            if (prevProps.modalQuiz_info.quizSeq !== -1 && prevProps.modalQuiz_info.quizSeq === this.props.modalQuiz_info.quizSeq && this.props.modalQuiz_info.quizSeq !== undefined) {
                if (prevProps.routeProps.location.search !== this.props.routeProps.location.search) {
                    console.log(`(2-1) prevProps.quizSeq[${prevProps.modalQuiz_info.quizSeq}], this.props.quizSeq[${this.props.modalQuiz_info.quizSeq}]`);
                    console.log(`(2-1) prevProps.location.search[${prevProps.routeProps.location.search}], this.props.location.search[${this.props.routeProps.location.search}]`);
                    console.log('move url success! changed edit mode!');
                    console.log('get quiz info');
                    this.props.getQuizInfo({ quizSeq: this.props.modalQuiz_info.quizSeq });
                } else {
                    console.log(`(2-2) prevProps.quizSeq[${prevProps.modalQuiz_info.quizSeq}], this.props.quizSeq[${this.props.modalQuiz_info.quizSeq}]`);
                    console.log(`(2-2) prevProps.location.search[${prevProps.routeProps.location.search}], this.props.location.search[${this.props.routeProps.location.search}]`);
                }
            } else {
                console.log(`(3) prevProps.quizSeq[${prevProps.modalQuiz_info.quizSeq}], this.props.quizSeq[${this.props.modalQuiz_info.quizSeq}]`);
                console.log(`(3) prevProps.location.search[${prevProps.routeProps.location.search}], this.props.location.search[${this.props.routeProps.location.search}]`);
            }

            /* if (prevProps.modalQuiz_info.quizSeq !== -1 && prevProps.modalQuiz_info.quizSeq === this.props.modalQuiz_info.quizSeq && this.props.modalQuiz_info.quizSeq !== undefined) {
                if (prevProps.isMoveUrlOK === this.props.isMoveUrlOK && this.props.isMoveUrlOK === false) {
                    console.log(`(2-1) prevProps.quizSeq[${prevProps.modalQuiz_info.quizSeq}], this.props.quizSeq[${this.props.modalQuiz_info.quizSeq}]`);
                    console.log(`(2-1) prevProps.isMoveUrlOK[${prevProps.isMoveUrlOK}], this.props.isMoveUrlOK[${this.props.isMoveUrlOK}]`);
                    console.log('move url success! changed edit mode!');
                    this.handleSelectedFunc({ selectedFunc: "MOVE_URL_OK" });
                } else {
                    if (prevProps.isMoveUrlOK !== this.props.isMoveUrlOK && this.props.isMoveUrlOK === true) {
                        console.log(`(2-2) prevProps.quizSeq[${prevProps.modalQuiz_info.quizSeq}], this.props.quizSeq[${this.props.modalQuiz_info.quizSeq}]`);
                        console.log(`(2-2) prevProps.isMoveUrlOK[${prevProps.isMoveUrlOK}], this.props.isMoveUrlOK[${this.props.isMoveUrlOK}]`);
                        console.log('get quiz info');
                        this.props.getQuizInfo({ quizSeq: this.props.modalQuiz_info.quizSeq });
                    } else {
                        console.log(`(2-3) prevProps.quizSeq[${prevProps.modalQuiz_info.quizSeq}], this.props.quizSeq[${this.props.modalQuiz_info.quizSeq}]`);
                        console.log(`(2-3) prevProps.isMoveUrlOK[${prevProps.isMoveUrlOK}], this.props.isMoveUrlOK[${this.props.isMoveUrlOK}]`);
                    }
                }
            } else {
                console.log(`(3) prevProps.quizSeq[${prevProps.modalQuiz_info.quizSeq}], this.props.quizSeq[${this.props.modalQuiz_info.quizSeq}]`);
                console.log(`(3) prevProps.isMoveUrlOK[${prevProps.isMoveUrlOK}], this.props.isMoveUrlOK[${this.props.isMoveUrlOK}]`);
            } */
        }

        /* if (prevProps.modalQuiz_info.quizSeq !== this.props.modalQuiz_info.quizSeq && this.props.modalQuiz_info.quizSeq !== undefined) {
            console.log('create success! move url');
            const { routeProps } = this.props;
            if (routeProps.history) {
                this.props.routeProps.history.push(`/quiz_hiclass/?quizSeq=${this.props.modalQuiz_info.quizSeq}&userSeq=${this.state.userSeq}`);
            }
        } */
    }

    handleSelectedFunc = ({ selectedFunc, save_info, remove_info, file_info, value }) => {
        console.log('handleSelectedFunc - ', selectedFunc);

        switch (selectedFunc) {
            case "GET_QUIZ_INFO":
                break;

            case "CREATE_QUIZ":
                if (this.state.userSeq === -1) {
                    this.props.createQuiz({ userSeq: 1011, modalQuiz_info: save_info });
                } else {
                    this.props.createQuiz({ userSeq: this.state.userSeq, modalQuiz_info: save_info });
                }
                break;

            case "EDIT_QUIZ":
                if (this.state.userSeq === -1) {
                    this.props.editQuiz({ userSeq: 1011, modalQuiz_info: save_info });
                } else {
                    this.props.editQuiz({ userSeq: this.state.userSeq, modalQuiz_info: save_info });
                }
                break;

            case "REMOVE_QUIZ":
                if (this.state.userSeq === -1) {
                    remove_info.userSeq = 1011;
                    this.props.removeQuiz(remove_info);
                } else {
                    remove_info.userSeq = this.state.userSeq;
                    this.props.removeQuiz(remove_info);
                }
                break;

            case "UPLOAD_QUIZ_FILE":
                if (this.state.userSeq === -1) {
                    file_info.userSeq = 1011;
                    this.props.uploadQuizFile(file_info);
                    //this.props.uploadQuizFile({ userSeq: 1011, kind: file_info.kind, seq: file_info.seq, file: file_info.file, filename: file_info.filename });
                } else {
                    file_info.userSeq = this.state.userSeq;
                    this.props.uploadQuizFile(file_info);
                    //this.props.uploadQuizFile({ userSeq: this.state.userSeq, kind: file_info.kind, seq: file_info.seq, file: file_info.file, filename: file_info.filename });
                }
                break;

            case "REMOVE_QUIZ_FILE":
                if (this.state.userSeq === -1) {
                    file_info.userSeq = 1011;
                    this.props.removeQuizFile(file_info);
                    //this.props.removeQuizFile({ userSeq: 1011, kind: file_info.kind, seq: file_info.seq, filePath: file_info.filePath });
                } else {
                    file_info.userSeq = this.state.userSeq;
                    this.props.removeQuizFile(file_info);
                    //this.props.removeQuizFile({ userSeq: this.state.userSeq, kind: file_info.kind, seq: file_info.seq, filePath: file_info.filePath });
                }
                break;

            case "INIT_FILE_INFO":
                this.props.initUploadInfo();
                break;

            case "MOVE_URL_OK":
                this.props.toggleMoveUrlOk({ isMoveUrlOK: true });
                break;

            case "FALSE_TO_MOVE_URL_OK":
                this.props.toggleMoveUrlOk({ isMoveUrlOK: false });
                break;

            case "FALSE_TO_CREATE_QUIZ":
                this.props.falseToCreateQuizOk();
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <QuizModal
                modalQuiz_info={this.props.modalQuiz_info}
                upload_info={this.props.upload_info}
                alertKind={this.props.alertKind}
                alertMessage={this.props.alertMessage}
                handleSelectedFunc={this.handleSelectedFunc}
            />
        );
    }
}

export default connect(
    ({ user, quiz }) => ({
        modalQuiz_info  : quiz.modalQuiz_info,
        upload_info     : quiz.upload_info,

        isMoveUrlOK     : quiz.isMoveUrlOK,

        alertKind       : quiz.alertKind,
        alertMessage    : quiz.alertMessage,
    }),
    {
        getQuizInfo,

        createQuiz,
        editQuiz,
        removeQuiz,

        uploadQuizFile,
        removeQuizFile,

        initUploadInfo,

        toggleMoveUrlOk,
        falseToCreateQuizOk
    }
)(QuizPopUpContainer);