import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';

const groupMemberLimit = [4, 9];

const ModifyAlertModal = ({ kind, info, isAlertModalOpened, handleModifyStudioInfo, toggleModalOpened }) => {

    const [lStudioName, setStudioName] = useState("");
    const [lGroupLImit, setGroupLimit] = useState(-1);

    useEffect(() => {
        if (isAlertModalOpened) {
            if (kind === "group-limit") {
                setGroupLimit(info.groupLimit);
            }

            setStudioName(info.studioName);       
        }
    }, [isAlertModalOpened]);
    
    const onChangeStudioInfo = (e) => {
        if (kind === "name") {
            setStudioName(e.target.value);
        } else if (kind === "group-limit") {
            setGroupLimit(groupMemberLimit[e.target.value]);
        }
    }

    return(
        <Modal isOpen={isAlertModalOpened} centered backdrop={true} toggle={() => toggleModalOpened(false)}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="d-flex flex-column justify-content-center align-items-center m-0 p-0">
                        <div className="d-flex justify-content-center dark-grey-text w-100">
                            <div className="d-flex justify-content-center align-items-center">
                                {
                                    kind === "name" ?
                                        <span>
                                            해당 스튜디오의 이름을 변경하시겠습니까?
                                        </span> :
                                    kind === "group-limit" ?
                                        <span>
                                            해당 스튜디오의 연결 인원을 변경하시겠습니까?
                                        </span> :
                                        <></>
                                }
                            </div>                            
                        </div>
                        <div className="d-flex flex-column align-items-center my-3 p-2 hiclasstv-grey w-100" style={{ borderRadius: '1rem'}}>
                            {
                                kind === "name" ?
                                    <span className="dark-grey-text">
                                        변경 전 이름 <b>[{info === undefined || info === null ? lStudioName : info.studioName}]</b>
                                    </span> :
                                kind === "group-limit" ?
                                    <span className="dark-grey-text">
                                        스튜디오 <b>[{info.studioName}]</b>
                                    </span> :
                                    <></>
                            }
                            
                        </div>
                        <div className="d-flex mb-3 w-100 justify-content-around align-items-center">
                            {
                                kind === "name" ?
                                    <input type="text" className="d-flex flex-fill form-control form-control-sm white" onChange={onChangeStudioInfo} placeholder={lStudioName} /> :
                                kind === "group-limit" ?
                                    groupMemberLimit.map(
                                        (item, idx) => (
                                            <div className="d-flex align-items-center" key={'limit-item-' + idx}>
                                                <input className="d-flex mr-1 p-0 web-search-input" type="radio" name="groupLimit" id={"groupLimit-" + idx} value={idx} defaultChecked={info.groupLimit === item ? true : false} onChange={onChangeStudioInfo} />
                                                <span>{item} 명</span>
                                            </div>
                                        )
                                    ) :
                                    <></>
                            }                            
                        </div>
                        <div className="d-flex justify-content-center">
                            {
                                kind === "name" ?
                                    <button type="button" className="btn btn-sm green white-text m-0 mr-1 py-1 px-2" onClick={() => handleModifyStudioInfo({kind, info, data:lStudioName})} disabled={lStudioName === "" ? true : false}>수정</button> :
                                kind === "group-limit" ?    
                                    <button type="button" className="btn btn-sm green white-text m-0 mr-1 py-1 px-2" onClick={() => handleModifyStudioInfo({kind, info, data:lGroupLImit})}>수정</button> :
                                    <></>
                            }
                            <button type="button" className="btn btn-sm red white-text m-0 mr-1 py-1 px-2" onClick={() => toggleModalOpened(false)}>취소</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default ModifyAlertModal;