import React, { useEffect } from 'react';

// import Congrats01 from '../../image/card/congrats_01.png';
// import Congrats02 from '../../image/card/congrats_02.png';
// import Congrats03 from '../../image/card/congrats_03.png';
// import Congrats04 from '../../image/card/congrats_04.png';

const CongratsType = ({ type }) => {
    switch (type) {
        case "1":
            return (
                <>
                    <img src="/images/card/congrats_01.png" alt="축하합니다!" />
                    축하합니다!
                </>
            );

        case "2":
            return (
                <>
                    <img src="/images/card/congrats_02.png" alt="참 잘했어요^^*" />
                    참 잘했어요^^*
                </>
            );

        case "3":
            return (
                <>
                    <img src="/images/card/congrats_03.png" alt="훌륭해요♥" />
                    훌륭해요♥
                </>
            );

        case "4":
            return (
                <>
                    <img src="/images/card/congrats_04.png" alt="수고했어~칭찬해~^^*" />
                    수고했어~칭찬해~^^*
                </>
            );

        default:
            break;
    }
}

const CongratsCard = ({ isCongratsVisible, info }) => {
    return (
        info && isCongratsVisible &&
        <div className="congrats-card">
            <CongratsType type={info.type} />
        </div>
    );
}

export default CongratsCard;