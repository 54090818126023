import React from 'react';

import MainContainer from '../containers/MainContainer';
import WebDownloadContainer from '../containers/etc/WebDownloadContainer';
import WebContentTemplate from '../components/template/WebContentTemplate';

const WebDownloadPage = ({ browserType, currentPageType, cookies, routeProps }) => {
    return (
        <MainContainer 
            browserType={browserType} 
            cookies={cookies} 
            currentPageType={currentPageType} 
            routeProps={routeProps}
        >
            <WebContentTemplate>
                <WebDownloadContainer routeProps={routeProps} />
            </WebContentTemplate>
        </MainContainer>
    );
};

export default WebDownloadPage;