/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';

const QnA007 = ({ isOpened, onChangeOpenedFlag }) => {
    useEffect(() => {
        let content = document.getElementById("qna7");

        if (isOpened) {
            content.style.maxHeight = content.scrollHeight + "px";
        } else {
            content.style.maxHeight = null;
        }
    }, [isOpened]);

    return (
        <div className="accordion">
            <div className="accordion-title" onClick={() => onChangeOpenedFlag(6)}>
                <span className="hiclasstv-deepDarkgrey-text button">
                    <b>7. 해외에서도 사용 가능한가요?</b>
                </span>
            </div>
            <div className="accordion-content hiclasstv-darkgrey-text" id="qna7">
                <span className="d-flex" style={{ minWidth: 'max-content' }}>A.&nbsp;</span>
                <p>
                    전 세계 어디서든 인터넷이 연결되어 있으면 가능합니다.<br />
                    HiClassTV는 클라우드 기반의 서비스입니다. 따라서 인터넷이 가능한 어떤 곳에서도 사용할 수 있습니다.
                </p>
            </div>
        </div>
    );
}
export default QnA007;