import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import ConstData from '../../../lib/ConstData';

import Alerts from '../../etc/list/Alerts';

const CertifyServerUser = ({ isModalOpened, loginMessage, alertKind, toggleCertifyServerUserModal, handleCertifyServerUser }) => {

    const [lServerUserID, setServerUserID] = useState('');
    const [lServerUserPW, setServerUserPW] = useState('');
    const [lkeepingSaveStatus, setKeepingSaveStatus] = useState(false);

    const [lAlertMessage, setAlertMessage] = useState(loginMessage);
    const [lAlertKind, setAlertKind] = useState(alertKind);

    useEffect(() => {
        setAlertMessage(loginMessage);
    }, [loginMessage]);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    const onChangeServerUserID = (e) => {
        setServerUserID(e.target.value);
    }

    const onChangeServerUserPW = (e) => {
        setServerUserPW(e.target.value);
    }

    const onChangeSavedCertifyUserInfo = (e) => {
        setKeepingSaveStatus(e.target.checked);
    }
    
    const onClosedModal = () => {
        setAlertKind(ConstData.ALERT_KIND.NONE);
        setAlertMessage('');
    }

    return (
        <Modal isOpen={isModalOpened} centered backdrop={true} toggle={() => toggleCertifyServerUserModal(false)} onClosed={onClosedModal}>
            <div className='d-flex flex-column align-items-center justify-content-center text-center my-2 w-100'>
                <div className='input-area'>
                    <span className='input-label pr-2 small'>
                        <b>서버 사용자 ID</b>
                    </span>
                    <div className='input-data'>
                        <input type="text" placeholder='서버 사용자 ID를 입력하세요' onChange={onChangeServerUserID} />
                    </div>
                </div>
                <div className='input-area'>
                    <span className='input-label pr-2 small'>
                        <b>서버 사용자 PW</b>
                    </span>
                    <div className='input-data'>
                        <input type="password" placeholder='서버 사용자 패스워드를 입력하세요' onChange={onChangeServerUserPW} />
                    </div>
                </div>
                <Alerts kind={lAlertKind} style={{marginTop:0, fontSize: '80%'}} message={lAlertMessage} />
                {
                    lAlertKind === ConstData.ALERT_KIND.SUCCESS ?
                        <button className='btn green white-text btn-sm' onClick={() => toggleCertifyServerUserModal(false)}>
                            확인
                        </button> :
                        <>
                            <div className='custom-control custom-checkbox'>
                                <input type="checkbox" className="custom-control-input" id="isKeeping" onChange={onChangeSavedCertifyUserInfo} />
                                <label className="custom-control-label pr-2 blue-text font-sm" htmlFor="isKeeping">인증정보 저장</label>
                            </div>
                            <div className='d-flex'>
                                <button className='btn btn-primary btn-sm' onClick={() => handleCertifyServerUser({ serverUserID: lServerUserID, serverUserPW: lServerUserPW, isKeepSaving: lkeepingSaveStatus })}>
                                    인증하기
                                </button>
                                <button className='btn btn-danger btn-sm' onClick={() => toggleCertifyServerUserModal(false)}>
                                    취소
                                </button>
                            </div>  
                        </>
                }
            </div>
        </Modal>        
    );
}

export default CertifyServerUser; 