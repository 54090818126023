import React, { useState } from 'react';
import { BsX } from 'react-icons/bs';

const EditChatRoom = ({ selectedChatRoomInfo, handleSelectedConfigFunc }) => {
    const { chatRoomSeq, chatRoomName } = selectedChatRoomInfo;

    const [lChatRoomName, setChatRoomName] = useState(chatRoomName);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const onChangeInputChatRoomName = (e) => {
        setChatRoomName(e.target.value);
    }

    const saveChatroomName = () => {
        if (lChatRoomName === "") {
            handleSelectedConfigFunc({selectedFunc:"SAVE", chatRoomSeq:chatRoomSeq, chatRoomName:chatRoomName});
        } else {
            handleSelectedConfigFunc({selectedFunc:"SAVE", chatRoomSeq:chatRoomSeq, chatRoomName:lChatRoomName});
        }
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area">
                <span>
                    <b>팀업 이름 수정</b>
                </span>
                <span className="func-btn" style={{position:'absolute', right:'0'}} onClick={() => handleSelectedConfigFunc({selectedFunc:"NONE"})}>
                    <BsX />
                </span>
            </div>
            <div className="input-area mt-2">
                <input type="text" title={"변경 전 이름 : " + chatRoomName} placeholder={chatRoomName} onChange={onChangeInputChatRoomName} />
            </div>
            <div className="d-flex justify-content-center align-items-center my-2">
                <button type="button" className="btn btn-sm small hiclasstv-blue white-text m-0 py-1 px-2" onClick={() => saveChatroomName()}>수정</button>
            </div>
        </div>
    );
}

export default EditChatRoom;