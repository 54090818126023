import React from 'react';

import MainContainer from '../containers/MainContainer';
import WebContentTemplate from '../components/template/WebContentTemplate';
import WebAdminContainer from '../containers/etc/WebAdminContainer';

const WebAdminPage = ({ ...props }) => {
    return (
        <MainContainer
            {...props}
        >
            <WebContentTemplate>
                <WebAdminContainer
                    { ...props }
                />
            </WebContentTemplate>
        </MainContainer>
    );
};

export default WebAdminPage;