import React from 'react';

const CenteredModalInChatting = ({ modalClose, children }) => {
    return(
        <div className="hiclasstv-modal centered chatting" onClick={() => modalClose({ selectedFunc: "NONE" })}>
            {children}
        </div>
    ) 
}

export default CenteredModalInChatting;