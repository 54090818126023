import html2canvas from "html2canvas";

import ConstCode from "../ConstCode";
import ConstData from "../ConstData";

import { decodingPacket } from "../classTalk/EncodeDecode";
import { enTokenCMD, enTabStatus } from "../classTalk/CodeEnum";
import { checkUrlText, getUrlText } from "../func/ExportFunction";

import {
    mqUpdateInfo,
    forceLogOutInit,
    mixedClassLoginSuccess,
    mixedClassLogoutSuccess
} from "../../modules/user";

import {
    mqLoginChat,
    mqLogoutChat,
    mqJoinChat,
    mqFinishChat,
    mqExitChat,
    mqCreateChat,
    mqRemoveChat,
    mqRemoveChatMember,
    mqRemovedChatMember,
    mqUpdateChat,
    mqNameUpdateChat,
    mqModeUpdateChat,
    mqTypeUpdateChat,
    mqInviteChatMember,
    mqAddChatMember,
    mqUpdateChatMemberInfo
} from "../../modules/chat";

import {
    mqLoginFriend,
    mqLogoutFriend,
    mqCancelFriend,
    mqCancelFriendMe,
    mqRejectFriendMe,
    mqRejectFriend,
    mqRemoveFriend,
    mqAddFriend,
    mqMultipleAddFriend,
    mqApplyFriend,
    mqSendApplyFriend,
    mqMultipleApplyFriend,
    mqUpdateFriendStatus,
    mqUpdateFriendInfo
} from "../../modules/friend";

import {
    mqLiveOn,
    mqLiveOff,
    mqJoinLive,
    mqExitLive,
    mqCreateLive,
    mqRemoveLive,
    mqEditLiveName,
    mqRejectLiveMember,
    mqRejectedLiveMember,
    mqRemoveLiveMember,
    mqRemovedLiveMember,
    mqApplyLive,
    mqApproveLive,
    mqApproveLiveMember,
    mqAddLiveMember,
    mqHandsUpOn,
    mqHandsUpOff,
    mqHandsUpStart,
    mqHandsUpStop,
    mqHandsUpActive,
    mqHandsUpInactive,
    mqSmallGroupStart,
    mqSmallGroupStop,
    mqLiveInfoInit,
    mqBroadcastOn,
    mqBroadcastOff,
    mqUpdateBroadcastDate,
    mqChattingLock,
    mqAndroidTouchScreenLock,
    mqUpdateLiveMemberInfo,
    mqUpdateLiveMemberNickname,
    mqRcvSmartTVBindInfo,
    joinMixedClassLive,
    finishMixedClassLive,
    toggleLivePullScreenAlert,
    updateMixedClassMemberList
} from "../../modules/live";

import {
    mqDrawInkStrokeDown,
    mqDrawInkStrokeMove,
    mqDrawInkStrokeDoubleMove,
    mqDrawInkStrokeUp,
    mqDrawInkStroke,
    mqDrawInkStrokes,
    mqClearInkStroke,
    mqEraseInkStroke,
    mqChangeInkAttr,
    mqChangeInkMode,
    mqChangeInkColor,
    mqChangeInkTipSize,
    closeWhisperModal,
    androidPenDown,
    androidPenMove,
    androidPenUp,
    androidPenDoubleMove,
    appendReceiveMessage,
    mqClearPage
} from "../../modules/classTalkLive";

import {
    mqUpdateStudioUser,
    mqUpdateStudioLive,
    mqUpdateDisplayStatus
} from "../../modules/studio";

import {
    mqRcvQuiz, mqRcvResponseFinishQuiz
} from "../../modules/quiz";

import {
    initHandsUpAudioConsume,
    startHandsUpAudioConsume,
    stopHandsUpAudioConsume,
    setHandsUpMicOn,
    setHandsUpMicOff,
    setHandsUpSoundMuteMode,
    setHandsUpSoundListenMode,
    handleHandsUpP2PMode_new,
    restartHandsUpP2PMode,
    handleGroupStudyMode,
    updateSmallGroupImage,
    androidHandsUpInitDone,
    setHandsUpMicDisable,
    setHandsUpMicEnable,
    handleHandsUpSmartTVMode,
} from "../HandsUpClient";

import {
    createConsumerTransportAfter,
    connectConsumerTransportAfter,
    joinConsumeAfter,
    exitConsumeAfter,
    consumeAfter,
    resumeAfter,
    unsubscribeAfter,
    newProducerAfter,
    closeProducerAfter,
    createProducerTransportAfter,
    connectProducerTransportAfter,
    produceAfter,
    unpublishAfter,
    getStateAfter
} from "../MediaSoupClient";

import {
    alertErrMsg,
    setImageSrc,
    getRcvKind,
    getMyUserSeq,
    getRcvLiveSeq,
    getMyRoutingKey,
    getIsSmartTVMode,
    getProfileImgUrl,
    getScreenImgStream,
    getMyHandsUpSendKey,
    getIsRunForSmartPen,
    setIsRunForSmartPen,
    getIsAlivePenCamService,
    setIsAlivePenCamService,
    getMySmallGroupImageSendKey,
    convertArrayBuffer,
    connectSmallGroup,
    disconnectSmallGroup,
    xmitCmd_AndroidInitDone,
    xmitCmd_AndroidStatusMessage,
    xmitCmd_AndroidMessageForSmartTV,
    sendPageGetArtifactsForAndroid,
    getCurrentPageType,
    getIndexForGroupSmartTV,
    getConnectedOnScreenAppInfo,
    getConnectedOnScreenAppInfoWithIndex,
    // xmitCmd_AndroidMessage,
    // xmitCmd_AndroidTouchScreenLock,
    // getIsHandsUpStarted,
} from "../RabbitmqClient";
import { enWebPage } from "../ConstCommand";

const clientInfo = {
    funcProc: null,
    funcProcArr: [
        { userSeq: -1, funcProc: null },
        { userSeq: -1, funcProc: null },
        { userSeq: -1, funcProc: null },
        { userSeq: -1, funcProc: null },
        { userSeq: -1, funcProc: null },
        { userSeq: -1, funcProc: null },
        { userSeq: -1, funcProc: null },
        { userSeq: -1, funcProc: null },
        { userSeq: -1, funcProc: null },    // max value is 9
    ],
};

export const initFuncProc = (imageProcess) => {
    clientInfo.funcProc = imageProcess;
}

export const initFuncProcArr = (idx, userSeq, imageProcess) => {
    // console.log(`initFuncProcArr - idx[${idx}], userSeq[${userSeq}]`);
    if (getCurrentPageType() === enWebPage.GroupSmartTV) {
        if (idx < clientInfo.funcProcArr.length) {
            clientInfo.funcProcArr[idx].userSeq = userSeq;
            clientInfo.funcProcArr[idx].funcProc = imageProcess;
        } else {
            clientInfo.funcProcArr.push({
                userSeq,
                funcProc: imageProcess
            });
        }
    } else {
        clientInfo.funcProc = imageProcess;
    }
}

export const getCurrentFuncProc = (userSeq, status, data) => {
    // console.log(`getCurrentFuncProc - userSeq[${userSeq}], status[${status}], data => `, data);
    if (getCurrentPageType() === enWebPage.GroupSmartTV) {
        const funcProcInfo = clientInfo.funcProcArr.find(info => info.userSeq === userSeq);
        // console.log(`getCurrentFuncProc - userSeq[${userSeq}], funcProcInfo => `, funcProcInfo);
        return funcProcInfo;
    } else {
        return clientInfo.funcProc;
    }
}

export const callCurrentFuncProc = (userSeq, status, data) => {
    // console.log(`getCurrentFuncProc - userSeq[${userSeq}], status[${status}], data => `, data);
    if (getCurrentPageType() === enWebPage.GroupSmartTV) {
        const funcProcInfo = clientInfo.funcProcArr.find(info => info.userSeq === userSeq);
        console.log(`getCurrentFuncProc - userSeq[${userSeq}], funcProcInfo => `, funcProcInfo);
        if (funcProcInfo) {
            funcProcInfo.funcProc(status, data);
        }
    } else {
        if (clientInfo.funcProc) clientInfo.funcProc(status, data);
    }
}

const drawInkStrokeDown = (cmd, point) => {
    let store = window.hiclasstv.store;
    store.dispatch(mqDrawInkStrokeDown({ cmd, point }));
}

const drawInkStrokeMove = (cmd, point) => {
    let store = window.hiclasstv.store;
    store.dispatch(mqDrawInkStrokeMove({ cmd, point }));
}

const drawInkStrokeDoubleMove = (cmd, spoint, epoint) => {
    let store = window.hiclasstv.store;
    store.dispatch(mqDrawInkStrokeDoubleMove({ cmd, spoint, epoint }));
}

const drawInkStrokeUp = (cmd, point) => {
    let store = window.hiclasstv.store;
    store.dispatch(mqDrawInkStrokeUp({ cmd, point }));
}

const drawInkStroke = (stroke) => {
    let store = window.hiclasstv.store;
    store.dispatch(mqDrawInkStroke({ stroke }));
}

const drawInkStrokes = (strokes, routingKey) => {
    let store = window.hiclasstv.store;
    const { live } = store.getState();
    const { performLiveInfo } = live;

    let performLiveSeq = performLiveInfo.liveSeq;

    if (performLiveSeq > 0) {
        let rcvLiveSeq = getRcvLiveSeq(routingKey);
        if (rcvLiveSeq === 0 || rcvLiveSeq === performLiveSeq) {
            // let store = window.hiclasstv.store;
            store.dispatch(mqDrawInkStrokes({ strokes }));
        }
    }
}

const clearInkStroke = (routingKey) => {
    let store = window.hiclasstv.store;
    const { live } = store.getState();
    const { performLiveInfo } = live;

    let performLiveSeq = performLiveInfo.liveSeq;

    if (performLiveSeq > 0) {
        let rcvLiveSeq = getRcvLiveSeq(routingKey);
        if (rcvLiveSeq === 0 || rcvLiveSeq === performLiveSeq) {
            // let store = window.hiclasstv.store;
            store.dispatch(mqClearInkStroke());
        }
    }
}

const eraseInkStroke = (cmd, spoint, epoint) => {
    let store = window.hiclasstv.store;
    store.dispatch(mqEraseInkStroke({ cmd, spoint, epoint }));
}

const changeInkAttribute = (mode, r, g, b, width, height) => {
    let store = window.hiclasstv.store;
    store.dispatch(mqChangeInkAttr({ mode, r, g, b, width, height }));
}

const changeInkMode = (mode) => {
    let store = window.hiclasstv.store;
    store.dispatch(mqChangeInkMode({ mode }));
}

const changeInkColor = (r, g, b) => {
    let store = window.hiclasstv.store;
    store.dispatch(mqChangeInkColor({ r, g, b }));
}

const changeInkTipSize = (width, height) => {
    let store = window.hiclasstv.store;
    store.dispatch(mqChangeInkTipSize({ width, height }));
}

const rcvClearPage = (cmd) => {
    let store = window.hiclasstv.store;
    store.dispatch(mqClearPage({ cmd }));
}

const rcvAndroidPenDown = (cmd, spoint) => {
    let store = window.hiclasstv.store;
    store.dispatch(androidPenDown({ cmd, point: spoint }));
}

const rcvAndroidPenMove = (cmd, spoint) => {
    let store = window.hiclasstv.store;
    store.dispatch(androidPenMove({ cmd, point: spoint }));
}

const rcvAndroidPenDoubleMove = (cmd, spoint, epoint) => {
    let store = window.hiclasstv.store;
    store.dispatch(androidPenDoubleMove({ cmd, point1: spoint, point2: epoint }));
}

const rcvAndroidPenUp = (cmd, spoint) => {
    let store = window.hiclasstv.store;
    store.dispatch(androidPenUp({ cmd, point: spoint }));
}

const rcvChatText = (userName, emoGIDno, emoMIDno, msgText, routingKey) => {
    let store = window.hiclasstv.store;

    let selRcvKind = getRcvKind(routingKey, getMyHandsUpSendKey());
    let profileImgUrl = getProfileImgUrl(selRcvKind, routingKey);
    let isUrlText = checkUrlText(msgText);
    let urlText = "";
    if (isUrlText) getUrlText(msgText);

    const rKeyArr = routingKey.split(".");
    if (rKeyArr.length > 4 && rKeyArr[0] !== "G") {    // direct key (귓속말)
        let msgInfo = {
            kind: "whisper",
            iconSeq: parseInt(profileImgUrl),
            rcvKind: selRcvKind,
            rcvName: userName,
            text: "귓속말을 보냈습니다.",
            emoticSeq: -1,
            urlInfo: { text: msgText, emoticSeq: emoMIDno, isUrlText, urlText },
            isUrlText,
            urlText
        };

        store.dispatch(appendReceiveMessage(msgInfo));
    } else {                        // entire key (전체에게)
        let msgInfo = {
            iconSeq: parseInt(profileImgUrl),
            rcvKind: selRcvKind,
            rcvName: userName,
            text: msgText,
            emoticSeq: emoMIDno,
            urlInfo: "",
            isUrlText,
            urlText
        };

        store.dispatch(appendReceiveMessage(msgInfo));
    }
}

const rcvChatAudioMemo = (voiceData, userName, routingKey) => {
    let store = window.hiclasstv.store;
    let selRcvKind = getRcvKind(routingKey, getMyHandsUpSendKey());
    let profileImgUrl = getProfileImgUrl(selRcvKind, routingKey);
    let blob = new Blob([voiceData], { type: "audio/mp3" });
    let voiceUrl;

    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
        voiceUrl = reader.result;

        const rKeyArr = routingKey.split(".");
        if (rKeyArr.length > 4 && rKeyArr[0] !== "G") {    // direct key (귓속말)
            let msgInfo = {
                kind: "whisper",
                iconSeq: parseInt(profileImgUrl),
                rcvKind: selRcvKind,
                rcvName: userName,
                text: "귓속말을 보냈습니다.",
                // urlInfo: { text: "음성톡", kind: "audio_memo", urlInfo: voiceUrl },
                urlInfo: { text: "음성메모", kind: "audio_memo", urlInfo: voiceUrl },
            };

            store.dispatch(appendReceiveMessage(msgInfo));
        } else {                        // entire key (전체에게)
            let msgInfo = {
                kind: "audio_memo",
                iconSeq: parseInt(profileImgUrl),
                rcvKind: selRcvKind,
                rcvName: userName,
                // text: "음성톡",
                text: "음성메모",
                urlInfo: voiceUrl
            };

            store.dispatch(appendReceiveMessage(msgInfo));
        }
    };
}

const rcvChatInkMemo = (imgData, userName, routingKey) => {
    let store = window.hiclasstv.store;
    let selRcvKind = getRcvKind(routingKey, getMyHandsUpSendKey());
    let profileImgUrl = getProfileImgUrl(selRcvKind, routingKey);
    let blob = new Blob([imgData], { type: "image/jpeg" });
    let imagUrl;

    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
        imagUrl = reader.result;

        const rKeyArr = routingKey.split(".");
        if (rKeyArr.length > 4 && rKeyArr[0] !== "G") {    // direct key (귓속말)
            let msgInfo = {
                kind: "whisper",
                iconSeq: parseInt(profileImgUrl),
                rcvKind: selRcvKind,
                rcvName: userName,
                text: "귓속말을 보냈습니다.",
                // urlInfo: { text: "메모톡", kind: "ink_memo", urlInfo: imagUrl },
                urlInfo: { text: "잉크메모", kind: "ink_memo", urlInfo: imagUrl }
            };

            store.dispatch(appendReceiveMessage(msgInfo));
        } else {
            let msgInfo = {
                kind: "ink_memo",
                iconSeq: parseInt(profileImgUrl),
                rcvKind: selRcvKind,
                rcvName: userName,
                // text: "메모톡",
                text: "잉크메모",
                urlInfo: imagUrl
            };

            store.dispatch(appendReceiveMessage(msgInfo));
        }
    };
}

const rcvChatFile = (path, userName, routingKey) => {
    let store = window.hiclasstv.store;
    let selRcvKind = getRcvKind(routingKey, getMyHandsUpSendKey());
    let profileImgUrl = getProfileImgUrl(selRcvKind, routingKey);

    const rKeyArr = routingKey.split(".");
    if (rKeyArr.length > 4 && rKeyArr[0] !== "G") {    // direct key (귓속말)
        let msgInfo = {
            kind: "whisper",
            iconSeq: parseInt(profileImgUrl),
            rcvKind: selRcvKind,
            rcvName: userName,
            text: "귓속말을 보냈습니다.",
            urlInfo: { text: userName + "님이 파일을 전송했습니다.", kind: "file_share", urlInfo: path, isClickedDownload: false }
        };

        store.dispatch(appendReceiveMessage(msgInfo));
    } else {
        let msgInfo = {
            kind: "file_share",
            iconSeq: parseInt(profileImgUrl),
            rcvKind: selRcvKind,
            rcvName: userName,
            text: userName + "님이 파일을 전송했습니다.",
            urlInfo: path,
            isClickedDownload: false
        };

        store.dispatch(appendReceiveMessage(msgInfo));
    }
}

const rcvCmd_PagePut = (imgData, routingKey) => {
    let store = window.hiclasstv.store;
    const { live } = store.getState();
    const { performLiveInfo } = live;

    let performLiveSeq = performLiveInfo.liveSeq;

    if (performLiveSeq > 0) {
        let rcvLiveSeq = getRcvLiveSeq(routingKey);
        if (rcvLiveSeq === 0 || rcvLiveSeq === performLiveSeq) {
            let blob = new Blob([imgData], { type: "image/jpeg" });
            let imageUrl;
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
                imageUrl = reader.result;
                let img = document.createElement("img");
                let canvas = document.getElementById("rcvDrawImgCanvas");
                img.src = imageUrl;
                img.onload = () => {
                    setImageSrc(img);
                };
            };
        }
    }
}

const rcvCmd_PageGet = (action, tokenCmd) => {
    let agent = navigator.userAgent.toLowerCase();

    if (agent.indexOf("android") !== -1) {
        if (getIsAlivePenCamService()) {
            sendPageGetArtifactsForAndroid(action, tokenCmd);
        } else { // app과 연결되지 않았을경우 android 임시로 ios와 같은 방식으로 처리하도록 ...
            html2canvas(document.body)
            .then((data) => {
                data.toBlob((blob) => {
                    convertArrayBuffer(tokenCmd, blob);
                    let store = window.hiclasstv.store;
                    store.dispatch(toggleLivePullScreenAlert({ isModalOpened: true }));
                }, "image/jpeg"/*, 1.0*/);
            })
            .catch((err) => {
                console.log("rcvCmd_PageGet(1) html2canvas err ", err);
            });
        }
    } else if (agent.indexOf("iphone") !== -1 || agent.indexOf("ipad") !== -1) {
        html2canvas(document.body)
        .then((data) => {
            data.toBlob((blob) => {
                convertArrayBuffer(tokenCmd, blob);
            }, "image/jpeg"/*, 1.0*/);
        })
        .catch((err) => {
            console.log("rcvCmd_PageGet(2) html2canvas err ", err);
        });
    } else {
        if (navigator.mediaDevices.getDisplayMedia) {
            navigator.mediaDevices.getDisplayMedia({ video: true })
            .then((stream) => {
                getScreenImgStream(tokenCmd, stream);
            })
            .catch((err) => {
                alertErrMsg(err);
            });
        } else {
            html2canvas(document.body)
            .then((data) => {
                data.toBlob((blob) => {
                    convertArrayBuffer(tokenCmd, blob);
                }, "image/jpeg"/*, 1.0*/);
            })
            .catch((err) => {
                console.log("rcvCmd_PageGet(3) html2canvas err ", err);
            });
        }
    }
}

const rcvCmd_AndroidLogin = (userSeq) => {
    let myUserSeq = getMyUserSeq();
    if (userSeq === myUserSeq) {
        if (!getIsAlivePenCamService()) {
            setIsAlivePenCamService(true);
            if (clientInfo.funcProc) clientInfo.funcProc("connected");
            xmitCmd_AndroidStatusMessage("ct_LoginResp", enTokenCMD.ct_LoginResp, enTabStatus.Connected);
        }
    }
}

const rcvCmd_AndroidLoginResp = (userSeq) => {
    let myUserSeq = getMyUserSeq();
    if (userSeq === myUserSeq && getIsAlivePenCamService()) {
        if (getIsRunForSmartPen()) {
            setIsRunForSmartPen(false);
        } else {
            /* if (getIsHandsUpStarted()) { // handsup이 시작했을 경우에만 앱 연동시 화면 캡쳐를 시작하도록 한다... by hjkim 20221025
                xmitCmd_AndroidMessage("ct_LiveOn", enTokenCMD.NULL); // test용 임시
                xmitCmd_AndroidMessage("ct_StartHandsUp", enTokenCMD.NULL); // test용 임시
                androidHandsUpInitDone();
                xmitCmd_AndroidTouchScreenLock(); // test용 임시
            } else {
                console.log("rcvCmd_AndroidLoginResp - handsup is not started ... ");
            } */
            xmitCmd_AndroidInitDone();
        }
    }
}

const rcvCmd_AndroidLogout = (userSeq) => {
    if (getCurrentPageType() === enWebPage.GroupSmartTV) {
        setIsAlivePenCamService(false, userSeq);
        let idx = getIndexForGroupSmartTV(userSeq);
        if (idx > -1) {
            if (clientInfo.funcProcArr) {
                let funcProc = clientInfo.funcProcArr[idx].funcProc;
                if (funcProc) {
                    funcProc("disconnect");
                }
            }
        } else {
            if (clientInfo.funcProc) clientInfo.funcProc("disconnect");
        }
    } else {
        let myUserSeq = getMyUserSeq();
        if (userSeq === myUserSeq) {
            if (getIsSmartTVMode()) {
                setIsAlivePenCamService(false, userSeq);
                if (clientInfo.funcProc) clientInfo.funcProc("disconnect");
            } else {
                setIsAlivePenCamService(false);
                if (clientInfo.funcProc) clientInfo.funcProc("disconnected");
            }
        }
    }
}

const rcvCmd_AndroidLogoutResp = (userSeq) => {
    let myUserSeq = getMyUserSeq();
    if (userSeq === myUserSeq) {
        if (getIsAlivePenCamService()) {
            setIsAlivePenCamService(false);
            if (clientInfo.funcProc) clientInfo.funcProc("disconnected");
        }
    }
}

const rcvCmd_AndroidReconnResp = (userSeq) => {
    let myUserSeq = getMyUserSeq();
    if (userSeq === myUserSeq) {
        if (!getIsAlivePenCamService()) {
            setIsAlivePenCamService(true);
            if (clientInfo.funcProc) clientInfo.funcProc("connected");
            androidHandsUpInitDone();
        }
    }
}

const rcvCmd_AndroidSmartTVResponse = (userSeq) => {
    // console.log(`rcvCmd_AndroidSmartTVResponse - userSeq[${userSeq}]`);
    if (getCurrentPageType() === enWebPage.GroupSmartTV) {
        if (!getIsAlivePenCamService()) {
            setIsAlivePenCamService(true, userSeq);
            let store = window.hiclasstv.store;
            const { user, live, hiclasstv } = store.getState();
            // const { userSeq, userNickname } = user;
            const { smartTVLiveInfo } = live;
            const { list_isConnectedOnScreenApp } = hiclasstv;

            /* const connectedOnScreenApp_info = list_isConnectedOnScreenApp.find(info => info.userSeq === userSeq);
            if (connectedOnScreenApp_info) {
                xmitCmd_AndroidMessageForSmartTV("android", "ct_SmartTVResponse", enTokenCMD.ct_Response, userSeq, connectedOnScreenApp_info.userNickname, smartTVLiveInfo.liveSeq);
                if (clientInfo.funcProc) clientInfo.funcProc("connect");
            } */

            const connectedOnScreenApp_info = getConnectedOnScreenAppInfo(userSeq);
            if (connectedOnScreenApp_info) {
                xmitCmd_AndroidMessageForSmartTV("android", "ct_SmartTVResponse", enTokenCMD.ct_Response, userSeq, connectedOnScreenApp_info.userNickname, smartTVLiveInfo.liveSeq);
                let idx = connectedOnScreenApp_info.idx;
                if (idx > -1) {
                    if (clientInfo.funcProcArr) {
                        let funcProc = clientInfo.funcProcArr[idx].funcProc;
                        if (funcProc) {
                            funcProc("connect");
                        }
                    }
                } else {
                    if (clientInfo.funcProc) clientInfo.funcProc("connect");
                }
                // if (clientInfo.funcProc) clientInfo.funcProc("connect");
            }
        }
    } else {
        let myUserSeq = getMyUserSeq();
        if (userSeq === myUserSeq) {
            if (!getIsAlivePenCamService()) {
                setIsAlivePenCamService(true);
                let store = window.hiclasstv.store;
                const { user, live } = store.getState();
                const { userSeq, userNickname } = user;
                const { smartTVLiveInfo } = live;
                xmitCmd_AndroidMessageForSmartTV("android", "ct_SmartTVResponse", enTokenCMD.ct_Response, userSeq, userNickname, smartTVLiveInfo.liveSeq);
                if (clientInfo.funcProc) clientInfo.funcProc("connect");
            }
        }
    }
}

const rcvCmd_AndroidSmartTVSharedModeStatus = (tokenCmd, userSeq, status) => {
    console.log(`rcvCmd_AndroidSmartTVSharedModeStatus - tokenCmd[${tokenCmd}], userSeq[${userSeq}], status[${status}]`);
    let myUserSeq = getMyUserSeq();
    if (userSeq === myUserSeq && tokenCmd === enTokenCMD.ct_UpdateHandsUpImage) {
        if (getIsAlivePenCamService()) {
            if (clientInfo.funcProc) clientInfo.funcProc(status);
        }
    }
}

const rcvCmd_AndroidSmartTVMode = (tokenCmd, mode) => {
    console.log(`rcvCmd_AndroidSmartTVMode - tokenCmd[${tokenCmd}], mode[${mode}]`);
    
    let funcProc;
    let idx = -1;

    const connectedOnScreenApp_info = getConnectedOnScreenAppInfoWithIndex(0);  // userSeq 가 없는 경우에는 0번 index 사용 ... by hjkim 20240105
    if (connectedOnScreenApp_info) {
        idx = connectedOnScreenApp_info.idx;

        if (idx > -1) {
            funcProc = clientInfo.funcProcArr[idx].funcProc;
        } else {
            funcProc = clientInfo.funcProc;
        }
    } else {
        funcProc = clientInfo.funcProc;
    }

    if (tokenCmd === enTokenCMD.ct_Poll) {
        if (funcProc) funcProc(mode, idx);
    }

    /* if (getIsAlivePenCamService()) {
        if (funcProc) funcProc(mode, idx);

        // if (tokenCmd === enTokenCMD.ct_Poll) {
        //     if (funcProc) funcProc(mode, idx);
        // }
    } else {
        if (funcProc) funcProc(mode);

        // if (tokenCmd === enTokenCMD.ct_Poll) {
        //     if (funcProc) funcProc(mode, idx);
        // }
    } */

    /* if (getCurrentPageType() === enWebPage.GroupSmartTV) {
        const connectedOnScreenApp_info = getConnectedOnScreenAppInfoWithIndex(0);  // userSeq 가 없는 경우에는 0번 index 사용 ... by hjkim 20240105 
    } else {
        if (tokenCmd === enTokenCMD.ct_Poll) {
            if (clientInfo.funcProc) clientInfo.funcProc(mode);        
        }
    } */
}

const rcvCmd_AndroidSmartTVStatus = (tokenCmd, userSeq, status) => {
    console.log(`rcvCmd_AndroidSmartTVStatus - tokenCmd[${tokenCmd}], userSeq[${userSeq}], status[${status}]`);
    let myUserSeq = getMyUserSeq();
    if (userSeq === myUserSeq) {
        
        let funcProc;
        let idx = -1;

        const connectedOnScreenApp_info = getConnectedOnScreenAppInfo(userSeq);
        if (connectedOnScreenApp_info) {
            idx = connectedOnScreenApp_info.idx;

            if (idx > -1) {
                funcProc = clientInfo.funcProcArr[idx].funcProc;
            } else {
                funcProc = clientInfo.funcProc;
            }
        } else {
            funcProc = clientInfo.funcProc;
        }

        if (getIsAlivePenCamService()) {
            if (funcProc) funcProc(status, idx);

            // if (tokenCmd === enTokenCMD.ct_UpdateHandsUpImage) {
            //     if (funcProc) funcProc(status, idx);
            // } else if (tokenCmd === enTokenCMD.ct_Response) {
            //     if (funcProc) funcProc(status, idx);
            // } else if (tokenCmd === enTokenCMD.ct_Poll) {
            //     if (funcProc) funcProc(status, idx);
            // }
        } else {
            if (funcProc) funcProc(status);

            // if (tokenCmd === enTokenCMD.ct_Poll) {
            //     if (funcProc) funcProc(status, idx);
            // }
        }

        /* if (getIsAlivePenCamService()) {
            if (getCurrentPageType() === enWebPage.GroupSmartTV) {
                const connectedOnScreenApp_info = getConnectedOnScreenAppInfo(userSeq);
                if (connectedOnScreenApp_info) {
                    let idx = connectedOnScreenApp_info.idx;
                    if (idx > -1) {
                        if (clientInfo.funcProcArr) {
                            let funcProc = clientInfo.funcProcArr[idx].funcProc;
                            if (funcProc) {
                                funcProc(status, idx);

                                // if (tokenCmd === enTokenCMD.ct_UpdateHandsUpImage) {
                                //     if (funcProc) funcProc(status);
                                // } else if (tokenCmd === enTokenCMD.ct_Response) {
                                //     if (funcProc) funcProc(status);
                                // } else if (tokenCmd === enTokenCMD.ct_Poll) {
                                //     if (funcProc) funcProc(status);
                                // }
                            }
                        }
                    }
                }
            } else {
                if (tokenCmd === enTokenCMD.ct_UpdateHandsUpImage) {
                    if (clientInfo.funcProc) clientInfo.funcProc(status);
                } else if (tokenCmd === enTokenCMD.ct_Response) {
                    if (clientInfo.funcProc) clientInfo.funcProc(status);
                } else if (tokenCmd === enTokenCMD.ct_Poll) {
                    if (clientInfo.funcProc) clientInfo.funcProc(status);
                }
            }
        } else {
            if (getCurrentPageType() === enWebPage.GroupSmartTV) {
                const connectedOnScreenApp_info = getConnectedOnScreenAppInfo(userSeq);
                if (connectedOnScreenApp_info) {
                    let idx = connectedOnScreenApp_info.idx;
                    if (idx > -1) {
                        if (clientInfo.funcProcArr) {
                            let funcProc = clientInfo.funcProcArr[idx].funcProc;
                            if (funcProc) {
                                funcProc(status, idx);

                                // if (tokenCmd === enTokenCMD.ct_Poll) {
                                //     if (funcProc) funcProc(status);
                                // }
                            }
                        }
                    }
                }
            } else {
                if (tokenCmd === enTokenCMD.ct_Poll) {
                    if (clientInfo.funcProc) clientInfo.funcProc(status);
                }
            }
        } */
    }
}

const rcvUserStatus = (roomSeq, userSeq, status, routingKey) => {
    let store = window.hiclasstv.store;
    // console.log("userStatusRcv-status:" + status);
    console.log(`rcvUserStatus ------ roomSeq[${roomSeq}], userSeq[${userSeq}], status[${status}], routingKey[${routingKey}]`);

    switch (status) {
        case ConstCode.LOGIN:
            // 로그인 (in hiclass)
            store.dispatch(mqLoginFriend({ userSeq }));
            store.dispatch(mqLoginChat({ userSeq }));
            break;

        case ConstCode.LOGOUT: {
            // 로그아웃
            let myUserSeq = getMyUserSeq();
            let myRoutingKey = getMyRoutingKey();
            let isMe = userSeq === myUserSeq ? true : false;
            let isForceLogout = myRoutingKey === routingKey ? true : false;
            let mode = myRoutingKey.endsWith(".0") ? "web" : "hiclass";

            store.dispatch(mqLogoutFriend({ userSeq }));
            store.dispatch(mqLogoutChat({ userSeq, isMe }));

            if (isMe && isForceLogout) {
                store.dispatch(forceLogOutInit({ userSeq, mode, isSuccessed: true, isLogoutSuccess: true }));
                window.history.pushState({ data: "push" }, "", "/main");
            }
        } break;

        case ConstCode.LOGIN_WEB:
            // 로그인 (in web)
            console.log("web login rbmq");
            break;

        case ConstCode.LIVE_INFO_INIT: {
            // 선생님이 로그아웃 => Live Init 해야함 (handsup 모드 등)
            let myUserSeq = getMyUserSeq();
            store.dispatch(mqLiveInfoInit({ liveSeq: roomSeq, teacherSeq: userSeq, userSeq: myUserSeq }));
        } break;

        case ConstCode.CANCEL_FRIEND_APPLY:
            // 친구 신청 취소 (hiclass -> web 또는 web -> hiclass 처리용)
            store.dispatch(mqCancelFriendMe({ userSeq }));
            break;

        case ConstCode.CANCELED_FRIEND_APPLY:
            // 친구 신청 취소됨
            store.dispatch(mqCancelFriend({ userSeq }));
            break;

        case ConstCode.REJECT_FRIEND_APPLY:
            // 친구 신청 거절 (hiclass -> web 또는 web -> hiclass 처리용)
            store.dispatch(mqRejectFriendMe({ userSeq }));
            break;

        case ConstCode.REJECTED_FRIEND_APPLY:
            // 친구 신청 거절
            store.dispatch(mqRejectFriend({ userSeq }));
            break;

        case ConstCode.REMOVE_FRIEND:
            // 친구 삭제
            store.dispatch(mqRemoveFriend({ userSeq }));
            break;

        case ConstCode.CHATROOM_JOIN:
            // 팀업 입장
            store.dispatch(mqUpdateFriendStatus({ userSeq, status: ConstData.USER_STATUS.STUDYING }));
            break;

        case ConstCode.CHATROOM_FINISH: {
            // 팀업 종료
            let myUserSeq = getMyUserSeq();
            let isMe = userSeq === myUserSeq ? true : false;
            store.dispatch(mqFinishChat({ roomSeq, userSeq, isMe }));
            store.dispatch(mqUpdateFriendStatus({ userSeq, status: ConstData.USER_STATUS.LOG_IN }));
        } break;

        case ConstCode.CHATROOM_EXIT:
            // 팀업 퇴장 (영구)
            store.dispatch(mqExitChat({ roomSeq, userSeq }));
            store.dispatch(mqUpdateFriendStatus({ userSeq, status: ConstData.USER_STATUS.LOG_IN }));
            break;

        case ConstCode.CHATROOM_REMOVE:
            // 팀업 삭제
            store.dispatch(mqRemoveChat({ roomSeq, userSeq }));
            break;

        case ConstCode.CHATROOM_MEMBER_REMOVE:
            // 팀업 멤버 삭제
            store.dispatch(mqRemoveChatMember({ roomSeq, userSeq }));
            break;

        case ConstCode.CHATROOM_MEMBER_REMOVED:
            // 팀업 멤버 퇴출
            store.dispatch(mqRemovedChatMember({ roomSeq, userSeq }));
            break;

        case ConstCode.LIVE_JOIN:
            // 클래스 참여
            store.dispatch(mqJoinLive({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.LIVE_EXIT:
            // 클래스 나가기
            store.dispatch(mqExitLive({ liveSeq: roomSeq, userSeq }));
            updateSmallGroupImage("disconnect", roomSeq, userSeq, null);
            break;

        case ConstCode.LIVE_ON:
            // 클래스 On
            store.dispatch(mqLiveOn({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.LIVE_OFF:
            // 클래스 off
            store.dispatch(mqClearPage({ cmd: enTokenCMD.rq_PageClear }));
            store.dispatch(mqLiveOff({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.LIVE_REMOVE:
            // 클래스 삭제
            store.dispatch(mqRemoveLive({ liveSeq: roomSeq }));
            break;

        case ConstCode.LIVE_MEMBER_REJECT:
            // 클래스 멤버 거절
            store.dispatch(mqRejectLiveMember({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.LIVE_MEMBER_REJECTED:
            // 클래스 멤버 거절됨
            store.dispatch(mqRejectedLiveMember({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.LIVE_MEMBER_REMOVE:
            // 클래스 멤버 삭제
            store.dispatch(mqRemoveLiveMember({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.LIVE_MEMBER_REMOVED:
            // 클래스 멤버 퇴출
            store.dispatch(mqRemovedLiveMember({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.HANDS_UP_MODE_START:
            // hands up 시작
            store.dispatch(mqHandsUpStart({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.HANDS_UP_MODE_STOP:
            // hands up 종료
            store.dispatch(mqHandsUpStop({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.HANDS_UP_ACTIVE: {
            // hands up active
            let myUserSeq = getMyUserSeq();
            store.dispatch(mqHandsUpActive({ liveSeq: roomSeq, userSeq: myUserSeq }));
        } break;

        case ConstCode.HANDS_UP_INACTIVE: {
            // hands up inactive
            let myUserSeq = getMyUserSeq();
            store.dispatch(mqHandsUpInactive({ liveSeq: roomSeq, userSeq: myUserSeq }));
        } break;

        case ConstCode.P2P_AUDIO_START: {
            // p2p audio start
            const { live } = store.getState();
            const { isMixedClassMember } = live;
            if (isMixedClassMember) {
                const { mixedClassInfo } = live;
                if (mixedClassInfo.localLiveSeq === roomSeq) {
                    startHandsUpAudioConsume(mixedClassInfo.serverLiveSeq, userSeq);
                } else {
                    console.log(`P2P_AUDIO_START - mixedClassInfo.localLiveSeq[${mixedClassInfo.localLiveSeq}], liveSeq[${roomSeq}]`);
                }
            } else {
                startHandsUpAudioConsume(roomSeq, userSeq);
            }
        } break;

        case ConstCode.P2P_AUDIO_STOP: {
            // p2p audio stop
            const { live } = store.getState();
            const { isMixedClassMember } = live;
            if (isMixedClassMember) {
                const { mixedClassInfo } = live;
                if (mixedClassInfo.localLiveSeq === roomSeq) {
                    stopHandsUpAudioConsume(mixedClassInfo.serverLiveSeq, userSeq);
                } else {
                    console.log(`P2P_AUDIO_STOP - mixedClassInfo.localLiveSeq[${mixedClassInfo.localLiveSeq}], liveSeq[${roomSeq}]`);
                }
            } else {
                stopHandsUpAudioConsume(roomSeq, userSeq);
            }
        } break;

        case ConstCode.P2P_SOUND_ON: {
            // p2p sound on
            const { live } = store.getState();
            const { isMixedClassMember } = live;
            if (isMixedClassMember) {
                const { mixedClassInfo } = live;
                if (mixedClassInfo.localLiveSeq === roomSeq) {
                    setHandsUpMicOn(mixedClassInfo.serverLiveSeq, userSeq);
                } else {
                    console.log(`P2P_SOUND_ON - mixedClassInfo.localLiveSeq[${mixedClassInfo.localLiveSeq}], liveSeq[${roomSeq}]`);
                }
            } else {
                setHandsUpMicOn(roomSeq, userSeq);
            }
        } break;

        case ConstCode.P2P_SOUND_OFF: {
            // p2p sound off
            const { live } = store.getState();
            const { isMixedClassMember } = live;
            if (isMixedClassMember) {
                const { mixedClassInfo } = live;
                if (mixedClassInfo.localLiveSeq === roomSeq) {
                    setHandsUpMicOff(mixedClassInfo.serverLiveSeq, userSeq);
                } else {
                    console.log(`P2P_SOUND_OFF - mixedClassInfo.localLiveSeq[${mixedClassInfo.localLiveSeq}], liveSeq[${roomSeq}]`);
                }
            } else {
                setHandsUpMicOff(roomSeq, userSeq);
            }
        } break;

        case ConstCode.P2P_MODE_RESTART: {
            // p2p 재시작
            const { live } = store.getState();
            const { isMixedClassMember } = live;
            if (isMixedClassMember) {
                const { mixedClassInfo } = live;
                if (mixedClassInfo.localLiveSeq === roomSeq) {
                    restartHandsUpP2PMode(mixedClassInfo.serverLiveSeq, [userSeq]);
                } else {
                    console.log(`P2P_MODE_RESTART - mixedClassInfo.localLiveSeq[${mixedClassInfo.localLiveSeq}], liveSeq[${roomSeq}]`);
                }
            } else {
                restartHandsUpP2PMode(roomSeq, [userSeq]);
            }
        } break;

        case ConstCode.P2P_SOUND_DISABLE: {
            // p2p sound button disable
            const { live } = store.getState();
            const { isMixedClassMember } = live;
            if (isMixedClassMember) {
                const { mixedClassInfo } = live;
                if (mixedClassInfo.localLiveSeq === roomSeq) {
                   setHandsUpMicDisable(mixedClassInfo.serverLiveSeq);
                } else {
                    console.log(`P2P_SOUND_DISABLE - mixedClassInfo.localLiveSeq[${mixedClassInfo.localLiveSeq}], liveSeq[${roomSeq}]`);
                }
            } else {
                setHandsUpMicDisable(roomSeq);
            }
        } break;

        case ConstCode.P2P_SOUND_ENABLE: {
            // p2p sound button enable
            const { live } = store.getState();
            const { isMixedClassMember } = live;
            if (isMixedClassMember) {
                const { mixedClassInfo } = live;
                if (mixedClassInfo.localLiveSeq === roomSeq) {
                    setHandsUpMicEnable(mixedClassInfo.serverLiveSeq);
                } else {
                    console.log(`P2P_SOUND_ENABLE - mixedClassInfo.localLiveSeq[${mixedClassInfo.localLiveSeq}], liveSeq[${roomSeq}]`);
                }
            } else {
                setHandsUpMicEnable(roomSeq);
            }
        } break;

        /* case ConstCode.VIEW_MODE_CAMERA:
            // 보낼 화면을 camera 화면으로 바꿈
            store.dispatch(mqChangeCameraView({ liveSeq: roomSeq, userSeq }));
            break; */

        /* case ConstCode.VIEW_MODE_SCREEN:
            // 보낼 화면을 screen 이미지로 바꿈
            store.dispatch(mqChangeScreenView({ liveSeq: roomSeq, userSeq }));
            break; */

        case ConstCode.HANDS_UP_ON:
            // 학생이 hans up 함
            store.dispatch(mqHandsUpOn({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.HANDS_UP_OFF:
            // 학생이 hans up 취소 또는 선생님이 학생의  hans up을 끔
            store.dispatch(mqHandsUpOff({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.SOUND_MUTE_MODE: {
            // sound mute mode
            const { live } = store.getState();
            const { isMixedClassMember } = live;
            if (isMixedClassMember) {
                const { mixedClassInfo } = live;
                if (mixedClassInfo.localLiveSeq === roomSeq) {
                    setHandsUpSoundMuteMode(mixedClassInfo.serverLiveSeq, userSeq);
                } else {
                    console.log(`SOUND_MUTE_MODE - mixedClassInfo.localLiveSeq[${mixedClassInfo.localLiveSeq}], liveSeq[${roomSeq}]`);
                }
            } else {
                setHandsUpSoundMuteMode(roomSeq, userSeq);
            }
        } break;

        case ConstCode.SOUND_LISTEN_MODE: {
            // sound listen mode
            const { live } = store.getState();
            const { isMixedClassMember } = live;
            if (isMixedClassMember) {
                const { mixedClassInfo } = live;
                if (mixedClassInfo.localLiveSeq === roomSeq) {
                    setHandsUpSoundListenMode(mixedClassInfo.serverLiveSeq, userSeq);
                } else {
                    console.log(`SOUND_LISTEN_MODE - mixedClassInfo.localLiveSeq[${mixedClassInfo.localLiveSeq}], liveSeq[${roomSeq}]`);
                }
            } else {
                setHandsUpSoundListenMode(roomSeq, userSeq);
            }
        } break;

        case ConstCode.DISPLAY_ON:
            // display on
            store.dispatch(mqUpdateDisplayStatus({ studioSeq: roomSeq, displaySeq: userSeq, status: "Y" }));
            break;

        case ConstCode.DISPLAY_OFF:
            // display off
            store.dispatch(mqUpdateDisplayStatus({ studioSeq: roomSeq, displaySeq: userSeq, status: "N" }));
            break;

        default:
            console.log("status is default... -> ", status);
            break;
    }
}

/**
 * RestApi or HandsUp 창 or Frontend 에서 온 데이터 처리
 * @param {Object} dataObject       JsonObject로 kind, action, information으로 구성됨
 */
const rcvJsonData = (dataObject, userNickname) => {
    const { kind, action, information } = dataObject;
    let store = window.hiclasstv.store;
    console.log("jsonDataRcv() - dataObject => ", dataObject);

    if (kind === "user") {
        if (action === "logout") {
            const { userSeq } = information;
            store.dispatch(mqLogoutFriend({ userSeq }));
            store.dispatch(mqLogoutChat({ userSeq, isMe: userSeq === getMyUserSeq() }));
        } else if (action === "force-logout") {
            const { userSeq, mode } = information;
            if (userSeq === getMyUserSeq()) {
                store.dispatch(forceLogOutInit({ userSeq, mode, isSuccessed: true, isLogoutSuccess: true }));
                window.history.pushState({ data: "push" }, "", "/main");
            }
        } else if (action === "create") {
            const { update_info } = information;
            // friend, chatting, live에 모두 처리하기
            store.dispatch(mqUpdateFriendInfo({ update_info }));
            store.dispatch(mqUpdateChatMemberInfo({ update_info }));
            store.dispatch(mqUpdateLiveMemberInfo({ update_info }));
        } else if (action === "update") {
            const { update_info } = information;
            // friend, chatting, live에 모두 처리하기
            store.dispatch(mqUpdateInfo({ update_info }));
            store.dispatch(mqUpdateFriendInfo({ update_info }));
            store.dispatch(mqUpdateChatMemberInfo({ update_info }));
            store.dispatch(mqUpdateLiveMemberInfo({ update_info }));
        } else {
            console.log("action is else... ", action);
        }
    } else if (kind === "chatting") {
        if (action === "create") {
            const { chatRoom_info } = information;
            store.dispatch(mqCreateChat({ chatRoom_info }));
        } else if (action === "update") {
            const { chatRoom_info } = information;
            store.dispatch(mqUpdateChat({ chatRoom_info }));
        } else if (action === "update-name") {
            const { chatRoom_info } = information;
            store.dispatch(mqNameUpdateChat({ chatRoom_info }));
        } else if (action === "update-mode") {
            const { chatRoom_info } = information;
            store.dispatch(mqModeUpdateChat({ chatRoom_info }));
        } else if (action === "update-type") {
            const { chatRoom_info } = information;
            store.dispatch(mqTypeUpdateChat({ chatRoom_info }));
        } else if (action === "join") {
            const { join_info } = information;
            const { chatRoomSeq, userSeq } = join_info;
            let myUserSeq = getMyUserSeq();
            let isMe = userSeq === myUserSeq ? true : false;
            store.dispatch(mqJoinChat({ join_info, isMe }));
            // store.dispatch({ type : SAGA_JOIN, payload : { join_info }});
        } else if (action === "invite") {
            const { chatRoom_info } = information;
            store.dispatch(mqInviteChatMember({ chatRoom_info }));
        } else if (action === "add-member") {
            const { add_info } = information;
            store.dispatch(mqAddChatMember({ add_info }));
        } else {
            console.log("action is else... ", action);
        }
    } else if (kind === "friend") {
        if (action === "add") {
            const { user_info } = information;
            store.dispatch(mqAddFriend({ user_info }));
        } else if (action === "rcv-apply") {
            const { user_info } = information;
            store.dispatch(mqApplyFriend({ user_info }));
        } else if (action === "send-apply") {
            const { user_info } = information;
            store.dispatch(mqSendApplyFriend({ user_info }));
        } else if (action === "multiple-apply") {
            const { list_user } = information;
            store.dispatch(mqMultipleApplyFriend({ list_user }));
        } else if (action === "multiple-add") {
            const { list_user } = information;
            store.dispatch(mqMultipleAddFriend({ list_user }));
        } else {
            console.log("action is else... ", action);
        }
    } else if (kind === "live") {
        if (action === "live-on") {
            const { liveSeq, userSeq, isLiveChattingLocked, isAndroidTouchScreenLocked } = information;
            // 클래스 On
            console.log(`kind[${kind}] action[${action}] liveSeq[${liveSeq}] userSeq[${userSeq}] isLiveChattingLocked[${isLiveChattingLocked}] isAndroidTouchScreenLocked[${isAndroidTouchScreenLocked}]`);
            store.dispatch(mqLiveOn({ liveSeq, userSeq, isLiveChattingLocked, isAndroidTouchScreenLocked }));
        } else if (action === "create") {
            const { live_info } = information;
            store.dispatch(mqCreateLive({ live_info }));
        } else if (action === "edit-name") {
            const { edit_info } = information;
            store.dispatch(mqEditLiveName({ edit_info }));
        } else if (action === "update-broadcastDate") {
            const { edit_info } = information;
            store.dispatch(mqUpdateBroadcastDate({ edit_info }));
        } else if (action === "apply-live") {
            const { apply_info } = information;
            store.dispatch(mqApplyLive({ apply_info }));
        } else if (action === "approve-member") {
            const { member_info } = information;
            store.dispatch(mqApproveLiveMember({ member_info }));
        } else if (action === "add-member") {
            const { member_info } = information;
            store.dispatch(mqAddLiveMember({ member_info }));
        } else if (action === "approve-live") {
            const { live_info } = information;
            // console.log("approve-live... ", live_info);
            store.dispatch(mqApproveLive({ live_info }));
        } else if (action === "broadcast-open") {
            const { liveSeq } = information;
            store.dispatch(mqBroadcastOn({ liveSeq }));
        } else if (action === "broadcast-close") {
            const { liveSeq } = information;
            store.dispatch(mqBroadcastOff({ liveSeq }));
        } else if (action === "chatting-lock") {
            const { liveSeq, userSeq, isLiveChattingLocked } = information;
            store.dispatch(mqChattingLock({ liveSeq, userSeq, isLiveChattingLocked }));
            store.dispatch(closeWhisperModal());
        } else if (action === "update-nickname") {
            const { user_info } = information;
            console.log(`kind[${kind}] action[${action}] information => `, information);
            store.dispatch(mqUpdateLiveMemberNickname({ user_info }));
        } else {
            console.log("action is else... ", action);
        }
    } else if (kind === "handsup") {
        if (action === "p2pstart") {
            const { liveSeq, list_user } = information;
            const { live } = store.getState();
            const { isMixedClassMember } = live;
            if (isMixedClassMember) {
                const { mixedClassInfo } = live;
                if (mixedClassInfo.localLiveSeq === liveSeq) {
                    handleHandsUpP2PMode_new(mixedClassInfo.serverLiveSeq, list_user);
                } else {
                    console.log(`kind[${kind}] action[${action}] mixedClassInfo.localLiveSeq[${mixedClassInfo.localLiveSeq}], liveSeq[${liveSeq}]`);
                }
            } else {
                handleHandsUpP2PMode_new(liveSeq, list_user);
            }
        } else if (action === "audioSelect") {
            const { liveSeq, list_user } = information;
            const { live } = store.getState();
            const { isMixedClassMember } = live;
            if (isMixedClassMember) {
                const { mixedClassInfo } = live;
                if (mixedClassInfo.localLiveSeq === liveSeq) {
                    initHandsUpAudioConsume(mixedClassInfo.serverLiveSeq, list_user);
                } else {
                    console.log(`kind[${kind}] action[${action}] mixedClassInfo.localLiveSeq[${mixedClassInfo.localLiveSeq}], liveSeq[${liveSeq}]`);
                }
            } else {
                initHandsUpAudioConsume(liveSeq, list_user);
            }
        } else if (action === "smallGroupStart") {
            const { bindKey, sendKey, list_user, liveSeq } = information;
            const { live } = store.getState();
            const { isMixedClassMember } = live;
            if (isMixedClassMember) {
                const { mixedClassInfo, performLiveInfo } = live;
                if (mixedClassInfo.localLiveSeq === liveSeq) {
                    if (performLiveInfo.liveSeq === mixedClassInfo.serverLiveSeq) {
                        connectSmallGroup(sendKey, bindKey);
                        handleGroupStudyMode(true, list_user, getMySmallGroupImageSendKey());
                        store.dispatch(mqSmallGroupStart({ list_user }));
                    } else {
                        console.log(`kind[${kind}] action[${action}] mixedClassInfo.serverLiveSeq[${mixedClassInfo.localLiveSeq}], performLiveInfo.liveSeq[${performLiveInfo.liveSeq}]`);
                    }
                } else {
                    console.log(`kind[${kind}] action[${action}] mixedClassInfo.localLiveSeq[${mixedClassInfo.localLiveSeq}], liveSeq[${liveSeq}]`);
                }
            } else {
                const { performLiveInfo } = live;
                if (performLiveInfo.liveSeq === liveSeq) {
                    connectSmallGroup(sendKey, bindKey);
                    handleGroupStudyMode(true, list_user, getMySmallGroupImageSendKey());
                    store.dispatch(mqSmallGroupStart({ list_user }));
                }
            }
        } else if (action === "smallGroupEnd") {
            console.log(`kind[${kind}] action[${action}] information => `, information);
            disconnectSmallGroup();
            handleGroupStudyMode(false, []);
            store.dispatch(mqSmallGroupStop());
        } else {
            console.log("action is else... ", action);
        }
    } else if (kind === "mixedClass") {
        if (action === "loginInfo") {
            information.mode = "web";
            store.dispatch(mixedClassLoginSuccess(information));
        } else if (action === "liveMemberList") {
            store.dispatch(updateMixedClassMemberList(information));
        } else if (action === "liveJoin") {
            store.dispatch(joinMixedClassLive(information));
        } else if (action === "liveFinish") {
            store.dispatch(finishMixedClassLive(information));
        } else if (action === "logout") {
            store.dispatch(mixedClassLogoutSuccess(information));
        }
    } else if (kind === "studio") {
        if (action === "assign-user") {
            const { studioSeq, userSeq, list_display } = information;
            store.dispatch(mqUpdateStudioUser({ studioSeq, userSeq, list_display }));
        } else if (action === "clear-user") {
            const { studioSeq } = information;
            store.dispatch(mqUpdateStudioUser({ studioSeq, userSeq: -1 }));
        } else if (action === "assign-live") {
            const { studioSeq, userSeq, liveSeq } = information;
            store.dispatch(mqUpdateStudioLive({ studioSeq, userSeq, liveSeq }));
        } else if (action === "clear-live") {
            const { studioSeq, userSeq } = information;
            store.dispatch(mqUpdateStudioLive({ studioSeq, userSeq, liveSeq: -1 }));
        }
    } else if (kind === "display") {
        if (action === "changed-info") {
            const { displaySeq, prevInfo, assingedInfo } = information;
        }
    } else if (kind === "android") {
        if (action === "ct_Login") {
            const { userSeq } = information;
            console.log(`kind[${kind}] action[${action}] userSeq[${userSeq}]`);
            rcvCmd_AndroidLogin(userSeq);
        } else if (action === "ct_LoginResp") {
            const { userSeq, status } = information;
            console.log(`kind[${kind}] action[${action}] userSeq[${userSeq}] status[${status}]`);
            if (status === enTabStatus.Connected) {
                rcvCmd_AndroidLoginResp(userSeq);
            } else {
                console.log(`already connected pencam service`);
            }
        } else if (action === "ct_Logout") {
            const { userSeq } = information;
            console.log(`kind[${kind}] action[${action}] userSeq[${userSeq}]`);
            rcvCmd_AndroidLogout(userSeq);
        } else if (action === "ct_LogoutResp") {
            const { userSeq, status } = information;
            console.log(`kind[${kind}] action[${action}] userSeq[${userSeq}] status[${status}]`);
            rcvCmd_AndroidLogoutResp(userSeq);
        } else if (action === "ct_ReconnModeResp") {
            const { userSeq, status } = information;
            console.log(`kind[${kind}] action[${action}] userSeq[${userSeq}] status[${status}]`);
            rcvCmd_AndroidReconnResp(userSeq);
        } else if (action === "ct_SmartTVResponse") {
            const { userSeq, status } = information;
            console.log(`kind[${kind}] action[${action}] userSeq[${userSeq}] status[${status}]`);
            rcvCmd_AndroidSmartTVResponse(userSeq);
        } else if (action === "ct_TouchScreenLock" || action === "ct_TouchScreenUnLock") {
            const { liveSeq, userSeq, isAndroidTouchScreenLocked } = information;
            console.log(`kind[${kind}] action[${action}] liveSeq[${liveSeq}] userSeq[${userSeq}] isAndroidTouchScreenLocked[${isAndroidTouchScreenLocked}]`);
            store.dispatch(mqAndroidTouchScreenLock({ liveSeq, userSeq, isAndroidTouchScreenLocked }));
        }
    } else if (kind === "smart_tv") {
        console.log(`kind[${kind}], action[${action}] => `, information);
        if (action === "bindInfo") {
            console.log(`kind[${kind}], action[${action}] => `, information);
            const { isCanBind } = information;
            if (isCanBind) {
                // const { bindKeyArr, startSeqOfSmartTV, serverGateWay } = information;
                // connectLiveForSmartTV(bindKeyArr, startSeqOfSmartTV, serverGateWay);
                /* if (information.list_member) {
                    store.dispatch(mqRcvSmartTVBindInfo({ bindKeyArr, startSeqOfSmartTV, serverGateWay, list_member: information.list_member }));
                } else {
                    store.dispatch(mqRcvSmartTVBindInfo({ bindKeyArr, startSeqOfSmartTV, serverGateWay }));
                } */
                store.dispatch(mqRcvSmartTVBindInfo(information));
            } else {
                console.log(`kind[${kind}], action[${action}], isCanBind[${isCanBind}], information => `, information);
                alert("선생님이 해당 수업을 선택하지 않았습니다. 선생님께 문의하세요.");
                // window.history.back();
                window.location.reload();
            }
        } else if (action === "ct_SwitchTeacherFullMode") {
            const { tokenCmd } = information;
            // console.log(`kind[${kind}] action[${action}] tokenCmd[${tokenCmd}]`);
            console.log(`ct_SwitchTeacherFullMode - tokenCmd[${tokenCmd}]`);
            console.log(information);
            rcvCmd_AndroidSmartTVMode(tokenCmd, "media_full_mode");
        } else if (action === "ct_SwitchStudentFullMode") {
            const { tokenCmd } = information;
            // console.log(`kind[${kind}] action[${action}] tokenCmd[${tokenCmd}]`);
            console.log(`ct_SwitchStudentFullMode - tokenCmd[${tokenCmd}]`);
            console.log(information);
            rcvCmd_AndroidSmartTVMode(tokenCmd, "image_full_mode");
        } else if (action === "ct_SwitchStudentVideoResolution") {
            const { tokenCmd, userSeq, liveSeq, mode, smartTVTempKey } = information;
            // let isSmartTVMode = mode === "image_full_mode" ? true : false;
            // console.log(`ct_SwitchStudentVideoResolution - userSeq[${userSeq}], liveSeq[${liveSeq}], tokenCmd[${tokenCmd}], mode[${mode}], isSmartTVMode[${isSmartTVMode}]`);
            console.log(`ct_SwitchStudentVideoResolution - userSeq[${userSeq}], liveSeq[${liveSeq}], tokenCmd[${tokenCmd}], mode[${mode}], smartTVTempKey[${smartTVTempKey}]`);
            handleHandsUpSmartTVMode(liveSeq, userSeq, mode, smartTVTempKey);
        } else if (action === "ct_StartScreenCapture") {
            const { tokenCmd, userSeq } = information;
            console.log(`ct_StartScreenCapture - userSeq[${userSeq}], tokenCmd[${tokenCmd}]`);
            rcvCmd_AndroidSmartTVStatus(tokenCmd, userSeq, "shared-screen-mode");
        } else if (action === "ct_StopScreenCapture") {
            const { tokenCmd, userSeq } = information;
            console.log(`ct_StopScreenCapture - userSeq[${userSeq}], tokenCmd[${tokenCmd}]`);
            rcvCmd_AndroidSmartTVStatus(tokenCmd, userSeq, "shared-camera-mode");
        } else if (action === "ct_StopHandsUp" || action === "ct_LiveOff") {
            const { tokenCmd, userSeq } = information;
            console.log(`${action} - userSeq[${userSeq}], tokenCmd[${tokenCmd}]`);
            rcvCmd_AndroidSmartTVStatus(tokenCmd, userSeq, "shared-stop-mode");
        } else if (action === "ct_SmartTVLogout") {
            const { tokenCmd, userSeq, liveSeq, mode, smartTVTempKey } = information;
            // let isSmartTVMode = mode === "image_full_mode" ? true : false;
            // console.log(`ct_SwitchStudentVideoResolution - userSeq[${userSeq}], liveSeq[${liveSeq}], tokenCmd[${tokenCmd}], mode[${mode}], isSmartTVMode[${isSmartTVMode}]`);
            console.log(`ct_SmartTVLogout - userSeq[${userSeq}], liveSeq[${liveSeq}], tokenCmd[${tokenCmd}], mode[${mode}], smartTVTempKey[${smartTVTempKey}]`);
            handleHandsUpSmartTVMode(liveSeq, userSeq, false, smartTVTempKey);
        }
    } else if (kind === "quiz") {
        if (action === "sendQuizInfo") {
            const { live } = store.getState();
            const { performLiveInfo } = live;
            let profileImgUrl = "0";

            if (performLiveInfo.teacher_info !== undefined && performLiveInfo.teacher_info !== null) {
                profileImgUrl = performLiveInfo.teacher_info.profileImgUrl;

                const { quizSeq, data, msgIndex } = information;
                store.dispatch(mqRcvQuiz({ userNickname, data, quizSeq, msgIndex, iconSeq: parseInt(profileImgUrl) }));
            }
        } else if (action === "quizResponseFinish") {
            /* const { live } = store.getState();
            const { performLiveInfo} = live; */

            const { liveSeq, idx, quizSeq, list_member } = information;
            store.dispatch(mqRcvResponseFinishQuiz({ liveSeq, idx, quizSeq, list_member }));
        }
    } else {
        console.log("kind is else... ", kind);
    }
}

/**
 * Mediasoup 서버에서 온 데이터 처리
 * @param {Object} dataObject       JsonObject로 kind, action, rtnVal 으로 구성됨
 */
const rcvJsonMessage = (dataObject) => {
    const { kind, action, rtnVal } = dataObject;
    // console.log("jsonMessageRcv() => ", dataObject);

    if (action === "joinConsume") {
        joinConsumeAfter(rtnVal);
    } else if (action === "exitConsume") {
        exitConsumeAfter(rtnVal);
    } else if (action === "createConsumerTransport") {
        createConsumerTransportAfter(rtnVal);
    } else if (action === "connectConsumerTransport") {
        connectConsumerTransportAfter(rtnVal);
    } else if (action === "newProducer") {
        newProducerAfter(kind, rtnVal);
    } else if (action === "live_produce") {
        const { id, kind } = rtnVal;
        produceAfter(rtnVal);
        //produceCallback({ id });
        //sendEndProduceCallback(kind);
    } else if (action === "closeConsumer") {
        //const {kind,consumUserSeq} = rtnVal;
    } else if (action === "consume") {
        consumeAfter(rtnVal);
    } else if (action === "resumeConsumer") {

    } else if (action === "resume") {
        resumeAfter(rtnVal);
    } else if (action === "unsubscrib") {
        unsubscribeAfter(rtnVal);
    } else if (action === "closeProducer" || action === "live_closeProducer") {
        closeProducerAfter(kind, rtnVal);
    } else if (action === "live_createProducerTransport") {
        createProducerTransportAfter(rtnVal);
    } else if (action === "live_connectProducerTransport") {
        connectProducerTransportAfter();
    } else if (action === "live_unpublish") {
        unpublishAfter(rtnVal);
    } else if (action === "getState") {
        getStateAfter(rtnVal);
    }
}

export const rawDataWithRoutingKey = (rawData, routingKey) => {
    const dataObject = decodingPacket(rawData);
    // console.log("rawDataWithRoutingKey - ", dataObject);

    if (dataObject === undefined || dataObject === null) return;

    switch (dataObject.CMD) {
        case enTokenCMD.NULL:
            break;

        case enTokenCMD.Ink_SDn:
            drawInkStrokeDown(dataObject.CMD, dataObject.SPT);
            break;

        case enTokenCMD.Ink_SM: //decode 1point
            drawInkStrokeMove(dataObject.CMD, dataObject.SPT);
            break;

        case enTokenCMD.Ink_SDM: //decode 2point
            drawInkStrokeDoubleMove(dataObject.CMD, dataObject.SPT, dataObject.EPT);
            break;

        case enTokenCMD.Ink_SUp: //decode 1point
            drawInkStrokeUp(dataObject.CMD, dataObject.SPT);
            break;

        case enTokenCMD.Ink_DDn:
            rcvAndroidPenDown(dataObject.CMD, dataObject.SPT);
            break;

        case enTokenCMD.Ink_DM:
            rcvAndroidPenMove(dataObject.CMD, dataObject.SPT);
            break;

        case enTokenCMD.Ink_DDM:
            rcvAndroidPenDoubleMove(dataObject.CMD, dataObject.SPT, dataObject.EPT);
            break;

        case enTokenCMD.Ink_DUp:
            rcvAndroidPenUp(dataObject.CMD, dataObject.SPT);
            break;

        case enTokenCMD.Ink_Erase1: //decode 2point
            eraseInkStroke(dataObject.CMD, dataObject.SPT, dataObject.EPT);
            break;

        case enTokenCMD.Ink_SingleStroke:
            drawInkStroke(dataObject.stroke);
            break;

        case enTokenCMD.Ink_PageStroke:
            //여기 찍힘 !
            drawInkStrokes(dataObject.strokes, routingKey);
            break;

        case enTokenCMD.Ink_Clear: //decode command
            clearInkStroke(routingKey);
            break;

        case enTokenCMD.Ink_DefaultAttr:
            changeInkAttribute(dataObject.INT1, dataObject.BYTE1, dataObject.BYTE2, dataObject.BYTE3, dataObject.DOUBLE1, dataObject.DOUBLE2);
            break;

        case enTokenCMD.Ink_Mode: //decode 1byte
            changeInkMode(dataObject.byte1);
            break;

        case enTokenCMD.Ink_Color: //decode 3byte
            changeInkColor(dataObject.byte1, dataObject.byte2, dataObject.byte3);
            break;

        case enTokenCMD.Ink_Tip: //decode 2double
            changeInkTipSize(dataObject.double1, dataObject.double2);
            break;

        case enTokenCMD.page_PutArtifact:
        case enTokenCMD.cont_PageJpeg:
        case enTokenCMD.cont_StudentPageJpeg:
            // 이 command를 받는 경우 해당 user가 보낸 이미지를 받아서 내 화면에 뿌려야한다.
            rcvCmd_PagePut(dataObject.data, routingKey);
            break;

        case enTokenCMD.page_GetArtifact:
            // 이 command를 받는 경우 내 화면을 캡쳐해서 rest api로 쏴야한다.
            rcvCmd_PageGet("page_GetArtifact", enTokenCMD.page_PutArtifact);
            break;

        case enTokenCMD.cont_GetPageJpeg:
            rcvCmd_PageGet("cont_PageJpeg", enTokenCMD.cont_PageJpeg);
            break;

        case enTokenCMD.cont_GetStudentPageJpeg:
            rcvCmd_PageGet("cont_StudentPageJpeg", enTokenCMD.cont_StudentPageJpeg);
            break;

        case enTokenCMD.chat_FileName:
            rcvChatFile(dataObject.FileName, dataObject.name, routingKey);
            break;

        case enTokenCMD.chat_Text:
            rcvChatText(dataObject.name, dataObject.emoGIDno, dataObject.emoMIDno, dataObject.text, routingKey);
            break;

        case enTokenCMD.chat_Voice:
            rcvChatAudioMemo(dataObject.data, dataObject.name, routingKey);
            break;

        case enTokenCMD.chat_Memo:
            rcvChatInkMemo(dataObject.data, dataObject.name, routingKey);
            break;

        case enTokenCMD.ctl_PageInfo:
            //console.log("page info : ", dataObject);
            //chatPageScrollOffset(dataObject.data);
            break;

        case enTokenCMD.ct_UserStatusChange:
            rcvUserStatus(dataObject.INT1, dataObject.INT2, dataObject.BYTE1, routingKey);
            break;

        case enTokenCMD.ct_UploadAndShareFiles:
            rcvChatFile(dataObject.FileName, dataObject.name, routingKey);
            break;

        case enTokenCMD.ct_SendToWebJSonInfo:
            const obj = JSON.parse(dataObject.text);

            if (obj.kind !== undefined && obj.kind !== null && obj.information !== undefined && obj.information !== null) {
                rcvJsonData(obj, dataObject.name);
            } else {
                rcvJsonMessage(obj);
            }
            break;

        case enTokenCMD.ct_UpdateHandsUpImage:
            // console.log("enTokenCMD.ct_UpdateHandsUpImage - ", dataObject);
            if (getIsSmartTVMode()) {
                /* if (getIsAlivePenCamService()) {
                    if (getCurrentPageType() === enWebPage.GroupSmartTV) {
                        let userSeq = dataObject.INT2;
                        let store = window.hiclasstv.store;
                        const { hiclasstv } = store.getState();
                        const { list_isConnectedOnScreenApp } = hiclasstv;

                        const connectedOnScreenApp_info = list_isConnectedOnScreenApp.find(info => info.userSeq === userSeq);
                        if (connectedOnScreenApp_info) {
                            let idx = list_isConnectedOnScreenApp.indexOf(connectedOnScreenApp_info);
                            if (clientInfo.funcProc) clientInfo.funcProc("update", dataObject.data, idx);
                        }
                    } else {
                        if (clientInfo.funcProc) clientInfo.funcProc("update", dataObject.data);
                    }
                } */

                // console.log("getCurrentPageType - ", getCurrentPageType());

                if (getCurrentPageType() === enWebPage.GroupSmartTV) {
                    let userSeq = dataObject.INT2;
                    if (getIsAlivePenCamService(userSeq)) {
                        const connectedOnScreenApp_info = getConnectedOnScreenAppInfo(userSeq);
                        if (connectedOnScreenApp_info) {
                            let idx = connectedOnScreenApp_info.idx;
                            if (idx > -1) {
                                if (clientInfo.funcProcArr) {
                                    let funcProc = clientInfo.funcProcArr[idx].funcProc;
                                    if (funcProc) {
                                        funcProc("update", dataObject.data, idx);
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (getIsAlivePenCamService()) {
                        if (clientInfo.funcProc) clientInfo.funcProc("update", dataObject.data);
                    }
                }
            } else {
                updateSmallGroupImage("update", dataObject.INT1, dataObject.INT2, dataObject.data);
            }
            break;

        case enTokenCMD.rq_PageClear:
            rcvClearPage(dataObject.CMD);
            break;

        default:
            console.log(`rawDataWithRoutingKey default case ... CMD[${dataObject.CMD}]`, dataObject);
            break;
    }
}

export const rawDataWithRoutingKeyForPentalk = (rawData, routingKey) => {
    const rawBinSring = window.atob(rawData);
    const len = rawBinSring.length;
    let rawBinData = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        rawBinData[i] = rawBinSring.charCodeAt(i);
    }

    const dataObject = decodingPacket(rawBinData);
    // console.log(dataObject);

    switch (dataObject.CMD) {

    }
}