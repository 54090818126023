import React from 'react';
import { FaCog } from 'react-icons/fa';
import { FaSearch, FaUserPlus, FaUserCog } from 'react-icons/fa';

const FriendListTitleTemplate = ({ handleSelectedFunc, handleSelectedAudioInfo, selectedFunc, countRecommendFriends }) => {

    const addFriends = () => {
        if(selectedFunc === "ADD" || selectedFunc === "RECOMMEND" || selectedFunc === "KEYWORD_TOGGLE" || selectedFunc === "ID_TOGGLE") {
            selectedFunc = "NONE";    
        } else {
            selectedFunc = "ADD";
        }
        handleSelectedFunc({selectedFunc:selectedFunc});
    }

    const searchFriends = () => {
        if(selectedFunc === "SEARCH_TOGGLE") {
            selectedFunc = "NONE";    
        } else {
            selectedFunc = "SEARCH_TOGGLE";
        }
        handleSelectedFunc({selectedFunc:selectedFunc});
    }

    const editFriendsList = () => {
        if(selectedFunc === "EDIT") {
            selectedFunc = "NONE";    
        } else {
            selectedFunc = "EDIT";
        } 
        handleSelectedFunc({selectedFunc:selectedFunc});
    }

    const configAudioSpeakerDevice = () => {
        handleSelectedAudioInfo({ selectedFunc:"OPEN_SOUND_SETTING" });
    }

    return(
        <div className="title-area main justify-content-between hiclasstv-blue white-text">
            <b>내 정보</b>
            <div className="func-area right">
                { // sound control mode 가 아니어도 음향기기 설정 버튼이 보이도록 수정 by hjkim 20230503
                    // viewCMD === remoconType.SoundControl &&
                    <button type="button" title="음향기기 설정" onClick={configAudioSpeakerDevice}>
                        <FaCog className="d-flex font-md white-text" />
                    </button>
                }
            </div>
            {/*<b>친구</b>
            <div className="func-area">
                <button type="button" className={(selectedFunc === "SEARCH_TOGGLE" ? "select" : "")} title="검색" onClick={searchFriends}>
                    <FaSearch className="d-flex font-md white-text" />
                </button>
                <button type="button" className={(selectedFunc === "ADD" || selectedFunc === "RECOMMEND" || selectedFunc === "KEYWORD_TOGGLE" || selectedFunc === "ID_TOGGLE" ? " select" : "")} style={{position:'relative'}} title="친구 추가" onClick={addFriends}>
                    <FaUserPlus className="d-flex font-md white-text" />
                    {
                        countRecommendFriends > 0 &&
                            <span className="count">N</span>
                    }
                </button>
                <button type="button" className={(selectedFunc === "EDIT" ? " select" : "")} title="친구 관리" onClick={editFriendsList}>
                    <FaUserCog className="d-flex font-md white-text" />
                </button>
            </div>
            */}
        </div>
    );
}

export default FriendListTitleTemplate;