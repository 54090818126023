import React from 'react';
import { Modal } from 'reactstrap';

const LiveMediaAlertModal = ({ kind, mediaDevice_info, isAlertModalOpened, handleSelectedFunc }) => {
    return (
        <Modal isOpen={isAlertModalOpened} centered backdrop={false}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        <div className="d-flex flex-column">
                            <span>
                                사용 중이던 카메라와 연결에 문제가 발생하여 더 이상 사용할 수 없습니다.
                            </span>
                            {
                                kind === 'myCam' ? 
                                    <span className="small my-2">[확인] 버튼을 클릭해 주세요.</span> :
                                    <span className="small my-2">[확인] 버튼을 클릭하면 스크린을 전송합니다.</span>
                            }
                        </div>
                    </div>
                    <div className="content-area dark-grey-text">
                        <span className="small mb-2">[기기 이름]</span>
                        <div className="text-area text-center">
                            <span className="hiclass-text area-70" title={mediaDevice_info.label}>
                                <b>{mediaDevice_info.label}</b>
                            </span>
                        </div>
                    </div>
                    <button type="button" className="btn btn-md green white-text py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "CLOSE_LIVE_MEDIA_ALERT" })}>확인</button>
                </div>
            </div>
        </Modal>
    );
};

export default LiveMediaAlertModal;