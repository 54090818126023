import React from 'react';

import QRLogInContainer from '../containers/QRLogInContainer';

const WebQRLogInPage = ({ ...props }) => {
    return (
        <QRLogInContainer {...props} />
    );
};

export default WebQRLogInPage;