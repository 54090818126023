import React from 'react';
import { FaSearch, FaComment, FaCog } from 'react-icons/fa';

const ChatListTitleTemplate = ({ handleSelectedFunc, selectedFunc }) => {

    const createChatRoom = () => {
        if(selectedFunc === "CREATE") {
            selectedFunc = "NONE";    
        } else {
            selectedFunc = "CREATE";
        }
        handleSelectedFunc({selectedFunc:selectedFunc});
    }

    const searchChatList = () => {
        if(selectedFunc === "SEARCH") {
            selectedFunc = "NONE";    
        } else {
            selectedFunc = "SEARCH";
        }
        handleSelectedFunc({selectedFunc:selectedFunc});
    }

    const configChatList = () => {
        if(selectedFunc === "CONFIG") {
            selectedFunc = "NONE";    
        } else {
            selectedFunc = "CONFIG";
        } 
        handleSelectedFunc({selectedFunc:selectedFunc});
    }

    return(
        <div className="title-area main justify-content-between hiclasstv-blue white-text">
            <b>팀업 목록</b>
            <div className="func-area">
                <button type="button" className={(selectedFunc === "SEARCH" ? " select" : "")} title="검색" onClick={searchChatList}>
                    <FaSearch className="d-flex font-md white-text" />
                </button>
                <button type="button" className={(selectedFunc === "CREATE" ? " select" : "")} title="팀업 만들기" onClick={createChatRoom}>
                    <FaComment className="d-flex font-md white-text" />
                </button>
                <button type="button" className={(selectedFunc === "EDIT" ? " select" : "")} title="팀업 관리" onClick={configChatList}>
                    <FaCog className="d-flex font-md white-text" />
                </button>
            </div>
        </div>
    );
}

export default ChatListTitleTemplate;