import React, { useState, useEffect } from 'react';
import { FaTimes, FaMicrophone, FaVolumeUp } from 'react-icons/fa';

const checkSelectedMic = () => {
    
}

const checkSelectedSpeaker = () => {

}

const SoundDeviceChoice = ({ isMyMicEchoEnable, myMicInfo, teampUpSpeakerInfo, handsUpSpeakerInfo, handleSelectedFunc }) => {

    const [lMySubMicVol, setMySubMicVol] = useState(myMicInfo.volume);
    const [lPartnerSpeakerVol, setPartnerSpeakerVol] = useState(teampUpSpeakerInfo.volume);
    const [lHandsupPartnerSpeakerVol, setHandsupPartnerSpeakerVol] = useState(handsUpSpeakerInfo.volume);
    const [lMyMicEchoEnable, setMyMicEchoEnable] = useState(isMyMicEchoEnable);

    useEffect(() => {
        setMySubMicVol(myMicInfo.volume);
    }, [myMicInfo.volume]);

    useEffect(() => {
        setPartnerSpeakerVol(teampUpSpeakerInfo.volume);
    }, [teampUpSpeakerInfo.volume]);

    useEffect(() => {
        setHandsupPartnerSpeakerVol(handsUpSpeakerInfo.volume);
    }, [handsUpSpeakerInfo.volume]);

    useEffect(() => {
        setMyMicEchoEnable(isMyMicEchoEnable);
    }, [isMyMicEchoEnable]);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const selectMySubMicDevice = (e) => {
        if (e.target.value !== -1)
            handleSelectedFunc({ selectedFunc: "SET_MY_SUB_MIC", kind: "DEVICE", value: e.target.value, idx: e.target.selectedIndex });
    }

    const selectTeamupSpeakerDevice = (e) => {
        if (e.target.value !== -1)
            handleSelectedFunc({ selectedFunc: "SET_PARTNER_SPEAKER", kind: "DEVICE", value: e.target.value, idx: e.target.selectedIndex });
    }

    const selectHandsupSpeakerDevice = (e) => {
        if (e.target.value !== -1)
            handleSelectedFunc({ selectedFunc: "SET_HANDSUP_PARTNER_SPEAKER", kind: "DEVICE", value: e.target.value, idx: e.target.selectedIndex });
    }

    const changeMySubMicVolume = (e) => {
        //setMySubMicVol(e.target.value);
        handleSelectedFunc({ selectedFunc: "SET_MY_SUB_MIC", kind: "VOLUME", value: e.target.value });
    }

    const changedPartnerSpeakerVolume = (e) => {
        //setPartnerSpeakerVol(e.target.value);
        handleSelectedFunc({ selectedFunc: "SET_PARTNER_SPEAKER", kind: "VOLUME", value: e.target.value });
    }

    const changedHandsupPartnerSpeakerVolume = (e) => {
        //setPartnerSpeakerVol(e.target.value);
        handleSelectedFunc({ selectedFunc: "SET_HANDSUP_PARTNER_SPEAKER", kind: "VOLUME", value: e.target.value });
    }

    const onChangeMyMicEcho = (e) => {
        handleSelectedFunc({ selectedFunc: "SET_MY_MIC_ECHO", value: e.target.checked });
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area">
                <span>
                    <b>음향 기기 설정</b>
                </span>
                <button className="button p-1" style={{ position: 'absolute', right: '0' }} title="닫기" onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <FaTimes className="d-flex m-1" />
                </button>
            </div>
            <div className="list-group" style={{ overflow: 'auto' }}>
                <div className="d-flex flex-column list-group-hitem list-group-hitem-action m-0 p-2" style={{ height: 'initial', fontSize: '0.9rem' }}>
                    <div className="d-flex justify-content-between">
                        <span className="font-weight-bold grey-text">내가 보낼 소리</span>
                    </div>
                    <select
                        className="custom-select small my-1"
                        style={{ width: 'initial', fontSize: '0.9rem' }}
                        value={myMicInfo && myMicInfo.list_mic && myMicInfo.list_mic.length > 0 ? myMicInfo.list_mic[myMicInfo.selectedIndex].deviceId : ""}
                        onChange={selectMySubMicDevice}>
                        {
                            myMicInfo && myMicInfo.list_mic && myMicInfo.list_mic.length > 0 ?
                                myMicInfo.list_mic.map((info, index) => (
                                    <option value={info.deviceId} key={"mySubMic-" + index}>{info.label}</option>
                                )) :
                                <option value="-1">검색된 장치가 없습니다.</option>
                        }
                    </select>
                    <div className="d-flex align-items-center grey-text">
                        <FaMicrophone className="font-md mr-2" />
                        <input type="range" className="custom-range w-75" id="mySubMicSlider" min="0" max="100" defaultValue={lMySubMicVol} onChange={changeMySubMicVolume} disabled={myMicInfo && myMicInfo.list_mic && myMicInfo.list_mic.length <= 0} />
                        <label htmlFor="mySubMicSlider" className="m-0 mx-2"><b>{lMySubMicVol}</b></label>
                    </div>
                    <div className="d-flex align-items-end justify-content-end mt-1 grey-text">
                        <span>내 목소리 에코</span>
                        <div className="custom-control custom-switch ml-2">
                            <input type="checkbox" className="custom-control-input" id="customSwitch" checked={lMyMicEchoEnable} onChange={onChangeMyMicEcho} />
                            <label className="custom-control-label" htmlFor="customSwitch">&nbsp;</label>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column list-group-hitem list-group-hitem-action m-0 p-2" style={{ height: 'initial', fontSize: '0.9rem' }}>
                    <span className="font-weight-bold grey-text">팀업 멤버 소리 스피커 설정</span>
                    <select
                        className="custom-select small my-1"
                        style={{ width: 'initial', fontSize: '0.9rem' }}
                        value={teampUpSpeakerInfo && teampUpSpeakerInfo.list_speaker && teampUpSpeakerInfo.list_speaker.length > 0 ? teampUpSpeakerInfo.list_speaker[teampUpSpeakerInfo.selectedIndex].deviceId : ""}
                        onChange={selectTeamupSpeakerDevice}>
                        {
                            teampUpSpeakerInfo && teampUpSpeakerInfo.list_speaker && teampUpSpeakerInfo.list_speaker.length > 0 ?
                                teampUpSpeakerInfo.list_speaker.map((info, index) => (
                                    <option value={info.deviceId} key={"partnerSpeaker-" + index}>{info.label}</option>
                                )) :
                                <option value="-1">검색된 장치가 없습니다.</option>
                        }
                    </select>
                    <div className="d-flex align-items-center grey-text">
                        <FaVolumeUp className="font-md mr-2" />
                        <input type="range" className="custom-range w-75" id="teamupSpeakerSlider" min="0" max="100" defaultValue={lPartnerSpeakerVol} onChange={changedPartnerSpeakerVolume} disabled={teampUpSpeakerInfo && teampUpSpeakerInfo.list_speaker && teampUpSpeakerInfo.list_speaker.length <= 0} />
                        <label htmlFor="teamupSpeakerSlider" className="m-0 mx-2"><b>{lPartnerSpeakerVol}</b></label>
                    </div>
                </div>
                <div className="d-flex flex-column list-group-hitem list-group-hitem-action m-0 p-2" style={{ height: 'initial', fontSize: '0.9rem' }}>
                    <span className="font-weight-bold grey-text">클래스 멤버 소리 스피커 설정</span>
                    <select 
                        className="custom-select small my-1" 
                        style={{ width: 'initial', fontSize: '0.9rem' }} 
                        value={handsUpSpeakerInfo && handsUpSpeakerInfo.list_speaker && handsUpSpeakerInfo.list_speaker.length > 0 ? handsUpSpeakerInfo.list_speaker[handsUpSpeakerInfo.selectedIndex].deviceId : ""} 
                        onChange={selectHandsupSpeakerDevice}>
                        {
                            handsUpSpeakerInfo && handsUpSpeakerInfo.list_speaker && handsUpSpeakerInfo.list_speaker.length > 0 ?
                                handsUpSpeakerInfo.list_speaker.map((info, index) => (
                                    <option value={info.deviceId} key={"handsupPartnerSpeaker-" + index}>{info.label}</option>
                                )) :
                                <option value="-1">검색된 장치가 없습니다.</option>
                        }
                    </select>
                    <div className="d-flex align-items-center grey-text">
                        <FaVolumeUp className="font-md mr-2" />
                        <input type="range" className="custom-range w-75" id="handsupSpeakerSlider" min="0" max="100" defaultValue={lHandsupPartnerSpeakerVol} onChange={changedHandsupPartnerSpeakerVolume} disabled={handsUpSpeakerInfo && handsUpSpeakerInfo.list_speaker && handsUpSpeakerInfo.list_speaker.length <= 0} />
                        <label htmlFor="handsupSpeakerSlider" className="m-0 mx-2"><b>{lHandsupPartnerSpeakerVol}</b></label>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SoundDeviceChoice;