import React from 'react';
import WebLiveJoinButton from '../button/WebLiveJoinButton';

import WebLiveStatus from './WebLiveStatus';

const WebWatchLiveInfo = ({ isGuestYn, broadcastOpenYN, live_info, handleSelectedFunc }) => {
    return (
        <tr className="d-flex">
            <td className="landscape-to-portrait area-80">
                <div className="list-row area-60">
                    <WebLiveStatus
                        isGuestYn={isGuestYn}
                        broadcastOpenYN={broadcastOpenYN}
                        liveStatus={live_info.liveStatus}
                        liveName={live_info.liveName} 
                    />
                </div>
                <div className="list-row area-40">
                    <div className="text-area">
                        <span className="hiclass-text hiclasstv-deepDarkgrey-text" title={live_info.teacher_info.userNickname}>
                            <b>{live_info.teacher_info.userNickname}</b>
                        </span>
                    </div>
                </div>
            </td>
            <td className="d-flex align-items-center justify-content-center area-20">
                <WebLiveJoinButton 
                    handleSelectedFunc={() => handleSelectedFunc({ selectedFunc: "JOIN", liveSeq: live_info.liveSeq, liveName: live_info.liveSeq })}
                />
            </td>
        </tr>
    );
}

export default WebWatchLiveInfo;