import React, { useState, useEffect, version } from 'react';
import { FaDownload } from 'react-icons/fa';

import { format } from 'date-fns';
import ConstData from '../../../lib/ConstData';

const WebHiClassDownload = ({ download_info, handleSelectedFunc }) => {
    const [lPentalkInfo, setPentalkInfo] = useState({ version: '', updateDT: '' });
    const [lPentalkWifiInfo, setPentalkWifiInfo] = useState({ version: '', updateDT: '' });
    const [lPentalkBoardInfo, setPentalkBoardInfo] = useState({ version: '', updateDT: '' });
    const [lPentalkManualInfo, setPentalkManualInfo] = useState({ version: '', updateDT: '' });
    const [lPentalkCloudManualInfo, setPentalkCloudManualInfo] = useState({ version: '', updateDT: '' });
    const [lPentalkWifiManualInfo, setPentalkWifiManualInfo] = useState({ version: '', updateDT: '' });
    const [lPenMgrInfo, setPenMgrInfo] = useState({ version: '', updateDT: '' });

    useEffect(() => {
        console.log('WebHiClassDownload useEffect');
        handleSelectedFunc({ selectedFunc: "GET_DOWNLOAD_INFO" });

        return () => {

        }
    }, []);

    useEffect(() => {
        if (download_info !== undefined && download_info !== null) {
            if (download_info.pentalk_info !== undefined && download_info.pentalk_info !== null) {
                let date = new Date(download_info.pentalk_info.updateDT);
                setPentalkInfo({ version: download_info.pentalk_info.version, updateDT: format(date, 'yyyy / MM / dd') });
            }

            if (download_info.pentalkWifi_info !== undefined && download_info.pentalkWifi_info !== null) {
                let date = new Date(download_info.pentalkWifi_info.updateDT);
                setPentalkWifiInfo({ version: download_info.pentalkWifi_info.version, updateDT: format(date, 'yyyy / MM / dd') });
            }

            if (download_info.pentalkBoard_info !== undefined && download_info.pentalkBoard_info !== null) {
                let date = new Date(download_info.pentalkBoard_info.updateDT);
                setPentalkBoardInfo({ version: download_info.pentalkBoard_info.version, updateDT: format(date, 'yyyy / MM / dd') });
            }

            if (download_info.pentalkCloudManual_info !== undefined && download_info.pentalkCloudManual_info !== null) {
                let date = new Date(download_info.pentalkCloudManual_info.updateDT);
                setPentalkCloudManualInfo({ version: download_info.pentalkCloudManual_info.version, updateDT: format(date, 'yyyy / MM / dd') });
            }

            if (download_info.pentalkManual_info !== undefined && download_info.pentalkManual_info !== null) {
                let date = new Date(download_info.pentalkManual_info.updateDT);
                setPentalkManualInfo({ version: download_info.pentalkManual_info.version, updateDT: format(date, 'yyyy / MM / dd') });
            }

            if (download_info.pentalkWifiManual_info !== undefined && download_info.pentalkWifiManual_info !== null) {
                let date = new Date(download_info.pentalkWifiManual_info.updateDT);
                setPentalkWifiManualInfo({ version: download_info.pentalkWifiManual_info.version, updateDT: format(date, 'yyyy / MM / dd') });
            }

            if (download_info.penMgr_info !== undefined && download_info.penMgr_info !== null) {
                let date = new Date(download_info.penMgr_info.updateDT);
                setPenMgrInfo({ version: download_info.penMgr_info.version, updateDT: format(date, 'yyyy / MM / dd') });
            }
        }
    }, [download_info]);

    const onClickDownload = (kind) => {
        let url = ConstData.CLOUD_HOST_ADD + ConstData.DOWNLOAD_DIR;
        let filename = "";

        switch (kind) {
            case "HICLASSTV_PENTALK":
                filename = "HiClassTVPentalk_Setup.zip";
                break;

            case "HICLASSTV_PENTALK_WIFI":
                filename = "PentalkWiFi_Setup.zip";
                break;

            case "PENTALK_BOARD":
                filename = "PentalkBoard_Setup.zip";
                break;

            case "HICLASSTV_PENTALK_MANUAL":
                filename = "HiClassTVPentalk_Manual.pdf";
                break;

            case "HICLASSTV_PENTALK_CLOUD_MANUAL":
                filename = "HiClassTVPentalk_Manual_Cloud.pdf";
                break;

            case "HICLASSTV_PENTALK_WIFI_MANUAL":
                filename = "HiClassTVPentalk_Manual_WiFi.pdf";
                break;

            case "HICLASS_PENCAM":
                filename = "HiClassPencam_Setup.zip";
                break;

            case "PENLAB_MGR":
                filename = "PenlabMgr.zip";
                break;

            default:
                break;
        }

        if (filename !== "" && filename !== null) {
            var link = document.createElement("a");
            link.href = url + filename;
            link.download = filename;
            link.click();
        } else {
            console.log("WebHiClassDownload() - onClickDownload url, filename error !");
        }
    }

    return (
        <div className="d-flex flex-column w-100" style={{ margin: '10px 0px', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
            <div className="d-flex justify-content-center align-items-center w-100" style={{ margin: '2rem 0' }}>
                <div className="d-flex flex-column w-100">
                    <div className="d-flex flex-wrap" style={{ fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                        <span className="hiclasstv-blue-text">소프트웨어&nbsp;</span>
                        <span className="hiclasstv-darkblue-text">다운로드</span>
                    </div>
                    <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                HiClassTV Pentalk
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" data-toggle="tooltip" title="HiClassTV Pentalk 다운로드" style={{ borderRadius: '50%' }} onClick={() => onClickDownload('HICLASSTV_PENTALK')}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                Version : {lPentalkInfo.version}<br />
                                Program Update date : {lPentalkInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2 mb-5">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                ▶ 비대면 협력 교육/협업과 인공지능 콘텐츠 등을 문자나 음성채팅을 통해 사용할 수 있도록 해 주는 메신저 기반 서비스<br />
                                ▶ 다운로드 후 압축을 풀고 설치하세요<br />
                                ▶ 기존 프로그램이 설치된 경우 기존 프로그램을 먼저 삭제하고 새 버전을 설치하세요<br />
                                ▶ Pentalk Audio Mixer 기능을 사용하기 위해서는 VB-Cable 설치를 권장합니다<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VB-Cable 홈페이지 : <a href="https://vb-audio.com/Cable/" target="_blank">https://vb-audio.com/Cable/</a>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                HiClassTV Pentalk WiFi
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" data-toggle="tooltip" title="Pentalk Wifi 다운로드" style={{ borderRadius: '50%' }} onClick={() => onClickDownload('HICLASSTV_PENTALK_WIFI')}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                Version : {lPentalkWifiInfo.version}<br />
                                Program Update date : {lPentalkWifiInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2 mb-5">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                ▶ 별도의 서버없이 HiClassTV Pentalk 기능을 사용할 수 있는 서비스 (* 일부 기능만 지원함)<br />
                                ▶ 다운로드 후 압축을 풀고 설치하세요<br />
                                ▶ 기존 프로그램이 설치된 경우 기존 프로그램을 먼저 삭제하고 새 버전을 설치하세요<br />
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                Pentalk Board
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" data-toggle="tooltip" title="Pentalk Board 다운로드" style={{ borderRadius: '50%' }} onClick={() => onClickDownload('PENTALK_BOARD')}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                Version : {lPentalkBoardInfo.version}<br />
                                Program Update date : {lPentalkBoardInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2 mb-5">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                ▶ PDF/3D 문서나 영상으로 펜톡과 함께 사용하며 강의를 할 수 있도록 해 주는 스마트티칭 서비스<br />
                                ▶ Pentalk Board와 Pencam OnAir가 함께 설치됩니다.<br />
                                ▶ 다운로드 후 압축을 풀고 설치하세요<br />
                                ▶ 기존 프로그램이 설치된 경우 기존 프로그램을 먼저 삭제하고 새 버전을 설치하세요
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                HiClassTV Pentalk Pro(Cloud) 매뉴얼
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" data-toggle="tooltip" title="HiClassTV Pentalk 매뉴얼" style={{ borderRadius: '50%' }} onClick={() => onClickDownload('HICLASSTV_PENTALK_CLOUD_MANUAL')}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                Version : HiClassTV Pentalk Pro(Cloud) (Ver {lPentalkCloudManualInfo.version})<br />
                                Program Update date : {lPentalkCloudManualInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2 mb-5">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                ▶ HiClassTV Pentalk Pro(Cloud) {lPentalkCloudManualInfo.version} 버전의 매뉴얼입니다.<br />
                                ▶ PDF Reader가 필요한 파일입니다.
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                HiClassTV Pentalk Pro 매뉴얼
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" data-toggle="tooltip" title="HiClassTV Pentalk 매뉴얼" style={{ borderRadius: '50%' }} onClick={() => onClickDownload('HICLASSTV_PENTALK_MANUAL')}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                Version : HiClassTV Pentalk Pro (Ver {lPentalkManualInfo.version})<br />
                                Program Update date : {lPentalkManualInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2 mb-5">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                ▶ HiClassTV Pentalk Pro {lPentalkManualInfo.version} 버전의 매뉴얼입니다.<br />
                                ▶ PDF Reader가 필요한 파일입니다.
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                HiClassTV Pentalk WiFi 매뉴얼
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" data-toggle="tooltip" title="HiClassTV Pentalk Wifi 매뉴얼" style={{ borderRadius: '50%' }} onClick={() => onClickDownload('HICLASSTV_PENTALK_WIFI_MANUAL')}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                Version : HiClassTV Pentalk WiFi (Ver {lPentalkWifiManualInfo.version})<br />
                                Program Update date : {lPentalkWifiManualInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2 mb-5">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                ▶ HiClassTV Pentalk WiFi {lPentalkWifiManualInfo.version} 버전의 매뉴얼입니다.<br />
                                ▶ PDF Reader가 필요한 파일입니다.
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column mt-4 w-100">
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                PenlabMgr
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" data-toggle="tooltip" title="Penlab 매니저 프로그램 다운로드" style={{ borderRadius: '50%' }} onClick={() => onClickDownload('PENLAB_MGR')}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                Version : {lPenMgrInfo.version}<br />
                                Program Update date : {lPenMgrInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                ▶ 전자펜을 Windows 10의 표준 Stylus로 작동하게 하여 MS Office 등 모든 SW에서 사용할 수 있게 해 주는 드라이버 및 매니저<br />
                                ▶ 다운로드 후 압축을 풀고 설치하세요<br />
                                ▶ 펜 관리자 프로그램을 설치할 때 제공된 Bluetooth 동글을 PC에 먼저 연결하세요<br />
                                ▶ 기존 프로그램이 설치된 경우 기존 프로그램을 먼저 삭제하고 새 버전을 설치하세요
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default WebHiClassDownload;