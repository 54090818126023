import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';

import createRequestThunk from '../lib/createRequestThunk';
import tossPhaseAction from '../lib/tossPhaseAction';

import { get_all_lecture_list } from '../lib/api/lecture';

import { WATSON_STATE_INIT } from './watson';
import ConstData from '../lib/ConstData';

export const LECTURE_INIT                   = 'lecture/LECTURE_INIT';
export const LECTURE_STATE_INIT             = 'lecture/LECTURE_STATE_INIT';
export const CLEAR_PERFORM_LECTURE          = 'lecture/CLEAR_PERFORM_LECTURE';

const GET_ALL_LECTURE_LIST                  = 'lecture/GET_ALL_LECTURE_LIST';
const GET_ALL_LECTURE_LIST_SUCCESS          = 'lecture/GET_ALL_LECTURE_LIST_SUCCESS';

const SELECT_LECTURE                        = 'lecture/SELECT_LECTURE';
const UNSELECT_LECTURE                      = 'lecture/UNSELECT_LECTURE';

const SELECT_LECTURE_AUTO_LOGIN             = 'lecture/SELECT_LECTURE_AUTO_LOGIN';

const TOGGLE_MODAL_LECTURE_ALERT            = 'lecture/TOGGLE_MODAL_LECTURE_ALERT';
const TOGGLE_NEED_GOTO_HOME                 = 'lecture/TOGGLE_NEED_GOTO_HOME';
const TOGGLE_INVALID_LECTURE_CODE_MODAL     = 'lecture/TOGGLE_INVALID_LECTURE_CODE_MODAL';

export const getLectureListByKind           = createRequestThunk(GET_ALL_LECTURE_LIST, get_all_lecture_list);

export const selectLecture                  = tossPhaseAction(SELECT_LECTURE, [WATSON_STATE_INIT]);
export const unselectLecture                = tossPhaseAction(UNSELECT_LECTURE, [WATSON_STATE_INIT]);
export const clearPerformLectureInfo        = createAction(UNSELECT_LECTURE);

export const selectLectureWithAutoLogin     = createAction(SELECT_LECTURE_AUTO_LOGIN);
export const toggleModalLectureAlert        = createAction(TOGGLE_MODAL_LECTURE_ALERT);
export const toggleIsNeedGoToHome           = createAction(TOGGLE_NEED_GOTO_HOME);
export const toggleInvalidLectureCodeModal  = createAction(TOGGLE_INVALID_LECTURE_CODE_MODAL);

const initialState = {
    isLectureLive                   : false,
    isLectureAlertModalOpened       : false,
    isInvalidLectureCodeModalOpened : false,

    isNeedGoToHome                  : false,

    list_lecture                    : [],
    list_reviewLecture              : [],

    /** 임시 데이터.. 나중에는 list_lecture안에...  */
    list_card                       : [],

    performLectureInfo              : {
        credentialCode              : "",
        stepSeq                     : -1,
        lectureCode                 : "",
        lectureKind                 : "",
        lectureName                 : ""
    },

    isQuizListMode                  : true,
    list_quiz                       : [],
    quiz_info                       : "",

    alertKind                       : ConstData.ALERT_KIND.NONE,
    alertMessage                    : ""
};

const lecture = handleActions({
    [LECTURE_INIT]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {  // 데이터를 가져온 경우
            const { isLoginSuccess } = action.payload;

            if (isLoginSuccess) {
                const { list_lecture, list_reviewLecture } = action.payload;
                let clist_lecture = [];
                let clist_reviewLecture = [];

                if (list_lecture !== undefined && list_lecture !== null) {
                    clist_lecture = list_lecture.map(row => ({
                        lectureKind: row.LECTURE_KIND,
                        lectureCode: row.LECTURE_CODE,
                        lectureName: row.LECTURE_NM,
                        credentialCode: row.CRDNT_CODE === null ? "" : row.CRDNT_CODE,
                        stepSeq: row.STEP_SEQ === null ? -1 : row.STEP_SEQ,
                    }));
                }

                if (list_reviewLecture !== undefined && list_reviewLecture !== null) {
                    clist_reviewLecture = list_reviewLecture.map(row => ({
                        lectureKind: row.LECTURE_KIND,
                        lectureCode: row.LECTURE_CODE,
                        lectureName: row.LECTURE_NM,
                        credentialCode: row.CRDNT_CODE === null ? "" : row.CRDNT_CODE,
                        stepSeq: row.STEP_SEQ === null ? -1 : row.STEP_SEQ,
                    }));
                }
                
                return {
                    ...state,
                    //list_lecture: clist_lecture,
                    list_lecture : state.list_lecture.concat(clist_lecture),
                    list_reviewLecture: clist_reviewLecture
                }
            } else {
                return {
                    ...state
                }
            }
        } else {    // 데이터를 못가져온 경우
            return {
                ...state
            }
        }
    },

    [LECTURE_STATE_INIT]: (state, action) => {
        const { } = action.payload;

        return {
            ...state,
            list_lecture        : [],
            list_reviewLecture  : [],
            isLectureLive       : false,
            isLectureAlertModalOpened : false,
            isInvalidLectureCodeModalOpened : false,
            isNeedGoToHome      : false,
            performLectureInfo  : {
                credentialCode  : "",
                stepSeq         : -1,
                lectureCode     : "",
                lectureKind     : "",
                lectureName     : ""
            },
            alertKind           : ConstData.ALERT_KIND.NONE,
            alertMessage        : ''
        }
    },

    [GET_ALL_LECTURE_LIST_SUCCESS]: (state, action) => {
        //console.log("GET_ALL_LECTURE_LIST_SUCCESS : ", action.payload);

        return {
            ...state
        }
    }, 

    [SELECT_LECTURE]: (state, action) => {
        if (action.payload !== undefined && action.payload !== null) {
            const { lectureCode, lectureKind } = action.payload;
            //console.log("SELECT_LECTURE", action.payload);

            if (lectureCode !== undefined && lectureCode !== null) {
                if (lectureKind === "watson" ||lectureKind === "dialog") { // lecture 종류 :: [watson]
                    return produce(state, draft => {
                        const lecture_info = state.list_lecture.find(info => info.lectureCode === lectureCode);
                        if (lecture_info !== undefined && lecture_info !== null) {
                            draft.performLectureInfo = {
                                lectureKind: lecture_info.lectureKind,
                                lectureCode: lecture_info.lectureCode,
                                lectureName: lecture_info.lectureName,
                                credentialCode: lecture_info.credentialCode,
                                stepSeq : lecture_info.stepSeq
                            }

                            draft.isLectureLive = true;
                        } else {
                            const reviewLecture_info = state.list_reviewLecture.find(info => info.lectureCode === lectureCode);
                            if (reviewLecture_info !== undefined && reviewLecture_info !== null) {
                                draft.performLectureInfo = {
                                    lectureKind: reviewLecture_info.lectureKind,
                                    lectureCode: reviewLecture_info.lectureCode,
                                    lectureName: reviewLecture_info.lectureName,
                                    credentialCode: reviewLecture_info.credentialCode,
                                    stepSeq: reviewLecture_info.stepSeq
                                }

                                draft.isLectureLive = true;
                            }
                        }
                    });
                } else if (lectureKind === 'card') {    // lecture 종류 :: [card]
                    return produce(state, draft => {
                        const lecture_info = state.list_lecture.find(info => info.lectureCode === lectureCode);
                        if (lecture_info !== undefined && lecture_info !== null) {
                            draft.performLectureInfo = {
                                lectureKind: lecture_info.lectureKind,
                                lectureCode: lecture_info.lectureCode,
                                lectureName: lecture_info.lectureName,
                                credentialCode: lecture_info.credentialCode,
                                stepSeq: lecture_info.stepSeq
                            }

                            draft.isLectureLive = true;
                        }
                    });
                } else {    // lecture 종류 :: [etc]
                    return {
                        ...state,
                    }
                }
            } else {
                //console.log("[START_LECTURE] err ----- 선택된 강의 없음");

                return {
                    ...state,
                }
            }
        } else {
            return {
                ...state
            }
        }
    },

    [UNSELECT_LECTURE]: (state, action) => {
        //console.log("UNSELECT_LECTURE");

        return {
            ...state,
            isLectureLive       : false,
            performLectureInfo  : {
                credentialCode  : "",
                stepSeq         : -1,
                lectureCode     : "",
                lectureKind     : "",
                lectureName     : ""
            },
        }
    },
    
    [CLEAR_PERFORM_LECTURE]: (state, action) => {
        //console.log("CLEAR_PERFORM_LECTURE");

        return {
            ...state,
            isLectureLive       : false,
            performLectureInfo  : {
                credentialCode  : "",
                stepSeq         : -1,
                lectureCode     : "",
                lectureKind     : "",
                lectureName     : ""
            },
        }
    },

    [SELECT_LECTURE_AUTO_LOGIN]: (state, action) => {
        const { lectureCode } = action.payload;
        //console.log('SELECT_LECTURE_AUTO_LOGIN - lectureCode => ', lectureCode);

        if (lectureCode !== undefined && lectureCode !== null) {
            const lecture_info = state.list_lecture.find(info => info.lectureCode === lectureCode);
            if (lecture_info !== undefined && lecture_info !== null) {
                if (lecture_info.lectureKind === 'watson') {    // lecture 종류 :: [watson]
                    return {
                        ...state,
                        performLectureInfo          : {
                            lectureKind             : lecture_info.lectureKind,
                            lectureCode             : lecture_info.lectureCode,
                            lectureName             : lecture_info.lectureName,
                            credentialCode          : lecture_info.credentialCode,
                            stepSeq                 : lecture_info.stepSeq
                        },
                        //isLectureLive               : true,
                        isLectureAlertModalOpened   : true
                    }
                } else {    // lecture 종류 :: [etc]
                    return {
                        ...state
                    }
                }
            } else {
                //console.log('SELECT_LECTURE_AUTO_LOGIN - lecture_info => ', lecture_info);
                return {
                    ...state,
                    isNeedGoToHome  : true,
                    alertKind       : ConstData.ALERT_KIND.DANGER,
                    alertMessage    : '유효하지 않은 강의코드입니다. 다른 강의를 선택해 주세요.',
                    isInvalidLectureCodeModalOpened : true
                }
            }
        } else {
            //console.log('SELECT_LECTURE_AUTO_LOGIN - lectureCode => ', lectureCode);
            return {
                ...state,
                isNeedGoToHome  : true
            }
        }
    },

    [TOGGLE_MODAL_LECTURE_ALERT]: (state, action) => {
        const { isModalOpened } = action.payload;

        return {
            ...state,
            alertKind       : isModalOpened ? state.alertKind : ConstData.ALERT_KIND.NONE,
            alertMessage    : isModalOpened ? state.alertMessage : '',
            isLectureAlertModalOpened   : isModalOpened
        }
    },

    [TOGGLE_NEED_GOTO_HOME]: (state, action) => {
        //console.log('TOGGLE_NEED_GOTO_HOME', action.payload);
        if (action.payload !== undefined && action.payload !== null) {
            const isNeedGoToHome = action.payload;

            if (isNeedGoToHome) {
                return {
                    ...state,
                    isNeedGoToHome                  : true,
                    isInvalidLectureCodeModalOpened : true,
                    alertKind                       : ConstData.ALERT_KIND.DANGER,
                    alertMessage                    : '유효하지 않은 강의코드입니다. 다른 강의를 선택해 주세요.'
                }
            } else {
                return {
                    ...state,
                    isNeedGoToHome  : false
                }
            }
        } else {
            //console.log('TOGGLE_NEED_GOTO_HOME - action.payload => ', action.payload);
            return {
                ...state
            }
        }
    },

    [TOGGLE_INVALID_LECTURE_CODE_MODAL]: (state, action) => {
        const { isModalOpened } = action.payload;

        return {
            ...state,
            alertKind       : isModalOpened ? state.alertKind : ConstData.ALERT_KIND.NONE,
            alertMessage    : isModalOpened ? state.alertMessage : '',
            isInvalidLectureCodeModalOpened : isModalOpened
        }
    },
}, initialState);

export default lecture;