import React, { useState, useEffect, useRef } from 'react';
import ConstData from '../../../lib/ConstData';
import Alerts from '../../etc/list/Alerts';

const FindPWCertifyPhone = ({ isExistUserInfo, handleSelectedFunc, isPhoneTestOK, isPhoneTestStart, alertKind, alertMessage }) => {

    const timer = useRef();

    const [phoneNumber, setPhoneNumber]                 = useState("");
    const [certiCode, setCertiCode]                     = useState("");
    const [isAliveCertifyTime, setIsAliveCertifyTime]   = useState(false);
    const [certifyTime, setCertifyTime]                 = useState(0);
    const [lIsPhoneTestOK, setIsPhoneTestOK]            = useState(isPhoneTestOK);
    const [lIsPhoneTestStart, setIsPhoneTestStart]      = useState(isPhoneTestStart);

    const [lAlertKind, setAlertKind]                    = useState(alertKind);
    const [lAlertMessage, setAlertMessage]              = useState(alertMessage);

    useEffect(() => {
        if (lIsPhoneTestStart && !isAliveCertifyTime) {
            handleSelectedFunc({ selectedFunc:"FALSE_TO_PHONE_CHECK_SEND", message:"인증 가능 시간 2분이 초과되었습니다. 인증번호를 다시 발급받으세요."});
        }
    }, [isAliveCertifyTime]);

    useEffect(() => {
        if (certifyTime > 0) {
            timer.current = setInterval(() => {
                setCertifyTime(c => c - 1);
            }, 1000);
        } else {
            if (certifyTime === -1) {

            } else if (certifyTime === -2) {
                handleSelectedFunc({ selectedFunc:"FLASE_TO_PHONE_CHECK_OK", message:"전화번호가 변경되었습니다. 인증을 다시 진행해 주세요."});
            } else {
                setIsAliveCertifyTime(false);
            }
        }
        if (timer.current !== null) {
            return () => clearInterval(timer.current);
        }
    }, [certifyTime]);

    useEffect (() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        if (lAlertKind !== alertKind) {
            setAlertKind(alertKind);
        }
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    useEffect(() => {
        if (isPhoneTestStart) {
            setCertifyTime(120);
            setIsAliveCertifyTime(true);
        }
        setIsPhoneTestStart(isPhoneTestStart);
    }, [isPhoneTestStart]);

    useEffect(() => {
        if (isPhoneTestOK) {
            setCertifyTime(-1);
        }
        setIsPhoneTestOK(isPhoneTestOK);
    }, [isPhoneTestOK]);

    useEffect(() => {
        if (isPhoneTestOK || isPhoneTestStart) {
            setCertifyTime(-2);
        }
    }, [phoneNumber]);

    const onChangePhoneNumber = (e) => {
        handleSelectedFunc({selectedFunc:"MESSAGE", message:"phoneCertification"});
        let str = autoHypenPhoneNumber(e.target.value);
        setPhoneNumber(str);
        //setPhoneNumber(e.target.value);
    }

    const onChangeCertiCode = (e) => {
        let str = e.target.value;
        str = str.replace(/[^0-9]/g, '');
        setCertiCode(str);
    }

    const checkCertiCodeValue = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
    }

    const checkPhoneNumber = () => {
        if (phoneNumber === "" || phoneNumber === '' || phoneNumber === null) {
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("휴대폰 번호를 입력해 주세요.");
        } else {
            handleSelectedFunc({ selectedFunc: "GET_CERTI", phoneNumber });
        }
    }
    
    const clickCertifyCode = () => {
        if (certiCode === "" || certiCode === '' || certiCode === null) {
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage("인증코드를 입력해 주세요.");
        } else {
            handleSelectedFunc({selectedFunc: "CONFIRM_CERTI", code: certiCode});
        }
    }


    const autoHypenPhoneNumber = (str) => {
        str = str.replace(/[^0-9]/g, '');
        var tmp = '';

        if (str.length < 4) {
            return str;
        } else if (str.length < 7) {
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3);
            return tmp;
        } else if (str.length < 11) {
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 3);
            tmp += '-';
            tmp += str.substr(6);
            return tmp;
        } else {              
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 4);
            tmp += '-';
            tmp += str.substr(7);
            return tmp;
        }
    }

    const getDisp = () => {
        const ss = certifyTime % 60;
        const mm = Math.floor(certifyTime / 60);

        const str_s = "0" + parseInt(ss).toString();
        const str_m = "0" + parseInt(mm).toString();

        const str_time = str_m.substr(str_m.length - 2, 2) + ":" + str_s.substr(str_s.length - 2, 2);

        return str_time;
    }

    return (
        
        <div className="d-flex flex-column align-items-center justify-content-center my-2">
            <div className="d-flex flex-column font-md py-4 px-2">
                {
                    isExistUserInfo ? 
                        <>
                            <div className="d-flex align-items-center justify-content-center mb-2">
                                <input className="d-flex mr-1" type="radio" name="findIdRadios" id="defaultRadio" defaultChecked value="ID" />
                                회원 정보에 등록된 휴대전화로 인증
                            </div>
                            <div className="input-area">
                                <div className="input-data align-items-center">
                                    <input type="text" maxLength={13} id="findPwPn" className="d-flex flex-fill web-search-input mr-2 wmin-50" placeholder="휴대폰 번호를 입력하세요" onChange={onChangePhoneNumber} value={phoneNumber} />
                                    <button type="button" className="btn btn-sm btn-primary m-0 px-2 fit-content" onClick={() => checkPhoneNumber()}>
                                        인증번호 전송
                                    </button>
                                    {
                                        !lIsPhoneTestOK && lIsPhoneTestStart && isAliveCertifyTime &&
                                        <span className="text-center red-text small ml-2">
                                            {getDisp()}
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="input-area">
                                <div className="input-data align-items-center">
                                    {
                                        !lIsPhoneTestOK ?
                                            <input type="text" id="findPwCode" className="d-flex flex-fill web-search-input mr-2" placeholder="인증번호를 입력하세요" onChange={onChangeCertiCode} onKeyUp={checkCertiCodeValue} /> :
                                            <input type="text" id="findPwCode" className="d-flex flex-fill web-search-input mr-2" placeholder="인증번호를 입력하세요" disabled />
                                    }
                                    <button type="button" className="btn btn-sm btn-primary m-0 px-2" disabled={lIsPhoneTestStart === true ? false : true} onClick={clickCertifyCode}>
                                        인증번호 확인
                                    </button>
                                </div>
                            </div>
                            <Alerts kind={lAlertKind} message={lAlertMessage} />
                            {/* 위에 거에 스타일 적용해야할지 확인해보기 m-0 mt-2 */}
                        </> :
                        <span className="small">
                            고객님의 정보와 일치하는 회원정보가 없습니다.
                        </span>
                }
            </div>
            <div className="d-flex w-100 justify-content-center">
                {
                    isExistUserInfo ?
                        <>
                            <button className="btn btn-sm hiclasstv-grey px-3" onClick={() => handleSelectedFunc({selectedFunc:"PRE", step:ConstData.FIND_STEP.START})}>이전</button>
                            <button className="btn btn-sm btn-primary px-4" onClick={() => handleSelectedFunc({selectedFunc:"RESET_PW", phoneNumber:phoneNumber})} disabled={isPhoneTestOK === true ? false : true}>다음</button>
                            <button className="btn btn-sm hiclasstv-grey px-3" onClick={() => handleSelectedFunc({selectedFunc:"CLOSE"})}>취소</button>
                        </> :
                        <>
                            <button className="btn btn-sm hiclasstv-grey px-3" onClick={() => handleSelectedFunc({selectedFunc:"PRE", step:ConstData.FIND_STEP.START})}>이전</button>
                            {/*<button className="btn btn-sm btn-primary px-4" onClick={() => handleSelectedFunc({selectedFunc:"REGIST_TOGGLE"})}>회원가입</button>*/}
                            <button className="btn btn-sm hiclasstv-grey px-3" onClick={() => handleSelectedFunc({selectedFunc:"CLOSE"})}>취소</button>
                        </>
                }                
            </div>
        </div>
    );
}

export default FindPWCertifyPhone;