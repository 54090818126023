import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';

const PerformLiveBroadcastDateCount = ({ broadcastDateTime }) => {

    const [broadcastDT, setBroadcastDT] = useState(new Date());

    const [isReversalCountdown, setIsReversalCountdown] = useState(false);

    const [remainDay, setRemainDay] = useState(0);
    const [remainHour, setRemainHour] = useState(0);
    const [remainMinutes, setRemainMinutes] = useState(0);
    const [remainSeconds, setRemainSeconds] = useState(0);

    const [lastDay, setLastDay] = useState(0);
    const [lastHour, setLastHour] = useState(0);
    const [lastMinutes, setLastMinutes] = useState(0);
    const [lastSeconds, setLastSeconds] = useState(0);

    useEffect(() => {
        //let brdcstDate = broadcastDateTime.strt.replace(' ', '');
        let brdcstStartDate = broadcastDateTime.strt.replace(' ', '');
        let brdcstDT = new Date();
        
        brdcstDT.setFullYear(brdcstStartDate.substring(0, 4));
        brdcstDT.setMonth(brdcstStartDate.substring(4, 6) - 1);
        brdcstDT.setDate(brdcstStartDate.substring(6, 8));
        brdcstDT.setHours(brdcstStartDate.substring(8, 10));
        brdcstDT.setMinutes(brdcstStartDate.substring(10, 12));
        brdcstDT.setSeconds(brdcstStartDate.substring(12, 14));

        setBroadcastDT(brdcstDT);

        let nowDT = new Date(Date.now());

        if (nowDT > brdcstDT) {
            let remain = (nowDT - brdcstDT);
            setIsReversalCountdown(true);
            setLastDay(parseInt(remain / (1000 * 60 * 60 * 24)));
            setLastHour(parseInt((remain / (1000 * 60 * 60)) % 24));
            setLastMinutes(parseInt((remain / (1000 * 60)) % 60));
            setLastSeconds(parseInt((remain / 1000) % 60));

            console.log(parseInt(remain / (1000 * 60 * 60 * 24)), ' 일');
            console.log(parseInt((remain / (1000 * 60 * 60)) % 24), ' 시');
            console.log(parseInt((remain / (1000 * 60)) % 60), ' 분');
            console.log(parseInt((remain / (1000)) % 60), ' 초');
        } else {
            let remain = (brdcstDT - nowDT);
            setRemainDay(parseInt(remain / (1000 * 60 * 60 * 24)));
            setRemainHour(parseInt((remain / (1000 * 60 * 60)) % 24));
            setRemainMinutes(parseInt((remain / (1000 * 60)) % 60));
            setRemainSeconds(parseInt((remain / 1000) % 60));
            
            console.log(parseInt(remain / (1000 * 60 * 60 * 24)), ' 일');
            console.log(parseInt((remain / (1000 * 60 * 60)) % 24), ' 시');
            console.log(parseInt((remain / (1000 * 60)) % 60), ' 분');
            console.log(parseInt((remain / (1000)) % 60), ' 초');
        }
    }, []);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (parseInt(remainSeconds) > 0) {
                setRemainSeconds(parseInt(remainSeconds) - 1);
            }

            if (parseInt(remainSeconds) === 0) {
                if (parseInt(remainMinutes) === 0) {
                    if (parseInt(remainHour) === 0) {
                        if (parseInt(remainDay) === 0) {
                            clearInterval(countdown);
                            setIsReversalCountdown(true);
                        } else {
                            setRemainDay(parseInt(remainDay) - 1);
                            setRemainHour(24);
                        }
                    } else {
                        setRemainHour(parseInt(remainHour) - 1);
                        setRemainMinutes(59);
                    }
                } else {
                    setRemainMinutes(parseInt(remainMinutes) - 1);
                    setRemainSeconds(59);
                }
            }
        }, 1000);

        return () => clearInterval(countdown);
    }, [remainDay, remainHour, remainMinutes, remainSeconds]);
    
    useEffect(() => {
        const countdown = setInterval(() => {
            if (parseInt(lastSeconds) < 60) {
                setLastSeconds(parseInt(lastSeconds) + 1);
            }

            if (parseInt(lastSeconds) === 59) {
                if (parseInt(lastMinutes) === 59) {
                    if (parseInt(lastHour) === 23) {
                        setLastDay(parseInt(lastDay) + 1);
                        setLastHour(0);
                    } else {
                        setLastHour(parseInt(lastHour) + 1);
                        setLastMinutes(0);
                    }
                } else {
                    setLastMinutes(parseInt(lastMinutes) + 1);
                    setLastSeconds(0);
                }
            }
        }, 1000);

        return () => clearInterval(countdown);
    }, [lastDay, lastHour, lastMinutes, lastSeconds]);

    return (
        <div className="live-countdown">
            {
                isReversalCountdown ?
                    <>
                        지난 시간
                        <span className="ml-2 red-text">{lastDay}일 {lastHour}:{lastMinutes < 10 ? `0${lastMinutes}` : lastMinutes}:{lastSeconds < 10 ? `0${lastSeconds}` : lastSeconds}</span>
                    </> :
                    <>
                        남은 시간
                        <span className="ml-2 red-text">{remainDay}일 {remainHour}:{remainMinutes < 10 ? `0${remainMinutes}` : remainMinutes}:{remainSeconds < 10 ? `0${remainSeconds}` : remainSeconds}</span>
                    </>
            }
        </div>
    )
}

export default PerformLiveBroadcastDateCount;