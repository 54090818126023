import React from "react";

import { ctRcvKind } from "../../../lib/classTalk/CodeEnum";
import { stCardType } from "../../../lib/ConstCommand";

import QuizCardForTeacher from "../../lecture/card/QuizCardForTeacher";

function MKAutoLink(content) {

    var regURL = new RegExp("(http|https|ftp|telnet|news|irc)://([-/.a-zA-Z0-9_~#%$?&=:200-377()]+)", "gi");

    var regURL2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

    var rep = content.replace(regURL, "<span onclick=\"setWebView('$1://$2', 'link');\">$1://$2</span>").replace(regURL2, "<span onclick=\"setWebView('http://$2', 'link');\">http://$2</span>");

    var phoneRegex = /((\({0,1}(0|\+61){0,1}(\ |-){0,1}0{0,1}(2|4|3|7|8){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{5}))|((\({0,1}(0|\+61){0,1}(\ |-){0,1}0{0,1}(2|4|3|7|8){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{4}))|((\({0,1}(0|\+61){0,1}(\ |-){0,1}0{0,1}(2|4|3|7|8){0,1}\){0,1}(\ |-){0,1}[0-9]{3}(\ |-){0,1}[0-9]{3}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{4}))/g;

    rep = rep.replace(phoneRegex, "<span onclick=\"setWebView('$&', 'tel');\">$&</span>");

    return rep;

};

function regUrlType(data) {

	var regex = /^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?/;

	return regex.test(data);

}

function validURL(text) {
    console.log("validURL called");

    var res = text.match(/(?:(?:(https?|ftp|telnet):\/\/|[\s\t\r\n[\]`<>"'])((?:[\w$\-_.+!*'(),]|%[0-9a-f][0-9a-f])*:(?:[\w$\-_.+!*'(),;?&=]|%[0-9a-f][0-9a-f])+@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?::([0-9]+))?((?:\/(?:[\w$\-_.+!*'(),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s/?.:<>|#]*(?:\.[^\s/?:<>|#]+)*))?(\/?[?;](?:[a-z0-9-]+(?:=[^\s:&<>]*)?&)*[a-z0-9-]+(?:=[^\s:&<>]*)?)?(#[\w-]+)?)/gmi);

    console.log("res => ", res);

    var res_origin = text.match(/(?:(?:(https?|ftp|telnet):\/\/|[\s\t\r\n\[\]\`\<\>\"\'])((?:[\w$\-_\.+!*\'\(\),]|%[0-9a-f][0-9a-f])*\:(?:[\w$\-_\.+!*\'\(\),;\?&=]|%[0-9a-f][0-9a-f])+\@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9\-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?:\:([0-9]+))?((?:\/(?:[\w$\-_\.+!*\'\(\),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s\/\?\.:<>|#]*(?:\.[^\s\/\?:<>|#]+)*))?(\/?[\?;](?:[a-z0-9\-]+(?:=[^\s:&<>]*)?\&)*[a-z0-9\-]+(?:=[^\s:&<>]*)?)?(#[\w\-]+)?)/gmi);

    console.log("res_origin => ", res_origin);

    var regURL     = new RegExp("(http|https|ftp|telnet|news|irc)://([-/.a-zA-Z0-9_~#%$?&=:200-377()]+)", "gi");

    var regURL2    = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

    // var domExp = {
    //     dom : /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi,
    //     par : /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}([\/a-z0-9-%#?&=\w])+(\.[a-z0-9]{2,4}(\?[\/a-z0-9-%#?&=\w]+)*)*/gi
    // };

    var rUrlRegex = /(?:(?:(https?|ftp|telnet):\/\/|[\s\t\r\n\[\]\`\<\>\"\'])((?:[\w$\-_\.+!*\'\(\),]|%[0-9a-f][0-9a-f])*\:(?:[\w$\-_\.+!*\'\(\),;\?&=]|%[0-9a-f][0-9a-f])+\@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9\-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?:\:([0-9]+))?((?:\/(?:[\w$\-_\.+!*\'\(\),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s\/\?\.:<>|#]*(?:\.[^\s\/\?:<>|#]+)*))?(\/?[\?;](?:[a-z0-9\-]+(?:=[^\s:&<>]*)?\&)*[a-z0-9\-]+(?:=[^\s:&<>]*)?)?(#[\w\-]+)?)/gmi;
    console.log("rUrlRegex => ", text.match(rUrlRegex));

    const regp_1 = /(https?:\/\/[^ ]*)/gmi;
    console.log("regp_1 => ", text.match(regp_1));

    const regp_2 = /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi;
    console.log("rUrlRegex => ", text.match(rUrlRegex));

    const regp_3 = "\\b(https?|ftp|file)://[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]";
    console.log("regp_3 => ", text.match(new RegExp(regp_3, 'gmi')));

    const Rexp =
      /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?/=~_|!:,.;]*)[-A-Z0-9+&@#/%=~_|])/gi;

    const regp_4 = /((http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\wㄱ-ㅎㅏ-ㅣ가-힣\;\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?)/gi;
    const regp_5 = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gmi;
    const regp_6 = /(^|[^\/])(www\.[\S]+(\b|$))/gmi;
    const regp_7 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gmi;
    
    
    const regp_8 = /(?=.*^https)(?=.*com$).*/gmi;       // 문자열 중 두 개의 단어를 모두 포함하는지 검사        (※AND 검색)
    const regp_9 = /[^0-9]+/gmi;                        // [...] 내의 ^는 not의 의미를 가지므로 예시는 문자열 중 숫자를 제외한 문자를 검사 (※NOT 검색)
    const regp_10 = /^https/;                           // [...] 밖의 ^은 문자열의 시작의 의미를 가지므로 문자열 중 해당 단어로 시작하는지 검사 (※시작 위치로 검색)
    const regp_11 = /com$/;                             // $는 문자열의 마지막의 의미를 가지므로 문자열 중 해당 단어로 끝나는지 검사 (※마지막 위치로 검색)
    const regp_12 = /^(http|https):\/\//;               // 문자열이 http:// 또는 https:// 로 시작하는지 검사   (※특정 단어로 시작하는지 검색)
    const regp_13 = /^[\s]+/;                           // \s는 여러 가지 공백 문자를 의미(\s = [\t\r\n\v\f]). 문자열이 하나 이상의 공백으로 시작하는지 검사 (※하나 이상의 공백으로 시작하는지 검색)
    const regp_14 = /^[A-Za-z0-9]{4,10}$/;              // 문자열이 영어 대소문자, 숫자 포함 4~10자리인지 검사 (※아이디로 사용할 수 있는지 검색)
    const regp_15 = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*[a-zA-Z]{2,3}$/; // 문자열이 메일 주소 형식에 맞는지 검사 (※메일 주소 형식에 맞는지 검색)
    const regp_16 = /^\d{3}-\d{3,4}-\d{4}$/;            // 문자열이 핸드폰 번호 양식에 맞는지 검사 (※핸드폰 번호 양식에 맞는지 검색)
    const regp_17 = /[^A-Za-z0-9]/gi;                   // 문자열 중 특수 문자(A-Za-z0-9 이외의 문자)가 포함되어 있는지를 검사 (※특수 문자 포함 여부 검색)
    const regp_18 = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi; // 문자열 중 특수 문자(검사를 원하는 특수 문자를 선택적으로 검사)가 포함되어 있는지를 검사 (※특수 문자 포함 여부 검색)


    // const Rexp = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/gi;

    return (res);
}

const testCollectURL = (message) => {
    console.log("testCollectURL called - message => ", message);

    const rexp_1 = /(?:(?:(https?|ftp|telnet):\/\/|[\s\t\r\n[\]`<>"'])((?:[\w$\-_.+!*'(),]|%[0-9a-f][0-9a-f])*:(?:[\w$\-_.+!*'(),;?&=]|%[0-9a-f][0-9a-f])+@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?::([0-9]+))?((?:\/(?:[\w$\-_.+!*'(),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s/?.:<>|#]*(?:\.[^\s/?:<>|#]+)*))?(\/?[?;](?:[a-z0-9-]+(?:=[^\s:&<>]*)?&)*[a-z0-9-]+(?:=[^\s:&<>]*)?)?(#[\w-]+)?)/gmi;
    const rexp_2 = /(?:(?:(https?|ftp|telnet):\/\/|[\s\t\r\n\[\]\`\<\>\"\'])((?:[\w$\-_\.+!*\'\(\),]|%[0-9a-f][0-9a-f])*\:(?:[\w$\-_\.+!*\'\(\),;\?&=]|%[0-9a-f][0-9a-f])+\@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9\-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?:\:([0-9]+))?((?:\/(?:[\w$\-_\.+!*\'\(\),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s\/\?\.:<>|#]*(?:\.[^\s\/\?:<>|#]+)*))?(\/?[\?;](?:[a-z0-9\-]+(?:=[^\s:&<>]*)?\&)*[a-z0-9\-]+(?:=[^\s:&<>]*)?)?(#[\w\-]+)?)/gmi;
    const rexp_3 = new RegExp("(http|https|ftp|telnet|news|irc)://([-/.a-zA-Z0-9_~#%$?&=:200-377()]+)", "gmi");
    const rexp_4 = /(^|[^\/])(www\.[\S]+(\b|$))/gmi;
    const rexp_5 = /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gmi;
    const rexp_6 = /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}([\/a-z0-9-%#?&=\w])+(\.[a-z0-9]{2,4}(\?[\/a-z0-9-%#?&=\w]+)*)*/gmi;
    const rexp_7 = /(?:(?:(https?|ftp|telnet):\/\/|[\s\t\r\n\[\]\`\<\>\"\'])((?:[\w$\-_\.+!*\'\(\),]|%[0-9a-f][0-9a-f])*\:(?:[\w$\-_\.+!*\'\(\),;\?&=]|%[0-9a-f][0-9a-f])+\@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9\-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?:\:([0-9]+))?((?:\/(?:[\w$\-_\.+!*\'\(\),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s\/\?\.:<>|#]*(?:\.[^\s\/\?:<>|#]+)*))?(\/?[\?;](?:[a-z0-9\-]+(?:=[^\s:&<>]*)?\&)*[a-z0-9\-]+(?:=[^\s:&<>]*)?)?(#[\w\-]+)?)/gmi;
    const rexp_8 = /(https?:\/\/[^ ]*)/gmi;
    const rexp_9 = /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gmi;
    const rexp_10 = new RegExp("\\b(https?|ftp|file)://[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]", "gmi");
    const rexp_11 = /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?/=~_|!:,.;]*)[-A-Z0-9+&@#/%=~_|])/gmi;
    const rexp_12 = /((http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\wㄱ-ㅎㅏ-ㅣ가-힣\;\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?)/gmi;
    const rexp_13 = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gmi;
    const rexp_14 = /(^|[^\/])(www\.[\S]+(\b|$))/gmi;
    const rexp_15 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gmi;
    const rexp_16 = /(?=.*^https)(?=.*com$).*/gmi;
    const rexp_17 = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/gmi;
    const rexp_18 = new RegExp('(?:\\b(?:(?:(?:(ftp|https?|mailto|telnet):\\/\\/)?(?:((?:[\\w$\\-'
    + '_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+(?:\\:(?:[\\w$'
    + '\\-_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+)?)\\@)?((?'
    + ':[\\d]{1,3}\\.){3}[\\d]{1,3}|(?:[a-z0-9]+\\.|[a-z0-9][a-z0-9\\-]+'
    + '[a-z0-9]\\.)+(?:biz|com|info|name|net|org|pro|aero|asia|cat|coop|'
    + 'edu|gov|int|jobs|mil|mobi|museum|tel|travel|ero|gov|post|geo|cym|'
    + 'arpa|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|'
    + 'bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bw|by|bz|ca|cc|cd|cf|cg|ch'
    + '|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|e'
    + 'r|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|'
    + 'gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it'
    + '|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|l'
    + 't|lu|lv|ly|ma|mc|me|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|'
    + 'mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph'
    + '|pk|pl|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|s'
    + 'i|sk|sl|sm|sn|sr|st|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tr|'
    + 'tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|za|zm'
    + '|zw)|localhost)\\b(?:\\:([\\d]+))?)|(?:(file):\\/\\/\\/?)?([a-z]:'
    + '))(?:\\/((?:(?:[\\w$\\-\\.\\+\\!\\*\\(\\),;:@=ㄱ-ㅎㅏ-ㅣ가-힣]|%['
    + '0-9a-f][0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|'
    + 'reg);)*\\/)*)([^\\s\\/\\?:\\"\\\'<>\\|#]*)(?:[\\?:;]((?:\\b[\\w]+'
    + '(?:=(?:[\\w\\$\\-\\.\\+\\!\\*\\(\\),;:=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f]'
    + '[0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|reg);)*'
    + ')?\\&?)*))*(#[\\w\\-ㄱ-ㅎㅏ-ㅣ가-힣]+)?)?)', 'gmi');

    /**
     * x(?=y)
     * - Lookahead 어설션
     *  'x' 다음에 'y'가 오는 경우에만 x와 일치
     * - Example
     *  /Jack(?=Sprat)/ 인 경우 뒤에 "Sprat"가 오는 경우에만 "Jack"과 일치
     *  /Jack(?=Sprat|Frost)/ 인 경우 뒤에 "Sprat" 또는 "Frost"가 오는 경우에만 "Jack"과 일치
     *  그러나 위의 두 경우 모두 "Sprat"도 "Frost"도 일치 결과에 포함되지 않음!
     * 
     * x(?!y)
     * - Negative lookahead 어설션
     *  'x' 다음에 'y'가 오지 않는 경우에만 x와 일치
     * - Example
     *  /\d+(?!\.)/ 인 경우 뒤에 소수점이 없는 경우에만 숫자와 일치
     *  /\d+(?!\.)/.exec('3.141') 인 경우 "141"과 일치하지만 "3"과는 일치하지 않음
     * 
     * (?<=y)x 
     * - Lookbehind 어설션
     *  'x' 앞에 'y'가 오는 경우에만 x와 일치
     * - Example
     *  /(?<=Jack)Sprat/ 인 경우 앞에 "Jack"이 있는 경우에만 "Sprat"와 일치
     *  /(?<=Jack|Tom)Sprat/ 인 경우 앞에 "Jack" 또는 "Tom" 이 있는 경우에만 "Sprat"와 일치
     *  그러나 위의 두 경우 모두 "Jack"도 "Tom"도 일치 결과에 포함되지 않음!
     * 
     * (?<!y)x
     * - Negative lookbehind 어설션
     *  'x' 앞에 'y'가 오지 않는 경우에만 x와 일치
     * - Example
     *  /(?<!-)\d+/ 인 경우 앞에 빼기 기호('-')가 없는 경우에만 숫자와 일치
     *  /(?<!-)\d+/.exec('3') 인 경우 숫자 "3"과 일치
     *  /(?<!-)\d+/.exec('-3') 인 경우 숫자 앞에 빼기 기호('-')가 있기 때문에 일치하는 항목을 찾을 수 없음!
     */
    const rexp_19 = /(?:(?:(https?|ftp|telnet):\/\/|[^\s\t\r\n[\]`<>"'])((?:[\w$\-_.+!*'(),]|%[0-9a-f][0-9a-f])*:(?:[\w$\-_.+!*'(),;?&=]|%[0-9a-f][0-9a-f])+@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?::([0-9]+))?((?:\/(?:[\w$\-_.+!*'(),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s/?.:<>|#]*(?:\.[^\s/?:<>|#]+)*))?(\/?[?;](?:[a-z0-9-]+(?:=[^\s:&<>]*)?&)*[a-z0-9-]+(?:=[^\s:&<>]*)?)?(#[\w-]+)?)/gmi;
    const rexp_20 = /(?:(?:(https?|ftp|telnet):\/\/|[^\s\t\r\n[\]`<>"'])((?:[\w$\-_.+!*'(),]|%[0-9a-f][0-9a-f])*:(?:[\w$\-_.+!*'(),;?&=]|%[0-9a-f][0-9a-f])+@)?(?:((?:(?:[a-z0-9]+\.)+[a-z0-9-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|(?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9-]{1,})|localhost)(?::([0-9]+))?((?:\/(?:[\w$\-_.+!*'(),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s/?.:<>|#]*(?:\.[^\s/?:<>|#]+)*))?(\/?[?;](?:[a-z0-9-]+(?:=[^\s:&<>]*)?&)*[a-z0-9-]+(?:=[^\s:&<>]*)?)?(#[\w-]+)?)/gmi;
    const rexp_21 = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!-\/]))?/gmi;

    const rexp_22 = new RegExp('(?:\\b(?:(?:(?:(ftp|https?|mailto|telnet):\\/\\/)?(?:((?:[\\w$\\-'
    + '_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+(?:\\:(?:[\\w$'
    + '\\-_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+)?)\\@)?((?'
    + ':[\\d]{1,3}\\.){3}[\\d]{1,3}|(?:(?:[a-z0-9]+\\.|[a-z0-9][a-z0-9\\-]+'
    + '[a-z0-9]\\.)|(?:[a-z0-9\\-가-힣]+\\.))+(?:biz|com|info|name|net|org|pro|aero|asia|cat|coop|'
    + 'edu|gov|int|jobs|mil|mobi|museum|tel|travel|ero|gov|post|geo|cym|'
    + 'arpa|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|'
    + 'bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bw|by|bz|ca|cc|cd|cf|cg|ch'
    + '|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|e'
    + 'r|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|'
    + 'gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it'
    + '|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|l'
    + 't|lu|lv|ly|ma|mc|me|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|'
    + 'mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph'
    + '|pk|pl|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|s'
    + 'i|sk|sl|sm|sn|sr|st|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tr|'
    + 'tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|za|zm'
    + '|zw)|localhost)\\b(?:\\:([\\d]+))?)|(?:(file):\\/\\/\\/?)?([a-z]:'
    + '))(?:\\/((?:(?:[\\w$\\-\\.\\+\\!\\*\\(\\),;:@=ㄱ-ㅎㅏ-ㅣ가-힣]|%['
    + '0-9a-f][0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|'
    + 'reg);)*\\/)*)([^\\s\\/\\?:\\"\\\'<>\\|#]*)(?:[\\?:;]((?:\\b[\\w]+'
    + '(?:=(?:[\\w\\$\\-\\.\\+\\!\\*\\(\\),;:=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f]'
    + '[0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|reg);)*'
    + ')?\\&?)*))*(#[\\w\\-ㄱ-ㅎㅏ-ㅣ가-힣]+)?)?)', 'gmi');

    const domain = 'biz|com|info|name|net|org|pro|aero|asia|cat|coop|'
    + 'edu|gov|int|jobs|mil|mobi|museum|tel|travel|ero|gov|post|geo|cym|'
    + 'arpa|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|'
    + 'bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bw|by|bz|ca|cc|cd|cf|cg|ch'
    + '|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|e'
    + 'r|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|'
    + 'gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it'
    + '|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|l'
    + 't|lu|lv|ly|ma|mc|me|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|'
    + 'mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph'
    + '|pk|pl|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|s'
    + 'i|sk|sl|sm|sn|sr|st|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tr|'
    + 'tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|za|zm'
    + '|zw';

    const rexp_23 = /(?:(?:(https?|ftp|telnet):\/\/|[^\s\t\r\n[\]`<>"'])((?:[\w$\-_.+!*'(),]|%[0-9a-f][0-9a-f])*:(?:[\w$\-_.+!*'(),;?&=]|%[0-9a-f][0-9a-f])+@)?(?:((?:(?:[a-z0-9]+\.)+[a-z0-9-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|(?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9-]{1,})|localhost)(?::([0-9]+))?((?:\/(?:[\w$\-_.+!*'(),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s/?.:<>|#]*(?:\.[^\s/?:<>|#]+)*))?(\/?[?;](?:[a-z0-9-]+(?:=[^\s:&<>]*)?&)*[a-z0-9-]+(?:=[^\s:&<>]*)?)?(#[\w-]+)?)/gmi;

    const rexp_24 = /(?:\\b(?:(?:(?:(ftp|https?|mailto|telnet):\/\/)?(?:((?:[\w$\-_.+!*'(),;?&=]|%[0-9a-f][0-9a-f])+(?::(?:[\w$\-_.+!*'(),;?&=]|%[0-9a-f][0-9a-f])+)?)@)?((?:[\d]{1,3}\.){3}[\d]{1,3}|(?:[a-z0-9]+\.|[a-z0-9][a-z0-9-]+[a-z0-9]\.)+(?:biz|com|info|name|net|org|pro|aero|asia|cat|coop|edu|gov|int|jobs|mil|mobi|museum|tel|travel|ero|gov|post|geo|cym|arpa|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|me|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sk|sl|sm|sn|sr|st|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|za|zm|zw)|localhost)(?::([\d]+))?)(?:(file):\/\/\/?)?([a-z]:))(?:\/((?:(?:[\w$\-.+!*(),;:@=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|reg);)*\/)*)([^\s/?:"\\'<>|#]*)(?:[?:;]((?:\b[\w]+(?:=(?:[\w$-.+!*(),;:=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|reg);)*)?&?)*))*(#[\w\-ㄱ-ㅎㅏ-ㅣ가-힣]+)?)?)/gmi;
    // var tmp = /(?:\\b(?:(?:(?:(ftp|https?|mailto|telnet):\/\/)?(?:((?:[\w$\-_.+!*'(),;?&=]/gmi;

    // const rexp_25 = /(?:(?:(https?|ftp|telnet):\/\/|[^\s\t\r\n\[\]\`\<\>\"\'])((?:[\w$\-_\.+!*\'\(\),]|%[0-9a-f][0-9a-f])*\:(?:[?:[\w$\-_\.+!*\'\(\),;\?&=]|%[0-9a-f][0-9a-f])+\@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?:\:([0-9]+))?((?:\/(?:[\w$\-_\.+!*\'\(\),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s\/\?\.:<>|#]*(?:\.[^\s\/\?:<>|#]+)*))?(\/?[\?;](?:[a-z0-9\-]+(?:=[^\s:&<>]*)?\&)*[a-z0-9\-]+(?:=[^\s:&<>]*)?)?(#[\w\-]+)?)/gmi;
    const rexp_26 = /(?:(?:(https?|ftp|telnet):\/\/|[^\s\t\r\n[\]`<>"'])((?:[\w$\-_.+!*'(),]|%[0-9a-f][0-9a-f])*:(?:[\w$\-_.+!*'(),;?&=]|%[0-9a-f][0-9a-f])+@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?::([0-9]+))?((?:\/(?:[\w$\-_.+!*'(),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s/?.:<>|#]*(?:\.[^\s/?:<>|#]+)*))?(\/?[?;](?:[a-z0-9-]+(?:=[^\s:&<>]*)?&)*[a-z0-9-]+(?:=[^\s:&<>]*)?)?(#[\w-]+)?)/gmi;

    console.log("rexp_1 => ", message.match(rexp_1));
    console.log("rexp_2 => ", message.match(rexp_2));
    console.log("rexp_3 => ", message.match(rexp_3));
    console.log("rexp_4 => ", message.match(rexp_4));
    console.log("rexp_5 => ", message.match(rexp_5));
    console.log("rexp_6 => ", message.match(rexp_6));
    console.log("rexp_7 => ", message.match(rexp_7));
    console.log("rexp_8 => ", message.match(rexp_8));
    console.log("rexp_9 => ", message.match(rexp_9));
    console.log("rexp_10 => ", message.match(rexp_10));
    console.log("rexp_11 => ", message.match(rexp_11));
    console.log("rexp_12 => ", message.match(rexp_12));
    console.log("rexp_13 => ", message.match(rexp_13));
    console.log("rexp_14 => ", message.match(rexp_14));
    console.log("rexp_15 => ", message.match(rexp_15));
    console.log("rexp_16 => ", message.match(rexp_16));
    console.log("rexp_17 => ", message.match(rexp_17));
    console.log("rexp_18 => ", message.match(rexp_18));
}

const convertURL = (url) => {
    console.log("convertURL called - url => ", url);

    if (!url.includes("://")) {
        url = "//" + url;
        console.log("new url => ", url);
    }

    return url;
}

let strfn = "convertURL";
let context = {};

context["convertURL"] = convertURL;


const isIncludesProtocol = (url) => {
    console.log("isIncludesProtocol called - url => ", url, typeof url);

    if (url && url.length > 0) {
        for (let i = 0; i < url.length; i++) {
            if (!url[i].includes("://")) {
                console.log("not includes protocol", url[i]);
            } else {
                console.log("includes protocol", url[i]);
            }

            return url[i].includes("://");
        }
    } else {
        return false;
    }
}

const processMessage = (message, rexp) => {
    let result = message.match(rexp);
    console.log("processMessage - result => ", result);
    return result;
}

/* const collectURL = (message, rexp) => {
    let array = processMessage(message, rexp);
    console.log("collectURL - result => ", array);
    return (array) ? array : [];
} */

const collectAllURL = (message) => {
    console.log("collectAllURL - message => ", message);

    const Rexp = new RegExp('(?:\\b(?:(?:(?:(ftp|https?|mailto|telnet):\\/\\/)?(?:((?:[\\w$\\-'
    + '_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+(?:\\:(?:[\\w$'
    + '\\-_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+)?)\\@)?((?'
    + ':[\\d]{1,3}\\.){3}[\\d]{1,3}|(?:[a-z0-9]+\\.|[a-z0-9][a-z0-9\\-]+'
    + '[a-z0-9]\\.)+(?:biz|com|info|name|net|org|pro|aero|asia|cat|coop|'
    + 'edu|gov|int|jobs|mil|mobi|museum|tel|travel|ero|gov|post|geo|cym|'
    + 'arpa|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|'
    + 'bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bw|by|bz|ca|cc|cd|cf|cg|ch'
    + '|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|e'
    + 'r|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|'
    + 'gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it'
    + '|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|l'
    + 't|lu|lv|ly|ma|mc|me|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|'
    + 'mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph'
    + '|pk|pl|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|s'
    + 'i|sk|sl|sm|sn|sr|st|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tr|'
    + 'tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|za|zm'
    + '|zw)|localhost)\\b(?:\\:([\\d]+))?)|(?:(file):\\/\\/\\/?)?([a-z]:'
    + '))(?:\\/((?:(?:[\\w$\\-\\.\\+\\!\\*\\(\\),;:@=ㄱ-ㅎㅏ-ㅣ가-힣]|%['
    + '0-9a-f][0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|'
    + 'reg);)*\\/)*)([^\\s\\/\\?:\\"\\\'<>\\|#]*)(?:[\\?:;]((?:\\b[\\w]+'
    + '(?:=(?:[\\w\\$\\-\\.\\+\\!\\*\\(\\),;:=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f]'
    + '[0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|reg);)*'
    + ')?\\&?)*))*(#[\\w\\-ㄱ-ㅎㅏ-ㅣ가-힣]+)?)?)', 'gmi');

    let urlCollectResult = collectAllURL(message, Rexp);
    console.log("urlCollectResult => ", urlCollectResult);

    if (urlCollectResult.length > 0) {

    }
}

const testFunc = (match, sub1, sub2, sub3, sub4, sub5, sub6, sub7, sub8, offset, string) => {
    let obj = { match, sub1, sub2, sub3, sub4, sub5, sub6, sub7, sub8, offset, string };
    // console.log("testFunc called - match => ", match);
    console.log("testFunc called - obj => ", obj);
    // console.log("sub1, sub2, sub3 => ", sub1, sub2, sub3);
    // console.log("sub4, sub5, sub6 => ", sub4, sub5, sub6);
    // console.log("sub7, sub8, sub9 => ", sub7, sub8, sub9);
    // console.log("sub7, sub8=> ", sub7, sub8);
    // console.log("offset, string => ", offset, string);
    // return sub1 + sub2 + sub3;
    // return sub1 + sub2 + sub3 + sub4 + sub5 + sub6;
    // return sub1 + sub2 + sub3 + sub4 + sub5 + sub6 + sub7 + sub8 + sub9;
    return sub1 + sub2 + sub3 + sub4 + sub5 + sub6 + sub7 + sub8;
}

const testFunc_2 = (obj) => {
    console.log("testFunc_2 called - obj => ", obj, typeof obj);
    // console.log("sub1, sub2, sub3 => ", sub1, sub2, sub3);
    // console.log("sub4, sub5, sub6 => ", sub4, sub5, sub6);
    // console.log("sub7, sub8, sub9 => ", sub7, sub8, sub9);
    // console.log("sub7, sub8=> ", sub7, sub8);
    // console.log("offset, string => ", offset, string);
    // return sub1 + sub2 + sub3;
    // return sub1 + sub2 + sub3 + sub4 + sub5 + sub6;
    // return sub1 + sub2 + sub3 + sub4 + sub5 + sub6 + sub7 + sub8 + sub9;
    // return sub1 + sub2 + sub3 + sub4 + sub5 + sub6 + sub7 + sub8;
    return obj;
}

const collectURL = (match, p1, p2, p3, p4, p5, p6, p7, p8, offset, string) => {
    let obj = { match, p1, p2, p3, p4, p5, p6, p7, p8, offset, string };
    // console.log("collectURL called - match => ", match);
    // console.log("obj => ", obj);
    let href = !match.includes("://") ? "//" + match : match;
    // console.log("href => ", href);
    let result = '<a href=' + href + ' target=_blank>' + match + '</a>';
    // console.log("result => ", result);
    return result;
}

const autoLink = (message) => {
    // console.log("autoLink called");
    // testCollectURL(message);

    const regExp =
        /(?:(?:(https?|ftp|telnet):\/\/|[^\s\t\r\n[\]`<>"'])((?:[\w$\-_.+!*'(),]|%[0-9a-f][0-9a-f])*:(?:[\w$\-_.+!*'(),;?&=]|%[0-9a-f][0-9a-f])+@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?::([0-9]+))?((?:\/(?:[\w$\-_.+!*'(),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s/?.:<>|#]*(?:\.[^\s/?:<>|#]+)*))?(\/?[?;](?:[a-z0-9-]+(?:=[^\s:&<>]*)?&)*[a-z0-9-]+(?:=[^\s:&<>]*)?)?(#[\w-]+)?)/gmi;

    const regExp_2 = /(?:(?:(https?|ftp|telnet):\/\/|[^\s\t\r\n[\]`<>"'])((?:[\w$\-_.+!*'(),]|%[0-9a-f][0-9a-f])*:(?:[\w$\-_.+!*'(),;?&=]|%[0-9a-f][0-9a-f])+@)?(?:((?:(?:[a-z0-9]+\.)+[a-z0-9-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|(?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9-]{1,})|localhost)(?::([0-9]+))?((?:\/(?:[\w$\-_.+!*'(),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s/?.:<>|#]*(?:\.[^\s/?:<>|#]+)*))?(\/?[?;](?:[a-z0-9-]+(?:=[^\s:&<>]*)?&)*[a-z0-9-]+(?:=[^\s:&<>]*)?)?(#[\w-]+)?)/gmi;

    // const Rexp =
    //     /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?/=~_|!:,.;]*)[-A-Z0-9+&@#/%=~_|])/gi;

    // \b 단어 경계와 일치

    // const Rexp =
    //     /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?/=~_|!:,.;]*)[-A-Z0-9+&@#/%=~_|])/gi;

    // console.log(validURL(message));

    // const Rexp = /(?:(?:(https?|ftp|telnet):\/\/|[\s\t\r\n[\]`<>"'])((?:[\w$\-_.+!*'(),]|%[0-9a-f][0-9a-f])*:(?:[\w$\-_.+!*'(),;?&=]|%[0-9a-f][0-9a-f])+@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?::([0-9]+))?((?:\/(?:[\w$\-_.+!*'(),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s/?.:<>|#]*(?:\.[^\s/?:<>|#]+)*))?(\/?[?;](?:[a-z0-9-]+(?:=[^\s:&<>]*)?&)*[a-z0-9-]+(?:=[^\s:&<>]*)?)?(#[\w-]+)?)/gmi;

    /* const Rexp = '(?:\\b(?:(?:(?:(ftp|https?|mailto|telnet):\\/\\/)?(?:((?:[\\w$\\-'
    + '_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+(?:\\:(?:[\\w$'
    + '\\-_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+)?)\\@)?((?'
    + ':[\\d]{1,3}\\.){3}[\\d]{1,3}|(?:[a-z0-9]+\\.|[a-z0-9][a-z0-9\\-]+'
    + '[a-z0-9]\\.)+(?:biz|com|info|name|net|org|pro|aero|asia|cat|coop|'
    + 'edu|gov|int|jobs|mil|mobi|museum|tel|travel|ero|gov|post|geo|cym|'
    + 'arpa|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|'
    + 'bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bw|by|bz|ca|cc|cd|cf|cg|ch'
    + '|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|e'
    + 'r|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|'
    + 'gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it'
    + '|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|l'
    + 't|lu|lv|ly|ma|mc|me|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|'
    + 'mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph'
    + '|pk|pl|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|s'
    + 'i|sk|sl|sm|sn|sr|st|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tr|'
    + 'tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|za|zm'
    + '|zw)|localhost)\\b(?:\\:([\\d]+))?)|(?:(file):\\/\\/\\/?)?([a-z]:'
    + '))(?:\\/((?:(?:[\\w$\\-\\.\\+\\!\\*\\(\\),;:@=ㄱ-ㅎㅏ-ㅣ가-힣]|%['
    + '0-9a-f][0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|'
    + 'reg);)*\\/)*)([^\\s\\/\\?:\\"\\\'<>\\|#]*)(?:[\\?:;]((?:\\b[\\w]+'
    + '(?:=(?:[\\w\\$\\-\\.\\+\\!\\*\\(\\),;:=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f]'
    + '[0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|reg);)*'
    + ')?\\&?)*))*(#[\\w\\-ㄱ-ㅎㅏ-ㅣ가-힣]+)?)?)'; */

    /* const Rexp = new RegExp('(?:\\b(?:(?:(?:(ftp|https?|mailto|telnet):\\/\\/)?(?:((?:[\\w$\\-'
    + '_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+(?:\\:(?:[\\w$'
    + '\\-_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+)?)\\@)?((?'
    + ':[\\d]{1,3}\\.){3}[\\d]{1,3}|(?:[a-z0-9]+\\.|[a-z0-9][a-z0-9\\-]+'
    + '[a-z0-9]\\.)+(?:biz|com|info|name|net|org|pro|aero|asia|cat|coop|'
    + 'edu|gov|int|jobs|mil|mobi|museum|tel|travel|ero|gov|post|geo|cym|'
    + 'arpa|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|'
    + 'bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bw|by|bz|ca|cc|cd|cf|cg|ch'
    + '|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|e'
    + 'r|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|'
    + 'gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it'
    + '|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|l'
    + 't|lu|lv|ly|ma|mc|me|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|'
    + 'mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph'
    + '|pk|pl|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|s'
    + 'i|sk|sl|sm|sn|sr|st|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tr|'
    + 'tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|za|zm'
    + '|zw)|localhost)\\b(?:\\:([\\d]+))?)|(?:(file):\\/\\/\\/?)?([a-z]:'
    + '))(?:\\/((?:(?:[\\w$\\-\\.\\+\\!\\*\\(\\),;:@=ㄱ-ㅎㅏ-ㅣ가-힣]|%['
    + '0-9a-f][0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|'
    + 'reg);)*\\/)*)([^\\s\\/\\?:\\"\\\'<>\\|#]*)(?:[\\?:;]((?:\\b[\\w]+'
    + '(?:=(?:[\\w\\$\\-\\.\\+\\!\\*\\(\\),;:=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f]'
    + '[0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|reg);)*'
    + ')?\\&?)*))*(#[\\w\\-ㄱ-ㅎㅏ-ㅣ가-힣]+)?)?)', 'gi'); */

    /* const Rexp = new RegExp('(?:\\b(?:(?:(?:(ftp|https?|mailto|telnet):\\/\\/)?(?:((?:[\\w$\\-'
    + '_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+(?:\\:(?:[\\w$'
    + '\\-_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+)?)\\@)?((?'
    + ':[\\d]{1,3}\\.){3}[\\d]{1,3}|(?:[a-z0-9]+\\.|[a-z0-9][a-z0-9\\-]+'
    + '[a-z0-9]\\.)+(?:biz|com|info|name|net|org|pro|aero|asia|cat|coop|'
    + 'edu|gov|int|jobs|mil|mobi|museum|tel|travel|ero|gov|post|geo|cym|'
    + 'arpa|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|'
    + 'bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bw|by|bz|ca|cc|cd|cf|cg|ch'
    + '|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|e'
    + 'r|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|'
    + 'gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it'
    + '|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|l'
    + 't|lu|lv|ly|ma|mc|me|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|'
    + 'mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph'
    + '|pk|pl|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|s'
    + 'i|sk|sl|sm|sn|sr|st|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tr|'
    + 'tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|za|zm'
    + '|zw)|localhost)\\b(?:\\:([\\d]+))?)|(?:(file):\\/\\/\\/?)?([a-z]:'
    + '))(?:\\/((?:(?:[\\w$\\-\\.\\+\\!\\*\\(\\),;:@=ㄱ-ㅎㅏ-ㅣ가-힣]|%['
    + '0-9a-f][0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|'
    + 'reg);)*\\/)*)([^\\s\\/\\?:\\"\\\'<>\\|#]*)(?:[\\?:;]((?:\\b[\\w]+'
    + '(?:=(?:[\\w\\$\\-\\.\\+\\!\\*\\(\\),;:=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f]'
    + '[0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|reg);)*'
    + ')?\\&?)*))*(#[\\w\\-ㄱ-ㅎㅏ-ㅣ가-힣]+)?)?)', 'gmi'); */

    /* const Rexp = new RegExp('(?:\\b(?:(?:(?:(ftp|https?|mailto|telnet):\\/\\/)?(?:((?:[\\w$\\-'
    + '_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+(?:\\:(?:[\\w$'
    + '\\-_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+)?)\\@)?((?'
    + ':[\\d]{1,3}\\.){3}[\\d]{1,3}|(?:[a-z0-9]+\\.|[a-z0-9][a-z0-9\\-]+'
    + '[a-z0-9]\\.)+(?:biz|com|info|name|net|org|pro|aero|asia|cat|coop|'
    + 'edu|gov|int|jobs|mil|mobi|museum|tel|travel|ero|gov|post|geo|cym|'
    + 'arpa|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|'
    + 'bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bw|by|bz|ca|cc|cd|cf|cg|ch'
    + '|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|e'
    + 'r|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|'
    + 'gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it'
    + '|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|l'
    + 't|lu|lv|ly|ma|mc|me|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|'
    + 'mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph'
    + '|pk|pl|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|s'
    + 'i|sk|sl|sm|sn|sr|st|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tr|'
    + 'tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|za|zm'
    + '|zw)|localhost)\\b(?:\\:([\\d]+))?)|(?:(file):\\/\\/\\/?)?([a-z]:'
    + '))(?:\\/((?:(?:[\\w$\\-\\.\\+\\!\\*\\(\\),;:@=ㄱ-ㅎㅏ-ㅣ가-힣]|%['
    + '0-9a-f][0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|'
    + 'reg);)*\\/)*)([^\\s\\/\\?:\\"\\\'<>\\|#]*)(?:[\\?:;]((?:\\b[\\w]+'
    + '(?:=(?:[\\w\\$\\-\\.\\+\\!\\*\\(\\),;:=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f]'
    + '[0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|reg);)*'
    + ')?\\&?)*))*(#[\\w\\-ㄱ-ㅎㅏ-ㅣ가-힣]+)?)?)', 'gmi'); */

    /* const Rexp = new RegExp('(?:\\b(?:(?:(?:(ftp|https?|mailto|telnet):\\/\\/)?(?:((?:[\\w$\\-'
    + '_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+(?:\\:(?:[\\w$'
    + '\\-_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+)?)\\@)?((?'
    + ':[\\d]{1,3}\\.){3}[\\d]{1,3}|(?:[a-z0-9]+\\.|[a-z0-9][a-z0-9\\-]+'
    + '[a-z0-9]\\.)+(?:biz|com|info|name|net|org|pro|aero|asia|cat|coop|'
    + 'edu|gov|int|jobs|mil|mobi|museum|tel|travel|ero|gov|post|geo|cym|'
    + 'arpa|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|'
    + 'bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bw|by|bz|ca|cc|cd|cf|cg|ch'
    + '|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|e'
    + 'r|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|'
    + 'gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it'
    + '|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|l'
    + 't|lu|lv|ly|ma|mc|me|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|'
    + 'mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph'
    + '|pk|pl|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|s'
    + 'i|sk|sl|sm|sn|sr|st|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tr|'
    + 'tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|za|zm'
    + '|zw)|localhost)\\b(?:\\:([\\d]+))?)|(?:(file):\\/\\/\\/?)?([a-z]:'
    + '))(?:\\/((?:(?:[\\w$\\-\\.\\+\\!\\*\\(\\),;:@=ㄱ-ㅎㅏ-ㅣ가-힣]|%['
    + '0-9a-f][0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|'
    + 'reg);)*\\/)*)([^\\s\\/\\?:\\"\\\'<>\\|#]*)(?:[\\?:;]((?:\\b[\\w]+'
    + '(?:=(?:[\\w\\$\\-\\.\\+\\!\\*\\(\\),;:=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f]'
    + '[0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|reg);)*'
    + ')?\\&?)*))*(#[\\w\\-ㄱ-ㅎㅏ-ㅣ가-힣]+)?)?)(\\b[\\w]+(?:=(?:[\\w\\$'
    + '\\-\\.\\+\\!\\*\\(\\),;:=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f]|&(?:nb'
    + 'sp|lt|gt|amp|cent|pound|yen|euro|sect|copy|reg);)*)?)\\&?', 'gmi'); */

    /* const Rexp = {
		url : '(?:\\b(?:(?:(?:(ftp|https?|mailto|telnet):\\/\\/)?(?:((?:[\\w$\\-'
			+ '_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+(?:\\:(?:[\\w$'
			+ '\\-_\\.\\+\\!\\*\\\'\\(\\),;\\?&=]|%[0-9a-f][0-9a-f])+)?)\\@)?((?'
			+ ':[\\d]{1,3}\\.){3}[\\d]{1,3}|(?:[a-z0-9]+\\.|[a-z0-9][a-z0-9\\-]+'
			+ '[a-z0-9]\\.)+(?:biz|com|info|name|net|org|pro|aero|asia|cat|coop|'
			+ 'edu|gov|int|jobs|mil|mobi|museum|tel|travel|ero|gov|post|geo|cym|'
			+ 'arpa|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|'
			+ 'bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bw|by|bz|ca|cc|cd|cf|cg|ch'
			+ '|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|e'
			+ 'r|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|'
			+ 'gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it'
			+ '|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|l'
			+ 't|lu|lv|ly|ma|mc|me|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|'
			+ 'mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph'
			+ '|pk|pl|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|s'
			+ 'i|sk|sl|sm|sn|sr|st|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tr|'
			+ 'tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|za|zm'
			+ '|zw)|localhost)\\b(?:\\:([\\d]+))?)|(?:(file):\\/\\/\\/?)?([a-z]:'
			+ '))(?:\\/((?:(?:[\\w$\\-\\.\\+\\!\\*\\(\\),;:@=ㄱ-ㅎㅏ-ㅣ가-힣]|%['
			+ '0-9a-f][0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|'
			+ 'reg);)*\\/)*)([^\\s\\/\\?:\\"\\\'<>\\|#]*)(?:[\\?:;]((?:\\b[\\w]+'
			+ '(?:=(?:[\\w\\$\\-\\.\\+\\!\\*\\(\\),;:=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f]'
			+ '[0-9a-f]|&(?:nbsp|lt|gt|amp|cent|pound|yen|euro|sect|copy|reg);)*'
			+ ')?\\&?)*))*(#[\\w\\-ㄱ-ㅎㅏ-ㅣ가-힣]+)?)?)',
		querystring: new RegExp('(\\b[\\w]+(?:=(?:[\\w\\$\\-\\.\\+\\!\\*\\(\\),;'
			+ ':=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f]|&(?:nbsp|lt|gt|amp|cent|poun'
			+ 'd|yen|euro|sect|copy|reg);)*)?)\\&?', 'gi')
	}; */

    /* return message.replace(
        Rexp,
        "<div><a href='$&' target='_blank'>📎 $&</a></div>"
    ); */

    /* let msg = message;

    // let url = msg.replace(Rexp, '$&');
    let url = msg.match(Rexp);
    console.log("url => ", url);

    if (url && url.length > 0) {
        for (let i = 0; i < url.length; i++) {
            if (!url[i].includes("://")) {
                console.log("not includes protocol", url[i]);
            } else {
                console.log("includes protocol", url[i]);
            }

            // return url[i].includes("://");
        }
    } else {
        // return false;
    } */

    /* if (isIncludesProtocol(url)) {
        return message.replace(
            Rexp, "<div><a href='$&' target='_blank'>📎 $&</a></div>"
        );
    } else {
        let newUrl = convertURL(url);
        console.log("newUrl => ", newUrl);

        // let newRexp = new RegExp('//', 'gmi');
        let newUrl_2 = url.replace(Rexp, "//$&");
        console.log("newUrl_2 => ", newUrl_2);

        let newMessage = message.replace(Rexp, "<div><a href=//$& target='_blank'>📎 $&</a></div>");
        console.log("newMessage => ", newMessage);

        return message.replace(
            Rexp, "<div><a href=//$& target='_blank'>📎 $&</a></div>"
        );
    } */

    /* let testChangeMessage = message.replace(regExp, testFunc);
    console.log("testChangeMessage => ", testChangeMessage); */

    /* let testChangeMessage_2 = message.replace(regExp_2, testFunc);
    console.log("testChangeMessage_2 => ", testChangeMessage_2);

    let testChangeMessage_3 = message.replace(Rexp, testFunc);
    console.log("testChangeMessage_3 => ", testChangeMessage_3);

    let testChangeMessage_4 = message.replace(regExp, testFunc_2);
    console.log("testChangeMessage_4 => ", testChangeMessage_4, typeof testChangeMessage_4);

    let testChangeMessage_5 = message.replace(regExp_2, testFunc_2);
    console.log("testChangeMessage_5 => ", testChangeMessage_5, typeof testChangeMessage_5);

    let testChangeMessage_6 = message.replace(Rexp, testFunc_2);
    console.log("testChangeMessage_6 => ", testChangeMessage_6, typeof testChangeMessage_6); */

    /* return message.replace(
        regExp, '<div><a href=$& target=_blank>📎 $&</a></div>'
    ); */

    /* let testChangeMessage_7 = message.replace(regExp, collectURL);
    console.log("testChangeMessage_7 => ", testChangeMessage_7); */

    /* return message.replace(
        regExp, '<a href=$& target=_blank>📎 $&</a>'
    ); */

    return message.replace(regExp, collectURL);
}

const SendMessage = ({ indexKey, msgInfo, activeLive, handleSelectUrl, handleSelectedClassTalkFunc }) => {

    return (
        <div className="d-flex flex-column w-100" style={{ padding: '2px 5px 2px 2px' }}>
            {
                msgInfo.rcvKind === ctRcvKind.sendPentalk && msgInfo.rcvName !== undefined
                && msgInfo.rcvName !== null && msgInfo.rcvName !== "" &&
                <div className="d-flex justify-content-end align-items-center w-100" style={{ padding: '0px 2px 1px 0px' }}>
                    <span className="ct-profile-nickname">{msgInfo.rcvName}</span>
                </div>
            } {
                msgInfo.rcvKind === ctRcvKind.SendWatson && msgInfo.rcvName !== undefined
                && msgInfo.rcvName !== null && msgInfo.rcvName !== "" &&
                <div className="d-flex justify-content-end align-items-center w-100" style={{ padding: '0px 2px 1px 0px' }}>
                    <span className="ct-profile-nickname">{msgInfo.rcvName}</span>
                </div>
            } {
                msgInfo.rcvKind === ctRcvKind.Card && msgInfo.kind === stCardType.Quiz &&
                <div className="d-flex justify-content-end align-items-center w-100">
                    <div className="bubble-card msg-bg-violet-btext">
                        <QuizCardForTeacher indexKey={indexKey} cardInfo={msgInfo.cardInfo} activeLive={activeLive} handleSelectedClassTalkFunc={handleSelectedClassTalkFunc} />
                    </div>
                </div>
            } {
                msgInfo.rcvKind === ctRcvKind.sendWhisper ?
                    <div className="d-flex justify-content-end align-items-center w-100">
                        <span className="ct-bubble bubble-msg msg-send msg-bg-orange" style={{ cursor: 'pointer' }} onClick={() => handleSelectUrl("whisper", msgInfo, indexKey)}>
                            귓속말을 보냈습니다.
                        </span>
                    </div> :
                    <>
                        {
                            /* msgInfo.text !== undefined && msgInfo.text !== null && msgInfo.text !== "" &&
                            <div className="d-flex justify-content-end align-items-center w-100">
                                <span className={"ct-bubble bubble-msg msg-send" + (msgInfo.rcvKind === ctRcvKind.SendWatson ? " msg-bg-yellow-btext" : " msg-bg-yellow")}>
                                    {msgInfo.text}
                                </span>
                            </div> */
                            msgInfo.text !== undefined && msgInfo.text !== null && msgInfo.text !== "" &&
                            <div className="d-flex justify-content-end align-items-center w-100">
                                <span className={"ct-bubble bubble-msg msg-send" + (msgInfo.rcvKind === ctRcvKind.SendWatson ? " msg-bg-yellow-btext" : " msg-bg-yellow")}
                                    dangerouslySetInnerHTML={{__html: autoLink(msgInfo.text)}}
                                >
                                </span>
                            </div>
                        } {
                            msgInfo.kind === "ink_memo" &&
                            <div className="d-flex" style={{ justifyContent: "flex-end", paddingTop: '0.25rem', paddingRight: '0.75rem' }}>
                                <img className="hiclasstv-classtalk-thumbnail" src={msgInfo.urlInfo} onClick={() => handleSelectUrl(msgInfo.kind, msgInfo.urlInfo)} alt="" />
                            </div>
                        } {
                            msgInfo.kind === "audio_memo" &&
                            <div className="d-flex" style={{ justifyContent: "flex-end", paddingTop: '0.25rem', paddingRight: '0.75rem' }}>
                                <button type="button" className="btn btn-sm mx-1 px-2 py-1" style={{ borderRadius: '0.5rem' }} onClick={() => handleSelectUrl(msgInfo.kind, msgInfo.urlInfo)}>
                                    Play
                                </button>
                            </div>
                        } {
                            msgInfo.emoticSeq > 0 && msgInfo.emoticSeq < 64 &&
                            <div className="d-flex" style={{ justifyContent: "flex-end", paddingTop: '0.25rem' }}>
                                {/* <img className="hiclasstv-classtalk-emo" src={require(`../images/et${msgInfo.emoticSeq.toString().padStart(2, "0")}.png`)} alt="" /> */}
                                <img className="hiclasstv-classtalk-emo" src={`/images/classtalk/emoticon/et${msgInfo.emoticSeq.toString().padStart(2, "0")}.png`} alt="" />
                            </div>
                        } {
                            msgInfo.emoMIDno > 0 && msgInfo.emoMIDno < 64 &&
                            <div className="d-flex" style={{ justifyContent: "flex-end", paddingTop: '0.25rem' }}>
                                {/* <img className="hiclasstv-classtalk-emo" src={require(`../images/et${msgInfo.emoMIDno.toString().padStart(2, "0")}.png`)} alt="" /> */}
                                <img className="hiclasstv-classtalk-emo" src={`/images/classtalk/emoticon/et${msgInfo.emoMIDno.toString().padStart(2, "0")}.png`} alt="" />
                            </div>
                        }
                    </>
            }
        </div>
    );
}

export default SendMessage;