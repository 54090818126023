/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { FaAndroid, FaAssistiveListeningSystems, FaRegLightbulb, FaFileArrowDown } from "react-icons/fa";
import { FaFileCircleCheck, FaFileCircleXmark } from "react-icons/fa6";
import { BsPeople } from "react-icons/bs";

import ConstData from "../../lib/ConstData";
import { ctActionType, ctWebActionType } from "../../lib/ConstCommand";
import { enTokenCMD } from "../../lib/classTalk/CodeEnum";
import { sendStartPentalkOnScreenForAndroid, xmitCmd_AndroidMessage } from "../../lib/RabbitmqClient";
// import { subscribe, unsubscribe } from "../../lib/MediaSoupClient";
// import { initFuncProc } from "../../lib/rbmq/rcvMsgFromRbmq";

const isAndroidDevice = navigator.userAgent.toLowerCase().indexOf("android") > -1 ? true : false;
// const isAndroidDevice = navigator.userAgent.toLowerCase().indexOf("android") === -1 ? true : false;

/* const FunctionIcon = ({ title, isActiveLive, isPanelWindowOpened, isTeamUpOpened, isHandsupOpened, isQuizWindowOpened, isRunningWatson, isSpeakingMode, selectedLanguageCode, handleClickToggle }) => {

    const getIconImgUrl = (name) => {
        return ConstData.ICON_IMG_DIR + name + ".png";
    }

    switch (title) {
        case "PANEL":
            return (
                <div className="d-flex flex-column align-items-center">
                    {
                        isActiveLive ?
                            <button className={"hiclasstv-classtalk-menu" + (isPanelWindowOpened ? " select" : "")} style={{ backgroundColor: 'rgb(62, 188, 210)' }} onClick={handleClickToggle}>
                                <BsPeople className="hiclasstv-classtalk-icon" style={{ padding: '.25rem' }} />
                            </button> :
                            <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(215, 215, 215)' }} disabled>
                                <BsPeople className="hiclasstv-classtalk-icon" style={{ padding: '.25rem' }} />
                            </button>
                    }
                    <span className="hiclasstv-classtalk-text mt-1">대상자 패널</span>
                </div>
            );

        case "INK_MEMO":
            return (
                <div className="d-flex flex-column align-items-center">
                    <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(28, 162, 209)' }} onClick={() => handleClickToggle({ selectedFunc: "FUNC_SELECT", func: ctActionType.InkMemo })}>
                        <img src={getIconImgUrl("inkMemo")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                    </button>
                    <span className="hiclasstv-classtalk-text mt-1">잉크메모</span>
                </div>
            );

        case "VOICE_MEMO":
            return (
                <div className="d-flex flex-column align-items-center">
                    <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(28, 162, 209)' }} onClick={() => handleClickToggle({ selectedFunc: "FUNC_SELECT", func: ctActionType.VoiceMemo })}>
                        <img src={getIconImgUrl("voiceMemo")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                    </button>
                    <span className="hiclasstv-classtalk-text mt-1">음성메모</span>
                </div>
            );

        case "FILE_SHARE":
            return (
                <div className="d-flex flex-column align-items-center">
                    <button className="hiclasstv-classtalk-menu" type="file" style={{ backgroundColor: 'rgb(52, 182, 157)' }} onClick={handleClickToggle}>
                        <label className="button m-0 p-0" htmlFor="ex_file">
                            <img src={getIconImgUrl("fileShare")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                        </label>
                        <input className="p-0" type="file" id="ex_file" style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', overflow: 'hidden', border: '0', clip: 'rect(0,0,0,0)' }} onChange={handleClickToggle} />
                    </button>
                    <span className="hiclasstv-classtalk-text mt-1">파일공유</span>
                </div>
            );

        case "PULL_SCREEN":
            return (
                <div className="d-flex flex-column align-items-center">
                    <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(62, 188, 210)' }} onClick={() => handleClickToggle({ selectedFunc: "FUNC_SELECT", func: ctActionType.PullScreen })}>
                        <img src={getIconImgUrl("pullScreen")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                    </button>
                    <span className="hiclasstv-classtalk-text mt-1">Pull</span>
                </div>
            );

        case "TEAMUP_WINDOW":
            return (
                <div className="d-flex flex-column align-items-center">
                    <button className={"hiclasstv-classtalk-menu" + (isTeamUpOpened ? " select" : "")} style={{ backgroundColor: 'rgb(106, 78, 157)' }} onClick={() => handleClickToggle({ selectedFunc: "FUNC_SELECT", func: ctActionType.TeamUpWindow })}>
                        <img src={getIconImgUrl("teamUp")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                    </button>
                    <span className="hiclasstv-classtalk-text mt-1">Teamup</span>
                </div>
            );

        case "HANDSUP_WINDOW":
            return (
                <div className="d-flex flex-column align-items-center">
                    <button className={"hiclasstv-classtalk-menu" + (isHandsupOpened ? " select" : "")} style={{ backgroundColor: 'rgb(106, 78, 157)' }} onClick={() => handleClickToggle({ selectedFunc: "FUNC_SELECT", func: ctActionType.HandsUpWindow })}>
                        <img src={getIconImgUrl("handsUp")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                    </button>
                    <span className="hiclasstv-classtalk-text mt-1">Handsup</span>
                </div>
            );

        case "QUIZ":
            return (
                <div className="d-flex flex-column align-items-center">
                    <button className={"hiclasstv-classtalk-menu" + (isQuizWindowOpened ? " select" : "")} style={{ backgroundColor: 'rgb(62, 188, 210)' }} onClick={() => handleClickToggle({ selectedFunc: "FUNC_SELECT", func: ctActionType.Quiz })}>
                        <FaRegLightbulb className="hiclasstv-classtalk-icon" style={{ padding: '.25rem' }} />
                    </button>
                    <span className="hiclasstv-classtalk-text mt-1">Quiz</span>
                </div>
            );

        case "WatsonS-KR":
            return (
                <div className="d-flex flex-column align-items-center">
                    <button
                        className={"hiclasstv-classtalk-menu" + (isRunningWatson && isSpeakingMode && selectedLanguageCode === "ko-KR" ? " select" : "")}
                        disabled={isRunningWatson ? true : false}
                        style={{ backgroundColor: 'rgb(255, 145, 70)' }}
                        onClick={() => handleClickToggle({ selectedFunc: "FUNC_SELECT", func: ctActionType.WatsonS_KR })}
                    >
                        <img src={getIconImgUrl("watsonKR")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                    </button>
                    <span className="hiclasstv-classtalk-text mt-1">Watson</span>
                </div>
            );

        default:
            return <></>;
    }
} */

const FunctionList = ({ activeLive, isWhisper, isChatting, isFileDownloadingNow, isPanelWindowOpened, isTeamUpOpened, isHandsupOpened, isQuizWindowOpened, isActiveLive, isRunningWatson, isSpeakingMode, handsUpYN, myHandsUpYN, connectStatus, selectedLanguageCode, isConnectedOnScreenApp, handleSelectedClassTalkFunc, onHandleClassTalkFunc, togglePanelWindow }) => {

    const [functionTitles, setFunctionTitles] = useState([]);
    const [conColor, setConColor] = useState("grey");
    const [isConnectedApp, setIsConnectedApp] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const imageProcess = (status) => {
        if (status === "connected") {
            setIsConnectedApp(true);
            setIsDisabled(false);
        } else if (status === "disconnected") {
            setIsConnectedApp(false);
            setIsDisabled(false);
        }
    }

    useEffect(() => {
        if (isAndroidDevice) {
            // initFuncProc(imageProcess);
            if (isConnectedOnScreenApp) setIsConnectedApp(true);
        }

        if (isWhisper) {
            setFunctionTitles(["INK_MEMO", "VOICE_MEMO", "FILE_SHARE"]);
        } else {
            if (isChatting) {
                if (ConstData.IS_LOCAL_VERSION) {
                    // 귓속말 ver ==== 
                    setFunctionTitles(["PANEL", "INK_MEMO", "VOICE_MEMO", "FILE_SHARE", /*"PUSH_SCREEN,"*/ "PULL_SCREEN", /* "TEAMUP_WINDOW", "HANDSUP_WINDOW",  */"QUIZ", "FILE_DOWN", "FILE_CLEAR"]);
                    // setFunctionTitles(["INK_MEMO", "VOICE_MEMO", "FILE_SHARE", /*"PUSH_SCREEN,"*/ "PULL_SCREEN", "TEAMUP_WINDOW", "HANDSUP_WINDOW", "FILE_DOWN", "FILE_CLEAR"]);
                } else {
                    // 귓속말 ver ==== 
                    setFunctionTitles(["PANEL", "INK_MEMO", "VOICE_MEMO", "FILE_SHARE", /*"PUSH_SCREEN,"*/ "PULL_SCREEN", /* "TEAMUP_WINDOW", "HANDSUP_WINDOW",  */"QUIZ", /* "FILE_DOWN", "FILE_CLEAR",  *//* "WatsonS-KR", "WatsonS-EN", "WatsonT-KR", "WatsonT-EN" */]);
                    // setFunctionTitles(["INK_MEMO", "VOICE_MEMO", "FILE_SHARE", /*"PUSH_SCREEN,"*/ "PULL_SCREEN", "TEAMUP_WINDOW", "HANDSUP_WINDOW", "WatsonS-KR"/*, "WatsonS-EN", "WatsonT-KR", "WatsonT-EN"*/]);
                }
            } else {
                if (isAndroidDevice) {
                    if (ConstData.IS_LOCAL_VERSION) {
                        setFunctionTitles(["WHISPER", "INK_MEMO", "VOICE_MEMO", "FILE_SHARE", "MEDIA_SOUP_CON", "HANDS_UP", "ANDROID_CON"]);
                    } else {
                        setFunctionTitles(["WHISPER", "INK_MEMO", "VOICE_MEMO", "FILE_SHARE"/* , "WatsonS-KR", "WatsonS-EN", "WatsonT-KR", "WatsonT-EN" */, "MEDIA_SOUP_CON", "HANDS_UP", "ANDROID_CON"]);
                    }
                } else {
                    if (ConstData.IS_LOCAL_VERSION) {
                        setFunctionTitles(["WHISPER", "INK_MEMO", "VOICE_MEMO", "FILE_SHARE", "MEDIA_SOUP_CON", "HANDS_UP"]);
                    } else {
                        setFunctionTitles(["WHISPER", "INK_MEMO", "VOICE_MEMO", "FILE_SHARE"/* , "WatsonS-KR", "WatsonS-EN", "WatsonT-KR", "WatsonT-EN" */, "MEDIA_SOUP_CON", "HANDS_UP"]);
                    }
                }
            }
        }
    }, []);

    useEffect(() => {
        if (connectStatus === "disable") {
            setConColor("grey");
        } else if (connectStatus === "on") {
            setConColor("rgb(108, 185, 54)");
        } else if (connectStatus === "off") {
            setConColor("red");
        }
    }, [connectStatus]);

    useEffect(() => {
        setIsConnectedApp(isConnectedOnScreenApp);
        setIsDisabled(false);
    }, [isConnectedOnScreenApp]);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const handleChangeFile = (e) => {
        if (e.target.files !== undefined && e.target.files !== null) {
            if (e.target.files[0] !== undefined && e.target.files[0] !== null) {
                if (e.target.files[0].name !== undefined && e.target.files[0].name !== null) {
                    let filename = e.target.files[0].name;
                    onHandleClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: isChatting ? ctActionType.FileShare : ctWebActionType.FileShare, data: e.target.files[0], filename });
                } else {
                    console.log("handleChangeFile 1 : cancel was pressed");
                }
            } else {
                console.log("handleChangeFile 2 : cancel was pressed");
            }
        } else {
            console.log("handleChangeFile 3 : cancel was pressed");
        }
    }

    const getIconImgUrl = (name) => {
        return ConstData.ICON_IMG_DIR + name + ".png";
    }

    const connectBtProc = () => {
        // console.log("------connectBtProc------2", connectStatus);

        if (connectStatus === "off") {
            handleSelectedClassTalkFunc({ selectedFunc: "LIVE_RECON" });
        } else if (connectStatus === "on") {
            // console.log("connectBtProc-222");
            // unsubscribe(false, false);
        }
    }

    const androidConnectBtProc = () => {
        setIsDisabled(true);

        // 기존 코드---------여기부터 call android app for smart pen connect & call disconnect android app
        /* if (isConnectedApp) {
            sendStartPentalkOnScreenForAndroid(true, false, false, false);
        } else {
            sendStartPentalkOnScreenForAndroid(handsUpYN !== "Y" ? true : false, false, false, false);
        } */
        // 기존 코드---------여기까지 ... by hjkim 20221020

        // 수정 코드 ... by hjkim 20221020 (not support smart pen connect)
        if (isConnectedApp) {
            xmitCmd_AndroidMessage("ct_FinishLive", enTokenCMD.NULL);
        } else {
            sendStartPentalkOnScreenForAndroid(handsUpYN !== "Y" ? true : false, false, false, false);
        }
    }
    
    return (
        <div className="d-flex justifty-content-between flex-wrap w-100 px-2 py-1" style={{ backgroundColor: 'rgba(246, 248, 250)', minHeight: 'max-content' }} onClick={handlePreventClick}>
            {
                isChatting ?
                    functionTitles !== undefined && functionTitles !== null && functionTitles.map((title, index) => (
                        <div className="d-flex my-2 align-items-center justify-content-center area-25" key={index}>
                            {
                                title === "PANEL" ?
                                    <div className="d-flex flex-column align-items-center">
                                        {
                                            isActiveLive ?
                                                <button className={"hiclasstv-classtalk-menu" + (isPanelWindowOpened ? " select" : "")} style={{ backgroundColor: 'rgb(62, 188, 210)' }} onClick={togglePanelWindow}>
                                                    <BsPeople className="hiclasstv-classtalk-icon" style={{ padding: '.25rem' }} />
                                                </button> :
                                                <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(215, 215, 215)' }} disabled>
                                                    <BsPeople className="hiclasstv-classtalk-icon" style={{ padding: '.25rem' }} />
                                                </button>
                                        }
                                        <span className="hiclasstv-classtalk-text mt-1">대상자 패널</span>
                                    </div> :
                                title === "INK_MEMO" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(28, 162, 209)' }} onClick={() => onHandleClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.InkMemo })}>
                                            <img src={getIconImgUrl("inkMemo")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">잉크메모</span>
                                    </div> :
                                title === "VOICE_MEMO" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(28, 162, 209)' }} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.VoiceMemo })}>
                                            <img src={getIconImgUrl("voiceMemo")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">음성메모</span>
                                    </div> :
                                title === "FILE_SHARE" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className="hiclasstv-classtalk-menu" type="file" style={{ backgroundColor: 'rgb(52, 182, 157)' }} onClick={handleChangeFile}>
                                            <label className="button m-0 p-0" htmlFor="ex_file">
                                                <img src={getIconImgUrl("fileShare")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                            </label>
                                            <input className="p-0" type="file" id="ex_file" style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', overflow: 'hidden', border: '0', clip: 'rect(0,0,0,0)' }} onChange={handleChangeFile} />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">파일공유</span>
                                    </div> :
                                title === "PULL_SCREEN" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(62, 188, 210)' }} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.PullScreen })}>
                                            <img src={getIconImgUrl("pullScreen")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">Pull</span>
                                    </div> :
                                title === "TEAMUP_WINDOW" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className={"hiclasstv-classtalk-menu" + (isTeamUpOpened ? " select" : "")} style={{ backgroundColor: 'rgb(106, 78, 157)' }} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.TeamUpWindow })}>
                                            <img src={getIconImgUrl("teamUp")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">Teamup</span>
                                    </div> :
                                title === "HANDSUP_WINDOW" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className={"hiclasstv-classtalk-menu" + (isHandsupOpened ? " select" : "")} style={{ backgroundColor: 'rgb(106, 78, 157)' }} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.HandsUpWindow })}>
                                            <img src={getIconImgUrl("handsUp")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">Handsup</span>
                                    </div> :
                                title === "QUIZ" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className={"hiclasstv-classtalk-menu" + (isQuizWindowOpened ? " select" : "")} style={{ backgroundColor: 'rgb(62, 188, 210)' }} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.Quiz })}>
                                            <FaRegLightbulb className="hiclasstv-classtalk-icon" style={{ padding: '.25rem' }} />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">Quiz</span>
                                    </div> :
                                /* ConstData.IS_LOCAL_VERSION &&  */activeLive && activeLive.liveSeq > -1 && title === "FILE_DOWN" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(52, 182, 157)' }} disabled={isFileDownloadingNow ? true : false} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.FileDownload })}>
                                            <FaFileCircleCheck className="hiclasstv-classtalk-icon" style={{ padding: '.25rem' }} />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">공유파일다운</span>
                                    </div> :
                                /* ConstData.IS_LOCAL_VERSION &&  */activeLive && activeLive.liveSeq > -1 && title === "FILE_CLEAR" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(52, 182, 157)' }} disabled={isFileDownloadingNow ? true : false} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "TOGGLE_FILE_DIR_REMOVE"/* selectedFunc: "FUNC_SELECT", func: ctActionType.FileClear */ })}>
                                            <FaFileCircleXmark className="hiclasstv-classtalk-icon" style={{ padding: '.25rem' }} />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">공유파일삭제</span>
                                    </div> :
                                title === "WatsonS-KR" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button
                                            className={"hiclasstv-classtalk-menu" + (isRunningWatson && isSpeakingMode && selectedLanguageCode === "ko-KR" ? " select" : "")}
                                            disabled={isRunningWatson ? true : false}
                                            style={{ backgroundColor: 'rgb(255, 145, 70)' }}
                                            onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.WatsonS_KR })}
                                        >
                                            <img src={getIconImgUrl("watsonKR")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">Watson</span>
                                    </div> :
                                <></>
                            }
                        </div>
                    )) :
                    functionTitles !== undefined && functionTitles !== null && functionTitles.map((title, index) => (
                        <div className="d-flex my-2 align-items-center justify-content-center" style={{ width: '20%' }} key={index}>
                            {
                                title === "WHISPER" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" title="귓속말">
                                        <button className="hiclasstv-classtalk-menu hiclasstv-grey" /* style={{ backgroundColor: 'rgb(215, 215, 215)' }} */ onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctWebActionType.Whisper })}>
                                            <FaAssistiveListeningSystems className="hiclasstv-classtalk-icon" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text">귓속말</span>
                                    </div> :
                                title === "INK_MEMO" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" title="잉크메모">
                                        <button className="hiclasstv-classtalk-menu hiclasstv-cornflowerblue" /* style={{ backgroundColor: 'rgb(28, 162, 209)' }} */ onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctWebActionType.InkMemo })}>
                                            <img src={getIconImgUrl("inkMemo")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">잉크메모</span>
                                    </div> :
                                title === "VOICE_MEMO" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" title="음성메모">
                                        <button className="hiclasstv-classtalk-menu hiclasstv-dodgerblue" /* style={{ backgroundColor: 'rgb(28, 162, 209)' }} */ onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctWebActionType.VoiceMemo })}>
                                            <img src={getIconImgUrl("voiceMemo")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">음성메모</span>
                                    </div> :
                                title === "FILE_SHARE" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" title="파일공유">
                                        <button className="hiclasstv-classtalk-menu hiclasstv-mediumseagreen" type="file" /* style={{ backgroundColor: 'rgb(52, 182, 157)' }} */ onClick={handleChangeFile}>
                                            <label className="button m-0 p-0" htmlFor="ex_file">
                                                <img src={getIconImgUrl("fileShare")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                            </label>
                                            <input className="p-0" type="file" id="ex_file" style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', overflow: 'hidden', border: '0', clip: 'rect(0,0,0,0)' }} onChange={handleChangeFile} />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">파일공유</span>
                                    </div> :
                                title === "MEDIA_SOUP_CON" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" 
                                        title={connectStatus === "disable" || connectStatus === "off" ? "화면 연결" : "연결 끊기"}
                                    >
                                        <button className={"hiclasstv-classtalk-menu" + (connectStatus === "disable" ? " hiclasstv-grey" : connectStatus === "off" ? " hiclasstv-red" : " hiclasstv-yellowgreen")} /* style={{ backgroundColor: `${conColor}` }} */ disabled={connectStatus === "disable"} onClick={connectBtProc}>
                                            {
                                                /* connectStatus === "disable" ?
                                                    <img src={ConstData.ICON_IMG_DIR + "disconnectDisable.png"} aria-hidden="true" className="hiclasstv-classtalk-icon" data-toggle="tooltip" title="화면 연결" alt="화면 연결" /> :
                                                    connectStatus === "off" ?
                                                        <img src={ConstData.ICON_IMG_DIR + "connectDisable.png"} aria-hidden="true" className="hiclasstv-classtalk-icon" data-toggle="tooltip" title="화면 연결" alt="화면 연결" /> :
                                                        <img src={ConstData.ICON_IMG_DIR + "connectDisable.png"} aria-hidden="true" className="hiclasstv-classtalk-icon" data-toggle="tooltip" title="연결 끊기" alt="연결 끊기" /> */
                                            }
                                            <img className="hiclasstv-classtalk-icon" aria-hidden="true" /* data-toggle="tooltip" */
                                                src={connectStatus === "disable" ? ConstData.ICON_IMG_DIR + "disconnectDisable.png" : ConstData.ICON_IMG_DIR + "connectDisable.png"}
                                                /* title={"화면 연결"} */
                                                alt={connectStatus === "disable" || connectStatus === "off" ? "화면 연결" : "연결 끊기"}
                                            />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">화면연결</span>
                                    </div> :
                                title === "HANDS_UP" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" title="저요저요">
                                        <button className={"hiclasstv-classtalk-menu" + (handsUpYN === "N" ? " hiclasstv-grey" : myHandsUpYN === "Y" ? " menu-color-auto" : " hiclasstv-blue")} disabled={handsUpYN === "N"} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "HANDS_UP" })}>
                                            <img src={ConstData.ICON_IMG_DIR + "handsup_me.png"} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">저요저요</span>
                                    </div> :
                                title === "ANDROID_CON" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" title={isConnectedApp ? "앱연동해제" : "앱연동"}>
                                        <button className={"hiclasstv-classtalk-menu" + (isConnectedApp ? " hiclasstv-blue" : " red")} disabled={isDisabled} onClick={androidConnectBtProc}>
                                            <FaAndroid className="hiclasstv-classtalk-icon" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{isConnectedApp ? "앱연동해제" : "앱연동"}</span>
                                    </div> :
                                title === "WatsonS-KR" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" title="Watson">
                                        <button className={"hiclasstv-classtalk-menu" + (isRunningWatson && isSpeakingMode && selectedLanguageCode === "ko-KR" ? " select" : "")} disabled={isRunningWatson && selectedLanguageCode === "en-US" ? true : false} style={{ backgroundColor: 'rgb(255, 145, 70)' }} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctWebActionType.WatsonS_KR })}>
                                            <img src={getIconImgUrl("watsonKR")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">Watson</span>
                                    </div> :
                                    <></>
                            }
                        </div>
                    ))
            }
        </div>
        /* <div className="d-flex justify-content-between flex-wrap w-100 m-0" style={{ height: 'max-content', padding: '10px 25px', lineHeight: '1rem', backgroundColor: 'rgba(246, 248, 250)' }} onClick={handlePreventClick}>
            {getImageList()}
        </div> */
    );
}

export default FunctionList;