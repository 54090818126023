import React, { useState } from 'react';

import { stCardType } from '../../lib/ConstCommand';

import TextCard from './card/TextCard';
import AudioCard from './card/AudioCard';
import AudioTextCard from './card/AudioTextCard';
import ImageCard from './card/ImageCard';
import ImageAudioCard from './card/ImageAudioCard';
import VideoCard from './card/VideoCard';
import VideoTextCard from './card/VideoTextCard';
import AnimatedMP3Card from './card/AnimatedMP3Card';
import AnimatedMP3TextCard from './card/AnimatedMP3TextCard';
import LinkCard from './card/LinkCard';
import PronunciationCard from './card/PronunciationCard';
import InkMediaCard from './card/InkMediaCard';
import QuizCardForStudent from './card/QuizCardForStudent';
import CongratsCard from './card/CongratsCard';
import MultiTurnCard from './card/MultiTurnCard';
import RoleScriptCard from './card/RoleScriptCard';

const CardType = ({ info, handleSelectedFunc, isFinished, handleSelectedCardFunc }) => {
    switch (info.type) {
        case stCardType.InkMedia :
            return <InkMediaCard cardInfo={info} />;

        case stCardType.Text :
            return <TextCard cardInfo={info} />;

        case stCardType.Audio :
            return <AudioCard cardInfo={info} />;

        case stCardType.AudioText :
            return <AudioTextCard cardInfo={info} />;

        case stCardType.Video :
            return <VideoCard cardInfo={info} />;

        case stCardType.VideoText :
            return <VideoTextCard cardInfo={info} />;

        case stCardType.Image :
            return <ImageCard cardInfo={info} />;
    
        case stCardType.ImageAudio :
            return <ImageAudioCard cardInfo={info} />;

        case stCardType.AnimatedMP3 :
            return <AnimatedMP3Card cardInfo={info} />;

        case stCardType.AnimatedMP3Text :
            return <AnimatedMP3TextCard cardInfo={info} />;

        case stCardType.Link :
            return <LinkCard cardInfo={info} />;

        case stCardType.Pronunciation :
            return <PronunciationCard cardInfo={info} handleSelectedFunc={handleSelectedFunc} />;

        case stCardType.Quiz :
            return <QuizCardForStudent cardInfo={info} isFinished={isFinished} handleSelectedCardFunc={handleSelectedCardFunc} />;

        case stCardType.MultiTurn :
            return <MultiTurnCard cardInfo={info} handleSelectedCardFunc={handleSelectedCardFunc} />;
        
        case stCardType.RoleScript :
            return <RoleScriptCard cardInfo={info} />;

            default :
            return <></>;
    }
}

const CardTemplate = ({ cardInfo, handleSelectedFunc }) => {
    const [isFinished, setIsFinished] = useState(false);

    const [congratsCardData, setCongratsCardData] = useState(null);
    const [isCongratsVisible, setIsCongratsVisible] = useState(false);
    
    const getNextCard = ({ cardInfo, isQuiz, answer }) => {
        //console.log('CardTemplate - ', cardInfo);
        if (isQuiz) {
            handleSelectedFunc({ selectedFunc: 'SEND_QUIZ_ANSWER', data: { cardSeq: cardInfo.seq, msgIndex: cardInfo.idx, answer } });
        } else {
            handleSelectedFunc({ selectedFunc: 'GET_NEXT_CARD', msgText: 'next' });
        }
    }

    const onHandleQuizCard = () => {
        //console.log('제출');
        setIsFinished(true);
    }

    const handleSelectedCardFunc = ({ selectedFunc, isQuiz, data, answer, isCongratsVisible }) => {
        switch(selectedFunc) {
            case 'GET_NEXT_CARD' : 
                getNextCard({ cardInfo, isQuiz, answer });
                break;

            case 'GET_NEXT_CARD_AFTER_CONGRATS_CARD' :
                setIsCongratsVisible(isCongratsVisible);
                setCongratsCardData(data);
                getNextCard({ cardInfo, isQuiz, answer });                
                break;

            case 'SET_PASS_POSSIBLE' : 
                handleSelectedFunc({ selectedFunc, data });
                break;

            case 'SEND_MSG_WATSON':
                handleSelectedFunc({ selectedFunc, msgText: data });
                break;

            case '' :
                break;

            default :
                break;
        }
    }

    return (
        <>
            <div className="bubble-card msg-bg-violet-btext">
                <CardType
                    info={cardInfo}
                    isFinished={isFinished}
                    handleSelectedFunc={handleSelectedFunc}
                    handleSelectedCardFunc={handleSelectedCardFunc}
                />
                {
                    cardInfo.type === stCardType.Quiz ?
                        <button type="button" title="답안 제출" className="btn font-xsm hiclasstv-blue" disabled={cardInfo.isFinishedQuiz || !cardInfo.isPossiblePass || isFinished} onClick={() => onHandleQuizCard()}>
                            <span>▶ 제출</span>
                        </button> :
                    cardInfo.next !== null &&
                        <button type="button" className="btn font-xsm hiclasstv-blue" disabled={cardInfo.isCurrent === false || (cardInfo.isCurrent && !cardInfo.isPossiblePass)} onClick={() => getNextCard({ cardInfo, isQuiz: false })}>
                            <span>▶ 다음</span>
                        </button>
                }
            </div>
            {
                (cardInfo.type === stCardType.Quiz && isCongratsVisible) &&
                <div className="bubble-card msg-bg-violet-btext">
                    <CongratsCard 
                        isCongratsVisible={isCongratsVisible}
                        info={congratsCardData}
                    />
                </div>
            }
        </>
    );
}

export default CardTemplate;