import React from 'react';
import CardTemplate from '../CardTemplate';

const MultiTurnCard = ({ cardInfo, handleSelectedFunc }) => {
    return (
        cardInfo && cardInfo.data && cardInfo.data.length > 0 ?
            cardInfo.data.map((info, idx) => (
                <CardTemplate key={idx} cardInfo={info} handleSelectedFunc={handleSelectedFunc} />
            )) :
            <></>
    );
}

export default MultiTurnCard;