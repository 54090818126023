import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import WebMyWatchingLiveList from '../../components/live/WebMyWatchingLiveList';

import {
    toggleIsNeedGoToHome
} from '../../modules/live';

class MyWatchingLiveListContainer extends Component {

    shouldComponentUpdate(nextProps) {
        return (JSON.stringify(nextProps) !== JSON.stringify(this.props));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isNeedGoToHome !== this.props.isNeedGoToHome && this.props.isNeedGoToHome === true) {
            this.props.routeProps.history.push('/main');
            this.props.toggleIsNeedGoToHome(false);
        } else {
            if (prevProps.isGotoWebLive !== this.props.isGotoWebLive && this.props.isGotoWebLive === true) {
                //this.props.routeProps.history.push(`/live/${this.props.performLiveSeq}`);
            } else {
                if (this.props.routeProps && this.props.routeProps.location) {
                    if (this.props.routeProps.location.search && this.props.routeProps.location.search !== "") {
                        const query = queryString.parse(this.props.routeProps.location.search);
                        if (query && ((query.broadcastKey && query.broadcastKey !== "") ||
                            (query.guestLoginKey && query.guestLoginKey !== "") ||
                            (query.mixedClassTokenKey && query.mixedClassTokenKey !== ""))) {
                            const live_info = this.props.list_watchLive.find(info => info.liveSeq === this.props.joinLiveInfo.liveSeq);
                            console.log("live_info => ", live_info);
                            if (live_info) {
                                this.props.handleSelectedFunc({
                                    selectedFunc: "JOIN",
                                    liveSeq: live_info.liveSeq,
                                    liveName: live_info.liveName,
                                    mixedClassSeq: this.props.mixedClassInfo.mixedClassSeq
                                });
                            } else {
                                this.props.toggleIsNeedGoToHome(true);
                            }
                        }
                    }
                }
            }
        }
    }

    render() {
        return (
            <WebMyWatchingLiveList
                isGuestYn={this.props.isGuestYn}
                broadcastOpenYN={this.props.joinLiveInfo.broadcastOpenYN}
                list_watchLive={this.props.list_watchLive}
                list_requestedWatchLive={this.props.list_requestedWatchLive}
                alertKind={this.props.alertKind}
                alertMessage={this.props.alertMessage}
                searchLiveInfo={this.props.searchLiveInfo}
                handleSelectedFunc={this.props.handleSelectedFunc}
            />
        );
    }
}

export default connect(
    ({ user, live }) => ({
        isGuestYn               : user.isGuestYn,
        //userSeq                 : user.userSeq,
        //userNickname            : user.userNickname,

        isNeedGoToHome          : live.isNeedGoToHome,
        isGotoWebLive           : live.isGotoWebLive,

        joinLiveInfo            : live.joinLiveInfo,
        performLiveSeq          : live.performLiveInfo.liveSeq,

        list_watchLive          : live.list_watchLive,
        list_requestedWatchLive : live.list_requestedWatchLive,

        searchLiveInfo          : live.searchLiveInfo,

        mixedClassInfo          : live.mixedClassInfo,

        alertMessage            : live.searchInfoAlertMessage,
        alertKind               : live.searchInfoAlertKind
    }),
    {
        toggleIsNeedGoToHome
    }
)(MyWatchingLiveListContainer);