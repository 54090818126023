import React, { useState, useEffect } from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import { FaBook } from 'react-icons/fa';
import WebListArrowButton from '../etc/button/WebListArrowButton';
import WebLectureList from './list/WebLectureList';

const WebMyLectureList = ({ isUsablePass, list_lecture, handleSelectedFunc }) => {
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        if (list_lecture.length > 0) {
            setIsOpened(true);
        }
    }, [list_lecture]);

    return (
        <div className="web-main list-area">
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <span className="d-flex align-items-center white-text mx-1">
                            <FaBook className="font-lg mr-2" aria-hidden="true" />내 강의
                        </span>
                    </div>
                    <WebListArrowButton toggle_id={"lectureList"} list_array={list_lecture} isOpened={isOpened} />
                </div>
                <UncontrolledCollapse toggler="#lectureList" defaultOpen={true} onEntering={() => setIsOpened(true)} onExiting={() => setIsOpened(false)}>
                    <WebLectureList rowKey={'lecture-'} isUsablePass={isUsablePass} list_lecture={list_lecture} handleSelectedFunc={handleSelectedFunc} />
                </UncontrolledCollapse>
            </div>
        </div>
    );
}

export default WebMyLectureList;