import React from 'react';
import { BsX } from 'react-icons/bs';

const ParticipateChatRoom = ({ performChatRoomInfo, participateChatRoomInfo, handleSelectedFunc }) => {

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" onClick={() => handleSelectedFunc({selectedFunc:"NONE"})}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex text-center my-2 dark-grey-text small">
                현재 참여 중인 팀업을 종료하고<br />
                새로운 팀업에 참여하시겠습니까?
            </div>
            <div className="content-area dark-grey-text my-0">
                <span className="small mb-1">
                    [참여 중]
                </span>
                <div className="text-area text-center area-80 mb-3">
                    <span className="hiclass-text" title={performChatRoomInfo.chatRoomName}>
                        <b>{performChatRoomInfo.chatRoomName}</b>
                    </span>
                </div>
                <span className="small mb-1">
                    [참여 요청]
                </span>
                <div className="text-area text-center area-80 mb-3">
                    <span className="hiclass-text" title={participateChatRoomInfo.chatRoomName}>
                        <b>{participateChatRoomInfo.chatRoomName}</b>
                    </span>
                </div>
            </div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small green white-text m-0 mr-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc:"PARTICIPATE_OK", chatRoomSeq:participateChatRoomInfo.chatRoomSeq, chatRoomName:participateChatRoomInfo.chatRoomName })}>예</button>
                <button type="button" className="btn btn-sm small red white-text m-0 ml-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc:"NONE"})}>아니오</button>
            </div>
        </div>
    );
}

export default ParticipateChatRoom;