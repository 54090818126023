import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';

import { getEntryMemberCount, getMemberCount } from '../../lib/func/ExportFunction';

const PerformChattingInfo = ({ isOnAirLive, performChatRoomInfo, handleSelectedChatFunc }) => {
    return (
        <div className="title-area main hiclasstv-blue">
            <div className="text-area justify-content-start">
                <span className="hiclass-text white-text" title={performChatRoomInfo.chatRoomName}>
                    {performChatRoomInfo.chatRoomName}
                </span>
                <span className="hiclass-text hiclasstv-yellow-text">
                    <b>&nbsp;{getEntryMemberCount('teamUp', performChatRoomInfo.list_member)}</b>/{getMemberCount(performChatRoomInfo.list_member)}&nbsp;&nbsp;
                </span>
            </div>
            <div className="d-flex area-10">
                {/*<div className="d-flex align-items-center m-0 p-0">
                    <button type="button" className="btn btn-sm red white-text mx-1 px-2 py-1" style={{borderRadius:'0.5rem', width:'max-content'}} onClick={() => handleSelectedChatFunc({ selectedFunc: "FINISH", chatRoomSeq: performChatRoomInfo.chatRoomSeq })}>
                        종료
                    </button>
                </div>
                */}
                <button type="button" className="d-flex p-1 rounded" title="더보기" onClick={() => handleSelectedChatFunc({ selectedFunc: "CHAT_MENU" })}>
                    <FaBars className="d-flex font-md white-text" />
                </button>
            </div>
        </div>
    );
}

export default PerformChattingInfo;