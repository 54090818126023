import React from "react";
import MainTemplate from "../components/template/MainTemplate";
import QuizPopUpContainer from "../containers/QuizPopUpContainer";

const QuizPage = ({ ...props }) => {
    return (
        <MainTemplate>
            <QuizPopUpContainer {...props} />
        </MainTemplate>
    );
}

export default QuizPage;