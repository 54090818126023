import React, { useState } from 'react';

import CenteredModal from '../template/modal/CenteredModal';
import DisplayList from './DisplayList';
import GroupInfo from './etc/GroupInfo';
import AlertStudio from './modal/AlertStudio';

const getCount = (list_array) => {
    return list_array.length;
};

const PerformStudioInfo = ({ studio_info, list_live, userSeq, handleSelectedFunc }) => {
    const [isAlertModalOpened, setIsAlertModalOpened] = useState(false);

    const handleFunc = ({ selectedFunc, studio_info }) => {
        //console.log('handleFunc - ', selectedFunc, studio_info);
        switch (selectedFunc) {
            case "CONFIRM_ALERT" :
                setIsAlertModalOpened(false);
                break;

            case "DIS_CON_STUDIO" :
                handleSelectedFunc({ selectedFunc: "CLEAR_ASSIGN", studioSeq: studio_info.studioSeq });
                setIsAlertModalOpened(false);
                break;
            
            default :
                console.log('handleFunc() - selectedFunc:', selectedFunc);
                break;
        }
    };
    
    return (
        studio_info !== undefined && studio_info !== null ? 
            <>
                {
                    isAlertModalOpened &&
                    <CenteredModal modalClose={() => setIsAlertModalOpened(false)}>
                        <AlertStudio 
                            userSeq={userSeq}
                            studio_info={studio_info}
                            list_live={list_live}
                            handleFunc={handleFunc}
                        />
                    </CenteredModal>
                }
                <div className="list-group" style={{minHeight:'10%', height:'50%', maxHeight:'max-content', overflow:'auto'}}>
                    <div className="title-area sub hiclasstv-grey hiclasstv-blue-text">
                        <div className="text-area justify-content-start full" title={"스튜디오 [" + studio_info.studioName + "]"}>
                            <span className="hiclass-text">현재 스튜디오 [</span>
                            <span className="hiclass-text font-weight-bold">{studio_info.studioName}</span>
                            <span className="hiclass-text">]</span>
                        </div>
                        <div className="d-flex area-15 ml-2">
                            <button type="button" className="d-flex rounded px-1 logout red-text" onClick={() => setIsAlertModalOpened(true)}>
                                <span className="small" title="스튜디오 연결 종료"><b>종료</b></span>
                            </button>
                        </div>
                    </div>
                    <DisplayList list_display={studio_info.list_display} groupLimit={studio_info.groupLimit} />
                    {/* <div className="title-area sub hiclasstv-blue white-text">
                        <b>클래스 목록</b>
                    </div> */}
                </div>
            </>
             :
            <></>
    );
};

export default PerformStudioInfo;