/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';

const QnA006 = ({ isOpened, onChangeOpenedFlag }) => {
    useEffect(() => {
        let content = document.getElementById("qna06");

        if (isOpened) {
            content.style.maxHeight = content.scrollHeight + "px";
        } else {
            content.style.maxHeight = null;
        }
    }, [isOpened]);

    return (
        <div className="accordion">
            <div className="accordion-title" onClick={() => onChangeOpenedFlag(5)}>
                <span className="hiclasstv-deepDarkgrey-text button">
                    <b>6. 특장점을 설명해 주세요.</b>
                </span>
            </div>
            <div className="accordion-content hiclasstv-darkgrey-text" id="qna06">
                <span className="d-flex" style={{ minWidth: 'max-content' }}>A.&nbsp;</span>
                <p>
                    HiClassTV에 관심을 가져 주심에 감사드립니다. HiClassTV의 특장점은 다음과 같습니다.<br />
                    <b>ㆍ에듀텍 특허 기술에 의한 차별화</b><br />
                    10여 년 이상의 투자로 10여 개 특허와 40여 개의 지식재산권을 보유하고 있으며, 이를 기반으로 비대면 플랫폼부터 에듀텍 기기까지 전체 기술을 확보하였습니다.<br />
                    <b>ㆍ사용자 경험에 기반</b><br />
                    오랫동안 에듀텍 관련 기술 개발과 공교육 조달을 통해 현장에서 실사용자들의 요구사항을 지속해서 반영했습니다.<br />
                    <b>ㆍ국내 유일의 디지로그 콘텐츠 기술</b><br />
                    비대면의 최대 약점인 <b>“종이 쓰기”</b>를 함께 할 수 있어 다양한 디지로그 콘텐츠를 생성할 수 있습니다.<br />
                    <b>ㆍ인공지능 기술 접목</b><br />
                    인공지능 챗봇 서비스 기술을 접목하였습니다.<br />
                    <b>ㆍ보조 도구의 활용</b><br />
                    다양한 보조 도구들이 개발되어 있으며 HID 표준 기술로 개발되어 다른 도구들도 쉽게 접목할 수 있습니다.
                </p>
            </div>
        </div>
    );
}
export default QnA006;