import React, { useMemo } from 'react';
import { getMemberProfileImage } from '../../../lib/func/ExportFunction';

const ClassTalkProfileImage = ({ url, alt }) => {
    const profileImgUrl = useMemo(() =>  getMemberProfileImage(url), [url]);

    return (
        <img className="hiclasstv-profile-img ct-profile-img" src={profileImgUrl} alt={alt} />
    );
}

export default ClassTalkProfileImage;