export default function tossPhaseAction(type, afterTypeArr) { 

    return parm => (dispatch, getState) => {
        //console.log(parm);

        dispatch({
            type,
            parms : parm,
            payload: parm
        });

        if (afterTypeArr !== undefined && afterTypeArr !== null) {
            afterTypeArr.forEach(typeName => {
                dispatch({
                    type: typeName,
                    parms : parm,
                    payload: parm
                })
            });
        }
    }
}