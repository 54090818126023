import React, { useState, useEffect } from 'react';
import { FaServer, FaPlusCircle } from 'react-icons/fa';
import { UncontrolledCollapse } from 'reactstrap';

import WebAddLocalSite from './WebAddLocalSite';
import WebLocalSiteList from './list/WebLocalSiteList';

const WebLocalSiteConfig = ({ list_configLocalSite, handleSelectedFunc }) => {
    const [isAddLocalSiteOK, setIsAddLocalSiteOK] = useState(false);

    useEffect(() => {
        handleSelectedFunc({ selectedFunc: "GET_LOCAL_SITE_LIST" });

        return () => {
            
        }
    }, []);

    useEffect(() => {
        setIsAddLocalSiteOK(true);
    }, [list_configLocalSite]);

    const onChangeIsAddLocalSiteOK = () => {
        setIsAddLocalSiteOK(!isAddLocalSiteOK);
    }

    return (
        <div className="web-main list-area" style={{ maxWidth: '900px' }}>
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaServer className="font-lg mr-2" aria-hidden="true" />로컬 사이트
                        </span>
                        <button type="button" className="d-flex btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" id="addLocalSite">
                            <FaPlusCircle className="font-md mr-1" />로컬 사이트 등록
                        </button>
                    </div>
                </div>
                <UncontrolledCollapse toggler="#addLocalSite" defaultOpen={false}>
                    <WebAddLocalSite 
                        isAddLocalSiteOK={isAddLocalSiteOK}
                        onChangeIsAddLocalSiteOK={onChangeIsAddLocalSiteOK}
                        handleSelectedFunc={handleSelectedFunc}
                    />
                </UncontrolledCollapse>
                <WebLocalSiteList
                    list_localSite={list_configLocalSite}
                    handleSelectedFunc={handleSelectedFunc}
                />
            </div>
        </div>
    )
};

export default WebLocalSiteConfig;