import React, { useState, useEffect } from "react";
import { UncontrolledCollapse } from "reactstrap";
import { FaSortUp, FaSortDown, FaCircle, FaBell } from "react-icons/fa";

import CustomProfileImage from "../../user/profile/CustomProfileImage";

const convertDateTime = (date) => {
    let dateTime = date.replace(" ", "");
    let dt = new Date();
    dt.setFullYear(dateTime.substring(0, 4));
    dt.setMonth(dateTime.substring(4, 6) - 1);
    dt.setDate(dateTime.substring(6, 8));
    dt.setHours(dateTime.substring(8, 10));
    dt.setMinutes(dateTime.substring(10, 12));
    dt.setSeconds(dateTime.substring(12, 14));

    return (`${dt.getFullYear()}. ${dt.getMonth()}. ${dt.getDate()}. ` + (dt.getHours() > 12 ? "오후 " : "오전 ") + `${dt.getHours() - 12}:` + (dt.getMinutes() < 10 ? `0${dt.getMinutes()}` : dt.getMinutes()));
}

const ChattingMenuLiveList = ({ list_live, handleSelectedFunc }) => {

    const liveArr = list_live.map(live => ({
        liveSeq:            live.liveSeq,
        isOpened:           false,
        isBroadcastOpened:  false,
        list_broadcast:     live.list_broadcast.map(
                broadcast => ({
                broadcastSeq:       broadcast.broadcastSeq,
                isOpened:           false
            })
        )
    }));

    const [list_live_toggle, setLiveToggleValue] = useState(liveArr);

    useEffect(() => {
        if (list_live_toggle.length > 0) {
            list_live_toggle.forEach(toggle => {
                toggle.isOpened = false;
            });
        }
    }, [list_live_toggle.forEach(toggle => toggle.isOpened)]);

    useEffect(() => {
        if (list_live_toggle.length > 0) {
            list_live_toggle.forEach(toggle => {
                toggle.isBroadcastOpened = false;
            });
        }
    }, [list_live_toggle.forEach(toggle => toggle.isBroadcastOpened)]);

    const changeLiveToggleValue = (liveSeq, value) => {
        if(list_live_toggle && list_live_toggle.length > 0) {
            const arr = list_live_toggle.map(
                (toggle) => {
                    let item = toggle.liveSeq === liveSeq ? { 
                        liveSeq:            toggle.liveSeq, 
                        isOpened:           value, 
                        isBroadcastOpened:  toggle.isBroadcastOpened,
                        list_broadcast:     toggle.list_broadcast
                    } : toggle;

                    return item;
                }
            );
            setLiveToggleValue(arr);
        }
    }

    const changeBroadcastToggleValue = (liveSeq, value) => {
        if (list_live_toggle && list_live_toggle.length > 0) {
            const arr = list_live_toggle.map(
                (toggle) => {
                    let item = toggle.liveSeq === liveSeq ? { 
                        liveSeq:            toggle.liveSeq, 
                        isOpened:           toggle.isOpened, 
                        isBroadcastOpened:  value,
                        list_broadcast:     toggle.list_broadcast
                    } : toggle;

                    return item;
                }
            );
            setLiveToggleValue(arr);
        }
    }

    const changeBroadcastGuestToggleValue = (liveSeq, broadcastSeq, value) => {
        if (list_live_toggle && list_live_toggle.length > 0) {
            let live = list_live_toggle.find(info => info.liveSeq === liveSeq);

            if (live.list_broadcast && live.list_broadcast.length > 0) {
                const arr = live.list_broadcast.map(
                    (toggle) => {
                        let item = toggle.broadcastSeq === broadcastSeq ? {
                            broadcastSeq : toggle.broadcastSeq,
                            isOpened : value
                        } : toggle;

                        return item;
                    }
                );

                live.list_broadcast = arr;
            }            

            setLiveToggleValue(list_live_toggle.map(
                (info) => {
                    let item = info.liveSeq === liveSeq ? live : info;
                    return item;
                }
            ));
        }
    }

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area justify-content-between">
                <span className="hiclasstv-darkgrey-text button" title="이전" onClick={() => handleSelectedFunc({ selectedFunc: "LIST_LIVE" })}>
                    이전
                </span>
                <span>
                    <b>내 클래스 목록</b>
                </span>
                <span className="hiclasstv-darkgrey-text button" title="닫기" onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    닫기
                </span>
            </div>
            <div className="list-group" style={{ overflow: 'auto' }}>
                {
                    list_live && list_live.length > 0 ?
                        list_live.map((live, index) => (
                            <div className="list-group-hitem list-group-hitem-action p-0" style={{ height: 'auto', minHeight: 'max-content' }} key={"live_" + live.liveSeq}>
                                <div className="d-flex justify-content-center align-items-center p-2">
                                    <div className="text-area flex-column align-items-start">
                                        <span className="hiclass-text" title={live.liveName}><b>{live.liveName}</b></span>
                                        <div className="d-flex w-100">
                                            <span className="hiclass-text hiclasstv-darkgrey-text small">참여자&nbsp;<b>{live.list_member.length}</b>명</span>
                                        </div>
                                    </div>
                                    <div className="d-flex area-10 ml-2">
                                        <span className="func-btn" id={"live_member_toggler-" + live.liveSeq}>
                                            {
                                                list_live_toggle[index].isOpened ?
                                                    <FaSortUp className="font-md" title="숨기기" /> :
                                                    <FaSortDown className="font-md" title="더보기" />
                                            }
                                        </span>
                                    </div>
                                </div>
                                <UncontrolledCollapse toggler={"#live_member_toggler-" + live.liveSeq} defaultOpen={false} onEntering={() => changeLiveToggleValue(live.liveSeq, true)} onExiting={() => changeLiveToggleValue(live.liveSeq, false)}>
                                    {
                                        live.list_broadcast && live.list_broadcast.length > 0 &&
                                        <>
                                            <div className="title-area sub hiclasstv-yellow white-text p-2">
                                                <div className="text-area flex-column align-items-start">
                                                    <b>방송 예약 목록 ({live.list_broadcast.length})</b>
                                                </div>
                                                <div className="d-flex area-10 ml-2">
                                                    <span className="func-btn" id={"live-" + live.liveSeq + "_broadcast_toggler"}>
                                                        {
                                                            list_live_toggle[index].isBroadcastOpened ?
                                                                <FaSortUp className="font-md" title="방송 목록 숨기기" /> :
                                                                <FaSortDown className="font-md" title="방송 목록 보기" />
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <UncontrolledCollapse toggler={"#live-" + live.liveSeq + "_broadcast_toggler"} defaultOpen={false} onEntering={() => changeBroadcastToggleValue(live.liveSeq, true)} onExiting={() => changeBroadcastToggleValue(live.liveSeq, false)}>
                                                <div className="list-group">
                                                    {
                                                        live.list_broadcast.map((info, idx) => (
                                                            <div className="d-flex flex-column w-100" key={"brdcst-" + info.broadcastSeq}>
                                                                <div className="d-flex list-group-hitem list-group-hitem-action broadcast p-2 justify-content-center align-items-center">
                                                                    <div className="d-flex area-5 ml-1">
                                                                        {
                                                                            info.broadcastOpenYN === "Y" ?
                                                                                <FaBell className="font-xsm green-text" title="예약 방송 켜짐" /> :
                                                                                <FaBell className="font-xsm hiclasstv-darkgrey-text" title="예약 방송 꺼짐" />
                                                                        }
                                                                    </div>
                                                                    <div className="text-area flex-column align-items-start ml-2">
                                                                        <span className="hiclass-text" style={{ fontSize: '.75rem' }}>{"시작 " + convertDateTime(info.broadcastDateTime.strt)}</span>
                                                                        <span className="hiclass-text" style={{ fontSize: '.75rem' }}>{"종료 " + convertDateTime(info.broadcastDateTime.end)}</span>
                                                                    </div>
                                                                    <div className="d-flex area-10 ml-2">
                                                                        <span className="func-btn" id={"live-" + live.liveSeq + "-broadcast-" + info.broadcastSeq + "-guest_toggler"}>
                                                                            {
                                                                                list_live_toggle[index].list_broadcast[idx].isOpened ?
                                                                                    <FaSortUp className="font-md" title="게스트 명단 숨기기" /> :
                                                                                    <FaSortDown className="font-md" title="게스트 명단 보기" />
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <UncontrolledCollapse toggler={"#live-" + live.liveSeq + "-broadcast-" + info.broadcastSeq + "-guest_toggler"} defaultOpen={false} onEntering={() => changeBroadcastGuestToggleValue(live.liveSeq, info.broadcastSeq, true)} onExiting={() => changeBroadcastGuestToggleValue(live.liveSeq, info.broadcastSeq, false)}>
                                                                    <div className="list-group">
                                                                        {
                                                                            info.list_guest && info.list_guest.length > 0 ?
                                                                                info.list_guest.map(member => (
                                                                                    <div className="d-flex list-group-hitem list-group-hitem-action member py-2 px-1 justify-content-center align-items-center" key={"brdcst-" + info.broadcastSeq + "-guest-" + member.userSeq}>
                                                                                        <div className="d-flex area-15 fit-content">
                                                                                            <CustomProfileImage url={member.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={member.userNickname} />
                                                                                        </div>
                                                                                        <div className="text-area flex-column align-items-start">
                                                                                            <span className="hiclass-text" title={member.userNickname} style={{ fontSize: '90%' }}><b>{member.userNickname}</b></span>
                                                                                        </div>
                                                                                        {
                                                                                            member.userJoinStatus === "Y" &&
                                                                                            <div className="d-flex area-10 ml-1">
                                                                                                <FaCircle className="d-flex font-md green-text" />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                )) :
                                                                                <div className="text-center p-2 white grey-text small">
                                                                                    초대 한 게스트가 없습니다.
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </UncontrolledCollapse>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </UncontrolledCollapse>
                                        </>
                                    }
                                    <div className="title-area sub hiclasstv-grey white-text">
                                        <b>승인대기 ({live.list_waitMember.length})</b>
                                    </div>
                                    <div className="list-group">
                                        {
                                            live.list_waitMember && live.list_waitMember.length > 0 ?
                                                live.list_waitMember.map(member => (
                                                    <div className="d-flex list-group-hitem list-group-hitem-action member py-2 px-1 justify-content-center align-items-center" key={"lwmem_" + member.userSeq}>
                                                        <div className="d-flex area-15 fit-content">
                                                            <CustomProfileImage url={member.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={member.userNickname} />
                                                        </div>
                                                        <div className="text-area flex-column align-items-start">
                                                            <span className="hiclass-text" title={member.userNickname} style={{ fontSize: '90%' }}><b>{member.userNickname}</b></span>
                                                            <span className="hiclass-text hiclasstv-deepDarkgrey-text small" title={member.userProfile} style={{ fontSize: '70%' }}>{member.userProfile}</span>
                                                        </div>
                                                        <div className="d-flex area-25 ml-2" onClick={handlePreventClick}>
                                                            <button type="button" className="btn btn-sm green white-text m-0 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "MEMBER_ACCEPT", liveSeq: live.liveSeq, memberSeq: member.userSeq })}>
                                                                승인
                                                            </button>
                                                        </div>
                                                    </div>
                                                )) :
                                                <div className="text-center p-2 white grey-text small">
                                                    가입 요청한 멤버가 없습니다.
                                                </div>
                                        }
                                    </div>
                                    <div className="title-area sub hiclasstv-blue white-text">
                                        <b>멤버 ({live.list_member.length})</b>
                                    </div>
                                    <div className="list-group">
                                        {
                                            live.list_member && live.list_member.length > 0 ?
                                                live.list_member.map(member => (
                                                    <div className="d-flex list-group-hitem list-group-hitem-action member py-2 px-1 align-items-center justify-content-center" key={"lmem_" + member.userSeq}>
                                                        <div className="d-flex area-15 fit-content">
                                                            <CustomProfileImage url={member.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={member.userNickname} />
                                                        </div>
                                                        <div className="text-area flex-column align-items-start">
                                                            <span className="hiclass-text" title={member.userNickname} style={{ fontSize: '90%' }}><b>{member.userNickname}</b></span>
                                                            <span className="hiclass-text hiclasstv-deepDarkgrey-text" title={member.userProfile} style={{ fontSize: '70%' }}>{member.userProfile}</span>
                                                        </div>
                                                        {
                                                            member.userJoinStatus === "Y" &&
                                                            <div className="d-flex area-10 ml-1">
                                                                <FaCircle className="d-flex font-md green-text" />
                                                            </div>
                                                        }
                                                    </div>
                                                )) :
                                                <div className="text-center p-2 grey-text white small">
                                                    멤버가 존재하지 않습니다.
                                                </div>
                                        }
                                    </div>
                                </UncontrolledCollapse>
                            </div>
                        )) :
                        <div className="view p-2 text-center grey-text">
                            생성한 클래스 목록이 없습니다.
                        </div>
                }
            </div>
        </div>
    );
}

export default ChattingMenuLiveList;