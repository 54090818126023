import React, { useRef, useState } from 'react';

import ConstData from '../../lib/ConstData';
import { BsX } from 'react-icons/bs';

import AudioMemoMessage from '../classtalk/template/AudioMemoMessage';
import FileShareMessage from '../classtalk/template/FileShareMessage';
import ThumbnailMessage from '../classtalk/template/ThumbnailMessage';

const WhisperMessage = ({ indexKey, msgInfo, handleSelectUrl }) => {
    switch(msgInfo.kind) {
        case "audio_memo" :
            return <AudioMemoMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;

        case "ink_memo" :
            return <ThumbnailMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;

        case "file_share" :
            return <FileShareMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;
        
        default:
            return <></>;
    }
}

const WebWhisperArea = ({ msgInfo, handleSelectedClassTalkFunc }) => {    
    const audioEL = useRef(null);
    
    const audioStyle = {
        display : "none"
    };

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const handleSelectUrl = (selKind, srcUrl, idx, type) => {
        if (selKind === "audio_memo") {
            audioEL.current.src = srcUrl;
        } else if (selKind === "ink_memo") {
            handleSelectedClassTalkFunc({ selectedFunc: "INK_MEMO", fileURL: srcUrl });
        } else if (selKind === "file_share") {
            console.log("file_share msg : ", srcUrl);
            handleSelectedClassTalkFunc({ selectedFunc: "FILE_DOWNLOAD", fileURL: ConstData.DOWNLOAD_DIR + srcUrl, idx });
        } else if (selKind === "file_open") {
            handleSelectedClassTalkFunc({ selectedFunc: "FILE_OPEN", fileURL: srcUrl, fileType: type });
        } 
    };

    return (
        <div className='whisper-modal' onClick={handlePreventClick}>
            <div className='title-area'>
                <span>귓속말</span>
                <span className='func-btn' style={{ position: 'absolute', right: 0 }} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: 'CLOSE_WHISPER_MODAL' })}>
                    <BsX />
                </span>
            </div>
            <div className='msgBox'>
                <span className='pb-2'>
                    발신자 : <span className="font-weight-bold">{msgInfo.sender}</span>
                </span>
                <span className='text b-top font-weight-bold font-md plus'>
                    {msgInfo.text}
                </span>
                {
                    msgInfo.emoticSeq > 0 && msgInfo.emoticSeq < 64 &&
                    <img className="hiclasstv-classtalk-emo" src={`/images/classtalk/emoticon/et${msgInfo.emoticSeq.toString().padStart(2, '0')}.png`} alt="" />
                } {
                    msgInfo.urlInfo !== undefined && msgInfo.urlInfo !== "" &&
                    <WhisperMessage indexKey={msgInfo.idx} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />
                }
                <audio ref={audioEL} autoPlay style={audioStyle} />
            </div>
        </div>
    );
};
export default WebWhisperArea;