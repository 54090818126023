export const enEffectType = {
    None            : 0,
    CreateCode      : 1,
    CheckCode       : 2,
    CreateLive      : 3,
    AlertMessage    : 4
};

export const enLoginMode = {
    Web             : 0,
    Hiclass         : 1,
    None            : 2
};

export const enLoginStatus = {
    LogOut          : 0,
    LogIn           : 1,
    Studying        : 2
};

export const stUserStatus = {
    LogIn           : 'LOG_IN',
    LogOut          : 'LOG_OUT',
    Studying        : 'STUDYING'
};

export const enApplyStatus = {
    Apply           : 0,
    Approve         : 1,
    Reject          : 2,
    Cancel          : 3,
    Remove          : 4
};

export const enPageKind = {
    Chatting        : 0,
    ListChatting    : 1,
    ListLive        : 2,
    ListFriend      : 3,
    WebMain         : 4,
    WebLive         : 5
};

export const enBrowserType = {
    Web             : 0,
    HiClass         : 1,
};

export const enWebBrowserType = {
    Default         : 0,    // PC (Chrome)
    Android         : 1,
    iOS             : 2,
    Etc             : 3
};

export const enPentalkPage = {
    Friend          : 0,
    TeamUp          : 1,
    HandsUp         : 2,
    Chatting        : 3,
    Remocon         : 4,
    Whisper         : 5,
    Quiz            : 6,
};

export const enWebPage = {
    Home                : 0,
    Friend              : 1,
    TeamUp              : 2,
    LiveClassTalk       : 3,
    Live                : 4,
    Studio              : 5,
    Promotion           : 6,
    Pass                : 7,
    Download            : 8,
    Info                : 9,
    Manage              : 10,
    LiveQRLogin         : 11,
    SmartTVQRLogin      : 12,
    SmartTV             : 13,
    GroupSmartTVQRLogin : 14,
    GroupSmartTV        : 15,
    MixedClassLogin     : 16,
    RobotClassTalk      : 17,
    Robot               : 18,
    Policy              : 100,
    AppPolicy           : 101,
    NotFound            : 404,
};

export const enPassTap = {
    PRDT_LIST       : 0,
    PCHS_LIST       : 1,
    REFUND_LIST     : 2,
}

export const enInfoTap = {
    Home            : 0,
    Edutech         : 1,
    Contents        : 2,
    SmartSheet      : 3,
    Download        : 4,
    FAQ             : 5,
    Event           : 6,
    SHARP           : 7,
    AI              : 8,
    LINK            : 9
};

export const enAdminTap = {
    Home            : 0,        // default... sub domain 관리 화면
    LocalServer     : 1,
    Studio          : 2,
    Media           : 3,
    Notice          : 4,
    LiteLocalServer : 5,
    LocalSite       : 6,
    Lecture         : 7,
    Etc             : 99
};

export const enManagePage = {
    SubDomainInfo           : 0,
    ConfigSubDomain         : 1,
    ConfigSubDomainMember   : 2,
    LectureInfo             : 3
};

export const enFindOption = {
    START           : 0,
    ID              : 1,
    PW              : 2
};

export const enFindStep = {
    START           : 0,
    CHECK_ID        : 3,
    CERTIFY_PHONE   : 4,
    SHOW_ID         : 5,
    RESET_PW        : 6,
    END             : 7
};

export const enRbmqError = {
    OK                          : 0,
    CONNECT_TIMEOUT             : 1,
    SUBSCRIBE_TIMEOUT           : 2,
    UNSUBSCRIBE_TIMEOUT         : 3,
    PING_TIMEOUT                : 4,
    INTERNAL_ERROR              : 5,
    CONNACK_RETURNCODE          : 6,
    SOCKET_ERROR                : 7,
    SOCKET_CLOSE                : 8,
    MALFORMED_UTF               : 9,
    UNSUPPORTED                 : 10,
    INVALID_STATE               : 11,
    INVALID_TYPE                : 12,
    INVALID_ARGUMENT            : 13,
    UNSUPPORTED_OPERATION       : 14,
    INVALID_STORED_DATA         : 15,
    INVALID_MQTT_MESSAGE_TYPE   : 16,
    MALFORMED_UNICODE           : 17,
    TRANSPORT_CLOSE             : 18,
    TRANSPORT_ERROR             : 19,
};

export const enRbmqConRC = {
    ACCEPTED                        : 0,
    UNACCEPTABLE_PROTOCOL_VERSION   : 1,
    IDENTIFIER_REJECTED             : 2,
    SERVER_UNAVAILABLE              : 3,
    BAD_USER_NAME_OR_PASSWORD       : 4,
    NOT_AUTHORIZED                  : 5,
};

export const ctStyle = {
    Default         : 0,
    First           : 1,
    Second          : 2,
    Third           : 3,
    Fourth          : 4,
    Fifth           : 5,
    Sixth           : 6,
    Seventh         : 7,
    Eighth          : 8,
    Ninth           : 9
};

export const ctWatsonStatus = {
    InActive        : 0,
    Listening       : 1,
    Processing      : 2,
    Speaking        : 3,
    Exit            : 4
};

export const ctActionType = {
    InkMemo         : 0,
    VoiceMemo       : 1,
    FileShare       : 2,
    //VoiceTalk       : 3,
    //LiveTalk        : 4,
    PushScreen      : 3, // 현재 미사용 by hjkim 200705 사용시 아래 값들 모두 +1 처리해야함
    PullScreen      : 4,
    TeamUpWindow    : 5,
    HandsUpWindow   : 6,
    Quiz            : 7,
    FileDownload    : 8,
    FileClear       : 9,
    WatsonS_KR      : 20,
    WatsonS_EN      : 21,
    WatsonT_KR      : 22,
    WatsonT_EN      : 23
};

export const ctWebActionType = {
    InkMemo         : 0,
    VoiceMemo       : 1,
    FileShare       : 2,
    MediaSoupCon    : 3,
    HandsUp         : 4,
    Whisper         : 5,
    WatsonS_KR      : 20,
    WatsonS_EN      : 21,
    WatsonT_KR      : 22,
    WatsonT_EN      : 23,
};

export const ctTopKind = {
    None            : 0,
    Single          : 1,
    Multiple        : 2,
    Type1           : 1,
    Type2           : 2,
    Type4           : 3,
};

export const ctTimerLv = {
    LV_DEFAULT      : 250,
    LV_HIGH         : 167,
    LV_HIGH_MID     : 200,
    LV_MID          : 300,
    LV_LOW_MID      : 400,
    LV_LOW          : 500
};

export const prdtKind = {
    Purchase    : 'PURCH',
    Promtion    : 'PROMT'
};

export const prdtTarget = {
    B2B         : 'B2B',
    B2C         : 'B2C'
};

export const prdtSacle = {
    B2C         : { seq:10, code: 'B2C', name:'B2C 대상', value:1 },
    L50         : { seq:20, code: 'L50', name:'50명 미만', value:50 },
    L100        : { seq:30, code: 'L100', name:'100명 미만', value:100 },
    L500        : { seq:40, code: 'L500', name:'500명 미만', value:500 },
    H500        : { seq:50, code: 'H500', name:'500명 이상', value:100000 },
};

export const prdtTerm = {
    D30         : { seq:10, code:'D30', name:'30일', value:30 },
    D60         : { seq:20, code:'D60', name:'60일', value:60 },
    D90         : { seq:30, code:'D90', name:'90일', value:90 },
    D180        : { seq:40, code:'D180', name:'180일', value:180 },
    D365        : { seq:50, code:'D365', name:'1년(365일)', value:365 },
    D730        : { seq:60, code:'D730', name:'2년(730일)', value:730 },
};

export const termKind = {
    ALL         : "all",
    MONTH1      : 1,
    MONTH3      : 3,
    MONTH6      : 6,
    MONTH12     : 12
};

export const term = [
    { value     : termKind.ALL,             name  : '전체기간' },
    { value     : termKind.MONTH1,          name  : '1개월' },
    { value     : termKind.MONTH3,          name  : '3개월' },
    { value     : termKind.MONTH6,          name  : '6개월' },
    { value     : termKind.MONTH12,         name  : '1년' }
];

export const publishStatusType = {
    ALL         : "all",
    WAIT        : "nonissued",
    PUBLISHED   : "issued",
};

export const publishStatus = [
    { value : publishStatusType.ALL,        name : "전체" },
    { value : publishStatusType.WAIT,       name : "미발급" },
    { value : publishStatusType.PUBLISHED,  name : "발급완료" }
];

export const kindType = [
    { value : 'all',                        name : '전체' },
    { value : prdtKind.Purchase,            name : '구매' },
    { value : prdtKind.Promtion,            name : '프로모션' }
];

export const langCode = {
    Korea               : { value : 'ko-KR', subValue : 'kor', lang : 'ko', contry : 'KR' },
    USA                 : { value : 'en-US', lang : 'en', contry : 'US' },
    Japan               : { value : 'ja-JP', lang : 'ja', contry : 'JP' },
};

export const enAlertKind = {
    None                : 0,
    Success             : 1,
    Danger              : 2,
    Warning             : 3,
    Info                : 4
};

export const oAlert = {
    None                : { kind: enAlertKind.None, message: '' }
};

export const oWarningAlert = {
    LoginTryFail        : { kind : enAlertKind.Warning, message: '로그인 요청에 실패했습니다. 다시 시도해 주세요.' },
    AlreadyLogin        : { kind : enAlertKind.Warning, message: '이미 사용 중인 아이디입니다.' },
    NoPassUser          : { kind : enAlertKind.Warning, message: '이용권이 만료되었습니다. 이용권 갱신 후, 서비스를 이용하세요.' },
    InvalidAccess       : { kind : enAlertKind.Warning, message: '유효하지 않은 접근입니다.' },
    InvalidLoginToken   : { kind : enAlertKind.Warning, message: '사용할 수 없는 토큰이거나 유효하지 않은 접근입니다.' },
};

export const oWarningMessage = [
    { code: 'LOGIN_TRY_FAIL', message: '로그인 요청에 실패했습니다. 다시 시도해 주세요.'},
    { code: 'ALREADY_LOGIN', message: '이미 사용 중인 아이디입니다.' },
    { code: 'NO_PASS_USER', message: '이용권이 만료되었습니다. 이용권 갱신 후, 서비스를 이용하세요.' },
    
];

export const oAlertKind = {
    ERROR               : 'ERROR',      // restAPI 등에서 발생되는 에러
    INVALID             : 'INVALID'     // 유효하지 않은/만료된 접근
}

export const oAlertCode = {
    None                : { code: 'NONE', message: '' },
    LoginTryFail        : { code: 'LOGIN_TRY_FAIL', message: '로그인 요청에 실패했습니다. 다시 시도해 주세요.'},
    AlreadyLogin        : { code: 'ALREADY_LOGIN', message: '이미 사용 중인 아이디입니다.' },
    NoPassUser          : { code: 'NO_PASS_USER', message: '이용권이 만료되었습니다. 이용권 갱신 후, 서비스를 이용하세요.' },
    InvalidLoginToken   : { code: 'INVALID_LOGIN_TOKEN', message: '사용할 수 없는 토큰이거나 유효하지 않은 접근입니다.' },
    InvalidAccess       : { code: 'INVALID_ACCESS', message: '유효하지 않은 접근입니다.' }
};

export const stCardType = {
    InkMedia            : 'inkMedia',
    Text                : 'text',
    Audio               : 'audio',
    AudioText           : 'audioText',
    Image               : 'image',
    ImageAudio          : 'imageAudio',
    Video               : 'video',
    VideoText           : 'videoText',
    AnimatedMP3         : 'animatedMP3',
    AnimatedMP3Text     : 'animatedMP3Text',
    Link                : 'link',
    Pronunciation       : 'pronunciation',
    Quiz                : 'quiz',
    MultiTurn           : 'multiturn',
    RoleScript          : 'role-script',
    RolePlay            : 'role-play'
};

export const stCardItemType = {
    text                : 'text',
    audio               : 'audio',
    video               : 'video',
    image               : 'image',
    animatedMP3         : 'animatedMP3',
    pronunciation       : 'pronunciation',
    inkMedia            : 'inkMedia',
    roleScript          : 'role-script'
};

export const stQnAItemType = {
    Title               : 'title',
    Question            : 'question',
    Example             : 'example',
    Answer              : 'answer',
    Congratulation      : 'congratulation'
};

export const stQuizItemType = [
    'Text',
    'Image',
    'Audio',
    'Video'
];

export const stExampleItem = [
    '①',
    '②',
    '③',
    '④',
    '⑤',
    '⑥',
    '⑦',
    '⑧',
    '⑨',
    '⑩',
    '⑪',
    '⑫',
    '⑬',
    '⑭',
    '⑮'
];

export const stQuizDataKind = {
    Text                : 'Text',
    Image               : 'Image',
    Audio               : 'Audio',
    Video               : 'Video'
};

export const stQuizDataType = {
    None                : 'None',
    File                : 'File',
    URL                 : 'URL',
    Iframe              : 'Iframe',
    // Object              : 'Object',
    // Embed               : 'Embed'
};

export const stAttachmentType = {
    None                : 'none',
    URL                 : 'url',
    Base64              : 'base64',
    Iframe              : 'iframe',
    Object              : 'object',
    Embed               : 'embed'
};

export const stQuizFileUploadActionKind = {
    QuestionFileUpload  : 'question-file-upload',
    ExampleFileUpload   : 'example-file-upload',
    RemoveQuestionFile  : 'remove-question-file',
    RemoveExampleFile   : 'remove-example-file'
};

export const ctMsgType = {
    Text                : 'text',
    WatsonText          : 'watson_text',
    WatsonImage         : 'watson_image',
    FileShare           : 'file_share',
    InkMemo             : 'ink_memo',
    AudioMemo           : 'audio_memo',
    PageJpeg            : 'page_jpeg',
    CardTemplate        : 'card_template',
    Whisper             : 'whisper',
    Quiz                : 'quiz'
};

export const enRecordStatus = {
    Ready               : 0,
    Recording           : 1,
    Finish              : 2,
    Retry               : 3,
    Try                 : 4
};

export const enPermissionStatus = {
    Granted             : 'granted',
    Denied              : 'denied',
    NotSupported        : 'not-supported'
};

export const enResponseStatus = {
    Success             : 0,
    AuthFailed          : 1,
    PaymentFailed       : 2,
    UserCanceled        : 3,
    FailedWithError     : 4,
    MobilePaymentFailed : 5,
};

export const stMediaSoupKind = [
    {value      : 'soup.server'},
    {value      : 'soup.server.1'},
    {value      : 'soup.server.2'}
];

export const stMediaSoupStatusType = {
    None        : 'none',
    On          : 'on',
    Off         : 'off',
    Mute        : 'mute',
    Listen      : 'listen',
    Disable     : 'disable'
};

export const stUserType = {
    User        : 'USER',
    Robot       : 'ROBOT',
};