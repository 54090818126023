/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import WebHiClassInfoHome from './WebHiClassInfoHome';
import WebHiClassInfoEdutech from './WebHiClassInfoEdutech';
import WebHiClassInfoContents from './WebHiClassInfoContents';
import WebHiClassInfoGreenSmartDevice from './WebHiClassInfoGreenSmartDevice';
import WebHiClassDownload from './WebHiClassDownload';
import WebHiClassInfoQnA from './WebHiClassInfoQnA';

import { enInfoTap } from '../../../lib/ConstCommand';

const WebHiClassInfo = ({ currentInfoTap, download_info, handleSelectedFunc }) => {
    
    //console.log(document.getElementsByClassName("nav_list"));
    
    switch (currentInfoTap) {
        case enInfoTap.Home : return <WebHiClassInfoHome />;
        case enInfoTap.Edutech : return <WebHiClassInfoEdutech />;
        case enInfoTap.Contents : return <WebHiClassInfoContents />;
        case enInfoTap.SmartSheet : return <WebHiClassInfoGreenSmartDevice />;
        case enInfoTap.Download : return <WebHiClassDownload download_info={download_info} handleSelectedFunc={handleSelectedFunc} />;
        case enInfoTap.FAQ : return <WebHiClassInfoQnA />;
        default : return <WebHiClassInfoHome />;
    }
};

export default WebHiClassInfo;