import React from 'react';
import { BsX } from 'react-icons/bs';

const LiveMemberLimit = ({ live_info, handleSelectedConfigFunc }) => {

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" onClick={() => handleSelectedConfigFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex text-center my-2 dark-grey-text small">
                클래스의 최대 허용 멤버 수를 초과하였습니다.<br />
                기존 멤버를 삭제하거나<br />
                새로운 클래스를 만드세요.
            </div>
            <div className="content-area dark-grey-text">
                <span className="small mb-1">[클래스 이름]</span>
                <div className="text-area text-center area-80">
                    <span className="hiclass-text" title={live_info.liveName}><b>{live_info.liveName}</b></span>
                </div>
            </div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => handleSelectedConfigFunc({ selectedFunc: "NONE" })}>닫기</button>
            </div>
        </div>
    );
}

export default LiveMemberLimit;