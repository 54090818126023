import axios from "axios";
import ConstData from "../ConstData";
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const get_lite_server_list = () => {
    return axios.get(`${ConstData.REST_API_HOST_ADD}/lite-local`, options);
}

export const create_lite_server = ({ liteServerName, liteServerMemo }) => {
    let data = {};

    if (liteServerName !== undefined && liteServerName !== null) {
        data.liteServerName = liteServerName;
    }

    if (liteServerMemo !== undefined && liteServerMemo !== null) {
        data.liteServerMemo = liteServerMemo;
    }

    return axios.post(`${ConstData.REST_API_HOST_ADD}/lite-local`, data, options);
}

export const remove_lite_server = ({ authCode }) => {
    return axios.delete(`${ConstData.REST_API_HOST_ADD}/lite-local`, {
        authCode
    }, options);
}

export const change_lite_server_info = ({ kind, authCode, liteServerMemo, initStatus }) => {
    console.log(authCode, liteServerMemo, initStatus);

    let data = {};

    if (liteServerMemo !== "") {
        data.liteServerMemo = liteServerMemo;
    }

    if (initStatus !== undefined && initStatus !== null) {
        data.initStatus = initStatus;
    }

    return axios.put(`${ConstData.REST_API_HOST_ADD}/lite-local/${authCode}/server-admin?kind=${kind}`, data, options);
}