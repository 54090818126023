import React from 'react';

import CustomProfileImage from '../../user/profile/CustomProfileImage';

const UserInfo = ({ user_info }) => {
    return (
        <tr className="d-flex px-2">
            <td className="d-flex align-items-center justify-content-center area-15 fit-content">
                <CustomProfileImage url={user_info.profileImgUrl} alt={user_info.userNickname} />
            </td>
            <td className="d-flex full">
                <div className="d-flex flex-column text-area">
                    <span className="hiclass-text" title={user_info.userNickname}><b>{user_info.userNickname}</b></span>
                    <span className="hiclass-text small grey-text" title={user_info.userProfile}>{user_info.userProfile}</span>
                </div>
            </td>
        </tr>
    );
}

export default UserInfo;