import React from 'react';
import { FaCog } from 'react-icons/fa';
import { remoconType } from '../../../lib/classTalk/CodeEnum';

const RemoconTitleTemplate = ({ viewCMD, handleSelectedFunc }) => {
    const configAudioSpeakerDevice = () => {
        handleSelectedFunc({selectedFunc:"OPEN_SOUND_SETTING"});
    }
    
    return (
        <div className="title-area main justify-content-between hiclasstv-blue white-text">
            <b>리모콘</b>
            <div className="func-area right">
                { // sound control mode 가 아니어도 음향기기 설정 버튼이 보이도록 수정 by hjkim 20230503
                    // viewCMD === remoconType.SoundControl &&
                    <button type="button" title="음향기기 설정" onClick={configAudioSpeakerDevice}>
                        <FaCog className="d-flex font-md white-text" />
                    </button>
                }
            </div>
        </div>
    )
}
export default RemoconTitleTemplate;